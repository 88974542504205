<div class="expense-manager">
  <div class="layout-content p-pt-0">
    <span class="pull-right p-mr-3 clientform-btn-top">
      <p-button
        pRipple
        label="{{'Approve' | translate}}"
        icon="fas fa-check"
        styleClass="p-button-sm"
        class="p-pr-2"
        *ngIf="selectedItems.length > 0"
        (click)="approve()"></p-button>
      <p-button
        pRipple
        label="{{'add' | translate}}"
        icon="fal fa-plus"
        styleClass="p-button-sm"
        class="p-pr-2"
        (click)="addExpensePopUp()"></p-button>
    </span>
    <div class="p-col-12 p-mx-1">
      <div class="card p-pt-0">
        <p-table
          #dt
          [value]="clients"
          [responsive]="true"
          [rows]="10"
          dataKey="SlipMasterID"
          [columns]="selectedColumns"
          [totalRecords]="clients.length"
          selectionMode="single"
          [showCurrentPageReport]="true"
          [filterDelay]="0"
          styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
          sortField="Ddate"
          rowExpandMode="single"
          (onRowExpand)="onRowExpandEvent($event)"
          [globalFilterFields]="globalFilterColumns"
          [resizableColumns]="true"
          [(selection)]="selectedItems"
          (onFilter)="onFilter($event)"
          [sortOrder]="-1"
          (onRowUnselect)="uncheckRow($event)"
          [rows]="clients.length"
          [scrollable]="true"
          [scrollHeight]="'calc(100vh - 350px)'"
          [virtualScroll]="true">
          <ng-template pTemplate="caption">
            <div class="table-header">
              <span class="p-input-icon-left">
                <i class="fal fa-search"></i>
                <input
                  #searchValue
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{'globalsearch' | translate}}" />
              </span>
              <span>
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                  (click)="dt.reset();clearSearchFilter()"
                  tooltipPosition="top"
                  pTooltip="{{'clear' | translate}}"></button>
              </span>
              <p-message
                *ngIf="filteredItemsSize > -1"
                severity="success"
                text="{{filteredItemsSize}} {{'records_found' | translate}}."
                styleClass="p-ml-2">
              </p-message>
              <span class="pull-right">
                <label for="All Dates" class="p-mr-3 label-text">{{'All_Dates' | translate}}</label>
                <p-dropdown
                  [options]="dateRangeList"
                  styleClass="p-mr-3"
                  name="AllDates"
                  (onChange)="changeDateTypes()"
                  [(ngModel)]="DateRange"
                  name="dateRange"
                  id="dateRange"
                  #dateRange="ngModel"></p-dropdown>
                <label for="Date From" class="p-mr-3 label-text">{{'date-from' | translate}}</label>
                <p-calendar
                  #DateFromCalendar
                  styleClass="p-mr-3"
                  name="DateFrom"
                  [disabled]="IsDateTypeReadOnly"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="2000:2030"
                  showButtonBar="true"
                  [(ngModel)]="DateFrom"
                  (onSelect)="onDateChange($event)">
                </p-calendar>
                <label for="Date To" class="p-mr-3 label-text">{{'date-to' | translate}}</label>
                <p-calendar
                  #DateToCalendar
                  name="DateTo"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="2000:2030"
                  showButtonBar="true"
                  [(ngModel)]="DateTo"
                  [disabled]="IsDateTypeReadOnly"
                  (onSelect)="onDateChange($event)">
                </p-calendar>
                <p-multiSelect
                  [options]="cols"
                  [(ngModel)]="selectedColumns"
                  optionLabel="header"
                  styleClass="p-ml-3"
                  selectedItemsLabel="{0} columns selected"
                  [style]="{minWidth: '200px'}"
                  placeholder="Choose Columns"></p-multiSelect>
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th class="width-2p p-text-center"></th>
              <th class="width-2p p-text-center">
                <p-tableHeaderCheckbox [disabled]="!isAllowUser"></p-tableHeaderCheckbox>
              </th>
              <th class="width-5p p-text-center">{{'actions' | translate}}</th>
              <th class="width-7p p-text-center" pSortableColumn="Ddate">
                {{'date' | translate}}
                <p-sortIcon field="Ddate"></p-sortIcon>
              </th>
              <th class="width-10p p-text-left" pSortableColumn="ClientName">
                {{'client.name' | translate}}
                <p-sortIcon field="ClientName"></p-sortIcon>
              </th>
              <!--  <th class="width-8p p-text-left" pSortableColumn="EngagementName">
                                {{'timer.engagement' | translate}}
                                <p-sortIcon field="EngagementName"></p-sortIcon>
                            </th>
                            <th class="width-8p p-text-left" pSortableColumn="UserName">
                                {{'user-title' | translate}}
                                <p-sortIcon field="UserName"></p-sortIcon>
                            </th>
                            <th class="width-6p p-text-left" pSortableColumn="ExpenseCode">
                                {{'Expense' | translate}}
                                <p-sortIcon field="ExpenseCode"></p-sortIcon>
                            </th> -->
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="{{col.class}}">
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
              </th>
              <th class="width-5p p-text-right">{{'units' | translate}}</th>
              <th class="width-5p p-text-right">{{'Cost' | translate}}</th>
              <th class="width-5p p-text-right" pSortableColumn="StandardAmount">
                {{'amount' | translate}}
                <p-sortIcon field="StandardAmount"></p-sortIcon>
              </th>
              <th class="width-5p p-text-center">{{'billable' | translate}}</th>
              <th class="width-5p p-text-center" pSortableColumn="Reimburseable">
                {{'Reimbursable' | translate}}
                <p-sortIcon field="Reimburseable"></p-sortIcon>
              </th>
              <th class="width-5p p-text-center" pSortableColumn="Reimbursed">
                {{'Reimbursed' | translate}}
                <p-sortIcon field="Reimbursed"></p-sortIcon>
              </th>
              <th class="width-5p p-text-center">{{'Approve' | translate}}</th>
              <th class="width-5p p-text-center">{{'taxable' | translate}}</th>
              <th class="width-5p p-text-center">{{'billed' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-expanded="expanded"
            let-i="rowIndex"
            let-columns="columns">
            <tr class="cursor-hand">
              <td class="width-2p p-text-center">
                <span [pRowToggler]="rowData" *ngIf="rowData.hasChildren">
                  <i
                    pTooltip="{{'time.Show-Client-Documents' | translate}}"
                    pRipple
                    tooltipPosition="right"
                    showDelay="900"
                    [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                  </i>
                </span>
              </td>
              <td class="width-2p p-text-center">
                <p-tableCheckbox [value]="rowData" [disabled]="!isAllowUser"></p-tableCheckbox>
              </td>
              <td class="width-5p">
                <div class="p-text-center">
                  <i
                    pRipple
                    class="fal fa-pencil p-mr-1 ic-green"
                    (click)="openTimeEntryOrExpenseEntryDailog(rowData)"
                    tooltipPosition="top"
                    pTooltip="{{'Edit' | translate}}"></i>
                  <i
                    pRipple
                    (click)="deleteClientAlert(rowData,rowData.SlipMasterID);$event.stopPropagation();"
                    class="fal fa-trash-alt ic-red p-pl-2"
                    tooltipPosition="top"
                    pTooltip="{{'mgmt-delete' | translate}}"></i>
                </div>
              </td>
              <td class="width-7p p-text-center">
                {{ mangoUtils.formatDateString(rowData['Ddate']) | date : 'MM/dd/yyyy'}}
              </td>
              <td
                (click)="openTimeEntryOrExpenseEntryDailog(rowData)"
                class="width-10p p-text-left p-text-nowrap p-text-truncate"
                showDelay="800"
                pTooltip="{{rowData.ClientName}}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-no-wrap">
                {{rowData.ClientName}}
              </td>
              <td
                *ngFor="let col of columns"
                class="{{col.class}}"
                pTooltip="{{rowData[col.field]}}"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap"
                (click)="openTimeEntryOrExpenseEntryDailog(rowData)"
                tooltipPosition="top">
                {{rowData[col.field]}}
              </td>
              <!-- <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                pTooltip="{{rowData.EngagementName}}" showDelay="800" tooltipPosition="top"
                                (click)="openTimeEntryOrExpenseEntryDailog(rowData)" tooltipPosition="top"
                                pTooltip="{{rowData.EngagementName}}" tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.EngagementName}}
                            </td>
                            <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                pTooltip="{{rowData.StaffName}}" tooltipPosition="top"
                                (click)="openTimeEntryOrExpenseEntryDailog(rowData)" tooltipPosition="top"
                                pTooltip="{{rowData.StaffName}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.StaffName}}
                            </td>
                            <td class="width-6p p-text-left p-text-nowrap p-text-truncate"
                                pTooltip="{{rowData.ExpenseCode}}" showDelay="800" tooltipPosition="top"
                                (click)="openTimeEntryOrExpenseEntryDailog(rowData)"
                                tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.ExpenseCode}}
                            </td> -->
              <td
                class="width-5p p-text-right"
                (click)="openTimeEntryOrExpenseEntryDailog(rowData)">
                {{rowData.Units}}
              </td>
              <td
                class="width-5p p-text-right"
                (click)="openTimeEntryOrExpenseEntryDailog(rowData)">
                {{rowData.Cost}}
              </td>
              <td
                class="width-5p p-text-right"
                (click)="openTimeEntryOrExpenseEntryDailog(rowData)">
                {{rowData.StandardAmount | number : '1.2-2'}}
              </td>
              <td class="width-5p p-text-center">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="rowData['Billable']"
                  disabled="true"
                  [ngModelOptions]="{ standalone: true }"
                  >{{ rowData["Billable"] }}</p-checkbox
                >
              </td>
              <td class="width-5p p-text-center">
                <p-checkbox
                  [(ngModel)]="rowData['Reimburseable']"
                  binary="true"
                  (click)="saveExpenseData(rowData)">
                  {{ rowData["Reimburseable"] }}
                </p-checkbox>
              </td>
              <td class="width-5p p-text-center">
                <p-checkbox
                  [(ngModel)]="rowData['Reimbursed']"
                  binary="true"
                  (click)="saveExpenseData(rowData)">
                  {{ rowData["Reimbursed"] }}
                </p-checkbox>
              </td>
              <td class="width-5p p-text-center p-text-nowrap p-text-truncate">
                <p-checkbox [(ngModel)]="rowData['Approved']" binary="true" disabled="true">
                  {{ rowData["Approved"] }}
                </p-checkbox>
              </td>
              <td class="width-5p p-text-center">
                <p-checkbox [(ngModel)]="rowData['IsTaxable']" binary="true" disabled="true">
                  {{ rowData["IsTaxable"] }}
                </p-checkbox>
              </td>
              <td class="width-5p p-text-center">
                <p-checkbox [(ngModel)]="rowData['Billed']" binary="true" disabled="true">
                  {{ rowData["Billed"] }}
                </p-checkbox>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">
                <p-message
                  severity="info"
                  text="{{'data_not_found' | translate}}."
                  styleClass="p-px-2">
                </p-message>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData>
            <tr *ngIf="rowData.hasChildren">
              <td></td>
              <td [attr.colspan]="7">
                <div class="p-p-2 bg-blue">
                  <p-table
                    [value]="rowData.childItem"
                    dataKey="dmsParentID"
                    [rowsPerPageOptions]="[5,10,20]"
                    [rowHover]="true"
                    [paginator]="rowData.childItem.length > 7"
                    [paginatorPosition]="'top'"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="width-6p p-text-center">{{'actions' | translate}}</th>
                        <th class="width-10p p-text-center">{{'File' | translate}}</th>
                        <th class="width-5p p-text-center">{{'time.type' | translate}}</th>
                        <th class="width-5p p-text-center">{{'Size' | translate}}</th>
                        <th class="width-7p p-text-center">{{'Uploaded-By' | translate}}</th>
                        <th class="width-7p p-text-center">{{'Created-On' | translate}}</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-childData>
                      <tr class="cursor-hand">
                        <td>
                          <div class="p-text-center">
                            <i
                              class="fal fa-pencil cursor-hand ic-green"
                              (click)="editRow(childData)"
                              tooltipPosition="top"
                              pTooltip="{{'Edit' | translate}}"></i>
                            <i
                              (click)="deleteDocRow(childData,rowData)"
                              class="fal fa-trash-alt ic-red cursor-hand p-mx-3"
                              tooltipPosition="top"
                              pTooltip="{{'mgmt-delete' | translate}}"></i>
                            <i
                              (click)="previewRow(childData)"
                              class="fal fa-file-pdf ic-orange cursor-hand"
                              tooltipPosition="top"
                              pTooltip="{{'preview' | translate}}"></i>
                          </div>
                        </td>
                        <td class="p-text-center">
                          <span
                            class="pull-right cursor-hand ic-red p-pr-5 p-mt-2"
                            (click)="previewRow(childData)">
                            <u>{{childData.FName}}</u>
                          </span>
                        </td>
                        <td class="p-text-center">{{childData.FileType}}</td>
                        <td class="p-text-center">{{childData.Size}}</td>
                        <td class="p-text-center">{{childData['ModifyBy']}}</td>
                        <td class="p-text-center">
                          {{ mangoUtils.formatDateString(childData['CreatedOn']) | date:'MM/dd/yyyy'
                          : 'UTC'}}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <p-sidebar
    [(visible)]="isFilePreview"
    [position]="'right'"
    styleClass="width-60p"
    [dismissible]="true"
    [showCloseIcon]="true"
    (onHide)="closePreviewDialog()"
    [closeOnEscape]="false">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card">
          <div
            #preiewviewer
            class="preiewviewer"
            [ngStyle]="{'visibility':isPreviewViewerShow? 'visible' : 'hidden' }"></div>
          <div #dummyViewer class="dummyViewer"></div>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>

<p-dialog
  header="File Name"
  [(visible)]="isShowfileName"
  [modal]="true"
  [style]="{width: '35vw'}"
  [maximizable]="false"
  showEffect="fade"
  [baseZIndex]="10000"
  [draggable]="true"
  [resizable]="false"
  (onHide)="isShowfileName=false;fileName='';selectedObj = null;">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <label for="subject" class="required-field p-mr-5 p-col-3"
          >{{ 'file_name' | translate }}</label
        >
        <input
          type="text"
          pInputText
          class="required-field p-mr-5 p-col-7"
          [(ngModel)]="fileName"
          maxlength="40"
          autocomplete="nope"
          autocomplete="off" />
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="fal fa-check"
      [disabled]="!fileName"
      (click)="saveFileName()"
      label="{{ 'save_button' | translate }}"></button>
    <button
      type="button"
      pButton
      icon="fal fa-times"
      (click)="isShowfileName=false;fileName='';selectedObj = null;"
      label="{{ 'close' | translate }}"></button>
  </ng-template>
</p-dialog>
