<div class="mentions">
  <textarea class="width-100p" [style]="style" [disabled]="disabled" [required]="required" [id]="id" #inputBox (input)="onInput(inputBox.value)"
    pInputTextarea [(ngModel)]="inputText" (ngModelChange)="onInputTextChange()"
    (focus)="$event.target.select()" [class]="class"
    [rows]="rows" [maxlength]="maxlength"
    placeholder="Type @ to search Shortcuts"></textarea>
  <ul *ngIf="showSuggestions && suggestions.length > 0">
    <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)">
      {{ suggestion }}
    </li>
  </ul>
</div>