import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { Table } from 'primeng/table';
import { SharedComponentsService } from '@app/shared/components';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-engagements-contact',
  templateUrl: './engagements-contact.component.html'
})
export class EngagementsContactComponent implements OnInit {

  public contacts = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public selectedClientName: string = null;
  searchTextStr: any = "";
  projectClientID: number = -1;
  projectID: number = -1;
  selectedEngagementName: string = null;

  constructor(private router: Router, public sharedSrvc: SharedComponentsService,private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public translate: TranslateService) {
    this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.projectClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.selectedEngagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + 'C' + AppConstants.SearchString);
    this.getContactsByProjectId();
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('engagement') },
      { label: this.translate.instant('client.contacts') },
      { label: this.selectedClientName, icon: 'ic-red' },
      { label: this.selectedEngagementName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.sharedSrvc.contactDialogData
      .subscribe((data) => {
        this.getContactsByProjectId();
      })
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'C' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'C' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  // adding new engagent
  addContactPopUp() {   
    const data = {}
    data['ClientID'] = this.projectClientID;
    data['ProjectMasterID'] = this.projectID;
    data['isEditFlow'] = false;
    data['ClientName'] = this.selectedClientName;
    data['isDisableOptions'] = true;
    this.sharedSrvc.openContactDialog(data);
  }

  editContact(contact) {
    contact['isEditFlow'] = true;
    contact['ClientName'] = this.selectedClientName;
    contact['isDisableOptions'] = true;
    this.sharedSrvc.openContactDialog(contact);
  }

  deleteContact(customerContactID) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteContact(customerContactID).subscribe(function (data) {
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
          parent.mangoAPISrvc.showLoader(false);
          parent.getContactsByProjectId();
        }, error => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

  sendEmailToContact(client) {
    window.location.href = "mailto:" + client.Email;
  }
  
  getContactsByProjectId() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getContactsByProjectId(parent.projectID).subscribe(function (data:any) {
      parent.contacts = data;
      parent.loadFilterGrid();
      parent.mangoAPISrvc.fireEngagementBadgeView(true);
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  redirectListView() {
    this.encrDecSrvc.addObject(AppConstants.projectID, "");
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }

}
