import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils} from '@app/_services';
import { SelectItem } from 'primeng/api';
import { AppConstants } from '@app/_helpers/api-constants';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-rates',
  templateUrl: './user-rates.component.html'
})
export class UserRatesComponent implements OnInit, AfterViewInit{
  public selectedUserNameStr: any;
  public myUserRateForm: UntypedFormGroup;
  public templatesDataSet: any;
  public costMethodDataSet: any;
  public userRatesDataSet: any;
  public StaffID: number = null;
  public profileDataSet: SelectItem[];
  public selectedProfileDataSet: SelectItem[];
  public RateMasterIDNo: any;
  selectedValue: string;
  disabledflag: boolean;
  public isFormValid: boolean = false;

  constructor(private router: Router, private _fb: UntypedFormBuilder, protected mangoUtils: mangoUtils,private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService,public translate: TranslateService) {
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.selectedUserName);
    this.StaffID = this.encrDecSrvc.getObject(AppConstants.usersID);
    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('user.user_list')},
        { label: this.translate.instant('user_rates') },
        { label: this.selectedUserNameStr, icon: 'ic-red'}
      ]);
    })
    this.initializeUserRateForm();
  }

  ngOnInit(): void {
    if (this.StaffID > 0) {
      this.loadUserRateData();
    } else {
      this.selectedValue = 'true';
      this.myUserRateForm.controls['FixedRateBit'].setValue(true);
      this.myUserRateForm.controls['PercentBit'].setValue(false);
    }

    
  }

  ngAfterViewInit() {
    this.mangoAPISrvc.isFormChanged = false;
    this.myUserRateForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.myUserRateForm.pristine);
      this.validateForm();
    })
  }

  redirectToUserList() {
    this.encrDecSrvc.addObject(AppConstants.usersID, -1);
    this.router.navigate([AppConstants.users + '/' + AppConstants.listRoutePath]);
    setTimeout(() => {
      this.mangoAPISrvc.fireUserView(true);
    }, 100);
  }

  initializeUserRateForm() {
    this.myUserRateForm = this._fb.group({
      DefaultBillingRate: ['0.00', [<any>Validators.required, <any>Validators.maxLength(20)]],
      ActivateBillingRateProfiles: [false],
      FixedRateBit: new UntypedFormControl(),
      AverageCostPerHour: ['0.00'],
      PercentBit: new UntypedFormControl(),
      PercentBilling: ['0.00']
    });
  }

  updateUserRateData() {
    /* default values */
    this.disabledflag = false;
    this.userRatesDataSet['DefaultBillingRate'] = (this.userRatesDataSet['DefaultBillingRate']) ? this.userRatesDataSet['DefaultBillingRate'] : '';
    this.myUserRateForm.controls['DefaultBillingRate'].setValue(this.userRatesDataSet['DefaultBillingRate'].replace(/\$/g, ''));
    this.myUserRateForm.controls['ActivateBillingRateProfiles'].setValue(this.userRatesDataSet['ActivateBillingRateProfiles']);
    this.costMethodDataSet['AverageCostPerHour'] = (this.costMethodDataSet['AverageCostPerHour']) ? this.costMethodDataSet['AverageCostPerHour'] : '';
    this.myUserRateForm.controls['AverageCostPerHour'].setValue(this.costMethodDataSet['AverageCostPerHour'].replace(/\$/g, ''));
    this.myUserRateForm.controls['FixedRateBit'].setValue(this.costMethodDataSet['FixedRateBit']);
    this.myUserRateForm.controls['PercentBilling'].setValue(this.costMethodDataSet['PercentBilling']);
    this.myUserRateForm.controls['PercentBit'].setValue(this.costMethodDataSet['PercentBit']);

    this.costMethodDataSet['FixedRateBit'] = (this.costMethodDataSet['FixedRateBit']) ? this.costMethodDataSet['FixedRateBit'] : '';
    this.costMethodDataSet['PercentBit'] = (this.costMethodDataSet['PercentBit']) ? this.costMethodDataSet['PercentBit'] : '';
    if (this.costMethodDataSet['FixedRateBit']) {
      this.selectedValue = this.costMethodDataSet['FixedRateBit'].toString();
      if (this.selectedValue === 'false') {
        this.disabledflag = true;
      } else {
        this.disabledflag = false;
      }
    } else if (this.costMethodDataSet['PercentBit']) {
      this.selectedValue = this.costMethodDataSet['PercentBit'].toString();
      if (this.selectedValue === 'true') {
        this.disabledflag = true;
        this.selectedValue = 'false';
      } else {
        this.disabledflag = false;
      }
    } else if (this.costMethodDataSet['FixedRateBit'] === "" && this.costMethodDataSet['PercentBit'] === "") {
      this.myUserRateForm.controls['PercentBit'].setValue(true);
      this.myUserRateForm.controls['FixedRateBit'].setValue(false);
      this.myUserRateForm.controls['AverageCostPerHour'].setValue("0.00");
      this.selectedValue = 'true';
      this.disabledflag = false;
    }
  }

  loadUserRateData() {
    this.mangoAPISrvc.getRateTemplates().subscribe((data:any) => {
      const templatesDataSet = data.filter((tmp) => (tmp.Inactive == false)).sort(this.mangoUtils.compareValues('StaffRateTemplate', 'asc'));
      this.templatesDataSet = this.setDropDownLabels(templatesDataSet, 'StaffRateTemplate', 'RateMasterID', 'Select RateTemplate');
      // loading Cose method data
      this.mangoAPISrvc.getcostMethodData(this.StaffID).subscribe((data) => {
        this.costMethodDataSet = data;
        // loading Cose method data
        this.mangoAPISrvc.getUserRatesData(this.StaffID).subscribe((data:any) => {
          this.userRatesDataSet = data;
          let filteredObj = this.templatesDataSet.filter((user) => (data.RateMasterID === user['RateMasterID']));
          if (filteredObj.length === 0) { filteredObj = this.templatesDataSet[0]; this.selectedProfileDataSet = this.templatesDataSet[0]['serviceCode']; this.RateMasterIDNo = this.templatesDataSet[0]['RateMasterID']; }
          else { this.selectedProfileDataSet = filteredObj[0]['serviceCode']; this.RateMasterIDNo = filteredObj[0]['RateMasterID']; }
          this.updateUserRateData();
        });
      });
    });
  }

  saveUserRateData(arg: any) {    
    this.isFormValid = false;
    const obj = this.myUserRateForm.value;
    // Cost Method Data
    const costMethodObj = {};
    if (this.selectedValue === 'false') {
      costMethodObj['PercentBit'] = true;
      costMethodObj['FixedRateBit'] = false;
    } else {
      costMethodObj['FixedRateBit'] = true;
      costMethodObj['PercentBit'] = false;
    }
    if (obj['PercentBilling']) {
      costMethodObj['PercentBilling'] = obj['PercentBilling'];
    } else {
      costMethodObj['PercentBilling'] = '0';
    }

    costMethodObj['AverageCostPerHour'] = obj['AverageCostPerHour'];
    // Rate form fillData
    const rateData = {};
    rateData['DefaultBillingRate'] = obj['DefaultBillingRate'].toString();
    rateData['ActivateBillingRateProfiles'] = obj['ActivateBillingRateProfiles'];
    rateData['RateMasterID'] = this.RateMasterIDNo;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updatecostMethodDataById(this.StaffID, costMethodObj).subscribe((data) => {
      this.mangoAPISrvc.updateUserRatesDataById(this.StaffID, rateData).subscribe((data) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      });
    }, error => {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    });
  }

  changeStatus(flag) {
    this.disabledflag = flag;
  }

  dropDownRateType(opt) {
    this.selectedProfileDataSet = this.templatesDataSet.filter((item) => item['RateMasterID'] == opt.value)[0]['serviceCode'];
    this.RateMasterIDNo = opt.value;
    this.isFormValid = true;
  }

  setDropDownLabels(data, title, value, defaultValue) {
    for (let i = 0; i < data.length; ++i) {
      data[i]['label'] = data[i][title];
      data[i]['value'] = data[i][value]
    }
    data.unshift({ label: defaultValue, value: null });
    return data;
  }
 
  /*
        Verifing the form
    */
  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myUserRateForm.controls).forEach(key => {
      if (this.myUserRateForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myUserRateForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.myUserRateForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }    
  }

}
