import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BreadcrumbService,
  EncrDecrService,
  mangoUtils,
  MangoApiService
} from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import moment from 'moment';
declare let numeral: any;

import FileSaver from "file-saver";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: 'app-transaction-return-and-void',
  templateUrl: './transaction-return-and-void.component.html'
})
export class TransactionReturnAndVoidComponent implements OnInit {
  public dataSource: any = [];
  public companyDataSource: any;
  public cols: any[];
  public datesObj: any = { 'startDate': null, 'endDate': null };
  @ViewChild('searchValue') searchValue;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  public totalAmt: number = 0;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private mangoUtils: mangoUtils,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Transactions" },
      { label: "Return & Void", icon: "ic-red" },
    ]);

    this.searchTextStr = this.encrDecSrvc.getObject(
      "return&void_" + AppConstants.SearchString
    );

    this.cols = [
      { field: 'Status', header: 'Status' },
      { field: 'Message', header: 'Message' },
      { field: 'Confirmation', header: 'Transaction Reference' }
    ];

    this.getCompanyData();
  }

  getCompanyData() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getCompanyViewData().subscribe((data: any) => {
      this.companyDataSource = data;
      this.showReports();
      this.mangoAPISrvc.showLoader(false)
    }, (data: any) => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('error', 'Error!', data.message
      );
    });
  }

  showReports() {
    const sbpMerchant = {};
    sbpMerchant['MerchantID'] = this.companyDataSource['CCmerchantID'];
    sbpMerchant['Login'] = this.companyDataSource['CClogin'];
    sbpMerchant['Password'] = this.companyDataSource['CCpassword'];
    sbpMerchant['DateFrom'] = this.datesObj.startDate;
    sbpMerchant['DateTo'] = this.datesObj.endDate;
    sbpMerchant['FilterOptions'] = 'achonly';

    const parent = this;
    parent.dataSource = [];
    parent.mangoAPISrvc.showLoader(true)
    parent.mangoAPISrvc.getReports(sbpMerchant).subscribe((data: any) => {
      if (data['error']) { return false; }
      data = data['Transactions'].filter((item) => item['FinalStatus'] != 'ACH Payment Voided');
      data = data.filter((item) => item['AuthCode'] != 'Tokenized');
      
      const dataDS = data.map(function (obj) {
        obj['Amount'] = numeral(obj['Amount']).format('0,0.00');
        obj['udfield2'] = obj['udfield2'] ? obj['udfield2'] : "";
        obj['SubmitDate'] = moment(obj['SubmitDate']).format('MM/DD/YYYY hh:mm A');
        obj['TempDate'] = moment(obj['SubmitDate']).format('MM/DD/YYYY');
        obj['isGrandTotal'] = false;
        obj['isProccessed'] = false;
        obj['subAmount'] = 0;
        
        if (obj['FinalStatus'] == 'Payment Marked' || obj['FinalStatus'] == 'success') {
          obj['udfield2'] = "Reverse";
          obj['udfield1'] = 'Settled';
        } else {
          obj['udfield1'] = obj['FinalStatus'];
        }
        return obj;
      });
      const backUpDS = parent.mangoUtils.removeDuplicatesByProperty(Object.assign([], dataDS), 'TempDate');

      for (let index = 0; index < backUpDS.length; index++) {
        const element = backUpDS[index];
        let totals = 0;
        const tempObj = { 'isGrandTotal': true, 'isProccessed': true, 'SubmitDate': '', 'Confirmation': '', 'AuthCode': '', 'udfield1': '', 'FirstName': 'Sub Total', 'Amount': 0, 'udfield2': '' };
        if (element['TempDate'] && element['TempDate'] != null) {
          for (let innerIndex = 0; innerIndex < dataDS.length; innerIndex++) {
            const innerElement = dataDS[innerIndex];
            if (innerElement.isProccessed) {
              continue;
            }
            if (innerElement['TempDate'] && innerElement['TempDate'] != null && (element['TempDate'] == innerElement['TempDate'])) {
              innerElement.isProccessed = true;
              totals = (innerElement['FinalStatus'] == "ACH Payment Voided") ? totals : (totals + numeral(innerElement['Amount']).value());
              if (totals > 0 && innerIndex > 0 && innerIndex == dataDS.length - 1) {
                tempObj['subAmount'] = numeral(totals).format('0,0.00');
                dataDS.splice(dataDS.length + 1, 0, tempObj);
                break;
              }
            } else {
              if (totals > 0) {
                tempObj['subAmount'] = numeral(totals).format('0,0.00');
                dataDS.splice(innerIndex, 0, tempObj);
              }
              break;
            }
          }
        }
      }

      parent.dataSource = [...dataDS];
      const tempList = parent.dataSource.filter((item) => item['FinalStatus'] != "ACH Payment Voided");
      const tempTotalsAmt = tempList.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
      this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');
      this.mangoAPISrvc.showLoader(false)
    }, (data: any) => {
      this.mangoAPISrvc.showLoader(false)
      parent.mangoAPISrvc.notify('error', 'Error!', data.message);
    });
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    this.encrDecSrvc.addObject("return&void_" + AppConstants.SearchString, "");
  }

  onFilter(event) {
    if (!this.searchValue?.nativeElement?.value) return;

    this.filteredItemsSize = event.filteredValue.length;

    this.encrDecSrvc.addObject(
      "return&void_" + AppConstants.SearchString,
      event.filters.global.value
    );
  }

  exportExcel() {
    const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Customers");
  }

  exportPdf() {
    const doc: any = new jsPDF("l", "pt");

    const columns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    const body = this.dataSource;

    doc.autoTable(columns, body);
    doc.save(`Credit_cards_export_${new Date().getTime()}.pdf`);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

}
