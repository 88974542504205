<div class="customers">
    <div class="layout-content p-px-3">
        <div class="p-grid p-p-0">
            <div class="p-col-12">
                <div class="card p-grid p-pt-0 p-m-0">
                    <div class="p-col-12 p-pt-0">
                        <p-table #dt [value]="dataSource" [responsive]="true" [rows]="10" [columns]=cols
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [filterDelay]="0" (onFilter)="onFilter($event)" [sortOrder]="-1" [scrollable]="true"
                            scrollHeight="400px" [rowHover]="true" [globalFilterFields]="['CompanyName','LexStatusMessage']">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>

                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                    <span class="pull-right">
                                        <button type="button" pButton pRipple icon="pi pi-file-o"
                                            (click)="dt.exportCSV()" class="p-ml-2 p-mr-2" pTooltip="CSV"
                                            tooltipPosition="bottom" *ngIf="dataSource.length > 0"></button>
                                       <!--  <button type="button" pButton pRipple icon="pi pi-file-excel"
                                            (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                            tooltipPosition="bottom" *ngIf="dataSource.length > 0"></button> -->
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                            (click)="exportPdf()" class="p-button-warning" pTooltip="PDF"
                                            tooltipPosition="bottom" *ngIf="dataSource.length > 0"></button>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-80p p-text-left" pSortableColumn="CompanyName">
                                        Company Name
                                        <p-sortIcon field="CompanyName"></p-sortIcon>
                                    </th>
                                    <th class="width-13p p-text-left">
                                        Status
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-80p">
                                        {{rowData.CompanyName}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-13p">
                                    {{rowData.LexStatusMessage}}
                                </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td [colspan]="cols.length">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>