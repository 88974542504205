import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { billingInvoicingComponent } from './billing-invoicing.component';
import { BatchBillingComponent } from './batch-billing/batch-billing.component';
import { ApplyTimeToInvoiceComponent } from './apply-time-to-invoice/apply-time-to-invoice.component';
import { ClientStatementsComponent } from './client-statements/client-statements.component';
import { InvoiceReviewComponent } from './invoice-review/invoice-review.component';
import { RecurringInvoicesComponent } from './recurring-invoices/recurring-invoices.component';
import { ManualInvoiceComponent } from './manual-invoice/manual-invoice.component';
import { StatmentPrintingComponent } from './statment-printing/statment-printing.component';
import { RetainerInvoiceComponent } from './retainer-invoice/retainer-invoice.component';
import { RecurringInvoiceNewComponent } from './recurring-invoice-new/recurring-invoice-new.component';
import { QuerybuilderComponent } from './querybuilder/querybuilder.component';
import { AuthGuard } from '@app/_services';
import { CanDeactivateForm } from './can-deactivate';
import { AppConstants } from '@app/_helpers/api-constants';
import { ClearInvoicingBusyStaffName } from './clear-invoicing-busy-staff-name';
import { InvoiceBlocking } from './invoice-blocking';

const routes: Routes = [
  {
    path: '',
    component: billingInvoicingComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: billingInvoicingComponent },
      { path: AppConstants.manualInvoice, component: ManualInvoiceComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 17 }},
      { path: AppConstants.invoiceReview, component: InvoiceReviewComponent,canActivate: [AuthGuard], data: { 'featID': 17 }},
      { path: AppConstants.batchBilling, component: BatchBillingComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 17 }},
      { path: AppConstants.applyTimeToInvoice, component: ApplyTimeToInvoiceComponent, canActivate: [AuthGuard], data: { 'featID': 17 }},
      { path: AppConstants.clientStatements, component: ClientStatementsComponent, canActivate: [AuthGuard], data: { 'featID': 17 }},
      { path: AppConstants.statementPrinting, component: StatmentPrintingComponent, canActivate: [AuthGuard], data: { 'featID': 17 }},
      { path: AppConstants.retainerInvoice, component: RetainerInvoiceComponent, canActivate: [AuthGuard], canDeactivate: [], data: { 'featID': 17 }},
      { path: AppConstants.recurringInvoiceNew, component: RecurringInvoiceNewComponent, canActivate: [AuthGuard], data: { 'featID': 17 }},
      { path: AppConstants.queryBuilder, component: QuerybuilderComponent, canActivate: [AuthGuard]}
    ],
    data: { 'roles': ["manager","Senior Manager"]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm, ClearInvoicingBusyStaffName]
})
export class BillingInvoicingRoutingModule { }
