import { Component, OnInit, ViewChild } from "@angular/core";
import { BreadcrumbService, MangoApiService } from "@app/_services";
import { SelectItem } from "primeng/api";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import moment from "moment";

import FileSaver from "file-saver";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
declare let numeral: any;
@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
})
export class CustomersComponent implements OnInit {
  @ViewChild("searchValue") searchValue;
  customerCols: any = [];
  customersList: any = [];
  filteredCustomersList: any = [];
  filteredItemsSize = -1;
  totalUserCount: number = 0;
  public IsDateTypeReadOnly: boolean = true;
  public dateRangeList: SelectItem[];
  public planList: SelectItem[];
  public myReportsForm: UntypedFormGroup;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private _fb: UntypedFormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: "Super Admin Dashboard" },
      { label: "Customers", icon: "ic-red" },
    ]);

    this.customerCols = [
      { field: "CompanyName", header: "Customer" },
      { field: "EmailCompany", header: "Email" },
      { field: "CompanyContact", header: "Contact" },
      { field: "SubscriptionLevel", header: `Plan` },
      { field: "NumberofUsers", header: "Users" },
      { field: "annualvalue", header: "Value" },
      { field: "DateSubscribed", header: "Plan Date" },
      { field: "DatePlanActivated", header: "Plan Date" },
      { field: "Telephone", header: "Phone #" },
      { field: "State", header: "State" },
    ];

    this.dateRangeList = [
      { label: "All Dates", value: null },
      { label: "Todays Date", value: "Today" },
      { label: "This Week", value: "Week" },
      { label: "This Month", value: "Month" },
      { label: "This Quarter", value: "Quarter" },
      { label: "This Year", value: "Year" },
      { label: "Last Week", value: "lastweek" },
      { label: "Last Month", value: "lastmonth" },
      { label: "Last Quarter", value: "lastquarter" },
      { label: "Last Year", value: "lastyear" },
      // { label: "Custom", value: "custom" },
    ];

    this.planList = [
      { label: "All", value: null },
      { label: "FREE", value: "free" },
      { label: "SOLO", value: "solo" },
      { label: "PRO", value: "pro" },
      { label: "ENTERPRISE", value: "enterprise" },
    ]
  }

  ngOnInit() {
    this.initializeForm();
    this.myReportsForm.reset();
    this.getCustomers();
  }

  initializeForm() {
    this.myReportsForm = this._fb.group({
      DateFrom: [new Date("1901-01-01"), [<any>Validators.required]],
      DateTo: [new Date(), [<any>Validators.required]],
      DateRange: [""],
      PlanFilter: [null],
    });
  }

  changeDateTypes() {
    let isAllDateSelected = false;
    this.IsDateTypeReadOnly = true;
    const obj = this.myReportsForm.controls["DateRange"].value;
    if (obj == "Today") {
      this.myReportsForm.controls["DateFrom"].setValue(new Date());
      this.myReportsForm.controls["DateTo"].setValue(new Date());
    } else if (obj == "Week") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().startOf("isoWeek").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().endOf("isoWeek").format())
      );
    } else if (obj == "lastweek") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().startOf("isoWeek").subtract(1, "week").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().endOf("isoWeek").subtract(1, "week").format())
      );
    } else if (obj == "Month") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().startOf("month").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().endOf("month").format())
      );
    } else if (obj == "lastmonth") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().subtract(1, "months").startOf("month").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().subtract(1, "months").endOf("month").format())
      );
    } else if (obj == "Quarter") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().startOf("quarter").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().endOf("quarter").format())
      );
    } else if (obj == "lastquarter") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().subtract(1, "quarters").startOf("quarter").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().subtract(1, "quarters").endOf("quarter").format())
      );
    } else if (obj == "Year") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().startOf("year").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().endOf("year").format())
      );
    } else if (obj == "lastyear") {
      this.myReportsForm.controls["DateFrom"].setValue(
        new Date(moment().subtract(1, "years").startOf("year").format())
      );
      this.myReportsForm.controls["DateTo"].setValue(
        new Date(moment().subtract(1, "years").endOf("year").format())
      );
      // } else if (obj == 'custom') {
      //   this.IsDateTypeReadOnly = false;
      //   this.myReportsForm.controls['DateFrom'].setValue(new Date());
      //   this.myReportsForm.controls['DateTo'].setValue(new Date());
    } else {
      this.myReportsForm.controls["DateFrom"].setValue(new Date("1901-01-01"));
      this.myReportsForm.controls["DateTo"].setValue(new Date("2099-12-30"));
      isAllDateSelected = true;
    }

    this.onRefresh(isAllDateSelected);
  }

  onRefresh(isAllDateSelected) {
    this.totalUserCount = 0;
    let list = this.customersList;
    const planFilter = this.myReportsForm.controls["PlanFilter"].value;
    if(planFilter !== null) {
      list = list.filter((item) => item.SubscriptionLevel?.toLowerCase() == planFilter?.toLowerCase())
    }
    
    if (isAllDateSelected) this.filteredCustomersList = list;
    else {
      const startDate = moment(this.myReportsForm.controls["DateFrom"].value);
      const endDate = moment(this.myReportsForm.controls["DateTo"].value);
      this.filteredCustomersList = list.filter(
        (customer) =>
          moment(customer.DatePlanActivated) > startDate &&
          moment(customer.DatePlanActivated) < endDate
      );
    }
    
    this.totalUserCount += this.filteredCustomersList.reduce((a, b) => {
      return numeral(a).value() + +numeral(b.NumberofUsers).value();
    }, 0)

    this.filteredItemsSize = this.filteredCustomersList.length;
  }

  onFilter(obj) {
    this.totalUserCount = 0;
    this.filteredItemsSize = obj.filteredValue.length;
    this.totalUserCount += obj.filteredValue.reduce((a, b) => {
      return numeral(a).value() + +numeral(b.NumberofUsers).value();
    }, 0)
  }

  getCustomers() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCustomerDetails().subscribe(
      (data: any) => {
        this.filteredCustomersList = this.customersList = data.map(
          (customer) => {
            return {
              ...customer,
              DateSubscribed: customer.DateSubscribed ? moment(customer.DateSubscribed).format('YYYY/MM/DD') : null,
              DatePlanActivated: customer.DatePlanActivated ? moment(customer.DatePlanActivated).format('YYYY/MM/DD') : null,
              annualvalue: customer.annualvalue
                ? parseFloat(
                    customer.annualvalue.replace("$", "").replace(",", "")
                  )
                : 0.0,
            };
          }
        );
        this.filteredItemsSize = this.filteredCustomersList.length;
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    this.filteredItemsSize = -1;
  }

  exportExcel() {
    const worksheet = xlsx.utils.json_to_sheet(this.customersList);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Customers");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportPdf() {
    const doc: any = new jsPDF("l", "pt");

    const columns = this.customerCols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    const body = this.customersList;

    doc.autoTable(columns, body);
    doc.save(`Customers_export_${new Date().getTime()}.pdf`);
  }

  onChangePlanFilter() {
    this.onRefresh(this.myReportsForm.controls["DateRange"].value == null);
  }
}
