import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, AuthGuard } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;
  isUsersList: boolean = false;
  userViewChangeSubscription: Subscription;
  usersID: number = -1;
  editMode: boolean = false;

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public authGuard: AuthGuard,
    public translate: TranslateService) { 
    this.mangoAPISrvc.applyLanguage();
    this.userViewChangeSubscription = this.mangoAPISrvc.userViewChange$.subscribe((data) => {
      this.redirectScreens();
    });
  }

  ngOnInit(): void {
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
  }

  redirectScreens() {
    this.usersID = this.encrDecSrvc.getObject(AppConstants.usersID);
    this.isUsersList = this.usersID ? false : true;
    if (this.isUsersList) {
      this.router.navigate([AppConstants.users + '/' + AppConstants.listRoutePath]);
    } else if (this.usersID === -1) {
      this.encrDecSrvc.removeObject(AppConstants.selectedUserRecord);
      this.editMode = false;
      this.items = [
        { label: AppConstants.Main, routerLink: [AppConstants.viewRoutePath], icon: 'fas fa-user-circle' }
      ];
      this.selectedMenu = this.items[0];
      this.router.navigate([AppConstants.users + '/' + AppConstants.viewRoutePath]);
    } else {
      this.setMenuIndex();
      this.editMode = true;
      this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
        this.loadMenu();
      })
      this.translate.onLangChange.subscribe(data=>{
        this.loadMenu();
      })
      this.router.navigate([this.selectedUrl]);
    }
  }

  loadMenu() {
    this.items = [
      { label: this.translate.instant('user_information'), routerLink: [AppConstants.viewRoutePath], icon: 'fal fa-user', }
    ];

    if (
      this.authGuard.isAllowAccess(33) ||
      this.encrDecSrvc.getObject(AppConstants.usersID) ===
        this.encrDecSrvc.getObject(AppConstants.resourceID)
    ) {
      this.items.push({
        label: this.translate.instant("user_rates"),
        routerLink: [AppConstants.ratesRoutePath],
        icon: "fal fa-cog",
      });
      this.items.push({
        label: this.translate.instant("education_cpe"),
        routerLink: [AppConstants.educationRoutePath],
        icon: "fal fa-building",
      });
      this.items.push({
        label: this.translate.instant("schedule_preferences"),
        routerLink: [AppConstants.scheduleRoutePath],
        icon: "fal fa-file-invoice",
      });
    }

    if (this.authGuard.isAllowAccess(10) && this.encrDecSrvc.getObject(AppConstants.usersID) !== this.encrDecSrvc.getObject(AppConstants.resourceID)) {
      this.items.push({
        label: this.translate.instant("user_permissions"),
        routerLink: [AppConstants.permissionsRoutePath],
        icon: "fal fa-file-invoice-dollar",
      });
    }

    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = "";
    switch (url) {
      case "/users/rates":
        this.selectedMenuIndex = 1;
        this.selectedUrl = "users/rates";
        break;
      case "/users/education":
        this.selectedMenuIndex = 2;
        this.selectedUrl = "users/education";
        break;
      case "/users/Schedule":
        this.selectedMenuIndex = 3;
        this.selectedUrl = "users/Schedule";
        break;
      case "/users/permissions":
        this.selectedMenuIndex = 4;
        this.selectedUrl = "users/permissions";
        break;     
      default:
        this.selectedMenuIndex = 0;
        this.selectedUrl = "users/view";
    }
  }

}
