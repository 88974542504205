import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MangoApiService } from '@app/_services';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
    dark: boolean;
    public resetPasswordForm: UntypedFormGroup
    token: string = ''
    isEmailExists: boolean = false;

    constructor(
        private mangoAPISrvc: MangoApiService,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder
    ) {
        this.resetPasswordForm = this.fb.group({
            email: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
            password: [null, [Validators.required]],
            confirmPassword: [null],
        }, { validators: this.checkPassword })
    }

    checkPassword(group: UntypedFormGroup) {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirmPassword').value;
      
        return pass === confirmPass ? null : { passwordNotEqual: true }     
    }

    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.params.subscribe((params: Params) => {
            this.token = params['token'];
            if (this.token) {
                this.mangoAPISrvc.showLoader(true);

                this.mangoAPISrvc.verifyToken(this.token).subscribe((result: any) => {
                    this.mangoAPISrvc.showLoader(false);

                    if (!result) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Information!',
                            text: 'Invalid token supplied.You are not allowed to access this page.',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        return;
                    }
                    this.resetPasswordForm.controls['email'].setValue(result.email);

                    this.checkEmail();
                }, (err) => {
                    this.mangoAPISrvc.showLoader(false);
                    this.mangoAPISrvc.notify('error', 'Error', err.json().message);
                })
            }
        });
    }

    // verifing the Email
    checkEmail() {
        const self = this;

        self.mangoAPISrvc.showLoader(true);

        self.mangoAPISrvc.isUserExitsOrNot(self.resetPasswordForm.value.email).subscribe((data: any) => {
            self.mangoAPISrvc.showLoader(false);
            if (data["Email"] && data["Email"].length > 0) {
                self.isEmailExists = true;
            } else {
                self.mangoAPISrvc.notify('error', 'Error', 'Email not found. Please recheck your email.');
                self.isEmailExists = false;
            }

        }, (err) => {
            self.mangoAPISrvc.showLoader(false);
            self.mangoAPISrvc.notify('error', 'Error', 'Error while finding email in database.');
        });
    }
    // creating new password 
    reset() {
        const self = this;
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc.resetPassword(self.resetPasswordForm.value, this.token).subscribe((data: any) => {
            self.mangoAPISrvc.showLoader(true);
            self.mangoAPISrvc.notify("success", 'Success', "Password has been created. Now you will be redirected to home screen.")
            this._router.navigate(['/login']);
        }, (err) => {
            self.mangoAPISrvc.showLoader(false);
            self.mangoAPISrvc.notify("error", 'Error', "Error while updating password.");
        });
    }

    redirect(page) {
        switch (page) {
            case 'login':
                this._router.navigate(['/login']);
                break;
        }
    }

}
