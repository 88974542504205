import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { SuperDashboardRoutingModule } from './super-dashboard-routing.module';

import { AppTranslationModule } from '@shared-modules/app.translation.module';

// Components
import { SuperDashboardComponent } from './super-dashboard.component';
import { MBRevenueComponent } from './mb-revenue/mb-revenue.component'
import { ManageAccountsComponent } from './manage-accounts/manage-accounts.component';
import { CustomersComponent } from './customers/customers.component';
import { CpapayEnrollmentsComponent } from './cpapay-enrollments/cpapay-enrollments.component';
import { UsageMeteringComponent } from './usage-metering/usage-metering.component';

@NgModule({
  declarations: [SuperDashboardComponent, MBRevenueComponent, ManageAccountsComponent, CustomersComponent, CpapayEnrollmentsComponent, UsageMeteringComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    SuperDashboardRoutingModule,
    AppTranslationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SuperDashboardModule { }
