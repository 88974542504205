<form [formGroup]="myUserForm" autocomplete="nope">
    <div class="clientform-btn-top">
        <button pButton pRipple label="{{'save_button' | translate}}" icon="fal fa-check"
            [disabled]="!isFormValid || myUserForm.pristine" class="p-button-sm p-pr-2 p-mr-2"
            (click)="saveUserData(myUserForm.value)"></button>
        <p-button pRipple label="{{'new' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
            class="p-button-help p-pr-2" (click)="redirectToAddUser()"></p-button>
        <button pButton pRipple label="{{'user.Send-Mail-Invitation' | translate}}" icon="fal fa-envelope"
            [disabled]="myUserForm.controls.Email.invalid || !isEmailExits || StaffID == -1 || !StaffID"
            class="p-button-sm p-pr-2 p-mr-2" (click)="sendMail()"></button>
        <button pButton pRipple label="{{'user.user_list' | translate}}" icon="fal fa-user-friends" styleClass="p-button-sm"
            class="p-button-sm p-pr-2 p-mr-2" (click)="redirectToUserList()"></button>

    </div>
    <div class="p-fluid p-formgrid p-grid p-mx-2 common-dialog">
        <div class="p-col-4">
            <div class="card p-mt-1 p-mb-3">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{'user.user_initials' | translate}}
                            <i pTooltip="{{'user.user-initial-tooltip' | translate}}" tooltipStyleClass="tooltip-width-300"
                                class="fa fa-question-circle ic-blue question-tooltip-icon" tooltipPosition="right"></i>
                        </label>
                        <input type="text" pInputText maxlength="3" autocomplete="nope" formControlName="StaffNumber">
                        <small *ngIf="((myUserForm.get('StaffNumber').invalid && myUserForm.get('StaffNumber').touched) || myUserForm.get('StaffNumber').dirty) && myUserForm.get('StaffNumber').errors?.required" class="text-danger ic-red">User Initials is required</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{'user.name_profile' | translate}}</label>
                        <input type="text" pInputText autocomplete="nope" formControlName="StaffName">
                        <small *ngIf="((myUserForm.get('StaffName').invalid && myUserForm.get('StaffName').touched) || myUserForm.get('StaffName').dirty) && myUserForm.get('StaffName').errors?.required" class="text-danger ic-red">User Name is required</small>
                    </div>
                </div>

                <div class="p-field p-col-12">
                    <label class="label-text">{{'Street' | translate}} 1</label>
                    <input type="text" pInputText autocomplete="nope" formControlName="Street1">
                    <small *ngIf="((myUserForm.get('Street1').invalid && myUserForm.get('Street1').touched) || myUserForm.get('Street1').dirty) && myUserForm.get('Street1').hasError('minlength')" class="text-danger ic-red">
                        Street 1 should contain at least 5 characters.
                    </small>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{'Street' | translate}} 2</label>
                    <input type="text" pInputText autocomplete="nope" formControlName="Street2">
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{'client.city' | translate}}</label>
                    <input type="text" pInputText autocomplete="nope" formControlName="City">
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'client.state' | translate}}</label>
                        <input type="text" pInputText autocomplete="nope" maxlength="2" formControlName="State">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'client.zip' | translate}}</label>
                        <input type="text" pInputText class="p-text-right" formControlName="Zip" maxlength="10" autocomplete="nope">
                    </div>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{'user.Staff-ID' | translate}}</label>
                    <input type="text" pInputText autocomplete="nope" class="p-text-right" formControlName="StaffID" readonly>
                </div>
                <div class="p-p-field p-mb-2 p-col-12">
                    <div *ngIf="isTFAEnabled" class="p-d-flex p-jc-between">
                        <label class="label-text p-mt-2 ic-size-13">
                            <i class="fas fa-exclamation-circle p-mr-2"></i>
                            {{ 'users.TFA-enabled' | translate }}
                        </label>
                        <button pButton pRipple label="Disable TFA" icon="fal fa-times"
                            class="p-button-sm p-button-danger width-25p p-pr-2 p-mr-2" (click)="onDisableTFA()"></button>
                    </div>
                    <div class="p-pt-2 p-mt-2" *ngIf="!isTFAEnabled">
                        <label class="label-text p-mt-2 ic-size-13">
                            <i class="fas fa-exclamation-circle p-mr-2"></i>
                            {{ 'users.TFA-disabled' | translate }}</label>
                    </div>
                </div>
                <!--  end of card -->
            </div>
            <div class="card p-mt-2 p-mb-6">
                <h4 class="label-text p-pb-2">{{'user.Important-Dates' | translate}}</h4>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.hired' | translate}}</label>
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                            formControlName="DateHired" readonly></p-calendar>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">Terminated</label>
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                            formControlName="DateTerminated" readonly></p-calendar>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'client.birthday' | translate}}</label>
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                            formControlName="DateBirthday" readonly></p-calendar>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.anniversary' | translate}}</label>
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                            formControlName="DateAnniversary" readonly></p-calendar>
                    </div>
                </div>
                <!--  end of card -->
            </div>
        </div>
        <div class="p-col-4">
            <div class="card p-mt-1 p-mb-3">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.office' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999? x99999" autocomplete="nope" formControlName="TeleOffice">
                        </p-inputMask>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'mobile' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="TeleMobile">
                        </p-inputMask>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'home' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="TeleHome"></p-inputMask>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">
                            {{'email' | translate}}
                            <i *ngIf="emailExists" class="fas fa-exclamation-circle ic-red"
                                pTooltip="{{'user.email-no-longer-available' | translate}}" tooltipPosition="top"
                                tooltipStyleClass="tooltip-no-wrap"></i>
                        </label>
                        <input type="text" pInputText maxlength="80" autocomplete="nope" formControlName="Email"
                            (keyup)="changeText($event,myUserForm.get('Email'),'lower')">
                        <small *ngIf="((myUserForm.get('Email').invalid && myUserForm.get('Email').touched) || myUserForm.get('Email').dirty) && myUserForm.get('Email').errors?.required" class="text-danger ic-red">Email is required</small>
                        <small *ngIf="((myUserForm.get('Email').invalid && myUserForm.get('Email').touched) || myUserForm.get('Email').dirty) && myUserForm.get('Email').errors?.email" class="text-danger ic-red">Please provide a valid email address</small>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'password' | translate}}</label>
                        <input type="text" pInputText maxlength="80" autocomplete="nope"
                            formControlName="SystemPassword">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'Confirm-Password' | translate}}</label>
                        <input type="text" pInputText maxlength="80" autocomplete="nope"
                            formControlName="ConfirmPassword">
                    </div>
                    <div *ngIf="StaffID && StaffID != -1 && !createMode" class="p-col-12 ic-red ic-size-10">
                        <i class="fas fa-exclamation-circle p-mr-2"></i>
                        <label>{{ 'password_hidden' | translate }}</label>
                    </div>
                </div>
                <!--  end of card -->
            </div>

            <div class="card p-mt-2 p-mb-3">
                <h4 class="label-text p-pb-2">{{'user.target_billable_hrs' | translate}}</h4>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.annually' | translate}}</label>
                        <input type="text" pInputText maxlength="4" class="p-text-right" autocomplete="nope"
                            formControlName="TargetYearHours">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.monthly' | translate}}</label>
                        <input type="text" pInputText class="p-text-right" autocomplete="nope"
                            formControlName="TargetMonthHours">
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.weekly' | translate}}</label>
                        <input type="text" pInputText  class="p-text-right" autocomplete="nope"
                            formControlName="TargetWeekHours">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.daily' | translate}}</label>
                        <input type="text" pInputText class="p-text-right" autocomplete="nope"
                            formControlName="TargetDayHours">
                    </div>
                </div>
            </div>

            <div class="card p-mt-2">
                <h4 class="label-text p-pb-2">{{'Emergency-Contact' | translate}}</h4>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-12">
                        <label class="label-text">{{'user.Name' | translate}}</label>
                        <input type="text" pInputText maxlength="80" autocomplete="nope"
                            formControlName="EmergencyName">
                        <small *ngIf="((myUserForm.get('EmergencyName').invalid && myUserForm.get('EmergencyName').touched) || myUserForm.get('EmergencyName').dirty) && myUserForm.get('EmergencyName').hasError('minlength')" class="text-danger ic-red">
                            Name should contain at least 3 characters.
                        </small>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'user.relation' | translate}}</label>
                        <input type="text" pInputText maxlength="80" autocomplete="nope"
                            formControlName="EmergencyRelation">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'Phone-Number' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="EmergencyPhone1">
                        </p-inputMask>
                    </div>
                </div>
                <!--  end of card -->
            </div>
            <!--  end of card -->
        </div>
        <div class="p-col-4">
            <div class="card p-mb-3 p-mt-1">
                <div class="p-formgroup-inline">
                    <div class="p-col-6">
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="{{'inactive' | translate}}" formControlName="Inactive"
                            class="label-text"></p-checkbox>
                    </div>
                    <div class="p-col-6">
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="{{'user.calendar_resource' | translate}}"
                            formControlName="BitAddCalendarResource" class="label-text"></p-checkbox>
                    </div>
                </div>

                <div class="p-formgroup-inline p-mt-5">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'title' | translate}}</label>
                        <p-dropdown [options]="titleDataSet" scrollHeight="400px" formControlName="StaffTitleID">
                        </p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'department' | translate}}</label>
                        <p-dropdown [options]="deptDataSet" formControlName="StaffDeptID"></p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'manager' | translate}}</label>
                        <p-dropdown [options]="managerDataSet" formControlName="ManagerID"></p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{'Select-Language' | translate}}</label>
                        <p-dropdown [options]="selectLanguages" formControlName="Language"
                            [disabled]="loggedUserId != StaffID"></p-dropdown>
                    </div>
                </div>
                <!--  end of card -->
            </div>
            <div class="card p-mt-2">
                <div class="p-field p-col-12">
                    <h4 class="label-text p-pb-2">{{'profile_picture' | translate}}</h4>
                </div>
                <div class="p-col-12 timeline-images">
                    <div class="p-grid p-my-5">
                        <div class="p-col-12 p-text-center p-mb-3">
                            <img *ngIf="isImageUrl" class="user-img-app"
                                [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"
                                onError="this.src='assets/img/userForTable.png'" />
                            <i *ngIf="!isImageUrl" class="fal fa-user-circle ic-size-70"></i>
                        </div>
                        <div class="p-col-9 p-text-left p-mt-4">
                            <p-fileUpload #fileUpload mode="basic" accept="image/*" [auto]="true" maxFileSize="1000000"
                                (onSelect)="fileEvent($event);" chooseLabel="{{'Upload' | translate}}"></p-fileUpload>
                        </div>
                        <div class="p-col-3 p-text-right p-mt-4">
                            <p-button pRipple label="{{'Remove' | translate}}" icon="fal fa-trash-alt"
                                styleClass="p-button-sm p-button-danger" tooltipPosition="top"
                                pTooltip="{{'Remove' | translate}}" (click)="removeImage()"></p-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
