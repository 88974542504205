<div class="list">
    <div class="layout-content p-pt-0">
        <div class="p-formgrid">
            <div class="p-col-12 p-pl-3 p-d-flex">
                <div class="p-col-2 p-pt-0 p-mr-0 p-px-0">
                    <div class="card p-pr-0 p-pl-3">
                        <p-megaMenu [model]="items" orientation="vertical"></p-megaMenu>
                    </div>
                </div>

                <div class="p-col-10 p-pt-0 p-mr-0 p-pr-2">
                    <!-- Client Note Categories -->
                    <div class="card p-pt-0" *ngIf="addIndex == 1">
                        <div class="p-col-12 label-text ic-size-18">{{'settings.Client-Note-Categories' | translate}}
                        </div>
                        <p-table [value]="noteList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="NoteCategoryID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm mo"
                            sortField="NoteCategory" [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'"
                            [globalFilterFields]="['NoteCategory']" [sortOrder]="1" #dt>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchNoteGB pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter(1)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(1);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="noteInactive" label="{{'show_inactive' | translate}}"
                                            binary="true" (onChange)="onStatusChange(1)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="NoteCategory">{{'settings.Note-Categories' |
                                        translate}}
                                        <p-sortIcon field="NoteCategory"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'Color' | translate}}
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 1)" pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top" ></i>
                                            <i pRipple (click)="delete(rowData.NoteCategoryID, 1);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.NoteCategory}}
                                    </td>
                                    <td class="width-10p p-text-center p-py-0">
                                        <p-colorPicker [(ngModel)]="rowData.NoteColor" [disabled]="true" appendTo="body"></p-colorPicker>
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateNote(rowData)"></p-checkbox>
                                    </td>
                                    
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Client Contact Types -->
                    <div class="card p-pt-0" *ngIf="addIndex == 2">
                        <div class="p-col-12 label-text ic-size-18">{{'settings.Client-Contact-Types' | translate}}
                        </div>
                        <p-table [value]="contactTypeList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="CustomerContactTypeID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="ContactType" [globalFilterFields]="['ContactType']" [sortOrder]="1"
                            [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #dtcontact>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchContactGB pInputText type="text"
                                            (input)="dtcontact.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dtcontact.reset();clearSearchFilter(2)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(2);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="contactTypeInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(2)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="ContactType">{{'settings.Contact-Types' |
                                        translate}}
                                        <p-sortIcon field="ContactType"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 2)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.CustomerContactTypeID, 2);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.ContactType}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateContactType(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Client Types -->
                    <div class="card p-pt-0" *ngIf="addIndex == 3">
                        <div class="p-col-12 label-text ic-size-18">{{'Client_Types' | translate}}</div>
                        <p-table [value]="customerTypeList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="CustomerTypeID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="CustomerTypeDescription" [globalFilterFields]="['CustomerTypeDescription']"
                            [sortOrder]="1" [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #typelist>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #customerTypesGB pInputText type="text"
                                            (input)="typelist.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="typelist.reset();clearSearchFilter(3)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(3);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="customerTypeInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(3)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="CustomerTypeDescription">
                                        {{'settings.Customer-Types' | translate}}
                                        <p-sortIcon field="CustomerTypeDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 3)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.CustomerTypeID, 3);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.CustomerTypeDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateCustomerType(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Staff Titles -->
                    <div class="card p-pt-0" *ngIf="addIndex == 4">
                        <div class="p-col-12 label-text ic-size-18">{{'settings.Staff-Titles' | translate}}</div>
                        <p-table [value]="staffList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="StaffTitleID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="StaffTitleDescription" [globalFilterFields]="['StaffTitleDescription']"
                            [sortOrder]="1" [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #staffsList>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #staffTitlesGB pInputText type="text"
                                            (input)="staffsList.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="staffsList.reset();clearSearchFilter(4)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(4);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="staffInactive" label="{{'show_inactive' | translate}}"
                                            binary="true" (onChange)="onStatusChange(4)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="StaffTitleDescription">
                                        {{'settings.Staff-Titles' | translate}}
                                        <p-sortIcon field="StaffTitleDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 4)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.StaffTitleID, 4);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.StaffTitleDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateStaff(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Client Terms -->
                    <div class="card p-pt-0" *ngIf="addIndex == 5">
                        <div class="p-col-12 label-text ic-size-18">{{ 'client_terms' | translate }}</div>
                        <p-table [value]="termList" [rowHover]="true" [responsive]="true" [rows]="10" dataKey="TermsID"
                            selectionMode="single" [showCurrentPageReport]="true" [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="TermsDescription" [globalFilterFields]="['GraceDays','TermsDescription']"
                            [sortOrder]="1" [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #custmorTerms>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #termsGB pInputText type="text"
                                            (input)="custmorTerms.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="custmorTerms.reset();clearSearchFilter(5)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(5);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="termInactive" label="{{'show_inactive' | translate}}"
                                            binary="true" (onChange)="onStatusChange(5)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-45p" pSortableColumn="TermsDescription">Customer Terms
                                        <p-sortIcon field="TermsDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-15p p-text-center" pSortableColumn="GraceDays">Grace Days
                                        <p-sortIcon field="GraceDays"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 5)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.TermsID, 5);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-45p p-text-left">
                                        {{rowData.TermsDescription}}
                                    </td>
                                    <td class="width-15p p-text-center">
                                        {{rowData.GraceDays}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateTerm(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Staff Department -->
                    <div class="card p-pt-0" *ngIf="addIndex == 6">
                        <div class="p-col-12 label-text ic-size-18">{{'settings.Staff-Department' | translate}}</div>
                        <p-table [value]="staffDeptList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="StaffDeptID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="DeptDescription" [globalFilterFields]="['DeptDescription']" [sortOrder]="1"
                            [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #staffDept>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #staffDeptGB pInputText type="text"
                                            (input)="staffDept.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="staffDept.reset();clearSearchFilter(6)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(6);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="staffDeptInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(6)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="DeptDescription">{{'settings.Staff-Titles' |
                                        translate}}
                                        <p-sortIcon field="DeptDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 6)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.StaffDeptID, 6);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.DeptDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateStaffDept(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Client Groups -->
                    <div class="card p-pt-0" *ngIf="addIndex == 7">
                        <div class="p-col-12 label-text ic-size-18">{{'list.groups' | translate}}</div>
                        <p-table [value]="groupList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="CustomerGroupCategoryID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [globalFilterFields]="['GroupDescription']" [sortOrder]="1"
                            [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #customerGroups>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #customerGroupsGB pInputText type="text"
                                            (input)="customerGroups.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="customerGroups.reset();clearSearchFilter(7)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(7);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="groupInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(7)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="GroupDescription">
                                        {{'settings.Customer-Groups' | translate}}
                                        <p-sortIcon field="GroupDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 7)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.CustomerGroupCategoryID, 7);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.GroupDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateGroup(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Recurring Invoice Groups -->
                    <div class="card p-pt-0" *ngIf="addIndex == 8">
                        <div class="p-col-12 label-text ic-size-18">{{'settings.Recurring-Invoice-Groups' | translate}}
                        </div>
                        <p-table [value]="invoiceGroupList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="RecurringInvoiceGroupID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="RecurringGroupDescription" [globalFilterFields]="['RecurringGroupDescription']"
                            [sortOrder]="1" [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #customerGroupslist>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #invoiceGroupsGB pInputText type="text"
                                            (input)="customerGroupslist.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="customerGroupslist.reset();clearSearchFilter(8)"
                                            tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(8);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="invoiceGroupInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(8)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="RecurringGroupDescription">
                                        {{'settings.Recurring-Invoice-Groups' | translate}}
                                        <p-sortIcon field="RecurringGroupDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 8)" pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top" ></i>
                                            <i pRipple (click)="delete(rowData.RecurringInvoiceGroupID, 8);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.RecurringGroupDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateInvoiceGroup(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Engagement Types -->
                    <div class="card p-pt-0" *ngIf="addIndex == 9">
                        <div class="p-col-12 label-text ic-size-18">{{'Engagement-Types' | translate}}</div>
                        <p-table [value]="engagementsTypeList" [rowHover]="true" [responsive]="true"
                            dataKey="EngagementTypeID" selectionMode="single" [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="Description" [globalFilterFields]="['Description']" [sortOrder]="1"
                            [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #engagementlist>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #engagementGB pInputText type="text"
                                            (input)="engagementlist.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="engagementlist.reset();clearSearchFilter(9)" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(9);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="engagementsInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(9)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-25p" pSortableColumn="Description">
                                        {{'settings.Engagement-Type-Description' | translate}}
                                        <p-sortIcon field="Description"></p-sortIcon>
                                    </th>
                                    <th class="width-50p">{{'settings.Default-Invoice-Description' | translate}}
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 9)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.EngagementTypeID, 9);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-25p p-text-left">
                                        {{rowData.Description}}
                                    </td>
                                    <td class="width-50p p-text-left">
                                        {{rowData.InvoiceDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateEngagement(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Work Locations -->
                    <div class="card p-pt-0" *ngIf="addIndex == 10">
                        <div class="p-col-12 label-text ic-size-18">{{'Work-Locations' | translate}}</div>
                        <p-table [value]="workLocationsList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="WorkLocationID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="ZipCode" [globalFilterFields]="['ZipCode']" [sortOrder]="1" [scrollable]="true"
                            [scrollHeight]="'calc(50vh - 100px)'" #workLocationList>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue10 pInputText type="text"
                                            (input)="workLocationList.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="workLocationList.reset();clearSearchFilter(10)"
                                            tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(10);"></p-button>
                                    </span>
                                    <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="workLocationInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(10)">
                                        </p-checkbox>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-40p" pSortableColumn="ZipCode">{{'Work-Location' | translate}}
                                        <p-sortIcon field="ZipCode"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'inactive' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class=" width-10p p-text-center">
                                        <div>
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 10)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.WorkLocationID, 10);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-40p p-text-left">
                                        {{rowData.ZipCode}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                            (onChange)="updateWorkLocations(rowData)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- Company Payment Methods -->
                    <div class="card p-pt-0" *ngIf="addIndex == 11">
                        <div class="p-col-12 label-text ic-size-18">{{'settings.Company-Payment-Methods' | translate}}</div>
                        <p-table [value]="paymentMethodsList" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="CompanyPaymentMethodID" selectionMode="single" [showCurrentPageReport]="true"
                            [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [globalFilterFields]="['PaymentDescription']"  [scrollable]="true"
                            [scrollHeight]="'calc(50vh - 100px)'" #paymentMethodList>
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue11 pInputText type="text"
                                            (input)="paymentMethodList.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="paymentMethodsList.reset();clearSearchFilter(10)"
                                            tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus"
                                            styleClass="p-button-sm" class="p-button-help p-pr-2"
                                            (click)="openAddWindow(11);"></p-button>
                                    </span>
                                   <!--  <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                        <p-checkbox [(ngModel)]="workLocationInactive"
                                            label="{{'show_inactive' | translate}}" binary="true"
                                            (onChange)="onStatusChange(10)">
                                        </p-checkbox>
                                    </span> -->
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                    <th class="width-55p" pSortableColumn="PaymentDescription">{{'company_payment-description' | translate}}
                                        <p-sortIcon field="PaymentDescription"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'company_payment-required' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-10p p-text-center" >
                                        <div *ngIf="isRequiredPayment(rowData)">
                                            <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                (click)="openEditWindow(rowData, 11)"  pTooltip="{{'Edit' | translate}}"
                                                tooltipPosition="top"></i>
                                            <i pRipple (click)="delete(rowData.CompanyPaymentMethodID, 11);"
                                                class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                                pTooltip="{{'mgmt-delete' | translate}}"  tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-55p p-text-left">
                                        {{rowData.PaymentDescription}}
                                    </td>
                                    <td class="width-10p p-text-center">
                                        <span *ngIf="!isRequiredPayment(rowData)"><i
                                            class="fal fa-check ic-green cursor-hand" style="padding-left:8px;"
                                             ></i></span>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog header="{{(obj.isEdit ? 'Edit' : 'add') | translate}} {{obj.title}}" [(visible)]="displayAddModal"
    [modal]="true" [style]="{width: '37vw'}" [maximizable]="false" showEffect="fade" [contentStyle]="{'overflow':'visible'}"
    [draggable]="true" [resizable]="false" (onHide)="listMainForm.reset();">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <form #listMainForm="ngForm" class="p-textarea-width">
            <div class="p-col-12">
                <div class="card p-m-0 p-p-0">
                    <div class="p-formgroup-inline" *ngIf="addIndex == 10">
                        <div class="p-field p-pt-2 p-col-4">
                            <label for="{{obj.lable}}" class="label-text required-field">{{obj.lable}}</label>
                        </div>
                        <div class="p-field p-col-4">
                            <input type="text" pInputText pKeyFilter="num" class="p-text-right" [(ngModel)]="obj.value" name="value" id="value" #listName1="ngModel" required maxlength="10" autocomplete="nope">
                            <!-- <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                [(ngModel)]="obj.value" name="value" id="value" #listName1="ngModel"
                                [useGrouping]="false" locale="en-US" [minFractionDigits]="0" required></p-inputNumber> -->
                        </div>
                    </div>
                    <div class="p-formgroup-inline" *ngIf="addIndex != 10">
                        <div class="p-field p-pt-2 p-col-4" *ngIf="addIndex != 9">
                            <label for="{{obj.lable}}" class="label-text required-field">{{obj.lable}}</label>
                        </div>
                        <div class="p-field p-pt-2 p-col-4" *ngIf="addIndex == 9">
                            <label for="{{obj.lable}}" class="label-text required-field">{{obj.lable}}</label>
                        </div>
                        <!--  -->
                        <div class="p-field p-col-6">
                            <input pInputText type="text" [(ngModel)]="obj.value" name="value" id="value"
                                #listName="ngModel" minlength="2" maxlength="{{obj.maxlength}}" required>
                        </div>
                    </div>
                    <div class="p-formgroup-inline" *ngIf="addIndex == 5">
                        <div class="p-field p-pt-2 p-col-4">
                            <label for="{{obj.lable1}}" class="label-text required-field">{{obj.lable1}}</label>
                        </div>
                        <div class="p-field p-col-6">
                            <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                [(ngModel)]="obj.value1" name="value1" id="value1" #listName1="ngModel" mode="decimal"
                                locale="en-US" required></p-inputNumber>
                        </div>
                    </div>
                    <div class="p-formgroup-inline" *ngIf="addIndex == 9">
                        <div class="p-field p-pt-2 p-col-4">
                            <label for="New Invoice Description"
                                class="label-text">{{'billing-invoicing.invoice-description' | translate}}</label>
                        </div>
                        <div class="p-field p-col-8">
                            <app-mentions [(inputText)]="obj.customlableValue" [rows]="3" id="privatememo" (inputTextChange)="replaceCaret(selectedRowData, 'Memo');"
                                style="width:100% !important; resize: vertical;"></app-mentions>
                        </div>
                    </div>
                    <div class="p-formgroup-inline" *ngIf="obj.isEdit && addIndex != 11">
                        <div class="p-field p-pt-2 p-col-4" *ngIf="addIndex != 9">
                            <label for="Active" class="label-text">{{'inactive' | translate}}</label>
                        </div>
                        <div class="p-field p-pt-2 p-col-4" *ngIf="addIndex == 9">
                            <label for="Active" class="label-text">{{'inactive' | translate}}</label>
                        </div>
                        <div class="p-field p-col-6 p-mt-2">
                            <p-checkbox [(ngModel)]="obj.Inactive" binary="true"
                                (onChange)="listMainForm.control.markAsDirty()" name="inactive" id="inactive"
                                #inactive="ngModel"></p-checkbox>
                        </div>
                    </div>
                    <div class="p-formgroup-inline" *ngIf="addIndex == 1">
                        <div class="p-field p-pt-2 p-col-4">
                            <label for="Active" class="label-text">{{'Color' | translate}}</label>
                        </div>
                        <div class="p-field p-col-1 p-mt-2" style="width: 45px;height: 45px;">
                            <p-colorPicker [(ngModel)]="obj.NoteColor" appendTo="body"></p-colorPicker>
                        </div>
                    </div>
                    <!-- <div class="p-formgroup-inline" *ngIf="addIndex == 11">
                        <div class="p-field p-pt-2 p-col-4">
                            <label for="Active" class="label-text">{{'company_payment-required' | translate}}</label>
                        </div>
                        <div class="p-field p-col-1 p-mt-2" style="width: 45px;height: 45px;">
                            <p-checkbox [(ngModel)]="obj.Inactive" binary="true"
                                (onChange)="listMainForm.control.markAsDirty()" name="inactive" id="inactive"
                                #inactive="ngModel"></p-checkbox>
                        </div>
                    </div> -->
                </div>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!listMainForm.valid || !listMainForm.dirty" (click)="save(true,true)" *ngIf="!obj.isEdit"
            label="{{'save_and_close' | translate}}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!listMainForm.valid || !listMainForm.dirty" (click)="save(!obj.isEdit,false)"
            label="{{'save_and_new' | translate}}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!listMainForm.valid || !listMainForm.dirty" (click)="save(false,true)" *ngIf="obj.isEdit"
            label="{{'save_and_close' | translate}}" ></button>
        <button type="button" pButton pRipple icon="fal fa-times" label="{{'cancel_button' | translate}}"
             (click)="close()"></button>
    </ng-template>
</p-dialog>