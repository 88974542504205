<p-sidebar *ngIf="clientNoteView" [(visible)]="isNotesSideBarShow" position="right" (onHide)="closeSeeMore()"
    [style]="{'min-width':'40%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <div class="timeline-sidebar">
            <p-card>
                <ng-template pTemplate="title">
                    <span style="font-size: 20px;">
                        {{ clientNoteView.Subject }}
                    </span>
                </ng-template>
                <ng-template pTemplate="subtitle">
                    <div style="text-align: left;">
                        <span *ngIf="clientNoteView.DdateTimestamp" class="timeline-property-container p-mr-2">
                            {{ clientNoteView.DdateTimestamp }}
                        </span>
                        <span *ngIf="clientNoteView.NoteCategory" class="timeline-property-container p-mr-2">
                            {{ clientNoteView.NoteCategory }}
                        </span>
                        <span *ngIf="clientNoteView.EngagementName" class="timeline-property-container p-mr-2">
                            {{ clientNoteView.EngagementName }}
                        </span>
                        <span *ngIf="clientNoteView.StaffName" class="timeline-property-container p-mr-2">
                            <i class="pi pi-user"></i>
                            {{ clientNoteView.StaffName }}
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="clientNoteView.DescriptionClean !== ''" [innerHTML]="clientNoteView.Description" style="font-weight: 200;"></div>
                </ng-template>
            </p-card>
        </div>
</p-sidebar>
<div class="client-notes">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" class="p-button-sm p-mr-2" (click)="addNoteModal();"></button>
            <button pButton pRipple label="{{ (isTableView ? 'timeline_view': 'table_view') | translate }}" [disabled]="noteTableDataSource.length == 0" class="p-button-sm p-mr-2" icon="fal fa-list" (click)="toggleView()">
            </button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-mr-3" *ngIf="!isTableView" style="overflow-y: scroll; max-height: calc(80vh - 50px); background-color: #f8f8fc;">
                    <p-timeline [value]="noteTableDataSource" align="alternate" styleClass="customized-timeline">
                        <ng-template pTemplate="marker" let-event>
                            <span class="custom-marker p-shadow-2" [style.backgroundColor]="event.NoteColor" 
                                style="border-radius: 30px; height: 30px; width: 30px; box-shadow: 2px 2px 5px #7b7b7b; text-align: center; padding: 7.3px;">
                                <i class="fal fa-sticky-note ic-size-15" [ngClass]="event.isLight? 'ic-black' : 'ic-white'"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-event>
                            <p-card class="timeline-card">
                                    <ng-template pTemplate="title">
                                        <span style="font-size: 20px;">
                                            {{ event.Subject }}
                                        </span>
                                        <span [ngClass]="event.index % 2 == 0? 'pull-right' : 'pull-left'">
                                            <i *ngIf="event.documentcount > 0" class="fal fa-paperclip p-mr-3 cursor-hand  ic-blue ic-size-15"
                                                    (click)="showAttachedFiles(event)" tooltipPosition="top" pTooltip="{{ 'Attachments' | translate }}"></i>
                                            <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green ic-size-15"
                                                (click)="editNote(event)" tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                            <i (click)="deleteNote(event,event.CustomerNotesID)"
                                                class="fal fa-trash-alt ic-red cursor-hand p-pl-2 ic-size-15" tooltipPosition="top"
                                                pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="subtitle">
                                        <div [ngStyle]="{'text-align': (event.index % 2 == 0)? 'left' : 'right'}">
                                            <span *ngIf="event.DdateTimestamp" class="timeline-property-container p-mr-2">
                                                {{ event.DdateTimestamp }}
                                            </span>
                                            <span *ngIf="event.NoteCategory" class="timeline-property-container p-mr-2" [ngStyle]="{'background-color': event.NoteColor, 'color': (event.isLight? 'black' : 'white')}">
                                                {{ event.NoteCategory }}
                                            </span>
                                            <span *ngIf="event.EngagementName" class="timeline-property-container p-mr-2">
                                                {{ event.EngagementName }}
                                            </span>
                                            <span *ngIf="event.StaffName" class="timeline-property-container p-mr-2">
                                                <i class="pi pi-user"></i>
                                                {{ event.StaffName }}
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div *ngIf="event.DescriptionClean && event.DescriptionClean !== ''" [innerHTML]="event.Description" class="html-content-truncate html-content"></div>
                                        <div *ngIf="!event.DescriptionClean || event.DescriptionClean === ''" style="font-weight: 200;"><i> No Description </i></div>
                                        <button pButton pRipple type="button" label="See more" class="p-button-outlined p-mt-3 see-more-button" 
                                            *ngIf="event.overflow" (click)="seeMore(event)"></button>
                                    </ng-template>
                            </p-card>
                        </ng-template>
                    </p-timeline>
                </div>
                <div class="card p-ml-3 p-mr-3 p-pt-0" *ngIf="isTableView">
                    <div>
                        <p-table #dt [value]="tableDataSorce" dataKey="CustomerNotesID" [responsive]="true" [rows]="10"
                            rowExpandMode="single" [rowHover]="true" sortField="Ddate" [sortOrder]="-1"
                            (sortFunction)="customSort($event)" [customSort]="true" rowExpandMode="single"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" [filterDelay]="0"
                            (onFilter)="onFilter($event)" (onRowExpand)="onRowExpandEvent($event)"
                            [globalFilterFields]="globalFilterColumns">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{ 'globalsearch' | translate }}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="to"
                                            pTooltip="{{ 'clear' | translate }}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-3p p-text-center"></th>
                                    <th class="width-6p p-text-center">{{ 'actions' | translate}}</th>
                                    <th class="width-8p p-text-center" pSortableColumn="displayDate">
                                        {{ 'date' | translate }}
                                        <p-sortIcon field="displayDate"></p-sortIcon>
                                    </th>
                                    <th class="width-15p p-text-left" pSortableColumn="EngagementName">
                                        {{ 'engagement' | translate }}
                                        <p-sortIcon field="EngagementName"></p-sortIcon>
                                    </th>
                                    <th class="width-15p p-text-left" pSortableColumn="NoteCategory">
                                        {{ 'client.note_category' | translate }}
                                        <p-sortIcon field="NoteCategory"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-center">{{'Color' | translate}}
                                    </th>
                                    <th class="width-20p p-text-left" pSortableColumn="Subject">
                                        {{ 'Subject' | translate }}
                                        <p-sortIcon field="Subject"></p-sortIcon>
                                    </th>
                                    <th class="width-12p p-text-left" pSortableColumn="StaffName">
                                        {{ 'client.note_originator' | translate }}
                                        <p-sortIcon field="StaffName"></p-sortIcon>
                                    </th>
                                    <th class="width-8p p-text-center">{{ 'last_modified' | translate }}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-expanded="expanded">
                                <tr class="cursor-hand">
                                    <td class="width-3p">
                                        <span [pRowToggler]="rowData" *ngIf="rowData.documentcount > 0">
                                            <i pTooltip="{{ 'time.Show-Client-Documents' | translate }}" pRipple tooltipPosition="right"
                                                showDelay="900"
                                                [ngClass]="expanded && rowData.childItem.length > 0 ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                                            </i>
                                        </span>
                                    </td>
                                    <td class="width-6p">
                                        <div class="p-text-center">
                                            <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                                                (click)="editNote(rowData)" tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                            <i (click)="deleteNote(rowData,rowData.CustomerNotesID)"
                                                class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                                                pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                        </div>
                                    </td>
                                    <td (click)="editNote(rowData);" class="width-8p p-text-center">
                                        {{rowData.displayDate | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td (click)="editNote(rowData);" class="width-15p p-text-left">
                                        {{rowData.EngagementName}}</td>
                                    <td (click)="editNote(rowData);" class="width-15p p-text-left">
                                            {{rowData.NoteCategory}}
                                    </td>
                                    <td (click)="editNote(rowData);" class="width-10p p-text-center p-py-0">
                                        <p-colorPicker [(ngModel)]="rowData.NoteColor" [disabled]="true" appendTo="body"></p-colorPicker>
                                    </td>
                                    <td (click)="editNote(rowData);" class="width-20p p-text-left">{{rowData.Subject}}</td>
                                    <td (click)="editNote(rowData);" class="width-12p p-text-left">{{rowData.StaffName}}
                                    </td>
                                    <td (click)="editNote(rowData);" class="width-8p p-text-center">
                                        {{rowData.LastModifiedDate | date:'MM/dd/yyyy' : 'UTC'}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="rowexpansion" let-rowData>
                                <tr *ngIf="rowData.hasChildren">
                                    <td></td>
                                    <td [attr.colspan]="7">
                                        <div class="p-p-2 bg-blue">
                                            <p-table [value]="rowData.childItem" dataKey="dmsParentID"
                                                [rowsPerPageOptions]="[5,10,20]" [rowHover]="true" [rows]="5"
                                                [paginator]="rowData.childItem.length > 10" [paginatorPosition]="'top'"
                                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th class="width-12p p-text-center">
                                                            {{ 'actions' | translate }}
                                                        </th>
                                                        <th class="p-text-left">
                                                            {{ 'File' | translate }}
                                                        </th>
                                                        <th class="p-text-left">
                                                            {{ 'time.type' | translate }}
                                                        </th>
                                                        <th class="p-text-left">
                                                            {{ 'Size' | translate }}
                                                        </th>
                                                        <th class="p-text-left">
                                                            {{ 'Uploaded-By' | translate }}
                                                        </th>
                                                        <th class="p-text-left">
                                                            {{ 'Created-On' | translate }}
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-childData>
                                                    <tr class="cursor-hand">
                                                        <td>
                                                            <div class="p-text-center">
                                                                <i class="fal fa-pencil cursor-hand  ic-green" (click)="editRow(childData)"
                                                                    tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                                                <i (click)="deleteDocRow(childData,rowData)"
                                                                    class="fal fa-trash-alt ic-red cursor-hand p-mx-3" tooltipPosition="top" pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                                                <i (click)="previewRow(childData)" class="fal fa-file-pdf ic-orange cursor-hand"
                                                                    tooltipPosition="top" pTooltip="{{ 'preview' | translate }}"></i>
                                                            </div>
                                                        </td>
                                                        <td class="p-text-left">
                                                            <span class="cursor-hand ic-red"
                                                                (click)="previewRow(childData)">
                                                                <u>{{childData.FName}}</u>
                                                            </span>
                                                        </td>
                                                        <td class="p-text-left">
                                                            {{childData.FileType}}
                                                        </td>
                                                        <td class="p-text-left">
                                                            {{childData.Size}}
                                                        </td>
                                                        <td class="p-text-left">
                                                            {{childData['ModifyBy']}}
                                                        </td>
                                                        <td class="p-text-left">
                                                            {{childData['CreatedOn'] | date:'MM/dd/yyyy' : 'UTC'}}
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">
                                        <p-message severity="info" text="{{ 'data_not_found' | translate }}" styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-sidebar *ngIf="noteData != null" [(visible)]="viewAttachedFiles" position="bottom" [style]="{'min-height':'522px','background':'#8e8f92'}" (onHide)="onAttachedFilesClose()">
        <div class="card">
            <div class="control-label header-color text-nowrap p-col-12">
                <h4 class="label-text">{{ 'Attachments' | translate }}</h4>
            </div>
            <div  class="card" style="height: calc(45vh - 50px); overflow: auto;">
                <p-table [value]="noteData.childItem" dataKey="dmsParentID"
                    [rowsPerPageOptions]="[5,10,20]" [rowHover]="true" [rows]="5"
                    [paginator]="noteData.childItem.length > 10" [paginatorPosition]="'top'"
                    [scrollHeight]="'calc(50vh - 50px)'"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-12p p-text-center">
                                {{ 'actions' | translate }}
                            </th>
                            <th class="p-text-left">
                                {{ 'File' | translate }}
                            </th>
                            <th class="p-text-left">
                                {{ 'time.type' | translate }}
                            </th>
                            <th class="p-text-left">
                                {{ 'Size' | translate }}
                            </th>
                            <th class="p-text-left">
                                {{ 'Uploaded-By' | translate }}
                            </th>
                            <th class="p-text-left">
                                {{ 'Created-On' | translate }}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-childData>
                        <tr class="cursor-hand">
                            <td>
                                <div class="p-text-center">
                                    <i class="fal fa-pencil cursor-hand  ic-green" (click)="editRow(childData)"
                                        tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                    <i (click)="deleteDocRow(childData,noteData)"
                                        class="fal fa-trash-alt ic-red cursor-hand p-mx-3" tooltipPosition="top" pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                    <i (click)="previewRow(childData)" class="fal fa-file-pdf ic-orange cursor-hand"
                                        tooltipPosition="top" pTooltip="{{ 'preview' | translate }}"></i>
                                </div>
                            </td>
                            <td class="p-text-left">
                                <span class="cursor-hand ic-red"
                                    (click)="previewRow(childData)">
                                    <u>{{childData.FName}}</u>
                                </span>
                            </td>
                            <td class="p-text-left">
                                {{childData.FileType}}
                            </td>
                            <td class="p-text-left">
                                {{childData.Size}}
                            </td>
                            <td class="p-text-left">
                                {{childData['ModifyBy']}}
                            </td>
                            <td class="p-text-left">
                                {{childData['CreatedOn'] | date:'MM/dd/yyyy' : 'UTC'}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-sidebar>

<p-dialog header="File Name" [(visible)]="isShowfileName" [modal]="true" [style]="{width: '35vw'}" [maximizable]="false"
    showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="isShowfileName=false;fileName='';selectedObj = null;">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <label for="subject" class="required-field p-mr-5 p-col-3">{{ 'file_name' | translate }}</label>
                <input type="text" pInputText class="required-field p-mr-5 p-col-7" [(ngModel)]="fileName"
                    maxlength="40" autocomplete="nope" autocomplete="off">
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="fal fa-check" [disabled]="!fileName" (click)="saveFileName()"
            label="{{ 'save_button' | translate }}"></button>
        <button type="button" pButton icon="fal fa-times" (click)="isShowfileName=false;fileName='';selectedObj = null;"
            label="{{ 'close' | translate }}"></button>
    </ng-template>
</p-dialog>

<p-sidebar [(visible)]="isFilePreview" [position]="'right'" [style]="{width:'60%'}" [dismissible]="true"
    [showCloseIcon]="true" (onHide)="closePreviewDialog()" [closeOnEscape]="false">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div #dummyViewer class="dummyViewer"></div>
                <div #preiewviewer class="preiewviewer"
                    [ngStyle]="{'visibility':isPreviewViewerShow? 'visible' : 'hidden' }"></div>
            </div>
        </div>
    </div>
</p-sidebar>