import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageRolesComponent } from './manage-roles.component';
import { AppSharedModule, AppTranslationModule } from '@app/shared/modules';



@NgModule({
  declarations: [
    ManageRolesComponent
  ],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule
  ],
  exports: [
    ManageRolesComponent
  ]
})
export class ManageRolesModule { }
