import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DialogEditEventArgs, SelectionSettingsModel, GridComponent, RowSelectEventArgs, RowDragEventArgs, SaveEventArgs } from '@syncfusion/ej2-angular-grids';
import { EditService, TreeGridComponent, PageService, FilterService, ResizeService, FreezeService } from '@syncfusion/ej2-angular-treegrid';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { CheckBoxChangeEventArgs, QueryCellInfoEventArgs } from '@syncfusion/ej2-grids';
import { MenuEventArgs } from '@syncfusion/ej2-navigations';
import { BeforeOpenCloseEventArgs } from '@syncfusion/ej2-inputs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, timer } from "rxjs";
declare let numeral: any;
import $ from "jquery";
import * as AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import WebViewer from '@pdftron/webviewer';
import { LinkService, ImageService, HtmlEditorService, ToolbarType, ToolbarService, RichTextEditorComponent, NodeSelection } from '@syncfusion/ej2-angular-richtexteditor';
import { Dialog, Tooltip } from '@syncfusion/ej2-popups';
import { environment } from "@environments/environment";
import { AppConstants } from "@app/_helpers/api-constants";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Table } from 'primeng/table';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

L10n.load({
  'en-US': {
    grid: {
      AddFormTitle: 'Add Child Folder',
    },
  }
});

@Component({
  selector: 'app-dms-main',
  templateUrl: './dms-main.component.html',
  providers: [ToolbarService, EditService, PageService, FilterService, FreezeService, ResizeService, LinkService, ImageService, HtmlEditorService],
  encapsulation: ViewEncapsulation.None
})
export class DmsMainComponent implements OnInit, AfterViewInit {
  clientsList: any = [];
  tasksDataSource: any = [];
  clientscols: any[];
  companyId;
  filteredItemsSize = -1;
  clientfilteredItemsSize = -1;

  @ViewChild('clientsGB') searchClientValue;
  @ViewChild('searchTaskValue') searchTaskValue;
  @ViewChild('cientslist') dataTabclientsList: Table;
  @ViewChild('dttask') dataTabTaskclientsList: Table;

  public myGroupForm: UntypedFormGroup;
  files: File[] = [];
  public filterSettings: Object;
  public isRightPanelShow: boolean = true;
  public isLeftPanelShow: boolean = true;
  public visibleSidebar: boolean = false;
  public selClient: any = "";
  public filteredClients: any = [];
  public fileBlob: any = null;
  public panelsSettings: any = { "panel2_size": "99%", "panel3_size": "1%", "panel1_size": "18%" };
  public emailTemplate = { "to": "", "from": "", "subject": "", "fileName": "" };
  public inviteClientEmailTemplate = { "to": "", "from": "", "subject": "" };
  public rowIndexes: any = [];

  public data: Object[] = [];
  public editSettings: Object;
  public toolbar = [];
  public dialogToolbar = [];
  public pageSettings: Object;
  @ViewChild('taskForm')
  public taskForm: UntypedFormGroup;
  public selectOptions: SelectionSettingsModel;
  public rowDrop: Object;
  public selectedRow: Object;
  public submitClicked: boolean = false;
  public isUploadShow: boolean = false;
  public showRightContent: boolean = false;
  public showSaveButtons: boolean = false;
  public isMyTemplateEnabled: boolean = false;
  public loading: boolean;
  public loadingDoc: boolean = false;
  public isViewerShow: boolean = true;
  public isUserTableChanged: boolean = false;
  public settingsDisplay: boolean = false;
  public isUserSettingsTouched: boolean = false;
  public isFilePreview: boolean = false;
  public isPreviewViewerShow: boolean = false;
  public isTemplateShowGrid: boolean = false;
  public isEmailDialog: boolean = false;
  public showInactiveClients: boolean = false;
  public selectedRowIndex: any = -1;
  public clientRawList: any;
  public idMap = {};

  public selectedClientID: any = null;
  public selectedCompanyID: any = null;
  public isDropAction: boolean = false;
  public isApiReady: boolean = false;
  userName;
  selectedParentId: number = -1;
  public settingsData: Object[] = [];
  public globalTemplateArr: Object[] = [];
  public globalClientTemplateArr: Object[] = [];
  public selectedSettings: Object = { "TemplateName": "", "readOnly": true };
  public settingsRightSideData: Object[] = [];
  public selectedObjs: Object[] = [];
  public value: number[] = [];
  public isShowGrid: boolean = false;
  public isCreateNew: boolean = false;
  public isDuplicateEnabled: boolean = false;
  public showDuplicate: boolean = false;
  public settingsNotifications: boolean = false;
  public isAddTemplate: boolean = false;
  public filePasswordchecked: boolean = false;
  public isShowPassword: boolean = false;
  public isInviteClient: boolean = false;
  public filePassword: any = "";
  public selectedClientsItems: any = [];
  public usersList: any = [];
  public sourceList: any = [];
  public emailtemplateList: any = [];
  public staffPermission;
  public emailSingnatureObj: any = { "EmailSignatureID": null, "CompanyID": -1, "StaffID": -1, "HtmlBodySignature": '' };

  public taskCols: any = [];
  public backUpUserList = [];
  public emailCols: any = [];
  clientGroupCategory: any = [];
  public masterEmailTemplates = [];
  intervalId: any = null;
  public awsBucket: any;
  public defaultPermisssionObj = {};
  public loadGroupPermissionsObj = {};
  public selectedCustomer = null;
  public emailTemplateObj = { 'htmlBody': '', 'EmailSubject': null, "EmailTemplateID": null };
  public permisssionObj = {
    'admin_Full': false, 'mgr_Full': false, 'standard_Full': false, 'limited_Full': false, 'admin_Read': false, 'admin_Create': false, 'admin_Delete': false, 'mgr_Read': false, 'mgr_Create': false, 'mgr_Delete': false, 'standard_Read': false, 'standard_Create': false, 'standard_Delete': false, 'limited_Read': false, 'limited_Create': false, 'limited_Delete': false, 'admin_Download': false, 'mgr_Download': false, 'standard_Download': false, 'limited_Download': false, 'admin_Upload': false, 'mgr_Upload': false, 'standard_Upload': false, 'limited_Upload': false, 'ShowInPortal': false, "LineType": null, "EmailUploadTemplateID": null, "EmailDownloadTemplateID": null, "StaffID": []
  };

  public defaultClientTemplates = [{ "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }, { "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }, { "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }, { "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }];

  @ViewChild('treeGrid')
  public treeGrid: TreeGridComponent;

  @ViewChild('treetempleteGrid')
  public treetempleteGrid: GridComponent;

  @ViewChild('treeAddTemplateGrid')
  public treeAddTemplateGrid: GridComponent;

  @ViewChild('treeSettingtempleteGrid')
  public treeSettingtempleteGrid: TreeGridComponent;

  @ViewChild('viewer') viewer: ElementRef;
  wvInstance: any;

  @ViewChild('dummyViewer') dummyViewer: ElementRef;
  dummyPDFInstance: any;

  usercols: any[];
  public contextMenuItems: Object;
  public contextMenuDialogItems: Object;

  @ViewChild('preiewviewer') preiewviewer: ElementRef;
  wvPreviewInstance: any;

  @ViewChild('customRTE')
  public rteObj: RichTextEditorComponent;

  @ViewChild('inviteRTE')
  public inviteObj: RichTextEditorComponent;

  @ViewChild('emailRTE')
  public emailObj: RichTextEditorComponent;

  public selection: NodeSelection = new NodeSelection();
  public ranges: Range;
  @ViewChild('Dialog')
  public dialogObj: Dialog;

  public textArea: HTMLElement;

  public imageSettings = {
    saveFormat: "Base64"
  };
  public tools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      {
        tooltipText: 'Insert Field',
        undo: true,
        click: this.onClick.bind(this),
        template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%">'
          + '<div class="e-tbar-btn-text" style="font-weight: 500;"><i class="fa fa-file-text-o" aria-hidden="true"></i></div></button>'
      },
      'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'CreateLink', 'Image', '|', 'ClearFormat', 'Print', 'SourceCode']
  };

  public emailTools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', '|',
      'FontName', 'FontSize', 'FontColor',
      'Alignments', '|', 'OrderedList', 'UnorderedList',
      'CreateLink', 'SourceCode']
  };

  public editorValue: any = null;
  public emailEditorValue: any = null;
  public inviteEditorValue: any = null;
  public dlgButtons: any = [{ buttonModel: { content: "Insert", isPrimary: true }, click: this.onInsert.bind(this) },
  { buttonModel: { content: 'Cancel' }, click: this.dialogOverlay.bind(this) }];
  public header: string = 'Field Names';
  public target: HTMLElement = document.getElementById('rteSection');

  public uploadList: any = [];
  public downloadList: any = [];
  intervalid: any;
  public companyName: any;
  public emailCompany: any;
  public userEmail: any;
  public selectedExpandRowIndex : number = -1;

  constructor(private mangoAPISrvc: MangoApiService, private translate: TranslateService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, protected mangoUtils: mangoUtils, private _fb: UntypedFormBuilder, private http: HttpClient,) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffPermission = this.encrDecSrvc.getObject(AppConstants.staffPermission);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.emailCompany = this.encrDecSrvc.getObject(AppConstants.companyEmail);
    this.companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    this.userEmail = this.encrDecSrvc.getObject(AppConstants.userEmail);

    this.emailSingnatureObj.CompanyID = this.companyId;
    this.emailSingnatureObj.StaffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.clientGroupCategory = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);

    this.initializeGroupForm();
    this.setAWSOBject();
    this.mangoAPISrvc.notifyLogging(
      this.encrDecSrvc.getObject(AppConstants.isLoggingUser)
    );

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.editSettings = { showDeleteConfirmDialog: true, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
    this.filterSettings = { type: 'Menu', hierarchyMode: 'Both' };
    this.toolbar = ['Add', { text: 'Add Template', prefixIcon: 'e-add', tooltipText: 'Add Template ', id: 'toolbarAddTemplate', align: 'left' }, 'Edit', 'Delete', 'ExpandAll', 'CollapseAll'];
    this.dialogToolbar = ['Add', 'Edit', 'Delete'];
    this.selectOptions = { type: 'Single', mode: 'Both',enableToggle: true };
    this.value = [1];

    this.contextMenuItems = ['Edit', 'Delete',
      { text: 'Email', target: '.e-content', id: 'emailrow', iconCss: 'fa fa-envelope settings' },
      { text: 'Show Password', target: '.e-content', id: 'showpasswordrow', iconCss: 'fa fa-key settings' },
      { text: 'Preview', target: '.e-content', id: 'previewrow', iconCss: 'fa fa-eye settings' },
      { text: 'Permissions', target: '.e-content', id: 'settings', iconCss: 'fas fa-cog settings' },
      { text: 'Upload', target: '.e-content', id: 'uploadFile', iconCss: 'fa fa-cloud-upload settings' },
      { text: 'Download', target: '.e-content', id: 'downloadrow', iconCss: 'fa fa-cloud-download' }
    ]

    this.contextMenuDialogItems = ['Edit', 'Delete',
      { text: 'Permissions', target: '.e-content', id: 'dialogsettings', iconCss: 'fas fa-cog settings' },
      { text: 'Notifications', target: '.e-content', id: 'dialognotification', iconCss: 'far fa-bell settings' }
    ]

    if (this.staffPermission == 1 || this.staffPermission == 2) {
      this.contextMenuItems = ['Edit', 'Delete',
        { text: 'Email', target: '.e-content', id: 'emailrow', iconCss: 'fa fa-envelope settings' },
        { text: 'Show Password', target: '.e-content', id: 'showpasswordrow', iconCss: 'fa fa-key settings' },
        { text: 'Preview', target: '.e-content', id: 'previewrow', iconCss: 'fa fa-eye settings' },
        { text: 'Permissions', target: '.e-content', id: 'settings', iconCss: 'fas fa-cog settings' },
        { text: 'Notifications', target: '.e-content', id: 'notification', iconCss: 'far fa-bell settings' },
        { text: 'Upload', target: '.e-content', id: 'uploadFile', iconCss: 'fa fa-cloud-upload settings' },
        { text: 'Download', target: '.e-content', id: 'downloadrow', iconCss: 'fa fa-cloud-download' }
      ]
      this.toolbar.push({ text: 'Permissions', prefixIcon: 'remove-ejs-icons fas fa-cog settings ic-size-14 dms-icons-color', tooltipText: 'Settings ', id: 'toolbarfilter', align: 'left' }, { text: 'Notifications', prefixIcon: 'remove-ejs-icons far fa-bell settings ic-size-14 dms-icons-color', tooltipText: 'Notifications ', id: 'toolbarnotification', align: 'left' }, { text: 'Invite Client', prefixIcon: 'remove-ejs-icons far fa-paper-plane settings ic-size-14 dms-icons-color', tooltipText: 'Invite Client ', id: 'toolbarInv', align: 'left' });

      this.dialogToolbar.push({ text: 'Permissions', prefixIcon: 'remove-ejs-icons fas fa-cog settings ic-size-14 dms-icons-color', tooltipText: 'Settings ', id: 'toolbarfilter', align: 'left' }, { text: 'Notifications', prefixIcon: 'remove-ejs-icons far fa-bell settings ic-size-14 dms-icons-color', tooltipText: 'Notifications ', id: 'toolbarnotification', align: 'left' })
    } else {
      this.contextMenuItems = ['Edit', 'Delete',
        { text: 'Email', target: '.e-content', id: 'emailrow', iconCss: 'fa fa-envelope settings' },
        { text: 'Show_Password', target: '.e-content', id: 'showpasswordrow', iconCss: 'fa fa-key settings' },
        { text: 'Preview', target: '.e-content', id: 'previewrow', iconCss: 'fa fa-eye settings' },
        { text: 'Download', target: '.e-content', id: 'downloadrow', iconCss: 'fa fa-cloud-download' }
      ]
    }
    localStorage.setItem('selectedParentTableRow', "-1");
  }

  ngOnInit(): void {
    this.getEmailSignature();
    this.loadDefaultData();
  }

  ngAfterViewInit() {
    this.createDummyPDFInstance('../../../../assets/dummyPDF.pdf', '');
    setTimeout(() => {
      this.disableToolBars(true);
      setCulture('en-US');
    }, 200);
  }

  initTranslations() {
    this.uploadList = []
    this.downloadList = []

    this.clientscols = [
      { field: 'ClientName', header: this.translate.instant('client.name') }
    ];

    this.emailCols = [
      { field: 'TemplateName', header: this.translate.instant('Template-Names') }
    ];
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);

  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientsList.length; i++) {
        const client = this.clientsList[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1
        ) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }


  /** Removes all e-icons class name for custom toolbar that uses font-awesome icon  */
  configureEjsIcons() {
    const elements: any = document.getElementsByClassName('remove-ejs-icons');

    Object.values(elements).map((element: HTMLSpanElement) => {
      element.classList.remove("e-icons");
    })
  }

  loadDefaultData() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.loadDefaultDMSViewAPI().subscribe(function (data: any) {
      self.masterEmailTemplates = data[0];
      self.loadGroupPermissionsObj = data[1];
      self.getUsersList(data[3]);
      self.clientRawList = data[2];
      self.getAllClientsDataSource(data[2]);
      self.getEmailTemplates();
      self.mangoAPISrvc.showLoader(false);

      setTimeout(() => {
        self.configureEjsIcons();
      }, 500);
    });
  }

  createClientTemplates(objArr) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    objArr.forEach((selectedItem, key) => {
      const urlOne = parent.http.post(`${environment.API_URL}/dms/dmsClientTemplates/create`, selectedItem, { 'headers': headers });
      observableBatch.push(urlOne);
    });
    return forkJoin(observableBatch);
  }

  initDataSources() {
    const parent = this;
    parent.isApiReady = false;
    parent.showRightContent = false;
    parent.globalTemplateArr = [];
    parent.globalClientTemplateArr = [];
    parent.settingsData = [];
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token')).set('withCredentials', 'true');
    const urlOne = parent.http.get(`${environment.API_URL}/dms/globalTemplateNames`, { 'headers': headers });
    const urlTwo = parent.http.get(`${environment.API_URL}/dms/clientTemplateNames`, { 'headers': headers });
    parent.mangoAPISrvc.showLoader(true);
    forkJoin([urlOne, urlTwo]).subscribe(
      (data: any) => {
        parent.globalTemplateArr.push({ "UniqueID": parent.mangoUtils.generateUUID(), "GlobalTemplateNameID": null, "TemplateName": "Global Templates", "ParentID": null, "readOnly": true });

        for (let index = 0; index < data[0].length; index++) {
          const element = data[0][index];
          element['UniqueID'] = parent.mangoUtils.generateUUID();
          element['readOnly'] = true;
          element['ParentID'] = parent.globalTemplateArr[0]['UniqueID'];
          parent.globalTemplateArr.push(element);
        }

        parent.globalClientTemplateArr.push({ "UniqueID": parent.mangoUtils.generateUUID(), "dmsClientTemplateNameID": null, "TemplateName": "My Templates", "ParentID": null, "readOnly": false });

        for (let index = 0; index < data[1].length; index++) {
          const element = data[1][index];
          element['UniqueID'] = parent.mangoUtils.generateUUID();
          element['readOnly'] = false;
          element['ParentID'] = parent.globalClientTemplateArr[0]['UniqueID'];
          parent.globalClientTemplateArr.push(element);
        }

        setTimeout(() => {
          parent.settingsData = [...parent.globalTemplateArr, ...parent.globalClientTemplateArr];
          parent.mangoAPISrvc.showLoader(false);
        }, 200);

        parent.isApiReady = true;
        parent.disableSettingsToolBars(false);
        parent.handleChange({ index: 1 });
        parent.disableTreeGridToolBars(false);
      },
      err => console.error(err)
    );
  }

  getEmailTemplates() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailTemplates('dms').subscribe((objItem: any) => {
      if (objItem.length > 0) {
        parent.emailtemplateList = objItem;
        for (let i = 0; i < objItem.length; i++) {
          parent.uploadList.push({ label: objItem[i].TemplateName, value: objItem[i].EmailTemplateID });
          parent.downloadList.push({ label: objItem[i].TemplateName, value: objItem[i].EmailTemplateID })
        }
      } else {
        const copyObj = Object.assign([], parent.masterEmailTemplates);
        copyObj.map(function (lineObj) {
          lineObj['CompanyID'] = parent.companyId;
          lineObj['MasterTemplateID'] = lineObj.EmailTemplateID;
          lineObj['isMaster'] = false;
        });
        parent.bulkCreateEmailTemplates(copyObj);
      }
      parent.mangoAPISrvc.showLoader(false);
    });
  }



  deleteDMSParentFolder(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.deleteDMSParentFolder(id).subscribe((objItem) => {
      parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.deleteMessage);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  updateDMSParentFolder(obj) {
    const parent = this;
    const backup = Object.assign({}, obj);
    delete backup["Children"];
    delete backup["childRecords"];
    delete backup["taskData"];
    delete backup["uniqueID"];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateDMSParentFolder(backup).subscribe((objItem: any) => {
      parent.settingsDisplay = false;
      parent.isShowPassword = false;
      parent.filePassword = "";
      parent.mangoAPISrvc.showLoader(false);
      if (backup['settings'] == true && backup.ClientID) {
        parent.reloadParentGrid(backup.ClientID);
      } else {
        parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), objItem.message);
      }

    });
  }

  saveEmailTemplate() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.emailTemplateObj['htmlBody'] = parent.editorValue;
    parent.mangoAPISrvc.updateEmailTemplate(parent.emailTemplateObj).subscribe((objItem: any) => {
      parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), objItem.message);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  sendMail(type, selectedObj) {
    const parent = this;
    const sendInBlueObj = {};
    const toArr = [];
    let toObj = { email: '', name: '' };
    const contactPerson = parent.selectedCustomer['ContactPerson'] ? parent.selectedCustomer['ContactPerson'] : "";
    const contactEmail = parent.selectedCustomer['Email'] ? parent.selectedCustomer['Email'] : "";
    const emailTemplateId = (type == 'upload') ? selectedObj['EmailUploadTemplateID'] : selectedObj['EmailDownloadTemplateID'];

    if (contactEmail && emailTemplateId) {
      const dataSet = parent.treeGrid['grid']['currentViewData'];
      const parentObj = (type == 'upload') ? dataSet.filter((item) => item['dmsParentID'] == selectedObj.ParentID)[0] : selectedObj;

      const companyEmail = parent.emailCompany;
      const companyName = parent.companyName;
      const linkToPortal = parent.encrDecSrvc.getObject(AppConstants.linkToPortal);
      const websiteCompany = parent.encrDecSrvc.getObject(AppConstants.WebsiteCompany);

      const emailTemplate = parent.emailtemplateList.filter((item) => item['EmailTemplateID'] == emailTemplateId)[0];
      if (!emailTemplate) {
        return false;
      }
      //sendInBlueObj['attachment'] = attachmentsArr;
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%<strong>ClientName</strong>%", parent.selectedCustomer['ClientName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%ClientName%", parent.selectedCustomer['ClientName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%<strong>ContactName</strong>%", contactPerson);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%ContactName%", contactPerson);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceAll(emailTemplate['htmlBody'], "%<strong>LinkToPortal</strong>%", linkToPortal);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceAll(emailTemplate['htmlBody'], "%LinkToPortal%", linkToPortal);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceAll(emailTemplate['htmlBody'], "http://https://", "https://");
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceLinkToPortal(emailTemplate['htmlBody'], 'Link to Portal');
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%<strong>YourFirmName</strong>%", companyName);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%YourFirmName%", companyName);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%<strong>FileName</strong>%", selectedObj['FName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%FileName%", selectedObj['FName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%<strong>FolderName</strong>%", selectedObj['FName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%FolderName%", selectedObj['FName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%<strong>EngagementFolder</strong>%", dataSet.filter((item) => item['dmsParentID'] == parentObj['YellowParentID'])[0]['FName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%EngagementFolder%", dataSet.filter((item) => item['dmsParentID'] == parentObj['YellowParentID'])[0]['FName']);
      emailTemplate['EmailSubject'] = parent.mangoUtils.replaceSpan(emailTemplate['EmailSubject'], "%<strong>ClientName</strong>%", parent.selectedCustomer['ClientName']);
      emailTemplate['EmailSubject'] = parent.mangoUtils.replaceSpan(emailTemplate['EmailSubject'], "%ClientName%", parent.selectedCustomer['ClientName']);
      emailTemplate['htmlBody'] = parent.mangoUtils.replaceSpan(emailTemplate['htmlBody'], "%website%", websiteCompany);
      // if sNotifyClientUpload is true and it is Upload Event
      if (parentObj['isNotifyClientUpload'] == true && type != 'upload') {
        const emailArr = contactEmail.split(";");
        for (let index = 0; index < emailArr.length; index++) {
          const element = emailArr[index];
          toObj = { email: '', name: '' };
          toObj['email'] = element;
          if (contactPerson == "") {
            toObj['name'] = toObj['email'];
          } else {
            toObj['name'] = contactPerson;
          }
          toArr.push(toObj);
        }
      }


      for (let index = 0; index < selectedObj['StaffID'].length; index++) {
        const eachSelectedUser = selectedObj['StaffID'][index];
        const userObj = parent.usersList.filter((item) => item['StaffID'] == eachSelectedUser)[0];
        if (userObj['Email']) {
          toObj = { email: '', name: '' };
          toObj.email = userObj['Email'];
          toObj.name = userObj['StaffName'];
          toArr.push(toObj);
        }
      }

      // Only the Staff gets email notification (Client never gets notified on a download)
      //if there is email template(Download) and Staff in the List email all staff in list

      if (type == 'download' && (!selectedObj['EmailDownloadTemplateID'] || selectedObj['StaffID'].length == 0)) {
        return false;
      } else if (type == 'upload' && (!selectedObj['EmailUploadTemplateID'] || selectedObj['StaffID'].length == 0)) {
        return false;
      }

      if (toArr.length == 0) {
        return false;
      }
      const senderObj = { name: '', email: '' };
      senderObj['name'] = companyName;
      senderObj['email'] = environment.EMAIL_RETURN_SENDER;

      sendInBlueObj['replyTo.email'] = environment.EMAIL_RETURN_SENDER;
      sendInBlueObj['sender'] = senderObj;
      sendInBlueObj['sender.name'] = companyName;
      sendInBlueObj['sender.email'] = companyEmail;
      sendInBlueObj['to'] = toArr;
      sendInBlueObj['subject'] = emailTemplate.EmailSubject;
      sendInBlueObj['htmlContent'] = emailTemplate['htmlBody'];

      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe((data) => {
        parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.emailSentMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    }
  }

  actionComplete(args: DialogEditEventArgs): void {
    const self = this;
    if (args.requestType === 'refresh') {
      $('.e-rowdragheader').show();
      setTimeout(() => {
        self.selectedRowIndex = localStorage.getItem('selectedParentTableRow') ? parseInt(localStorage.getItem('selectedParentTableRow')) : -1;
        if (self.treeGrid) {
          self.treeGrid.selectRow(self.selectedRowIndex);
        }
      }, 500);
    }
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      // Set initail Focus
      if (args.rowData['FName']) {
        $('#_gridcontrol_dialogEdit_wrapper_title').text(args.rowData['FName']);
        $('#_gridcontrolLineType').val(args.rowData['FName']);
      }
      $(args.form.elements[1]).parent().hide(); // hiding the size column in Dialog
      $(args.form.elements[2]).parent().hide();
      $(args.form.elements[3]).parent().hide();
      $(args.form.elements[4]).parent().hide();
      $(args.form.elements[5]).parent().hide();
      $(args.form.elements[6]).parent().hide();
      if (args.requestType === 'beginEdit') {
        (args.form.elements.namedItem('FileType') as HTMLInputElement).focus();
      }
    }
    if (args['action'] === 'edit' && this.selectedClientID && args.rowData) {
      args.rowData['ModifyOn'] = new Date();
      args.rowData['ModifyBy'] = this.userName;
      this.updateDMSParentFolder(args.rowData);
    } else if (args['action'] === 'add' && this.selectedClientID) {
      let addObj = {};
      if (args.rowData['LineType'] == "P" || this.selectedRow['LineType'] == "C") {
        addObj = this.loadGroupPermissionsObj;
      } else {
        addObj = this.selectedRow;
      }
      args['data']['admin_Read'] = addObj['admin_Read'];
      args['data']['admin_Create'] = addObj['admin_Create'];
      args['data']['admin_Delete'] = addObj['admin_Delete'];
      args['data']['admin_Download'] = addObj['admin_Download'];
      args['data']['admin_Upload'] = addObj['admin_Upload'];

      args['data']['mgr_Read'] = addObj['mgr_Read'];
      args['data']['mgr_Create'] = addObj['mgr_Create'];
      args['data']['mgr_Delete'] = addObj['mgr_Delete'];
      args['data']['mgr_Download'] = addObj['mgr_Download'];
      args['data']['mgr_Upload'] = addObj['mgr_Upload'];

      args['data']['standard_Read'] = addObj['standard_Read'];
      args['data']['standard_Create'] = addObj['standard_Create'];
      args['data']['standard_Delete'] = addObj['standard_Delete'];
      args['data']['standard_Download'] = addObj['standard_Download'];
      args['data']['standard_Upload'] = addObj['standard_Upload'];

      args['data']['limited_Read'] = addObj['limited_Read'];
      args['data']['limited_Create'] = addObj['limited_Create'];
      args['data']['limited_Delete'] = addObj['limited_Delete'];
      args['data']['limited_Download'] = addObj['limited_Download'];
      args['data']['limited_Upload'] = addObj['limited_Upload'];
      args['data']['ModifyBy'] = this.userName;
      this.addDMSParentFolder(args['data'], true);
    } else if (args['requestType'] === 'delete' && this.selectedClientID) {
      const values = [];
      if (args['data'].length > 0) {
        for (let index = 0; index < args['data'].length; index++) {
          const element = args['data'][index];
          values.push(element['dmsParentID']);
        }

        for (let j = 0; j < this.rowIndexes.length; j++) {
          const element = this.rowIndexes[j];
          if(values.indexOf(element['dmsParentID']) == -1){
            values.push(element['dmsParentID']);
          }
        }
      } else {
        values.push(this.selectedRow['dmsParentID']);
      }
      this.deleteDMSParentFolder(values.toString());
      if (args['data'][0]['LineType'] == 'F') {
        for (let j = 0; j < this.rowIndexes.length; j++) {
          const element = this.rowIndexes[j];
          this.deleteAWSFile(element['UniqueName']);
          if(j == this.rowIndexes.length - 1){
            this.reloadParentGrid(element.ClientID);
          }
        }
      } else {
        const fileNames = self.mangoUtils.findAllByKey(this.selectedRow, 'UniqueName');
        const uniqueArray = Array.from(new Set(fileNames));
        if (uniqueArray.length > 0) {
          this.bulkDelete(uniqueArray);
        }
      }

    } else if (this.isDropAction && this.selectedClientID) {
      this.data.map(function (lineObj) {
        lineObj['LineType'] = lineObj['ParentID'] == null ? 'P' : 'C';
        lineObj['ModifyOn'] = new Date();
      });
      this.bulkUpdateParentFolder();
      this.isDropAction = false;
    }
  }

  editClientTemplate(obj) {
    const parent = this;
    if (obj['isNewRecord'] == true) {
      parent.settingsDisplay = false;
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.editClientTemplate(obj).subscribe((objItem: any) => {
      parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), objItem.message);
      parent.settingsDisplay = false;
      parent.hideNotifications();
      parent.mangoAPISrvc.showLoader(false);
      if (obj['settings'] == true) {
        parent.getClientTemplate(obj['dmsClientTemplateNameID']);
      }
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  applyClientTemplatesYellowChildrens(obj, isParent) {
    const parent = this;
    const updateobj = {};
    updateobj['ShowInPortal'] = isParent;
    const updateId = isParent ? obj.YellowParentID : obj.dmsClientTemplatesID;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.applyClientTemplatesYellowChildrens(updateobj, updateId, isParent).subscribe((objItem) => {
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  deleteClientTemplateNames() {
    const parent = this;
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('dm.Do_you_want_to_delete_this_record'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteClientTemplateNames(parent.selectedSettings['dmsClientTemplateNameID']).subscribe((childresponse: any) => {
          parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), childresponse.message);
          parent.showRightContent = false;
          parent.isShowGrid = false;
          parent.mangoAPISrvc.showLoader(false);
          parent.initDataSources();
        });
      }
    })
  }

  rowTempleteSelected(args: RowSelectEventArgs, isRowSelected) {
    const parent = this;
    parent.settingsRightSideData = [];
    parent.isCreateNew = false;
    const selectedData = args.data[0] ? args.data[0] : args.data;
    parent.removeToolBars(selectedData.readOnly);
    this.disableSettingsNotificationToolBars(false);
    this.disableSettingsToolBars(false);
    if (selectedData && (selectedData['TemplateName'] == "Global Templates" || selectedData['TemplateName'] == "My Templates")) {
      parent.isDuplicateEnabled = parent.isMyTemplateEnabled = parent.showDuplicate = false;

      const selectedTemp = parent.treetempleteGrid.getSelectedRecords();
      parent.selectedSettings = (selectedTemp && selectedTemp.length) ? selectedTemp[0] : { "TemplateName": "", "readOnly": true };
      parent.showRightContent = parent.isShowGrid = false;

      if (selectedData['TemplateName'] == "My Templates") {
        parent.isCreateNew = true;
        parent.showSaveButtons = false;
      }
    } else {
      parent.showRightContent = parent.isShowGrid = true;

      if (isRowSelected) {
        parent.showSaveButtons = !selectedData.readOnly;
        parent.isDuplicateEnabled = true;
        if (parent.treeSettingtempleteGrid) {
          // parent.treeSettingtempleteGrid.showSpinner();
        }
        parent.selectedSettings = parent.treetempleteGrid.getSelectedRecords()[0];
        if (parent.selectedSettings['readOnly'] == false) {
          parent.getClientTemplate(parent.selectedSettings['dmsClientTemplateNameID']);
        } else {
          parent.isMyTemplateEnabled = true;
          parent.mangoAPISrvc.getGlobalTemplate(parent.selectedSettings['GlobalTemplateNameID']).subscribe((templates: any) => {
            for (let index = 0; index < templates.length; index++) {
              const element = templates[index];
              element['UniqueID'] = element['GlobalTemplateID'];
              element['isGlobal'] = true;
            }

            setTimeout(() => {
              parent.settingsRightSideData = templates;
              // if (parent.treeSettingtempleteGrid) {
              //   // parent.treeSettingtempleteGrid.hideSpinner();
              // }
            }, 200);

          });
        }
      } else {
        parent.selectedSettings = { "UniqueID": parent.mangoUtils.generateUUID(), "TemplateName": "", "readOnly": true };
        parent.isShowGrid = parent.isMyTemplateEnabled = parent.showRightContent = parent.showSaveButtons = parent.isDuplicateEnabled = false;
      }
    }

  }

  getAllClientsDataSource(clients) {
    const parent = this;
    parent.loading = true;
    parent.disableTreeGridToolBars(false);
    clients.map(function (obj) {
      obj['Inactive'] = obj['Inactive'] ? obj['Inactive'] : false;
      obj['ContactRecord'] = obj['ContactRecord'] ? obj['ContactRecord'] : false;
      obj['GroupDescriptionIDArrayDesc'] = "";
      obj['GroupDescriptionIDArray'] = obj['GroupDescriptionIDArray'] ? obj['GroupDescriptionIDArray'] : [];
      if (obj['GroupDescriptionIDArray'].length > 0) {
        const tempValues = obj['GroupDescriptionIDArray'].toString().split(',');
        for (var i = 0; i < tempValues.length; ++i) {
          if (tempValues[i]) {
            const matchedArr = parent.clientGroupCategory.filter((item) => item['CustomerGroupCategoryID'] == tempValues[i]);
            if (matchedArr.length > 0) {
              if (i == 0) {
                obj['GroupDescriptionIDArrayDesc'] += parent.clientGroupCategory.filter((item) => item['CustomerGroupCategoryID'] == tempValues[i])[0]['GroupDescription'];
              } else {
                obj['GroupDescriptionIDArrayDesc'] = obj['GroupDescriptionIDArrayDesc'] + " , " + parent.clientGroupCategory.filter((item) => item['CustomerGroupCategoryID'] == tempValues[i])[0]['GroupDescription'];
              }
            }
          }
        }
      }
      return obj;
    });
    parent.hideRightPanel();
    parent.clientsList = clients.filter((item) => { return item.Inactive == false && item.ContactRecord == false });
    parent.filteredClients = clients.filter((item) => { return item.Inactive == false && item.ContactRecord == false });
    parent.tasksDataSource = Object.assign([], parent.clientsList);
    parent.initDataSources();
    parent.loading = false;
    parent.disableTreeGridToolBars(true);
  }

  onChangeShowInactive() {
    if (this.showInactiveClients)
      this.clientsList = this.clientRawList.filter((item) => { return item.ContactRecord == false });
    else
      this.clientsList = this.clientRawList.filter((item) => { return item.Inactive == false && item.ContactRecord == false });

    this.tasksDataSource = Object.assign([], this.clientsList);
    this.initDataSources();
  }

  getClientTemplate(id) {
    const parent = this;
    parent.settingsRightSideData = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getClientTemplate(id).subscribe((templates: any) => {
      for (let index = 0; index < templates.length; index++) {
        const element = templates[index];
        element['UniqueID'] = element['dmsClientTemplatesID'];
        element['isGlobal'] = false;
        element['LineType'] = element['ParentID'] == null ? 'P' : 'C';
      }
      setTimeout(() => {
        parent.settingsRightSideData = templates;
        parent.mangoAPISrvc.showLoader(false);
        // parent.treeSettingtempleteGrid.hideSpinner();
      }, 200);
    }, (err) => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  public changeHandler(args: CheckBoxChangeEventArgs, data: any): void {
    this.treeGrid.clearSelection();
    data.isChecked = args.checked;
    if(data.isChecked == true){
      this.rowIndexes.push(data);
    }else{
      const index = this.rowIndexes.findIndex(item => item.dmsParentID == data.dmsParentID);
      this.rowIndexes.splice(index, 1);
    }
  }

  onRowSelect(event, isRowSelected) {
    const self = this;
    self.selClient = event;
    self.selectedClientID = null;
    self.selectedCompanyID = null;
    self.selectedRow = null;
    self.isViewerShow = false;
    self.isPreviewViewerShow = false;
    self.data = [];
    this.rowIndexes = [];
    self.selectedRowIndex = -1;
    localStorage.setItem('selectedParentTableRow', "-1");
    this.selectedCustomer = event;
    this.disableTreeGridToolBars(false);
    this.disableNotificationToolBars(false);
    this.disableInviteToolBars(false);
    if (isRowSelected) {
      self.selectedClientID = event['ClientID'];
      self.selectedCompanyID = this.companyId;
      if (event.Email) {
        this.disableInviteToolBars(true);
      }
      setTimeout(() => {
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc.getDMSFilesByClientId(event['ClientID']).subscribe((result: any) => {

          if (result.length > 0) {
            result.map((data) => {
              data.admin_Create = data.admin_Create ? data.admin_Create : false;
              data.admin_Delete = data.admin_Delete ? data.admin_Delete : false;
              data.admin_Read = data.admin_Read ? data.admin_Read : false;
              data.admin_Download = data.admin_Download ? data.admin_Download : false;
              data.admin_Upload = data.admin_Upload ? data.admin_Upload : false;

              data.mgr_Delete = data.mgr_Delete ? data.mgr_Delete : false;
              data.mgr_Read = data.mgr_Read ? data.mgr_Read : false;
              data.mgr_Download = data.mgr_Download ? data.mgr_Download : false;
              data.mgr_Upload = data.mgr_Upload ? data.mgr_Upload : false;

              data.standard_Delete = data.standard_Delete ? data.standard_Delete : false;
              data.standard_Read = data.standard_Read ? data.standard_Read : false;
              data.standard_Download = data.standard_Download ? data.standard_Download : false;
              data.standard_Upload = data.standard_Upload ? data.standard_Upload : false;

              data.limited_Delete = data.limited_Delete ? data.limited_Delete : false;
              data.limited_Read = data.limited_Read ? data.limited_Read : false;
              data.limited_Download = data.limited_Download ? data.limited_Download : false;
              data.limited_Upload = data.limited_Upload ? data.limited_Upload : false;
              data.ShowInPortal = data.ShowInPortal ? data.ShowInPortal : false;
              data.EmailDownloadTemplateID = data.EmailDownloadTemplateID;
              data.EmailUploadTemplateID = data.EmailUploadTemplateID;

              data.isUploadEnable = false;
              data.isChecked = false;
            });
            self.treeGrid.dataSource = result;
            self.disableToolBars(false);
          } else {
            self.data = [];
            self.disableToolBars(false);
          }
          self.mangoAPISrvc.showLoader(false);

          // self.treeGrid.hideSpinner();
          setTimeout(() => {
            if(self.selectedExpandRowIndex > -1){
              self.treeGrid.expandRow(self.treeGrid.getRows()[this.selectedExpandRowIndex]);
            }
          }, 100);
        })
      }, 200);
    } else {
      self.treeGrid.dataSource = [];
      self.treeGrid.clearSelection();
      self.treeGrid.refresh();
      self.disableToolBars(true);
    }
  }

  saveData() {
    const parent = this;
    if (parent.isCreateNew) {
      // parent.treeSettingtempleteGrid.showSpinner();
      // parent.treetempleteGrid.showSpinner();

      const obj = { "TemplateName": parent.selectedSettings['TemplateName'], "CompanyID": parent.companyId };
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.createClientTemplateNames(obj).subscribe((response: any) => {

        const dataSet = parent.treeSettingtempleteGrid['grid']['currentViewData'];
        // updating newly created templateNameID
        for (let index = 0; index < parent.defaultClientTemplates.length; index++) {
          const element = parent.defaultClientTemplates[index];
          element['dmsClientTemplateNameID'] = response.data.dmsClientTemplateNameID;
          element['TemplateName'] = dataSet[index]['FolderName'];
          element['CompanyID'] = parent.companyId;
          element['admin_Read'] = dataSet[index]['admin_Read'] ? dataSet[index]['admin_Read'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['admin_Read'];

          element['admin_Delete'] = dataSet[index]['admin_Delete'] ? dataSet[index]['admin_Delete'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['admin_Delete'];
          element['admin_Create'] = dataSet[index]['admin_Create'] ? dataSet[index]['admin_Create'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['admin_Create'];
          element['admin_Download'] = dataSet[index]['admin_Download'] ? dataSet[index]['admin_Download'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['admin_Download'];
          element['admin_Upload'] = dataSet[index]['admin_Upload'] ? dataSet[index]['admin_Upload'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['admin_Upload'];

          element['mgr_Read'] = dataSet[index]['mgr_Read'] ? dataSet[index]['mgr_Read'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['mgr_Read'];
          element['mgr_Delete'] = dataSet[index]['mgr_Delete'] ? dataSet[index]['mgr_Delete'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['mgr_Delete'];
          element['mgr_Create'] = dataSet[index]['mgr_Create'] ? dataSet[index]['mgr_Create'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['mgr_Create'];
          element['mgr_Download'] = dataSet[index]['mgr_Download'] ? dataSet[index]['mgr_Download'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['mgr_Download'];
          element['mgr_Upload'] = dataSet[index]['mgr_Upload'] ? dataSet[index]['mgr_Upload'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['mgr_Upload'];

          element['standard_Read'] = dataSet[index]['standard_Read'] ? dataSet[index]['standard_Read'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['standard_Read'];
          element['standard_Delete'] = dataSet[index]['standard_Delete'] ? dataSet[index]['standard_Delete'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['standard_Delete'];
          element['standard_Create'] = dataSet[index]['standard_Create'] ? dataSet[index]['standard_Create'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['standard_Create'];
          element['standard_Download'] = dataSet[index]['standard_Download'] ? dataSet[index]['standard_Download'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['standard_Download'];
          element['standard_Upload'] = dataSet[index]['standard_Upload'] ? dataSet[index]['standard_Upload'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['standard_Upload'];

          element['limited_Read'] = dataSet[index]['limited_Read'] ? dataSet[index]['limited_Read'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['limited_Read'];
          element['limited_Delete'] = dataSet[index]['limited_Delete'] ? dataSet[index]['limited_Delete'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['limited_Delete'];
          element['limited_Create'] = dataSet[index]['limited_Create'] ? dataSet[index]['limited_Create'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['limited_Create'];
          element['limited_Download'] = dataSet[index]['limited_Download'] ? dataSet[index]['limited_Download'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['limited_Download'];
          element['limited_Upload'] = dataSet[index]['limited_Upload'] ? dataSet[index]['limited_Upload'] : (dataSet[index]['isUpdated'] == true) ? false : this.myGroupForm.value['limited_Upload'];
          if (index == 0) {
            element['ParentID'] = null;
          } else {
            element['ParentID'] = response.data.dmsClientTemplateNameID;
          }
        }

        // create First Record

        parent.mangoAPISrvc.createClientTemplates(parent.defaultClientTemplates[0]).subscribe((childresponse: any) => {

          for (let index = 0; index < parent.defaultClientTemplates.length; index++) {
            const element = parent.defaultClientTemplates[index];
            element['ParentID'] = childresponse.data.dmsClientTemplatesID;
            element['YellowParentID'] = childresponse.data.dmsClientTemplatesID
            element['FolderName'] = dataSet[index]['FolderName'];
          }
          parent.defaultClientTemplates.shift();
          parent.mangoAPISrvc.showLoader(false);
          parent.createClientTemplates(parent.defaultClientTemplates)
            .subscribe(data => {
              parent.showRightContent = false;
              parent.initDataSources();
              setTimeout(() => {
                // parent.treeSettingtempleteGrid.hideSpinner();
                // parent.treetempleteGrid.hideSpinner();
              }, 100);
            });
        });

      });
    } else {
      const obj = { "primaryID": this.selectedSettings['dmsClientTemplateNameID'], "TemplateName": this.selectedSettings['TemplateName'], "CompanyID": parent.companyId }
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.updateClientTemplateNames(obj).subscribe((response) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.treetempleteGrid.setCellValue(this.selectedSettings['UniqueID'], 'TemplateName', this.selectedSettings['TemplateName']);
        parent.initDataSources();
        parent.mangoAPISrvc.notify("success", this.translate.instant("Success_notify"), AppConstants.updateMsg)
      }, err => parent.mangoAPISrvc.notify("error", this.translate.instant("error"), AppConstants.updateErrorMsg));
    }
  }

  applyParentYellowChildrens(obj, isParent) {
    const parent = this;
    const updateobj = {};
    updateobj['ShowInPortal'] = isParent;
    const updateId = isParent ? obj.ParentID : obj.dmsParentID;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.applyParentYellowChildrens(updateobj, updateId, isParent).subscribe((objItem) => {
      parent.updateDMSParentFolder(parent.defaultPermisssionObj);
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  deleteClientTemplate(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.deleteClientTemplate(id).subscribe((objItem: any) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), objItem.message);
    }, error => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  createClientTemplate(obj) {
    const parent = this;
    if (obj['isNewRecord'] == true) {
      parent.settingsDisplay = false;
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createClientTemplates(obj).subscribe((objItem: any) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), objItem.message);
      parent.getClientTemplate(obj['dmsClientTemplateNameID']);
    }, error => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  sendInviteClient() {
    const parent = this;
    const sendInBlueObj = {};
    const attachmentObj = { url: '', name: '' };
    const toArr = [];
    let isinValidMailProperties = false;
    const contactPerson = parent.selectedCustomer['ContactPerson'] ? parent.selectedCustomer['ContactPerson'] : "";

    const emailArr = this.inviteClientEmailTemplate['to'].split(";");
    for (let index = 0; index < emailArr.length; index++) {
      const mail = emailArr[index];
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        const toObj = { email: '', name: '' };
        toObj['email'] = mail;
        toObj['name'] = mail;
        toArr.push(toObj);
      } else {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('Warning'),
          text: this.translate.instant('dms.Invalid_To_Email_Address'),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timer: 2000
        })
        isinValidMailProperties = true;
        break;
      }
    }

    if (isinValidMailProperties) {
      return false;
    }
    const senderObj = { name: '', email: '' };
    senderObj['name'] = parent.companyName;
    senderObj['email'] = environment.EMAIL_RETURN_SENDER;
    sendInBlueObj['replyTo.email'] = environment.EMAIL_RETURN_SENDER;


    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['sender.name'] = parent.userName;
    sendInBlueObj['sender.email'] = parent.userEmail;
    sendInBlueObj['to'] = toArr;
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceSpan(parent.inviteClientEmailTemplate['subject'], "%<strong>ClientName</strong>%", parent.selectedCustomer['ClientName']);
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceSpan(parent.inviteClientEmailTemplate['subject'], "%ClientName%", parent.selectedCustomer['ClientName']);
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceSpan(parent.inviteClientEmailTemplate['subject'], "%<strong>ContactName</strong>%", contactPerson);
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceSpan(parent.inviteClientEmailTemplate['subject'], "%ContactName%", contactPerson);

    sendInBlueObj['htmlContent'] = parent.inviteEditorValue;
    sendInBlueObj['subject'] = parent.inviteClientEmailTemplate['subject'];
    parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(res => {
      parent.closeClient();
      Swal.fire({
        icon: 'success',
        title: this.translate.instant('Information'),
        text: this.translate.instant('email-sent-successfully'),
        showConfirmButton: false,
        timer: 3000
      })
    });
  }

  sendEmailWithAttachment() {
    const parent = this;
    const sendInBlueObj = {};
    const attachmentObj = { url: '', name: '' };
    const toArr = [];
    let isinValidMailProperties = false;
    const attachmentsArr = [];
    let params = {};


    const emailArr = this.emailTemplate['to'].split(";");
    for (let index = 0; index < emailArr.length; index++) {
      const mail = emailArr[index];
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        const toObj = { email: '', name: '' };
        toObj['email'] = mail;
        toObj['name'] = mail;
        toArr.push(toObj);
      } else {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('Warning'),
          text: this.translate.instant('dms.Invalid_To_Email_Address'),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timer: 2000
        })
        isinValidMailProperties = true;
        break;
      }
    }

    if (isinValidMailProperties) {
      return false;
    }

    const senderObj = { name: '', email: '' };
    senderObj['name'] = parent.companyName;
    senderObj['email'] = environment.EMAIL_RETURN_SENDER;
    sendInBlueObj['replyTo.email'] = environment.EMAIL_RETURN_SENDER;
    sendInBlueObj['subject'] = parent.emailTemplate['subject'];

    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['sender.name'] = parent.userName;
    sendInBlueObj['sender.email'] = parent.userEmail;
    sendInBlueObj['to'] = toArr;
    sendInBlueObj['htmlContent'] = parent.emailEditorValue;
    parent.updateDMSParentFolder(parent.selectedRow);

    if (parent.filePasswordchecked && parent.fileBlob) {
      const UniqueName = this.mangoUtils.generateUUID() + '_' + parent.selectedRow['FName'];
      params = {
        Bucket: 'mangobillings3', Expires: 600000,
        ResponseContentDisposition: 'attachment; filename ="' + this.selectedRow['FName'] + '"',
        Key: 'protecteddocuments/' + UniqueName
      };

      const uploadparams = { Bucket: 'mangobillings3', Key: 'protecteddocuments/' + UniqueName, Body: parent.fileBlob };
      parent.awsBucket.upload(

        uploadparams,

        function (err, data) {
          parent.fileBlob = null;

          parent.awsBucket.getSignedUrl(
            'getObject',

            params,

            function (err, url) {
              attachmentObj['url'] = url;
              attachmentObj['name'] = parent.emailTemplate['fileName'];
              attachmentsArr.push(attachmentObj);


              sendInBlueObj['attachment'] = attachmentsArr;

              parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(res => {
                parent.isEmailDialog = false;
                Swal.fire({
                  icon: 'success',
                  title: this.translate.instant('Information'),
                  text: this.translate.instant('email-sent-successfully'),
                  showConfirmButton: false,
                  timer: 3000
                })
              });
            },

            parent.mangoAPISrvc
          );
        },

        parent.mangoAPISrvc
      );
    } else {
      params = {
        Bucket: 'mangobillings3', Expires: 600000,
        ResponseContentDisposition: 'attachment; filename ="' + this.selectedRow['FName'] + '"',
        Key: 'documents/' + this.selectedRow['UniqueName']
      };

      parent.awsBucket.getSignedUrl(
        'getObject',

        params,

        function (err, url) {
          attachmentObj['url'] = url;
          attachmentObj['name'] = parent.emailTemplate['fileName'];
          attachmentsArr.push(attachmentObj);


          sendInBlueObj['attachment'] = attachmentsArr;

          parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(res => {
            parent.isEmailDialog = false;
            Swal.fire({
              icon: 'success',
              title: this.translate.instant('Information'),
              text: this.translate.instant('email-sent-successfully'),
              showConfirmButton: false,
              timer: 3000
            })
          });
        },

        parent.mangoAPISrvc
      );
    }
  }

  bulkCreateEmailTemplates(observableBatch) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.bulkCreateEmailTemplates({ observableBatch }).subscribe((objItem) => {
      parent.getEmailTemplates();
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  onEmailRowSelect(event) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailTemplate(event.EmailTemplateID).subscribe((objItem: any) => {
      parent.emailTemplateObj = objItem;
      parent.emailTemplateObj['EmailSubject'] = parent.emailTemplateObj['EmailSubject'] ? parent.emailTemplateObj['EmailSubject'] : '';
      parent.editorValue = objItem['htmlBody'] ? objItem['htmlBody'] : '';
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public onClick() {
    this.rteObj.focusIn();
    this.ranges = this.selection.getRange(document);
    this.dialogObj.width = this.rteObj.element.offsetWidth * 0.4;
    this.dialogObj.dataBind();
    this.dialogObj.show();
    this.dialogObj.element.style.maxHeight = 'none';
  }

  public onInsert(): void {
    const activeEle: Element = this.dialogObj.element.querySelector('.char_block.e-active');
    if (activeEle) {
      const newNode = document.createElement('strong');
      const newTxtNode = document.createTextNode(activeEle.textContent);
      newNode.appendChild(newTxtNode);
      this.ranges.insertNode(newNode);
    }
    this.dialogOverlay();
  }

  public dialogOverlay(): void {
    const activeEle: Element = this.dialogObj.element.querySelector('.char_block.e-active');
    if (activeEle) {
      activeEle.classList.remove('e-active');
    }
    this.dialogObj.hide();
  }

  changeUpload(event, isUpload) {
    this.isUserSettingsTouched = true;
  }

  headerCellInfo(args) {
    if (args.cell.column.field === 'ShowInPortal') {
      const toolcontent = "Show In Portal";
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
  }

  userDrag(event) {
    this.isUserSettingsTouched = true;
    for (let index = 0; index < event.items.length; index++) {
      const element = event.items[index];
      const obj = this.sourceList.filter((item) => item['StaffID'] == element['StaffID']);
      if (obj && obj.length == 0) {
        this.sourceList.push(element);
      }
    }
  }

  validateEmailForm() {
    this.isUserSettingsTouched = true;
    const dataValues = Object.keys(this.emailTemplate);
    for (let index = 0; index < dataValues.length; index++) {
      const key = dataValues[index];
      if (!this.emailTemplate[key] || !this.emailEditorValue) {
        this.isUserSettingsTouched = false;
        break;
      }
    }
  }

  public dialogCreate(): void {
    const dialogCtn: HTMLElement = document.getElementById('rteSpecial_char');
    dialogCtn.onclick = (e: Event) => {
      const target: HTMLElement = e.target as HTMLElement;
      const activeEle: Element = this.dialogObj.element.querySelector('.char_block.e-active');
      if (target.classList.contains('char_block')) {
        target.classList.add('e-active');
        if (activeEle) {
          activeEle.classList.remove('e-active');
        }
      }
    };
  }

  settingContextMenuOpen(arg?: BeforeOpenCloseEventArgs): void {
    const elem: Element = arg.event.target as Element;
    const row: Element = elem.closest('.e-row');
    const uid: string = row && row.getAttribute('data-uid');
    if (elem.closest('.e-row')) {
      const rowData = this.treeSettingtempleteGrid.grid.getRowObjectFromUID(uid).data;
      document.querySelectorAll('li#dialognotification')[0].setAttribute('style', 'display:  none;');
      if (rowData['LineType'] == 'C') {
        document.querySelectorAll('li#dialognotification')[0].setAttribute('style', 'display:  block;');
      }
    } else {
      arg.cancel = true;
    }
  }

  applyYellowParentIDs(tableObj, propertyId) {
    const dataSet = tableObj['grid']['currentViewData'];
    const matchedArr = dataSet.filter((item) => item[propertyId] == null);
  }

  contextMenuOpen(arg?: BeforeOpenCloseEventArgs): void {
    const elem: Element = arg.event.target as Element;
    const row: Element = elem.closest('.e-row');
    const uid: string = row && row.getAttribute('data-uid');
    if (elem.closest('.e-row')) {
      let val: string = 'none';
      const rowData = this.treeGrid.grid.getRowObjectFromUID(uid).data;
      document.querySelectorAll('li#notification')[0].setAttribute('style', 'display:  none;');
      if (rowData['LineType'] == 'F') {
        document.querySelectorAll('li#settings')[0].setAttribute('style', 'display:  none;');
        document.querySelectorAll('li#uploadFile')[0].setAttribute('style', 'display:  none;');
        if (this.isRightPanelShow) {
          document.querySelectorAll('li#previewrow')[0].setAttribute('style', 'display:  none;');
          document.querySelectorAll('li#downloadrow')[0].setAttribute('style', 'display:  none;');
        } else {
          document.querySelectorAll('li#previewrow')[0].setAttribute('style', 'display:  block;');
          document.querySelectorAll('li#downloadrow')[0].setAttribute('style', 'display:  block;');
        }
        val = 'block';
      } else {
        document.querySelectorAll('li#uploadFile')[0].setAttribute('style', 'display:  block;');
        document.querySelectorAll('li#settings')[0].setAttribute('style', 'display:  block;');
        document.querySelectorAll('li#previewrow')[0].setAttribute('style', 'display:  none;');
        document.querySelectorAll('li#downloadrow')[0].setAttribute('style', 'display:  none;');
        if (rowData['LineType'] == 'C') {
          document.querySelectorAll('li#notification')[0].setAttribute('style', 'display:  block;');
        }
      }
      document.querySelectorAll('li#emailrow')[0].setAttribute('style', 'display:  ' + val + ';');
    } else {
      arg.cancel = true;
    }
  }

  closePreviewDialog() {
    this.isFilePreview = false;
    this.isPreviewViewerShow = false;
  }

  clearPwd() {
    this.filePasswordchecked = false;
    this.filePassword = '';
  }

  createWebPreviewViewer(docUrl) {
    const self = this;
    self.isPreviewViewerShow = true;

    if (self.wvPreviewInstance) {
      self.wvPreviewInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    } else {
      WebViewer({
        licenseKey: environment.PDF_WebViewer_KEY,
        path: '../../../../wv-resources/lib',
        initialDoc: docUrl
      }, self.preiewviewer.nativeElement).then(instance => {
        self.wvPreviewInstance = instance;
        self.wvPreviewInstance.disableElements(['leftPanel', 'leftPanelButton', 'panToolButton', 'toolsButton', 'signatureToolButton', 'freeHandToolGroupButton', , 'signatureTool', 'freeTextToolButton', 'eraserToolButton', 'shapeToolGroupButton', 'textToolGroupButton', 'miscToolGroupButton', 'stickyToolButton']);
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    }
  }

  contextMenuClick(args?: MenuEventArgs): void {
    const self = this;
    if (!args.item || !args.item.id) {
      return;
    }
    if (args.item.id == 'showpasswordrow') {
      const passcode = self.selectedRow['FilePassword'] ? self.selectedRow['FilePassword'] : '-';
      Swal.fire({
        icon: 'info',
        title: this.translate.instant('password'),
        html: '<strong class="text-success">' + passcode + '<strong>',
        showConfirmButton: true
      });
    }
    else if (args.item.id === 'emailrow') {
      self.emailTemplate['to'] = '';
      self.emailTemplate['from'] = self.userEmail;
      self.emailTemplate['fileName'] = self.selectedRow['FName'];
      self.filePasswordchecked = self.selectedRow['FilePassword'] ? true : false;
      self.emailObj.toolbarSettings.type = ToolbarType.MultiRow;

      self.isEmailDialog = true;
      self.filePassword = self.selectedRow['FilePassword'] ? self.selectedRow['FilePassword'] : "";
      self.filePasswordchecked = self.filePassword ? true : false;
      if (self.filePassword) {
        self.setPasswordToFile(self.filePassword);
      }
    } else if (args.item.id === 'settings' || args.item.id === 'inviteClient' || args.item.id === 'dialogsettings' || args.item.id === 'notification' || args.item.id === 'dialognotification') {
      self.clickHandler(args);
    }
    else if (args.item.id === 'downloadrow' && self.selectedRow && self.selectedRow['LineType'] == 'F') {
      self.downloadAWSFile();
    }
    else if (args.item.id === 'previewrow' && self.selectedRow && self.selectedRow['LineType'] == 'F') {
      if (self.selectedRow['FileType'] == 'csv' || self.selectedRow['FileType'] == 'rtf') {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('Warning'),
          text: this.translate.instant('dms.File_extension_CSV_not_supported'),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timer: 2000
        })
      } else {
        self.isFilePreview = true;
        self.showPreviewDocument(self.selectedRow['UniqueName']);
        setTimeout(() => {
          self.loadingDoc = false;
        }, 500);
      }
    } else if (args.item.id === 'uploadFile') {
      if (self.selectedRow && self.selectedRow['LineType'] == 'C' && self.selectedRow['isUploadEnable'] == true) {
        self.isUploadShow = true
      }
    }
  }

  showPreviewDocument(UniqueName): void {
    const params = { Bucket: 'mangobillings3', Key: 'documents/' + UniqueName };
    const self = this;
    self.loadingDoc = true;
    self.isViewerShow = true;

    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        $("#overlay").hide();
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.createWebPreviewViewer(docUrl);
        setTimeout(() => {
          self.loadingDoc = false;
        }, 500);
      },

      self.mangoAPISrvc
    );
  }

  saveSettingsData() {
    const parent = this;

    let rowData = {};
    let dataSet = [];
    const tempArr = [];
    if (parent.defaultPermisssionObj['EmailUploadTemplateID'] || parent.defaultPermisssionObj['EmailDownloadTemplateID'] || parent.settingsDisplay) {
      parent.settingsNotifications = false;
      parent.isUserSettingsTouched = false;
      parent.defaultPermisssionObj['CompanyID'] = parent.companyId;
      if (parent.defaultPermisssionObj['StaffID'].length > 0) {
        for (let index = 0; index < parent.defaultPermisssionObj['StaffID'].length; index++) {
          const element = parent.defaultPermisssionObj['StaffID'][index];
          if (element && element.StaffID) {
            tempArr.push(element.StaffID);
          }
        }
        parent.defaultPermisssionObj['StaffID'] = tempArr;
      } else {
        parent.defaultPermisssionObj['StaffID'] = null;
      }
      if (parent.defaultPermisssionObj['isNewRecord'] == true && parent.defaultPermisssionObj['dmsParentID'] == null) {
        parent.editClientTemplate(parent.defaultPermisssionObj);
        dataSet = parent.treeSettingtempleteGrid['grid']['currentViewData'];
        rowData = dataSet[parent.defaultPermisssionObj['RowIndex']];
        if (parent.defaultPermisssionObj['LineType'] == 'P' && parent.defaultPermisssionObj['ShowInPortal'] == false) {
          parent.applyClientTemplatesYellowChildrens(parent.defaultPermisssionObj, false);
        }
        else if (parent.defaultPermisssionObj['LineType'] == 'C' && parent.defaultPermisssionObj['ShowInPortal'] == true) {
          parent.applyClientTemplatesYellowChildrens(parent.defaultPermisssionObj, true);
        }

      } else if (parent.defaultPermisssionObj['dmsClientTemplatesID'] != null) {
        parent.editClientTemplate(parent.defaultPermisssionObj);
        dataSet = parent.treeSettingtempleteGrid['grid']['currentViewData'];
        rowData = dataSet[parent.defaultPermisssionObj['RowIndex']];

        if (parent.defaultPermisssionObj['LineType'] == 'P' && parent.defaultPermisssionObj['ShowInPortal'] == false) {
          parent.applyClientTemplatesYellowChildrens(parent.defaultPermisssionObj, false);
        } else if (parent.defaultPermisssionObj['LineType'] == 'C' && parent.defaultPermisssionObj['ShowInPortal'] == true) {
          parent.applyClientTemplatesYellowChildrens(parent.defaultPermisssionObj, true);
        }
      } else {
        let fileObj = [];
        parent.defaultPermisssionObj['ClientID'] = parent.selectedRow['ClientID'];
        dataSet = parent.treeGrid['grid']['currentViewData'];
        rowData = dataSet[parent.defaultPermisssionObj['RowIndex']];
        if (rowData['Children']) {
          fileObj = rowData['Children'].filter((item) => item['LineType'] == 'F');
        }
        if (rowData['Children'] && fileObj && fileObj.length > 0) {
          parent.updateBlueChildren(parent.defaultPermisssionObj);
        }
        else if (parent.defaultPermisssionObj['LineType'] == 'P' && parent.defaultPermisssionObj['ShowInPortal'] == false) {
          parent.applyParentYellowChildrens(parent.defaultPermisssionObj, false);
        } else if (parent.defaultPermisssionObj['LineType'] == 'C' && parent.defaultPermisssionObj['ShowInPortal'] == true) {
          parent.applyParentYellowChildrens(parent.defaultPermisssionObj, true);
        } else {
          parent.updateDMSParentFolder(parent.defaultPermisssionObj);
        }
      }

      rowData['isUpdated'] = true;
      rowData['ShowInPortal'] = this.defaultPermisssionObj['ShowInPortal'];
      rowData['admin_Read'] = this.defaultPermisssionObj['admin_Read'];
      rowData['admin_Delete'] = this.defaultPermisssionObj['admin_Delete'];
      rowData['admin_Create'] = this.defaultPermisssionObj['admin_Create'];
      rowData['admin_Upload'] = this.defaultPermisssionObj['admin_Upload'];
      rowData['admin_Download'] = this.defaultPermisssionObj['admin_Download'];

      rowData['mgr_Read'] = this.defaultPermisssionObj['mgr_Read'];
      rowData['mgr_Delete'] = this.defaultPermisssionObj['mgr_Delete'];
      rowData['mgr_Create'] = this.defaultPermisssionObj['mgr_Create'];
      rowData['mgr_Upload'] = this.defaultPermisssionObj['mgr_Upload'];
      rowData['mgr_Download'] = this.defaultPermisssionObj['mgr_Download'];

      rowData['standard_Read'] = this.defaultPermisssionObj['standard_Read'];
      rowData['standard_Delete'] = this.defaultPermisssionObj['standard_Delete'];
      rowData['standard_Create'] = this.defaultPermisssionObj['standard_Create'];
      rowData['standard_Upload'] = this.defaultPermisssionObj['standard_Upload'];
      rowData['standard_Download'] = this.defaultPermisssionObj['standard_Download'];

      rowData['limited_Read'] = this.defaultPermisssionObj['limited_Read'];
      rowData['limited_Delete'] = this.defaultPermisssionObj['limited_Delete'];
      rowData['limited_Create'] = this.defaultPermisssionObj['limited_Create'];
      rowData['limited_Upload'] = this.defaultPermisssionObj['limited_Upload'];
      rowData['limited_Download'] = this.defaultPermisssionObj['limited_Download'];

      if (parent.staffPermission == 1) {
        rowData['isUploadEnable'] = rowData['admin_Upload'];
      }
      else if (parent.staffPermission == 2) {
        rowData['isUploadEnable'] = rowData['mgr_Upload'];
      }
      else if (parent.staffPermission == 3) {
        rowData['isUploadEnable'] = rowData['standard_Upload'];
      }
      else if (parent.staffPermission == 4) {
        rowData['isUploadEnable'] = rowData['limited_Upload'];
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('dms.Email_templates_must_be_selected'),
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timer: 2000
      })
    }
  }

  applyAll(str) {
    this.isUserSettingsTouched = true;
    this.defaultPermisssionObj[str + 'Read'] = this.defaultPermisssionObj[str + 'Full'];
    this.defaultPermisssionObj[str + 'Create'] = this.defaultPermisssionObj[str + 'Full'];
    this.defaultPermisssionObj[str + 'Delete'] = this.defaultPermisssionObj[str + 'Full'];
    this.defaultPermisssionObj[str + 'Upload'] = this.defaultPermisssionObj[str + 'Full'];
    this.defaultPermisssionObj[str + 'Download'] = this.defaultPermisssionObj[str + 'Full'];
  }

  checkPermission(obj, str, isform) {
    this.isUserSettingsTouched = true;
    if (isform) {
      if (obj[str + 'Read'] == false || obj[str + 'Create'] == false || obj[str + 'Delete'] == false || obj[str + 'Upload'] == false || obj[str + 'Download'] == false) {
        this.myGroupForm.controls[str + 'Full'].setValue(false);
      } else {
        this.myGroupForm.controls[str + 'Full'].setValue(true);
      }
    } else {
      if (obj[str + 'Download'] == false || obj[str + 'Upload'] == false || obj[str + 'Read'] == false || obj[str + 'Create'] == false || obj[str + 'Delete'] == false) {
        obj[str + 'Full'] = false;
      } else {
        obj[str + 'Full'] = true;
      }
    }
  }

  changeRow(rowItem, isFull) {
    this.isUserTableChanged = true;
    rowItem['isRowChanged'] = true;
    if (isFull) {
      rowItem['Read'] = rowItem['Full'];
      rowItem['Create'] = rowItem['Full'];
      rowItem['Delete'] = rowItem['Full'];
      rowItem['Upload'] = rowItem['Full'];
      rowItem['Download'] = rowItem['Full'];
    } else {
      if (rowItem['Read'] == false || rowItem['Create'] == false || rowItem['Delete'] == false || rowItem['Download'] == false || rowItem['Upload'] == false) {
        rowItem['Full'] = false;
      } else {
        rowItem['Full'] = true;
      }
    }
  }

  initializeGroupForm() {
    this.myGroupForm = this._fb.group({
      dmsGroupPermissionsID: [null],
      CompanyID: [this.companyId],
      admin_Full: [false],
      mgr_Full: [false],
      standard_Full: [false],
      limited_Full: [false],
      Download_Full: [false],
      Upload_Full: [false],
      admin_Read: [true],
      admin_Create: [true],
      admin_Delete: [true],
      admin_Upload: [true],
      admin_Download: [true],
      mgr_Read: [true],
      mgr_Create: [true],
      mgr_Delete: [true],
      mgr_Upload: [true],
      mgr_Download: [true],
      standard_Read: [true],
      standard_Create: [true],
      standard_Delete: [true],
      standard_Upload: [true],
      standard_Download: [true],
      limited_Read: [false],
      limited_Create: [false],
      limited_Delete: [false],
      limited_Upload: [true],
      limited_Download: [true],
    });

  }

  applyFormAll(str) {
    this.myGroupForm.controls[str + 'Read'].setValue(this.myGroupForm.value[str + 'Full']);
    this.myGroupForm.controls[str + 'Create'].setValue(this.myGroupForm.value[str + 'Full']);
    this.myGroupForm.controls[str + 'Delete'].setValue(this.myGroupForm.value[str + 'Full']);
    this.myGroupForm.controls[str + 'Upload'].setValue(this.myGroupForm.value[str + 'Full']);
    this.myGroupForm.controls[str + 'Download'].setValue(this.myGroupForm.value[str + 'Full']);
  }

  rowDropEvent(args: RowDragEventArgs) {
    const dropID = $(args.target).closest(".e-row").find(".e-rowcell").attr('rowUniqueID');
    if (dropID) {
      const dropObj = this.mangoUtils.findNestedObj(this.treeGrid.dataSource, 'dmsParentID', parseInt(dropID));
      if (dropObj && dropObj.ParentID == null && args.data[0]['ParentID'] == null && args['dropPosition'] != 'topSegment') {
        args.cancel = true;
        this.mangoAPISrvc.notify('warn', this.translate.instant('Warning'), this.translate.instant('dms.A_Parent_Folder_can_not_be_added'));
      } else if (args.data[0]['LineType'] == 'F' && dropObj.LineType == 'F' && args['dropPosition'] != 'topSegment') {
        args.cancel = true;
        this.mangoAPISrvc.notify('warn', this.translate.instant('Warning'), this.translate.instant('dms.A_File_can_not_be_added'));
      } else {
        this.selectedParentId = args.data[0]['dmsParentID'];
        args.data[0]['ModifyBy'] = this.userName;
        this.isDropAction = true;
      }
    } else {
      this.selectedParentId = args.data[0]['dmsParentID'];
      args.data[0]['ModifyBy'] = this.userName;
      this.isDropAction = true;
    }

  }

  public focusIn(target: HTMLElement): void {
    target.parentElement.classList.add('e-input-focus');
  }

  public focusOut(target: HTMLElement): void {
    target.parentElement.classList.remove('e-input-focus');
  }

  closeDialog() {
    this.visibleSidebar = this.isUserTableChanged = false;
    this.settingsRightSideData = [];
    //this.settingsData = [];
    this.isShowGrid = this.showRightContent = this.showDuplicate = this.loading = false;
    this.selectedSettings = { "TemplateName": "", "readOnly": true };
    this.settingsDisplay = this.isUserSettingsTouched = false;
    this.treetempleteGrid.clearSelection();
  }

  hideRightPanel() {
    this.isRightPanelShow = !this.isRightPanelShow;
    if (this.isRightPanelShow) {
      this.panelsSettings.panel2_size = '60%';
      this.panelsSettings.panel3_size = '40%';
    } else {
      this.panelsSettings.panel2_size = '100%'
      this.panelsSettings.panel3_size = '1%';
    }
  }

  onClientFilter(obj) {
    this.clientfilteredItemsSize = obj.filteredValue.length;
  }

  onDuplicateClientFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  clearClientsSearchFilter() {
    this.searchClientValue.nativeElement.value = "";
    this.dataTabclientsList.reset();
    this.clientfilteredItemsSize = -1;
  }

  clearTaskSearchFilter() {
    this.searchTaskValue.nativeElement.value = "";
    this.dataTabTaskclientsList.reset();
    this.filteredItemsSize = -1;
  }

  openDialog() {
    this.visibleSidebar = true;
  }

  async setPasswordToDoc(docUrl, passWordStr) {
    const self = this;
    const { PDFNet } = self.dummyPDFInstance;
    await PDFNet.initialize();
    const doc = await PDFNet.PDFDoc.createFromURL(docUrl);// aws file location url
    doc.initSecurityHandler();
    doc.lock();
    const newHandler = await PDFNet.SecurityHandler.createDefault();
    newHandler.changeUserPasswordUString(passWordStr);
    console.log("Setting password to >> " + passWordStr);
    newHandler.setPermission(PDFNet.SecurityHandler.Permission.e_print, false);
    newHandler.setPermission(PDFNet.SecurityHandler.Permission.e_extract_content, true);
    doc.setSecurityHandler(newHandler);

    const docbuf = await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);
    self.saveBufferAsPDFDoc(docbuf, self.selectedRow['UniqueName']);
  }

  saveBufferAsPDFDoc(buf, name) {
    this.saveBuffer(buf, name, 'application/pdf');
  }

  saveBuffer(buf, name, mimetype) {
    const self = this;
    const blob = new Blob([buf], {
      type: mimetype,
    });
    self.fileBlob = new File([blob], self.selectedRow['FName']);
    self.isShowPassword = false;
  }

  showPassword() {
    const self = this;
    self.isShowPassword = true;
    self.filePassword = self.selectedRow['FilePassword'] ? self.selectedRow['FilePassword'] : "";
    self.filePasswordchecked = self.filePassword ? true : false;
  }

  savePassword() {
    const self = this;
    self.selectedRow['FilePassword'] = self.filePassword;
    self.filePasswordchecked = self.selectedRow['FilePassword'] ? true : false;
    if (self.filePassword) {
      self.setPasswordToFile(self.filePassword);
    } else {
      self.fileBlob = null;
      self.filePasswordchecked = self.isShowPassword = false;
    }
  }

  async setPasswordToFile(passWordStr) {
    const self = this;
    const params = {
      Bucket: 'mangobillings3', Expires: 600000,
      ResponseContentDisposition: 'attachment; filename ="' + this.selectedRow['FName'] + '"',
      Key: 'documents/' + this.selectedRow['UniqueName']
    };
    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        self.isEmailDialog = true;
        self.createDummyPDFInstance(docUrl, passWordStr);
      },

      self.mangoAPISrvc
    );
  }

  createWebViewer(docUrl) {
    const self = this;
    if (self.wvInstance) {
      self.wvInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });

        // var FitMode = instance.FitMode;
        // instance.setZoomLevel('100%');//(FitMode.FitWidth); // FitPage
      });
    } else {
      WebViewer({
        licenseKey: environment.PDF_WebViewer_KEY,
        path: '../../../../wv-resources/lib',
        initialDoc: docUrl
      }, self.viewer.nativeElement).then(instance => {
        self.wvInstance = instance;
        self.wvInstance.disableElements(['leftPanel', 'leftPanelButton', 'panToolButton', 'toolsButton', 'AnnotationEdit', 'AnnotationCreateFreeText', 'signatureTool', 'freeTextToolButton', 'eraserToolButton', 'freeHandToolGroupButton', 'freeTextToolButton', 'shapeToolGroupButton', 'textToolGroupButton', 'miscToolGroupButton', 'stickyToolButton']);
        // var FitMode = self.wvInstance.FitMode;
        // self.wvInstance.setZoomLevel('100%');//setFitMode(FitMode.FitWidth); // FitPage

        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    }
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = 'us-east-1'
    this.awsBucket = new AWS.S3({ params: { Bucket: 'mangobillings3' } });
    */
    this.awsBucket = (
      AWSBucketMask
    );
  }

  showDocument(): void {
    const params = { Bucket: 'mangobillings3', Key: 'documents/' + this.selectedRow['UniqueName'] };
    const self = this;
    self.loadingDoc = true;
    self.isViewerShow = true;

    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        $("#overlay").hide();
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.createWebViewer(docUrl);
        setTimeout(() => {
          self.loadingDoc = false;
        }, 500);

      },

      self.mangoAPISrvc
    );
  }
  async createDummyPDFInstance(docUrl, passWordStr) {
    const self = this;
    if (self.dummyPDFInstance) {
      self.setPasswordToDoc(docUrl, passWordStr);
    }
    else {
      WebViewer({
        licenseKey: environment.PDF_WebViewer_KEY,
        path: '../../../../wv-resources/lib',
        initialDoc: docUrl,
        fullAPI: true
      }, self.dummyViewer.nativeElement).then(instance => {
        self.dummyPDFInstance = instance;
      });
    }
  }

  async onSelect(event) {
    if (this.selectedRow == null || (this.selectedRow && this.selectedRow['isUploadEnable'] == false)) {
      return false;
    }
    const dataSet = this.treeGrid['grid']['currentViewData'];
    const dropObj = this.mangoUtils.findNestedObj(dataSet, 'dmsParentID', parseInt(this.selectedRow['dmsParentID']));
    const listOfAcceptedFiles = ['pdf', 'PDF', 'bmg', 'BMG', 'txt', 'TXT', 'xls', 'XLS', 'xlsx', 'doc', 'DOC', 'docx', 'DOCX', 'jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'].toString();
    const filePos = event.addedFiles[0].name.lastIndexOf('.');
    const fileType = event.addedFiles[0].name.substring(filePos + 1, event.addedFiles[0].name.length);
    const fileSize = event.addedFiles[0].size / 1048576; //size in mb
    if (event.addedFiles && listOfAcceptedFiles.indexOf(fileType) > -1 && fileSize <= 30) {
      this.files.push(...event.addedFiles);
      //this.file = event.addedFiles[0];
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index];
        setTimeout(() => {
          this.uploadFile(file, dropObj);
        }, 200);
      }

    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('accepted-files'),
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('ok'),
        timer: 2000
      })
    }

  }

  disableToolBars(isNoData) {
    if (!isNoData) {
      this.treeGrid.element.classList.remove('e-overlay');
      document.getElementById('treeparent').classList.remove('wrapper');
    } else {
      this.treeGrid.element.classList.add('e-overlay');
      document.getElementById('treeparent').classList.add('wrapper');
    }
  }

  removeToolBars(isHideToolBar) {
    //this.showSaveButtons = !isHideToolBar;
    if (isHideToolBar) {
      $('.rightSideGrid #_gridcontrol_toolbarItems').hide();
      $('.rightSideGrid .e-lastcell').hide();
      $('.rightSideGrid .e-unboundcell').hide();
    } else {
      $('.rightSideGrid #_gridcontrol_toolbarItems').show();
      $('.rightSideGrid .e-lastcell').show();
      $('.rightSideGrid .e-unboundcell').show();
    }
  }

  hideNotifications() {
    this.sourceList = Object.assign([], this.usersList);
    this.settingsNotifications = this.isUserSettingsTouched = false;
  }

  handleChange(event) {
    this.editorValue = null;
    this.selectedObjs = [];
    if (event.index == 1) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getGroupPermissions().subscribe((response: any) => {
        if (Object.keys(response).length > 0) {
          this.myGroupForm.markAsPristine();
          this.myGroupForm.markAsUntouched();
          this.myGroupForm.controls['dmsGroupPermissionsID'].setValue(response['dmsGroupPermissionsID']);
          this.myGroupForm.controls['CompanyID'].setValue(response['CompanyID']);
          this.myGroupForm.controls['admin_Read'].setValue(response['admin_Read']);
          this.myGroupForm.controls['admin_Create'].setValue(response['admin_Create']);
          this.myGroupForm.controls['admin_Delete'].setValue(response['admin_Delete']);
          this.myGroupForm.controls['admin_Download'].setValue(response['admin_Download']);
          this.myGroupForm.controls['admin_Upload'].setValue(response['admin_Upload']);
          this.myGroupForm.controls['mgr_Read'].setValue(response['mgr_Read']);
          this.myGroupForm.controls['mgr_Create'].setValue(response['mgr_Create']);
          this.myGroupForm.controls['mgr_Delete'].setValue(response['mgr_Delete']);
          this.myGroupForm.controls['mgr_Download'].setValue(response['mgr_Download']);
          this.myGroupForm.controls['mgr_Upload'].setValue(response['mgr_Upload']);
          this.myGroupForm.controls['standard_Read'].setValue(response['standard_Read']);
          this.myGroupForm.controls['standard_Create'].setValue(response['standard_Create']);
          this.myGroupForm.controls['standard_Delete'].setValue(response['standard_Delete']);
          this.myGroupForm.controls['standard_Download'].setValue(response['standard_Download']);
          this.myGroupForm.controls['standard_Upload'].setValue(response['standard_Upload']);
          this.myGroupForm.controls['limited_Read'].setValue(response['limited_Read']);
          this.myGroupForm.controls['limited_Create'].setValue(response['limited_Create']);
          this.myGroupForm.controls['limited_Delete'].setValue(response['limited_Delete']);
          this.myGroupForm.controls['limited_Upload'].setValue(response['limited_Upload']);
          this.myGroupForm.controls['limited_Download'].setValue(response['limited_Download']);

          const formData = this.myGroupForm.value;

          this.myGroupForm.controls['standard_Full'].setValue(formData['standard_Read'] && formData['standard_Delete'] && formData['standard_Create']);
          this.myGroupForm.controls['limited_Full'].setValue(formData['limited_Read'] && formData['limited_Delete'] && formData['limited_Create']);
          this.myGroupForm.controls['mgr_Full'].setValue(formData['mgr_Read'] && formData['mgr_Delete'] && formData['mgr_Create']);
          this.myGroupForm.controls['admin_Full'].setValue(formData['admin_Read'] && formData['admin_Delete'] && formData['admin_Create']);

        } else {
          this.initializeGroupForm();
        }
        this.mangoAPISrvc.showLoader(false);
      });
    } else if (event.index == 2) {
      this.loading = true;
      this.isUserTableChanged = false;
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getListOfUsers().subscribe((response: any) => {
        response.map(function (obj, index) {
          obj['Create'] = obj['Create'] ? obj['Create'] : false;
          obj['Delete'] = obj['Delete'] ? obj['Delete'] : false;
          obj['Read'] = obj['Read'] ? obj['Read'] : false;
          obj['Upload'] = obj['Upload'] ? obj['Upload'] : false;
          obj['Download'] = obj['Download'] ? obj['Download'] : false;
          obj['Full'] = (obj['Create'] && obj['Delete'] && obj['Read'] && obj['Download'] && obj['Upload']);
          return obj;
        });
        this.usersList = response.filter((item) => { return item.Inactive == false });
        this.loading = false;
        this.mangoAPISrvc.showLoader(false);
      });
    }
  }

  queryCellInfo(args: QueryCellInfoEventArgs): void {
    $(args.cell).attr('rowUniqueID', args.data['dmsParentID']);
    if (args.column.field === 'FName') {
      const imgElement: HTMLElement = document.createElement('i');
      if (args.data['LineType'] == 'P') {
        args.data['Size'] = "";
        imgElement.setAttribute('class', 'fa fa-folder ic-size-15 ic-yellow cursor-hand');
      } else if (args.data['LineType'] == 'C') {
        args.data['Size'] = "";
        imgElement.setAttribute('class', 'fa fa-folder ic-size-15 ic-blue cursor-hand');
      } else {
        if (args.data['LineType'] == 'F') {
          if (args.data['FileType'] == 'doc' || args.data['FileType'] == 'docx' || args.data['FileType'] == 'rtf') {
            imgElement.setAttribute('class', 'fa fa-file-word-o ic-size-15 folder-file doc cursor-hand');
          } else if (args.data['FileType'] == 'xls' || args.data['FileType'] == 'xlsx' || args.data['FileType'] == 'csv') {
            imgElement.setAttribute('class', 'fa fa-file-excel-o ic-size-15 folder-file excel cursor-hand');
          }
          else if (args.data['FileType'] == 'jpeg' || args.data['FileType'] == 'jpg' || args.data['FileType'] == 'png' || args.data['FileType'] == 'bmp' || args.data['FileType'] == 'tif') {
            imgElement.setAttribute('class', 'fa fa-file-image-o ic-size-15 folder-file imges cursor-hand');
          }
          else if (args.data['FileType'] == 'txt') {
            imgElement.setAttribute('class', 'fa fa-file-text-o ic-size-15 folder-file cursor-hand');
          } else if (args.data['FileType'] == 'pdf') {
            imgElement.setAttribute('class', 'fa fa-file-pdf-o ic-size-15 folder-file pdf cursor-hand');
          }
        }
      }

      const div: HTMLElement = document.createElement('DIV');
      div.style.display = 'inline';
      div.appendChild(imgElement);
      const cellValue: HTMLElement = document.createElement('DIV');
      cellValue.innerHTML = args.cell.querySelector('.e-treecell').innerHTML;
      if (args.data['LineType'] == 'P') {
        cellValue.setAttribute('style', 'display:inline;padding-left:6px;font-weight:600;cursor: pointer;');
      } else {
        cellValue.setAttribute('style', 'display:inline;padding-left:6px;cursor: pointer;');
      }
      args.cell.querySelector('.e-treecell').innerHTML = '';
      args.cell.querySelector('.e-treecell').appendChild(div);
      args.cell.querySelector('.e-treecell').appendChild(cellValue);
    } else if (args.column.field === 'FolderName' || args.column.field === 'TemplateName') {
      const imgElement: HTMLElement = document.createElement('i');
      if (args.data['ParentID'] == null) {
        imgElement.setAttribute('class', 'fa fa-folder ic-size-15 ic-yellow cursor-hand');
      } else if (args.data['ParentID'] != null) {
        imgElement.setAttribute('class', 'fa fa-folder ic-size-15 ic-blue cursor-hand');
      }

      const div: HTMLElement = document.createElement('DIV');
      div.style.display = 'inline';
      div.appendChild(imgElement);
      const cellValue: HTMLElement = document.createElement('DIV');
      cellValue.innerHTML = args.cell.querySelector('.e-treecell').innerHTML;
      if (args.data['ParentID'] == null) {
        cellValue.setAttribute('style', 'display:inline;padding-left:6px;font-weight:600;cursor: pointer;');
      } else {
        cellValue.setAttribute('style', 'display:inline;padding-left:6px;cursor: pointer;');
      }
      args.cell.querySelector('.e-treecell').innerHTML = '';
      args.cell.querySelector('.e-treecell').appendChild(div);
      args.cell.querySelector('.e-treecell').appendChild(cellValue);
    }
  }

  getEmailSignature() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailSignature(parent.emailSingnatureObj.StaffID).subscribe((response: any) => {
      parent.emailSingnatureObj = response;
      parent.emailEditorValue = response.HtmlBodySignature ? response.HtmlBodySignature : null;
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  saveGroupData(value) {
    const self = this;
    self.loadGroupPermissionsObj = self.myGroupForm.value;
    self.mangoAPISrvc.showLoader(true);
    if (value['dmsGroupPermissionsID'] != null) {
      self.mangoAPISrvc.updateGroupPermissions(value).subscribe(function (data) {
        self.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.updateMsg);
        self.mangoAPISrvc.showLoader(false);
      }, error => {
        self.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      });
    } else {
      self.mangoAPISrvc.createGroupPermissions(value).subscribe(function (data) {
        self.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.createMsg);
        self.mangoAPISrvc.showLoader(false);
      }, error => {
        self.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      });
    }
  }

  saveUserSettings() {
    const self = this;
    self.loading = true;
    const apisArr = [];
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', self.encrDecSrvc.getObject('token'));

    for (let index = 0; index < self.usersList.length; index++) {
      const element = self.usersList[index];
      if (element['isRowChanged'] == true) {
        element['isRowChanged'] = false;
        const urlOne = self.http.put(`${environment.API_URL}/staff/users/${element.StaffID}`, element, { 'headers': headers });
        apisArr.push(urlOne)
      }
    }
    forkJoin(apisArr).subscribe(
      data => {
        self.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.updateMsg);
        self.isUserTableChanged = self.loading = false;
      },
      err => console.error(err)
    );
  }

  bulkSave(isSingle) {
    const self = this;
    let payload = {};
    self.idMap = {};
    self.loading = true;
    const defaultObj = { "ClientID": null, "FName": null, "CompanyID": self.companyId, "isFullAccess": false, "isPrivate": false, "isRead": false, "isCreate": false, "LineItem": null, "isDelete": false, "isEdit": false, "isView": false, "isViewInPortal": false, "isClientUpload": false, "isClientDelete": false, "ParentID": null, "FileName": null, "Size": 0, "ModifyBy": self.userName, "ModifyOn": new Date(), "CreatedOn": new Date(), "FileType": null, "isDownload": false, "LineType": null };

    const observableBatch = [];
    const clientIds = [];
    const parentItems = [];
    const childItems = [];
    if (!isSingle) {
      clientIds.push(self.selectedClientID);
    } else {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        clientIds.push(clientObj.ClientID);
      }
    }

    for (let index = 0; index < self.settingsRightSideData.length; index++) {
      const element = self.settingsRightSideData[index];
      const rowData = Object.assign({}, defaultObj);
      rowData.FName = element['FolderName'];
      rowData.ParentID = element['ParentID'];
      rowData['YellowParentID'] = element['ParentID'];
      rowData['dmsClientTemplatesID'] = element['dmsClientTemplatesID'];
      rowData.LineType = element['ParentID'] == null ? 'P' : 'C';
      rowData.LineItem = element['ParentID'] == null ? 1 : null;
      rowData['admin_Read'] = element['admin_Read'];//self.myGroupForm.value['admin_Read'];
      rowData['admin_Delete'] = element['admin_Delete'];
      rowData['admin_Create'] = element['admin_Create'];
      rowData['admin_Download'] = element['admin_Download'];
      rowData['admin_Upload'] = element['admin_Upload'];

      rowData['mgr_Read'] = element['mgr_Read'];
      rowData['mgr_Delete'] = element['mgr_Delete'];
      rowData['mgr_Create'] = element['mgr_Create'];
      rowData['mgr_Download'] = element['mgr_Download'];
      rowData['mgr_Upload'] = element['mgr_Upload'];

      rowData['standard_Read'] = element['standard_Read'];
      rowData['standard_Delete'] = element['standard_Delete'];
      rowData['standard_Create'] = element['standard_Create'];
      rowData['standard_Download'] = element['standard_Download'];
      rowData['standard_Upload'] = element['standard_Upload'];

      rowData['limited_Read'] = element['limited_Read'];
      rowData['limited_Delete'] = element['limited_Delete'];
      rowData['limited_Create'] = element['limited_Create'];
      rowData['limited_Download'] = element['limited_Download'];
      rowData['limited_Upload'] = element['limited_Upload'];
      rowData['ShowInPortal'] = element['ShowInPortal'];
      rowData['EmailUploadTemplateID'] = element['EmailUploadTemplateID'];
      rowData['EmailDownloadTemplateID'] = element['EmailDownloadTemplateID'];

      if (rowData.ParentID === null) {
        self.idMap['root'] = rowData;
      } else {
        if (self.idMap[rowData.ParentID]) {
          self.idMap[rowData.ParentID].push(rowData)
        } else {
          self.idMap[rowData.ParentID] = [
            rowData
          ]
        }
      }
    }

    const parentObject = self.settingsRightSideData.find(eachNode => eachNode['ParentID'] === null);

    payload = { ...self.addChilds(parentObject) };

    observableBatch.push({ 'clientIds': clientIds.toString(), 'dataSource': payload, 'size': self.settingsRightSideData.length - 1 });
    this.intervalId = setInterval(() => self.createClientTemplateNames(observableBatch, isSingle), 2000);
  }

  addChilds(parentObject) {
    const self = this;
    const id = parentObject.dmsClientTemplatesID;
    const children = self.idMap[id];
    parentObject.children = [];
    if (children && children.length > 0) {
      for (let childIndex = 0; childIndex < children.length; childIndex++) {
        const element = children[childIndex];
        parentObject.children.push(self.addChilds(element));
      }
    }
    return parentObject;
  }

  bulkUpdateParentFolder() {
    const parent = this;
    const dataSet = parent.treeGrid['grid']['currentViewData'];
    dataSet.map(function (obj, index) {
      obj['LineItem'] = index;
      if (obj['dmsParentID'] == parent.selectedParentId) {
        obj['ModifyBy'] = parent.userName;
      }
      return obj;
    });
    const observableBatch = parent.mangoUtils.deepCloneObject(dataSet);
    if (observableBatch.length == 0) {
      return false
    }
    for (let i = 0; i < observableBatch.length; i++) {
      const dataToUpdate = observableBatch[i];
      delete dataToUpdate["Children"];
      delete dataToUpdate["childRecords"];
      delete dataToUpdate["taskData"];
      delete dataToUpdate["uniqueID"];
      delete dataToUpdate["parentItem"];
      delete dataToUpdate["parentUniqueID"];
      delete dataToUpdate["UniqueName"];
      delete dataToUpdate["hasFilteredChildRecords"];
      delete dataToUpdate["hasChildRecords"];
      delete dataToUpdate["index"];
      delete dataToUpdate["expanded"];
      delete dataToUpdate["uncheck"];
      delete dataToUpdate["filterLevel"];
      delete dataToUpdate["level"];
    }

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.bulkUpdateParentFolder({ observableBatch }).subscribe((objItem) => {
      parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.updateMsg);
      parent.mangoAPISrvc.showLoader(false);
    }, err => parent.mangoAPISrvc.showLoader(false));
  }

  bulkDelete(filesList): void {
    const self = this;
    const params = { Bucket: 'mangobillings3', Delete: { Objects: [] } };
    for (let index = 0; index < filesList.length; index++) {
      const element = filesList[index];
      const obj = {};
      obj['Key'] = 'documents/' + element;
      params.Delete.Objects.push(obj);
    }
    self.awsBucket.deleteObjects(
      params,

      function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);           // successful response
      },

      self.mangoAPISrvc
    );

  }

  deleteAWSFile(fileName): void {
    const params = { Bucket: 'mangobillings3', Key: 'documents/' + fileName };
    const self = this;
    $("#overlay").show();
    self.awsBucket.deleteObject(
      params,

      function (err, data) {
        $("#overlay").hide();
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
      },

      self.mangoAPISrvc
    );
  }

  downloadAWSFile(): void {
    const self = this;

    for (let index = 0; index < this.rowIndexes.length; index++) {
      const rowObj = this.rowIndexes[index];
      const params = {
        Bucket: 'mangobillings3', Expires: 600,
        ResponseContentDisposition: 'attachment; filename ="' + rowObj['FName'] + '"',
        Key: 'documents/' + rowObj['UniqueName']
      };

      $("#overlay").show();

      self.awsBucket.getSignedUrl(
        'getObject',

        params,

        function (err, data) {
          $("#overlay").hide();
          if (err) {
            console.log("error while saving file on s3 server", err);
            return;
          }
          setTimeout(() => {
            const dataSet = self.treeGrid['grid']['currentViewData'];
            const parentObj = dataSet.filter((item) => item['dmsParentID'] == rowObj['ParentID'])[0];
            $('<iframe>', { id: 'idown', src: data }).hide().appendTo('body').click();
            self.sendMail('download', parentObj);
          }, 50);
        },

        self.mangoAPISrvc
      );
    }
  }

  updateBlueChildren(obj) {
    const parent = this;
    const reqObj = {};
    reqObj['ShowInPortal'] = obj['ShowInPortal'] ? obj['ShowInPortal'] : false;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateBlueChildren(reqObj, obj.dmsParentID).subscribe((response) => {
      parent.updateDMSParentFolder(obj);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  async uploadFile(fileObj, dropObj) {
    const self = this;
    const UniqueName = this.mangoUtils.generateUUID() + '_' + fileObj.name;
    const filePos = fileObj.name.lastIndexOf('.');
    const fileType = fileObj.name.substring(filePos + 1, fileObj.name.length);
    localStorage.setItem('selectedParentTableRow', self.selectedRow['rowIndex']);
    const params = { Bucket: 'mangobillings3', Key: 'documents/' + UniqueName, Body: fileObj };
    $("#overlay").show();
    self.awsBucket.upload(
      params,

      function (err, data) {
        $("#overlay").hide();

        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.onRemove(fileObj);
        setTimeout(() => {
          const cloneObj = Object.assign({}, dropObj);
          cloneObj.FName = fileObj.name;
          cloneObj.UniqueName = UniqueName;
          cloneObj.Size = self.mangoUtils.formatBytes(fileObj.size, 0);
          cloneObj.ModifyOn = cloneObj.CreatedOn = new Date();
          cloneObj.FileType = fileType;
          cloneObj.LineType = 'F';
          cloneObj.YellowParentID = dropObj.dmsParentID;
          cloneObj['ModifyBy'] = self.userName;
          cloneObj.ParentID = dropObj.dmsParentID;
          cloneObj['ShowInPortal'] = cloneObj['ShowInPortal'] ? cloneObj['ShowInPortal'] : false;
          cloneObj['EmailUploadTemplateID'] = cloneObj['EmailUploadTemplateID'];
          cloneObj['EmailDownloadTemplateID'] = cloneObj['EmailDownloadTemplateID'];
          cloneObj['isLastRecord'] = self.files.length == 0 ? true : false;
          self.addDMSParentFolder(cloneObj, cloneObj['isLastRecord']);
          if (self.files.length == 0) {
            const obj = Object.assign({}, self.selectedRow);
            obj['FName'] = cloneObj.FName;
            obj['ParentID'] = dropObj.dmsParentID;
            self.sendMail('upload', obj);
          }
          self.isUploadShow = false;
        }, 200);
      },

      self.mangoAPISrvc
    );
  }

  addDMSParentFolder(obj, isLastRecord?: any) {
    const parent = this;
    // this.treeGrid.showSpinner();
    $('.e-gridcontent').css("opacity", ".1");
    parent.data = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.addDMSParentFolder(obj).subscribe((response) => {
      if (!isLastRecord) {

      }
      // update blue folder
      else if (obj.LineType = 'F' && obj.dmsParentID) {
        const reqObj = {};
        reqObj['ShowInPortal'] = obj['ShowInPortal'] ? obj['ShowInPortal'] : false;
        parent.mangoAPISrvc.updateBlueChildren(reqObj, obj.dmsParentID).subscribe((response: any) => {
          parent.reloadParentGrid(obj.ClientID);
        });
      } else {
        parent.reloadParentGrid(obj.ClientID);
      }
    });
  }

  reloadParentGrid(clientId){
    const parent = this;
    $('.e-gridcontent').css("opacity", ".1");
    parent.mangoAPISrvc.getDMSFilesByClientId(clientId).subscribe((result: any) => {
      $('.e-gridcontent').css("opacity", "1");
      parent.data = result;
      setTimeout(() => {
        if(parent.selectedExpandRowIndex > -1){
          parent.treeGrid.expandRow(parent.treeGrid.getRows()[this.selectedExpandRowIndex]);
        }
      }, 100);
      //parent.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), AppConstants.createMsg);
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  getUsersList(response) {
    response.map(function (obj, index) {
      obj['Create'] = obj['Create'] ? obj['Create'] : false;
      obj['Delete'] = obj['Delete'] ? obj['Delete'] : false;
      obj['Read'] = obj['Read'] ? obj['Read'] : false;
      obj['Upload'] = obj['Upload'] ? obj['Upload'] : false;
      obj['Download'] = obj['Download'] ? obj['Download'] : false;
      obj['Full'] = (obj['Create'] && obj['Delete'] && obj['Read'] && obj['Download'] && obj['Upload']);
      obj['ProfilePic'] = obj['ProfilePic'] ? obj['ProfilePic'] : 'assets/img/userForTable.png';
      return obj;
    });
    this.usersList = response.filter((item) => { return item.Inactive == false });
    this.loading = false;
  }

  addToMyTemplate() {
    const parent = this;
    // parent.treeSettingtempleteGrid.showSpinner();
    // parent.treetempleteGrid.showSpinner();
    const obj = { "TemplateName": parent.selectedSettings['TemplateName'], "CompanyID": parent.companyId };
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createClientTemplateNames(obj).subscribe((response: any) => {
      let parentObj = {};
      const childObj = [];

      for (let index = 0; index < parent.settingsRightSideData.length; index++) {
        const element = parent.settingsRightSideData[index];
        element['dmsClientTemplateNameID'] = response.data.dmsClientTemplateNameID;
        element['CompanyID'] = parent.companyId;
        element['admin_Read'] = parent.myGroupForm.value['admin_Read'];
        element['admin_Delete'] = parent.myGroupForm.value['admin_Delete'];
        element['admin_Create'] = parent.myGroupForm.value['admin_Create'];
        element['admin_Upload'] = parent.myGroupForm.value['admin_Upload'];
        element['admin_Download'] = parent.myGroupForm.value['admin_Download'];

        element['mgr_Read'] = parent.myGroupForm.value['mgr_Read'];
        element['mgr_Delete'] = parent.myGroupForm.value['mgr_Delete'];
        element['mgr_Create'] = parent.myGroupForm.value['mgr_Create'];
        element['mgr_Upload'] = parent.myGroupForm.value['mgr_Upload'];
        element['mgr_Download'] = parent.myGroupForm.value['mgr_Download'];

        element['standard_Read'] = parent.myGroupForm.value['standard_Read'];
        element['standard_Delete'] = parent.myGroupForm.value['standard_Delete'];
        element['standard_Create'] = parent.myGroupForm.value['standard_Create'];
        element['standard_Upload'] = parent.myGroupForm.value['standard_Upload'];
        element['standard_Download'] = parent.myGroupForm.value['standard_Download'];

        element['limited_Read'] = parent.myGroupForm.value['limited_Read'];
        element['limited_Delete'] = parent.myGroupForm.value['limited_Delete'];
        element['limited_Create'] = parent.myGroupForm.value['limited_Create'];
        element['limited_Upload'] = parent.myGroupForm.value['limited_Upload'];
        element['limited_Download'] = parent.myGroupForm.value['limited_Download'];

        if (element['ParentID']) {
          element['ParentID'] = response.data.dmsClientTemplateNameID;
          childObj.push(element);
        } else {
          parentObj = element;
        }
      }

      parent.mangoAPISrvc.createClientTemplates(parentObj).subscribe((childresponse: any) => {
        for (let index = 0; index < childObj.length; index++) {
          const element = childObj[index];
          element['ParentID'] = childresponse.data.dmsClientTemplatesID;
          element['YellowParentID'] = childresponse.data.dmsClientTemplatesID;
        }
        parent.mangoAPISrvc.showLoader(false);
        parent.createClientTemplates(childObj)
          .subscribe(data => {
            parent.showRightContent = false;
            parent.initDataSources();
            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              // parent.treeSettingtempleteGrid.hideSpinner();
              // parent.treetempleteGrid.hideSpinner();
            }, 100);
          });
      });
    });
  }

  createClientTemplateNames(bulkData, isSingle) {
    const self = this;
    const observableBatch = bulkData.splice(0, 25);
    if (bulkData.length == 0) {
      clearInterval(self.intervalId);
    }
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.bulkClientTemplateNames({ observableBatch }).subscribe(function (data) {
      self.mangoAPISrvc.showLoader(false);
      if (bulkData.length > 0) {
        return false;
      }
      self.loading = false;
      let lengthOfClients = self.selectedClientsItems.length;
      if (!isSingle) {
        self.onRowSelect(self.selectedCustomer, true);
        self.treeAddTemplateGrid.clearSelection();
        lengthOfClients = 1;
      }
      self.selectedClientsItems = [];
      self.isAddTemplate = self.isTemplateShowGrid = false

      Swal.fire({
        icon: 'success',
        title: self.translate.instant('Success_notify'),
        text: (lengthOfClients) + self.translate.instant('dms.Client_Template_Created'),
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timer: 2000
      })
    }, error => {
      self.mangoAPISrvc.notify('error', self.translate.instant('error'), AppConstants.updateErrorMsg);
      self.mangoAPISrvc.showLoader(false);
    });
  }

  templeteActionComplete(args: DialogEditEventArgs): void {
    //let selectedRow = this.treeSettingtempleteGrid.getSelectedRecords();
    this.removeToolBars(this.selectedSettings['readOnly']);
    if (this.selectedRow && this.selectedRow['length'] == 0) {
      args.cancel = true;
    }
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      if (args.rowData['TemplateName']) {
        $('#_gridcontrol_dialogEdit_wrapper_title').text(args.rowData['TemplateName']);
      }
      if (args.rowData['FolderName']) {
        $('#_gridcontrol_dialogEdit_wrapper_title').text(args.rowData['FolderName']);
      }
    }
    const obj = { 'FolderName': '', 'CompanyID': this.companyId, 'ParentID': '', 'dmsClientTemplateNameID': '', 'YellowParentID': null }
    if (args['action'] === 'edit') {
      obj.dmsClientTemplateNameID = args.rowData['dmsClientTemplateNameID'];
      obj.ParentID = args.rowData['ParentID'];
      obj.FolderName = args.rowData['FolderName'];
      obj['dmsClientTemplatesID'] = args.rowData['dmsClientTemplatesID'];

      this.editClientTemplate(obj);
    } else if (args['action'] === 'add') {
      if (args['data']['FolderName']) {
        obj.dmsClientTemplateNameID = args['data']['dmsClientTemplateNameID'];
        obj.ParentID = args['data']['dmsClientTemplatesID'];
        obj.YellowParentID = args['data']['dmsClientTemplatesID'];
        obj.FolderName = args['data']['FolderName'];
        if (!obj.dmsClientTemplateNameID) {
          return;
        }
        this.createClientTemplate(obj);
      } else {
        args.cancel = true;
      }

    } else if (args['requestType'] === 'delete') {
      if (this.selectedRow['ParentID']) {
        this.deleteClientTemplate(this.selectedRow['dmsClientTemplatesID']);
      } else {
        args.cancel = true;
      }
    }
  }

  disableTreeGridPermissionToolBars() {
    this.selectedRow['admin_Create'] = this.selectedRow['admin_Create'] ? this.selectedRow['admin_Create'] : false;
    this.selectedRow['admin_Delete'] = this.selectedRow['admin_Delete'] ? this.selectedRow['admin_Delete'] : false;
    this.treeGrid.toolbarModule.enableItems(['_gridcontrol_edit'], this.selectedRow['admin_Create']);
    this.treeGrid.toolbarModule.enableItems(['_gridcontrol_delete'], this.selectedRow['admin_Delete']);
  }

  parentByTag(el) {
    const $curr = $(el).prev();
    if($curr && $curr.find(".ic-yellow").length > 0) {
      const index = $curr.attr('aria-rowindex');
      localStorage.setItem('selectedExpandRowIndex', index);
        return el
    } else if($curr.length == 0) {
      return  el;
    }else {
        return this.parentByTag($curr)
    }
}

  rowSelected(args: RowSelectEventArgs) {
    const parent = this;
    this.isViewerShow = false;
    this.isPreviewViewerShow = false;
    localStorage.setItem('selectedExpandRowIndex', '-1');

    if (this.treeGrid.getSelectedRecords().length > 0 && this.selectedClientID) {
      this.parentByTag(args.row);

      this.selectedRow = this.treeGrid.getSelectedRecords()[0];
      this.selectedRow['rowIndex'] = args['rowIndex'].toString();
      this.selectedRow['isUploadEnable'] = false;
      this.selectedRow['isDownLoadEnable'] = false;

      const dataSet = this.treeGrid['grid']['currentViewData'];
      if (this.selectedRow && this.selectedRow['LineType'] == 'F') {
        this.disableNotificationToolBars(false);
        const parentObj = dataSet.filter((item) => item['dmsParentID'] == parent.selectedRow['ParentID']);
        if (parentObj.length > 0) {
          if (parent.staffPermission == 1 && parentObj[0]['admin_Download'] == true) {
            this.selectedRow['isDownLoadEnable'] = true;
            this.disableTreeGridToolBars(false);
          }
          else if (parent.staffPermission == 2 && parentObj[0]['mgr_Download'] == true) {
            this.selectedRow['isDownLoadEnable'] = true;
            this.disableTreeGridToolBars(false);
          }
          else if (parent.staffPermission == 3 && parentObj[0]['standard_Download'] == true) {
            this.selectedRow['isDownLoadEnable'] = true;
            this.disableTreeGridToolBars(false);
          }
          else if (parent.staffPermission == 4 && parentObj[0]['limited_Download'] == true) {
            this.selectedRow['isDownLoadEnable'] = true;
            this.disableTreeGridToolBars(false);
          }
        }
      } else {
        // this.selectedRow['isDownLoadEnable'] = false;
        this.disableTreeGridToolBars(true);
        if (this.selectedRow && this.selectedRow['LineType'] == 'C') {
          this.disableNotificationToolBars(true);
          if (parent.staffPermission == 1 && this.selectedRow['admin_Upload'] == true) {
            this.selectedRow['isUploadEnable'] = true;
          }
          else if (parent.staffPermission == 2 && this.selectedRow['mgr_Upload'] == true) {
            this.selectedRow['isUploadEnable'] = true;
          }
          else if (parent.staffPermission == 3 && this.selectedRow['standard_Upload'] == true) {
            this.selectedRow['isUploadEnable'] = true;
          }
          else if (parent.staffPermission == 4 && this.selectedRow['limited_Upload'] == true) {
            this.selectedRow['isUploadEnable'] = true;
          }
        }
      }
      this.disableTreeGridPermissionToolBars();
      if (this.isRightPanelShow && this.selectedRow && this.selectedRow['LineType'] == 'F' && this.isRightPanelShow) {

        if (this.selectedRow['FileType'] == 'csv' || this.selectedRow['FileType'] == 'rtf') {
          Swal.fire({
            icon: 'warning',
            title: this.translate.instant('Warning'),
            text: this.translate.instant('dms.File_extension_CSV_not_supported'),
            showConfirmButton: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timer: 2000
          })
        } else {
          if (this.selectedClientID == (args.data && args.data['ClientID'])) {
            this.showDocument();
          }
        }
      }
    } else {
      this.selectedRow = null;
      localStorage.setItem('selectedParentTableRow', "-1");
      this.disableTreeGridToolBars(false);
      this.disableNotificationToolBars(false);
    }
    this.selectedExpandRowIndex = parseInt(localStorage.getItem('selectedExpandRowIndex'));
  }

  dialogRowSelected(args: RowSelectEventArgs) {
    this.disableSettingsNotificationToolBars(false);
    if (this.treeSettingtempleteGrid.getSelectedRecords().length > 0) {
      this.selectedRow = this.treeSettingtempleteGrid.getSelectedRecords()[0];
      this.disableSettingsToolBars(true);
      if (this.selectedRow['LineType'] == 'C') {
        this.disableSettingsNotificationToolBars(true);
      }
    } else {
      this.selectedRow = null;
      this.disableSettingsToolBars(false);
    }
  }

  templateactionBegin(args: SaveEventArgs): void {
    const selectedRow = this.treeSettingtempleteGrid.getSelectedRecords();
    this.removeToolBars(this.selectedSettings['readOnly']);
    if (selectedRow && selectedRow.length == 0 && args.requestType === 'add') {
      args.cancel = true;
      return;
    }
    if (args.rowData && args.rowData['isGlobal'] == true) {
      this.showSaveButtons = false;
      args.cancel = true;
      return;
    }
    if (args.requestType === 'add') {
      args.rowData = Object.assign({}, this.selectedRow);
      args.rowData['FolderName'] = '';
    }
    if (args.requestType === 'save' && args.action === 'add') {
      if (args.rowData) {
        args.rowData['FolderName'] = args.data['FolderName'];
        args.data = args.rowData;
      } else {
        args.cancel = true;
      }
    }
  }

  verifyPermissions(obj) {
    let isinValidAction = false;
    let data = null;

    if (obj.rowData) {
      data = obj.rowData;
    } else if (obj.requestType === 'delete' && obj.data && obj.data[0]) {
      data = obj.data[0];
    }

    // admin
    if (this.staffPermission == 1) {
      if (obj.requestType === 'beginEdit' && data.admin_Create == false) {
        isinValidAction = true;
      } else if (obj.requestType === 'add' && data.admin_Create == false) {
        isinValidAction = true;
      }
      else if (obj.requestType === 'delete' && data.admin_Delete == false) {
        isinValidAction = true;
      }
    }
    // mgr
    else if (this.staffPermission == 2) {
      if (obj.requestType === 'beginEdit' && data.mgr_Create == false) {
        isinValidAction = true;
      } else if (obj.requestType === 'add' && data.mgr_Create == false) {
        isinValidAction = true;
      }
      else if (obj.requestType === 'delete' && data.mgr_Delete == false) {
        isinValidAction = true;
      }
    }
    // standard
    else if (this.staffPermission == 3) {
      if (obj.requestType === 'beginEdit' && data.standard_Create == false) {
        isinValidAction = true;
      } else if (obj.requestType === 'add' && data.standard_Create == false) {
        isinValidAction = true;
      }
      else if (obj.requestType === 'delete' && data.standard_Delete == false) {
        isinValidAction = true;
      }
    }
    else if (this.staffPermission == 4) {
      if (obj.requestType === 'beginEdit' && data.limited_Create == false) {
        isinValidAction = true;
      } else if (obj.requestType === 'add' && data.limited_Create == false) {
        isinValidAction = true;
      }
      else if (obj.requestType === 'delete' && data.limited_Delete == false) {
        isinValidAction = true;
      }
    }
    return isinValidAction;
  }

  actionBegin(args: SaveEventArgs): void {
    if (args.requestType !== "refresh" && this.verifyPermissions(args)) {
      args.cancel = true;
      return;
    }

    if (this.treeGrid.getSelectedRecords().length > 0) {
      this.selectedRow = this.treeGrid.getSelectedRecords()[0];
    } else {
      this.selectedRow = null;
    }
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      this.submitClicked = false;
      if (args.requestType === 'add' && this.selectedClientID) {
        args.rowData['LineType'] = this.selectedRow != null ? 'C' : 'P';
        args.rowData['ParentID'] = this.selectedRow != null ? this.selectedRow['dmsParentID'] : null;
        args.rowData['FName'] = args.rowData['FileType'] = args.rowData['FileName'] = args.rowData['LineItem'] = null;
        args.rowData['ModifyOn'] = new Date();
        args.rowData['CreatedOn'] = new Date();
        args.rowData['ModifyBy'] = this.userName;
        args.rowData['ClientID'] = this.selectedClientID;
        args.rowData['CompanyID'] = this.companyId;
        args.rowData['YellowParentID'] = (args.rowData['LineType'] == 'C' && this.selectedRow != null) ? this.selectedRow['dmsParentID'] : null;
        args.rowData['Size'] = 0;
        args.rowData['isFullAccess'] = args.rowData['isRead'] = args.rowData['isCreate'] = args.rowData['isDelete']
          = args.rowData['isEdit'] = args.rowData['isPrivate'] = args.rowData['isView'] = args.rowData['isViewInPortal'] = args.rowData['isClientUpload'] = args.rowData['isClientDelete'] = args.rowData['isDownload'] = false;
      }
    }
    if (args.requestType === 'save' && this.selectedClientID) {
      this.submitClicked = true;
      if (args.rowData) {
        args.rowData['FName'] = $('#_gridcontrolLineType').val();
        args.data = args.rowData;
      } else {
        args.cancel = true;
      }
    }
  }

  duplicateTemplate() {
    const parent = this;
    parent.showRightContent = false;
    parent.isShowGrid = false;
    parent.showDuplicate = true;

  }

  createTemplate() {
    if (this.selectedSettings && this.selectedSettings['TemplateName'] == "My Templates") {
      this.showRightContent = this.showSaveButtons = this.isShowGrid = true;
      this.selectedSettings = { "UniqueID": this.mangoUtils.generateUUID(), "TemplateName": "", "readOnly": false };
      this.defaultClientTemplates = [{ "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }, { "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }, { "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }, { "FolderName": "New Folder", "CompanyID": null, "ParentID": null, "dmsClientTemplateNameID": null }];
      // Preparing dummy data
      for (let index = 0; index < this.defaultClientTemplates.length; index++) {
        const element = this.defaultClientTemplates[index];
        element['UniqueID'] = this.mangoUtils.generateUUID();
        element['CompanyID'] = this.companyId;
        element['isNewRecord'] = true;
        element['YellowParentID'] = null;
        if (index != 0) {
          element['ParentID'] = this.defaultClientTemplates[0]['UniqueID'];
        }
      }
      this.settingsRightSideData = this.defaultClientTemplates;
    }
  }

  rowAddTemplateSelected(args: RowSelectEventArgs, isRowSelected) {
    this.isTemplateShowGrid = true;
    const selectedTemp = this.treeAddTemplateGrid.getSelectedRecords();
    this.selectedSettings = (selectedTemp && selectedTemp.length) ? selectedTemp[0] : { "TemplateName": "", "readOnly": true };
    this.getClientTemplate(this.selectedSettings['dmsClientTemplateNameID']);
  }

  getFileSize(obj, property) {
    return obj.reduce(function (a, b) { return a + +numeral(b[property]).value(); }, 0);
  }

  disableSettingsToolBars(isNoData) {
    if (this.treeSettingtempleteGrid && this.treeSettingtempleteGrid.toolbarModule) {
      this.treeSettingtempleteGrid.toolbarModule.enableItems(['toolbarfilter'], isNoData);
    }
  }

  disableTreeGridToolBars(isNoData) {
    if (this.treeGrid && this.treeGrid.toolbarModule) {
      this.treeGrid.toolbarModule.enableItems(['toolbarfilter'], isNoData);
    }
  }

  disableSettingsNotificationToolBars(isNoData) {
    if (this.treeSettingtempleteGrid && this.treeSettingtempleteGrid.toolbarModule) {
      this.treeSettingtempleteGrid.toolbarModule.enableItems(['toolbarnotification'], isNoData);
    }
  }

  disableNotificationToolBars(isNoData) {
    if (this.treeGrid && this.treeGrid.toolbarModule) {
      this.treeGrid.toolbarModule.enableItems(['toolbarnotification'], isNoData);
    }
  }

  disableInviteToolBars(isNoData) {
    if (this.treeGrid && this.treeGrid.toolbarModule) {
      this.treeGrid.toolbarModule.enableItems(['toolbarInv'], isNoData);
    }
  }

  closeClient() {
    this.isInviteClient = false;
    this.inviteClientEmailTemplate = { "to": "", "from": "", "subject": "" };
    this.inviteEditorValue = null;
  }

  clickHandler(args: Object): void {
    this.settingsDisplay = this.settingsNotifications = false;
    const contactPerson = this.selectedCustomer['ContactPerson'] ? this.selectedCustomer['ContactPerson'] : "";
    if ((args['item']['properties']['text'] == "Permissions" || args['item']['properties']['text'] == 'Notifications') && this.selectedRow) {
      if (args['item']['properties']['text'] == "Permissions") {
        this.settingsDisplay = true;
      } else {
        this.settingsNotifications = true;
      }
      this.backUpUserList = [];
      this.defaultPermisssionObj = Object.assign({}, this.permisssionObj);
      this.defaultPermisssionObj['dmsClientTemplateNameID'] = this.selectedRow['dmsClientTemplateNameID'];
      this.defaultPermisssionObj['ParentID'] = this.selectedRow['ParentID'];
      this.defaultPermisssionObj['settings'] = true;
      this.defaultPermisssionObj['RowIndex'] = this.selectedRow['index'];
      this.defaultPermisssionObj['isNewRecord'] = this.selectedRow['isNewRecord'] ? this.selectedRow['isNewRecord'] : false;
      this.defaultPermisssionObj['FolderName'] = this.selectedRow['dmsClientTemplatesID'] ? this.selectedRow['FolderName'] : this.selectedRow['FName'];
      this.defaultPermisssionObj['dmsClientTemplatesID'] = this.selectedRow['dmsClientTemplatesID'];

      this.defaultPermisssionObj['isNotifyClientUpload'] = this.selectedRow['isNotifyClientUpload'] ? this.selectedRow['isNotifyClientUpload'] : false;

      this.defaultPermisssionObj['admin_Read'] = this.selectedRow['admin_Read'] ? this.selectedRow['admin_Read'] : false;
      this.defaultPermisssionObj['admin_Delete'] = this.selectedRow['admin_Delete'] ? this.selectedRow['admin_Delete'] : false;
      this.defaultPermisssionObj['admin_Create'] = this.selectedRow['admin_Create'] ? this.selectedRow['admin_Create'] : false;
      this.defaultPermisssionObj['admin_Download'] = this.selectedRow['admin_Download'] ? this.selectedRow['admin_Download'] : false;
      this.defaultPermisssionObj['admin_Upload'] = this.selectedRow['admin_Upload'] ? this.selectedRow['admin_Upload'] : false;

      this.defaultPermisssionObj['mgr_Read'] = this.selectedRow['mgr_Read'] ? this.selectedRow['mgr_Read'] : false;
      this.defaultPermisssionObj['mgr_Delete'] = this.selectedRow['mgr_Delete'] ? this.selectedRow['mgr_Delete'] : false;
      this.defaultPermisssionObj['mgr_Create'] = this.selectedRow['mgr_Create'] ? this.selectedRow['mgr_Create'] : false;
      this.defaultPermisssionObj['mgr_Download'] = this.selectedRow['mgr_Download'] ? this.selectedRow['mgr_Download'] : false;
      this.defaultPermisssionObj['mgr_Upload'] = this.selectedRow['mgr_Upload'] ? this.selectedRow['mgr_Upload'] : false;

      this.defaultPermisssionObj['standard_Read'] = this.selectedRow['standard_Read'] ? this.selectedRow['standard_Read'] : false;
      this.defaultPermisssionObj['standard_Delete'] = this.selectedRow['standard_Delete'] ? this.selectedRow['standard_Delete'] : false;
      this.defaultPermisssionObj['standard_Create'] = this.selectedRow['standard_Create'] ? this.selectedRow['standard_Create'] : false;
      this.defaultPermisssionObj['standard_Download'] = this.selectedRow['standard_Download'] ? this.selectedRow['standard_Download'] : false;
      this.defaultPermisssionObj['standard_Upload'] = this.selectedRow['standard_Upload'] ? this.selectedRow['standard_Upload'] : false;

      this.defaultPermisssionObj['limited_Read'] = this.selectedRow['limited_Read'] ? this.selectedRow['limited_Read'] : false;
      this.defaultPermisssionObj['limited_Delete'] = this.selectedRow['limited_Delete'] ? this.selectedRow['limited_Delete'] : false;
      this.defaultPermisssionObj['limited_Create'] = this.selectedRow['limited_Create'] ? this.selectedRow['limited_Create'] : false;
      this.defaultPermisssionObj['limited_Download'] = this.selectedRow['limited_Download'] ? this.selectedRow['limited_Download'] : false;
      this.defaultPermisssionObj['limited_Upload'] = this.selectedRow['limited_Upload'] ? this.selectedRow['limited_Upload'] : false;
      this.defaultPermisssionObj['dmsParentID'] = this.selectedRow['dmsParentID'];
      this.defaultPermisssionObj['YellowParentID'] = this.selectedRow['YellowParentID'];
      this.defaultPermisssionObj['ShowInPortal'] = this.selectedRow['ShowInPortal'];
      this.defaultPermisssionObj['LineType'] = this.selectedRow['LineType'];
      this.defaultPermisssionObj['EmailUploadTemplateID'] = this.selectedRow['EmailUploadTemplateID'];
      this.defaultPermisssionObj['EmailDownloadTemplateID'] = this.selectedRow['EmailDownloadTemplateID'];

      this.defaultPermisssionObj['standard_Full'] = (this.defaultPermisssionObj['standard_Read'] && this.defaultPermisssionObj['standard_Download'] && this.defaultPermisssionObj['standard_Upload'] && this.defaultPermisssionObj['standard_Delete'] && this.defaultPermisssionObj['standard_Create']);

      this.defaultPermisssionObj['mgr_Full'] = (this.defaultPermisssionObj['mgr_Read'] && this.defaultPermisssionObj['mgr_Delete'] && this.defaultPermisssionObj['mgr_Download'] && this.defaultPermisssionObj['mgr_Upload'] && this.defaultPermisssionObj['mgr_Create']);

      this.defaultPermisssionObj['admin_Full'] = (this.defaultPermisssionObj['admin_Read'] && this.defaultPermisssionObj['admin_Download'] && this.defaultPermisssionObj['admin_Upload'] && this.defaultPermisssionObj['admin_Delete'] && this.defaultPermisssionObj['admin_Create']);

      this.defaultPermisssionObj['limited_Full'] = (this.defaultPermisssionObj['limited_Read'] && this.defaultPermisssionObj['limited_Download'] && this.defaultPermisssionObj['limited_Upload'] && this.defaultPermisssionObj['limited_Delete'] && this.defaultPermisssionObj['limited_Create']);

      const isMgrData = (this.defaultPermisssionObj['mgr_Read'] || this.defaultPermisssionObj['mgr_Delete'] || this.defaultPermisssionObj['mgr_Download'] || this.defaultPermisssionObj['mgr_Upload'] || this.defaultPermisssionObj['mgr_Create']);

      const isAdminData = (this.defaultPermisssionObj['admin_Read'] || this.defaultPermisssionObj['admin_Delete'] || this.defaultPermisssionObj['admin_Download'] || this.defaultPermisssionObj['admin_Upload'] || this.defaultPermisssionObj['admin_Create']);

      const isStandardData = (this.defaultPermisssionObj['standard_Read'] || this.defaultPermisssionObj['standard_Delete'] || this.defaultPermisssionObj['standard_Download'] || this.defaultPermisssionObj['standard_Upload'] || this.defaultPermisssionObj['standard_Create']);

      const isLimitedData = (this.defaultPermisssionObj['limited_Read'] || this.defaultPermisssionObj['limited_Delete'] || this.defaultPermisssionObj['limited_Download'] || this.defaultPermisssionObj['limited_Upload'] || this.defaultPermisssionObj['limited_Create']);



      const adminArr = this.usersList.filter((item) => item['Permission'] == 1);
      const mgrArr = this.usersList.filter((item) => item['Permission'] == 2);
      const standArr = this.usersList.filter((item) => item['Permission'] == 3);
      const limitedArr = this.usersList.filter((item) => item['Permission'] == 4);

      if (adminArr.length > 0 && isAdminData) {
        for (let index = 0; index < adminArr.length; index++) {
          const element = adminArr[index];
          if (this.selectedRow['StaffID'] && this.selectedRow['StaffID'].length > 0 && this.selectedRow['StaffID'].indexOf(element['StaffID']) > -1) {
          } else {
            this.backUpUserList.push(element);
          }
        }
      }

      if (mgrArr.length > 0 && isMgrData) {
        for (let index = 0; index < mgrArr.length; index++) {
          const element = mgrArr[index];
          if (this.selectedRow['StaffID'] && this.selectedRow['StaffID'].length > 0 && this.selectedRow['StaffID'].indexOf(element['StaffID']) > -1) {
          } else {
            this.backUpUserList.push(element);
          }
        }
      }

      if (standArr.length > 0 && isStandardData) {
        for (let index = 0; index < standArr.length; index++) {
          const element = standArr[index];
          if (this.selectedRow['StaffID'] && this.selectedRow['StaffID'].length > 0 && this.selectedRow['StaffID'].indexOf(element['StaffID']) > -1) {
          } else {
            this.backUpUserList.push(element);
          }
        }
      }

      if (limitedArr.length > 0 && isLimitedData) {
        for (let index = 0; index < limitedArr.length; index++) {
          const element = limitedArr[index];
          if (this.selectedRow['StaffID'] && this.selectedRow['StaffID'].length > 0 && this.selectedRow['StaffID'].indexOf(element['StaffID']) > -1) {
          } else {
            this.backUpUserList.push(element);
          }
        }
      }
      this.sourceList = Object.assign([], this.usersList);

      this.defaultPermisssionObj['StaffID'] = [];

      if (this.selectedRow['StaffID'] && this.selectedRow['StaffID'].length > 0) {
        for (let index = 0; index < this.selectedRow['StaffID'].length; index++) {
          const elementID = this.selectedRow['StaffID'][index];
          const userObj = this.usersList.filter((item) => item['StaffID'] == elementID)[0];
          this.sourceList = this.sourceList.filter(item=> item['StaffID'] != elementID);
          this.defaultPermisssionObj['StaffID'].push(userObj);
        }
      }
    }
    if (args['item']['properties']['text'] == "Add Template" && this.selectedClientID) {
      this.treeAddTemplateGrid.clearSelection();
      this.isAddTemplate = true;
    }
    if (args['item']['properties']['text'] == "Invite Client" && this.selectedClientID && this.emailtemplateList.length > 0) {
      const contactEmail = this.selectedCustomer['Email'] ? this.selectedCustomer['Email'] : "";
      const emailtemplate = this.emailtemplateList.filter((item) => item['TemplateName'] == "Invite to Client Portal")[0];

      this.inviteClientEmailTemplate['to'] = contactEmail;
      this.inviteClientEmailTemplate['from'] = this.userEmail;
      this.inviteClientEmailTemplate['subject'] = emailtemplate['EmailSubject'];

      const companyName = this.companyName;
      const linkToPortal = this.encrDecSrvc.getObject(AppConstants.linkToPortal);

      emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%<strong>ClientName</strong>%", this.selectedCustomer['ClientName']);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%ClientName%", this.selectedCustomer['ClientName']);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%<strong>ContactName</strong>%", contactPerson);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%ContactName%", contactPerson);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceAll(emailtemplate['htmlBody'], "%<strong>LinkToPortal</strong>%", linkToPortal);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceAll(emailtemplate['htmlBody'], "%LinkToPortal%", linkToPortal);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceAll(emailtemplate['htmlBody'], "http://https://", "https://");
      emailtemplate['htmlBody'] = this.mangoUtils.replaceLinkToPortal(emailtemplate['htmlBody'], 'Link to Portal');
      emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%<strong>YourFirmName</strong>%", companyName);
      emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%YourFirmName%", companyName);

      this.inviteEditorValue = emailtemplate['htmlBody'];
      this.inviteObj.toolbarSettings.type = ToolbarType.MultiRow;
      this.isInviteClient = true;
    }
  }
}
