
import {
  Component,
  OnInit
} from '@angular/core';

import {
  MenuItem
} from 'primeng/api';

import {
  Router
} from '@angular/router';

import {
  Subscription
} from 'rxjs';

import {
  concatMap
} from 'rxjs/operators';

import {
  AppConstants
} from '@app/_helpers/api-constants';

import {
  EncrDecrService,
  MangoApiService
} from '@app/_services';

import {
  environment
} from "@environments/environment";

@Component(
  {
    selector: 'app-admin',
    templateUrl: './admin.component.html'
  }
)
export class AdminComponent implements OnInit {
  public nylasIntegrationFeatureStatus: boolean = false;

  public isProfileView: boolean = false;
  items: MenuItem[];
  profileViewChangeSubscription: Subscription;
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;
  companyID: any;
  isEnableTFA: boolean;
  isMicrosoftLogin: boolean;

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService
  ){
    this.mangoAPISrvc.applyLanguage( );

    this.companyID = this.encrDecSrvc.getObject( AppConstants.companyID );

    this.redirectScreens( );

    this.profileViewChangeSubscription = (
      this.mangoAPISrvc.profileViewChange$
      .subscribe(
        ( data ) => {
          this.redirectScreens( );
        }
      )
    );

    this.nylasIntegrationFeatureStatus = (
      (
            environment.NYLAS_INTEGRATION_FEATURE_MAIL_STATUS
        === true
      )
    );
  }

  ngOnInit( ): void {
  }

  redirectScreens( ){
    this.setMenuIndex( );

    if(
      this.selectedMenuIndex > -1
    ){
      this.isProfileView = true;

      this.mangoAPISrvc
      .getIsEnableTFA( this.companyID )
      .pipe(
        concatMap(
          ( companyData: any ) => {
            this.isEnableTFA = companyData.isEnableTFA;

            return this.mangoAPISrvc.getIsMicrosoftLogin( this.companyID );
          }
        )
      )
      .subscribe(
        ( companyData: any ) => {
          this.isMicrosoftLogin = companyData.isMicrosoftLogin;

          this.loadMenu( );
        }
      )
    }
    else{
      this.isProfileView = false;
    }
  }

  loadMenu( ){
    this.items = (
      [
        {
          label: 'User Information',
          routerLink: [ AppConstants.myProfile ],
          icon: 'fal fa-user-cog',
        },

        {
          label: 'Schedule Preferences',
          routerLink: [ AppConstants.schedulePreference ],
          icon: 'fal fa-calendar-check'
        },

        {
          label: 'Calendar Integration',
          routerLink: [ AppConstants.calendarIntegration ],
          icon: 'fal fa-calendar-alt'
        },

        this.nylasIntegrationFeatureStatus &&
        (
          {
            label: 'Mail Integration',
            routerLink: [ AppConstants.mailIntegration ],
            icon: 'fal fa-envelope'
          }
        ),

        {
          label: 'Email Signature',
          routerLink: [ AppConstants.emailSignature ],
          icon: 'fal fa-file-signature'
        },
      ]
      .filter( Boolean )
    );

    if(
      this.isMicrosoftLogin
    ){
      this.items.push(
        {
          label: "Microsoft Integration",
          routerLink: [ AppConstants.microsoftIntegration ],
          icon: "fab fa-microsoft",
        }
      );
    }

    if(
      this.isEnableTFA
    ){
      this.items.push(
        {
          label: "Two Factor Authentication",
          routerLink: [ AppConstants.twoFA ],
          icon: "fal fa-unlock-alt",
        }
      );
    }

    this.items.push(
      {
        label: "Other",
          routerLink: [ AppConstants.other ],
          icon: "fal fa-tasks-alt",
      }
    );

    this.selectedMenu = this.items[ this.selectedMenuIndex ];
  }

  setMenuIndex( ){
    const url = this.router.url;

    this.selectedUrl = "";

    switch( url ){
      case "/admin/myProfile":
        this.selectedMenuIndex = 0;
        this.selectedUrl = "admin/myProfile";

        break;

      case "/admin/schedulePreference":
        this.selectedMenuIndex = 1;
        this.selectedUrl = "admin/schedulePreference";

        break;

      case "/admin/calendarIntegration":
        this.selectedMenuIndex = 2;
        this.selectedUrl = "admin/calendarIntegration";

        break;

      case "/admin/mailIntegration":
        this.selectedMenuIndex = 3;
        this.selectedUrl = "admin/mailIntegration";

        break;

      case "/admin/emailSignature":
        this.selectedMenuIndex = 4;
        this.selectedUrl = "admin/emailSignature";

        break;

      case `${AppConstants.superAdmin}/${AppConstants.microsoftIntegration}`:
        if(
          this.isMicrosoftLogin
        ){
          this.selectedMenuIndex = 5;
          this.selectedUrl = `${AppConstants.superAdmin}/${AppConstants.microsoftIntegration}`;
        }
        else{
          this.selectedMenuIndex = 0;
          this.selectedUrl = "admin/myProfile";
        }

        break;

      case `${AppConstants.superAdmin}/${AppConstants.twoFA}`:
          if(
            this.isEnableTFA
          ){
            this.selectedMenuIndex = 6;
            this.selectedUrl = `${AppConstants.superAdmin}/${AppConstants.twoFA}`;
          }
          else{
            this.selectedMenuIndex = 0;
            this.selectedUrl = "admin/myProfile";
          }

          break;

      case `${AppConstants.superAdmin}/${AppConstants.other}`:
        if(
              this.isMicrosoftLogin
          &&  this.isEnableTFA
        ){
          this.selectedMenuIndex = 7;
        }
        else
        if(
              this.isMicrosoftLogin
          ||  this.isEnableTFA
        ){
          this.selectedMenuIndex = 6;
        }
        else{
          this.selectedMenuIndex = 5;
        }

        this.selectedUrl = (
          `${AppConstants.superAdmin}/${AppConstants.other}`
        );

        break;

      default:
        this.selectedMenuIndex = -1;
        this.selectedUrl = "Invalid";
    }
  }
}
