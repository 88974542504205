import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MangoApiService, EncrDecrService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '@app/_helpers/api-constants';
import Swal from 'sweetalert2';
import { LinkService, ImageService, HtmlEditorService, ToolbarService, ToolbarType, RichTextEditorComponent, NodeSelection } from '@syncfusion/ej2-angular-richtexteditor';
import { FileManagerComponent, NavigationPaneService, DetailsViewService, MenuOpenEventArgs, MenuClickEventArgs, FileSelectEventArgs, ToolbarClickEventArgs, ToolbarCreateEventArgs } from '@syncfusion/ej2-angular-filemanager';
import { environment } from '@environments/environment';
import WebViewer from '@pdftron/webviewer';
import $ from "jquery";
import * as AWS from 'aws-sdk';
import moment from 'moment';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: 'app-filesandfolders',
  templateUrl: './filesandfolders.component.html',
  styleUrls: ['./filesandfolders.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NavigationPaneService, ToolbarService, DetailsViewService, ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class FilesandfoldersComponent implements OnInit, AfterViewInit {
  public isInvalidToken = false;
  public isApiLoaded = false;
  public nativeWindow: any;
  @ViewChild("fileExplorer") public fileManagerInstance: FileManagerComponent;
  @ViewChild('preiewviewer') preiewviewer: ElementRef;
  wvPreviewInstance: any;
  public clientID: any;

  @ViewChild('fileObj')
  public fileObj: FileManagerComponent;
  public ajaxSettings: object;
  public navigationPaneSettings: object;
  public hostUrl: string = environment.API_URL;
  public toolbarSettings: object;
  public contextMenuSettings: object;
  public isFilePreview: boolean = false;
  public isValidQA: boolean = false;
  public isPreviewViewerShow: boolean = false;
  blockedPanel: boolean = false;
  public isErrors: boolean = false;
  public awsBucket: any;
  public questionStr: any;
  public securityAnswer: any;
  public securityQuestion: any;
  public publicURL: any;
  public CompanyID: any;
  public staffEmail: any;
  public StaffName: any;
  public maxAttempts: number = 0;
  public EmailRecipient: any = null;
  public companyObj: any = { StaffName: "", CompanyLogoWeb: null, CompanyName: "", ClientName: "" };

  public securityQuestionsList: any = [{ label: "What are the last 4 numbers of your Social Security Number?", value: 0 }, { label: "What is your social security number, without the dashes?", value: 1 }, { label: "What are the last four numbers of the client's Social Security Number?", value: 2 }, { label: "What are the last 4 of your phone number?", value: 3 }, { label: "What is your zip code?", value: 4 }, { label: "What are the last four digits of your Federal Tax Identification Number?", value: 5 }];

  constructor(public mangoUtils: mangoUtils, public translate: TranslateService, private _router: Router, private activatedRoute: ActivatedRoute, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService) {
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.mangoAPISrvc.notifyLogging(false);
    this.setAWSOBject();
  }

  ngAfterViewInit() {

  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = 'us-east-1'
    this.awsBucket = new AWS.S3({ params: { Bucket: 'mangobillings3' } });
    */
    this.awsBucket = (
      AWSBucketMask
    );
  }

  closePreviewDialog() {
    this.isFilePreview = false;
    this.isPreviewViewerShow = false;
  }

  toolbarClick(args: ToolbarClickEventArgs) {
    const selectedRow = args.fileDetails[0]
    if (args.item.text == "Preview") {
      if (selectedRow['type'] == 'CSV' || selectedRow['type'] == 'RTF') {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('Warning'),
          text: this.translate.instant('dms.File_extension_CSV_not_supported'),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timer: 2000
        })
      } else {
        this.isFilePreview = true;
        this.showPreviewDocument(selectedRow['UniqueName']);
      }
    }
  }

  enableDisableToolBars(options, isEnable) {
    setTimeout(() => {
      if (this.fileManagerInstance) {
        if (isEnable) {
          this.fileManagerInstance.enableToolbarItems(options);
        } else {
          this.fileManagerInstance.disableToolbarItems(options);
        }
      }
    }, 150);
  }


  onFileSelect(args: FileSelectEventArgs) {
    if (this.fileManagerInstance.selectedItems.length == 1) {
      this.enableDisableToolBars(['Download'], true);
      if (this.fileManagerInstance.selectedItems.toString().indexOf('.') > 0) {
        this.enableDisableToolBars(['Preview'], true);
      }
    } else if (this.fileManagerInstance.selectedItems.length > 1) {
      this.enableDisableToolBars(['Download'], true);
      this.enableDisableToolBars(['Preview'], false);
    } else {
      this.enableDisableToolBars(['Preview', 'Download'], false);
    }
  }

  showPreviewDocument(UniqueName): void {
    const params = { Bucket: 'mangobillings3', Key: 'documents/' + UniqueName };
    const self = this;

    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        $("#overlay").hide();
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.createWebPreviewViewer(docUrl);
      },

      self.mangoAPISrvc
    );
  }

  createWebPreviewViewer(docUrl) {
    const self = this;
    self.isPreviewViewerShow = true;

    if (self.wvPreviewInstance) {
      self.wvPreviewInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    } else {
      WebViewer({
        licenseKey: environment.PDF_WebViewer_KEY,
        path: '../../../../../wv-resources/lib',
        initialDoc: docUrl
      }, self.preiewviewer.nativeElement).then(instance => {
        self.wvPreviewInstance = instance;
        self.wvPreviewInstance.disableElements(['leftPanel', 'leftPanelButton', 'panToolButton', 'toolsButton', 'signatureToolButton', 'freeHandToolGroupButton', , 'signatureTool', 'freeTextToolButton', 'eraserToolButton', 'shapeToolGroupButton', 'textToolGroupButton', 'miscToolGroupButton', 'stickyToolButton']);
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    }
  }

  loadFileManager(token) {
    this.ajaxSettings = {
      url: this.hostUrl + `/dms/getShareDetailsByToken/${token}`,
      downloadUrl: this.hostUrl + '/dms/AmazonS3Download'
    };
    this.toolbarSettings = { items: ['Refresh', 'Download', 'Preview'], visible: true };
    this.navigationPaneSettings = {
      visible: false
    };
    this.contextMenuSettings = {
      visible: false
    };
    this.enableDisableToolBars(['Download', 'Preview'], false);
  }

  beforeSend(req: any) {
    if (!req.ajaxSettings.url || req.action == "Upload") {
      req.cancel = true;
    }
    const parent = this;
    req.ajaxSettings.beforeSend = function (args) {
      args.httpRequest.setRequestHeader("selectedClientId", parent.clientID);
      args.httpRequest.setRequestHeader("selectedCompanyId", parent.CompanyID);
    }
  }

  onAjaxSuccess(args: any) {
    this.mangoAPISrvc.showLoader(false);
  }

  getCompanyDetails(token) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getFirmDetails(token).subscribe(function (data) {
      if (data) {
        self.mangoAPISrvc.showLoader(false);
        self.companyObj = data;
        self.clientID = data['ClientID'];
        self.CompanyID = data['CompanyID'];
      }
    });
  }

  validateToken(token) {
    const self = this;
    self.blockedPanel = false;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.verifyValidUrl(token).subscribe(function (data: any) {
      if (data) {
        if(data?.isIshareLink && data.shareableIShareUrl) {
            window.location.assign(data.shareableIShareUrl);
            return;
        }
        const date1 = moment(data['shareExpiryDate']).format('YYYY-MM-DD');
        const date2 = moment().format('YYYY-MM-DD');
        if (moment(date1).isSameOrBefore(date2)) {
          self.blockedPanel = true;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'The Folder/Document link has expired. Please contact sender',
            showConfirmButton: false,
            backdrop: false
          })
          return false;
        }

        if (data && data['securityLink'] == 1) {
          self.mangoAPISrvc.showLoader(false);
          self.isValidQA = true;
          self.securityQuestion = data['securityQuestion'];
          self.publicURL = data['PublicURL'];
          self.maxAttempts = data['maxAttempts'];
          self.EmailRecipient = data['EmailRecipient'];
          self.questionStr = self.securityQuestionsList.filter((item) => item['value'] == data['securityQuestion'])[0].label;
        } else {
          self.isValidQA = false;
          setTimeout(() => {
            self.loadFileManager(token);
          }, 100);
        }
      }

    });
  }

  validateQA() {
    const self = this;
    self.isErrors = false;
    self.mangoAPISrvc.showLoader(true);
    self.maxAttempts = self.maxAttempts + 1;
    const obj = { securityQuestion: self.securityQuestion, securityAnswer: self.securityAnswer, PublicURL: self.publicURL, CompanyID: self.CompanyID, maxAttempts: self.maxAttempts };
    self.mangoAPISrvc.verifyQuestionandAnswer(obj).subscribe(function (data: any) {
      if (data && data.isError == false) {
        self.mangoAPISrvc.showLoader(false);
        self.isValidQA = false;
        setTimeout(() => {
          self.loadFileManager(self.publicURL);
        }, 3000);
      } else {
        self.mangoAPISrvc.showLoader(false);
        self.isErrors = true;
        if (self.maxAttempts >= 5) {
          self.sendEmail();
        }
      }
    }, (error) => {
      self.mangoAPISrvc.showLoader(false);
    });
  }

  sendEmail() {
    const parent = this;
    const sendInBlueObj = {
      sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      to: [],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 51,
      params: {},
    };

    if (!this.EmailRecipient) {
      return false;
    }
    const emails = this.EmailRecipient.split(',');
    for (let index = 0; index < emails.length; index++) {
      const element = emails[index];
      const obj = { email: "", name: "" };
      obj["email"] = element;
      obj["name"] = element;
      sendInBlueObj.to.push(obj);
    }

    sendInBlueObj.params["clientname"] = parent.companyObj.ClientName;
    sendInBlueObj.params["emailrecipient"] = this.EmailRecipient;
    sendInBlueObj.params["companyname"] = parent.companyObj.CompanyName;

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(
      (data) => {
        parent.mangoAPISrvc.notify(
          "success",
          parent.translate.instant('Success'),
          AppConstants.emailSentMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.getCompanyDetails(params.token);
      this.validateToken(params.token);
    });
  }

  toolbarCreate(args: ToolbarCreateEventArgs) {
    for (let i = 0; i < args.items.length; i++) {
      if (args.items[i].id === this.fileManagerInstance.element.id + '_tb_preview') {
        args.items[i].prefixIcon = 'e-icons e-preview';
        args.items[i].tooltipText = 'Preview File';
      }
    }
  }

}
