import { AfterViewInit, Component, OnInit,NgZone,Compiler, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import * as AWS from 'aws-sdk';
import { SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html'
})
export class ViewComponent implements OnInit, AfterViewInit {
  myUserForm: UntypedFormGroup;
  @ViewChild("fileUpload") fileUpload: any;
  public selectedUserNameStr: any;
  public file: any;
  public fileInput: any
  public PwdInputType: any = 'password';
  public hidePwdInputType: any = 'password';
  public fileName: any;
  public titleDataSet: SelectItem[];
  public deptDataSet: SelectItem[];
  public managerDataSet: SelectItem[];
  public isImageUrl: boolean = false;
  public isInvalidZip: boolean = false;
  public isAdminUser: boolean = false;
  selectedLanguage: any;
  selectLanguages: SelectItem[];
  public isFormValid: boolean = false;
  public isEmailExits: boolean = false;
  public emailExists: boolean = false;
  public imageUrl: any = '';
  public StaffID: number = null;
  public resourceId: number = null;
  public userDataSource: any;
  public newUserData: any;
  public loggedUserId: any = -1;
  public prevLang: any;
  planInfo: any;
  origInactive = false;
  createMode = null;
  isTFAEnabled = false

  private emailCheckTimeout: any;

  constructor(public translate: TranslateService,private zone: NgZone,private _compiler: Compiler,private router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, private _fb: UntypedFormBuilder, private mangoUtils: mangoUtils) {
    this._compiler.clearCache();
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.loggedUserId = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.StaffID = this.encrDecSrvc.getObject(AppConstants.usersID) ?? this.loggedUserId;
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.selectedUserName);
    this.planInfo = this.encrDecSrvc.getObject(AppConstants.planInfo);
    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('user.user_list') },
        { label: this.translate.instant('user_information')},
        { label: this.selectedUserNameStr, icon: 'ic-red'}
      ]);
    })
    this.translate.onLangChange.subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('user.user_list') },
        { label: this.translate.instant('user_information')},
        { label: this.selectedUserNameStr, icon: 'ic-red'}
      ]);
    })
    this.initializeUserForm();
    if (this.resourceId == this.StaffID && this.StaffID > 0) {
      this.isAdminUser = true;
    }
  }

  ngOnInit(): void {
    const staffList = this.encrDecSrvc.getObject(AppConstants.staffList);
    this.managerDataSet = this.setDropDownLabels(staffList, 'StaffName', 'StaffID', 'Select Staff');
    this.selectLanguages = [
      { value: 'English', label: 'English' },
      { value: 'Spanish', label: 'Spanish' }
      //{ value: 'Spanish', label: 'Spanish' },//Spanish
    ];
    this.selectedLanguage = this.selectLanguages[0].value;
    this.fetchDefaultUserDropDownValues();
    //this.updateUserForm(this.userDataSource);
  }

  ngAfterViewInit() {
    this.mangoAPISrvc.isFormChanged = false;
  }

  initializeUserForm() {
    this.myUserForm = this._fb.group({
      BitAddCalendarResource: [true],
      City: [''],
      Country: [''],
      DateAnniversary: [''],
      DateBirthday: [''],
      DateHired: [''],
      DateTerminated: [''],
      Email: ['', [<any>Validators.required, <any>Validators.maxLength(50), Validators.email]],
      Inactive: [false],
      ManagerID: [''],
      ClientID: [''],
      MemoStaff: [''[<any>Validators.minLength(10), <any>Validators.maxLength(600)]],
      ProfilePic: [''],
      StaffDeptID: [''],
      Language: ['English'],
      StaffID: [''],
      StaffName: ['', [<any>Validators.required,<any>Validators.maxLength(70)]],
      StaffNumber: ['', [<any>Validators.required,<any>Validators.maxLength(70)]],
      StaffTitleID: [''],
      State: [''],
      Street1: ['', [<any>Validators.maxLength(100), <any>Validators.minLength(5)]],
      Street2: ['', [<any>Validators.maxLength(100)]],
      SystemPassword: ['', []],
      ConfirmPassword: ['', []],
      TargetDayHours: ['6'],
      TargetWeekHours: ['32'],
      TargetMonthHours: ['135'],
      TargetYearHours: ['1600'],
      Tele1Descr: ['', [<any>Validators.maxLength(70)]],
      Tele2Descr: ['', [<any>Validators.maxLength(70)]],
      Tele1: [''],
      Tele2: [''],
      TeleHome: [''],
      TeleMobile: [''],
      TeleOffice: [''],
      Zip: [''],
      EmergencyName: ['', [<any>Validators.maxLength(70), <any>Validators.minLength(3)]],
      EmergencyPhone1: [''],
      EmergencyPhone2: [''],
      EmergencyRelation: ['', [<any>Validators.maxLength(50)]]
    });
    this.myUserForm.valueChanges.subscribe(data => {
      this.validateForm();
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.myUserForm.pristine);
      if(this.myUserForm.controls['Email'].value && this.myUserForm.controls['Email'].value.length>0){
        this.isEmailExits = true;
      } else{
        this.isEmailExits = false;
      }
    })
  }

  initEditableUser() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getUserById(this.StaffID).subscribe((data) => {
      this.userDataSource = data;
      this.origInactive = data['Inactive'];
      this.updateUserForm(data);
      this.mangoAPISrvc.showLoader(false);
    }, error => {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    });
  }

  updateUserForm(itemData: any) {
    if (Object.keys(itemData).length > 0 && this.StaffID > 0) {
      /* default values */
      this.myUserForm.controls['BitAddCalendarResource'].setValue(itemData['BitAddCalendarResource']);

      itemData['DateAnniversary'] = (itemData['DateAnniversary']) ? new Date(itemData['DateAnniversary']) : itemData['DateAnniversary'];
      this.myUserForm.controls['DateAnniversary'].setValue(itemData['DateAnniversary']);

      itemData['DateBirthday'] = (itemData['DateBirthday']) ? new Date(itemData['DateBirthday']) : itemData['DateBirthday'];
      this.myUserForm.controls['DateBirthday'].setValue(itemData['DateBirthday']);

      itemData['DateHired'] = (itemData['DateHired']) ? new Date(itemData['DateHired']) : itemData['DateHired'];
      this.myUserForm.controls['DateHired'].setValue(itemData['DateHired']);

      itemData['DateTerminated'] = (itemData['DateTerminated']) ? new Date(itemData['DateTerminated']) : itemData['DateTerminated'];
      this.myUserForm.controls['DateTerminated'].setValue(itemData['DateTerminated']);

      this.myUserForm.controls['Inactive'].setValue(itemData['Inactive']);
      this.myUserForm.controls['ManagerID'].setValue(itemData['ManagerID']);
      itemData['MemoStaff'] = (itemData['MemoStaff']) ? itemData['MemoStaff'] : '';
      this.myUserForm.controls['MemoStaff'].setValue(itemData['MemoStaff']);
      this.myUserForm.controls['StaffDeptID'].setValue(itemData['StaffDeptID']);
      this.myUserForm.controls['StaffID'].setValue(itemData['StaffID']);
      this.myUserForm.controls['StaffNumber'].setValue(itemData['StaffNumber']);

      this.myUserForm.controls['StaffTitleID'].setValue(itemData['StaffTitleID']);
      itemData['TargetDayHours'] = (itemData['TargetDayHours']) ? itemData['TargetDayHours'] : '';
      this.myUserForm.controls['TargetDayHours'].setValue(itemData['TargetDayHours'].toString());

      itemData['TargetMonthHours'] = (itemData['TargetMonthHours']) ? itemData['TargetMonthHours'] : '';
      this.myUserForm.controls['TargetMonthHours'].setValue(itemData['TargetMonthHours'].toString());

      itemData['TargetWeekHours'] = (itemData['TargetWeekHours']) ? itemData['TargetWeekHours'] : '';
      this.myUserForm.controls['TargetWeekHours'].setValue(itemData['TargetWeekHours'].toString());

      itemData['TargetYearHours'] = (itemData['TargetYearHours']) ? itemData['TargetYearHours'] : '';
      this.myUserForm.controls['TargetYearHours'].setValue(itemData['TargetYearHours'].toString());

      /* User Information Data*/
      itemData['StaffName'] = (itemData['StaffName']) ? itemData['StaffName'].trim() : "";
      itemData['Street1'] = (itemData['Street1']) ? itemData['Street1'].trim() : "";
      itemData['Street2'] = (itemData['Street2']) ? itemData['Street2'].trim() : "";
      itemData['Zip'] = (itemData['Zip']) ? itemData['Zip'].trim() : "";
      itemData['City'] = (itemData['City']) ? itemData['City'].trim() : "";
      itemData['State'] = (itemData['State']) ? itemData['State'].trim() : "";
      itemData['Country'] = (itemData['Country']) ? itemData['Country'].trim() : "";

      this.myUserForm.controls['StaffName'].setValue(itemData['StaffName']);
      this.myUserForm.controls['Email'].setValue(itemData['Email']);
      this.myUserForm.controls['Street1'].setValue(itemData['Street1']);
      this.myUserForm.controls['Street2'].setValue(itemData['Street2']);
      this.myUserForm.controls['City'].setValue(itemData['City']);
      this.myUserForm.controls['State'].setValue(itemData['State']);
      this.myUserForm.controls['Zip'].setValue(itemData['Zip']);
      this.myUserForm.controls['Country'].setValue(itemData['Country']);
      this.myUserForm.controls['TeleOffice'].setValue((itemData['TeleOffice']));
      this.myUserForm.controls['TeleMobile'].setValue(itemData['TeleMobile']);
      this.myUserForm.controls['TeleHome'].setValue(itemData['TeleHome']);
      this.myUserForm.controls['Tele1'].setValue(itemData['Tele1']);
      this.myUserForm.controls['Tele2'].setValue(itemData['Tele2']);
      this.myUserForm.controls['Tele1Descr'].setValue(itemData['Tele1Descr']);
      this.myUserForm.controls['Tele2Descr'].setValue(itemData['Tele2Descr']);
      this.isTFAEnabled = !!itemData['TFASecret']
      itemData['Email'] = (itemData['Email']) ? itemData['Email'] : '';
      if(itemData['Email'] && itemData['Email'].length>0){
        this.isEmailExits = true;
      }else{
        this.isEmailExits = false;
      }
      this.myUserForm.controls['Email'].setValue(itemData['Email']);

      this.myUserForm.controls['ProfilePic'].setValue(itemData['ProfilePic']);
      itemData['EmergencyName'] = (itemData['EmergencyName']) ? itemData['EmergencyName'] : '';
      this.myUserForm.controls['EmergencyName'].setValue(itemData['EmergencyName']);
      itemData['EmergencyRelation'] = (itemData['EmergencyRelation']) ? itemData['EmergencyRelation'] : '';
      this.myUserForm.controls['EmergencyRelation'].setValue(itemData['EmergencyRelation']);
      this.myUserForm.controls['EmergencyPhone1'].setValue(itemData['EmergencyPhone1']);
      this.myUserForm.controls['EmergencyPhone2'].setValue(itemData['EmergencyPhone2']);
      itemData['Language'] = itemData['Language'] ? itemData['Language'] :'English';
      this.myUserForm.controls['Language'].setValue(itemData['Language']);
      this.prevLang = itemData['Language'];

      this.imageUrl = itemData['ProfilePic'];
      if (this.imageUrl) {
        this.isImageUrl = true;
        const index = this.imageUrl.indexOf('https://s3.amazonaws.com/mangobillinglogos/userprofilepic/')
        if (index > 0) {
          const img = this.imageUrl.substring(this.imageUrl.indexOf('https://s3.amazonaws.com/mangobillinglogos/userprofilepic/') + 9, this.imageUrl.length)
          this.myUserForm.controls['ProfilePic'].setValue(img);
        }
      }
      this.myUserForm.controls["SystemPassword"].setValidators([Validators.maxLength(70), Validators.minLength(3)]);
      this.myUserForm.get('SystemPassword').updateValueAndValidity();
      this.myUserForm.controls["ConfirmPassword"].setValidators([]);
      this.myUserForm.get('ConfirmPassword').updateValueAndValidity();
    }
  }

  makeUserInactive() {
    const self = this;
    if (this.planInfo.SubscriptionLevel.toLowerCase() == "free") {
      if(Number(this.planInfo.NoofActiveUsers) < Number(this.planInfo.NumberofUsers)){
        return true;
      }
      else{
        Swal.fire(
          self.translate.instant('cancelled'),
          self.translate.instant('user_added_confirmation_message'),
          'error'
        )
        return false;
      }
    } else if (Number(this.planInfo.NumberofUsers) <= Number(this.planInfo.NoofActiveUsers)) {
      Swal.fire(
        self.translate.instant('cancelled'),
        self.translate.instant('user_added_confirmation_message'),
        'error'
      )
      return false;
    }
    return true;
  }

  getPlanInfo(){
    const parent = this;
    this.mangoAPISrvc.getPlanInformation().subscribe((planInfo:any) => {
      parent.encrDecSrvc.removeObject(AppConstants.planInfo);
      parent.encrDecSrvc.addObject(AppConstants.planInfo, planInfo);
      parent.planInfo = planInfo;
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
    });
  }

  saveUserData(arg1: any) {
    this.mangoAPISrvc.isFormChanged = false;
    this.isFormValid = false;
    let obj = this.myUserForm.value;
    if(this.origInactive && !obj.Inactive && !this.makeUserInactive()){
      this.myUserForm.controls['Inactive'].setValue(true);
      return;
    }
    if (obj.Email === "") {
      obj = this.userDataSource;
    }
    obj.Email = obj.Email.toLowerCase();
    // update Image Url
    obj['ProfilePic'] = this.imageUrl;
    obj['DateHired'] = (obj['DateHired']) ? obj['DateHired'] : null;
    obj['DateBirthday'] = (obj['DateBirthday']) ? obj['DateBirthday'] : null;
    obj['DateAnniversary'] = (obj['DateAnniversary']) ? obj['DateAnniversary'] : null;
    obj['DateTerminated'] = (obj['DateTerminated']) ? obj['DateTerminated'] : null;

    obj['TargetYearHours'] = (obj['TargetYearHours']) ? parseInt(obj['TargetYearHours']) : null;
    obj['TargetMonthHours'] = (obj['TargetMonthHours']) ? parseInt(obj['TargetMonthHours']) : null;
    obj['TargetWeekHours'] = (obj['TargetWeekHours']) ? parseInt(obj['TargetWeekHours']) : null;
    obj['TargetDayHours'] = (obj['TargetDayHours']) ? parseInt(obj['TargetDayHours']) : null;
    obj['StaffTitleID'] = (obj['StaffTitleID']) ? parseInt(obj['StaffTitleID']) : null;
    obj['StaffDeptID'] = (obj['StaffDeptID']) ? parseInt(obj['StaffDeptID']) : null;
    obj['ManagerID'] = (obj['ManagerID']) ? parseInt(obj['ManagerID']) : null;
    obj['StaffID'] = (obj['StaffID']) ? parseInt(obj['StaffID']) : null;
    const parent = this;
    parent.selectedUserNameStr = obj.StaffName;
    // -1 for usersID is being returned as true hence, the api endpoint will return an error
    if (parent.StaffID > 0) {
      obj['SystemPassword'] = (obj['SystemPassword']?.length) ? obj['SystemPassword'] : null;
      parent.mangoAPISrvc.updateUserByAdmin(parent.StaffID, obj).subscribe((data) => {
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.getPlanInfo();
        if (data.message) {
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
        }
        if (parent.StaffID == parent.resourceId) {
          parent.encrDecSrvc.addObject(AppConstants.userName, parent.myUserForm.controls['StaffName'].value);
          parent.encrDecSrvc.addObject(AppConstants.imageUrl, obj['ProfilePic']);
          parent.mangoAPISrvc.fireImageView(true);
          //parent._authGuard.switchLang(obj.Language);
        }
        const permissionObj = parent.encrDecSrvc.getObject(AppConstants.userPermissionObj);
          if(parent.StaffID == permissionObj?.StaffID)
            parent.encrDecSrvc.addObject(AppConstants.userPermissionObj, {
              ...permissionObj,
              BitAddCalendarResource:
                parent.myUserForm.controls["BitAddCalendarResource"]
                  .value,
            });
        if (parent.myUserForm.controls['BitAddCalendarResource'].value == true) {
          const resourceObj = {};
          resourceObj['resourceId'] = parent.StaffID;
          resourceObj['ResourceName'] = parent.myUserForm.controls['StaffName'].value;
          resourceObj['Color'] = '#0000ff';
          resourceObj['CustomField1'] = null;
          // getting unique id from staff table
          parent.mangoAPISrvc.getUniqueIdFromResourceId(parent.StaffID).subscribe(function (item:any) {
            if (item.length == 0) {
              parent.mangoAPISrvc.createAppointmentResource(resourceObj).subscribe((resource) => {
                parent.router.navigate([parent.router.url]);
                parent.loadLanguage();
              });
            } else {
              resourceObj['UniqueID'] = item[0].UniqueID;
              parent.mangoAPISrvc
                .updateAppointmentResource({
                  UniqueID: resourceObj["UniqueID"],
                  ResourceName: resourceObj["ResourceName"],
                })
                .subscribe((resource) => {
                  parent.router.navigate([parent.router.url]);
                  parent.loadLanguage();
                });
            }
          });

        } else {
          // getting unique id from staff table
          parent.mangoAPISrvc.deleteAppointmentResource(parent.StaffID).subscribe(function (data) {
            parent.router.navigate([parent.router.url]);
            parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          });
        }
      }, error => {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      });
    } else {
      const self = this;
      this.newUserData = obj;
      if (Number(self.planInfo.numberofUsers) <= Number(self.planInfo.noofActiveUsers)) {
        Swal.fire(
          self.translate.instant('cancelled'),
          self.translate.instant('user_added_confirmation_message'),
          'error'
        )
        self.redirectToUserList();
      } else {
        this.continueSaveUser();
      }
    }
  }

  redirectToUserList() {
    this.encrDecSrvc.addObject(AppConstants.usersID, -1);
    this.router.navigate([AppConstants.users + '/' + AppConstants.listRoutePath]);
    setTimeout(() => {
      this.mangoAPISrvc.fireUserView(true);
    }, 100);
  }

  redirectToAddUser(){
    const self = this;
    if (Number(this.planInfo.NumberofUsers) <= Number(this.planInfo.NoofActiveUsers)) {
      Swal.fire(
        self.translate.instant('cancelled'),
        self.translate.instant('user_added_confirmation_message'),
        'error'
      )
    } else {
      this.initializeUserForm();
      this.StaffID = -1;
      this.fetchDefaultUserDropDownValues(true);
      this.encrDecSrvc.addObject(AppConstants.usersID, -1);
      this.encrDecSrvc.addObject(AppConstants.selectedUserName, "");
      this.mangoAPISrvc.fireUserView(true);
    }
  }

  setDropDownLabels(data, title, value, defaultValue) {
    for (let i = 0; i < data.length; ++i) {
      data[i]['label'] = data[i][title];
      data[i]['value'] = data[i][value]
    }
    data.unshift({ label: defaultValue, value: null });
    return data;
  }

  fetchDefaultUserDropDownValues(createMode?) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.loadDefaultUserDropDownList().subscribe((results: any) => {
      self.titleDataSet = self.setDropDownLabels(results[0], 'StaffTitleDescription', 'StaffTitleID', 'Select Title');
      self.deptDataSet = self.setDropDownLabels(results[1], 'DeptDescription', 'StaffDeptID', 'Select Department');
      self.mangoAPISrvc.showLoader(false);
      if (self.StaffID && self.StaffID != -1) {
        this.createMode = createMode;
        self.initEditableUser();
      } else {
        this.createMode = true;
        self.PwdInputType = self.hidePwdInputType = 'text';
        self.myUserForm.controls['Email'].setValue(null);
        self.myUserForm.controls['SystemPassword'].setValue('1234');
        self.myUserForm.controls['ConfirmPassword'].setValue('1234');
        self.checkSubscription();
        self.myUserForm.controls["SystemPassword"].setValidators([Validators.required, Validators.maxLength(70), <any>Validators.minLength(3)]);
        self.myUserForm.get('SystemPassword').updateValueAndValidity();
        self.myUserForm.controls["ConfirmPassword"].setValidators([<any>Validators.required]);
        self.myUserForm.get('ConfirmPassword').updateValueAndValidity();
        self.prevLang = 'English';
      }
    });
  }

  checkSubscription() {
    const self = this;
    const SubscriptionLevel = self.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    if (SubscriptionLevel == "free") {
      Swal.fire(
        self.translate.instant('cancelled'),
        self.translate.instant('user_added_confirmation_message'),
        'error'
      )
    } else if (Number(self.planInfo.numberofUsers) <= Number(self.planInfo.noofActiveUsers)) {
      Swal.fire(
        self.translate.instant('cancelled'),
        self.translate.instant('user_added_confirmation_message'),
        'error'
      )
    }
  }

  removeImage() {
    if (this.imageUrl) {
      this.myUserForm.controls['ProfilePic'].setValue(null);
      this.imageUrl = null;
      this.isImageUrl = false;
      this.isFormValid = true;
      //this.encrDecSrvc.addObject(AppConstants.imageUrl, null);
      this.validateForm();
    }
  }

  uploadImage(): void {
    const uniqueName = `${this.mangoUtils.generateUUID()}_${this.encrDecSrvc.getObject(AppConstants.companyID)}_${this.file.name}`
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = 'us-east-1'
    let bucket = new AWS.S3({ params: { Bucket: 'mangobillinglogos' } });
    */
    const bucket = (
      AWSBucketMask
    );
    const params = { Bucket: 'mangobillinglogos', Key: 'userprofilepic/' + uniqueName, ACL: "public-read", Body: this.file };
    const self = this;
    bucket.upload(
      params,

      function (err, data) {
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.isImageUrl = true;
        self.imageUrl = data.Location;
        self.myUserForm.controls['ProfilePic'].markAsDirty();
        self.myUserForm.controls['ProfilePic'].setValue(self.imageUrl);
        self.validateForm();
        self.fileUpload.clear()
      },

      self.mangoAPISrvc
    );
  }

  fileEvent(fileInput: any) {
    for (const file of fileInput.files) {
      this.file = file;
      this.uploadImage();
    }
  }

  checkIfUserExistsOrNot(email, control, enteredValue) {
    this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.isUserExitsOrNot(email).subscribe((data) => {
          if (data['Email'] && data['Email'] !== '') {
            this.emailExists = true;
            this.isFormValid = false;
            this.myUserForm.controls['Email'].setErrors({'incorrect': true});
            this.mangoAPISrvc.notify('error', this.translate.instant('error'), "This email address is no longer available!")
          } else {
            this.emailExists = false;
          }
          this.mangoAPISrvc.showLoader(false);
        }, error => {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        });
  }

  changeText(event, control: any, type: any) {
    const enteredValue = control['value'];
    if (enteredValue == "" || enteredValue === null || control.invalid) { return null }
    if (type === 'lower') {
      clearTimeout(this.emailCheckTimeout);
      this.emailCheckTimeout = setTimeout(() => {
        if (this.StaffID != -1) {
          const previousEmail = this.userDataSource.Email.toLowerCase();
          if (previousEmail !== enteredValue.toLowerCase()) {
            this.checkIfUserExistsOrNot(enteredValue.toLowerCase(), control, enteredValue);
            control.setValue(enteredValue.toLowerCase());
          } else {
            control.setValue(enteredValue.toLowerCase());
            this.emailExists = false;
          }
        } else {
          const obj = {};
          obj['email'] = enteredValue.toLowerCase();
          this.checkIfUserExistsOrNot(obj['email'], control, enteredValue);
          control.setValue(enteredValue.toLowerCase());
        }
      }, 500);
    } else {
      control.setValue(enteredValue.toUpperCase());
    }
  }


  validateForm() {
    let isInValidData = false;
    Object.keys(this.myUserForm.controls).forEach(key => {
      if (this.myUserForm.get(key).invalid || this.myUserForm.get('Email').invalid) {
        isInValidData = true;
      }
    });
    this.isFormValid = !isInValidData;
    this.mangoAPISrvc.isFormChanged = !this.isFormValid;
  }

  changePwdType() {
    this.PwdInputType = (this.PwdInputType == 'password') ? 'text' : 'password';
  }

  changehidePwdType() {
    this.hidePwdInputType = (this.hidePwdInputType == 'password') ? 'text' : 'password';
  }

  continueSaveUser() {
    this.mangoAPISrvc.showLoader(true);
    const self = this;
    const callback = (data) => {
      self.mangoAPISrvc.createUser(self.newUserData).subscribe((data:any) => {
        this.mangoAPISrvc.showLoader(false);
        if (data.message) {
          const usersActive = Number(self.planInfo.noofActiveUsers) + 1;
          self.encrDecSrvc.removeObject(AppConstants.NoofActiveUsers);
          self.encrDecSrvc.addObject(AppConstants.NoofActiveUsers, usersActive);
          self.getPlanInfo();
          self.mangoAPISrvc.notify('success', self.translate.instant('Success'), AppConstants.createMsg);
          self.createUserRatesPermissonschedules(data.StaffID);
          self.StaffID = data.StaffID;
          self.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          if (self.myUserForm.controls['BitAddCalendarResource'].value == true) {
            const resourceObj = {};
            resourceObj['resourceId'] = data.StaffID;
            resourceObj['ResourceName'] = self.myUserForm.controls['StaffName'].value;
            resourceObj['Color'] = '#0000ff';
            resourceObj['CustomField1'] = null;
            self.mangoAPISrvc.createAppointmentResource(resourceObj).subscribe((data) => {
              self.loadLanguage();
            });
          } else {
            self.mangoAPISrvc.deleteAppointmentResource(data.StaffID).subscribe(function (data) {self.loadLanguage(); });
          }
        }
      }, error => {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      });

    }
    callback(null);
  }

   createUserRatesPermissonschedules(id) {
    const defaultPermissionObj = { "Permission": 1, "PermissionDescription": "Administrator" };
    const defaultUserRatesObj = { "DefaultBillingRate": "0.00", "ActivateBillingRateProfiles": null };
    const defaultcostMethodObj = { "FixedRateBit": true, "PercentBit": false, "PercentBilling": "0", "AverageCostPerHour": "0.00" };
    const defaultScheduleObj = { "FirstDayofWeek": "Monday", "DefaultView": "Work Week", "DefaultTimeInterval": "30 minutes", "EndTimeVisible": "1970-01-01T16:00:00Z", "EndTimeWork": "1970-01-01T16:00:00Z", "StartTimeVisible": "1970-01-01T09:00:00Z", "StartTimeWork": "1970-01-01T09:00:00Z", "WebAppDaysofWeek": [1, 2, 3, 4, 5] };
     const parent = this;
     parent.mangoAPISrvc.showLoader(true);
     parent.mangoAPISrvc.updateNewUserDefaults(id, defaultPermissionObj, defaultUserRatesObj, defaultcostMethodObj, defaultScheduleObj).subscribe((results: any) => {
       if (parent.myUserForm.controls['BitAddCalendarResource'].value == true) {
         const resourceObj = {};
         resourceObj['resourceId'] = id;
         resourceObj['ResourceName'] = parent.myUserForm.controls['StaffName'].value;
         resourceObj['Color'] = '#0000ff';
         resourceObj['CustomField1'] = null;
         // getting unique id from staff table
          parent.mangoAPISrvc.getUniqueIdFromResourceId(id).subscribe(function (item:any) {
           if (item.length == 0) {
             parent.mangoAPISrvc.createAppointmentResource(resourceObj).subscribe((resource) => {
             });
           }
         });
       }
       parent.mangoAPISrvc.showLoader(false);
     });
  }
  matchingPasswords(SystemPasswordKey: string, ConfirmPasswordKey: string) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[SystemPasswordKey];
      const passwordConfirmationInput = group.controls[ConfirmPasswordKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ mismatchedPasswords: true })
      }
    }
  }

  sendMail() {
    this.mangoAPISrvc.showLoader(true);
    const obj = this.myUserForm.value;
    const companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    const sendInBlueObj = { "sender": { "name": "Mango Billing", "email": environment.EMAIL_RETURN_SENDER }, "to": [], "replyTo": { "email": environment.EMAIL_RETURN_SENDER }, "templateId": null, "params": {} };
    const toObj = { "email": "", "name": "" };

    toObj['email'] = obj['Email'];
    toObj['name'] = obj['StaffName'];
    sendInBlueObj.to.push(toObj);

    sendInBlueObj['templateId'] = 46;
    sendInBlueObj.params['user'] = obj.StaffName;
    sendInBlueObj.params['password'] = obj.SystemPassword;
    sendInBlueObj.params['companyname'] = companyName;

    this.mangoAPISrvc.sendUserEmailInvite({sendInBlueObj, email: obj['Email'], staffID: this.StaffID}).subscribe(sentMail => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), 'Email sent successfully.');
    }, err => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', this.translate.instant('Error'), 'There was an error sending the Email.');
    });
  }

  // ngOnDestroy() {
  //   if (this.isFormValid && this.myUserForm.dirty) {
  //     this.saveUserData(this.myUserForm.value);
  //   }
  // }

  loadLanguage(){
    const obj = this.myUserForm.value;
    if(this.prevLang != obj.Language){
      this.prevLang = obj.Language;
      this._compiler.clearCache();
      localStorage.setItem("language", obj.Language);
      if(obj.Language == 'English'){
        this.translate.setDefaultLang('en');
        this.translate.use('en');
      }else{
        this.translate.setDefaultLang('es');
        this.translate.use('es');
      }
    }
  }

  onDisableTFA() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.deleteTFA(this.StaffID).subscribe((data) => {
      this.isTFAEnabled = false
      this.mangoAPISrvc.showLoader(false);
    }, err => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', 'Error!', err);
    });
  }

}
