import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetupWizardComponent } from './setup-wizard.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { CompanyLogoComponent } from './company-logo/company-logo.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { FinishedComponent } from './finished/finished.component';
import { AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: SetupWizardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CompanyInfoComponent, canActivate: [AuthGuard] },
      { path: AppConstants.companyInfo, component: CompanyInfoComponent, canActivate: [AuthGuard] },
      { path: AppConstants.companyLogo, component: CompanyLogoComponent, canActivate: [AuthGuard] },
      { path: AppConstants.companysettings, component: CompanySettingsComponent, canActivate: [AuthGuard] },
      { path: AppConstants.userSettings, component: UserSettingsComponent, canActivate: [AuthGuard] },
      { path: AppConstants.finished, component: FinishedComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SetupWizardRoutingModule { }
