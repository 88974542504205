import { Component, OnInit, AfterViewInit, isDevMode, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils } from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import Swal from "sweetalert2";
import { environment } from "@environments/environment";
import { HttpEvent, HttpResponse } from "@angular/common/http";
import { LazyLoadEvent } from "primeng/api";
import { ModuleDateExclusionComponent } from './module-date-exclusion/module-date-exclusion.component';
import { finalize } from "rxjs/operators";
import { instructions } from './instructions';

interface Instruction {
  title: string;
  steps: string[];
}

@Component({
  selector: "app-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.css"],
})
export class IntegrationsComponent implements OnInit, AfterViewInit {
  @ViewChild(ModuleDateExclusionComponent)
  moduleDateExclusionComponent!: ModuleDateExclusionComponent;
  isEnableFile: boolean = false;
  isEnableAuthBtn: boolean = false;
  isFormValid: boolean = false;
  isLoading: boolean = false;
  isReviewCust: boolean = false;
  isValidSelection: boolean = false;
  qbSelection: string;
  public file: any;
  public nativeWindow: any;
  public currentStatus: number = -1;
  qb: any = {};
  companyData: any = {};
  mapIShareMango: any = [];
  busy: Promise<any>;
  defaultDBSettings = {
    username: "",
    password: "",
    qbfilePath: "",
    moduleAllowed: {},
    status: 0,
    dateSyncStart: "",
    defaultItemId: "",
  };
  public imagineshare: any = {
    vendorAPIKeyIS: null,
    APIKeyMangoIS: null,
    isIntegrateIS: false,
    isCopied: false,
    currentProcess: "",
    CompanyIDImagineShare: null,
    mangoCompanyID: -1,
  };

  public mySettingsForm: UntypedFormGroup;
  loggedInemail;
  staffId;
  companyId;
  superAdminVisible: number[] = AppConstants.superAdminVisible;
  duplicateClients = [];
  isIshareConnected: boolean = false;
  isFormChanged: boolean = false;
  public qbSyncErrors: any = [];
  public qbErrors: any[] = [];
  public totalRecords: number = 0;
  public qbErrorDetails: any = [];
  public showErrorDetailsDialog: boolean = false;
  public showDataDialog: boolean = false;
  public showInstructionsDialog: boolean = false;
  public formattedJson: string = "";
  public first: number = 0;
  public errorDetailsTitle: string = "";

  public clientCount: number = 0;
  public userConfirmed: boolean = false;
  public quickBooksPrefix: string = "";
  public QBOAuthSuccess: boolean = false;

  public defaultQBOItemsList: any = [];

  isInstructionsModalVisible = false;

  instructions: Instruction[];

  public isTrialVersion: boolean = false;

  // QB Available Modules
  public qbModules: any = [
    { name: "Customers", platform: "both" },
    { name: "Items", platform: "web" },
    { name: "ItemServices", platform: "desktop" },
    { name: "Invoices", platform: "both" },
    { name: "ItemGroups", platform: "desktop" },
    { name: "Payments", platform: "web" },
    { name: "Deposits", platform: "both" },
    { name: "ReceivePayment", platform: "desktop" },
  ];

  constructor(
    private _fb: UntypedFormBuilder,
    protected mangoUtils: mangoUtils,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.loggedInemail = this.encrDecSrvc.getObject(AppConstants.userEmail);
    this.staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.initializeSettingsForm();
    this.loadAvailableQBOItems();
    //this.applyISharesettings();
  }

  ngOnInit(): void {
    this.instructions = instructions;
    // this.quickBooksPrefix = "http://ec2-54-161-223-237.compute-1.amazonaws.com:8083";
    this.quickBooksPrefix = environment.QB_ENDPOINT_URL;
    // this.quickBooksPrefix = isDevMode() ? "http://ec2-54-161-223-237.compute-1.amazonaws.com:8083" : environment.QB_ENDPOINT_URL;
    this.getUserCredentials();
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.isLoading = true;
    this.loadDataOnPageChange({ first: this.first, rows: 10 });
    
    this.mangoAPISrvc.fetchClientsCount().subscribe(
      (data: any) => {
        this.clientCount = data?.active;
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  ngAfterViewInit() {
    this.mySettingsForm.valueChanges.subscribe((values) => {
      this.isFormValid = this.mySettingsForm.valid && this.mySettingsForm.dirty;
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, this.isFormValid);
      if (this.currentStatus == 1) {
        this.isEnableAuthBtn = true;
      } else if (this.mySettingsForm.dirty) {
        this.isEnableAuthBtn = false;
      }
    });
   
    //free trial check
    this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe((data:any) =>{
			//console.log(data);
			if (data[0].SubscriptionLevel === "FREE"){
        this.isTrialVersion = true;
      //free trial pop up
        Swal.fire({
          title: this.translate.instant('Information'),
          html: `<div>Quickbooks Integration is not allowed during FREE Trial.  Please contact sales for more information.</div>`,
          icon: 'info',
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Cancel',
        });
      }
		});

  }

  filteredCheckboxes() {
    const isQBWeb = this.mySettingsForm.controls["IsQBWeb"].value;
    return this.qbModules.filter((checkbox) => {
      if (isQBWeb) {
        return checkbox.platform === "both" || checkbox.platform === "web";
      } else {
        return checkbox.platform === "both" || checkbox.platform === "desktop";
      }
    });
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant("Settings") },
      { label: this.translate.instant("integrations"), icon: "ic-red" },
    ]);
  }

  getModulesConfigurationQB(data, isQBWeb) {
    const moduleAllowedJson = data.data.moduleAllowedJson;

    const jsonTest = JSON.parse(JSON.stringify(moduleAllowedJson));
    if (isQBWeb) {
      if (jsonTest.hasOwnProperty("Customer")) {
        this.setFormControls("QBSyncCustomers", "QBImportCustomers", jsonTest.Customer);
      }

      if (jsonTest.hasOwnProperty("Item")) {
        this.setFormControls("QBSyncItems", "QBImportItems", jsonTest.Item);
      }

      if (jsonTest.hasOwnProperty("Invoice")) {
        this.setFormControls("QBSyncInvoices", "QBImportInvoices", jsonTest.Invoice);
      }

      if (jsonTest.hasOwnProperty("Payment")) {
        this.setFormControls("QBSyncPayments", "QBImportPayments", jsonTest.Payment);
      }

      if (jsonTest.hasOwnProperty("Deposit")) {
        this.setFormControls("QBSyncDeposits", "QBImportDeposits", jsonTest.Deposit);
      }
    } else {
      this.mySettingsForm.controls["QBCompanyFileLocation"].setValue(data.data.qbfilePath);

      if (jsonTest.hasOwnProperty("Customer")) {
        this.setFormControls("QBSyncCustomers", "QBImportCustomers", jsonTest.Customer);
      }

      if (jsonTest.hasOwnProperty("ItemGroup")) {
        this.setFormControls("QBSyncItemGroups", "QBImportItemGroups", jsonTest.ItemGroup);
      }

      if (jsonTest.hasOwnProperty("Invoice")) {
        this.setFormControls("QBSyncInvoices", "QBImportInvoices", jsonTest.Invoice);
      }

      if (jsonTest.hasOwnProperty("ReceivePayment")) {
        this.setFormControls(
          "QBSyncReceivePayment",
          "QBImportReceivePayment",
          jsonTest.ReceivePayment
        );
      }

      if (jsonTest.hasOwnProperty("ItemService")) {
        this.setFormControls("QBSyncItemServices", "QBImportItemServices", jsonTest.ItemService);
      }

      if (jsonTest.hasOwnProperty("Deposit")) {
        this.setFormControls("QBSyncDeposits", "QBImportDeposits", jsonTest.Deposit);
      }
    }
    this.cd.detectChanges();
  }

  private setFormControls(control1: string, control2: string, value: string): void {
    const values = {
      "2": [true, true],
      "1": [false, true],
      "0": [true, false],
      default: [false, false],
    };
    const [val1, val2] = values[value] || values["default"];
    this.mySettingsForm.controls[control1].setValue(val1);
    this.mySettingsForm.controls[control2].setValue(val2);
  }

  getCompanyByEmail() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getQBProfileData(this.loggedInemail).subscribe(
      (dbData) => {
        const parent = this;
        const isQBWeb = dbData["data"].isQBWeb;
        const QBOAuthSuccess = dbData["data"].QBOAuthSuccess;
        parent.mySettingsForm.controls["IsQBWeb"].setValue(isQBWeb);
        parent.QBOAuthSuccess = QBOAuthSuccess;
        parent.getQuickbookIntegration(dbData);
        parent.getModulesConfigurationQB(dbData, isQBWeb);
        parent.mySettingsForm.controls["currentQbView"].setValue(isQBWeb ? "Online" : "Desktop");
        parent.mySettingsForm.controls["QBIntegration"].setValue(dbData["data"].status == 1 ? false : true);
        const defaultItem = parent.defaultQBOItemsList?.find((i) => i.Id === dbData["data"].defaultItemId);
        parent.mySettingsForm.controls["defaultItemId"].setValue(defaultItem || null);
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getUserCredentials() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getUserCredentails(parent.staffId).subscribe(
      function (userDetails) {
        parent.defaultDBSettings.password = userDetails["SystemPassword"];
        parent.defaultDBSettings.username = userDetails["Email"];
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.notify(
          "error",
          parent.translate.instant("error"),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getQBErrors(page: number, size: number) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getQBErrors(this.loggedInemail, page, size).subscribe(
        (response: HttpResponse<any>) => {
            const totalCount = response.headers.get("X-Total-Count");
            if (totalCount) {
                this.totalRecords = parseInt(totalCount, 10);
            } else {
                this.totalRecords = response.body.length;
                console.warn('X-Total-Count header missing in response, using body length as total records.');
            }
            this.qbErrors = response.body;
            this.mangoAPISrvc.showLoader(false);
            this.cd.detectChanges(); 
        },
        (error) => {
            console.error("Error fetching QB errors:", error);
            this.mangoAPISrvc.showLoader(false);
        }
    );
}


  loadDataOnPageChange(event: LazyLoadEvent) {
    const pageNumber = event.first / event.rows;
    this.first = event.first;
    this.getQBErrors(pageNumber, event.rows);
  }

  getErrorDetails(errorCode: string) {
    if (errorCode != null) {
      this.mangoAPISrvc.getQBErrorDetails(errorCode).subscribe(
        (response: HttpResponse<any>) => {
          this.qbErrorDetails = response.body;
          this.errorDetailsTitle = `Error Details for ${errorCode}`;
          this.showErrorDetailsDialog = true;
        },
        () => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The error details could not be retrieved. Please try again later, or contact support if you need further information.",
          });
        }
      );
    }
  }

  openJSONDataDialog(mangoData: string, event: MouseEvent) {
    event.stopPropagation();
    this.formattedJson = JSON.parse(mangoData);
    this.showDataDialog = true;
  }

  updateRecord(dataStoreId: string, event: MouseEvent) {
    event.stopPropagation();
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateDataStore(dataStoreId).subscribe(
      (httpEvent: HttpEvent<any>) => {
        if (httpEvent instanceof HttpResponse) {
          const statusCode = httpEvent.status;

          if (statusCode === 200) {
            Swal.fire({
              icon: "success",
              title: "Record Updated",
              text: "The record has been updated successfully. It will be taken in the following sync process.",
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong! Please try again later or contact support.",
            });
          }
        }
        this.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        console.log(error);

        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  initializeSettingsForm() {
    this.mySettingsForm = this._fb.group({
      QBImportOpenInvoices: [false],
      SyncFromQB: [false],
      QBIntegration: [false],
      IsQBWeb: [null],
      currentQbView: ["Desktop"],
      QBCompanyFileLocation: [null],
      QBOAuthSuccess: [false],
      dateSyncStart: ["", Validators.required],
      defaultItemId: [""],
    });
    this.qbModules.forEach((item) => {
      this.mySettingsForm.addControl("QBImport" + item.name, this._fb.control(false));
      this.mySettingsForm.addControl("QBSync" + item.name, this._fb.control(false));
    });
  }

  isEnableBrowseButton() {
    const formObj = this.mySettingsForm.value;
    this.isEnableFile =
      formObj.QBIntegration == false ||
      (formObj.QBImportCustomer == false && formObj.QBImportServices == false);
  }

  onStatusChange(event) {
    if (!this.mySettingsForm.controls["QBIntegration"].value) {
      if (this.clientCount > 0 && this.checkQBModuleSelected()) {
        let messageString = "";
        messageString += `<div>Are you sure to disable the QuickBooks Integration?</div>`;
        Swal.fire({
          title: "Confirmation",
          html: messageString,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, I'm sure!`,
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.value) {
            this.userConfirmed = true;
            this.isLoading = false;
            this.qb.QBIntegration = !this.qb.QBIntegration;
            this.mySettingsForm.controls["QBIntegration"].setValue(false);
            this.disableQBModules();
            this.saveQuickbookIntegration();
          } else {
            this.userConfirmed = false;
            this.qb.QBIntegration = false;
            this.mySettingsForm.controls["QBIntegration"].setValue(!this.qb.QBIntegration);
          }
        });
      } else {
        this.userConfirmed = true;
        this.qb.QBIntegration = !this.qb.QBIntegration;
        this.mySettingsForm.controls["QBIntegration"].setValue(this.qb.QBIntegration);
      }
    } else {
      this.userConfirmed = true;
      this.qb.QBIntegration = !this.qb.QBIntegration;
      this.mySettingsForm.controls["QBIntegration"].setValue(this.qb.QBIntegration);
    }
  }

  checkQBModuleSelected() {
    let hasTrueValue = false;

    this.qbModules.forEach((item) => {
      if (
        this.mySettingsForm.controls["QBImport" + item.name].value ||
        this.mySettingsForm.controls["QBSync" + item.name].value
      ) {
        hasTrueValue = true;
      }
    });

    return hasTrueValue;
  }

  getQuickbookIntegration(dbData) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getQuickbookIntegration().subscribe(
      function (data: any) {
        data.QBIntegration = data.QBIntegration ? data.QBIntegration : false;
        parent.qb = data;
        parent.currentStatus = data["IsQBWeb"] ? parseInt(dbData.data.status) : -1;
        if (parent.currentStatus == 1) {
          parent.isEnableAuthBtn = true;
        }
        parent.qb.QBIntegration = data.QBIntegration;
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.notify(
          "error",
          parent.translate.instant("error"),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  changeQBValue(flag) {
    this.mySettingsForm.controls["IsQBWeb"].setValue(flag);
  }

  saveQuickbookIntegration() {
    const messageString = `<div>You have Clients in Mango Billing.  By selecting this option to import Clients, there is a risk that you will <strong>DUPLICATE</strong> Clients!</div>`;
    if (this.clientCount > 0 && this.mySettingsForm.controls["QBIntegration"].value) {
      Swal.fire({
        title: "Confirmation",
        html: messageString,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `It's ok, proceed`,
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.callAPItoSave();
        }
      });
    } else {
      this.callAPItoSave();
    }
  }

  disableQBModules() {
    const formData = this.mySettingsForm.value;
    this.defaultDBSettings.status = 1;
    this.qbModules.forEach((item) => {
      this.mySettingsForm.controls["QBImport" + item.name].setValue(false);
      this.mySettingsForm.controls["QBSync" + item.name].setValue(false);
    });
    if (formData.isQBWeb) {
      this.defaultDBSettings.moduleAllowed["Customer"] = "-1";
      this.defaultDBSettings.moduleAllowed["Item"] = "-1";
      this.defaultDBSettings.moduleAllowed["Invoice"] = "-1";
      this.defaultDBSettings.moduleAllowed["Payment"] = "-1";
      this.defaultDBSettings.moduleAllowed["Deposit"] = "-1";

      formData.QBImportCustomer = false;
      formData.QBImportServices = false;
      formData.QBImportInvoice = false;
      formData.QBImportPayment = false;
      formData.QBImportDeposits = false;
      formData.QBImportItems = false;
      formData.QBSyncCustomers = false;
      formData.QBSyncServices = false;
      formData.QBSyncInvoices = false;
      formData.QBSyncPayments = false;
    } else {
      this.defaultDBSettings.moduleAllowed["Customer"] = "-1";
      this.defaultDBSettings.moduleAllowed["ItemGroup"] = "-1";
      this.defaultDBSettings.moduleAllowed["Invoice"] = "-1";
      this.defaultDBSettings.moduleAllowed["ReceivePayment"] = "-1";
      this.defaultDBSettings.moduleAllowed["Deposit"] = "-1";
      this.defaultDBSettings.moduleAllowed["ItemService"] = "-1";
    }
  }

  callAPItoSave() {
    this.mangoAPISrvc.showLoader(true);
    this.isEnableAuthBtn = false;
    const settings = this.mySettingsForm.controls["currentQbView"].value == "Desktop" ? false : true;
    this.mySettingsForm.controls["IsQBWeb"].setValue(settings);
    const formData = this.mySettingsForm.value;
    this.defaultDBSettings.dateSyncStart = this.mySettingsForm.controls["dateSyncStart"].value;
    this.defaultDBSettings.status = formData.QBIntegration == true ? 0 : 1;
    this.defaultDBSettings.qbfilePath = formData.QBCompanyFileLocation;
    this.defaultDBSettings.moduleAllowed = {};
    if (formData.IsQBWeb) {
      this.defaultDBSettings.moduleAllowed["Customer"] =
        formData.QBImportCustomers && formData.QBSyncCustomers
          ? "2"
          : formData.QBImportCustomers
          ? "1"
          : formData.QBSyncCustomers
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["Item"] =
        formData.QBImportItems && formData.QBSyncItems
          ? "2"
          : formData.QBImportItems
          ? "1"
          : formData.QBSyncItems
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["Invoice"] =
        formData.QBImportInvoices && formData.QBSyncInvoices
          ? "2"
          : formData.QBImportInvoices
          ? "1"
          : formData.QBSyncInvoices
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["Payment"] =
        formData.QBImportPayments && formData.QBSyncPayments
          ? "2"
          : formData.QBImportPayments
          ? "1"
          : formData.QBSyncPayments
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["Deposit"] =
        formData.QBImportDeposits && formData.QBSyncDeposits
          ? "2"
          : formData.QBImportDeposits
          ? "1"
          : formData.QBSyncDeposits
          ? "0"
          : "-1";
    } else {
      this.defaultDBSettings.moduleAllowed["Customer"] =
        formData.QBImportCustomers && formData.QBSyncCustomers
          ? "2"
          : formData.QBImportCustomers
          ? "1"
          : formData.QBSyncCustomers
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["ItemGroup"] =
        formData.QBImportItemGroups && formData.QBSyncItemGroups
          ? "2"
          : formData.QBImportItemGroups
          ? "1"
          : formData.QBSyncItemGroups
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["Invoice"] =
        formData.QBImportInvoices && formData.QBSyncInvoices
          ? "2"
          : formData.QBImportInvoices
          ? "1"
          : formData.QBSyncInvoices
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["ReceivePayment"] =
        formData.QBImportReceivePayment && formData.QBSyncReceivePayment
          ? "2"
          : formData.QBImportReceivePayment
          ? "1"
          : formData.QBSyncReceivePayment
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["ItemService"] =
        formData.QBImportItemServices && formData.QBSyncItemServices
          ? "2"
          : formData.QBImportItemServices
          ? "1"
          : formData.QBSyncItemServices
          ? "0"
          : "-1";
      this.defaultDBSettings.moduleAllowed["Deposit"] =
        formData.QBImportDeposits && formData.QBSyncDeposits
          ? "2"
          : formData.QBImportDeposits
          ? "1"
          : formData.QBSyncDeposits
          ? "0"
          : "-1";
    }

    const parent = this;
    if (parent.mySettingsForm.controls["IsQBWeb"].value) {
      parent.mangoAPISrvc.addOrUpdateWebQB(parent.defaultDBSettings).subscribe({
        next: () => {
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        },
        error: (error) => {
          console.error(error);
          parent.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            "The QuickBooks integration was not enabled. Please try again later."
          );
        },
        complete: () => {
          this.moduleDateExclusionComponent.loadAllowedModules();
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "success",
            "Success!",
            "The QuickBooks integration was enabled succesfully!"
          );
        },
      });
    } else {
      parent.mangoAPISrvc.addOrUpdateQB(parent.defaultDBSettings).subscribe({
        next: (dbData) => {},
        error: (error) => {
          console.error(error);
          parent.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            "The QuickBooks integration was not enabled. Please try again later."
          );
        },
        complete: () => {
          this.moduleDateExclusionComponent.loadAllowedModules();
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "success",
            "Success!",
            "The QuickBooks integration was enabled succesfully!"
          );
        },
      });
    }
  }

  changeDefaultSettings() {
    const formData = this.mySettingsForm.value;
    if (formData.SyncFromQB) {
      this.mySettingsForm.controls["QBImportCustomer"].setValue(true);
      this.mySettingsForm.controls["QBImportServices"].setValue(true);
      this.mySettingsForm.controls["QBImportOpenInvoices"].setValue(true);
    }
  }

  downloadFile() {
    const formData = this.mySettingsForm.value;
    const filename =
      "Mango Billing -" + moment.utc(new Date()).format("YYYY-MM-DD/HH-mm-ss") + ".qwc";
    const data =
      `<?xml version="1.0"?>\n<QBWCXML>\n\t<AppName>Mango Billing</AppName>\n\t<AppID></AppID>\n\t` +
      `<AppURL>` +
      `${environment.QB_ENDPOINT_URL}` +
      `services/ws?wsdl</AppURL>\n\t<AppDescription>Mango Billing Time and Billing Software.</AppDescription>\n\t` +
      `<AppSupport>` +
      `${environment.SERVICE_ADDRESS}` +
      `</AppSupport>\n\t<UserName>` +
      this.loggedInemail +
      `</UserName>\n\t<OwnerID>{` +
      this.mangoUtils.generateUUID() +
      `}</OwnerID>\n\t<FileID>{` +
      this.mangoUtils.generateUUID() +
      `}</FileID>\n\t<QBType>QBFS</QBType>\n\t<Scheduler>\n\t\t<RunEveryNMinutes>30</RunEveryNMinutes>\n\t</Scheduler>\n\t<IsReadOnly>false</IsReadOnly>\n</QBWCXML>`;
  
    const blob = new Blob([data], { type: "text/plain" });
  
    // FOR IE:
    if (window.navigator && 'msSaveOrOpenBlob' in window.navigator) {
      const navigator = window.navigator as any;
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/plain", a.download, a.href].join(":");
      const e = document.createEvent("MouseEvents");
      e.initEvent("click", true, false);
      a.dispatchEvent(e);
    }
  }
  

  authenticateQBO() {
    const url = environment.QB_ENDPOINT_URL + "/connectToQuickbooks/" + this.loggedInemail;
    const newWindow = this.nativeWindow.open(decodeURI(url));
    newWindow.location = url;
  }

  generateMangoApiKey() {
    this.imagineshare.APIKeyMangoIS = this.mangoUtils.generateUUID();
    (this.imagineshare.CompanyID = this.companyId), (this.imagineshare.isCopied = false);
    this.isFormChanged = true;
  }

  copyClipBoard(inputElement) {
    const copyValue = this.imagineshare.APIKeyMangoIS;
    if (copyValue) {
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.mangoAPISrvc.notify("success", this.translate.instant("Success"), "Copied to Clipboard");
      this.imagineshare.isCopied = true;
    } else {
      document.execCommand("Unselect");
    }
  }

  processRecords() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    //vendorAPIKeyIS
    const reqbody = {
      apiKey: parent.companyData.vendorAPIKeyIS,
      CompanyIDImagineShare: parent.companyData.CompanyIDImagineShare,
      mangoCompanyId: parent.companyId,
    };
    if (this.imagineshare.currentProcess == "mango-to-imagineshare") {
      parent.mangoAPISrvc.processMangoClients(reqbody).subscribe(
        function (mangoresposne: any) {
          parent.mangoAPISrvc.notify("success", mangoresposne.message, "");
          parent.mangoAPISrvc.showLoader(false);
          Swal.fire({
            title: parent.translate.instant("confirmation"),
            html: `<div>Process Files & Folders</div>`,
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: parent.translate.instant("Yes"),
          }).then((result) => {
            if (result.value) {
              parent.mangoAPISrvc.showLoader(true);
              parent.processfiles();
            }
          });
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else if (this.imagineshare.currentProcess == "imagineshare-to-mango") {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.processIShareClients(reqbody).subscribe(
        function (mangoresposne: any) {
          parent.mangoAPISrvc.notify("success", mangoresposne.message, "");
          parent.mangoAPISrvc.showLoader(false);
          Swal.fire({
            title: parent.translate.instant("confirmation"),
            html: `<div>Process Files & Folders</div>`,
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: parent.translate.instant("Yes"),
          }).then((result) => {
            if (result.value) {
              parent.mangoAPISrvc.showLoader(true);
              parent.processfiles();
            }
          });
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.mangoAPISrvc.mapIShareMangoClients(reqbody).subscribe(
        function (mangoresposne: any) {
          //parent.mapIShareMango = mangoresposne.duplicateClients;
          parent.mapIShareMango = [
            ...mangoresposne.duplicateClients,
            ...mangoresposne.createClients.iShare,
          ];
          parent.isReviewCust = true;
          parent.isValidSelection =
            parent.mapIShareMango.filter((item) => !item.isValidSelection).length == 0;
          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }
  processfiles() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.companyData["mangoAPIKey"] = parent.companyData.APIKeyMangoIS;
    parent.companyData["ishareAPIKey"] = parent.companyData.vendorAPIKeyIS;
    parent.companyData["CompanyID"] = parent.companyId;
    const reqbody = {
      apiKey: parent.companyData.vendorAPIKeyIS,
      CompanyIDImagineShare: parent.companyData.CompanyIDImagineShare,
      mangoCompanyId: parent.companyId,
    };
    if (this.imagineshare.currentProcess == "mango-to-imagineshare") {
      parent.mangoAPISrvc.processMangoFolders(reqbody).subscribe(
        function (mangoresposne: any) {
          parent.companyData.isISIntegrationComplete = true;
          parent.mangoAPISrvc
            .validateapiKey(parent.companyData)
            .subscribe(function (mangoresposne: any) {});
          parent.mangoAPISrvc.notify("success", mangoresposne.message, "");
          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else if (this.imagineshare.currentProcess == "imagineshare-to-mango") {
      parent.mangoAPISrvc.processIShareFolders(reqbody).subscribe(
        function (mangoresposne: any) {
          parent.companyData.isISIntegrationComplete = true;
          parent.mangoAPISrvc
            .validateapiKey(parent.companyData)
            .subscribe(function (mangoresposne: any) {});
          parent.mangoAPISrvc.notify("success", mangoresposne.message, "");
          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      // Mango to Ishare
      parent.mangoAPISrvc.processMangoFolders(reqbody).subscribe(
        function (mangoresposne: any) {
          // Ishare to Mango
          parent.mangoAPISrvc.processIShareFolders(reqbody).subscribe(
            function (mangoresposne: any) {
              parent.companyData.isISIntegrationComplete = true;
              parent.mangoAPISrvc
                .validateapiKey(parent.companyData)
                .subscribe(function (mangoresposne: any) {});
              parent.mangoAPISrvc.notify("success", mangoresposne.message, "");
              parent.mangoAPISrvc.showLoader(false);
            },
            (error) => {
              parent.mangoAPISrvc.showLoader(false);
            }
          );
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  createOrUpdate() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    const apiKeys = {
      apiKey: parent.companyData.vendorAPIKeyIS,
      CompanyIDImagineShare: parent.companyData.CompanyIDImagineShare,
      mangoCompanyId: parent.companyId,
    };
    const objPayload = { update: [], mangoCreate: [], IShareCreate: [], apiKeys: apiKeys };
    this.mapIShareMango.forEach((element) => {
      element.CompanyID = parent.companyId;
      if (element.action == "Update") {
        if (element.children.length > 0) {
          const mangoclients = element.children
            .filter((item) => item.action != "Update")
            .map((item) => {
              return item;
            });
          objPayload.mangoCreate = objPayload.mangoCreate.concat(mangoclients);
        } else {
          objPayload.update.push(element);
        }
      } else if (element.action == "IShare_create") {
        objPayload.IShareCreate.push(element);
      } else if (element.action == "mango_create") {
        objPayload.mangoCreate.push(element);
      }
    });
    parent.mangoAPISrvc.syncIShareMangoClients(objPayload).subscribe(
      function (mangoresposne: any) {
        parent.mangoAPISrvc.notify("success", mangoresposne.message, "");
        parent.mangoAPISrvc.showLoader(false);
        parent.isReviewCust = false;
        Swal.fire({
          title: parent.translate.instant("confirmation"),
          html: `<div>Process Files & Folders</div>`,
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: parent.translate.instant("Yes"),
        }).then((result) => {
          if (result.value) {
            parent.mangoAPISrvc.showLoader(true);
            parent.processfiles();
          }
        });
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  closeDialog() {
    this.isReviewCust = false;
  }

  matchedObj(mangoObj, item) {
    mangoObj.selectedRowValue = item;
    mangoObj.isValidSelection = true;
    mangoObj.children.map((IShareObj) => {
      IShareObj.isNotMatched = true;
      IShareObj.action = null;
      if (mangoObj.selectedRowValue && mangoObj.selectedRowValue._id == IShareObj._id) {
        IShareObj.isNotMatched = false;
        IShareObj.action = "Update";
        mangoObj.IShareClientID = IShareObj._id;
        mangoObj.IShareCompanyID = IShareObj._firm;
      } else {
        IShareObj.isNotMatched = true;
        IShareObj.action = "mango_create";
      }
    });
    this.isValidSelection =
      this.mapIShareMango.filter((item) => !item.isValidSelection).length == 0;
  }

  notMatchedObj(parentrow, item) {
    parentrow.children.map((mangoObject) => {
      mangoObject.isNotMatched = true;
      mangoObject.isNotMatched = true;
      if (parentrow.selectedRowValue && parentrow.selectedRowValue._id == mangoObject._id) {
        mangoObject.isNotMatched = false;
      } else {
        mangoObject.isNotMatched = true;
      }
    });
  }

  clearall(parentrow) {
    parentrow.selectedRowValue = null;
    parentrow.matchedRowValue = null;
    parentrow.isValidSelection = false;
    this.isValidSelection =
      this.mapIShareMango.filter((item) => !item.isValidSelection).length == 0;
    parentrow.children.map((mangoObject) => {
      mangoObject.isNotMatched = false;
      mangoObject.action = "mango_create";
    });
  }

  async connectToIShare(model) {
    const parent = this;
    if (!model.APIKeyMangoIS || !model.vendorAPIKeyIS) {
      parent.isIshareConnected = false;
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);
    const obj = {
      mangoAPIKey: model.APIKeyMangoIS,
      ishareAPIKey: model.vendorAPIKeyIS,
      CompanyIDImagineShare: parent.companyData.CompanyIDImagineShare,
      isIntegrateIS: parent.imagineshare.isIntegrateIS,
      CompanyID: parent.companyId,
    };
    parent.mangoAPISrvc.validateIShareKey(obj).subscribe(
      function (ishare: any) {
        if (!ishare.data) {
          parent.mangoAPISrvc.showLoader(false);
          parent.isIshareConnected = false;
          parent.mangoAPISrvc.notify("error", "Error", "Invalid Company");
          return false;
        }
        obj.CompanyIDImagineShare = ishare.data.CompanyIDImagineShare;
        parent.mangoAPISrvc.validateapiKey(obj).subscribe(
          function (mangoresposne: any) {
            //parent.mangoAPISrvc.notify("success", 'Success', ishare.message)
            parent.isIshareConnected = true;
            parent.isFormChanged = false;
            parent.mangoAPISrvc.showLoader(false);
            parent.companyData.vendorAPIKeyIS = obj.ishareAPIKey;
            parent.companyData.CompanyIDImagineShare = obj.CompanyIDImagineShare;
            parent.companyData.APIKeyMangoIS = obj.mangoAPIKey;
          },
          (error) => {
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  onTabChange(evt) {
    if (evt.index == 1) {
      //this.applyISharesettings();
    }
  }

  async applyISharesettings() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getTimeAndExpenses().subscribe(
      async function (mangoresposne) {
        parent.companyData = mangoresposne;
        await parent.connectToIShare(parent.companyData);
        parent.imagineshare.mangoCompanyID = parent.companyData.IShareCompanyID;
        parent.imagineshare.CompanyIDImagineShare = parent.companyId;
        parent.imagineshare.APIKeyMangoIS = parent.companyData.APIKeyMangoIS;
        parent.imagineshare.vendorAPIKeyIS = parent.companyData.vendorAPIKeyIS;
        parent.imagineshare.isIntegrateIS = parent.companyData.isIntegrateIS
          ? parent.companyData.isIntegrateIS
          : false;
        parent.isIshareConnected = parent.companyData.vendorAPIKeyIS ? true : false;
        parent.companyData.isISIntegrationComplete = parent.companyData.isISIntegrationComplete
          ? parent.companyData.isISIntegrationComplete
          : false;
        parent.isFormChanged = false;
        parent.encrDecSrvc.addObject(AppConstants.timeAndExpenses, parent.companyData);
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  UpdateKeys(event) {
    const parent = this;
    let obj = {
      mangoAPIKey: null,
      ishareAPIKey: null,
      CompanyID: parent.companyId,
      isIntegrateIS: parent.imagineshare.isIntegrateIS,
    };
    if (event.checked) {
      parent.imagineshare.APIKeyMangoIS = parent.mangoUtils.generateUUID();
      parent.imagineshare.isCopied = false;
      obj = {
        mangoAPIKey: parent.imagineshare.APIKeyMangoIS,
        ishareAPIKey: null,
        CompanyID: parent.companyId,
        isIntegrateIS: parent.imagineshare.isIntegrateIS,
      };
    } else {
      parent.imagineshare.vendorAPIKeyIS = null;
      parent.isIshareConnected = false;
    }
    parent.mangoAPISrvc.validateapiKey(obj).subscribe(
      function (mangoresposne: any) {},
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  openQuickBooks(event) {
    window.open(this.quickBooksPrefix + "connectToQuickbooks", "_blank");
  }

  onSelectDefaultItemId(option) {
    this.defaultDBSettings.defaultItemId = option.value.Id;
  }

  loadAvailableQBOItems() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getDefaultQBOItems(this.loggedInemail).pipe(
      finalize(() => {
        this.getCompanyByEmail();
        this.mangoAPISrvc.showLoader(false);
      })
    ).subscribe({
      next: (response) => {
        this.defaultQBOItemsList = Array.isArray(response.body) ? response.body : [];
        this.defaultQBOItemsList.unshift({
          Id: "0",
          Name: "None",
        });
      },
      error: (error) => {
        console.error(error);
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          "There was an error loading the Items from QBO. Check if there is a problem with the authentication."
        );
      },
    });
  }

  openDownloadBatModal(event: MouseEvent) {
    event.stopPropagation();
    this.showInstructionsDialog = true;
  }

}
