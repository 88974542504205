import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { SharedComponentsService } from '@app/shared/components';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-engagements',
  templateUrl: './client-engagements.component.html'
})
export class ClientEngagementsComponent implements OnInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  @ViewChild('dt2') dataTableComponent2: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";

  projectInactive = false;
  editableClient: any = {};
  clientID: number = null;
  projectsList: any = [];
  tmpProjectsList = [];
  planInfo: any;
  createProjectFromCompanyTemplate = false;
  companyTemplateID: any;
  public isDisplayDetailsTable: boolean = false;
  public templateDataSource: any = [];
  public companyId ;
  public isRadioSelected: boolean = false;
  selectedTemplate;
  public globalFilterColumns: any = [];

  constructor(private router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, private _translate: TranslateService, private auth: AuthGuard)  {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.planInfo = this.encrDecSrvc.getObject(AppConstants.planInfo);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.createProjectFromCompanyTemplate = this.planInfo.SubscriptionLevel.toLowerCase() == "pro" || this.planInfo.SubscriptionLevel.toLowerCase() == "enterprise";
    const interval = setInterval(() => {
      if(!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.Engagements + '_' + AppConstants.SearchString);
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.editableClient = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
    this.getCompanyTemplateDetails();
    this.getProjectsById();
    this.initEditableClient();
  }

  createProjectScreen() {
    this.router.navigate(['project-management/projectDetails']);
  }

  radioCompanyClicked(event, item) {
    this.isRadioSelected = true;
    this.companyTemplateID = item.CompanyTemplateHeaderID;
    item.ClientID = this.clientID;
    item.ClientName = this.clientName;
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(item));
  }

  getCompanyTemplateDetails() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyId).subscribe((data) => {
      parent.templateDataSource = data;
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    })
  }

  createFromCompanyTemplate(){
    this.isDisplayDetailsTable = true;
  }
  
  onCloseTemplateDialog() {
    this.isDisplayDetailsTable = false;
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this._translate.instant('client') },
      { label: this._translate.instant('Engagements') },
      { label: this.clientName, icon: 'ic-red'}
    ]);
  }

  ngOnInit(): void {
    this.sharedSrvc.engagementDialogData
      .subscribe((data) => {
        this.getProjectsById();
      })
  }

  getProjectsById() {
    const _this = this;
    _this.projectsList = [];
    _this.tmpProjectsList = [];
    if (this.clientID != null) {
      _this.mangoAPISrvc.showLoader(true);
      _this.mangoAPISrvc.getProjectsAndEngagementByClientID(_this.clientID).subscribe(function (data: any) {
        _this.projectsList = [...data];
        _this.globalFilterColumns = ['EngagementName','Description','BillingMethod','FlatFeeAmount']
        _this.onStatusChange();
        setTimeout(() => {
          _this.loadFilterGrid();
        }, 100);
        _this.mangoAPISrvc.fireClientBadgeView(true);
        _this.mangoAPISrvc.showLoader(false);
      }, error => {
        _this.mangoAPISrvc.notify('error', _this._translate.instant('error'), AppConstants.fetchErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.Engagements + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.Engagements + '_' + AppConstants.SearchString,  obj.filters.global?.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent2.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  initEditableClient() {
    const _this = this;
    if (this.clientID != null) {
        this.editableClient['ContactRecord'] = this.editableClient['ContactRecord'] ? this.editableClient['ContactRecord'] : false;
        this.editableClient.Inactive = this.editableClient.Inactive ? this.editableClient.Inactive : false;
        this.editableClient.Billable = this.editableClient.Billable ? this.editableClient.Billable : false;
        if (_this.editableClient.Inactive == "")
          _this.editableClient.Inactive = false;

        if (_this.editableClient.Billable == "")
          _this.editableClient.Billable = false;

      this.encrDecSrvc.addObject('selected-ClientEmail', this.editableClient.Email);

    }
    else {
      //_this.isFormLoaded = true;
    }
  }

  addEngagementPopUp() {
    const parent = this;
    parent.editableClient['isClientFlow'] = true;
    if(!parent.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: parent._translate.instant('client.engagement.permission')
      });
      return
    }

    parent.sharedSrvc.openEngagementDialog(parent.editableClient);
  }

  editEngagement(rowData) {
    rowData['isClientFlow'] = true;
    this.encrDecSrvc.removeObject(AppConstants.projectID);
    this.encrDecSrvc.removeObject(AppConstants.clientID);
    this.encrDecSrvc.removeObject(AppConstants.ClientName);
    this.encrDecSrvc.removeObject(AppConstants.selectedClientRecord);
    this.encrDecSrvc.addObject(AppConstants.projectID, rowData['ProjectMasterID']);
    this.encrDecSrvc.addObject(AppConstants.clientID, rowData['ClientID']);
    this.encrDecSrvc.addObject(AppConstants.ClientName, rowData['ClientName']);
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, rowData);
    this.encrDecSrvc.addObject(AppConstants.selectedEngagementName, rowData['EngagementName']);
    this.router.navigate(['/engagements/view']);
  }

  deleteEngagement(id, rowData) {
    const _this = this;
    // before deleting we need to check any time records are presets
    _this.mangoAPISrvc.getDetailsCountByProjectId(id).subscribe(function (data:any) {
      _this.mangoAPISrvc.showLoader(false)
      if (data.timeslips > 0) {
        Swal.fire({
          icon: 'error',
          title: _this._translate.instant('error'),
          showCancelButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: _this._translate.instant('ok'),
          text: _this._translate.instant('delete_engagement_warning'),
        });
      } else {
        Swal.fire({
          title: _this._translate.instant('confirmation'),
          text: _this._translate.instant('delete_alert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: _this._translate.instant('yes_delete'),
          cancelButtonText: _this._translate.instant('no_delete')
        }).then((result) => {
          if (result.value) {
            _this.mangoAPISrvc.showLoader(true);
             _this.mangoAPISrvc.deleteProject(id).subscribe(function (data) {

               const userLogs = {}
               userLogs['Action'] = "Delete Engagement";
               userLogs['Description'] = rowData['EngagementName'] + " ##" + rowData['ProjectMasterID'] + ` --${rowData['ClientName']}`;
               userLogs['Table'] = "Engagement";
               const isManaging = _this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
               if(!isManaging){
                _this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
                  console.log(err);
                });
               }
            

               _this.mangoAPISrvc.notify('success',  _this._translate.instant('delete-message'), AppConstants.deleteMessage);
              _this.getProjectsById();
             }, error => {
                 _this.mangoAPISrvc.notify('error',  _this._translate.instant('error'), AppConstants.deleteErrorMsg);
               _this.mangoAPISrvc.showLoader(false);
             });
          }
        })
      }
    }, error => {
        _this.mangoAPISrvc.notify('error', _this._translate.instant('error'), AppConstants.deleteErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  onStatusChange() {
    if (this.tmpProjectsList.length < 1)
      this.tmpProjectsList = this.projectsList;
    if (this.projectInactive)
      this.projectsList = this.tmpProjectsList.filter((note) => note.Inactive == true);
    else
      this.projectsList = this.tmpProjectsList.filter((note) => note.Inactive == false);
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38)
  }
}
