<div class="p-ml-4 p-col-11 p-d-flex">
    <div class="layout-topbar-logo p-col-11 p-py-0">
        <!-- <img alt="logo" class="fileImg" src="assets/layout/images/logo-mirage@2x.png" />
        <span class="logotext"><strong>mango</strong>billing</span> -->
        <img [attr.src]="(companyObj && companyObj.CompanyLogoWeb)? companyObj.CompanyLogoWeb: 'assets/mango/img/user.png'"
            onError="this.src='assets/mango/img/user.png'" alt="{{companyObj.StaffName}}" class="companyImg" />
    </div>
    <div class="p-col-3 p-text-right p-d-flex p-pt-4" *ngIf="companyObj.StaffName !== 'Portal'">
        <span class="profile-image-wrapper">
            <img [attr.src]="(companyObj && companyObj.ProfilePic)? companyObj.ProfilePic: 'assets/mango/img/user.png'"
                onError="this.src='assets/mango/img/user.png'" alt="{{companyObj.StaffName}}"
                class="user-header-img headerimg" />
        </span>
        <span class="profile-info-wrapper">
            <h4 class="label-text p-ml-3 p-mt-2">{{companyObj.StaffName}}</h4>
        </span>
    </div>
</div>

<div class="p-grid vertical-container p-mx-4" *ngIf="!isValidQA">
    <div class="p-col p-as-center">
        <div class="content-wrapper">
            <div class="content"> 
                <div id="control_wrapper">
                    <p-blockUI [target]="blockedArea" [blocked]="blockedPanel">
                        <i class="pi pi-lock" style="font-size: 3rem"></i>
                    </p-blockUI>
                    <div class="card file-manager-container card-bg">
                        <p class="p-text-bold">Shared files for <strong
                                class="recent-activity-task-color">{{companyObj.ClientName}}</strong> from
                            <strong class="recent-activity-client-color">{{companyObj.CompanyName}}</strong>
                        </p>
                    </div>
                    <div class="file-manager-container" #blockedArea>
                        <ejs-filemanager id='fileExplorer' #fileExplorer [ajaxSettings]='ajaxSettings'
                            (beforeSend)="beforeSend($event)" (success)="onAjaxSuccess($event)"
                            [contextMenuSettings]='contextMenuSettings' [toolbarSettings]='toolbarSettings'
                            [navigationPaneSettings]='navigationPaneSettings' (toolbarCreate)='toolbarCreate($event)'
                            (toolbarClick)='toolbarClick($event)' (fileSelect)='onFileSelect($event)'>
                        </ejs-filemanager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog header="Security Access" [(visible)]="isValidQA" [modal]="true" [style]="{width: '38vw'}"
    [maximizable]="false" [closable]="false" showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card">
                <div class="p-col-12 p-pl-0 p-my-2">
                    <label for="question" class="p-col-12">{{questionStr}}</label>
                </div>
                <div class="p-col-12 ">
                    <input id="from" type="text" [(ngModel)]="securityAnswer" autocomplete="off" pInputText
                        placeholder="Enter your answer">
                </div>
                <div *ngIf="isErrors && maxAttempts < 5" class="ic-red p-ml-2 p-my-2">
                    Invalid answer.You are left with {{5-maxAttempts}} more attempts
                </div>
                <div *ngIf="isErrors && maxAttempts >= 5" class="ic-red p-ml-2 p-my-2">
                    {{ 'access-locked-msg' | translate }}
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!securityAnswer" (click)="validateQA()"
            label="Validate"></button>
    </ng-template>
</p-dialog>

<p-sidebar [(visible)]="isFilePreview" [position]="'right'" [style]="{width:'75%'}" [dismissible]="true"
    [baseZIndex]="1" [showCloseIcon]="true" (onHide)="closePreviewDialog()" [closeOnEscape]="false">
    <div class="card p-px-0">
        <div #preiewviewer class="preiewviewer" [ngStyle]="{'visibility':isPreviewViewerShow? 'visible' : 'hidden' }">
        </div>
    </div>
</p-sidebar>