import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '@app/_services';


import { environment } from '@environments/environment';

export function createTranslateLoader(http: HttpClient) {
  const baseUrl: string = environment.API_URL;
  const str = new Date().getTime();
  return new TranslateHttpLoader(http, `${baseUrl}/language/keys/`, `?cache=${str}`);
  // return new TranslateHttpLoader(http, '/assets/i18n/US/', '.json');
}

export const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
};

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forChild(translationOptions)
  ],
  exports: [TranslateModule],
  providers: [TranslateService, HttpClient]
})
export class AppTranslationModule {
  private static numofInstances: number = 0;
  constructor(private translate: TranslateService, private authGuard: AuthGuard) {
    this.translate.addLangs(['en', 'es']);
    // this.translate.setDefaultLang('en');
    // this.translate.use('en');
    this.authGuard.changeLang.subscribe((lang) => {
        switch (lang) {
          case "Spanish":
            this.translate.use('es');
            break;
          case "English":
          default:
            this.translate.use('en');
            break;
        }
      });
  }

  initialise() {
    /*this.translate.addLangs(["en", "es"]);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.auth.changeLang.subscribe((lang) => {
      // console.log("lang changed");
      // console.log(lang);
      switch(lang) {
        case "Spanish":
        this.translate.use('es');
        break;
        case "English":
        default:
        this.translate.use('en');
        break;
      }
    });*/
  }
}
