<div class="layout-content">
    <div class="p-formgrid p-grid p-mx-2">
      <div class="p-col-4">
        <div class="card">
          <!-- <div *ngIf="!tfa?.secret"> -->
          <div class="p-col-12 p-text-center p-mt-2">
            <h5 *ngIf="!tfa?.secret">
              Scan the QR code in Twilio Authy or Google Authenticator
            </h5>
            <h5 *ngIf="tfa?.secret">Two Factor Authentication is Enabled</h5>
          </div>
          <div *ngIf="!tfa?.secret" class="p-col-12 p-mb-3">
            <img
              [src]="tfa?.dataURL"
              alt=""
              class="img-thumbnail"
              style="display: block; margin: auto"
            />
          </div>
          <div class="p-formgroup-inline" *ngIf="!tfa?.secret">
            <div class="p-col-2 p-mr-2">&nbsp;</div>
            <div class="p-col">
              <div class="p-formgroup-inline p-mb-3">
                <div class="p-field">
                  <label class="label-text p-mr-6">Secret Key:</label>
                </div>
                <div class="p-field">
                  <label class="label-text ic-red">{{ tfa?.tempSecret }}</label>
                </div>
              </div>
              <div class="p-formgroup-inline p-mb-3">
                <div class="p-field">
                  <label class="label-text p-mr-4 p-mt-2">Security Code</label>
                </div>
                <div class="p-field">
                  <input
                    type="text"
                    pInputText
                    [(ngModel)]="authcode"
                    [ngModelOptions]="{ standalone: true }"
                    class="p-text-right"
                    name="authcode"
                    autocomplete="off"
                   
                    placeholder="Enter the Security Code"
                    maxlength="6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-mb-3 p-text-center">
            <button
              *ngIf="!tfa?.secret"
              type="button"
              pButton
              pRipple
              label="Enable TFA"
              icon="fal fa-check"
              class="p-button-sm"
              (click)="onEnableTFA()"
              [disabled]="authcode?.length != 6"
            ></button>
            <button
              *ngIf="tfa?.secret"
              type="button"
              pButton
              pRipple
              label="Disable TFA"
              icon="fal fa-times"
              class="p-button-sm p-button-danger"
              (click)="onDisableTFA()"
            ></button>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="p-col-8" *ngIf="!tfa?.secret">
        <div class="card p-pt-5" style="height: 100%">
          <h5>Setting Up Authenticator</h5>
          <br />
          <p>
            Download and install any authenticator application (<strong>Twilio Authy</strong>, <strong>Microsoft Authenticator</strong>, or <strong>Google Authenticator)</strong> on your mobile device.
            <br />You can find it in the App Store (for iOS) or Google Play (for Android).
          </p>
          <br />
          <p>
            <strong>1. </strong>Open the mobile application then add an account
            (<strong>Twilio Authy</strong>) or click the【+】symbol (<strong
              >Google Authenticator</strong
            >).
          </p>
          <p>
            <strong>2. </strong>Scan the QR Code on the left side of the screen
            using your authenticator application.
          </p>
          <p>
            <strong>3. </strong>Enter the 6 digit numbers from your authenticator
            application into the Security code text box (Mangobilling).
          </p>
          <p><strong>4. </strong>Click the 'Enable TFA'.</p>
          <p><strong>Note: </strong>Save the Secret Key somewhere safe which can be used if you have lost your access to your authenticator.</p>
          <br />
          <p>Everytime you login, Mangobilling application will ask for the code that will be generated on your authenticator.</p>
          <p>If you are using Authy authenticator, they have a widget for both iOS and Android you can use to get the code without opening their application.</p>
        </div>
      </div>
    </div>
  </div>
  