import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ManualInvoiceComponent } from './manual-invoice/manual-invoice.component';
import Swal from 'sweetalert2';
import { EncrDecrService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { SharedComponentsService } from '@app/shared/components';

@Injectable()
export class CanDeactivateForm  {
  constructor(private translate: TranslateService, private _service: EncrDecrService, private sharedService: SharedComponentsService) { }

  canDeactivate(
    component: ManualInvoiceComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    const isFormChanged = this._service.getObject(AppConstants.isFormChanged);
    if (!isFormChanged) {
      return true;
    }
    return Observable.create((observer: Observer<boolean>) => {
      Swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant('unsaved_changes_warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_leave'),
        cancelButtonText: this.translate.instant('no_stay')
      }).then((result) => {
        if (result.value) {
          this._service.addObject(AppConstants.isFormChanged, false); 
          observer.next(true);
          observer.complete();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          observer.next(false);
          observer.complete();
        }
      })
    });
  }
}
