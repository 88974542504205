import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  MangoApiService,
} from "@app/_services";

@Injectable({
  providedIn: "root",
})
export class RProjectDetailsService {
  private reloadKey = "reloadFlag";

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    ) {}

    reloadPageOnce() {
      const hasReloaded = sessionStorage.getItem(this.reloadKey);

      if (!hasReloaded) {
        sessionStorage.setItem(this.reloadKey, "true");
        //this.mangoAPISrvc.showLoader(true);
        setTimeout(() => {
          // location.reload();
          this.mangoAPISrvc.showLoader(false);
        }, 3500);
      }
    }

  resetReloadFlag() {
    sessionStorage.removeItem(this.reloadKey);
  }
}
