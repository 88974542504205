import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare let numeral: any;

@Component({
  selector: 'app-client-portal',
  templateUrl: './client-portal.component.html'
})
export class ClientPortalComponent implements OnInit {

  clientPortalForm: UntypedFormGroup;
  timeexp: any = {};
  loginCompanyId;
  displayPaymentOptions: boolean = false;
  public subscriptionLevel;


  constructor(
    private fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService,
    private router: Router,
    private breadcrumbService: BreadcrumbService) {

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.initializeForm();
  }

  ngOnInit(): void {
    this.updateClientPortalForm();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('settings.Client-Portal'), icon: 'ic-red' }
    ]);
  }

  initializeForm() {
    this.clientPortalForm = this.fb.group({
      ActivateCustomerPortal: [false],
      AllowCustomerPortalEdit: [false],
      isShowInvHistory: [false],
      isShowPmtHistory: [false],
      isDMSVisiblePortal: [],
      modeOfPayment: [-1],
      isAllowPartialPayments: [false]
    })

    this.clientPortalForm.get('modeOfPayment').valueChanges.subscribe(val => {
      this.clientPortalForm.controls['modeOfPayment'].setValue(val, { emitEvent: false });
    });

    this.clientPortalForm.valueChanges.subscribe(val => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.clientPortalForm.pristine);
    });

  }


  updateClientPortalForm() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getTimeAndExpenses().subscribe(function (data) {
      parent.encrDecSrvc.addObject(AppConstants.timeAndExpenses, data);
      parent.timeexp = parent.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
      parent.displayPaymentOptions = parent.timeexp.isStMIdPresents;
      if (data && Object.keys(data).length > 0) {
        // parent.clientPortalForm.controls['ActivateCustomerPortal'].setValue(data['ActivateCustomerPortal']);
        parent.clientPortalForm.controls['AllowCustomerPortalEdit'].setValue(data['AllowCustomerPortalEdit']);
        parent.clientPortalForm.controls['isShowInvHistory'].setValue(data['isShowInvHistory']);
        parent.clientPortalForm.controls['isShowPmtHistory'].setValue(data['isShowPmtHistory']);
        parent.clientPortalForm.controls['isDMSVisiblePortal'].setValue(data['isDMSVisiblePortal']);
        parent.clientPortalForm.controls['isAllowPartialPayments'].setValue(data['isAllowPartialPayments']);

        data['modeOfPayment'] = data['modeOfPayment'] != null ? numeral(data['modeOfPayment']).value() : -1;
        parent.clientPortalForm.controls['modeOfPayment'].setValue(data['modeOfPayment']);
      }
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  saveClientPortalForm() {
    const parent = this;
    const formObj = this.clientPortalForm.value;
    formObj.modeOfPayment = formObj.modeOfPayment == -1 ? null : formObj.modeOfPayment;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateCustomerPortalSettings(parent.loginCompanyId, formObj).subscribe(function (data) {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
      parent.clientPortalForm.markAsPristine();
      parent.encrDecSrvc.addObject(AppConstants.isFormChanged, !parent.clientPortalForm.pristine);
      parent.updateStorage();

    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
    });

  }

  updateStorage() {
    const formObj = this.clientPortalForm.value;
    this.timeexp['ActivateCustomerPortal'] = formObj.ActivateCustomerPortal;
    this.timeexp['AllowCustomerPortalEdit'] = formObj.AllowCustomerPortalEdit;
    this.timeexp['isShowInvHistory'] = formObj.isShowInvHistory;
    this.timeexp['isShowPmtHistory'] = formObj.isShowPmtHistory;
    this.timeexp['isAllowPartialPayments'] = formObj.isAllowPartialPayments;
    this.timeexp['modeOfPayment'] = formObj.modeOfPayment;
    this.encrDecSrvc.addObject(AppConstants.timeAndExpenses, this.timeexp);
  }

  redirectStaxToLogin() {
    if (this.subscriptionLevel == 'FREE') {
      Swal.fire({
        icon: 'warning',
        title: 'Information!',
        text: "Your are on a trial account. You must be on a paid plan to enroll and electronic payments!",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      this.router.navigate([`/accounting/cards-view`]);
    }
  }



}



