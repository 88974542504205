import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule, AppTranslationModule } from '@app/shared/modules';
import { PmWildcardsDialogComponent } from './pm-wildcards-dialog.component';



@NgModule({
  declarations: [PmWildcardsDialogComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule
  ],
  exports: [
    PmWildcardsDialogComponent
  ]
})
export class PmWildcardsDialogModule { }
