import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from 'moment';
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { forkJoin, timer } from 'rxjs';
import Swal from "sweetalert2";
declare let numeral: any;

@Component({
  selector: "app-batch-time-entry",
  templateUrl: "./batch-time-entry.component.html",
})
export class BatchTimeEntryComponent implements OnInit {
  timeSheetList: any = [];
  selectedTsList = [];
  tsListcols = [];
  showStartStopTime = true;
  busy: Promise<any>;
  gridcolSpan = 8;
  isFormValid: boolean = false;
  public clientList: any = [];
  public filteredClients: any[];
  public activityList: any = [];
  public fullactivityList: any = [];
  public clients: any[] = [];
  public previousStaffId: any;
  public selClient: any = null;
  public staffDetails: any = {};
  public isFormChanged: boolean = false;
  public companyGlobalSetting: any = {};
  public selectedRowData: any = null;
  public engagementsList: any = [];
  public fullEngagementsList: any = [];
  public isValidForm: boolean = false;
  public showAddMemo: boolean = false;
  public isHideBillRateColumn = true;
  public actualTimeTooltip: any;
  public timeCardCols: any[];
  public staffID: any = null;
  public staffList: SelectItem[];
  public unsavedChanges = false;
  @ViewChild("dt") dt: Table;
  public defaultTimeRecord = {
    Ddate: null,
    TimeStart: null,
    TimeStop: null,
    Approved: true,
    Billable: true,
    BillingRate: 0,
    ClientID: null,
    ClientName: null,
    ElaspedTime: 0,
    Description: null,
    ServiceCodeID: null,
    StandardAmount: "0",
    TotalTime: 0,
    StaffID: null,
    StaffName: null,
    StaffDeptID: null,
    StaffPayRate: 0,
    StaffCost: 0,
    OriginatingPartnerID: null,
    BillingPartnerID: null,
    GroupDescriptionID: null,
    GroupDescriptionIDArray: null,
    ClientTypeID: null,
    ProjectMasterID: null,
    IsTimeRecord: "T",
    EngagementTypeID: null,
    Reimbursed: false,
    Reimburseable: false,
  };
  public processedTimeRecordsList: any = [];
  public workLocationsList: any = [];
  public isAPiLoaded: boolean = false;
  public totalElapseHrs = 0;
  public totalBillableHrs = 0;
  public totalBillingRates = 0;
  public totalBillableAmt = 0;
  public totalNonBillableAmt = 0;
  public UserLevel: any = null;
  StaffDeptID = 0;
  StaffPayRate = 0;
  intervalid: any;
  companySetting: any;
  companyId: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public sharedSrvc: SharedComponentsService,
    public mangoUtils: mangoUtils,
    public translate: TranslateService
  ) {
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Time_&_Expense') },
        { label: this.translate.instant('batch-time-entry'), icon: "ic-red" },
      ]);
    })
    this.mangoUtils.shouldFetchCode.next(true);
  }

  ngOnInit(): void {
    this.UserLevel = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.Title;
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);

    this.getAllDataList();
    this.getWorkLocations();
    const parent = this;
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    // TODO: Verify the refactor
    // parent.busy = parent.timeSheetEntrySvc.getStartStopTime(companyId).then(function (data) {
    // 	parent.showStartStopTime = data[0]['StartStopTime'];
    // 	parent.gridcolSpan = 8;
    // 	parent.gridcolSpan = parent.showStartStopTime ? (parent.gridcolSpan + 3) : parent.gridcolSpan;
    // 	setTimeout(() => {
    // 		parent.isAPiLoaded = true;
    // 		parent.addRow(0, {}, false, false);
    //   }, 500);
    // 	parent.cache.timeAndExpenseSettings.subscribe((companyData) => {
    // 		if (!companyData.HideBillRate || (companyData.isRateShowAdmin && parent.UserLevel == 1)) {
    // 			parent.isHideBillRateColumn = false;
    // 			parent.gridcolSpan = companyData.HideBillRate ? parent.gridcolSpan : (parent.gridcolSpan + 3);
    // 		} else {
    // 			parent.isHideBillRateColumn = true;
    // 		}
    //   });
    // })
    parent.mangoAPISrvc.getCompanyMangoDetails(`${this.companyId}`).subscribe((data) => {
      parent.showStartStopTime = data[0]['StartStopTime'];
      parent.gridcolSpan = 8;
      parent.gridcolSpan = parent.showStartStopTime ? (parent.gridcolSpan + 3) : parent.gridcolSpan;
      setTimeout(() => {
        parent.isAPiLoaded = true;
        parent.deleteRow(null, 0, true);
        parent.addRow(0, {}, false, false);
      }, 500);
      const companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
      if (!companyData.HideBillRate || (companyData.isRateShowAdmin && parent.UserLevel == "Administrator")) {
        parent.isHideBillRateColumn = false;
        parent.gridcolSpan = companyData.HideBillRate ? parent.gridcolSpan : (parent.gridcolSpan + 3);
      } else {
        parent.isHideBillRateColumn = true;
      }
    });

    this.intervalid = setInterval(() => {
      this.fetchClientList();
    }, 50);
  }

  getAllDataList() {
    this.getAllStaffList();
    this.getActivityGroups();
    this.getCompanyGlobalSetting();
  }

  getAllStaffList() {
    const self = this;
    const list = self.encrDecSrvc.getObject(AppConstants.staffList);
    self.staffList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].Inactive)
        continue;

      self.staffList.push({ label: list[i].StaffName, value: list[i].StaffID });

    }
    self.selectStaff({
      value: self.encrDecSrvc.getObject(AppConstants.resourceID),
    });
  }

  selectStaff(event) {
    const self = this;
    if (self.timeSheetList.length > 1) {
      Swal.fire({
        icon: "warning",
        title: self.translate.instant('Warning'),
        html: self.translate.instant('change_staff_warning'),
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: self.translate.instant('yes_continue'),
        cancelButtonText: self.translate.instant('no_cancel'),
      }).then((result) => {
        if (result.value) {
          self.staffID = parseInt(event.value);
          self.previousStaffId = self.staffID;
          self.staffDetails = {};
          self.mangoAPISrvc.getUserById(self.staffID).subscribe((data: any) => {
            self.staffDetails = data;
            self.StaffDeptID = self.staffDetails.StaffDeptID;
            self.StaffPayRate = Number(self.staffDetails.AverageCostPerHour);
            self.timeSheetList.map(function (obj) {
              obj["StaffDeptID"] = self.StaffDeptID;
              obj["StaffPayRate"] = self.StaffPayRate;
              obj["StaffID"] = self.staffID;
              obj["StaffCost"] =
                Number(obj.TotalTime) * Number(obj.StaffPayRate);
              return obj;
            });
          });
        } else {
          self.staffID = self.previousStaffId;
        }
      });
    } else {
      self.deleteRow(null, 0, true);
      self.addRow(0, {}, false, false);
      self.staffID = parseInt(event.value);
      self.previousStaffId = self.staffID;
      self.staffDetails = {};
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc.getUserById(self.staffID).subscribe((data: any) => {
        self.staffDetails = data;
        self.StaffDeptID = self.staffDetails.StaffDeptID;
        self.StaffPayRate = Number(self.staffDetails.AverageCostPerHour);
        self.mangoAPISrvc.showLoader(false);
      });
    }
  }

  getActivityGroups() {
    const self = this;
    let data = this.encrDecSrvc.getObject(AppConstants.activityList);
    data = data.sort(self.mangoUtils.compareValues('ServiceCode', 'asc'));
    self.fullactivityList = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].Inactive == false) {
        const obj = Object.assign({}, data[i]);
        obj['label'] = data[i].ServiceCode + ' - ' + data[i].Description;
        obj['value'] = data[i].ServiceCode;
        self.fullactivityList.push(obj);
      }
    }
  }

  getCompanyGlobalSetting() {
    const self = this;
    self.encrDecSrvc.removeObject(AppConstants.timeAndExpenseSettings);

    const data = self.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    self.companyGlobalSetting = data;
    self.companyGlobalSetting['TimeMemoRequired'] = data.TimeMemoRequired
      ? data.TimeMemoRequired
      : false;
  }

  getWorkLocations() {
    const _this = this;
    if (_this.workLocationsList.length > 0) {
      return false;
    }
    _this.workLocationsList = [{ label: "Select Location", value: null }];
    _this.mangoAPISrvc.getWorkLocations().subscribe(function (data: any) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element["label"] = element.ZipCode;
        element["value"] = element.WorkLocationID;
        _this.workLocationsList.push(element);
      }
    });
  }

  saveBatchAll() {
    const observableBatch = [];
    const parent = this;
    parent.timeSheetList.forEach((selectedItem, key) => {
      selectedItem['GroupDescriptionIDArray'] = selectedItem['GroupDescriptionIDArray']?.length > 0 ? selectedItem['GroupDescriptionIDArray'] : null;

      selectedItem[ "Ddate" ] = (
        moment( new Date( selectedItem[ "Ddate" ] ) )
        .format( "YYYY-MM-DD" )
      );

      selectedItem["CompanyID"] = this.companyId;
      selectedItem["TimeStop"] = parent.removeOffset(selectedItem["TimeStop"]);
      selectedItem["TimeStart"] = parent.removeOffset(
        selectedItem["TimeStart"]
      );
      selectedItem["StandardAmount"] = numeral(selectedItem["StandardAmount"])
        .value()
        .toString();

      if (selectedItem['MarkSlipsBilled']) {
        selectedItem['InvoiceDate'] = selectedItem["Ddate"]
      }

      observableBatch.push(
        // TODO: Verify the refactor
        // parent.http
        //   .post(
        //     parent.baseSvc.baseUrl + "/company/timeSheet",
        //     selectedItem,
        //     parent.baseSvc.authToken()
        //   )
        //   .map((res: Response) => res.json())
        this.mangoAPISrvc.createTimeSheet(selectedItem)
      );
    });

    // TODO: Verify the refactor
    // return Observable.forkJoin(observableBatch);
    return forkJoin(observableBatch);
  }

  saveAll() {
    const parent = this;
    this.saveBatchAll().subscribe((data) => {
      parent.timeSheetList = [];
      parent.timeSheetList.length = 0;
      parent.isValidTimeSheets();

      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      // parent.isFormChanged = false;
      this.addRow(0, {}, false, false);
      parent.footerTotals();
      this.unsavedChanges = false;
      Swal.fire({
        icon: "success",
        title: parent.translate.instant('Success'),
        text: parent.translate.instant('successfully_saved'),
        showConfirmButton: false,
        timer: 3000,
      });
    }, err => {
      this.mangoAPISrvc.notify('error', 'Error!', err);
    });
  }

  clearALL() {
    const self = this;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    Swal.fire({
      title: self.translate.instant('confirmation'),
      text: 'Clear All will clear the screen WITHOUT saving records.  Continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, clear records!',
      cancelButtonText: `No, don't clear.`
    }).then((result) => {
      if (result.value) {
        self.timeSheetList.length = 0;
        this.unsavedChanges = false;
        self.isValidTimeSheets();
        self.totalElapseHrs = 0;
        self.totalBillableHrs = 0;
        self.totalBillingRates = 0;
        self.totalBillableAmt = 0;
        self.totalNonBillableAmt = 0;
        self.isValidForm = true;
        self.addRow(0, {}, false, true)
      }
    })
  }

  updateStartStopTime() {
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.gridcolSpan = this.showStartStopTime ? this.gridcolSpan - 3 : this.gridcolSpan;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateStartStopTime({ 'StartStopTime': this.showStartStopTime }).subscribe((data) => {
      this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      this.mangoAPISrvc.showLoader(false);
    })
  }

  checkChangeUser(){
    if(this.unsavedChanges){
        Swal.fire({
          title: this.translate.instant('warning'),
          text: this.translate.instant('Changing of user is not allowed. There are unsaved records.'),
          icon: 'warning',
          confirmButtonText: this.translate.instant('Ok'),
        }).then((result) => {
        })
    }
  }

  addRow(index, rowData, isPrevRecord, isFormchangedflag) {
    const self = this;
    if (isFormchangedflag) {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    }

    if (this.isValidForm && this.timeSheetList.length > 0) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), "Please fill-up all required fields.");
      return false;
    }

    const newRow = {
      isNew: true,
      Memo: null,
      Ddate: null,
      TimeStart: null,
      TimeStop: null,
      Approved: true,
      Billable: true,
      BillingRate: "0",
      ClientID: null,
      ClientName: null,
      ElaspedTime: 0,
      Description: null,
      ServiceCodeID: null,
      StandardAmount: 0,
      TotalTime: 0,
      StaffID: null,
      StaffName: null,
      StaffDeptID: null,
      StaffPayRate: 0,
      StaffCost: 0,
      WorkCodeID: null,
      OriginatingPartnerID: null,
      BillingPartnerID: null,
      GroupDescriptionID: null,
      GroupDescriptionIDArray: null,
      ClientTypeID: null,
      ProjectMasterID: null,
      IsTimeRecord: "T",
      EngagementTypeID: null,
      Reimbursed: false,
      Reimburseable: false,
      engagementsList: [],
      fullEngagementsList: [],
      WorkLocation: null,
      Billed: false,
    };
    newRow["StaffDeptID"] = self.StaffDeptID;
    newRow["StaffPayRate"] = self.StaffPayRate;
    newRow["StaffID"] = self.staffID;
    newRow["uid"] = self.mangoUtils.generateUUID();
    newRow["StaffCost"] =
      Number(newRow.TotalTime) * Number(newRow.StaffPayRate);

    if (index == 0 && !isPrevRecord) {
      const now = new Date();
      newRow.Ddate = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000
      ).toISOString();
      newRow["Ddate"] = moment(rowData.Ddate).toDate();
      newRow.TimeStart = moment()
        .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      newRow.TimeStop = moment()
        .set({ hour: 9, minute: 30, second: 0, millisecond: 0 })
        .toDate();
    } else {
      newRow["Ddate"] = moment(rowData.Ddate).toDate();
      newRow.TimeStart = moment(rowData.TimeStop).toDate();
      newRow.TimeStop = moment(rowData.TimeStop).add(1, "hours").toDate();
      newRow["ClientName"] = rowData.ClientName;
      newRow["ClientID"] = rowData.ClientID;
      newRow["OriginatingPartnerID"] = rowData.OriginatingPartnerID;
      newRow["BillingPartnerID"] = rowData.BillingPartnerID;
      newRow["GroupDescriptionID"] = rowData.GroupDescriptionID;
      newRow["GroupDescriptionIDArray"] = rowData.GroupDescriptionIDArray;
      newRow["ClientTypeID"] = rowData.ClientTypeID;
      newRow["MarkSlipsBilled"] = rowData.MarkSlipsBilled;
      newRow["EngagementName"] = rowData["EngagementName"];
      newRow["ProjectMasterID"] = rowData["ProjectMasterID"];
      newRow["EngagementTypeID"] = rowData["EngagementTypeID"];
      newRow["engagementsList"] = [];
      newRow["fullEngagementsList"] = rowData.fullEngagementsList;
    }
    if (this.showStartStopTime) {
      self.calculateTimeDifference(newRow, true);
    }
    self.timeSheetList.splice(index + 1, 0, newRow);
    self.isValidTimeSheets();
    self.footerTotals();
  }

  calculateTimeDifference(itemData, isloadingTime) {

    const timeDiff = itemData.TimeStop.getTime() - itemData.TimeStart.getTime();
    if (isNaN(timeDiff))
      return;

    if (timeDiff < 0) {
      this.isValidForm = true;
      this.mangoAPISrvc.notify('error', 'Error!', "End time should be greater than start time");
    }
    else {
      const startTime = moment(itemData.TimeStart);
      const endTime = moment(itemData.TimeStop);
      let hours = endTime.diff(startTime, 'hours');
      const minutes = endTime.diff(startTime, 'minutes', true);
      let realmin = minutes - (60 * hours);
      itemData.ElaspedTime = this.formateNumber(Math.round((minutes / 60) * 100) / 100);
      this.actualTimeTooltip = hours + " hours, " + Math.round(realmin) + " minutes";
      /* get minutes from global setting */
      let globalMinute = 0;
      if (this.companyGlobalSetting.BillingIncrements) {
        globalMinute = parseInt(this.companyGlobalSetting.BillingIncrements.split(" ")[0]);
      }
      if (realmin <= globalMinute)
        if (realmin == 0)
          realmin = <any>"00";
        else
          realmin = globalMinute
      else {
        let diffGlobalMin = 0;
        // infinity issue
        if (globalMinute != 0) {
          diffGlobalMin = Math.floor(realmin / globalMinute);
        }
        if (realmin % globalMinute === 0)
          realmin = diffGlobalMin * globalMinute;
        else
          realmin = (diffGlobalMin + 1) * globalMinute;
      }

      if (realmin >= 60) {
        hours = hours + Math.floor(realmin / 60);
        realmin = realmin % 60;
        if (realmin < 9) realmin = <any>"0" + realmin;
      }
      itemData.TotalTime = this.convertHoursToNumericHours(hours + ":" + realmin);
      itemData['StaffCost'] = Number(itemData.TotalTime) * Number(itemData.StaffPayRate);
      ///this.billableTimeTooltip = hours + " hours, " + Math.round(realmin) + " minutes";
      this.isValidTimeSheets();
      this.footerTotals();
      if (!isloadingTime) {
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
      }
    }
  }

  formateNumber(name) {
    name = name.toString();
    if (name != undefined && name != '') {
      if (name.includes(".")) {
        const index = name.indexOf('.');
        if (name.substr(index + 1).length == 0) {
          name = name + '00';
        } else if (name.substr(index + 1).length == 1) {
          name = name + '0';
        }
      } else {
        name = name + '.00';
      }
      return name;
    }
  }

  convertHoursToNumericHours(time) {
    const arrTime = time.split(":");
    let minute: any = Math.round(arrTime[1] * 100 / 60);
    if (minute < 9) minute = "0" + minute;
    return arrTime[0] + "." + minute;
  }

  isValidTimeSheets() {
    const parent = this;
    parent.isValidForm = false;

    const srchClients = parent.timeSheetList.filter((item) => {
      if (parent.showStartStopTime) {
        if (item.isNew) {
          if (parent.companyGlobalSetting.TimeMemoRequired) {
            return (parent.staffID == null || item.TimeStart == null || item.TimeStop == null || item.ClientID == null || item.ServiceCode == null || item.ProjectMasterID == null || item.TotalTime == 0 || item.Memo == null || item.Memo == "");
          } else {
            return (parent.staffID == null || item.TimeStart == null || item.TimeStop == null || item.ClientID == null || item.ServiceCode == null || item.ProjectMasterID == null || item.TotalTime == 0);
          }
        } else {
          return false;
        }
      } else {
        if (item.isNew) {
          if (parent.companyGlobalSetting.TimeMemoRequired) {
            return (parent.staffID == null || item.TimeStart == null || item.TimeStop == null || item.ClientID == null || item.ServiceCode == null || item.ProjectMasterID == null || item.TotalTime == 0 || item.Memo == null || item.Memo == "");
          } else {
            return (parent.staffID == null || item.ClientID == null || item.ServiceCode == null || item.ProjectMasterID == null || item.TotalTime == 0);
          }
        } else {
          return false;
        }
      }
    });

    if (srchClients && srchClients.length > 0) {
      parent.isValidForm = true;
    }
  }

  footerTotals() {
    setTimeout(() => {
      const billableDataSet = this.timeSheetList.filter((item) => item['Billable'] == true);
      const NonbillableDataSet = this.timeSheetList.filter((item) => item['Billable'] == false);
      this.totalElapseHrs = this.timeSheetList.reduce(function (a, b) { return a + +numeral(b.ElaspedTime).value(); }, 0);
      this.totalBillableHrs = this.timeSheetList.reduce(function (a, b) { return a + +numeral(b.TotalTime).value(); }, 0);
      this.totalBillingRates = this.timeSheetList.reduce(function (a, b) { return a + +numeral(b.BillingRate).value(); }, 0);
      this.totalBillableAmt = billableDataSet.reduce(function (a, b) { return a + +numeral(b.StandardAmount).value(); }, 0);
      this.totalNonBillableAmt = NonbillableDataSet.reduce(function (a, b) { return a + +numeral(b.StandardAmount).value(); }, 0);
    }, 50);
  }

  changeMoney(evt: any, data: any) {
    if (evt == '.') {
      return false;
    }
    const myNumeral = numeral(data.TotalTime);
    if (myNumeral.value() === null || myNumeral.value() == 0) {
      data.TotalTime = 0.00;
    }
    data.TotalTime = data.TotalTime;
    data.ElaspedTime = data.TotalTime;
    this.calculateBillableAmount(data);
    this.isValidTimeSheets();

    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.footerTotals();
  }

  addMemo(data: any) {
    this.selectedRowData = data;
    this.showAddMemo = true;
    const savedWorkLocation = this.encrDecSrvc.getObject(AppConstants.WorkLocation) ? this.encrDecSrvc.getObject(AppConstants.WorkLocation) : null;
    this.selectedRowData['WorkLocation'] = data['WorkLocation'] ? data['WorkLocation'] : savedWorkLocation;
    this.isValidTimeSheets();
  }

  saveMemo(data) {
    this.isValidTimeSheets();
    this.showAddMemo = false;
    this.selectedRowData = null;
    this.encrDecSrvc.addObject(AppConstants.WorkLocation, data['WorkLocation']);
  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - (offset * 60 * 1000);
    return new Date(time);
  }

  addOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = new Date(date).getTime() + (offset * 60 * 1000);
    return new Date(time);
  }

  replaceShortcuts(value, desc, isMemo) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }

    if (isMemo) {
      desc['Memo'] = valueArr.join(" ");
    } else {
      desc['PrivateMemo'] = valueArr.join(" ");
    }
  }

  replaceCaret(desc, type) {
    desc[type] = this.mangoUtils.replaceCaretTemplate(desc[type]);
  }

  calculateBillableAmount(itemData) {
    if (itemData.BillingRate) {
      itemData.BillingRate = itemData.BillingRate ? numeral(itemData.BillingRate).value() : 0;
    }
    itemData.TotalTime = itemData.TotalTime ? itemData.TotalTime : 0;
    itemData.TotalTime = numeral(itemData.TotalTime).value();//format('0,0.00');
    //if (itemData.TotalTime && itemData.BillingRate) {
    if (itemData.BillingRate == 0) {
      itemData.StandardAmount = itemData.BillingRate;
    }
    else {
      itemData.StandardAmount = numeral(itemData.TotalTime).value() * numeral(itemData.BillingRate).value();
    }
    itemData.BillingRate = numeral(itemData.BillingRate).format('0,0.00');
    itemData.StandardAmount = numeral(itemData.StandardAmount).format('0,0.00');
    //}
    itemData['StaffCost'] = Number(itemData.TotalTime) * Number(itemData.StaffPayRate);
    this.footerTotals();
  }

  getProjects(clientId, itemData) {
    const _this = this;
    if (!clientId) {
      return false;
    }
    itemData.fullEngagementsList = [];
    itemData.engagementsList = [];
    _this.mangoAPISrvc.showLoader(true);

    // TODO: Verify `then` is replaced with `subscribe`
    _this.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(function (data: any) {
      const filterDataValue = data.filter((note) => note.Inactive == false);
      const filterData = filterDataValue.sort(_this.mangoUtils.compareValues('EngagementName', 'asc'));
      for (let i = 0; i < filterData.length; i++) {
        itemData.fullEngagementsList.push({ label: filterData[i].EngagementName, value: filterData[i].ProjectMasterID, EngagementTypeID: filterData[i].EngagementTypeID, isBillable: filterData[i].isBillable });
        itemData.engagementsList.push({ label: filterData[i].EngagementName, value: filterData[i].ProjectMasterID, EngagementTypeID: filterData[i].EngagementTypeID, isBillable: filterData[i].isBillable });
      }
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  filterActiveSingle(event) {
    const query = event.query;
    this.activityList = [];

    for (let i = 0; i < this.fullactivityList.length; i++) {
      const engg = this.fullactivityList[i];

      if (engg.label && engg.label.toLowerCase().indexOf(query.toLowerCase()) > -1 || query == "") {
        this.activityList.push(engg);
      }
    }
    return this.activityList;
  }

  filterEnggSingle(event, itemData) {
    const query = event.query;
    itemData.engagementsList = [];
    for (let i = 0; i < itemData.fullEngagementsList.length; i++) {
      const engg = itemData.fullEngagementsList[i];
      if (engg.label.toLowerCase().indexOf(query.toLowerCase()) > -1 || query == "") {
        itemData.engagementsList.push(engg);
      }
    }
    return itemData.engagementsList;
  }

  editCellComplete(data) {
    this.isValidTimeSheets();
  }

  handleEngagementSelectClick(event, itemData, isLoading) {
    itemData["EngagementName"] = event.label;
    itemData["ProjectMasterID"] = event.value;
    itemData["EngagementTypeID"] = event.EngagementTypeID;
    itemData["isBillable"] = event.isBillable ? event.isBillable : false;

    itemData.MarkSlipsBilled = itemData.MarkSlipsBilled ? itemData.MarkSlipsBilled : false;
    itemData.Billable = itemData.Billable ? itemData.Billable : false;

    if (itemData.MarkSlipsBilled == true || itemData.Billable == false || itemData.isBillable == false) {
      itemData["Billable"] = false;
    }

    this.isValidTimeSheets();
    if (!isLoading) {
      this.editCellComplete(itemData);
    }

    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  getBillingRateForUser(itemData) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getBillingRateFromRateTemplate(itemData.ClientID, itemData.ServiceCodeID, this.staffID).subscribe(function (data: any) {
      let enteredValue = numeral(data.BillingRate).value();
      enteredValue = enteredValue ? enteredValue : 0;
      itemData.BillingRate = numeral(enteredValue).format('0,0.00');
      self.calculateBillableAmount(itemData);
      self.mangoAPISrvc.showLoader(false);
    })
  }

  handleActivitySelectClick(event, itemData, isLoading) {
    if (!event.value) {
      itemData["ServiceCode"] = null;
      itemData["ServiceCodeID"] = null;
      itemData["Description"] = null;
      itemData["Billable"] = null;
      //itemData["WorkCodeID"] = null;
      itemData["Approved"] = null;
      itemData["BillingRate"] = 0;
      this.isValidTimeSheets();
      this.calculateBillableAmount(itemData);
      return false;
    }

    itemData["ServiceCode"] = event.ServiceCode;
    itemData["ServiceCodeID"] = event.ServiceCodeID;
    itemData["Description"] = event.Description;
    //itemData["WorkCodeID"] = event.WorkCodeID;
    itemData["Approved"] = this.companyGlobalSetting.AutoApproveSlips;
    itemData["Memo"] = event.DefaultMemo ? event.DefaultMemo : "";
    itemData["Memo"] = this.mangoUtils.replaceCaretTemplate(itemData["Memo"]);

    itemData.MarkSlipsBilled = itemData.MarkSlipsBilled ? itemData.MarkSlipsBilled : false;
    itemData.Billable = itemData.Billable ? itemData.Billable : false;

    if (itemData.MarkSlipsBilled == true || event.Billable == false || itemData.isBillable == false) {
      itemData.Billable = false;
    } else {
      itemData.Billable = event.Billable;
    }

    this.isValidTimeSheets();

    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);

    if (!isLoading) {
      this.editCellComplete(itemData);
    }
    this.getBillingRateForUser(itemData);
  }

  handleClientSelectClick(value, itemData) {

    if(value.BlockTimeExpenseEntry){
      this.selClient = null;
      itemData['ClientID'] = null;
      itemData['ClientName'] = null;

      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('Warning')}`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: `${this.translate.instant('block-expense-entry')}!`,
      });
      this.isValidTimeSheets();
      return;
    }
    else{
      this.selClient = value;
      itemData["ClientID"] = value.ClientID;
      itemData["ClientName"] = value.ClientName;
      itemData["OriginatingPartnerID"] = value.OriginatingPartnerID;
      itemData["BillingPartnerID"] = value.BillingPartnerID;
      itemData["GroupDescriptionID"] = value.GroupDescriptionID;
      itemData["GroupDescriptionIDArray"] = value.GroupDescriptionIDArray;
      itemData["ClientTypeID"] = value.ClientTypeID;
      //itemData["MarkSlipsBilled"] = value.MarkSlipsBilled;
      itemData["EngagementName"] = null;
      itemData["ProjectMasterID"] = null;
      itemData["EngagementTypeID"] = null;
      itemData["ServiceCode"] = null;

      itemData['Billable'] = value.Billable ? value.Billable : false;
      value.MarkSlipsBilled = value.MarkSlipsBilled ? value.MarkSlipsBilled : false;
      itemData["MarkSlipsBilled"] = value.MarkSlipsBilled;
      itemData['Billed'] = itemData["MarkSlipsBilled"] == true ? true : false;

      this.editCellComplete(itemData);
      this.getProjects(value.ClientID, itemData);
      this.isValidTimeSheets();

      this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    }
    // this.isFormChanged = true;
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  fetchClientList() {
    if (this.clientList.length == 0) {
      const list = this.encrDecSrvc.clientList;
      this.clientList = list;
      this.filteredClients = list;
    } else {
      clearInterval(this.intervalid);
    }
  }

  deleteRow(event, index, selectedStaff?) {
    const self = this;
    if(selectedStaff) {
      self.timeSheetList.splice(index, 1);
      self.isValidTimeSheets();
      self.footerTotals();
    } else {
      Swal.fire({
        title: self.translate.instant('confirmation'),
        text: self.translate.instant('delete_alert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: self.translate.instant('yes_delete'),
        cancelButtonText: self.translate.instant('no_delete'),
      }).then((result) => {
        if (result.value) {
          self.timeSheetList.splice(index, 1);
          self.isValidTimeSheets();
          self.footerTotals();
        }
      })
    }
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(e) <= lastDayOfMonth) {
      this.mangoAPISrvc.notify('error', "Error!", "System Locking is enabled on the selected date.");
    }
  }
}
