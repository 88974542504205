import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
declare let numeral: any;


@Component({
  selector: 'app-time-and-expenses',
  templateUrl: './time-and-expenses.component.html'
})
export class TimeAndExpensesComponent implements OnInit {

  TimeandExpenseForm: UntypedFormGroup;
  templates: SelectItem[];
  firstDayOptions: SelectItem[];
  timeexp: any = {};
  public isFormValid: boolean = false;
  salesTaxCalcOptions: SelectItem[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private fb: UntypedFormBuilder, 
    private mangoAPISrvc: MangoApiService, 
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService) {

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.initializeForm();
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

   }

  ngOnInit(): void {
    this.updateTimeandExpenseForm();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings')},
      { label: this.translate.instant('time_and_expenses'), icon: 'ic-red' }
    ]);
    this.templates = [
      { label: "None", value: null },
      { label: `06 ${this.translate.instant('minutes')}`, value: "06 minutes" },
      { label: `10 ${this.translate.instant('minutes')}`, value: "10 minutes" },
      { label: `15 ${this.translate.instant('minutes')}`, value: "15 minutes" },
      { label: `30 ${this.translate.instant('minutes')}`, value: "30 minutes" },
      { label: `60 ${this.translate.instant('minutes')}`, value: "60 minutes" }
    ];

    this.firstDayOptions = [
      { label: `${this.translate.instant('Monday')}`, value: "monday" },
      { label: `${this.translate.instant('Sunday')}`, value: "sunday" },
    ]

    this.salesTaxCalcOptions = [
      { label: this.translate.instant('settings.Calculate-Sales-Tax-Time-Record'), value: 'timeRecord' },
      { label: this.translate.instant('settings.Calculate-Sales-Tax-Engagement'), value: 'engagement' }
    ]
  }

  initializeForm() {
    this.TimeandExpenseForm = this.fb.group({
      AutoApproveSlips: [true],
      ActualTimeEdit: [true],
      BillableTimeEdit: [true],
      HideBillRate: [false],
      isRateShowAdmin: [true],
      TimeMemoRequired: [true],
      isExpenseMemoRequired: [true],
      isWorkLocationRequired: [false],
      ActivateExpenseRates: [false],
      isAutoPause: [false],
      isLockTime: [false],
      LockTimeDays: [0, [Validators.min(1)]],
      AllowedTimers: [0, [Validators.min(0)]],
      TimeEntryFirstDayofWeek: ['monday'],
      ExpenseRate1: ['$0.00'],
      ExpenseRate2: ['$0.00'],
      ActivateLaborRates:[false],
      LaborRate1: ['$0.00'],
      LaborRate2: ['$0.00'],
      BillingIncrements: ['none'],
      // ActivateCustomerPortal: [''],
      AllowCustomerPortalEdit: [''],
      HideExpenseEntryManualInvoice: [''],
      isShowPmtHistory: [''],
      AutoApproveExpenses: [false],
      isShowInvHistory: [''],
      modeOfPayment:[''],
      isAdminTimeZero: [false],
      SalesTaxOption: [''],
      isZeroTimeAllowed: [false]
    })
    this.TimeandExpenseForm.valueChanges.subscribe(data => {
      //  this.isFormValid = !this.TimeandExpenseForm.pristine;
      this.validateForm();
    });
  }

  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.TimeandExpenseForm.controls).forEach(key => {
      if (this.TimeandExpenseForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.TimeandExpenseForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.TimeandExpenseForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
    this.encrDecSrvc.addObject(AppConstants.isFormChanged,this.isFormValid);
  }

  onCheckLockTime() {
    const LockTimeDays = this.TimeandExpenseForm.get('LockTimeDays');
    if(this.TimeandExpenseForm.get('isLockTime').value === true)
      LockTimeDays.enable();
    else
      LockTimeDays.disable();
  }

  checkExpenseRateValue() {
    const activeExpense = this.TimeandExpenseForm.get('ActivateExpenseRates');
    const expenseRate1 = this.TimeandExpenseForm.get('ExpenseRate1');
    const expenseRate2 = this.TimeandExpenseForm.get('ExpenseRate2');
    //activeExpense.value ?
    if (activeExpense.value === true) {
      expenseRate1.enable();
      expenseRate2.enable();
    } else {
      expenseRate1.disable();
      expenseRate2.disable();
    }
  }

  convertValue(event,controlName) {
    const active = this.TimeandExpenseForm.get(controlName);
    const myNumeral = numeral(active.value);
    if (myNumeral.value() === null) {
      this.TimeandExpenseForm.controls[controlName].setValue(0.00);
      return;
    } else {
      this.TimeandExpenseForm.controls[controlName].setValue(myNumeral.format('0,0.00'));
    }
  }

  checkSalesValue() {
    const active = this.TimeandExpenseForm.get('ActivateLaborRates');
    const rate1 = this.TimeandExpenseForm.get('LaborRate1');
    const rate2 = this.TimeandExpenseForm.get('LaborRate2');
    if (active.value === true) {
      rate1.enable();
      rate2.enable();
    } else {
      rate1.disable();
      rate2.disable();
    }
  }

  updateTimeandExpenseForm() {
    const parent = this;
    if (parent.timeexp && Object.keys(parent.timeexp).length > 0) {
      parent.timeexp.LaborRate1 = parent.timeexp.LaborRate1 ? numeral(parent.timeexp.LaborRate1).value() : 0;
      parent.timeexp.LaborRate2 = parent.timeexp.LaborRate2 ? numeral(parent.timeexp.LaborRate2).value() : 0;
      parent.timeexp.ExpenseRate1 = parent.timeexp.ExpenseRate1 ? numeral(parent.timeexp.ExpenseRate1).value() : 0;
      parent.timeexp.ExpenseRate2 = parent.timeexp.ExpenseRate2 ? numeral(parent.timeexp.ExpenseRate2).value() : 0;

      parent.TimeandExpenseForm.controls['AutoApproveSlips'].setValue(parent.timeexp['AutoApproveSlips']);
      parent.TimeandExpenseForm.controls['AutoApproveExpenses'].setValue(parent.timeexp['AutoApproveExpenses']);
      parent.TimeandExpenseForm.controls['ActualTimeEdit'].setValue(parent.timeexp['ActualTimeEdit']);
      parent.TimeandExpenseForm.controls['BillableTimeEdit'].setValue(parent.timeexp['BillableTimeEdit']);
      parent.TimeandExpenseForm.controls['HideBillRate'].setValue(parent.timeexp['HideBillRate']);
      parent.TimeandExpenseForm.controls['isRateShowAdmin'].setValue(parent.timeexp['isRateShowAdmin']);
      parent.TimeandExpenseForm.controls['TimeMemoRequired'].setValue(parent.timeexp['TimeMemoRequired']);
      parent.TimeandExpenseForm.controls['isExpenseMemoRequired'].setValue(parent.timeexp['isExpenseMemoRequired']);
      parent.TimeandExpenseForm.controls['isWorkLocationRequired'].setValue(parent.timeexp['isWorkLocationRequired']);
      parent.TimeandExpenseForm.controls['isAutoPause'].setValue(parent.timeexp['isAutoPause']);
      parent.TimeandExpenseForm.controls['isLockTime'].setValue(parent.timeexp['isLockTime']);
      parent.TimeandExpenseForm.controls['ActivateExpenseRates'].setValue(parent.timeexp['ActivateExpenseRates']);
      parent.TimeandExpenseForm.controls['ExpenseRate1'].setValue(parent.timeexp['ExpenseRate1']);
      parent.TimeandExpenseForm.controls['ExpenseRate2'].setValue(parent.timeexp['ExpenseRate2']);
      parent.TimeandExpenseForm.controls['ActivateLaborRates'].setValue(parent.timeexp['ActivateLaborRates']);
      parent.TimeandExpenseForm.controls['LaborRate1'].setValue(parent.timeexp['LaborRate1']);
      parent.TimeandExpenseForm.controls['LaborRate2'].setValue(parent.timeexp['LaborRate2']);
      parent.TimeandExpenseForm.controls['BillingIncrements'].setValue(parent.timeexp['BillingIncrements']);
      // parent.TimeandExpenseForm.controls['ActivateCustomerPortal'].setValue(parent.timeexp['ActivateCustomerPortal']);
      parent.TimeandExpenseForm.controls['AllowCustomerPortalEdit'].setValue(parent.timeexp['AllowCustomerPortalEdit']);
      parent.TimeandExpenseForm.controls['HideExpenseEntryManualInvoice'].setValue(parent.timeexp['HideExpenseEntryManualInvoice']);
      parent.TimeandExpenseForm.controls['isShowPmtHistory'].setValue(parent.timeexp['isShowPmtHistory']);
      parent.TimeandExpenseForm.controls['isShowInvHistory'].setValue(parent.timeexp['isShowInvHistory']);
      parent.TimeandExpenseForm.controls['modeOfPayment'].setValue(parent.timeexp['modeOfPayment']);
      parent.TimeandExpenseForm.controls['LockTimeDays'].setValue(parent.timeexp['LockTimeDays']);
      parent.TimeandExpenseForm.controls['AllowedTimers'].setValue(parent.timeexp['AllowedTimers']);
      parent.TimeandExpenseForm.controls['isAdminTimeZero'].setValue(parent.timeexp['isAdminTimeZero']);
      parent.TimeandExpenseForm.controls['isZeroTimeAllowed'].setValue(parent.timeexp['isZeroTimeAllowed']);
      parent.TimeandExpenseForm.controls['TimeEntryFirstDayofWeek'].setValue(parent.timeexp['TimeEntryFirstDayofWeek'] ? parent.timeexp['TimeEntryFirstDayofWeek'] : 'monday');
      parent.TimeandExpenseForm.controls['SalesTaxOption'].setValue(parent.timeexp['SalesTaxOption'] || 'timeRecord');
      parent.checkExpenseRateValue();
      parent.checkSalesValue();
      parent.onCheckLockTime();
    }
  }

  submitTimeandExpenseForm() {
    const formObj = this.TimeandExpenseForm.value;
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateTimeAndExpenses(formObj).subscribe(function (data) {
      const userLogs = {}
      userLogs['Action'] = "Settings - Time and Expense";
      userLogs['Description'] = "Update Company Settings"
      userLogs['Table'] = "CompanyMango";
      const isManaging = _this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
      if (!isManaging) {
        _this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
          console.log(err);
        });
      }
      
      _this.isFormValid = false;
      _this.mangoAPISrvc.showLoader(false);
      _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), _this.translate.instant(data.message));
      _this.encrDecSrvc.addObject(AppConstants.timeAndExpenses, formObj);
      _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    }, error => {
        _this.mangoAPISrvc.showLoader(false);
    }
    )
  }



}



