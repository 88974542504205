<div class="customers">
    <div class="layout-content p-px-3">
        <div class="p-grid p-p-0">
            <div class="p-col-12">
                <div class="card p-grid p-pt-0 p-m-0">
                    <div class="p-col-12 p-pt-0">
                        <p-table #dt [value]="filteredCustomersList" [responsive]="true" [rows]="10" [columns]=customerCols
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [filterDelay]="0" (onFilter)="onFilter($event)" [sortOrder]="-1" [scrollable]="true"
                            scrollHeight="400px"
                            [globalFilterFields]="['CompanyName', 'EmailCompany', 'CompanyContact', 'SubscriptionLevel', 'NumberofUsers', 'annualvalue', 'DateSubscribed', 'DatePlanActivated', 'Telephone', 'State']"
                            [rowHover]="true">

                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search by" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="Clear"></button>
                                    </span>

                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                    <span class="pull-right">
                                        <form [formGroup]="myReportsForm" novalidate>
                                            <label class="label-text p-mr-2">Plan</label>
                                            <p-dropdown [options]="planList" formControlName="PlanFilter"
                                                (onChange)="onChangePlanFilter()" [style]="{'width':'200px'}" scrollHeight="500px"
                                                placeholder="Select Plan" required>
                                            </p-dropdown>
                                            <label class="label-text p-ml-2 p-mr-2">Plan Started</label>
                                            <p-dropdown [options]="dateRangeList" formControlName="DateRange"
                                                (onChange)="changeDateTypes()" scrollHeight="500px"
                                                placeholder="Select Date" required>
                                            </p-dropdown>
                                            <label class="label-text p-ml-2 p-mr-2">Date From</label>
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                                formControlName="DateFrom" [disabled]="IsDateTypeReadOnly" [style]="{'width':'100px'}"
                                                required>
                                            </p-calendar>
                                            <label class="label-text p-ml-2 p-mr-2">Date To</label>
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                                formControlName="DateTo" [disabled]="IsDateTypeReadOnly" [style]="{'width':'100px'}">
                                            </p-calendar>
                                            <!-- <button pButton pRipple name="syncIcon" pTooltip="Refresh" tooltipPosition="top"
                                                (click)="onRefresh()" type="button" icon="fal fa-sync"
                                                class="p-field p-col p-button-rounded p-mb-0 p-ml-2 p-mr-2"></button> -->
                                            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" class="p-ml-2 p-mr-2" pTooltip="CSV" tooltipPosition="bottom" *ngIf="filteredCustomersList.length > 0"></button>
                                            <!-- <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom" *ngIf="filteredCustomersList.length > 0"></button> -->
                                            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning" pTooltip="PDF" tooltipPosition="bottom" *ngIf="filteredCustomersList.length > 0"></button>
                                        </form>
                                    </span>
                                </div>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-13p p-text-left" pSortableColumn="CompanyName">
                                        Customer
                                        <p-columnFilter type="text" field="CompanyName" display="menu"></p-columnFilter>
                                    </th>
                                    <th class="width-11p p-text-left">
                                        Email
                                    </th>
                                    <th class="width-11p p-text-left">
                                        Contact
                                    </th>
                                    <th class="width-7p p-text-left">
                                        Plan
                                    </th>
                                    <th class="width-5p p-text-right">
                                        Users
                                    </th>
                                    <th class="width-5p p-text-right" pSortableColumn="annualvalue">
                                        Value
                                        <p-sortIcon field="annualvalue"></p-sortIcon>
                                    </th>
                                    <th class="width-9p p-text-center" pSortableColumn="DateSubscribed">
                                        Trial Date
                                        <p-columnFilter type="text" field="DateSubscribed" display="menu">
                                        </p-columnFilter>
                                    </th> 
                                    <th class="width-9p p-text-center" pSortableColumn="DatePlanActivated">
                                        Plan Started
                                        <p-columnFilter type="text" field="DatePlanActivated" display="menu">
                                        </p-columnFilter>
                                    </th>
                                    <th class="width-9p p-text-center">
                                        Phone #
                                    </th>
                                    <th class="width-6p p-text-left">
                                        State
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-13p"
                                        pTooltip="{{rowData.CompanyName}}" tooltipPosition="top"
                                        showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.CompanyName.trim().length === 0 ? rowData.CompanyID : rowData.CompanyName}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-11p"
                                        pTooltip="{{rowData.EmailCompany}}" tooltipPosition="top"
                                        showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.EmailCompany}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-11p"
                                        pTooltip="{{rowData.CompanyContact}}" tooltipPosition="top"
                                        showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.CompanyContact}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-7p"
                                        pTooltip="{{rowData.SubscriptionLevel}}" tooltipPosition="top"
                                        showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.SubscriptionLevel}}
                                    </td>
                                    <td class="p-text-right width-5p">
                                        {{rowData.NumberofUsers}}
                                    </td>
                                    <td class="p-text-right width-5p">
                                        {{rowData.annualvalue | currency:'USD'}}
                                    </td>
                                    <td class="p-text-center width-9p"
                                        pTooltip="{{rowData.DateSubscribed | date:'YYYY/MM/dd'}}"
                                        tooltipPosition="top" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.DateSubscribed | date:'YYYY/MM/dd'}}
                                    </td>
                                    <td class="p-text-center width-9p"
                                        pTooltip="{{rowData.DatePlanActivated | date:'YYYY/MM/dd'}}"
                                        tooltipPosition="top" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.DatePlanActivated | date:'YYYY/MM/dd'}}
                                    </td>
                                    <td class="p-text-center width-9p">
                                        {{ rowData.Telephone }}
                                    </td>
                                    <td class="p-text-left width-6p">
                                        {{ rowData.State }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer"  let-columns>
                                <tr *ngIf="filteredCustomersList.length > 0">
                                    <td class="width-13p p-text-left ic-red">Total Count</td>
                                    <td class="width-11p p-text-left ic-red">{{filteredItemsSize > 0 ? filteredItemsSize : filteredCustomersList.length}}</td>
                                    <td class="width-11p"></td>
                                    <td class="width-7p p-text-left ic-red">Total Users</td>
                                    <td class="width-5p p-text-right ic-red">{{totalUserCount}}</td>
                                    <td class="width-5p"></td>
                                    <td class="width-9p"></td>
                                    <td class="width-9p"></td>
                                    <td class="width-9p"></td>
                                    <td class="width-6p"></td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="10">
                                        <p-message severity="info" text="Data not found." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>