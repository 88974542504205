<p-dialog [modal]="true" [(visible)]="isDisplayWildcardsTable" [style]="{width: '35vw'}" [responsive]="true" (onHide)="onCloseDialog()"
  [draggable]="true" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
  <p-header>{{ 'pm.available_wildcards' | translate }}</p-header>
  <p-table #dt [value]="tableData" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
    [rowHover]="true" dataKey="wildcard" sortField="wildcard" [sortOrder]="1" #wildcardTable>
    <ng-template pTemplate="header">
      <tr>
        <th class="width-20p p-text-left" pSortableColumn="wildcard">
          Wildcard
          <p-sortIcon styleClass="p-ml-0" field="wildcard"></p-sortIcon>
        </th>
        <th class="width-40p p-text-left" pSortableColumn="equivalent">
          Equivalent
          <p-sortIcon styleClass="p-ml-0" field="equivalent"></p-sortIcon>
        </th>
        <th class="width-40p p-text-left" pSortableColumn="example">
          Example
          <p-sortIcon styleClass="p-ml-0" field="example"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="width-20p p-text-left">
          {{ rowData.wildcard }}
        </td>
        <td class="width-40p p-text-left">
          {{rowData.equivalent}}
        </td>
        <td class="width-40p p-text-left">
          {{rowData.example}}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-footer>
    <button pButton label="{{ 'close' | translate }}" (click)="onCloseDialog()"></button>
  </p-footer>
</p-dialog>