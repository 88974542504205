import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetsComponent } from './budgets.component';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { BatchAssignBudgetComponent } from './batch-assign-budget/batch-assign-budget.component';
import { TasksBudgetComponent } from './tasks-budget/tasks-budget.component';
import { BudgetRolloverOptionsComponent } from './budget-rollover-options/budget-rollover-options.component';

@NgModule({
  declarations: [
    BudgetsComponent,
    BatchAssignBudgetComponent,
    TasksBudgetComponent,
    BudgetRolloverOptionsComponent
  ],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule
  ], 
  exports: [
    BudgetsComponent,
    BatchAssignBudgetComponent,
    TasksBudgetComponent,
    BudgetRolloverOptionsComponent
  ]
})
export class BudgetsModule { }
