<p-dialog header="Session Expired" [(visible)]="showSessionDialog" [modal]="true" [style]="{width: '35vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    [closable]="false" [closeOnEscape]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card p-text-center">
                <i class="pi pi-exclamation-triangle ic-red" style="font-size: 3rem"></i>
                <h4>Your session has expired. Please Relogin..</h4>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-sign-out" (click)="onConfirm()" label="Logout"></button>
    </ng-template>
</p-dialog>