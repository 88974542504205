<div class="p-grid vertical-container p-mt-6">
    <div class="p-col p-as-start" *ngIf="!isMobileResolution">
        <div>&nbsp;</div>
    </div>
    <div class="p-col p-as-center">
        <div *ngIf="isApiLoaded" class="mobilepayment">
            <p-panel>
                <ng-template pTemplate="header" styleClass="p-my-0">
                    <div *ngIf="!isStatementFlow">Pay Invoice</div>
                    <div *ngIf="isStatementFlow">Statement Payment</div>
                    <!-- Pay Invoice  Pay Statement Balance-->
                    <div class="ic-size-12" *ngIf="isInvalidToken">{{companyData.company.CompanyName}}</div>
                </ng-template>
                <div class="p-panel-content">
                    <div *ngIf="!isInvalidToken && !isPaymentCompleted">
                        <div class="p-text-center">
                            <img src="assets/mango/img/denied.png" alt="denied" width="150" height="150"
                                class="opacity-4">
                        </div>
                        <div class="p-text-center p-pt-3">
                            Retainer Invoice has been already paid, or there is no longer an Invoice to be paid.
                        </div>
                    </div>
                    <!-- Paid  -->
                    <div *ngIf="isInvalidToken && (companyData.company.InvoiceBalance <= 0 || isPaymentCompleted)">
                        <div class="p-field">
                            <label for="name" class="label-text p-d-block">{{ 'client.name' | translate }}</label>
                            <div class="p-text-nowrap p-text-truncate" title="{{companyData.company.ClientName}}">
                                {{companyData.company.ClientName}}</div>
                        </div>
                        <div class="p-field">
                            <label for="name" class="label-text p-d-block">{{ 'invoice_number' | translate }}</label>
                            <div class="p-text-nowrap p-text-truncate">
                                {{companyData.company.InvoiceNumber}}</div>
                        </div>
                        <div class="p-field">
                            <label for="name" class="label-text p-d-block">{{ 'Accounts.invoice-amount' | translate
                                }}</label>
                            <div class="p-text-nowrap p-text-truncate">
                                {{companyData.company.InvoiceAmountWithTax || 0 | currency : 'USD' : true :
                                '1.2-2'}}</div>
                        </div>
                        <div class="p-field">
                            <label for="name" class="label-text p-d-block">{{ 'Accounts.invoice-balance' | translate
                                }}</label>
                            <div class="p-text-nowrap p-text-truncate">
                                {{currentInvoiceBalance || 0 | currency : 'USD' : true :
                                '1.2-2'}}</div>
                        </div>
                        <div *ngIf="paymentsApplied?.length > 0">
                            <h5 for="name" class="label-text p-d-block">
                                Payment(s) Applied
                            </h5>
                            <p-table #dt [value]="paymentsApplied" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [rowHover]="true" dataKey="PaymentDetailID" sortField="PaymentDetailID" [sortOrder]="-1" #paymentsAppliedTable>
                                <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-40p p-text-left" pSortableColumn="PaymentDate">
                                        Payment Date
                                        <p-sortIcon styleClass="p-ml-0" field="PaymentDate"></p-sortIcon>
                                    </th>
                                    <th class="width-30p p-text-left">
                                        Payment Type
                                    </th>
                                    <th class="width-30p p-text-right">
                                        Payment Amount
                                    </th>
                                </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td class="width-40p p-text-left">
                                            {{ rowData.PaymentDate | date: "MM/dd/yyyy" }}
                                        </td>
                                        <td class="width-30p p-text-left">
                                            {{rowData.PaymentType}}
                                        </td>
                                        <td class="width-30p p-text-center">
                                            {{rowData.AppliedAmount | currency: "USD":true:"1.2-2"}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="p-field">
                            &nbsp;
                        </div>
                        <div class="p-text-center">
                            <img src="assets/mango/img/paid_business.svg" alt="Paid" width="150" height="150"
                                class="opacity-4">
                        </div>
                    </div>
                    <div *ngIf="!isPaymentCompleted && isInvalidToken && companyData.company.InvoiceBalance > 0">
                        <div class="p-field">
                            <label for="name" class="label-text p-d-block">{{ 'client.name' | translate }}</label>
                            <div class="p-text-nowrap p-text-truncate" title="{{companyData.company.ClientName}}">
                                {{companyData.company.ClientName}}</div>
                        </div>
                        <div class="p-field" *ngIf="!isStatementFlow">
                            <label for="name" class="label-text p-d-block">{{ 'invoice_number' | translate }}</label>
                            <div class="p-text-nowrap p-text-truncate">
                                {{companyData.company.InvoiceNumber}}</div>
                        </div>
                        <!--<div class="p-field">
                            <label for="name" class="label-text p-d-block">{{ 'Accounts.invoice-amount' | translate
                                }}</label>
                            <div class="p-text-nowrap p-text-truncate">
                                {{companyData.company.InvoiceAmount || 0 | currency : 'USD' : true :
                                '1.2-2'}}</div>
                        </div> -->
                        <form role="form" [formGroup]="myPaymentForm" autocomplete="off" novalidate>
                            <div *ngIf="companyData.company.isAllowPartialPayments">
                                <div class="p-field" *ngIf="!companyData.company.isSurchargeEnabled">
                                    <div class="p-field-radiobutton p-d-block">
                                        <p-radioButton name="invoiceBalance" value="true"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="isFullPayment"
                                            inputId="fullPayment" (onClick)="applyFullAmount()"></p-radioButton>
                                        <label for="fullPayment">Pay Full Balance Due:
                                            {{companyData.company.InvoiceBalance
                                            |
                                            currency : 'USD' : true :
                                            '1.2-2'}}</label>
                                    </div>
                                    <div class="p-field-radiobutton p-d-block">
                                        <p-radioButton name="invoiceBalance " value="false"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="isFullPayment"
                                            inputId="partialPay" (onClick)="applyPartialAmount()"></p-radioButton>
                                        <label for="partialPay">Partial Pay</label>
                                    </div>

                                    <label *ngIf="isFullPayment ==='false'" for="amt"
                                        class="label-text p-d-block p-mb-1">Enter Amount</label>
                                    <div *ngIf="!isPaymentCompleted && isFullPayment ==='false'">
                                        <p-inputNumber formControlName="Amount" mode="currency" currency="USD"
                                            locale="en-US" [maxFractionDigits]="2" [min]="0.01"
                                            [max]="companyData.company.InvoiceBalance">
                                        </p-inputNumber>
                                    </div>
                                    <div *ngIf="isPaymentCompleted">
                                        {{paidAmount | currency : 'USD' : true :
                                        '1.2-2'}}
                                    </div>
                                </div>

                                <div class="p-field" *ngIf="companyData.company.isSurchargeEnabled">
                                    <div class="p-field-radiobutton p-d-block">
                                        <p-radioButton name="invoiceBalance" value="true"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="isFullPayment"
                                            inputId="fullPayment" (onClick)="applyFullAmount()"></p-radioButton>
                                        <label for="fullPayment">Pay Full Balance Due:
                                            {{companyData.company.InvoiceBalance
                                            |
                                            currency : 'USD' : true :
                                            '1.2-2'}}</label>
                                    </div>
                                    <div class="p-field-radiobutton p-d-block">
                                        <p-radioButton name="invoiceBalance " value="false"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="isFullPayment"
                                            inputId="partialPay"></p-radioButton>
                                        <label for="partialPay">Partial Pay</label>
                                    </div>
                                    <div class="p-d-flex">
                                        <div class="p-mr-2" *ngIf="isFullPayment ==='false'">
                                            <label for="amt" class="label-text p-d-block p-mb-1">Enter Amount</label>
                                            <div *ngIf="!isPaymentCompleted">
                                                <p-inputNumber formControlName="Amount" mode="currency" currency="USD"
                                                    locale="en-US" [maxFractionDigits]="2" inputStyleClass="width-120px"
                                                    (onBlur)="calculateSurcharge()" (focus)="$event.target.select()"
                                                    [max]="companyData.company.InvoiceBalance">
                                                </p-inputNumber>
                                                <span class="surcharge-msg p-pl-3"
                                                    *ngIf="cardProfile && (selectedModeOfPayment === 1 || selectedModeOfPayment === 2)">
                                                    <p-message severity="info" text="{{'surcharge-msg' | translate}}"></p-message>
                                                </span>
                                            </div>
                                            <div *ngIf="isPaymentCompleted">
                                                {{paidAmount | currency : 'USD' : true :
                                                '1.2-2'}}
                                            </div>
                                        </div>
                                        <div class="p-mx-3"
                                            *ngIf="cardProfile && isCrCard && myPaymentForm.get('SurChargeAmount').value > 0 && selectedModeOfPayment === 0">
                                            <label for="amt" class="label-text p-d-block p-mb-1">Surcharge</label>
                                            <div *ngIf="!isPaymentCompleted">
                                                <p-inputNumber disabled="true" inputStyleClass="width-120px"
                                                    formControlName="SurChargeAmount" mode="currency" currency="USD"
                                                    locale="en-US" [maxFractionDigits]="2">
                                                </p-inputNumber>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="cardProfile && isCrCard && myPaymentForm.get('SurChargeAmount').value > 0 && selectedModeOfPayment === 0">
                                            <label for="amt" class="label-text p-d-block p-mb-1">Total Amount</label>
                                            <div *ngIf="!isPaymentCompleted">
                                                <p-inputNumber disabled="true" inputStyleClass="width-120px"
                                                    formControlName="withSurcharge" mode="currency" currency="USD"
                                                    locale="en-US" [maxFractionDigits]="2">
                                                </p-inputNumber>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-field" *ngIf="!companyData.company.isAllowPartialPayments">
                                <label for="name" class="label-text p-d-block">Balance Due</label>
                                <div class="p-text-nowrap p-text-truncate"
                                    title="{{companyData.company.InvoiceBalance | currency : 'USD' : true : '1.2-2'}}">
                                    {{companyData.company.InvoiceBalance | currency : 'USD' : true : '1.2-2'}}</div>
                            </div>
                            <!-- Single Option -->
                            <div *ngIf="cardOptionsList.length == 1 && isSTaxEnabled" class="p-field">
                                <label for="paymenttype" class="label-text p-d-block">{{ 'Accounts.payment-type' |
                                    translate }}</label>
                                <div class="p-text-nowrap p-text-truncate">
                                    {{cardOptionsList[0].label}}</div>
                            </div>
                            <!-- multiple Option -->
                            <div *ngIf="cardOptionsList.length > 1 && isSTaxEnabled" class="p-field">
                                <label for="paymenttype" class="label-text p-d-block">{{ 'Accounts.payment-type' |
                                    translate }}</label>
                                <p-dropdown [options]="cardOptionsList" [(ngModel)]="selectedModeOfPayment"
                                    *ngIf="cardOptionsList.length > 1" [style]="{'width':'75%'}"
                                    [ngModelOptions]="{ standalone: true }" (onChange)="changePaymentType()">
                                </p-dropdown>
                            </div>
                            <div *ngIf="cardProfile && !isStaxNewCard">
                                <div class="p-field">
                                    <label for="paymenttype" class="label-text p-d-block" *ngIf="isCrCard">{{
                                        'billing-invoicing.name-on-card' | translate
                                        }}</label>
                                    <label for="paymenttype" class="label-text p-d-block" *ngIf="!isCrCard">{{
                                        'user.Name' | translate
                                        }}</label>
                                    <div>{{cardProfile.NameOnCard}}</div>
                                </div>
                                <div class="p-field p-mb-0">
                                    <label for="paymenttype" class="label-text p-d-block" *ngIf="isCrCard">
                                        <span *ngIf="!isDebitCard">
                                            <label for="uname" class="label-text">{{ 'last-4digits-crcard' | translate
                                                }}</label>
                                        </span>
                                        <span *ngIf="isDebitCard">
                                            <label for="uname" class="label-text">{{ 'last-4digits-dbcard' | translate
                                                }}</label>
                                        </span>
                                    </label>
                                    <label for="paymenttype" class="label-text p-d-block" *ngIf="!isCrCard">{{
                                        'last-4digits-bank' |
                                        translate }}</label>
                                    <div> ****{{cardProfile.CardNo}}
                                        <span *ngIf="cardProfile['CardType'] && !isCrCard">
                                            <i class="far fa-money-check ic-green p-ml-2 ic-size-22"
                                                tooltipPosition="top" pTooltip="{{cardProfile['CardType']}}"
                                                showDelay="800" tooltipStyleClass="tooltip-no-wrap"></i>
                                        </span>
                                        <span *ngIf="isCrCard && cardProfile['CardType'] === 'visa'"
                                            tooltipPosition="top" pTooltip="Visa" showDelay="800"
                                            class="p-ml-2 crcardimg" tooltipStyleClass="tooltip-no-wrap">
                                            <img src="assets/mango/svg/visa.svg">
                                        </span>
                                        <span *ngIf="isCrCard && cardProfile['CardType'] === 'mastercard'"
                                            tooltipPosition="top" pTooltip="Mastercard" showDelay="800"
                                            class="p-ml-2 crcardimg" tooltipStyleClass="tooltip-no-wrap">
                                            <img src="assets/mango/svg/mastercard.svg">
                                        </span>
                                        <span *ngIf="isCrCard && cardProfile['CardType'] === 'discover'"
                                            tooltipPosition="top" pTooltip="Discover" showDelay="800"
                                            class="p-ml-2 crcardimg" tooltipStyleClass="tooltip-no-wrap">
                                            <img src="assets/mango/svg/discover.svg">
                                        </span>
                                        <span *ngIf="isCrCard && cardProfile['CardType'] === 'amex'"
                                            tooltipPosition="top" pTooltip="Amex" showDelay="800"
                                            class="p-ml-2 crcardimg" tooltipStyleClass="tooltip-no-wrap">
                                            <img src="assets/mango/svg/amex.svg">
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isStaxNewCard && isSelctedPaymentIndex > -1">
                                <div class="p-field">
                                    <label for="amt" class="required-field label-text p-d-block">{{ 'fname' | translate
                                        }}</label>
                                    <input type="text"  #firstNameInputFocus pInputText formControlName="FirstName" />
                                </div>
                                <div class="p-field">
                                    <label for="amt" class="required-field label-text p-d-block">{{ 'lname' | translate
                                        }}</label>
                                    <input type="text" pInputText formControlName="LastName" />
                                </div>
                                <div class="p-field">
                                    <label for="cardNumber" class="required-field label-text">{{ 'user.user_address'
                                        |
                                        translate }}</label> <span class="p-text-italic p-ml-2 ic-red"
                                        style="font-size: 11px">(Address must match billing
                                        address on Credit Card)</span>
                                    <input type="text" pInputText formControlName="Address1" />
                                </div>
                                <div class="p-field">
                                    <label for="cardExpiry" class="required-field label-text  p-d-block"><span>{{
                                            'client.city' | translate }}</span></label>
                                    <input type="text" formControlName="City" pInputText autocomplete="nope"
                                        maxlength="50">
                                </div>

                                <div class="p-field">
                                    <label for="cardExpiry" class="required-field label-text  p-d-block"><span>{{
                                            'client.state' | translate }}</span></label>
                                    <input type="text" formControlName="State" pInputText autocomplete="nope"
                                        maxlength="50">
                                </div>

                                <div class="p-field">
                                    <label class="label-text required-field  p-d-block">{{ 'client.zip' | translate
                                        }}</label>
                                    <input type="text" pInputText formControlName="Zip" maxlength="10"
                                        (blur)="focusCard(0,'card_number')" autocomplete="nope">
                                </div>
                                <div *ngIf="isCrCard">
                                    <div class="p-field">
                                        <label for="cardNumber" class="required-field label-text p-d-block">{{
                                            'billing-invoicing.card-number' | translate }}</label>
                                        <div id="fattjs-number" style="height:35px;">
                                        </div>
                                    </div>
                                    <div class="p-field">
                                        <label for="amt" class="required-field label-text p-d-block">{{
                                            'billing-invoicing.expiration-date' | translate }}</label>
                                        <p-inputMask mask="99/9999" formControlName="expDate" autofocus
                                            (onBlur)="focusCard(1,'cvv')">
                                        </p-inputMask>
                                    </div>
                                    <div class="p-field">
                                        <label for="amt" class="required-field label-text p-d-block">{{ 'cv-code' |
                                            translate }}</label>
                                        <div id="fattjs-cvv" style="height:35px;">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isCrCard">
                                    <div class="p-field">
                                        <label for="amt" class="required-field label-text p-d-block">Account to
                                            Debit</label>
                                        <p-dropdown [options]="transCodeTypes" formControlName="TransCode"
                                            [style]="{'width':'75%'}">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-field">
                                        <label for="amt" class="required-field label-text p-d-block">Routing
                                            Number</label>
                                        <input type="text" pInputText formControlName="RoutingNumber" />
                                    </div>
                                    <div class="p-field">
                                        <label for="amt" class="required-field label-text p-d-block">Account
                                            Number</label>
                                        <input type="text" pInputText formControlName="AccountNumber" />
                                    </div>
                                    <div class="p-field">
                                        <p-radioButton name="groupname" value="false" [(ngModel)]="isBusiness"
                                            [ngModelOptions]="{standalone: true}" label="Personal"
                                            labelStyleClass="p-pr-2">
                                        </p-radioButton>
                                        <p-radioButton name="groupname" value="true" [(ngModel)]="isBusiness"
                                            [ngModelOptions]="{standalone: true}" label="Business">
                                        </p-radioButton>
                                    </div>
                                </div>
                            </div>
                            <!-- no Option -->
                            <div *ngIf="cardOptionsList.length == 0 || !isSTaxEnabled" class="p-fluid">
                                <div class="p-field ic-red">
                                    Payment Options are not enabled.
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ng-template pTemplate="footer"
                    *ngIf="isApiLoaded && isInvalidToken && companyData.company.InvoiceBalance > 0">
                    <div class="p-text-center" *ngIf="!isPaymentCompleted">
                        <button pButton pRipple type="button" label="Pay Now" class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="isInvalidToken && isSTaxEnabled && cardOptionsList.length > 0 && isSelctedPaymentIndex > -1  && !companyData.company.isSurchargeEnabled"
                            [disabled]="!isDialogFormValid || isProcessing" (click)="processPayment();"></button>

                        <button pButton pRipple type="button" label="Pay Now" class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="isInvalidToken && isSTaxEnabled && cardOptionsList.length > 0 && isSelctedPaymentIndex > -1 && !isStaxNewCard && companyData.company.isSurchargeEnabled"
                            [disabled]="!isDialogFormValid || isProcessing" (click)="processPayment();"></button>

                        <button pButton pRipple type="button" label="{{ 'authorize-card' | translate }}"
                            class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="isInvalidToken && isSTaxEnabled && isCrCard &&  cardOptionsList.length > 0 && isSelctedPaymentIndex > -1 && isStaxNewCard && companyData.company.isSurchargeEnabled"
                            [disabled]="!isDialogFormValid" (click)="authorizeSTaxNewCard();"></button>

                        <button pButton pRipple type="button" label="{{ 'authorize-bank' | translate }}"
                            class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="isInvalidToken && isSTaxEnabled && !isCrCard &&  cardOptionsList.length > 0 && isSelctedPaymentIndex > -1 && isStaxNewCard && companyData.company.isSurchargeEnabled"
                            [disabled]="isProcessing || !isDialogFormValid" (click)="authorizeSTaxNewCard();"></button>

                        <button pButton pRipple type="button" label="{{ 'add-new-bank' | translate }}"
                            class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="isInvalidToken && isSTaxEnabled && !isStaxNewCard &&  cardOptionsList.length > 0 && isSelctedPaymentIndex == 1 && companyData.cards.length > 0"
                            (click)="addNewStax('bank')" [disabled]="isStaxNewCard"></button>

                        <button pButton pRipple type="button" label="{{ 'Add-New-Card' | translate }}"
                            class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="isInvalidToken && isSTaxEnabled && !isStaxNewCard && cardOptionsList.length > 0 && isSelctedPaymentIndex == 0 && companyData.cards.length > 0"
                            (click)="genarateIFrames();addNewStax('card')" [disabled]="isStaxNewCard"></button>

                        <button pButton pRipple type="button" label="Close Window"
                            class="p-button-sm p-pr-3 p-mr-2 p-my-2"
                            *ngIf="!isInvalidToken || cardOptionsList.length == 0 || !isSTaxEnabled"
                            (click)="closeWindow()"></button>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>
    <div class="p-col p-as-end" *ngIf="!isMobileResolution">
        <div>&nbsp;</div>
    </div>
</div>