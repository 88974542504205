
import {
  Component,
  OnInit
} from '@angular/core';

import {
  ActivatedRoute,
  Params
} from '@angular/router';

import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService
} from '@app/_services';

import {
  AppConstants
} from '@app/_helpers/api-constants';

import {
  requestGetSystemEnvironmentSetting,
} from '@app/connector/request-get-system-environment-setting'
import {
  requestGetStaffNylasSetting,
} from '@app/connector/request-get-staff-nylas-setting'
import {
  requestSetStaffNylasSetting,
} from '@app/connector/request-set-staff-nylas-setting'

import {
  environment
} from "@environments/environment";

@Component(
  {
    selector: 'app-calendar-integration',
    templateUrl: './calendar-integration.component.html'
  }
)
export class CalendarIntegrationComponent implements OnInit {
  public nylasIntegrationFeatureStatus: boolean = false;

  public nylasCalendarIntegrationSupportStatus: boolean = false;
  public nylasMicrosoftCalendarIntegrationStatus: boolean = false;
  public nylasGoogleCalendarIntegrationStatus: boolean = false;

  public hasAccessToken: boolean = false;
  public isGoogleCalendarIntegration: boolean = false;
  public selectedUserNameStr: any;
  public hasOLAccessToken: boolean = false;
  public isOutlookCalendarIntegration: boolean = false;
  staffID;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private activatedRoute: ActivatedRoute,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService
  ){
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.userName);

    this.nylasIntegrationFeatureStatus = (
      (
            environment.NYLAS_INTEGRATION_FEATURE_STATUS
        === true
      )
    );
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'My Profile' },
      { label: 'Calendar Integration' },
      { label: this.selectedUserNameStr, icon: 'ic-red' }
    ]);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const code = params['code'];
      const scope = params['scope'];
      const parent = this;
      if (code) {
        if (scope) {
          parent.mangoAPISrvc.showLoader(true);
          parent.fetchOutlookApis();
          parent.mangoAPISrvc.updateApiToken(code).subscribe((res) => {
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('success', 'Success!', 'Google API token has been generated.');
            parent.fetchGoogleApis();
          }, error => {
            parent.fetchGoogleApis();
            parent.mangoAPISrvc.notify('error', 'Error!', 'Error Google API token has not been generated.');
            parent.mangoAPISrvc.showLoader(false);
          })
        } else {
          const self = this;
          self.fetchGoogleApis();
          self.mangoAPISrvc.validateOutlookToken(self.staffID, { code: code }).subscribe((data: any) => {
            if (!data) {
              return;
            }
            self.hasOLAccessToken = data.OutlookAccessToken ? true : false;
            self.isOutlookCalendarIntegration = self.hasOLAccessToken;
            self.mangoAPISrvc.outlookWebhookRegistration(self.staffID).subscribe((data: any) => {
              console.log(data)
            }, err => {
              console.log(err)
            })
          }, error => {
            parent.fetchOutlookApis();
            parent.mangoAPISrvc.notify('error', 'Error!', 'Error Outlook token has not been generated.');
            parent.mangoAPISrvc.showLoader(false);
          })
        }

      } else {
        parent.fetchGoogleApis();
        parent.fetchOutlookApis();
      }
    });
  }

  fetchGoogleApis() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getApiToken().subscribe((data:any) => {
      if (!data) {
        return;
      }

      self.hasAccessToken = !!data.token;
      self.isGoogleCalendarIntegration = data.syncEnabled;
      self.mangoAPISrvc.showLoader(false);
    }, error => {
      self.removeToken();
      self.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      self.mangoAPISrvc.showLoader(false);
    })
  }

  generateToken() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getApiAuthURL().subscribe((result:any) => {
      if (result && result.redirectUrl) {
        self.mangoAPISrvc.notify('info', 'Info!', "You will be redirected to Google website for authentication. Please wait...");
        window.location.href = result.redirectUrl;
      }
      self.mangoAPISrvc.showLoader(false);
    }, error => {
      self.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      self.mangoAPISrvc.showLoader(false);
    })
  }

  fetchOutlookApis() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getOutlookApiToken(self.staffID).subscribe((data:any) => {
      if (!data || data?.isExpired) {
        return;
      }
      self.hasOLAccessToken = data.OutlookAccessToken ? true : false;
      self.isOutlookCalendarIntegration = self.hasOLAccessToken;
      self.mangoAPISrvc.showLoader(false);
    }, error => {
      self.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      self.mangoAPISrvc.showLoader(false);
    });
  }

  generateOutLookToken() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getLoginUrl().subscribe((result: any) => {
      self.mangoAPISrvc.showLoader(false);
      if (result && result.redirectUrl) {
        self.mangoAPISrvc.notify('info', 'Info!', "You will be redirected to Microsoft Outlook website for authentication. Please wait...");
        window.location.href = result.redirectUrl;
      }
    }, error => {
      self.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      self.mangoAPISrvc.showLoader(false);
    })
  }

  removeOLToken() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);

    self.mangoAPISrvc.outlookDeleteWebhook(self.staffID).subscribe((data) => {
      self.hasOLAccessToken = false;
      self.isOutlookCalendarIntegration = false;
      self.mangoAPISrvc.showLoader(false);
      self.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
    }, err => {
      self.mangoAPISrvc.updateOutlookApiToken(self.staffID, {
        OutlookAccessToken: null,
        OutlookRefreshToken: null,
        TokenExpDate: null,
        SubscriptionExpiredDate: null,
        OutlookSubscriptionID: null
      }).subscribe((data) => {
        self.hasOLAccessToken = false;
        self.isOutlookCalendarIntegration = false;
        self.mangoAPISrvc.showLoader(false);
        self.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
      }, error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      })
    })
  }

  saveGapiSettings() {
    this.mangoAPISrvc.updateApiSettings({ syncEnabled: this.isGoogleCalendarIntegration }).subscribe((result) => {
        this.mangoAPISrvc.notify('success', 'Success', 'Google API settings has been updated.');
    }, (err) => {
        this.mangoAPISrvc.notify('error', 'Error', err.json().message);
    });
}

  removeToken() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.removeApiToken().subscribe((result) => {
      self.hasAccessToken = false;
      self.mangoAPISrvc.notify('error', 'Error!', 'Google API token has been removed.');
      self.mangoAPISrvc.showLoader(false);
    }, error => {
      self.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
      self.mangoAPISrvc.showLoader(false);
    });
  }
}
