import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { SharedComponentsService } from "@app/shared/components";
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
declare let numeral: any;

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html'
})
export class ExpensesComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  @ViewChild('ExpenseMainForm') ngForm;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";

  editIndex;
  filterOrderBy;
  isEditExpenses;

  formattedAmount: string = '';
  expense: any = {};
  expenseList: any = [];
  tempExpenseList: any = [];
  expenseInactive: boolean = false;
  displayAddExpenseModal: boolean = false;
  subscription

  public tempInvoice: any = [];
  public timeAndExpenseData: any = null;

  
  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    protected mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService,
    private translate: TranslateService) {

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.expenses + '_' + AppConstants.SearchString);
    this.timeAndExpenseData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.getExpenses();

    this.subscription = this.sharedSrvc.expensesDialogData.subscribe((data) => {
      if (data) {
        this.getExpenses();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('billing-invoicing.apply-to-expenses'), icon: 'ic-red' }
    ]);
  }

  onExpenseListStatusChange(data) {
    this.expense = data;
    this.saveExpenses(false, true);
  }

  customSort(event: SortEvent) {
    this.mangoUtils.tableColumnsSort(event);
  }

  
  doMoneyFormate(inputValue) {
    const value = inputValue;
    const myNumeral = numeral(value);
    if (myNumeral.value() === null) {
      this.expense.Cost = 0.0;
    }
    return "$" + numeral(myNumeral.value()).format("0,0.00");
  }

  closeExpenses() {
    this.expense = {};
    this.expense.TaxMap1 = false;
    //this.expense.TaxMap2 = false;
    //this.expense.TaxMap3 = false;
    this.expense.Billable = true;
    this.expense.Inactive = false;

    this.ngForm.form.markAsPristine();
    this.ngForm.form.markAsUntouched();
    this.ngForm.form.updateValueAndValidity();

    this.expense.Description = "";
    this.expense.ExpenseCode = "";
    this.expense.Cost = "";
    this.expense.Markup = " 0";
    //this.displayAddExpenseModal = false;
  }

  onChangeInputCode() {
    this.expense.ExpenseCode = this.expense.ExpenseCode.toUpperCase();
  }

 

  openEditExpenses(obj) {
    this.editIndex = obj.index;
    this.expense = Object.assign({}, obj);
    this.isEditExpenses = true;
    this.expense.Cost = numeral(this.expense.Cost).value();
    this.expense.TaxMap1 = this.expense.TaxMap1 ? this.expense.TaxMap1 : false;
    // this.expense.TaxMap2 = this.expense.TaxMap2 ? this.expense.TaxMap2 : false;
    this.sharedSrvc.openExpensesDialog({isEditFlow: true, expense: this.expense})
  }

  openAddExpenses() {
    this.isEditExpenses = false;
    this.expense = {};
    this.expense.TaxMap1 = false;
    //this.expense.TaxMap2 = false;
    //this.expense.TaxMap3 = false;
    this.expense.Billable = true;
    this.expense.Inactive = false;

    this.expense.Description = "";
    this.expense.ExpenseCode = "";
    this.expense.Cost = "";
    this.expense.Markup = " 0";
    this.sharedSrvc.openExpensesDialog({isEditFlow: false, expense: this.expense})
  }

  saveExpenses(isSave, isClose) {
    const parent = this;
    parent.expense.Cost = numeral(parent.expense.Cost).value().toString();
    parent.expense.Markup = numeral(parent.expense.Markup).value().toString();
    if (typeof (parent.expense.TaxMap1) == "object") {
      ///parent.expense.TaxMap2 = false;
      parent.expense.TaxMap1 = true;
    }
    if (typeof (parent.expense.TaxMap2) == "object") {
      parent.expense.TaxMap1 = false;
     // parent.expense.TaxMap2 = true;
    }
    parent.mangoAPISrvc.showLoader(true);
    if (isSave || parent.expense['ExpenseCodeID'] === null) {
      delete this.expense['ExpenseCodeID'];
      parent.mangoAPISrvc.saveExpenses(parent.expense).subscribe(function (data) {
        parent.getExpenses();
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.createMsg);
        parent.mangoAPISrvc.showLoader(false);
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      }, error => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.createErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    } else {
      if (parent.expense.Markup == null)
      parent.expense.Markup = "0.0%";
      parent.mangoAPISrvc.updateExpenses(parent.expense.ExpenseCodeID, parent.expense).subscribe(function (data) {
        parent.getExpenses();
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.mangoAPISrvc.showLoader(false);
      }, error => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    }
    if (isClose) {
      //parent.displayAddExpenseModal = false;
    } else {
      this.isEditExpenses = false;
      this.expense = { "ExpenseCodeID": null, "TaxMap1": false, "TaxMap2": false, "TaxMap3": false, "Billable": true, "Inactive": false, "Description": "", "ExpenseCode": "", "Cost": "0", "Markup": "0" };
    }
  }

  deleteExpense(id) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteExpenses(id).subscribe(function (data) {
          parent.getExpenses();
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
          parent.mangoAPISrvc.showLoader(false);
        }, error => {
          parent.makeInactive(id);
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

  makeInactive(id) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('make_inactive_first'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.forceDeleteExpenses(id).subscribe(function (data) {
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
          parent.mangoAPISrvc.showLoader(false);
        }, error => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.expenses + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.expenses + '_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  saveAndNew(IsEdit) {
    this.saveExpenses(!IsEdit, false);
  }

  getExpenses() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getExpenses().subscribe(function (data:any) {
      _this.tempExpenseList = data;
      data.map(itm=>{
        itm.Cost = _this.doMoneyFormate(itm.Cost);
      });
      _this.expenseList = data.filter((expense) => expense.Inactive == _this.expenseInactive)
      _this.loadFilterGrid();
      _this.mangoAPISrvc.showLoader(false);
      
    }, error => {
      _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  onStatusChange() {
    this.expenseList = this.tempExpenseList.filter((expense) => expense.Inactive == this.expenseInactive)
  }
}


