import { Component, OnInit } from '@angular/core';
import { MangoApiService } from '@app/_services';
@Component({
  selector: 'app-querybuilder',
  templateUrl: './querybuilder.component.html'
})
export class QuerybuilderComponent implements OnInit {

  public query: any;
  public responseData: any;
  constructor(private mangoAPISrvc: MangoApiService) {

  }

  ngOnInit(): void {

  }


  getData() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.queryTest(this.query).subscribe(response => {
      this.mangoAPISrvc.showLoader(false);
      this.responseData = response[0];
    }, () => {
      this.mangoAPISrvc.showLoader(false);
    })
  }

}
