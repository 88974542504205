import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BreadcrumbService, MangoApiService, EncrDecrService } from "@app/_services";
import { SelectItem } from "primeng/api";
import { AppConstants } from "@app/_helpers/api-constants";
declare let numeral: any;

@Component({
  selector: "app-company-info",
  templateUrl: "./company-info.component.html",
})
export class CompanyInfoComponent implements OnInit {
  company: any = {};
  staffID: any;
  isValidPhoneNumber: boolean = false;
  isInvalidZip: boolean = false;
  public timeZoneDataSet: SelectItem[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    private encrDecSrvc: EncrDecrService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup Wizard" },
      { label: "Company Information", icon: "ic-red" },
    ]);

    this.company.CompanyName = "";
    this.company.CompanyContact = "";
    this.company.Telephone = "";
    this.company.EmailCompany = "";
    this.company.City = "";
    this.company.State = "";
    this.company.Zip = "";
    this.company.Street1 = "";
    this.company.Street2 = "";
    this.company.Country = "";
    this.company.timezone = "";
    this.timeZoneDataSet = [
      { label: "Eastern Time Zone", value: "EST" },
      { label: "Central Time Zone", value: "CST" },
      { label: "Hawaii Standard Time", value: "HST" },
      { label: "Mountain Time Zone", value: "MST" },
      { label: "Pacific Time Zone", value: "PST" },
    ];

    this.staffID = localStorage.getItem("ResourceID");
    this.getCompanyDetails();
  }

  ngOnInit(): void {}

  getCompanyDetails() {
    const self = this;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyViewData().subscribe(
      (data: any) => {
        self.company = data;
        self.company.timezone = data.TimeZone;
        self.isValidPhoneNumber = data.Telephone ? true : false;
        if (!data.EmailCompany) {
          self.initEditableUser(self);
        }
        // console.log(data);
        this.mangoAPISrvc.showLoader(false);
      },
      (data) => {
        self.mangoAPISrvc.notify("error", "Error!", data.message);
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  initEditableUser(self?: any) {
    if (!self) {
      self = this;
    }
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getUserById(self.staffID).subscribe(
      (data: any) => {
        self.company.EmailCompany = data.Email;
        self.company.CompanyContact = data.StaffName;
        self.mangoAPISrvc.showLoader(false);
      },
      (data: any) => {
        self.mangoAPISrvc.showLoader(false);
        self.mangoAPISrvc.notify("error", "Error!", data.message);
      }
    );
  }

  saveCompanyInfo() {
    const klassObj = this;
    this.company.timezone = this.company.timezone
      ? this.company.timezone
      : "EST";
    const obj = {
      companyName: this.company.CompanyName,
      companyContact: this.company.CompanyContact,
      phone: this.company.Telephone,
      email: this.company.EmailCompany,
      city: this.company.City,
      state: this.company.State,
      zip: this.company.Zip,
      street1: this.company.Street1,
      street2: this.company.Street2,
      country: this.company.Country,
      timezone: this.company.timezone,
    };

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateCompanyView(obj).subscribe(
      (data) => {
        klassObj.nextStep();
        this.mangoAPISrvc.showLoader(false);
      },
      (data) => {
        klassObj.mangoAPISrvc.notify("error", "Error!", data);
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }
  /* verifing the phnumber */
  validPhoneNumber() {
    this.isValidPhoneNumber = false;
    const value = numeral(this.company.Telephone).value();
    if (value && value.toString().length == 10) {
      this.isValidPhoneNumber = true;
    }
  }

  updateSetupWizardStatus() {
    const klassObj = this;
    const obj = { setupStep: 2 };
    this.mangoAPISrvc.showLoader(true);
    klassObj.mangoAPISrvc.updateSetupStatus(obj).subscribe(
      (data) => {
        klassObj.encrDecSrvc.addObject(AppConstants.setupStep, "2")
        klassObj.router.navigate(["/setup-wizard/company-settings"]);
        this.mangoAPISrvc.showLoader(false);
      },
      (data) => { this.mangoAPISrvc.showLoader(false); }
    );
  }

  previousStep() {
    this.router.navigate(["/setup-wizard"]);
  }

  nextStep() {
    this.updateSetupWizardStatus();
  }
}
