import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { AppConstants } from '@app/_helpers/api-constants';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html'
})
export class UserLogsComponent implements OnInit {
  public logsData: any = [];
  public filteredItemsSize: any;
  public dateRangeList: SelectItem[];
  public dateFrom: Date;
  public dateTo: Date;
  public dateRange = 'Today';
  isDisplayUserLogDetails = false
  userLogDetails = {
    StaffName: '',
    createdAt: null,
    Action: '',
    Description: '',
  }

  constructor(private router: Router, private _fb: UntypedFormBuilder, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public _translate: TranslateService) {
    const interval = setInterval(() => {
      if (!this._translate.translations[this._translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  ngOnInit(): void {
    this.mangoAPISrvc.showLoader(true);
    /* this.getLogsData(null, null); */
    this.changeDateTypes();
  }

  getLogsData(fromDate, toDate){
    const compID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.mangoAPISrvc.getUserLogs(fromDate, toDate, compID).subscribe((result: any) => {
      this.logsData = result;
      this.mangoAPISrvc.showLoader(false);
    }, err => {
      this.mangoAPISrvc.notify('error', this._translate.instant('error'), AppConstants.updateErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    });
  }

  initTranslations() {
    this.breadcrumbService.setItems([{ label: 'Admin' }, { label: 'User Logs', icon: 'ic-red' }]);
    this.dateRangeList = [
      { label: this._translate.instant('All'), value: null },
      { label: this._translate.instant('reports.Todays_Date'), value: 'Today' },
      { label: this._translate.instant('This-Week'), value: 'Week' },
      { label: this._translate.instant('reports.This_Month'), value: 'Month' }
    ];
  }

  clearSearchFilter() {
    this.filteredItemsSize = -1;
  }

  changeDateTypes() {
    this.mangoAPISrvc.showLoader(true);
    const obj = this.dateRange;
    if (obj == 'Today') {
      this.dateFrom = new Date();
      this.dateTo = new Date();
    } else if (obj == 'Week') {
      this.dateFrom = new Date(moment().startOf('isoWeek').format());
      this.dateTo = new Date(moment().endOf('isoWeek').format());
    } else if (obj == 'lastweek') {
      this.dateFrom = new Date(moment().startOf('isoWeek').subtract(1, 'week').format());
      this.dateTo = new Date(moment().endOf('isoWeek').subtract(1, 'week').format());
    } else if (obj == 'Month') {
      this.dateFrom = new Date(moment().startOf('month').format());
      // this.dateTo = new Date(moment().endOf('month').format());
      this.dateTo = new Date();
    } else if (obj == 'lastmonth') {
      this.dateFrom = new Date(moment().subtract(1, 'months').startOf('month').format());
      this.dateTo = new Date(moment().subtract(1, 'months').endOf('month').format());
    } else if (obj == 'Quarter') {
      this.dateFrom = new Date(moment().startOf('quarter').format());
      this.dateTo = new Date(moment().endOf('quarter').format());
    } else if (obj == 'lastquarter') {
      this.dateFrom = new Date(moment().subtract(1, 'quarters').startOf('quarter').format());
      this.dateTo = new Date(moment().subtract(1, 'quarters').endOf('quarter').format());
    } else if (obj == 'Year') {
      this.dateFrom = new Date(moment().startOf('year').format());
      this.dateTo = new Date(moment().endOf('year').format());
    } else if (obj == 'lastyear') {
      this.dateFrom = new Date(moment().subtract(1, 'years').startOf('year').format());
      this.dateTo = new Date(moment().subtract(1, 'years').endOf('year').format());
    } else if (obj == 'custom') {
      this.dateFrom = new Date();
      this.dateTo = new Date();
    } else {
      this.dateTo = null;
      this.dateFrom = null;
    }

    this.getLogsData(this.dateFrom, this.dateTo);
  }

  onUserLogClick(data) {
    this.userLogDetails = {...data}
    this.isDisplayUserLogDetails = true
  }

}
