<div class="mail">
    <form [formGroup]="mailConfigurationsForm" novalidate>
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{'save_button' | translate}}" [disabled]="!mailConfigurationsForm.dirty"
                icon="fas fa-check" class="p-mr-1 p-button-sm" (click)="saveMailOptions()"></button>
        </span>
        <div class="layout-content p-pt-0">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-mt-2 p-px-3">
                        <div class="p-d-flex p-jc-between">
                            <div class="card p-col-6 p-p-3 p-mb-0 p-mr-2">
                                <h4 class="label-text">{{'settings.Email-Notification-Options' | translate}}</h4>
                                <div class="p-field p-mb-0 p-col-12">
                                    <p-checkbox formControlName="EmailPaymentPortal" [binary]="true"
                                        label="{{'settings.send-thank-customer-portal' | translate}}"></p-checkbox>
                                    <div class="p-col-9 p-pb-0 p-ml-5">
                                        <div class="p-col-12">
                                            <p-checkbox [binary]="true" formControlName="portalFirm"
                                                label="{{'settings.portal-send-firm' | translate}}">
                                             </p-checkbox>
                                        </div>
                                        <div class="p-col-10">
                                            <p-multiSelect  formControlName="PortalUserAssignedID" [(ngModel)]="PortalUserAssignedID" [options]="AllUsers" [disabled]="isPortalUserEnable"
                                            [style]="{minWidth: '100%'}" placeholder="Select User(s) for Portal.." id="UserAssignedID"
                                            name="UserAssignedID" [ngModelOptions]="{standalone: true}"></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-field p-mb-0 p-col-12">
                                    <p-checkbox formControlName="EmailPaymentMango" [binary]="true"
                                        label="{{'settings.send-thank-payment' | translate}}"></p-checkbox>
                                    <div class="p-col-9 p-pb-0 p-ml-5">
                                        <div class="p-col-12">
                                            <p-checkbox [binary]="true" formControlName="mangoFirm"
                                                label="{{'settings.mango-send-firm' | translate}}">
                                            </p-checkbox>
                                        </div>
                                        <div class="p-col-10">
                                            <p-multiSelect formControlName="MangoUserAssignedID" [(ngModel)]="MangoUserAssignedID" [options]="AllUsers"  [disabled]="isMangoUserEnable"
                                            [style]="{minWidth: '100%'}" placeholder="Select User(s) for Mango..." id="MangoUserAssignedID"
                                            name="MangoUserAssignedID" [ngModelOptions]="{standalone: true}"></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="EmailCalendarEvent" [binary]="true"
                                        label="{{'settings.send-calendar-event' | translate}}">
                                    </p-checkbox>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isToDoEmailNotify" [binary]="true"
                                        label="{{'settings.todo-send-email-to-user' | translate}}">
                                    </p-checkbox>
                                </div>
                            </div>
                            <div class="card p-col-6 p-p-3 p-mb-0">
                                <h4 class="label-text p-mb-0">
                                    {{'settings.email_budgets_notification' | translate}}
                                    <i pTooltip="{{ 'settings.email_budgets_notifications_tooltip' | translate }}"
                                        tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle ic-blue p-ml-1"></i>
                                </h4>
                                <div class="p-d-flex p-jc-between p-col-12">
                                    <div class="p-col-12">
                                        <div class="p-col-12">
                                             <label>{{'settings.budgets_revenue_label' | translate}}</label>
                                        </div>
                                        <div class="p-col-7">
                                            <p-multiSelect  formControlName="RevenueBudgetUserAssignedID" [options]="AllUsers"
                                            [style]="{minWidth: '100%'}" placeholder="Select User(s).." id="RevenueBudgetUserAssignedID"
                                            name="RevenueBudgetUserAssignedID"></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-d-flex p-jc-between p-col-12">
                                    <div class="p-col-12">
                                        <div class="p-col-12">
                                            <label>{{'settings.budgets_time_label' | translate}}</label>
                                        </div>
                                        <div class="p-col-7">
                                            <p-multiSelect formControlName="TimeBudgetUserAssignedID" [options]="AllUsers"
                                            [style]="{minWidth: '100%'}" placeholder="Select User(s)..." id="TimeBudgetUserAssignedID"
                                            name="TimeBudgetUserAssignedID"></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>