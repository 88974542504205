<div class="pmsmastertemplate">
    <span class="pull-right p-mr-3 clientform-btn-top">
        <p-button pRipple label="Engagement Letters Dashboard" icon="fal fa-users" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management"></p-button>
        <p-button pRipple label="{{ 'Clients' | translate }}" icon="fal fa-users" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management/clientHistory"></p-button>
        <p-button pRipple label="{{ 'Projects' | translate }}" icon="fal fa-building" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management/projectList"></p-button>
        <p-button pRipple label="{{ 'PM-Settings' | translate }}" icon="fal fa-cog" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management/settings"></p-button>
    </span>
    <div class="layout-content p-p-2">
        <div class="p-grid nested-grid">
            <div class="p-col-7">
                <div class="card p-pt-0">
                    <p-table [value]="libDataSource" [rows]="15" [totalRecords]="libDataSource.length" [pageLinks]="3"
                        [rowsPerPageOptions]="[15,20,25]" [expandedRowKeys]="groupHeaders" (onFilter)="onFilter($event)"
                        [globalFilterFields]="['templateName', 'SearchField']"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [paginator]="libDataSource.length > 15" [filterDelay]="0" dataKey="templateName" #dt>

                        <!-- Search -->
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>

                        <!-- Header -->
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-15p p-text-center"></th>
                                <th class="width-55p p-text-left">
                                    {{ 'Master-Templates' | translate }}
                                </th>
                                <th class="width-10p p-text-center">
                                    # {{ 'of-tasks' | translate }}
                                </th>
                                <th class="width-15p p-text-center">
                                    {{ 'View-Tasks' | translate }}
                                </th>
                            </tr>
                        </ng-template>

                        <!-- Root Row -->
                        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                            <tr class="cursor-hand" [pRowToggler]="rowData">
                                <td colspan="4">
                                    <span>
                                        <i *ngIf="rowData.hasChildrens"
                                            [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right' "></i>
                                        <span class="master-template-name p-ml-2">{{rowData.templateName}}</span>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>

                        <!-- Row Expansion -->
                        <ng-template pTemplate="rowexpansion" let-item let-i="rowIndex">
                            <tr>
                                <td [attr.colspan]="4" *ngIf="item.hasChildrens">
                                    <div class="p-p-2">

                                        <p-table [value]="item.childItems" selectionMode="single"
                                            metaKeySelection="true" dataKey="MasterTemplateHeaderID" #dtchild>
                                            <ng-template pTemplate="body" let-childrowData let-columns="columns">
                            <tr>
                                <th class="width-15p p-text-center">
                                    <button type="button" pTooltip="{{ 'added-to-company-library' | translate }}" pButton icon="fal fa-plus"
                                        (click)="addtoCompanyLibrary(childrowData)" *ngIf="!childrowData.isSaved"
                                        label="{{ 'add' | translate }}"></button>
                                    <button type="button" pTooltip="{{ 'added' | translate }}" pButton icon="fa fa-check" label="Added"
                                        *ngIf="childrowData.isSaved" [disabled]="childrowData.isSaved"
                                        class="ic-white bg-neon-green"></button>
                                </th>
                                <th class="width-55p p-text-left p-text-truncate"
                                    pTooltip="{{childrowData.TemplateName}}" tooltipStyleClass="tooltip-no-wrap"
                                    tooltipPosition="top">
                                    {{childrowData.TemplateName}}
                                </th>
                                <th class="width-10p p-text-center">
                                    <div class="badge-number bg-blue" *ngIf="childrowData.detailcount > 0">
                                        {{childrowData.detailcount}}
                                    </div>
                                </th>
                                <th class="width-15p p-text-center">
                                    <i class="fas fa-file-alt ic-size-18"
                                        [ngClass]="childrowData.detailcount > 0 ? 'ic-blue' : 'ic-grey'"
                                        pTooltip="{{ 'view_project_tasks' | translate }}" aria-hidden="true"
                                        (click)="childrowData.detailcount < 1 ? null : searchDetails(childrowData)"></i>
                                </th>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                </td>
                </tr>
                </ng-template>

                </p-table>
            </div>
        </div>
    </div>
</div>
</div>

<p-dialog [(visible)]="isDisplayDetailsTable" [draggable]="true" modal="modal" (onHide)="libDetailsDataSource = []"
    [style]="{width: '50vw'}" [responsive]="true">
    <p-header>{{ 'project' | translate }} - <strong>{{dialogHeaderText}}</strong> - {{ 'Tasks' | translate }} </p-header>
    <p-table styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
        [value]="libDetailsDataSource" [rows]="10" [totalRecords]="libDetailsDataSource.length" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20]" [paginator]="libDetailsDataSource.length > 10" [paginatorPosition]="'top'"
        #detailtab>

        <!-- Table Header -->
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="width-15p p-text-center">
                    {{ 'task' | translate }} #
                </th>
                <th class="width-85p p-text-center">
                    {{ 'Task-Description' | translate }}
                </th>
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr>
                <td class="p-text-center">
                    {{rowData.TaskRow}}
                </td>
                <td class="p-text-left">
                    {{rowData.TaskDescription}}
                </td>
            </tr>
        </ng-template>

        <!-- No data -->
        <ng-template pTemplate="emptymessage">
            <tr id="emptymessage">
                <td [attr.colspan]="2" class="p-text-center">
                    <i class="fal fa-list-alt p-mt-5"></i>
                    <p class="p-pb-5"><strong>{{ 'No-Project-Tasks' | translate }}.</strong></p>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton label="Close" (click)="isDisplayDetailsTable = false"></button>
    </p-footer>
</p-dialog>