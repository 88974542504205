<div class="billrate">
    <div class="layout-content p-pt-0">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addBRP();"></p-button>
        </span>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mt-2 p-pt-0 p-mx-3">
                    <p-table #dt [value]="brpList" [rowHover]="true" [responsive]="true" [rows]="10"
                        dataKey="RateMasterID" [showCurrentPageReport]="true" rowExpandMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="StaffRateTemplate" [scrollable]="true" [virtualScroll]="true"
                        [scrollHeight]="'calc(100vh - 350px)'"
                        [globalFilterFields]="['InvoiceDate','InvoiceNumber','EngagementName','DescriptionShort','InvoiceBalance','TotalPayments','Discount','InvoiceAmount']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'globalsearch' | translate}}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                </p-message>
                                <span class="p-pt-2 p-d-inline-block pull-right">
                                    <p-checkbox [(ngModel)]="billingRateProfile.showInactive"
                                        label="{{'show_inactive' | translate}}" binary="true"
                                        (onChange)="onStatusChange()">
                                    </p-checkbox>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-2p p-text-left"></th>
                                <th class="width-5p p-text-center">{{'actions' | translate}}</th>
                                <th pSortableColumn="StaffRateTemplate" pResizableColumn class="width-45p p-text-left">
                                    {{'description' | translate}}
                                    <p-sortIcon field="StaffRateTemplate"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-4p p-text-center">{{'inactive' | translate}}</th>
                                <th pResizableColumn class="width-4p p-text-center">{{'add' | translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
                            <tr class="cursor-hand">
                                <td class="width-2p p-text-center">
                                    <span [pRowToggler]="rowData"
                                        *ngIf="rowData.serviceCode && rowData.serviceCode.length > 0">
                                        <i tooltipPosition="top" pTooltip="{{'settings.Show-Rates' | translate}}"
                                            pRipple showDelay="900"
                                            [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                                        </i>
                                    </span>
                                </td>
                                <td class="width-5p">
                                    <div class="p-text-center">
                                        <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editBRP(rowData);" pTooltip="{{'Edit' | translate}}"
                                            tooltipPosition="top"></i>
                                        <i pRipple (click)="deleteGroup(rowData.RateMasterID, rowData.index);"
                                            class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                            pTooltip="{{'mgmt-delete' | translate}}" tooltipPosition="top"></i>
                                    </div>
                                </td>
                                <td class="width-45p p-text-left">
                                    {{rowData.StaffRateTemplate}}
                                </td>
                                <td class="width-4p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.Inactive" binary="true" [disabled]="true"
                                        (onChange)="onBRPListStatusChange(rowData)"></p-checkbox>
                                </td>
                                <td class="width-4p p-text-center">
                                    <i pRipple class="fal fa-plus cursor-hand ic-blue ic-size-17"
                                        (click)="addActivity(rowData);" pTooltip="{{'add_activity' | translate}}"
                                        tooltipPosition="top"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-activity>
                            <tr>
                                <td class="width-2p p-text-center">&nbsp;</td>
                                <td [attr.colspan]="4">
                                    <div class="p-p-2 bg-blue">
                                        <p-table [value]="activity.serviceCode" [rowsPerPageOptions]="[5,10,20]"
                                            [rowHover]="true" sortField="ServiceCode" dataKey="RateDetailID"
                                            [paginator]="activity.serviceCode.length > 10" [sortOrder]="1"
                                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                            #dtchild>
                                            <!-- Table Header -->
                                            <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-5p p-text-center">{{'actions' | translate}}</th>
                                <th pSortableColumn="ServiceCode" pResizableColumn class="width-20p p-text-left">
                                    {{'activity-code' | translate}}
                                    <p-sortIcon field="ServiceCode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Description" pResizableColumn class="width-45p p-text-left">
                                    {{'description' | translate}}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                                <th pSortableColumn="BillingRate" pResizableColumn class="width-8p p-text-right">
                                    {{'time.Billing-Rate' | translate}}
                                    <p-sortIcon field="BillingRate"></p-sortIcon>
                                </th>
                                <th class="width-20p"></th>
                            </tr>
                        </ng-template>
                        <!-- Rows -->
                        <ng-template pTemplate="body" let-childrowData let-columns="columns">
                            <tr>
                                <td>
                                    <div class="p-text-center">
                                        <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editActivity(activity,childrowData);"
                                            pTooltip="{{'Edit' | translate}}" tooltipPosition="top"></i>
                                        <i pRipple (click)="deleteActivities(childrowData);"
                                            class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                            pTooltip="{{'mgmt-delete' | translate}}" tooltipPosition="top"></i>
                                    </div>
                                </td>
                                <td class="p-text-left">
                                    <span>{{childrowData.ServiceCode}}</span>
                                </td>
                                <td class="p-text-left"> {{childrowData.Description}}</td>
                                <td class="p-text-right">{{childrowData.BillingRate}}</td>
                                <td class="p-text-right">&nbsp;</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                </td>
                </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="11">
                            <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                            </p-message>
                        </td>
                    </tr>
                </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<!-- Billing Rate -->
<p-dialog header="Billing Rate" [(visible)]="displayAddBillingRateModal" [modal]="true" [style]="{width: '30vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="billrateMainForm.reset();">
    <form #billrateMainForm="ngForm">
        <div class="card">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="Description" class="p-col-4 required-field label-text">{{'description' |
                        translate}}</label>
                    <div class="p-col-8">
                        <input type="text" pInputText [(ngModel)]="brp.StaffRateTemplate" name="billDescription"
                            #billrateNameVl="ngModel" required minlength="1" maxlength="100" autocomplete="off">
                    </div>
                </div>
                <div class="p-field p-grid" *ngIf="isEditBRP">
                    <div class="p-col-12 p-md-10">
                        <p-checkbox [(ngModel)]="brp.Inactive" name="inactive" id="inactive" #inactive="ngModel" binary="true"
                            label="{{'inactive' | translate}}"
                            (onChange)="billrateMainForm.control.markAsDirty()"></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!billrateMainForm.valid || !billrateMainForm.dirty " (click)="saveBRP(true,true)"
            *ngIf="!isEditBRP" label="{{'save_and_close' | translate}}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!billrateMainForm.valid || !billrateMainForm.dirty " (click)="saveAndNew(isEditBRP)"
            label="{{'save_and_new' | translate}}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!billrateMainForm.valid || !billrateMainForm.dirty" (click)="saveBRP(false,true)"
            *ngIf="isEditBRP" label="{{'update_button' | translate}}" ></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="billrateMainForm.reset();closeBRP()"
            label="{{'cancel_button' | translate}}" ></button>
    </ng-template>
</p-dialog>
<!-- Activity Codes -->
<p-dialog header="Activity Codes" [(visible)]="displayAddActivityCodeModal" [modal]="true" [style]="{width: '40vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="billrateMainnForm.reset();">
    <form #billrateMainnForm="ngForm">
        <div class="card">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="Description" class="p-col-4 label-text">{{'settings.Rate-Profile' | translate}}</label>
                    <div class="p-col-8">
                        <p-message severity="success" text="{{selectedRowData.StaffRateTemplate}}" styleClass="p-mr-2">
                        </p-message>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="Activity Code" class="p-col-4 required-field label-text">{{'activity-code' |
                        translate}}</label>
                    <div class="p-col-8">
                        <p-autoComplete [(ngModel)]="selActivity" name="servicecodeID" [minLength]="1"
                            placeholder="Search Code" #servicecodeID="ngModel" [suggestions]="filteredActivities"
                            (completeMethod)="filterActivities($event)" (onSelect)="handleActivitySelectClick($event)"
                            [baseZIndex]="9999999" [autoZIndex]="true" appendTo="body" [dropdown]="true"
                            field="Description" placeholder="{{'settings.Type-Search-Code' | translate}}" required>
                            <ng-template let-client pTemplate="item">
                                <div>{{client.ServiceCode}} - {{client.Description}}</div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="Activity Code" class="p-col-4 label-text">{{'Bill-Rate' | translate}}</label>
                    <div class="p-col-8">
                        <span class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fal fa-dollar-sign"></i></span>
                            <p-inputNumber inputId="integeronly" [(ngModel)]="activity.BillingRate" name="billingRate"
                                placeholder="0.00" maxlength="10" #billingRate="ngModel" autocomplete="off"
                                mode="decimal" locale="en-US" [minFractionDigits]="2"></p-inputNumber>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!billrateMainnForm.valid || !billrateMainnForm.dirty || activity.ServiceCode == null "
            (click)="saveActivity(true,true)" *ngIf="!isEditActivity" label="{{'save_and_close' | translate}}"
            ></button>
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!billrateMainnForm.valid || !billrateMainnForm.dirty || activity.ServiceCode == null "
            (click)="saveAndNewBL(isEditActivity)" label="{{'save_and_new' | translate}}"
            ></button>
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="!billrateMainnForm.valid || !billrateMainnForm.dirty || activity.ServiceCode == null"
            (click)="saveActivity(false,true)" *ngIf="isEditActivity" label="{{'update_button' | translate}}"
            ></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="billrateMainnForm.reset();closeActivity()"
            label="{{'cancel_button' | translate}}" ></button>
    </ng-template>
</p-dialog>
</div>