<p-dialog
  contentStyleClass="notes-dialog"

  header="{{ 'notes' | translate }}"

  [(visible)]="displayAddNotesModal"

  (onHide)="closeNotesDialog(false)"

  showEffect="fade"

  [modal]="true"
  [style]="{width: '80vw'}"
  [maximizable]="false"
  [baseZIndex]="1"
  [draggable]="true"
  [resizable]="false"
>
  <form
    #addNotesForm="ngForm"
    autocomplete="nope"
  >
    <div
      class="p-fluid p-formgrid p-grid p-m-2 common-dialog"
    >
      <div
        class="p-field p-col-12 p-md-6"
      >
        <label
          for="date"
          class="required-field label-text"
        >
          {{ 'date' | translate }}
        </label>
        <p-calendar
          [(ngModel)]="defaultNotesObj.Ddate"
          [monthNavigator]="true"
          [baseZIndex]="9999999"
          [yearNavigator]="true"
          [autoZIndex]="true"
          yearRange="2000:2090"
          showButtonBar="true"
          appendTo="body"
          required
        >
        </p-calendar>
      </div>

      <div
        class="p-field p-col-12 p-md-6"
      >
        <label
          for="subject"
          class="required-field label-text"
        >
          {{ 'Subject' | translate }}
        </label>
        <button
          class="p-button-rounded p-button-danger p-button-text pull-right height-button"

          pButton
          pRipple

          (click)="showFiles()"

          tooltipPosition="top"
          pTooltip="Attach Files to this Note"
          tooltipStyleClass="tooltip-no-wrap"
          type="button"
          icon="fal fa-cloud-upload"
        >
        </button>
        <input
          type="text"
          pInputText
          [(ngModel)]="defaultNotesObj.Subject"
          name="subject"
          #subjectVal="ngModel"
          maxlength="40"
          autocomplete="nope"
          autocomplete="off"
          required
        >
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="fileNames.length > 0"
      >
          <label
            for="Attachments"
            class="p-col-2 label-text"
          >
            {{ 'Attachments' | translate }}
          </label>
          <span
            class="ic-blue p-mx-1"
            *ngFor="let fileObj of fileNames"
          >
            {{fileObj['FName']}}
            <button
              pButton
              pRipple
              (click)="deleteFile(fileObj)"
              tooltipPosition="top"
              pTooltip="Remove file"
              type="button"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
            >
            </button>
          </span>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="showAttachedFiles"
      >
        <label
          for="address"
          class="label-text"
        >
          {{ 'Upload' | translate }}
        </label>
        <div class="card flex justify-content-center" style="width: 100%;">
          <p-fileUpload name="fileupload" #fileUpload [disabled]="!form.valid" [auto]="true" chooseLabel="Upload" (onSelect)="onSelect($event)"
            [multiple]="true" accept="text/csv" maxFileSize="3145728">
            <ng-template pTemplate="content">
              <div class="p p-d-flex p-jc-center" *ngIf="files.length == 0" style="height: 40px;">
                Drag file(s) here
              </div>
              <ul *ngIf="files.length > 0">
                <li *ngFor="let file of files">
                  {{ file.name }} - {{ file.size }} bytes
                </li>
              </ul>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>

      <div
        class="p-field p-col-12 p-md-6"
      >
        <label
          for="state"
          class="required-field label-text"
        >
          {{ 'client' | translate }}
        </label>
        <p-autoComplete
          [(ngModel)]="selClient"
          autocomplete="off"
          [suggestions]="filteredClients"
          (completeMethod)="filterClients($event)"
          field="ClientName"
          (onSelect)="handleSelectClick($event.value)"
          [dropdown]="true"
          ngDefaultControl
          name="clientOpt"
          #clientOpt="ngModel"
          placeholder="{{ 'type_client_name' | translate }}"
          required
        >
          <ng-template
            let-client
            pTemplate="item"
          >
            <div>
              {{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div
        class="p-field p-col-12 p-md-6 p-pt-2"
      >
        <label
          for="state"
          class="label-text p-mb-1 p-pb-2"
        >
          {{ 'engagement' | translate }}
        </label>
        <button
          pButton
          pRipple
          (click)="addEngagementPopUp()"
          [disabled]="selClient==null"
          tooltipPosition="top"
          pTooltip="{{ 'add_engagement' | translate }}"
          tooltipStyleClass="tooltip-no-wrap"
          type="button"
          icon="fal fa-plus-circle"
          class="p-button-rounded p-button-danger p-button-text pull-right height-button"
        >
        </button>
        <p-dropdown
          [options]="projectsList"
          [(ngModel)]="defaultNotesObj.ProjectMasterID"
          panelStyleClass="dialog-dropdown"
          placeholder="Select Engagement"
          name="itemProjectSelected"
          id="itemProjectSelected"
          #itemProjectSelected="ngModel"
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          [disabled]="selClient==null"
          [baseZIndex]="9999999"
          [autoZIndex]="true"
          appendTo="body"
          [readonly]="!isProjectEnable"
        >
        </p-dropdown>
      </div>

      <div
        class="p-field p-col-12 p-md-6"
      >
        <label
          for="state"
          class="required-field label-text"
        >
          {{'Category' | translate }}
        </label>
        <p-dropdown
          [options]="noteCategories"
          [(ngModel)]="defaultNotesObj.NoteCategoryID"
          placeholder="Select Category"
          name="itemSelected"
          id="itemSelected"
          #itemSelectedVal="ngModel"
          [baseZIndex]="9999999"
          [autoZIndex]="true"
          appendTo="body"
          required
        >
        </p-dropdown>
      </div>

      <div
        class="p-field p-col-12 p-md-6"
      >
          <label
            for="state"
            class="required-field label-text"
          >
            {{ 'Originator' | translate }}
          </label>
          <p-dropdown
            [options]="originatingPartner"
            [(ngModel)]="defaultNotesObj.StaffID"
            placeholder="Select Originator"
            name="originator"
            id="originator"
            #originatorVal="ngModel"
            [baseZIndex]="9999999"
            [autoZIndex]="true"
            appendTo="body"
            required
            >
          </p-dropdown>
      </div>

      <div
        class="p-field p-col-12 richtext"
      >
          <label
            for="notesEditor"
            class="label-text"
          >
            {{ 'memo' | translate }}
          </label>
          <ejs-richtexteditor
            #notesEditor
            id="notesEditor"
            [(value)]="defaultNotesObj.Description"
            [insertImageSettings]='imageSettings'
            (change)="editorChange($event)"
            [height]="400"
            [toolbarSettings]="tools"
            [saveInterval]="500"
            [autoSaveOnIdle]="true"
            [enableResize]="false"
          >
          </ejs-richtexteditor>
      </div>
    </div>
  </form>

  <ng-template
    pTemplate="footer"
  >
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      (click)="createOrUpdateNotes(true,false)"
      label="{{ 'create' | translate }}"
      *ngIf="!isEditNote"
      [disabled]="!isFormValid"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      (click)="createOrUpdateNotes(false,false)"
      label="{{ 'update_button' | translate }}"
      *ngIf="isEditNote"
      [disabled]="!isFormValid"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      (click)="createOrUpdateNotes(!isEditNote,true)"
      label="{{ 'save_and_new' | translate }}"
      [disabled]="!isFormValid"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      (click)="closeNotesDialog(false)"
      label="{{ 'cancel_button' | translate }}"
    >
    </button>
  </ng-template>
</p-dialog>
