<div class="client-contacts">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addContact();"></p-button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="contacts" [rowHover]="true" [responsive]="true" [rows]="10" [columns]="selectedColumns"
                        dataKey="CustomerContactID" selectionMode="single" [pageLinks]="3" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="ContactName" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'"
                        [globalFilterFields]="globalFilterColumns" [resizableColumns]="true"
                        (onFilter)="onFilter($event)" [sortOrder]="1">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{ 'globalsearch' | translate }}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{ 'clear' | translate }}"></button>
                                        </span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                    </div>
                                    <div class="p-col-6 p-text-right">
                                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                        selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                                        placeholder="Choose Columns"></p-multiSelect>
                                    </div>
                                </div>
                               
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                                <th pSortableColumn="ContactName" class="width-15p p-text-left">
                                    {{'client.contact_name' | translate }}
                                    <p-sortIcon field="ContactName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Company" class="width-15p p-text-left">
                                    {{'company' | translate }}
                                    <p-sortIcon field="Company"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left">{{ 'work' | translate }}</th>
                                <th class="width-10p p-text-left">{{ 'Fax-Number' | translate }}</th>
                                <th class="width-7p p-text-left">{{ 'mobile' | translate }}</th>
                                <th class="width-10p p-text-left">{{ 'email' | translate }}</th>
                                <th class="width-6p p-text-center" pTooltip="{{'email_statements' | translate}}" tooltipPosition="top">
                                    {{ 'Stmt' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-6p p-text-center" pTooltip="{{'email_invoices' | translate}}" tooltipPosition="top">
                                    {{ 'Inv' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-6p p-text-center" pTooltip="{{'access_client_portal' | translate}}" tooltipPosition="top">
                                    {{ 'Portal' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-6p p-text-center">
                                    Valid Email
                                </th>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="{{col.class}}">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                </th>
                                <th class="width-4p p-text-center"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-expanded="expanded" let-columns="columns">
                            <tr class="cursor-hand" [ngClass]="rowData['isDefaultContact']? 'ic-green' : ''">
                                <td class="width-6p">
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                                            (click)="editContact(rowData)" tooltipPosition="top" pTooltip="{{ 'Edit' | translate}}"></i>
                                        <i (click)="deleteContact(rowData.CustomerContactID, rowData.Email)"
                                            class="fal fa-trash-alt ic-red p-pl-2 cursor-hand" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                    </div>
                                </td>
                                <td (click)="editContact(rowData)"
                                    class="width-15p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    pTooltip="{{rowData.ContactName}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.ContactName}}
                                </td>
                                <td (click)="editContact(rowData)"
                                    class="width-15p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    pTooltip="{{rowData.Company}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.Company}}
                                </td>
                                <td (click)="editContact(rowData)" class="width-10p p-text-left">
                                    {{rowData.Office}}
                                </td>
                                <td (click)="editContact(rowData)" class="width-10p p-text-left">
                                    {{rowData.Fax}}
                                </td>
                                <td (click)="editContact(rowData)" class="width-7p p-text-left">
                                    {{rowData.Mobile}}
                                </td>
                                <td (click)="editContact(rowData)" class="width-10p p-text-left"
                                    pTooltip="{{rowData.Email}}" tooltipPosition="top" tooltipStyleClass="tooltip-width-800">
                                    {{rowData.Email}}
                                </td>
                                <td (click)="editContact(rowData)" class="width-6p p-text-center">
                                    <i *ngIf="rowData.isEmailStatements" class="pi pi-check ic-green"></i>
                                </td>
                                <td (click)="editContact(rowData)" class="width-6p p-text-center">
                                    <i *ngIf="rowData.isEmailInvoices" class="pi pi-check ic-green"></i>
                                </td>
                                <td (click)="editContact(rowData)" class="width-6p p-text-center">
                                    <i *ngIf="rowData.isClientPortalAccess" class="pi pi-check ic-green"></i>
                                </td>
                                <td (click)="editContact(rowData)" class="width-6p p-text-center">
                                    <i *ngIf="rowData.isValidEmailAddress" class="pi pi-check ic-green"></i>
                                    <i *ngIf="!rowData.isValidEmailAddress" class="pi pi-times ic-red"
                                        pTooltip="{{'contacts.valid_email_tooltip' | translate}}" tooltipPosition="top"></i>
                                </td>
                                <td *ngFor="let col of columns" class="{{col.class}} p-text-left p-text-truncate"
                                    pTooltip="{{rowData[col.field]}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                    (click)="editContact(rowData);" tooltipPosition="top">
                                    {{rowData[col.field]}}
                                </td>
                                <td class="p-text-center width-4p">
                                    <i class="far fa-envelope ic-red " (click)="sendEmailToContact(rowData);"
                                        tooltipPosition="top" pTooltip="{{ 'email_contact' | translate }}"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>