import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { runInThisContext } from 'vm';
import { AppMainComponent } from './app.main.component';
import { AppConstants } from './_helpers/api-constants';
import { EncrDecrService, MangoApiService } from './_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  model: any[];
  isStaxEnrollmentStarted: any = {};
  paymentsRouteUrl: any = '';
  companyId: any = null;
  staffId: any = null;
  ddmSettings: any;
  showCapacityPlanning: boolean = false;
  isCompanyIDEnabled: boolean = false;
  subscriptionLevel: string = '';
  isCapacityPlanningAllowed: boolean = false;

  constructor(
    public translate: TranslateService,
    private router: Router,
    public app: AppMainComponent,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.isStaxEnrollmentStarted = this.encrDecSrvc.getObject(AppConstants.isStaxEnrollmentStarted);
    this.paymentsRouteUrl =
      this.isStaxEnrollmentStarted == true ? '/accounting/cards-view' : '/accounting/merchantSetup';
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
  }

  /**
   * observable to refresh the menu capacity planning.
   */
  dataChange$ = this.mangoAPISrvc.capacityPlanningMenu$.subscribe(async isEnabled => {
    this.showCapacityPlanning = isEnabled;
    this.configureMenu();
  });

  async ngOnInit() {
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.configureMenu();
    });
    this.translate.onLangChange.subscribe(data => this.configureMenu());
    this.capacityPlanningCheck();
    this.showCapacityPlanning = await this.capacityPlanningCheck();
    this.fetchDdmSettings();
  }

  fetchDdmSettings() {
    this.mangoAPISrvc.fetchDDMSettings(this.companyId).subscribe((data: any) => {
      if (data.length > 0) {
        this.showCapacityPlanning = data[0].isCapacityPlanningEnabled;
        this.ddmSettings = data[0];
        //enable/disable from db companymango CapacityPlanningEnabled field
        this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe((companyData: any) => {
          this.isCapacityPlanningAllowed = Boolean(companyData[0].CapacityPlanningEnabled);
          this.configureMenu();
        });
      }
    });
  }

  async capacityPlanningCheck() {
    const response = await this.mangoAPISrvc.showCapacityPlanningMenu(this.companyId);
    let enabled = response.toString().length > 0 ? response[0].show : false;
    this.mangoAPISrvc.showCapacityPlanningMenuChange(enabled);

    AppConstants.superAdminVisible.forEach(e => {
      if (e == this.companyId) this.isCompanyIDEnabled = true;
    });

    return enabled;
  }

  configureMenu() {
    let parent = this;
    let subscription = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    let invoiceItems = [
      {
        label: parent.translate.instant('billing-invoicing.manual-invoice'),
        icon: 'fal fa-file-invoice-dollar',
        routerLink: ['billing-invoicing/manualInvoice']
      },
      {
        label: parent.translate.instant('recurring-invoice'),
        icon: 'fal fa-redo',
        routerLink: ['billing-invoicing/recurring-invoice']
      },
      {
        label: parent.translate.instant('batch-invoicing'),
        icon: 'fal fa-file-spreadsheet',
        routerLink: ['billing-invoicing/batchBilling']
      },
      {
        label: parent.translate.instant('retainer-invoice'),
        icon: 'fal fa-hand-holding-usd',
        routerLink: ['billing-invoicing/retainerInvoice']
      },
      {
        label: parent.translate.instant('billing-invoicing.review-invoices'),
        icon: 'fal fa-file-check',
        routerLink: ['billing-invoicing/invoiceReview']
      },
      {
        label: parent.translate.instant('apply-slips'),
        icon: 'fal fa-file-medical',
        routerLink: ['billing-invoicing/applyTimeToInvoice']
      }
    ];

    if (subscription.toUpperCase() == 'PM') {
      this.model = [
        {
          label: parent.translate.instant('Clients'),
          icon: 'fal fa-user-friends',
          command: event => {
            parent.handleClient(event);
          }
        },
        {
          label: parent.translate.instant('Project-Management'),
          icon: 'fal fa-tasks',
          routerLink: [AppConstants.projectManagementRoutePath]
        }
      ];
    } else {
      this.model = [
        {
          label: parent.translate.instant('dashboard'),
          icon: 'fal fa-home-lg-alt',
          routerLink: [AppConstants.dashBoardRoutePath],
          items: [
            {
              label: 'Home',
              icon: 'fal fa-home-lg-alt',
              routerLink: [AppConstants.dashBoardRoutePath]
            }
            /*{
              label: 'Inbox',
              icon: 'fal fa-envelope',
              routerLink: [AppConstants.dashBoardInboxRoutePath]
            }*/
          ]
        },
        {
          label: parent.translate.instant('Clients'),
          icon: 'fal fa-user-friends',
          command: event => {
            parent.handleClient(event);
          }
        },
        {
          label: parent.translate.instant('Engagements'),
          icon: 'fal fa-project-diagram',
          items: [
            {
              label: parent.translate.instant('engagement.list'),
              icon: 'fal fa-list',
              command: event => {
                parent.handleEngagement(event);
              }
            },
            {
              label: parent.translate.instant('engagement_letters'),
              icon: 'fal fa-envelope',
              routerLink: [
                `${AppConstants.engagementsRoutePath}/${AppConstants.lettersRoutePath}/${AppConstants.dashboard}`
              ]
            }
          ]
        },
        {
          label: parent.translate.instant('Time_&_Expense'),
          icon: 'fal fa-stopwatch',
          routerLink: [AppConstants.timeExpenseRoutePath],
          items: [
            {
              label: parent.translate.instant('time-sheet-entry'),
              icon: 'fal fa-user-clock',
              routerLink: ['/time-expense/list']
            },
            {
              label: parent.translate.instant('time-card-entry'),
              icon: 'fal fa-calendar-week',
              routerLink: ['/time-expense/time-card']
            },
            {
              label: parent.translate.instant('batch-time-entry'),
              icon: 'fal fa-file-spreadsheet',
              routerLink: ['/time-expense/batch-time-card']
            },
            {
              label: parent.translate.instant('Expense-Manager'),
              icon: 'fal fa-receipt',
              routerLink: ['/time-expense/expense-manager']
            }
          ]
        },
        {
          label: parent.translate.instant('Billing-&-Invoicing'),
          icon: 'fal fa-badge-dollar',
          routerLink: [AppConstants.billinginvoicingRoutePath],
          items: invoiceItems
        },
        {
          label: parent.translate.instant('accounting'),
          icon: 'fal fa-calculator-alt',
          routerLink: [AppConstants.accountingRoutePath],
          items: [
            {
              label: parent.translate.instant('Accounts.cash-receipts'),
              icon: 'fal fa-landmark',
              routerLink: ['/accounting/receipts']
            },
            {
              label: parent.translate.instant('Accounts.make-payment'),
              icon: 'fal fa-money-check-alt',
              routerLink: ['/accounting/deposits']
            },
            {
              label: parent.translate.instant('accounts.edit-deposit'),
              icon: 'fal fa-money-check-edit-alt',
              routerLink: [
                `/${AppConstants.accountingRoutePath}/${AppConstants.editDepositRoutePath}`
              ]
            },
            {
              label: parent.translate.instant('electronic-payments'),
              icon: 'fal fa-credit-card',
              routerLink: [this.paymentsRouteUrl]
            }
          ]
        },
        {
          label: parent.translate.instant('Scheduling'),
          icon: 'fal fa-calendar-alt',
          routerLink: [AppConstants.schedulingRoutePath]
        },
        {
          label: parent.translate.instant('Reports'),
          icon: 'fal fa-file-chart-line',
          routerLink: [AppConstants.reportsRoutePath]
        },
        {
          label: parent.translate.instant('Project-Management'),
          icon: 'fal fa-tasks',
          items: /*this.showCapacityPlanning &&*/ this.isCapacityPlanningAllowed
            ? [
                {
                  label: parent.translate.instant('dashboard'),
                  icon: 'far fa-tachometer-average',
                  routerLink: [AppConstants.projectManagementRoutePath]
                },
                {
                  label: parent.translate.instant('capacity-planning'),
                  icon: 'fal fa-head-side-brain',
                  command: (event: any) => {
                    if (
                      this.subscriptionLevel == 'ENTERPRISE' ||
                      this.subscriptionLevel == 'FREE' ||
                      this.subscriptionLevel == ''
                    ) {
                      if (this.showCapacityPlanning) {
                        this.router.navigate([AppConstants.capacityPlanningRoutePath]);
                      } else {
                        //ENABLE CAPACITY-PLANNING Y/N
                        Swal.fire({
                          title: 'Confirmation',
                          text: this.translate.instant('enable-capacity-planning-msg'),
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: this.translate.instant('Yes'),
                          cancelButtonText: this.translate.instant('No')
                        }).then(async result => {
                          if (result.isConfirmed) {
                            let isCapacityPlanningEnabled: any = {
                              ddmSettingsID: this.ddmSettings.ddmSettingsID,
                              isCapacityPlanningEnabled: true
                            };
                            let isBudgetingProjectLevel: any = {
                              ddmSettingsID: this.ddmSettings.ddmSettingsID,
                              isBudgetingProjectLevel: true
                            };
                            let isTaskBudgetEnabled: any = {
                              ddmSettingsID: this.ddmSettings.ddmSettingsID,
                              isTaskBudgetEnabled: true
                            };
                            this.mangoAPISrvc
                              .updateDDMSettings(isCapacityPlanningEnabled)
                              .subscribe((data: any) => {
                                this.mangoAPISrvc.notify('success', 'Success!', data['message']);
                              });
                            this.mangoAPISrvc
                              .updateDDMSettings(isBudgetingProjectLevel)
                              .subscribe((data: any) => {
                                this.mangoAPISrvc.notify('success', 'Success!', data['message']);
                              });
                            this.mangoAPISrvc
                              .updateDDMSettings(isTaskBudgetEnabled)
                              .subscribe((data: any) => {
                                this.mangoAPISrvc.notify('success', 'Success!', data['message']);
                              });
                            this.mangoAPISrvc.showCapacityPlanningMenuChange(true);
                            setTimeout(() => {
                              this.router.navigate([AppConstants.capacityPlanningRoutePath]);
                            }, 1000);
                          }
                        });
                      }
                    } else {
                      //WARNING ONLY FREE OR ENTERPRISE
                      Swal.fire({
                        icon: 'warning',
                        title: `${parent.translate.instant('warning')}!`,
                        text: this.translate.instant('warning-only-free-or-enterprise-message'),
                        showConfirmButton: true
                      });
                    }
                  }
                }
              ]
            : [
                {
                  label: parent.translate.instant('dashboard'),
                  icon: 'far fa-tachometer-average',
                  routerLink: [AppConstants.projectManagementRoutePath]
                }
              ]
        },
        {
          label: parent.translate.instant('Document-Management'),
          icon: 'fal fa-folders',
          command: event => {
            parent.handleDMS(event);
          }
        }
      ];
    }
  }

  onMenuClick() {
    this.app.menuClick = true;
  }

  handleClient(event) {
    this.encrDecSrvc.addObject(AppConstants.clientID, '');
    this.mangoAPISrvc.fireClientView(true);
    this.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
  }

  handleEngagement(event) {
    this.encrDecSrvc.addObject(AppConstants.projectID, '');
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }

  handleDMS(event) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getIsDMSIshareValue().subscribe((result: any) => {
      if (result?.data?.isDMSIShare) {
        const subscriptionLevel = localStorage.getItem('plmkioukl');
        if (
          subscriptionLevel?.trim().toUpperCase() === 'ENTERPRISE' ||
          subscriptionLevel?.trim().toUpperCase() === 'PRO'
        ) {
          this.mangoAPISrvc.getIShareUserApiToken().subscribe(
            (result: any) => {
              this.mangoAPISrvc.showLoader(false);
              const url = `${environment.BASE_IMAGINETIME}/firm/${result.data?.firmID}/workspaces?userapitoken=${result.data?.userapitoken}`;
              window.open(url, '_blank').focus();
            },
            error => {
              this.mangoAPISrvc.showLoader(false);
              Swal.fire({
                icon: 'warning',
                title: `${this.translate.instant('warning')}!`,
                text: this.translate.instant('contact_support'),
                showConfirmButton: false,
                timer: 3000
              });
            }
          );
        } else {
          Swal.fire({
            icon: 'warning',
            title: `${this.translate.instant('warning')}!`,
            html: '<div>This feature is only available for <strong>PRO</strong> plan.</div>',
            showConfirmButton: true
          });
          this.mangoAPISrvc.showLoader(false);
        }
      } else this.router.navigate([AppConstants.dmsRoutePath + '/' + AppConstants.dmsUIMain]);
    });
  }
}
