import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import { DocumentManagementRoutingModule } from './document-management-routing.module';
import { DmsMainComponent } from './dms-main/dms-main.component';
import { dmsComponent } from './document-management-system.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ContextMenuModule ,ToolbarModule  } from '@syncfusion/ej2-angular-navigations';
import { DmsUiComponent } from './dms-ui/dms-ui.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { SettingsModule } from '../settings/settings.module';

@NgModule({
  declarations: [DmsMainComponent, dmsComponent, DmsUiComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    DocumentManagementRoutingModule,
    DialogModule,
    TreeGridModule,
    InputSwitchModule,
    ContextMenuModule ,ToolbarModule,
    AppTranslationModule,
    FileManagerAllModule,
    SettingsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DocumentManagementModule { }
