import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { Router } from '@angular/router';
import {
  AuthGuard,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { SharedComponentsService } from '../shared-components-service';
declare let numeral: any;

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html'
})
export class ActivityComponent implements OnInit {
  displayAddActivityModal: boolean = false;
  displayDeleteActivityModal: boolean = false;
  activity: any = {};
  activityList: any = [];
  @Output() closeActivityModal = new EventEmitter<any>();
  isEditActivity: boolean = false;
  timeExpObject: any = {};
  selectedServiceCode = "";
  activityRateTemplates = [];
  isActivityChanged = false;
  @ViewChild('ActivitiesMainForm') ActivitiesMainForm;

  constructor(
    private translate :TranslateService,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    public sharedSrvc: SharedComponentsService,
    private encrDecSrvc: EncrDecrService,
    private authGuard: AuthGuard,
  ) { }

  ngOnInit(): void {
    this.getRateTemplates();
  }

  getRateTemplates(){ 
    this.mangoAPISrvc.showLoader(true);
    const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.mangoAPISrvc.getRateTemplateNames(companyId)
      .subscribe((result: any)=>{ 
        this.mangoAPISrvc.showLoader(false);
        this.activityRateTemplates = 
          result.filter(item=> item['TemplateType'] == 2).map(item=>{
            return {
              label: item['TemplateName'],
              value: item['RateTemplateNamesID']
            }
          })
      })
  }

  onChangeInputCode() {
    this.activity.ServiceCode = this.activity.ServiceCode.toUpperCase();
  }

  addActivity(data) { 
    this.activity = {};
    this.isEditActivity = data.isEditFlow;
    if(this.isEditActivity) {
      if(!this.authGuard.isAllowAccess(44)) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          showConfirmButton: true,
          text: 'Your permission level does not allow access to edit an activity.'
        });
        return
      }
    } else {
      if(!this.authGuard.isAllowAccess(40)) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          showConfirmButton: true,
          text: 'Your permission level does not allow access to create an activity.'
        });
        return
      }
    }
    this.activity = data.activity;
    this.displayAddActivityModal = true;
  }

  closeActivity(isSave?: any, doNotRefresh?) {
    const url = this.router.url;
    const urls = ['/settings/activities'] 
    if ((this.ActivitiesMainForm.valid || this.ActivitiesMainForm.dirty) && (urls.includes(url))) {
      this.sharedSrvc.setTimeEntryDialogData(true);
    }

    if (!doNotRefresh)
      this.closeActivityModal.emit(true);

    this.activity = {};
    this.displayAddActivityModal = false;

    this.ActivitiesMainForm.form.markAsPristine();
    this.ActivitiesMainForm.form.markAsUntouched();
    this.ActivitiesMainForm.form.updateValueAndValidity();
    this.sharedSrvc.sharedCompVisibility.activity = false;
  }

  validateSC(obj) {
    const result = this.activityList.filter(
      (item) =>
        item.ServiceCode.toString().toUpperCase() ==
        obj.ServiceCode.toString().toUpperCase()
    );
    if (result.length > 0) {
      if (
        this.selectedServiceCode == obj.ServiceCode.toString().toUpperCase()
      ) {
        return false;
      }
      obj.ServiceCode = null;
      Swal.fire({
        icon: "warning",
        title: this.translate.instant('warning'),
        text: this.translate.instant('duplicate_activity_not_allowed'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  transformbillrate(event) {
    const value = numeral(this.activity.BillRate).value();
    if (value && value > 0) {
      event.target.value = numeral(value).format("0,0.00");
    } else {
      event.target.value = 0.0;
      this.activity.BillRate = null;
    }
  }

  copyToWorkMemo() {
    this.activity.DefaultMemo = this.activity.Description;
    this.ActivitiesMainForm.form.markAsTouched();
    this.ActivitiesMainForm.form.markAsDirty();
   
  }

  saveAndNew(IsEdit) {
    this.saveActivity(!IsEdit, false);
  }

  reload() {
    const _this = this;
    this.mangoAPISrvc.getActivities().subscribe(function (data: any) {
      _this.mangoAPISrvc.showLoader(false);
      _this.activityList = data;
      _this.encrDecSrvc.addObject(AppConstants.activityList, data);
      _this.sharedSrvc.activityDialogData.next(true)
      //todo
      // _this.tableDataSorce = data.filter(
      //   (expense) => expense.Inactive == false
      // );
    });
  }

  saveActivity(isSave, isClose) {
    const _this = this;
    _this.activity.ServiceCode = _this.activity.ServiceCode.trim().toUpperCase();
    _this.activity.BillRate = _this.activity.BillRate
      ? _this.activity.BillRate.toString()
      : "0.00";
    if (typeof _this.activity.Tax1 == "object") {
      _this.activity.Tax2 = false;
      _this.activity.Tax1 = true;
    }
    if (typeof _this.activity.Tax2 == "object") {
      _this.activity.Tax1 = false;
      _this.activity.Tax2 = true;
    }
    _this.mangoAPISrvc.showLoader(true);
    if (isSave || _this.activity["ServiceCodeID"] === null) {
      delete _this.activity["ServiceCodeID"];
      _this.mangoAPISrvc.addActivity(_this.activity).subscribe(
        function (data: any) {

          const logsData = {}
          logsData['Action'] = "Settings - Activities";
          logsData['Description'] = data.data.ServiceCode + " ##" + data.data.ServiceCodeID;
          logsData['Table'] = "CompanyMango";
          _this.mangoAPISrvc.addUserLogs(logsData).subscribe(res=>{}, err=>{});

          _this.reload();
          _this.mangoAPISrvc.notify(
            "success",
            _this.translate.instant('Success'),
            AppConstants.createMsg
          );
        },
        (error) => {
          _this.mangoAPISrvc.showLoader(false);
          _this.mangoAPISrvc.notify(
            "error",
            _this.translate.instant('error'),
            AppConstants.createErrorMsg
          );
        }
      );
    } else {
      _this.mangoAPISrvc
        .updateActivity(_this.activity.ServiceCodeID, _this.activity)
        .subscribe(
          function (data) {
            _this.reload();
            _this.mangoAPISrvc.notify(
              "success",
              _this.translate.instant('Success'),
              AppConstants.updateMsg
            );
          },
          (error) => {
            _this.mangoAPISrvc.showLoader(false);
            _this.mangoAPISrvc.notify(
              "error",
              _this.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
          }
        );
    }
    if (isClose) {
      _this.displayAddActivityModal = false;
      this.sharedSrvc.sharedCompVisibility.activity = false;
    } else {
      _this.isEditActivity = false;
      _this.activity = {
        ServiceCodeID: null,
        ServiceCode: "",
        Description: "",
        Tax1: false,
        Tax2: false,
        Billable: true,
        BillRate: "0",
        Inactive: false,
        DefaultMemo: "",
      };
    }
  }

}
