<form #engagementMainForm="ngForm" autocomplete="nope">
  <!-- buttons -->
  <span class="pull-right p-mr-3 clientform-btn-top">
      <button pButton pRipple label="{{ 'save_button' | translate }}" icon="fas fa-check" class="p-mr-1 p-button-sm"
          (click)="saveEngagement()" [disabled]="!engagementMainForm.valid || !engagementMainForm.dirty "></button>
      <button pButton pRipple label="{{ 'list' | translate }}" icon="fal fa-project-diagram"
          class="p-mr-1 p-button-sm" (click)="redirectListView()"></button>
      <button pButton pRipple icon="fal fa-poll-h" tooltipPosition="top" pTooltip="{{ 'scratch_pad' | translate }}"
          class="p-mr-1 p-button-sm" (click)="scratchPadEnabled = !scratchPadEnabled"></button>
  </span>
  <!-- form -->
  <div class="p-fluid p-formgrid p-grid p-mx-2 common-dialog">
      <div class="p-col-4">
          <div class="card p-mt-2 engagementview-pb">
              <div class="p-field p-col-12">
                  <label class="required-field label-text">{{ 'engagement.type' | translate }}</label>
                  <p-dropdown [options]="engagementList" [(ngModel)]="engagementObj.EngagementTypeID"
                      name="EngagementTypeID" id="EngagementTypeID" #EngagementTypeID="ngModel" [ngModelOptions]="{standalone: true}"
                      [disabled]="true" required></p-dropdown>
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text required-field">{{ 'engagement.name' | translate }}</label>
                  <input type="text" pInputText autocomplete="nope" name="engagementName"
                      [(ngModel)]="engagementObj.EngagementName" #engagementName="ngModel"
                      (focus)="$event.target.select()" (ngModelChange)="formChanged()" required />
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'client.name' | translate }}</label>
                  <a class="ic-size-18 client-link pull-right" routerLink="{{clientRouteUrl}}">
                      {{selectedClientName}}
                  </a>
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'client.client_contact' | translate }}</label>
                  <p-dropdown [options]="custmorContacts" [(ngModel)]="engagementObj.CustomerContactID"
                      name="clientContact" (focus)="$event.target.select()" (ngModelChange)="formChanged()">
                  </p-dropdown>
              </div>
              <div class="p-formgroup-inline">
                  <div class="p-field p-col-12">
                      <label class="label-text">{{ 'client.email' | translate }}</label>
                      <input type="text" pInputText name="clientEmail" autocomplete="nope"
                          [(ngModel)]="engagementObj.ContactEmail" #clientEmail="ngModel"
                          (focus)="$event.target.select()" (blur)="isValidEmail(engagementObj.ContactEmail)"
                          (ngModelChange)="formChanged()" />
                  </div>
              </div>
              <!--  end of card -->
          </div>
      </div>
      <div class="p-col-4">
          <div class="card p-mt-2 engagementview-pb">
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'engagement.billing_method' | translate }}</label>
                  <p-dropdown [options]="billingRates" [(ngModel)]="engagementObj.BillingMethod" name="billingMethod"
                      (ngModelChange)="formChanged()"></p-dropdown>
              </div>
              <div class="p-field p-col-12" *ngIf="engagementObj.BillingMethod === 'Fixed Fee'">
                  <label class="label-text">{{ 'Flat-Rate-Amount' | translate }}</label>
                  <span class="p-inputgroup">
                      <span class="p-inputgroup-addon"><i class="fal fa-dollar-sign"></i></span>
                      <p-inputNumber maxlength="12" placeholder="0.00" inputId="integeronly" autocomplete="off"
                          mode="decimal" locale="en-US" [minFractionDigits]="2"
                          [(ngModel)]="engagementObj.FlatFeeAmount" inputStyleClass="p-text-right" name="flateRateAmount" #flateRateAmount="ngModel"
                          (focus)="$event.target.select()" (ngModelChange)="formChanged()"></p-inputNumber>
                  </span>
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'status' | translate }}</label>
                  <p-dropdown [options]="statusTypes" [(ngModel)]="engagementObj.Status" name="Status"
                      (ngModelChange)="formChanged()"></p-dropdown>
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{'engagement.number' | translate }} ({{'Optional' | translate }})
                      <i pTooltip="Engagement # is optional and could be an internal Engagement #."
                          tooltipStyleClass="tooltip-width-300"
                          class="fa fa-question-circle ic-blue question-tooltip-icon" tooltipPosition="right"></i>
                  </label>
                  <input type="text" pInputText maxlength="50" name="engagementID" autocomplete="off"
                      [(ngModel)]="engagementObj.EngagementNumber" class="p-text-right" (focus)="$event.target.select()"
                      #engagementID="ngModel" (ngModelChange)="formChanged()" />
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'sys_id' | translate }}</label>
                  <input type="text" pInputText name="projectMasterID" class="p-text-right" autocomplete="off"
                      [(ngModel)]="engagementObj.ProjectMasterID" #projectMasterID="ngModel" disabled />
              </div>
              <!-- <div class="p-field p-col-12">
                  <label class="label-text">{{'settings.Sales_Tax_Calculation_Option' | translate}}</label>
                  <p-dropdown [options]="salesTaxCalcOptions" placeholder="Select Option" [(ngModel)]="engagementObj.SalesTaxOption" name="SalesTaxOption"
                      (ngModelChange)="formChanged()"></p-dropdown>
              </div> -->
          </div>
      </div>
      <div class="p-col-4">
          <div class="card p-mt-2 engagementview-pb">
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'Created' | translate }}</label>
                  <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" name="startDate"
                      [(ngModel)]="engagementObj.StartDate" readonly (ngModelChange)="formChanged()"></p-calendar>
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'end_date' | translate }}</label>
                  <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" name="DueDate"
                      [(ngModel)]="engagementObj.DueDate" readonly (ngModelChange)="formChanged()"></p-calendar>
              </div>
              <div class="p-field p-col-12">
                  <label class="label-text">{{ 'assigned_to' | translate }}</label>
                  <p-dropdown [options]="staffList" [(ngModel)]="engagementObj.AssignedTo" name="staffID"
                      #staff="ngModel" (ngModelChange)="formChanged()"></p-dropdown>
              </div>
              <div class="p-field p-col-12 p-mt-6 p-d-flex p-jc-start">
                  <p-checkbox [binary]="true" label="{{ 'billable' | translate }}" class="p-mr-4"
                      [(ngModel)]="engagementObj.isBillable" name="isBillable" (ngModelChange)="formChanged()">
                  </p-checkbox>
                  <p-checkbox [binary]="true" label="{{ 'taxable' | translate }}" class="p-mr-4" *ngIf="timeexp?.ActivateExpenseRates || timeexp?.ActivateLaborRates"
                      [(ngModel)]="engagementObj.isTaxable" name="isTaxable" (ngModelChange)="formChanged()">
                  </p-checkbox>
                  <p-checkbox [binary]="true" label="{{ 'engagement.Calculate-Sales-Tax-if-NO-Time-Records' | translate }}"  *ngIf="(timeexp?.ActivateLaborRates || timeexp?.ActivateExpenseRates) && engagementObj.isTaxable"
                      [(ngModel)]="engagementObj.isCalcTaxNoTime" name="isCalcTaxNoTime" (ngModelChange)="formChanged()">
                  </p-checkbox>
              </div>
          </div>
      </div>
      <div class="p-field p-col-12 p-pt-2">
          <div class="card p-mt-2">
              <label class="label-text">{{ 'billing-invoicing.invoice-description' | translate }}</label>
              <app-mentions [rows]="3" [(inputText)]="engagementObj.EngagementInvoiceDescription"
                  (inputTextChange)="formChanged()"
                  style="width:100% !important; resize: vertical;"></app-mentions>
          </div>
      </div>
  </div>
</form>

<p-sidebar [(visible)]="scratchPadEnabled" position="bottom"
  [style]="{'min-height':'522px','background':'#8e8f92'}">
  <div class="card">
      <div class="control-label header-color text-nowrap p-col-12">
          <h4 class="label-text">{{ 'scratch_pad' | translate }}</h4>
      </div>
      <div class="p-col-12 col-md-12">
          <ejs-richtexteditor id='clientEditor' #clientEditor [(value)]="engagementObj.EngagementDescription" [toolbarSettings]='tools'
              [height]="400">
          </ejs-richtexteditor>
      </div>
  </div>
</p-sidebar>
