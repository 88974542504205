import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MenuItem, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-client-projects',
  templateUrl: './client-projects.component.html'
})
export class ClientProjectsComponent implements AfterViewInit {
  public tableDataSorce = [];
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dt: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  public companyId;
  clientID: number = -1;
  searchTextStr: any = "";
  project: any = [];
  cols: any[];
  public _selectedColumns: any[];
  public tagsList: any = [{ label: "All", value: null, color: "" }];
  groupList: SelectItem[];
  public projectObj = {
    TemplateName: null,
    tagID: null,
    dueDateID: null,
    statusID: null,
    staffId: null,
    managerId: null,
    clientGroup: null
  };
  isGroupListFetched: boolean = false

  projectFilterOptions: any = [];
  defaultFilter: any = null;
  buttonsList: MenuItem[];
  public isDisplayDetailsTable: boolean = false;
  public isRadioSelected: boolean = false;
  public companyTemplateID: any;
  public selectedTemplate: any;
  public createProjectFromCompanyTemplate = false;
  public planInfo: any;
  public templateDataSource: any = [];
  public companyTags;
  public ddmSettingsObj: any = {};
  public tagsListForFilters: any = [];

  constructor(private authGuard: AuthGuard, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, private translate: TranslateService, private router: Router, public mangoUtils: mangoUtils,) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.planInfo = this.encrDecSrvc.getObject(AppConstants.planInfo);
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString);
    this.createProjectFromCompanyTemplate = this.planInfo.SubscriptionLevel.toLowerCase() == "pro" || this.planInfo.SubscriptionLevel.toLowerCase() == "enterprise";
    this.fetchDdmSettings();
    this.getCompanyTemplateDetails();
    this.getGroups();

  }

  createFromCompanyTemplate(){
    this.isDisplayDetailsTable = true;
  }

  getCompanyTemplateDetails() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyId).subscribe((data) => {
      parent.templateDataSource = data;
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    })
  }

  createProjectScreen() {
    this.router.navigate(['project-management/projectDetails']);
  }

  getFormattedProjectName(projectName:string) {
    if (projectName && projectName.includes('^PY')) {
      const previousYear = new Date().getFullYear() - 1;
      return projectName.replace('^PY', previousYear.toString());
    }
    return projectName;
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('Projects') },
      { label: this.clientName, icon: 'ic-red' }
    ]);

    this.projectFilterOptions = [
      { label: this.translate.instant('all_active'), value: null, color: null },
      { label: this.translate.instant('Pending'), value: "Pending", color: "Pending" },
      { label: this.translate.instant('mgmt-inprogress'), value: "In Progress", color: "InProgress" },
      { label: this.translate.instant('Ready-for-Review'), value: "Ready for Review", color: "review" },
      { label: this.translate.instant('completed'), value: "Completed", color: "Completed" },
    ];
    this.defaultFilter = this.projectFilterOptions[0];

    this.buttonsList = [
      {
        label: this.translate.instant('New-Project'), icon: 'fal fa-plus',  command: () => {
          this.redirectProject();
        },
      },
      {
        label: this.translate.instant('create_company_template'), icon: 'fal fa-plus', command: () => {
          this.isDisplayDetailsTable = true;
          const interval = setInterval(() => {
            if (!this.searchValue) return;
            clearInterval(interval);
            this.searchValue.nativeElement.focus();
          })
        }
      },
      // {
      //   label: this.translate.instant('create_new_template'), icon: 'fal fa-plus', routerLink: ['/project-management/createTemplate'],
      // },
    ];

  }
  ngOnInit(): void {

  }


  ngAfterViewInit(): void {
    const intervalId = setInterval(() => {
      if(this.isGroupListFetched) {
        clearInterval(intervalId)
        this.getProjectList(true);
      }
    }, 300)
  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getGroups().subscribe((data: any) => {
      this.groupList = data.map((group) => {
        return {
          label: group.GroupDescription,
          value: group.CustomerGroupCategoryID
        }
      });
      this.groupList.unshift({ label: "All", value: null })
      this.isGroupListFetched = true
      this.mangoAPISrvc.showLoader(false);
    }, error => {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    })
  }


  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString, obj.filters.global.value);
  }


  findTagByValue(tagValue, property) {
    const temp = this.tagsList.filter((item) => item["value"] == tagValue);
    return temp[0] ? temp[0][property] : "";
  }

  getToolTip(obj) {
    if (obj.ExtensionDate) return 'Due Date: ' + moment(obj.DueDate).format("MM/DD/YYYY")

    return obj.NextDueDate
      ? "Next Due Date : " + moment(obj.NextDueDate).format("MM/DD/YYYY")
      : "";
  }

  redirectToProjectDetails(item) {
    this.encrDecSrvc.addObject(
      AppConstants.ddmProjectDetails,
      JSON.stringify(item)
    );
    this.router.navigate([
      `/project-management/${item.ProjectHeaderID}/projectDetails`,
    ]);
  }

  getProjectList(isLoading?: boolean, defaultFilter?) {
    const _this = this;
    const argsObj = {
      TemplateName: null,
      tagID: null,
      dueDateID: null,
      statusID: null,
      staffId: null,
      managerId: null,
      clientGroup: null
    };

    if(defaultFilter){
      if(defaultFilter.value != 'All'){
        argsObj.statusID = defaultFilter.value;
      }
    }

    if (argsObj.statusID == 'Completed') {
      this.cols?.splice(5, 0, { field: 'Completion', header: this.translate.instant('Completion'), rowClass: 'p-text-center cursor-hand' });
    } else {
      this.cols = this.cols?.filter(col => col.field !== 'Completion');
      this._selectedColumns = this._selectedColumns?.filter(col => col.field !== 'Completion');
    }

    _this.project = [];
    _this.filteredItemsSize = -1;

    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc
      .getProjectList(
        argsObj.dueDateID,
        argsObj.statusID,
        argsObj.staffId,
        argsObj.managerId,
        _this.clientID,
        argsObj.TemplateName,
        argsObj.tagID,
        _this.companyId,
        argsObj.clientGroup,
        false,
        'All',
        null,
        null,
        null,
        null,
        null,

        { }
      )
      .subscribe(
        ( responseData: any ) => {
          const data = (
            responseData.projectList
          );

          data.map((item) => {
            item["compareDueDate"] = item["ActualDueDate"]
              ? moment(moment.utc(item["ActualDueDate"]).toDate()).format(
                "YYYY-MM-DD"
              )
              : null;
            item["compareExtensionDate"] = item["ExtensionDate"]
              ? moment(moment.utc(item["ExtensionDate"]).toDate()).format(
                "YYYY-MM-DD"
              )
              : null;
            item.managername = item.ManagerID == -1 ? "Unassigned" : item.managername;
            item.username = item.UserAssignedID == -1 ? "Unassigned" : item.username;
            item.SortableDueDate = item.ExtensionDate != null ? item.ExtensionDate : (item.DueDate != null ? item.DueDate : null);
            switch (item.Status) {
              case "Pending": item.statusClass = "pending";
                break;
              case "In Progress": item.statusClass = "inprogress";
                break;
              case "Ready for Review": item.statusClass = "review";
                break;
              case "Completed": item.statusClass = "completed";
                break;
            }
            if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
              item['GroupDescriptionValues'] = _this.groupList
                .filter(group => item['GroupDescriptionIDArray'].includes(group['value']))
                .map(group => group['label']).join(", ");
            }
          });

          // if (this.defaultFilter && this.defaultFilter.value !== "All") {
          //   console.log("xxx")
          //   let filteredItems = _this.filterByStatus(_this.defaultFilter.value, data);
          //   _this.project = filteredItems;
          // }
          // else {
          // }
          _this.project = data;

          _this.mangoAPISrvc.getTotalAndCompletedTasks().subscribe((list: any) => {
            _this.project = _this.project.map((item) => {
              const res = list.filter((task) => task.ProjectHeaderID == item.ProjectHeaderID)[0];
              return {
                ...item,
                TasksCompleted: res?.TasksCompleted ? res.TasksCompleted : 0 ,
                TotalTasks: res?.TotalTasks ? res.TotalTasks : 0
              }
            })
          })


          if (isLoading && isLoading === true) {
            setTimeout(() => {
              _this.filterDataTable();
            }, 100);
          }
          _this.mangoAPISrvc.showLoader(false);
        },
        (error) => _this.mangoAPISrvc.showLoader(false)
      );
  }

  filterByStatus(filter, data) {
    const statusFilter = filter;
    const filteredItem = [];
    const tempData = data;

    if (statusFilter === "Active") {
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].Status != "Completed") {
          filteredItem.push(tempData[i]);
        }
      }
      return filteredItem;
    }
    else if (statusFilter === "Completed") {
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].Status === "Completed") {
          filteredItem.push(tempData[i]);
        }
      }
      return filteredItem;
    }
    else {
      return tempData;
    }
  }

  filterDataTable() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dt.reset();
      if (this.searchTextStr) {
        const event = new Event("input", {
          bubbles: true,
          cancelable: true,
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  get isAllowCreateProject() {
    return this.authGuard.isAllowAccess(41)
  }

  changeProjectFilter(event: any) {
    this.getProjectList(true, this.defaultFilter);
  }

  radioCompanyClicked(event, item) {
    this.isRadioSelected = true;
    this.companyTemplateID = item.CompanyTemplateHeaderID;
    item.ClientID = this.clientID;
    item.ClientName = this.clientName;

    const transformedItem = {
      ...item,
      TemplateWildcards: item.TemplateName,
      TemplateName: this.mangoUtils.replacePMCaretTemplate(
        item.TemplateName,
        item.DueDate,
        item.isPreviousPeriodYearPolicy,
        item.Repeat
      ),
      ClientID: this.clientID || null,
      ClientName: this.clientName || null,
    };
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(transformedItem));
  }

  redirectProject() {
    this.encrDecSrvc.addObject(AppConstants.ddmClientName, JSON.stringify({ 'ClientID': this.clientID, 'ClientName': this.clientName }));
    this.router.navigate(['project-management/projectDetails']);
  }

  fetchDdmSettings() {
    const parent = this;

    parent.companyTags = parent.encrDecSrvc.getObject(AppConstants.companyTags);
    console.log(parent.companyTags);
    if (!parent.companyTags) {
      // parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
        (res: any) => {
          if (res.length > 0) parent.companyTags = res[0];
          else {
            parent.companyTags = {
              CompanyID: "",
              IsEmailProjectAssigned: false,
              IsEmailTaskAssigned: false,
              IsEmailTaskReady: false,
              IsManagerProjectInProgress: false,
              IsManagerReadyReview: false,
              IsProjectRejected: false,
              Tag1: "Available for Work",
              Tag2: "Extended",
              Tag3: "Urgent",
              Tag4: "Missing Information",
              Tag5: "Waiting on Client",
              Tag6: "Notice",
              Tag7: "Telephone Call",
              Tag8: "Tag 8",
              Tag9: "Tag 9 ",
              ddmSettingsID: null,
              DefaultUserID: null,
              DefaultManagerID: null,
              TagArray2: []
            };
          }

          parent.encrDecSrvc.addObject(
            AppConstants.companyTags,
            parent.companyTags
          );
          // parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
    parent.ddmSettingsObj = parent.companyTags;
    if (
      parent.ddmSettingsObj &&
      Object.keys(parent.ddmSettingsObj).length > 0
    ) {
      const allTags = parent.ddmSettingsObj.TagArray2;
      allTags?.map((item, i) => {
        if (item[0] != '') {
          const obj = {};
          obj["label"] = item[0];
          obj["value"] = (i + 1).toString();
          obj["color"] = item[1];
          obj["textColor"] = item[1] != '' ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(item[1])) : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb('#ffffff'));
          parent.tagsList.push(obj);
          parent.tagsListForFilters.push(obj);
        }
      });
    }
  }

  checkDueDate(obj) {
    const dueDate = obj.ExtensionDate || obj.DueDate
    return dueDate && dueDate < moment().format("YYYY-MM-DD");
  }

}
