import { Component, Inject, OnInit, Renderer2, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils
} from "@app/_services";
import { SelectItem } from "primeng/api";
import * as AWS from "aws-sdk";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { resolve } from "dns";
import { environment } from "@environments/environment";

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent implements OnInit {
  public timeZoneDataSet: SelectItem[];
  public companyDetailForm: UntypedFormGroup;
  public imageUrl: any = "";
  public file: any;
  public fileInput: any;
  public fileName: any;
  public isImageUrl: boolean = false;
  public isInvalidZip: boolean = false;
  public isFormValid: boolean = false;
  public companyDataSource: any;
  public dom: Document;
  public nativeWindow: any;
  public monthOptions;
  public isValidFile: boolean = false;
  public superAdminVisible: number[] = AppConstants.superAdminVisible;
  companyID: any;
  isStaxEnrollmentStarted: any = {};
  @ViewChild("fileUpload") fileUpload: any;
  constructor(
    private _fb: UntypedFormBuilder,
    @Inject(DOCUMENT) dom: Document,
    private renderer: Renderer2,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private mangoUtils: mangoUtils
  ) {
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.isStaxEnrollmentStarted = this.encrDecSrvc.getObject(AppConstants.isStaxEnrollmentStarted);
    this.translate.reloadLang(this.translate.currentLang).subscribe((data) => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant("company_setup") },
        { label: this.translate.instant("Settings"), icon: 'ic-red' }
      ]);
      this.timeZoneDataSet = [
        { label: this.translate.instant("timezone.eastern"), value: "EST" },
        { label: this.translate.instant("timezone.central"), value: "CST" },
        { label: this.translate.instant("Hawaii Standard Time"), value: "HST" },
        { label: this.translate.instant("timezone.mountain"), value: "MST" },
        { label: this.translate.instant("timezone.pacific"), value: "PST" },

      ];
    });
  }

  ngOnInit(): void {
    this.monthOptions = [
      { label: "Select Year End Month", value: null },
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 },
    ]
    this.initializeForm();
    this.getCompanyData();
  }

  /*
  Fetching company data from Server
  */
  getCompanyData() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyViewData().subscribe((data) => {
      parent.companyDataSource = data;
      parent.updateForm(data);
      parent.mangoAPISrvc.showLoader(false);
    }),
      (error) => {
        parent.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      };
  }

  /*
initialize the formGroup and its controllers with validations
*/
  initializeForm() {
    this.companyDetailForm = this._fb.group({
      companyName: [
        "",
        [
          <any>Validators.required,
          <any>Validators.maxLength(70),
          <any>Validators.minLength(3),
          this.mangoUtils.noWhitespaceValidator
        ],
      ],
      street1: [
        null,
        [<any>Validators.maxLength(100), <any>Validators.minLength(10)],
      ],
      street2: [null, [<any>Validators.maxLength(100)]],
      city: [""],
      state: ["", [<any>Validators.maxLength(2), <any>Validators.minLength(2)]],
      zip: [""],
      timezone: [""],
      country: [
        ""[(<any>Validators.maxLength(2), <any>Validators.minLength(2))],
      ],
      phone: ["", [<any>Validators.required]],
      faxNumber: [""],
      companyLogoWeb: [""],
      useCompanyLogo: ["false"],
      email: ["", [<any>Validators.required, Validators.email]],
      companyId: [],
      PortalURL: [],
      HostedPaymentToken: [],
      WebsiteCompany: [],
      yearEndMonth: [null],
      companyContact: [
        "",
        [
          <any>Validators.required,
          <any>Validators.maxLength(50),
          <any>Validators.minLength(3),
        ],
      ],
    });

    this.companyDetailForm.valueChanges.subscribe((data) => {
      this.encrDecSrvc.addObject(
        AppConstants.isFormChanged,
        !this.companyDetailForm.pristine
      );
      this.validateForm();
    });
  }

  buildPortalUrl() {
    let url = null;
    const formData = this.companyDetailForm.value;
    if (formData["companyName"] && formData["companyId"]) {
      url = environment.BASE_SECURE_PORTAL_DOMAIN + 
        "/#/login?CCID=" +
        formData["companyId"] +
        "&CName=" +
        encodeURIComponent(formData["companyName"].trim());
      this.companyDetailForm.controls["PortalURL"].setValue(url);


      const hpurl = `${environment.BASIC_DOMAIN}` + `/payments/hosted-page/${formData["HostedPaymentToken"]}`;
      this.companyDetailForm.controls["HostedPaymentToken"].setValue(hpurl);
    } else {
      this.companyDetailForm.controls["PortalURL"].setValue(url);
      this.companyDetailForm.controls["HostedPaymentToken"].setValue(url);
    }
  }

  updateForm(itemData: any) {
    if (Object.keys(itemData).length > 0) {
      this.companyDetailForm.controls["timezone"].setValue(
        itemData["TimeZone"]
      );
      this.companyDetailForm.controls["companyName"].setValue(
        itemData["CompanyName"].trim()
      );
      /* Company Information Data*/
      this.companyDetailForm.controls["street1"].setValue(itemData["Street1"]);
      if (itemData["Street1"]) {
        this.companyDetailForm.controls["street2"].setValue(
          itemData["Street2"]
        );
      }
      this.companyDetailForm.controls["city"].setValue(itemData["City"]);
      this.companyDetailForm.controls["state"].setValue(itemData["State"]);
      this.companyDetailForm.controls["zip"].setValue(itemData["Zip"]);
      this.companyDetailForm.controls["country"].setValue(itemData["Country"]);

      /* Contact Information Data*/
      this.companyDetailForm.controls["phone"].setValue(itemData["Telephone"]);
      this.companyDetailForm.controls["faxNumber"].setValue(itemData["Fax"]);
      this.companyDetailForm.controls["companyLogoWeb"].setValue(
        itemData["CompanyLogoWeb"]
      );
      this.companyDetailForm.controls["useCompanyLogo"].setValue(
        itemData["UseLogo"]
      );
      this.companyDetailForm.controls["email"].setValue(
        itemData["EmailCompany"]
      );
      this.companyDetailForm.controls["WebsiteCompany"].setValue(
        itemData["WebsiteCompany"]
      );

      this.companyDetailForm.controls["yearEndMonth"].setValue(
        itemData["YearEndMonth"]
      );
      // if (itemData['EmailPassword']) {
      //   this.companyDetailForm.controls['password'].setValue(itemData['EmailPassword']);
      //   //this.companyDetailForm.controls['ConfirmPassword'].setValue(itemData['password']);
      // }
      if (itemData["CompanyContact"]) {
        this.companyDetailForm.controls["companyContact"].setValue(
          itemData["CompanyContact"]
        );
      }
      if (itemData["CompanyLogoWeb"]) {
        this.isImageUrl = true;
        this.imageUrl = itemData["CompanyLogoWeb"];
      }
      this.companyDetailForm.controls["HostedPaymentToken"].setValue(
        itemData["HostedPaymentToken"]
      );
      this.companyDetailForm.controls["companyId"].setValue(
        this.encrDecSrvc.getObject(AppConstants.companyID)
      );
      this.buildPortalUrl();
      // display timezone
      //  this.companyDetailForm.controls['timezone'].setValue(itemData['TimeZone']);
    }
  }

  /*
Saving the company data
*/
  saveCompnayData() {
    this.isFormValid = false;
    const obj = this.companyDetailForm.value;
    if (obj.companyName === "") {
      obj.companyName = this.companyDataSource.CompanyName;
      obj.street1 =
        this.companyDataSource.Street1 != ""
          ? this.companyDataSource.Street1
          : null;
      obj.street2 =
        this.companyDataSource.Street2 != ""
          ? this.companyDataSource.Street2
          : null;
      obj.city = this.companyDataSource.City;
      obj.state = this.companyDataSource.State.toUpperCase();
      obj.zip = this.companyDataSource.Zip;
      obj.country = this.companyDataSource.Country.toUpperCase();
      obj.phone = this.companyDataSource.Telephone;
      obj.faxNumber = this.companyDataSource.Fax;
      obj.companyLogoWeb = this.companyDataSource.CompanyLogoWeb;
      obj.useCompanyLogo = this.companyDataSource.UseLogo;
      obj.email = this.companyDataSource.EmailCompany;
      obj.companyContact = this.companyDataSource.CompanyContact;
      obj.WebsiteCompany = this.companyDataSource.WebsiteCompany;
    } else {
      obj.street1 = obj.street1 != "" ? obj.street1 : null;
      obj.street2 = obj.street2 != "" ? obj.street2 : null;
    }
    obj.YearEndMonth = obj.yearEndMonth;



    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateCompanyView(obj).subscribe(
      (data) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "success",
          this.translate.instant("Success"),
          AppConstants.updateMsg
        );
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      },
      () => {
        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  removeImage() {
    if (this.imageUrl) {
      this.companyDetailForm.controls["companyLogoWeb"].setValue(null);
      this.imageUrl = null;
      this.isImageUrl = false;
      this.fileUpload.clear();
      this.saveCompnayData();
    }
  }

  uploadImage(): void {
    const uniqueName = (
      `${this.encrDecSrvc.getObject(AppConstants.companyID)}_${this.file.name}`
    );

    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = "us-east-1";

    let bucket = (
      new AWS.S3(
        {
          params: {
            Bucket: "mangobillinglogos"
          }
        }
      )
    );
    */
    const bucket = (
      AWSBucketMask
    );

    const params = {
      Bucket: "mangobillinglogos",
      Key: "companylogo/" + uniqueName,
      ACL: "public-read",
      Body: this.file,
    };

    const self = this;

    self.mangoAPISrvc.showLoader(true);

    bucket.upload(
      params,

      function (err, data) {
        self.mangoAPISrvc.showLoader(false);

        if (err) {
          // console.log("error while saving file on s3 server", err);
          return;
        }

        self.isImageUrl = true;
        self.imageUrl = data.Location;

        self.companyDetailForm.controls["companyLogoWeb"]
          .setValue(self.imageUrl);

        self.saveCompnayData();

        self.fileUpload.clear()
      },

      self.mangoAPISrvc
    );
  }

  async fileEvent(fileInput: any) {
    for (const file of fileInput.files) {
      this.file = file;

      if (
        !this.checkSize(this.file.size)
      ) {
        this.fileUpload.clear();

        Swal.fire({
          title: this.translate.instant('confirmation'),
          text: this.translate.instant('max_file_size_warning'),
          icon: 'warning',
          confirmButtonText: this.translate.instant('ok_button')
        });

        return;
      }

      const chkDimension = (
        await this.checkDimension(this.file)
      );

      if (
        !chkDimension
      ) {
        this.fileUpload.clear();

        Swal.fire({
          title: this.translate.instant('confirmation'),
          text: this.translate.instant('max_file_dimension_warning'),
          icon: 'warning',
          confirmButtonText: this.translate.instant('ok_button')
        });

        return;
      }

      this.uploadImage();
    }
  }

  async checkDimension(file: any) {
    const dimension: any = (
      await this.getDimension(file)
    );

    if (
      dimension.height > 150
      && dimension.width > 300
    ) {
      return false;
    }
    else {
      return true;
    }
  }

  getDimension(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();

        img.src = reader.result as string;

        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;

          resolve({ height, width });
        };
      };
    });
  }

  checkSize(bytes) {
    //check if 2mb;
    const fileSize = bytes / 1024 / 1024; // in MiB
    if (Math.round(fileSize) > Math.round(2.00)) {
      return false;
    } else {
      return true;
    }
  }

  validateForm() {
    let isInValidData = false;
    Object.keys(this.companyDetailForm.controls).forEach((key) => {
      if (this.companyDetailForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData && this.companyDetailForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }
  /*
  copy the general desc data to clipboard\
*/
  copyClipBoard(inputElement) {
    const copyValue = this.companyDetailForm.controls["PortalURL"].value;
    if (copyValue) {
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.mangoAPISrvc.notify(
        "success",
        this.translate.instant("Success"),
        "Copied to Clipboard"
      );
    } else {
      document.execCommand("Unselect");
    }
  }

  /*
copy the general desc data to clipboard\
*/
  copyHPClipBoard(inputElement) {
    const copyValue = this.companyDetailForm.controls["HostedPaymentToken"].value;
    if (copyValue) {
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.mangoAPISrvc.notify(
        "success",
        this.translate.instant("Success"),
        "Copied to Clipboard"
      );
    } else {
      document.execCommand("Unselect");
    }
  }

  openHPClipBoard(isPortal) {
    let url = null
    if (isPortal) {
      url = `https://${this.companyDetailForm.controls["PortalURL"].value}`;
    } else {
      url = `https://${this.companyDetailForm.controls["HostedPaymentToken"].value}`;
    }
    this.nativeWindow.open(decodeURI(url));
  }
}
