import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { Table } from 'primeng/table';
import { SharedComponentsService } from '@app/shared/components';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-engagements-notes',
  templateUrl: './engagements-notes.component.html'
})
export class EngagementsNotesComponent implements OnInit {

  public notes = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  public selectedClientName: string = null;
  projectClientID: number = -1;
  projectID: number = -1;
  selectedEngagementName: string = null;

  constructor(private router: Router, public sharedSrvc: SharedComponentsService, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public translate: TranslateService) {
    this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.projectClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.selectedEngagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + 'N' + AppConstants.SearchString);
    this.getProjectNotes();
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('engagement')},
      { label: this.translate.instant('notes') },
      { label: this.selectedClientName, icon: 'ic-red' },
      { label: this.selectedEngagementName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.sharedSrvc.notesDialogData
      .subscribe((data) => {
        this.getProjectNotes();
      })
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'N' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'N' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  // adding new engagent
  addNotesPopUp() {
    const obj = {};
    obj['isEditNote'] = false;
    obj['isFromMenu'] = false;
    obj['isProjectEnable'] = false;
    obj['ClientID'] = this.projectClientID;
    obj['ProjectMasterID'] = this.projectID;
    obj['ClientName'] = this.selectedClientName;
    this.sharedSrvc.openNotesDialog(obj);
  }

  editNote(obj) {
    obj['isEditNote'] = true;
    obj['isFromMenu'] = false;
    obj['isProjectEnable'] = false;
    obj['ClientName'] = this.selectedClientName;
    obj['ProjectMasterID'] = obj.ProjectMasterID ? obj.ProjectMasterID : this.projectID;
    this.sharedSrvc.openNotesDialog(obj);
  }

  getProjectNotes() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getProjectNotes(parent.projectID).subscribe(function (data: any) {
      parent.notes = data;
      parent.notes = data.map(function (obj) {
        obj['Ddate'] = moment(obj['Ddate']).format('MM/DD/YYYY');
        return obj;
      });
      parent.loadFilterGrid();
      parent.mangoAPISrvc.fireEngagementBadgeView(true);
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }


  redirectListView() {
    this.encrDecSrvc.addObject(AppConstants.projectID, "");
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }

  deleteNote(CustomerNotesID) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteNote(CustomerNotesID).subscribe(function (data) {
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
          parent.mangoAPISrvc.showLoader(false);
          parent.getProjectNotes();
        }, error => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

}
