import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectListComponent } from './project-list.component';
import { ProjectListProjectsComponent } from './project-list-projects/project-list-projects.component';
import { ProjectListTasksComponent } from './project-list-tasks/project-list-tasks.component';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: ProjectListComponent,
    children: [
      { path: '', component: ProjectListProjectsComponent },
      { path: AppConstants.projects, component: ProjectListProjectsComponent },
      { path: AppConstants.tasks, component: ProjectListTasksComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectListRoutingModule { }
