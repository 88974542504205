import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthGuard, EncrDecrService, MangoApiService } from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import { SharedComponentsService } from "@app/shared/components";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent implements OnInit {
  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;
  companyId: number = null;
  subscriptionLevel: string;

  constructor(
    private router: Router,
    public authGuard: AuthGuard,
    private _translate: TranslateService,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private sharedDialogSvc: SharedComponentsService
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
  }

  ngOnInit() {
    this.sharedDialogSvc.toggleDMSIshareSub.subscribe(() => {
      this.loadMenu()
    })
    this.setMenuIndex();
    const interval = setInterval(() => {
      if (!this._translate.translations[this._translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  initTranslations() {
    this.loadMenu();
  }

  loadMenu() {
    if(this.subscriptionLevel.toUpperCase() == 'PM') {
      this.items = [
        {
          label: this._translate.instant("lists"),
          routerLink: ["/settings/customLists"],
          icon: "fal fa-th-list",
        }
      ];

      if (this.authGuard.isAllowAccess(10)) {
        this.items.push({
          label: `${this._translate.instant("rate.manage")} Roles`,
          routerLink: ["/settings/manageRoles"],
          icon: "fal fa-cogs",
        });
      }
    } else {
      this.items = [
        {
          label: this._translate.instant("time_and_expenses"),
          routerLink: ["/settings/timeAndExpenses"],
          icon: "fal fa-clock",
        },
        {
          label: this._translate.instant("invoices"),
          routerLink: ["/settings/invoices"],
          icon: "fal fa-file-invoice",
        },
        {
          label: this._translate.instant("activities"),
          routerLink: ["/settings/activities"],
          icon: "fal fa-list-alt",
        },
        {
          label: this._translate.instant("billing-invoicing.apply-to-expenses"),
          routerLink: ["/settings/expenses"],
          icon: "fal fa-receipt",
        },
        {
          label: this._translate.instant("lists"),
          routerLink: ["/settings/customLists"],
          icon: "fal fa-th-list",
        },
        {
          label: this._translate.instant("banks"),
          routerLink: ["/settings/banks"],
          icon: "fal fa-university",
        },
        // { label: this._translate.instant('rate_profiles'), routerLink: ['/settings/billrate'], icon: 'fal fa-users'},
        {
          label: this._translate.instant("Rate Templates"),
          routerLink: ["/settings/rateTemplates"],
          icon: "fal fa-users",
        },
        {
          label: this._translate.instant("notifications"),
          routerLink: ["/settings/mailConfiguration"],
          icon: "fal fa-envelope-open-text",
        },
        {
          label: this._translate.instant("qb-iintegration"),
          routerLink: ["/settings/integrations"],
          icon: "fal fa-abacus",
        },
        {
          label: this._translate.instant("mangoshare_integration"),
          routerLink: ["/settings/mangoshare-integration"],
          icon: "fal fa-folders",
        },
        {
          label: this._translate.instant("Utilities"),
          routerLink: ["/settings/assignments"],
          icon: "fal fa-users-cog",
        },
      ];
      
      if (!this.encrDecSrvc.getObject(AppConstants.isDMSIshare)) {
        this.items.push({
          label: this._translate.instant("settings.Client-Portal"),
          routerLink: ["/settings/clientPortal"],
          icon: "fal fa-passport",
        });
      }

      if (this.authGuard.isAllowAccess(10)) {
        this.items.push({
          label: `${this._translate.instant("rate.manage")} Roles`,
          routerLink: ["/settings/manageRoles"],
          icon: "fal fa-cogs",
        });
      }
  
      this.items.push({
        label: this._translate.instant("client.other"),
        routerLink: ["/settings/other"],
        icon: "fal fa-tasks-alt",
      });
    }
    
    this.selectedMenu = this.items[this.selectedMenuIndex];
    // if(AppConstants.superAdminVisible.includes(this.companyId)){
    //   this.items.splice(7, 0, { label: this._translate.instant('Rate Templates'), routerLink: ['/settings/rateTemplates'], icon: 'fal fa-users'})
    // }
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;
    if(this.subscriptionLevel.toUpperCase() == 'PM') {
      switch (url) {
        case "/settings/customLists": {
          this.selectedMenuIndex = 0;
          break;
        }
        case "/settings/manageRoles": {
          this.selectedMenuIndex = 1;
          break;
        }
        default: {
          this.selectedMenuIndex = 0;
          break;
        }
      }
    } else {
      switch (url) {
        case "/settings/invoices": {
          this.selectedMenuIndex = 1;
          break;
        }
        case "/settings/activities": {
          this.selectedMenuIndex = 2;
          break;
        }
        case "/settings/expenses": {
          this.selectedMenuIndex = 3;
          break;
        }
        case "/settings/customLists": {
          this.selectedMenuIndex = 4;
          break;
        }
        case "/settings/banks": {
          this.selectedMenuIndex = 5;
          break;
        }
        case "/settings/billrate": {
          this.selectedMenuIndex = 6;
          break;
        }
        case "/settings/mailConfiguration": {
          this.selectedMenuIndex = 7;
          break;
        }
        case "/settings/integrations": {
          this.selectedMenuIndex = 8;
          break;
        }
        case "/settings/assignments": {
          this.selectedMenuIndex = 9;
          break;
        }
        case "/settings/clientPortal": {
          this.selectedMenuIndex = 10;
          break;
        }
        case "/settings/manageRoles": {
          this.selectedMenuIndex = 11;
          break;
        }
        default: {
          this.selectedMenuIndex = 0;
          break;
        }
      }      
    }
  }
}
