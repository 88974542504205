<p-dialog header="{{ 'client.contact' | translate }}{{ (viewingOnly? ' - Read Only' : '')}}" [(visible)]="displayContactModal" (onHide)="closeContactFormModal(false)"
    contentStyleClass="contact-dialog" [modal]="true" [style]="{width: '65vw'}" [maximizable]="false" showEffect="fade"
    [baseZIndex]="1" [draggable]="true" [resizable]="false">
    <form #contactMainForm="ngForm" autocomplete="off">
        <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
            <div class="p-col-9" *ngIf="!viewingOnly">
                <p-checkbox [binary]="true" label="{{ 'copy_client' | translate }}" class="label-text"
                    [(ngModel)]="copyClientChk" binary="true" (click)="changeCopyClient();" name="copyClientChk"
                    id="copyClientChk"></p-checkbox>
                <label class="p-d-block">&nbsp;</label>
            </div>
            <div class="p-col-4" [ngClass]="(viewingOnly)?'notAllowedCursor wrapper' :''">
                <div class="p-field p-col-12">
                    <label class="required-field label-text">{{ 'companyname' | translate }}</label>
                    <input type="text" pInputText maxlength="80" autocomplete="off" [(ngModel)]="contactObj.Company"
                        name="company" id="company" #company="ngModel" required>
                </div>
                <div class="p-field p-col-12">
                    <label class="required-field label-text">{{ 'client.contact_name' | translate }}</label>
                    <input type="text" pInputText autocomplete="off" [(ngModel)]="contactObj.ContactName"
                        name="contactName" id="contactName" #contactName="ngModel" maxlength="60" required>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'client.street' | translate }} 1</label>
                    <input type="text" pInputText autocomplete="off" maxlength="60" [(ngModel)]="contactObj.Street1"
                        name="street1" id="street1" #street1="ngModel">
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'client.street' | translate }} 2</label>
                    <input type="text" pInputText autocomplete="off" maxlength="60" [(ngModel)]="contactObj.Street2"
                        name="street2" id="street2">
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'client.city' | translate }} </label>
                    <input pInputText class="form-control" type="text" [(ngModel)]="contactObj.City" name="city"
                        id="city">
                </div>
            </div>
            <div class="p-col-3" [ngClass]="(viewingOnly)?'notAllowedCursor wrapper' :''">
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'work' | translate }}</label>
                    <p-inputMask mask="(999) 999-9999? x99999" autocomplete="off" [(ngModel)]="contactObj.Office"
                        name="office" id="office"></p-inputMask>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'Fax-Number' | translate }}</label>
                    <p-inputMask mask="(999) 999-9999?" autocomplete="off" [(ngModel)]="contactObj.Fax" name="fax"
                        id="fax"></p-inputMask>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'mobile' | translate }}</label>
                    <p-inputMask mask="(999) 999-9999?" autocomplete="nope" [(ngModel)]="contactObj.Mobile"
                        name="mobile" id="mobile" #mobile="ngModel"></p-inputMask>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'home' | translate }}</label>
                    <p-inputMask mask="(999) 999-9999?" autocomplete="off" [(ngModel)]="contactObj.Home" name="home"
                        id="home" #home="ngModel"></p-inputMask>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.state' | translate }}</label>
                        <input type="text" pInputText autocomplete="off" maxlength="2" [(ngModel)]="contactObj.State"
                            name="state" id="state">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.zip' | translate }}</label>
                        <input type="text" pInputText class="p-text-right" [(ngModel)]="contactObj.Zip" maxlength="25"
                            name="zip" id="zip" autocomplete="nope">
                    </div>
                </div>
            </div>
            <div class="p-col-5" [ngClass]="(viewingOnly)?'notAllowedCursor wrapper' :''">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'contact_type' | translate }}</label>
                        <p-dropdown [options]="contactsType"
                            placeholder="{{ 'select' | translate }} {{ 'contact_type' | translate }}"
                            [(ngModel)]="contactObj.CustomerContactTypeID" name="selectedContactType"
                            id="selectedContactType" #contactType="ngModel" appendTo="body"></p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.contact_description' | translate }}</label>
                        <input type="text" pInputText autocomplete="off" [(ngModel)]="contactObj.ContactDescription"
                            name="contactDescription" id="contactDescription" maxlength="30">
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'engagement' | translate }}</label>
                        <button pButton pRipple (click)="addEngagementPopUp()" [disabled]="selClient==null"
                            tooltipPosition="top" pTooltip="{{ 'add_engagement' | translate }}"
                            tooltipStyleClass="tooltip-no-wrap" type="button" icon="fal fa-plus-circle"
                            class="p-button-rounded p-button-danger p-button-text pull-right height-button">
                        </button>
                        <p-dropdown [options]="projectsList" placeholder="{{ 'select-engagement' | translate }}"
                            [(ngModel)]="contactObj.ProjectMasterID" name="itemProjectSelected" [filter]="true"
                            id="itemProjectSelected" #itemProjectSelected="ngModel" [readonly]="isDisableOptions"
                            appendTo="body"></p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'email' | translate }}</label>
                        <i *ngIf="emailExists && contactObj.Email && contactMainForm.dirty" class="fas fa-exclamation-circle ic-red"
                            pTooltip="{{'user.email-no-longer-available' | translate}}" tooltipPosition="top"
                            tooltipStyleClass="tooltip-no-wrap"></i>
                        <i tooltipPosition="top" pTooltip="{{ 'reset_password_tooltip_message' | translate }}"
                            tooltipStyleClass="tooltip-no-wrap" (click)="onClickResetPw()"
                            class="{{(isEmailValid && contactObj.Email) ? 'fal fa-key pull-right p-button-danger cursor-hand p-pt-2' : 'fal fa-key pull-right p-button-danger cursor-hand p-pt-2 notAllowed low-opacity'}}"
                            tooltipPosition="top">
                        </i>
                        <i tooltipPosition="top" pTooltip="{{'company-setup.Copy-URL-to-Clipboard' | translate}}"
                            class="{{(isEmailValid && contactObj.Email) ? 'fal fa-copy pull-right ic-green cursor-hand p-pt-2 p-pr-3' : 'fal fa-copy pull-right ic-green cursor-hand p-pt-2 p-pr-2 notAllowed low-opacity'}}"
                            (click)="copyClipBoard(email)">
                        </i>
                        <i tooltipPosition="top" pTooltip="{{'email_client' | translate}}"
                            class="{{(isEmailValid && contactObj.Email) ? 'fal fa-envelope pull-right ic-red cursor-hand p-pt-2 p-pr-3' : 'fal fa-envelope pull-right ic-red cursor-hand p-pt-2 p-pr-2 notAllowed low-opacity'}}"
                            (click)="sendEmailToClient();"></i>
                        <input type="text" #email pInputText autocomplete="nope" [(ngModel)]="contactObj.Email"
                            (ngModelChange)="isValidEmail(contactObj.Email);emailAlreadyExists(contactObj.Email)" name="email" id="email">
                        <div *ngIf="!isEmailValid && contactObj.Email && contactMainForm.dirty" class="p-invalid">
                            {{'invalid_email' | translate }}</div>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="{{ 'email_statements' | translate }}" class="label-text"
                            [(ngModel)]="contactObj.isEmailStatements"
                            class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                            name="isEmailStatements" id="isEmailStatements"></p-checkbox>
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="{{ 'email_invoices' | translate }}"
                            class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                            [(ngModel)]="contactObj.isEmailInvoices" name="isEmailInvoices" id="isEmailInvoices">
                        </p-checkbox>
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="{{ 'access_client_portal' | translate }}"
                            class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                            [(ngModel)]="contactObj.isClientPortalAccess" name="isClientPortalAccess"
                            id="isClientPortalAccess"></p-checkbox>
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="{{ 'default_contact' | translate }}"
                            class="{{(isEmailValid && contactObj.Email && contactObj.ContactName) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                            [(ngModel)]="contactObj.isDefaultContact" name="isDefaultContact"
                            id="isDefaultContact"></p-checkbox>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="p-d-block">&nbsp;</label>
                        <p-checkbox [binary]="true" label="Email Payment Notification" class="label-text"
                            [(ngModel)]="contactObj.isEmailPaymentNotification"
                            class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                            name="isEmailPaymentNotification" id="isEmailPaymentNotification"></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-file-alt" (click)="isShowMemo=!isShowMemo" label="Memo"
            class="pull-left"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_and_new' | translate }}"
            (click)="saveAndNewContact()" [disabled]="!isFormValid || contactMainForm.pristine || emailExists || !contactObj.ContactName"
            *ngIf="!isEditFlow && !viewingOnly"></button>
        <button *ngIf="!viewingOnly" type="button" pButton pRipple icon="fal fa-check" (click)="displayContactModal=false"
            label="{{ 'save_button' | translate }}" (click)="saveContact(isEditFlow)"
            [disabled]="!isFormValid || contactMainForm.pristine || emailExists || mangoUtils.isEmptyOrNull(contactObj.ContactName)"></button>
        <button *ngIf="!viewingOnly" type="button" pButton pRipple icon="fal fa-envelope" label="{{ 'portal_invite' | translate }}"
            (click)="openEmailDialog()" [disabled]="!contactObj.Email"></button>
        <button *ngIf="!viewingOnly" type="button" pButton pRipple icon="fal fa-times" (click)="displayContactModal=false"
            label="{{ 'cancel_button' | translate }}" (click)="closeContactFormModal(false)"></button>
    </ng-template>

</p-dialog>
<p-sidebar [(visible)]="isShowMemo" [baseZIndex]="10001" position="bottom" styleClass="p-sidebar-md">
    <div class="card">
        <div class="p-field p-col-12" [ngClass]="(viewingOnly)?'notAllowedCursor wrapper' :''">
            <label class="label-text">{{ 'memo' | translate }}</label>
            <app-mentions [(inputText)]="contactObj.ScratchPad" (inputTextChange)="validateForm();" [rows]="3" style="width: 100%; resize: vertical;"></app-mentions>
        </div>
    </div>
</p-sidebar>
<p-dialog header="Invite Client" [(visible)]="isInviteClient" [modal]="true" [style]="{width: '70vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10001" [draggable]="false" [resizable]="false">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="from" class="p-col-12 label-text p-mb-2 p-md-2 p-mb-md-0">{{ 'From' | translate
                            }}</label>
                        <div class="p-col-12 p-md-10">
                            <input id="from" type="email" pInputText [(ngModel)]="inviteClientEmailTemplate['from']"
                                required>
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="to" class="p-col-12 label-text p-mb-2 p-md-2 p-mb-md-0">{{ 'To' | translate
                            }}</label>
                        <div class="p-col-12 p-md-10">
                            <input id="to" type="email" pInputText [(ngModel)]="inviteClientEmailTemplate['to']"
                                required>
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="subject" class="p-col-12 label-text p-mb-2 p-md-2 p-mb-md-0">{{ 'Subject' |
                            translate }}</label>
                        <div class="p-col-12 p-md-10">
                            <input id="subject" type="email" pInputText
                                [(ngModel)]="inviteClientEmailTemplate['subject']" required>
                        </div>
                    </div>
                    <div class="p-field p-grid richtext">
                        <ejs-richtexteditor id='inviteRTE' #inviteRTE [(value)]="inviteEditorValue"
                            [toolbarSettings]='emailTools' [enabled]='true'> </ejs-richtexteditor>
                        <ng-template #valueTemplate>&nbsp;</ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" (click)="sendInviteClient()"
            label="{{ 'Send' | translate }}"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="closeClient()"
            label="{{ 'cancel_button' | translate }}"></button>
    </ng-template>
</p-dialog>