import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  AuthGuard
} from '@app/_services';
import moment from 'moment';
import { Table } from 'primeng/table';
import { timer } from 'rxjs';
import Swal from 'sweetalert2';
declare let numeral: any;
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-entry-list',
  templateUrl: './time-entry-list.component.html'
})
export class TimeEntryListComponent implements OnInit {
  userId;
  staffID;
  clientID;
  clientName;
  staffName;
  activeDate;
  timeSheetList: any = [];
  actualTimeTooltip;
  billableTimeTooltip;
  staffList: any = [];
  staffListOptions: any = [];
  slipID: number = 1;
  slipOptions: any = [];
  user = 'staff';
  tse: any = {};
  dateList: any = [];
  counterList = [];
  activityList: any;
  busy: Promise<any>;
  dateChanged: boolean = false;
  companyGlobalSetting: any;
  public momentWeek: moment.unitOfTime.StartOf = 'isoWeek';
  public startDate = moment().startOf(this.momentWeek).toDate();
  public endDate = moment().endOf(this.momentWeek).toDate();
  userPermissionType: string;
  Ddate;
  totalTime;
  selectedDateIndex = -1;

  userType = 'Employee';
  period = "Today's Date";
  slips = 'All Slips';
  selectedDate = '';
  isBilled: boolean = null;
  public filteredClients: any[];
  public clientList: any = [];
  totalAmtT = 0.0;
  totalAmtT2 = 0.0;
  totalElaspedTime = 0.0;
  expenseTotals = 0.0;
  billableTotals = 0.0;
  tsList: any = [];
  selectedTsList = [];
  idForApprove = [];
  isToday = true;

  searchTmp: any;
  deleteNote: any;
  alertMessage: any;
  totalWeekTime: any;
  totalWeekAmtT: any;
  totalWeekAmtT2: any;
  wkExpenseTotals: any;
  wkbillableTotals: any;

  totalWeekElaspedTime: any;

  isCheckAll = false;
  showStartStopTime = false;
  billingRateChk = false;
  billableTimeChk = false;
  showActualTimeToolTip = false;
  gridcolSpan = 6;
  showBillableTimeToolTip = false;
  isHideBillRateColumn = true;
  displayTimeEntryModal: boolean = false;
  weekDate = new Date();
  weekedDate = new Date();
  selectedWeekDate = new Date();
  public UserLevel: any = null;
  displayExpenseEntryModal: boolean = false;
  tsListcols = [];
  public isAllowUser = false;
  public companyId;
  public userName;
  searchTextStr: any = '';
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  intervalID;
  clientIntervalID;
  companyData;
  public selClient: any = null;

  cellFocused: any = null;
  public cols: any[];
  public _selectedColumns: any[];

  private eventTimeSheetSubscription;
  private eventTimeExpenseSubscription;

  public globalFilterFields: any = [];

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public sharedSrvc: SharedComponentsService,
    public mangoUtils: mangoUtils,
    public translate: TranslateService,
    public auth: AuthGuard
  ) {
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
    this.userPermissionType = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.Title;
    this.UserLevel = this.encrDecSrvc.getObject(AppConstants.staffPermission);
    this.isAllowUser = this.auth.isAllowAccess(16);
    this.tse.TimeStart = new Date(
      moment.utc().year(),
      moment.utc().month(),
      moment.utc().date(),
      moment().hour(),
      moment().minute(),
      0
    );
    this.tse.TimeStop = null;
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    if (
      !this.companyData.HideBillRate ||
      (this.companyData.isRateShowAdmin && this.userPermissionType === 'Administrator')
    ) {
      this.isHideBillRateColumn = false;
      this.gridcolSpan = this.companyData.HideBillRate ? this.gridcolSpan : this.gridcolSpan + 3;
    } else {
      this.isHideBillRateColumn = true;
    }
    this.momentWeek = this.companyData?.TimeEntryFirstDayofWeek
      ? this.companyData.TimeEntryFirstDayofWeek == 'monday'
        ? 'isoWeek'
        : 'week'
      : 'isoWeek';
    this.startDate = moment().startOf(this.momentWeek).toDate();
    this.endDate = moment().endOf(this.momentWeek).toDate();
    this.getAllStaffList();
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Time_&_Expense') },
        { label: this.translate.instant('time-sheet-entry'), icon: 'ic-red' }
      ]);
      this.slipOptions = [
        { label: this.translate.instant('all_slips'), value: 1 },
        { label: this.translate.instant('unbilled_slips'), value: 3 },
        { label: this.translate.instant('billed_slips'), value: 2 }
      ];
      this.initializeColumn();
    });

    this.eventTimeSheetSubscription = this.sharedSrvc.timeEntryDialogData.subscribe(data => {
      // if (data && Object.keys(data).length > 0) {
      this.refresh();
      // }
    });

    this.eventTimeExpenseSubscription = this.sharedSrvc.expenseDialogData.subscribe(data => {
      // if (data && Object.keys(data).length > 0) {
      this.refresh();
      // }
    });
  }

  initializeColumn() {
    const allCols = [
      {
        field: 'SlipMasterID',
        header: this.translate.instant('sys_id'),
        class: 'width-7p p-text-left'
      },
      /*   { field: 'TimeStart', header: this.translate.instant('Start'), class:'width-7p p-text-left', show: true },
        { field: 'TimeStop', header: this.translate.instant('Stop'), class:'width-7p p-text-left', show: true },
        { field: 'Ddate', header: this.translate.instant('date'), class:'width-8p p-text-left', show: true },
        { field: 'ClientName', header: this.translate.instant('client.name'), class:'width-15p p-text-left', show: true },
        { field: 'EngagementName', header: this.translate.instant('timer.engagement'), class:'width-9p p-text-left', show: true }, */
      {
        field: 'Memo',
        header: this.translate.instant('memo'),
        class: 'width-25p p-text-left p-text-nowrap p-text-truncate'
      },
      {
        field: 'Taxable',
        header: this.translate.instant('taxable'),
        class: 'width-6p p-text-center'
      }
      /*  { field: 'TotalTime', header: this.translate.instant('Total'), class:'width-5p p-text-right', show: true },
       { field: 'Billable', header: this.translate.instant('billable'), class:'width-5p p-text-right', show: true },
       { field: 'BillingRate', header: this.translate.instant('rate'), class:'width-5p p-text-right', show: true },
       { field: 'StandardAmount', header: this.translate.instant('amount'), class:'width-7p p-text-right', show: true },
       { field: 'StandardAmount', header: "N/B Amt", class:'width-7p p-text-right', show: true },
       { field: 'StandardAmount', header: this.translate.instant('billing-invoicing.apply-to-expenses'), class:'width-7p p-text-right', show: true },
       { field: 'Billable', header: this.translate.instant('billable'), class:'width-5p p-text-center', show: true },
       { field: 'Approved', header: 'Appr.', class:'width-7p p-text-center', show: true },
       { field: 'isFlatFee', header: this.translate.instant('Flat'), class:'width-3p p-text-center', show: true },
       { field: 'Billed', header: this.translate.instant('billed'), class:'width-4p p-text-center', show: true }, */
    ];

    let selectedCols = [];

    const defaultCols = [
      {
        field: 'SlipMasterID',
        header: this.translate.instant('sys_id'),
        class: 'width-7p p-text-left'
      },
      /*   { field: 'TimeStart', header: this.translate.instant('Start'), class:'width-7p p-text-left', show: true },
        { field: 'TimeStop', header: this.translate.instant('Stop'), class:'width-7p p-text-left', show: true },
        { field: 'Ddate', header: this.translate.instant('date'), class:'width-8p p-text-left', show: true },
        { field: 'ClientName', header: this.translate.instant('client.name'), class:'width-15p p-text-left', show: true },
        { field: 'EngagementName', header: this.translate.instant('timer.engagement'), class:'width-9p p-text-left', show: true }, */
      {
        field: 'Memo',
        header: this.translate.instant('memo'),
        class: 'width-25p p-text-left p-text-nowrap p-text-truncate'
      }
      /*  { field: 'TotalTime', header: this.translate.instant('Total'), class:'width-5p p-text-right', show: true },
       { field: 'Billable', header: this.translate.instant('billable'), class:'width-5p p-text-right', show: true },
       { field: 'BillingRate', header: this.translate.instant('rate'), class:'width-5p p-text-right', show: true },
       { field: 'StandardAmount', header: this.translate.instant('amount'), class:'width-7p p-text-right', show: true },
       { field: 'StandardAmount', header: "N/B Amt", class:'width-7p p-text-right', show: true },
       { field: 'StandardAmount', header: this.translate.instant('billing-invoicing.apply-to-expenses'), class:'width-7p p-text-right', show: true },
       { field: 'Billable', header: this.translate.instant('billable'), class:'width-5p p-text-center', show: true },
       { field: 'Approved', header: 'Appr.', class:'width-7p p-text-center', show: true },
       { field: 'isFlatFee', header: this.translate.instant('Flat'), class:'width-3p p-text-center', show: true },
       { field: 'Billed', header: this.translate.instant('billed'), class:'width-4p p-text-center', show: true }, */
    ];

    /*  this._selectedColumns = defaultCols; */
    // this.cols = allCols;

    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.TimeSheetCols?.length > 0) {
            selectedCols = allCols.filter(col => data.TimeSheetCols.includes(col.field));
          } else {
            selectedCols = [...defaultCols];
          }

          this.cols = [...allCols];

          this._selectedColumns = selectedCols;
          this.globalFilterFields = [
            ...[
              'ClientName',
              'Ddate',
              'EngagementName',
              'StandardAmount',
              'ElaspedTime',
              'BillingRate',
              'TimeStart',
              'TimeStop'
            ],
            ...this._selectedColumns
          ];
        },
        err => {
          selectedCols = [...defaultCols];

          this.cols = [...allCols];

          this.globalFilterFields = [
            ...[
              'ClientName',
              'Ddate',
              'EngagementName',
              'StandardAmount',
              'ElaspedTime',
              'BillingRate',
              'TimeStart',
              'TimeStop'
            ],
            ...this.cols
          ];
        }
      );
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.globalFilterFields = [
      ...[
        'ClientName',
        'Ddate',
        'EngagementName',
        'StandardAmount',
        'ElaspedTime',
        'BillingRate',
        'TimeStart',
        'TimeStop'
      ],
      ...this._selectedColumns
    ];
  }

  removeOffset(date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  updateTS(name, rowData) {
    if (name == 'no') {
      return;
    }
    let isFlatFeeText = '';
    if (rowData.isFlatFee) {
      isFlatFeeText = 'Hourly';
    } else {
      isFlatFeeText = 'Flat Fee';
    }
    rowData.BillingRate = rowData.BillingRate ? rowData.BillingRate : 0;
    rowData.StandardAmount = rowData.StandardAmount ? numeral(rowData.StandardAmount).value() : 0.0;
    rowData['EngagementTypeID'] = rowData.engagementsList.filter(
      item => item.value == rowData.ProjectMasterID
    )[0]['EngagementTypeID'];
    const obj = {
      Ddate: moment(rowData.Ddate).format('MM/DD/YYYY'),
      TimeStart: moment.utc(rowData.TimeStart).local().toDate(),
      TimeStop: rowData.TimeStop ? moment.utc(rowData.TimeStop).local().toDate() : rowData.TimeStop,
      Approved: rowData.Approved,
      Billable: rowData.Billable,
      Billed: rowData.Billed,
      BillingRate: numeral(rowData.BillingRate).value().toString(),
      ClientID: rowData.ClientID,
      ClientName: rowData.ClientName,
      ElaspedTime: rowData.ElaspedTime,
      Description: rowData.Description,
      Memo: rowData.Memo,
      ServiceCode: rowData.ServiceCode,
      ServiceCodeID: rowData.ServiceCodeID,
      StandardAmount: rowData.StandardAmount.toString(),
      TotalTime: rowData.TotalTime,
      StaffID: rowData.StaffID,
      StaffName: rowData.StaffName,
      StaffDeptID: rowData.StaffDeptID,
      StaffPayRate: rowData.StaffPayRate,
      StaffCost: Number(rowData.TotalTime) * Number(rowData.StaffPayRate),
      WorkCodeID: rowData.WorkCodeID,
      OriginatingPartnerID: rowData.OriginatingPartnerID,
      BillingPartnerID: rowData.BillingPartnerID,
      GroupDescriptionID: rowData.GroupDescriptionID,
      GroupDescriptionIDArray: rowData.GroupDescriptionIDArray,
      ClientTypeID: rowData.ClientTypeID,
      ProjectMasterID: rowData.ProjectMasterID,
      OverrideBillingRate: rowData.billingRateChk,
      PrivateMemo: rowData.PrivateMemo,
      EngagementTypeID: rowData.EngagementTypeID,
      IsTimeRecord: 'T',
      WorkLocation: rowData.WorkLocation,
      isFlatFee: rowData.isFlatFee
    };
    if (!obj.TimeStop) {
      delete obj.TimeStop;
    }
    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, rowData.WorkLocation);

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateTimeSheet(rowData.SlipMasterID, obj).subscribe(
      data => {
        rowData['IsColumnChanges'] = false;

        this.mangoAPISrvc.notify(
          'success',
          this.translate.instant('updated'),
          AppConstants.updateMsg
        );
        this.actualTimeTooltip = '0 hours , 0 minutes';
        this.mangoAPISrvc.showLoader(false);
      },
      err => {
        this.mangoAPISrvc.notify('error', 'Error!', err);

        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  editCellComplete(event: any, data?: any) {
    if (event.type == 'focus') {
      return false;
    }
    if (event.data) {
      event.data['IsColumnChanges'] = true;
    } else if (data) {
      data['IsColumnChanges'] = true;
    }
  }

  changeUSMoney(evt: any, data: any) {
    let enteredValue;
    if (evt['target']) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt['value'] ? evt['value'] : evt['value'];
    }
    if (enteredValue == '' && enteredValue != 0) {
      enteredValue = data.amount;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt['target']['value'] = data.amount = '$0.00';
      } else {
        evt.setValue('0.00');
      }
    } else {
      if (data) {
        evt['target']['value'] = data.amount = '$' + numeral(enteredValue).format('0,0.00');
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format('0,0.00'));
      }
    }
  }

  changeTime(evt: any, data: any) {
    let enteredValue;
    if (evt['target']) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt['value'] ? evt['value'] : evt['value'];
    }
    if (enteredValue == '' && enteredValue != 0) {
      enteredValue = data.time;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt['target']['value'] = data.time = '0.00';
      } else {
        evt.setValue('0.00');
      }
    } else {
      if (data) {
        evt['target']['value'] = data.time = numeral(enteredValue).format('0,0.00');
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format('0,0.00'));
      }
    }
  }

  onBillRateChange(evt, data) {
    const newValue = evt.target.value
      ? evt.target.value.replace('$', '').replace(',', '')
      : data['BillingRate'];
    console.log(newValue, parseFloat(data['BillingRate']));
    if (newValue !== data['BillingRate']) {
      data['BillingRate'] = newValue;
      data = this.calculateStandardAmount(data);

      data['IsColumnChanges'] = true;
    }

    this.computeFooter(this.timeSheetList);
  }

  onTotalTimeChange(evt, data) {
    const newValue = evt.target.value ? evt.target.value.replace(',', '') : data['TotalTime'];
    if (newValue !== data['TotalTime']) {
      data['TotalTime'] = newValue;
      data = this.calculateStandardAmount(data);
      data['ElaspedTime'] = newValue;

      data['IsColumnChanges'] = true;
    }

    this.computeFooter(this.timeSheetList);
  }

  calculateStandardAmount(data) {
    if (data['BillingRate']) {
      if (data['Billable']) {
        data['billableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['billableamount'];
      } else {
        data['nonbillableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['nonbillableamount'];
      }
    }

    return data;
  }

  replaceShortcuts2(value, desc, type?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(' ');
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut['Inactive']) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    if (type === 'Memo') desc['Memo'] = valueArr.join(' ');
    else {
      desc['description'] = valueArr.join(' ');
      // this.validateLineItems();
    }
  }

  handleEngagementSelectClick(event, itemData, isLoading) {
    const selectedProjectMasterID = itemData.engagementsList.filter(item => {
      return event.value && item.value == event.value;
    });

    if (selectedProjectMasterID.length == 0) {
      itemData['EngagementName'] = null;
      itemData['ProjectMasterID'] = null;
      itemData['EngagementTypeID'] = null;
      itemData['isBillable'] = true;
      return false;
    }
    if (selectedProjectMasterID.length > 0 && selectedProjectMasterID[0].value == null) {
      itemData['EngagementName'] = null;
      itemData['ProjectMasterID'] = null;
      itemData['EngagementTypeID'] = null;
      itemData['isBillable'] = true;
      return false;
    }
    itemData['EngagementName'] = selectedProjectMasterID[0].label;
    itemData['ProjectMasterID'] = selectedProjectMasterID[0].value;
    itemData['EngagementTypeID'] = selectedProjectMasterID[0].EngagementTypeID;
    itemData['isBillable'] = selectedProjectMasterID[0].isBillable
      ? selectedProjectMasterID[0].isBillable
      : false;
    itemData['isEngagementBillable'] = itemData['isBillable'];
    itemData.MarkSlipsBilled = itemData.MarkSlipsBilled ? itemData.MarkSlipsBilled : false;
    itemData.Billable = itemData.Billable ? itemData.Billable : false;

    if (
      itemData.MarkSlipsBilled == true ||
      itemData.Billable == false ||
      itemData.isBillable == false
    ) {
      itemData['Billable'] = false;
    }
    itemData['IsColumnChanges'] = true;
    for (let totalIndex = 1; totalIndex < 8; ++totalIndex) {
      itemData['weekday' + totalIndex]['Billable'] = itemData['Billable'];
    }
    // this.isValidTimeSheets();
  }

  handleClientSelectClick(value, itemData) {
    if (value.BlockTimeExpenseEntry) {
      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('Warning')}`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: `${this.translate.instant('block-time-entry')}!`
      });
      return;
    }
    this.selClient = value;
    itemData['IsColumnChanges'] = true;
    console.log(itemData);
    itemData['ClientID'] = value.ClientID;
    itemData['ClientName'] = value.ClientName;
    itemData['OriginatingPartnerID'] = value.OriginatingPartnerID;
    itemData['BillingPartnerID'] = value.BillingPartnerID;
    itemData['GroupDescriptionID'] = value.GroupDescriptionID;
    itemData['GroupDescriptionIDArray'] = value.GroupDescriptionIDArray;
    itemData['ClientTypeID'] = value.ClientTypeID;
    itemData['EngagementName'] = null;
    itemData['ProjectMasterID'] = null;
    itemData['EngagementTypeID'] = null;
    itemData['ServiceCode'] = null;
    this.selClient['Billable'] = this.selClient['Billable'] ? this.selClient['Billable'] : false;
    itemData['isClientBillable'] = this.selClient['Billable'];
    value.MarkSlipsBilled = value.MarkSlipsBilled ? value.MarkSlipsBilled : false;
    itemData['MarkSlipsBilled'] = value.MarkSlipsBilled;
    this.editCellComplete(itemData);
    this.getProjects(value.ClientID, itemData);
    // this.isValidTimeSheets();
  }

  getProjects(clientId, itemData) {
    const parent = this;
    itemData.engagementsList = [];
    if (!clientId) {
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(
      function (data: any) {
        const filterDataValue = data.filter(note => note.Inactive == false);
        const filterData = filterDataValue.sort(
          parent.mangoUtils.compareValues('EngagementName', 'asc')
        );
        for (let i = 0; i < filterData.length; i++) {
          filterData[i].isBillable = filterData[i].isBillable ? filterData[i].isBillable : false;
          itemData.engagementsList.push({
            label: filterData[i].EngagementName,
            value: filterData[i].ProjectMasterID,
            EngagementTypeID: filterData[i].EngagementTypeID,
            isBillable: filterData[i].isBillable
          });
        }
        if (itemData.engagementsList.length == 1) {
          itemData['EngagementName'] = itemData.engagementsList[0].label;
          itemData['ProjectMasterID'] = itemData.engagementsList[0].value;
          itemData['EngagementTypeID'] = itemData.engagementsList[0].EngagementTypeID;
          itemData['isBillable'] = itemData.engagementsList[0].isBillable;
          itemData['isEngagementBillable'] = itemData.engagementsList[0].isBillable;
        }
        itemData.engagementsList.splice(0, 0, { label: 'Select Engagement', value: null });
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        item['Billable'] = item['Billable'] ? item['Billable'] : false;
        this.clientList.push(item);
      }
    } else {
      clearInterval(this.clientIntervalID);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }
    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (
          client.ClientName.toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] == false
        ) {
          filtered.push(client);
        } else if (
          client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] != true
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  ngOnInit(): void {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.search();
    parent.mangoAPISrvc.getCompanyMangoDetails(parent.companyId).subscribe(function (data) {
      parent.showStartStopTime = data[0]['StartStopTime'];
      parent.gridcolSpan = parent.showStartStopTime ? 6 : 8;
      parent.mangoAPISrvc.showLoader(false);
      parent.initializeColumn();
    });

    this.clientIntervalID = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  ngAfterViewInit() {
    this.setIntervalForBadge();
  }

  ngOnDestroy() {
    clearInterval(this.intervalID);
    clearInterval(this.clientIntervalID);
    this.eventTimeSheetSubscription.unsubscribe();
    this.eventTimeExpenseSubscription.unsubscribe();
  }

  addBadges() {
    const els = document.querySelectorAll('[class*=add-badge-]');
    for (const el of Array.from(els)) {
      let matches = el.className.match(/add-badge-(\S+)/);
      const badgeVal = matches ? matches[1] : '';
      const bmatches = el.className.match(/b-badge-(\S+)/);
      const badgebVal = bmatches ? bmatches[1] : '';
      const badgeText = badgeVal
        .replace(/\\\-/g, '__dash__')
        .replace(/\-/g, ' ')
        .replace('__dash__', '-');
      const badgeTextNode = document.createTextNode(badgeText);
      matches = el.className.match(/badge-class-(\S+)/);
      const badgeClass = matches ? matches[1] : 'danger';
      const badge = document.createElement('span');
      badge.classList.add('p-badge');
      badge.classList.add('p-ml-2');
      badge.classList.add('z-badge-' + badgebVal);

      if (parseInt(badgeVal) > 0) {
        badge.classList.add('bg-dark-blue');
      } else {
        badge.classList.add('bg-white');
        badge.classList.add('p-d-none');
      }
      badgeClass && badgeClass !== 'none' ? badge.classList.add('p-badge-' + badgeClass) : null;
      badge.appendChild(badgeTextNode);

      el.appendChild(badge);
      el.classList.remove('add-badge-' + badgeVal, 'badge-class-' + badgeClass);
    }
  }

  getAllStaffList() {
    const parent = this;
    let staffSelected = false;
    parent.staffListOptions = [{ label: 'Select Staff', value: -1 }];
    let tempList = [];
    if (this.auth.isAllowAccess(36)) {
      tempList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
      tempList = tempList?.filter(
        staff => staff?.StaffID == this.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive) continue;

      parent.staffListOptions.push({
        label: tempList[i].StaffName,
        value: tempList[i].StaffID
      });
      if (parent.userName == tempList[i].StaffName && parent.staffID == tempList[i].StaffID) {
        parent.selectStaff({
          label: tempList[i].StaffName,
          value: tempList[i].StaffID
        });
        staffSelected = true;
      }
    }
  }

  closeTimeEntryModal(startDate) {
    if (!startDate) {
      return false;
    }
    this.displayTimeEntryModal = false;
    //this.timeEntryForm.staffID = this.staffID;
    //this.timeEntryForm.staffName = this.staffName;
    const self = this;
    if (startDate) {
      this.weekDate = startDate;
      this.selectedWeekDate = startDate;
      this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
      this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
      setTimeout(() => {
        self.search();
      }, 500);
    } else {
      setTimeout(() => {
        self.refresh();
      }, 500);
    }
  }

  search() {
    const self = this;
    self.tsList = [];
    self.selectedTsList = [];
    self.counterList = [];
    const startDate = moment(self.startDate).format('MM-DD-YYYY');
    const endDate = moment(self.endDate).format('MM-DD-YYYY');
    self.getDates(self.startDate, self.endDate);
    self.activeDate = self.startDate;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc
      .getTimeSheet(self.companyId, self.user, self.userId, startDate, endDate)
      .subscribe(
        function (data) {
          self.resetCounter();
          self.timeSheetList = data;
          self.totalAmtT = 0.0;
          self.totalWeekAmtT = 0.0;
          self.totalAmtT2 = 0.0;
          self.totalWeekAmtT2 = 0.0;
          self.totalElaspedTime = 0.0;
          self.expenseTotals = 0.0;
          self.wkExpenseTotals = 0.0;
          self.billableTotals = 0.0;
          self.wkbillableTotals = 0.0;
          self.totalWeekElaspedTime = 0.0;

          self.totalTime = 0.0;
          self.totalWeekTime = 0.0;
          self.tsList = [];

          for (let i = 0; i < self.timeSheetList.length; i++) {
            if (self.timeSheetList[i].TotalTime == '0.00') continue;

            self.timeSheetList[i].index = i;
            self.timeSheetList[i].isShown = true;
            self.timeSheetList[i]['displayDate'] = moment(self.timeSheetList[i].Ddate).format(
              'MM/DD/YYYY'
            );
            self.timeSheetList[i]['timestamp'] = Date.parse(
              moment(self.timeSheetList[i].Ddate).format('MM/DD/YYYY')
            );
            self.timeSheetList[i]['sortableTimeStart'] = self.timeSheetList[i].TimeStart
              ? moment(self.addOffset(new Date(self.timeSheetList[i].TimeStart))).format('HH:mm:ss')
              : '00:00:00';
            self.timeSheetList[i]['sortableTimeStop'] = self.timeSheetList[i].TimeStop
              ? moment(self.addOffset(new Date(self.timeSheetList[i].TimeStop))).format('HH:mm:ss')
              : '00:00:00';
            if (self.isBilled != null && self.isBilled !== self.timeSheetList[i].Billed) {
              self.timeSheetList[i].isShown = false;
              continue;
            }
            const b = self.timeSheetList[i].StandardAmount
              ? numeral(self.timeSheetList[i].StandardAmount).value()
              : 0.0;
            const c = self.timeSheetList[i].ElaspedTime
              ? numeral(self.timeSheetList[i].ElaspedTime).value()
              : 0.0;
            const d = self.timeSheetList[i].TotalTime
              ? numeral(self.timeSheetList[i].TotalTime).value()
              : 0.0;

            const e = self.timeSheetList[i].BilledAmount
              ? numeral(self.timeSheetList[i].BilledAmount).value()
              : 0.0;

            console.log(`debug: ${new Date(self.timeSheetList[i].Ddate)}`);
            if (
              moment(self.timeSheetList[i].Ddate).format('MM-DD-YYYY') ==
              moment(self.dateList[self.selectedDateIndex]).format('MM-DD-YYYY')
            ) {
              self.timeSheetList[i].Ddate = moment(self.timeSheetList[i].Ddate).format(
                'MM/DD/YYYY'
              );
              //new Date(self.timeSheetList[i].Ddate)
              self.tsList.push(self.timeSheetList[i]);
              if (self.timeSheetList[i].Billable) {
                self.totalAmtT = self.totalAmtT + parseFloat(b);
              } else {
                self.totalAmtT2 = self.totalAmtT2 + parseFloat(b);
              }
              self.totalElaspedTime = self.totalElaspedTime + parseFloat(c);

              if (self.timeSheetList[i].Billable) {
                self.totalTime = self.totalTime + parseFloat(d);
              }

              if (
                self.timeSheetList[i].Billable == true &&
                (!self.timeSheetList[i]['IsTimeRecord'] ||
                  self.timeSheetList[i]['IsTimeRecord'] == 'T')
              ) {
                if (self.timeSheetList[i]['isFlatFee'] == true) {
                  self.billableTotals = self.billableTotals + parseFloat(e);
                } else {
                  self.billableTotals = self.billableTotals + parseFloat(b);
                }
              } else if (
                self.timeSheetList[i]['IsTimeRecord'] &&
                self.timeSheetList[i]['IsTimeRecord'] == 'X'
              ) {
                self.expenseTotals = self.expenseTotals + parseFloat(b);
              }
            }

            if (
              self.timeSheetList[i].Billable == true &&
              (!self.timeSheetList[i]['IsTimeRecord'] ||
                self.timeSheetList[i]['IsTimeRecord'] == 'T')
            ) {
              if (self.timeSheetList[i]['isFlatFee'] == true) {
                self.wkbillableTotals = self.wkbillableTotals + parseFloat(e);
              } else {
                self.wkbillableTotals = self.wkbillableTotals + parseFloat(b);
              }
            } else if (
              self.timeSheetList[i]['IsTimeRecord'] &&
              self.timeSheetList[i]['IsTimeRecord'] == 'X'
            ) {
              self.wkExpenseTotals = self.wkExpenseTotals + parseFloat(b);
            }

            if (self.timeSheetList[i].Billable) {
              self.totalWeekAmtT = self.totalWeekAmtT + parseFloat(b);
            } else {
              self.totalWeekAmtT2 = self.totalWeekAmtT2 + parseFloat(b);
            }
            self.totalWeekElaspedTime = self.totalWeekElaspedTime + parseFloat(c);

            if (self.timeSheetList[i].Billable) {
              self.totalWeekTime = self.totalWeekTime + parseFloat(d);
            }

            for (let j = 0; j < self.dateList.length; j++) {
              if (
                moment(self.timeSheetList[i].Ddate).format('MM-DD-YYYY') ==
                moment(self.dateList[j]).format('MM-DD-YYYY')
              ) {
                self.counterList[j] = self.counterList[j] + 1;
              }
            }
          }
          self.selectedDate = moment(self.dateList[0]).format('MM/DD/YYYY');
          // remove items not to be shown
          let items = [];
          for (let i = 0; i < self.timeSheetList.length; i++) {
            if (self.timeSheetList[i].isShown == true) {
              items.push(self.timeSheetList[i]);
            }
          }
          self.timeSheetList = items;
          items = [];
          for (let i = 0; i < self.tsList.length; i++) {
            if (self.tsList[i].isShown == true) {
              self.getProjects(self.tsList[i].ClientID, self.tsList[i]);
              items.push(self.tsList[i]);
            }
          }
          items.filter((item, i) => {
            items[i].StandardAmount = Number(items[i].StandardAmount);
          });
          self.tsList = items;
          setTimeout(() => {
            if (self.selectedDateIndex == 7) self.selectDate(self.selectedDateIndex);
          }, 300);
          self.mangoAPISrvc.showLoader(false);
        },
        error => {
          self.mangoAPISrvc.notify(
            'error',
            self.translate.instant('error'),
            AppConstants.fetchErrorMsg
          );
          self.mangoAPISrvc.showLoader(false);
        }
      );
  }

  getTooltip(data) {
    if (data)
      return (
        Math.round(data.split('.')[0]) +
        ' hours, ' +
        Math.round(parseInt(data.split('.')[1]) * 0.6) +
        ' minutes'
      );
    else return;
  }

  resetCounter() {
    this.counterList = [];
    for (let i = 0; i < 9; ++i) {
      this.counterList.push(0);
    }
  }

  selectOption(id, name) {
    this.user = id;
    this.userType = name;
  }

  selectStaff(opt) {
    const staff = this.staffListOptions.filter(item => {
      return item.value == opt.value;
    })[0];

    this.staffID = staff.value;
    this.staffName = staff.label;
    this.userId = staff.value;

    // this.timeEntryForm.staffID = staff.value;
    // this.timeEntryForm.staffName = staff.label;
  }

  selectUser(opt) {
    this.clientID = opt.ClientID;
    this.clientName = opt.ClientName;
    this.userId = opt.ClientID;
  }

  getDates(startDate, stopDate) {
    const currentDate = startDate;
    this.dateList = [];
    this.counterList = [];
    // this.selectedDateIndex = 0;
    let i = 0;
    while (currentDate <= stopDate) {
      this.dateList.push(new Date(currentDate));
      //this.counterList.push(0);
      // console.log("check selectedDateIndex", moment(currentDate).format("D-M-Y"), moment(this.selectedWeekDate).format("D-M-Y"));
      if (moment(currentDate).format('D-M-Y') == moment(this.selectedWeekDate).format('D-M-Y')) {
        if (this.isToday) {
          this.isToday = false;
          this.selectedDateIndex = i;
        }
      }

      currentDate.setDate(currentDate.getDate() + 1);
      i++;
    }
    // console.log(this.dateList);
  }

  approve() {
    const items = this.selectedTsList;
    const idForApprove = [];
    for (let i = 0; i < items.length; i++) {
      idForApprove.push(items[i].SlipMasterID);
    }

    const obj = { slipMasterIdArr: idForApprove };
    const parent = this;
    parent.mangoAPISrvc.approveTSE(obj).subscribe(function (data) {
      parent.refresh();
    });
  }

  uncheckBilledRecords(event) {
    let uncheckedRecords = false;
    const initialSelected = this.selectedTsList;
    this.selectedTsList
      .filter(item => item.Billed == true)
      .forEach(item => {
        this.dataTableComponent.toggleRowWithCheckbox(event, item);
        uncheckedRecords = true;
      });

    this.selectedTsList = initialSelected.filter(item => item.Billed != true);

    if (uncheckedRecords)
      this.mangoAPISrvc.notify(
        'error',
        'Warning!',
        this.translate.instant('time.cannot_select_billed_records')
      );
  }

  onToggleCheckbox(event) {
    this.uncheckBilledRecords(event);
  }

  onToggleHeaderCheckbox(event) {
    // if(event?.checked)
    this.uncheckBilledRecords(event);
  }

  unselectRows() {
    this.selectedTsList = [];
  }

  selectDate(index) {
    this.dateChanged = true;
    this.searchTextStr = '';
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.mangoAPISrvc.showLoader(true);
    this.selectedDateIndex = index;
    this.tsList = [];
    this.selectedTsList = [];
    this.totalAmtT = this.totalWeekAmtT = 0.0;
    this.totalAmtT2 = this.totalWeekAmtT2 = 0.0;
    this.totalElaspedTime = this.totalWeekElaspedTime = 0.0;
    this.expenseTotals = this.wkExpenseTotals = 0.0;
    this.billableTotals = this.wkbillableTotals = 0.0;
    this.totalTime = this.totalWeekTime = 0.0;
    for (let i = 0; i < this.timeSheetList.length; i++) {
      const b = this.timeSheetList[i].StandardAmount
        ? numeral(this.timeSheetList[i].StandardAmount).value()
        : 0.0;
      const c = this.timeSheetList[i].ElaspedTime
        ? numeral(this.timeSheetList[i].ElaspedTime).value()
        : 0.0;
      const d = this.timeSheetList[i].TotalTime
        ? numeral(this.timeSheetList[i].TotalTime).value()
        : 0.0;

      const e = this.timeSheetList[i].BilledAmount
        ? numeral(this.timeSheetList[i].BilledAmount).value()
        : 0.0;
      if (
        index == 7 ||
        moment(this.timeSheetList[i].Ddate).format('MM-DD-YYYY') ==
          moment(this.dateList[index]).format('MM-DD-YYYY')
      ) {
        this.tsList.push(this.timeSheetList[i]);

        if (this.timeSheetList[i].Billable) {
          this.totalAmtT = this.totalAmtT + parseFloat(b);
        } else {
          this.totalAmtT2 = this.totalAmtT2 + parseFloat(b);
        }
        this.totalElaspedTime = this.totalElaspedTime + parseFloat(c);

        //
        if (
          this.timeSheetList[i].Billable == true &&
          (!this.timeSheetList[i]['IsTimeRecord'] || this.timeSheetList[i]['IsTimeRecord'] == 'T')
        ) {
          if (this.timeSheetList[i]['isFlatFee'] == true) {
            this.billableTotals = this.billableTotals + parseFloat(e);
          } else {
            this.billableTotals = this.billableTotals + parseFloat(b);
          }
        } else if (
          this.timeSheetList[i]['IsTimeRecord'] &&
          this.timeSheetList[i]['IsTimeRecord'] == 'X'
        ) {
          this.expenseTotals = this.expenseTotals + parseFloat(b);
        }

        if (this.timeSheetList[i].Billable) {
          this.totalTime = this.totalTime + parseFloat(d);
        }
      }

      if (this.timeSheetList[i].Billable) {
        this.totalWeekAmtT = this.totalWeekAmtT + parseFloat(b);
      } else {
        this.totalWeekAmtT2 = this.totalWeekAmtT2 + parseFloat(b);
      }

      if (this.timeSheetList[i].Billable) {
        this.totalWeekTime = this.totalWeekTime + parseFloat(d);
      }
      this.totalWeekElaspedTime = this.totalWeekElaspedTime + parseFloat(c);

      if (
        this.timeSheetList[i].Billable == true &&
        (!this.timeSheetList[i]['IsTimeRecord'] || this.timeSheetList[i]['IsTimeRecord'] == 'T')
      ) {
        if (this.timeSheetList[i]['isFlatFee'] == true) {
          this.wkbillableTotals = this.wkbillableTotals + parseFloat(e);
        } else {
          this.wkbillableTotals = this.wkbillableTotals + parseFloat(b);
        }
      } else if (
        this.timeSheetList[i]['IsTimeRecord'] &&
        this.timeSheetList[i]['IsTimeRecord'] == 'X'
      ) {
        this.wkExpenseTotals = this.wkExpenseTotals + parseFloat(b);
      }
    }
    this.mangoAPISrvc.showLoader(false);
  }

  checkRow(event?: any) {
    const item = event.data;
    item.check = true;
  }

  uncheckRow(event?: any) {
    const item = event.data;
    item.check = false;
  }

  getFilteredData(event) {
    // calculate today's total
    const self = this;
    const totalAmtT = event.filteredValue.reduce(function (a, b) {
      if (
        self.dateList[self.selectedDateIndex] &&
        moment(b.Ddate).format('MM-DD-YYYY') !=
          moment(self.dateList[self.selectedDateIndex]).format('MM-DD-YYYY')
      ) {
        return a;
      }
      const c = b.StandardAmount ? numeral(b.StandardAmount).value() : 0.0;
      return a + +c;
    }, 0);
    this.totalAmtT = totalAmtT;

    const totalElaspedTime = event.filteredValue.reduce(function (a, b) {
      if (
        self.dateList[self.selectedDateIndex] &&
        moment(b.Ddate).format('MM-DD-YYYY') !=
          moment(self.dateList[self.selectedDateIndex]).format('MM-DD-YYYY')
      ) {
        return a;
      }
      const c = b.ElaspedTime ? numeral(b.ElaspedTime).value() : 0.0;
      return a + +c;
    }, 0);
    this.totalElaspedTime = totalElaspedTime;

    const expenseTotals = event.filteredValue.reduce(function (a, b) {
      if (
        self.dateList[self.selectedDateIndex] &&
        moment(b.Ddate).format('MM-DD-YYYY') !=
          moment(self.dateList[self.selectedDateIndex]).format('MM-DD-YYYY')
      ) {
        return a;
      }
      let c = 0.0;
      if (b['IsTimeRecord'] && b['IsTimeRecord'] == 'X') {
        c = b.StandardAmount ? numeral(b.StandardAmount).value() : 0.0;
      }
      return a + +c;
    }, 0);
    this.expenseTotals = expenseTotals;

    const billableTotals = event.filteredValue.reduce(function (a, b) {
      if (
        self.dateList[self.selectedDateIndex] &&
        moment(b.Ddate).format('MM-DD-YYYY') !=
          moment(self.dateList[self.selectedDateIndex]).format('MM-DD-YYYY')
      ) {
        return a;
      }
      let c = 0.0;
      if (b['Billable'] == true && (!b['IsTimeRecord'] || b['IsTimeRecord'] == 'T')) {
        if (b['isFlatFee'] == true) {
          c = b.BilledAmount ? numeral(b.BilledAmount).value() : 0.0;
        } else {
          c = b.StandardAmount ? numeral(b.StandardAmount).value() : 0.0;
        }
      }
      return a + +c;
    }, 0);
    this.billableTotals = billableTotals;

    const totalTime = event.filteredValue.reduce(function (a, b) {
      if (
        self.dateList[self.selectedDateIndex] &&
        moment(b.Ddate).format('MM-DD-YYYY') !=
          moment(self.dateList[self.selectedDateIndex]).format('MM-DD-YYYY')
      ) {
        return a;
      }
      const c = b.TotalTime ? numeral(b.TotalTime).value() : 0.0;
      return a + +c;
    }, 0);
    this.totalTime = totalTime;

    // calculate week's total
    const totalWeekAmtT = event.filteredValue.reduce(function (a, b) {
      const c = b.StandardAmount ? numeral(b.StandardAmount).value() : 0.0;
      return a + +c;
    }, 0);
    this.totalWeekAmtT = totalWeekAmtT;

    const totalWeekElaspedTime = event.filteredValue.reduce(function (a, b) {
      const c = b.ElaspedTime ? numeral(b.ElaspedTime).value() : 0.0;
      return a + +c;
    }, 0);
    this.totalWeekElaspedTime = totalWeekElaspedTime;

    const totalWeekTime = event.filteredValue.reduce(function (a, b) {
      const c = b.TotalTime ? numeral(b.TotalTime).value() : 0.0;
      return a + +c;
    }, 0);
    this.totalWeekTime = totalWeekTime;

    const wkExpenseTotals = event.filteredValue.reduce(function (a, b) {
      let c = 0.0;
      if (b['IsTimeRecord'] && b['IsTimeRecord'] == 'X') {
        c = b.StandardAmount ? numeral(b.StandardAmount).value() : 0.0;
      }
      return a + +c;
    }, 0);
    this.wkExpenseTotals = wkExpenseTotals;

    const wkbillableTotals = event.filteredValue.reduce(function (a, b) {
      let c = 0.0;
      if (b['Billable'] == true && (!b['IsTimeRecord'] || b['IsTimeRecord'] == 'T')) {
        if (b['isFlatFee'] == true) {
          c = b.BilledAmount ? numeral(b.BilledAmount).value() : 0.0;
        } else {
          c = b.StandardAmount ? numeral(b.StandardAmount).value() : 0.0;
        }
      }
      return a + +c;
    }, 0);
    this.wkbillableTotals = wkbillableTotals;
  }

  convertNumericHoursToHours(normalHours) {
    const arrTime = normalHours.split('.');
    return arrTime[0] + ':' + Math.round((arrTime[1] * 60) / 100);
  }

  deleteClientAlert(itemData, index) {
    itemData['Billed'] = itemData['Billed'] ? itemData['Billed'] : false;
    itemData['MarkSlipsBilled'] = itemData['MarkSlipsBilled'] ? itemData['MarkSlipsBilled'] : false;
    if (itemData['Billed'] && !itemData['MarkSlipsBilled']) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'This time entry has been billed. Deleting is not allowed.'
      );
      return false;
    } else {
      const parentObj = this;
      Swal.fire({
        title: parentObj.translate.instant('confirmation'),
        text: parentObj.translate.instant('delete_alert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parentObj.translate.instant('yes_delete'),
        cancelButtonText: parentObj.translate.instant('no_delete')
      }).then(result => {
        if (result.value) {
          parentObj.mangoAPISrvc.showLoader(true);
          parentObj.mangoAPISrvc.deleteTimeSheet(itemData.SlipMasterID).subscribe(function (data) {
            parentObj.mangoAPISrvc.showLoader(false);
            //parentObj.onDeleteSuccess(itemData.SlipMasterID);
            parentObj.mangoAPISrvc.notify(
              'success',
              parentObj.translate.instant('Success'),
              AppConstants.deleteMessage
            );
            parentObj.refresh();
          }),
            error => {
              parentObj.mangoAPISrvc.notify(
                'error',
                parentObj.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              parentObj.mangoAPISrvc.showLoader(false);
            };
        }
      });
    }
  }

  changeSelectedWeekDate(date) {
    this.weekDate = new Date(this.selectedWeekDate);
    this.weekedDate = new Date(this.selectedWeekDate);
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.selectedDateIndex = this.weekedDate.getDay();

    this.search();
    this.setIntervalForBadge();
  }

  previous() {
    this.selectedWeekDate = moment(this.selectedWeekDate).subtract(1, 'weeks').toDate();
    this.weekDate = new Date(this.selectedWeekDate);
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
    this.setIntervalForBadge();
  }

  today() {
    this.isToday = true;
    this.weekDate = new Date();
    this.selectedWeekDate = new Date();
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
    this.setIntervalForBadge();
  }

  next() {
    this.selectedWeekDate = moment(this.selectedWeekDate).add(1, 'weeks').toDate();
    this.weekDate = new Date(this.selectedWeekDate);
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
    this.setIntervalForBadge();
  }

  refresh() {
    this.startDate = moment(this.startDate).subtract(7, 'days').toDate();
    this.search();
    this.setIntervalForBadge();
  }

  setIntervalForBadge() {
    this.intervalID = setInterval(() => {
      if (document.querySelectorAll('[class*=add-badge-]').length > 0) {
        clearInterval(this.intervalID);
        this.addBadges();
      }
    }, 500);
  }

  formateNumber(item) {
    return item;
  }

  get showApproveBtn() {
    return this.selectedTsList.length > 0;
  }
  addOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = new Date(date).getTime() + offset * 60 * 1000;
    return new Date(time);
  }

  updateStartStopTime() {
    const parent = this;
    parent.gridcolSpan = parent.showStartStopTime ? 13 : 11;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc
      .updateStartStopTime({ StartStopTime: parent.showStartStopTime })
      .subscribe(function (data) {
        parent.initializeColumn();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      });
  }

  selectSlips(opt, index) {
    this.slips = opt;
    switch (index) {
      case 1:
        this.isBilled = null;
        break;
      case 2:
        this.isBilled = true;
        break;
      case 3:
        this.isBilled = false;
        break;
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.computeFooter(this.timeSheetList);
  }

  onFilter(obj) {
    if (!this.dateChanged) {
      this.filteredItemsSize = obj.filteredValue.length;
      this.computeFooter(obj.filteredValue);
    } else {
      this.filteredItemsSize = -1;
      this.dateChanged = false;
    }
  }

  isLocked(ddate) {
    if (!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)) {
      if (this.companyData.isLockTime && parseInt(this.companyData.LockTimeDays) > 0) {
        const dDate = moment(ddate).format('MM/DD/YYYY');
        const dateNow = moment().format('MM/DD/YYYY');
        return moment(dateNow).diff(moment(dDate), 'days') > this.companyData.LockTimeDays;
      }
    }
    return false;
  }

  verifyWeekendDay(date) {
    const getdate = new Date(date);
    const dateweek = getdate.getDay();
    if (dateweek === 0 || dateweek === 6) {
      return true;
    } else {
      return false;
    }
  }

  openTimeEntryOrExpenseEntryDailog(data?: any, isCreate?: any) {
    if (!data.IsTimeRecord || data.IsTimeRecord == 'T') {
      if (data && !isCreate) {
        data['Billed'] = data['Billed'] ? data['Billed'] : false;
        // this.timeEntryForm.staffID = this.staffID;
        // this.timeEntryForm.staffName = this.staffName;
        // this.timeEntryForm.isUpdated = false;
        //this.timeEntryForm.editTSModal(JSON.parse(JSON.stringify(data)));
        data['MarkSlipsBilled'] = data['MarkSlipsBilled'] ? data['MarkSlipsBilled'] : false;

        data['isEditFlow'] = true;
        this.sharedSrvc.openTimeEntryDialog(data);
      } else {
        let selectedDate = null;
        /*if (this.verifyWeekendDay(this.weekedDate)) {
          //selectedDate = this.weekedDate;
          selectedDate = this.dateList[this.selectedDateIndex];
        } else {
          if (this.selectedDateIndex < 7) {
            selectedDate = this.dateList[this.selectedDateIndex];
          }
        }*/
        if (this.selectedDateIndex < 7) {
          selectedDate = this.dateList[this.selectedDateIndex];
        }
        //this.timeEntryForm.isUpdated = false;
        data['isEditFlow'] = false;
        data['selectedDate'] = selectedDate || new Date();
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.getStaffOtherSettings().subscribe(
          (result: any) => {
            if (result.isUsedLastStartTime && this.tsList?.length > 0) {
              data['LatestEndTime'] = this.mangoUtils.sortByFiled(this.tsList, 'sortableTimeStop')[
                this.tsList.length - 1
              ]['sortableTimeStop'];
            }
            this.mangoAPISrvc.showLoader(false);
            this.sharedSrvc.openTimeEntryDialog(data);
          },
          err => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
          }
        );
      }
      //this.displayTimeEntryModal = true;
    } else {
      let selectedDate = null;
      if (this.selectedDateIndex < 7) {
        selectedDate = this.dateList[this.selectedDateIndex];
      }
      if (!isCreate) {
        data['isEditFlow'] = true;
        data['selectedDate'] = selectedDate;
        this.sharedSrvc.openExpenseDialog(data);
      } else {
        const obj = {};
        obj['isEditFlow'] = false;
        obj['ClientID'] = 'Header';
        obj['selectedDate'] = selectedDate;
        this.sharedSrvc.openExpenseDialog(obj);
      }
      // this.displayExpenseEntryModal = true;
    }
    this.mangoAPISrvc.showLoader(false);
  }

  computeFooter(list) {
    let totalTime = 0;
    let totalBillableTime = 0;
    let totalBillableAmount = 0;
    let totalNonBillableAmount = 0;
    let totalExpenses = 0;

    let totalWeekTime = 0;
    let totalWeekBillableTime = 0;
    let totalWeekBillableAmount = 0;
    let totalWeekNonBillableAmount = 0;
    let totalWeekExpenses = 0;

    list.forEach(timeSlip => {
      if (
        this.selectedDateIndex !== 7 &&
        this.compareDate(this.dateList[this.selectedDateIndex], timeSlip.Ddate)
      ) {
        totalTime += numeral(timeSlip.ElaspedTime).value();
        if (timeSlip.Billable) {
          totalBillableTime += numeral(timeSlip.TotalTime).value();
          totalBillableAmount += numeral(timeSlip.StandardAmount).value();
        } else {
          totalNonBillableAmount += numeral(timeSlip.StandardAmount).value();
        }

        if (timeSlip.IsTimeRecord === 'X') {
          totalExpenses += numeral(timeSlip.StandardAmount).value();
          totalBillableAmount -= numeral(timeSlip.StandardAmount).value();
        }
      }

      if (this.selectedDateIndex == 7) {
        totalWeekTime += numeral(timeSlip.ElaspedTime).value();
        if (timeSlip.Billable) {
          totalWeekBillableTime += numeral(timeSlip.TotalTime).value();
          totalWeekBillableAmount += numeral(timeSlip.StandardAmount).value();
        } else {
          totalWeekNonBillableAmount += numeral(timeSlip.StandardAmount).value();
        }

        if (timeSlip.IsTimeRecord === 'X') {
          totalWeekExpenses += numeral(timeSlip.StandardAmount).value();
          totalWeekBillableAmount -= numeral(timeSlip.StandardAmount).value();
        }
      }
    });

    if (this.selectedDateIndex !== 7) {
      this.timeSheetList.forEach(timeSlip => {
        totalWeekTime += numeral(timeSlip.ElaspedTime).value();
        if (timeSlip.Billable) {
          totalWeekBillableTime += numeral(timeSlip.TotalTime).value();
          totalWeekBillableAmount += numeral(timeSlip.StandardAmount).value();
        } else {
          totalWeekNonBillableAmount += numeral(timeSlip.StandardAmount).value();
        }

        if (timeSlip.IsTimeRecord === 'X') {
          totalWeekExpenses += numeral(timeSlip.StandardAmount).value();
          totalWeekBillableAmount -= numeral(timeSlip.StandardAmount).value();
        }
      });
    }

    if (this.selectedDateIndex === 7) {
      totalTime = totalWeekTime;
      totalBillableTime = totalWeekBillableTime;
      totalBillableAmount = totalWeekBillableAmount;
      totalNonBillableAmount = totalWeekNonBillableAmount;
      totalExpenses = totalWeekExpenses;
    }

    //Daily Footer
    this.totalElaspedTime = totalTime;
    this.totalTime = totalBillableTime;
    this.billableTotals = totalBillableAmount;
    this.totalAmtT2 = totalNonBillableAmount;
    this.expenseTotals = totalExpenses;
    //Weekly Footer
    this.totalWeekElaspedTime = totalWeekTime;
    this.totalWeekTime = totalWeekBillableTime;
    this.wkbillableTotals = totalWeekBillableAmount;
    this.totalWeekAmtT2 = totalWeekNonBillableAmount;
    this.wkExpenseTotals = totalWeekExpenses;
  }

  /**
   * onDeleteSuccess:
   * - Will remove the deleted timeSlip from the data instead of refetching data again
   * - After removing, will recompute the footer total using computeFooter function
   *
   * computeFooter:
   * - In inline editing, FE changes the data source directly (this.timeSlips)
   * - Instead of refetching the data again after saving a row, FE can just re-compute the total based on the (this.timeSlips)
   */
  onDeleteSuccess(id) {
    const newTimeSlips = this.timeSheetList.filter(timeSlip => timeSlip.SlipMasterID !== id);

    this.timeSheetList = newTimeSlips;
    this.computeFooter(this.timeSheetList);
  }

  compareDate(dateTimeA, dateTimeB) {
    return moment.utc(dateTimeB).format('MM-DD-YYYY') == moment(dateTimeA).format('MM-DD-YYYY');
  }

  onEditInit(event) {
    this.cellFocused = event;
  }

  onEditComplete() {
    this.cellFocused = null;
  }

  onEditCancel() {
    this.cellFocused = null;
  }

  cancelEditing(event, rowData) {
    if (rowData.Billed == true) {
      this.cellFocused = null;
      $(this.dataTableComponent.editingCell).removeClass('ui-editing-cell');
      this.dataTableComponent.editingCell = null;
    }
  }

  getOpenTimeRecords(arr) {
    return arr?.filter(item => item.IsTimeRecord == 'T');
  }

  getExpenseRecords(arr) {
    return arr?.filter(item => item.IsTimeRecord != 'T');
  }

  onChangeSelectedCols(event) {
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { TimeSheetCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        err => {}
      );
  }
}
