import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';

@Injectable()
export class AuthService{
  redirectUrl: string;

  constructor(private http: HttpClient) {   
  }
  
  getUserPermissonsData(id){
    return this.http.get(environment.API_URL + '/staff/permission/' + id)
      .toPromise().then((response: any) => response)
      .catch(this.handleError);
  }

  getPlanInformation() {
    return this.http.get(environment.API_URL + '/company/planSubscription')
      .toPromise().then((response:any) => response);
  }


  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  
}
