<div class="client-mails">
    <div class="layout-content">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="logsData" [rowHover]="true" [responsive]="true" [rows]="10"
                        dataKey="message-id" selectionMode="single" [pageLinks]="3" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [multiSortMeta]="[{field: 'date', order: -1}, {field: 'subject', order: 1}]" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'"
                        [globalFilterFields]="['event','date']" [sortOrder]="-1" sortField="date" [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                <span class="pull-right">
                                    <label class="label-text p-mr-2">{{'Date Options' | translate}}</label>
                                    <p-dropdown [options]="dateRangeList" [(ngModel)]="dateRange" class="p-mr-3"
                                        (ngModelChange)="changeDateTypes()"
                                        scrollHeight="500px" placeholder="Select Date" required></p-dropdown>
                                    <label class="label-text p-mr-2">{{'date-from' | translate}}</label>
                                    <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" class="p-mr-3" (onSelect)="changeDateFrom()"
                                        [(ngModel)]="dateFrom" dateFormat="mm/dd/yy" [readonlyInput]="true" [maxDate]="dateTo"></p-calendar>
                                    <label class="label-text p-mr-2">{{'date-to' | translate}}</label>
                                    <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                        [(ngModel)]="dateTo" dateFormat="mm/dd/yy" [readonlyInput]="true" [minDate]="dateFrom" [maxDate]="maxDate" (onSelect)="changeDateTo()"></p-calendar>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="p-text-center width-8p" pSortableColumn="event" pResizableColumn>{{
                                    'client.event' | translate }}
                                    <p-sortIcon field="event"></p-sortIcon>
                                </th>
                                <th class="p-text-center width-10p" pSortableColumn="date" pResizableColumn>{{ 'date' |
                                    translate }}
                                    <p-sortIcon field="date"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-20p">{{ 'client.to_mail' | translate }}</th>
                                <th pResizableColumn class="width-35p">{{ 'Subject' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr (click)="onRowSelect(rowData)" class="cursor-hand">
                                <td class="width-8p p-text-center">
                                    <button pButton pRipple type="button" label="Opened" style="pointer-events: none; cursor: pointer;" 
                                        *ngIf="rowData.event === 'opened'"
                                        class="p-button-rounded p-button-opened email-button"></button>
                                    <button pButton pRipple type="button" label="Delivered" style="pointer-events: none; cursor: pointer;" 
                                        class="p-button-rounded p-button-delivered email-button"
                                        *ngIf="rowData.event === 'delivered'"></button>
                                    <button pButton pRipple type="button" *ngIf="rowData.event === 'clicks'" style="pointer-events: none; cursor: pointer;" 
                                        label="Clicked" class="p-button-rounded p-button-clicked email-button"></button>
                                    <button pButton pRipple type="button" *ngIf="rowData.event === 'hardBounces'" style="pointer-events: none; cursor: pointer;" 
                                        label="Hard Bounce"
                                        class="p-button-rounded p-button-hardbounce email-button"></button>
                                    <button pButton pRipple type="button" *ngIf="rowData.event === 'softBounces'" style="pointer-events: none; cursor: pointer;" 
                                        label="Soft Bounce"
                                        class="p-button-rounded p-button-softbounce email-button"></button>
                                    <button pButton pRipple type="button" *ngIf="rowData.event === 'blocked'" style="pointer-events: none; cursor: pointer;" 
                                        label="Blocked"
                                        class="p-button-rounded p-button-blocked email-button"></button>
                                    <button pButton pRipple type="button" *ngIf="rowData.event === 'deferred'" style="pointer-events: none; cursor: pointer;" 
                                        label="Deferred"
                                        class="p-button-rounded p-button-deferred email-button"></button>
                                </td>
                                <td class="width-10p p-text-center">
                                    {{rowData.date | date:'medium'}}
                                </td>
                                <td class="width-20p">
                                    {{rowData.email}}
                                </td>
                                <td class="width-35p">
                                    {{rowData.subject}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <p-message severity="info" text="{{ 'no_emails_sent' | translate }}"
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar [(visible)]="viewDetails" position="right"
  [style]="{'min-width':'42%','min-height':'100%','background':'#8e8f92'}" [baseZIndex]="10000">
  <div class="card p-my-0 p-pt-0 p-pb-5" style="min-height: calc(100vh - 34px)">
    <div class="card p-pb-1">
      <div #htmlBody id="htmlBody"></div> 
    </div>
  </div>
</p-sidebar>