import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils, AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { MenuItem, SelectItem } from 'primeng/api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html'
})
export class MainDashboardComponent implements OnInit {
  public ddmMainForm: UntypedFormGroup;
  public typeofViews: any[];
  public AllStaffsTypes: any[];
  public userssCols: any[];
  @ViewChild("searchValue") searchValue;
  public usersDataSource: any = [];
  public tasksDataSource: any = [];
  public projectFeedDataSource: any = [];
  public taskFeedDataSource: any = [];
  public tagsList: any = [];
  busy: Promise<any>;
  public companyId;
  public resourceId;
  staffName;
  ddmMainViewType = null;
  ddmMainUserType = null;
  ddmMainDateRange = null;
  public dashBoardObj: any = {};
  public chartData: any = {};
  public tagsSettingsData: any = {};
  options: any;
  public isDisplayDetailsTable: boolean = false;
  public isRadioSelected: boolean = false;
  public templateDataSource: any = [];
  filteredItemsSize = -1;
  searchTextStr: any = "";
  selectedTemplate
  buttonsList: MenuItem[];
  dateRangeOptions: SelectItem[] = [];
  ddmSettings: any;
  public globalFilterColumns: any = [];
  private hasDataLoaded = false;

  public staffStatisticData = (
    {
      pendingProjectSummaryCount: 0,
      inProgressProjectSummaryCount: 0,
      forReviewProjectSummaryCount: 0,
      dueTodayProjectSummaryCount: 0,
      dueThisWeekProjectSummaryCount: 0,
      dueNextWeekProjectSummaryCount: 0,
      overdueProjectSummaryCount: 0
    }
  );

  constructor(
    private changeDetect: ChangeDetectorRef,
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private mangoUtils: mangoUtils,
    private auth: AuthGuard,
    private _fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {
    // const interval = setInterval(() => {
    //   if(!this.translate.translations[this.translate.currentLang])
    //     return;
    //   clearInterval(interval);
    //   this.initTranslations();
    // }, 300);

    this.translate.reloadLang(this.translate.currentLang).subscribe(() => {
      this.initTranslations();
    })
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.staffName = this.encrDecSrvc.getObject(AppConstants.userName);
    // this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
    //this.initializeColumns();
  }

  get isAllowFilterOtherUser() {
    return this.auth.isAllowAccess(23)
  }

  initializeColumns() {
    this.mangoAPISrvc.getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID)).subscribe((cols: any) => {
      if (cols.PMDashboardSettings?.length > 0) {
        this.ddmMainUserType = cols.PMDashboardSettings[0] ? parseInt(cols.PMDashboardSettings[0]) : null;
        this.ddmMainDateRange = cols.PMDashboardSettings[1] ? cols.PMDashboardSettings[1] : 'All';
      }
      this.ddmMainForm.controls['dateRange'].setValue(this.ddmMainDateRange);
      this.globalFilterColumns = ['TaskDescription'];

      // this.initializeForm();
    });
  }

  initTranslations() {
    this.typeofViews = [{ label: this.translate.instant('Projects'), value: 'projects' }, { label: this.translate.instant('Tasks'), value: 'tasks' }];
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('dashboard'), icon: 'ic-red' }
    ]);
    this.buttonsList = [
      {
        label: this.translate.instant('New-Project'), icon: 'fal fa-plus', routerLink: ['/project-management/projectDetails'],
      },
      {
        label: this.translate.instant('create_company_template'), icon: 'fal fa-plus', command: () => {
          this.isDisplayDetailsTable = true;
          const interval = setInterval(() => {
            if (!this.searchValue) return;
            clearInterval(interval);
            this.searchValue.nativeElement.focus();
          })
        }
      },
      {
        label: this.translate.instant('create_new_template'), icon: 'fal fa-plus', routerLink: ['/project-management/createTemplate'],
      },
    ];
  }

  ngOnInit(): void {
    this.searchTextStr = this.encrDecSrvc.getObject(
      "projTemplate_" + AppConstants.SearchString
    );

    this.dateRangeOptions = [
      { label: "All Dates", value: "All" },
      { label: "This Month", value: "Month" },
      { label: "This Quarter", value: "Quarter" },
      { label: "This Year", value: "Year" },
      { label: "Next Month", value: "NextMonth" },
      { label: "Next Quarter", value: "NextQuarter" },
      { label: "Next Year", value: "NextYear" },
    ];

    this.ddmMainUserType = this.encrDecSrvc.getObject(AppConstants.ddmMainUserType) ? this.encrDecSrvc.getObject(AppConstants.ddmMainUserType) : this.resourceId;
    this.ddmMainViewType = this.encrDecSrvc.getObject(AppConstants.ddmMainViewType) ? this.encrDecSrvc.getObject(AppConstants.ddmMainViewType) : 'projects';
    this.ddmMainDateRange = this.encrDecSrvc.getObject(AppConstants.ddmMainDateRange) ? this.encrDecSrvc.getObject(AppConstants.ddmMainDateRange) : 'All';
    this.fetchDdmSettings();
    this.AllStaffsTypes = [];
    this.initializeForm();
    this.initializeColumns();
    this.getStaffList();

    if(
          (
                this.ddmMainViewType
            === "projects"
          )
    ){
      this.getDashboardData( true );
    }
    else
    if(
          (
                this.ddmMainViewType
            === "tasks"
          )
    ){
      this.getDashboardTaskData( true );
    }
    else{
      this.getDashboardData( true );
    }

    this.getProjectFeed();
    this.getCompanyTemplateDetails();
    const parent = this;

    // this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
  }

  initializeForm() {
    const parent = this;
    this.ddmMainForm = this._fb.group({
      ViewType: [parent.ddmMainViewType, [<any>Validators.required]],
      staffId: [parent.ddmMainUserType],
      dateRange: [parent.ddmMainDateRange]
    });
    this.getStaffDashboard();
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(
      "projTemplate_" + AppConstants.SearchString,
      ""
    );
  }

  ngAfterViewInit() {
    this.searchValue?.nativeElement.focus();
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject('projTemplate_' + AppConstants.SearchString, obj.filters.global.value);
  }

  getCompanyTemplateDetails() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyId).subscribe((data) => {
      parent.templateDataSource = data;
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {

      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    })
  }

  radioCompanyClicked(event, item) {
    this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
    this.isRadioSelected = true;
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(item));
  }

  createProjectScreen() {
    this.router.navigate(['project-management/projectDetails']);
  }

  async viewChanges(isInitialLoad?, reset?) {
    if(reset) {
      this.hasDataLoaded = false;
      this.changeDetect.detectChanges();
    }
    this.encrDecSrvc.addObject(AppConstants.ddmMainViewType, this.ddmMainForm.value.ViewType);
    this.encrDecSrvc.addObject(AppConstants.ddmMainUserType, this.ddmMainForm.value.staffId);

    if (this.ddmSettings?.isShowProjectsView && this.ddmMainForm.value.staffId) {
      const values:any = await this.mangoAPISrvc.getPermissionByStaffId(this.ddmMainForm.value.staffId).toPromise();
      if (values?.Title != "Administrator") {
        const projects:any = await this.mangoAPISrvc.getProjectsByStaffID(this.ddmMainForm.value.staffId).toPromise();
        if (projects.length == 0) {
          this.typeofViews = [{ label: this.translate.instant('Tasks'), value: 'tasks' }];
          this.ddmMainForm.value.ViewType = "tasks";
          this.ddmMainViewType = "tasks";
          this.ddmMainForm.get( 'ViewType' ).setValue("tasks");
          this.changeDetect.detectChanges();
        }
      }
    }

    if(
          (
                this.ddmMainForm.value.ViewType
            === "projects"
          )
    ){
      this.getDashboardData( false );
    }
    else
    if(
          (
                this.ddmMainForm.value.ViewType
            === "tasks"
          )
    ){
      this.getDashboardTaskData( false );
    }
    else{
      this.getDashboardData( false );
    }

    this.getProjectFeed();
    if (!isInitialLoad) this.onDashBoardFilterChange();
  }

  getDashboardTaskData(isLoading?: boolean) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getDashboardTaskData(parent.companyId, parent.ddmMainForm.value.staffId, parent.ddmMainForm.value.dateRange).subscribe((data) => {
      parent.dashBoardObj['today'] = data['today'] || 0;
      parent.dashBoardObj['thisweek'] = data['thisweek'] || 0;
      parent.dashBoardObj['nextweek'] = data['nextweek'] || 0;
      parent.dashBoardObj['pastdue'] = data['pastdue'] || 0;
      const dashboardProjectTags = parent.tagsSettingsData.DashboardProjectTags
        ? parent.tagsSettingsData.DashboardProjectTags
        : "both";
      parent.mangoAPISrvc
        .getTaskStatusSummary(
          parent.companyId,
          parent.ddmMainForm.value.staffId,
          parent.ddmMainForm.value.dateRange
        )
        .subscribe(
          (resultTaskStatSummary: any) => {
            parent.dashBoardObj.pending = resultTaskStatSummary.pending
              ? resultTaskStatSummary.pending
              : 0;
            parent.dashBoardObj.inprogress = resultTaskStatSummary.ready
              ? resultTaskStatSummary.ready
              : 0;
            parent.dashBoardObj.review = resultTaskStatSummary.active
              ? resultTaskStatSummary.active
              : 0;
            const arrObj = [parent.dashBoardObj.pending, parent.dashBoardObj.inprogress];

            parent.chartData = {
              labels: ['Pending', 'Ready to Work'],
              datasets: [
                {
                  data: arrObj,
                  backgroundColor: [
                    "#0099ff",
                    "#33cc66"
                  ],
                  hoverBackgroundColor: [
                    "#0099ff",
                    "#33cc66"
                  ]
                }]
            };
            parent.options = {
              legend: {
                display: false
              }
            };

            parent.mangoAPISrvc
              .getTaskSummary(
                parent.companyId,
                parent.ddmMainForm.value.staffId,
                parent.ddmMainForm.value.dateRange
              )
              .subscribe(
                (resultTaskSummary: any) => {
                  parent.tasksDataSource = resultTaskSummary;
                },
                (err) => console.log(err)
              );
          },
          (err) => console.log(err)
        );
      if (isLoading && isLoading === true) {
        setTimeout(() => {
          parent.viewChanges(true);
        }, 100);
      }
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    });
  }

  redirectProject() {
    this.router.navigate(['project-management/projectDetails']);
  }

  fetchDdmSettings() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe((item: any) => {
      if (item.length > 0) {
        const data = item[0];
        parent.ddmSettings = data;
        parent.encrDecSrvc.addObject(AppConstants.companyTags, data);

        data.CompanyID = data.CompanyID != '' ? data.CompanyID : parseInt(parent.encrDecSrvc.getObject(AppConstants.companyID));
        data.DefaultUserID = data.DefaultUserID ? data.DefaultUserID : parseInt(parent.encrDecSrvc.getObject(AppConstants.staffID));
        data.DefaultManagerID = data.DefaultManagerID ? data.DefaultManagerID : parseInt(parent.encrDecSrvc.getObject(AppConstants.staffID));

        parent.tagsSettingsData = data;
      }
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {

      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    });
  }

  getDashboardData(isLoading: any) {
    if (this.hasDataLoaded) {
      return;
    }
    this.hasDataLoaded = true;
    const parent = this;
    parent.tagsList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getDdmDashboardMain(parent.companyId, parent.ddmMainForm.value.staffId, 'both', parent.ddmMainForm.value.dateRange).subscribe((data) => {
      parent.mangoAPISrvc.getProjectsForDashboard(parent.companyId, parent.ddmMainForm.value.staffId, 'both', parent.ddmMainForm.value.dateRange).subscribe((resultProject: any) => {
        this.processProjects(resultProject);
        this.updateDashboard(data);
       /*  if (Object.keys(data[0]).length > 0) {
          for (let index = 0; index < Object.keys(data[0]).length; index++) {
            const key = Object.keys(data[0])[index];
            data[0][key] = data[0][key] ? parseInt(data[0][key]) : 0;
            //
            if (key.indexOf('tag') >= 0 && data[0][key] > 0) {
              let tagObj = {}
              let label = key ? key[0].toUpperCase() + key.slice(1) : '';
              tagObj['name'] = key;
              tagObj['label'] = parent.tagsSettingsData[label];

              tagObj['value'] = data[0][key] + " " + `${this.translate.instant('project')}(s)`
              tagObj['class'] = label;
              tagObj['index'] = key.substring(key.length, key.length - (key.length == 5 ? 2 : 1));
              parent.tagsList.push(tagObj);
            }
          }
        } */
        parent.mangoAPISrvc.showLoader(false);
        if (isLoading && isLoading === true) {
          setTimeout(() => {
            this.viewChanges(true);
          }, 100);
        }

      })
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    });
  }

  updateDashboard(data: any) {
    this.dashBoardObj['today'] = data[0]['today'] || 0;
    this.dashBoardObj['thisweek'] = data[0]['thisweek'] || 0;
    this.dashBoardObj['nextweek'] = data[0]['nextweek'] || 0;
    this.dashBoardObj['pastdue'] = data[0]['pastdue'] || 0;

    this.dashBoardObj.pending = data[0].pending ||  0;
    this.dashBoardObj.inprogress = data[0].inprogress || 0;
    this.dashBoardObj.review = data[0].review || 0;

    const arrObj = [this.dashBoardObj.pending, this.dashBoardObj.inprogress, this.dashBoardObj.review];

    this.chartData = {
      labels: ['Pending', 'In Progress', 'Ready for Review'],
      datasets: [
        {
          data: arrObj,
          backgroundColor: [
            "#0099ff",
            "#33cc66",
            "#9900cc"
          ],
          hoverBackgroundColor: [
            "#0099ff",
            "#33cc66",
            "#9900cc"
          ]
        }]
    };
    this.options = {
      legend: {
        display: false
      }
    };
  }

  processProjects(resultProject: any) {
    const allTags = this.tagsSettingsData.TagArray2 || [];
    allTags.forEach((item, i) => {
      if (item[0] !== '') {
        const tagObj = {
          name: "tag" + (i + 1),
          label: item[0],
          value: this.countProjects(i + 1, resultProject) + " " + `${this.translate.instant('project')}(s)`,
          projCount: this.countProjects(i + 1, resultProject),
          class: "Tag" + (i + 1),
          index: (i + 1).toString(),
          color: item[1],
          textColor: item[1] !== '' ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(item[1])) : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb('#ffffff'))
        };
        this.tagsList.push(tagObj);
      }
    });
  }

  countProjects(index, projects){
    let counter = 0;
    for(let i = 0; i < projects.length; i++){
        if(projects[i].Tags && projects[i].Tags.includes(index)){
          counter++;
        }
    }
    return counter;
  }

  getStaffDashboard() {
    const parent = this;
    parent.mangoAPISrvc.showLoader( true );

    this.staffStatisticData = (
      {
        pendingProjectSummaryCount: 0,
        inProgressProjectSummaryCount: 0,
        forReviewProjectSummaryCount: 0,
        dueTodayProjectSummaryCount: 0,
        dueThisWeekProjectSummaryCount: 0,
        dueNextWeekProjectSummaryCount: 0,
        overdueProjectSummaryCount: 0
      }
    );

    parent.mangoAPISrvc
    .getDdmDashboardStaff(
      parent.companyId,
      parent.ddmMainForm.value.dateRange
    )
    .subscribe(
      (
        ( data: any ) => {
          parent.mangoAPISrvc.showLoader( false );

          parent.usersDataSource = (
            data?.filter(
              (
                ( item ) => (
                  item.StaffID !== null
                )
              )
            )?.map(
              (
                ( statisticData ) => {
                  (
                        this.staffStatisticData
                        .pendingProjectSummaryCount
                    +=  (
                          parseInt( statisticData.pending ) || 0
                        )
                  );

                  (
                        this.staffStatisticData
                        .inProgressProjectSummaryCount
                    +=  (
                          parseInt( statisticData.inprogress ) || 0
                        )
                  );

                  (
                        this.staffStatisticData
                        .forReviewProjectSummaryCount
                    +=  (
                          parseInt( statisticData.review ) || 0
                        )
                  );

                  (
                        this.staffStatisticData
                        .dueTodayProjectSummaryCount
                    +=  (
                          parseInt( statisticData.today ) || 0
                        )
                  );

                  (
                        this.staffStatisticData
                        .dueThisWeekProjectSummaryCount
                    +=  (
                          parseInt( statisticData.thisweek ) || 0
                        )
                  );

                  (
                        this.staffStatisticData
                        .dueNextWeekProjectSummaryCount
                    +=  (
                          parseInt( statisticData.nextweek ) || 0
                        )
                  );

                  (
                        this.staffStatisticData
                        .overdueProjectSummaryCount
                    +=  (
                          parseInt( statisticData.pastdue ) || 0
                        )
                  );

                  return  (
                            statisticData
                          );
                }
              )
            )
          );

        }
      ),

      (
        ( error ) => {
          parent.mangoAPISrvc.showLoader( false );

          parent.mangoAPISrvc.notify( 'error', 'Error!', AppConstants.fetchErrorMsg );
        }
      )
    );
  }

  getProjectFeed() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getProjectFeed(parent.companyId).subscribe((data: any) => {
      parent.projectFeedDataSource = data.map(item => {
        this.getStaffNames(item["UserAssignedIDArray"], item)
        return item;
      });
      parent.taskFeedDataSource = data
        .map((item) => {
          this.getStaffNames(item["UserAssignedIDArray"], item);
          return item;
        })
        .filter(
          (item) =>
            item.isProject !== "P" &&
            (!parent.ddmMainForm.value.staffId || item.UserAssignedIDArray?.includes(parent.ddmMainForm.value.staffId))
        );
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {

      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    });
  }

  getStaffNames(ids, data) {
    if (!ids || ids.length == 0) {
      data["StaffNames"] = null;
      return;
    }

    const labels = this.AllStaffsTypes.filter(
      (item) => ids.includes(item["value"])
    ).map(item => item['StaffName']);
    data["StaffNames"] = labels.join(', ');
  }


  getStaffList() {
    const item = this.encrDecSrvc.getObject(AppConstants.staffList);
    const staffIDTemp = this.encrDecSrvc.getObject(AppConstants.staffID);

    this.AllStaffsTypes.push({ "label": "All", "value": null });

    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive)
        continue;

      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      obj["StaffNumber"] = item[i]["StaffNumber"];

      this.AllStaffsTypes.push(obj);
      this.AllStaffsTypes.sort(this.mangoUtils.compareValues('label', 'asc'));
    }
  }

  onChangeSelDateRange(reset?){
    if(reset) {
      this.hasDataLoaded = false;
    }
    this.encrDecSrvc.addObject(
      AppConstants.ddmMainDateRange,
      this.ddmMainForm.value.dateRange
    );

    if(
          (
                this.ddmMainForm.value.ViewType
            === "projects"
          )
    ){
      this.getDashboardData( false );
      this.getStaffDashboard( );
    }
    else
    if(
          (
                this.ddmMainForm.value.ViewType
            === "tasks"
          )
    ){
      this.getDashboardTaskData( false );
    }
    else{
      this.getDashboardData( false );
      this.getStaffDashboard( );
    }

    this.onDashBoardFilterChange( );
  }

  onDashBoardFilterChange(isPrioInclude?) {
    let columnsToSave = '';
    columnsToSave += this.ddmMainForm.value.staffId ? this.ddmMainForm.value.staffId : null;
    columnsToSave += `, ${this.ddmMainForm.value.dateRange ? this.ddmMainForm.value.dateRange : null}`
    const objToSave = { PMDashboardSettings: `{${columnsToSave}}` }
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(
        this.encrDecSrvc.getObject(AppConstants.staffID),
        objToSave
      )
      .subscribe(
        (data) => { },
        (err) => { }
      );
  }

  get isAllowCreateProject() {
    return this.auth.isAllowAccess(41)
  }

}
