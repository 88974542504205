import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html'
})
export class ClientContactsComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  clientID: number = -1;
  searchTextStr: any = "";
  globalFilterColumns: any = ['ContactName', 'Company', 'EngagementName', 'ContactType'];

  displayAddContactModal: boolean = false;
  clientAddress;
  selectedContactType;
  selectedClientRecord;
  contacts: any = [];
  cols: any[];
  public _selectedColumns: any[];

  constructor(private mangoAPISrvc: MangoApiService, protected mangoUtils: mangoUtils, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService,private translate: TranslateService) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.selectedClientRecord = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString);
    this.getContacts();
    this.sharedSrvc.contactDialogData
      .subscribe((data) => {
        this.getContacts(data['saveAndNew']);
        // setTimeout(() => {
        //   //this.getAllCounts(this.clientID);
        // }, 2000);
      })
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('client.contacts') },
      { label: this.clientName,icon: 'ic-red' }
    ]);
    this.initializeColumn();
  }
  ngOnInit(): void {
  }

  initializeColumn() {
    const allCols = [
      /* { field: 'ContactName', header: this.translate.instant('client.contact_name'), class: 'width-15p p-text-left'}, */
     /*  { field: 'Company', header: this.translate.instant('company'), class: 'width-15p p-text-left'}, */
      { field: 'EngagementName', header: this.translate.instant('engagement'), class: 'width-15p p-text-left', sortable: true},
      { field: 'ContactDescription', header: this.translate.instant('client.contact_description'), class: 'width-15p p-text-left', sortable: false},
      { field: 'ContactType', header: this.translate.instant('contact_type'), class: 'width-10p p-text-left', sortable: false},
      /* { field: 'Office', header: this.translate.instant('work'), class: 'width-10p p-text-left'},
      { field: 'Fax', header: this.translate.instant('Fax-Number'), class: 'width-10p p-text-left'},
      { field: 'Mobile', header: this.translate.instant('mobile'), class: 'width-7p p-text-left'}, */
      { field: 'Street1', header: this.translate.instant('Street1'), class: 'width-7p p-text-left', sortable: false},
      { field: 'Street2', header: this.translate.instant('Street2'), class: 'width-7p p-text-left', sortable: false},
      { field: 'Zip', header: this.translate.instant('Zip_Code'), class: 'width-5p p-text-left', sortable: false},
      { field: 'City', header: this.translate.instant('City'), class: 'width-10p p-text-left', sortable: false},
      { field: 'State', header: this.translate.instant('State'), class: 'width-5p p-text-left', sortable: false},
     /*  { field: 'Email', header: this.translate.instant('email'), class: 'width-10p p-text-left'}, */
    ];
    
    let selectedCols = [];

    const defaultCols = [
      { field: 'ContactName', header: this.translate.instant('client.contact_name'), class: 'width-15p p-text-left'},
      { field: 'Company', header: this.translate.instant('company'), class: 'width-15p p-text-left'},
      { field: 'Office', header: this.translate.instant('work'), class: 'width-10p p-text-left'},
      { field: 'Fax', header: this.translate.instant('Fax-Number'), class: 'width-10p p-text-left'},
      { field: 'Mobile', header: this.translate.instant('mobile'), class: 'width-7p p-text-left'},
      { field: 'Email', header: this.translate.instant('email'), class: 'width-10p p-text-left'},
    ]

    selectedCols = [...defaultCols];

    this.cols = [
      ...allCols
    ]
  }

  getContacts(saveAndNew?) {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getContactsByClientIdWithPermission(_this.clientID).subscribe(function (data: any) {   
      _this.contacts = data.map(function (obj) {
        obj["isValidEmailAddress"] = obj["Email"]
          ? _this.mangoUtils.isEmailValid(obj["Email"])
          : false;
        obj['Company'] = obj['Company'] ? obj['Company'] : "";
        obj['ContactDescription'] = obj['ContactDescription'] ? obj['ContactDescription'] : "";
        obj['ContactName'] = obj['ContactName'] ? obj['ContactName'] : "";
        obj['ContactType'] = obj['ContactType'] ? obj['ContactType'] : "";
        obj['Email'] = obj['Email'] ? obj['Email'] : "";
        obj['Fax'] = obj['Fax'] ? obj['Fax'] : "";
        obj['Home'] = obj['Home'] ? obj['Home'] : "";
        obj['Mobile'] = obj['Mobile'] ? obj['Mobile'] : "";
        return obj;
      });
      if(saveAndNew){
        _this.addContact()
      }
      _this.mangoAPISrvc.fireClientBadgeView(true);
      _this.loadFilterGrid();
      _this.mangoAPISrvc.showLoader(false);
    }, error => {
        _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
    });
  }

  
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  addContact() {
    const contact = {};
    contact['ClientID'] = this.clientID;
    contact['isEditFlow'] = false;
    contact['ClientName'] = this.clientName;
    contact['isDisableOptions'] = false;
    this.sharedSrvc.openContactDialog(contact, this.contacts);
  }

  editContact(contact) {
    contact['isEditFlow'] = true;
    contact['ClientName'] = this.clientName;
    contact['isDisableOptions'] = false;
    this.sharedSrvc.openContactDialog(contact,this.contacts);
  }

  sendEmailToContact(client) {
    window.location.href = "mailto:" + client.Email;
  }

  checkIfCanDelete(email) {
    const dupe = this.contacts?.filter((contact) => contact.Email?.toLowerCase() == email?.toLowerCase)
    const mainEmail = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord)?.Email
    if(dupe?.length == 1 || mainEmail?.toLowerCase() == email?.toLowerCase())
      return false;
    else {
      return true;
    }
  }

  deleteContact(index, email) {
    const parent = this;
    if(!this.checkIfCanDelete(email)) {
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        text: parent.translate.instant('contact_primary_delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parent.translate.instant('yes_delete'),
        cancelButtonText: parent.translate.instant('no_delete')
      }).then((result) => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.deleteContact(index).subscribe(function (data) {
            parent.mangoAPISrvc.showLoader(false);
            const clientRecord = {
              ...parent.selectedClientRecord,
              Email: null,
              ContactPerson: null
            }
            parent.mangoAPISrvc.fireClientBadgeView(true);
            parent.encrDecSrvc.addObject(AppConstants.selectedClientRecord, clientRecord);
            parent.mangoAPISrvc.updateClient(clientRecord).subscribe((data) => {}, err => {})
            parent.mangoAPISrvc.notify('success', parent.translate.instant('delete-message') , AppConstants.deleteMessage);
            parent.contacts = parent.contacts?.filter((contact) => contact.CustomerContactID !== index)
          }, error => {
            parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          });
        }
      })
    }
    else{
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        text: parent.translate.instant('delete_alert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parent.translate.instant('yes_delete'),
        cancelButtonText: parent.translate.instant('no_delete')
      }).then((result) => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.deleteContact(index).subscribe(function (data) {
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('success', parent.translate.instant('delete-message') , AppConstants.deleteMessage);
            parent.mangoAPISrvc.fireClientBadgeView(true);
            parent.contacts = parent.contacts?.filter((contact) => contact.CustomerContactID !== index)
          }, error => {
            parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          });
        }
      })
    }    
  }

}
