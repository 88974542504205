import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { BreadcrumbService, MangoApiService, EncrDecrService, AuthGuard } from "@app/_services";
import { SelectItem } from "primeng/api";
import { AppConstants } from "@app/_helpers/api-constants";
declare let numeral: any;

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent implements OnInit {
  StaffID;
  staffLastName;
  staffFirstName;
  checkBillingRate;
  Language: any;
  selectedLanguage: any;
  formattedAmount: string = '';
  user: any = {};
  userRatesDataSet: any = {};
  isEmailExits: boolean = false;
  isValidForm: boolean = false;
  selectLanguages: SelectItem[];
  isInvalidZip: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    private _authGuard: AuthGuard,
    private encrDecSrvc: EncrDecrService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup Wizard" },
      { label: "User Settings", icon: "ic-red" },
    ]);

    this.selectLanguages = [
      { value: 'English', label: 'English' },
      { value: 'Spanish', label: 'Spanish' },
    ];
    this.selectedLanguage = this.selectLanguages[0].value;
    this.user.city = "";
    this.user.initials = "";
    this.user.street1 = "";
    this.user.street2 = "";
    this.user.state = "";
    this.user.zip = "";
    this.user.telephone = "";
    this.user.billingRate = "";

    this.StaffID = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.initEditableUser();
    this.loadUserRateData();
   }

  ngOnInit(): void {
  }

  initEditableUser() {
    const klassObj = this;
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getUserById(this.StaffID).subscribe((data: any) => {
      klassObj.user = data;
      const name = data.StaffName.split(" ");
      klassObj.staffFirstName = name[0];
      klassObj.staffLastName = name[1];
      klassObj.user.StaffNumber = name[0].substring(0, 1) + '' + name[1].substring(0, 1);
      klassObj.user.StaffNumber = klassObj.user.StaffNumber.toUpperCase();
      this.mangoAPISrvc.showLoader(false)
    }, (data) => {
      this.mangoAPISrvc.showLoader(false)
      klassObj.mangoAPISrvc.notify('error', 'Error!', data.message);
    });
  }

  loadUserRateData() {
    const klassObj = this;
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getUserRatesData(this.StaffID).subscribe((data: any) => {
      klassObj.userRatesDataSet = data;
      data.DefaultBillingRate = data.DefaultBillingRate ? numeral(data.DefaultBillingRate).value() : "150.00";
      klassObj.userRatesDataSet.DefaultBillingRate = numeral(data.DefaultBillingRate).format('0,0.00');
      const rateValue = numeral(klassObj.userRatesDataSet.DefaultBillingRate).value();
      klassObj.isValidForm = (rateValue > 0) ? true : false;
      this.mangoAPISrvc.showLoader(false)
    }, (data) => { 
      this.mangoAPISrvc.showLoader(false)
    });
  }

  transformbillingrate(event, formData: any) {
    console.log('Hello')
    this.isValidForm = false;
    const rateValue = numeral(this.userRatesDataSet.DefaultBillingRate).value();
    this.userRatesDataSet.DefaultBillingRate = (rateValue && rateValue != "") ? rateValue : '0.00';
    if (this.userRatesDataSet.DefaultBillingRate > 0) {
      this.isValidForm = true;
    }
    this.userRatesDataSet.DefaultBillingRate = numeral(this.userRatesDataSet.DefaultBillingRate).format('0,0.00');
  }

  /*
    Saving /Create the User data
  */
  saveUserData() {
    const klassObj = this;
    delete this.user.ProfilePic;
    this.user.staffName = this.staffFirstName + " " + this.staffLastName;
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.updateUser(this.user).subscribe((data) => {
      klassObj.updateUserRates();
      this.mangoAPISrvc.showLoader(false)
    }, (data: any) => {
      this.mangoAPISrvc.showLoader(false)
      klassObj.mangoAPISrvc.notify('error', 'Error!', data.json().message);
    });
  }

  updateUserRates() {
    const klassObj = this;
    this.userRatesDataSet.DefaultBillingRate += "$";
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.updateUserRatesDataById(this.StaffID, this.userRatesDataSet).subscribe((data: any) => {
      klassObj.nextStep();
      this.mangoAPISrvc.showLoader(false)
    }, (data) => {
      this.mangoAPISrvc.showLoader(false)
      klassObj.mangoAPISrvc.notify('error', 'Error!', data.message);
    });
  }

  /*
    getting state and Country
  */
  getStateCountry(event) {
    const zipcode = event.target.value;
    this.isInvalidZip = false;
    if (zipcode) {
      this.mangoAPISrvc.showLoader(true)
      this.mangoAPISrvc.getCityCountryStateByZip(zipcode).subscribe((data: any) => {
        if (data.results.length == 0) {
          this.isInvalidZip = true;
          this.user.City = "";
          this.user.State = "";
          this.user.Country = "";
        } else {
          var data = data.results[0].address_components;
          for (const obj in data) {
            const types = data[obj]['types'];
            if (types) {
              for (const index in types) {
                if (types[index] == 'locality') {
                  this.user.City = data[obj]['long_name'];
                }
                if (types[index] == 'administrative_area_level_1') {
                  let stateStr = data[obj]['short_name'].trim();
                  const indexNo = stateStr.indexOf(' ');
                  if (indexNo > 0) {
                    const str1 = stateStr.substring(0, 1);
                    const str2 = stateStr.substring(indexNo + 1, indexNo + 2);
                    stateStr = (str1 + str2);
                  } else {
                    stateStr = stateStr.substring(0, 2);
                  }
                  this.user.State = stateStr;
                }
                if (types[index] == 'country') {
                  this.user.Country = data[obj]['long_name'];
                }
              }
            }
          }
        }
        this.mangoAPISrvc.showLoader(false)
      }, (data) => { 
        this.mangoAPISrvc.showLoader(false)
      });
    }
  }

  updateSetupWizardStatus() {
    const klassObj = this;
    const obj = { setupStep: 4 };
    klassObj.mangoAPISrvc.showLoader(true)
    klassObj.mangoAPISrvc.updateSetupStatus(obj).subscribe((data) => {
      klassObj.encrDecSrvc.addObject(AppConstants.setupStep, "4")
      klassObj.router.navigate(['/setup-wizard/finished']);
      klassObj.mangoAPISrvc.showLoader(false)
    }, (data) => {
      klassObj.mangoAPISrvc.showLoader(false)
    });
  }

  previousStep() {
    this.router.navigate(['/setup-wizard/company-settings']);
  }

  nextStep() {
    this.updateSetupWizardStatus();
  }

}
