import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { HtmlEditorService, ImageService, LinkService, ResizeService, RichTextEditorComponent, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SharedComponentsService } from '../shared-components-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, ResizeService],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit, AfterViewInit {
  public defaultContactRecord: any = { "ClientID": null, "ProjectMasterID": null, "CustomerContactID": null, "ContactName": null, "Street1": null, "Street2": null, "City": null, "State": null, "Zip": null, "Country": null, "Office": null, "Fax": null, "Mobile": null, "Home": null, "Tele1": null, "Tele2": null, "Tele3": null, "Tele1Descr": null, "Tele2Descr": null, "Tele3Descr": null, "Email": null, "Website": null, "ScratchPad": null, "Company": null, "ContactDescription": null, "Inactive": false, "CustomerContactTypeID": '-1', "isEmailInvoices": false, "isEmailStatements": false, "isClientPortalAccess": false, "isFirmDefaultPayments": false, "isEmailPaymentNotification": false };
  public contactObj: any = {};
  @Output() closeContactModal = new EventEmitter<any>();
  @Input() displayContactModal: boolean = false;
  @ViewChild('contactMainForm') contactMainForm;
  contactsType: SelectItem[];
  clientContacts: any = [];
  projectsList: any = [];
  isEditFlow: boolean = false;
  selectedClientName: any = "";
  selectedClientNameInvoice: any = "";
  selectedClientID: any = "";
  clientAddress: any = {};
  copyClientChk = false;
  copyAddressChk = false;
  isShowMemo = false;
  isMemoChanged: boolean = false;
  isDisableOptions = false;
  isFormValid: boolean = false;
  isEmailValid: boolean = true;
  emailExists: boolean = false;
  emailID: any;
  public selClient = null;
  public staffID;
  public isInviteClient: boolean = false;
  public inviteClientEmailTemplate = { "to": "", "from": "", "subject": "" };
  public inviteEditorValue: any = null;
  public emailtemplateList: any = [];
  viewingOnly = false;
  busy: Promise<any>;
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor', 'Formats', 'Alignments', 'Print']
  };

  @ViewChild('inviteRTE')
  public inviteObj: RichTextEditorComponent;
  public emailTools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', '|',
      'FontName', 'FontSize', 'FontColor',
      'Alignments', '|', 'OrderedList', 'UnorderedList',
      'CreateLink', 'SourceCode']
  };

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService,
    private router: Router,
    public translate: TranslateService,
    private confirmationSrvc: ConfirmationService,
    private auth: AuthGuard,
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.sharedSrvc.engagementDialogData
      .subscribe((data) => {
        if (this.selClient && this.selClient['ClientID']) {
          this.getProjects(this.selClient['ClientID']);
        }
      })
  }

  ngOnInit(): void {
    this.isMemoChanged = false
    this.selectedClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.emailtemplateList = this.encrDecSrvc.getObject(AppConstants.dmsEmailTemplate);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.emailID = this.encrDecSrvc.getObject(AppConstants.userEmail);
    this.contactObj = this.defaultContactRecord;
  }

  ngAfterViewInit() {
    this.contactMainForm.control.valueChanges
      .subscribe(values => {
        this.validateForm()
      });
  }

  validateForm() {
    let validEmail = true;
    if(this.contactObj.Email){
      validEmail = this.contactMainForm.dirty && !this.emailExists && this.isEmailValid;
    }
    this.isFormValid = this.contactMainForm.valid && (this.contactMainForm.dirty || this.isMemoChanged) && validEmail;
  }

  openContactModal(data) {
    if (!data['ClientID'] && !data.data['ClientID']) {
      return false;
    }
    this.viewingOnly = data.data['viewingOnly']
    this.clientContacts = data['contacts'] || data.data['contacts'] || [];
    data = data.data;
    this.clientAddress = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
    this.contactObj = this.defaultContactRecord;
    this.getProjects(data['ClientID']);
    this.getContactType();
    this.copyClientChk = false;
    this.copyAddressChk = false;
    this.selClient = { ClientName: data.ClientName, ClientID: data.ClientID };
    delete data['EngagementName'];
    setTimeout(() => {
      this.isEditFlow = data['isEditFlow'];
      this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
      this.selectedClientNameInvoice = this.encrDecSrvc.getObject(AppConstants.ClientNameInvoice);
      this.selectedClientID = data['ClientID'];
      this.isDisableOptions = data['isDisableOptions'];
      delete data['isEditFlow'];
      delete data['ClientName'];
      delete data['isDisableOptions'];
      if (this.isEditFlow) {
        this.contactObj = data;
        this.clientContacts = this.clientContacts?.filter(contact => contact['Email'] && contact['Email'] != data['Email']);
      } else {
        this.contactObj['ClientID'] = data['ClientID'];
        this.contactObj['ProjectMasterID'] = data['ProjectMasterID'] ? data['ProjectMasterID'] : null;
      }
      this.contactObj.Inactive = this.contactObj.Inactive ? this.contactObj.Inactive : false;
      this.contactObj.isEmailInvoices = data['isEmailInvoices'] ? data['isEmailInvoices'] : false;
      this.contactObj.isEmailStatements = data['isEmailStatements'] ? data['isEmailStatements'] : false;
      this.contactObj.isClientPortalAccess = data['isClientPortalAccess'] ? data['isClientPortalAccess'] : false;
      this.contactObj.isEmailPaymentNotification = data['isEmailPaymentNotification'] ? data['isEmailPaymentNotification'] : false;
      this.contactObj.isFirmDefaultPayments = data['isFirmDefaultPayments'] ? data['isFirmDefaultPayments'] : false;
      this.isValidEmail(data['Email'])
      this.displayContactModal = true;
    }, 900);
  }

  closeContactFormModal(isRefresh) {
    this.contactMainForm.reset();
    this.copyClientChk = false;
    this.copyAddressChk = false;
    this.isMemoChanged = false;
    this.isShowMemo = false;
    this.contactObj = {};
    this.isEmailValid = true;
    this.emailExists = false;
    const url = this.router.url;
    if (url == "/client/contacts" || url == "/engagements/contacts") {
      this.sharedSrvc.setContactDialogData(this.contactObj);
    }
    if (isRefresh) {
      this.closeContactModal.emit(this.contactObj);
    }
    this.displayContactModal = false;
    this.sharedSrvc.sharedCompVisibility.contact = false;
  }

  /*
copy the general desc data to clipboard\
*/
  copyClipBoard(inputElement) {
    const copyValue = this.contactObj.Email;
    if (copyValue) {
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.mangoAPISrvc.notify(
        "success",
        this.translate.instant("Success"),
        "Copied to Clipboard"
      );
    } else {
      document.execCommand("Unselect");
    }
  }

  sendEmailToClient() {
    window.location.href = "mailto:" + this.contactObj.Email;
  }

  onClickResetPw() {
    this.confirmationSrvc.confirm({
      message: "Do you want to reset the password to empty?",
      header: "Reset Password",
      accept: () => {
        const obj = {
          ClientPassword: "",
          isRegistered: false,
          ContactName: this.contactObj?.ContactName,
          Company: this.contactObj?.Company
        }
        
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.updateContact(this.contactObj.CustomerContactID, obj).subscribe((data) => {
          this.mangoAPISrvc.notify('success', 'Success!', 'Password has been reset successfully!');
          this.mangoAPISrvc.showLoader(false);
        }, error => {
          this.mangoAPISrvc.notify('error', 'Error!', 'Password reset failed!');
          this.mangoAPISrvc.showLoader(false);
        });
      }
    });
  }

  getContactType() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getContactType().subscribe(function (data: any) {
      _this.contactsType = [];
      for (let i = 0; i < data.length; i++) {
        _this.contactsType.push({ label: data[i].ContactType, value: data[i].CustomerContactTypeID })
      }
      _this.contactsType.sort(_this.mangoUtils.compareValues('label', 'asc'));
      _this.contactsType.unshift({ label: 'Select Contact Type', value: '-1' });
    }, error => {
      _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  getProjects(clientId) {
    const _this = this;
    _this.projectsList = [];
    if (!clientId) {
      return false;
    }
    _this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(function (data: any) {
      const filterData = data.filter((note) => note.Inactive == false);
      _this.projectsList = [{ label: 'Select Project', value: null }]
      for (let i = 0; i < filterData.length; i++) {
        _this.projectsList.push({ label: filterData[i].EngagementName, value: filterData[i].ProjectMasterID })
      }
      _this.mangoAPISrvc.showLoader(false);
    }, error => {
      _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  sendEmailToContact(rawData) {
    if (rawData.Email) {
      window.location.href = "mailto:" + rawData.Email;
    }
  }

  closeClient() {
    this.isInviteClient = false;
    this.inviteClientEmailTemplate = { "to": "", "from": "", "subject": "" };
    this.inviteEditorValue = null;
  }

  openEmailDialog() {
    const contactEmail = this.contactObj['Email'] ? this.contactObj['Email'] : "";
    const emailtemplate = this.emailtemplateList.filter((item) => item['TemplateName'] == "Invite to Client Portal")[0];

    this.inviteClientEmailTemplate['to'] = contactEmail;
    this.inviteClientEmailTemplate['from'] = this.emailID;
    this.inviteClientEmailTemplate['subject'] = emailtemplate['EmailSubject'];

    const companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    const client = this.selectedClientName ? this.selectedClientName : this.selectedClientNameInvoice;

    emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%<strong>ClientName</strong>%", client);
    emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%ClientName%", client);
    emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%<strong>ContactName</strong>%", this.contactObj.ContactName);
    emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%ContactName%", this.contactObj.ContactName);
    emailtemplate['htmlBody'] = this.mangoUtils.replaceAll(emailtemplate['htmlBody'], "%<strong>LinkToPortal</strong>%", this.encrDecSrvc.getObject(AppConstants.linkToPortal));
    emailtemplate['htmlBody'] = this.mangoUtils.replaceAll(emailtemplate['htmlBody'], "%LinkToPortal%", this.encrDecSrvc.getObject(AppConstants.linkToPortal));
    emailtemplate['htmlBody'] = this.mangoUtils.replaceAll(emailtemplate['htmlBody'], "http://https://", "https://");
    emailtemplate['htmlBody'] = this.mangoUtils.replaceLinkToPortal(emailtemplate['htmlBody'], 'Link to Portal');
    emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%<strong>YourFirmName</strong>%", companyName);
    emailtemplate['htmlBody'] = this.mangoUtils.replaceSpan(emailtemplate['htmlBody'], "%YourFirmName%", companyName);

    this.inviteEditorValue = emailtemplate['htmlBody'];
    //this.inviteObj.toolbarSettings.type = ToolbarType.MultiRow;
    this.isInviteClient = true;
  }

  sendInviteClient() {
    const parent = this;
    const sendInBlueObj = {};
    const toArr = [];
    let isinValidMailProperties = false;

    const emailArr = this.inviteClientEmailTemplate['to'].split(";");
    for (let index = 0; index < emailArr.length; index++) {
      const mail = emailArr[index];
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        const toObj = { email: '', name: '' };
        toObj['email'] = mail;
        toObj['name'] = mail;
        toArr.push(toObj);
      } else {
        parent.mangoAPISrvc.notify('warning', 'Warning!', 'Invalid To Email Address');
        isinValidMailProperties = true;
        break;
      }
    }

    if (isinValidMailProperties) {
      return false;
    }
    const companyName = this.encrDecSrvc.getObject(AppConstants.companyName);

    const senderObj = { name: '', email: '' };
    senderObj['name'] = companyName;
    senderObj['email'] = environment.EMAIL_RETURN_SENDER
    sendInBlueObj['replyTo.email'] = environment.EMAIL_RETURN_SENDER;


    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['sender.name'] = this.encrDecSrvc.getObject(AppConstants.userName);
    sendInBlueObj['sender.email'] = this.encrDecSrvc.getObject(AppConstants.userName);
    sendInBlueObj['to'] = toArr;
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceAll(parent.inviteClientEmailTemplate['subject'], "%<strong>ClientName</strong>%", parent.contactObj['ClientName']);
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceAll(parent.inviteClientEmailTemplate['subject'], "%ClientName%", parent.contactObj['ClientName']);
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceAll(parent.inviteClientEmailTemplate['subject'], "%<strong>ContactName</strong>%", parent.contactObj.ContactName);
    parent.inviteClientEmailTemplate['subject'] = parent.mangoUtils.replaceAll(parent.inviteClientEmailTemplate['subject'], "%ContactName%", parent.contactObj.ContactName);

    sendInBlueObj['htmlContent'] = parent.inviteEditorValue;
    sendInBlueObj['subject'] = parent.inviteClientEmailTemplate['subject'];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(res => {
      parent.closeClient();
      parent.mangoAPISrvc.notify('info', 'Information!', 'Email sent successfully!');
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  changeCopyClient() {
    this.changeCopyAddress();
    if (this.copyClientChk)
      this.contactObj.Company = this.selectedClientName;
    else
      this.contactObj.Company = "";
  }

  saveContact(isEditFlow) {
    const _this = this;
    if (isEditFlow) {
      _this.updateContact();
    } else {
      delete this.contactObj.CustomerContactID;
      const contactObj = JSON.parse(JSON.stringify(_this.contactObj));
      _this.mangoAPISrvc.showLoader(true);
      _this.mangoAPISrvc.addContact(_this.contactObj.ClientID, _this.contactObj)
        .subscribe(function (data: any) {
          if(data.client){
            const client = _this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
            client['Email'] = contactObj['Email']
            client['ContactPerson'] = contactObj['ContactName'];
            _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, client);
          }
          _this.isMemoChanged = false;
          _this.contactObj['saveAndNew'] = false;
          _this.closeContactFormModal(true);
          //_this.getAllCounts(_this.selectedClientID);
          _this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
          _this.mangoAPISrvc.showLoader(false);
          //_this.notificationsService.notify('success', data.message);
        }, error => {
          _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          _this.mangoAPISrvc.showLoader(false);
        });
    }
  }

  saveAndNewContact() {
    const _this = this;
    delete this.contactObj.CustomerContactID;
    const contactObj = JSON.parse(JSON.stringify(_this.contactObj));
    _this.mangoAPISrvc.addContact(_this.contactObj.ClientID, _this.contactObj)
      .subscribe(function (data: any) {
        if(data.client){
          const client = _this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
          client['Email'] = contactObj['Email']
          client['ContactPerson'] = contactObj['ContactName'];
          _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, client);
        }
        _this.contactMainForm.reset();
        _this.copyClientChk = false;
        _this.copyAddressChk = false;
        _this.isEmailValid = true;
        _this.contactObj = _this.defaultContactRecord;
        _this.contactObj['ClientID'] = _this.selectedClientID;
        _this.contactObj['isEditFlow'] = false;
        _this.contactObj['ClientName'] = _this.selectedClientName;
        _this.contactObj['isDisableOptions'] = false;
        _this.contactObj['saveAndNew'] = true;
        _this.closeContactModal.emit(_this.defaultContactRecord);
        //_this.getAllCounts(_this.selectedClientID);
        _this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
      }, error => {
        _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });
  }

  updateContact() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    const contactObj = JSON.parse(JSON.stringify(_this.contactObj));
    _this.mangoAPISrvc.updateContact(_this.contactObj.CustomerContactID, _this.contactObj)
      .subscribe(function (data: any) {
        if(data.client){
          const client = _this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
          client['Email'] = contactObj['Email']
          client['ContactPerson'] = contactObj['ContactName'];
          _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, client);
        }
        _this.isMemoChanged = false;
        _this.closeContactFormModal(true);
        _this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        _this.mangoAPISrvc.showLoader(false);
      }, error => {
        _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });
  }

  changeCopyAddress() {
    if (this.copyClientChk) {
      this.contactObj.Street1 = this.clientAddress.BusStreet1;
      this.contactObj.Street2 = this.clientAddress.BusStreet2;
      this.contactObj.City = this.clientAddress.BusCity;
      this.contactObj.State = this.clientAddress.BusState;
      this.contactObj.Zip = this.clientAddress.BusZip;
      this.contactObj.Country = this.clientAddress.BusCountry;
    } else {
      this.contactObj.Street1 = '';
      this.contactObj.Street2 = '';
      this.contactObj.City = '';
      this.contactObj.State = '';
      this.contactObj.Zip = '';
      this.contactObj.Country = '';
    }
  }


  isValidEmail(email) {
    this.isEmailValid = true;
    if (!email || email == "" || email === null || email.trim() == "") {
      this.isFormValid = true;
      this.isEmailValid = true;
      return null;
    }
    this.contactObj.Email = email.trim();
    //var mailPattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mailPattern.test(email.trim())) {
      this.isEmailValid = true;
      this.isFormValid = true;
      return null;
    } else {
      this.isFormValid = false;
      this.isEmailValid = false;
    }
  }

  emailAlreadyExists(email){
    this.emailExists = false;
    if(this.clientContacts.filter(contact => contact?.Email.toLowerCase() === email?.toLowerCase()).length > 0){
      this.emailExists = true;
    }
  }

  findChoices(searchText: string) {
    return this['viewContainerRef']['parentInjector']['view']['component']['rawshortcutLabels'].filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel(choice: string) {
    return `${choice} `;
  }

  replaceShortcuts() {
    const value = this.contactObj.ScratchPad;
    const value2 = this.convertShortcuts(value);
    this.contactObj.ScratchPad = value2;
    this.isMemoChanged = true;
    this.validateForm()
  }

  private convertShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(" ");
  }

  // adding new engagent
  addEngagementPopUp() {
    const parent = this;

    if(!parent.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: parent.translate.instant('client.engagement.permission')
      });
      return
    }
    const obj = {};
    obj['isClientFlow'] = true;
    obj['isEditFlow'] = true;
    obj['ClientID'] = this.selClient['ClientID'];
    obj['ClientName'] = this.selClient['ClientName'];
    obj['AssignedTo'] = this.staffID;
    parent.sharedSrvc.openEngagementDialog(obj);
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38)
  }
}