import { Component, OnDestroy, OnInit, Compiler } from '@angular/core';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AuthGuard, EncrDecrService, MangoApiService } from '@app/_services';
import { SharedComponentsService } from '../shared-components-service'
import { MessageService } from 'primeng/api';
import { AppConstants } from '@app/_helpers/api-constants';
import { Router } from '@angular/router';
import * as workerTimers from 'worker-timers';

@Component({
  selector: 'app-inactivity',
  templateUrl: './inactivity.component.html'
})
export class InactivityComponent implements OnDestroy, OnInit {

  minutesDisplay = 0;
  secondsDisplay = 0;
  showSessionDialog: boolean = false;
  endTime = 5;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: any;

  constructor(private sharedSrvc: SharedComponentsService, private _compiler: Compiler, private authGuard: AuthGuard, private encrDecSrvc: EncrDecrService, private messageService: MessageService, private _router: Router, private mangoAPISrvc: MangoApiService) {

  }

  ngOnInit() {
    this.resetTimer();
    this.encrDecSrvc.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        // this.timerSubscription.unsubscribe();
        workerTimers.clearInterval(this.timerSubscription)
      }
      this.resetTimer();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    workerTimers.clearInterval(this.timerSubscription)
  }

  resetTimer(endTime: number = this.endTime) {
    const parent = this;
    const interval = 1000;
    const duration = endTime * AppConstants.sessionTimeout;
    this.timerSubscription = workerTimers.setInterval(() => {

        const tasks = parent.encrDecSrvc.getObject(AppConstants.timerTasks);
        if (tasks) {
          const Data = JSON.parse(tasks);
          if (Data && Data.length == 0) {
            this.sharedSrvc.sharedCompVisibility.inactivity = true;
            this.showSessionDialog = true;
            this.sharedSrvc.saveTasksToSession(true)
            this.messageService.clear();
          }
        } else {
          this.sharedSrvc.sharedCompVisibility.inactivity = true;
          this.showSessionDialog = true;
          this.sharedSrvc.saveTasksToSession(true)
          this.messageService.clear();
        }
      
    }, duration)
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  onConfirm() {
    this.showSessionDialog = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.messageService.clear();
    this.sharedSrvc.saveTasksToSession(true)
    this._router.navigate(['/login']).then((result) => {
      this.authGuard.logout();
      this._compiler.clearCache();
      setTimeout(() => {
        this.mangoAPISrvc.notifyLogging(false);
      }, 100);
    })
  }

}