<div class="client-engagements">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{ 'create_project' | translate }}" icon="fal fa-plus" styleClass="p-button-sm" *ngIf="createProjectFromCompanyTemplate"
                class="p-button-help p-pr-2" (click)="createFromCompanyTemplate();"></p-button>
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addEngagementPopUp();"></p-button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt2 [value]="projectsList" [rowHover]="true" [responsive]="true" [rows]="10" 
                        dataKey="ProjectMasterID" selectionMode="single" sortField="EngagementName" [pageLinks]="3"
                        [filterDelay]="0" [sortOrder]="-1"  [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [globalFilterFields]="globalFilterColumns"
                        [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt2.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                    <p-checkbox [(ngModel)]="projectInactive" label="{{ 'show_inactive' | translate }}"
                                        binary="true" (click)="onStatusChange()">
                                    </p-checkbox>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-8p p-text-center">{{ 'actions' | translate }}</th>
                                <th pSortableColumn="EngagementName" class="width-20p p-text-left" pResizableColumn>
                                    {{ 'client.engagement_name' | translate }}
                                    <p-sortIcon field="EngagementName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="EngagementType" pResizableColumn class="width-15p p-text-left">
                                    {{ 'engagement.type' | translate }}
                                    <p-sortIcon field="EngagementType"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-8p p-text-center">{{ 'engagement.billing_method' |
                                    translate }}</th>
                                <th pResizableColumn class="width-10p p-text-right">{{ 'engagement.fixed_fee_amount' |
                                    translate }}</th>
                                <th pResizableColumn class="width-5p p-text-center">{{ 'billable' | translate }}</th>
                                <th pResizableColumn class="width-8p p-text-center">{{ 'manager' | translate }}</th>
                                <th pResizableColumn class="width-8p p-text-center">{{ 'Created' | translate }}</th>
                                <th pResizableColumn class="width-6p p-text-center">{{ 'status' | translate }}</th>
                                <th pResizableColumn class="width-6p p-text-right">ID#</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-8p">
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editEngagement(rowData)" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}"></i>
                                        <i (click)="deleteEngagement(rowData.ProjectMasterID, rowData)"
                                            class="fal fa-trash-alt ic-red p-pl-3 cursor-hand" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                    </div>
                                </td>
                                <td class="width-20p p-text-left" (click)="editEngagement(rowData)"
                                    tooltipPosition="top" pTooltip="{{rowData.EngagementName}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.EngagementName}}
                                </td>
                                <td class="width-15p p-text-left" (click)="editEngagement(rowData)"
                                    tooltipPosition="top" pTooltip="{{rowData.Description}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.Description}}
                                </td>
                                <td class="width-8p p-text-center" (click)="editEngagement(rowData)">
                                    {{rowData.BillingMethod}}
                                </td>
                                <td class="width-10p p-text-right" (click)="editEngagement(rowData)">
                                    {{rowData.FlatFeeAmount | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td class="width-5p p-text-center" (click)="editEngagement(rowData)">
                                    <i class="fal fa-check ic-red" *ngIf="rowData['isBillable']"></i>
                                </td>
                                <td class="width-8p p-text-center" (click)="editEngagement(rowData)">
                                    {{rowData.StaffName}}
                                </td>
                                <td class="width-8p p-text-center" (click)="editEngagement(rowData)">
                                    {{rowData.StartDate | date:'MM/dd/yyyy' : 'UTC'}}
                                </td>
                                <td class="width-6p p-text-center" (click)="editEngagement(rowData)">
                                    {{rowData.Status}}
                                </td>
                                <td class="width-6p p-text-right" id="border-right" (click)="editEngagement(rowData)">
                                    {{rowData.ProjectMasterID}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isDisplayDetailsTable">
    <app-create-project-from-template 
        [clientID]="clientID" 
        [clientName]="clientName" 
        (onClose)="onCloseTemplateDialog()">
    </app-create-project-from-template>
</div>

<!-- <p-dialog [(visible)]="isDisplayDetailsTable" [modal]="true" [style]="{width: '50vw'}" [responsive]="true" [draggable]="false" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
    <p-header>{{ 'pm.Create-Project-From-Company-Template' | translate }}</p-header>
    <p-table #dt [value]="templateDataSource" (onFilter)="onFilter($event)" 
        [totalRecords]="templateDataSource.length" [pageLinks]="3" 
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" 
        [scrollHeight]="'calc(90vh - 360px)'" [scrollable]="true"
        [rowHover]="true" dataKey="MasterTemplateDetailsID" [globalFilterFields]="['TemplateName']"  sortField="TemplateName" [sortOrder]="1" #detailtab>
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchValue pInputText type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search by" />
                </span>
                <span>
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="Clear"></button>
                </span>
                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2">
                </p-message>
            </div>
        </ng-template>
        <!- Table Header ->
        <ng-template pTemplate="header">
            <tr>
                <th class="width-8p p-text-center">
                </th>
                <th class="width-85p p-text-center" pSortableColumn="TemplateName">
                    {{ 'Template-Names' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="TemplateName"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <!- Rows ->
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="width-8p p-text-center">
                    <p-radioButton name="groupname" [(ngModel)]="selectedTemplate" [value]="rowData" [inputId]="rowData.ProjectHeaderID"
                        (click)="radioCompanyClicked($event,rowData)">
                    </p-radioButton>
                </td>
                <td class="width-85p p-text-left">
                    {{rowData.TemplateName}}
                </td>
            </tr>
        </ng-template>
        <!- No data ->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">
                    <p-message severity="info" text="Data not found." styleClass="p-px-2">
                    </p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton label="{{ 'create' | translate }}" (click)="createProjectScreen()" [disabled]="!isRadioSelected"></button>
        <button pButton label="{{ 'update_button' | translate }}" routerLink="/project-management/{{companyTemplateID}}/createTemplate" [disabled]="!isRadioSelected"></button>
        <button pButton label="{{ 'cancel_button' | translate }}" (click)="isDisplayDetailsTable = false"></button>
    </p-footer>
</p-dialog> -->