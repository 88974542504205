<p-dialog header="{{ 'Shortcut.edit-add' | translate }}" [(visible)]="showShotcutsDialog" [modal]="true"
    [style]="{'width': '50vw'}" showEffect="fade" [baseZIndex]="1" (onHide)="closeCode()" [draggable]="true" [resizable]="false"
    (onHide)="closeShortCutsDialog()">
    <div class="layout-content  p-mt-0">
        <div class="p-fluid p-formgrid p-grid p-m-1">
            <div class="p-col-12">
                <p-table #dt [value]="codeList" [rowHover]="true" [responsive]="true" dataKey="ShortCutsID"
                    selectionMode="single" [filterDelay]="0" [resizableColumns]="true" [responsive]="true"
                    sortField="ShortCutCode" [sortOrder]="1" [sortOrder]="1"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    [globalFilterFields]="['ShortCutCode', 'Phrase']" (onFilter)="onFilter($event)" [scrollable]="true"
                    scrollHeight="300px">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                            <span class="p-input-icon-left width-35p">
                                <i class="fal fa-search"></i>
                                <input #searchValue pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{ 'global_filter' | translate }}" />
                            </span>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                pTooltip="Clear"></button>
                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                            </p-message>
                            <p-button (click)="openAddCode()" label="{{ 'add_shortcut' | translate }}"
                                icon="fal fa-plus" styleClass="p-button-sm" class="p-button-help p-pr-2 p-ml-auto"
                                tooltipPosition="top" pTooltip="Add Shortcut"></p-button>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th class="p-text-center width-5p">
                                {{ 'actions' | translate }}
                            </th>
                            <th class="p-text-left width-10p" pSortableColumn="ShortCutCode">
                                {{ 'Shortcut.short-codes' | translate }}
                                <p-sortIcon field="ShortCutCode"></p-sortIcon>
                            </th>
                            <th class="p-text-left width-30p" pSortableColumn="Phrase">
                                {{ 'Shortcut.phrase' | translate }}
                                <p-sortIcon field="Phrase"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData>
                        <tr (click)="openEditCode(rowData)" class="cursor-hand">
                            <td class="width-5p">
                                <div class="p-text-center">
                                    <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                        (click)="openEditCode(rowData);$event.stopPropagation();" tooltipPosition="top"
                                        pTooltip="Edit"></i>
                                    <i pRipple (click)="deleteCode(rowData.ShortCutsID);$event.stopPropagation();"
                                        class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                                        pTooltip="Delete"></i>
                                </div>
                            </td>
                            <td class="width-10p text-left">{{rowData.ShortCutCode}}</td>
                            <td class="width-30p text-left">{{rowData.Phrase}}</td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </div>
    </div>
</p-dialog>

<!-- <p-dialog [(visible)]="displayAddShortcutModal" modal="modal" [baseZIndex]="2" width="600" [responsive]="true"> -->
<p-dialog header="{{ (isEditCode ? 'Shortcut.edit-shortcuts' : 'Shortcut.add-shortcuts') | translate }}"
    [(visible)]="displayAddShortcutModal" [modal]="true" [style]="{width: '30vw'}" showEffect="fade" [baseZIndex]="1"
    [draggable]="false" [resizable]="false">
    <form #shortcutMainForm="ngForm" class="form-horizontal">
        <div class="p-fluid common-dialog">
            <div class="p-field">
                <label for="formattedStr required-field">
                    <h5 class="label-text">{{ 'QuickLinks.shortcut-codes' | translate }}</h5>
                </label>
                <input pInputText type="text" autocomplete="nope" [(ngModel)]="code.ShortCutCode" name="shortcode"
                    id="shortcode" #shortcodeVl="ngModel" minlength="3" maxlength="100"
                    (blur)="checkstartingLetter(code.ShortCutCode)" placeholder="Start code with a /" />
                <div *ngIf="shortcodeVl.touched && shortcodeVl.errors?.minlength" class="p-invalid p-mt-1">
                    Minimum of 2 characterss
                </div>
                <div *ngIf="shortcodeVl.touched && shortcodeVl.errors?.maxlength" class="p-invalid p-mt-1">
                    Maximum of 70 characters
                </div>
            </div>

            <div class="p-field">
                <label for="formattedStr required-field">
                    <h5 class="label-text">{{ 'description' | translate }}</h5>
                </label>
                <textarea pInputTextarea [rows]="5" name="phrase" id="phrase" #Phase="ngModel" [(ngModel)]="code.Phrase"
                    required (focus)="$event.target.select()">
                </textarea>
                <div *ngIf="Phase.touched && Phase.errors?.minlength" class="p-invalid p-mt-1">
                    Minimum of 3 characters
                </div>
                <div *ngIf="Phase.touched && Phase.errors?.maxlength" class="p-invalid p-mt-1">
                    Maximum of 500 characters
                </div>
            </div>
        </div>
    </form>
    <p-footer>
        <button pButton pRipple icon="fa fa-check" label="{{ 'save_button' | translate }}"
            [disabled]="!shortcutMainForm.valid || !shortcutMainForm.dirty" (click)="saveCode(true)"
            *ngIf="!isEditCode"></button>
        <button pButton pRipple icon="fa fa-check" label="{{ 'update_button' | translate }}" (click)="saveCode(false)"
            [disabled]="!shortcutMainForm.valid || !shortcutMainForm.dirty" *ngIf="isEditCode"></button>
        <button pButton pRipple icon="fa fa-close" label="{{ 'cancel_button' | translate }}" (click)="closeCode()"></button>
    </p-footer>
</p-dialog>