<div class="clientportal">
    <form [formGroup]="clientPortalForm">
        <div class="layout-content p-pt-0">
            <div class="p-grid p-ai-stretch vertical-container p-mx-2">
                <div class="p-col">
                    <div class="box box-stretched p-mt-2">
                        <h4 class="label-text">{{'settings.Client-Portal' | translate}}</h4>
                        <!-- <div class="p-field p-col-12">
                            <p-checkbox formControlName="ActivateCustomerPortal" [binary]="true"
                                label="{{'settings.Activate-Client-Portal' | translate}}"
                                (onChange)="saveClientPortalForm()"></p-checkbox>
                        </div> -->
                        <div class="p-field p-col-12">
                            <p-checkbox formControlName="AllowCustomerPortalEdit" [binary]="true"
                                label="{{'settings.Allow-Editing-in-Customer-Portal' | translate}}"
                                (onChange)="saveClientPortalForm()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox formControlName="isShowInvHistory" [binary]="true"
                                label="{{'settings.Show-Invoice-History' | translate}}"
                                (onChange)="saveClientPortalForm()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox formControlName="isShowPmtHistory" [binary]="true"
                                label="{{'settings.Show-Payment-History' | translate}}"
                                (onChange)="saveClientPortalForm()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox formControlName="isDMSVisiblePortal" [binary]="true"
                                label="Hide DMS in Client Portal" (onChange)="saveClientPortalForm()"></p-checkbox>
                        </div>

                    </div>
                </div>
                <div class="p-col" *ngIf="displayPaymentOptions">
                    <div class="box box-stretched p-mt-2">
                        <h4 class="label-text">{{'client_electronic_payment_options' | translate}}</h4>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="modeOfPayment" value="0" formControlName="modeOfPayment"
                                (onClick)="saveClientPortalForm()"></p-radioButton>
                            <label>CC {{'Only' | translate}}</label>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="modeOfPayment" value="1" formControlName="modeOfPayment"
                                (onClick)="saveClientPortalForm()"></p-radioButton>
                            <label>ACH {{'Only' | translate}}</label>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="modeOfPayment" value="2" formControlName="modeOfPayment"
                                (onClick)="saveClientPortalForm()"></p-radioButton>
                            <label>{{'all_methods' | translate}}</label>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="modeOfPayment" value="3" formControlName="modeOfPayment"
                                (onClick)="saveClientPortalForm()"></p-radioButton>
                            <label>{{'client.no_payments_allowed' | translate}}</label>
                        </div>
                        <div class="label-text">{{'settings.select-payment-method-available' | translate}}</div>
                    </div>
                </div>
                <div class="p-col" *ngIf="!displayPaymentOptions">
                    <div class="box box-stretched p-mt-2">
                        <h4 class="label-text">{{'client_electronic_payment_options' | translate}}</h4>
                        <button pButton pRipple type="button" icon="fal fa-credit-card"
                            label="{{ 'staxenrollment' | translate }}" class="pull-right" class="p-button"
                            (click)="redirectStaxToLogin()"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>