import {
  Component,
  OnInit, 
  SimpleChanges,
  ViewChild,
  Output,  
  EventEmitter,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SelectItem } from "primeng/api";
import { AuthGuard, EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import { timer } from 'rxjs';
import { SharedComponentsService } from '../shared-components-service'
import Swal from "sweetalert2";
declare let numeral: any;

@Component({
  selector: "app-engagement",
  templateUrl: "./engagement.component.html",
})
export class EngagementComponent implements OnInit {
  displayEngagementModal: boolean = false;
  engagementObj;
  public statusTypes = [
    { label: "Open", value: "Open" },
    { label: "Close", value: "Close" },
    { label: "Pending", value: "Pending" },
  ];
  public billingRates: SelectItem[];
  @ViewChild("engagementMainForm") engagementMainForm;
  public defaultRecord: any = {
    ClientID: "",
    AssignedTo: -1,
    Status: "Open",
    EngagementName: "",
    StartDate: "",
    DueDate: "null",
    ProjectStreet: "null",
    ProjectStreet2: "null",
    ProjectCity: "null",
    ProjectState: "null",
    ProjectZip: "null",
    ProjectCountry: "null",
    CustomerContactID: -1,
    ContactEmail: "",
    Inactive: false,
    EngagementTypeID: "",
    EngagementNumber: "",
    EngagementDescription: "",
    EngagementInvoiceDescription: "",
    FlatFeeAmount: 0.0,
    BillingMethod: "Hourly",
    BillTo: "Client",
    TermsID: 0,
    LaborTaxRate1: 0.0,
    LaborTaxRate2: 0.0,
    ExpenseRate1: 0.0,
    ExpenseRate2: 0.0,
    DefaultTaxRates: true,
    isBillable: false,
  };
  @Output() closeEngagementModal = new EventEmitter<any>();
  staffID;

  public staffList: SelectItem[];
  companyId: number;

  public filteredClients: any[];
  public engagementList: any = [];
  clientList: any = [];
  contacts: any = [];
  intervalid;

  isFormValid: boolean = false;
  isEmailValid: boolean = true;
  isClientFlow: boolean = false;
  public selClient = null;
  public selEngagementType;
  busy: Promise<any>;
  constructor(
    private _translate: TranslateService,
    private mangoAPISrvc: MangoApiService,
    private authGuard: AuthGuard,
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    private sharedSrvc: SharedComponentsService
  ) {
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    this.defaultRecord.AssignedTo = this.staffID;
    this.engagementObj = this.defaultRecord;
    this.billingRates = [
      { value: "Hourly", label: this._translate.instant("Hourly") },
      { value: "Fixed Fee", label: this._translate.instant("Fixed Fee") },
    ];
  }

  ngAfterViewInit() {
    this.engagementMainForm.control.valueChanges
      .subscribe(values => {
        this.validateForm();
      });
  }

  validateForm(){
    this.isFormValid = this.engagementMainForm.valid && this.selClient && this.selClient['ClientID'] && this.selEngagementType;
  }

  openEngagementDialog(data) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.intervalid = setInterval(() => {
      if (this.companyId) {
        this.getEngagementTypes();
        clearInterval(this.intervalid)
      }
    }, 500)
    this.staffID = this.encrDecSrvc.getObject(AppConstants.usersID);
    this.getAllStaffList();
    this.fetchClientList();
    this.addEngagementModal(data);
  }

  getAllStaffList() {
    const data = this.encrDecSrvc.getObject(AppConstants.staffList);

    this.staffList = [{ label: "Select User", value: null }];
    for (let i = 0; i < data.length; i++) {
      if(data[i].Inactive)
        continue;
        
      this.staffList.push({
        label: data[i].StaffName,
        value: data[i].StaffID,
      });
    }

    this.engagementObj.AssignedTo = this.encrDecSrvc.getObject(AppConstants.staffID);
  }

  getContacts(id) {
    const parent = this;

    parent.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.getContacts(id).subscribe(
      function (data: any) {
        parent.mangoAPISrvc.showLoader(false);

        parent.contacts = [{ label: "Select Contact", value: null }];
        for (let i = 0; i < data.length; i++) {
          parent.contacts.push({
            label: data[i].ContactName,
            value: data[i].CustomerContactID,
          });
        }
      },
      (err) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes["displayEngagementModal"]) {
      const displayEngagementModal = <boolean>(
        changes["displayEngagementModal"].currentValue
      );
      if (displayEngagementModal) {
        this.addEngagementModal(null);
      }
    }
  }

  addEngagementModal(data) {
    if(!this.authGuard.isAllowAccess(38)) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: this._translate.instant('client.engagement.permission')
      });
      return
    }
    const parent = this;
    parent.displayEngagementModal = true;
    parent.engagementMainForm.reset();
    parent.engagementObj = parent.defaultRecord;
    parent.engagementObj.ClientID = data["ClientID"];
    parent.engagementObj.AssignedTo = this.encrDecSrvc.getObject(AppConstants.staffID);
    parent.engagementObj.Inactive = false;

    if(data["isBilling"] !== null && data["isBilling"] !== undefined){
      parent.engagementObj.isBillable = data["isBilling"];
    }
    if (data["ClientName"] && data["ClientID"]) {
      parent.selClient = {
        ClientName: data["ClientName"],
        ClientID: data["ClientID"],
      };
    } else {
      parent.selClient = null;
    }
    parent.isClientFlow = data["isClientFlow"] ? data["isClientFlow"] : false;
    if (data && data.isClientFlow) {
      parent.getContacts(data.ClientID);
      parent.getEngagementTypes();
      parent.engagementObj.ClientID = data["ClientID"];
      parent.engagementObj.ContactEmail = data["Email"];
    }
  }

  formatAmount() {
    this.engagementObj.FlatFeeAmount = numeral(
      numeral(this.engagementObj.FlatFeeAmount).value()
    ).format("0.00");
  }

  createProject(isCloseDialog) {
    const self = this;
    self.engagementObj.BillingMethod = self.engagementObj.BillingMethod
      ? self.engagementObj.BillingMethod
      : "Hourly";
    self.engagementObj.isBillable = self.engagementObj.isBillable
      ? self.engagementObj.isBillable
      : true;

    self.mangoAPISrvc.showLoader(true);

    self.mangoAPISrvc.createProject(self.engagementObj).subscribe(
      function (data: any) {
        self.mangoAPISrvc.showLoader(false);
        
        const userLogs = {}
        userLogs['Action'] = "Add Engagement";
        userLogs['Description'] = data.data['EngagementName'] + " ##" + data.data['ProjectMasterID'] + " to Client: " +  data.data['ClientID'] + ` --${self.selClient['ClientName']}`;
        userLogs['Table'] = "Engagement";

        const isManaging = self.encrDecSrvc.getObject(AppConstants.isManagingAccount);
        if (!isManaging) {
          self.mangoAPISrvc.addUserLogs(userLogs).subscribe(()=>{},(err)=>{
            console.log(err);
          });
        }
      
        
        if (isCloseDialog) {
          self.closeTEModal(true);
        } else {
          self.closeEngagementModal.emit(self.engagementObj);
          const oldObj = self.selClient;
          self.engagementMainForm.reset();
          setTimeout(() => {
            self.selClient = oldObj;
            self.engagementObj = self.defaultRecord;
            self.engagementObj.ClientID = self.selClient.ClientID;
            self.engagementObj.AssignedTo = this.encrDecSrvc.getObject(AppConstants.staffID);
            self.engagementObj.Status = "Open";
          }, 500);
        }
        self.mangoAPISrvc.notify("success", "Success", AppConstants.createMsg);
      },
      (err) => {
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
  
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false
        ) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
  
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  fetchClientList() {
    if (!this.companyId) {
      return false;
    }
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getAllClientsByCompanyId(this.companyId).subscribe((data: any) => {
      this.clientList =  data;
      this.filteredClients =  data;
      this.mangoAPISrvc.showLoader(false);
    }, (err) => {
      this.mangoAPISrvc.showLoader(false);
    });
  }

  handleSelectClick(obj) {
    this.selClient = obj;
    this.engagementObj.ClientID = obj.ClientID;
    this.validateForm();
    if (!this.isClientFlow) {
      this.getContacts(obj.ClientID);
    }
  }

  replaceShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    this.engagementObj.EngagementInvoiceDescription = valueArr.join(" ");
  }

  isValidEmail(email) {
    this.isEmailValid = true;
    const mailPattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (email == "" || email === null || email.trim() == "") {
      return null;
    } else if (mailPattern.test(email.trim())) {
      return null;
    } else {
      this.isFormValid = false;
      this.isEmailValid = false;
    }
  }

  closeTEModal(isRefresh) {
    this.selEngagementType = null;
    this.selClient = null;
    this.engagementMainForm.reset();
    this.engagementObj = {};
    if (isRefresh) {
      this.closeEngagementModal.emit(this.engagementObj);
    }
    this.displayEngagementModal = false;
    this.sharedSrvc.sharedCompVisibility.engagement = false;
  }

  getEngagementTypes() {
    const parent = this;
    parent.engagementList = [];
    if (!parent.companyId) {
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getEngagementTypes(parent.companyId).subscribe(
      function (data: any) {
        parent.engagementList = [];
        data = data.filter(function (obj) {
          return !obj["Inactive"];
        });
        for (let i = 0; i < data.length; i++) {
          parent.engagementList.push({
            label: data[i].Description,
            value: {
              EngagementTypeID: data[i].EngagementTypeID,
              EngagementName: data[i].Description,
              InvoiceDescription: data[i].InvoiceDescription,
            },
          });
        }
        parent.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  selectEngagementType(event) {
    const data = event.value;
    this.selEngagementType = data;
    this.engagementObj.EngagementTypeID = data.EngagementTypeID;
    this.engagementObj.EngagementName = data.EngagementName;
    this.engagementObj.EngagementInvoiceDescription = data.InvoiceDescription;
  }
}
