import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
@Component({
  selector: "app-email-automation",
  templateUrl: "./email-automation.component.html",
})
export class EmailAutomationComponent implements OnInit {
  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;
  mangoCompanyDetails: any;
  enableEmailLists: boolean = false;

  constructor(private router: Router,private _translate: TranslateService, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService) {
  }

  ngOnInit() {
    this.setMenuIndex();
  
    this.mangoAPISrvc
      .getCompanyMangoDetails(
        this.encrDecSrvc.getObject(AppConstants.companyID)
      )
      .subscribe((companyData) => {
        this.mangoCompanyDetails = companyData[0];
        if (this.mangoCompanyDetails.ActivateEmailAutomation) {
          this.enableEmailLists = true;
        } else {
          this.enableEmailLists = false;
        }
        this.loadMenu();
      }, err => {
      })
  }

  loadMenu() {
    this.items = [
      { label: this._translate.instant('Setup'), routerLink: ['/admin/emailAutomation/setup'], icon: 'fal fa-cogs',},
      { label: this._translate.instant('Email Lists'), routerLink: ['/admin/emailAutomation/emailLists'], icon: 'fal fa-envelope', disabled: !this.enableEmailLists},
    ]
    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;
    switch (url) {
      case '/admin/emailAutomation/emailLists': {
        this.selectedMenuIndex = 1;
        break;
      }
      default: {
        this.selectedMenuIndex = 0;
        break;
      }
    }
  }
}
