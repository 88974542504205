import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchTimeEntryComponent } from './batch-time-entry/batch-time-entry.component';
import { ExpenseManagerComponent } from './expense-manager/expense-manager.component';
import { TimeEntryListComponent } from './time-entry-list/time-entry-list.component';
import { TimeSheetEntryComponent } from './time-sheet-entry.component';
import { TimeCardComponent } from './time-card/time-card.component';
import { CanDeactivateForm } from './can-deactivate';
import { AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';


const routes: Routes = [
  {
    path: '',
    component: TimeSheetEntryComponent,
    children: [
      { path: '', component: TimeEntryListComponent,canActivate: [AuthGuard] },
      { path: AppConstants.list, component: TimeEntryListComponent,canActivate: [AuthGuard] },
      { path: AppConstants.timeCard, component: TimeCardComponent,canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm]},
      { path: AppConstants.batchTimeCard, component: BatchTimeEntryComponent,canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm] },
      { path: AppConstants.expenseManager, component: ExpenseManagerComponent,canActivate: [AuthGuard], data: { 'featID': 26 }}
    ],
    data: { 'roles': ["manager","Senior Manager"]["staff"] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class TimeExpenseRoutingModule { }
