import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import * as AWS from "aws-sdk";
import Swal from 'sweetalert2';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
})
export class LocationsComponent implements OnInit {
  @ViewChild("searchValue") searchValue;
  cols: any = [];
  public companyDetailForm: UntypedFormGroup;
  dataSource: any = [];
  filteredItemsSize = -1;
  showAddDialog: boolean = false;
  public isImageUrl: boolean = false;
  public imageUrl: any = "";
  isFormValid: boolean = false;
  public file: any;
  public fileInput: any;
  public fileName: any;
  @ViewChild("fileUpload") fileUpload: any;
  @ViewChild("formDirective") formDirective: any;
  obj: any = {};
  location: any = {};
  companyID: any;
  companyName: any;

  constructor(
    private _fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {
    this.translate.reloadLang(this.translate.currentLang).subscribe((data) => {

      this.breadcrumbService.setItems([
        { label: this.translate.instant("company_setup") },
        { label: this.translate.instant("Locations"), icon: "ic-red" },
      ]);

      this.cols = [
        {
          field: "CompanyNameL",
          header: this.translate.instant("company-setup.Company-Name"),
        },
        { field: "Street1L", header: this.translate.instant("Street1") },
        { field: "Street2L", header: this.translate.instant("Street2") },
        { field: "CityL", header: this.translate.instant("client.city") },
        { field: "StateL", header: this.translate.instant("client.state") },
        { field: "ZipL", header: this.translate.instant("client.zip") },
        // { field: "CountryL", header: this.translate.instant("client.country") },
        {
          field: "TelephoneL",
          header: this.translate.instant("client.telephone_number"),
        },
        // { field: "FaxL", header: this.translate.instant("Fax-Number") },
        {
          field: "ContactL",
          header: this.translate.instant("client.contact_name"),
        },
        // { field: "EmailL", header: this.translate.instant("email") },
      ];
    });
  }

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    this.initializeForm();
    this.getData();
  }

  onFilter(obj) {
    if (!this.searchValue?.nativeElement?.value) return;

    this.filteredItemsSize = obj.filteredValue.length;
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    this.filteredItemsSize = -1;
  }

  getData() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.companyID).subscribe(
      (data: any) => {
        this.dataSource = data;
        this.filteredItemsSize = -1;
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  initializeForm() {
    this.companyDetailForm = this._fb.group({
      CompanyNameL: [this.companyName],
      street1: [
        null,
        [<any>Validators.maxLength(100), <any>Validators.minLength(3)],
      ],
      street2: [null, [<any>Validators.maxLength(100)]],
      city: [""],
      state: ["", [<any>Validators.maxLength(2), <any>Validators.minLength(2)]],
      zip: [""],
      timezone: [""],
      country: [
        ""[(<any>Validators.maxLength(2), <any>Validators.minLength(2))],
      ],
      phone: [""],
      faxNumber: [""],
      companyLogoWeb: [""],
      useCompanyLogo: ["false"],
      email: ["", [Validators.email]],
      companyId: [],
      PortalURL: [],
      TaxRateLaborL: [0.000],
      TaxRateExpenseL: [0.000],
      CompanyLocation: [
        "",
        [
          <any>Validators.required,
          <any>Validators.maxLength(15),
          <any>Validators.minLength(3),
        ],
      ],
      WebsiteCompany: [],
      companyContact: [
        "",
        [
          <any>Validators.maxLength(50),
          <any>Validators.minLength(3),
        ],
      ],
      companyMangoLocationID: [null]
    });

    this.companyDetailForm.valueChanges.subscribe((data) => {
      this.validateForm();
    });
  }

  updateForm(itemData: any) {
    if (Object.keys(itemData).length > 0) {
      this.companyDetailForm.controls["CompanyNameL"].setValue(
        itemData["CompanyNameL"].trim()
      );
      /* Company Information Data*/
      this.companyDetailForm.controls["companyMangoLocationID"].setValue(itemData["CompanyMangoLocationID"])
      this.companyDetailForm.controls["street1"].setValue(itemData["Street1L"]);
      if (itemData["Street2L"]) {
        this.companyDetailForm.controls["street2"].setValue(
          itemData["Street2L"]
        );
      }
      this.companyDetailForm.controls["city"].setValue(itemData["CityL"]);
      this.companyDetailForm.controls["state"].setValue(itemData["StateL"]);
      this.companyDetailForm.controls["zip"].setValue(itemData["ZipL"]);
      this.companyDetailForm.controls["country"].setValue(itemData["CountryL"]);
      this.companyDetailForm.controls["CompanyLocation"].setValue(itemData["CompanyLocation"]);
      this.companyDetailForm.controls["TaxRateLaborL"].setValue(itemData["TaxRateLaborL"]);
      this.companyDetailForm.controls["TaxRateExpenseL"].setValue(itemData["TaxRateExpenseL"]);

      /* Contact Information Data*/
      this.companyDetailForm.controls["phone"].setValue(itemData["TelephoneL"]);
      this.companyDetailForm.controls["faxNumber"].setValue(itemData["FaxL"]);
      this.companyDetailForm.controls["companyLogoWeb"].setValue(
        itemData["LogoFileL"]
      );

      this.companyDetailForm.controls["email"].setValue(itemData["EmailL"]);

      if (itemData["ContactL"]) {
        this.companyDetailForm.controls["companyContact"].setValue(
          itemData["ContactL"]
        );
      }
      if (itemData["LogoFileL"]) {
        this.isImageUrl = true;
        this.imageUrl = itemData["LogoFileL"];
      }
      this.companyDetailForm.controls["companyId"].setValue(
        this.encrDecSrvc.getObject(AppConstants.companyID)
      );

    }
  }

  validateForm() {
    let isInValidData = false;
    Object.keys(this.companyDetailForm.controls).forEach((key) => {
      if (this.companyDetailForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData && this.companyDetailForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  uploadImage( ): void {
    const uniqueName = (
      `${ this.encrDecSrvc.getObject( AppConstants.companyID ) }_${ this.file.name }`
    );

    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = "us-east-1";

    let bucket = (
      new AWS.S3(
        {
          params: {
            Bucket: "mangobillinglogos"
          }
        }
      )
    );
    */
    const bucket = (
      AWSBucketMask
    );

    const params = {
      Bucket: "mangobillinglogos",
      Key: "companylogo/" + uniqueName,
      ACL: "public-read",
      Body: this.file,
    };

    this.mangoAPISrvc.showLoader( true );

    bucket.upload(
      params,

      ( err, data ) => {
        this.mangoAPISrvc.showLoader( false );

        if( err ){
          return;
        }

        this.isImageUrl = true;
        this.imageUrl = data.Location;

        this.companyDetailForm.controls[ "companyLogoWeb" ]
        .setValue( this.imageUrl );

        if( this.obj.isEdit ){
          this.companyDetailForm.markAsDirty( );
        }

        this.validateForm( );
      },

      this.mangoAPISrvc
    );
  }

  removeImage() {
    if (this.imageUrl) {
      this.companyDetailForm.controls["companyLogoWeb"].setValue(null);
      this.imageUrl = null;
      this.isImageUrl = false;
      this.fileUpload.clear();
      if(this.obj.isEdit)
        this.companyDetailForm.markAsDirty();

      this.validateForm();
    }
  }

  fileEvent( fileInput: any ) {
    for( const file of fileInput.files ){
      this.file = file;

      this.uploadImage( );
    }
  }

  editRow(data) {
    this.obj = {};
    this.obj.isEdit = true;
    this.obj.title = "Location";
    this.updateForm(data);
    this.showAddDialog = true;
  }

  deleteRow(data) {
    this.mangoAPISrvc.getLocationUseById(data.CompanyMangoLocationID).subscribe((loc: any) => {
      if (loc[0].locationusage <= 1) {
        Swal.fire({
          title: this.translate.instant('confirmation'),
          text: this.translate.instant('delete_alert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('yes_delete'),
          cancelButtonText: this.translate.instant('no_delete')
        }).then((result) => {
          if (result.value) {
            this.mangoAPISrvc.showLoader(true);
            this.mangoAPISrvc.deleteCompanyLocation(data.CompanyMangoLocationID).subscribe((resp) => {
              this.dataSource = this.dataSource.filter((location) => location.CompanyMangoLocationID !== data.CompanyMangoLocationID);
              this.mangoAPISrvc.showLoader(false);
              this.mangoAPISrvc.notify("success", this.translate.instant("Success"), AppConstants.deleteMessage);
            }, err => {
              this.mangoAPISrvc.showLoader(false)
              this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.deleteErrorMsg);
            })
          }
        })

      } else {
        Swal.fire({
          title: this.translate.instant('warning'),
          text: this.translate.instant('locationDeletionWarning'),
          icon: 'warning',
        }).then((result) => {
        })

      }

    })
  }

  addLocation() {
    this.companyDetailForm.controls["CompanyNameL"].setValue(
      this.companyName
    );
    this.location = {};
    this.obj = {};
    this.obj.isEdit = false;
    this.obj.title = "Location";
    this.showAddDialog = true;
  }

  save(isEdit) {
    this.isFormValid = false;
    const obj = this.companyDetailForm.value;

    obj.CompanyID = this.companyID;
    obj.Street1L = this.companyDetailForm.controls['street1'].value
    obj.Street2L = this.companyDetailForm.controls['street2'].value
    obj.CityL = this.companyDetailForm.controls['city'].value
    obj.StateL = this.companyDetailForm.controls['state'].value
    obj.ZipL = this.companyDetailForm.controls['zip'].value
    // obj.CountryL = this.companyDetailForm.controls['country'].value
    obj.TelephoneL = this.companyDetailForm.controls['phone'].value
    obj.FaxL = this.companyDetailForm.controls['faxNumber'].value
    obj.LogoFileL = this.imageUrl
    // obj.SalesTaxRateL
    obj.ContactL = this.companyDetailForm.controls['companyContact'].value
    obj.EmailL = this.companyDetailForm.controls['email'].value

    if (!isEdit) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.addCompanyLocation(obj).subscribe((data: any) => {
        this.getData();
        this.mangoAPISrvc.showLoader(false)
        this.mangoAPISrvc.notify("success", this.translate.instant("Success"), AppConstants.createMsg)
      }, error => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.createErrorMsg
        );
      })
    } else {
      obj.CompanyMangoLocationID = this.companyDetailForm.controls['companyMangoLocationID'].value
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateCompanyLocation(obj.CompanyMangoLocationID, obj).subscribe(
        (data) => {
          this.getData();
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "success",
            this.translate.instant("Success"),
            AppConstants.updateMsg
          );
        },
        () => {
          this.mangoAPISrvc.notify(
            "error",
            this.translate.instant("error"),
            AppConstants.updateErrorMsg
          );
          this.mangoAPISrvc.showLoader(false);
        }
      );
    }

    this.isFormValid = false;
    this.closeDialog();
  }

  closeDialog() {
    this.removeImage();
    this.location = {};
    this.formDirective.resetForm();
    this.companyDetailForm.reset();
    this.showAddDialog = false;
  }
}
