import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';

import { UsersComponent, ViewComponent, CanDeactivateForm, ScheduleComponent, ListComponent, UserRatesComponent, EducationComponent, PermissionsComponent } from '@app/modules/users';

const routes: Routes = [
  {
    path: '', component: UsersComponent,
    canActivate: [AuthGuard],
    children: [
      { path: AppConstants.listRoutePath, component: ListComponent, canActivate: [AuthGuard], data: { 'featID': 9 } },
      { path: AppConstants.viewRoutePath, component: ViewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 9 } },
      { path: AppConstants.ratesRoutePath, component: UserRatesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 9 } },
      { path: AppConstants.educationRoutePath, component: EducationComponent, canActivate: [AuthGuard], data: { 'featID': 9 } },
      { path: AppConstants.scheduleRoutePath, component: ScheduleComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm],data: { 'roles': ["manager", "staff"], 'featID': 9 }},
      { path: AppConstants.permissionsRoutePath, component: PermissionsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager"], 'featID': 9 } },
    ],
    data: { 'roles': ["administrator"] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class UsersRoutingModule { }
