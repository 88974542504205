import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs'
import { TranslateService } from '@ngx-translate/core';

import { EncrDecrService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import Swal from 'sweetalert2';
import { EngagementsViewComponent } from './engagements-view/engagements-view.component';

@Injectable()
export class CanDeactivateForm  {
  constructor(private _translate: TranslateService, private _service: EncrDecrService) { }

  canDeactivate(
    component: EngagementsViewComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    const isFormChanged = this._service.getObject(AppConstants.isFormChanged);
    if (!isFormChanged) {
      return true;
    }
    return Observable.create((observer: Observer<boolean>) => {
      Swal.fire({
        title: this._translate.instant('Warning'),
        text: this._translate.instant('You have unsaved changes. Are you sure you want to leave this page?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, leave without saving',
        cancelButtonText: 'No, stay here!'
      }).then((result) => {
        if (result.value) {
          this._service.addObject(AppConstants.isFormChanged, false); 
          observer.next(true);
          observer.complete();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          observer.next(false);
          observer.complete();
        }
      })
    });
  }
}
