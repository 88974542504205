<div class="layout-content p-pt-0">
  <div class="p-fluid p-formgrid p-grid p-mx-2">
      <div class="p-col-4">
          <div class="card p-mt-2" style="height: 100%;">
              <h4 class="label-text">Startup Options</h4>
              <div class="p-field p-col-12">
                  <label class="label-text">Load screen after logging in</label>
                  <p-dropdown [ngModelOptions]="{standalone: true}" [options]="loadAfterLogin" 
                      [style]="{width: '300px'}" [(ngModel)]="LoadScreen" (onChange)="onChangeSettings($event, false);"></p-dropdown>
              </div>
              <div class="p-field p-col-12">
                  <p-checkbox [binary]="true" label="Lock Side Menu Open" [(ngModel)]="isLockMenu" (onChange)="onChangeSettings($event, false);"
                      [ngModelOptions]="{standalone: true}" class="label-text"></p-checkbox>
              </div>
          </div>
      </div>
      <div class="p-col-4">
          <div class="card p-mt-2" style="height: 100%;">
              <h4 class="label-text">Time Entry Settings</h4>
              <div class="p-my-3">
                  <p-checkbox name="isEnabledMSSignIn" binary="true"
                      label="Use Last Stop Time as Start Time" (onChange)="onChangeSettings($event, false)" [(ngModel)]="isUsedLastStartTime"
                      [ngModelOptions]="{standalone: true}"></p-checkbox>
                  <i pTooltip="Used to get the last End Time to be the Start Time"
                      tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                      class="fa fa-question-circle ic-blue p-ml-1"></i>
              </div>
          </div>
      </div>
      <div class="p-col-4">
          <div class="card p-mt-2" style="height: 100%;">
              <h4 class="label-text">Notification</h4>
              <div class="p-my-3">
                  <p-checkbox name="isNotifyTodo" binary="true"
                      label="{{ 'staff.notify-todo' | translate }}" (onChange)="onChangeSettings($event, false)" [(ngModel)]="isNotifyTodo"
                      [ngModelOptions]="{standalone: true}"></p-checkbox>
              </div>
          </div>
      </div>
  </div>
</div>
