import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SharedComponentsService } from "@app/shared/components";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, mangoUtils, MangoApiService, AuthGuard } from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { TasksBudgetComponent }  from "./tasks-budget/tasks-budget.component"
import moment from "moment";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
declare let numeral: any;
import $ from "jquery";
import { UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "app-budgets",
  templateUrl: "./budgets.component.html",
})
export class BudgetsComponent implements OnInit {
  @Input() projectBudgetObj: any;
  @Input() isFromProject: boolean;
  @ViewChild(TasksBudgetComponent) tasksDetailComponent;
  clonedData: any = [];
  lastRowIndex: number = -1;
  companyID;
  staffID;
  clientID;

  currSavedBudgetEngagements = [];
  currSavedBudgetYears = [];
  budgetYear: any = null;
  budgetEngagement = null;

  budgetsObject: any = {
    isShowBudgets: false,
  };

  footerTotalRevenue = {
    BudgetAmount: 0,
    ActualAmount: 0,
    Difference: 0,
  };

  footerTotalTime = {
    BudgetAmount: 0,
    ActualAmount: 0,
    Difference: 0,
  };

  selectedBudget: any = {};

  revenueDataSource = [];
  timeDataSource = [];
  ddmSettings: any;

  budgetsFromProject = [];

  engagementListItems = [];
  yearsList = [];

  AllUsers = [];

  formObj = {
    Year: null,
    ProjectMasterID: null,
    RevenueAmount: "0",
    TimeAmount: "0",
    type: null,
  };

  templateObj = {
    isUpdateRevenue: false,
    isUpdateTime: false,
    percentage: 0,
    timePercentage: 0,
  };

  rolloverObj = {
    isUpdateRevenue: false,
    isUpdateTime: false,
    percentage: 0,
    timePercentage: 0,
    isEnableRollover: true,
    isDefaultBudgetCompanyRollover: false,
  };

  notifObj = {
    amountAndAlertArr: {
      time: [],
      revenue: []
    },
    RevenueEmailList: [],
    TimeEmailList: [],
    RevenueAlertAmounts: [],
    TimeAlertAmounts: [],
    isRevenueAlerts: [],
    isTimeAlerts: [],
    RevenueAlertAmount: "0",
    TimeAlertAmount: "0",
    isRevenueAlert: false,
    isTimeAlert: false,
    engagementName: "",
  };

  previousNotifObj = {
    RevenueEmailList: [],
    TimeEmailList: [],
    RevenueAlertAmount: "0",
    TimeAlertAmount: "0",
  };

  radOptions = null;
  rolloverOptions = null;

  showAddUpdateDialog = false;
  showAddTemplateDialog = false;
  isEditMode = false;
  showManageNotifications = false;
  isBudgetCreated = false;
  initialBudgetForTime: number = 0
  isRevChipsChanged = false;
  isTimeChipsChanged = false;

  graphData: any;
  graphOptions: any;
  colors: any = ["#9ccd65", "#aa7ca2"];
  mySettingsForm:any = [];

  constructor(
    private _fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public sharedSrvc: SharedComponentsService,
    private translate: TranslateService,
    public mangoUtils: mangoUtils,
    private router: Router,
    public authGuard: AuthGuard,
  ) {
    this.graphOptions = {
      title: {
        display: true,
        text: "Monthly Total Time",
        fontSize: 16,
      },
      legend: {
        position: "bottom",
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += ": ";
            }
            label += numeral(tooltipItem.yLabel).format("0,0.00");
            label += " hrs.";
            return label;
          },
        },
      },
    };
  }

  ngOnInit(): void {
    //fetch budgetsObject
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.clientID = this.isFromProject
      ? this.projectBudgetObj.ClientID
      : this.encrDecSrvc.getObject(AppConstants.clientID);
    if (this.isFromProject) {
      this.formObj.ProjectMasterID = this.budgetEngagement =
        this.projectBudgetObj.ProjectMasterID;
    }
    this.getDDMSettings();
    this.getBudgets(
      parseInt(moment().format("YYYY")),
      this.budgetEngagement,
      this.clientID,
      this.companyID
    );

    
    this.mySettingsForm = this._fb.group({
      ddmSettingsID: [null],
      CompanyID: [this.companyID],
      DefaultUserID: [this.staffID],
      DefaultManagerID: [this.staffID],
     /*  Tag1: ['Available for Work'],
      Tag2: ['Extended'],
      Tag3: [''],
      Tag4: [''],
      Tag5: [''],
      Tag6: [''],
      Tag7: [''],
      Tag8: [''],
      Tag9: [''],
      Tag10: [''],
      Tag11: [''],
      Tag12: [''], */
      IsEmailProjectAssigned: [false],
      IsEmailTaskAssigned: [false],
      IsEmailTaskReady: [false],
      IsManagerReadyReview: [false],
      IsManagerProjectInProgress: [false],
      IsProjectRejected: [false],
      DashboardProjectTags: ['both'],
      CompletionPermission: [null],
      EnableProjectEmailAlerts: false,
      EnableTaskEmailAlerts: false,
      DaysBeforePastDueDateHeader: [0],
      DaysBeforePastDueDateHeaderBool: false,
      ProjectDaysToRemind: [0],
      TasksDaysToRemind: [0],
      PastDueHeader: [false],
      isNotifyManagerHeader: [false],
      isNotifyBillingPartnerHeader: [false],
      DaysBeforePastDueDateDetail: [0],
      DaysBeforePastDueDateDetailBool: false,
      PastDueDetail: [false],
      isNotifyManagerDetail: [false],
      isNotifyBillingPartnerDetail: [false],
      isNotifyStaffAssignedDetail: [false],
      isTrackingTimebyProjects: [false],
      isBudgetingProjectLevel: [false],
      isCapacityPlanningEnabled: [false],
      isTaskBudgetEnabled: [false],
      isShowProjectsView: [false],
    });

    this.mySettingsForm.valueChanges.subscribe(val => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.mySettingsForm.pristine);
    });

    this.mangoAPISrvc.fetchDDMSettings(this.companyID).subscribe((data: any) => {
      if (data.length > 0) {
        this.encrDecSrvc.addObject(AppConstants.companyTags, data[0]);
        this.ddmSettings = data[0];
        this.updateSettingsForm(data[0]);
        //this.fetchTagArray(data[0]);
      }
      else{
        //this.fetchTagArray(data[0]);
      }

      this.mangoAPISrvc.showLoader(false);
    });
    
    setTimeout(() => {
      this.onChangeTrackTime();      
    }, 2000);

  }

  
  updateSettingsForm(itemData) {
    if (itemData && Object.keys(itemData).length > 0) {
      this.mySettingsForm.controls['ddmSettingsID'].setValue(itemData['ddmSettingsID']);
      this.mySettingsForm.controls['IsEmailProjectAssigned'].setValue(itemData['IsEmailProjectAssigned']);
      this.mySettingsForm.controls['IsEmailTaskAssigned'].setValue(itemData['IsEmailTaskAssigned']);
      this.mySettingsForm.controls['IsEmailTaskReady'].setValue(itemData['IsEmailTaskReady']);
      this.mySettingsForm.controls['IsManagerReadyReview'].setValue(itemData['IsManagerReadyReview']);
      this.mySettingsForm.controls['IsManagerProjectInProgress'].setValue(itemData['IsManagerProjectInProgress']);
      this.mySettingsForm.controls['IsProjectRejected'].setValue(itemData['IsProjectRejected']);
      this.mySettingsForm.controls['DefaultUserID'].setValue(itemData['DefaultUserID']);
      this.mySettingsForm.controls['DefaultManagerID'].setValue(itemData['DefaultManagerID']);
      this.mySettingsForm.controls['DashboardProjectTags'].setValue(itemData['DashboardProjectTags'] ? itemData['DashboardProjectTags'] : 'both');
      this.mySettingsForm.controls['CompletionPermission'].setValue(itemData['CompletionPermission'] ? itemData['CompletionPermission'] : null);
      this.mySettingsForm.controls['isTrackingTimebyProjects'].setValue(itemData['isTrackingTimebyProjects']);
      this.mySettingsForm.controls['isBudgetingProjectLevel'].setValue(itemData['isBudgetingProjectLevel']);
      this.mySettingsForm.controls['isCapacityPlanningEnabled'].setValue(itemData['isCapacityPlanningEnabled']);
      this.mySettingsForm.controls['isTaskBudgetEnabled'].setValue(itemData['isTaskBudgetEnabled']);
      this.mySettingsForm.controls['isShowProjectsView'].setValue(itemData['isShowProjectsView']);

    }
  }


  getBudgets(year, projectID, clientID, companyID, isDelete?) {
    const ddmProjectHeaderID = this.isFromProject
      ? this.projectBudgetObj.ddmProjectHeaderID
      : null;

    this.budgetsObject.isShowBudgets = false; // to refetch tasks budget
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getBudgets(year, projectID, clientID, ddmProjectHeaderID, companyID)
      .subscribe(
        (data: any) => {
          this.revenueDataSource = [];
          this.timeDataSource = [];

          this.budgetsObject = data;

          if (!this.budgetsObject.YearEndMonth) {
            Swal.fire({
              icon: "warning",
              title: "Warning!",
              text: `You need to configure your Year End Month first at Settings > Company Information!`,
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: "Ok",
            });
          }

          if (
            this.isBudgetCreated &&
            this.ddmSettings?.isBudgetingProjectLevel &&
            !this.isFromProject &&
            this.budgetEngagement &&
            this.budgetsObject.isShowBudgets &&
            this.budgetsObject.budgets.filter(
              (item) => item.ddmProjectHeaderID !== null
            ).length == 0
          ) {
            this.isBudgetCreated = false;
            Swal.fire({
              icon: "warning",
              title: "Warning!",
              html: `<div>
                There is currently no budget for this engagement (<strong>${this.getEngagementName(
                  this.budgetEngagement
                )}</strong>) at the Project level. 
                <br/>You would need to manually add a budget at the project level (Project Management > Project > Budgets) to add a budget for Time.
              </div>`,
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: "Ok",
            });
          }

          if (
            this.isFromProject &&
            this.isBudgetCreated &&
            this.budgetsObject.isShowBudgets &&
            !this.budgetsObject.isParentExists
          ) {
            this.isBudgetCreated = false;
            Swal.fire({
              icon: "warning",
              title: "Warning!",
              html: `<div>
                There is currently no budget for this engagement (<strong>${this.projectBudgetObj["ProjectMasterLabel"]}</strong>) at the Client > Budget level. 
                <br/>You would need to manually add a budget at the Client > Budget level (Client > Budgets) to add a budget for Revenue.
              </div>`,
              showConfirmButton: true,
              showCancelButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: this.translate.instant("Go-to-Client"),
              cancelButtonText: this.translate.instant("no_cancel"),
            }).then((result) => {
              if (result.value) {
                this.redirectToClientView(clientID)
              }
            })
          }

          this.isBudgetCreated = false;
          this.currSavedBudgetYears = this.budgetsObject.Years.map((item) => ({
            ...item,
            label: item,
            value: item,
          }));

          if (!this.isFromProject && this.budgetEngagement) {
            this.budgetsFromProject = this.budgetsObject.budgets.filter(
              (budget) => budget.ddmProjectHeaderID
            );
          }

          if (isDelete && this.budgetsObject.budgets?.length > 0) {
            this.budgetYear = this.budgetsObject.budgets[0]?.Year;
          } else {
            if (this.currSavedBudgetYears.length == 0) {
              this.currSavedBudgetYears.unshift({
                label: moment().format("YYYY"),
                value: moment().format("YYYY"),
              });
              if (!this.budgetYear)
                this.budgetYear = this.budgetsObject.budgets[0]?.Year;
            } else {
              if (
                this.currSavedBudgetYears.filter(
                  (item) => item.value == parseInt(moment().format("YYYY"))
                )?.length > 0
              ) {
                if (!this.budgetYear)
                  this.budgetYear = this.currSavedBudgetYears.filter(
                    (item) => item.value == parseInt(moment().format("YYYY"))
                  )[0].value;
              } else {
                if (!this.budgetYear)
                  this.budgetYear = this.budgetsObject.budgets[0]?.Year;
              }
              // if(!this.budgetYear) this.budgetYear = this.currSavedBudgetYears[0].value
            }
          }

          this.drawCharts();

          this.filterEngagementOptions();

          this.mangoAPISrvc.showLoader(false);
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.fetchErrorMsg
          );
        }
      );
  }

  redirectToClientView(clientId: number) {
    const clientSelected = this.encrDecSrvc.clientList?.filter((client) => client.ClientID == clientId)[0]
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, clientSelected);
    this.encrDecSrvc.addObject(AppConstants.clientID, clientId);
    this.encrDecSrvc.addObject(AppConstants.ClientName, clientSelected?.ClientName);
    this.encrDecSrvc.addObject(
      AppConstants.selectedClientTabIndex,
      11
    );

    this.mangoAPISrvc.fireClientView(true);

    this.router.navigate([
      AppConstants.clientRoutePath +
      "/" +
      AppConstants.budgetsRoutePath,
    ]);
  }

  calculateFooters() {
    // footerTotalRevenue
    this.footerTotalRevenue.BudgetAmount = this.revenueDataSource.reduce(
      (a, b) => {
        return a + numeral(b.BudgetAmount).value();
      },
      0
    );
    this.footerTotalRevenue.ActualAmount = this.revenueDataSource.reduce(
      (a, b) => {
        return a + numeral(b.ActualAmount).value();
      },
      0
    );
    this.footerTotalRevenue.Difference = this.revenueDataSource.reduce(
      (a, b) => {
        return a + numeral(b.Difference).value();
      },
      0
    );

    // footerTotalTime
    this.footerTotalTime.BudgetAmount = this.timeDataSource.reduce((a, b) => {
      return a + numeral(b.BudgetAmount).value();
    }, 0);
    this.footerTotalTime.ActualAmount = this.timeDataSource.reduce((a, b) => {
      return a + numeral(b.ActualAmount).value();
    }, 0);
    this.footerTotalTime.Difference = this.timeDataSource.reduce((a, b) => {
      return a + numeral(b.Difference).value();
    }, 0);
  }

  onRowEditInit(rowData, type, index) {
    const column = "index";

    const filtered = this.clonedData.filter(
      (item) => item[column] == rowData[column] && item["type"] == type
    );
    if (filtered.length == 0) {
      this.clonedData.push({ ...rowData, type });
    }

    if (rowData["IsRowEditing"] && this.lastRowIndex == index) return;

    this.lastRowIndex = index;
    if (type === "revenue") {
      this.selectCell("rev", index);
    } else {
      this.selectCell("time", index);
    }
    rowData["IsRowEditing"] = true;
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`;
      colClass += xtraEl ? ` > ${xtraEl}` : "";
      $(colClass).select();
    }, 50);
  }

  removeItemInClonedArray(id, type) {
    this.clonedData = this.clonedData.filter(
      (item) => item["index"] !== id && item["type"] !== type
    );
  }

  onRowEditSave(rowData, type) {
    rowData["IsRowEditing"] = false;
    this.removeItemInClonedArray(rowData["index"], type);
  }

  onRowEditCancel(rowData, type) {
    rowData["IsRowEditing"] = false;
    const column = "index";
    const cloned = this.clonedData.filter(
      (item) => item[column] === rowData[column] && item["type"] === type
    )[0];

    rowData.BudgetAmount = cloned.BudgetAmount;
    this.removeItemInClonedArray(rowData[column], column);
  }

  refresh(isDelete?: boolean) {
    this.getBudgets(
      isDelete ? null : this.budgetYear,
      isDelete
        ? null
        : this.isFromProject
        ? this.projectBudgetObj.ProjectMasterID
        : this.budgetEngagement,
      this.clientID,
      this.companyID,
      isDelete
    );
  }

  handleSelectYear() {
    this.budgetEngagement = null;
    this.refresh();
  }

  deduplicateArray(arr, column) {
    const dedupArr = [];
    const retArr = [];
    arr.map((item) => {
      if (!dedupArr.includes(item[column])) {
        retArr.push(item);
        dedupArr.push(item[column]);
      }
    });

    return retArr;
  }

  filterEngagementOptions() {
    this.currSavedBudgetEngagements = this.budgetsObject.Engagements.filter(
      (item) => item.Year == this.budgetYear
    ).map((item) => ({
      ...item,
      label: item.EngagementName,
      value: item.ProjectMasterID,
    }));

    this.currSavedBudgetEngagements = this.deduplicateArray(
      this.currSavedBudgetEngagements,
      "ProjectMasterID"
    );

    this.currSavedBudgetEngagements.unshift({
      label: "All Engagements",
      value: null,
    });
  }

  editBudget() {}

  showEditDialog(type) {
    if (
      type !== "revenue" &&
      this.ddmSettings?.isBudgetingProjectLevel &&
      !this.isFromProject
    ) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: this.translate.instant(
          `budgets.edit_only_allowed_at_project_level`
        ),
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Ok",
      });
      return;
    }

    this.clearFormValues();
    this.isEditMode = true;
    this.formObj.type = type;
    if (type == "revenue")
      this.formObj.RevenueAmount = this.mangoUtils.doMoneyFormat(
        this.budgetsObject.RevenueAmount
      );
    else
      this.formObj.TimeAmount = this.mangoUtils.doMoneyFormat(
        this.budgetsObject.TimeAmount,
        "time"
      );

    this.showAddUpdateDialog = true;
  }

  onCloseAddEditDialog() {
    this.isEditMode = false;
    this.clearFormValues();
    this.showAddUpdateDialog = false;
  }

  onSaveDialog() {
    if (this.isEditMode) {
      Swal.fire({
        title: this.translate.instant("confirmation"),
        html: this.translate.instant("budget.update_budget_alert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.translate.instant("Yes_Proceed"),
        cancelButtonText: this.translate.instant("no_cancel"),
      }).then((result) => {
        if (result.value) {
          const obj = {
            BudgetsID: !this.isFromProject
              ? this.budgetsObject.budgets.find(
                  (item) => !item.ddmProjectHeaderID
                ).BudgetsID
              : this.budgetsObject.budgets[0].BudgetsID,
          };
          const alertFlags = {
            rev: !this.isFromProject
              ? this.budgetsObject.budgets.find(
                  (item) => !item.ddmProjectHeaderID
                ).isRevenueAlerts
              : this.budgetsObject.budgets[0].isRevenueAlerts,
            time: !this.isFromProject
              ? this.budgetsObject.budgets.find(
                  (item) => !item.ddmProjectHeaderID
                ).isTimeAlerts
              : this.budgetsObject.budgets[0].isTimeAlerts,
          };

          if (this.formObj.type == "revenue") {
            obj["RevenueAmount"] = numeral(this.formObj.RevenueAmount).value();
            if(alertFlags.rev?.length > 0)
              obj["isRevenueAlerts"] = alertFlags.rev.map((item) => false);
          } else {
            obj["TimeAmount"] = numeral(this.formObj.TimeAmount).value();
            if(alertFlags.time?.length > 0)
              obj["isTimeAlerts"] = alertFlags.time.map((item) => false);
          }

          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc
            .updateBudget(obj, obj.BudgetsID, this.companyID)
            .subscribe(
              (data) => {
                this.mangoAPISrvc.showLoader(false);
                if (this.formObj.type == "time" && this.isFromProject) {
                  this.mangoAPISrvc
                    .updateClientLvlTimeBudgets(
                      this.budgetsObject.budgets[0].ProjectMasterID,
                      this.companyID
                    )
                    .subscribe(
                      (result) => {
                        this.refresh();
                        this.clearFormValues();
                        this.onCloseAddEditDialog();
                        this.mangoAPISrvc.notify(
                          "success",
                          "Success!",
                          AppConstants.updateMsg
                        );
                      },
                      (err) => {
                        this.mangoAPISrvc.showLoader(false);
                        this.mangoAPISrvc.notify(
                          "error",
                          "Error!",
                          AppConstants.updateErrorMsg
                        );
                      }
                    );
                } else {
                  this.refresh();
                  this.clearFormValues();
                  this.onCloseAddEditDialog();
                  this.mangoAPISrvc.notify(
                    "success",
                    "Success!",
                    AppConstants.updateMsg
                  );
                }
              },
              (err) => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify(
                  "error",
                  "Error!",
                  AppConstants.updateErrorMsg
                );
              }
            );
        }
      });
    } else {
      const obj = {
        CompanyID: this.companyID,
        Year: this.formObj.Year,
        ProjectMasterID: this.formObj.ProjectMasterID,
        ClientID: this.clientID,
        RevenueAmount: !this.isFromProject
          ? numeral(this.formObj.RevenueAmount).value()
          : 0,
        TimeAmount: numeral(this.formObj.TimeAmount).value(),
        ddmProjectHeaderID: this.isFromProject
          ? this.projectBudgetObj.ddmProjectHeaderID
          : null,
        //save Rollover options
        isDefaultBudgetCompanyRollover:
          this.rolloverObj.isDefaultBudgetCompanyRollover,
        isEnableRollOver: this.rolloverObj.isEnableRollover,
        RollOverOption: this.rolloverOptions,
        PctOptionTime:
          this.rolloverObj.isUpdateTime && this.rolloverOptions == "percentage"
            ? numeral(this.rolloverObj.timePercentage).value()
            : 0,
        PctOptionRev:
          this.rolloverObj.isUpdateRevenue &&
          this.rolloverOptions == "percentage"
            ? numeral(this.rolloverObj.percentage).value()
            : 0,
      };

      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createBudget(obj).subscribe(
        (result: any) => {
          this.isBudgetCreated = true;
          this.mangoAPISrvc.showLoader(false);
          if (result?.exists) {
            const engagementName = !this.isFromProject ? this.engagementListItems.filter(
              (item) => item.value == obj.ProjectMasterID
            )[0].label : this.projectBudgetObj['ProjectMasterLabel'];
            Swal.fire({
              icon: "warning",
              title: "Warning!",
              text: `A budget for Year ${this.formObj.Year} and Engagement ${engagementName} already exists in this client!`,
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: "Ok",
            });
          } else {
            this.budgetYear = null;
            this.budgetEngagement = null;
            this.refresh();
            this.clearFormValues();
            this.onCloseAddEditDialog();
            this.mangoAPISrvc.notify(
              "success",
              "Success!",
              AppConstants.createMsg
            );
          }
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.createErrorMsg
          );
        }
      );
    }
  }

  shouldDisable(isAddTemplate?: boolean) {
    if (!isAddTemplate) {
      if (!this.isEditMode) {
        return !(
          this.formObj.ProjectMasterID &&
          this.formObj.Year &&
          ((this.isFromProject && numeral(this.formObj.TimeAmount).value()) ||
            (!this.isFromProject &&
              numeral(this.formObj.RevenueAmount).value())) &&
          (this.ddmSettings?.isBudgetingProjectLevel ||
            (!this.ddmSettings?.isBudgetingProjectLevel &&
              numeral(this.formObj.TimeAmount).value())) &&
          !this.shouldDisableRollover()
        );
      } else {
        return !(
          (this.formObj.type == "revenue" && this.isFromProject) ||
          (!this.isFromProject &&
            numeral(this.formObj.RevenueAmount).value() &&
            this.formObj.RevenueAmount !== this.budgetsObject.RevenueAmount) ||
          (this.formObj.type == "time" &&
            numeral(this.formObj.TimeAmount).value() &&
            this.formObj.TimeAmount !== this.budgetsObject.TimeAmount)
        );
      }
    } else {
      return !(
        (this.radOptions && this.radOptions !== "percentage") ||
        (!this.isFromProject &&
          ((this.templateObj.isUpdateRevenue && this.templateObj.percentage) ||
            (this.templateObj.isUpdateTime &&
              this.templateObj.timePercentage))) ||
        (this.isFromProject &&
          this.templateObj.isUpdateTime &&
          this.templateObj.timePercentage)
      );
    }
  }

  clearFormValues() {
    this.formObj["Year"] = null;
    this.formObj["RevenueAmount"] = "0";
    this.formObj["TimeAmount"] = "0";
    this.formObj["type"] = null;

    if (!this.isFromProject) this.formObj["ProjectMasterID"] = null;
  }

  showAddDialog() {
    // if(this.ddmSettings?.isBudgetingProjectLevel && !this.isFromProject) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Warning!",
    //     text: this.translate.instant(`budgets.add_only_allowed_at_project_level`),
    //     showConfirmButton: true,
    //     allowEscapeKey: false,
    //     allowEnterKey: false,
    //     confirmButtonText: "Ok",
    //   });
    //   return;
    // }

    this.rolloverObj.percentage = 0;
    this.rolloverObj.timePercentage = 0;
    this.rolloverObj.isUpdateRevenue = false;
    this.rolloverObj.isUpdateTime = false;
    this.rolloverObj.isDefaultBudgetCompanyRollover = false;
    this.rolloverOptions = "existing";

    if (
      !this.budgetsObject.isShowBudgets ||
      (!this.budgetEngagement && !this.isFromProject)
    ) {
      this.getEngagements(this.clientID);
      this.isEditMode = false;
      this.formObj.RevenueAmount = this.mangoUtils.doMoneyFormat("0");
      this.formObj.TimeAmount = this.mangoUtils.doMoneyFormat("0", "time");
      this.formObj.Year = parseInt(moment().format("YYYY"));
      this.getYears();

      this.showAddUpdateDialog = true;
    } else {
      this.showAddTemplateDialog = true;
    }
  }

  getTemplateAmount(type) {
    let amount = 0;
    if (type == "revenue") {
      if (this.radOptions == "percentage") {
        if (this.templateObj.isUpdateRevenue) {
          amount =
            (this.budgetsObject.RevenueAmount * this.templateObj.percentage) /
              100 +
            this.budgetsObject.RevenueAmount;
        } else {
          amount = this.budgetsObject.RevenueAmount;
        }
      } else if (this.radOptions == "existing") {
        amount = this.budgetsObject.RevenueAmount;
      } else {
        amount = this.budgetsObject.RevenueFigures.TimeValue;
      }
    } else {
      if (!this.isFromProject && this.ddmSettings?.isBudgetingProjectLevel) {
        amount = 0;
      } else {
        if (this.radOptions == "percentage") {
          if (this.templateObj.isUpdateTime) {
            amount =
              (this.budgetsObject.TimeAmount *
                this.templateObj.timePercentage) /
                100 +
              this.budgetsObject.TimeAmount;
          } else {
            amount = this.budgetsObject.TimeAmount;
          }
        } else if (this.radOptions == "existing") {
          amount = this.budgetsObject.TimeAmount;
        } else {
          amount = this.budgetsObject.TimeFigures.TotalHrs;
        }
      }
    }

    return amount;
  }

  computePctTimeBudget() {
    this.budgetsObject.TimeFigures.BudgetPct =
      (this.mangoUtils.converToNum(this.budgetsObject.TimeFigures.TotalHrs) /
        this.mangoUtils.converToNum(this.budgetsObject.TimeAmount)) *
      100;
  }

  getNextYear() {
    return (
      // this.currSavedBudgetYears[0].value + 1 ||
      moment().add(1, "years").format("YYYY")
    );
  }

  getDDMSettings() {
    this.mangoAPISrvc.fetchDDMSettings(this.companyID).subscribe(
      (result: any) => {
        this.ddmSettings = result[0];
      },
      (err) => {
        this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg);
      }
    );
  }

  onSaveAddTemplateDialog() {
    const obj = {
      CompanyID: this.companyID,
      Year: this.getNextYear(),
      ProjectMasterID: this.isFromProject ? this.projectBudgetObj.ProjectMasterID : this.budgetEngagement,
      ClientID: this.clientID,
      RevenueAmount: !this.isFromProject
        ? this.getTemplateAmount("revenue")
        : 0,
      TimeAmount: this.getTemplateAmount("time"),
      ddmProjectHeaderID: this.isFromProject
        ? this.projectBudgetObj.ddmProjectHeaderID
        : null,
    };

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.createBudget(obj).subscribe(
      (result: any) => {
        this.isBudgetCreated = true;
        this.mangoAPISrvc.showLoader(false);
        if (result?.exists) {
          const engagementName = !this.isFromProject ? this.engagementListItems.filter(
            (item) => item.value == obj.ProjectMasterID
          )[0].label : this.projectBudgetObj['ProjectMasterLabel'];
          Swal.fire({
            icon: "warning",
            title: "Warning!",
            text: `A budget for Year ${this.getNextYear()} and Engagement ${engagementName} already exists in this client!`,
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: "Ok",
          });
        } else {
          this.templateObj.percentage = 0;
          this.refresh();
          this.onCloseAddTemplateDialog();
          this.mangoAPISrvc.notify(
            "success",
            "Success!",
            AppConstants.createMsg
          );
        }
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.createErrorMsg
        );
      }
    );
  }

  onCloseAddTemplateDialog() {
    this.radOptions = null;
    this.templateObj = {
      isUpdateRevenue: false,
      isUpdateTime: false,
      percentage: 0,
      timePercentage: 0,
    };
    this.showAddTemplateDialog = false;
  }

  getYears() {
    if (this.yearsList?.length > 0) return;

    const value = 5;
    const yearToday = parseInt(moment().format("YYYY"));
    for (let x = yearToday + value; x >= yearToday - 3; x--) {
      this.yearsList.push({ label: x.toString(), value: x });
    }
  }

  getEngagements(clientID) {
    if (this.engagementListItems?.length > 0) return;

    this.engagementListItems = [];

    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.getProjectsByClientId(clientID).subscribe(
      (data: any) => {
        this.mangoAPISrvc.showLoader(false);

        this.engagementListItems = data
          .filter((item) => item.Inactive == false)
          .map((item) => ({
            label: item.EngagementName,
            value: item.ProjectMasterID,
          }));

        if (this.engagementListItems.length == 1) {
          this.formObj.ProjectMasterID = this.engagementListItems[0].value;
        }
      },
      () => {
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  private drawCharts() {
    const series = this.budgetsObject.TimeGraphByMonth?.map((stats, index) => {
      return {
        label: stats.name,
        backgroundColor: this.colors[index],
        borderColor: this.colors[index],
        data: stats.data,
      };
    });

    this.graphData = {
      labels: this.budgetsObject.MonthsLabel,
      datasets: series,
    };
  }

  deleteBudget() {
    const budgetsID = !this.isFromProject
      ? this.budgetsObject.budgets.find((item) => !item.ddmProjectHeaderID)
          .BudgetsID
      : this.budgetsObject.budgets[0].BudgetsID;

    Swal.fire({
      title: this.translate.instant("confirmation"),
      html: this.translate.instant("delete_alert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteBudget(budgetsID, this.companyID).subscribe(
          (result: any) => {
            if (this.isFromProject) {
              this.mangoAPISrvc
                .updateClientLvlTimeBudgets(
                  this.budgetsObject.budgets[0].ProjectMasterID,
                  this.companyID
                )
                .subscribe(
                  (result) => {
                    this.mangoAPISrvc.showLoader(false);
                    this.budgetYear = null;
                    this.budgetEngagement = null;
                    this.refresh(true);
                    this.mangoAPISrvc.notify(
                      "success",
                      "Success!",
                      AppConstants.deleteMessage
                    );
                  },
                  (err) => {
                    this.mangoAPISrvc.showLoader(false);
                    this.mangoAPISrvc.notify(
                      "error",
                      "Error!",
                      AppConstants.deleteErrorMsg
                    );
                  }
                );
            } else {
              this.mangoAPISrvc.showLoader(false);
              this.budgetYear = null;
              this.budgetEngagement = null;
              this.refresh(true);
              this.mangoAPISrvc.notify(
                "success",
                "Success!",
                AppConstants.deleteMessage
              );
            }
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "error",
              "Error!",
              AppConstants.deleteErrorMsg
            );
          }
        );
      }
    });
  }

  clearNotifObject() {
    this.notifObj = {
      amountAndAlertArr: {
        time: [],
        revenue: []
      },
      RevenueEmailList: [],
      TimeEmailList: [],
      RevenueAlertAmounts: [],
      TimeAlertAmounts: [],
      isRevenueAlerts: [],
      isTimeAlerts: [],
      RevenueAlertAmount: "0",
      TimeAlertAmount: "0",
      isRevenueAlert: false,
      isTimeAlert: false,
      engagementName: "",
    };

    this.rolloverObj = {
      isUpdateRevenue: false,
      isUpdateTime: false,
      percentage: 0,
      timePercentage: 0,
      isEnableRollover: true,
      isDefaultBudgetCompanyRollover: false,
    };

    this.rolloverOptions = null;
  }

  getEngagementName(projectMasterID) {
    return (
      this.currSavedBudgetEngagements.find((i) => i.value == projectMasterID)
        ?.EngagementName || ""
    );
  }

  get isAllowLoadOtherUser() {
    return this.authGuard.isAllowAccess(23)
  }

  manageBudget() {
    // this.selectedBudget = this.budgetsObject.budgets[0];
    this.selectedBudget = !this.isFromProject
      ? this.budgetsObject.budgets.find((item) => !item.ddmProjectHeaderID)
      : this.budgetsObject.budgets[0];

    this.notifObj.RevenueAlertAmount = this.mangoUtils.doMoneyFormat(
      this.selectedBudget.RevenueAlertAmount || 0
    );
    this.notifObj.TimeAlertAmount = this.mangoUtils.doMoneyFormat(
      this.selectedBudget.TimeAlertAmount || 0,
      "time"
    );

    this.selectedBudget.RevenueAlertAmounts?.map((amount, index) => {
      this.notifObj.amountAndAlertArr.revenue.push({
        amount: numeral(amount).value(),
        isAlert: this.selectedBudget.isRevenueAlerts
          ? this.selectedBudget.isRevenueAlerts[index]
          : false,
      });
    });

    this.selectedBudget.TimeAlertAmounts?.map((amount, index) => {
      this.notifObj.amountAndAlertArr.time.push({
        amount: numeral(amount).value(),
        isAlert: this.selectedBudget.isTimeAlerts
          ? this.selectedBudget.isTimeAlerts[index]
          : false,
      });
    });

    this.notifObj.RevenueAlertAmounts = this.selectedBudget.RevenueAlertAmounts
      ? this.selectedBudget.RevenueAlertAmounts.map((item) =>
          numeral(item).value()
        )
      : [];
    this.notifObj.TimeAlertAmounts = this.selectedBudget.TimeAlertAmounts
      ? this.selectedBudget.TimeAlertAmounts.map((item) =>
          numeral(item).value()
        )
      : [];
    this.notifObj.RevenueEmailList = this.selectedBudget.RevenueEmailList?.map(
      (item) => parseInt(item)
    );
    this.notifObj.TimeEmailList = this.selectedBudget.TimeEmailList?.map(
      (item) => parseInt(item)
    );
    this.previousNotifObj.RevenueAlertAmount = this.mangoUtils.doMoneyFormat(
      this.selectedBudget.RevenueAlertAmount || 0
    );
    this.previousNotifObj.TimeAlertAmount = this.mangoUtils.doMoneyFormat(
      this.selectedBudget.TimeAlertAmount || 0,
      "time"
    );
    this.previousNotifObj.RevenueEmailList =
      this.selectedBudget.RevenueEmailList?.map((item) => parseInt(item));
    this.previousNotifObj.TimeEmailList =
      this.selectedBudget.TimeEmailList?.map((item) => parseInt(item));
    this.notifObj.isRevenueAlert = !!numeral(
      this.notifObj.RevenueAlertAmount
    ).value();
    this.notifObj.isTimeAlert = !!numeral(
      this.notifObj.TimeAlertAmount
    ).value();
    this.notifObj.engagementName =
      this.currSavedBudgetEngagements.find(
        (i) => i.value == this.budgetEngagement
      )?.EngagementName || "";

    //get rollover options' value
    this.rolloverObj.isDefaultBudgetCompanyRollover =
      this.selectedBudget.isDefaultBudgetCompanyRollover;
    this.rolloverObj.isEnableRollover =
      this.selectedBudget.isEnableRollOver || true;
    this.rolloverObj.percentage = this.selectedBudget.PctOptionRev || 0;
    this.rolloverObj.timePercentage = this.selectedBudget.PctOptionTime || 0;
    if (
      this.rolloverObj.percentage &&
      numeral(this.rolloverObj.percentage).value() !== (NaN || 0)
    )
      this.rolloverObj.isUpdateRevenue = true;
    else this.rolloverObj.isUpdateRevenue = false;

    if (
      this.rolloverObj.timePercentage &&
      numeral(this.rolloverObj.timePercentage).value() !== (NaN || 0)
    )
      this.rolloverObj.isUpdateTime = true;
    else this.rolloverObj.isUpdateTime = false;

    this.rolloverOptions = this.selectedBudget.RollOverOption || "existing";

    if (this.AllUsers?.length == 0) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getPMAllStaffList().subscribe(
        (allStaff: any) => {
          for (let i = 0; i < allStaff.length; i++) {
            if (allStaff[i].Inactive) continue;
            if (!this.isAllowLoadOtherUser && this.staffID !== allStaff[i]["StaffID"]) {
              continue;
            }

            const obj = {};
            obj["label"] = allStaff[i]["StaffName"];
            obj["value"] = allStaff[i]["StaffID"];
            obj["StaffID"] = allStaff[i]["StaffID"];
            obj["StaffName"] = allStaff[i]["StaffName"];
            obj["StaffNumber"] = allStaff[i]["StaffNumber"];
            obj["Email"] = allStaff[i]["Email"];
            this.AllUsers.push(obj);
          }
          this.mangoAPISrvc.showLoader(false);

          this.showManageNotifications = true;
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.fetchErrorMsg
          );
        }
      );
    } else this.showManageNotifications = true;
  }

  onCloseManageBudget() {
    this.selectedBudget = {};
    this.clearNotifObject();
    this.showManageNotifications = false;
  }

  onSaveNotifSettings() {
    const obj = {
      // RevenueAlertAmount: numeral(this.notifObj.RevenueAlertAmount).value(),
      // TimeAlertAmount: numeral(this.notifObj.TimeAlertAmount).value(),
      RevenueEmailList: this.notifObj.RevenueEmailList
        ? `{${this.notifObj.RevenueEmailList.toString()}}`
        : null,
      TimeEmailList: this.notifObj.TimeEmailList
        ? `{${this.notifObj.TimeEmailList.toString()}}`
        : null,
      //save Rollover options
      isDefaultBudgetCompanyRollover:
        this.rolloverObj.isDefaultBudgetCompanyRollover,
      isEnableRollOver: this.rolloverObj.isEnableRollover,
      RollOverOption: this.rolloverOptions,
      PctOptionTime:
        this.rolloverObj.isUpdateTime && this.rolloverOptions == "percentage"
          ? numeral(this.rolloverObj.timePercentage).value()
          : 0,
      PctOptionRev:
        this.rolloverObj.isUpdateRevenue && this.rolloverOptions == "percentage"
          ? numeral(this.rolloverObj.percentage).value()
          : 0,
    };

    if(this.isRevChipsChanged) {
      let revAmounts = JSON.parse(JSON.stringify(this.notifObj.RevenueAlertAmounts))
      obj["isRevenueAlerts"] = `{${revAmounts?.map((amt) => {
        const alertObj = this.notifObj.amountAndAlertArr.revenue.find(
          (rev) => rev.amount == amt
        );
        if (alertObj) return alertObj["isAlert"];

        return false;
      })}}`;
      revAmounts = `{${revAmounts.map(item => item == "" ? "0" : item).join(",")}}`
      obj['RevenueAlertAmounts'] = revAmounts
    } 
    
    if(this.isTimeChipsChanged) {
      let timeAmounts = JSON.parse(JSON.stringify(this.notifObj.TimeAlertAmounts))
      obj["isTimeAlerts"] = `{${timeAmounts?.map((amt) => {
        const alertObj = this.notifObj.amountAndAlertArr.time.find(
          (rev) => rev.amount == amt
        );
        if (alertObj) return alertObj["isAlert"];

        return false;
      })}}`;
      timeAmounts = `{${timeAmounts.map(item => item == "" ? "0" : item).join(",")}}`
      obj['TimeAlertAmounts'] = timeAmounts
    }
    
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .updateBudget(obj, this.selectedBudget.BudgetsID, this.companyID)
      .subscribe(
        (data) => {
          this.isRevChipsChanged = false
          this.isTimeChipsChanged = false
          this.refresh();
          this.onCloseManageBudget();
          this.mangoAPISrvc.notify(
            "success",
            "Success!",
            AppConstants.updateMsg
          );
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.updateErrorMsg
          );
        }
      );
  }

  shouldDisableNotif() {
    if (
      this.isRevChipsChanged ||
      this.isTimeChipsChanged ||
      this.notifObj.RevenueEmailList?.toString() !=
        this.previousNotifObj.RevenueEmailList?.toString() ||
      this.notifObj.TimeEmailList?.toString() !=
        this.previousNotifObj.TimeEmailList?.toString() ||
      !this.shouldDisableRollover()
    ) {
      return false;
    }
    return true;
  }

  shouldDisableRollover() {
    return !(
      this.rolloverOptions != "percentage" ||
      this.rolloverObj.isDefaultBudgetCompanyRollover ||
      (this.rolloverOptions == "percentage" &&
        ((!this.isFromProject &&
          ((this.rolloverObj.isUpdateRevenue && this.rolloverObj.percentage) ||
            (this.rolloverObj.isUpdateTime &&
              this.rolloverObj.timePercentage))) ||
          (this.isFromProject &&
            this.rolloverObj.isUpdateTime &&
            this.rolloverObj.timePercentage)))
    );
  }

  onChckboxAlertClick(event, type) {
    if (type == "revenue") {
      if (!event) this.notifObj.RevenueAlertAmount = "$0.00";
    } else {
      if (!event) this.notifObj.TimeAlertAmount = "0.00";
    }
  }

  onBadgeClick() {
    let message = `<div>The projects below are associated with this Engagement: 
      <strong class="ic-red">${this.budgetsObject.budgets[0]?.EngagementName}</strong></div>`;

    this.mangoUtils
      .sortByFiled(this.budgetsFromProject, "TemplateName")
      ?.forEach((project, index) => {
        message += `<br/><div><strong>${++index}. ${
          project.TemplateName
        }</strong></div>`;
      });

    Swal.fire({
      icon: "info",
      title: "Information!",
      html: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: "Ok",
    });
  }

  onChangeTrackTime() {
    if (this.mySettingsForm.value.isTaskBudgetEnabled)
      this.budgetsObject.isTrackTimeTaskLevel = this.mySettingsForm.value.isTaskBudgetEnabled
    
    const obj = {
      isTrackTimeTaskLevel: this.budgetsObject.isTrackTimeTaskLevel,
    };
    if(obj.isTrackTimeTaskLevel) {
      this.initialBudgetForTime = numeral(this.budgetsObject.TimeAmount).value() || 0
    }
    
    this.mangoAPISrvc
      .updateProjectHeader(this.projectBudgetObj.ddmProjectHeaderID, obj)
      .subscribe(
        (result) => {},
        (err) => {
          this.mangoAPISrvc.showLoader(false);
        }
      );
  }

  onTasksBudgetSaved(event) {
    const totalTimeAmount = event.totalTimeAmount || 0;

    if (this.budgetsObject.TimeAmount != totalTimeAmount) {
      if (event?.isInitial) {
        if(totalTimeAmount == 0 && numeral(this.budgetsObject.TimeAmount).value() !== 0) {
          const initialBudgetForTime = numeral(this.budgetsObject.TimeAmount).value() || 0
          this.tasksDetailComponent.distributeInitialBudget(initialBudgetForTime)
        } else {
          this.budgetsObject.TimeAmount = totalTimeAmount;
          this.computePctTimeBudget()
          const obj = {
            BudgetsID: this.budgetsObject.budgets[0].BudgetsID,
            TimeAmount: totalTimeAmount,
          };
          this.mangoAPISrvc
            .updateBudget(obj, obj.BudgetsID, this.companyID)
            .subscribe(
              (data) => {
                this.updateParentBudget();
              },
              (err) => {}
            );
        }
      } else {
        this.budgetsObject.TimeAmount = totalTimeAmount;
        this.computePctTimeBudget()
        this.updateParentBudget();
      }
    }
  }

  updateParentBudget() {
    this.mangoAPISrvc
      .updateClientLvlTimeBudgets(
        this.budgetsObject.budgets[0].ProjectMasterID,
        this.companyID
      )
      .subscribe(
        (result) => {},
        (err) => {}
      );
  }

  getTimeTooltip() {
    if (this.budgetEngagement && !this.isFromProject) {
      return `The time figures below are coming from all Time Records which engagement is ${this.getEngagementName(
        this.budgetEngagement
      )}.`;
    } else if (!this.isFromProject) {
      return `The time figures below are coming from all Time Records of all engagements.`;
    } else if (this.isFromProject) {
      return `The time figures below are coming from all Time Records for Project: ${this.projectBudgetObj["TemplateName"]} which engagement is ${this.projectBudgetObj["ProjectMasterLabel"]}.`;
    } else {
      return "";
    }
  }

  onChipsAdd(event, type) {
    const val = numeral(event.value).value();
    if (type == "rev") {
      const revLength = this.notifObj.RevenueAlertAmounts?.length || 0;

      if (this.notifObj.RevenueAlertAmounts?.includes(val)) {
        this.notifObj.RevenueAlertAmounts.splice(revLength - 1);
        return;
      }

      this.notifObj.RevenueAlertAmounts[revLength - 1] = val;

      if (!val || val <= 0) {
        this.notifObj.RevenueAlertAmounts.splice(revLength - 1);
        return;
      }

      if (revLength > 8) {
        this.notifObj.RevenueAlertAmounts.splice(8);
        return;
      }

      this.isRevChipsChanged = true;
    } else {
      const timeLength = this.notifObj.TimeAlertAmounts?.length || 0;

      if (this.notifObj.TimeAlertAmounts?.includes(val)) {
        this.notifObj.TimeAlertAmounts.splice(timeLength - 1);
        return;
      }

      this.notifObj.TimeAlertAmounts[timeLength - 1] = val;

      if (!val || val <= 0) {
        this.notifObj.TimeAlertAmounts.splice(timeLength - 1);
        return;
      }

      if (timeLength > 8) {
        this.notifObj.TimeAlertAmounts.splice(8);
        return;
      }

      this.isTimeChipsChanged = true;
    }
  }

  onFocusChips(event, type) {
    if(type == 'rev') {
      if(this.notifObj.RevenueAlertAmounts?.length == 8) {
        Swal.fire({
          icon: "warning",
          title: "Warning!",
          text: `You can only put up to 8 alerts!`,
          showConfirmButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: "Ok",
        });
      }
    } else {
      if(this.notifObj.TimeAlertAmounts?.length == 8) {
        Swal.fire({
          icon: "warning",
          title: "Warning!",
          text: `You can only put up to 8 alerts!`,
          showConfirmButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: "Ok",
        });
      }
    }
  }

  onChipRemove(type) {
    if(type == 'rev')
      this.isRevChipsChanged = true;
    else
      this.isTimeChipsChanged = true;
  }
}
