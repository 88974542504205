import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { Table } from 'primeng/table';
import { SharedComponentsService } from '@app/shared/components';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-engagements-expense',
  templateUrl: './engagements-expense.component.html'
})
export class EngagementsExpenseComponent implements OnInit {
  public expensesList = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public selectedClientName: string = null;
  searchTextStr: any = "";
  projectClientID: number = -1;
  projectID: number = -1;
  selectedEngagementName: string = null;
  isShowAll = false;

  cellFocused: any = null;
  expList: any = [];

  constructor(private router: Router, public sharedSrvc: SharedComponentsService,private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public mangoUtils: mangoUtils, public translate: TranslateService) {
    this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.projectClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.selectedEngagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + 'E' + AppConstants.SearchString);
    this.getALLExpenses();
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('engagement')},
      { label: this.translate.instant('billing-invoicing.apply-to-expenses')},
      { label: this.selectedClientName, icon: 'ic-red' },
      { label: this.selectedEngagementName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.getExpenseGroups();

    this.sharedSrvc.expenseDialogData
      .subscribe((data) => {
        this.getALLExpenses();
      })
  }

  getALLExpenses() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getALLExpenses(parent.projectID).subscribe(function (data: any) {
      parent.expensesList = data.map((item) => {
        return {
          ...item,
          Ddate: item.Ddate
        }
      });
      
      parent.mangoAPISrvc.fireEngagementBadgeView(true);
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  getExpensesByBilled() {
    const parent = this;
    if (this.isShowAll) {
      parent.mangoAPISrvc.showLoader(true);
     this.mangoAPISrvc.getExpensesByBilled(parent.projectID).subscribe(function (data:any) {
       parent.expensesList = data;
       parent.mangoAPISrvc.showLoader(false);
     }, error => {
       parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
       parent.mangoAPISrvc.showLoader(false);
     });
    } else {
      parent.getALLExpenses();
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'E' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'E' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  // adding new engagent
  addExpensePopUp() {
    const data = {}
    data['ClientID'] = this.projectClientID;
    data['ClientName'] = this.selectedClientName;
    data['ProjectMasterID'] = this.projectID;
    data['isEditFlow'] = false;
    this.sharedSrvc.openExpenseDialog(data);
  }

  openExpenseEntryDailog(data) {
    data['ClientID'] = this.projectClientID;
    data['ClientName'] = this.selectedClientName;
    data['ProjectMasterID'] = this.projectID;
    data['isEditFlow'] = true;
    this.sharedSrvc.openExpenseDialog(data);
  }


  deleteExpense(id) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteTimeSheet(id).subscribe(function (data) {
          parent.getALLExpenses();
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
        }, error => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

  redirectListView() {
    this.encrDecSrvc.addObject(AppConstants.projectID, "");
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }


  saveTimeExpense(evt, data) {
    const _this = this;
    data.Ddate = moment(data.Ddate).format("MM/DD/YYYY");
    data["ExpenseCodeID"] = data.ExpenseCodeID
      ? data.ExpenseCodeID
      : null;
    data["ExpenseCode"] = data.ExpenseCode
      ? data.ExpenseCode
      : null;
    data["Cost"] = data["Cost"] ? data["Cost"] : 0;
    data["Units"] = data["Units"] ? data["Units"] : 0;
    data["Markup"] = data["Markup"] ? data["Markup"] : 0;
    data["Tax"] = data["Tax"] ? data["Tax"] : 0;
    data["Reimbursed"] = data["Reimbursed"]
      ? data["Reimbursed"]
      : false;
    data["Reimburseable"] = data["Reimburseable"]
      ? data["Reimburseable"]
      : false;
    data["IsTimeRecord"] = "X";
    data["Description"] = data["Description"]
      ? data["Description"]
      : null;
    data["BillingRate"] = data["BillingRate"]
      ? data["BillingRate"]
      : null;
    data["TotalTime"] = data["TotalTime"]
      ? data["TotalTime"]
      : null;
    data["StaffPayRate"] = data["StaffPayRate"]
      ? data["StaffPayRate"]
      : 0;
    data["ElaspedTime"] = data["ElaspedTime"]
      ? data["ElaspedTime"]
      : null;
    data["WriteupDown"] = data["WriteupDown"]
      ? data["WriteupDown"]
      : 0;

    data["SelectedAmount"] = data["SelectedAmount"]
      ? data["SelectedAmount"]
      : 0;
    data["Billed"] = data["Billed"] ? data["Billed"] : false;

    data.StaffID = data.StaffID
    _this.mangoAPISrvc.showLoader(true);

    _this.mangoAPISrvc
      .updateTimeSheet(data.SlipMasterID, data)
      .subscribe(function (resp) {
        data['IsColumnChanges'] = false
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify(
          "success",
          "Updated!",
          AppConstants.updateMsg
        );
      }),
      (error) => {
        data['IsColumnChanges'] = false
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify(
          "error",
          _this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      };
  }

  onEditInit(event) {
    this.cellFocused = event;
  }

  onEditComplete() {
    this.cellFocused = null;
  }

  onEditCancel() {
    this.cellFocused = null;
  }

  replaceShortcuts2(value, desc, type?) {
    desc['IsColumnChanges'] = true;

    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    if (type === "Memo") desc["Memo"] = valueArr.join(" ");
    else {
      desc["description"] = valueArr.join(" ");
    }
  }


  handleExpenseCode(event, data) {
    data["ExpenseCode"] = event.originalEvent.target.textContent;
    data["ExpenseCodeID"] = event.value;
    data["IsColumnChanges"] = true;
  }

  getExpenseGroups() {
    if (this.expList.length > 0) {
      return false;
    }
    this.expList = [{ label: "", value: null, dataItem: {} }];
    const data = this.encrDecSrvc.getObject(AppConstants.expenses);
    for (let i = 0; i < data.length; i++) {
      if (data[i].Inactive == false) {
        this.expList.push({
          label: data[i].Description,
          value: data[i].ExpenseCodeID,
          dataItem: data[i],
        });
      }
    }
    this.expList.sort(this.mangoUtils.sortList);
  }
}
