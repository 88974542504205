import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
} from "@app/_services";
import { SelectItem, MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare let numeral: any;
import moment from 'moment';

import FileSaver from "file-saver";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { environment } from '@environments/environment';

@Component({
  selector: 'app-transaction-credit-cards',
  templateUrl: './transaction-credit-cards.component.html',
})
export class TransactionCreditCardsComponent implements OnInit {
  public merchantSetupForm: UntypedFormGroup;
  public creditCardSetupForm: UntypedFormGroup;
  public isInvalidZip: boolean = false;
  public isFormValid: boolean = false;
  public isCrFormValid: boolean = false;
  public nativeWindow: any;
  items: MenuItem[];
  filteredItemsSize = -1;
  searchTextStr: any = "";
  activeIndex: number = 0;
  isEnrollForm: boolean = false;
  isShowSteps: boolean = false;
  busy: Promise<any>;
  public companyDataSource: any;
  public dataSource: any = [];
  public businessTypes: any = [];
  public cols: any[];
  public totalAmt: number = 0;
  public isLoaded: boolean = false;
  @ViewChild('searchValue') searchValue;
  public isTrialUser: boolean = false;
  public isValid: boolean = false;
  selectedDateOption: any;
  dateRangeList: SelectItem[];
  public datesObj: any = { 'startDate': null, 'endDate': null };

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private _fb: UntypedFormBuilder,
    private breadcrumbService: BreadcrumbService,
    private mangoUtils: mangoUtils,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Transactions" },
      { label: "Credit Card Enrollment", icon: "ic-red" },
    ]);

    this.searchTextStr = this.encrDecSrvc.getObject(
      "credit_cards_" + AppConstants.SearchString
    );

    this.dateRangeList = [{ label: "This Week", value: "week" }, { label: "This Month", value: "month" }, { label: "This Quarter", value: "quarter" }, { label: "This Year", value: "year" }, { label: "Previous Year", value: "lastyear" }];
    this.datesObj.startDate = moment().utc().startOf('week').format("MM/DD/YYYY");
    this.datesObj.endDate = moment().utc().endOf('week').add(1, 'days').format("MM/DD/YYYY");

    const localData = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel)
    if (localData && localData == 'FREE') {
      this.isTrialUser = true;
    }

    this.initializeForm();
    this.initializeCrForm();
    this.merchantSetupData();
    this.getCompanyData();

    this.items = [{ label: 'Company Information', command: (event: any) => { this.activeIndex = 0; } },
    { label: 'Your Information', command: (event: any) => { this.activeIndex = 1; } },
    { label: 'Bank Information', command: (event: any) => { this.activeIndex = 2; } },
    { label: 'Other Information', command: (event: any) => { this.activeIndex = 3; } },
    { label: 'Disclosures', command: (event: any) => { this.activeIndex = 4; } }];

    this.cols = [
      { field: 'SubmitDate', header: 'Date' },
      { field: 'Confirmation', header: 'Transaction Reference' },
      { field: 'PaymentType', header: 'Card Type' },
      { field: 'udfield1', header: 'Status', width: '8%' },
      { field: 'AccountName', header: 'Customer', width: '20%' },
      { field: 'Amount', header: 'Amount' },
      { field: 'udfield2', header: 'Action' }
    ];

    this.businessTypes = [
      { value: null, label: 'Select Options' },
      { value: 'Partnership', label: 'Partnership' },
      { value: 'C-Corp Private', label: 'C-Corp Private' },
      { value: 'S-Corp Private', label: 'S-Corp Private' },
      { value: 'Sole Prop', label: 'Sole Prop' },
      { value: 'Partnership Private', label: 'Partnership Private' },
      { value: 'LLC Private', label: 'LLC Private' },
      { value: 'Not For Profit', label: 'Not For Profit' }
    ];
  }

  validLegalName(value) {
    this.isValid = false;
    const formData = this.creditCardSetupForm.value;
    if (formData.legalName.indexOf('&') > -1) {
      this.isValid = true;
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'The & character is not allowed for name verification.  You must replace with AND.  This verification is similar to IRS guidelines where special characters are not allowed.',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok'
      });
    }
  }

  getCompanyData() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getCompanyViewData().subscribe((data: any) => {
      this.companyDataSource = data;
      this.isLoaded = true;
      this.updateForm(data);
      this.checkEnrollStatus();
      this.mangoAPISrvc.showLoader(false)
    }, (err) => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('error', 'Error!', err.message);
    });
  }

  initializeForm() {
    this.merchantSetupForm = this._fb.group({
      Gateway: ['Authorize.net', [<any>Validators.required, <any>Validators.maxLength(70)]],
      GatewayLogin: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      GatewayPassword: ['', [<any>Validators.maxLength(15)]],
      GatewayTransKey: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
    });
    this.merchantSetupForm.valueChanges.subscribe(data => {
      this.validateForm();
    })
  }

  selectDateRange(event) {
    this.datesObj = this.getStartEndDates(event.value);
    this.showReports();
  }

  initializeCrForm() {
    this.creditCardSetupForm = this._fb.group({
      legalName: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      dbaName: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      address: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      city: ['', [<any>Validators.required]],
      state: ['', [<any>Validators.required]],
      zip: ['', [Validators.required]],
      country: [''],
      businessDescription: ['', [<any>Validators.required, <any>Validators.maxLength(350)]],
      firstName: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      lastName: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      phone: ['', [<any>Validators.required, <any>Validators.maxLength(20)]],
      email: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      accountHolderName: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      bankRoutingNumbers: ['', [<any>Validators.maxLength(70)]],
      bankAccountNumber: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      business_type: [''],
      fed_tax_id: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      principal_postal_code: ['', [<any>Validators.required]],
      principal_phone_number: ['', [<any>Validators.required]],
      principal_country_province: [''],
      principal_state_province: ['', [<any>Validators.required]],
      principal_city: ['', [<any>Validators.required]],
      principal_date_of_birth: ['', [<any>Validators.required]],
      principal_ssn: ['', [<any>Validators.required]],
      principal_ownership_percent: ['', [<any>Validators.required]],
      principal_title: ['', [<any>Validators.required]],
      cc_monthly_volume_range: ['', [<any>Validators.required]],
      cc_average_ticket_range: ['', [<any>Validators.required]],
      cc_high_ticket: ['', [<any>Validators.required]],
      swiped_percent: ['0', [<any>Validators.required]],
      keyed_percent: ['100', [<any>Validators.required]]
    });

    this.creditCardSetupForm.valueChanges.subscribe(data => {
      this.validateCrForm();
    })
  }

  /*
    Fetching merchant Setup from Server
  */

  merchantSetupData() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getMerchantSetupViewData().subscribe((data: any) => {
      this.updateForm(data);
      this.mangoAPISrvc.showLoader(false)
    }, (err: any) => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('error', 'Error!', err.message);
    });
  }

  /*
    populate form data
  */

  updateForm(itemData: any) {
    if (Object.keys(itemData).length > 0) {
      this.merchantSetupForm.controls['GatewayLogin'].setValue(itemData['GatewayLogin']);
      this.merchantSetupForm.controls['GatewayPassword'].setValue(itemData['GatewayPassword']);
      this.merchantSetupForm.controls['GatewayTransKey'].setValue(itemData['GatewayTransKey']);
    }
  }

  /*
    Saving the merchant Setup data
  */

  saveMerchantSetupData() {
    this.isFormValid = false;
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.updateMerchantSetupView(this.merchantSetupForm.value).subscribe((data: any) => {
      this.mangoAPISrvc.notify('success', 'Error!', data.message);
      this.mangoAPISrvc.showLoader(false)
    }, (err: any) => {
      this.mangoAPISrvc.notify('error', 'Error!', err.message);
      this.mangoAPISrvc.showLoader(false)
    });
  }

  /*
      Verifing the form
    */
  validateForm() {
    let isInValidData = false;
    Object.keys(this.merchantSetupForm.controls).forEach(key => {
      if (this.merchantSetupForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData && this.merchantSetupForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  validateCrForm() {
    let isInValidData = false;
    Object.keys(this.creditCardSetupForm.controls).forEach(key => {
      if (this.creditCardSetupForm.get(key).invalid) {
        isInValidData = true;
      }
    });

    if (!isInValidData && this.creditCardSetupForm.dirty) {
      this.isCrFormValid = true;
    } else {
      this.isCrFormValid = false;
    }
  }
  /* doing refund or void */
  doAction(itemData) {
    const parent = this;
    const sbpMerchant = {};

    sbpMerchant['MerchantID'] = this.companyDataSource['CCmerchantID'];
    sbpMerchant['Login'] = this.companyDataSource['CClogin'];
    sbpMerchant['Password'] = this.companyDataSource['CCpassword'];
    sbpMerchant['ConfirmationID'] = itemData['Confirmation'];
    sbpMerchant['Amount'] = numeral(itemData['Amount']).value();

    if (itemData.udfield2 == 'Reverse') {
      Swal.fire({
        title: 'Confirmation',
        html: '<div>Would you like to reverse this transation?</div>',
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Yes, reverse transaction!',
        cancelButtonText: 'No, cancel'
      }).then((result) => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true)
          parent.mangoAPISrvc.doRefundAction(sbpMerchant).subscribe((data) => {
            parent.mangoAPISrvc.showLoader(false)
            Swal.fire({
              icon: 'success',
              title: 'Successful!',
              text: 'Transaction Reversed!',
              showCancelButton: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: 'OK',
            }).then((result) => {
              setTimeout(() => {
                parent.showReports();
              }, 200);
            })

          }, (err: any) => {
            parent.mangoAPISrvc.showLoader(false)
            parent.mangoAPISrvc.notify('error', 'Error!', err.message);
          });
        }
      });
    }
  }
  /* Enroll new account in to singular API*/
  createEnrollUser() {
    const parent = this;
    this.mangoAPISrvc.showLoader(true)
    parent.mangoAPISrvc.enrollUser(parent.getMerchentData()).subscribe((data: any) => {
      if (data.error) {
        return false;
      }
      // update client partner id 
      const obj = {};
      obj['CCGUID'] = data['guid'];
      obj['EnrollmentPending'] = true;
      if (data['guid']) {
        parent.mangoAPISrvc.updateCreditCardsSetting(obj).subscribe((response) => {
          Swal.fire({
            title: 'Step 1 Completed!',
            text: 'Please check your email and follow the instructions in the email in order to complete your credit card enrollment.',
            icon: 'success',
            showCancelButton: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'OK',

          }).then((result) => {
            if (result.value) {
              parent._router.navigate(['/companySettings/CompanyDetails']);
            }
            parent.mangoAPISrvc.showLoader(false)
          })
        }, (data) => {
          parent.mangoAPISrvc.showLoader(false)
          parent.mangoAPISrvc.notify('error', 'Error!', data.message);
        });
      }

    }, (data) => {
      parent.mangoAPISrvc.showLoader(false)
      parent.mangoAPISrvc.notify('error', 'Error!', data.message);
    });

    // Add This Company to NEW CC Enrollment List to Notify Mango Billing
    const userObj = {};
    const fullName = this.companyDataSource['CompanyContact'];
    const dataArr = fullName.split(' ');
    let fName = "";
    if (dataArr[0]) {
      fName = dataArr[0];
    }

    const lName = "";
    if (dataArr[1]) {
      fName = dataArr[1];
    }

    userObj['LNAME'] = lName;
    userObj['FNAME'] = fName;


    const sendInBlueObj = {};
    sendInBlueObj['email'] = this.companyDataSource['EmailCompany'];
    sendInBlueObj['attributes'] = userObj;
    sendInBlueObj['listid'] = ['15'];
    sendInBlueObj['listid_unlink'] = [];
    sendInBlueObj['updateEnabled'] = false;

    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.createOrUpdateUser(sendInBlueObj).subscribe((data) => {
      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify("error", "Error", err.message)
    });

  }

  /* Delete Profile */
  deleteProfile() {
    const parent = this;
    Swal.fire({
      title: 'Confirmation',
      html: '<div>Are you sure you want STOP accepting credit cards?</div>',
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'Yes, please cancel!',
      cancelButtonText: 'No, do not cancel'
    }).then((result) => {
      if (result.value) {
        const sbpMerchant = {};
        sbpMerchant['partnerkey'] = this.companyDataSource['ClientPartnerKey'];
        sbpMerchant['partnerid'] = this.companyDataSource['ClientPartnerID'];
        sbpMerchant['profileid'] = '18032113032013147TEST';
        sbpMerchant['payeeid'] = this.companyDataSource['ClientPartnerID'];
        parent.mangoAPISrvc.showLoader(true)
        parent.mangoAPISrvc.cancelEnrollStatus(sbpMerchant).subscribe((data) => {
          parent.mangoAPISrvc.showLoader(false)
          parent.mangoAPISrvc.notify('success', 'Success!', 'Profile deleted successfully');
        }, (data) => {
          parent.mangoAPISrvc.showLoader(false)
          parent.mangoAPISrvc.notify('error', 'Error!', data.message);
        });
        // Add This Company to CANCEL CC Enrollment List to Notify Mango Billing
        const userObj = {};
        const fullName = this.companyDataSource['CompanyContact'];
        const dataArr = fullName.split(' ');
        let fName = "";
        if (dataArr[0]) {
          fName = dataArr[0];
        }

        const lName = "";
        if (dataArr[1]) {
          fName = dataArr[1];
        }

        userObj['LNAME'] = lName;
        userObj['FNAME'] = fName;

        const sendInBlueObj = {};
        sendInBlueObj['email'] = this.companyDataSource['EmailCompany'];
        sendInBlueObj['attributes'] = userObj;
        sendInBlueObj['listid'] = ['16'];
        sendInBlueObj['listid_unlink'] = [];
        sendInBlueObj['updateEnabled'] = false;
        this.mangoAPISrvc.showLoader(true)
        this.mangoAPISrvc.createOrUpdateUser(sendInBlueObj).subscribe((data) => {
          this.mangoAPISrvc.showLoader(false)
        }, err => {
          this.mangoAPISrvc.notify('error', 'Error!', err.message)
          this.mangoAPISrvc.showLoader(false)
        });

      }

    });
  }

  /* check Enroll Status */
  checkEnrollStatus() {
    const parent = this;
    if (!this.companyDataSource['CCmerchantID']) {

      parent.isEnrollForm = false;
      return false;
    } else {
      parent.isEnrollForm = true;
      parent.showReports();
    }
  }

  getMerchentData() {
    const formData = this.creditCardSetupForm.value;
    const sbpMerchant = {};
    sbpMerchant['emailAddress'] = formData['email'];
    sbpMerchant['dbaName'] = formData['dbaName'];
    sbpMerchant['legalName'] = formData['legalName'];
    sbpMerchant['addressLine1'] = formData['address'];
    sbpMerchant['city'] = formData['city'];
    sbpMerchant['postalCode'] = formData['zip'].replace(/[^0-9]/g, "");
    sbpMerchant['state'] = formData['state'].toUpperCase();
    sbpMerchant['busPhoneNo'] = formData['phone'].replace(/[^0-9]/g, "");
    sbpMerchant['principalFirstName'] = formData['firstName'];
    sbpMerchant['principalLastName'] = formData['lastName'];
    sbpMerchant['businessDesc'] = formData['businessDescription'];
    sbpMerchant['accountHolderName'] = formData['accountHolderName'];
    sbpMerchant['bankAccountNo'] = formData['bankAccountNumber'];
    sbpMerchant['ownershipType'] = formData['business_type'];
    sbpMerchant['fedTaxID'] = formData['fed_tax_id'];
    sbpMerchant['principalPostalCode'] = formData['principal_postal_code'].replace(/[^0-9]/g, "");
    sbpMerchant['principalPhoneNo'] = formData['principal_phone_number'].replace(/[^0-9]/g, "");
    sbpMerchant['principalState'] = formData['principal_state_province'].toUpperCase();
    sbpMerchant['principalCity'] = formData['principal_city'];
    sbpMerchant['principalDOB'] = formData['principal_date_of_birth'];
    sbpMerchant['principalLast4SSN'] = formData['principal_ssn'];
    sbpMerchant['ownershipPercent'] = numeral(formData['principal_ownership_percent']).value();
    sbpMerchant['principalTitle'] = formData['principal_title'];
    sbpMerchant['ccMonthlyVolume'] = formData['cc_monthly_volume_range'];
    sbpMerchant['ccAverageTicket'] = formData['cc_average_ticket_range'];
    sbpMerchant['ccHighTicket'] = formData['cc_high_ticket'];
    sbpMerchant['swipedPercent'] = numeral(formData['swiped_percent']).value();
    sbpMerchant['keyedPercent'] = numeral(formData['keyed_percent']).value();
    this.companyDataSource['FederalID'] = formData['fed_tax_id'];
    return sbpMerchant;
  }

  resetForm() {
    this.isShowSteps = false;
    this.activeIndex = 0;
    this.creditCardSetupForm.reset();
  }

  showSteps() {
    if (this.isTrialUser) {
      Swal.fire({
        icon: 'warning',
        title: 'Disallowed!',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'OK',
        text: 'You need a Paid Plan in order to Enroll for Credit Card Processing',
      });
      return false;
    }
    this.isShowSteps = true;
    this.updateMechForm(this.companyDataSource);
  }
  /*
    populate form data
    */
  updateMechForm(itemData: any) {
    if (itemData) {
      this.creditCardSetupForm.controls['legalName'].setValue(itemData['CompanyName'].trim());
      /* Company Information Data*/
      itemData['Street1'] = itemData['Street1'] ? itemData['Street1'] : "";
      itemData['Street2'] = itemData['Street2'] ? itemData['Street2'] : "";
      this.creditCardSetupForm.controls['address'].setValue(itemData['Street1'] + ' ' + itemData['Street2']);
      this.creditCardSetupForm.controls['city'].setValue(itemData['City']);
      this.creditCardSetupForm.controls['state'].setValue(itemData['State']);
      this.creditCardSetupForm.controls['zip'].setValue(itemData['Zip']);
      this.creditCardSetupForm.controls['country'].setValue(itemData['Country']);
      this.creditCardSetupForm.controls['phone'].setValue(itemData['Telephone']);
      this.creditCardSetupForm.controls['email'].setValue(itemData['EmailCompany']);
      const userName = itemData['CompanyContact'].split(' ');
      this.creditCardSetupForm.controls['firstName'].setValue(userName[0]);
      this.creditCardSetupForm.controls['lastName'].setValue(userName[1]);
      this.creditCardSetupForm.controls['fed_tax_id'].setValue(itemData['FederalID']);
    }
  }

  showReports() {
    const sbpMerchant = {};
    sbpMerchant['MerchantID'] = this.companyDataSource['CCmerchantID'];
    sbpMerchant['Login'] = this.companyDataSource['CClogin'];
    sbpMerchant['Password'] = this.companyDataSource['CCpassword'];
    sbpMerchant['DateFrom'] = this.datesObj.startDate;//"01/01/2019 00:01:00";
    sbpMerchant['DateTo'] = this.datesObj.endDate;//"12/31/2019 23:12:59";
    sbpMerchant['FilterOptions'] = 'creditonly';

    // sbpMerchant['transactionreference'] = null;
    // sbpMerchant['payeeid'] = null;
    // sbpMerchant['orderid'] = null;
    // sbpMerchant['paymentmode'] = null;

    const parent = this;
    parent.dataSource = [];
    parent.mangoAPISrvc.showLoader(true)
    parent.mangoAPISrvc.getReports(sbpMerchant).subscribe((data: any) => {
      if (data['error']) { return false; }
      data['Transactions'] = data['Transactions'].filter((obj) => obj['FinalStatus'] != 'pending'
        && obj['FinalStatus'] != 'pending'
        && obj['Amount'] > 0
        && obj['AccountType'] != "ccvoid"
        && obj['FinalStatus'] != 'badcard');
      const dataDS = data['Transactions'].map(function (obj) {
        obj['Amount'] = numeral(obj['Amount']).format('0,0.00');
        obj['udfield2'] = obj['udfield2'] ? obj['udfield2'] : "";
        obj['SubmitDate'] = moment(obj['SubmitDate']).format('MM/DD/YYYY hh:mm A');
        obj['TempDate'] = moment(obj['SubmitDate']).format('MM/DD/YYYY');
        obj['isGrandTotal'] = false;
        obj['subAmount'] = 0;
        obj['isProccessed'] = false;

        if (obj['FinalStatus'] == 'Payment Marked' || obj['FinalStatus'] == 'success') {
          obj['udfield2'] = "Reverse";
          obj['udfield1'] = 'Settled';
        } else {
          obj['udfield1'] = obj['FinalStatus'];
        }
        if (obj['FirstName'] === obj['LastName']) {
          obj['CustomName'] = obj['FirstName'];
        } else {
          obj['CustomName'] = obj['FirstName'] + ' ' + obj['LastName'];
        }
        return obj;
      });


      const backUpDS = parent.mangoUtils.removeDuplicatesByProperty(Object.assign([], dataDS), 'TempDate');

      for (let index = 0; index < backUpDS.length; index++) {
        const element = backUpDS[index];
        let totals = 0;
        const tempObj = { 'isGrandTotal': true, 'isProccessed': true, 'SubmitDate': '', 'Confirmation': '', 'PaymentType': '', 'udfield1': '', 'CustomName': 'Sub Total', 'Amount': 0, 'udfield2': '' };
        if (element['TempDate'] && element['TempDate'] != null) {
          for (let innerIndex = 0; innerIndex < dataDS.length; innerIndex++) {
            const innerElement = dataDS[innerIndex];
            if (innerElement.isProccessed) {
              continue;
            }
            if (innerElement['TempDate'] && innerElement['TempDate'] != null && (element['TempDate'] == innerElement['TempDate'])) {
              innerElement.isProccessed = true;
              totals = (innerElement['FinalStatus'] == "Payment Voided" || innerElement['FinalStatus'] == "Payment Returned") ? totals : (totals + numeral(innerElement['Amount']).value());
              if (totals > 0 && innerIndex > 0 && innerIndex == dataDS.length - 1) {
                tempObj['subAmount'] = numeral(totals).format('0,0.00');
                dataDS.splice(dataDS.length + 1, 0, tempObj);
                break;
              }
            } else {
              if (totals > 0) {
                tempObj['subAmount'] = numeral(totals).format('0,0.00');
                dataDS.splice(innerIndex, 0, tempObj);
              }
              break;
            }
          }
        }
      }

      parent.dataSource = [...dataDS];
      const tempList = parent.dataSource.filter((item) => (item['FinalStatus'] != "Payment Voided" || item['FinalStatus'] == "Payment Returned"));
      const tempTotalsAmt = tempList.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
      this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');
      parent.mangoAPISrvc.showLoader(false)
    }, (data) => {
      parent.mangoAPISrvc.showLoader(false)
      parent.mangoAPISrvc.notify('error', 'Error!', data.message);
    });

  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    const tempTotalsAmt = this.dataSource.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
    this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');
    this.encrDecSrvc.addObject("credit_cards_" + AppConstants.SearchString, "");
  }

  onFilter(event) {
    if (!this.searchValue?.nativeElement?.value) return;

    const tempList = event.filteredValue.filter((item) => item['FinalStatus'] != "Payment Voided" || item['FinalStatus'] == "Payment Returned");
    const tempTotalsAmt = tempList.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
    this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');

    this.filteredItemsSize = event.filteredValue.length;

    this.encrDecSrvc.addObject(
      "credit_cards_" + AppConstants.SearchString,
      event.filters.global.value
    );
  }

  exportExcel() {
    const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Customers");
  }

  exportPdf() {
    const doc: any = new jsPDF("l", "pt");

    const columns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    const body = this.dataSource;

    doc.autoTable(columns, body);
    doc.save(`Credit_cards_export_${new Date().getTime()}.pdf`);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  getStartEndDates(type) {
    let dateRangeCode = null;
    let startDate = null;
    let endDate = null;
    switch (type) {
      case 'week':
        dateRangeCode = 'week';
        break;
      case 'month':
        dateRangeCode = 'month';
        break;
      case 'quarter':
        dateRangeCode = 'quarter';
        break;
      case 'year':
        dateRangeCode = 'year';
        break;
      case 'lastyear':
        dateRangeCode = 'lastyear';
        break;
    }
    if (dateRangeCode != 'lastyear') {
      startDate = moment().utc().startOf(dateRangeCode).format("MM/DD/YYYY");
      endDate = moment().utc().endOf(dateRangeCode).format("MM/DD/YYYY");
    } else {
      startDate = moment().subtract(1, 'years').startOf('year').startOf('day').format("MM/DD/YYYY");
      endDate = moment().subtract(1, 'years').endOf('year').startOf('day').format("MM/DD/YYYY");
    }

    return { startDate: startDate, endDate: endDate }
  }

}
