import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
} from "@app/_services";
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare let numeral: any;
import moment from 'moment';

import FileSaver from "file-saver";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: 'app-transaction-ach-enrollment',
  templateUrl: './transaction-ach-enrollment.component.html'
})
export class TransactionAchEnrollmentComponent implements OnInit {
  public companyDataSource: any;
  public dataSource: any = [];
  busy: Promise<any>;
  dateRangeList: SelectItem[];
  public datesObj: any = { 'startDate': null, 'endDate': null };
  public cols: any[];
  @ViewChild('searchValue') searchValue;
  public totalAmt: number = 0;
  selectedDateOption: any;

  filteredItemsSize = -1;
  searchTextStr: any = "";

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private _fb: UntypedFormBuilder,
    private breadcrumbService: BreadcrumbService,
    private mangoUtils: mangoUtils,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Transactions" },
      { label: "ACH Transactions", icon: "ic-red" },
    ]);

    this.searchTextStr = this.encrDecSrvc.getObject(
      "ach_" + AppConstants.SearchString
    );

    this.getCompanyData();
    this.dateRangeList = [{ label: "This Week", value: "week" }, { label: "This Month", value: "month" }, { label: "This Quarter", value: "quarter" }, { label: "This Year", value: "year" }, { label: "Previous Year", value: "lastyear" }];
    this.datesObj.startDate = moment().utc().startOf('week').format("MM/DD/YYYY");
    this.datesObj.endDate = moment().utc().endOf('week').add(1, 'days').format("MM/DD/YYYY");
    this.cols = [
      { field: 'SubmitDate', header: 'Date' },
      { field: 'Confirmation', header: 'Transaction Reference' },
      { field: 'PaymentType', header: 'ACH Transaction Type' },
      { field: 'udfield1', header: 'Status', width: '8%' },
      { field: 'AccountName', header: 'Customer', width: '20%' },
      { field: 'Amount', header: 'Amount' },
      { field: 'udfield2', header: 'Action' }
    ];
  }

  selectDateRange(event) {
    this.datesObj = this.getStartEndDates(event.value);
    this.showReports();
  }

  exportExcel() {
    const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Customers");
  }

  exportPdf() {
    const doc: any = new jsPDF("l", "pt");

    const columns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    const body = this.dataSource;

    doc.autoTable(columns, body);
    doc.save(`Credit_cards_export_${new Date().getTime()}.pdf`);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  getStartEndDates(type) {
    let dateRangeCode = null;
    let startDate = null;
    let endDate = null;
    switch (type) {
      case 'week':
        dateRangeCode = 'week';
        break;
      case 'month':
        dateRangeCode = 'month';
        break;
      case 'quarter':
        dateRangeCode = 'quarter';
        break;
      case 'year':
        dateRangeCode = 'year';
        break;
      case 'lastyear':
        dateRangeCode = 'lastyear';
        break;
    }
    if (dateRangeCode != 'lastyear') {
      startDate = moment().utc().startOf(dateRangeCode).format("MM/DD/YYYY");
      endDate = moment().utc().endOf(dateRangeCode).format("MM/DD/YYYY");
    } else {
      startDate = moment().subtract(1, 'years').startOf('year').startOf('day').format("MM/DD/YYYY");
      endDate = moment().subtract(1, 'years').endOf('year').startOf('day').format("MM/DD/YYYY");
    }

    return { startDate: startDate, endDate: endDate }
  }

  getCompanyData() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getCompanyViewData().subscribe((data: any) => {
      this.companyDataSource = data;
      this.showReports();
      this.mangoAPISrvc.showLoader(false)
    }, (data: any) => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('error', 'Error!', data.message
      );
    });
  }

  showReports() {
    const sbpMerchant = {};
    sbpMerchant['MerchantID'] = this.companyDataSource['CCmerchantID'];
    sbpMerchant['Login'] = this.companyDataSource['CClogin'];
    sbpMerchant['Password'] = this.companyDataSource['CCpassword'];
    sbpMerchant['DateFrom'] = this.datesObj.startDate;
    sbpMerchant['DateTo'] = this.datesObj.endDate;
    sbpMerchant['FilterOptions'] = 'achonly';

    const parent = this;
    parent.dataSource = [];
    parent.mangoAPISrvc.showLoader(true)
    parent.mangoAPISrvc.getReports(sbpMerchant).subscribe((data: any) => {
      if (data['error']) { return false; }
      data = data['Transactions'].filter((item) => item['FinalStatus'] != 'ACH Payment Voided');
      data = data.filter((item) => item['AuthCode'] != 'Tokenized');
      
      const dataDS = data.map(function (obj) {
        obj['Amount'] = numeral(obj['Amount']).format('0,0.00');
        obj['udfield2'] = obj['udfield2'] ? obj['udfield2'] : "";
        obj['SubmitDate'] = moment(obj['SubmitDate']).format('MM/DD/YYYY hh:mm A');
        obj['TempDate'] = moment(obj['SubmitDate']).format('MM/DD/YYYY');
        obj['isGrandTotal'] = false;
        obj['isProccessed'] = false;
        obj['subAmount'] = 0;
        
        if (obj['FinalStatus'] == 'Payment Marked' || obj['FinalStatus'] == 'success') {
          obj['udfield2'] = "Reverse";
          obj['udfield1'] = 'Settled';
        } else {
          obj['udfield1'] = obj['FinalStatus'];
        }
        return obj;
      });
      const backUpDS = parent.mangoUtils.removeDuplicatesByProperty(Object.assign([], dataDS), 'TempDate');

      for (let index = 0; index < backUpDS.length; index++) {
        const element = backUpDS[index];
        let totals = 0;
        const tempObj = { 'isGrandTotal': true, 'isProccessed': true, 'SubmitDate': '', 'Confirmation': '', 'AuthCode': '', 'udfield1': '', 'FirstName': 'Sub Total', 'Amount': 0, 'udfield2': '' };
        if (element['TempDate'] && element['TempDate'] != null) {
          for (let innerIndex = 0; innerIndex < dataDS.length; innerIndex++) {
            const innerElement = dataDS[innerIndex];
            if (innerElement.isProccessed) {
              continue;
            }
            if (innerElement['TempDate'] && innerElement['TempDate'] != null && (element['TempDate'] == innerElement['TempDate'])) {
              innerElement.isProccessed = true;
              totals = (innerElement['FinalStatus'] == "ACH Payment Voided") ? totals : (totals + numeral(innerElement['Amount']).value());
              if (totals > 0 && innerIndex > 0 && innerIndex == dataDS.length - 1) {
                tempObj['subAmount'] = numeral(totals).format('0,0.00');
                dataDS.splice(dataDS.length + 1, 0, tempObj);
                break;
              }
            } else {
              if (totals > 0) {
                tempObj['subAmount'] = numeral(totals).format('0,0.00');
                dataDS.splice(innerIndex, 0, tempObj);
              }
              break;
            }
          }
        }
      }

      parent.dataSource = [...dataDS];
      const tempList = parent.dataSource.filter((item) => item['FinalStatus'] != "ACH Payment Voided");
      const tempTotalsAmt = tempList.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
      this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');
      this.mangoAPISrvc.showLoader(false)
    }, (data: any) => {
      this.mangoAPISrvc.showLoader(false)
      parent.mangoAPISrvc.notify('error', 'Error!', data.message);
    });
  }

  doAction(itemData) {
    const parent = this;
    const sbpMerchant = {};

    sbpMerchant['MerchantID'] = this.companyDataSource['CCmerchantID'];
    sbpMerchant['Login'] = this.companyDataSource['CClogin'];
    sbpMerchant['Password'] = this.companyDataSource['CCpassword'];
    sbpMerchant['ConfirmationID'] = itemData['Confirmation'];
    sbpMerchant['Amount'] = numeral(itemData['Amount']).value();
    sbpMerchant['Description'] = 'Reversed';


    //if (itemData.udfield2 == 'Reverse') {
    Swal.fire({
      title: 'Confirmation',
      html: '<div>Would you like to reverse this transation?</div>',
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'Yes, reverse transaction!',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true)
        parent.mangoAPISrvc.doACHRefundAction(sbpMerchant).subscribe((data) => {
          parent.showReports();
          setTimeout(() => {
            Swal.fire({
              icon: 'success',
              title: 'Successful!',
              text: 'Transaction Reversed!',
              showConfirmButton: false,
              timer: 2000
            });
          }, 100);
          parent.mangoAPISrvc.showLoader(false)
        }, (data) => {
          parent.mangoAPISrvc.showLoader(false)
          parent.mangoAPISrvc.notify('error', 'Error!', data.message);
        });
      }
    });
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    const tempList = this.dataSource.filter((item) => item['FinalStatus'] != "ACH Payment Voided");
    const tempTotalsAmt = tempList.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
    this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');
    this.encrDecSrvc.addObject("ach_" + AppConstants.SearchString, "");
  }

  onFilter(event) {
    if (!this.searchValue?.nativeElement?.value) return;

    const tempList = event.filteredValue.filter((item) => item['FinalStatus'] != "ACH Payment Voided");
    const tempTotalsAmt = tempList.reduce(function (a, b) { return numeral(a).value() + +numeral(b.Amount).value(); }, 0);
    this.totalAmt = numeral(tempTotalsAmt.toFixed(2)).format('0,0.00');
    
    this.filteredItemsSize = event.filteredValue.length;
    
    this.encrDecSrvc.addObject(
      "ach_" + AppConstants.SearchString,
      event.filters.global.value
    );
  }
  

}
