import { Component, OnInit } from "@angular/core";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-company-dashboard",
  templateUrl: "./company-dashboard.component.html",
})
export class CompanyDashboardComponent implements OnInit {

  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private _translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {
    const interval = setInterval(() => {
      if(!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this._translate.instant('Company_Dashboard')},
      { label: this._translate.instant('billed_revenue'), icon: "ic-red" },
    ]);
    this.loadMenu();
  }

  ngOnInit(): void {
    this.setMenuIndex();
  }

  loadMenu() {
    this.items = [
      {
        label: this._translate.instant("client.revenue"),
        routerLink: ["/admin/companyDashboard/revenue"],
        // icon: "fal fa-building",
      },
      {
        label: this._translate.instant("receipts"),
        routerLink: ["/admin/companyDashboard/receipts"],
        // icon: "fal fa-tasks",
      },
      {
        label: this._translate.instant("staff-name"),
        routerLink: ["/admin/companyDashboard/staff"],
        // icon: "fal fa-tasks",
      },
    ];
    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;
    switch (url) {
      case "/admin/companyDashboard/receipts": {
        this.selectedMenuIndex = 1;
        break;
      }
      case "/admin/companyDashboard/staff": {
        this.selectedMenuIndex = 2;
        break;
      }
      default: {
        this.selectedMenuIndex = 0;
        break;
      }
    }
  }
}
