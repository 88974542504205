<div class="timeandexpense">
    <form [formGroup]="TimeandExpenseForm" (ngSubmit)="submitTimeandExpenseForm()">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple [disabled]="(!isFormValid)" label="{{'save_button' | translate}}"
                icon="fas fa-check" class="p-mr-1 p-button-sm"></button>
        </span>
        <div class="layout-content p-pt-0">
            <div class="p-fluid p-formgrid p-grid p-mx-2">
                <div class="p-col-8 p-formgroup-inline" style="padding-bottom: 150px;">
                    <div class="p-col-12">
                        <div class="card p-mt-2">
                            <h4 class="label-text">{{'QuickLinks.time-entry' | translate}}</h4>
                            <div class="p-formgroup-inline">
                                <div class="p-col-6">
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="AutoApproveSlips" [binary]="true"
                                            label="{{'settings.Auto-Approve-Time-Slips' | translate}}">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="ActualTimeEdit" [binary]="true"
                                            label="{{'settings.Billable-Time-Allow-Override' | translate}}"></p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="BillableTimeEdit" [binary]="true"
                                            label="{{'settings.Billing-Rate-Allow-Override' | translate}}">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="HideBillRate" [binary]="true"
                                            label="{{'settings.Hide-Billing-Rate-and-Billable-Amounts' | translate}}"></p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="isRateShowAdmin" [binary]="true"
                                            label="{{'settings.Always-Show-Rate-for-Admin-Users' | translate}}"></p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="TimeMemoRequired" [binary]="true"
                                            label="{{'settings.Require-Work-Description' | translate}}">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="isWorkLocationRequired" [binary]="true"
                                            label="{{'settings.Require-Work-Location-Tracking' | translate}}">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="isAdminTimeZero" [binary]="true"
                                            label="{{'settings.force_admin' | translate}}">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="isZeroTimeAllowed" [binary]="true"
                                            label="{{'settings.Allow-Zero-Time-Entry' | translate}}">
                                        </p-checkbox>
                                    </div>
                                </div>
                                <div class="p-col-6">
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="isAutoPause" [binary]="true" 
                                            label="{{'auto-pause-timer' | translate}}">
                                        </p-checkbox>
                                        <i pTooltip="{{ 'settings.Locktime_Tooltip-tip' | translate }}" 
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                            class="fa fa-question-circle ic-blue p-ml-1 p-mb-1">
                                        </i>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <p-checkbox formControlName="isLockTime" [binary]="true" (onChange)="onCheckLockTime()"
                                            label="{{'Lock_Time_Entries' | translate}}">
                                        </p-checkbox>
                                        <i pTooltip="{{ 'settings.Locktime_Tooltip' | translate }}" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        class="fa fa-question-circle ic-blue p-ml-1 p-mb-1"></i>
                                    </div>
                                    <div class="p-field p-col-6">
                                        <label class="label-text">{{'settings.Lock_after_Days' | translate}}</label>
                                        <p-inputNumber maxlength="3" (click)="$event.target.select()" autocomplete="off"
                                            formControlName="LockTimeDays" mode="decimal" inputStyleClass="p-text-right"
                                            placeholder="0"></p-inputNumber>
                                    </div>
                                    <div class="p-field p-col-6">
                                        <label class="label-text">{{'settings.First_day_of_week' | translate}}</label>
                                        <p-dropdown [options]="firstDayOptions" scrollHeight="500px" formControlName="TimeEntryFirstDayofWeek">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-field p-col-6">
                                        <label class="label-text">
                                            {{'allowed_timers' | translate}}
                                            <i pTooltip="{{ 'allowed_timers_tooltip' | translate }}" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                                class="fa fa-question-circle ic-blue p-ml-1 p-mb-1"></i>
                                        </label>
                                        <p-inputNumber maxlength="2" (click)="$event.target.select()" autocomplete="off"
                                            formControlName="AllowedTimers" mode="decimal" inputStyleClass="p-text-right"
                                            placeholder="0"></p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="p-col-4">
                        <div class="card p-mt-2 time-expense-salestax">
                            <h4 class="label-text">{{'settings.Sales-Tax-on-Expenses' | translate}}</h4>
                            <div class="p-field p-col-12">
                                <p-checkbox formControlName="ActivateExpenseRates" [binary]="true"
                                    label="{{'activate' | translate}}" (onChange)="checkExpenseRateValue()"></p-checkbox>
                            </div>
                            <div class="p-field p-col-3">
                                <label class="label-text">{{'sale-tax-rate' | translate}}</label>
                                <span class="p-inputgroup">
                                    <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                        formControlName="ExpenseRate1" mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                                        [minFractionDigits]="2"></p-inputNumber>
                                    <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                                </span>
                            </div>
                        </div>
                    </div> -->
                    
                    <div class="p-col-6">
                        <div class="card p-mt-3 p-pb-5" style="height: 100%;">
                            <h4 class="label-text">{{'QuickLinks.expense-entry' | translate}}</h4>
                            <div class="p-field p-col-12">
                                <p-checkbox formControlName="AutoApproveExpenses" [binary]="true"
                                    label="{{'settings.Auto-Approve-Expenses' | translate}}"></p-checkbox>
                            </div>
                            <div class="p-field p-col-12">
                                <p-checkbox formControlName="isExpenseMemoRequired" [binary]="true"
                                    label="{{'settings.Require-Expense-Memo' | translate}}">
                                </p-checkbox>
                            </div>
                            <!--  end of card -->
                        </div>
                    </div>
                    <div class="p-col-6" style="height: 27.5%;">
                        <div class="card p-mt-3 p-pb-5" style="height: 100%;">
                            <h4 class="label-text">{{'settings.Time-Increment-for-Time-Entry' | translate}}</h4>
                            <div class="p-field p-col-6 time-expense-increment">
                                <p-dropdown [options]="templates" scrollHeight="500px" formControlName="BillingIncrements">
                                </p-dropdown>
                            </div>
    
                            <!--  end of card -->
                        </div>
                    </div>
                </div>
                <div class="p-col-4 p-mb-2" style="padding-bottom: 150px;">
                    <div class="card p-mt-2 time-expense-salestax" style="height: 100%;">
                        <h4 class="label-text">{{'settings.Sales-Tax-on-Labor' | translate}}</h4>
                        <div class="p-field p-col-12">
                            <p-checkbox formControlName="ActivateLaborRates" [binary]="true"
                                label="{{'activate' | translate}}" (onChange)="checkSalesValue()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-3">
                            <label class="label-text">{{'sale-tax-rate' | translate}}</label>
                            <span class="p-inputgroup">
                                <p-inputNumber maxlength="12" (focus)="$event.target.select()" class="p-text-right"
                                    autocomplete="off" formControlName="LaborRate1" mode="decimal" inputStyleClass="p-text-right" locale="en-US"
                                    [minFractionDigits]="2"></p-inputNumber>
                                <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                            </span>
                        </div>
                        <h4 class="label-text">{{'settings.Sales-Tax-on-Expenses' | translate}}</h4>
                        <div class="p-field p-col-12">
                            <p-checkbox formControlName="ActivateExpenseRates" [binary]="true"
                                label="{{'activate' | translate}}" (onChange)="checkExpenseRateValue()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-3">
                            <label class="label-text">{{'sale-tax-rate' | translate}}</label>
                            <span class="p-inputgroup">
                                <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                    formControlName="ExpenseRate1" mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                                    [minFractionDigits]="2"></p-inputNumber>
                                <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                            </span>
                        </div>
                        <!-- <div class="p-field p-col-12 p-mt-4" *ngIf="TimeandExpenseForm.value?.LaborRate1 || TimeandExpenseForm.value?.ExpenseRate1">
                            <h4 class="label-text">{{'settings.Sales_Tax_Calculation_Option' | translate}}</h4>
                            <div class="p-field p-col-12">
                                <p-dropdown [options]="salesTaxCalcOptions" scrollHeight="500px" placeholder="Select Option" formControlName="SalesTaxOption">
                                </p-dropdown>
                            </div>
                        </div> -->
                        <!-- <div class="p-field p-col-3">
                            <label class="label-text">{{'rate' | translate}} 2</label>
                            <span class="p-inputgroup">
                                <p-inputNumber maxlength="12" (focus)="$event.target.select()" class="p-text-right"
                                    autocomplete="off" formControlName="LaborRate2" mode="decimal" inputStyleClass="p-text-right" locale="en-US"
                                    [minFractionDigits]="2"></p-inputNumber>
                                <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                            </span>
                        </div> -->
                        <!--  end of card -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>