import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RruleGeneratorComponent } from './rrule-generator.component';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';

@NgModule({
  declarations: [RruleGeneratorComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule,
    RecurrenceEditorAllModule
  ],
  exports: [
    RruleGeneratorComponent
  ]
})
export class RruleGeneratorModule { }
