import { Injectable } from '@angular/core';
import {
    HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { MangoApiService, EncrDecrService } from '@app/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    constructor(private encrDecSrvc: EncrDecrService, private mangoAPISrvc: MangoApiService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.mangoAPISrvc.showLoader(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const parent = this;
        const isLoggedIn = parent.encrDecSrvc.getObject('token');
        const isApiUrl = req.url.startsWith( environment.API_URL );

        /*;
          @todo-note:
            This is just a quick fix,
            move this to a separate setting module.
          @todo-note;
        */
        const WHITELISTED_DOMAIN_LIST = (
          [
            environment.QB_ENDPOINT_URL,
            environment.BASE_IMAGINETIME,
          ]
        );

        if(
              (
                isLoggedIn
              )
          &&
              (
                isApiUrl
              )
        ){
          (
                req
            =   req.clone(
                  {
                      withCredentials: true,
                      setHeaders: {
                          Authorization: (
                            parent.encrDecSrvc.getObject( 'token' )
                          )
                      }
                  }
                )
          );
        }
        else if(
              (
                    WHITELISTED_DOMAIN_LIST.some(
                      function( domain ){
                        return  (
                                      req.url.startsWith( domain )
                                  === true
                                );
                      }
                    )
                === true
              )
        ){
          (
                req
            =   (
                  req.clone( )
                )
          );
        }
        else{
          (
                req
            =   (
                  req.clone(
                    {
                        withCredentials: true
                    }
                  )
                )
          );
        }

        parent.requests.push( req );

        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            const refToken = event?.headers?.get('refresh-token');
                            if(refToken) {
                                parent.encrDecSrvc.addObject('token', refToken);
                            }

                            parent.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        parent.mangoAPISrvc.showLoader(false);
                        parent.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        parent.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                parent.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     let parent = this;
    //     // add auth header with jwt if user is logged in and request is to the api url
    //     const isLoggedIn = parent.encrDecSrvc.getObject('token');
    //     const isApiUrl = request.url.startsWith(environment.apiUrl);
    //     if (isLoggedIn && isApiUrl) {
    //         request = request.clone({
    //             setHeaders: {
    //                 Authorization: parent.encrDecSrvc.getObject('token')
    //             }
    //         });
    //     }

    //     return next.handle(request);
    // }
}
