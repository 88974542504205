import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { ReportsRoutingModule } from './reports-routing.module';

import { ReportsComponent } from './reports.component';
@NgModule({
  declarations: [ReportsComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule,
    ReportsRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ReportsModule { }
