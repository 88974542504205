<div class="education">
    <div class="layout-content">
        <div class="clientform-btn-top">
            <p-button pRipple label="{{'new' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="createUsersEducationData()"></p-button>
            <p-button pRipple label="{{'user.user_list' | translate}}" icon="fal fa-user-friends"
                styleClass="p-button-sm" class="p-button-help p-pr-2" (click)="redirectToUserList()"></p-button>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mx-3 p-pt-0">
                    <p-table #dt [value]="educationDataSources" [rowHover]="true" [responsive]="true" [rows]="10"
                        dataKey="StaffCertificationsID" selectionMode="single" [showCurrentPageReport]="true"
                        [pageLinks]="3" [rowsPerPageOptions]="[10,25,50]" [filterDelay]="0" [sortOrder]="-1"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="DateCompleted" [paginator]="educationDataSources.length > 10"
                        [globalFilterFields]="['DateCompleted','CertificationType','ClassDescription','HoursCompleted']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                </p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-6p p-text-center">{{'actions' | translate}}</th>
                                <th class="width-12p p-text-center" pSortableColumn="DateCompleted">
                                    {{'date_completed' | translate}}
                                    <p-sortIcon field="DateCompleted"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-center" pSortableColumn="YearCompleted">
                                    {{'year_completed' | translate}}
                                    <p-sortIcon field="YearCompleted"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-left" pSortableColumn="CertificationType">
                                    {{'user.certification_type' | translate}}
                                    <p-sortIcon field="CertificationType"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-left" pSortableColumn="ClassDescription">
                                    {{'user.class_description' | translate}}
                                    <p-sortIcon field="ClassDescription"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-right" pSortableColumn="HoursCompleted">
                                    {{'user.hours_completed' | translate}}
                                    <p-sortIcon field="HoursCompleted"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-userData>
                            <tr class="cursor-hand">
                                <td>
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editUsersEducationData($event,userData)" tooltipPosition="top"
                                            pTooltip="{{'Edit' | translate}}"></i>
                                        <i (click)="deleteUsersEducationData($event,userData.StaffCertificationsID)"
                                            class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                                            pTooltip="{{'mgmt-delete' | translate}}"></i>
                                    </div>
                                </td>
                                <td class="p-text-center">{{userData.DateCompleted | date:'MM/dd/yyyy'}}</td>
                                <td class="p-text-center">{{userData.YearCompleted}}</td>
                                <td class="p-text-left">{{userData.CertificationType}}</td>
                                <td class="p-text-left">{{userData.ClassDescription}}</td>
                                <td class="p-text-right">{{userData.HoursCompleted}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="totalNumberOfHrs > 0">
                                <td colspan="5" class="p-text-right ic-red">
                                    {{'Totals' | translate}}:
                                </td>
                                <td class="p-text-right ic-red">
                                    {{totalNumberOfHrs}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <!--  -->
    <p-dialog header="{{'Education' | translate}}" [(visible)]="dialogDisplay" [modal]="true" [style]="{width: '50vw'}"
        [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
        <form role="form" novalidate [formGroup]="myEducationForm" novalidate>
            <div class="p-fluid p-formgrid p-grid p-m-1">
                <div class="p-col-12">
                    <div class="card">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-6">
                                <label class="required-field label-text">{{'date_completed' | translate}}</label>
                                <p-calendar [yearNavigator]="true" formControlName="DateCompleted" yearRange="1950:2090"
                                    dateFormat="mm/dd/yy"></p-calendar>
                            </div>
                            <div class="p-field p-col-6">
                                <label class="required-field label-text">{{'user.certification_type' |
                                    translate}}</label>
                                <input type="text" pInputText maxlength="70" formControlName="CertificationType"
                                    (focus)="$event.target.select()" autocomplete="off">
                            </div>
                            <div class="p-field p-col-6">
                                <label class="required-field label-text">{{'user.class_description' |
                                    translate}}</label>
                                <input type="text" maxlength="70" pInputText formControlName="ClassDescription"
                                    (focus)="$event.target.select()" autocomplete="off">
                            </div>
                            <div class="p-field p-col-6">
                                <label class="required-field label-text">{{'user.hours_completed' | translate}}</label>
                                <input type="text" pInputText maxlength="12" formControlName="HoursCompleted"
                                    (focus)="$event.target.select()" class="p-text-right" autocomplete="off" placeholder="00.00"
                                    (blur)="formatHrs()">
                            </div>
                            <div class="p-field p-col-12">
                                <label class="label-text">{{'comments' | translate}}</label>
                                <textarea rows="5" cols="30" pInputTextarea formControlName="Comments"
                                    (focus)="$event.target.select()"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" [disabled]="(!isFormValid)"
                (click)="saveUsersEducationData();" label="{{'save_and_close' | translate}}"
                ></button>
            <button type="button" pButton pRipple icon="fal fa-check" [disabled]="(!isFormValid)"
                (click)="saveUsersEducationData(true);" label="{{'save_and_new' | translate}}"
                ></button>
            <button type="button" pButton pRipple icon="fal fa-times" (click)="dialogDisplay=false"
                label="{{'cancel_button' | translate}}" ></button>
        </ng-template>
    </p-dialog>
</div>