<div
  class="
    layout-content
    p-pt-0
  "
>
  <div
    class="
      p-fluid
      p-formgrid
      p-grid
      p-mx-2
    "
  >
    <div
      class="
        p-col-5
      "
    >
      <div
        class="
          card
          p-mt-2
          profile-calendar-mh
        "
      >
        <div
          class="
            p-d-flex
            p-jc-between
          "
        >
          <div>
            <h5>
              Microsoft Integration
            </h5>
            <div
              class="
                p-my-3
              "
            >
              <p-checkbox
                name="isEnabledMSSignIn"
                binary="true"
                label="Enable Signing in with Microsoft Account"
                (onChange)="onChangeMSSignIn($event)"
                [(ngModel)]="isEnabledMSSignIn"
                [ngModelOptions]="{standalone: true}"
              >
              </p-checkbox>
            </div>
          </div>
          <div>
            <img
              src="assets/img/ms-symbollockup_mssymbol_19.svg"
              class="
                cal-integrations
              "
              alt="Microsoft Integration"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
