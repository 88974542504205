import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BreadcrumbService, MangoApiService, EncrDecrService } from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
})
export class WelcomeComponent implements OnInit {
  constructor(
    private breadcrumbService: BreadcrumbService,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    private encrDecSrvc: EncrDecrService
  ) {
    this.breadcrumbService.setItems([
      { label: "Setup Wizard" },
      { label: "Let's Get Started", icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {}

  updateSetupWizardStatus() {
    const klassObj = this;
    const obj = { setupStep: 1 };
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateSetupStatus(obj).subscribe(
      (data) => {
        klassObj.encrDecSrvc.addObject(AppConstants.setupStep, "1")
        this.router.navigate(["/setup-wizard/company-info"]);
        this.mangoAPISrvc.showLoader(false);
      },
      (data) => {
        klassObj.mangoAPISrvc.notify("error", "Error!", data.message);
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  step2() {
    this.updateSetupWizardStatus();
  }
}
