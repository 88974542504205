
import {
  NgModule
} from "@angular/core";

import {
  Routes,
  RouterModule
} from "@angular/router";

import {
  AdminComponent
} from "./admin.component";

import {
  LabelPrintingComponent
} from "./label-printing/label-printing.component";

import {
  ProfileComponent
} from "./profile/profile.component";

import {
  SchedulePreferencesComponent
} from "./schedule-preferences/schedule-preferences.component";

import {
  CalendarIntegrationComponent
} from "./calendar-integration/calendar-integration.component";

import {
  MailIntegrationComponent
} from "./mail-integration/mail-integration.component";

import {
  TwoFactorAuthComponent
} from "./two-factor-auth/two-factor-auth.component";

import {
  EmailSignatureComponent
} from "./email-signature/email-signature.component";

import {
  MicrosoftLoginComponent
} from "./microsoft-login/microsoft-login.component";

import {
  OthersComponent
} from "./others/others.component";

import {
  UserLogsComponent
} from "./user-logs/user-logs.component";

import {
  UpdateHistorySubmitRequestsComponent
} from "./update-history-submit-requests/update-history-submit-requests.component";

import {
  AuthGuard
} from "@app/_services";

import {
  CanDeactivateForm
} from "./can-deactivate";

import {
  AppConstants
} from "@app/_helpers/api-constants";

const routeList: Routes = [
  {
    path: "",
    component: AdminComponent,
    canActivate: [
      AuthGuard,
    ],
    children: [
      {
        path: "",
        component: ProfileComponent,
        canActivate: [
          AuthGuard,
        ]
      },

      {
        path: AppConstants.myProfile,
        component: ProfileComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ]
      },

      {
        path: AppConstants.labelPrinting,
        component: LabelPrintingComponent,
        canActivate: [
          AuthGuard,
        ]
      },

      {
        path: AppConstants.emailAutomation,
        loadChildren: () =>
          import("./email-automation/email-automation.module").then(
            (mod) => mod.EmailAutomationModule
          ),
        canActivate: [AuthGuard], data: { 'plans': ["free", "enterprise", "pm"] }
      },
      { path: AppConstants.companyDashboard,
        loadChildren: () =>
          import("./company-dashboard/company-dashboard.module").then(
          (mod) => mod.CompanyDashboardModule
          ),
        canActivate: [AuthGuard]
        , data: { 'plans': ["free", "enterprise", 'pro', 'pm'] ,'roles': ["administrator"], 'featID': 20  }
      },

      {
        path: AppConstants.adminSuperAdmin,
        loadChildren: ( ) => import("./super-dashboard/super-dashboard.module")
        .then( ( mod ) => mod.SuperDashboardModule ),
        canActivate: [
          AuthGuard,
        ]
      },

      {
        path: AppConstants.userLogs,
        component: UserLogsComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ],
        data: {
          "featID": 29
        }
      },

      {
        path: AppConstants.schedulePreference,
        component: SchedulePreferencesComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ]
      },

      {
        path: AppConstants.calendarIntegration,
        component: CalendarIntegrationComponent,
        canActivate: [
          AuthGuard,
        ]
      },

      {
        path: AppConstants.mailIntegration,
        component: MailIntegrationComponent,
        canActivate: [
          AuthGuard,
        ]
      },

      {
        path: AppConstants.microsoftIntegration,
        component: MicrosoftLoginComponent,
        canActivate: [
          AuthGuard,
        ]
      },

      {
        path: AppConstants.emailSignature,
        component: EmailSignatureComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ]
      },

      {
        path: AppConstants.twoFA,
        component: TwoFactorAuthComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ]
      },

      {
        path: AppConstants.other,
        component: OthersComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ]
      },

      {
        path: AppConstants.updateHistory,
        component: UpdateHistorySubmitRequestsComponent,
        canActivate: [
          AuthGuard,
        ],
        canDeactivate: [
          CanDeactivateForm,
        ]
      },
    ]
  }
];

@NgModule(
  {
    imports: [
      RouterModule.forChild( routeList ),
    ],

    exports: [
      RouterModule,
    ],

    providers: [
      CanDeactivateForm,
    ]
  }
)
export class AdminRoutingModule { }
