<div class="return-void">
    <div class="layout-content">
        <div class="p-grid p-mx-2">
            <div class="p-col-12 p-pt-0">
                <div class="card p-pt-0">
                    <div class="p-col-12 p-pt-0 p-my-1">
                        <p-table [value]="dataSource" [responsive]="true" sortField="UploadedAt"
                            (onFilter)="onFilter($event)" [rows]="10"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [columns]="cols" [globalFilterFields]="['Status', 'Message', 'Confirmation']"
                            [sortOrder]="-1" [rowHover]="true" #dt [rows]="dataSource.length" [scrollable]="true"
                            [scrollHeight]="'calc(90vh - 350px)'" [virtualScroll]="true">
                            <ng-template pTemplate="caption">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{ 'globalsearch' | translate }}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{ 'clear' | translate }}"></button>
                                        </span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                            styleClass="p-ml-2">
                                        </p-message>
                                    </div>
                                    <div class="p-col-6 p-text-right" *ngIf="dataSource.length > 0">
                                        <button type="button" pButton pRipple icon="pi pi-file-o"
                                            (click)="dt.exportCSV()" class="p-mx-2" pTooltip="CSV"
                                            tooltipPosition="top"></button>
                                        <!-- <button type="button" pButton pRipple icon="pi pi-file-excel"
                                            (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                            tooltipPosition="top"></button> -->
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                            (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                            tooltipPosition="top"></button>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" class="p-text-center" [pSortableColumn]="col.field">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-userData>
                                <tr>
                                    <td class="p-text-right p-text-bold">${{userData.Status}}</td>
                                    <td class="p-text-left">{{userData.Message}}</td>
                                    <td class="p-text-left">{{userData.Confirmation}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>