
export const environment = {
  production: true,
  "ENVIRONMENT_NAME": "production",
  "BASE_DOMAIN": "secure.mangobilling.com",
  "SERVICE_ADDRESS": "https://secure.mangobilling.com",
  "API_URL": "https://secure.mangobilling.com/api",
  "IS_API_URL": "https://api.imaginetime.com/api",
  "UPLOADS_URL": "https://secure.mangobilling.com/uploads/",
  "GOOGLE_API_URL": "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAA-CzphhAPr3JsKxKi6l-6HPwE1okVSbA&",
  "SINGULAR_BILLPAY_API_URL": "https://enroll.securepds.com/enroll.svc/JSON",
  "SINGULAR_BILLPAY_API_PAYMENTS_URL": "https://api.securepds.com/2.0/payments.svc/JSON",
  "SINGULAR_BILLPAY_DEVAPI_URL": "https://api.singularbillpay.com/v1",
  "SINGULAR_API_PARTNER_ID": "mango_18407",
  "SENDINBLUE_BASE_URL": "https://api.sendinblue.com/v3",
  "QB_ENDPOINT_URL": "https://qb.secure.mangobilling.com/",
  "PENTAHO_USERNAME": "tim",
  "AWS_BUCKET_NAME": "mangobillings3",
  "AWS_REGION": "us-east-1",
  "PlanID": "price_1HDuRyAk13GiRFapmrF0rRFh",
  "PDF_WebViewer_KEY": "Mango Billing, Inc.(mangobilling.com):OEM:Mango Billing - Document Management Module::B+:AMS(20210804):F8B577020427760AB360B13AC982737860614F3EB748F59ADD8FB49A142CA9D65AC2BEF5C7",
  "lexcharge_clientId": "A678CEC2-0847-4276-ABA4-7DD342D6DB31",
  "lexcharge_clientSecret": "BC718B10-3589-441A-B671-B3C9EDA525F3",
  "lexcharge_url": "https://api.lexcharge.com/v1",
  "STAX_brand": "mangobilling",
  "STAX_API": "https://apiprod.fattlabs.com",
  "STAX_REDIRECT_URL": "https://signup.fattmerchant.com/#/sso?",
  "STAX_RECUUING_PAY_URL": "https://secure.mangobilling.com/api/Stax/recurringPaymentCallback",
  "SOCKET_URL": "wss://secure.mangobilling.com/websockets/",
  // "PUBLIC_GOOGLE_BUCKET_URL": "https://storage.googleapis.com/public-mangobilling",
  "NYLAS_INTEGRATION_FEATURE_STATUS": false,
  "NYLAS_INTEGRATION_FEATURE_MAIL_STATUS": false,
  "BASIC_DOMAIN": "secure.mangobilling.com",
  "BASE_IMAGINETIME": "https://app.imaginetime.com",
  "BASE_SECURE_PORTAL_DOMAIN": "secureportal.mangobilling.com",
  "LINK_REPLACE_REGEX": "\/\/secureportal\\.mangobilling\\.com",
  "EMAIL_RETURN_SENDER": "noreply@mangobilling.com",
  "GAINSIGHT_PX_KEY": "AP-TDXTSZT95GLE-2",
  "GAINSIGHT_PX_URL": "https://web-sdk.aptrinsic.com/api/aptrinsic.js",
  "RC_KEY": "6Lfd_KYnAAAAAIt2mza7sFiBRAzv1bKpEmabwrZ0",
  "PRIVATE_PHRASE": "6LrlUutVdY3NHaF6OMnPJ60l",
  "SALES_TEAM_EMAIL": "sales@mangopractice.com",
  "SYNCFUSION_LICENSE_KEY": "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5UdExiWH1ZdHFWRGNf"
};
