import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MangoApiService, mangoUtils } from '@app/_services';
import moment from 'moment';
import Swal from 'sweetalert2';
import { EncrDecrService } from './EncrDecrService';
import { AppConstants } from '@app/_helpers/api-constants';

interface ICompanySetting {
  enabled: string[];
  disabled?: string[];
}

@Injectable()
export class AuthGuard  {
  private staffID: number = null;
  private staffPermission = null;
  private planInfo = null;
  companyId;
  private trialExpired = false;
  private membershipExpired = false;
  //// '/setup-wizard/company-logo',
  public setupWizardUrls = [
    '/setup-wizard',
    '/setup-wizard/company-info',
    '/setup-wizard/company-settings',
    '/setup-wizard/user-settings',
    '/setup-wizard/finished'
  ];
  private remainingDaysInTrail: number = null;
  private remainingDaysInExpiry: number = null;
  private activeSubscription: boolean = false;
  private staffRoles: string[] = [];
  private staffRolesNew: string[] = [];
  companySettingsData = null;
  public changeLang: EventEmitter<string> = new EventEmitter();
  private cachedPermissions = {};

  constructor(private encrDecSrvc: EncrDecrService, private authService: AuthService, private mangoUtils: mangoUtils, private router: Router, private _translate: TranslateService, private mangoAPISrvc: MangoApiService) {
    this.staffID = this.encrDecSrvc.getObject(AppConstants.resourceID);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    const self = this;
    const setupStep = self.encrDecSrvc.getObject(AppConstants.setupStep);
    const resourceID = self.encrDecSrvc.getObject(AppConstants.resourceID);
    this.companyId = self.encrDecSrvc.getObject(AppConstants.companyID);
    this.companySettingsData = self.encrDecSrvc.getObject(AppConstants.companySettingsData)
    const urlPM = AppConstants.urlPM;
    const subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    const callback = () => {
      // console.log("before call checkLogin");
      if (!self.checkLogin(url)) {
        return false;
      }
      // console.log("after call checkLogin");

      // console.log("before call checkRoles");
      // if (typeof route.data["roles"] !== "undefined") {
      //   let roles = route.data["roles"] as Array<string>;
      //   if (!this.verifyRoles(roles)) {
      //     return false;
      //   }
      // }

      if (typeof route.data["featID"] !== "undefined") {
        const featID = route.data["featID"];
        if (!this.verifyRoles(featID)) {
          return false;
        }
      }

      if (typeof route.data["companySettings"] !== "undefined") {
        const companySettings = route.data["companySettings"];
        if(companySettings) {
          if (!this.verifyCompanySettings(companySettings)) {
            Swal.fire({
              icon: 'warning',
              title: 'Warning!',
              showConfirmButton: true,
              text: `Your Engagement letter feature is not enabled. Please enable it first at General Settings > Others > Engagement Letter Settings`
            });
            return false;
          }
        }
      }

      // companySettings

      if (typeof route.data["isSuperAdminOnly"] !== "undefined") {
        const isSuperAdminOnlyAccess = route.data["isSuperAdminOnly"];
        const isSuperAdmin = self.encrDecSrvc.getObject(AppConstants.userPermissions)?.isSuperAdmin;
        if (isSuperAdminOnlyAccess && !isSuperAdmin) {
          return false;
        }
      }

      // console.log("after call checkRoles");

      // console.log("before call checkPlans");
      if (typeof route.data["plans"] !== "undefined") {
        const plans = route.data["plans"] as Array<string>;
        if (!this.verifyPlans(plans)) {
          return false;
        }
      }

      if (typeof route.data["calResource"] !== "undefined") {
        const calResource = route.data["calResource"] as boolean;
        if (!this.verifyCalendarResource(calResource)) {
          return false;
        }
      }

      // console.log("after call checkPlans");
      const allowedURLs = ['/companySettings/Subscription', '/dashboard'];
      if (allowedURLs.indexOf(url) < 0 && !this.checkSubscription()) {
        // console.log("inside checkSubscription");
        return false;
      }
      const lang = localStorage.getItem("language");
      self.switchLang(lang);
      //Mond - Modified because 0 is equal to false and this will disregard new user
      // if (setupStep  && setupStep != 6 && !this.checkNewUser(url)) {
      if (setupStep != undefined && setupStep != 6 && !this.checkNewUser(url)) {
        return false;
      }
      
      if(subscriptionLevel!.toUpperCase() == 'PM' && (!urlPM.includes(url))) {
        this.router.navigate(['/client/list']);
        return false;
      }
      return true;
    };

    if (self.staffPermission) {
      return callback();
    } else if (resourceID) {
      self.staffID = resourceID;// subscribe((data: any) => {
      const userPermissionObj = self.encrDecSrvc.getObject(AppConstants.userPermissionObj);
      const userPermissions = self.encrDecSrvc.getObject(AppConstants.userPermissions);
      self.staffRolesNew = userPermissions?.Title?.toLowerCase();
      self.staffPermission = parseInt(userPermissionObj.Permission);
      self.staffRoles = userPermissionObj.Roles;
      setTimeout(() => {
        self.switchLang(localStorage.getItem('language'));
      }, 200);
      self.encrDecSrvc.addObject(AppConstants.staffPermission, self.staffPermission);
      self.planInfo = self.encrDecSrvc.getObject(AppConstants.planInfo);
      self.encrDecSrvc.addObject(AppConstants.NumberofUsers, self.planInfo.NumberofUsers);
      self.encrDecSrvc.addObject(AppConstants.NoofActiveUsers, self.planInfo.NoofActiveUsers);
      self.encrDecSrvc.addObject(AppConstants.subscriptionLevel, self.planInfo['SubscriptionLevel']);
      localStorage.setItem("plmkioukl", self.planInfo['SubscriptionLevel']);
      self.calcMembershipExpiry();
      self.calcRemainingDaysInTrail();
      if (self.isMembershipExpired || self.isTrialExpired) {
        Swal.fire({
          title: 'Membership Expired',
          html: this._translate.instant(`Membership_Expired_Message`),
          icon: 'warning',
          confirmButtonText: 'Ok',
          backdrop: true,
          allowOutsideClick: false
        }).then(result => {
          if (result.value) {
            self.logout();
          }
        });
        return false;
      }
      return callback();
    } else {
      return callback();
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  /** NOTE: If you will be adding a new key value pair on MasterPermissions table,
   * always update this list of key value pairs.
   * Key Value for Checking/Verifying Permissions if the User has Access to the Module/Feature
    3 Clients
    4 View Invoice
    5 Access Payments
    6 Access Time Records
    7 Company
    8 Settings
    9 Users
    10 Allow to Change Permission of Other Users
    33 Allow to View/Update Settings
    11 Reports
    30 Show User Reports Only
    12 Accounting
    13 Time & Expense Entry
    14 Allow Entry for Other Users
    15 Allowed Rate Overrides
    16 Allow Approval
    36 Can View Time Card/Entry of Other User(s)
    17 Billing & Invoicing
    34 Allow to Update Invoice Amount
    18 Scheduling
    19 "Allowed to View/Edit Other User Schedules "
    20 Company Dashboard
    21 Project Management
    35 Allow access to Settings
    22 Allow to Complete Project in the Project List
    23 "Allow to View/Edit Other User Due Dates "
    24 Document Management System
    25 Allow access to Settings
    26 Expense Manager
    27 Allow Approval
    28 Budgets
    29 User Logs
    31 Data Import/Export
    32 Disregard Lock Open Time Records After (#) Days
    33	Allow to View/Update Settings
    34	Allow to Update Invoice Amount
    35	Allow access to Settings
    36	Can View Time Card/Entry of Other User(s)
    37	Engagements
    39	Activities
    40	Allow to Create Activity
    41	Allow to Create Project
    44	Allow to Update Activity
    45	Allow to Update Engagement
    46	Allow all staff to filter
    47 Allow to change client name when project is in progress
  * */
  isAllowAccess(featID: number): boolean {
    if(this.cachedPermissions[featID] === true || this.cachedPermissions[featID] === false) return this.cachedPermissions[featID]
    const roleValues = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.RoleValues;
    const exceptionValues = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.Exceptions;

    let exception = null;
    if (exceptionValues && exceptionValues?.length > 0) {
      exception = exceptionValues?.find(item => item.id == featID)?.val;
    }

    if (exception === false) {
      this.cachedPermissions[featID] = false;
      return false;
    } else if (exception === true) {
      this.cachedPermissions[featID] = true;
      return true;
    }
    if (!roleValues || roleValues?.length == 0) return true;
    const roleVal = roleValues.find(item => item.MasterPermissionID == featID)?.isAccessAllowed;

    if (roleVal === false) {
      this.cachedPermissions[featID] = false;
      return false; //value can be undefined, hence, strict checking is required. If undefined, should return true
    } else {
      this.cachedPermissions[featID] = true;
      return true; //if value is true, or there is nothing saved on the database for this featID, should return true.
    }

    // return true //Note: Uncomment this line if ever there is an issue with permissions. Then comment the codes above.
  }

  verifyCompanySettings(companySettings: ICompanySetting): boolean {
    if (companySettings.enabled?.length > 0) {
      if (
        companySettings.enabled
          .map(requiredColumn => {
            if (this.companySettingsData[requiredColumn]) return true;
            else return false;
          })
          .every(item => item)
      ) {
        return true;
      } else return false;
    } else return true;
  }

  verifyRoles(featID: number): boolean {
    if (this.isAllowAccess(featID)) return true;

    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      showConfirmButton: true,
      text: 'Your permission level does not allow access to this feature.'
    });
    return false;
  }

  verifyCalendarResource(isCalResource: boolean): boolean {
    const BitAddCalendarResource = this.encrDecSrvc.getObject(
      AppConstants.userPermissionObj
    )?.BitAddCalendarResource;
    if (isCalResource == BitAddCalendarResource) return true;
    else {
      Swal.fire({
        title: this._translate.instant('access_denied'),
        html: this._translate.instant('access.should_be_a_calendar_resource'),
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this._translate.instant('ok')
      }).then(result => {});
      return false;
    }
  }

  canActivateIfEnterpriseOrFree() {
    const subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel)
    return (subscriptionLevel == 'ENTERPRISE' || subscriptionLevel == 'FREE')
  }

  // Verify plans to access dashboard
  verifyPlans(plans: string[], path = ''): boolean {
    let currentPlan: string = this.planInfo.SubscriptionLevel.toLowerCase();
    if (plans.indexOf(currentPlan) >= 0) {
      return true;
    }

    // alert("You are not allowed to access this page.")
    //this.router.navigate(['/dashboard']);
    //_this.mangoAPISrvc.notify('error', this._translate.instant("You are not allowed to access this page."));
    const parent = this;
    const localData = parent.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    if (localData == 'SOLO' || localData == 'PRO') {
      if (path === AppConstants.capacityPlanningRoutePath) {
        Swal.fire({
          title: this._translate.instant('access_denied'),
          html: this._translate.instant('authguard.capacity_planning'),
          icon: 'warning',
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Ok'
        });
      } else {
        Swal.fire({
          title: this._translate.instant('access_denied'),
          html: '<div>This feature is only available for <strong>PRO</strong> plan.</div>', //ENTERPRISE is now labeled as PRO
          icon: 'warning',
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Ok'
        });
      }
    }
    return false;
  }

  checkLogin(url: string): boolean {
    // if (this.authService.isLoggedIn) { return this.checkNewUser(url); }
    const sessionId = this.encrDecSrvc.getObject('token');
    const rememberMe = this.encrDecSrvc.getObject(AppConstants.rememberMe);
    if (sessionId == null && url != '/login') {
      this.authService.redirectUrl = url;
      this.logout();
      // this.router.navigate(['/login']);
      return false;
    } else if (sessionId && rememberMe == 'true' && url == '/login') {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }

  checkNewUser(url?: string) {
    //Mond - Modified because setupStep is a string and not a number
    //let setupStep = this.encrDecSrvc.getObject(AppConstants.setupStep).toString();
    const setupStep = this.encrDecSrvc.getObject(AppConstants.setupStep).toString();
    switch (setupStep) {
      case null: {
        return true;
      }
      case '0': {
        if (url != '/setup-wizard') {
          this.router.navigate(['/setup-wizard']);
          return false;
        }
        return true;
      }
      case '1': {
        if (this.checkoutCurrentUrl(url, 1)) {
          this.router.navigate(['/setup-wizard/company-info']);
          return false;
        }
        return true;
      }
      // case "2": {
      //   if (this.checkoutCurrentUrl(url, 2)) {
      //     this.router.navigate(['/setup-wizard/company-logo']);
      //     return false;
      //   }
      //   return true;
      // }
      case '2': {
        if (this.checkoutCurrentUrl(url, 2)) {
          this.router.navigate(['/setup-wizard/company-settings']);
          return false;
        }
        return true;
      }
      case '3': {
        if (this.checkoutCurrentUrl(url, 3)) {
          this.router.navigate(['/setup-wizard/user-settings']);
          return false;
        }
        return true;
      }
      case '4': {
        if (this.checkoutCurrentUrl(url, 4)) {
          this.router.navigate(['/setup-wizard/finished']);
          return false;
        }
        return true;
      }
      case '5': {
        if (this.setupWizardUrls.indexOf(url) != -1) {
          this.router.navigate(['/clients/status/full']);
          return false;
        }
        return true;
      }
      default: {
        return true;
      }
    }
  }

  checkoutCurrentUrl(url, step) {
    const _i = this.setupWizardUrls.indexOf(url);
    return (_i == -1 || _i > step);
  }

  resetStaffPermission() {
    this.staffPermission = undefined;
    this.membershipExpired = false;
    this.trialExpired = false;
    this.remainingDaysInExpiry = null;
    this.remainingDaysInTrail = null;
    this.activeSubscription = false;
  }

  checkSubscription() {
    // console.log("membershipExpired:", this.membershipExpired);
    // console.log("trialExpired:", this.trialExpired);
    if (this.membershipExpired) {
      this.mangoAPISrvc.notify(
        'error',
        'Error',
        this._translate.instant('Your membership has been expired. Please renew your subscription.')
      );
      this.router.navigate(['/companySettings/Subscription']);
      return false;
    } else if (this.trialExpired) {
      this.mangoAPISrvc.notify(
        'error',
        'Error',
        this._translate.instant('Your trial has been expired. Please purchase subscription.')
      );
      this.router.navigate(['/companySettings/Subscription']);
      return false;
    }
    return true;
  }

  calcMembershipExpiry() {
    const dateSubscribed = this.encrDecSrvc.getObject(AppConstants.dateSubscribed);
    const datePlanActivated = this.encrDecSrvc.getObject(AppConstants.datePlanActivated);
    let dateCancelled = this.encrDecSrvc.getObject(AppConstants.dateCancelled);
    const companyData = this.encrDecSrvc.getObject(AppConstants.companySettings);

    dateCancelled = dateCancelled ? this.mangoUtils.addOffset(dateCancelled) : null;
    if (dateCancelled && dateCancelled < new Date()) {
      this.membershipExpired = true;
      return false;
    }

    if (dateSubscribed && !datePlanActivated) {
      // let datePlanDue = moment(dateSubscribed).add(14, 'days');
      // let today = moment();
      // let numOfDaysLeft = datePlanDue.diff(today, 'days');

      if (companyData.trialDaysLeft < 0) {
        //console.log("trial expired");
        this.trialExpired = true;
        return false;
      }
    }

    if (!dateCancelled && datePlanActivated) {
      this.activeSubscription = true;
    }

    return true;
  }

  calcRemainingDaysInTrail() {
    const dateSubscribed = localStorage.DateSubscribed;
    const datePlanActivated = localStorage.DatePlanActivated;
    const dateCancelled = localStorage.DateCancelled;

    if (dateSubscribed && !datePlanActivated) {
      const datePlanDue = moment(dateSubscribed).add(14, 'days');
      const today = moment();
      const numOfDaysLeft = datePlanDue.diff(today, 'days');
      this.remainingDaysInTrail = numOfDaysLeft;
    }

    if (dateCancelled && datePlanActivated) {
      const dateRenewDue = moment(datePlanActivated).add(30, 'days');
      const today = moment();

      const numOfDaysLeft = dateRenewDue.diff(today, 'days');
      this.remainingDaysInExpiry = numOfDaysLeft;
    }
  }

  get isTrialExpired() {
    return this.trialExpired;
  }

  get isMembershipExpired() {
    return this.membershipExpired;
  }

  get numOfRemainingDaysInTrail() {
    return this.remainingDaysInTrail;
  }

  get numOfRemainingDaysInExpiry() {
    return this.remainingDaysInExpiry;
  }

  get hasActiveSubscription() {
    return this.activeSubscription;
  }

  get isSuperAdmin() {
    return this.staffRoles.indexOf('super-admin') > -1;
  }

  get isLoggedin() {
    if (this.encrDecSrvc.getObject(AppConstants.token)) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    let userDetails = {};

    const isRemember = this.encrDecSrvc.getObject( AppConstants.rememberMe );
    if( isRemember ){
      userDetails = this.encrDecSrvc.getObject( AppConstants.userDetails );
    }

    this.encrDecSrvc.clearStorage( );
    this.mangoAPISrvc.showLoader( false );
    this.encrDecSrvc.addObject( AppConstants.isLoggingUser, "false" );

    this.resetStaffPermission();

    this.mangoAPISrvc.logout().subscribe(() => {
      if (isRemember) {
        this.encrDecSrvc.addObject( AppConstants.rememberMe, isRemember );
        this.encrDecSrvc.addObject( AppConstants.userDetails, userDetails );
      }

      this.mangoAPISrvc.notifyLogging(false);
    });
  }

  // doStartScheduleJob() {
  //   this.authService.doStartScheduleJob();
  // }

  switchLang(lang) {
    lang = lang ? lang : 'English';
    localStorage.setItem('language', lang);
    this.changeLang.next(lang);
  }
}