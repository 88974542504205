<div class="email-automation-setup">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="Add To List" icon="fas fa-check" class="p-button-sm p-pr-2 p-mr-2"
                (click)="saveAction()" [disabled]="!isAddToListBtnEnabled">
            </button>
        </span>
        <div class="p-grid">
            <div class="p-col-12 p-pb-0">
                <div class="card p-mx-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col">
                            <label for="All Dates" class="label-text">{{ 'name' | translate }}</label>
                            <p-multiSelect [options]="AllClientNames" class="multi-select" styleClass="p-mt-2"
                                name="ClientName" [(ngModel)]="selectedClientItem" defaultLabel="Choose">
                            </p-multiSelect>
                        </div>
                        <div class="p-col">
                            <label for="ClientType" class="label-text">{{ 'client.type' | translate }}</label>
                            <p-multiSelect [options]="AllCustomerType" class="multi-select" styleClass="p-mt-2"
                                name="ClientType" [(ngModel)]="selectedCustomerType" defaultLabel="Choose">
                            </p-multiSelect>
                        </div>
                        <div class="p-col">
                            <label for="ClientGroup" class="label-text">{{ 'Client_Groups' | translate}}</label>
                            <p-multiSelect [options]="AllCustomerGroupCategoryList" class="multi-select"
                                styleClass="p-mt-2" name="ClientGroup" [(ngModel)]="selectedGroupCategory"
                                defaultLabel="Choose">
                            </p-multiSelect>
                        </div>
                        <div class="p-col">
                            <label for="BillingGroup" class="label-text">{{ 'billing-group' | translate }}</label>
                            <p-multiSelect [options]="AllCustomerGroupCategoryList" class="multi-select" styleClass="p-mt-2"
                                name="BillingGroup" [(ngModel)]="selectedBillingGroup" defaultLabel="Choose">
                            </p-multiSelect>
                        </div>
                        <div class="p-col">
                            <label for="UserAssigned" class="label-text">{{ 'staff-assigned' | translate }}</label>
                            <p-multiSelect [options]="AllStaffsTypes" class="multi-select" styleClass="p-mt-2"
                                name="UserAssigned" [(ngModel)]="selectedtaskUserItem" defaultLabel="Choose">
                            </p-multiSelect>
                        </div>
                        <div class="p-col">
                            <label for="BillingPartner" class="label-text">{{ 'Billing_Partner' | translate }}</label>
                            <p-multiSelect [options]="Allbillingpartners" class="multi-select" styleClass="p-mt-2"
                                name="BillingPartner" [(ngModel)]="selectedbillingpartnerItem" defaultLabel="Choose">
                            </p-multiSelect>
                        </div>
                        <div class="p-col p-mt-4 p-formgroup-inline">
                            <button pButton pRipple name="syncIcon" (click)="fetchClientListOfDetails()" type="button"
                                icon="fal fa-sync" class="p-button-rounded p-mr-3"></button>
                            <label for="syncIcon" class="label-text p-mt-2">Selected Clients:
                                <strong>{{selectedClientItems.length}}</strong></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-8 p-pt-0">
                <div class="card p-pt-0 p-mt-3 p-ml-3 p-mr-0">
                    <div class="p-col-12">
                        <p-table #dt [value]="assignTaskDataSource" [responsive]="true"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" dataKey="ClientID"
                            [scrollHeight]="'calc(90vh - 330px)'" [scrollable]="true"
                            (onRowSelect)="isValidForNew()" [(selection)]="selectedClientItems"
                            (onRowUnselect)="isValidForNew()" (onHeaderCheckboxToggle)="isValidForNew()">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-2p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-7p p-text-left">{{ 'name' | translate }}</th>
                                    <th class="width-3p p-text-left">
                                        {{ 'client.type' | translate }}
                                    </th>
                                    <th class="width-4p p-text-left">
                                        {{ 'Client_Groups' | translate}}
                                    </th>
                                    <th class="width-4p p-text-left">
                                        {{ 'billing-group' | translate }}
                                    </th>
                                    <th class="width-4p p-text-left">
                                        {{ 'staff-assigned' | translate }}
                                    </th>
                                    <th class="width-4p p-text-left">
                                        {{ 'Billing_Partner' | translate }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-2p p-text-center">
                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                    </td>
                                    <td class="width-7p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.ClientName}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.ClientName}}
                                    </td>
                                    <td class="width-3p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.CustomerTypeDescription}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.CustomerTypeDescription}}
                                    </td>
                                    <td class="width-4p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.GroupDescriptionIDArrayDesc}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.GroupDescriptionIDArrayDesc}}
                                    </td>
                                    <td class="width-4p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.BillingGroup}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.BillingGroup}}
                                    </td>
                                    <td class="width-4p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.StaffAssignedName}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.StaffAssignedName}}
                                    </td>
                                    <td class="width-4p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.BillingPartnerName}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.BillingPartnerName}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">
                                        <p-message severity="info" text="Data not found." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
            <div class="p-col-4 p-pt-0">
                <div class="card p-pt-0 p-mt-3 p-mr-3 p-ml-0">
                    <div class="p-col-12">
                        <p-table #dt [value]="listDataSource" [responsive]="true"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" dataKey="id"
                            [scrollHeight]="'calc(90vh - 330px)'" [scrollable]="true"
                            (onRowSelect)="isValidForNew()"
                            [(selection)]="selectedDataList" (onRowUnselect)="isValidForNew()">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-2p">
                                    </th>
                                    <th class="width-14p p-text-left">List</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-2p p-text-center">
                                        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                                    </td>
                                    <td class="width-14p p-text-left p-text-nowrap p-text-truncate" pTooltip="{{rowData.name}}"
                                        showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.name}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="2">
                                        <p-message severity="info" text="Data not found." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>