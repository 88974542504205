import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedComponentsService } from "@app/shared/components";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  EncrDecrService,
  mangoUtils,
  MangoApiService,
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import Swal from "sweetalert2";
declare let numeral: any;
import $ from "jquery";

@Component({
  selector: 'app-batch-assign-budget',
  templateUrl: './batch-assign-budget.component.html'
})
export class BatchAssignBudgetComponent implements OnInit {
  showAssignDialog: boolean = true
  dialogObj = {
    revenueAmount: "$0.00",
    timeAmount: "0.00",
    year: parseInt(moment().format('YYYY')),
    isOverride: false
  }

  yearsList = [];

  companyID

  rolloverObj = {
    isUpdateRevenue: false,
    isUpdateTime: false,
    percentage: 0,
    timePercentage: 0,
    isEnableRollover: true,
    isDefaultBudgetCompanyRollover: false
  };

  rolloverOptions = 'existing'

  @Input() selectedItems: any;
  @Input() isFromProject: any;
  @Input() isBudgetingProjectLevel: any;
  @Output() onAssignBudgetsCompleted = new EventEmitter<{
    isSuccess: boolean
  }>();

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public sharedSrvc: SharedComponentsService,
    private translate: TranslateService,
    public mangoUtils: mangoUtils
  ) { }

  ngOnInit(): void {
    this.getYears()
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    console.log({items: this.selectedItems})
  }

  onCloseAssignDialog(isCompleted) {
    this.clearDialogValues()
    this.onAssignBudgetsCompleted.emit(isCompleted);
  }

  clearDialogValues() {
    this.dialogObj = {
      revenueAmount: "$0.00",
      timeAmount: "0.00",
      year: parseInt(moment().format('YYYY')),
      isOverride: false
    }
  }

  onAssignBudgets() {
    const list = this.selectedItems?.map((item) => ({
      ClientID: item.ClientID,
      ProjectMasterID: item.ProjectMasterID,
      CompanyID: item.CompanyID,
      Year: this.dialogObj.year,
      RevenueAmount: numeral(this.dialogObj.revenueAmount).value(),
      TimeAmount: numeral(this.dialogObj.timeAmount).value(),
      isOverride: this.dialogObj.isOverride,
      ddmProjectHeaderID: item.ddmProjectHeaderID
        ? item.ddmProjectHeaderID
        : null,
      //save Rollover options
      isDefaultBudgetCompanyRollover:
        this.rolloverObj.isDefaultBudgetCompanyRollover,
      isEnableRollOver: this.rolloverObj.isEnableRollover,
      RollOverOption: this.rolloverOptions,
      PctOptionTime:
        this.rolloverObj.isUpdateTime && this.rolloverOptions == "percentage"
          ? numeral(this.rolloverObj.timePercentage).value()
          : 0,
      PctOptionRev:
        this.rolloverObj.isUpdateRevenue && this.rolloverOptions == "percentage"
          ? numeral(this.rolloverObj.percentage).value()
          : 0,
    }));

    Swal.fire({
      title: this.translate.instant("confirmation"),
      icon: "warning",
      text: this.dialogObj.isOverride
        ? this.translate.instant("budgets.Override_Confirmation")
        : this.translate.instant("budgets.assign_budgets_confirmation"),
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_Proceed"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.bulkCreateBudgets({ list }, this.companyID).subscribe(
          (result: any) => {
            const returnObj = {
              failed: result?.failed?.length || 0,
              success: result?.success?.length || 0,
              exists:
                result?.failed?.filter((item) => item.exists)?.length || 0,
              noID: result?.failed?.filter((item) => item.noID)?.length || 0,
            };

            let message = `<div><strong>${returnObj.success} out of ${list?.length}</strong> Budgets Assigned Successfully!</div>`;
            message += returnObj.failed > 0 ? `<br><div><strong>${returnObj.failed} Failed</strong></div>` : ''
            message += returnObj.exists > 0 ? `<br><div><strong>${returnObj.exists} Already Exists!</strong></div>` : ''
            message += returnObj.noID > 0 ? `<br><div><strong>${returnObj.noID} has either no Client or Engagement assigned!</strong></div>` : ''

            this.mangoAPISrvc.showLoader(false);

            Swal.fire({
              icon: "info",
              title: "Information!",
              html: message,
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: "Ok",
            });

            this.clearDialogValues();
            this.onCloseAssignDialog(true);
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "error",
              "Error!",
              AppConstants.createErrorMsg
            );
          }
        );
      }
    });
  }

  shouldDisable() {
    return !(
      (numeral(this.dialogObj.revenueAmount).value()) ||
      (numeral(this.dialogObj.timeAmount).value())
    );
  }

  getYears() {
    const value = 5;
    const yearToday = parseInt(moment().format("YYYY"));
    for (let x = yearToday + value; x >= yearToday; x--) {
      this.yearsList.push({ label: x.toString(), value: x });
    }
  }
}
