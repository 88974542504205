import { Component, OnInit, ViewChild, Input, IterableDiffers, DoCheck } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import moment from 'moment';
declare let numeral: any;
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-make-deposits',
  templateUrl: './make-deposits.component.html'
})
export class MakeDepositsComponent implements OnInit, DoCheck {
  public banksDataSource: any = [];
  public makeDepositeForm: UntypedFormGroup;
  public banksList: any = [];
  public isFormValid: boolean = false;
  public isReverseValid: boolean = false;
  public totalNumberAmt: number = 0;
  searchTextStr: any = '';
  @Input() selectedItems: Array<any> = [];
  differ: any;
  loginCompanyId: any = -1;

  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  companySetting: any;

  constructor(private http: HttpClient, differs: IterableDiffers, private _fb: UntypedFormBuilder, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public mangoUtils: mangoUtils, private translate: TranslateService) {
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.depositsRoutePath + '_' + AppConstants.SearchString);
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.differ = differs.find([]).create(null);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('accounting') },
      { label: this.translate.instant('make-deposits'), icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);

    this.getAllPaymentsData();
    this.initializeForm();
    this.initBanksList();
  }

  ngDoCheck() {
    const change = this.differ.diff(this.selectedItems);
    if (change) {
      this.checkBoxChanged();
    }
  }

  checkBoxChanged() {
    const totalsAmt = this.selectedItems.reduce(function (a, b) { return numeral(a).value() + +numeral(b.PaymentAmount).value(); }, 0);
    this.totalNumberAmt = totalsAmt;
    this.makeDepositeForm.controls['DepositAmount'].setValue(numeral(this.totalNumberAmt).value());
    if (numeral(this.totalNumberAmt).value() > 0) {
      this.isReverseValid = true;
    }
  }

  getAllPaymentsData() {
    this.selectedItems = [];
    this.isReverseValid = false;
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getALLPaymentsData().subscribe((data: any) => {
      parent.banksDataSource = data.map(function (obj) {
        obj['PaymentAmount'] = numeral(obj['PaymentAmount']).format('0,0.00');
        obj['selected'] = false;
        obj['uuid'] = parent.mangoUtils.generateUUID();
        return obj;
      });
      // parent.banksDataSource = parent.banksDataSource
      //   .sort((a,b)=>{
      //     let dateSort = new Date(a['PaymentDate']) < new Date(b['PaymentDate']);
      //     let paymentTypeSort = a['PaymentType'].toLowerCase() > b['PaymentType'].toLowerCase();
      //     let paymentAmountSort = Number(a['PaymentAmount']) > Number(b['PaymentAmount']);
      //     return dateSort || paymentTypeSort || paymentAmountSort
      //     // return paymentTypeSort
      //   })
      console.log(parent.banksDataSource);
      parent.loadFilterGrid();
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  /*
  Verifing the form
  */
  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.makeDepositeForm.controls).forEach(key => {
      if (this.makeDepositeForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.makeDepositeForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (
      !isInValidData &&
      this.makeDepositeForm.dirty &&
      this.makeDepositeForm.controls['DepositAmount'].value > 0
    ) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  initializeForm() {
    this.makeDepositeForm = this._fb.group({
      DepositDate: [new Date(), [<any>Validators.required]],
      DepositMemo: [''],
      DepositAmount: [''],
      BankID: ['', [<any>Validators.required]]
    });
    this.makeDepositeForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.makeDepositeForm.pristine);
      this.validateForm();
    });
  }

  /*
    fetch bank names
  */
  initBanksList() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getBankNames().subscribe((banks:any) => {
      banks.map(function (obj) { obj['label'] = obj['BankName']; obj['value'] = obj['BankID']; return obj; });
      const sortedData = banks.sort(parent.mangoUtils.compareValues('BankName', 'asc'));
      const activeData = sortedData.filter(function (obj) { return !obj['Inactive'] });
      if(activeData && activeData.length == 1){
        this.makeDepositeForm.controls['BankID'].setValue(activeData[0].BankID);
        this.makeDepositeForm.controls['BankID'].markAsDirty();
      } else if (activeData && activeData.length > 1) {
        activeData.map(item => {
          if (item['DefaultBank']) {
            this.makeDepositeForm.controls['BankID'].setValue(item.BankID);
            this.makeDepositeForm.controls['BankID'].markAsDirty();
          }
        });
      }
      parent.banksList = activeData;
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  batchDeletePaymentDetail(detailId, headerId) {
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    const observableBatch = [];
    const urlOne = parent.http.delete(`${environment.API_URL}/cash-receipts/payment-detail/${detailId}`, { 'headers': headers });
    observableBatch.push(urlOne);
    parent.mangoAPISrvc.showLoader(true);

    forkJoin(observableBatch).subscribe(
      (data: any) => {
        parent.mangoAPISrvc.getPaymentHeaderById(headerId).subscribe(
          (paymentHeaderObj: any) => {
            if (paymentHeaderObj.PaymentUnapplied > 0) {
              parent.mangoAPISrvc
                .deleteBillingHeader(paymentHeaderObj['BillingHeaderID'])
                .subscribe(item => {});
            }
            parent.mangoAPISrvc.showLoader(false);
            parent.batchDeletePaymentHeader(headerId);
          },
          error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      },
      err => console.error(err)
    );
  }

  batchDeletePaymentHeader(id) {
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    const observableBatch = [];
    const urlOne = parent.http.delete(`${environment.API_URL}/cash-receipts/payment-header/${id}`, { 'headers': headers });
    observableBatch.push(urlOne);
    parent.mangoAPISrvc.showLoader(true);

    forkJoin(observableBatch).subscribe(
      (data: any) => {
        parent.totalNumberAmt = 0;
        parent.isReverseValid = false;
        parent.mangoAPISrvc.showLoader(false);
        parent.getAllPaymentsData();
      },
      err => console.error(err)
    );
  }

  saveData( ){
    const parent = this;

    const formObj = parent.makeDepositeForm.value;

    formObj.DepositDate = moment(new Date(formObj.DepositDate)).format('YYYY-MM-DD');

    parent.isFormValid = true;

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.createDeposit(formObj).subscribe(
      (banks: any) => {
        parent.totalNumberAmt = 0;

          // payment header list
          const observableBatch = [ ];

          for(
            let i = 0;
            i < parent.selectedItems.length;
            ++i
          ){
            const currentObj = parent.selectedItems[ i ];

          currentObj['PaymentToBankID'] = banks.data.PaymentToBankID;
          currentObj['SelectedForDepositToBank'] = true;
          currentObj['PaymentAmount'] = numeral(currentObj['PaymentAmount']).value();

          observableBatch.push(currentObj);
        }

        parent.mangoAPISrvc.bulkUpdatePaymentHeader({ observableBatch }).subscribe(
          data => {
            parent.mangoAPISrvc.showLoader(false);

            parent.mangoAPISrvc.notify('success', 'Success!', 'Deposit saved successfully!');

            parent.getAllPaymentsData();
          },
          error => {
            parent.mangoAPISrvc.showLoader(false);

            parent.mangoAPISrvc.notify('error', 'Error!', 'Error while saving Deposit(s)!');
          }
        );
      },
      error => {
        parent.mangoAPISrvc.notify('error', 'Error!', error);

        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  reverseData(itemdata) {
    const parentObj = this;
    if(itemdata['PaymentType'] == 'Credit Card') {
      Swal.fire({
        icon: 'warning',
        title: 'Delete Not Allowed!',
        text: this.translate.instant('payments.ach_cc_delete'),
        showConfirmButton: true
      });
      return;
    }

    Swal.fire({
      title: this.translate.instant('reverse-payment'),
      text: this.translate.instant('MakeDeposits.invoices-balances-will-be-adjusted'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then(result => {
      if (result.value) {
        parentObj.mangoAPISrvc.showLoader(true);
        parentObj.mangoAPISrvc.getPaymentDetailByHeaderID(itemdata['PaymentHeaderID']).subscribe(
          (data: any) => {
            itemdata['paymentDetails'] = data;
            this.mangoAPISrvc
              .reversePayment({ paymentObj: itemdata, deletePaymentToBank: false })
              .subscribe(
                (result: any) => {
                  this.mangoAPISrvc.showLoader(false);
                  this.totalNumberAmt = 0;
                  this.isReverseValid = false;
                  this.getAllPaymentsData();
                },
                err => {
                  this.mangoAPISrvc.showLoader(false);
                  this.mangoAPISrvc.notify('error', 'Error!', err);
                }
              );
          },
          error => {
            parentObj.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parentObj.mangoAPISrvc.showLoader(false);
          }
        );
      }
    });
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(
      AppConstants.depositsRoutePath + '_' + AppConstants.SearchString,
      ''
    );
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      AppConstants.depositsRoutePath + '_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || '';
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'System Locking is enabled on the selected date.'
      );
    }
  }

  validateDepositDateTimeout: any;
  validateDepositDate( ){
    if(
        (
              typeof
              this.validateDepositDateTimeout
          !=  "undefined"
        )
    ){
      clearTimeout( this.validateDepositDateTimeout );
      this.validateDepositDateTimeout = undefined;
    }

    this.validateDepositDateTimeout = (
      setTimeout( ( ) => {
        const depositDate = (
          this.makeDepositeForm.controls[ "DepositDate" ].value
        );

        const dateToday = (
          new Date( )
        );

        if (this.mangoUtils.dateDiff(depositDate, dateToday, "months") > 2) {
          Swal.fire(
            {
              "icon": 'warning',

              "title": (
                `${ this.translate.instant( 'Warning' ) }?`
              ),
              "text": (
                `${ this.translate.instant( 'two-month-gap-date-entry-warning' ) }!`
              ),

              "confirmButtonText": 'OK',

              "showCancelButton": false,
              "allowEscapeKey": true,
              "allowEnterKey": true,
            }
          );
        }

        clearTimeout( this.validateDepositDateTimeout );
        this.validateDepositDateTimeout = undefined;
      }, 500 )
    );
  }
}