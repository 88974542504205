<div class="p-col-12 p-pl-3 p-mr-2 quickbooks" *ngIf="!isTrialVersion" >
  <p-tabView>
    <!-- Quickbooks -->
    <p-tabPanel header="Quickbooks">
      <form [formGroup]="mySettingsForm" novalidate style="height: calc(100vh - 200px)">
        <div class="layout-content p-pt-0">
          
          <div class="card p-col-12 p-mx -3 p-mb-2 p-mt-2" >
            <div *ngIf="!QBOAuthSuccess && mySettingsForm.controls['QBIntegration'].value" style="width: 100%;">
              <p-message severity="warn" text="Seems there is a problem with the QuickBooks Online (QBO) tokens needed to perform the sync process to QuickBooks. Please click the 'Authenticate on QuickBooks API' button at the bottom of the page to re-authenticate." ></p-message>
            </div>
            <div class="p-grid p-p-2">
              <div class="p-col-2">
                <p-checkbox binary="true" label="{{ 'settings.Activate-Quickbooks' | translate }}"
                  formControlName="QBIntegration" (click)="onStatusChange($event)"></p-checkbox>
              </div>
              <div class="p-col-1" *ngIf="mySettingsForm.controls['QBIntegration'].value">
                <p-radioButton name="currentQbView" value="Desktop" label="{{ 'Desktop' | translate }}"
                  formControlName="currentQbView" (onClick)="changeQBValue(false)" inputId="city1">
                </p-radioButton>
              </div>
              <div class="p-col-2" *ngIf="mySettingsForm.controls['QBIntegration'].value">
                <p-radioButton name="currentQbView" value="Online" label="{{ 'Online' | translate }}"
                  formControlName="currentQbView" (onClick)="changeQBValue(true)" inputId="city2">
                </p-radioButton>
              </div>
            </div>
          </div>


          <div class="card p-grid p-mb-2" *ngIf="mySettingsForm.controls['QBIntegration'].value">
            <div class="p-col-12 p-md-6">
              <h5 class="ic-blue">
                {{ "settings.Import-from-Quickbooks" | translate }}
              </h5>
              <div *ngFor="let module of filteredCheckboxes()" class="p-col-12">
                <p-checkbox binary="true" class="font-size-checkbox_mailconfig"
                  label="Import {{module.name}} FROM QuickBooks" formControlName="QBImport{{module.name}}"
                  [disabled]="mySettingsForm.controls['SyncFromQB'].value"></p-checkbox>
              </div>
            </div>

            <div class="p-col-12 p-md-6">
              <h5 class="ic-blue">
                {{ "settings.Sync-from-Mango-Billing" | translate }}
              </h5>

              <div *ngFor="let module of filteredCheckboxes()" class="p-col-12">
                <p-checkbox binary="true" class="font-size-checkbox_mailconfig"
                  label="Sync {{module.name}} FROM Mango Billing TO QuickBooks." formControlName="QBSync{{module.name}}"
                  [disabled]="mySettingsForm.controls['SyncFromQB'].value"></p-checkbox>
              </div>
            </div>

            <div class="p-col-6">
              <h5 class="ic-blue">
                {{ "Choose the start date for synchronization." | translate }}
              </h5>
            
              <p-calendar [style]="{'width': '50%'}" formControlName="dateSyncStart"
                required [showIcon]="true">
              </p-calendar>
            </div>

            <div class="p-col-6" *ngIf="mySettingsForm.controls['currentQbView'].value === 'Online' && mySettingsForm.controls['IsQBWeb'].value">
              <h5 class="ic-blue">{{ "Choose a default QBO Item." | translate }}
                <i class="fa fa-info-circle" pTooltip="Sometimes, items in invoices cannot be found in QBO, or they are of a disallowed type. To prevent this, please select a default item to which the issue will be associated if there is an error." tooltipPosition="right" showDelay="500" hideDelay="300"></i>
              </h5>
              <p-dropdown [options]="defaultQBOItemsList" optionLabel="Name" [style]="{'width': '70%'}" [disabled]="!QBOAuthSuccess" formControlName="defaultItemId" placeholder="Select a QBO default item." (onChange)="onSelectDefaultItemId($event)" id="defaultItemId" autoDisplayFirst="false">
                <ng-template let-item pTemplate="selectedItem">{{item.Id}} - {{item.Name}}</ng-template>
                <ng-template let-item pTemplate="item">{{item.Id}} - {{item.Name}}</ng-template>
              </p-dropdown>
            </div>
            
          </div>
          
          <div class="card p-col-12 p-mx -3 p-mb-2 p-mt-2" *ngIf="
                                            mySettingsForm.controls['currentQbView'].value === 'Online' &&
                                            mySettingsForm.controls['IsQBWeb'].value && mySettingsForm.controls['QBIntegration'].value
                                          ">
            <div class="card p-col-12">
              <div class="p-col-12 p-text-bold">
                <div class="p-d-flex p-align-center">
                  <p-button class="p-mx-2 p-mt-2" label="Authenticate on QuickBooks API"
                    (click)="openQuickBooks($event)"></p-button>
                  <span>
                    <i class="fas fa-exclamation-circle p-mr-1"></i>
                    {{ "If you are having sync issues using the online version, try to re-authenticate by clicking this
                    button." |
                    translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="card p-col-12 p-mb-3" *ngIf="
              mySettingsForm.controls['QBIntegration'].value &&
              mySettingsForm.controls['currentQbView'].value === 'Desktop'">
            <h5 class="p-py-2 ic-blue">
              {{ "settings.Location-of-Quickbooks-Company-File" | translate }}
              <i tooltipPosition="top"
                pTooltip="{{ 'settings.Location-of-Quickbooks-Company-File-tooltip' | translate }}"
                tooltipStyleClass="tooltip-width-450" class="fa fa-question-circle ic-blue p-ml-1 cursor-hand"></i>
            </h5>
            <div class="p-col-11 p-d-flex">
              <span class="p-col-8">
                <input type="text" pInputText [disabled]="isEnableBrowseButton()" autocomplete="off"
                  placeholder="{{ 'settings.Please-paste-folder' | translate }}" formControlName="QBCompanyFileLocation"
                  class="custom-input" />
              </span>
              <span class="p-col-2">
                <div class="tooltip-container">
                  <span class="tooltip-text">QBW finder will help you to find the exact path of the QBW file. Paste the result in the input next to this button.</span>
                  <button pButton pRipple label="See QBW finder Instructions" (click)="openDownloadBatModal($event)" class="p-mr-1 p-button-md"></button>
                </div>
              </span>
                                        
              <span class="p-col-2">
                <button type="button" pButton pRipple label="{{ 'settings.Download-QWC-File' | translate }}" icon="fal fa-download"
                  class="p-mr-1 p-button-md" [disabled]="
                    !mySettingsForm.controls['QBIntegration'].value ||
                    mySettingsForm.controls['QBCompanyFileLocation'].value === ''
                  " (click)="downloadFile()"></button>
              </span>
            </div>
            
            <div class="card p-col-8">
              <div class="p-col-11 p-text-bold">
                {{ "settings.required-for-quickbooks" | translate }}
              </div>
              <div class="p-col-11 p-text-bold">
                {{ "settings.after-downloading-file" | translate }}
              </div>

            </div>

          </div>
          <div class="p-col-2">
            <button pButton pRipple label="{{ 'save_button' | translate }}" icon="fas fa-check"
              class="p-pr-5 p-button-sm" (click)="saveQuickbookIntegration()"
              [disabled]="!checkQBModuleSelected() && mySettingsForm.valid"></button>
          </div>
        </div>
      </form>
    </p-tabPanel>
    <!-- Quickbooks Sync Errors -->
    <p-tabPanel header="Quickbooks Sync Errors">

      <p-table [value]="qbErrors" [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [lazy]="true"
        (onLazyLoad)="loadDataOnPageChange($event)" [rowsPerPageOptions]="[10, 25, 50]" [first]="first">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%">Module Name</th>
            <th style="width: 10%;">QB Error Code</th>
            <th style="width: 20%;">Error Message</th>
            <th style="width: 20%;">Error Details</th>
            <th style="width: 10%;">Created At</th>
            <th style="width: 10%;">Last Update</th>
            <th style="width: 20%;">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr (click)="getErrorDetails(rowData.errorCode)">
            <td>{{ rowData.module.moduleName }}</td>
            <td>{{ rowData.errorCode === null ? 'No error code.' : rowData.errorCode }}</td>
            <td class="wrap-text">{{ rowData.errorMessage }}</td>
            <td class="wrap-text">{{ rowData.errorDetails === null ? 'No error details.' : rowData.errorDetails}}</td>
            <td>{{ rowData.createdDate | date: 'short' }}</td>
            <td>{{ rowData.updatedDate | date: 'short' }}</td>
            <td>
              <button pButton pRipple label="View Data" class="p-button p-component p-button-text-icon-left p-button-sm"
                (click)="openJSONDataDialog(rowData.mangoData, $event)"
                pTooltip="View JSON Data associated with the error." tooltipPosition="top"
                style="margin-right: 10px;"></button>

              <button pButton pRipple label="Sync Record"
                class="p-button p-component p-button-text-icon-left p-button-sm"
                (click)="updateRecord(rowData.dataStoreId, $event)"
                pTooltip="Sync this record in the following sync process." tooltipPosition="top"></button>

            </td>
          </tr>
        </ng-template>
      </p-table>

    </p-tabPanel>

    <p-tabPanel header="Module Date Exclusion">
      <app-module-date-exclusion></app-module-date-exclusion>
    </p-tabPanel>
  </p-tabView>

  <p-dialog [(visible)]="showErrorDetailsDialog" [modal]="true" [style]="{width: '70vw'}" [closable]="true"
    [header]="errorDetailsTitle">
    <ng-container *ngIf="qbErrorDetails">
      <div class="p-grid">
        <div class="p-col-12 p-md-6">
          <h4>Causes</h4>
          <ul>
            <li *ngFor="let cause of qbErrorDetails.causes">{{ cause }}</li>
          </ul>
        </div>
        <div class="p-col-12 p-md-6">
          <h4>Solutions</h4>
          <ul>
            <li *ngFor="let fix of qbErrorDetails.fix">{{ fix }}</li>
          </ul>
        </div>
      </div>
    </ng-container>
    <p-footer>
      <button pButton type="button" label="Close" (click)="showErrorDetailsDialog=false"></button>
    </p-footer>
  </p-dialog>

  <p-dialog header="Mango Data" [visible]="showDataDialog" [modal]="true" [style]="{ width: '40vw' }" [closable]="false"
    [dismissableMask]="true">
    <pre>{{formattedJson | json}}</pre>
    <p-footer>
      <button pButton type="button" label="Close" (click)="showDataDialog = false" class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>

  <p-dialog header="How to Use the QBW finder" [visible]="showInstructionsDialog" [modal]="true" [style]="{ width: '40vw' }" [closable]="false" [dismissableMask]="true">
    <ng-container *ngIf="instructions && instructions.length > 0">
      <ng-container *ngFor="let instruction of instructions">
        <strong>{{ instruction.title }}</strong>
        <ng-container *ngFor="let step of instruction.steps">
          <div style="margin-left: 20px;">
            - <span style="font-weight: normal;">{{ step }}</span>
          </div>
        </ng-container>
        <br> 
      </ng-container>
    </ng-container>
    <p-footer>
      <a href="https://mangobillinglogos.s3.amazonaws.com/qbw_finder.bat" download>
        <button pButton type="button" label="Download QBW file finder." class="ui-button-secondary"></button>
      </a>
      <button pButton type="button" label="Close" (click)="showInstructionsDialog = false" class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  
  
  
  
  
  
  
  
  
  
</div>