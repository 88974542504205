import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Compiler } from "@angular/core";
import { Router } from "@angular/router";
import { EncrDecrService, MangoApiService, AuthGuard } from "@app/_services";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen } from "rxjs/operators";
import { AppConstants } from "./api-constants";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authGuard: AuthGuard,
    private _compiler: Compiler,
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let interceptedError = null;
    return next.handle(request).pipe(
      catchError((err) => {
        interceptedError = err;
        if (err.url.indexOf("api.lexcharge.com") > 0) {
          this.mangoAPISrvc.showLoader(false);
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
        if (err.status === 422) {
          this.mangoAPISrvc.showLoader(false);
          return throwError(err.error);
        }
        if (err.status === 401 || err.status === 0 || err.status == "") {
          const timerTasks = this.encrDecSrvc.getObject(AppConstants.timerTasks);
          const timerOwner = this.encrDecSrvc.getObject(AppConstants.timerOwner);
          this.router.navigate(["/login"]).then((result) => {
            //this.authGuard.logout();
            this._compiler.clearCache();
            setTimeout(() => {
              this.mangoAPISrvc.notifyLogging(false);
              if (timerTasks) {
                this.encrDecSrvc.addObject(AppConstants.timerOwner, timerOwner);
                this.encrDecSrvc.addObject(AppConstants.timerTasks, timerTasks);
              }
            }, 100);
          });
        }
        if (err.status === 500) {
          this.mangoAPISrvc.showLoader(false);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      }),
      retryWhen((error) => {
        let retries = 0;
        return error.pipe(
          delay(1000),
          concatMap((err) => {
            // @NOTE: Only retry 3 times if the api is /api/authenticate
            if (interceptedError?.url?.includes("/api/authenticate")) {
              retries++;
              if (retries < 3) {
                return of(err);
              } else throw err;
            } else throw err;
          })
        );
      })
    );
  }
}
