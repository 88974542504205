<div class="finished-setup">
    <div class="layout-content">
        <div class="p-mx-5 p-py-2 p-px-3">
            <div class="card p-col-12 p-px-3">
                <div class="p-col-12">
                    <h4 class="ic-orange">You have finished your initial setup of Mango Practice Management!</h4>
                </div>
                <div class="p-col-12">
                    <h5>It is recommended to review your <strong>Company</strong> information where you can load your firm logo and enter address information.  Please review all <strong>Settings & Setups</strong> for further customization. Need help, let us know!</h5>
                </div>
                <div class="p-grid p-nogutter p-justify-end">

                    <p-button label="Start Using Mango Practice Management" (onClick)="nextStep()"
                        icon="pi pi-angle-right" iconPos="right">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
</div>