import { Component, Renderer2 } from '@angular/core';
import { MenuService } from './app.menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MangoApiService } from './_services';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('mask-anim', [
            state('void', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 0.8
            })),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppMainComponent {

    horizontalMenu: boolean;

    darkMode = false;

    menuColorMode = 'dark';

    menuColor = 'layout-menu-dark';

    themeColor = 'blue';

    layoutColor = 'blue';

    rightPanelClick: boolean;

    rightPanelActive: boolean;

    menuClick: boolean;

    staticMenuActive: boolean;

    menuMobileActive: boolean;

    megaMenuClick: boolean;

    megaMenuActive: boolean;

    megaMenuMobileClick: boolean;

    megaMenuMobileActive: boolean;

    topbarItemClick: boolean;

    topbarMobileMenuClick: boolean;

    topbarMobileMenuActive: boolean;

    sidebarActive: boolean;

    activeTopbarItem: any;

    topbarMenuActive: boolean;

    menuHoverActive: boolean;

    configActive: boolean;

    configClick: boolean;

    ripple: boolean = true;

    inputStyle = 'outlined';//filled

    lockViewChangeSubscription: Subscription;
    profileDataSource:any;

    menuLayoutChangedSubscription: Subscription;

    constructor(private menuService: MenuService, private primengConfig: PrimeNGConfig,private mangoAPISrvc: MangoApiService,) {
        this.mangoAPISrvc.applyLanguage(); 
        //this.getProfileData();     
        this.updateTopMenuView();
        this.onLayoutClick();

        this.menuLayoutChangedSubscription = this.menuService.horizontalSource$.subscribe(() => {
            this.horizontalMenu = !this.horizontalMenu;
            localStorage.setItem("isTopMenu", JSON.stringify(this.horizontalMenu));
            if(this.profileDataSource){
                this.updateTopMenu();
            }else{
                this.mangoAPISrvc.getProfileData().subscribe((data: any) => {
                    this.profileDataSource = data;
                    this.updateTopMenu();
                  }, error => {
                  })
            }            
            this.updateTopMenuView();             
        });
    }

     updateTopMenu() {
        const parent = this;
        const isTopMenu = localStorage.getItem("isTopMenu");
        const isLockMenu = localStorage.getItem("isLockMenu");
        parent.profileDataSource.isTopMenu = isTopMenu ? JSON.parse(isTopMenu) : false;
        parent.profileDataSource.isLockMenu = isLockMenu ? JSON.parse(isLockMenu) : false;
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.updateProfileView(parent.profileDataSource).subscribe((data) => {
            parent.mangoAPISrvc.showLoader(false);
        }, error => {
        });
      }

      updateTopMenuView() {
        const isTopMenu = localStorage.getItem("isTopMenu");
        if(isTopMenu == 'true') {
            this.staticMenuActive = false;
            this.sidebarActive = false;
            this.horizontalMenu = true;
        } else {
            this.staticMenuActive = true;
            this.sidebarActive = true;
            this.horizontalMenu = false;
        } 
      }

    ngOnInit() {
        this.mangoAPISrvc.applyLanguage();
        this.primengConfig.ripple = true;
        this.setLockMenu();
       
    }

    ngOnDestroy() {
        if (this.menuLayoutChangedSubscription) {
            this.menuLayoutChangedSubscription.unsubscribe();
        }
    }

    setLockMenu(){
        const locked = localStorage.getItem("isLockMenu");
        if(locked && locked == 'true'){
            this.staticMenuActive = this.sidebarActive =  true;
        }else{
            this.staticMenuActive = this.sidebarActive =  false;
        }
    }

    onLayoutClick() {
        this.updateTopMenuView();
        this.setLockMenu();
        this.primengConfig.ripple = true;
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.megaMenuClick) {
            this.megaMenuActive = false;
        }

        if (!this.megaMenuMobileClick) {
            this.megaMenuMobileActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal()) {
                this.menuService.reset();
            }

            if (this.menuMobileActive) {
                this.menuMobileActive = false;
            }

            this.menuHoverActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.configClick = false;
        this.menuClick = false;
        this.topbarItemClick = false;
        this.megaMenuClick = false;
        this.megaMenuMobileClick = false;
        this.rightPanelClick = false;
    }

    onMegaMenuButtonClick(event) {
        this.megaMenuClick = true;
        this.megaMenuActive = !this.megaMenuActive;
        event.preventDefault();
    }

    onMegaMenuClick(event) {
        this.megaMenuClick = true;
        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;

        event.preventDefault();
    }

    onRightPanelClose(event) {
        this.rightPanelActive = false;
        this.rightPanelClick = false;

        event.preventDefault();
    }

    onRightPanelClick(event) {
        this.rightPanelClick = true;

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMobileMenuClick = true;
        this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

        event.preventDefault();
    }

    onMegaMenuMobileButtonClick(event) {
        this.megaMenuMobileClick = true;
        this.megaMenuMobileActive = !this.megaMenuMobileActive;

        event.preventDefault();
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isMobile()) {
            this.menuMobileActive = !this.menuMobileActive;
        }

        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    onToggleMenuClick(event: Event) {
        this.staticMenuActive = !this.staticMenuActive;
        localStorage.setItem("isLockMenu", JSON.stringify(this.staticMenuActive));
        this.onLayoutClick();
        if(this.profileDataSource){
            this.updateTopMenu();
        }else{
            this.mangoAPISrvc.getProfileData().subscribe((data: any) => {
                this.profileDataSource = data;
                this.updateTopMenu();
              }, error => {})
        }            
        // this.updateTopMenuView();
        event.preventDefault();
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRippleChange(event) {
        this.ripple = event.checked;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isHorizontal() {
        const isTopMenu = localStorage.getItem("isTopMenu");
        if(isTopMenu == 'true'){
            return true;
        }else{
            return false;
        }
    }

}
