import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Table } from "primeng/table";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, timer } from "rxjs";
import { environment } from "@environments/environment";
declare let numeral: any;
import moment from "moment";
import { AppConstants } from "@app/_helpers/api-constants";

@Component({
  selector: "app-apply-time-to-invoice",
  templateUrl: "./apply-time-to-invoice.component.html",
})
export class ApplyTimeToInvoiceComponent implements OnInit, AfterViewInit {
  searchTextStr: any = "";
  @ViewChild("searchValue") searchValue;
  @ViewChild("historydt") dataTableComponent: Table;
  @ViewChild("dt") dt: Table;
  filteredItemsSize = -1;
  intervalid: any;
  isIncludeAll = false;
  public timeSlipsInvoiceForm: UntypedFormGroup;
  public timeSlipsDataSource: any = [];
  public clientsList: any = [];
  public filteredClients: any = [];
  public historyDataSource: any = [];
  public isFormValid: boolean = false;
  public isHistoryValid: boolean = false;
  public isShowTable: boolean = false;
  public selClient: any = null;
  public historyDisplay: boolean = false;
  public IsEnableHistory: boolean = false;
  public selectedTimeRecords = [];
  public TotalTime: any = 0;
  public totalStandarAmount: any = 0;
  public totalWriteUpDown: any = 0;
  public totalBilledAmount: any = 0;
  public globalFilterColumns: any = [];

  cols: any[];
  _selectedColumns: any = [];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private _fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils
  ) {
    this.translate.reloadLang(this.translate.currentLang).subscribe((data) => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant("Billing-&-Invoicing") },
        { label: this.translate.instant("apply-slips"), icon: "ic-red" },
      ]);
      this.initializeColumns();
    });

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns = this.cols.filter((col) => arr.includes(col.field));

    this.globalFilterColumns = [
      ...["Ddate", "TotalTime", "StandardAmount", "WriteUpDown", "BilledAmount", "Memo"],
      ...this._selectedColumns.map((col) => col.field),
    ];
  }

  initializeColumns() {
    this.cols = [
      {
        field: "StaffName",
        header: this.translate.instant("user-title"),
        rowClass: "width-7p p-text-left",
      },
      {
        field: "StaffNumber",
        header: this.translate.instant("User Initials"),
        rowClass: "width-6p p-text-left",
      },
      {
        field: "EngagementName",
        header: this.translate.instant("Engagement"),
        rowClass: "width-10p p-text-left",
      },
      {
        field: "ServiceCode",
        header: this.translate.instant("Service Code"),
        rowClass: "width-6p p-text-left",
      },
      {
        field: "Description",
        header: this.translate.instant("work.description"),
        rowClass: "width-10p p-text-left",
      },
    ];
    this._selectedColumns = [
      {
        field: "StaffName",
        header: this.translate.instant("user-title"),
        rowClass: "width-7p p-text-left",
      },
      {
        field: "EngagementName",
        header: this.translate.instant("Engagement"),
        rowClass: "width-10p p-text-left",
      },
      {
        field: "ServiceCode",
        header: this.translate.instant("Service Code"),
        rowClass: "width-6p p-text-left",
      },
      {
        field: "Description",
        header: this.translate.instant("work.description"),
        rowClass: "width-10p p-text-left",
      },
    ];

    this.globalFilterColumns = [
      "Ddate",
      "StaffName",
      "EngagementName",
      "ServiceCode",
      "Description",
      "TotalTime",
      "StandardAmount",
      "WriteUpDown",
      "BilledAmount",
      "Memo",
    ];
  }

  ngOnInit(): void {
    this.initializeForm();
  }
  /*
    dropDownselect
    */
  handleSelectClick(event) {
    this.historyDataSource = [];
    if (event && event["ClientID"]) {
      this.clearData();
      this.selClient = event;
      this.isHistoryValid = true;
      this.timeSlipsInvoiceForm.controls["ClientID"].setValue(event["ClientID"]);
      this.historyData();
    } else {
      this.clearData();
    }
  }

  clearData() {
    this.timeSlipsInvoiceForm.reset();
    this.isHistoryValid = false;
    this.selClient = null;
    this.historyDataSource = [];
    this.selectedTimeRecords = [];
    this.timeSlipsDataSource = [];
    this.TotalTime = 0.0;
    this.totalStandarAmount = 0;
    this.totalWriteUpDown = 0;
    this.totalBilledAmount = 0;
    this.isShowTable = false;
  }

  fetchClients() {
    if (this.clientsList.length == 0) {
      const list = this.encrDecSrvc.clientList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientsList.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);

  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered = [];
      const query = event.query.toLowerCase();

      for (const client of this.clientsList) {
        const nameMatch = client["ClientName"].toLowerCase().includes(query);
        const numberMatch = client["ClientNumber"]?.toLowerCase()?.includes(query);
        const isActiveClient = client["Inactive"] === false;

        if (this.isIncludeAll) {
          if ((nameMatch || numberMatch) && client["ContactRecord"] !== true) {
            filtered.push(client);
          }
        } else {
          if ((nameMatch || numberMatch) && client["ContactRecord"] !== true && isActiveClient) {
            filtered.push(client);
          }
        }

        if (filtered.length > 20) {
          break;
        }
      }

      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  /*
   History drop down click
 */
  handleHistoryClick(item) {
    this.isShowTable = false;
    this.timeSlipsInvoiceForm.controls["DescriptionShort"].setValue(item["DescriptionShort"]);
    this.timeSlipsInvoiceForm.controls["InvoiceAmount"].setValue(
      "$" + numeral(item["InvoiceAmount"]).format("0,0.00")
    );
    item["InvoiceDate"] = item["ParsedInvoiceDate"]
      ? moment(item["ParsedInvoiceDate"]).format("MM/DD/YYYY")
      : "";
    this.timeSlipsInvoiceForm.controls["InvoiceDate"].setValue(item["InvoiceDate"]);
    this.timeSlipsInvoiceForm.controls["BillingHeaderID"].setValue(item["BillingHeaderID"]);
    this.timeSlipsInvoiceForm.controls["InvoiceNumber"].setValue(item["InvoiceNumber"]);
    this.validateForm();
    this.historyDisplay = false;
    this.getInvoiceHistory(item["ClientID"], item["BillingHeaderID"]);
  }

  historyData() {
    const parent = this;
    parent.historyDisplay = true;
    if (parent.selClient["ClientID"] && parent.historyDataSource.length == 0) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getInvoiceHistory(parent.selClient["ClientID"]).subscribe((item: any) => {
        parent.historyDataSource = item.filter(
          (item) =>
            item["InvoiceType"] !== "Retainer Invoice" &&
            item["InvoiceType"] !== "Retainer Payment" &&
            item["InvoiceBalance"] >= 0
        );
        const interval = setInterval(() => {
          if (!this.searchValue) return;
          clearInterval(interval);
          this.searchValue.nativeElement.focus();
        });
        parent.mangoAPISrvc.showLoader(false);
      });
    }
  }

  getInvoiceHistory(clientId, bilingHeaderId) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    const query =
      "ClientID=" + clientId + "&BillingHeaderID=" + bilingHeaderId + "&offset=0&limit=2000";
    parent.mangoAPISrvc.getInvoiceList(query).subscribe((item) => {
      parent.timeSlipsDataSource = item;
      parent.isShowTable = true;
      parent.timeSlipsDataSource.map(function (obj) {
        obj["isCalculationDone"] = false;
        obj["Billed"] = obj["Billed"] ? obj["Billed"] : false;
        obj["BilledAmount"] = obj["BilledAmount"] ? obj["BilledAmount"] : 0.0;
        obj["WriteUpDown"] = obj["WriteUpDown"] ? obj["WriteUpDown"] : 0.0;
        obj["StandardAmount"] = obj["StandardAmount"] ? obj["StandardAmount"] : 0.0;
        obj["Memo"] = obj["Memo"] ? obj["Memo"] : "";
        obj["StandardAmount"] = obj["StandardAmount"] ? obj["StandardAmount"] : 0.0;
        return obj;
      });
      parent.timeSlipsDataSource.sort(function (x, y) {
        return (
          y["Billed"] - x["Billed"] ||
          new Date(x["Ddate"]).getTime() - new Date(y["Ddate"]).getTime()
        );
      });
      parent.footGroupTotalsForRetainer();
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  parentCheckboxSelection(event) {
    // event.checked :table level check box > else > row level checkbox
    if (event.checked) {
      if (this.selectedTimeRecords.length > 0 && event.checked == true) {
        const selectedParentItem = this.selectedTimeRecords.filter((val) => val["Billed"] != true);
        this.selectedTimeRecords = [...selectedParentItem];
      } else {
        this.selectedTimeRecords = [];
      }
    } else if (event.data) {
      if (event.data.Billed == true) {
        this.selectedTimeRecords = this.selectedTimeRecords.filter(
          (val, i) => val["Billed"] != true
        );
      }
    }
    this.footGroupTotalsForRetainer();
    const headerIvoiceAmount = numeral(
      this.timeSlipsInvoiceForm.controls["InvoiceAmount"].value
    ).value();
    /*
      handle calculations by row for billed = true and checked or unchecked row items
      Need to get Footer Total for Standard Amount then do calculations by row.
    */
    for (
      let timeRecordIndex = 0;
      timeRecordIndex < this.timeSlipsDataSource.length;
      timeRecordIndex++
    ) {
      const rowData = this.timeSlipsDataSource[timeRecordIndex];
      const BillablePct = numeral(rowData.StandardAmount).value() / this.totalStandarAmount;
      if (rowData.Billed == true) {
        rowData.WriteUpDown = (headerIvoiceAmount - this.totalStandarAmount) * BillablePct;
        rowData.BilledAmount = numeral(rowData.StandardAmount).value() + rowData.WriteUpDown;
      } else {
        rowData.WriteUpDown = null;
        rowData.BilledAmount = null;
      }
      if (this.selectedTimeRecords.length > 0) {
        const selectedIndex = this.selectedTimeRecords.indexOf(rowData);
        if (selectedIndex > -1) {
          rowData.WriteUpDown = (headerIvoiceAmount - this.totalStandarAmount) * BillablePct;
          rowData.BilledAmount = numeral(rowData.StandardAmount).value() + rowData.WriteUpDown;
        }
      }
    }
    this.footGroupTotalsForRetainer();
  }

  footGroupTotalsForRetainer() {
    // Only for billed = true
    const billedTimeSlipRecords = this.timeSlipsDataSource.filter((val, i) => val["Billed"] == true);
    this.TotalTime = billedTimeSlipRecords.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.TotalTime).value();
    }, 0);
    this.totalStandarAmount = billedTimeSlipRecords.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.StandardAmount).value();
    }, 0);
    this.totalWriteUpDown = billedTimeSlipRecords.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.WriteUpDown).value();
    }, 0);
    this.totalBilledAmount = billedTimeSlipRecords.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.BilledAmount).value();
    }, 0);

    if (this.selectedTimeRecords.length > 0) {
      this.TotalTime += this.selectedTimeRecords.reduce(function (a, b) {
        return numeral(a).value() + +numeral(b.TotalTime).value();
      }, 0);
      this.totalStandarAmount += this.selectedTimeRecords.reduce(function (a, b) {
        return numeral(a).value() + +numeral(b.StandardAmount).value();
      }, 0);
      this.totalWriteUpDown += this.selectedTimeRecords.reduce(function (a, b) {
        return numeral(a).value() + +numeral(b.WriteUpDown).value();
      }, 0);
      this.totalBilledAmount += this.selectedTimeRecords.reduce(function (a, b) {
        return numeral(a).value() + +numeral(b.BilledAmount).value();
      }, 0);
    }

    this.TotalTime = numeral(this.TotalTime).format("0.00");
  }

  /*
    Process Time Records - There are 2 conditions
    There are time records in the data table
    1. Time records are selected and need to be processed (Update Time Records)
    There are time records and NONE are selected OR There are NO time records in the Billable Time table.
    2. Time records are NOT selected (At least 1 time record needs to be created based on the Service Line Item.  Need to Insert new time record.)
  */
  processTimeRecords() {
    const formObj = this.timeSlipsInvoiceForm.value;
    let _TotalStaffCost = 0;
    const timeSlipsDS = [];
    const parent = this;

    for (
      let timeRecordIndex = 0;
      timeRecordIndex < this.timeSlipsDataSource.length;
      timeRecordIndex++
    ) {
      const rowData = this.timeSlipsDataSource[timeRecordIndex];
      const selectedParentItem = this.selectedTimeRecords.filter(
        (val) => val["SlipMasterID"] == rowData.SlipMasterID
      );
      rowData["StaffCost"] = rowData["StaffCost"] ? rowData["StaffCost"] : 0;
      rowData.WriteUpDown = rowData.WriteUpDown ? rowData.WriteUpDown : 0;

      if (rowData.Billed == true || selectedParentItem.length > 0) {
        _TotalStaffCost += numeral(rowData["StaffCost"]).value();
        rowData["BillingHeaderID"] = formObj.BillingHeaderID;
        rowData["Billed"] = true;
        rowData["InvoiceDate"] = formObj.InvoiceDate;
        rowData["InvoiceNumber"] = formObj.InvoiceNumber;
        timeSlipsDS.push(rowData);
      }
    }
    parent.mangoAPISrvc.showLoader(true);
    // update Time Records from Billable Time table - one shot in DB
    this.updateTimeSlipsRecords(timeSlipsDS).subscribe(
      (data) => {
        parent.mangoAPISrvc
          .getBillingHeaderRecordById(formObj.BillingHeaderID)
          .subscribe((billingHeader) => {
            billingHeader["TotalStaffCost"] = _TotalStaffCost;
            billingHeader["TotalWUWD"] = numeral(parent.totalWriteUpDown).value();
            parent.mangoAPISrvc
              .updateBillingHeader(billingHeader, billingHeader["BillingHeaderID"])
              .subscribe((rateData) => {
                setTimeout(() => {
                  parent.mangoAPISrvc.showLoader(false);
                  parent.clearData();
                }, 500);
                parent.mangoAPISrvc.notify(
                  "success",
                  this.translate.instant("Success_notify"),
                  this.translate.instant("Time_Records_successfully_applied_to_Invoice")
                );
              });
          });
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify(
          "error",
          this.translate.instant("Error"),
          AppConstants.updateErrorMsg
        );
      }
    );
  }

  updateTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject("token"));

    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.put(
        `${environment.API_URL}/company/timeSheet/${selectedItem["SlipMasterID"]}`,
        selectedItem,
        { headers: headers }
      );
      observableBatch.push(urlOne);
    });

    return forkJoin(observableBatch);
  }

  /*
    initialize the form
  */
  initializeForm() {
    this.timeSlipsInvoiceForm = this._fb.group({
      BillingHeaderID: [""],
      ClientID: ["", [<any>Validators.required]],
      CompanyID: [""],
      InvoiceNumber: [""],
      InvoiceDate: ["", [<any>Validators.required]],
      InvoiceAmount: ["", [<any>Validators.required]],
      DescriptionShort: [""],
    });

    this.timeSlipsInvoiceForm.valueChanges.subscribe((data) => {
      this.validateForm();
    });
  }

  /*
    Verifing the form
  */
  validateForm() {
    let isInValidData = false;
    Object.keys(this.timeSlipsInvoiceForm.controls).forEach((key) => {
      if (this.timeSlipsInvoiceForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }
  /*
    updating US Money format
  */
  changeUSMoney(evt: any) {
    const enteredValue = evt["value"].trim();
    const myNumeral = numeral(enteredValue).value();
    if (myNumeral === null) {
      evt.setValue("$0.00");
    } else {
      evt.setValue("$" + numeral(myNumeral).format("0,0.00"));
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  onFilterTimeRecords(obj, dt) {
    const filteredKeys = obj.filteredValue.map((timeSlip) => timeSlip["SlipMasterID"]);
    this.timeSlipsDataSource.forEach((timeSlip) => {
      if (timeSlip["Billed"] && !filteredKeys.includes(timeSlip["SlipMasterID"])) {
        obj.filteredValue.push(timeSlip);
      }
    });
    obj.filteredValue.sort(function (x, y) {
      return (
        y["Billed"] - x["Billed"] || new Date(x["Ddate"]).getTime() - new Date(y["Ddate"]).getTime()
      );
    });
    this.filteredItemsSize = obj.filteredValue.length;
    dt.filteredValue = obj.filteredValue;
  }

  globalSearch(event) {
    this.dt.filterGlobal(event.target.value, "contains");
  }
}
