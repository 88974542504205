<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">
  <div class="login-content">
    <div class="login-panel p-pt-0">
      <div class="login-panel-content p-text-left">
        <div class="logo-height">
          <img src="assets/img/mango-3.svg" alt="Welcome to Mango Practice Management" />
        </div>
        <div class="p-text-center">
          Start My 10-Day Free Trial
        </div>
        
        <h3>Create Account</h3>

        <div class="p-mt-1">
          <form [formGroup]="signupForm" (ngSubmit)="signUp(signupForm)">
            <div class="p-fluid">
              <!-- Company Name -->
              <div class="p-field">
                <input type="text" [attr.name]="'dynamic_field_' + dynamicFieldName" formControlName="honeypot"
                  style="display:none" tabindex="-1" autocomplete="off">

                <label for="username" class="label-text required-field">
                  Company Name
                </label>
                <input pInputText type="text" autocomplete="off" formControlName="companyName"
                  placeholder="Company Name" name="companyName">
                <div *ngIf="signupForm.controls['companyName'].touched">
                  <small *ngIf="signupForm.controls['companyName'].hasError('required')" class="p-invalid p-mt-1">
                    Company Name is required
                  </small>
                </div>
              </div>

              <!--
                Note: Simply setting "autocomplete='off'"" for email
                and password input fields won't work.
              -->

              <div class="p-formgroup-inline">
                <!-- First Name -->
                <div class="p-field p-col-6 p-pl-0">
                  <label for="firstname" class="label-text required-field">
                    First Name
                  </label>
                  <input pInputText type="text" autocomplete="nope" formControlName="firstName" class="firstName"
                    placeholder="First Name" name="firstName">
                  <div *ngIf="signupForm.controls['firstName'].touched">
                    <small *ngIf="signupForm.controls['firstName'].hasError('required')" class="p-invalid p-mt-1">
                      First Name is required
                    </small>
                  </div>
                </div>

                <!-- Last Name -->
                <div class="p-field p-col-6 p-pr-0">
                  <label for="lastname" class="label-text required-field">
                    Last Name
                  </label>
                  <input pInputText type="text" autocomplete="nope" formControlName="lastName" class="lastName"
                    placeholder="Last Name" name="lastName">
                  <div *ngIf="signupForm.controls['lastName'].touched">
                    <small *ngIf="signupForm.controls['lastName'].hasError('required')" class="p-invalid p-mt-1">
                      Last Name is required
                    </small>
                  </div>
                </div>
              </div>

              <div class="p-formgroup-inline">
                <!-- Email -->
                <div class="p-field p-col-6 p-pl-0">
                  <label for="email" class="label-text required-field">
                    Email Address
                    <i *ngIf="emailExists" class="fas fa-exclamation-circle ic-red"
                      pTooltip="This email address is no longer available!" tooltipPosition="top"
                      tooltipStyleClass="tooltip-no-wrap">
                    </i>
                  </label>
                  <input pInputText type="new-email" autocomplete="new-password" formControlName="email"
                    placeholder="Email Address" name="email"
                    (blur)="changeText($event,signupForm.get('email'),'lower')" />
                  <div *ngIf="signupForm.controls['email'].touched">
                    <small *ngIf="signupForm.controls['email'].hasError('pattern')" class="p-invalid p-mt-1">
                      Email is invalid
                    </small>
                    <small *ngIf="signupForm.controls['email'].hasError('required')" class="p-invalid p-mt-1">
                      Email is required
                    </small>
                  </div>
                </div>

                <div class="p-field p-col-6 p-pr-0">
                  <label class="label-text required-field">Phone Number</label>
                  <p-inputMask autocomplete="off" formControlName="telephone" mask="(999) 999-9999" name="telephone"
                    class="p-text-bold" placeholder="(XXX) XXX-XXXX">
                  </p-inputMask>
                  <div *ngIf="signupForm.controls['telephone'].touched">
                    <small *ngIf="signupForm.controls['telephone'].hasError('required')" class="p-invalid p-mt-1">
                      Phone Number is required
                    </small>
                    <small *ngIf="signupForm.controls['telephone'].hasError('minLength')" class="p-invalid p-mt-1">
                      Phone Number's length does not meet minimum length
                    </small>
                    <small *ngIf="signupForm.controls['telephone'].hasError('maxLength')" class="p-invalid p-mt-1">
                      Phone Number's length exceeds maximum length
                    </small>
                  </div>
                </div>
              </div>

              <div class="p-formgroup-inline">
                <!-- Password -->
                <div class="p-field p-col-6 p-pl-0">
                  <label for="password" class="label-text required-field">
                    Password
                  </label>
                  <input autocomplete="new-password" pInputText type="text" onfocus="this.type='password'"
                    formControlName="password" placeholder="Password" name="password">
                  <div *ngIf="signupForm.controls['password'].touched">
                    <small *ngIf="signupForm.controls['password'].hasError('required')" class="p-invalid p-mt-1">
                      Password is required
                    </small>
                  </div>
                </div>

                <!-- Confirm Password -->
                <div class="p-field p-col-6 p-pr-0">
                  <label for="confirmpassword" class="label-text required-field">
                    Confirm Password
                  </label>
                  <input autocomplete="new-password" pInputText type="text" onfocus="this.type='password'"
                    placeholder="Confirm Password" name="confirmpassword" formControlName="confirmPassword">
                  <div *ngIf="signupForm.controls['confirmPassword'].touched">
                    <small *ngIf="signupForm.hasError('passwordNotEqual')" class="p-invalid p-mt-1">
                      Password do not match
                    </small>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-px-0">
                <label class="label-text required-field">How did you hear about us?</label>
                <textarea pInputTextarea style="resize: vertical;" appendTo="body"
                  placeholder="How did you hear about us" (focus)="$event.target.select();" [rows]="2" class="p-pb-3"
                  formControlName="howDidYouHear"></textarea>
                <div *ngIf="signupForm.controls['howDidYouHear'].touched">
                  <small *ngIf="signupForm.controls['howDidYouHear'].hasError('required')" class="p-invalid p-mt-1">
                    This field is required
                  </small>
                  <small *ngIf="signupForm.controls['howDidYouHear'].hasError('maxLength')" class="p-invalid p-mt-1">
                    The length exceeds maximum length
                  </small>
                </div>
              </div>

              <div class="p-col-12">
                <p-checkbox name="terms" #tosagree="ngModel" [(ngModel)]="agreeTerms"
                  [ngModelOptions]="{standalone: true}" label="I agree to the Terms of Service" binary="true">
                </p-checkbox>
              </div>

              <div id="salesforce-form-container" style="
                  display: none !important;
                  width: 0px !important;
                  height: 0px !important;
                  opacity: 0 !important;
                ">
              </div>
              <div class="p-my-2">
                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
                  [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
              </div>
              <button class="p-mt-2" pButton type="submit" pRipple label="Let's Get Started" [disabled]="!isFormValid">
              </button>

              <h6 class="p-text-center p-mb-0">
                Questions? Give us a call at 877-520-1525, ext 204
              </h6>
              <h6 class="p-text-center p-m-0">
                <a href="https://www.mangopractice.com/terms-conditions/" target="_blank" class="ic-size-12">
                  Terms of Service
                </a>
              </h6>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="desert">
    </div>
  </div>
</div>

<p-dialog header="Thanks for giving Mango Practice Management a test drive!" [(visible)]="display" [modal]="true"
  [closable]="true" [style]="{width: '50vw'}" [maximizable]="false" [draggable]="false" [resizable]="false"
  (onHide)="redirectLogin();">
  <p>
    &nbsp;
  </p>
  <p style="font-size: 22px;color:#4f4f4f">
    Awesome <strong>{{sendInBlueObj['attributes']['FIRSTNAME']}}</strong>!
  </p>
  <p style="font-size: 18px;color:#4f4f4f">
    We just sent you an email at <strong>{{sendInBlueObj['email']}}</strong>.
    Click the verify link in the email to login and get started!
  </p>
  <p style="font-size: 18px;color:#4f4f4f">
    Be sure to check your spam box, if by-chance the email was filtered.
  </p>
  <p style="font-size: 18px;color:#4f4f4f">
    You can also directly email
    <a href="mailto:support@mangopractice.com" style="color: #1c0df5;">
      support&#64;mangopractice.com
    </a>
    if you require assistance. We are happy to help.
  </p>
  <p>&nbsp;</p>
  <p-footer>
    <button type="button" style="font-size: 16px;" pButton icon="pi pi-check" (click)="resendEMail()"
      label="Please Resend Email">
    </button>
  </p-footer>
</p-dialog>