import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectManagementComponent } from './project-management.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { DuplicateProjectsComponent } from './duplicate-projects/duplicate-projects.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { MasterTemplateLibraryComponent } from './master-template-library/master-template-library.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { RuleGeneratorComponent } from './rule-generator/rule-generator.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthGuard } from '@app/_services';
import { CanDeactivateForm } from './can-deactivate';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: ProjectManagementComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component:MainDashboardComponent, canActivate: [AuthGuard], data: { 'featID': 21 } },
      { path: AppConstants.dashboard, component: MainDashboardComponent, canActivate: [AuthGuard], data: { 'featID': 21 } },
      {
        path: AppConstants.projectList,
        loadChildren: () =>
          import("./project-list/project-list.module").then(
            (mod) => mod.ProjectListModule
          ),
        canActivate: [AuthGuard],
      },
      { path: AppConstants.projectListWithID, component: ProjectListComponent, canActivate: [AuthGuard], data: { 'featID': 21 } },
      { path: AppConstants.settings, component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 35 } },
      { path: AppConstants.createTemplate, component: CreateTemplateComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 21 } },
      { path: AppConstants.createDuplicateWithTemplateID, component: DuplicateProjectsComponent, canActivate: [AuthGuard], data: { 'featID': 21 } },
      { path: AppConstants.createTemplateWithSettingsID, component: CreateTemplateComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 21 } },
      { path: AppConstants.projectDetails, component: ProjectDetailsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 21 } },
      { path: AppConstants.projectDetailsWithProjectHeaderID, component: ProjectDetailsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 21 } },
      { path: AppConstants.clientDetailsWithProjectHeaderID, component: ClientDetailsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 21 } },
      { path: AppConstants.masterTemplateLibrary, component: MasterTemplateLibraryComponent, canActivate: [AuthGuard], data: { 'featID': 21 } },
      { path: AppConstants.clientHistory, component: ClientHistoryComponent, canActivate: [AuthGuard], data: { 'featID': 21 } },
      { path: AppConstants.ruleGenerator, component: RuleGeneratorComponent, canActivate: [AuthGuard], data: { 'featID': 21 } }
    ],
    data: { 'roles': ['manager', "staff","Senior Manager"], 'plans': ["free", 'enterprise', 'pro', 'pm'] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class ProjectManagementRoutingModule { }
