import { Component, EventEmitter, AfterViewInit, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SharedComponentsService } from '../shared-components-service'
import Swal from 'sweetalert2';


@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html'
})
export class ShortcutsComponent implements OnInit, AfterViewInit {
  showShotcutsDialog: boolean = false;
  @Output() closeShortcutsModal = new EventEmitter<any>();
  public shortcutMainForm: UntypedFormGroup;
  busy: Promise<any>;
  cols: any[];
  code: any = {};
  codeList: any = [];
  tempcodeList: any = [];
  codeInactive = false;
  displayAddShortcutModal: boolean = false;
  @ViewChild('searchValue') searchValue;
  isEditCode;
  filteredItemsSize: number = -1;
  intervalid: any;

  constructor(private mangoAPISrvc: MangoApiService, private sharedSrvc: SharedComponentsService, private encrDecSrvc: EncrDecrService, private translate: TranslateService) {
    this.mangoAPISrvc.applyLanguage();  
    this.intervalid = setInterval(() => {
      this.fetchShortcuts();
    }, 50);
  }

  ngOnInit(): void {
    this.codeList = this.encrDecSrvc.getObject(AppConstants.shotHands);
  }
  
  ngAfterViewInit() {
    // this.searchValue.nativeElement.focus();
  }

  openAddCode(index?: any, id?: any) {
    this.code = { "ShortCutCode": "" };
    this.isEditCode = false;
    this.displayAddShortcutModal = true;
  }

  fetchShortcuts() {
    this.codeList = this.encrDecSrvc.getObject(AppConstants.shotHands);
    if (this.codeList.length > 0) {
      this.codeList = this.encrDecSrvc.getObject(AppConstants.shotHands);
      clearInterval(this.intervalid);
    } else {
     
    }
  }

  openEditCode(obj) {
    this.code = Object.assign({}, obj);
    this.isEditCode = true;
    this.displayAddShortcutModal = true;
  }

  closeCode() {
    this.displayAddShortcutModal = false;
    this.sharedSrvc.sharedCompVisibility.shortcuts = false;
  }

  checkstartingLetter(value) {
    const firstChar = value.substring(0, 1);
    if (firstChar != '/') {
      this.code['ShortCutCode'] = '/' + value;
    }
  }

  getCodes() {
    const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    if (!companyId) {
      return false;
    }
    this.mangoAPISrvc.getCodes().subscribe((data: any) => {
      this.encrDecSrvc.addObject(AppConstants.shotHands, data);
      this.codeList = data;

      for (let i = 0; i < data.length; i++) {
        this.codeList[i].index = i;

        if (this.codeList[i].Inactive == null) {
          this.codeList[i].Inactive = false;
        }
      }
      this.tempcodeList = this.codeList;
      this.codeInactive = !this.codeInactive;
    }, error => {
      this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg)
    });
  }

  deleteCode(id) {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.deleteCode(id).subscribe((data: any) => {
          this.getCodes();
          Swal.fire({
            icon: 'success',
            title: `${this.translate.instant('Deleted')}!`,
            text: data.message,
            showConfirmButton: false,
            timer: 1500
          });
        });
      }
    })
  }

  saveCode(isSave) {
    if (isSave) {
      this.mangoAPISrvc.saveCode(this.code).subscribe((data) => {
        this.getCodes();
      })
    } else {
      this.mangoAPISrvc.updateCode(this.code.ShortCutsID, this.code).subscribe((data) => {
        this.getCodes();
      });
    }

    this.displayAddShortcutModal = false;
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    this.filteredItemsSize = -1;
  }

  openShortCutsDialog(data) {
    setTimeout(() => {
      this.showShotcutsDialog = true;
      const interval = setInterval(()=>{
        if(!this.searchValue) return;
        clearInterval(interval);
        this.searchValue.nativeElement.focus();
      })
    }, 500);
  }

  closeShortCutsDialog() {
    this.showShotcutsDialog = false;
    this.searchValue.nativeElement.value = "";
    this.closeShortcutsModal.emit({ "time": new Date() });
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }
}
