<div class="
    clientother
  ">
  <form [formGroup]="dashboardSettingsForm" novalidate>
    <span class="
        p-mr-3

        clientform-btn-top

        pull-right
      ">
      <button pButton pRipple class="
          p-mr-1
          p-button-sm
        " label="{{ 'save_button' | translate }}" icon="fas fa-check" [disabled]="formDisabled" (click)="
          saveClientSettings( );
        ">
      </button>
    </span>

    <div class="
        layout-content

        p-pt-0
      ">
      <div class="
          vertical-container

          p-grid
          p-ai-stretch
          p-mx-2
        ">
        <div class="
            p-col-4
          ">
          <div class="
              card

              p-mt-2
            " style="
              min-height: 100%;
            ">
            <h4 class="
                label-text
              ">
              {{ 'Microsoft-Integration-Settings' | translate }}
            </h4>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="IsMicrosoftLogin" binary="true"
                label="{{ 'settings.enable-microsoft-login' | translate }}">
              </p-checkbox>

              <i class="
                  fa-question-circle
                  fa

                  ic-blue
                  ic-size-15

                  p-ml-2

                  cursor-hand
                " pTooltip="{{ 'settings.ms-integration-note' | translate }}" tooltipStyleClass="tooltip-width-800"
                tooltipPosition="top">
              </i>
            </div>

            <div *ngIf="
                dashboardSettingsForm.value?.IsMicrosoftLogin
              " class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="isMSRequired" binary="true"
                label="{{ 'settings.require-microsoft-login' | translate }}">
              </p-checkbox>
            </div>
          </div>
        </div>

        <div class="
            p-col-4
          ">
          <div class="
              card

              p-mt-2
            " style="
              min-height: 100%;
            ">
            <h4 class="
                label-text
              ">
              {{ 'Two-Factor-Authentication-Settings' | translate }}
            </h4>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="IsEnableTFA" binary="true" label="{{ 'settings.enable-tfa' | translate }}">
              </p-checkbox>

              <i class="
                  fa-question-circle
                  fa

                  ic-blue
                  ic-size-15

                  cursor-hand

                  p-ml-2
                " pTooltip="{{ 'settings.two-factor-note' | translate }}" tooltipStyleClass="tooltip-width-800"
                tooltipPosition="top">
              </i>
            </div>

            <div *ngIf="
                dashboardSettingsForm.value?.IsEnableTFA
              " class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="isTFARequired" binary="true"
                label="{{ 'settings.require-2fa' | translate }}">
              </p-checkbox>
            </div>
          </div>
        </div>

        <div class="
            p-col-4
          ">
          <div class="
              card

              p-mt-2
            " style="
              min-height: 100%;
            ">
            <h4 class="
                label-text
              ">
              {{ 'dm.Email_Templates' | translate }}
            </h4>

            <div class="
                p-grid
                p-fluid
              ">
              <div class="
                  p-col-8
                ">
                <p-dropdown placeholder="{{ 'dms.Select_Email_Template' | translate }}" [options]="emailTemplateOptions"
                  [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedTemplate" (onChange)="
                    selectTemplate( $event );
                  ">
                </p-dropdown>
              </div>

              <div class="
                  p-col-4
                ">
                <i class="
                    fa-question-circle
                    fa

                    ic-blue
                    ic-size-15

                    cursor-hand

                    p-mt-2
                  " pTooltip="{{ 'Email-Template-Tooltip' | translate }}" tooltipPosition="top"
                  tooltipStyleClass="tooltip-width-800">
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="
          nylasIntegrationFeatureStatus === true
        " class="
          vertical-container

          p-grid
          p-ai-stretch
          p-mx-2
        ">
        <div class="
            p-col-4
          ">
          <div class="
              card

              p-mt-2
            " style="
              min-height: 100%;
            ">
            <h4 class="
                label-text
              ">
              {{ 'General_Setting.Nylas_Integration_Support_Setting' | translate }}
            </h4>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="nylasIntegrationSupportStatus" binary="true"
                label="{{ 'General_Setting.Enable_Nylas_Integration_For_All_Staff' | translate }}">
              </p-checkbox>

              <i class="
                  fa-question-circle
                  fa

                  ic-blue
                  ic-size-15

                  p-ml-2

                  cursor-hand
                " tooltipPosition="top"
                pTooltip="{{ 'General_Setting.Nylas_Integration_Support_Tooltip_Note' | translate }}"
                tooltipStyleClass="tooltip-width-800">
              </i>
            </div>
            <div *ngIf="
                  (
                        dashboardSettingsForm.value?.nylasIntegrationSupportStatus
                    === true
                  )
              " class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="nylasCalendarIntegrationSupportStatus" binary="true"
                label="{{ 'General_Setting.Enable_Nylas_Calendar_Integration_For_All_Staff' | translate }}">
              </p-checkbox>
            </div>
            <div *ngIf="
                  (
                        dashboardSettingsForm.value?.nylasIntegrationSupportStatus
                    === true
                  )
              " class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="nylasMailIntegrationSupportStatus" binary="true"
                label="{{ 'General_Setting.Enable_Nylas_Mail_Integration_For_All_Staff' | translate }}">
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="
          vertical-container

          p-grid
          p-ai-stretch
          p-mx-2
        ">
        <div class="
            p-col-4
          ">
          <div class="
              card
              p-mt-2
            " style="
              min-height: 100%;
            ">
            <h4 class="
                label-text
              ">
              {{ 'Dashboard-Settings' | translate }}
            </h4>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="IsHideAgenda" binary="true" label="{{ 'settings.Hide-Agenda' | translate }}">
              </p-checkbox>
            </div>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="IsHideLeaderBoard" binary="true"
                label="{{ 'settings.Hide-Leaderboard' | translate }}">
              </p-checkbox>
            </div>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="isShowDashboardAdmin" binary="true"
                label="{{ 'settings.admin-show-leaderboard' | translate }}">
              </p-checkbox>
            </div>

            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="IsHideToDo" binary="true"
                label="{{ 'settings.Hide-To-Do-List' | translate }}">
              </p-checkbox>
            </div>
          </div>
        </div>

        <div class="
            p-col-4
          ">
          <div class="
              card
              p-mt-2
            " style="min-height: 100%;">
            <h4 class="
                label-text
              ">
              {{ 'System-Lockdown' | translate }}
            </h4>
            <div class="
                p-field-checkbox
              ">
              <p-checkbox formControlName="isEnableSystemLocking" binary="true"
                label="{{ 'settings.enable-system-locking' | translate }}">
              </p-checkbox>
            </div>
            <div class="
                p-mb-2
              ">
              <label class="
                  p-mr-2
                ">
                {{ 'warn-no-of-days' | translate }}:
              </label>
              <p-dropdown name="warnNumberOfDays" formControlName="warnNumberOfDays"
                placeholder="{{ 'warn-no-warning' | translate }}" [options]="warnNumOfDaysOptions"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!dashboardSettingsForm.value?.isEnableSystemLocking">
              </p-dropdown>
              <i class="
                  p-ml-2

                  fa-question-circle
                  fa

                  ic-blue
                  ic-size-15

                  cursor-hand
                " pTooltip="{{ 'warn-no-of-days-tooltip' | translate }}" tooltipPosition="top"
                tooltipStyleClass="tooltip-width-800">
              </i>
            </div>
            <div class="
                p-mb-2
              ">
              <label class="
                  p-mr-2

                  required-field
                  label-text
                ">
                {{ 'lock-day' | translate }}:
              </label>
              <p-dropdown name="effectiveLockDay" formControlName="effectiveLockDay"
                placeholder="{{ 'select' | translate }} {{ 'lock-day-short' | translate }}"
                [options]="effectiveLockDayOptions" [ngModelOptions]="{standalone: true}"
                [disabled]="!dashboardSettingsForm.value?.isEnableSystemLocking">
              </p-dropdown>
              <i class="
                  p-ml-2

                  fa-question-circle
                  fa

                  ic-blue
                  ic-size-15

                  cursor-hand
                " pTooltip="{{ 'lock-day-tooltip' | translate }}" tooltipPosition="top"
                tooltipStyleClass="tooltip-width-800">
              </i>
            </div>
            <div class="
                p-mb-2
              ">
              <label class="
                  required-field
                  label-text

                  p-mr-2
                ">
                {{ 'lock-day-months-previous' | translate }}:
              </label>
              <p-dropdown name="monthsPreviousToLock" formControlName="monthsPreviousToLock"
                placeholder="{{ 'select' | translate }} {{ 'Month' | translate }}" [options]="previousMonthsOptions"
                [disabled]="!dashboardSettingsForm.value?.isEnableSystemLocking" [ngModelOptions]="{standalone: true}">
              </p-dropdown>
              <i class="
                  p-ml-2

                  fa-question-circle
                  fa

                  ic-blue
                  ic-size-15

                  cursor-hand
                " pTooltip="{{ 'lock-day-months-previous-tooltip' | translate }}" tooltipPosition="top"
                tooltipStyleClass="tooltip-width-800">
              </i>
            </div>
          </div>
        </div>
        <div *ngIf="
            (
                  subscriptionLevel === 'ENTERPRISE'
              ||
                  subscriptionLevel === 'FREE'
            )
          " class="
            p-col-4
          ">
          <div class="
              card

              p-mt-2
            " style="
              min-height: 100%;
            ">
            <h4 class="
                label-text
              ">
              Budget Settings
            </h4>

            <div class="
                p-d-flex
                p-jc-start

                p-ml-2
              ">
              <p-checkbox class="
                  label-text
                " name="isEnableRollOver" formControlName="isEnableRollOver"
                label="Enable Automatic Rollover to Next Year" [binary]="true">
              </p-checkbox>
            </div>

            <div>
              <div class="
                  p-col-12
                  p-d-flex
                  p-jc-between
                ">
                <div class="
                    p-col-12
                    p-pl-0
                  ">
                  <p-radioButton name="RollOverOption" label="Increase by fixed percentage"
                    formControlName="RollOverOption" value="percentage"
                    [disabled]="!dashboardSettingsForm.value.isEnableRollOver">
                  </p-radioButton>
                </div>
              </div>

              <div class="
                  p-col-12
                  p-pl-4
                  p-pr-0
                  p-d-flex
                  p-jc-between
                ">
                <div class="
                    p-col-6
                  ">
                  <p-checkbox class="
                      label-text
                    " name="isUpdateRevenue" formControlName="isUpdateRevenue" label="Revenue Amount" [binary]="true"
                    [disabled]="
                          dashboardSettingsForm.value.RollOverOption !== 'percentage'
                      ||
                          !dashboardSettingsForm.value.isEnableRollOver
                    ">
                  </p-checkbox>
                </div>

                <div class="
                    p-col-6
                    p-pl-0
                    p-pr-2
                  ">
                  <span class="
                      p-inputgroup
                    ">
                    <p-inputNumber name="percentage" formControlName="percentage" autocomplete="off"
                      inputStyleClass="p-text-right" locale="en-US" maxlength="12" mode="decimal"
                      [minFractionDigits]="2" [disabled]="
                            !dashboardSettingsForm.value.isUpdateRevenue
                        ||
                            !dashboardSettingsForm.value.isEnableRollOver
                      " (focus)="
                        $event.target.select( );
                      ">
                    </p-inputNumber>

                    <span class="
                        p-inputgroup-addon
                      ">
                      <i class="
                          fa-percent
                          fal
                        ">
                      </i>
                    </span>
                  </span>
                </div>
              </div>

              <div class="
                  p-col-12
                  p-pr-0
                  p-pt-0
                  p-d-flex
                  p-pl-4
                  p-jc-between
                ">
                <div class="
                    p-col-6
                  ">
                  <p-checkbox class="
                      label-text
                    " name="isUpdateTime" formControlName="isUpdateTime" label="Time Amount" [binary]="true"
                    [disabled]="
                          dashboardSettingsForm.value.RollOverOption !== 'percentage'
                      ||
                          !dashboardSettingsForm.value.isEnableRollOver
                    ">
                  </p-checkbox>
                </div>

                <div class="
                    p-col-6
                    p-pl-0
                    p-pr-2
                  ">
                  <span class="
                      p-inputgroup
                    ">
                    <p-inputNumber name="timePercentage" formControlName="timePercentage" autocomplete="off"
                      inputStyleClass="p-text-right" locale="en-US" maxlength="12" mode="decimal"
                      [minFractionDigits]="2" [disabled]="
                            !dashboardSettingsForm.value.isUpdateTime
                        ||
                            !dashboardSettingsForm.value.isEnableRollOver
                      " (focus)="
                        $event.target.select( );
                      ">
                    </p-inputNumber>

                    <span class="
                        p-inputgroup-addon
                      ">
                      <i class="
                          fa-percent
                          fal
                        ">
                      </i>
                    </span>
                  </span>
                </div>
              </div>

              <div class="
                  p-col-12
                ">
                <p-radioButton name="RollOverOption" label="Create from Existing Budget"
                  formControlName="RollOverOption" value="existing" [disabled]="
                    !dashboardSettingsForm.value.isEnableRollOver
                  ">
                </p-radioButton>
              </div>
              <div class="
                  p-col-12
                ">
                <p-radioButton name="RollOverOption" label="Create based on Actual Amounts"
                  formControlName="RollOverOption" value="actual" [disabled]="
                    !dashboardSettingsForm.value.isEnableRollOver
                  ">
                </p-radioButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<p-sidebar *ngIf="
    selectedEmailTemplate
  " position="right" [(visible)]="showTemplateEditor" [style]="
    {
      'min-width':'40%',
      'min-height':'100%',
      'background':'#8e8f92',
      'overflow': 'auto'
    }
  " (onHide)="
    onTemplateEditorClose( );
  ">
  <div class="
      card
    " style="
      height: calc(100vh - 40px);
    ">
    <div class="
        p-col-12
      ">
      <label for="Email Subject" class="
          ic-red
          p-mb-2
        ">
        {{ 'dm.Email_Subject' | translate }}
      </label>
      <div class="
          p-mt-2
        ">
        <input pInputText class="
            inputText

            width-100p
          " id="itememailtemplateName" name="itememailtemplateName" type="text" autocomplete="off"
          #itemtemplateName="ngModel" [(ngModel)]="selectedEmailTemplate['EmailSubject']" (ngModelChange)="
            onRecordChange( );
          " (focus)="
            $event.target.select( );
          ">
      </div>
    </div>
    <div class="
        p-my-2
        p-col-12
      ">
      <ejs-richtexteditor #customRTE id='customRTE' [insertImageSettings]='imageSettings' [toolbarSettings]='tools'
        [(ngModel)]="selectedEmailTemplate['htmlBody']" (ngModelChange)="
          onRecordChange( );
        ">
      </ejs-richtexteditor>
      <ejs-dialog #Dialog id="rteDialog" [buttons]='dlgButtons' [header]="'Field Names'" [visible]='false'
        [showCloseIcon]='false' [target]='target' [isModal]='true' (overlayClick)="
          dialogOverlay( );
        " (created)="
          dialogCreate( );
        ">
        <ng-template #content>
          <div id="rteSpecial_char">
            <div class="
                char_block
              " pTooltip="{{ 'client.name' | translate }}" tooltipPosition="top">
              %ClientName%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'client.contact_name' | translate }}" tooltipPosition="top">
              %ContactName%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'date' | translate }}" tooltipPosition="top">
              %Date%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'dm.Your_Firm_Name' | translate }}" tooltipPosition="top">
              %YourFirmName%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'invoice_number' | translate }}" tooltipPosition="top">
              %InvoiceNumber%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'Accounts.invoice-balance' | translate }}" tooltipPosition="top">
              %Balance%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'amount' | translate }}" tooltipPosition="top">
              %Amount%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'billing-invoicing.invoice-summary' | translate }}" tooltipPosition="top">
              %Summary%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'user.mobile_phone' | translate }}" tooltipPosition="top">
              %Mobile%
            </div>

            <div class="
                char_block
              " pTooltip="{{ 'email' | translate }}" tooltipPosition="top">
              %Email%
            </div>
          </div>
        </ng-template>
      </ejs-dialog>
    </div>

    <div style="
        position: absolute;

        bottom: 0px;
      ">
      <button pButton class="
          p-mb-3
          p-ml-2

          blue-btn
        " type="button" label="{{ 'Save-Template' | translate }}" [disabled]="!isChanged && !usingDefaultTemplate"
        (click)="
          saveTemplate( );
        ">
      </button>

      <button *ngIf="
          !usingDefaultTemplate
        " pButton class="
          p-mb-3
          p-ml-2

          blue-btn
        " type="button" label="{{ 'Use-Default' | translate }}" (click)="
          resetTemplate( );
        ">
      </button>
    </div>
  </div>
</p-sidebar>