import { Component, OnInit } from '@angular/core';
import { MangoApiService, EncrDecrService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent implements OnInit {

  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService ) { }

  ngOnInit(): void {
    this.encrDecSrvc.clearStorage();
    // this.mangoAPISrvc.showLoader(false);
    // this.encrDecSrvc.addObject(AppConstants.isLoggingUser, "false");
    // this.mangoAPISrvc.notifyLogging(false);
  }

}
