import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "@app/_helpers/api-constants";
import { MangoApiService, EncrDecrService, mangoUtils } from "@app/_services";
import { RProjectDetailsService } from "@app/modules/project-management/project-details/rproject-details.service";
import { TranslateService } from "@ngx-translate/core";
import { Table } from "primeng/table";

@Component({
  selector: "app-create-project-from-template",
  templateUrl: "./create-project-from-template.component.html",
})
export class CreateProjectFromTemplateComponent implements OnInit {
  @Input() clientID;
  @Input() clientName;
  @Output() onClose = new EventEmitter();
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";

  selectedTemplate;
  isRadioSelected: boolean = false;
  isDisplayDetailsTable: boolean = true;

  templateDataSource: any = [];
  companyID: number | undefined;
  companyTemplateID: number | undefined;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private _translate: TranslateService,
    private router: Router,
    private mangoUtils: mangoUtils,
    private rProjectDetailsService: RProjectDetailsService
  ) {}

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.getCompanyTemplateDetails();
  }

  getCompanyTemplateDetails() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyID).subscribe(
      (data) => {
        this.templateDataSource = data;
        this.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg);
      }
    );
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(
      AppConstants.CreateFromCompanyTemplate + "_" + AppConstants.SearchString,
      ""
    );
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      AppConstants.CreateFromCompanyTemplate + "_" + AppConstants.SearchString,
      obj.filters.global?.value
    );
  }

  radioCompanyClicked(item) {
    this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
    this.isRadioSelected = true;
    this.companyTemplateID = item.CompanyTemplateHeaderID;
    const transformedItem = {
      ...item,
      TemplateWildcards: item.TemplateName,
      TemplateName: this.mangoUtils.replacePMCaretTemplate(
        item.TemplateName,
        item.DueDate,
        item.isPreviousPeriodYearPolicy,
        item.Repeat
      ),
      ClientID: this.clientID || null,
      ClientName: this.clientName || null,
    };
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(transformedItem));
  }

  createProjectScreen() {
    this.rProjectDetailsService.resetReloadFlag();
    this.router.onSameUrlNavigation = 'reload';
    if (this.router.url == '/project-management/projectDetails') {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/project-management', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
      this.onCloseDialog();
      return;
    }

    this.router.navigate(["project-management/projectDetails"]).then((result) => {
      this.onCloseDialog();
    });
  }

  onCloseDialog() {
    this.isDisplayDetailsTable = false;
    this.onClose.emit(true);
  }
}
