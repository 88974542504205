import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
@Component({
  selector: 'project-management',
  templateUrl: './project-management.component.html'
})
export class ProjectManagementComponent implements OnInit {

  constructor(private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,) {
    // this.mangoAPISrvc.applyLanguage();
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
    this.breadcrumbService.setItems([
      { label: 'Project Management' }
    ]);
  }

  ngOnInit() {
    // this.mangoAPISrvc.applyLanguage();
  }
}
