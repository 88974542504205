<div class="layout-content">
    <div class="clientform-btn-top">
        <button pButton pRipple label="{{'save_button' | translate}}" [disabled]="(!isFormValid)" icon="fal fa-check"
        class="p-button-sm p-pr-2 p-mr-2" (click)="saveUserRateData(myUserRateForm.value)">
        </button>
        <p-button pRipple label="{{'user.user_list' | translate}}" icon="fal fa-user-friends" styleClass="p-button-sm"
            class="p-button-help p-pr-2" (click)="redirectToUserList()"></p-button>
    </div>
    <form [formGroup]="myUserRateForm" novalidate>
        <div class="p-fluid p-formgrid p-grid p-mx-2">
            <div class="p-col-6">
                <div class="card p-mb-3">
                    <div class="p-field p-col-4">
                        <label class="label-text">{{'default_billing_rate' | translate}}</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber formControlName="DefaultBillingRate" inputStyleClass="p-text-right" autocomplete="off" placeholder="0.00"
                                inpudId="locale-us" mode="decimal" locale="en-US" [minFractionDigits]="2">
                            </p-inputNumber>
                        </div>
                    </div>
                    <h4 class="label-text p-mt-5 p-mb-3">{{'staff_cost_method' | translate}}</h4>
                    <div class="p-formgroup-inline p-mt-3">
                        <div class="p-col-4">
                            <p-radioButton label="{{'hourly_rate' | translate}}" value="true" class="label-text p-mt-2"
                                [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}"
                                (onClick)="changeStatus(false)" formControlName="FixedRateBit"></p-radioButton>
                        </div>
                        <div class="p-field p-col-4">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">$</span>
                                <p-inputNumber formControlName="AverageCostPerHour" inputStyleClass="p-text-right" autocomplete="off"
                                    placeholder="0.00" inpudId="billing-us" mode="decimal" locale="en-US"
                                    [minFractionDigits]="2" [disabled]="(disabledflag)">
                                </p-inputNumber>
                            </div>
                        </div>
                    </div>
                    <div class="p-formgroup-inline p-mt-3">
                        <div class="p-col-4">
                            <p-radioButton label="{{'user.Based-on-Amount-Invoiced' | translate}}" value="false"
                                class="label-text p-mt-2" (onClick)="changeStatus(true)" [(ngModel)]="selectedValue"
                                [ngModelOptions]="{standalone: true}" formControlName="PercentBit"></p-radioButton>
                        </div>
                        <div class="p-field p-col-4">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">%</span>
                                <p-inputNumber formControlName="PercentBilling" autocomplete="off" placeholder="0.00"
                                    inpudId="billing-us" mode="decimal" locale="en-US" inputStyleClass="p-text-right" [minFractionDigits]="2"
                                    [disabled]="!(disabledflag)">
                                </p-inputNumber>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="p-col-6">
                <div class="card p-mb-3">
                    <div class="p-col-6">
                        <p-checkbox label="{{'activate_billing_rate_profiles' | translate}}"
                            formControlName="ActivateBillingRateProfiles" binary="true" class="label-text p-mt-2">
                        </p-checkbox>
                    </div>
                    <div class="p-col-8 p-mt-4" *ngIf="myUserRateForm.get('ActivateBillingRateProfiles').value">
                        <div class="p-field p-col-8">
                            <label class="label-text p-mt-3">{{'billing_rate_profile' | translate}}</label>
                            <p-dropdown [options]="templatesDataSet" [(ngModel)]="RateMasterIDNo"
                                [ngModelOptions]="{standalone: true}" (onChange)="dropDownRateType($event)">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-8 p-mt-4 permissions"
                        *ngIf="(selectedProfileDataSet && selectedProfileDataSet.length > 0 && myUserRateForm.get('ActivateBillingRateProfiles').value)">
                        <table class="p-datatable p-component" style="width: 100%;">
                            <thead class="p-datatable-thead">
                                <tr>
                                    <th class="p-text-left ic-blue">{{'activity-code' | translate}}</th>
                                    <th class="p-text-right ic-blue">{{'rate' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody class="p-datatable-tbody">
                                <tr *ngFor="let profile of selectedProfileDataSet">
                                    <td class="p-text-left">
                                        <span>{{profile.Description}}</span>
                                    </td>
                                    <td class="p-text-right">
                                        <span>{{profile.BillingRate}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        *ngIf="(selectedProfileDataSet && selectedProfileDataSet.length == 0 && myUserRateForm.get('ActivateBillingRateProfiles').value)">
                        <span class="p-text-danger"><strong>{{'data_not_found' | translate}}</strong></span>
                    </div>
                </div>
            </div> -->
        </div>
    </form>
</div>