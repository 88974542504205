<div class="statement-printing">
    <div class="layout-content p-px-3">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button type="button" pButton pRipple label="{{ 'statement_printing.process_statements' | translate }}" icon="fa fa-play"
                class="p-button-sm p-pr-2 p-mr-2" (click)="processStatements()" [disabled]="(selectedClientsRecords.length == 0)"></button>
            <button type="button" pButton pRipple label="{{ 'preview' | translate }}" icon="fal fa-print"
                class="p-button-sm p-pr-2 p-mr-2" (click)="previewStatementsSelectedClients()" [disabled]="(selectedClientsRecords.length == 0)"></button>
            <button type="button" pButton pRipple label="{{ 'Reports_Center' | translate }}" class="p-button-sm p-pr-2" routerLink="/reports"></button>
        </span>
        <div class="p-grid p-p-0">
            <div class="p-col-4">
                <div class="card">
                    <div class="p-fluid">
                        <div class="p-formgroup-inline">
                            <div class="p-field p-col-12 p-pl-0">
                                <label for="fieldId">{{ 'statement_printing.minimum_statement_balance' | translate }}</label>
                                <p-inputNumber name="minimumbalance" id="minimumbalance" inputStyleClass="p-text-right"
                                    #minimumbalance="ngModel" required minlength="3" maxlength="25" autocomplete="off"
                                    (onBlur)="changeUSMoney();" [(ngModel)]="minimumBalance" mode="currency" currency="USD"
                                    (ngModelChange)="FormChanged = true;" locale="en-US" (click)="onClick($event)" >
                                </p-inputNumber>
                            </div>
                            
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-col-7 p-pl-0">
                                <label for="fieldId">{{ 'statement_printing.include_invoices' | translate }}</label>
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="invoiceDate"
                                    yearRange="2000:2099" showButtonBar="true" (onSelect)="FormChanged = true;">
                                </p-calendar>
                            </div>
                            <div class="p-field p-col-5 p-pr-0">
                                <label for="fieldId">{{ 'statement_printing.statement_date' | translate }}</label>
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="stmtDate"
                                    yearRange="2000:2099" showButtonBar="true">
                                </p-calendar>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-col-12 p-pl-0 p-text-right">
                                <p-checkbox [(ngModel)]="isExcludeRetainerInvoices" [binary]="true" [ngModelOptions]="{standalone: true}"
                                    label="Exclude Retainer Invoices" class="label-text">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="p-text-right">
                        <span>
                            <p-button (click)="loadClientStatements();FormChanged=false;" styleClass="p-button-sm"
                                class="p-button-help" type="button" icon="fa fa-file-text" label="{{ 'refresh' | translate }}"
                                [disabled]="!FormChanged">
                            </p-button>
                        </span>
                        <!-- <span>
                            <p-button styleClass="p-button-sm" class="p-button-help" type="button"
                                (click)="processStatements()" icon="fa fa-play" label="{{ 'statement_printing.process_statements' | translate }}"
                                [disabled]="(selectedClientsRecords.length == 0)"></p-button>
                        </span> -->
                    </div>
                </div>
            </div>
            <div class="p-col-8">
                <div class="card p-pt-1">
                    <p-table #dt [value]="clientsDataSource" [(selection)]="selectedClientsRecords" [rows]="10"
                        [scrollable]="true" scrollHeight="calc(90vh - 280px)"
                        [totalRecords]="clientsDataSource.length" [resizableColumns]="true" [filterDelay]="0"
                        styleClass="p-datatable-sm p-datatable-responsive-demo" [responsive]="true" dataKey="ClientID"
                        [sortOrder]="0" (onFilter)="onFilter($event)"
                        [globalFilterFields]="['ClientName', 'openbalance', 'FinalizeAction','BillingGroup','StaffAssignedName','BillingPartnerName','ClientNumber']">
                        <!-- Filter -->
                        <ng-template pTemplate="caption">
                            <span class="p-input-icon-left">
                                <i class="fal fa-search"></i>
                                <input #searchValue pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{ 'globalsearch' | translate }}" />
                            </span>
                            <span>
                                <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="dt.reset();clearSearchFilter()" pTooltip="Clear"
                                    tooltipPosition="top"></button>
                            </span>
                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            
                            <div class="pull-right p-mt-2">
                                <p-checkbox [(ngModel)]="showAllWithBalances" [binary]="true" [ngModelOptions]="{standalone: true}"
                                    label="Show All Customers with Balances Regardless of Terms" class="label-text" (onChange)="onChangeCheckbox()">
                                </p-checkbox>
                            </div>
                        </ng-template>

                        <!-- Table Header -->
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-10p p-text-center">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="p-text-left width-30p" pSortableColumn="ClientName">
                                    {{ 'client.name' | translate }}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th class="p-text-right width-20p" pSortableColumn="openbalance">
                                    Client Balance
                                    <p-sortIcon field="openbalance"></p-sortIcon>
                                </th>
                                <th class="p-text-left width-15p" pSortableColumn="ClientNumber">
                                    {{ 'client.client_number' | translate }}
                                    <p-sortIcon field="ClientNumber"></p-sortIcon>
                                </th>
                                <th class="p-text-left width-20p" pSortableColumn="BillingGroup">
                                    {{ 'billing-group' | translate }}
                                    <p-sortIcon field="BillingGroup"></p-sortIcon>
                                </th>
                                <th class="p-text-left width-20p" pSortableColumn="BillingPartnerName">
                                    {{ 'Billing_Partner' | translate }}
                                    <p-sortIcon field="BillingPartnerName"></p-sortIcon>
                                </th>
                                <th class="p-text-left width-20p" pSortableColumn="StaffAssignedName">
                                    {{ 'staff-assigned' | translate }}
                                    <p-sortIcon field="StaffAssignedName"></p-sortIcon>
                                </th>
                                <th class="p-text-center width-12p">{{ 'action' | translate }}</th>
                            </tr>
                        </ng-template>

                        <!-- Rows -->
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td class="width-10p p-text-center">
                                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                </td>
                                <td class="width-30p p-text-left">
                                    {{rowData.ClientName}}
                                </td>
                                <td class="width-20p p-text-right">
                                    {{rowData.openbalance | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td class="width-15p p-text-left">
                                    {{rowData.ClientNumber}}
                                </td>
                                <td class="width-20p p-text-left">
                                    {{rowData.BillingGroup}}
                                </td>
                                <td class="width-20p p-text-left">
                                    {{rowData.BillingPartnerName}}
                                </td>
                                <td class="width-20p p-text-left">
                                    {{rowData.StaffAssignedName}}
                                </td>
                                <td pEditableColumn style="overflow:visible;" class="width-12p p-text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [(ngModel)]="rowData['FinalizeAction']"
                                                [options]="finalizeActions" [autoWidth]="false"
                                                [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}"
                                                appendTo="body" (onChange)="doFinalizeActions($event,rowData)">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>{{rowData['FinalizeAction']}}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Footer -->
                        <ng-template pTemplate="footer">
                            <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && clientsDataSource.length > 0)">
                                <td class="width-40p p-text-right ic-red">
                                    <strong>{{ 'Totals' | translate }}</strong>
                                </td>
                                <td class="width-20p p-text-right ic-red">
                                    {{ totalLateFeeAmount | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td class="width-15p"></td>
                                <td class="width-72p"></td>
                            </tr>
                        </ng-template>
                        <!-- No data -->
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="4">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>