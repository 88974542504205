<div class="engagement-list">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addtimeSheetPopUp();" tooltipPosition="top"
                pTooltip="{{ 'add' | translate }}"></p-button>
            <button pButton pRipple label="{{ 'list' | translate }}" icon="fal fa-project-diagram"
                class="p-mr-1 p-button-sm" (click)="redirectListView()"></button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="timeSlips" [rowHover]="true" [responsive]="true"
                        dataKey="ServiceCodeID" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                        [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="Ddate" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'"
                        [globalFilterFields]="['ServiceCode','StaffName','Ddate','StaffName','Memo','TotalTime','BillingRate','StandardAmount']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)" (onEditInit)="onEditInit($event)"
                        (onEditComplete)="onEditComplete()" (onEditCancel)="onEditCancel()">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-7p p-text-center">{{ 'actions' | translate }}</th>
                                <th class="width-7p p-text-left" pSortableColumn="Ddate">
                                    {{ 'date' | translate }}
                                    <p-sortIcon field="Ddate"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left">{{ 'staff' | translate }}</th>
                                <th class="width-8p p-text-left" pSortableColumn="ServiceCode">
                                    {{ 'work_activity'| translate }}
                                    <p-sortIcon field="ServiceCode"></p-sortIcon>
                                </th>
                                <th class="width-23p p-text-left">{{ 'timer.work-description' | translate }}</th>
                                <th class="width-5p p-text-right">{{ 'Time' | translate }}</th>
                                <th class="width-6p p-text-right">{{ 'billing-rate' | translate }}</th>
                                <th class="width-9p p-text-right">{{ 'nonbillable-amount' | translate }}</th>
                                <th class="width-9p p-text-right">{{ 'billable-amount' | translate }}</th>
                                <th class="width-6p p-text-right">{{ 'Flat-Amount' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex>
                            <tr class="cursor-hand">
                                <td class="width-7p">
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                                            (click)="openTimeEntryDailog(rowData)" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}"></i>
                                        <i (click)="deleteEngagement(rowData.SlipMasterID)"
                                            class="fal fa-trash-alt ic-red cursor-hand p-mr-2" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                        <i class="fas fa-save ic-blue ic-size-15"
                                            (click)="saveTimeEntry($event, rowData)" *ngIf="rowData['IsColumnChanges']"
                                            pTooltip="{{ 'save_button' | translate }}" tooltipPosition="top"></i>
                                    </div>
                                </td>
                                <td class="width-7p p-text-left" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                (ngModelChange)="rowData['IsColumnChanges'] = true"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="rowData['Ddate']"
                                                appendTo="body" yearRange="2000:2099" dateFormat="mm/dd/yy">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{rowData['Ddate'] | date:'MM/dd/yyyy'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-8p p-text-left" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-autoComplete placeholder="{{ rowData.StaffName }}"
                                                [(ngModel)]="rowData['StaffName']" [suggestions]="filteredStaffSingle"
                                                (onSelect)="handleDropStaffSelectClick($event.value, rowData, 'Staff')"
                                                [disabled]="rowData['Billed'] || isLocked(rowData['Ddate'])"
                                                (completeMethod)="filterStaffItems($event,rowData)" field="label"
                                                [size]="30" [minLength]="1" [dropdown]="true" appendTo="body">
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>{{ rowData.StaffName }}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-8p p-text-left" pEditableColumn showDelay="800" tooltipPosition="top"
                                    pTooltip="{{rowData.ServiceCode}}">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="activityList" placeholder="{{ rowData.ServiceCode }}"
                                                [(ngModel)]="rowData.ServiceCodeID" id="TimeDescription"
                                                name="TimeDescription" [filter]="true" #TimeDescription="ngModel"
                                                (onChange)="handleDropStaffSelectClick($event,rowData,'Activity')"
                                                [disabled]="rowData['Billed'] || isLocked(rowData['Ddate'])"
                                                appendTo="body" [style]="{'width':'200px !important'}"
                                                class="p-text-left"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>{{ rowData['ServiceCode'] }}
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-23p p-text-left text-nowrap p-text-truncate"
                                    [ngStyle]="{'overflow': (cellFocused && cellFocused.field === 'Memo' && cellFocused.index === rowIndex) ? 'visible' : 'hidden'}"
                                    [pEditableColumn]="rowData" [pEditableColumnField]="'Memo'"
                                    [pEditableColumnRowIndex]="rowIndex">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span>
                                                <app-mentions [rows]="1" [(inputText)]="rowData['Memo']" (inputTextChange)="rowData['IsColumnChanges']=true"
                                                    [disabled]="rowData['Billed'] || isLocked(rowData['Ddate'])"
                                                    style="width:100% !important; resize: vertical;"></app-mentions>
                                            </span>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData.Memo }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td class="p-text-left">{{rowData.Memo}}</td> -->
                                <td class="width-5p p-text-right" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="width-100p" pInputText [(ngModel)]="rowData['TotalTime']"
                                                (ngModelChange)="rowData['IsColumnChanges']=true"
                                                (focus)="$event.target.select();"
                                                [disabled]="rowData['Billed'] || isLocked(rowData['Ddate'])"
                                                (blur)="onTotalTimeChange($event, rowData)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData.TotalTime | number:'1.2-2'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-6p p-text-right" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="width-100p" pInputText [(ngModel)]="rowData['BillingRate']"
                                                (ngModelChange)="rowData['IsColumnChanges']=true"
                                                (focus)="$event.target.select();"
                                                [disabled]="rowData['Billed'] || isLocked(rowData['Ddate'])"
                                                (blur)="changeUSMoney($event,rowData);onBillRateChange($event, rowData)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData.BillingRate | currency: "USD":true:"1.2-2" }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td (click)="openTimeEntryDailog(rowData);" class="p-text-right width-9p"
                                    *ngIf="!rowData.Billable">
                                    <span>{{ rowData.StandardAmount | currency:'USD':true:'1.2-2' }}</span>
                                </td>
                                <td (click)="openTimeEntryDailog(rowData);" class="p-text-right width-9p"
                                    *ngIf="rowData.Billable">
                                    <span>--</span>
                                </td>
                                <!-- Billable Column -->
                                <td (click)="openTimeEntryDailog(rowData);" class="p-text-right width-9p"
                                    *ngIf="rowData.Billable">
                                    <span>{{ rowData.StandardAmount | currency:'USD':true:'1.2-2' }}</span>
                                </td>
                                <td (click)="openTimeEntryDailog(rowData);" class="p-text-right width-9p"
                                    *ngIf="!rowData.Billable">
                                    <span>--</span>
                                </td>
                                <td (click)="openTimeEntryDailog(rowData);" class="width-6p p-text-right p-text-nowrap">
                                    <i class="fal fa-check ic-red" *ngIf="rowData['isFlatFee']"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="10">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td class="width-7p"></td>
                                <td class="width-7p"></td>
                                <td class="width-8p"></td>
                                <td class="width-8p"></td>
                                <td class="width-23p p-text-right ic-red">{{ 'Totals' | translate }}</td>
                                <td class="width-5p p-text-right ic-red">
                                    <span>{{ totalTime | number:'1.2-2'}}</span>
                                </td>
                                <td class="width-6p p-text-right ic-red">
                                    <span>&nbsp;</span>
                                </td>
                                <td class="width-9p p-text-right ic-red">
                                    <span>{{ totalNonBillableAmount | currency:'USD':true:'1.2-2' }}</span>
                                </td>
                                <td class="width-9p p-text-right ic-red">
                                    <span>{{ totalBillableTime | currency:'USD':true:'1.2-2' }}</span>
                                </td>
                                <td class="width-6p p-text-right ic-red">
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>