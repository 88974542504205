<div class="locations">
    <div class="layout-content p-px-3">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addLocation()"></p-button>
        </span>
        <div class="p-grid p-p-0">
            <div class="p-col-12">
                <div class="card p-grid p-pt-0 p-m-0">
                    <div class="p-col-12 p-pt-0">
                        <p-table #dt [value]="dataSource" datakey="CompanyMangoLocationID" [responsive]="true"
                            [rows]="10" [columns]=cols
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [filterDelay]="0" (onFilter)="onFilter($event)" sortField="CompanyName" [sortOrder]="1"
                            [scrollable]="true" scrollHeight="400px" [rowHover]="true"
                            [globalFilterFields]="['CompanyName','City', 'Street1', 'Street2', 'State', 'Zip', 'Telephone', 'Fax', 'CompanyContact', 'EmailCompany']">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>

                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-6p p-text-center">
                                        {{ 'actions' | translate }}
                                    </th>
                                    <th class="width-15p p-text-left" pSortableColumn="CompanyName">
                                        {{'company-setup.Company-Name' | translate}}
                                        <p-sortIcon field="CompanyName"></p-sortIcon>
                                    </th>
                                    <th class="width-15p p-text-left" pSortableColumn="CompanyLocation">
                                        {{'location' | translate}}
                                        <p-sortIcon field="CompanyLocation"></p-sortIcon>
                                    </th>
                                    <th class="width-8p p-text-left">
                                        {{'Street1' | translate}}
                                    </th>
                                    <th class="width-8p p-text-left">
                                        {{'Street2' | translate}}
                                    </th>
                                    <th class="width-9p p-text-left">
                                        {{'client.city' | translate}}
                                    </th>
                                    <th class="width-5p p-text-left">
                                        {{'client.state' | translate}}
                                    </th>
                                    <th class="width-8p p-text-left">
                                        {{'client.zip' | translate}}
                                    </th>
                                    <th class="width-10p p-text-left">
                                        {{'client.telephone_number' | translate}}
                                    </th>
                                    <th class="width-10p p-text-left">
                                        {{'client.contact_name' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="p-text-center width-6p">
                                        <div class="p-text-center">
                                            <i pRipple class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                                                (click)="editRow(rowData);" tooltipPosition="top"
                                                pTooltip="{{ 'Edit' | translate }}"></i>
                                            <i pRipple (click)="deleteRow(rowData);$event.stopPropagation();"
                                                class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                                                pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                        </div>
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-15p"
                                        pTooltip="{{rowData.CompanyNameL}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.CompanyNameL}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-15p"
                                        pTooltip="{{rowData.CompanyLocation}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.CompanyLocation}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-8p"
                                        pTooltip="{{rowData.Street1L}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.Street1L}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-8p"
                                        pTooltip="{{rowData.Street2L}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.Street2L}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-9p"
                                        pTooltip="{{rowData.CityL}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.CityL}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-5p"
                                        pTooltip="{{rowData.StateL}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.StateL}}
                                    </td>
                                    <td class="p-text-left width-8p" pTooltip="{{rowData.ZipL}}" tooltipPosition="top"
                                        showDelay="900" tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.ZipL}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-10p"
                                        pTooltip="{{rowData.TelephoneL}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.TelephoneL}}
                                    </td>
                                    <td class="p-text-left p-text-truncate p-text-nowrap width-10p"
                                        pTooltip="{{rowData.ContactL}}" tooltipPosition="top" showDelay="900"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.ContactL}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="10">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="{{(obj.isEdit ? 'Edit' : 'add') | translate}} {{obj.title}}" [(visible)]="showAddDialog"
    [modal]="true" [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="1" [draggable]="true"
    [resizable]="false" (onHide)="closeDialog()">
    <form [formGroup]="companyDetailForm" novalidate #formDirective="ngForm">
        <div class="p-fluid p-formgrid p-grid p-m-1">
            <div class="p-col-8">
                <div class="card p-m-0 p-p-0">
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col">
                            <label class="label-text required-field">{{'company-setup.Company-Name' |
                                translate}}</label>
                            <input type="text" pInputText maxlength="70" autocomplete="off"
                                formControlName="CompanyNameL" disabled>
                        </div>
                        <div class="p-field p-col">
                            <label class="label-text required-field">{{'location' | translate}}</label>
                            <input type="text" pInputText autocomplete="nope" formControlName="CompanyLocation"
                                required>
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col">
                            <label class="label-text">{{'Street' | translate}} 1</label>
                            <input type="text" pInputText autocomplete="nope" maxlength="70" formControlName="street1">
                        </div>
                        <div class="p-field p-col">
                            <label class="label-text">{{'Street' | translate}} 2</label>
                            <input type="text" pInputText autocomplete="nope" maxlength="70" formControlName="street2">
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col">
                            <label class="label-text">{{'client.city' | translate}}</label>
                            <input type="text" pInputText autocomplete="nope" formControlName="city">
                        </div>
                        <div class="p-field p-col">
                            <label class="label-text">{{'client.state' | translate}}</label>
                            <input type="text" pInputText
                                (blur)="companyDetailForm.controls['state'].setValue($event.target.value.toUpperCase())"
                                autocomplete="nope" maxlength="2" formControlName="state">
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col">
                            <label class="label-text">{{'client.zip' | translate}}</label>
                            <input type="text" pInputText class="p-text-right" formControlName="zip" maxlength="10"
                                autocomplete="nope">
                        </div>
                        <div class="p-field p-col">
                            <label class="label-text">{{'email' | translate}}
                                <i pTooltip="{{'company-setup.email-tooltip' | translate}}"
                                    tooltipStyleClass="tooltip-width-300"
                                    class="fa fa-question-circle ic-blue question-tooltip-icon"
                                    tooltipPosition="right"></i>
                            </label>
                            <input type="text" pInputText autocomplete="nope" formControlName="email">
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col">
                            <label class="label-text">{{'Office-Telephone-Number' | translate}}</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="phone">
                            </p-inputMask>
                        </div>
                        <div class="p-field p-col">
                            <label class="label-text">{{'Fax-Number' | translate}}</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="faxNumber">
                            </p-inputMask>
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col">
                            <label class="label-text">{{'client.contact_name' | translate}}</label>
                            <input type="text" pInputText autocomplete="nope" maxlength="50"
                                formControlName="companyContact">
                        </div>
                        <div class="p-field p-col">
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col">
                                    <label class="label-text">{{'labor-rate' | translate}}</label>
                                    <p-inputNumber inputId="integeronly" formControlName="TaxRateLaborL"
                                        (click)="$event.target.select()" autocomplete="nope"
                                        inputStyleClass="p-text-right" mode="decimal" [minFractionDigits]="2"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                </div>
                                <div class="p-field p-col">
                                    <label class="label-text">{{'expense-rate' | translate}}</label>
                                    <p-inputNumber inputId="integeronly" formControlName="TaxRateExpenseL"
                                        (click)="$event.target.select()" autocomplete="nope"
                                        inputStyleClass="p-text-right" mode="decimal" [minFractionDigits]="2"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
              class="p-col-4"
            >
              <div
                class="p-d-flex p-jc-center"
              >
                <h4
                  class="label-text p-mb-0"
                >
                  {{ 'Company-Logo' | translate }}
                </h4>
              </div>
              <div
                class="p-col-12 timeline-images"
              >
                <div
                  class="p-grid p-my-5"
                >
                  <div
                    class="p-col-12 p-text-center p-mb-3"
                  >
                    <img
                      *ngIf="isImageUrl"

                      class="company-img-app"

                      [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"

                      onError="this.src='assets/img/userForTable.png'"
                    />
                    <i
                      *ngIf="!isImageUrl"

                      class="fal fa-user-circle ic-size-70"
                    >
                    </i>
                  </div>
                  <div
                    class="p-col-7 p-text-left p-mt-4"
                  >
                    <p-fileUpload
                      #fileUpload

                      mode="basic"
                      accept="image/*"
                      chooseLabel="{{ 'Upload' | translate }}"

                      [auto]="true"

                      (onSelect)="
                        fileEvent( $event );
                      "
                    >
                    </p-fileUpload>
                  </div>
                  <div
                    class="p-col-5 p-text-right p-mt-4"
                  >
                    <p-button
                      pRipple

                      label="{{ 'Remove' | translate }}"
                      pTooltip="{{ 'Remove' | translate }}"
                      icon="fal fa-trash-alt"
                      styleClass="p-button-sm p-button-danger"
                      tooltipPosition="top"

                      (click)="
                        removeImage( );
                      "
                    >
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!isFormValid" (click)="save(obj.isEdit)"
            label="{{'save_button' | translate}}"></button>
        <button type="button" pButton pRipple icon="fal fa-times" label="{{'cancel_button' | translate}}"
            (click)="closeDialog()"></button>
    </ng-template>
</p-dialog>
