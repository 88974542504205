<div class="time-entry-list">
    <span class="pull-right p-mr-3 clientform-btn-top">
        <button pButton class="p-button-sm p-mr-2" label="{{'add' | translate}}" pRipple icon="fal fa-plus"
            *ngIf="headerListItems.length == 0" (click)="addRow(-1,{})"></button>
        <button pButton pRipple icon="fal fa-file-text" [disabled]="(isNewenabled)" (click)="processInvoiceConfirm()"
            class="p-button-sm p-mr-2" label="{{'process-invoices' | translate}}"></button>
        <button pButton pRipple icon="fa fa-floppy-o" [disabled]="(!isUpdateAllenabled)" class="p-button-sm p-mr-2"
            label="{{'update-all' | translate}}" (click)="updateAll()"></button>
        <button pButton pRipple icon="fal fa-paperclip" [disabled]="(isNewenabled)" class="p-button-sm p-mr-2"
            label="{{'attach-invoice-memo' | translate}}" (click)="attachInvoiceMemo();"></button>

    </span>
    <div class="layout-content p-pt-0">
        <div class="p-mx-2">
            <div class="p-col-12 p-py-0">
                <div class="card p-py-3">
                    <div class="p-formgroup-inline">
                        <div class="p-field p-mb-0 p-p-2">
                            <label for="View" class="p-mr-3 label-text">{{'Accounts.invoice-date' | translate}}</label>
                            <p-calendar name="Ddate" [(ngModel)]="invoiceDate" [monthNavigator]="true"
                                [inputStyle]="{'width':'100px'}" [yearNavigator]="true" yearRange="2000:2030"
                                showButtonBar="true"></p-calendar>
                        </div>
                        <div class="p-field p-ml-3 p-mb-0 p-p-2">
                            <label for="For" class="p-mr-3 label-text">{{'last-invoice' | translate}}</label>
                            <input type="text" class="p-text-right" disabled pInputText [(ngModel)]="lastInvoice"
                                name="lastinvoice" id="lastinvoice" readonly>
                        </div>
                        <div class="p-field p-ml-3 p-mb-0 p-col-6">
                            <label for="For" class="p-mr-3 label-text">{{replaceSText}}
                                <i pTooltip="{{tooltipText}}" tooltipPosition="right"
                                    tooltipStyleClass="tooltip-width-450"
                                    class="fa fa-question-circle question-tooltip-icon" aria-hidden="true"></i>
                            </label>
                            <input class="width-60p" type="text" pInputText [(ngModel)]="replaceText" name="replacetext"
                                id="replacetext">
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-pt-1">
                <div class="card p-pt-0">
                    <p-table [value]="headerListItems" [rowHover]="true" dataKey="RecurringHeaderID"
                        selectionMode="single" [filterDelay]="0" [columns]="selectedColumns"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [globalFilterFields]="globalFilterColumns" [(selection)]="selectedReviewItems"
                        (onFilter)="onFilter($event)" (onHeaderCheckboxToggle)="checkUncheckExpensesRow($event)"
                        [scrollable]="true" scrollHeight="450px" editMode="row" #dt>
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-6 p-pb-0">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                        styleClass="p-ml-2"></p-message>
                                </div>
                                <div class="p-col-6 p-text-right p-pb-0">
                                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                        selectedItemsLabel="{0} {{'columns_selected' | translate}}"
                                        [style]="{minWidth: '200px'}" placeholder="{{'Choose_Columns' | translate}}">
                                    </p-multiSelect>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="p-text-center">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="p-text-center">{{'actions' | translate}}</th>
                                <th class="p-text-left required-field" pSortableColumn="ClientName">
                                    {{'client.name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>

                                <th class="{{ col.text + ' ' + col.class }}" *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col style="width:38px">
                                <col style="width:140px">
                                <col style="width:250px">

                                <col *ngFor="let col of columns" [ngStyle]="{width: col.width}">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-rowIndex="rowIndex">
                            <tr class="cursor-hand" [pEditableRow]="rowData">

                                <!-- Checkbox -->
                                <td class="p-text-center" (click)="checkUncheckExpensesRow(rowData)">
                                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                </td>

                                <!-- Actions -->
                                <td class="p-text-center">
                                    <!-- <i *ngIf="isEnrollForm" class="fa fa-usd p-mr-2" aria-hidden="true"
                                        (click)="openDialog(rowIndex,rowData)"></i> -->
                                    <i class="fal fa-plus ic-blue p-mr-3" aria-hidden="true" pTooltip="Add Row" tooltipPosition="top"
                                        (click)="addRow(rowIndex,rowData)"></i>
                                    <i class="fal fa-trash-alt ic-red p-mr-3" aria-hidden="true" pTooltip="Delete" tooltipPosition="top"
                                        (click)="deleteRow($event,rowData)"></i>
                                    <i *ngIf="rowData['isNew']" class="fal fa-save ic-green p-mr-3" pTooltip="Save" tooltipPosition="top"
                                        (click)="saveRow($event,rowData)"></i>
                                    <i *ngIf="rowData['IsColumnChanges'] && !rowData['isNew']"
                                        class="fal fa-asterisk ic-red p-mr-3"></i>
                                    <i *ngIf="editing" class="pi pi-check ic-size-11 ic-blue p-mr-3 save-btn-edit-row"
                                        (click)="onRowEditSave($event, rowData, 'TimeRecords')" pTooltip="Update" tooltipPosition="top" pSaveEditableRow></i>
                                    <!-- <i *ngIf="editing" class="pi pi-times ic-size-11 ic-red" pTooltip="Cancel" tooltipPosition="top"
                                        (click)="onRowEditCancel(rowData, rowIndex)" pCancelEditableRow></i> -->
                                </td>

                                <!-- Client Name -->
                                <td pInitEditableRow (click)="onRowEditInit(rowData)" class="p-text-left">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-autoComplete placeholder="{{rowData['ClientName']}}"
                                                [(ngModel)]="rowData['ClientName']" autocomplete="off" [suggestions]="filteredClients"
                                                (completeMethod)="filterClients($event)" field="ClientName"
                                                (onSelect)="handleSelectClick($event,rowData)" [size]="30"
                                                [minLength]="1" appendTo="body" (onFocus)="$event.target.select()"
                                                [dropdown]="true" class="text-nowrap"></p-autoComplete>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div style="margin:8px 0 0 0" class="text-nowrap">{{rowData['ClientName']}}
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td *ngFor="let col of columns">
                                    <!-- User -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)" *ngIf="col.header === 'User'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-autoComplete placeholder="{{rowData['StaffName']}}"
                                                    [(ngModel)]="rowData['StaffName']"
                                                    [suggestions]="filteredStaffSingle"
                                                    (completeMethod)="filterStaffItems($event,rowData)" field="label"
                                                    (onSelect)="handleDropSelectClick($event,rowData,'Staff')"
                                                    [size]="30" [minLength]="1" (onFocus)="$event.target.select()"
                                                    [dropdown]="true" appendTo="body"></p-autoComplete>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div style="margin:8px 0 0 0">{{rowData['StaffName']}}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Recurring Invoice Group -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)"  *ngIf="col.header === 'Recurring Invoice Group'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown [options]="recurringInvoiceGroupList"
                                                    [(ngModel)]="rowData.RecurringInvoiceGroupID"
                                                    name="itemRecurringInvoiceGroupID" [filter]="true"
                                                    id="itemRecurringInvoiceGroupID"
                                                    #itemRecurringInvoiceGroupID="ngModel"
                                                    (onChange)="handleDropSelectClick($event,rowData,'RecurringGroupDescription')"
                                                    appendTo="body" [style]="{'width':'200px !important'}"
                                                    class="text-ellipse recurring-dpdown"
                                                    [disabled]="rowData.ClientID==null"></p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div style="margin:8px 0 0 0">{{rowData['RecurringGroupDescription']}}
                                                </div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Engagements -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)"  *ngIf="col.header === 'Engagements'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-autoComplete placeholder="{{rowData['EngagementName']}}"
                                                    [(ngModel)]="rowData['EngagementName']"
                                                    [suggestions]="filteredGroupsSingle"
                                                    (completeMethod)="filterGroupsItems($event,rowData)" field="label"
                                                    (onSelect)="handleDropSelectClick($event,rowData,'EngagementName')"
                                                    [size]="30" [minLength]="1" (onFocus)="$event.target.select()"
                                                    [dropdown]="true" appendTo="body">
                                                </p-autoComplete>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div style="margin:8px 0 0 0">{{rowData['EngagementName']}}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Engagements -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)"  *ngIf="col.header === 'Invoice Description'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input type="text" pInputText
                                                    [(ngModel)]="rowData['InvoiceShortDescription']"
                                                    (focus)="$event.target.select()"
                                                    (ngModelChange)="editCellComplete($event,rowData)"
                                                    class="width-100p text-left text-ellipse">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData.InvoiceShortDescription}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Invoice Amount -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)"  class="p-text-right" *ngIf="col.header === 'Invoice Amount'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="width-100p" type="text" pInputText
                                                    [(ngModel)]="rowData['BillAmount']"
                                                    (focus)="$event.target.select();"
                                                    (blur)="changeUSMoney($event,rowData);"
                                                    (focus)="$event.target.select()"
                                                    (ngModelChange)="rowData['IsColumnChanges'] = true;editCellComplete($event,rowData)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData.BillAmount | currency:'USD':'symbol':'1.2-2'}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Frequency -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)"  *ngIf="col.header === 'Frequency'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown [(ngModel)]="rowData['Frequency']" [options]="freqList"
                                                    [autoWidth]="false" [style]="{'width':'96%'}" required="true"
                                                    appendTo="body"
                                                    (onChange)="handleDropSelectClick($event,rowData,'frequency')">
                                                </p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div>{{rowData['Frequency']}}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Scheduled Date -->
                                    <div pInitEditableRow (click)="onRowEditInit(rowData)"  class="p-text-center" *ngIf="col.header === 'Scheduled Date'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                    [(ngModel)]="rowData['NextInvoiceDate']" appendTo="body"
                                                    yearRange="1000:2099" dateFormat="mm/dd/yy"
                                                    (onSelect)="editCellComplete($event,rowData)"
                                                    class="calender-component"
                                                    (onInput)="editCellComplete($event,rowData)" showButtonBar="true">
                                                </p-calendar>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['NextInvoiceDate'] | date:'MM/dd/yyyy'}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Release Time -->
                                    <div class="p-text-center" *ngIf="col.header === 'Release Time'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-checkbox [(ngModel)]="rowData['ReleaseTime']"
                                                    value="rowData['ReleaseTime']" binary="true"
                                                    (onChange)="editCellComplete($event,rowData)"
                                                    (ngModelChange)="rowData['IsColumnChanges'] = true"></p-checkbox>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <p-checkbox [(ngModel)]="rowData['ReleaseTime']"
                                                    value="rowData['ReleaseTime']" binary="true"
                                                    (onChange)="editCellComplete($event,rowData)"></p-checkbox>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>

                                    <!-- Date Thru -->
                                    <div pInitEditableRow class="p-text-center" *ngIf="col.header === 'Date Thru'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                    [(ngModel)]="rowData['PeriodTo']" appendTo="body"
                                                    yearRange="1000:2099" dateFormat="mm/dd/yy"
                                                    (onSelect)="editCellComplete($event,rowData)"
                                                    class="calender-component"
                                                    (onBlur)="editCellComplete($event,rowData)" showButtonBar="true"
                                                    [disabled]="(rowData['ReleaseTime'] == false)"></p-calendar>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['PeriodTo'] | date:'MM/dd/yyyy'}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td class="width-100p" [attr.colspan]="3 + selectedColumns.length">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Attach Invoice Memo -->
<p-dialog header="{{'invoice-attach-memo' | translate}}" [(visible)]="dialogDisplay" positionTop="1" [modal]="true"
    [style]="{width: '55vw'}">
    <div class="p-col-12 richtext">
        <div class="p-field p-col-12 p-py-0 p-my-0 ">
            <div class="p-text-right">
                <span><a href="JavaScript:void(0);" (disable)="IsEditorChanges"
                        (click)="enableEditor()">{{btnText}}</a></span>
            </div>
            <ejs-richtexteditor [height]="'calc(30vh - 100px)'" [enableResize]="false" [readonly]="IsDataReadOnly"
                [(value)]="invoiceList.InvoiceMessage" (change)="onEditorChange($event)">
            </ejs-richtexteditor>
        </div>
    </div>
    <div class="p-col-12 richtext">
        <div class="p-field p-col-12 p-py-0 p-my-0 ">
            <span class="label-text">{{'top-memo' | translate}}</span>
            <div>
                <ejs-richtexteditor [height]="'calc(30vh - 100px)'" [enableResize]="false" [(value)]="topMemoValue"
                    (change)="onTopBottomEditorChange($event)">
                </ejs-richtexteditor>
            </div>
        </div>
    </div>
    <div class="p-col-12 richtext">
        <div class="p-field p-col-12 p-py-0 p-my-0 ">
            <span class="label-text">{{'bottom-memo' | translate}}</span>
            <div>
                <ejs-richtexteditor [height]="'calc(30vh - 100px)'" [enableResize]="false" [(value)]="bottomMemoValue"
                    (change)="onTopBottomEditorChange($event)">
                </ejs-richtexteditor>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!IsTopBottomModified"
            (click)="saveTopBottomMemo()" label="{{'attach-memo' | translate}}"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="dialogDisplay=false"
            label="{{'cancel_button' | translate}}"></button>
    </ng-template>
</p-dialog>

<!-- Credit Card Dialog -->
<p-dialog [(visible)]="cardDialogDisplay" modal="modal" [responsive]="true" [resizable]="true" [positionTop]="0"
    (onHide)="closeCreditCardForm();" [showHeader]="false" [style]="{width: '50vw'}" [draggable]="true">
    <form class="education-form" role="form" novalidate [formGroup]="myPaymentForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12">
                <!-- Card Header -->
                <div class="p-grid">
                    <h3 class="p-col-6">{{'Credit-Card' | translate}}</h3>
                    <div class="p-col-6 p-text-right">
                        <span><i class="fab fa-cc-visa p-ml-2 ic-blue ic-size-22"></i></span>
                        <span><i class="fab fa-cc-mastercard p-ml-2 ic-blue ic-size-22"></i></span>
                        <span><i class="fab fa-cc-discover p-ml-2 ic-blue ic-size-22"></i></span>
                        <span><i class="fab fa-cc-amex p-ml-2 ic-blue ic-size-22"></i></span>
                    </div>
                </div>
                <div class="p-fluid">
                    <div class="p-field p-grid p-mt-2">
                        <div class="p-col-12">
                            <p-checkbox binary="true" class="font-size-checkbox_mailconfig"
                                label="{{'billing.Activate-Automatic-billing' | translate}}"
                                formControlName="InvoiceReminderActivate">
                            </p-checkbox>
                        </div>
                    </div>
                    <!-- Card type -->
                    <div class="p-grid p-mt-2">
                        <div class="p-fluid p-col-12">
                            <p-radioButton name="groupname" value="true" label="Visa/MasterCard/Discover"
                                [(ngModel)]="isVisaCard" [ngModelOptions]="{standalone: true}">
                            </p-radioButton>
                            <p-radioButton name="groupname" value="false" label="Amex" class="p-ml-2"
                                [(ngModel)]="isVisaCard" [ngModelOptions]="{standalone: true}">
                            </p-radioButton>
                        </div>
                    </div>
                    <!-- Amount -->
                    <div class="p-field p-grid p-mt-2">
                        <div class="p-col-12">
                            <div>
                                <label for="cardNumber" class="required-field label-text">{{'billing.Amount-to-Charge' |
                                    translate}}:</label>
                                <div>
                                    <input type="text" pInputText class="form-control width-100"
                                        formControlName="amount" readonly="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Company Name -->
                    <div class="p-field p-grid p-mt-2">
                        <div class="p-col-12">
                            <div>
                                <label class="label-text" for="cupncode">{{'company' | translate}}</label>
                                <div>
                                    <input type="text" pInputText class="form-control width-100"
                                        formControlName="company" readonly="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Card Holder Name -->
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div>
                                <label for="cardNumber"
                                    class="label-text  required-field">{{'billing-invoicing.name-on-card' |
                                    translate}}</label>
                                <div>
                                    <input type="text" pInputText class="form-control width-100"
                                        formControlName="nameOnCard" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Card Number -->
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div>
                                <label for="cardNumber"
                                    class="label-text required-field">{{'billing-invoicing.card-number' |
                                    translate}}</label>
                                <div class="input-group" *ngIf="isVisaCard ==='true'">
                                    <p-inputMask mask="9999-9999-9999-9999" formControlName="cardno" autofocus
                                        styleClass="form-control width-100"></p-inputMask>
                                    <span class="input-group-addon"><i class="fa fa-credit-card"></i></span>
                                </div>
                                <div class="input-group" *ngIf="isVisaCard ==='false'">
                                    <p-inputMask mask="9999-999999-99999" formControlName="cardno" autofocus
                                        styleClass="form-control width-100"></p-inputMask>
                                    <span class="input-group-addon"><i class="fa fa-credit-card"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- EXP Date CVV -->
                    <div class="p-grid">
                        <div class="p-col-7">
                            <label for="cardExpiry"
                                class="label-text  required-field"><span>{{'billing-invoicing.expiration-date' |
                                    translate}}</span></label>
                            <p-inputMask mask="99/99" formControlName="expDate" styleClass="form-control width-100"
                                autofocus>
                            </p-inputMask>
                        </div>
                        <div class="p-col-5">
                            <label class="label-text" for="cardCVC">CV {{'Code' | translate}}</label>
                            <p-inputMask mask="999?9" formControlName="securityCode" styleClass="form-control width-100"
                                autofocus></p-inputMask>
                        </div>
                    </div>
                    <!-- Effective Start Date/ Interval -->
                    <div class="p-grid">
                        <div class="p-col-7">
                            <label for="cardExpiry"
                                class="label-text required-field"><span>{{'billing.Effective-Start-Date' |
                                    translate}}</span></label>
                            <p-calendar formControlName="InvoiceReminderStartDate" [monthNavigator]="true"
                                [yearNavigator]="true" yearRange="2000:2099" class="form-control width-100"
                                showButtonBar="true"></p-calendar>
                        </div>
                        <div class="p-col-5">
                            <label class="label-text" for="cardCVC">{{'Interval' | translate}}</label>
                            <p-inputMask mask="9?999" formControlName="securityCode" styleClass="form-control width-100"
                                autofocus></p-inputMask>
                        </div>
                    </div>
                </div>
                <!-- Buttons -->
                <div class="p-grid p-mt-5 p-text-right">
                    <div class="p-col-12">
                        <button type="button" pButton [disabled]="(!isDialogFormValid)"
                            (click)="singularProcessPayment();" label="{{'save_button' | translate}}"
                            class="p-mr-2"></button>
                        <button type="button" pButton class="p-mr-2" (click)="cardDialogDisplay=false;"
                            label="{{'cancel_button' | translate}}"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</p-dialog>