import { Component, Input, OnInit } from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, MangoApiService } from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: "app-manage-roles",
  templateUrl: "./manage-roles.component.html",
})
export class ManageRolesComponent implements OnInit {
  @Input() permissionProfiles: any;
  @Input() selectedProfileToManage: any;
  @Input() groupedPermissions: any;
  @Input() masterPermissions: any;
  @Input() isFromUsers: boolean;
  isShowAddRoleDialog: boolean = false;
  formObj: { title: string } = { title: "" };
  companyID

  constructor(
    public mangoAPISrvc: MangoApiService,
    public translate: TranslateService,
    public encrDecSrvc: EncrDecrService,
  ) {}

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
  }

  get isManageRoleReadOnly(): boolean {
    const roleSelected = this.permissionProfiles?.find(
      (item) => item.value == this.selectedProfileToManage
    )?.role;
    if (roleSelected) {
      return roleSelected?.isReadOnly;
    }
    return false;
  }

  onCloseAddRoleDialog() {
    this.formObj.title = "";
    this.isShowAddRoleDialog = false;
  }

  showAddDialog() {
    this.isShowAddRoleDialog = true;
  }

  onCreateRole() {
    const roleValues = [];
    let rolesToSave = "";
    this.masterPermissions?.forEach((item) => {
      roleValues.push({
        id: item.MasterPermissionID,
        val: true,
        masterPermission: item,
      });
    });
    roleValues.map((item, index) => {
      const nesArr = [];
      nesArr.push(item.id != "" ? item.id : `""`);
      nesArr.push(item.val != "" ? item.val : `false`);
      if (index > 0) rolesToSave += `, {${nesArr.toString()}}`;
      else rolesToSave += `{${nesArr.toString()}}`;
    });
    const obj = {
      RoleValues: `{${rolesToSave}}`,
      Title: this.formObj.title,
      CompanyID: this.companyID,
    };
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.createStaffRole(obj).subscribe(
      (result: any) => {
        this.permissionProfiles.push({
          label: result.data.Title,
          value: result.data.StaffRoleID,
          role: result.data,
        });
        this.selectedProfileToManage = result.data.StaffRoleID;
        this.groupedPermissions[result.data.StaffRoleID] = roleValues;
        this.onCloseAddRoleDialog();
        this.mangoAPISrvc.notify("success", "Success!", AppConstants.createMsg);
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.createErrorMsg
        );
      }
    );
  }

  deleteRole() {
    const roleSelected = this.permissionProfiles?.find(
      (item) => item.value == this.selectedProfileToManage
    )?.role;
    if (roleSelected?.canDelete) {
      Swal.fire({
        title: this.translate.instant("confirmation"),
        html: this.translate.instant("permissions.delete_staff_role"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.translate.instant("Yes_Proceed"),
        cancelButtonText: this.translate.instant("no_cancel"),
      }).then((result) => {
        if (result.value) {
          this.mangoAPISrvc
            .deleteStaffRole(this.selectedProfileToManage)
            .subscribe(
              (res) => {},
              (err) => console.log("Failed to delete", err)
            );
          this.permissionProfiles = this.permissionProfiles?.filter(
            (profile) => profile.value !== this.selectedProfileToManage
          );
          this.selectedProfileToManage =
            this.permissionProfiles?.length > 0
              ? this.permissionProfiles[0]?.value
              : null;
        }
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Failed to Delete",
        text: `This role cannot be deleted!`,
        showConfirmButton: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: "Ok",
      });
    }
  }

  onClickAllowDenyAccess(data) {
    if (this.isManageRoleReadOnly) {
      Swal.fire({
        icon: "warning",
        title: "Read Only!",
        text: `This role cannot be modified!`,
        showConfirmButton: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: "Ok",
      });
      return;
    }
    data.val = !data.val;
    if (!data?.masterPermission?.ParentPermissionID) {
      const parentPermissionID = data?.id;
      this.groupedPermissions[this.selectedProfileToManage]?.forEach(
        (item, index) => {
          if (item?.masterPermission?.ParentPermissionID === parentPermissionID)
            item["val"] = data.val;
        }
      );
    } else {
      const parentPermissionID = data?.masterPermission?.ParentPermissionID;
      if (parentPermissionID) {
        this.groupedPermissions[this.selectedProfileToManage]?.forEach(
          (item, index) => {
            if (parentPermissionID === item?.id) {
              const noOfChildAllowed = this.groupedPermissions[
                this.selectedProfileToManage
              ]?.reduce((a, b) => {
                return b?.masterPermission?.ParentPermissionID ===
                  parentPermissionID && b?.val
                  ? a + 1
                  : a;
              }, 0);
              item["val"] = noOfChildAllowed > 0 ? true : parentPermissionID === 3 ? true : false;
            }
          }
        );
      }
    }
    let columnsToSave = "";
    this.groupedPermissions[this.selectedProfileToManage]?.map(
      (item, index) => {
        if (index > 0) columnsToSave += `, {${item.id}, ${item.val}}`;
        else columnsToSave += `{${item.id}, ${item.val}}`;
      }
    );
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .updateStaffRole(
        {
          RoleValues: `{${columnsToSave}}`,
        },
        this.selectedProfileToManage
      )
      .subscribe(
        (result: any) => {
          this.mangoAPISrvc.notify(
            "success",
            "Success",
            AppConstants.updateMsg
          );
          this.mangoAPISrvc.showLoader(false);
        },
        (err) => {
          this.mangoAPISrvc.notify(
            "error",
            "Error",
            AppConstants.updateErrorMsg
          );
          this.mangoAPISrvc.showLoader(false);
        }
      );
  }
}
