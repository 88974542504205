import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "@environments/environment";

declare let numeral: any;

@Component({
  selector: 'app-engagements-history',
  templateUrl: './engagements-history.component.html'
})
export class EngagementsHistoryComponent implements OnInit {
  public tempInvoice2 = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  public selectedClientName: string = null;
  projectClientID: number = -1;
  projectID: number = -1;
  selectedEngagementName: string = null;
  companyIdRef: number = -1;

  public termsList: any = [];
  public shortcutLabels: any = [];
  invoiceTemplates: SelectItem[];
  public lineItems: any = [];
  lineItemsCols: any[];
  public expensiveCols: any[];
  public expensesItems: any = [];
  public showText1: any = "Show";
  public showText2: any = "Show";
  public isTopShow: boolean = false;
  public isBottomShow: boolean = false;
  public rawshortcutLabels: any = [];
  allInvoices;
  countOpenInvoices;
  countBilledInvoices;
  public nameCT: any;
  public invoiceForm: UntypedFormGroup;
  selectedInvoiceTemplate: any;
  isFormValid: boolean = false;
  public nativeWindow: any;
  isEditDialogOpen = false;
  invoices = [];
  activitiesTypes: any;
  totalAmountAppliedRetainer = [];
  childCols: any[];
  totalInvoiceAmount: any = 0;
  totalDiscount: any = 0;
  totalPaymentsApplied: any = 0;
  totalLateFeeAmount: any = 0;
  totalInvoiceBalance: any = 0;
  invoiceOption: SelectItem[];

  constructor(private _fb: UntypedFormBuilder, private router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService,protected mangoUtils: mangoUtils, public translate: TranslateService) {
    this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.projectClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.selectedEngagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);
    this.companyIdRef = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.shortcutLabels = this.encrDecSrvc.getObject(AppConstants.shotHands);
    this.initializeInvoiceForm();
    this.getShortCutLabels();

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + 'H' + AppConstants.SearchString);
    this.getShortCutLabels();
    this.LoadDefaults();
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('engagement')},
      { label: this.translate.instant('billing-invoicing.invoice-history')},
      { label: this.selectedClientName, icon: 'ic-red' },
      { label: this.selectedEngagementName, icon: 'ic-red' }
    ]);
    this.invoiceOption = [
      { label: this.translate.instant('Accounts.open-invoices'), value: 'open' },
      { label: this.translate.instant('paid_invoices'), value: 'billed' },
      { label: this.translate.instant('all_invoices'), value: 'all' }
    ];
    this.nameCT = this.invoiceOption[0];
  }

  ngOnInit(): void {
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'H' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'H' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  redirectListView() {
    this.encrDecSrvc.addObject(AppConstants.projectID, "");
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }

  initializeInvoiceForm() {
    this.invoiceForm = this._fb.group({
      BillingHeaderID: [''],
      ClientName: [''],
      InvoiceNumber: [''],
      InvoiceAmount: [''],
      InvoiceDate: [new Date(), [<any>Validators.required]],
      DescriptionShort: [''],
      BillNote: [''],
      BillNoteTop: [''],
      selectedInvoiceTemplate: [''],
      customerTermId: [''],
      CopytoClipBoard: [false]
    });
    this.invoiceForm.valueChanges.subscribe(data => {
      this.validateForm();
    })
  }

  previewTimeHistoryReport(obj) {
    let url;
    url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3ATime%20History%20by%20Invoice.prpt/viewer?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;

    const newWindow = this.nativeWindow.open(decodeURI(url));
    newWindow.location = url;
  }

  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.invoiceForm.controls).forEach(key => {
      if (this.invoiceForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.invoiceForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.invoiceForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  getShortCutLabels() {
    if (this.rawshortcutLabels.length > 1) {
      return false;
    }
    this.rawshortcutLabels = [];
    for (let i = 0; i < this.shortcutLabels.length; i++) {
      const shortcut = this.shortcutLabels[i];
      if (shortcut["Inactive"]) {
        continue;
      }
      this.rawshortcutLabels.push(shortcut.ShortCutCode);
    }
  }

  replaceShortcuts() {
    const value = this.invoiceForm.value["DescriptionShort"];
    const value2 = this.convertShortcuts(value);
    this.invoiceForm.controls["DescriptionShort"].setValue(value2);
    const desValue = this.invoiceForm.controls['DescriptionShort'].value;
    if (desValue == null || desValue.trim() == "") {
      this.invoiceForm.controls['CopytoClipBoard'].setValue(false);
    }
  }

  private convertShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.shortcutLabels.length; i++) {
        const shortcut = this.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(" ");
  }

  findChoices(searchText: string) {
    return this['viewContainerRef']['parentInjector']['view']['component']['rawshortcutLabels'].filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel(choice: string) {
    return `${choice} `;
  }

  LoadDefaults() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.fireEngagementBadgeView(true);
    self.mangoAPISrvc.loadHistoryDefaults(this.projectClientID, this.projectID).subscribe((results: any) => {
      self.getTerms(results[0], results[1]);
      self.activitiesTypes = results[2];
      self.getInvoicesDataSet(results[3]);
      self.mangoAPISrvc.showLoader(false);
    });
  }

  getTerms(obj, data) {
    const parent = this;
    parent.termsList = [];
    for (let i = 0; i < obj.length; i++) {
      if (!obj[i].Inactive) {
        parent.termsList.push({ label: obj[i].TermsDescription, value: obj[i].TermsID, GraceDays: obj[i].GraceDays })
      }
    }

    if (data.TermsID != null) {
      parent.invoiceForm.controls['customerTermId'].setValue(data.TermsID);
    } else {
      parent.invoiceForm.controls['customerTermId'].setValue(parent.termsList[0].value);
    }
  }

  getInvoicesDataSet(data) {
    this.countOpenInvoices = 0;
    this.countBilledInvoices = 0;
    this.allInvoices = 0;
    const parent = this;

    data.map(function (obj) { obj['isExpandble'] = obj.paymentDetails.length > 0 ? true : false; return obj; });
    parent.invoices = data;
    for (let i = 0; i < parent.invoices.length; i++) {

      parent.invoices[i].index = i;
      parent.invoices[i].LateFeeAmount = parent.invoices[i].LateFeeAmount ? numeral(parent.invoices[i].LateFeeAmount).value() : 0;
      parent.invoices[i].InvoiceBalance = parent.invoices[i].InvoiceBalance ? numeral(parent.invoices[i].InvoiceBalance).value() : 0;

      const invoiceAmt = numeral(parent.invoices[i].InvoiceAmount).value();
      parent.invoices[i].InvoiceAmount = invoiceAmt ? invoiceAmt : 0;

      const invoiceDis = numeral(parent.invoices[i].Discount).value();
      parent.invoices[i].Discount = invoiceDis ? invoiceDis : 0;

      const invoiceApp = numeral(parent.invoices[i].PaymentsApplied).value();
      parent.invoices[i].PaymentsApplied = invoiceApp ? invoiceApp : 0;

      const invoiceBal = numeral(parent.invoices[i].InvoiceBalance).value();
      parent.invoices[i].InvoiceBalance = invoiceBal ? invoiceBal : 0;

      if (!parent.invoices[i].TotalPayments) {
        parent.invoices[i].TotalPayments = parent.invoices[i].TotalPayments ? parent.invoices[i].TotalPayments : 0;
      }

      if (parent.invoices[i].InvoiceBalance == 0) {
        parent.countBilledInvoices++;
      }
      else {
        parent.countOpenInvoices++;
      }
    }
    parent.allInvoices = parent.countOpenInvoices + parent.countBilledInvoices;
    parent.tempInvoice2 = [...parent.invoices];
    parent.chageFilterAIA('open');
    parent.loadFilterGrid();
  }

  chageFilterAIA(name) {
    if (name != 'open') {
      this.nameCT = name.value;
    }
    const strvalue  = name.value ? name.value.value : 'open';
    if (strvalue == 'open') {
      this.tempInvoice2 = this.invoices.filter((item) => {
        let invoiceBal = numeral(item.InvoiceBalance).value();
        invoiceBal = invoiceBal ? invoiceBal : 0.00;
        return invoiceBal != 0;
      });
    }
    else if (strvalue == 'billed') {
      this.tempInvoice2 = this.invoices.filter((item) => {
        let invoiceBal = numeral(item.InvoiceBalance).value();
        invoiceBal = invoiceBal ? invoiceBal : 0.00;
        return invoiceBal == 0;
      });
    }
    else {
      this.tempInvoice2 = [...this.invoices];
    }
    this.calculateFooterTotals();
  }

  calculateFooterTotals() {
    const self = this;
    self.totalInvoiceAmount = self.tempInvoice2.reduce(function (a, b) { return a + +numeral(b.InvoiceAmount).value(); }, 0);
    self.totalDiscount = self.tempInvoice2.reduce(function (a, b) { return a + +numeral(b.Discount).value(); }, 0);
    self.totalPaymentsApplied = self.tempInvoice2.reduce(function (a, b) { return a + +numeral(b.TotalPayments).value(); }, 0);
    self.totalInvoiceBalance = self.tempInvoice2.reduce(function (a, b) { return a + +numeral(b.InvoiceBalance).value(); }, 0);
    self.totalLateFeeAmount = self.tempInvoice2.reduce(function (a, b) { return a + +numeral(b.LateFeeAmount).value(); }, 0);
  }

  previewInvoice(event: any, obj) {
    if (numeral(obj.InvoiceAmount) && numeral(obj.InvoiceAmount).value() < 0) {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Information'),
        text: this.translate.instant('credit-memo-printed-message'),
        showConfirmButton: false,
        timer: 3500
      })
    } else {
      let url;
      const InvTemplate = obj['InvoiceTemplate']
      switch (InvTemplate) {
        case '4': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceMiniStatement.prpt/report?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '6': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceDetailMemoNoRate.prpt/report?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '7': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceDetailMemoNoRateHrs.prpt/report?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '1': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceDetail.prpt/report?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '0': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceDetailNoMemo.prpt/report?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '8': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceNarrativeSummaryDetail.prpt/report?ClientID=" + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '9': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceNarrativeSummaryDetail.prpt/report?ClientID=" + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '15': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceDetailSummary.prpt/report?ClientID=" + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
        case '16': {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceNarrativeSummarybyActivity.prpt/report?ClientID=" + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        } 
        default: {
          url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3AInvoiceNarrative.prpt/report?ClientID=" + this.projectClientID + "&BillingHeaderID=" + obj.BillingHeaderID;
          break;
        }
      }

      const newWindow = this.nativeWindow.open(decodeURI(url));
      newWindow.location = url;
    }
  }

  reverseInvoice(invoiceObj,index) {
    const self = this;
    if (invoiceObj.InvoiceAmount < 0) {
      self.mangoAPISrvc.notify('error', 'Error!', "A Retainer Payment can't be reversed since it is associated with a Deposit. Try reversing the Deposit.");
      return false;
    } else if (invoiceObj.TotalPayments > 0) {
      self.mangoAPISrvc.notify('error', 'Error!', "An Invoice can not be reversed that has payments applied.  A Payment must be reversed first.");
      return false;
    } else {
      Swal.fire({
        title: 'Reverse Invoice Number: #' + invoiceObj.InvoiceNumber,
        html: '<div>Time records will be released as unbilled.</div><div>All Time Records will be restored to WIP.</div><div> Invoice information will be deleted.</div><br><div>Are you sure you want to reverse this invoice ?</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: self.translate.instant('yes_continue'),
        cancelButtonText: self.translate.instant('no_cancel')
      }).then((result) => {
        if (result.value) {
          self.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc.reverseInvoice(invoiceObj.BillingHeaderID).subscribe((data:any) => {
            self.invoices.splice(self.invoices.findIndex(x => x.BillingHeaderID == invoiceObj.BillingHeaderID), 1);
            self.dataTableComponent.reset();
            self.mangoAPISrvc.fireEngagementBadgeView(true);
            self.chageFilterAIA(self.nameCT);
            self.mangoAPISrvc.notify('success', self.translate.instant('Success'), data.message);
            self.mangoAPISrvc.showLoader(false);
          }, error => {
              self.mangoAPISrvc.notify('error', self.translate.instant('error'), error ?? AppConstants.deleteErrorMsg);
              self.mangoAPISrvc.showLoader(false);
          });
        }
      })
    }
  }

  editInvoice(itemData) {
    const parent = this;
    parent.selectedInvoiceTemplate = "";
    parent.isEditDialogOpen = true;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getBillingDetailRecords(itemData['BillingHeaderID']).subscribe((data) => {
      parent.invoiceForm.controls['BillingHeaderID'].setValue(itemData['BillingHeaderID']);
      parent.invoiceForm.controls['ClientName'].setValue(parent.selectedClientName);
      parent.invoiceForm.controls['InvoiceNumber'].setValue(itemData['InvoiceNumber']);
      parent.invoiceForm.controls['InvoiceAmount'].setValue(itemData['InvoiceAmount']);
      itemData['InvoiceDate'] = (itemData['InvoiceDate']) ? new Date(itemData['InvoiceDate']) : itemData['InvoiceDate'];
      parent.invoiceForm.controls['InvoiceDate'].setValue(itemData['InvoiceDate']);
      parent.invoiceForm.controls['DescriptionShort'].setValue(itemData['DescriptionShort']);
      parent.invoiceForm.controls['BillNoteTop'].setValue(itemData['BillNoteTop']);
      parent.invoiceForm.controls['BillNote'].setValue(itemData['BillNote']);
      parent.mangoUtils.setPropertyById(parent.activitiesTypes, data, 'ServiceCodeID', 'Description', 'ServiceCode');
      parent.lineItems = data;
      if (itemData.InvoiceTemplate != null) {
        if (isNaN(itemData.InvoiceTemplate)) {
          parent.selectedInvoiceTemplate = parent.invoiceTemplates.filter((client) => client['label'] == itemData.InvoiceTemplate)[0].value;
        } else {
          parent.selectedInvoiceTemplate = parent.invoiceTemplates.filter((client) => client['value'] == itemData.InvoiceTemplate)[0].value;
        }
      } else {
        parent.selectedInvoiceTemplate = parent.invoiceTemplates[0].value;
      }
      parent.invoiceForm.controls['selectedInvoiceTemplate'].setValue(parent.selectedInvoiceTemplate);
      parent.mangoAPISrvc.getSettingData(itemData['ClientID']).subscribe((data: any) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.invoiceForm.controls['customerTermId'].setValue(data.TermsID);
      });
      parent.mangoAPISrvc.getExpenseMasterList(itemData['BillingHeaderID']).subscribe((data) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.expensesItems = data;
      });
    });
  }

  saveInvoice(billingHeaderObj: any) {
    const billingDetailsList = [];
    for (let i = 0; i < this.lineItems.length; ++i) {
      const billingDetailRecord = {};
      billingDetailRecord['ClientID'] = billingHeaderObj.ClientID;
      billingDetailRecord['BillingHeaderID'] = billingHeaderObj.BillingHeaderID;
      billingDetailRecord['InvoiceDate'] = billingHeaderObj.InvoiceDate;
      billingDetailRecord['Amount'] = this.lineItems[i].Amount;
      billingDetailRecord['InvoiceNumber'] = billingHeaderObj.InvoiceNumber;
      billingDetailRecord['Description'] = this.lineItems[i].Description;
      billingDetailRecord['ServiceCodeID'] = this.lineItems[i].ServiceCodeID;
      billingDetailRecord['ProjectID'] = this.lineItems[i].ProjectID;
      billingDetailRecord['StaffID'] = this.lineItems[i].StaffID;
      billingDetailRecord['BillingDetailID'] = this.lineItems[i].BillingDetailID;
      billingDetailsList.push(billingDetailRecord);
    }
  }
}
