import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { CommonModule } from '@angular/common';

import { SetupWizardRoutingModule } from './setup-wizard-routing.module';
import { SetupWizardComponent } from './setup-wizard.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { CompanyLogoComponent } from './company-logo/company-logo.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { FinishedComponent } from './finished/finished.component';
import { MenuComponent } from './menu/menu.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';


@NgModule({
  declarations: [WelcomeComponent, SetupWizardComponent, CompanyInfoComponent, CompanyLogoComponent, CompanySettingsComponent, FinishedComponent, MenuComponent, UserSettingsComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    SetupWizardRoutingModule
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SetupWizardModule { }
