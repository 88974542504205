import { Injectable } from '@angular/core';
import { IProposal } from './el-proposal/el-proposal.component';
import { EffectiveFromEnum } from './el-proposal/general/general.component';
import { Subject } from 'rxjs';
import { EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { environment } from '@environments/environment';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class ELSharedService {
  filterDialogSub: Subject<any> = new Subject<any>();
  unsaved: boolean = true;
  proposalObj: IProposal = {
    ELProposalID: null,
    ProposalName: 'Untitled Proposal',
    EffectiveFrom: EffectiveFromEnum.ON_ACCEPTANCE,
    SpecificStartDate: new Date(),
    MinContractMonths: 12,
    Client: {
      ClientID: null,
      ClientName: '',
      ContactName: '',
      ContactEmail: '',
      ClientAddress: ''
    },
    isAllowACHTransfer: false,
    isAllowCreditCard: false,
    isRequirePayment: true,
    IntroMessageHtml: '',
    isShowPerPriceService: false,
    isShowOneOffAndDeposit: true,
    isShowMinimumPrice: false,
    NotifyReadOnlyProposalEmails: [],
    ELTermID: null,
    ELTermHtmlText: '',
    ELTermName: '',
    ELProjectHeaders: [],
    Value: 0,
    Status: 'Draft',
    DateCreated: null,
    LastModified: null,
    Code: null,
    AcceptedTimeStamp: null,
    Base64Signature: null,
    isPaid: false,
    ContactName: '',
    ContactEmail: '',
    autoRenew: false,
    enableEmailNotif: false,
    percentageIncrease: 0,
    notifyDays: 0,
    isReadyToEmail: false,
    AnniversaryDate: null,
    DateELBilling: null,
    ELBillAmount: '',
    isEmailReminderEnabled: false,
    ProposalReminderInterval: 1,
    NewProposalEmailTemplate: null,
    AcceptedProposalEmailTemplate: null,
    ReadonlyProposalEmailTemplate: null
  };
  elSettings: any;
  companyName: any;
  proposal: any;
  companyDetails: any;
  setting: any;
  clientCount: number = 0;
  // shouldDisableSave: boolean = false;
  hasFilters: boolean = false;
  engagementTypeId: number;
  clientsList: any[];
  selectedClientList: any = [];
  clientLimitReached: boolean = false;

  isClientsLoaded = new Subject<boolean>();
  isClientsLoaded$ = this.isClientsLoaded.asObservable();
  isProposalUpdated = new Subject<boolean>();
  isProposalUpdated$ = this.isProposalUpdated.asObservable();
  mangoCompanyData: any;
  clientLimitMaxed: boolean;
  isBatch: boolean;
  state: string;
  overrideDisableSaving: boolean;
  ELProposals: any;

  constructor(
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.mangoCompanyData = this.encrDecSrvc.getObject(AppConstants.companySettings);
    this.companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    this.companyDetails = this.encrDecSrvc.getObject(AppConstants.companySettingsData);
    this.setting = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
  }

  public get shouldDisableSendAndPreview(): Boolean {
    if (
      this.proposalObj?.Client &&
      this.proposalObj?.Client?.ClientID &&
      this.proposalObj?.Client?.ContactEmail
    ) {
      return false;
    }
    return true;
  }

  public getNoOfFreeClientsPerTier(key) {
    let obj = {
      '3': 100, //ENTERPRISE
      '2': 50, //PRO
      '1': 25, //SOLO
      ENTERPRISE: 100,
      PRO: 50,
      SOLO: 25
    };
    return obj[key] ?? 0;
  }

  public getTierAmountPerClient(key) {
    let obj = {
      '3': 1.5, //ENTERPRISE
      '2': 2.5, //PRO
      '1': 3.5, //SOLO
      ENTERPRISE: 1.5,
      PRO: 2.5,
      SOLO: 3.5
    };
    return obj[key] ?? 0;
  }

  public get shouldDisableSave(): Boolean {
    const isBatch = this.router.url.includes('batch');

    if (
      ((!isBatch &&
        this.proposalObj?.Client &&
        this.proposalObj?.Client?.ClientID &&
        this.proposalObj?.Client?.ContactEmail) ||
        (isBatch && this.selectedClientList.length > 0)) &&
      !this.clientLimitMaxed
    ) {
      return false;
    }
    return true;
  }

  public get resetProposalObj(): IProposal {
    const isAllowACHTransfer = this.setting.modeOfPayment == 2;
    const isAllowCreditCard = this.setting.modeOfPayment == 2 || this.setting.modeOfPayment == 1;
    return {
      ELProposalID: null,
      ProposalName: 'Untitled Proposal',
      EffectiveFrom: EffectiveFromEnum.ON_ACCEPTANCE,
      SpecificStartDate: new Date(),
      MinContractMonths: 12,
      Client: {
        ClientID: null,
        ClientName: '',
        ContactName: '',
        ContactEmail: '',
        ClientAddress: ''
      },
      isAllowACHTransfer,
      isAllowCreditCard,
      isRequirePayment: (isAllowACHTransfer || isAllowCreditCard) ?? false,
      IntroMessageHtml: '',
      isShowPerPriceService: false,
      isShowOneOffAndDeposit: true,
      isShowMinimumPrice: false,
      NotifyReadOnlyProposalEmails: [],
      ELTermID: null,
      ELTermHtmlText: '',
      ELTermName: '',
      ELProjectHeaders: [],
      Value: 0,
      Status: 'Draft',
      DateCreated: null,
      LastModified: null,
      Code: null,
      AcceptedTimeStamp: null,
      Base64Signature: null,
      isPaid: false,
      ContactName: '',
      ContactEmail: '',
      autoRenew: false,
      enableEmailNotif: false,
      notifyDays: 0,
      percentageIncrease: 0,
      isReadyToEmail: false,
      AnniversaryDate: null,
      DateELBilling: null,
      ELBillAmount: '',
      isEmailReminderEnabled: false,
      ProposalReminderInterval: 1,
      NewProposalEmailTemplate: null,
      AcceptedProposalEmailTemplate: null,
      ReadonlyProposalEmailTemplate: null
    };
  }

  openFilterTaskDialogSub(data: any) {
    this.filterDialogSub.next(data);
  }

  updateProposalTotal(newTotal: number) {
    this.proposalObj.Value = newTotal;
  }

  replaceWildcards(htmlContent, obj?) {
    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%ProposalName%',
      (obj ?? this.proposalObj).ProposalName ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%Total%',
      (obj ?? this.proposalObj).Value ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(htmlContent, '&nbsp;', ' ');

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%ContactName%',
      (obj ?? this.proposalObj).Client.ContactName ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%ClientName%',
      (obj ?? this.proposalObj).Client.ClientName ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(htmlContent, '%CompanyName%', this.companyName ?? '');

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%ClientAddress%',
      (obj ?? this.proposalObj).Client.ClientAddress ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%PortalLink%',
      `https://${environment.BASE_SECURE_PORTAL_DOMAIN}/#/proposal/${
        (obj ?? this.proposalObj).ELProposalID
      }/${(obj ?? this.proposalObj).Code}`
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%CompanyPhone%',
      this.companyDetails.Telephone ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%CompanyEmail%',
      this.companyDetails.EmailCompany ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%Website%',
      this.companyDetails.WebsiteCompany ?? ''
    );

    htmlContent = this.mangoUtils.replaceAll(
      htmlContent,
      '%ProposalStartDate%',
      (obj ?? this.proposalObj).SpecificStartDate
        ? moment((obj ?? this.proposalObj).SpecificStartDate).format('MM/DD/YYYY')
        : ''
    );

    return htmlContent;
  }

  onClientSelect(selectedClientsItems: any[]) {
    this.selectedClientList = selectedClientsItems;
  }

  getClientCount() {
    let parent = this;
    return new Promise<number>(async function (resolve, reject) {
      try {
        parent.mangoAPISrvc.getELClientCount().subscribe(
          function (data: any) {
            resolve(parseInt(data.count) ?? 0);
          },
          error => {
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  async printProposal() {
    const obj = this.proposalObj;

    return new Promise(function (resolve, reject) {
      const html = document.getElementById('printable-div');
      var clonedElement = html.cloneNode(true) as HTMLElement;
      clonedElement.style.display = 'block';
      clonedElement.style.top = '0px';
      // clonedElement.style.width = "900px";

      let opt = {
        margin: 1,
        filename: `AgreementDocument-${obj.ELProposalID}.pdf`,
        image: { type: 'png', quality: 1 },
        html2canvas: { scale: 4, dpi: 192 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: {
          before: '.html2pdf__page-break',
          avoid: ['img', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
        }
      };
      html2pdf()
        .set(opt)
        .from(clonedElement)
        .toPdf()
        .get('pdf')
        .then(function (pdf) {
          window.open(pdf.output('bloburl'), '_blank');
          resolve(1);
        });
      // doc.html(clonedElement , {
      //   callback: function (jsPdf) {
      //     doc.save(`AgreementDocument-${obj.ELProposalID}.pdf`);
      //     window.open(doc.output('bloburl').toString(), '_blank');
      //   },
      //   margin: [50, 50, 50, 50],
      //   autoPaging: 'text',
      //   html2canvas: {
      //       allowTaint: true,
      //       letterRendering: true,
      //       logging: false,
      //       scale: .6,
      //   },
      // });
    });
  }

  onEngagementChanged() {
    this.clientsList = [];
    const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);

    this.mangoAPISrvc.getCustomersByEngagementTypeID(this.engagementTypeId, companyId).subscribe(
      (result: any) => {
        let tempList = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
        result.map(obj => {
          obj['GroupDescriptionIDArrayDesc'] = '';
          obj['GroupDescriptionIDArray'] = obj['GroupDescriptionIDArray']
            ? obj['GroupDescriptionIDArray']
            : [];
          if (obj['GroupDescriptionIDArray'].length > 0) {
            let tempValues = obj['GroupDescriptionIDArray'].toString().split(',');
            for (var i = 0; i < tempValues.length; ++i) {
              if (tempValues[i]) {
                let matchedArr = tempList.filter(
                  item => item['CustomerGroupCategoryID'] == tempValues[i]
                );
                if (matchedArr.length > 0) {
                  if (i == 0) {
                    obj['GroupDescriptionIDArrayDesc'] += tempList.filter(
                      item => item['CustomerGroupCategoryID'] == tempValues[i]
                    )[0]['GroupDescription'];
                  } else {
                    obj['GroupDescriptionIDArrayDesc'] =
                      obj['GroupDescriptionIDArrayDesc'] +
                      ' , ' +
                      tempList.filter(item => item['CustomerGroupCategoryID'] == tempValues[i])[0][
                        'GroupDescription'
                      ];
                  }
                }
              }
            }
          }
        });
        this.clientsList = result;
        this.isClientsLoaded.next(true);
      },
      err => {
        this.isClientsLoaded.next(false);

        this.mangoAPISrvc.notify('error', 'Error', AppConstants.fetchErrorMsg);
      }
    );
  }

  computeTotals() {
    this.proposalObj.ELProjectHeaders = this.proposalObj?.ELProjectHeaders?.map(x => {
      let total = 0;
      for (const detail of x.ELProjectDetails) {
        total += detail.Total;
      }

      x.Total = total;
      return x;
    });
  }

  updateDateELBilling(ClientID, dt) {
    const _this = this;
    // return new Promise<void>(async function (resolve, reject) {
    //   _this.mangoAPISrvc.updateClientELBilling({ DateELBilling: dt, ClientID: ClientID }).subscribe(function (e) {
    _this.encrDecSrvc.clientList = _this.encrDecSrvc.clientList.map(x => {
      if (x.ClientID == ClientID) {
        x.DateELBilling = dt;
      }

      return x;
    });
    //       resolve();
    //     }, (error) => {
    //       _this.mangoAPISrvc.notify('error', 'Error', 'Error while saving client details.');
    //       reject();
    //     });
    //   });
  }
}
