<div class="engagement-list">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addNotesPopUp();" tooltipPosition="top"
                pTooltip="{{ 'add' | translate }}"></p-button>
            <button pButton pRipple label="{{ 'list' | translate }}" icon="fal fa-project-diagram"
                class="p-mr-1 p-button-sm" (click)="redirectListView()"></button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="notes" [rowHover]="true" [responsive]="true"
                        dataKey="CustomerNotesID" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                        [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="Ddate" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" 
                        [globalFilterFields]="['Ddate','NoteCategory','Subject','StaffName']" [resizableColumns]="true"
                        (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" showDelay="800" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                                <th class="width-8p p-text-center" pSortableColumn="Ddate">
                                    {{ 'date' | translate }}
                                    <p-sortIcon field="Ddate"></p-sortIcon>
                                </th>
                                <th class="width-15p p-text-left" pSortableColumn="NoteCategory">
                                    {{ 'client.note_category' | translate }}
                                    <p-sortIcon field="NoteCategory"></p-sortIcon>
                                </th>
                                <th class="width-20p p-text-left" pSortableColumn="Subject">
                                    {{ 'Subject' | translate }}
                                    <p-sortIcon field="Subject"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-left" pSortableColumn="StaffName">
                                    {{ 'client.note_originator' | translate }}
                                    <p-sortIcon field="StaffName"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-center">{{ 'last_modified' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-6p">
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                                            (click)="editNote(rowData)" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}"></i>
                                        <i (click)="deleteNote(rowData.CustomerNotesID)"
                                            class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                    </div>
                                </td>
                                <td (click)="editNote(rowData);" class="p-text-center width-8p">
                                    {{rowData.Ddate | date:'MM/dd/yyyy'}}
                                </td>
                                <td (click)="editNote(rowData);" class="p-text-left width-15p">{{rowData.NoteCategory}}</td>
                                <td (click)="editNote(rowData);" class="p-text-left width-20p">{{rowData.Subject}}</td>
                                <td (click)="editNote(rowData);" class="p-text-left width-12p">{{rowData.StaffName}}</td>
                                <td (click)="editNote(rowData);" class="width-8p p-text-center">
                                    {{rowData.LastModifiedDate | date:'MM/dd/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>