import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { LocationsComponent } from './locations/locations.component';
import { CompanySetupComponent } from './company-setup.component';
import { CanDeactivateForm } from '@app/modules/company/company-setup';
import { AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: CompanySetupComponent,
    children: [
      { path: '', component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 7 }},
      { path: AppConstants.setupSettings, component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 7 }},
      { path: AppConstants.setupLocations, component: LocationsComponent, canActivate: [AuthGuard], data: { 'featID': 7 } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class CompanySetupRoutingModule { }
