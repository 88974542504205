import { Component, OnInit } from "@angular/core";
import {
  BreadcrumbService
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { AppConstants } from "@app/_helpers/api-constants";

@Component({
  selector: "app-super-dashboard",
  templateUrl: "./super-dashboard.component.html",
})
export class SuperDashboardComponent implements OnInit {

  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;

  constructor(
    private _translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.breadcrumbService.setItems([
      { label: "Super Admin Dashboard" },
      { label: "Manage Accounts", icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {
    this.setMenuIndex();
    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      const url = this.router.url;
      if (url.includes(`/${AppConstants.manageAccounts}`)) this.selectedMenuIndex = 0;
      else if (url.includes(`/${AppConstants.mbRevenue}`)) this.selectedMenuIndex = 1;
      else if (url.includes(`/${AppConstants.customers}`)) this.selectedMenuIndex = 2;
      else if (url.includes(`/${AppConstants.cpaEnrollments}`)) this.selectedMenuIndex = 3;
      else if (url.includes(`/${AppConstants.elUsageMetering}`)) this.selectedMenuIndex = 4;

      this.loadMenu();
    })
  }

  loadMenu() {
    this.items = [
      {
        label: this._translate.instant("Manage_Accounts"),
        routerLink: [`/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.manageAccounts}`],
      },
      {
        label: this._translate.instant("MB_Revenue"),
        routerLink: [`/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.mbRevenue}`],
      },
      {
        label: this._translate.instant("Customers"),
        routerLink: [`/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.customers}`],
      },
      {
        label: this._translate.instant("cpaPayEnrollments"),
        routerLink: [`/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.cpaEnrollments}`],
      },
      {
        label: this._translate.instant("el_usage_metering"),
        routerLink: [`/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.elUsageMetering}`],
      },
    ];
    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;
    switch (url) {
      case `/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.mbRevenue}`: {
        this.selectedMenuIndex = 1;
        break;
      }
      case `/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.customers}`: {
        this.selectedMenuIndex = 2;
        break;
      }
      case `/${AppConstants.superAdmin}/${AppConstants.adminSuperAdmin}/${AppConstants.cpaEnrollments}`: {
        this.selectedMenuIndex = 3;
        break;
      }
      default: {
        this.selectedMenuIndex = 0;
        break;
      }
    }
  }
}
