<div *ngIf="tasksDataSource?.length > 0" class="manual">
  <p-panel header="Task(s) Budget" [toggleable]="true">
    <p-table [value]="tasksDataSource" [responsive]="true" [rowHover]="true" sortField="TaskRow" [sortOrder]="1"
      editMode="row" [dataKey]="isFromTemplate ? 'CompanyTemplateDetailsID' : 'ProjectDetailsID'" [scrollable]="true" [scrollHeight]="'calc(60vh - 170px)'"
      styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
      <ng-template pTemplate="header">
        <tr>
          <th class="width-5p p-text-center">
            {{ 'action' | translate }}
          </th>
          <th class="width-35p p-text-left">
            {{ 'Task-Description' | translate }}
          </th>
          <th class="width-5p p-text-center">
            {{ 'task' | translate }} #
          </th>
          <th class="width-15p p-text-right">
            Task Budgeted Time
          </th>
          <th class="width-15p p-text-right" *ngIf="!isFromTemplate">
            {{ 'actual_amount' | translate }}
          </th>
          <th class="width-15p p-text-right" *ngIf="!isFromTemplate">
            <span [style.color]="'#f28686'">Over</span> / <span [style.color]="'#33c126'">Under</span>
          </th>
          <th class="width-10p p-text-center">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rIndex="rowIndex" let-rowData let-editing="editing">
        <tr [pEditableRow]="rowData">
          <td class="width-5p p-text-center">
            <i class="fal fa-pencil p-pr-2 cursor-hand ic-green" pInitEditableRow (click)="onRowEditInit(rowData, rIndex)"
              pTooltip="{{ 'Edit' | translate }}" tooltipPosition="top"></i>
          </td>
          <td class="width-35p p-text-left p-text-truncate p-text-nowrap" pTooltip="{{ rowData.TaskDescription }}"
            tooltipPosition="top" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
            {{ rowData.TaskDescription }}
          </td>
          <td class="width-5p p-text-center">
            <div class="badge-number bg-blue">{{ rowData.TaskRow }}</div>
          </td>
          <td pInitEditableRow (click)="onRowEditInit(rowData, rIndex)" class="width-15p p-text-right">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="width-100p p-text-right rev-{{rIndex}}" type="text" pInputText 
                  [style.borderColor]="!!!mangoUtils.converToNum(rowData.TimeAmount) ? '#dc3545' : '#ced4da'"
                  (blur)="rowData.TimeAmount = mangoUtils.doMoneyFormat(rowData.TimeAmount, 'time');onTimeAmountChanged(rowData)"
                  [(ngModel)]="rowData.TimeAmount" (focus)="$event.target.select();" pKeyFilter="num"
                  [ngModelOptions]="{standalone: true}">
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.TimeAmount }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="width-15p p-text-right" *ngIf="!isFromTemplate">
            <p-progressSpinner *ngIf="rowData?.isLoadingActualAmt" [style]="{width: '1.5rem', height: '1.5rem'}"></p-progressSpinner>
            {{ !rowData?.isLoadingActualAmt ? ((rowData.ActualAmount) | number:'1.2-2')  : "" }}
          </td>
          <td class="width-15p p-text-right" *ngIf="!isFromTemplate">
            <p-progressSpinner *ngIf="rowData?.isLoadingDifference" [style]="{width: '1.5rem', height: '1.5rem'}"></p-progressSpinner>
            <span *ngIf="!rowData?.isLoadingDifference" [style.color]="mangoUtils.setWriteUpDownColor(rowData.Difference)">
              {{ (rowData.Difference < 0 ? (mangoUtils.getAbsolute(rowData.Difference) | number:'1.2-2' ) : rowData.Difference | number:'1.2-2' ) }} </span>
          </td>
          <td class="width-10p p-text-center">
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" [disabled]="!!!mangoUtils.converToNum(rowData.TimeAmount)"
              (click)="onRowEditSave(rowData);" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
              (click)="onRowEditCancel(rowData)" class="p-button-rounded p-button-text p-button-danger"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td class="width-5p p-text-center">
          </td>
          <td class="width-35p p-text-left ic-red">
            <!-- {{'Totals' | translate}} -->
          </td>
          <td class="width-5p p-text-center ic-red">
            {{'Totals' | translate}}
          </td>
          <td class="width-15p p-text-right ic-red">
            <strong>{{ footerTotal.TimeAmount | number:'1.2-2' }}</strong>
          </td>
          <td class="width-15p p-text-right ic-red" *ngIf="!isFromTemplate">
            <strong>{{ (footerTotal.ActualAmount < 0 ? (mangoUtils.getAbsolute(footerTotal.ActualAmount) |
              number:'1.2-2' ) : footerTotal.ActualAmount | number:'1.2-2' ) }}</strong>
          </td>
          <td class="width-15p p-text-right" [style.color]="mangoUtils.setWriteUpDownColor(footerTotal.Difference)" *ngIf="!isFromTemplate">
            <strong>{{ (footerTotal.Difference < 0 ? (mangoUtils.getAbsolute(footerTotal.Difference) |
              number:'1.2-2' ) : footerTotal.Difference | number:'1.2-2' ) }}</strong>
          </td>
          <td class="width-10p p-text-center"></td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
</div>
<div *ngIf="tasksDataSource.length == 0">
  <h4>{{ 'data_not_found' | translate }}</h4>
</div>