<div class="credit-cards">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple icon="fal fa-credit-card" label="Cancel" pTooltip="Cancel Credit Card Enrollment"
                tooltipPosition="top" styleClass="p-button-sm" class="p-button-help p-pr-2" (click)="deleteProfile();">
            </p-button>
        </span>
        <div class="p-grid p-mx-2">
            <div class="p-col-12">
                <div class="card p-mb-1 p-pl-5 p-py-3">
                    <label class="label-text p-mr-4">{{'Selected-Date-Range' | translate}}</label>
                    <p-dropdown [ngModelOptions]="{standalone: true}" [options]="dateRangeList"
                        [(ngModel)]="selectedDateOption" (onChange)="selectDateRange($event)" styleClass="width-14p">
                    </p-dropdown>
                </div>
                <div class="card p-pt-0">
                    <div class="p-col-12 p-pt-0">
                        <p-table [value]="dataSource" [responsive]="true" sortField="UploadedAt"
                            (onFilter)="onFilter($event)" [rows]="10"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [columns]="cols"
                            [globalFilterFields]="['FileName', 'UploadedAt', 'StartedAt', 'TotalRecords', 'NumSuccess', 'NumFailed', 'CompletedAt']"
                            [sortOrder]="-1" [rowHover]="true" #dt [rows]="dataSource.length" [scrollable]="true"
                            [scrollHeight]="'calc(90vh - 350px)'" [virtualScroll]="true">
                            <ng-template pTemplate="caption">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{ 'globalsearch' | translate }}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{ 'clear' | translate }}"></button>
                                        </span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                            styleClass="p-ml-2">
                                        </p-message>
                                    </div>
                                    <div class="p-col-6 p-text-right" *ngIf="dataSource.length > 0">
                                        <button type="button" pButton pRipple icon="pi pi-file-o"
                                            (click)="dt.exportCSV()" class="p-mx-2" pTooltip="CSV"
                                            tooltipPosition="top"></button>
                                       <!--  <button type="button" pButton pRipple icon="pi pi-file-excel"
                                            (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                            tooltipPosition="top"></button> -->
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                            (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                            tooltipPosition="top"></button>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-6p p-text-center">Date</th>
                                    <th class="width-7p p-text-right">Transaction</th>
                                    <th class="width-7p p-text-center">Card Type</th>
                                    <th class="width-8p p-text-left">Status</th>
                                    <th class="width-10p p-text-left">Customer</th>
                                    <th class="width-6p p-text-right">Amount</th>
                                    <th class="width-6p p-text-center">Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-userData>
                                <tr>
                                    <td class="width-6p p-text-center">{{userData.SubmitDate}}</td>
                                    <td class="width-7p p-text-right" showDelay="800" pTooltip="{{userData.Confirmation}}"
                                        tooltipPosition="top">{{userData.Confirmation}}</td>
                                    <td class="width-7p p-text-center">{{userData.PaymentType}}</td>
                                    <td class="width-8p p-text-left" showDelay="800" pTooltip="{{userData.udfield1}}"
                                        tooltipPosition="top">{{userData.udfield1}}</td>
                                    <td class="width-10p p-text-left"
                                        [ngClass]="(userData.isGrandTotal)?'p-text-bold p-text-danger ic-orange p-text-center':''">
                                        {{userData.CustomName}}</td>

                                    <td class="width-6p p-text-right p-text-bold ic-orange"
                                        *ngIf="userData.isGrandTotal">
                                        ${{userData.subAmount}}</td>
                                    <td class="width-6p p-text-right" *ngIf="!userData.isGrandTotal">
                                        ${{userData.Amount}}</td>
                                    <td class="width-6p p-text-center">
                                        <button pButton type="button" label="{{userData.udfield2}}"
                                            *ngIf="userData.udfield2 !=='' " (click)="doAction(userData)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <!-- <div class="p-d-flex p-jc-end">
                        <a href="JavaScript:void(0);" (click)="deleteProfile();">Cancel Credit Card Enrollment</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>