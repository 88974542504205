<p-dialog header="Expenses" [(visible)]="displayAddExpenseModal" [modal]="true" [style]="{width: '35vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="closeExpenses()">
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
        <div class="p-col-12">
            <div class="card">
                <form #ExpenseMainForm="ngForm">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="expenseCode" class="required-field label-text">{{'expense-code' |
                                translate}}</label>
                            <input type="text" pInputText [(ngModel)]="expense.ExpenseCode"
                                (change)="onChangeInputCode()" name="expenseCode" id="expenseCode"
                                #expenseCodeVl="ngModel" required minlength="3" maxlength="100" autocomplete="off">
                                <p class="p-error" *ngIf="expenseCode.length > 20">maximum of 20 characters.</p>
                        </div>
                        <div class="p-field p-col-6">
                            <label for="Cost" class="required-field label-text">{{'Cost' | translate}}</label>
                            <span class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="fal fa-dollar-sign"></i></span>
                               <!--  <p-inputNumber maxlength="12" placeholder="0.00" inputStyleClass="p-text-right" inputId="integeronly"
                                    autocomplete="off" [(ngModel)]="expense.Cost" name="expenseCost"
                                    #expenseCost="ngModel" (focus)="$event.target.select()" required mode="decimal"
                                    locale="en-US" [minFractionDigits]="2"></p-inputNumber> -->
                                    <input type="text" pInputText class="p-text-right" placeholder="0.00" #expenseCost="ngModel"
                                    inputId="integeronly"
                                    autocomplete="off" [(ngModel)]="expense.Cost" name="expenseCost"
                                    (keydown.Tab)="doMoneyFormate($event.target.value);"
                                    (blur)="doMoneyFormate($event.target.value)"
                                    maxlength="14" (focus)="$event.target.select();" />
                            </span>
                        </div>
                        <div class="p-field p-col-12">
                            <label for="Description" class="required-field label-text">{{'description' |
                                translate}}</label>
                            <textarea [rows]="5" [cols]="30" pInputTextarea [(ngModel)]="expense.Description"
                                name="expenseDescription" #expenseDescriptionVl="ngModel" required minlength="3"
                                autocomplete="off" maxlength="100" title="Description can have a maximum of 100 characters."
                                (focus)="$event.target.select()"></textarea>
                            
                        </div>
                        <!-- <div class="p-field p-col-3"
                            *ngIf="timeAndExpenseData && timeAndExpenseData['ActivateExpenseRates'] == true">
                            <p-checkbox name="tax1" [(ngModel)]="expense.TaxMap1" binary="true" name="tax1" id="tax1" #tax1="ngModel"
                                label="{{'tax' | translate}} 1"></p-checkbox>
                        </div>
                        <div class="p-field p-col-3"
                            *ngIf="timeAndExpenseData && timeAndExpenseData['ActivateExpenseRates'] == true">
                            <p-checkbox name="tax2" [(ngModel)]="expense.TaxMap2" binary="true" name="tax2" id="tax2" #tax2="ngModel"
                                label="{{'tax' | translate}} 2"></p-checkbox>
                        </div> -->
                        <div class="p-field p-col-3">
                            <p-checkbox name="billable" [(ngModel)]="expense.Billable" binary="true" name="billable" id="billable" #billable="ngModel"
                                label="{{'billable' | translate}}" (onChange)="ExpenseMainForm.control.markAsDirty()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-3">
                            <p-checkbox name="taxable" [(ngModel)]="expense.TaxMap1" binary="true" name="taxable" id="taxable" #taxable="ngModel"
                                label="{{'Taxable' | translate}}"
                                (onChange)="ExpenseMainForm.control.markAsDirty()"></p-checkbox>
                        </div>
                        <div class="p-field p-col-3" *ngIf="isEditExpenses">
                            <p-checkbox name="billable" [(ngModel)]="expense.Inactive" binary="true" name="inactive" id="inactive" #inactive="ngModel"
                                label="{{'inactive' | translate}}"
                                (onChange)="ExpenseMainForm.control.markAsDirty()"></p-checkbox>
                        </div>
                        <!-- <div *ngIf="expense.TaxMap1 && expense.TaxMap2">
                            <p-message severity="error" text="{{'settings.Both-tax-types' | translate}}">
                            </p-message>
                        </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_close' | translate}}"
            [disabled]="!ExpenseMainForm.valid || expenseCode.length > 20 ||!ExpenseMainForm.dirty"
            (click)="saveExpenses(true,true)" *ngIf="!isEditExpenses"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_new' | translate}}"
            [disabled]="!ExpenseMainForm.valid ||!ExpenseMainForm.dirty"
            (click)="saveAndNew(isEditExpenses)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'setings.Update-and-Close' | translate}}"
            (click)="saveExpenses(false,true)"
            [disabled]="!ExpenseMainForm.valid || !ExpenseMainForm.dirty"
            *ngIf="isEditExpenses"></button>
        <button type="button" pButton pRipple icon="fal fa-times" label="{{'cancel_button' | translate}}"
            (click)="closeExpenses()"></button>
    </ng-template>
</p-dialog>
