<div class="banks">
    <div class="layout-content p-pt-0">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addBank();"></p-button>
        </span>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mx-3 p-mt-2 p-pt-0">
                    <p-table #dt [value]="tableDataSorce" [rowHover]="true" [responsive]="true" [rows]="10"
                        dataKey="BankID" selectionMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="BankName" [scrollable]="true" [virtualScroll]="true"
                        [scrollHeight]="'calc(100vh - 350px)'" [globalFilterFields]="['BankName']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'globalsearch' | translate}}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                </p-message>
                                <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                    <p-checkbox [(ngModel)]="bankInactive" label="{{'show_inactive' | translate}}"
                                        binary="true" (onChange)="onStatusChange()">
                                    </p-checkbox>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                                <th class="width-55p" pSortableColumn="BankName" pResizableColumn>{{'settings.Bank-Name'
                                    | translate}}
                                    <p-sortIcon field="BankName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-center" pResizableColumn>{{'settings.Default-Bank' |
                                    translate}}
                                </th>
                                <th class="width-10p p-text-center" pResizableColumn>{{'settings.No-Deposits' |
                                    translate}}
                                </th>
                                <th class="width-10p p-text-center" pResizableColumn>{{'inactive' | translate}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td class="width-10p p-text-center">
                                    <div>
                                        <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editBank(rowData);" pTooltip="{{'Edit' | translate}}"
                                            tooltipPosition="top"></i>
                                        <i pRipple (click)="deleteBank(rowData.BankID);"
                                            class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                            pTooltip="{{'mgmt-delete' | translate}}" tooltipPosition="top"></i>
                                    </div>
                                </td>
                                <td class="width-55p" (click)="editBank(rowData);">
                                    {{rowData.BankName}}
                                </td>
                                <td (click)="editBank(rowData);" class="width-10p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.DefaultBank" binary="true" [disabled]="true">
                                    </p-checkbox>
                                </td>
                                <td (click)="editBank(rowData);" class="width-10p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.NoDeposit" binary="true" [disabled]="true">
                                    </p-checkbox>
                                </td>
                                <td class="width-10p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.Inactive" binary="true"
                                        (onChange)="saveInactive(rowData)"></p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Banks" [(visible)]="displayAddBankModal" [modal]="true" [style]="{width: '30vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="closeBanks()">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-p-0">
                <form #bankMainForm="ngForm">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-11">
                            <label for="description" class="required-field label-text">{{'settings.Bank-Name' |
                                translate}}</label>
                            <input type="text" pInputText class="p-inputtext p-component"
                                [(ngModel)]="bankRowObj.BankName" name="bankname" id="bankname" #bankname="ngModel"
                                required minlength="3" maxlength="100" autocomplete="off">
                        </div>
                        <div class="p-field p-field p-col-12 p-mt-3">
                            <p-checkbox [(ngModel)]="bankRowObj.DefaultBank" name="DefaultBank" [binary]="true"
                                label="{{'settings.Default-Bank' | translate}}"></p-checkbox>
                        </div>
                        <div class="p-field p-field p-col-12">
                            <p-checkbox [(ngModel)]="bankRowObj.NoDeposit" name="NoDeposit" [binary]="true"
                                label="{{'settings.No-Deposits' | translate}}"></p-checkbox>
                        </div>
                        <div class="p-fieldp-field p-col-12">
                            <p-checkbox [(ngModel)]="bankRowObj.Inactive" name="Inactive" [binary]="true"
                                label="{{'inactive' | translate}}"></p-checkbox>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_close' | translate}}"
             (click)="saveBanks(true,true)" *ngIf="!isEditbank"
            [disabled]="!bankMainForm.valid ||!bankMainForm.dirty || (!bankRowObj.BankName)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_new' | translate}}"
             (click)="saveAndNew(isEditbank)"
            [disabled]="!bankMainForm.valid ||!bankMainForm.dirty || (!bankRowObj.BankName)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_button' | translate}}"
             (click)="saveBanks(false,true)" *ngIf="isEditbank"
            [disabled]="!bankMainForm.valid ||!bankMainForm.dirty || (!bankRowObj.BankName)"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="closeBanks()" label="{{'close' | translate}}"
            ></button>
    </ng-template>
</p-dialog>