import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard } from '@app/_services';
import { ClientComponent, ClientListComponent, ClientPaymentsComponent, ClientNotesComponent, ClientMailsComponent, ClientTimeSlipsComponent, ClientSettingsComponent, ClientInvoicesComponent, ClientEngagementsComponent, ClientViewComponent, ClientContactsComponent, ClientProjectsComponent, ClientScheduleComponent, ClientTodoComponent, ClientBudgetsComponent } from '@app/modules/client';
import { CanDeactivateForm } from './can-deactivate';

const routes: Routes = [
  {
    path: '', component: ClientComponent,
    canActivate: [AuthGuard],
    data: {
      'featID': 3
    },
    children: [
      { path: AppConstants.listRoutePath, component: ClientListComponent, canActivate: [AuthGuard] },
      { path: AppConstants.invoicesRoutePath, component: ClientInvoicesComponent, canActivate: [AuthGuard], data: { 'roles': ["manager","Senior Manager"], 'featID': 4 } },
      { path: AppConstants.viewRoutePath, component: ClientViewComponent,canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"] }, canDeactivate: [CanDeactivateForm] },
      { path: AppConstants.noteRoutePath, component: ClientNotesComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"], 'featID': 3 } },
      { path: AppConstants.paymentsRoutePath, component: ClientPaymentsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager","Senior Manager"], 'featID': 5 } },
      { path: AppConstants.timeslipsRoutePath, component: ClientTimeSlipsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager","Senior Manager"], 'featID': 6 }, canDeactivate: [CanDeactivateForm] },
      { path: AppConstants.mailRoutePath, component: ClientMailsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager","Senior Manager"], 'featID': 3 } },
      { path: AppConstants.settingsRoutePath, component: ClientSettingsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"], 'featID': 3 }, canDeactivate: [CanDeactivateForm] },
      { path: AppConstants.contactsRoutePath, component: ClientContactsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"], 'featID': 3 } },
      { path: AppConstants.engagementsRoutePath, component: ClientEngagementsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager","Senior Manager"], 'featID': 3 } },
      { path: AppConstants.projectsRoutePath, component: ClientProjectsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"], 'featID': 21 } },
      { path: AppConstants.clientScheduleRoutePath, component: ClientScheduleComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"], 'featID': 3 } },
      { path: AppConstants.clientTodo , component: ClientTodoComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff","Senior Manager"], 'featID': 3 } },
      { path: AppConstants.budgetsRoutePath, component: ClientBudgetsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "administrator"], 'plans': ["free", "enterprise"], 'featID': 28 } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class ClientRoutingModule { }
