import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils} from '@app/_services';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-billrate',
  templateUrl: './billrate.component.html'
})
export class BillrateComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";

  isEditBRP;
  editIndex;
  isEditActivity;
  groupHeaderClick;
  activityDescription;
  rateMasterID: number;
  serviceCodeID: any = null;
  public filteredActivities: any[];
  activityOrderBy = "ServiceCode";
  brpOrderBy = "StaffRateTemplate";
  formattedAmount: string = '';
  brp: any = {};
  brpList: any = [];
  tmpBrpList: any = [];
  selActivity: any = {};
  activity: any = {};
  billingRateProfile: any = {};
  expandedItems: number[] = [];
  serviceCodeList: any = [];
  rateMasterList: SelectItem[];
  busy: Promise<any>;
  expandAllFlag: boolean = true;
  displayAddActivityCodeModal: boolean = false;
  displayAddBillingRateModal: boolean = false;
  loginCompanyId; 
  selectedRowData: any = {};

  constructor(
    protected mangoUtils: mangoUtils,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService) {

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.billinginvoicingRoutePath + '_' + AppConstants.SearchString);
    this.serviceCodeList = this.encrDecSrvc.getObject(AppConstants.activityList);
    this.serviceCodeList = this.serviceCodeList.sort(this.mangoUtils.compareValues('ServiceCode', 'asc'));
    this.activity.showInactive = false;
    this.billingRateProfile.showInactive = false;
      
  }

  ngOnInit(): void {
    this.getRateTemplates(); 
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('rate_profiles'), icon: 'ic-red' }
    ]);
  }
  
  saveBRP(isSave, isClose) {
    const parent = this;
    parent.brp['CompanyID'] = this.loginCompanyId;
    parent.mangoAPISrvc.showLoader(true);
    if (isSave || parent.brp['RateMasterID'] === null) {
      delete parent.brp['RateMasterID'];
      parent.mangoAPISrvc.addBillingRateProfiles(parent.brp).subscribe(function (data) {
        parent.getRateTemplates();
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.createMsg);
      }, error => {
        parent.getRateTemplates();
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.createErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })
    } else {
      parent.mangoAPISrvc.updateBillingRateProfiles(parent.brp.RateMasterID, parent.brp).subscribe(function (data) {
        parent.getRateTemplates();
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
      }, error => {
        parent.getRateTemplates();
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })
    }
    if (isClose) {
      this.displayAddBillingRateModal = false;
    } else {
      this.isEditBRP = false;
      this.brp = { "RateMasterID": "-1", "StaffRateTemplate": "", "Inactive": false, "CompanyID": this.loginCompanyId, "serviceCode": [] }
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterActivities(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const tempObj = [];
      this.filteredActivities = [];
      for (let i = 0; i < this.serviceCodeList.length; i++) {
        const activity = this.serviceCodeList[i];
        if (activity['ServiceCode'] && activity['ServiceCode'].toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
          tempObj.push(activity);
        }
      }
      this.filteredActivities = tempObj.sort(this.mangoUtils.compareValues('ServiceCode', 'asc'));
      this.filterTimeout.unsubscribe();
    })
  }

  handleActivitySelectClick(obj) {
    this.selActivity = null;
    this.activityDescription = null;
    this.activity.ServiceCode = null;
    this.activity.ServiceCodeID = null;
    const result = this.selectedRowData.serviceCode.filter(item => item.ServiceCode == obj.ServiceCode);
    if (result.length == 0) {
      this.activityDescription = obj.Description;
      this.activity.ServiceCode = obj.ServiceCode;
      this.activity.ServiceCodeID = obj.ServiceCodeID;
      this.selActivity = { ServiceCode: this.activity.ServiceCodeID, Description: this.activityDescription };
    } else {
      this.mangoAPISrvc.notify('warning', this.translate.instant('warning'), 'Duplicate Activity is not allowed in Profile!');
    }
  }

  saveAndNewBL(IsEdit) {
    this.saveActivity(!IsEdit, false);
  }

  saveActivity(isSave, isClose) {
    const parent = this;
    parent.rateMasterID = parent.activity.RateMasterID;
    parent.activity['CompanyID'] = parent.loginCompanyId;
    parent.activity.BillingRate = parent.activity.BillingRate != null ? parent.activity.BillingRate : 0
    parent.mangoAPISrvc.showLoader(true);
    if (isSave) {
      parent.mangoAPISrvc.addBillingRateActivityProfiles(parent.activity).subscribe(function (data) {
        parent.getRateTemplates();
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.createMsg);
      }, error => {
        parent.getRateTemplates();
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.createErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })
    } else {
      parent.mangoAPISrvc.updateBillingRateActivityProfiles(this.activity.RateDetailID, this.activity).subscribe(function (data) {
        parent.getRateTemplates();
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
      }, error => {
        parent.getRateTemplates();
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })
    }
    if (isClose) {
      this.displayAddActivityCodeModal = false;
      this.selectedRowData = {};
    } else {
      this.selActivity = null;
      this.isEditActivity = false;
      this.activity["CompanyID"] = this.loginCompanyId;
      this.activity["BillingRate"] = 0;
    }

  }

  getRateTemplates() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getRateTemplates().subscribe(function (data:any) {
      _this.brpList = data;
      _this.rateMasterList = [];

      for (let i = 0; i < data.length; i++) {
        _this.brpList[i].index = i;
        _this.rateMasterList.push({ label: data[i].StaffRateTemplate, value: data[i].RateMasterID });
        for (let j = 0; j < data[i].serviceCode?.length; j++) {
          data[i].serviceCode[j].index = j;
          data[i].serviceCode[j].RateMasterID = _this.brpList[i].RateMasterID;
          data[i].serviceCode[j].tmp = _this.brpList[i].StaffRateTemplate;
        }
        if (data[i].RateMasterID == _this.rateMasterID) {
          _this.expandedItems = [data[i]];
        }
      }

      _this.rateMasterList.sort(_this.mangoUtils.sortList);
      _this.tmpBrpList = _this.brpList;
      _this.onStatusChange();
      _this.loadFilterGrid();
      _this.mangoAPISrvc.showLoader(false);
    }, error => {
      _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  onStatusChange() {
    this.brpList = this.tmpBrpList.filter((billRate) => billRate.Inactive == this.billingRateProfile.showInactive);
  }

  reload() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getRateTemplates().subscribe(function (data:any) {
      _this.brpList = data;
      for (let i = 0; i < data.length; i++) {
        _this.brpList[i].index = i;
      }
      _this.mangoAPISrvc.showLoader(false);
    }, error => {
      _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });

  }

  addBRP(){
    this.brp = { StaffRateTemplate: "" };
    this.isEditBRP = false;
    this.brp.StaffRateTemplate = "";
    this.brp.Inactive = false;
    this.displayAddBillingRateModal = true;
  }

  onBRPListStatusChange(obj) {
    this.brp = obj;
    this.editIndex = obj.index;
    this.saveBRP(false, true);
  }

  onBRPEditStatusChange() {
    this.brp.Inactive = !this.brp.Inactive;
  }

  onActivityAddStatusChange() {
    this.activity.Inactive = !this.activity.Inactive;
  }

  addActivity(data) {
    this.activity = {};
    this.selectedRowData = data;
    this.activity.RateMasterID = data.RateMasterID;
    this.isEditActivity = false;
    this.activity.Inactive = false;
    this.displayAddActivityCodeModal = true;
  }

  editBRP(obj) {
    this.editIndex = obj.index;
    this.brp = Object.assign({}, obj);
    this.isEditBRP = true;
    this.displayAddBillingRateModal = true;
  }

  editActivity(parent, obj) {
    this.editIndex = obj.index;
    this.activity = Object.assign({}, obj);
    this.selectedRowData = parent;
    this.activity.BillingRate = this.activity.BillingRate.replace("$", "").replace(",", "");
    this.serviceCodeID = { serviceCodeID: this.activity.ServiceCodeID, description: this.activity.Description };
    this.selActivity = { ServiceCode: this.activity.ServiceCodeID, Description: this.activity.Description };
    this.isEditActivity = true;
    this.displayAddActivityCodeModal = true;
  }

  closeBRP() {
    this.displayAddBillingRateModal = false;
  }

  saveAndNew(IsEdit) {
    this.saveBRP(!IsEdit, false);
  }

  closeActivity() {
    this.selectedRowData = {};
    this.displayAddActivityCodeModal = false;
  }

  deleteGroup(id, index) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_cancel')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteBillingRateStatus(id, false).subscribe(function (data:any) {
          parent.brpList.splice(index, true);
          // parent.reload();
          parent.getRateTemplates();
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);
        }, error => {
            parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), error.message);
          parent.mangoAPISrvc.showLoader(false);
        })
      }
    })
  }

  deleteActivities(obj) {
    const parent = this;
    parent.rateMasterID = obj.RateMasterID;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_cancel')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteBillingRateActivityStatus(obj.RateDetailID).subscribe(function (data) {
          parent.getRateTemplates();
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
        }, error => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        })
      }
    })
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.billinginvoicingRoutePath + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.billinginvoicingRoutePath + '_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }
  
}
