import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService } from "@app/_services";
import Swal from 'sweetalert2';


@Component({
  selector: "app-module-date-exclusion",
  templateUrl: "./module-date-exclusion.component.html",
  styleUrls: ["./module-date-exclusion.component.css"],
})
export class ModuleDateExclusionComponent implements OnInit {
  exclusionForm: UntypedFormGroup = this.fb.group({
    dateUntil: ["", Validators.required],
  });

  modules: any[] = [];

  currentYear: number;
  loggedInemail: string;

  constructor(
    private fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private cd: ChangeDetectorRef
  ) {
    this.currentYear = new Date().getFullYear();
    this.loggedInemail = this.encrDecSrvc.getObject(AppConstants.userEmail);
  }

  ngOnInit(): void {
    this.loadAllowedModules();
  }

  onSubmit(): void {
    this.mangoAPISrvc.showLoader(true);
    if (this.exclusionForm.valid) {
      this.mangoAPISrvc.saveModuleDateExclusion(this.exclusionForm).subscribe((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Saved Successfully",
            text: response.body,
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.exclusionForm.reset();
            this.loadAllowedModules();
            this.mangoAPISrvc.showLoader(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.body,
          }).then(() => {
            this.mangoAPISrvc.showLoader(false);
          });
        }
      });
    }
  }

  loadAllowedModules(): void {
    this.mangoAPISrvc.getQBAllowedModules(this.loggedInemail).subscribe((response) => {
      if (response.status === 200) {
        this.modules = [];
        for (const moduleName in this.exclusionForm.controls) {
          if (moduleName !== "dateUntil" && moduleName !== "email") {
            this.exclusionForm.removeControl(moduleName);
          }
        }
        response.body.forEach((module) => {
          this.modules.push(module);
          this.exclusionForm.addControl(module.moduleName, this.fb.control(null));
        });
        if (!this.exclusionForm.contains("dateUntil")) {
          this.exclusionForm.addControl("dateUntil", this.fb.control("", Validators.required));
        }
        if (!this.exclusionForm.contains("email")) {
          this.loggedInemail = this.encrDecSrvc.getObject(AppConstants.userEmail);
          this.exclusionForm.addControl("email", this.fb.control(this.loggedInemail));
        }
        this.exclusionForm.get("email").setValue(this.loggedInemail);
      }
      this.cd.detectChanges();
    });
  }
  
  onCheckboxChange(module: any, checked: boolean): void {
    const control = this.exclusionForm.get(module.moduleName);
    if (control) {
      control.setValue(checked);
      this.exclusionForm.markAllAsTouched();
      this.exclusionForm.updateValueAndValidity();
    }
  }  
}
