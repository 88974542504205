<div class="layout-content p-pt-2">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-ml-3 p-pt-0">
                <p-table #dt [value]="logsData" [rowHover]="true" [responsive]="true" [rows]="10"
                    dataKey="message-id" selectionMode="single" [pageLinks]="3" [filterDelay]="0"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'"
                    [globalFilterFields]="['CompanyID','StaffID', 'StaffName','Action','createdAt', 'Description']" [sortOrder]="-1" sortField="date" [resizableColumns]="true">
                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <span class="p-input-icon-left">
                                <i class="fal fa-search"></i>
                                <input #searchValue pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{ 'globalsearch' | translate }}" />
                            </span>
                            <span>
                                <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                    pTooltip="{{ 'clear' | translate }}"></button>
                            </span>
                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            <span class="pull-right">
                                <label class="label-text p-mr-2">{{'Date Options' | translate}}</label>
                                <p-dropdown [options]="dateRangeList" [(ngModel)]="dateRange" class="p-mr-3"
                                    (ngModelChange)="changeDateTypes()"
                                    scrollHeight="500px" placeholder="Select Date" required></p-dropdown>
                                <label class="label-text p-mr-2">{{'date-from' | translate}}</label>
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" class="p-mr-3"
                                    [(ngModel)]="dateFrom" dateFormat="mm/dd/yy" [readonlyInput]="true"></p-calendar>
                                <label class="label-text p-mr-2">{{'date-to' | translate}}</label>
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                    [(ngModel)]="dateTo" dateFormat="mm/dd/yy" [readonlyInput]="true" [minDate]="dateFrom"></p-calendar>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn class="width-2p"></th>
                            <th pResizableColumn class="width-5p"  pSortableColumn="StaffName" >
                                <div class="p-d-flex p-jc-between">
                                    <div class="p-mt-1">
                                        {{ 'staff-name' | translate }}
                                        <p-sortIcon field="StaffName"></p-sortIcon>
                                    </div>
                                    <p-columnFilter type="text" field="StaffName"
                                        display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn class="width-10p">
                                {{ 'client.event' | translate }}
                            </th>
                            <th pResizableColumn class="width-20p">{{ 'Description' | translate }}</th>
                            <th pResizableColumn class="width-8p"  pSortableColumn="createdAt">
                                {{ 'date' | translate }}
                                <p-sortIcon field="createdAt"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr class="cursor-hand" (click)="onUserLogClick(rowData)">
                            <td class="width-2p">
                            </td>
                            <td class="width-5p">
                                {{rowData.StaffName}}
                            </td>
                            <td class="width-10p" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                                showDelay="800" pTooltip="{{ rowData.Action }}">
                                {{rowData.Action}}
                            </td>
                            <td class="width-20p" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                                showDelay="800" pTooltip="{{ rowData.Description }}">
                                {{rowData.Description}}
                            </td>
                            <td class="width-8p">
                                {{rowData.createdAt | date:'medium'}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">
                                <p-message severity="info" text="{{ 'no_logs_message' | translate }}"
                                    styleClass="p-px-2"></p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <p-dialog [modal]="true" [(visible)]="isDisplayUserLogDetails" [style]="{width: '35vw'}" [responsive]="true" (onHide)="isDisplayUserLogDetails=false"
        [draggable]="false" [resizable]="false" showEffect="fade" [closable]="true" [baseZIndex]="1" [maximizable]="false">
        <p-header>User Log Details</p-header>
        <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
            <div class="p-formgroup-inline p-col-12">
                <div class="p-field p-col-12">
                    <label class="label-text" style="display: block;">Staff
                    </label>
                    <label>{{userLogDetails.StaffName}}</label>
                </div>
            </div>
            <div class="p-formgroup-inline p-col-12">
                <div class="p-field p-col-12">
                    <label class="label-text" style="display: block;">Date
                    </label>
                    <label>{{userLogDetails.createdAt | date:'medium'}}</label>
                </div>
            </div>
            <div class="p-formgroup-inline p-col-12">
                <div class="p-field p-col-12">
                    <label class="label-text">Event
                    </label>
                    <textarea rows="1" cols="30" pInputTextarea [disabled]="true" [autoResize]="true" [(ngModel)]="userLogDetails.Action"></textarea>
                </div>
            </div>
            <div class="p-formgroup-inline p-col-12">
                <div class="p-field p-col-12">
                    <label class="label-text">Description
                    </label>
                    <textarea rows="5" cols="30" [disabled]="true" [autoResize]="true" pInputTextarea [(ngModel)]="userLogDetails.Description"></textarea>
                </div>
            </div>
        </div>
    </p-dialog>
</div>