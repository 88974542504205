<div class="engagement-list">
    <!-- buttons -->
    <span class="pull-right p-mr-3 clientform-btn-top">
        <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
            class="p-button-help p-pr-2" (click)="addContactPopUp();" tooltipPosition="top"
            pTooltip="{{ 'add' | translate }}"></p-button>
        <button pButton pRipple label="{{ 'list' | translate }}" icon="fal fa-project-diagram"
            class="p-mr-1 p-button-sm" (click)="redirectListView()"></button>
    </span>
    <!-- table -->
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-ml-3 p-mt-1 p-pt-0">
                <p-table #dt [value]="contacts" [rowHover]="true" [responsive]="true"
                    dataKey="CustomerContactID" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                    [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    sortField="ContactName" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" 
                    [globalFilterFields]="['ContactName','Company','ContactDescription','ContactType','Office','Mobile']"
                    [resizableColumns]="true" (onFilter)="onFilter($event)">
                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <span class="p-input-icon-left">
                                <i class="fal fa-search"></i>
                                <input #searchValue pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{ 'globalsearch' | translate }}" />
                            </span>
                            <span>
                                <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                    pTooltip="{{ 'clear' | translate }}"></button>
                            </span>
                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                            <th pSortableColumn="ContactName" pResizableColumn class="width-10p p-text-left">{{
                                'client.contact_name' | translate }}
                                <p-sortIcon field="ContactName"></p-sortIcon>
                            </th>
                            <th pSortableColumn="Company" pResizableColumn class="width-20p p-text-left">{{ 'company' |
                                translate }}
                                <p-sortIcon field="Company"></p-sortIcon>
                            </th>
                            <th pSortableColumn="ContactDescription" pResizableColumn class="width-15p p-text-left">{{
                                'client.contact_description' | translate }}
                                <p-sortIcon field="ContactDescription"></p-sortIcon>
                            </th>
                            <th pSortableColumn="ContactType" pResizableColumn class="width-15p p-text-left">{{
                                'contact_type' | translate }}
                                <p-sortIcon field="ContactType"></p-sortIcon>
                            </th>
                            <th pResizableColumn class="width-8p p-text-left">{{ 'work' | translate }}</th>
                            <th pResizableColumn class="width-8p p-text-left">{{ 'Fax-Number' | translate }}</th>
                            <th pResizableColumn class="width-8p p-text-left">{{ 'mobile' | translate }}</th>
                            <th pResizableColumn class="width-5p p-text-center">{{ 'email' | translate }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr class="cursor-hand">
                            <td class="width-6p">
                                <div class="p-text-center">
                                    <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green" (click)="editContact(rowData)"
                                        tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                    <i (click)="deleteContact(rowData.CustomerContactID)"
                                        class="fal fa-trash-alt ic-red p-pl-2 cursor-hand" tooltipPosition="top"
                                        pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                </div>
                            </td>
                            <td (click)="editContact(rowData)" class="width-10p p-text-left p-text-nowrap p-text-truncate"
                                tooltipPosition="top" showDelay="800" pTooltip="{{rowData.ContactName}}"
                                tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.ContactName}}
                            </td>
                            <td (click)="editContact(rowData)" class="width-20p p-text-left p-text-nowrap p-text-truncate"
                                tooltipPosition="top" showDelay="800" pTooltip="{{rowData.Company}}"
                                tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.Company}}
                            </td>
                            <td class="width-15p" (click)="editContact(rowData)">
                                {{rowData.ContactDescription}}
                            </td>
                            <td (click)="editContact(rowData)" class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                tooltipPosition="top" showDelay="800" pTooltip="{{rowData.ContactType}}"
                                tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.ContactType}}
                            </td>
                            <td class="width-8p" (click)="editContact(rowData)">
                                {{rowData.Office}}
                            </td>
                            <td class="width-8p" (click)="editContact(rowData)">
                                {{rowData.Fax}}
                            </td>
                            <td class="width-8p" (click)="editContact(rowData)">
                                {{rowData.Mobile}}
                            </td>
                            <td class="width-5p p-text-center">
                                <i class="far fa-envelope ic-red" (click)="sendEmailToContact(rowData);"
                                    tooltipPosition="top" pTooltip="{{ 'email_contact' | translate }}"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">
                                <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                    styleClass="p-px-2"></p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>