import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExportsComponent } from './exports/exports.component';
import { ImportsComponent } from './imports/imports.component';
import { ImportExportComponent } from './import-export.component';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard } from '@app/_services';

const routes: Routes = [
  {
    path: '',
    component: ImportExportComponent,
    children: [
      { path: '', component: ImportsComponent, canActivate: [AuthGuard], data: { 'featID': 31 } },
      { path: AppConstants.imports, component: ImportsComponent, canActivate: [AuthGuard], data: { 'featID': 31 } },
      { path: AppConstants.exports, component: ExportsComponent, canActivate: [AuthGuard], data: { 'featID': 31 } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportExportRoutingModule { }
