import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_services';
import { TransactionComponent } from './transaction.component';
import { TransactionCreditCardsComponent } from './transaction-credit-cards/transaction-credit-cards.component';
import { TransactionAchEnrollmentComponent } from './transaction-ach-enrollment/transaction-ach-enrollment.component';
import { TransactionReturnAndVoidComponent } from './transaction-return-and-void/transaction-return-and-void.component';
import { AppConstants } from "@app/_helpers/api-constants";

const routes: Routes = [
  {
    path: '',
    component: TransactionComponent,
    children: [
      { path: '', component: TransactionCreditCardsComponent, canActivate: [AuthGuard], },
      { path: AppConstants.creditCardsRoutePath, component: TransactionCreditCardsComponent, canActivate: [AuthGuard], },
      { path: AppConstants.achTransactionRoutePath, component: TransactionAchEnrollmentComponent, canActivate: [AuthGuard], },
      { path: AppConstants.returnAndVoidRoutePath, component: TransactionReturnAndVoidComponent, canActivate: [AuthGuard], },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionRoutingModule { }
