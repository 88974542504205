import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppSharedModule } from '@app/shared/modules';
import { AppTranslationModule } from '@shared-modules/app.translation.module';

import { CompanySetupComponent } from './company-setup.component';
import { SettingsComponent } from './settings/settings.component';
import { LocationsComponent } from './locations/locations.component';
import { CompanySetupRoutingModule } from './company-setup-routing.module'

@NgModule({
  declarations: [SettingsComponent, LocationsComponent, CompanySetupComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule,
    CompanySetupRoutingModule
  ]
})
export class CompanySetupModule { }
