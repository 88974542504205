import { Component, OnInit } from "@angular/core";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { SelectItem } from "primeng/api";
import swal from "sweetalert2";
import { forkJoin } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AppConstants } from "@app/_helpers/api-constants";
import { environment } from "@environments/environment";

@Component({
  selector: "app-email-automation-email",
  templateUrl: "./email-automation-email.component.html",
})
export class EmailAutomationEmailComponent implements OnInit {
  public selectedIndex: number = 0;
  public isAddToListBtnEnabled: boolean = false;
  clientGroupCategory: any = [];
  public isCreateNewEnabled = true;
  public selectedDataList: any;
  public listDataSource: SelectItem[] = [];
  public selectedBillingGroup: string[];
  public selectedClientItem: string[];
  public selectedClientItems: any = [];
  public selectedCustomerType: string[];
  public EmailAutomationKey: string = "";
  public ActivateEmailAutomation: boolean = false;
  public EmailAutomationIntegration: string = "";
  public AllClientNames: any = [];
  public selectedGroupCategory: string[];
  public selectedtaskUserItem: string[];
  public selectedbillingpartnerItem: string[];
  public AllCustomerType: any = [];
  public AllCustomerGroupCategoryList: any = [];
  public mangoCompanyDetails: any = {};
  intervalId: any = null;
  public assignTaskDataSource: SelectItem[] = [];
  public AllStaffsTypes: SelectItem[] = [];
  public Allbillingpartners: SelectItem[] = [];

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private http: HttpClient
  ) {
    this.breadcrumbService.setItems([
      { label: "Email Automation" },
      { label: "Email Lists", icon: "ic-red" },
    ]);

    this.fetchClientListForAssignTaks();
    this.getAllStaffList();
    this._initDataSources();

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getCompanyMangoDetails(
        this.encrDecSrvc.getObject(AppConstants.companyID)
      )
      .subscribe((companyData) => {
        this.mangoCompanyDetails = companyData[0];
        if (this.mangoCompanyDetails.ActivateEmailAutomation) {
          this.ActivateEmailAutomation = this.mangoCompanyDetails.ActivateEmailAutomation;
          this.EmailAutomationIntegration = this.mangoCompanyDetails.EmailAutomationIntegration;
          this.EmailAutomationKey = this.mangoCompanyDetails.EmailAutomationKey;
          if (this.ActivateEmailAutomation && this.mangoCompanyDetails.isEAKPresents) {
            if (this.EmailAutomationIntegration == "SendInBlue") {
              this.mangoAPISrvc
                .getSndInBlueAccountDetails()
                .subscribe((result) => {
                  this.getDataList(true);
                });
            } else {
              this.mangoAPISrvc
                .getMailChimpAccountDetails()
                .subscribe((result) => {
                  this.getDataList(false);
                });
            }
          }
        }
        this.mangoAPISrvc.showLoader(false);
      });
  }

  ngOnInit(): void {
    this.clientGroupCategory = this.encrDecSrvc.getObject(
      AppConstants.clientGroupCategory
    );
  }

  getDataList(isSendInBlue) {
    this.mangoAPISrvc.showLoader(true);
    if (isSendInBlue) {
      this.mangoAPISrvc
        .getSendinblueList()
        .subscribe((data: any) => {
          this.listDataSource = [...data.lists];
        });
      this.mangoAPISrvc.showLoader(false);
    } else {
      this.mangoAPISrvc
        .getMailChimpList()
        .subscribe((data: any) => {
          this.listDataSource = [...data.lists];
        });
      this.mangoAPISrvc.showLoader(false);
    }
  }

  getAllStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive)
        continue;

      parent.AllStaffsTypes.push({
        label: item[i].StaffName,
        value: item[i].StaffID,
      });
      parent.Allbillingpartners.push({
        label: item[i].StaffName,
        value: item[i].StaffID,
      });
    }
  }

  fetchClientListOfDetails() {
    const parent = this;
    let QueryStr = "offset=0&limit=20000";
    parent.selectedClientItem =
      parent.selectedClientItem && parent.selectedClientItem.length > 0
        ? parent.selectedClientItem
        : null;
    parent.selectedBillingGroup =
      parent.selectedBillingGroup && parent.selectedBillingGroup.length > 0
        ? parent.selectedBillingGroup
        : null;
    parent.selectedCustomerType =
      parent.selectedCustomerType && parent.selectedCustomerType.length > 0
        ? parent.selectedCustomerType
        : null;
    parent.selectedGroupCategory =
      parent.selectedGroupCategory && parent.selectedGroupCategory.length > 0
        ? parent.selectedGroupCategory
        : null;
    parent.selectedtaskUserItem =
      parent.selectedtaskUserItem && parent.selectedtaskUserItem.length > 0
        ? parent.selectedtaskUserItem
        : null;
    parent.selectedbillingpartnerItem =
      parent.selectedbillingpartnerItem &&
        parent.selectedbillingpartnerItem.length > 0
        ? parent.selectedbillingpartnerItem
        : null;

    if (
      parent.selectedClientItem ||
      parent.selectedCustomerType ||
      parent.selectedGroupCategory ||
      parent.selectedtaskUserItem ||
      parent.selectedbillingpartnerItem ||
      parent.selectedBillingGroup
    ) {
      if (parent.selectedClientItem) {
        QueryStr += "&ClientID=" + parent.selectedClientItem.toString();
      }
      if (parent.selectedCustomerType) {
        QueryStr += "&ClientTypeID=" + parent.selectedCustomerType.toString();
      }
      if (parent.selectedGroupCategory) {
        QueryStr +=
          "&GroupDescriptionIDArray=" + parent.selectedGroupCategory.toString();
      }
      if (parent.selectedtaskUserItem) {
        QueryStr +=
          "&StaffAssignedID=" + parent.selectedtaskUserItem.toString();
      }
      if (parent.selectedbillingpartnerItem) {
        QueryStr +=
          "&BillingPartnerID=" + parent.selectedbillingpartnerItem.toString();
      }
      if (parent.selectedBillingGroup) {
        QueryStr +=
          "&BillingGroupID=" + parent.selectedBillingGroup.toString();
      }
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getListOfClientsDetails(QueryStr).subscribe(
        (result: any) => {
          result = result.filter((item) => item["Email"] != null)?.map((obj) => {
            obj["GroupDescriptionIDArrayDesc"] = "";
            obj["GroupDescriptionIDArray"] = obj["GroupDescriptionIDArray"] || [];
            if (obj['GroupDescriptionIDArray'] && obj['GroupDescriptionIDArray'].length > 0) {
              obj['GroupDescriptionIDArray'].filter(item => item != -1);
              obj['GroupDescriptionIDArrayDesc'] = parent.clientGroupCategory?.filter(group => obj['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID']))
                .map(group => group['GroupDescription']).join(", ");
            }
            const billingGroup = parent.clientGroupCategory.find(item => item['CustomerGroupCategoryID'] == obj['BillingGroupID']);
            if (billingGroup) {
              obj["BillingGroup"] = billingGroup['GroupDescription'];
            }
            return obj
          });

          parent.assignTaskDataSource = [...result];
          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.fetchErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.assignTaskDataSource = [];
    }
  }

  _initDataSources() {
    const parent = this;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject(AppConstants.token));
    const observableBatch = [];
    const urlOne = parent.http.get(
      `${environment.API_URL}/company/customerType`,
      { headers: headers }
    );
    const urlTwo = parent.http.get(
      `${environment.API_URL}/company/getCustomerGroupCategoryList`,
      { headers: headers }
    );
    observableBatch.push(urlOne);
    observableBatch.push(urlTwo);

    forkJoin(observableBatch).subscribe(
      (data: any) => {
        const dataSetOne = data[0];
        const dataSetTwo = data[1];
        for (let i = 0; i < dataSetOne.length; i++) {
          parent.AllCustomerType.push({
            label: dataSetOne[i].CustomerTypeDescription,
            value: dataSetOne[i].CustomerTypeID,
          });
        }
        for (let i = 0; i < dataSetTwo.length; i++) {
          parent.AllCustomerGroupCategoryList.push({
            label: dataSetTwo[i].GroupDescription,
            value: dataSetTwo[i].CustomerGroupCategoryID,
          });
        }
      },
      (err) => console.error(err)
    );
  }

  /* Loading all custmors */
  fetchClientListForAssignTaks() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getListOfClientsForTaks().subscribe(
      (result: any) => {
        for (let i = 0; i < result.length; i++) {
          self.AllClientNames.push({
            label: result[i].ClientName,
            value: result[i].ClientID,
          });
        }
        self.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        self.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  isValidForNew() {
    this.isAddToListBtnEnabled = false;
    this.isCreateNewEnabled = true;
    if (this.selectedDataList && this.selectedClientItems.length > 0) {
      this.isAddToListBtnEnabled = true;
    }
  }

  ngOnDestroy(): void { }

  saveAction() {
    if (this.EmailAutomationIntegration == "SendInBlue") {
      this.mangoAPISrvc.showLoader(true);
      this.addMultipleContacts(
        this.selectedClientItems,
        this.selectedDataList["id"]
      ).subscribe(
        (data) => {
          // setTimeout(() => {
          // }, 500);
          this.mangoAPISrvc.showLoader(false);
          swal.fire({
            icon: "success",
            title: "Success!",
            text: "Successfully added new clients to list..",
            showConfirmButton: false,
            timer: 2500,
          });
          this.mangoAPISrvc.showLoader(false);
        },
        (err) => {
          console.log(err);
          this.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      const observableBatch = [];
      for (let j = 0; j < this.selectedClientItems.length; ++j) {
        const clientObj = this.selectedClientItems[j];
        if (clientObj["Email"]) {
          const obj = {};

          if (clientObj["ContactPerson"]) {
            obj["FNAME"] = clientObj["ContactPerson"].split(" ")[0];
            obj["LNAME"] = clientObj["ContactPerson"].split(" ")[1];
          } else {
            obj["FNAME"] = clientObj["ClientName"];
            obj["LNAME"] = "";
          }
          obj["requestId"] = this.selectedDataList["id"];
          obj["email_address"] = clientObj["Email"];
          observableBatch.push(obj);
        }
      }
      this.intervalId = setInterval(
        () => this.sendMailChimp(observableBatch),
        2000
      );
    }
  }

  sendMailChimp(bulkData: any) {
    const self = this;
    const observableBatch = bulkData.splice(0, 100);
    if (bulkData.length == 0) {
      clearInterval(this.intervalId);
    }
    self.mangoAPISrvc
      .bulkMailChimpListCreate({ observableBatch })
      .subscribe((data) => {
        if (bulkData.length > 0) {
          return false;
        }
        swal.fire({
          icon: "success",
          title: "Success!",
          text: "Successfully added new clients to list..",
          showConfirmButton: false,
          timer: 2500,
        });
      });
  }

  addMultipleContacts(selectedItemList, id) {
    const parentObj = this;
    const observableBatch = [];
    selectedItemList.forEach((selectedItem, key) => {
      if (selectedItem["Email"]) {
        const obj = {};
        const contactObj = {};
        const ids = [];
        ids[0] = id;
        if (selectedItem["ContactPerson"]) {
          contactObj["FIRSTNAME"] = selectedItem["ContactPerson"].split(" ")[0];
          contactObj["LASTNAME"] = selectedItem["ContactPerson"].split(" ")[1];
        } else {
          contactObj["FIRSTNAME"] = selectedItem["ClientName"];
          contactObj["LASTNAME"] = "";
        }
        obj["attributes"] = contactObj;
        obj["email"] = selectedItem["Email"];
        obj["listIds"] = ids;

        observableBatch.push(
          parentObj.mangoAPISrvc.createSendInBlueContacts(obj)
        );
      }
    });
    return forkJoin(observableBatch)
  }
}
