import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from "@app/_helpers/api-constants";
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-merchant-setup',
  templateUrl: './merchant-setup.component.html'
})
export class MerchantSetupComponent implements OnInit {
  public companyId;
  public logincompanyId;
  public subscriptionLevel;
  public isErolled: boolean = false;
  public url;
  public mangoCompanyData: any;
  public enrollStatus: string = "";
  public serviceProvider: string = "";
  public nativeWindow: any;
  public superAdminVisible: number[] = AppConstants.superAdminVisible;

  constructor(private router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, private sanitizer: DomSanitizer, private translate: TranslateService) {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('accounting') },
      { label: this.translate.instant('MerchantSetup'), icon: 'ic-red' }
    ]);
    this.logincompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID).toString();
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.getCompanyInfo();
  }

  ngOnInit(): void {

  }

  enrollNow() {
    const parent = this;
    if (parent.subscriptionLevel == 'FREE') {
      Swal.fire({
        icon: 'warning',
        title: 'Information!',
        text: "Electronic Payments Not Allowed in Trial!",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      parent.mangoAPISrvc.showLoader(true)
      parent.mangoAPISrvc.requestEnrollment(parent.companyId).subscribe(
        (data: any) => {
          parent.url = parent.sanitizer.bypassSecurityTrustResourceUrl(data.formUrl);
          parent.mangoAPISrvc.showLoader(false);
        },
        (data: any) => { parent.mangoAPISrvc.showLoader(false) }
      );
    }
  }

  getCompanyInfo() {
    const parent = this;
    parent.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe(response => {
      parent.mangoCompanyData = response[0];
      parent.checkEnrollProcess();
    });
  }

  checkEnrollProcess() {
    const parent = this;
    if (parent.mangoCompanyData.LexOperatingMerchantAccountId) {
      parent.isErolled = true;
      parent.serviceProvider = "CPAPAY";

      if (parent.mangoCompanyData.LexStatusMessage == 'Open') {
        parent.enrollStatus = "Approved - You're live for electronic payments!";
      } else if (parent.mangoCompanyData.LexStatusMessage == 'Pending') {
        parent.enrollStatus = 'PENDING';
      }

    } else if (parent.mangoCompanyData.CCmerchantID) {
      parent.isErolled = true;
      parent.enrollStatus = "Approved - You're live for electronic payments!";
      parent.serviceProvider = "USIO";
    } else {
      parent.isErolled = false;
    }
  }

  redirectToLogin() {
    if (this.subscriptionLevel == 'FREE') {
      Swal.fire({
        icon: 'warning',
        title: 'Information!',
        text: "Electronic Payments Not Allowed in Trial!",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      const newWindow = this.nativeWindow.open(decodeURI(`https://portal.cpapay.io/login`));
      newWindow.location = `https://portal.cpapay.io/login`;
    }
  }

  redirectStaxToLogin() {
    if (this.subscriptionLevel == 'FREE') {
      Swal.fire({
        icon: 'warning',
        title: 'Information!',
        text: "Your are on a trial account. You must be on a paid plan to enroll and electronic payments!",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      this.router.navigate([`/accounting/cards-view`]);
    }
  }
}
