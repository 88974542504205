import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { Table } from 'primeng/table';
import { SelectItem } from "primeng/api";
import Swal from 'sweetalert2';
declare let dymo: any;

@Component({
  selector: 'app-label-printing',
  templateUrl: './label-printing.component.html'
})
export class LabelPrintingComponent implements OnInit {
  clientListDatasource: any = [];
  clientGroupCategory: any = [];
  clientTypes: any = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  selectedClient: any = null;
  formClientName: any = null;
  formBusStreet1: any = null;
  formAddressItem: any = null;
  formPrinter: any = "";
  formPrinterLabel: any = "1";
  formattedStr: any = "";
  label: any;
  intervalid;
  searchTextStr: any = "";
  selectedRows: any = [];
  showInvoiceReview: boolean = false;
  filteredDataSource: any = [];
  displayList: SelectItem[] = [];
  totalProcessed: number = 0;
  selectedDisplay: any = null;
  companyID: any;

  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Label Printing' }
    ]);

    this.searchTextStr = this.encrDecSrvc.getObject('label_' + AppConstants.SearchString);

    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  ngOnInit(): void {
    this.displayList = [
      { label: "All Clients", value: null },
      { label: "All Client Contacts", value: 'contacts' },
      { label: "All Client(s) in Invoice Review to be Printed", value: 'invoiceReview' }
    ]
  }

  fetchClients() {
    if (this.clientListDatasource.length == 0) {
      this.clientGroupCategory = this.encrDecSrvc.getObject(
        AppConstants.clientGroupCategory
      );
      this.clientTypes = this.encrDecSrvc.getObject(
        AppConstants.customerType
      );
      const list = this.encrDecSrvc.clientList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if(item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0){
          item['GroupDescriptionIDArray'].filter(item => item != -1);
          item['GroupDescriptionIDArrayDesc'] = this.clientGroupCategory?.filter(group => item['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID']))
            .map(group => group['GroupDescription']).join(", ");
        } else
          item['GroupDescriptionIDArrayDesc'] = null
          
        this.clientListDatasource.push(item);
      }
      this.clientListDatasource.map((item) => {
        item['contactcount'] = parseInt(item['contactcount']);
        item['childItem'] = [];
        item['hasChildren'] = false;
        return item
      });
      this.filteredDataSource = this.clientListDatasource.filter((client) => client.Inactive != true);
    } else {
      this.loadFilterGrid();
      clearInterval(this.intervalid);
    }
  }

  PrintLabel(formPrinter, formPrinterLabel, formattedStr) {
    try {
      // if (!this.label) {
      //   alert("Load label before printing");
      //   return;
      // }

      let label: any;
      label = this.loadLabelFromWeb(formattedStr);
      const labelSet = new dymo.label.framework.LabelSetBuilder();
      const printParams = {};
      printParams['twinTurboRoll'] = dymo.label.framework.TwinTurboRoll.Left;

      setTimeout(() => {
        for (let i = 0; i < formPrinterLabel; i++) {
          label.print(formPrinter);
        }
      }, 10);

      if(this.selectedRows.length == 1) {
        setTimeout(() => {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            showCancelButton: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'OK',
            text: 'Successfully Printed.',
          });
        }, 200);
      }

      this.totalProcessed += 1;
    }
    catch (e) {
      this.mangoAPISrvc.showLoader(false);
      alert(e.message || e);
    }
  }

  loadLabelFromWeb(formattedStr?) {
    const str = formattedStr ? formattedStr : this.formattedStr
    const label = dymo.label.framework.openLabelXml(this.getAddressLabelXml(str));
    // check that label has an address object
    if (label.getAddressObjectCount() == 0) {
      alert("Selected label does not have an address object on it. Select another label");
      return;
    }

    label.setAddressText(0, str);
    return label;
  }

  loadPrinters() {
    const parent = this;
    const printers = dymo.label.framework.getPrinters();
    if (printers.length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'OK',
        text: 'No DYMO printers are installed or found.',
      });
      return;
    }

    if (parent.formPrinter != "") {
      parent.label = parent.loadLabelFromWeb();
      return false;
    }
    parent.formPrinter = "";

    for (let i = 0; i < printers.length; i++) {
      const printer = printers[i];
      if (printer.printerType == "LabelWriterPrinter") {
        parent.formPrinter = printer.name;
      }
    }

    parent.label = parent.loadLabelFromWeb();
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject('label_' + AppConstants.SearchString, "");
  }

  onRowSelect(id, type, event?) {
    if(this.selectedRows?.length > 0) {
      setTimeout(() => {
        if(this.selectedRows?.length > 0) {
          this.loadPrinters();
        }
      }, 800);
    }

    if((this.selectedRows?.length > 1 || this.selectedRows?.length == 0) || event)
      return false;

    const parent = this;
    parent.selectedClient = {};
    parent.formClientName = "";
    parent.formBusStreet1 = "";
    parent.formAddressItem = "";
    parent.formattedStr = "";

    if (type == 'parent') {
      if(this.selectedDisplay == 'contacts') {
        this.updateForm(this.selectedRows[0])
      } else {
        this.mangoAPISrvc.getClientFullinformation(this.selectedRows[0].ClientID).subscribe((data: any) => {
          this.updateForm(data)
        })
      }
    }
  }

  getAddressLabelXml(address) {
    const labelXml = `<?xml version="1.0" encoding="utf-8"?>\
          <DieCutLabel Version="8.0" Units="twips">\
            <PaperOrientation>Landscape</PaperOrientation>\
            <Id>Address</Id>\
            <PaperName>30252 Address</PaperName>\
            <DrawCommands>\
              <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270" />\
            </DrawCommands>\
            <ObjectInfo>\
              <AddressObject>\
                <Name>Address</Name>\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
                <LinkedObjectName></LinkedObjectName>\
                <Rotation>Rotation0</Rotation>\
                <IsMirrored>False</IsMirrored>\
                <IsVariable>True</IsVariable>\
                <HorizontalAlignment>Left</HorizontalAlignment>\
                <VerticalAlignment>Middle</VerticalAlignment>\
                <TextFitMode>ShrinkToFit</TextFitMode>\
                <UseFullFontHeight>True</UseFullFontHeight>\
                <Verticalized>False</Verticalized>\
                <StyledText>\
                  <Element>\
                    <String>`+ address + `</String>\
                          <Attributes>\
                              <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                              <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                          </Attributes>\
                      </Element>\
                  </StyledText>\
                  <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\
                  <BarcodePosition>AboveAddress</BarcodePosition>\
                  <LineFonts>\
                      <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                      <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                      <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                  </LineFonts>\
              </AddressObject>\
              <Bounds X="332" Y="150" Width="4455" Height="1260" />\
          </ObjectInfo>\
      </DieCutLabel>`;
    return labelXml;
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject('label_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 1000);
  }

  onShowInvoiceReview() {
    if (this.showInvoiceReview) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getinvoiceReview().subscribe(
        (reviewData: any) => {
          reviewData = reviewData
            .filter((obj) => obj.FinalizeAction == "Print")
            .map((obj) => {
              if (
                obj["CustGroupDescriptionIDArray"] &&
                obj["CustGroupDescriptionIDArray"].length > 0
              ) {
                obj["CustGroupDescriptionIDArray"].filter((obj) => obj != -1);
                obj["GroupDescriptionIDArrayDesc"] = this.clientGroupCategory
                  ?.filter((group) =>
                    obj["CustGroupDescriptionIDArray"].includes(
                      group["CustomerGroupCategoryID"]
                    )
                  )
                  .map((group) => group["GroupDescription"])
                  .join(", ");
              } else obj["GroupDescriptionIDArrayDesc"] = null;

              if (obj["ClientTypeID"]) {
                obj["CustomerTypeDescription"] = this.clientTypes.filter(
                  (type) => type.CustomerTypeID == obj["ClientTypeID"]
                )[0]?.CustomerTypeDescription;
              } else obj["CustomerTypeDescription"] = null;

              return {
                ClientID: obj["ClientID"],
                GroupDescriptionIDArrayDesc: obj["GroupDescriptionIDArrayDesc"],
                CustomerTypeDescription: obj["CustomerTypeDescription"],
                ClientName: obj["ClientName"],
              };
            });

          reviewData.sort(function (a, b) {
            if (a.ClientName.toLowerCase() < b.ClientName.toLowerCase()) {
              return -1;
            }
            if (a.ClientName.toLowerCase() > b.ClientName.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          this.filteredDataSource = reviewData.filter(
            (v, i, a) => a.findIndex((t) => t.ClientID === v.ClientID) === i
          );
          this.mangoAPISrvc.showLoader(false);
        },
        (err) => {
          this.filteredDataSource = [];
          this.mangoAPISrvc.showLoader(false);
        }
      );
    } else
      this.filteredDataSource = this.clientListDatasource.filter(
        (client) => client.Inactive != true
      );
  }

  updateForm(data) {
    this.selectedClient = data;
    this.formClientName = data['ClientName'] ? data['ClientName'] : "";
    this.formClientName = this.formClientName.replace("&", " and ");
    data['BusStreet1'] = data['BusStreet1'] ? data['BusStreet1'] : "";
    data['BusStreet2'] = data['BusStreet2'] ? data['BusStreet2'] : "";
    this.formBusStreet1 = data['BusStreet1'];
    if (data['BusStreet2'] != "") {
      this.formBusStreet1 += '\n' + data['BusStreet2'];
    }
    data['BusCity'] = data['BusCity'] ? data['BusCity'] : "";
    data['BusState'] = data['BusState'] ? data['BusState'] : "";
    data['BusZip'] = data['BusZip'] ? data['BusZip'] : "";
    this.formAddressItem = data['BusCity'] + '  ' + data['BusState'] + '   ' + data['BusZip'];
    this.formattedStr = this.formClientName + '\n' + this.formBusStreet1 + '\n' + this.formAddressItem;
  }

  prepareToPrint(data, index) {
    setTimeout(() => {
      this.updateForm(data);

      this.PrintLabel(this.formPrinter, this.formPrinterLabel, this.formattedStr);
      
      const intervalId = setInterval(() => {
        if(this.selectedRows.length == this.totalProcessed) {
          clearInterval(intervalId);
          setTimeout(() => {
            this.mangoAPISrvc.showLoader(false);
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              showCancelButton: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: 'OK',
              text: 'Successfully Printed.',
            });
          }, 200);
        } else
          return;
      })
    }, index * 1000)
  }

  onPrintLabels() {
    if(this.selectedRows.length > 1) {
      this.totalProcessed = 0;
      this.mangoAPISrvc.showLoader(true);
      this.selectedRows.forEach((row, index) => {
        if(this.selectedDisplay == 'contacts') {
          this.prepareToPrint(row, index)
        } else {
          this.mangoAPISrvc.getClientFullinformation(row.ClientID).subscribe((clientInfoRes: any) => {
            this.prepareToPrint(clientInfoRes, index)
          }, err => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg)
          })
        }
      })
    } else {
      this.PrintLabel(this.formPrinter, this.formPrinterLabel, this.formattedStr);
    }
  }

  onChangeSelectedDisplay() {
    this.selectedRows = [];
    this.showInvoiceReview = false;
    if(this.selectedDisplay == 'invoiceReview') {
      this.showInvoiceReview = true;
      this.onShowInvoiceReview();
    } else if(this.selectedDisplay == 'contacts') {
      this.displayContactRecords();
    } else {
      this.onShowInvoiceReview();
    }
  }

  displayContactRecords() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getContactsByCompanyID(this.companyID).subscribe((result: any) => {
      this.filteredDataSource = result.map((obj) => {
        if (
          obj["GroupDescriptionIDArray"] &&
          obj["GroupDescriptionIDArray"].length > 0
        ) {
          obj["GroupDescriptionIDArray"].filter((obj) => obj != -1);
          obj["GroupDescriptionIDArrayDesc"] = this.clientGroupCategory
            ?.filter((group) =>
              obj["GroupDescriptionIDArray"].includes(
                group["CustomerGroupCategoryID"]
              )
            )
            .map((group) => group["GroupDescription"])
            .join(", ");
        } else obj["GroupDescriptionIDArrayDesc"] = null;

        obj['BusStreet1'] = obj['Street1']
        obj['BusStreet2'] = obj['Street2']
        obj['BusCity'] = obj['City']
        obj['BusState'] = obj['State']
        obj['BusZip'] = obj['Zip']

        return obj;
      })

      this.mangoAPISrvc.showLoader(false);
    }, err => {
      this.filteredDataSource = [];
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg)
    })
  }
}
