import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent , CountService } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, CountService]
})
export class InvoicesComponent implements OnInit, AfterViewInit {

  settingInvoiceForm: UntypedFormGroup;
  invoiceTemplates: SelectItem[];
  invoiceEmailOptions: SelectItem[];
  statementEmailButtonOptions: SelectItem[];
  finalizeActions: SelectItem[];
  public isFormValid: boolean = false;
  public isStandardMsg: boolean = false;
  public isFooterMsg: boolean = false;
  public editStatementFooterMessageComponentStatus: boolean = false;
  public editor1Value: any = null;
  public editor2Value: any = null;
  public statementFooterMessageEditorValue: any = null;
  loginCompanyId: any;

  invoiceOpt: any = {};
  templates: SelectItem[];
  public termsList: any = [];
  invoice: any = {};
  GraceDays: any;
  selectedTerm: any;
  FinalizeAction: any;
  merchantIDs = {
    isStMIdPresents: null,
    CCmerchantID: null,
    LexOperatingMerchantAccountId: null
  }

  @ViewChild('standardEditor')
  public standardObj: RichTextEditorComponent;

  @ViewChild('footerEditor')
  public footerObj: RichTextEditorComponent;

  @ViewChild('statementFooterEditor')
  public statementFooterObject: RichTextEditorComponent;

  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
  };
  constructor(private fb: UntypedFormBuilder, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private _translate: TranslateService, private breadcrumbService: BreadcrumbService) {


    this.initializeForm();
    this.LoadDefaults();
  }

  ngOnInit(): void {
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this._translate.instant('Settings') },
      { label: this._translate.instant('invoices'), icon: 'ic-red' }
    ]);
    this.finalizeActions = [
      { label: this._translate.instant('print'), value: 'Print' },
      { label: this._translate.instant('email'), value: 'Email' },
      { label: this._translate.instant('finalize_only'), value: 'Finalize Only' }];
    this.invoiceTemplates = [
      { value: '2', label: this._translate.instant('client.narrative') },
      { value: '9', label: this._translate.instant('client.narrative_summary') },
      { value: '10', label: this._translate.instant('client.narrative_detail') },
      { value: '4', label: this._translate.instant('client.narrative_mini_statement') },
      { value: '0', label: this._translate.instant('client.detail') },
      { value: '6', label: this._translate.instant('client.detail_no_rate') },
      { value: '7', label: this._translate.instant('client.detail_no_rate_time') },
      { value: '11', label: this._translate.instant('client.detail_no_rate_time_amount') },
      { value: '12', label: this._translate.instant('client.narrative_simple_no_detail') },
      { value: '13', label: this._translate.instant('client.narrative_simple_with_detail') },
      { value: '14', label: this._translate.instant('client.narrative_simple_no_remit') },
      { value: '15', label: this._translate.instant('client.invoice_detail_summary') },
      { value: '16', label: this._translate.instant('client.invoice_narrative_summary_by_activity') },
    ];
    // this.statementEmailButtonOptions = [
    //   { value: true, label: this._translate.instant('Desktop-Client-Portal') },
    //   { value: false, label: this._translate.instant('No-Payment-Button') }
    // ]
    if (this.merchantIDs['isStMIdPresents'] == true) {
      this.invoiceEmailOptions = [
        { value: "isDesktopClientPortal", label: this._translate.instant('Desktop-Client-Portal') },
        { value: "isClickPayOnly", label: this._translate.instant('Click-To-Pay') },
        { value: "isClickPayandLogin", label: this._translate.instant('Portal-Login-And-Click-To-Pay') },
        { value: "isEmailNoPayment", label: this._translate.instant('No-Payment-Button') }
      ]
    } else if (this.merchantIDs['CCmerchantID'] || this.merchantIDs['LexOperatingMerchantAccountId']) {
      this.invoiceEmailOptions = [
        { value: "isDesktopClientPortal", label: this._translate.instant('Desktop-Client-Portal') },
        { value: "isEmailNoPayment", label: this._translate.instant('No-Payment-Button') }
      ]
    }
    else {
      this.invoiceEmailOptions = [
        { value: "isEmailNoPayment", label: this._translate.instant('No-Payment-Button') },
      ]
    }

    if (this.merchantIDs['isStMIdPresents'] == true) {
      this.statementEmailButtonOptions = [
        { value: 1, label: this._translate.instant('Desktop-Client-Portal') },
        { value: 2, label: this._translate.instant('Click-To-Pay') },
        { value: 3, label: this._translate.instant('Portal-Login-And-Click-To-Pay') },
        { value: 0, label: this._translate.instant('No-Payment-Button') }
      ]
    } else if (this.merchantIDs['CCmerchantID'] || this.merchantIDs['LexOperatingMerchantAccountId']) {
      this.statementEmailButtonOptions = [
        { value: 1, label: this._translate.instant('Desktop-Client-Portal') },
        { value: 0, label: this._translate.instant('No-Payment-Button') }
      ]
    }
    else {
      this.statementEmailButtonOptions = [
        { value: 0, label: this._translate.instant('No-Payment-Button') },
      ]
    }

  }

  LoadDefaults() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.loadInvoiceDefaults().subscribe((results: any) => {
      self.getTerms(results[0]);
      self.getInvoiceOption(results[1]);
      self.getInvoiceEmailOptions(results[2]);
      self.getMerchantIds(results[2]);
      self.mangoAPISrvc.showLoader(false);
      const interval = setInterval(() => {
        if (!this._translate.translations[this._translate.currentLang])
          return;
        clearInterval(interval);
        this.initTranslations();
      }, 300);
    });
  }

  getMerchantIds(data) {
    this.merchantIDs = {
      isStMIdPresents: data['isStMIdPresents'],
      CCmerchantID: data['CCmerchantID'],
      LexOperatingMerchantAccountId: data['LexOperatingMerchantAccountId']
    }
  }

  getInvoiceEmailOptions(data) {
    if (data['isClickPayOnly']) {
      this.settingInvoiceForm.controls['invoiceEmailOption'].setValue("isClickPayOnly");
    } else if (data['isClickPayandLogin']) {
      this.settingInvoiceForm.controls['invoiceEmailOption'].setValue("isClickPayandLogin");
    } else if (data['isDesktopClientPortal']) {
      this.settingInvoiceForm.controls['invoiceEmailOption'].setValue("isDesktopClientPortal");
    } else if (data['isEmailNoPayment']) {
      this.settingInvoiceForm.controls['invoiceEmailOption'].setValue("isEmailNoPayment");
    } else {
      this.settingInvoiceForm.controls['invoiceEmailOption'].setValue("isEmailNoPayment");
    }
  }

  getInvoiceOption(data) {
    data.NoLateFees = data.NoLateFees != null ? data.NoLateFees.toString() : "true";
    this.invoiceOpt = data;
    this.updatesettingInvoiceForm(data);
  }

  getTerms(obj) {
    const _this = this;
    for (let i = 0; i < obj.length; i++) {
      if (!obj[i].Inactive) {
        _this.termsList.push({ label: obj[i].TermsDescription, value: obj[i].TermsID, GraceDays: obj[i].GraceDays })
      }
    }
  }

  initializeForm() {
    this.settingInvoiceForm = this.fb.group({
      NoLateFees: [false],
      InterestRate: [''],
      GraceDays: [0],
      FinalizeAction: [''],
      NextInvoiceNumber: [0],
      GlobalInvoiceTemplate: [],
      IsHidePriorBalance: [false],
      isShowBeforeInvoiceDate: [false],
      isSurchargeActivated: [false],
      IsHideClientName: [false],
      isInvoiceBlocking: [false],
      InvoiceReminderActivate: [false],
      AutoRollupExpenses: [false],
      SkipInvoiceReview: [false],
      isPrintClientNumber: [false],
      InvoiceReminderInterval: [0],
      UseDefaultTerms: [''],
      TermsID: [''],
      StandardFooterMessage: '',
      InvoiceMessage: '',
      StmtFooterMessage: '',
      IsInterestRate: [false],
      invoiceEmailOption: [],
      statementReminderOption: [],
      statementEmailPaymentOption: [],
      isEditInvoiceAmount: [false],
      EmailDaysBeforeProcessingPmt: [0],
      isShowTearOffFooter: [false]
    })
  }

  ngAfterViewInit() {
    this.settingInvoiceForm.valueChanges.subscribe(data => {
      this.isFormValid = this.settingInvoiceForm.valid && this.settingInvoiceForm.dirty;
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, this.isFormValid);
    });
  }

  saveInvoice() {
    const _this = this;
    _this.encrDecSrvc.removeObject(AppConstants.invoiceOptions);
    const forObj = _this.settingInvoiceForm.value;
    if (forObj.NoLateFees == 'false') {
      forObj.IsInterestRate = true;
      forObj.InterestRate = forObj.InterestRate;
      forObj.NoLateFees = false;
    }
    if (forObj.NoLateFees == 'true') {
      forObj.IsInterestRate = false;
      forObj.InterestRate = null;
      forObj.NoLateFees = true;
    }
    _this.mangoAPISrvc.showLoader(true);
    forObj['ActivateCustomerPortal'] = forObj['statementEmailPaymentOption'] == 1 ? true : false;
    forObj['isClickPayOnly'] = forObj['isClickPayandLogin'] = forObj['isEmailNoPayment'] = forObj['isDesktopClientPortal'] = false;
    if (forObj['invoiceEmailOption']) forObj[forObj['invoiceEmailOption']] = true;
    _this.mangoAPISrvc.saveInvoice(forObj).subscribe(function (data: any) {

      const userLogs = {}
      userLogs['Action'] = "Settings - Invoice Option";
      userLogs['Description'] = "Update Company Settings"
      userLogs['Table'] = "CompanyMango";
      const isManaging = _this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
      if (!isManaging) {
        _this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
          console.log(err);
        });
      }

      _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      _this.mangoAPISrvc.showLoader(false);
      _this.isFormValid = false;
      _this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      _this.invoiceOpt.NoLateFees = forObj.NoLateFees.toString();
      _this.encrDecSrvc.addObject(AppConstants.invoiceOptions, forObj);
    }, error => {
      _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }


  updatesettingInvoiceForm(data) {
    const parent = this;
    if (data && Object.keys(data).length > 0) {
      parent.settingInvoiceForm.controls['NextInvoiceNumber'].setValue(data['NextInvoiceNumber']);
      parent.settingInvoiceForm.controls['GlobalInvoiceTemplate'].setValue(data['GlobalInvoiceTemplate'] || '2');
      parent.settingInvoiceForm.controls['statementEmailPaymentOption'].setValue(data['statementEmailPaymentOption']);
      parent.settingInvoiceForm.controls['FinalizeAction'].setValue(data['FinalizeAction']);
      parent.settingInvoiceForm.controls['IsHidePriorBalance'].setValue(data['IsHidePriorBalance']);
      parent.settingInvoiceForm.controls['isShowBeforeInvoiceDate'].setValue(data['isShowBeforeInvoiceDate']);
      parent.settingInvoiceForm.controls['isSurchargeActivated'].setValue(data['isSurchargeActivated']);
      parent.settingInvoiceForm.controls['IsHideClientName'].setValue(data['IsHideClientName']);
      parent.settingInvoiceForm.controls['isInvoiceBlocking'].setValue(data['isInvoiceBlocking']);
      parent.settingInvoiceForm.controls['InvoiceReminderActivate'].setValue(data['InvoiceReminderActivate']);
      parent.settingInvoiceForm.controls['AutoRollupExpenses'].setValue(data['AutoRollupExpenses']);
      parent.settingInvoiceForm.controls['SkipInvoiceReview'].setValue(data['SkipInvoiceReview']);
      parent.settingInvoiceForm.controls['isPrintClientNumber'].setValue(data['isPrintClientNumber'] || false);
      parent.settingInvoiceForm.controls['InvoiceReminderInterval'].setValue(data['InvoiceReminderInterval']);
      parent.settingInvoiceForm.controls['NoLateFees'].setValue(data['NoLateFees']);
      parent.settingInvoiceForm.controls['InterestRate'].setValue(data['InterestRate']);
      parent.settingInvoiceForm.controls['GraceDays'].setValue(data['GraceDays']);
      parent.settingInvoiceForm.controls['TermsID'].setValue(data['TermsID']);
      parent.settingInvoiceForm.controls['TermsID'].setValue(data['TermsID']);
      parent.settingInvoiceForm.controls['IsInterestRate'].setValue(data['IsInterestRate']);
      parent.settingInvoiceForm.controls['InvoiceMessage'].setValue(data['InvoiceMessage'] || '');
      parent.settingInvoiceForm.controls['StandardFooterMessage'].setValue(data['StandardFooterMessage'] || '');
      parent.settingInvoiceForm.controls['StmtFooterMessage'].setValue(data['StmtFooterMessage'] || '');
      parent.settingInvoiceForm.controls['EmailDaysBeforeProcessingPmt'].setValue(data['EmailDaysBeforeProcessingPmt'] || 0);
      parent.settingInvoiceForm.controls['isShowTearOffFooter'].setValue(data['isShowTearOffFooter'] || false);
      parent.editor1Value = data.InvoiceMessage ? data.InvoiceMessage : '';
      parent.editor2Value = data.StandardFooterMessage ? data.StandardFooterMessage : '';
      parent.statementFooterMessageEditorValue = data.StmtFooterMessage ? data.StmtFooterMessage : '';
      const isInvoiceEditing = data['isEditInvoiceAmount'];
      if(isInvoiceEditing) parent.settingInvoiceForm.controls['isEditInvoiceAmount'].setValue(isInvoiceEditing);
      else parent.settingInvoiceForm.controls['isEditInvoiceAmount'].setValue(false);
    }
  }

  updateGraceDays() {
    const template = this.termsList.filter((item) => item['value'] == this.invoiceOpt.TermsID);
    if (template.length > 0 && template[0]) {
      this.invoiceOpt.GraceDays = template[0].GraceDays;
    }

  }

  onEditorChange(content: string) {
    const cleanedContent = this.cleanHtml(content);
    if (this.editor2Value !== cleanedContent) {
      this.editor2Value = cleanedContent;
      this.settingInvoiceForm.get('StandardFooterMessage')?.setValue(cleanedContent);
      console.log(cleanedContent);
    }
  }

  cleanHtml(html: string): string {
    html = html.replace(/font-size:\s*(\d+(\.\d+)?)(px|em|rem|%);/g, (match, p1) => {
      const roundedSize = Math.round(parseFloat(p1));
      return `font-size: ${roundedSize}px;`;
    });
    console.log(html)
    return html;
  }

}
