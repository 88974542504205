import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

import { ClientViewComponent, ClientSettingsComponent} from '@app/modules/client';
import { EncrDecrService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { SharedComponentsService } from '@app/shared/components';

@Injectable()
export class CanDeactivateForm  {
  constructor(private _translate: TranslateService, private _service: EncrDecrService, private sharedService: SharedComponentsService) { }

  canDeactivate(
    component: ClientViewComponent | ClientSettingsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    const isFormChanged = this._service.getObject(AppConstants.isFormChanged);
    if (!isFormChanged) {
      return true;
    }
    return Observable.create((observer: Observer<boolean>) => {
      Swal.fire({
        title: this._translate.instant('Warning'),
        icon: 'warning',
        html: `${this._translate.instant('unsaved_changes_warning')} </br></br>` +
        '<button type="button" role="button" tabindex="0" class="swal2-confirm swal2-styled" style="display: inline-block;" id="yes_leave">' + this._translate.instant('leave') + '</button>' +
        '<button type="button" role="button" tabindex="0" class="swal2-confirm swal2-styled ic-green-bg" style="display: inline-block;" id="save_leave">' + this._translate.instant('save_and_leave') + '</button>' +
        '<button type="button" role="button" tabindex="0" class="swal2-cancel swal2-styled" style="display: inline-block;" id="no_stay">' + this._translate.instant('cancel_button') + '</button>',
        showCancelButton: false,
        showConfirmButton: false,
      });
      const parent = this;
      document.getElementById('yes_leave').onclick = function(){
        parent._service.addObject(AppConstants.isFormChanged, false); 
        observer.next(true);
        observer.complete();
        Swal.close();
      }
      document.getElementById('save_leave').onclick = function(){
        if(!parent.sharedService.leaveAndSaveSub.closed){
          parent.sharedService.leaveAndSaveSub.next(null);
        }
        parent._service.addObject(AppConstants.isFormChanged, false); 
        observer.next(true);
        observer.complete();
        Swal.close();
      }
      document.getElementById('no_stay').onclick = function(){
        observer.next(false);
        observer.complete();
        Swal.close();
      }
    });
  }
}
