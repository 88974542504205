import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CARET_DAY_CARDINAL, CARET_DAY_ORDINAL, CARET_MONTH, CARET_QUARTER_CARDINAL, CARET_QUARTER_ORDINAL, CARET_YEAR } from '@app/modules/project-management/project-details/constants';

type WildcardsInfo = {
  wildcard: string,
  equivalent: string,
  example: string,
}

@Component({
  selector: 'app-pm-wildcards-dialog',
  templateUrl: './pm-wildcards-dialog.component.html'
})

export class PmWildcardsDialogComponent implements OnInit {
  isDisplayWildcardsTable: boolean = true;
  tableData: WildcardsInfo[] = [
    {
      
      wildcard: CARET_MONTH,
      equivalent: 'Month',
      example: 'June, July, August, etc..',
    },
    {
      wildcard: CARET_YEAR,
      equivalent: 'Year',
      example: '2022, 2023, 2024, etc..',
    },
    {
      wildcard: CARET_QUARTER_CARDINAL,
      equivalent: 'Quarter - Cardinal',
      example: '1, 2, 3, and 4',
    },
    {
      wildcard: CARET_QUARTER_ORDINAL,
      equivalent: 'Quarter - Ordinal',
      example: '1st, 2nd, 3rd, and 4th',
    },
    {
      wildcard: CARET_DAY_ORDINAL,
      equivalent: 'Day of Month - Ordinal',
      example: '1st, 2nd, 3rd, 4th, 5th, etc..',
    },
    {
      wildcard: CARET_DAY_CARDINAL,
      equivalent: 'Day of Month - Cardinal',
      example: '1, 2, 3, 4, 5, etc..',
    }
  ]
  @Output() onClose = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseDialog() {
    this.isDisplayWildcardsTable = false
    this.onClose.emit(true)
  }
}
