import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import $ from "jquery";

import { AuthGuard, EncrDecrService } from '@app/_services';
import { AppConstants } from "@app/_helpers/api-constants";
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-setup-wizard',
  templateUrl: './setup-wizard.component.html',
  styles: [`
        .layout-sidebar{
          display: none;
        }
        .layout-topbar,.layout-breadcrumb{
          display: none;
        }
        .layout-main{
          padding-top: 0 ;
        }

        .layout-wrapper-static .layout-main {
          margin-left: 0px;
      }
      .center-box{
        display: flex;
        justify-content: center;
      }
    `],
  encapsulation: ViewEncapsulation.None
})
export class SetupWizardComponent implements OnInit {
  StaffName: any;
  profilePicUrl: any;
  rootUrls: any = [];
  items: MenuItem[];

  constructor(private zone: NgZone, private encrDecSrvc: EncrDecrService, private _authGuard: AuthGuard) {
    this.items = [{
      label: 'Company Information',
      routerLink: '/setup-wizard/company-info'
    },
    {
      label: 'Company Settings',
      routerLink: '/setup-wizard/company-settings'
    },
    {
      label: 'User Settings',
      routerLink: '/setup-wizard/user-settings'
    },
    {
      label: 'Finish',
      routerLink: '/setup-wizard/finished'
    }
    ];
  }

  ngOnInit() {
    this.loadUserProfile();
    this.initializeJS();
  }

  /* geting the user profile information
  */
  loadUserProfile() {
    this.StaffName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.profilePicUrl = this.encrDecSrvc.getObject(AppConstants.imageUrl);
  }

  logout() {
    this._authGuard.logout();
    this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  initializeJS() {
    $('.header .right .dropdown').on('mouseenter', function () {
      $('.dropDownList', $(this)).addClass('dropDownListBlock');
    });

    $('.header .right .dropdown ').on('mouseleave', function () {
      $('.dropDownList', $(this)).removeClass('dropDownListBlock');
    });
  }
}
