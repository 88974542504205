<div class="expenses">
    <div class="layout-content p-pt-0">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="openAddExpenses();"></p-button>
        </span>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mx-3 p-mt-2 p-pt-0">
                    <p-table #dt [value]="expenseList" [rowHover]="true" [responsive]="true"
                        dataKey="ExpenseCodeID" selectionMode="single" [showCurrentPageReport]="true" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [globalFilterFields]="['ExpenseCode','Description','Cost']" [resizableColumns]="true"
                        (onFilter)="onFilter($event)" [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'globalsearch' | translate}}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                </p-message>
                                <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                    <p-checkbox [(ngModel)]="expenseInactive" label="{{'show_inactive' | translate}}"
                                        binary="true" (onChange)="onStatusChange()">
                                    </p-checkbox>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-6p p-text-center">{{'actions' | translate}}</th>
                                <th pSortableColumn="ExpenseCode" pResizableColumn class="width-12p p-text-left">
                                    {{'expense-code' | translate}}
                                    <p-sortIcon field="ExpenseCode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Description" pResizableColumn class="width-30p p-text-left">
                                    {{'description' | translate}}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Cost" pResizableColumn class="width-5p p-text-right">{{'Cost' |
                                    translate}}
                                    <p-sortIcon field="Cost"></p-sortIcon>
                                </th>
                                <th class="width-6p p-text-center">{{'billable' | translate}}</th>
                                <th class="width-6p p-text-center">{{'taxable' | translate}}</th>
                                <th class="width-6p p-text-center">{{'inactive' | translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-6p">
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="openEditExpenses(rowData)" tooltipPosition="top"
                                            pTooltip="{{'Edit' | translate}}"></i>
                                        <i (click)="deleteExpense(rowData.ExpenseCodeID)"
                                            class="fal fa-trash-alt ic-red p-pl-2 cursor-hand" tooltipPosition="top"
                                            pTooltip="{{'mgmt-delete' | translate}}"></i>
                                    </div>
                                </td>
                                <td (click)="openEditExpenses(rowData)" class="width-12p p-text-left">
                                    {{rowData.ExpenseCode}}</td>
                                <td (click)="openEditExpenses(rowData)" class="width-30p p-text-left">
                                    {{rowData.Description}}</td>
                                <td (click)="openEditExpenses(rowData)" class="width-5p p-text-right">{{rowData.Cost}}
                                </td>
                                <td (click)="openEditExpenses(rowData)" class="width-6p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.Billable" [disabled]="true" [binary]="true">
                                    </p-checkbox>
                                </td>
                                <td (click)="openEditExpenses(rowData)" class="width-6p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.TaxMap1" [disabled]="true" [binary]="true">
                                    </p-checkbox>
                                </td>
                                <td class="width-6p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData.Inactive" [binary]="true"
                                        (onChange)="onExpenseListStatusChange(rowData)"></p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>  
</div>