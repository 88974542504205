import { Component, OnInit } from "@angular/core";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import { TranslateService } from "@ngx-translate/core";
declare let numeral: any;

@Component({
  selector: "app-cb-staff",
  templateUrl: "./staff.component.html",
})
export class StaffComponent implements OnInit {
  public staffList: any = [];
  public selectedStaff: any;
  data: any;
  options: any;
  statistics: any = {
    billedRevenue: {
      currentMonth: 0,
      lastYearMonth: 0,
      currentYear: 0,
      lastYear: 0,
      unbilled: 0,
      writeUpDown: 0,
      grossProfitMargin: 0,
      realizationRate: 0,
    },
    // customers: {
    //     new: 0,
    //     inactive: 0
    // },
    // tasks: {
    //     open: 0,
    //     pastDue: 0,
    //     completed: 0
    // },
    staffRevenueMonthly: [],
  };

  colors: any = ['#e57368', '#42a5f5', '#3366cc']


  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private _translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {

    const interval = setInterval(() => {
      if(!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.options = {
      title: {
        display: true,
        text: "Year on Year Comparison",
        fontSize: 16,
      },
      legend: {
        position: "bottom",
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
                label += ': $';
            }
            label += numeral(tooltipItem.yLabel).format("0,0");
            return label;
        }
        }
      }
    };

    this.getAllStaffList();
    this.selectedStaff = this.staffList.filter((staff) => staff.value === this.encrDecSrvc.getObject(AppConstants.staffID))[0];
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this._translate.instant('Company_Dashboard')},
      { label: this._translate.instant('staff-name'), icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {
    this.getCompanyDataByID(this.selectedStaff.value);
  }

  getAllStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < item.length; ++i) {
      const obj = {};
      obj["label"] = item[i]["StaffName"];
      obj["value"] = item[i]["StaffID"];
      obj["StaffID"] = item[i]["StaffID"];
      obj["StaffName"] = item[i]["StaffName"];
      obj["Email"] = item[i]["Email"];
      this.staffList.push(obj);
    }
  }

  onSelectStaff(opt) {
    this.getCompanyDataByID(this.selectedStaff.value);
  }

  getCompanyDataByID(id) {
    const parent = this;
   parent.mangoAPISrvc.showLoader(true)
    parent.mangoAPISrvc.getDashboardDataByStaffId(id).subscribe((result: any) => {
      parent.statistics = result;
      parent.drawCharts();
      parent.mangoAPISrvc.showLoader(false);
    }, err => parent.mangoAPISrvc.showLoader(false))
  }

  private drawCharts() {
    const series = this.statistics.billedRevenueMonthly.map((yearStats, index) => {
      return {
        label: yearStats.name,
        backgroundColor: this.colors[index],
        borderColor: this.colors[index],
        data: yearStats.data,
      };
    });

    this.data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: series,
    };
  }
}
