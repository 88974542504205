<div class="shared-budgets">
    <!-- <span class="pull-right p-mr-3 clientform-btn-top">
        <button type="button" pButton pRipple label="{{ 'add' | translate }}" icon="fal fa-plus"
            class="p-mr-2 p-button-sm" *ngIf="budgetsObject.MonthAmount.length == 0 && budgetsObject.MonthAmount.length == 0"></button>
        <button type="button" pButton pRipple label="{{ 'save_button' | translate }}" icon="fal fa-check"
            class="p-mr-2 p-button-sm" *ngIf="budgetsObject.MonthAmount.length > 0 && budgetsObject.MonthAmount.length > 0" [disabled]="true"></button>
    </span> -->
    <div>
        <div class="card p-mb-2 p-col-12">
            <div class="p-col-12 p-formgroup-inline">
                <label class="label-text ic-size-16 p-mt-2">{{ 'Year' | translate }}:</label>
                <p-dropdown [options]="currSavedBudgetYears" [(ngModel)]="budgetYear" styleClass="p-mx-4"
                    [style]="{width: '150px'}" [disabled]="!budgetsObject.isShowBudgets"
                    [ngModelOptions]="{ standalone: true }" (onChange)="handleSelectYear()" placeholder="Select Year">
                </p-dropdown>
                <label class="label-text ic-size-16 p-ml-3 p-mt-2" *ngIf="isFromProject">{{ 'project' | translate
                    }}:</label>
                <label
                    class="label-text ic-size-16 p-ml-3 {{ !budgetsObject.isShowBudgets ? 'p-mr-3' : ''}} ic-black p-mt-2"
                    *ngIf="isFromProject">{{ projectBudgetObj['TemplateName'] }}</label>

                <label class="label-text ic-size-16 p-mt-2" *ngIf="!isFromProject">{{ 'engagement' | translate
                    }}:</label>
                <p-dropdown [options]="currSavedBudgetEngagements" [(ngModel)]="budgetEngagement"
                    [ngModelOptions]="{ standalone: true }" (onChange)="refresh()" *ngIf="!isFromProject"
                    styleClass="p-ml-4" [style]="{width: '300px'}"
                    [disabled]="!budgetsObject.isShowBudgets || isFromProject" placeholder="Select Engagement">
                </p-dropdown>
                <p-badge *ngIf="!isFromProject && this.budgetsFromProject.length > 0 && budgetEngagement"
                    pTooltip="{{'budgets.badge_tooltip' | translate}}" tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap" [value]="this.budgetsFromProject.length"
                    styleClass="bg-green p-mt-2 cursor-hand" (click)="onBadgeClick()" [style]="{'margin-left':'8px'}">
                </p-badge>

                <label *ngIf="budgetsObject.isShowBudgets" class="label-text p-mx-4 ic-size-14 p-mt-2"
                    style="font-style: italic;">
                    Firm Year: {{ budgetsObject.YTD.dateEnd | date:'MM/dd/yyyy' }}
                    <i pTooltip="{{ budgetsObject.YearEndMonth == 12 ? 'Firm Calendar Year' : 'Firm
                        Fiscal Year'}}: {{ budgetsObject.YTD.dateStart | date:'MM/dd/yyyy' }} - {{ budgetsObject.YTD.dateEnd
                        | date:'MM/dd/yyyy' }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                        class="fa fa-question-circle ic-blue p-ml-1 ic-size-14"></i>
                </label>
                <button type="button"
                    *ngIf="isFromProject || (!isFromProject && budgetEngagement) || !budgetsObject.isShowBudgets || budgetsObject.isShowBudgets"
                    pButton pRipple label="{{ 'add' | translate }} Budget" icon="fal fa-plus"
                    [ngClass]="!budgetsObject.isShowBudgets ? 'p-ml-3 p-mr-3 p-button-sm' : 'p-ml-0 p-mr-3 p-button-sm'"
                    (click)="showAddDialog()">
                </button>
                <button type="button" *ngIf="(budgetEngagement || isFromProject) && budgetsObject.isShowBudgets" pButton
                    pRipple label="{{ 'mgmt-delete' | translate }} Budget" icon="fal fa-trash-alt"
                    class="p-mr-3 p-button-sm" (click)="deleteBudget()">
                </button>
                <button type="button" *ngIf="(budgetEngagement || isFromProject) && budgetsObject.isShowBudgets" pButton
                    pRipple label="{{ 'Settings' | translate }}" icon="fal fa-cogs" class="p-mr-3 p-button-sm"
                    (click)="manageBudget()">
                </button>
                <demo-video videoCode="nq1q1tln1h"></demo-video>
            </div>

            <div class="p-col-12 p-mt-2 p-d-flex" *ngIf="isFromProject">
                <div class="p-pl-0 p-pr-6" *ngIf="budgetsObject.isShowBudgets">
                    <p-checkbox [(ngModel)]="budgetsObject.isTrackTimeTaskLevel" [binary]="true" [disabled]="mySettingsForm.value.isTaskBudgetEnabled"
                        label="Enable Task Budget" [ngModelOptions]="{ standalone: true }" class="label-text"
                        (onChange)="onChangeTrackTime()">
                    </p-checkbox>
                </div>
                <div class="p-pl-6 p-ml-1">
                    <label class="label-text ic-size-16 p-mt-2">{{ 'engagement' | translate }}:</label>
                    <label class="label-text ic-size-16 p-mx-3 ic-black p-mt-2">{{
                        projectBudgetObj['ProjectMasterLabel'] }}</label>
                </div>
            </div>
        </div>
        
        <div class="p-pr-2" *ngIf="budgetsObject.isShowBudgets">
            <div *ngIf="!isFromProject">
                <div class="p-col-12">
                    <h4 class="ic-red p-mb-0">YTD - {{'client.revenue' | translate}}</h4>
                </div>

                <div class="width-100p p-d-flex p-jc-between">
                    <div class="width-13p">
                        <p-panel>
                            <ng-template pTemplate="header">
                                <div class="p-px-2 p-d-flex p-jc-between">
                                    <label>Budget</label>
                                    <label
                                    [class]="'ic-size-13 p-mt-1 ' + (budgetsObject.RevenueFigures.BudgetPct > 100 ? 'ic-light-red' : budgetsObject.RevenueFigures.BudgetPct > 50 ? 'ic-yellow' : 'ic-green')">
                                        ({{budgetsObject.RevenueFigures.BudgetPct | number : '1.2-2'}}%)
                                    </label>
                                </div>
                            </ng-template>
                            <div class="p-grid ic-blue ic-size-16">
                                <div class="p-col-4 p-py-0 p-pl-4">
                                    <button pButton pRipple name="syncIcon"
                                        *ngIf="(budgetEngagement && budgetsObject.isShowBudgets) || (isFromProject && budgetsObject.isShowBudgets)"
                                        pTooltip="Update Budget" (click)="showEditDialog('revenue')" type="button"
                                        icon="fal fa-pencil" class="budget-edit-btn p-button-rounded p-mr-3"></button>
                                </div>
                                <label class="p-col-8 p-px-3 p-py-1">{{ budgetsObject.RevenueAmount |
                                    currency:'USD':'symbol':'1.2-2' }}</label>
                            </div>
                            <!-- <div class="p-d-flex p-jc-end">
                                <label class="ic-orange ic-size-13">
                                    {{ budgetsObject.RevenueFigures.BudgetPct | number : '1.2-2' }}%
                                </label>
                            </div> -->
                        </p-panel>
                    </div>
                    <div class="width-13p">
                        <p-panel header="WIP Value">
                            <div class="p-text-center ic-blue ic-size-16">
                                {{ budgetsObject.RevenueFigures.WIP | currency:'USD':'symbol':'1.2-2' }}
                            </div>
                        </p-panel>
                    </div>
                    <div class="width-13p">
                        <p-panel header="Billed/Unbilled Value">
                            <div class="p-text-center ic-blue ic-size-16">
                                {{ budgetsObject.RevenueFigures.TimeValue | currency:'USD':'symbol':'1.2-2' }}
                            </div>
                        </p-panel>
                    </div>
                    <div class="width-13p">
                        <p-panel header="Billed Amount">
                            <div class="p-text-center ic-blue ic-size-16">
                                {{ (budgetsObject.RevenueFigures.BilledAmount < 0 ? '(' +
                                    (mangoUtils.getAbsolute(budgetsObject.RevenueFigures.BilledAmount) | currency : 'USD' :
                                    true : '1.2-2' ) + ')' : budgetsObject.RevenueFigures.BilledAmount | currency : 'USD' :
                                    true : '1.2-2' ) }} </div>
                        </p-panel>
                    </div>
                    <div class="width-13p">
                        <p-panel header="WU/WD">
                            <div class="p-text-center ic-blue ic-size-16">
                                {{ (budgetsObject.RevenueFigures.WUWD < 0 ? '(' +
                                    (mangoUtils.getAbsolute(budgetsObject.RevenueFigures.WUWD) | currency : 'USD' : true
                                    : '1.2-2' ) + ')' : budgetsObject.RevenueFigures.WUWD | currency : 'USD' : true
                                    : '1.2-2' ) }} </div>
                        </p-panel>
                    </div>
                    <div class="width-13p">
                        <p-panel header="Total Cost">
                            <div class="p-text-center ic-blue ic-size-16">
                                {{ (budgetsObject.RevenueFigures.TotalCost < 0 ? '(' +
                                    (mangoUtils.getAbsolute(budgetsObject.RevenueFigures.TotalCost) | currency : 'USD' :
                                    true : '1.2-2' ) + ')' : budgetsObject.RevenueFigures.TotalCost | currency : 'USD' :
                                    true : '1.2-2' ) }} </div>
                        </p-panel>
                    </div>
                    <div class="width-13p">
                        <p-panel header="Profit">
                            <div class="p-text-center ic-blue ic-size-16">
                                {{ (budgetsObject.RevenueFigures.Profit < 0 ? '(' +
                                    (mangoUtils.getAbsolute(budgetsObject.RevenueFigures.Profit) | currency : 'USD' :
                                    true : '1.2-2' ) + ')' : budgetsObject.RevenueFigures.Profit | currency : 'USD' :
                                    true : '1.2-2' ) }} </div>
                        </p-panel>
                    </div>
                </div>
            </div>

            <div class="p-col-12">
                <h4 class="ic-red p-mb-0">
                    YTD - {{'Time' | translate}}
                    <i [pTooltip]="getTimeTooltip()" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                        class="fa fa-question-circle ic-blue p-ml-1 ic-size-16"></i>
                </h4>
            </div>

            <div class="width-100p p-d-flex p-jc-between">
                <div class="width-24p">
                    <p-panel>
                        <ng-template pTemplate="header">
                            <div class="p-px-2 p-d-flex p-jc-between">
                                <label>Budget</label>
                                <label
                                    [class]="'ic-size-13 p-mt-1 ' + (budgetsObject.TimeFigures.BudgetPct > 100 ? 'ic-light-red' : budgetsObject.TimeFigures.BudgetPct > 50 ? 'ic-yellow' : 'ic-green')">
                                    ({{budgetsObject.TimeFigures.BudgetPct | number : '1.2-2'}}%)
                                </label>
                            </div>
                        </ng-template>
                        <div class="p-grid ic-blue ic-size-16">
                            <div class="p-col-4 p-py-0 p-pl-4">
                                <button pButton pRipple name="syncIcon"
                                    *ngIf="(!isFromProject && budgetEngagement && budgetsObject.isShowBudgets) || (isFromProject && budgetsObject.isShowBudgets && !budgetsObject.isTrackTimeTaskLevel)"
                                    pTooltip="Update Budget" (click)="showEditDialog('time')" type="button"
                                    icon="fal fa-pencil" class="budget-edit-btn p-button-rounded p-mr-3"></button>
                            </div>
                            <label class="p-col-8 p-px-3 p-py-1">{{ budgetsObject.TimeAmount | number : '1.2-2'
                                }}</label>
                        </div>
                        <!-- <div class="p-d-flex p-jc-end">
                            <label class="ic-orange ic-size-13">
                                {{ budgetsObject.TimeFigures.BudgetPct | number : '1.2-2' }}%
                            </label>
                        </div> -->
                    </p-panel>
                </div>
                <div class="width-24p">
                    <p-panel header="WIP Hrs.">
                        <div class="p-text-center ic-blue ic-size-16">
                            {{ budgetsObject.TimeFigures.WIP | number : '1.2-2' }}
                        </div>
                    </p-panel>
                </div>
                <div class="width-24p">
                    <p-panel header="Total Billed Hrs.">
                        <div class="p-text-center ic-blue ic-size-16">
                            {{ budgetsObject.TimeFigures.TotalBilledHrs | number : '1.2-2' }}
                        </div>
                    </p-panel>
                </div>
                <div class="width-24p">
                    <p-panel header="Total YTD Hrs.">
                        <div class="p-text-center ic-blue ic-size-16">
                            {{ budgetsObject.TimeFigures.TotalHrs | number : '1.2-2' }}
                        </div>
                    </p-panel>
                </div>
                <!-- <div class="width-19p">
                    <p-panel header="% of Budget">
                        <div class="p-text-center ic-blue ic-size-16">
                            {{ budgetsObject.TimeFigures.BudgetPct | number : '1.2-2' }}%
                        </div>
                    </p-panel>
                </div> -->
            </div>

            <div *ngIf="budgetsObject.isShowBudgets && isFromProject && budgetsObject.isTrackTimeTaskLevel">
                <div class="p-col-12 p-pt-4 p-px-0">
                    <app-tasks-budget [budget]="budgetsObject.budgets[0]"
                        [initialBudgetForTime]="initialBudgetForTime"
                        (onTasksBudgetSaved)="onTasksBudgetSaved($event)"
                        [ddmProjectHeaderID]="projectBudgetObj.ddmProjectHeaderID"></app-tasks-budget>
                </div>
            </div>

            <div class="p-col-12 p-mt-2 p-px-0 manual" style="padding-bottom: 200px;">
                <p-panel header="YTD - Total Billed/Unbilled Time" [toggleable]="true">
                    <p-chart responsive="true" height="300px" type="bar" [data]="graphData" [options]="graphOptions">
                    </p-chart>
                </p-panel>
            </div>
            <!-- <div class="card p-mb-0 p-mr-1 p-col-6 p-pt-0">
                <div class="p-col-12 p-pt-3 p-pb-0 p-text-center">
                    <h4>{{'client.revenue' | translate}} (Invoice)</h4>
                </div>
                <div class="p-col-12 p-pt-0">
                    <p-table [value]="revenueDataSource" [responsive]="true" [rowHover]="true"
                        sortField="index" [sortOrder]="1" editMode="row"
                        dataKey="index" [scrollable]="true" [scrollHeight]="'calc(60vh - 170px)'"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-25p p-text-left">
                                    {{ 'Month' | translate }}
                                </th>
                                <th class="width-15p p-text-right">
                                    {{ 'budget_amount' | translate }}
                                </th>
                                <th class="width-15p p-text-right">
                                    {{ 'actual_amount' | translate }}
                                </th>
                                <th class="width-15p p-text-right">
                                    {{ 'difference' | translate }}
                                </th>
                                <th class="width-15p p-text-center">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rIndex="rowIndex" let-rowData let-editing="editing">
                            <tr [pEditableRow]="rowData">
                                <td class="width-25p p-text-left">
                                    {{ rowData.Month }}
                                </td>
                                <td pInitEditableRow *ngIf="budgetEngagement" (click)="onRowEditInit(rowData, 'revenue', rIndex)" class="width-15p p-text-right">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="width-100p p-text-right rev-{{rIndex}}" type="text" pInputText [(ngModel)]="rowData.BudgetAmount" (focus)="$event.target.select();"
                                                pKeyFilter="num" [ngModelOptions]="{standalone: true}">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData.BudgetAmount | currency:'USD':'symbol':'1.2-2' }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-15p p-text-right" *ngIf="!budgetEngagement">
                                    {{(rowData.BudgetAmount) | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-15p p-text-right">
                                    {{(rowData.ActualAmount) | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-15p p-text-right">
                                    <span [style.color]="mangoUtils.setWriteUpDownColor(rowData.Difference)">
                                        {{ (rowData.Difference < 0 ? '(' + (mangoUtils.getAbsolute(rowData.Difference) | currency : 'USD' : true : '1.2-2') + ')' : rowData.Difference | currency : 'USD' : true : '1.2-2' ) }}
                                    </span>
                                </td>
                                <td class="width-15p p-text-center">
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                        (click)="onRowEditSave(rowData, 'revenue');"
                                        class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                        (click)="onRowEditCancel(rowData, 'revenue')"
                                        class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer"> 
                            <tr>
                                <td class="width-25p p-text-left ic-red">
                                    {{'Totals' | translate}}
                                </td>
                                <td class="width-15p p-text-right ic-red">
                                    {{ (footerTotalRevenue.BudgetAmount < 0 ? '(' + (mangoUtils.getAbsolute(footerTotalRevenue.BudgetAmount) | currency : 'USD' : true : '1.2-2') + ')' : footerTotalRevenue.BudgetAmount | currency : 'USD' : true : '1.2-2' ) }}
                                </td>
                                <td class="width-15p p-text-right ic-red">
                                    {{ (footerTotalRevenue.ActualAmount < 0 ? '(' + (mangoUtils.getAbsolute(footerTotalRevenue.ActualAmount) | currency : 'USD' : true : '1.2-2') + ')' : footerTotalRevenue.ActualAmount | currency : 'USD' : true : '1.2-2' ) }}
                                </td>
                                <td class="width-15p p-text-right ic-red">
                                    {{ (footerTotalRevenue.Difference < 0 ? '(' + (mangoUtils.getAbsolute(footerTotalRevenue.Difference) | currency : 'USD' : true : '1.2-2') + ')' : footerTotalRevenue.Difference | currency : 'USD' : true : '1.2-2' ) }}
                                </td>
                                <td class="width-15p p-text-center"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="card p-ml-1 p-col-6 p-pt-0">
                <div class="p-col-12 p-pt-3 p-pb-0 p-text-center">
                    <h4>{{'Time' | translate}} (Billable/Nonbillable)</h4>
                </div>
                <div class="p-col-12 p-pt-0">
                    <p-table [value]="timeDataSource" [responsive]="true" [rowHover]="true"
                        sortField="index" [sortOrder]="1" editMode="row"
                        dataKey="index" [scrollable]="true" [scrollHeight]="'calc(60vh - 170px)'"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-25p p-text-left">
                                    {{ 'Month' | translate }}
                                </th>
                                <th class="width-15p p-text-right">
                                    {{ 'budget_amount' | translate }}
                                </th>
                                <th class="width-15p p-text-right">
                                    {{ 'actual_amount' | translate }}
                                </th>
                                <th class="width-15p p-text-right">
                                    {{ 'difference' | translate }}
                                </th>
                                <th class="width-15p p-text-center">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rIndex="rowIndex" let-rowData let-editing="editing">
                            <tr [pEditableRow]="rowData">
                                <td class="width-25p p-text-left">
                                    {{ rowData.Month }}
                                </td>
                                <td pInitEditableRow *ngIf="budgetEngagement" (click)="onRowEditInit(rowData, 'time', rIndex)" class="width-15p p-text-right">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="width-100p p-text-right time-{{rIndex}}" type="text" pInputText [(ngModel)]="rowData.BudgetAmount" (focus)="$event.target.select();"
                                                pKeyFilter="num" [ngModelOptions]="{standalone: true}">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData.BudgetAmount | number:'1.2-2' }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-15p p-text-right" *ngIf="!budgetEngagement">
                                    {{(rowData.BudgetAmount) | number : '1.2-2' }}
                                </td>
                                <td class="width-15p p-text-right">
                                    {{(rowData.ActualAmount) | number : '1.2-2' }}
                                </td>
                                <td class="width-15p p-text-right">
                                    <span [style.color]="mangoUtils.setWriteUpDownColor(rowData.Difference)">
                                        {{ (rowData.Difference < 0 ? '(' + (mangoUtils.getAbsolute(rowData.Difference) | number : '1.2-2') + ')' : rowData.Difference | number : '1.2-2' ) }}
                                    </span>
                                </td>
                                <td class="width-15p p-text-center">
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                        (click)="onRowEditSave(rowData, 'time');"
                                        class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                        (click)="onRowEditCancel(rowData, 'time')"
                                        class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer"> 
                            <tr>
                                <td class="width-25p p-text-left ic-red">
                                    {{'Totals' | translate}}
                                </td>
                                <td class="width-15p p-text-right ic-red">
                                    {{ (footerTotalTime.BudgetAmount < 0 ? '(' + (mangoUtils.getAbsolute(footerTotalTime.BudgetAmount) | number : '1.2-2') + ')' : footerTotalTime.BudgetAmount | number : '1.2-2' ) }}
                                </td>
                                <td class="width-15p p-text-right ic-red">
                                    {{ (footerTotalTime.ActualAmount < 0 ? '(' + (mangoUtils.getAbsolute(footerTotalTime.ActualAmount) | number : '1.2-2') + ')' : footerTotalTime.ActualAmount | number : '1.2-2' ) }}
                                </td>
                                <td class="width-15p p-text-right ic-red">
                                    {{ (footerTotalTime.Difference < 0 ? '(' + (mangoUtils.getAbsolute(footerTotalTime.Difference) | number : '1.2-2') + ')' : footerTotalTime.Difference | number : '1.2-2' ) }}
                                </td>
                                <td class="width-15p p-text-center"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div> -->
        </div>
    </div>
    <div class="card" *ngIf="!budgetsObject.isShowBudgets">
        <h4>{{ 'data_not_found' | translate }}</h4>
    </div>
</div>

<div class="form-dialog shared-budgets-form">
    <p-dialog [(visible)]="showAddUpdateDialog" *ngIf="showAddUpdateDialog" [modal]="true" [style]="{ width: '33vw' }"
        [contentStyle]="{overflow: 'visible'}" [baseZIndex]="1" [maximizable]="false" showEffect="fade"
        [draggable]="true" [resizable]="false" (onHide)="onCloseAddEditDialog()">
        <p-header>
            {{ isEditMode ? "Edit Budget" : "Add Budget" }}
        </p-header>
        <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
            <div *ngIf="!isEditMode" class="p-formgroup-inline p-col-12">
                <div class="p-col-4 p-mt-4">
                    <label class="label-text">Year</label>
                </div>
                <div class="p-col-8 p-mt-4">
                    <p-dropdown [options]="yearsList" autofocus [(ngModel)]="formObj.Year"
                        [ngModelOptions]="{ standalone: true }" styleClass="width-100p" placeholder="Select Year">
                    </p-dropdown>
                </div>
            </div>
            <div *ngIf="!isEditMode" class="p-formgroup-inline p-col-12">
                <div class="p-col-4 p-mt-4">
                    <label class="label-text">Engagement</label>
                </div>
                <div class="p-col-8 p-mt-4">
                    <p-dropdown [options]="engagementListItems" [disabled]="isFromProject" autofocus
                        [(ngModel)]="formObj.ProjectMasterID" [ngModelOptions]="{ standalone: true }"
                        styleClass="width-100p" placeholder="Select Engagement">
                    </p-dropdown>
                </div>
            </div>
            <div *ngIf="!isFromProject && (!isEditMode || (isEditMode && formObj.type === 'revenue'))"
                class="p-formgroup-inline p-col-12">
                <div class="p-col-4 p-mt-4">
                    <label class="label-text">Revenue Budget</label>
                </div>
                <div class="p-col-8 p-mt-4">
                    <input class="width-100p p-text-right" type="text" pInputText
                        [(ngModel)]="formObj.RevenueAmount" (focus)="$event.target.select();"
                        (blur)="formObj.RevenueAmount = mangoUtils.doMoneyFormat(formObj.RevenueAmount)"
                        pKeyFilter="num" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div *ngIf="((!isEditMode && !isFromProject && !ddmSettings?.isBudgetingProjectLevel) || !isEditMode && isFromProject)  || (isEditMode && formObj.type === 'time')"
                class="p-formgroup-inline p-col-12">
                <div class="p-col-4 p-mt-4">
                    <label class="label-text">{{ isFromProject ? 'Project' : '' }} Time Budget</label>
                </div>
                <div class="p-col-8 p-mt-4">
                    <input class="width-100p p-text-right" type="text" pInputText
                        [(ngModel)]="formObj.TimeAmount" (focus)="$event.target.select();"
                        (blur)="formObj.TimeAmount = mangoUtils.doMoneyFormat(formObj.TimeAmount, 'time')"
                        pKeyFilter="num" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <!--rollover options-->
            <div class="p-col-12 p-mt-4" *ngIf="!isEditMode">
                <h5 class="label-text p-mb-0"><strong>Rollover Options</strong></h5>
                <div class="p-d-flex p-jc-start p-ml-2">
                    <div class="p-d-flex">
                        <p-checkbox [(ngModel)]="rolloverObj.isDefaultBudgetCompanyRollover" [binary]="true"
                            label="Use Firm Defaults" [ngModelOptions]="{ standalone: true }" class="p-mt-4 label-text">
                        </p-checkbox>
                        <i pTooltip="{{ 'budgets.firm_defaults' | translate }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                            class="fa fa-question-circle ic-blue p-ml-2  ic-size-15 p-pt-4 p-mt-1"></i>
                    </div>
                    <p-checkbox [(ngModel)]="rolloverObj.isEnableRollover" [binary]="true"
                        [disabled]="rolloverObj.isDefaultBudgetCompanyRollover"
                        label="Enable Automatic Rollover to Next Year" [ngModelOptions]="{ standalone: true }"
                        class="p-mt-4 p-ml-6 label-text">
                    </p-checkbox>
                </div>
                <div>
                    <div class="p-col-12 p-my-4 p-d-flex p-jc-between">
                        <div class="p-col-12 p-pl-0">
                            <p-radioButton name="BudgetTemplate"
                                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover"
                                label="Increase by fixed percentage" [(ngModel)]="rolloverOptions" value="percentage">
                            </p-radioButton>
                        </div>
                    </div>
                    <div class="p-col-12 p-pl-4 p-mb-4 p-pr-0 p-d-flex p-jc-between" *ngIf="!isFromProject">
                        <div class="p-col-6">
                            <p-checkbox [(ngModel)]="rolloverObj.isUpdateRevenue" [binary]="true" label="Revenue Amount"
                                [ngModelOptions]="{ standalone: true }" class="label-text"
                                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollover">
                            </p-checkbox>
                        </div>
                        <div class="p-col-6 p-pl-0 p-pr-2">
                            <span class="p-inputgroup">
                                <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                    [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateRevenue || !rolloverObj.isEnableRollover"
                                    [(ngModel)]="rolloverObj.percentage" mode="decimal" locale="en-US"
                                    inputStyleClass="p-text-right" [ngModelOptions]="{ standalone: true }"
                                    [minFractionDigits]="2"></p-inputNumber>
                                <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-pr-0 p-mb-4 p-d-flex p-pl-4 p-jc-between"
                        *ngIf="(!isFromProject && !ddmSettings?.isBudgetingProjectLevel) || (isFromProject)">
                        <div class="p-col-6">
                            <p-checkbox [(ngModel)]="rolloverObj.isUpdateTime" [binary]="true" label="Time Amount"
                                [ngModelOptions]="{ standalone: true }" class="label-text"
                                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollover">
                            </p-checkbox>
                        </div>
                        <div class="p-col-6 p-pl-0 p-pr-2">
                            <span class="p-inputgroup">
                                <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                    [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateTime || !rolloverObj.isEnableRollover"
                                    [(ngModel)]="rolloverObj.timePercentage" mode="decimal" locale="en-US"
                                    inputStyleClass="p-text-right" [ngModelOptions]="{ standalone: true }"
                                    [minFractionDigits]="2"></p-inputNumber>
                                <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-mb-4">
                        <p-radioButton name="BudgetTemplate"
                            [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover"
                            label="Create from Existing Budget" [(ngModel)]="rolloverOptions" value="existing">
                        </p-radioButton>
                    </div>
                    <div class="p-col-12 p-mb-4">
                        <p-radioButton name="BudgetTemplate"
                            [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover"
                            label="Create based on Actual Amounts" [(ngModel)]="rolloverOptions" value="actual">
                        </p-radioButton>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex p-jc-end">
                <button type="button" pButton pRipple label="{{ (!isEditMode ? 'create' : 'Save') | translate }}"
                    icon="fal fa-check" (click)="onSaveDialog()" [disabled]="shouldDisable()"></button>
                <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'Cancel' | translate }}"
                    (click)="onCloseAddEditDialog()"></button>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="showAddTemplateDialog" *ngIf="showAddTemplateDialog" [modal]="true"
        [style]="{ width: '40vw' }" [contentStyle]="{overflow: 'visible'}" [baseZIndex]="1" [maximizable]="false"
        showEffect="fade" [draggable]="true" [resizable]="false" (onHide)="onCloseAddTemplateDialog()">
        <p-header>
            Add Budget for the Year {{getNextYear()}}
        </p-header>
        <div class="p-pl-3 p-fluid p-formgrid p-grid p-m-1 common-dialog">
            <div class="p-col-12 p-d-flex p-mb-5 p-jc-between">
                <div class="p-col-12 p-pl-0">
                    <p-radioButton name="BudgetTemplate" label="Increase by fixed percentage" [(ngModel)]="radOptions"
                        value="percentage">
                    </p-radioButton>
                </div>
            </div>
            <div class="p-col-12 p-pl-5 p-mb-3 p-d-flex p-jc-between" *ngIf="!isFromProject">
                <div class="p-col-6">
                    <p-checkbox [(ngModel)]="templateObj.isUpdateRevenue" [binary]="true" label="Revenue Amount"
                        [ngModelOptions]="{ standalone: true }" class="label-text"
                        [disabled]="radOptions !== 'percentage'">
                    </p-checkbox>
                </div>
                <div class="p-col-6">
                    <span class="p-ml-5 p-inputgroup">
                        <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                            [disabled]="!templateObj.isUpdateRevenue" [(ngModel)]="templateObj.percentage"
                            mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                            [ngModelOptions]="{ standalone: true }" [minFractionDigits]="2"></p-inputNumber>
                        <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-d-flex p-pl-5 p-mb-3 p-jc-between"
                *ngIf="(!isFromProject && !ddmSettings?.isBudgetingProjectLevel) || (isFromProject)">
                <div class="p-col-6">
                    <p-checkbox [(ngModel)]="templateObj.isUpdateTime" [binary]="true" label="Time Amount"
                        [ngModelOptions]="{ standalone: true }" class="label-text"
                        [disabled]="radOptions !== 'percentage'">
                    </p-checkbox>
                </div>
                <div class="p-col-6">
                    <span class="p-ml-5 p-inputgroup">
                        <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                            [disabled]="!templateObj.isUpdateTime" [(ngModel)]="templateObj.timePercentage"
                            mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                            [ngModelOptions]="{ standalone: true }" [minFractionDigits]="2"></p-inputNumber>
                        <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                    </span>
                </div>
            </div>
            <!-- Add rollover options -->
            <div class="p-col-12 p-mb-4">
                <p-radioButton name="BudgetTemplate" label="Create from Existing Budget" [(ngModel)]="radOptions"
                    value="existing">
                </p-radioButton>
            </div>
            <div class="p-col-12 p-mb-3">
                <p-radioButton name="BudgetTemplate" label="Create based on Actual Amounts" [(ngModel)]="radOptions"
                    value="actual">
                </p-radioButton>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex p-jc-end">
                <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'create' | translate }}"
                    (click)="onSaveAddTemplateDialog()" [disabled]="shouldDisable(true)"></button>
                <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'Cancel' | translate }}"
                    (click)="onCloseAddTemplateDialog()"></button>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="showManageNotifications" *ngIf="showManageNotifications" [modal]="true" [closeOnEscape]="false"
        [style]="{ width: '62vw' }" [contentStyle]="{overflow: 'visible'}" [baseZIndex]="1" [maximizable]="false"
        showEffect="fade" [draggable]="true" [resizable]="false" (onHide)="onCloseManageBudget()">
        <p-header>
            <span>Manage {{ isFromProject ? 'Project' : '' }} - </span><span class="ic-red">{{ !isFromProject ?
                notifObj.engagementName : projectBudgetObj['TemplateName']}}</span>
        </p-header>
        <div class="common-dialog" *ngIf="showManageNotifications">
            <div class="p-col-12" *ngIf="!isFromProject">
                <h5 class="label-text p-mb-0"><strong>Revenue Email Notifications - Budget: <span class="ic-red">{{
                            selectedBudget['RevenueAmount'] | currency:'USD':'symbol':'1.2-2' }}</span></strong></h5>
                <div class="p-pb-0 p-pt-0 p-d-flex p-jc-between p-col-12 p-px-0">
                    <div class="p-col-7 p-py-0 p-pl-0">
                        <div class="p-col-12 p-py-0 p-field p-mb-0 p-pl-0">
                            <div class="p-col-12 label-text p-d-flex p-jc-start">
                                <p-chips  styleClass="width-100p" placeholder="Add Percentage Amount" (click)="onFocusChips($event, 'rev')"
                                    (onAdd)="onChipsAdd($event, 'rev')" [max]="8" separator="," (onRemove)="onChipRemove('rev')"
                                    [(ngModel)]="notifObj['RevenueAlertAmounts']" [addOnTab]="true"
                                    [addOnBlur]="true">
                                    <ng-template let-item pTemplate="item">
                                        {{ (item | number : '1.0-0') }}%
                                    </ng-template></p-chips>
                                <!-- <p-checkbox [(ngModel)]="notifObj.isRevenueAlert" [binary]="true"
                                    (ngModelChange)="onChckboxAlertClick($event, 'revenue')"
                                    [ngModelOptions]="{ standalone: true }" class="label-text p-mr-2">
                                </p-checkbox>
                                <div>
                                    Alert when <input class="width-40p p-mx-1 p-text-right" type="text" pInputText
                                        [disabled]="!notifObj.isRevenueAlert" [(ngModel)]="notifObj.RevenueAlertAmount"
                                        (focus)="$event.target.select();"
                                        (blur)="notifObj.RevenueAlertAmount = mangoUtils.doMoneyFormat(notifObj.RevenueAlertAmount)"
                                        pKeyFilter="num" [ngModelOptions]="{standalone: true}"> is exceeded.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="p-col-5 p-px-0">
                        <div class="p-col-12">
                            <label class="label-text">{{'settings.budgets-send-firm' | translate}}</label>
                        </div>
                        <div class="p-col-12">
                            <p-multiSelect [(ngModel)]="notifObj.RevenueEmailList" [options]="AllUsers"
                                [disabled]="!notifObj['RevenueAlertAmounts']" [style]="{minWidth: '100%'}"
                                placeholder="Select User(s).." id="RevenueBudgetUserAssignedID"
                                name="RevenueBudgetUserAssignedID" [ngModelOptions]="{standalone: true}">
                            </p-multiSelect>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-pb-0 p-col-12">
                <h5 class="label-text p-mb-0"><strong>Time Email Notifications - Budget: <span class="ic-red">{{
                            selectedBudget['TimeAmount'] | number : '1.2-2' }} hrs</span></strong></h5>
                <div class="p-pb-0 p-pt-0 p-d-flex p-jc-between p-col-12 p-px-0">
                    <div class="p-col-7 p-py-0 p-pl-0">
                        <div class="p-col-12 p-py-0 p-field p-mb-0 p-pl-0">
                            <div class="p-col-12 label-text p-d-flex p-jc-start">
                                <p-chips styleClass="width-100p" placeholder="Add Percentage Amount" (click)="onFocusChips($event, 'time')"
                                    (onAdd)="onChipsAdd($event, 'time')" [max]="8" separator="," (onRemove)="onChipRemove('time')"
                                    [(ngModel)]="notifObj['TimeAlertAmounts']" [addOnTab]="true"
                                    [addOnBlur]="true">
                                    <ng-template let-item pTemplate="item">
                                        {{ (item | number : '1.0-0') }}%
                                    </ng-template></p-chips>
                                <!-- <p-checkbox [(ngModel)]="notifObj.isTimeAlert" [binary]="true"
                                    (ngModelChange)="onChckboxAlertClick($event, 'time')"
                                    [ngModelOptions]="{ standalone: true }" class="label-text p-mr-2">
                                </p-checkbox>
                                <div>
                                    Alert when <input class="width-40p p-mx-1 p-text-right" type="text" pInputText
                                        [disabled]="!notifObj.isTimeAlert" [(ngModel)]="notifObj.TimeAlertAmount"
                                        (focus)="$event.target.select();"
                                        (blur)="notifObj.TimeAlertAmount = mangoUtils.doMoneyFormat(notifObj.TimeAlertAmount, 'time')"
                                        pKeyFilter="num" [ngModelOptions]="{standalone: true}"> hrs is exceeded.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="p-col-5 p-px-0">
                        <div class="p-col-12">
                            <label class="label-text">{{'settings.budgets-send-firm-time' | translate}}</label>
                        </div>
                        <div class="p-col-12">
                            <p-multiSelect [(ngModel)]="notifObj.TimeEmailList" [options]="AllUsers"
                                [disabled]="!notifObj['TimeAlertAmounts']" [style]="{minWidth: '100%'}"
                                placeholder="Select User(s).." id="TimeBudgetUserAssignedID"
                                name="TimeBudgetUserAssignedID" [ngModelOptions]="{standalone: true}"></p-multiSelect>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-pb-0 p-col-12">
                <h5 class="label-text p-mb-0"><strong>Rollover Options</strong></h5>
                <div class="p-d-flex p-jc-start p-ml-3">
                    <div class="p-d-flex">
                        <p-checkbox [(ngModel)]="rolloverObj.isDefaultBudgetCompanyRollover" [binary]="true"
                            label="Use Firm Defaults" [ngModelOptions]="{ standalone: true }" class="p-mt-3 label-text">
                        </p-checkbox>
                        <i pTooltip="{{ 'budgets.firm_defaults' | translate }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                            class="fa fa-question-circle ic-blue p-ml-2 ic-size-14 p-pt-1 p-mt-3"></i>
                    </div>
                    <p-checkbox [(ngModel)]="rolloverObj.isEnableRollover" [binary]="true"
                        [disabled]="rolloverObj.isDefaultBudgetCompanyRollover"
                        label="Enable Automatic Rollover to Next Year" [ngModelOptions]="{ standalone: true }"
                        class="p-mt-3 p-ml-6 label-text">
                    </p-checkbox>
                </div>
                <div class="p-pb-0 p-pt-0 p-d-flex p-jc-between p-col-12 p-px-0">
                    <div class="p-col-12 p-pl-0">
                        <div class="p-col-12 p-pr-0">
                            <div class="p-col-12 p-d-flex p-jc-between">
                                <div class="p-col-12 p-pl-0">
                                    <p-radioButton name="BudgetTemplate"
                                        [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover"
                                        label="Increase by fixed percentage" [(ngModel)]="rolloverOptions"
                                        value="percentage">
                                    </p-radioButton>
                                </div>
                            </div>
                            <div class="p-col-12 p-py-0 p-px-0 p-d-flex p-jc-start">
                                <div class="p-pl-4 p-pr-0 p-d-flex p-jc-start" *ngIf="!isFromProject">
                                    <div class="p-col-6">
                                        <p-checkbox [(ngModel)]="rolloverObj.isUpdateRevenue" [binary]="true"
                                            label="Revenue Amount" [ngModelOptions]="{ standalone: true }"
                                            class="label-text"
                                            [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollover">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-col-6 p-px-0">
                                        <span class="p-inputgroup">
                                            <p-inputNumber maxlength="12" (focus)="$event.target.select()"
                                                autocomplete="off"
                                                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateRevenue || !rolloverObj.isEnableRollover"
                                                [(ngModel)]="rolloverObj.percentage" mode="decimal" locale="en-US"
                                                inputStyleClass="p-text-right" [ngModelOptions]="{ standalone: true }"
                                                [minFractionDigits]="2"></p-inputNumber>
                                            <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                                        </span>
                                    </div>
                                </div>
                                <div class="p-pr-0 p-d-flex p-pl-4 p-jc-start"
                                    *ngIf="(!isFromProject && !ddmSettings?.isBudgetingProjectLevel) || (isFromProject)">
                                    <div class="p-col-6">
                                        <p-checkbox [(ngModel)]="rolloverObj.isUpdateTime" [binary]="true"
                                            label="Time Amount" [ngModelOptions]="{ standalone: true }" class="label-text"
                                            [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollover">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-col-6 p-px-0">
                                        <span class="p-inputgroup">
                                            <p-inputNumber maxlength="12" (focus)="$event.target.select()"
                                                autocomplete="off"
                                                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateTime || !rolloverObj.isEnableRollover"
                                                [(ngModel)]="rolloverObj.timePercentage" mode="decimal" locale="en-US"
                                                inputStyleClass="p-text-right" [ngModelOptions]="{ standalone: true }"
                                                [minFractionDigits]="2"></p-inputNumber>
                                            <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12">
                                <p-radioButton name="BudgetTemplate"
                                    [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover"
                                    label="Create from Existing Budget" [(ngModel)]="rolloverOptions" value="existing">
                                </p-radioButton>
                            </div>
                            <div class="p-col-12">
                                <p-radioButton name="BudgetTemplate"
                                    [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover"
                                    label="Create based on Actual Amounts" [(ngModel)]="rolloverOptions" value="actual">
                                </p-radioButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <div class="p-col-12 p-pr-3 p-d-flex p-jc-end">
                    <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'Save' | translate }}"
                        (click)="onSaveNotifSettings()" [disabled]="shouldDisableNotif()" class="p-mr-3"></button>
                    <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'Cancel' | translate }}"
                        (click)="onCloseManageBudget()"></button>
                </div>
            </ng-template>
        </div>
    </p-dialog>
</div>