import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ManualInvoiceComponent } from './manual-invoice/manual-invoice.component';
import Swal from 'sweetalert2';
import { EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { BatchBillingComponent } from './batch-billing/batch-billing.component';
import { RecurringInvoicesComponent } from './recurring-invoices/recurring-invoices.component';
import { SharedComponentsService } from '@app/shared/components';
import { RecurringInvoiceNewComponent } from './recurring-invoice-new/recurring-invoice-new.component';

@Injectable({
  providedIn: 'root'
})
export class ClearInvoicingBusyStaffName  {
  constructor(private translate: TranslateService, private encrDecSrvc: EncrDecrService, private sharedSrvc: SharedComponentsService, private mangoAPISrvc: MangoApiService) {
  }

  clearInvoicingBusyStaffName(){
    const obj = {};
    const data =  this.encrDecSrvc.getObject(AppConstants.companyData);
    if(!data) return;
    obj['companyName'] = data.CompanyName;
    obj['street1'] =
      data.Street1 != ""
        ? data.Street1
        : null;
    obj['street2'] =
      data.Street2 != ""
        ? data.Street2
        : null;
    obj['city'] = data.City;
    obj['state'] = data.State? data.State.toUpperCase() : null;
    obj['zip'] = data.Zip;
    obj['country'] = data.Country? data.Country.toUpperCase() : null;
    obj['phone'] = data.Telephone;
    obj['faxNumber'] = data.Fax;
    obj['companyLogoWeb'] = data.CompanyLogoWeb;
    obj['useCompanyLogo'] = data.UseLogo;
    obj['email'] = data.EmailCompany;
    obj['companyContact'] = data.CompanyContact;
    obj['WebsiteCompany'] = data.WebsiteCompany;
    obj['InvoicingBusyStaffName'] = null;
    this.mangoAPISrvc.updateCompanyView(obj).subscribe((res) => {});
  }

  canDeactivate(
    component: ManualInvoiceComponent | BatchBillingComponent | RecurringInvoiceNewComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    
    // clearTimeout(this.sharedSrvc.userActivity);
    // clearInterval(this.sharedSrvc.resetInvoiceBusyStaffDateEvery)
    // this.sharedSrvc.invoiceInactivitySub.unsubscribe();
    
    // let staffList = this.encrDecSrvc.getObject(AppConstants.staffList);
    // let staffID = this.encrDecSrvc.getObject(AppConstants.resourceID);
    // if(!staffList || !staffID){
    //   return false;
    // }
    // let user = staffList.find(staff=> staff.StaffID == staffID);
    // let data =  this.encrDecSrvc.getObject(AppConstants.companyData);
    // if(!data.isInvoiceBlocking){
    //   // console.log("[EXIT] Invoice blocking feature is not enabled.");
    // }
    // if(!data || !user || user.StaffName != data.InvoicingBusyStaffName){
    //   // console.log("[EXIT] No clearing.")
    // }else{
    //   // console.log("[EXIT] Clearing InvoicingBusyStaffName")
    //   this.clearInvoicingBusyStaffName()
    // }
   
    return true;    

  }
}
