<p-dialog [(visible)]="isVisible" [modal]="true" [style]="{ width: '40vw' }" [contentStyle]="{overflow: 'visible'}"
  [baseZIndex]="1" [maximizable]="false" showEffect="fade" [draggable]="true" [resizable]="false"
  (onHide)="onHideDialog()">
  <p-header>
    Rollover Options
  </p-header>
  <div class="p-pl-3 p-fluid p-formgrid p-grid p-m-1 common-dialog">
    <div class="p-d-flex p-jc-start p-mb-4 p-ml-2">
      <div class="p-d-flex">
        <p-checkbox [(ngModel)]="rolloverObj.isDefaultBudgetCompanyRollover" [binary]="true" label="Use Firm Defaults"
          [ngModelOptions]="{ standalone: true }" class="p-mt-3 label-text">
        </p-checkbox>
        <i pTooltip="{{ 'budgets.firm_defaults' | translate }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
            class="fa fa-question-circle ic-blue p-ml-2 ic-size-14 p-pt-1 p-mt-3"></i>
      </div>
      <p-checkbox [(ngModel)]="rolloverObj.isEnableRollOver" [binary]="true"
        [disabled]="rolloverObj.isDefaultBudgetCompanyRollover" label="Enable Automatic Rollover to Next Year"
        [ngModelOptions]="{ standalone: true }" class="p-mt-3 p-ml-6 label-text">
      </p-checkbox>
    </div>
    <div class="p-pb-0 p-pt-0 p-d-flex p-jc-between p-col-12 p-px-0">
      <div class="p-col-12 p-px-0">
        <div class="p-col-12 p-mb-3 p-d-flex p-jc-between">
          <div class="p-col-12 p-pl-0">
            <p-radioButton name="BudgetTemplate"
              [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollOver"
              label="Increase by fixed percentage" [(ngModel)]="rolloverOptions" value="percentage">
            </p-radioButton>
          </div>
        </div>
        <div class="p-col-12 p-mb-3 p-pl-4 p-pr-0 p-d-flex p-jc-between" *ngIf="!templateObj.isFromProject">
          <div class="p-col-6">
            <p-checkbox [(ngModel)]="rolloverObj.isUpdateRevenue" [binary]="true" label="Revenue Amount"
              [ngModelOptions]="{ standalone: true }" class="label-text"
              [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollOver">
            </p-checkbox>
          </div>
          <div class="p-col-6 p-px-0">
            <span class="p-inputgroup">
              <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateRevenue || !rolloverObj.isEnableRollOver"
                [(ngModel)]="rolloverObj.percentage" mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                [ngModelOptions]="{ standalone: true }" [minFractionDigits]="2"></p-inputNumber>
              <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
            </span>
          </div>
        </div>
        <div class="p-col-12 p-pr-0 p-mb-3 p-d-flex p-pl-4 p-jc-between">
          <div class="p-col-6">
            <p-checkbox [(ngModel)]="rolloverObj.isUpdateTime" [binary]="true" label="Time Amount"
              [ngModelOptions]="{ standalone: true }" class="label-text"
              [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollOver">
            </p-checkbox>
          </div>
          <div class="p-col-6 p-px-0">
            <span class="p-inputgroup">
              <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateTime || !rolloverObj.isEnableRollOver"
                [(ngModel)]="rolloverObj.timePercentage" mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                [ngModelOptions]="{ standalone: true }" [minFractionDigits]="2"></p-inputNumber>
              <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
            </span>
          </div>
        </div>
        <div class="p-col-12 p-mb-3">
          <p-radioButton name="BudgetTemplate"
            [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollOver"
            label="Create from Existing Budget" [(ngModel)]="rolloverOptions" value="existing">
          </p-radioButton>
        </div>
        <div class="p-col-12 p-mb-3">
          <p-radioButton name="BudgetTemplate"
            [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollOver"
            label="Create based on Actual Amounts" [(ngModel)]="rolloverOptions" value="actual">
          </p-radioButton>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-d-flex p-jc-end">
      <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_button' | translate }}"
        (click)="onSaveTemplate()" [disabled]="shouldDisable()"></button>
      <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'Cancel' | translate }}"
        (click)="onHideDialog()"></button>
    </div>
  </ng-template>
</p-dialog>