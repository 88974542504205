import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AppSharedModule } from '@app/shared/modules';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { BillingInvoicingRoutingModule } from './billing-invoicing-routing.module';
import { billingInvoicingComponent } from './billing-invoicing.component';
import { BatchBillingComponent } from './batch-billing/batch-billing.component';
import { ApplyTimeToInvoiceComponent } from './apply-time-to-invoice/apply-time-to-invoice.component';
import { ClientStatementsComponent } from './client-statements/client-statements.component';
import { InvoiceReviewComponent } from './invoice-review/invoice-review.component';
import { RecurringInvoicesComponent } from './recurring-invoices/recurring-invoices.component';
import { ManualInvoiceComponent } from './manual-invoice/manual-invoice.component';
import { StatmentPrintingComponent } from './statment-printing/statment-printing.component';
import { RetainerInvoiceComponent } from './retainer-invoice/retainer-invoice.component';
import { RecurringInvoiceNewComponent } from './recurring-invoice-new/recurring-invoice-new.component';
import { QuerybuilderComponent } from './querybuilder/querybuilder.component';
import { RecurrenceEditorAllModule, ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';

@NgModule({
  declarations: [billingInvoicingComponent,
    BatchBillingComponent,
    ApplyTimeToInvoiceComponent,
    ClientStatementsComponent,
    InvoiceReviewComponent,
    RecurringInvoicesComponent,
    ManualInvoiceComponent,
    StatmentPrintingComponent,
    RetainerInvoiceComponent,
    RecurringInvoiceNewComponent,
    QuerybuilderComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    NgxExtendedPdfViewerModule,
    AppTranslationModule,
    BillingInvoicingRoutingModule,
    ScheduleAllModule,
    PdfViewerModule,
    RecurrenceEditorAllModule
  ],
  providers: [CurrencyPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class BillingInvoicingModule { }
