import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils, AuthGuard } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import * as workerTimers from "worker-timers";
import { timer } from 'rxjs';





@Component({
  selector: 'app-engagements-list',
  templateUrl: './engagements-list.component.html'
})
export class EngagementsListComponent implements OnInit {
  projectsList: any = [];
  projectsRawList: any = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('searchValue2') searchValue2;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";
  public showInactiveClients: boolean = false;

  showAssignBudgetDialog: boolean = false;

  projectInactive = false;
  hasNoRecords = false;
  tmpProjectsList = [];
  selectedProjects = []
  ddmSettings: any;
  subscriptionLevel
  allClients: any = [];
  allStaff: any = [];
  companyId: any = null;
  public lastRecordId: any = null;
  public searchLastRecordId: any = null;
  public isDataLoading: boolean = true;
  public isShowLoadMore: boolean = false;
  public timerSubscription: any;
  public tempLoadedRecords: any = [];
  public searchInputText: any = '';
  dataCols: any;
  selectedColumns: any;

  constructor(private router: Router,
              private mangoAPISrvc: MangoApiService,
              private encrDecSrvc: EncrDecrService, 
              private auth: AuthGuard,
              private breadcrumbService: BreadcrumbService, 
              public sharedSrvc: SharedComponentsService, 
              public mangoUtils: mangoUtils,
              private _translate: TranslateService) {
    const interval = setInterval(() => {
      if(!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.mangoUtils.shouldFetchCode.next(true);
    this.allStaff = this.encrDecSrvc.getObject(AppConstants.staffList);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString);
    this.getProjects();
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this._translate.instant('engagement.list') }
    ]);
  }

  
 /*  fetchClientList() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getAllClientsByCompanyId(this.companyId).subscribe((data: any) => {
      this.allClients = data;
      this.getProjects();
    }, (err) => {
      this.mangoAPISrvc.showLoader(false);
    });
  } */

  ngOnDestroy() {
    workerTimers.clearInterval(this.timerSubscription)
 
  }


  ngOnInit(): void {
    this._translate.reloadLang(this._translate.currentLang).subscribe(data => {
      this.initializeColumn();
    })
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.getDDMSettings();
    this.sharedSrvc.engagementDialogData
      .subscribe((data) => {
        this.getProjects();
      })
  }

  getDDMSettings() {
    this.mangoAPISrvc
      .fetchDDMSettings(this.encrDecSrvc.getObject(AppConstants.companyID))
      .subscribe(
        (result: any) => {
          this.ddmSettings = result[0];
        },
        (err) => {
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.fetchErrorMsg
          );
        }
      );
  }

  initializeColumn() {
    const dataCols = [
      { field: "EngagementName", header: this._translate.instant("client.engagement") },
      { field: "Description", header: this._translate.instant("engagement.type")},
      { field: "ClientName", header: this._translate.instant("name") },
    ];

    this.dataCols = [
      ...dataCols,
      { field: "StaffName", header: this._translate.instant("assigned_to") },
      { field: "BillingPartnerName", header: this._translate.instant("Billing_Partner") },
      { field: "BillingGroupDescription", header: this._translate.instant("client.billing_group_description") },
      { field: "StartDate", header: this._translate.instant("Created") },
      { field: "Status", header: this._translate.instant("status") },
      { field: "ProjectMasterID", header: "ID#" },
    
    ];

    this.selectedColumns = dataCols;
  }

  checkRowData(header, data) {
    // if(header === 'ClientID') {
    //   return this.getClientName(data)
    if (header.toLowerCase().includes('date')) {
      return this.getDisplayDate(data)
    } else {
      return data;
    }
  }

  getDisplayDate(text) {
    const date = moment(text);
    if (!text && !date.isValid())
      return text;

    // return moment(text).format("MM/DD/YYYY")
    return moment(text?.substr(0, 10)).format("MM/DD/YYYY")
  }

  onStatusChange() {
    this.getProjects();
	/* 	this.projectInactive = !this.projectInactive;
		if (this.tmpProjectsList.length < 1)
			this.tmpProjectsList = this.projectsList;
		if (this.projectInactive)
			this.projectsList = this.tmpProjectsList.filter((note) => note.Inactive == true);
		else
			this.projectsList = this.tmpProjectsList.filter((note) => note.Inactive == false); */
	}

  
  private filterTimeout: any = null;
  private filterTimer: any = timer(300);

  onInputSearchChange(event){
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    if (event.target.value != '') {
      parent.projectsList = [];
      parent.projectsRawList = [];
      if (parent.filterTimeout) {
        workerTimers.clearInterval(this.timerSubscription);
      }
      parent.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString, event.target.value);
      parent.searchProjects(event.target.value);
    }
    else {
      parent.mangoAPISrvc.showLoader(false);
    }

  }

  searchProjects(searchText){
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    const status = parent.showInactiveClients ? "Close" : "Open"
    const limit = 5000;
    parent.mangoAPISrvc.getProjects2(status, searchText, parent.searchLastRecordId, limit).subscribe((data: any) => {
      parent.mangoAPISrvc.showLoader(true);
      parent.hasNoRecords = (data.length == 0) ? true : false;
      parent.searchLastRecordId = (data.length > 0) ? data[data.length - 1].ProjectMasterID : null;
      parent.projectsList = [];
      parent.projectsRawList = [];

      parent.projectsList = data;
      parent.projectsRawList = data;


      parent.projectInactive = !parent.projectInactive;
      parent.searchProjectsBackground(searchText);
      //parent.loadFilterGrid();
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  searchProjectsBackground(searchText){
    this.timerSubscription = workerTimers.setInterval(() => {
      const parent = this;
      const status = parent.showInactiveClients ? "Close" : "Open"
      const limit = 5000;
      const searchString = searchText;
      parent.mangoAPISrvc.getProjects2(status, searchString, parent.searchLastRecordId, limit).subscribe((data: any) => {
        parent.hasNoRecords = (data.length == 0) ? true : false;
        parent.searchLastRecordId = (data.length > 0) ? data[data.length - 1].ProjectMasterID : null;
        
        if (parent.projectsList.length > 0) {
          for(let i = 0; i < data.length; i++){
            parent.projectsList.push(data[i]);
            parent.projectsRawList.push(data[i]);
          }
        }
        else {
          parent.projectsList = data;
          parent.projectsRawList = data;
        }

        if(data.length < limit){
          parent.isDataLoading = false;
          console.log('All Engagements are loaded..');
          if(parent.timerSubscription){
            workerTimers.clearInterval(parent.timerSubscription)
          }
          return;
        }
    

        parent.projectInactive = !parent.projectInactive;
        parent.mangoAPISrvc.showLoader(false);
      }, error => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });

    }, 7000);
  }


  getBackgroundProjects(){
    this.timerSubscription = workerTimers.setInterval(() => {
      const parent = this;
      const status = parent.showInactiveClients ? "Close" : "Open"
      const limit = 5000;
      let srcTxt = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString);
      parent.searchInputText = srcTxt;
      if(srcTxt == '')  srcTxt = null;
      parent.mangoAPISrvc.getProjects2(status, srcTxt, parent.lastRecordId, limit).subscribe((data:any) => {
        parent.hasNoRecords = (data.length == 0) ? true : false;
        parent.lastRecordId =  (data.length > 0) ? data[data.length - 1].ProjectMasterID : null;
  
        if(parent.projectsList.length > 0){
          parent.projectsList = [...parent.projectsList, ...data];
          parent.projectsRawList = [...parent.projectsList, ...data];
        }
        else{
          parent.projectsList = data
          parent.projectsRawList = data;
        }

        parent.tempLoadedRecords = parent.projectsList;

        if(data.length < limit){
          parent.isDataLoading = false;
          console.log('All Engagements are loaded..');
          if(parent.timerSubscription){
            workerTimers.clearInterval(parent.timerSubscription)
          }
          return;
        }
    
        parent.projectInactive = !parent.projectInactive;
        parent.mangoAPISrvc.showLoader(false);
      }, error => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })
      
    }, 5000);

  }

  getProjects() {
    const parent = this;
    parent.tmpProjectsList = [];
    parent.mangoAPISrvc.showLoader(true);
   
    parent.mangoAPISrvc.getEngagementCount(parent.companyId).subscribe((engCount: any)=>{
      parent.mangoAPISrvc.showLoader(true);
      const status = parent.showInactiveClients ? "Close" : "Open"
      const limit =  engCount && engCount[0].count > 50000 ? 5000 : null; //
      

      let srcTxt = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString);
      parent.searchInputText = srcTxt;
      if(srcTxt == '')  srcTxt = null;
      if(limit == null) parent.isDataLoading = false;
      parent.mangoAPISrvc.getProjects2(status, srcTxt, parent.lastRecordId, limit).subscribe((data:any) => {
        parent.mangoAPISrvc.showLoader(true);
        parent.hasNoRecords = (data.length == 0) ? true : false;
        parent.lastRecordId =  (data.length > 0) ? data[data.length - 1].ProjectMasterID : null;
      
        parent.projectsList = data;
        parent.projectsRawList = data;
  
        parent.projectInactive = !parent.projectInactive;
        parent.tempLoadedRecords = parent.projectsList;
      
        //do background search if engagement is more than 50k
        if(engCount && engCount[0].count > 50000){
          parent.isDataLoading = true;
          parent.getBackgroundProjects();
        }
        parent.loadFilterGrid();
    
        parent.mangoAPISrvc.showLoader(false);
      }, error => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })
    }, err=>{
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    })
   
  }

  deleteEngagement(id, rowData) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true)
    parent.mangoAPISrvc.getDetailsCountByProjectId(id).subscribe(function (data:any) {
      parent.mangoAPISrvc.showLoader(false)
      if (data.timeslips == 0) {
        Swal.fire({
          title: parent._translate.instant('confirmation'),
          text: parent._translate.instant('delete_alert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: parent._translate.instant('yes_delete'),
          cancelButtonText: parent._translate.instant('no_delete')
        }).then((result) => {
          if (result.value) {
            parent.mangoAPISrvc.showLoader(true);
            parent.mangoAPISrvc.deleteProject(id).subscribe(function (data) {

              const userLogs = {}
              userLogs['Action'] = "Delete Engagement";
              userLogs['Description'] = rowData['EngagementName'] + " ##" + rowData['ProjectMasterID'] + ` --${rowData['ClientName']}`;
              userLogs['Table'] = "Engagement";
              const isManaging = parent.encrDecSrvc.getObject(AppConstants.isManagingAccount);
              if(!isManaging){
                parent.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
                  console.log(err);
                });
              }
              

              parent.projectsList = parent.projectsList?.filter((project) => project.ProjectMasterID !== id)
              parent.projectsRawList = parent.projectsRawList?.filter((project) => project.ProjectMasterID !== id)
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('success', parent._translate.instant('Success'), AppConstants.deleteMessage);
            }, error => {
              parent.mangoAPISrvc.notify('error', parent._translate.instant('error'), AppConstants.deleteErrorMsg);
              parent.mangoAPISrvc.showLoader(false);
            });
          }
        })
      } else {
        parent.mangoAPISrvc.notify('error', parent._translate.instant('error'), 'Deleting Engagement is not allowed.  Time and Expense records exist.');
      }

    }, error => {
      parent.mangoAPISrvc.notify('error', parent._translate.instant('error'), AppConstants.deleteErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });

  }
  // Editing the Engagement
  editEngagement(data) {
    if(!this.isAllowUpdateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: 'Your permission level does not allow access to update an engagement.'
      });
      return
    }
    this.encrDecSrvc.addObject(AppConstants.projectID, data['ProjectMasterID']);
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, data);
    this.encrDecSrvc.addObject(AppConstants.clientID, data['ClientID']);
    this.encrDecSrvc.addObject(AppConstants.ClientName, data['ClientName']);
    this.encrDecSrvc.addObject(AppConstants.selectedEngagementName, data['EngagementName']);
    this.mangoAPISrvc.fireEngagementView(true);
  }
  // adding new engagent
  addEngagementPopUp() {
    if(!this.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: this._translate.instant('client.engagement.permission')
      });
      return
    }
    const parent = this;
    const obj = {};
    obj['isClientFlow'] = false;
    obj['isEditFlow'] = false;
    parent.sharedSrvc.openEngagementDialog(obj);
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString, "");
  }

  clearSearchFilter2() {
    if(this.timerSubscription){
      workerTimers.clearInterval(this.timerSubscription);
    }

    this.searchValue2.nativeElement.value  = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString, "");
    this.projectsList = [];
    this.projectsRawList =  [];
    
    this.getProjects();
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + '_' + AppConstants.SearchString, obj.filters.global?.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      if(this.searchValue) this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        if(this.searchValue){
          this.searchValue.nativeElement.dispatchEvent(event);
          this.searchValue.nativeElement.select();
        }
        
      } else {
        if(this.searchValue){
          this.searchValue.nativeElement.focus();
        }
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  onShowBudgetAssign() {
    this.showAssignBudgetDialog = true
  }

  onAssignBudgetsCompleted(event) {
    if(event)
      this.selectedProjects = []

    this.showAssignBudgetDialog = false
  }

  get isBudgetingAllowed() {
    return this.auth.isAllowAccess(28)
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38)
  }

  get isAllowUpdateEngagement() {
    return this.auth.isAllowAccess(45)
  }
}
