<div class="exception-body access">
    <div class="exception-content">
        <div class="moon">
            <img src="assets/layout/images/pages/asset-moon.svg" alt="mirage-layout" />
        </div>
        <div class="exception-panel">
            <div class="exception-panel-content">
                <span class="tag">401</span>
                <h1>Access Denied</h1>
                <div class="seperator"></div>
                <p>You do not have the necesary permisions. Please contact admins.</p>
                <button pButton pRipple type="button" [routerLink]="['/login']" label="Sign In"></button>
            </div>
        </div>
        <div class="desert"></div>
    </div>
</div>