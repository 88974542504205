import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from "jquery";
import { environment } from "@environments/environment";
import { forkJoin, timer } from 'rxjs'
import { ToolbarService, LinkService, ImageService, HtmlEditorService, ResizeService } from '@syncfusion/ej2-angular-richtexteditor';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { SharedComponentsService } from '@app/shared/components';
import { ParsedEvent } from '@angular/compiler';
declare let numeral: any;

@Component({
  selector: 'app-recurring-invoices',
  templateUrl: './recurring-invoices.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, ResizeService],
})
export class RecurringInvoicesComponent implements OnInit {
  searchTextStr: any = "";
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dt: Table;
  filteredItemsSize = -1;
  isEnrollForm: boolean = false;

  public selectedReviewItems: any = [];
  public myPaymentForm: UntypedFormGroup;
  public headerListItems: any = [];
  public invoiceList: any = [];
  public staffList: any = [];
  public clonedData: any = {};
  public engagementListItems: any = [];
  public activityList: any = [];
  public clientsList: any = [];
  public totalAmount: any = 0;
  public invoiceDate: any = new Date();
  public lastInvoice: any;
  public replaceText: any;
  public topMemoValue: any;
  public bottomMemoValue: any;
  public clientProfile: any;
  public companyLocations: SelectItem[] = [];

  public dialogDisplay: boolean = false;
  public isDialogFormValid = false;
  public isVisaCard: string = 'true';
  public cardDialogDisplay: boolean = false;
  public IsDataReadOnly: boolean = true;
  public IsEditorChanges: boolean = false;
  public IsTopBottomModified: boolean = false;
  public isUpdateAllenabled: boolean = false;
  public isNewenabled: boolean = true;
  public showAssignTask: boolean = false;
  public btnText: any = 'Edit';
  public staffListItems: SelectItem[];
  public groupsList: SelectItem[];
  public recurringInvoiceGroupList: SelectItem[];
  public freqList: SelectItem[];
  public Staff: any = {};
  public TableCols: any = [];
  public filteredStaffSingle: any[];
  public filteredGroupsSingle: any[];
  public filteredServiceSingle: any[];
  public innerWidth: any;
  public tooltipText: any = `The variable {replace} can used in the Invoice Description column. Example: Services for the month of {replace}. The {replace} variable will be replaced with text you enter here.  Example:  July 2017.  The result will be 'Services for the month of July 2017`;
  public replaceSText: any = `Replace text {replace} with`;

  public clientList: any = [];
  public filteredClients: any = [];
  intervalid: any;
  cols: any[];
  _selectedColumns: any = [];
  globalFilterColumns: any = [
    'ClientName', 'StaffName', 'RecurringGroupDescription',
    'EngagementName', 'InvoiceShortDescription', 'BillAmount',
    'Frequency', 'NextInvoiceDate', 'PeriodTo',
  ];
  public salesTax: any = { 'Labor': 0, 'Expense': 0, 'taxableAmtService': 0, 'taxableAmtExpense': 0, 'serviceTax': 0, 'expenseTax': 0, 'billingHeaderId': null };
  public invoiceTax = 0;
  companyId;

  constructor(
    private _fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private mangoUtils: mangoUtils,
    private http: HttpClient,
    private router: Router,
    private sharedSrvc: SharedComponentsService,
    private translate: TranslateService) {
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Billing-&-Invoicing') },
        { label: this.translate.instant('recurring-invoice'), icon: 'ic-red' }
      ]);
      this.freqList = [
        { label: this.translate.instant('user.weekly'), value: 'Weekly' },
        { label: this.translate.instant('bi_weekly'), value: 'Bi-Weekly' },
        { label: this.translate.instant('user.monthly'), value: 'Monthly' },
        { label: this.translate.instant('semi_monthly'), value: 'Semi-Monthly' },
        { label: this.translate.instant('quarterly'), value: 'Quarterly' },
        { label: this.translate.instant('semi_annually'), value: 'Semi-Annually' },
        { label: this.translate.instant('user.annually'), value: 'Annually' }
      ];
      this.initializeColumn();
    })
    this.innerWidth = (window.innerWidth - 50) + 'px';
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove', ['$event'])
  refreshUserState(event: MouseEvent) {
    if (!this.sharedSrvc.invoiceInactivitySub.closed) this.sharedSrvc.invoiceInactivitySub.next(null);
  }

  ngOnInit(): void {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.innerWidth = (window.innerWidth - 50) + 'px';
    this.initDataSetList();
    this.initializeDialogForm();
    this.getCompanyData();
    this.getCompanyLocations();
    this.staffListItems = []; //[{label: this._translate.instant('Choose'), value: null}];
    this.groupsList = [];//[{label: this._translate.instant('Choose'), value: null}];
    this.recurringInvoiceGroupList = [{ label: 'Choose', value: null }];

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  initializeColumn() {
    this.cols = [
      { header: this.translate.instant('user-title'), width: '130px', text: 'p-text-left', class: 'required-field' },
      { header: this.translate.instant('recurring_invoice_group'), width: '180px', text: 'p-text-left', class: 'required-field' },
      { header: this.translate.instant('Engagements'), width: '200px', text: 'p-text-left', class: 'required-field' },
      { header: this.translate.instant('billing-invoicing.invoice-description'), width: '350px', text: 'p-text-left', class: 'required-field' },
      { header: this.translate.instant('Accounts.invoice-amount'), width: '120px', text: 'p-text-right', class: '' },
      { header: this.translate.instant('frequency'), width: '150px', text: 'p-text-left', class: '' },
      { header: this.translate.instant('scheduled-date'), width: '120px', text: 'p-text-center', class: '' },
      { header: this.translate.instant('release-time-expenses'), width: '100px', text: 'p-text-center', class: '' },
      { header: this.translate.instant('date_thru'), width: '100px', text: 'p-text-center', class: '' },
    ]

    this._selectedColumns = this.cols;
  }

  @Input() get selectedColumns(): any {
    return this._selectedColumns;
  }

  set selectedColumns(val: any) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  ngAfterViewInit() {
    const parent = this;
    $(".ui-widget-header").click(function (e) {
      parent.stopEdit(e);
    });
  }

  filterGroupsItems(event, data) {
    if (data.EngagementsList && data.EngagementsList.length > 0) {
      this.filteredGroupsSingle = this.filterData(event.query, data.EngagementsList);
    } else {
      this.getProjects(data, event.query, true);
    }
  }

  handleSelectClick(event, itemData) {
    itemData['EngagementName'] = "";
    itemData['ProjectID'] = ""
    if (event && event['ClientID']) {
      itemData['ClientID'] = event['ClientID'];
      itemData['ClientName'] = event['ClientName'];
      itemData.EngagementsList = [];
      this.getProjects(itemData, "", false);
    }
    itemData['IsColumnChanges'] = true;
    this.verifyNewRecordPresents();
  }

  getProjects(itemData, serach?: any, isSearch?: boolean) {
    const parent = this;
    parent.groupsList = [];
    parent.engagementListItems = [];

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getProjectsByClientId(itemData.ClientID).subscribe(function (data: any) {
      parent.mangoAPISrvc.showLoader(false);

      parent.engagementListItems = data.filter((note) => note.Inactive == false);

      if (parent.engagementListItems.length === 0) {
        Swal.fire({
          title: parent.translate.instant("Client_Engagements_Required"),
          html:
            parent.translate.instant("billing.there_are_no_engagements_setup"),
          icon: "warning",
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: parent.translate.instant("Go_to_Client"),
          cancelButtonText: parent.translate.instant("no_cancel"),
        }).then((result) => {
          if (result.value) {
            parent.encrDecSrvc.addObject(
              AppConstants.selectedClientRecord,
              parent.clientList.filter((client) => client.ClientID === itemData["ClientID"])[0]
            );

            parent.encrDecSrvc.addObject(
              AppConstants.clientID,
              itemData["ClientID"]
            );
            parent.encrDecSrvc.addObject(
              AppConstants.ClientName,
              itemData["ClientName"]
            );
            parent.mangoAPISrvc.showLoader(true);
            parent.mangoAPISrvc
              .getAllDataCounts(itemData["ClientID"])
              .subscribe(function (data) {
                parent.encrDecSrvc.addObject(
                  AppConstants.allDataCountsForClient,
                  data
                );
                parent.mangoAPISrvc.fireClientView(true);
                parent.router.navigate([
                  AppConstants.clientRoutePath +
                  "/" +
                  AppConstants.viewRoutePath,
                ]);
                //parent.mangoAPISrvc.showLoader(false);
              });
          } else
            return;
        });
      }

      parent.applySelectedItems(parent.engagementListItems, parent.groupsList, 'EngagementName', 'ProjectMasterID');
      const projectObj = parent.engagementListItems.filter((item) => { return item.ProjectMasterID == itemData.ProjectID; })[0];
      if (projectObj) {
        itemData['EngagementName'] = projectObj['EngagementName'];
      }
      itemData['EngagementsList'] = (parent.groupsList.length > 0) ? parent.groupsList : [];
      if (isSearch) {
        serach = serach ? serach : "";
        parent.filteredGroupsSingle = parent.filterData(serach, itemData['EngagementsList']);
      }
    }, () => {
      parent.mangoAPISrvc.showLoader(false);
    })
  }

  initDataSetList() {
    forkJoin([
      this.mangoAPISrvc.getRecurringHeader(),
      this.mangoAPISrvc.getInvoiceOption(),
      this.mangoAPISrvc.getInvoiceGroups(),
    ]).subscribe(
      (data: any) => {
        this.invoiceList = data[1];
        this.activityList = data[2];
        this.staffList = this.encrDecSrvc.getObject(AppConstants.staffList);
        this.staffList = this.staffList.filter((staff) => !staff.Inactive)

        this.lastInvoice = this.invoiceList.NextInvoiceNumber;

        // adding new properties Label and value
        this.applySelectedItems(this.staffList, this.staffListItems, 'StaffName', 'StaffID');
        this.applySelectedItems(this.activityList, this.recurringInvoiceGroupList, 'RecurringGroupDescription', 'RecurringInvoiceGroupID');

        // adding new property based on its id .  Ex : client id to Client Name
        this.mangoUtils.setPropertyById(this.activityList, data[0], 'RecurringInvoiceGroupID', 'RecurringGroupDescription', 'RecurringGroupDescription');
        this.mangoUtils.setPropertyById(this.staffList, data[0], 'StaffID', 'StaffName', 'StaffName');
        data[0].map(function (obj) {
          obj['NextInvoiceDate'] = obj['NextInvoiceDate'] ? new Date(obj['NextInvoiceDate']) : null;
          obj['BillAmount'] = numeral(obj['BillAmount']).value();
          obj['PeriodFrom'] = new Date(obj['PeriodFrom']);
          obj['PeriodTo'] = new Date(obj['PeriodTo']);
          obj['isNew'] = false;
          return obj;
        });
        this.totalAmount = numeral(data[0].reduce(function (a, b) { return a + +numeral(b['BillAmount']).value(); }, 0)).format('0,0.00');

        this.headerListItems = data[0];
        this.headerListItems.sort(function(a, b){
          if(a.ClientName.toLowerCase() < b.ClientName.toLowerCase()) { return -1; }
          if(a.ClientName.toLowerCase() > b.ClientName.toLowerCase()) { return 1; }
          return 0;
        })
        if (this.headerListItems.length == 0) {
          this.showAssignTask = true;
        }
      },
      err => console.error(err)
    );
  }

  filterStaffItems(event, data) {
    this.filteredStaffSingle = this.filterData(event.query, this.staffListItems);
  }

  filterServiceCodeItems(event, data) {
    this.filteredServiceSingle = this.filterData(event.query, this.recurringInvoiceGroupList);
  }
  filterData(query, staffListItems: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    const filtered: any[] = [];
    for (let i = 0; i < staffListItems.length; i++) {
      const staffItem = staffListItems[i];
      if (staffItem.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(staffItem);
      }
    }
    return filtered;
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientList.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      this.filteredClients = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false) {
          filtered.push(client);
        }
        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }
  /*
    Process the form
  */
  attachInvoiceMemo() {
    this.bottomMemoValue = this.invoiceList.StandardFooterMessage;
    this.dialogDisplay = true;
    this.IsEditorChanges = false;
  }

  processInvoiceConfirm() {
    if (typeof this.replaceText == 'undefined' || this.replaceText == '') {
      Swal.fire({
        title: this.translate.instant('Warning'),
        html: this.translate.instant('no_replace_text_message'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('yes_continue'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then((result) => {
        if (result.value) {
          this.processInvoice();
        }
      })
    } else {
      this.processInvoice();
    }
  }

  processInvoice() {
    let _InvoicesProcessed = 0;
    const parent = this;
    for (let i = 0; i < parent.selectedReviewItems.length; ++i) {

      parent.lastInvoice += 1;
      _InvoicesProcessed += 1;


      const selectedRow = parent.selectedReviewItems[i];
      selectedRow['temp_lastInvoice'] = parent.lastInvoice;

      const FreqItem = selectedRow['Frequency'];
      const fromDate = new Date(selectedRow['PeriodFrom']);
      const toDate = new Date(selectedRow['PeriodTo']);
      const nextInvoiceDate = new Date(selectedRow['NextInvoiceDate']);
      const dateFormat = "MM-DD-YYYY"
      //
      switch (FreqItem) {
        case "Weekly":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(7, 'days').utc().format();
          selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(7, 'days').utc().format();
          selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(7, 'days').utc().format();
          break;
        case "Bi-Weekly":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(14, 'days').utc().format();
          selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(14, 'days').utc().format();
          selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(14, 'days').utc().format();
          break;
        case "Monthly":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(1, 'months').utc().format();

          if (moment(toDate).format('DD') == moment(toDate).endOf('month').format('DD')) {
            selectedRow['PeriodTo'] = moment(toDate).add(1, 'month').endOf('months').utc().format();
          } else {
            selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(1, 'months').utc().format();
          }

          if (moment(nextInvoiceDate).format('DD') == moment(nextInvoiceDate).endOf('month').format('DD')) {
            selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate).add(1, 'month').endOf('months').utc().format();
          } else {
            selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(1, 'months').utc().format();
          }
          break;
        case "Semi-Monthly":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(15, 'days').utc().format();
          selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(15, 'days').utc().format();
          selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(15, 'days').utc().format();
          break;
        case "Quarterly":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(3, 'months').utc().format();
          selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(3, 'months').utc().format();
          selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(3, 'months').utc().format();
          break;
        case "Semi-Anually":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(6, 'months').utc().format();
          selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(6, 'months').utc().format();
          selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(6, 'months').utc().format();
          break;
        case "Annually":
          selectedRow['PeriodFrom'] = moment(fromDate, dateFormat).add(1, 'years').utc().format();
          selectedRow['PeriodTo'] = moment(toDate, dateFormat).add(1, 'years').utc().format();
          selectedRow['NextInvoiceDate'] = moment(nextInvoiceDate, dateFormat).add(1, 'years').utc().format();
          break;
      }
      const clientId = parseInt(selectedRow['ClientID']);
      const projectId = parseInt(selectedRow['ProjectID']);
      const endDate = moment(selectedRow['PeriodTo']).format("MM-DD-YYYY");
      const startDate = moment(selectedRow['PeriodFrom']).format("MM-DD-YYYY");
      parent.mangoAPISrvc.showLoader(true);

      parent.mangoAPISrvc.getClientFullinformation(selectedRow.ClientID).subscribe(clientdata => {
        parent.clientProfile = clientdata;
        parent.setLaborRates();
        parent.mangoAPISrvc.saveRecurringHeader(selectedRow).subscribe(clientdata => { }, (error) => {
          const staffID = parent.encrDecSrvc.getObject(AppConstants.staffID);
          const data = {}
          data['Action'] = "Recurring Invoice";
          data['Description'] = "Process Recurring Invoice" + " - " + "StaffID:" +  staffID;
          data['Table'] = "CompanyMango";
          const isManaging = parent.encrDecSrvc.getObject(AppConstants.isManagingAccount);
          if(!isManaging){
            parent.mangoAPISrvc.addUserLogs(data).subscribe(res=>{}, err=>{});
          }

          parent.mangoAPISrvc.showLoader(false);
        });

        parent.mangoAPISrvc.showLoader(false);

        (function (index, selectedLevelOneObj, clientdata) {
          setTimeout(() => {
            parent.createBillingHeader(clientdata, selectedLevelOneObj);
          }, index * 1000);
        })(i, selectedRow, clientdata);

        // parent.mangoAPISrvc.getTimeSheetsByClientId(clientId, projectId, startDate, endDate).subscribe(timeRecords => {
        //   parent.mangoAPISrvc.showLoader(false);
        //   parent.calculateSalesTaxes(timeRecords);




        // }, () => {
        //   parent.mangoAPISrvc.showLoader(false);
        // });

      }, (error) => {
        parent.mangoAPISrvc.showLoader(false);
      });
    }
  }

  createBillingHeader(clientdata, selectedRow) {
    const temp = [];
    const billingHeaderObj = {};
    billingHeaderObj['ClientID'] = selectedRow['ClientID'];
    billingHeaderObj['CompanyID'] = selectedRow['CompanyID'];
    billingHeaderObj['InvoiceAmount'] = numeral(selectedRow['BillAmount']).value();
    billingHeaderObj['InvoiceDate'] = this.invoiceDate;
    billingHeaderObj['InvoiceNumber'] = selectedRow['temp_lastInvoice'];
    billingHeaderObj['PeriodFrom'] = selectedRow['PeriodFrom']
    billingHeaderObj['PeriodTo'] = selectedRow['PeriodTo']
    billingHeaderObj['BillNoteTop'] = selectedRow['TopMemo'];
    billingHeaderObj['BillNote'] = this.invoiceList.StandardFooterMessage;
    billingHeaderObj['InvoiceType'] = "Recurring"
    if (this.replaceText && this.replaceText != "") {
      billingHeaderObj['DescriptionShort'] = selectedRow['InvoiceShortDescription'].replace("{replace}", this.replaceText);
    } else {
      billingHeaderObj['DescriptionShort'] = selectedRow['InvoiceShortDescription']
    }
    temp.push(selectedRow['ProjectID']);
    billingHeaderObj['ProjectMasterIDArray'] = temp;
    billingHeaderObj['GraceDays'] = clientdata['GraceDays'];
    billingHeaderObj['BillingPartnerID'] = clientdata['BillingPartnerID'];
    billingHeaderObj['OriginatingPartnerID'] = clientdata['OriginatingPartnerID'];
    billingHeaderObj['StaffAssignedID'] = clientdata['StaffAssignedID'];
    billingHeaderObj['GroupDescriptionID'] = clientdata['GroupDescriptionID'];
    billingHeaderObj['GroupDescriptionIDArray'] = clientdata['GroupDescriptionIDArray'];
    billingHeaderObj['ClientTypeID'] = clientdata['ClientTypeID'];
    billingHeaderObj['EngagementTypeID'] = selectedRow['EngagementTypeID'];

    billingHeaderObj['RecurringInvoice'] = true;
    billingHeaderObj['RecurringHeaderID'] = selectedRow['RecurringHeaderID'];
    // billingHeaderObj['TotalExpenses'] = billingHeaderObj['TotalTax'] = billingHeaderObj['TotalPayments'] = billingHeaderObj['TotalStaffCost'] = billingHeaderObj['TotalWUWD'] = billingHeaderObj['Discount'] = billingHeaderObj['RetainerApplied'] = 0;

    billingHeaderObj['TotalServices'] = billingHeaderObj['InvoiceAmount'];

    billingHeaderObj['InvoiceBalance'] = billingHeaderObj['InvoiceAmount'];
    billingHeaderObj['Discount'] = 0;
    billingHeaderObj['TotalPayments'] = 0;

    billingHeaderObj['InvoiceTemplate'] = "2";
    billingHeaderObj['InvoicePosted'] = false;
    billingHeaderObj['FinalizeAction'] = 'Print';


    if (clientdata['DefaultInvoiceTemplate']) {
      billingHeaderObj['InvoiceTemplate'] = clientdata['DefaultInvoiceTemplate'];
    }
    if (clientdata['DefaultInvoiceTemplate']) {
      billingHeaderObj['InvoiceTemplate'] = clientdata['DefaultInvoiceTemplate'];
    }

    const parent = this;
    parent.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.createBillingHeader(billingHeaderObj).subscribe((rateData: any) => {
      parent.mangoAPISrvc.showLoader(false);
      billingHeaderObj['InvoiceNumber'] = rateData.data['InvoiceNumber'];
      billingHeaderObj['BillingHeaderID'] = rateData.data['BillingHeaderID'];
      billingHeaderObj['StaffID'] = selectedRow['StaffID'];
      billingHeaderObj['ReleaseExpense'] = selectedRow['ReleaseExpense'];
      billingHeaderObj['ReleaseTime'] = selectedRow['ReleaseTime'];
      parent.salesTax.billingHeaderId = rateData.data['BillingHeaderID'];

      // parent.busy = parent._billingSvc.getActivityListByServiceCodeId(selectedRow['ServiceCodeID']).then(serviceData => {
      parent.createBillingDetail(billingHeaderObj);
      // });
    }, () => {
      parent.mangoAPISrvc.showLoader(false);
    });

  }

  createBillingDetail(billingHeaderObj) {

    const billingDetailObj = {};
    billingDetailObj['ClientID'] = billingHeaderObj['ClientID'];
    billingDetailObj['CompanyID'] = billingHeaderObj['CompanyID'];
    billingDetailObj['BillingHeaderID'] = billingHeaderObj['BillingHeaderID'];
    //billingDetailObj['WorkCodeID'] = serviceData['WorkCodeID'];
    //billingDetailObj['ServiceCodeID'] = serviceData['ServiceCodeID'];
    billingDetailObj['Description'] = billingHeaderObj['DescriptionShort'];
    billingDetailObj['StaffID'] = billingHeaderObj['StaffID'];
    billingDetailObj['InvoiceDate'] = billingHeaderObj['InvoiceDate'];
    billingDetailObj['InvoiceNumber'] = billingHeaderObj['InvoiceNumber'];
    billingDetailObj['EngagementTypeID'] = billingHeaderObj['EngagementTypeID'];
    billingDetailObj['PostedQB'] = false;
    billingDetailObj['Amount'] = billingHeaderObj['InvoiceAmount'];
    billingDetailObj['ProjectID'] = billingHeaderObj['ProjectMasterIDArray'][0];

    billingDetailObj['OriginatingPartnerID'] = billingHeaderObj['OriginatingPartnerID'];
    billingDetailObj['BillingPartnerID'] = billingHeaderObj['BillingPartnerID'];
    billingDetailObj['StaffAssignedID'] = billingHeaderObj['StaffAssignedID'];
    billingDetailObj['GroupDescriptionIDArray'] = billingHeaderObj['GroupDescriptionIDArray'];
    billingDetailObj['ClientTypeID'] = billingHeaderObj['ClientTypeID'];


    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.createBillingDetailsRecord(billingDetailObj).subscribe(detailsRecord => {
      billingHeaderObj["WorkCodeID"] = billingDetailObj['WorkCodeID'];
      billingHeaderObj["ServiceCodeID"] = billingDetailObj['ServiceCodeID'];


      if (billingHeaderObj['ReleaseTime'] == true) {
        const clientId = parseInt(billingDetailObj['ClientID']);
        const projectId = parseInt(billingHeaderObj['ProjectMasterIDArray'][0]);
        const endDate = moment(billingHeaderObj['PeriodTo']).format("MM-DD-YYYY");
        const startDate = moment(billingHeaderObj['PeriodFrom']).format("MM-DD-YYYY");

        parent.mangoAPISrvc.getTimeSheetsByClientId(clientId, projectId, startDate, endDate).subscribe(timeRecords => {
          parent.mangoAPISrvc.showLoader(false);
          // No time Records - Need to Create at least 1 time record - No need to calculate Sales Tax
          parent.createAndUpdateTimeRecord(billingHeaderObj, timeRecords);
        }, () => {
          parent.mangoAPISrvc.showLoader(false);
        });
      } else {
        // Need to Update all existing time records - Calculate Sales Tax and then Update BillingHeader
        const slipEntity = {};
        slipEntity["ClientID"] = billingHeaderObj.ClientID
        slipEntity["CompanyID"] = billingHeaderObj["CompanyID"]
        slipEntity["BillingHeaderID"] = billingHeaderObj["BillingHeaderID"];
        slipEntity["ServiceCodeID"] = billingHeaderObj["ServiceCodeID"];
        slipEntity["ProjectMasterID"] = parseInt(billingHeaderObj['ProjectMasterIDArray'][0]);
        slipEntity["IsTimeRecord"] = "T";
        slipEntity["WorkCodeID"] = billingHeaderObj["WorkCodeID"];
        slipEntity["StaffID"] = billingHeaderObj["StaffID"];
        //'to hard to get the detail line item which is in RTF.  Try converting later.
        slipEntity["Description"] = billingHeaderObj["DescriptionShort"];
        slipEntity["StandardAmount"] = 0
        slipEntity["BilledAmount"] = slipEntity["SelectedAmount"] = billingHeaderObj["InvoiceAmount"];
        slipEntity["Billable"] = slipEntity["Approved"] = slipEntity["Billed"] = true;
        slipEntity["WriteUpDown"] = slipEntity["BilledAmount"]
        slipEntity['StaffCost'] = slipEntity["Finished"] = slipEntity["TotalTime"] = slipEntity["StaffCost"] = slipEntity["ElaspedTime"] = 0;
        slipEntity["BillingPartnerID"] = billingHeaderObj["BillingPartnerID"];
        slipEntity["OriginatingPartnerID"] = billingHeaderObj["OriginatingPartnerID"];
        slipEntity["GroupDescriptionID"] = billingHeaderObj["GroupDescriptionID"];
        slipEntity["ClientTypeID"] = billingHeaderObj["ClientTypeID"];

        slipEntity[ "InvoiceDate" ] = billingHeaderObj[ "InvoiceDate" ];

        slipEntity[ "Ddate" ] = (
          moment( new Date( slipEntity[ "InvoiceDate" ] ) )
          .format( "YYYY-MM-DD" )
        );

        slipEntity["InvoiceNumber"] = billingHeaderObj["InvoiceNumber"];
        slipEntity["PostedQB"] = slipEntity["Exclude"] = slipEntity["Vacation"] = false;

        this.mangoAPISrvc.createTimeSheet(slipEntity).subscribe(data => {
          this.mangoAPISrvc.showLoader(false);
          this.updateInvoice(billingHeaderObj['InvoiceNumber']);
        }, (err) => {
          this.mangoAPISrvc.showLoader(false);
        });
      }
    }, (err) => {
      this.mangoAPISrvc.showLoader(false);
    });

  }

  createAndUpdateTimeRecord(billingHeaderObj, timeRecords) {
    const timeRecordsList: any = [];
    if (timeRecords.length == 0) {
      const slipEntity = {};
      slipEntity["ClientID"] = billingHeaderObj.ClientID
      slipEntity["CompanyID"] = billingHeaderObj["CompanyID"]
      slipEntity["BillingHeaderID"] = billingHeaderObj["BillingHeaderID"];
      slipEntity["ServiceCodeID"] = billingHeaderObj["ServiceCodeID"];
      slipEntity["WorkCodeID"] = billingHeaderObj["WorkCodeID"];
      slipEntity["ProjectMasterID"] = parseInt(billingHeaderObj['ProjectMasterIDArray'][0]);
      slipEntity["IsTimeRecord"] = "T";
      slipEntity["StaffID"] = billingHeaderObj["StaffID"];
      //'to hard to get the detail line item which is in RTF.  Try converting later.
      slipEntity["Description"] = billingHeaderObj["DescriptionShort"];
      slipEntity["StandardAmount"] = 0;
      slipEntity["BilledAmount"] = slipEntity["SelectedAmount"] = billingHeaderObj["InvoiceAmount"];
      slipEntity["Billable"] = slipEntity["Approved"] = slipEntity["Billed"] = true;
      slipEntity["WriteUpDown"] = slipEntity["StandardAmount"]
      slipEntity['StaffCost'] = slipEntity["Finished"] = slipEntity["TotalTime"] = slipEntity["StaffCost"] = slipEntity["ElaspedTime"] = 0;
      slipEntity["BillingPartnerID"] = billingHeaderObj["BillingPartnerID"];
      slipEntity["OriginatingPartnerID"] = billingHeaderObj["OriginatingPartnerID"];
      slipEntity["GroupDescriptionID"] = billingHeaderObj["GroupDescriptionID"];
      slipEntity["ClientTypeID"] = billingHeaderObj["ClientTypeID"];

      slipEntity[ "InvoiceDate" ] = billingHeaderObj[ "InvoiceDate" ];

      slipEntity[ "Ddate" ] = (
        moment( new Date( slipEntity[ "InvoiceDate" ] ) )
        .format( "YYYY-MM-DD" )
      );

      slipEntity["InvoiceNumber"] = billingHeaderObj["InvoiceNumber"];
      slipEntity["PostedQB"] = slipEntity["Exclude"] = slipEntity["Vacation"] = false;

      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createTimeSheet(slipEntity).subscribe((data) => {
        this.mangoAPISrvc.showLoader(false);
        this.updateInvoice(billingHeaderObj['InvoiceNumber']);
      }, (err) => {
        this.mangoAPISrvc.showLoader(false);
      });
    } else {
      //let TotalStandardAmt = timeRecords.reduce(function (a, b) { return a + +numeral(b['StandardAmount']).value(); }, 0);
      let TotalBillableAmount = 0;
      let totalNonBillableAmount = 0;

      timeRecords.map(function (itemData) {
        if (itemData['Billable']) {
          TotalBillableAmount += numeral(itemData['StandardAmount']).value();
        } else {
          totalNonBillableAmount += numeral(itemData['StandardAmount']).value();
        }
      });

      const writeupDownAmount = numeral(billingHeaderObj['InvoiceAmount'] - TotalBillableAmount).value();
      const AmountToAllocate = totalNonBillableAmount + writeupDownAmount;

      this.invoiceTax = 0;
      this.salesTax.serviceTax = 0;
      this.salesTax.taxableAmtService = 0;

      for (let i = 0; i < timeRecords.length; ++i) {
        const eachTime = timeRecords[i];
        eachTime['BillingHeaderID'] = billingHeaderObj['BillingHeaderID'];
        eachTime["IsTimeRecord"] = "T";

        const billableAmt = numeral(eachTime['StandardAmount']).value();

        if (eachTime['Billable']) {
          eachTime['WriteUpDown'] = AmountToAllocate * (billableAmt / TotalBillableAmount);
          eachTime['BilledAmount'] = writeupDownAmount * (billableAmt / TotalBillableAmount) + billableAmt;
        } else {
          eachTime['WriteUpDown'] = billableAmt * -1;
          eachTime['BilledAmount'] = 0;
        }

        eachTime['Billed'] = true;
        eachTime['InvoiceDate'] = billingHeaderObj['InvoiceDate'];
        eachTime['InvoiceNumber'] = billingHeaderObj['NextInvoiceNumber'];
        eachTime['SelectedAmount'] = billingHeaderObj["InvoiceAmount"];

        // calculate sales tax
        if (this.invoiceList.ActivateLaborRates == true && this.clientProfile.SalesTaxLevel != 'None') {
          eachTime.BilledAmount = eachTime.BilledAmount ? eachTime.BilledAmount : 0;
          if (eachTime.sctaxable == true) {
            this.salesTax.taxableAmtService += numeral(eachTime.BilledAmount).value();
            this.salesTax.serviceTax +=  numeral(eachTime.BilledAmount).value() * (this.salesTax.Labor / 100);
          }
        }

        timeRecordsList.push(eachTime);
      }
      const parent = this;
      parent.invoiceTax = (parent.salesTax.serviceTax);
      //update Time Records from Each row - one shot in DB
      parent.updateTimeSlipsRecords(timeRecordsList)
        .subscribe(data => {
          const obj = {};
          obj["TotalTax"] = this.salesTax.serviceTax
            ? numeral(this.salesTax.serviceTax).value()
            : 0;
          obj["SalesTaxAmount"] = this.salesTax.taxableAmtService
            ? numeral(this.salesTax.taxableAmtService).value()
            : 0;

          obj['InvoiceBalance'] = billingHeaderObj['InvoiceAmount'] + obj["TotalTax"];

          parent.mangoAPISrvc.updateSalesTaxInBillingHeader(obj, parent.salesTax.billingHeaderId).subscribe(data => {
            parent.mangoAPISrvc.showLoader(false);
            parent.updateInvoice(billingHeaderObj['InvoiceNumber']);
          }, (error) => {
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('error', this.translate.instant('error'), error);
          });

        });
    }

  }

  /*
     processing the invoice below are the steps

    1. updating the comapnymango table with NextInvoiceNumber

  */
  updateInvoice(invoiceNumber) {
    const lastInvoiceObj = {};
    lastInvoiceObj['NextInvoiceNumber'] = invoiceNumber;
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.updateLastInvoiceNumber(lastInvoiceObj).subscribe(data => {
      this.mangoAPISrvc.showLoader(false);
      Swal.fire({
        icon: 'success',
        title: this.translate.instant('Success'),
        text: this.translate.instant('invoice_processed_message'),
        showConfirmButton: true,
        showDenyButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        backdrop: false,
        confirmButtonText: parent.translate.instant('ok'),
        denyButtonText: parent.translate.instant("Go_To_Invoice_Review"),
      }).then((result) => {
        if (result.isConfirmed) {
          parent.selectedReviewItems = [];
          parent.verifyNewRecordPresents();
        } else if (result.isDenied) {
          parent.router.navigate(['/billing-invoicing/invoiceReview'])
        }
      })
    }, (error) => {
      this.mangoAPISrvc.showLoader(false);
    });
  }

  updateTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    selectedItemList.forEach((selectedItem) => {
      observableBatch.push(this.mangoAPISrvc.updateTimeSheet(selectedItem['SlipMasterID'], selectedItem))
    });
    return forkJoin(observableBatch);
  }

  /* drop downs on row */
  handleDropSelectClick(event: any, data: any, formtype: any) {
    if (formtype == 'Staff') {
      data['StaffName'] = event.label;
      data['StaffID'] = event.value;
    } else if (formtype == 'EngagementName') {
      data['EngagementName'] = event.label;
      data['ProjectID'] = event.value;
      data['EngagementTypeID'] = this.engagementListItems.filter((item) => item.ProjectMasterID == event.value)[0]['EngagementTypeID']

    } else if (formtype == 'RecurringGroupDescription') {
      const recurringGroupObj = this.recurringInvoiceGroupList.filter((item) => {
        return item.value == event.value;
      });

      if (recurringGroupObj.length == 0) {
        return false;
      }
      // if (recurringGroupObj.length > 0 && recurringGroupObj[0].value == null) {
      //   return false;
      // }
      data['RecurringInvoiceGroupID'] = recurringGroupObj[0].value;
      if (recurringGroupObj[0].value) {
        data['RecurringGroupDescription'] = recurringGroupObj[0].label.split('-')[0];
      } else {
        data['RecurringGroupDescription'] = "";
      }

    }
    // data['IsColumnChanges'] = true;
    // this.verifyNewRecordPresents();
  }

  addRow(rowIndex, itemdata) {
    const obj = {};
    obj['BillAmount'] = itemdata['BillAmount'] ? itemdata['BillAmount'] : 0;
    obj['BottomMemo'] = itemdata['BottomMemo'] ? itemdata['BottomMemo'] : "";
    obj['ClientID'] = null;
    obj['ClientName'] = null;
    obj['EngagementName'] = "";
    obj['Frequency'] = itemdata['Frequency'] ? itemdata['Frequency'] : '';
    obj['Inactive'] = itemdata['Inactive'] ? itemdata['Inactive'] : false;
    obj['InvoiceShortDescription'] = itemdata['InvoiceShortDescription'] ? itemdata['InvoiceShortDescription'] : "";
    obj['MarkInvoicePosted'] = itemdata['MarkInvoicePosted'] ? itemdata['MarkInvoicePosted'] : false;

    //if we have only one item
    if (this.recurringInvoiceGroupList.length == 1) {
      obj['RecurringGroupDescription'] = this.recurringInvoiceGroupList[0]['label'];
      obj['RecurringInvoiceGroupID'] = this.recurringInvoiceGroupList[0]['value'];
    } else {
      obj['RecurringGroupDescription'] = itemdata['RecurringGroupDescription'] ? itemdata['RecurringGroupDescription'] : "";
      obj['RecurringInvoiceGroupID'] = itemdata['RecurringInvoiceGroupID'] ? itemdata['RecurringInvoiceGroupID'] : "";
    }

    obj['ReleaseExpense'] = itemdata['ReleaseExpense'] ? itemdata['ReleaseExpense'] : false;
    obj['ReleaseTime'] = itemdata['ReleaseTime'] ? itemdata['ReleaseTime'] : false;

    //obj['ServiceCode'] = itemdata['ServiceCode'] ? itemdata['ServiceCode'] : "";
    //obj['ServiceCodeID'] = itemdata['ServiceCodeID'] ? itemdata['ServiceCodeID'] : "";
    obj['StaffID'] = itemdata['StaffID'] ? itemdata['StaffID'] : "";
    obj['StaffName'] = itemdata['StaffName'] ? itemdata['StaffName'] : "";
    obj['TopMemo'] = itemdata['TopMemo'] ? itemdata['TopMemo'] : "";
    obj['CompanyID'] = itemdata['CompanyID'] ? itemdata['CompanyID'] : "";
    obj['isNew'] = true;
    if (rowIndex == -1) {
      obj['NextInvoiceDate'] = new Date();
      obj['PeriodFrom'] = new Date();
      obj['PeriodTo'] = new Date();
      obj['RecurringHeaderID'] = 1;
      this.headerListItems.push(obj);
    } else {
      obj['NextInvoiceDate'] = new Date(itemdata['NextInvoiceDate']);
      obj['PeriodFrom'] = new Date(itemdata['PeriodFrom']);
      obj['PeriodTo'] = new Date(itemdata['PeriodTo']);
      obj['RecurringHeaderID'] = this.maxRecurringHeaderID(this.headerListItems) + 1;
      this.headerListItems.splice((rowIndex + 1), 0, obj);
    }
    this.totalAmount = numeral(this.headerListItems.reduce(function (a, b) { return a + +numeral(b['BillAmount']).value(); }, 0)).format('0,0.00');
    this.verifyNewRecordPresents();
  }

  /*

  */
  editCellComplete(event: any, data?: any) {
    if (event.type == 'focus') {
      return false;
    }
    if (event.data) {
      event.data['IsColumnChanges'] = true;
    } else if (data) {
      data['IsColumnChanges'] = true;
    }
    this.verifyNewRecordPresents();

  }

  /*
  saving new record
*/
  saveRow(event: any, itemData: any) {
    if (itemData['isNew']) {
      itemData['CompanyID'] = itemData['CompanyID'] ? itemData['CompanyID'] : this.encrDecSrvc.getObject(AppConstants.companyID);
      if (!itemData.ClientID) {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Client is Required');
        return false;
      } else if (!itemData.StaffID || itemData.StaffID == "") {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'User is Required');
        return false;
      } else if (!itemData.RecurringInvoiceGroupID || itemData.RecurringInvoiceGroupID == "") {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Billing Group is Required');
        return false;
      } else if (!itemData.ProjectID || itemData.ProjectID == "") {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Engagement is Required');
        return false;
      } else if (!itemData.InvoiceShortDescription || itemData.InvoiceShortDescription == "") {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Invoice Description is Required');
        return false;
      }

      this.mangoAPISrvc.showLoader(true);

      this.mangoAPISrvc.createRecurringHeader(itemData).subscribe((data: any) => {
        this.mangoAPISrvc.showLoader(false);

        if (data.message) {
          itemData['RecurringHeaderID'] = data.data['RecurringHeaderID'];
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), 'Saved Successfully');
          itemData['isNew'] = false;
          itemData['IsColumnChanges'] = false;
          this.verifyNewRecordPresents();
        } else {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg);
        }
      }, (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg);
      });
    } else {
      this.mangoAPISrvc.showLoader(true);

      this.mangoAPISrvc.saveRecurringHeader(itemData).subscribe((data: any) => {
        this.mangoAPISrvc.showLoader(false);

        if (data.message) {
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), data.message);
          itemData['IsColumnChanges'] = false;
          this.verifyNewRecordPresents();
        } else {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        }
      }, () => {
        this.mangoAPISrvc.showLoader(false);

        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
      })

    }
  }
  /*
     updating all the records at a time
  */
  updateAll() {
    this.saveAllEditedRows();
    const modifiedList = this.headerListItems.filter((item) => item['IsColumnChanges'] == true);
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.updateRecurringHeaders(modifiedList)
      .subscribe(data => {
        this.mangoAPISrvc.showLoader(false);

        this.selectedReviewItems = [];
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), '');
        parent.verifyNewRecordPresents();
      }, () => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
      })
  }

  updateRecurringHeaders(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject(AppConstants.token));
    selectedItemList.forEach((selectedItem, key) => {
      selectedItem['IsColumnChanges'] = false;
      // observableBatch.push(this.mangoAPISrvc.updateTopBottomMemoRecurringHeader(selectedItem['RecurringHeaderID'], selectedItem))
      observableBatch.push(
        this.http
          .put(
            `${environment.API_URL}/company/setting/updateRecurringHeader/${selectedItem["RecurringHeaderID"]}`,
            selectedItem,
            { headers: headers }
          )
      );

    });

    return forkJoin(observableBatch);
  }
  /*
     updating multiple http calls

  */
  processBatchUpdate(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    selectedItemList.forEach((selectedItem, key) => {
      selectedItem['TopMemo'] = parent.topMemoValue;
      selectedItem['BottomMemo'] = parent.bottomMemoValue;
      observableBatch.push(this.mangoAPISrvc.updateTopBottomMemoRecurringHeader(selectedItem['RecurringHeaderID'], selectedItem))
    });

    return forkJoin(observableBatch);
  }
  /*

  */
  saveTopBottomMemo() {
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.processBatchUpdate(this.selectedReviewItems)
      .subscribe(data => {
        this.mangoAPISrvc.showLoader(false);

        parent.IsTopBottomModified = false;
        parent.dialogDisplay = false;
        parent.bottomMemoValue = "";
        parent.topMemoValue = "";
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), "Memo's attached Successfully")
      }, () => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), "Memo's attached Successfully")
      })
  }

  /* Delete row */
  deleteRow(event: any, itemData: any) {
    event.preventDefault();
    if (itemData['isNew']) {
      const index = this.headerListItems.indexOf(itemData);
      this.headerListItems.splice(index, 1);
      this.totalAmount = numeral(this.headerListItems.reduce(function (a, b) { return a + +numeral(b['BillAmount']).value(); }, 0)).format('0,0.00');
      this.verifyNewRecordPresents();
      return false;
    }
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('pm.do-you-want-to-delete-this-record'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);

        parent.mangoAPISrvc.deleteRecurringHeader(itemData.RecurringHeaderID).subscribe((data: any) => {
          parent.mangoAPISrvc.showLoader(false);

          if (data.message) {
            parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), 'Successfully deleted.');
            const index = parent.headerListItems.indexOf(itemData);
            parent.headerListItems.splice(index, 1);
            parent.totalAmount = numeral(parent.headerListItems.reduce(function (a, b) { return a + +numeral(b['BillAmount']).value(); }, 0)).format('0,0.00');
          }
        }, () => {
          parent.mangoAPISrvc.showLoader(false);
        });

      }
    })
  }

  maxRecurringHeaderID(array) {
    return array.reduce((prev, current) => (prev['RecurringHeaderID'] > current['RecurringHeaderID']) ? prev : current)['RecurringHeaderID'];
  }

  /*
      updating US Money format
  */
  changeUSMoney(evt: any, data: any) {
    const enteredValue = evt.target.value;
    const myNumeral = numeral(enteredValue).value();
    if (myNumeral === null) {
      data.BillAmount = 0.00;
    } else {
      data.BillAmount = myNumeral;
    }
    // data['IsColumnChanges'] = true;
    // this.verifyNewRecordPresents();
    this.totalAmount = numeral(this.headerListItems.reduce(function (a, b) { return a + +numeral(b['BillAmount']).value(); }, 0)).format('0,0.00');
  }

  checkUncheckExpensesRow(item: any) {
    this.verifyNewRecordPresents();
    if (!item) { return false }
  }

  verifyNewRecordPresents() {

    setTimeout(() => {
      const newRecordsList = this.headerListItems.filter((item) => item['isNew'] == true);
      const colRecordsList = this.headerListItems.filter((item) => item['IsColumnChanges'] == true);

      if (newRecordsList.length > 0) {
        this.isUpdateAllenabled = false;
        this.isNewenabled = true;
      } else {
        // Memo button enbale/disable
        if (this.selectedReviewItems.length > 0) {
          this.isNewenabled = false;
        } else {
          this.isNewenabled = true;
        }
        // update all enbale/disable
        if (colRecordsList.length > 0) {
          this.isUpdateAllenabled = true;
          this.isNewenabled = true;
        } else {
          this.isUpdateAllenabled = false;
          this.isNewenabled = false;
        }
      }
      if (this.selectedReviewItems.length == 0 && !this.isNewenabled) {
        this.isNewenabled = true;
      }
    }, 500);
  }

  saveInvoice() {
    if (!this.IsEditorChanges) { return }
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.saveInvoice(this.invoiceList).subscribe(function (data: any) {
      this.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message)
    }, (err) => {
      this.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg)
    });
  }

  onEditorChange(evt: any) {
    this.IsEditorChanges = true;
  }

  onTopBottomEditorChange(evt: any) {
    this.IsTopBottomModified = true;
  }
  /*
 }

 */
  enableEditor() {
    this.IsDataReadOnly = !this.IsDataReadOnly;
    if (this.IsDataReadOnly) {
      this.btnText = 'Edit';
      this.saveInvoice();
    } else {
      this.btnText = 'Save';
    }
  }
  applySelectedItems(source, destination, labelStr, valueStr) {
    if (source.length > 0) {
      for (let j = 0; j < source.length; ++j) {
        const innerItem = source[j];
        const tempObj = {};
        if (labelStr == 'ServiceCode') {
          tempObj['label'] = innerItem[labelStr] + ' - ' + innerItem['Description'];
        } else {
          tempObj['label'] = innerItem[labelStr];
        }
        tempObj['value'] = innerItem[valueStr];
        destination.push(tempObj);
      }
    }
    return destination;
  }

  closeCreditCardForm() {
    this.myPaymentForm.reset();
    this.cardDialogDisplay = false;
    this.isDialogFormValid = false;
  }

  openDialog(rowIndex, rowData) {
    this.cardDialogDisplay = true;
    this.updateDialogForm(rowData);
  }

  singularProcessPayment() {

  }

  initializeDialogForm() {
    this.myPaymentForm = this._fb.group({
      nameOnCard: ['', [Validators.minLength(1)]],
      cardno: ['', [Validators.minLength(16)]],
      expDate: ['', [Validators.minLength(5)]],
      securityCode: ['', [Validators.minLength(3)]],
      customerDesc: [''],
      firstName: [''],
      lastName: [''],
      address: [''],
      phoneNum: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: [''],
      email: [''],
      company: [''],
      amount: [''],
      InvoiceReminderActivate: [false],
      InvoiceReminderStartDate: [new Date()]
    });
    this.myPaymentForm.valueChanges.subscribe(data => {
      this.validateDialogForm();
    })
  }

  /*
  Verifing the form
  */
  validateDialogForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myPaymentForm.controls).forEach(key => {
      if (this.myPaymentForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myPaymentForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.myPaymentForm.dirty) {
      this.isDialogFormValid = true;
    } else {
      this.isDialogFormValid = false;
    }
  }

  /*
  updateDialogForm form data
  */
  updateDialogForm(data) {
    this.myPaymentForm.controls['nameOnCard'].setValue('');
    this.myPaymentForm.controls['cardno'].setValue('');
    this.myPaymentForm.controls['expDate'].setValue('');
    this.myPaymentForm.controls['securityCode'].setValue('');
    this.myPaymentForm.controls['customerDesc'].setValue('');
    this.myPaymentForm.controls['firstName'].setValue('');
    this.myPaymentForm.controls['lastName'].setValue('');
    this.myPaymentForm.controls['address'].setValue('');
    this.myPaymentForm.controls['phoneNum'].setValue('');
    this.myPaymentForm.controls['city'].setValue('');
    this.myPaymentForm.controls['state'].setValue('');
    this.myPaymentForm.controls['zip'].setValue('');
    this.myPaymentForm.controls['country'].setValue('');
    this.myPaymentForm.controls['email'].setValue('');
    this.myPaymentForm.controls['company'].setValue(this.encrDecSrvc.getObject(AppConstants.companyName));
    this.myPaymentForm.controls['amount'].setValue('$' + numeral(data['BillAmount']).format('0,0.00'));
  }

  stopEdit(event) {
    event.stopPropagation();
    $(this.dt.editingCell).removeClass('ui-editing-cell');
    this.dt.editingCell = null;
  }

  /*
     Fetching company data from Server
     */
  getCompanyData() {
    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.getCompanyViewData().subscribe((data) => {
      this.mangoAPISrvc.showLoader(false);

      if (data['EnrollmentPending'] != null && data['ClientPartnerKey'] != null && data['ClientPartnerKey'] != null) {
        this.isEnrollForm = true;
      }
      else {
        this.isEnrollForm = false;
      }
    }, (err) => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
    })
  }

  onRowEditInit(data: any) {
    if (Object.keys(this.clonedData).length === 0) {
      this.clonedData = {};
      this.clonedData = { ...data };
    }
    // data['IsColumnChanges'] = true
  }

  onRowEditSave(event: any, data: any, type: string) {
    data['IsColumnChanges'] = true
    this.clonedData = {};
    this.verifyNewRecordPresents();
  }

  onRowEditCancel(data: any, index: number) {
    data = Object.assign(data, this.clonedData);

    // data['IsColumnChanges'] = false
    this.clonedData = {};
  }

  saveAllEditedRows() {
    $(`.save-btn-edit-row`).click();
  }

  setLaborRates() {
    const parent = this;
    if (parent.invoiceList.ActivateLaborRates == true && parent.clientProfile.SalesTaxLevel != 'None') {
      if (parent.clientProfile.SalesTaxLevel == 'ClientRate') {
        parent.salesTax.Labor = parent.clientProfile.Tax1ID ? numeral(parent.clientProfile.Tax1ID).value() : 0;
        parent.salesTax.Expense = parent.clientProfile.Tax2ID ? numeral(parent.clientProfile.Tax2ID).value() : 0;
      } else if (parent.clientProfile.SalesTaxLevel == 'CompanyLocationRate') {
        const selectedCompanyLocation = this.companyLocations.filter((location) => location['value'] == parent.clientProfile.CompanyMangoLocationID)[0];
        parent.salesTax.Labor = selectedCompanyLocation['laborRate'] ? numeral(selectedCompanyLocation['laborRate']).value() : 0;
        parent.salesTax.Expense = selectedCompanyLocation['expenseRate'] ? numeral(selectedCompanyLocation['expenseRate']).value() : 0;
      } else {
        parent.salesTax.Labor = parent.invoiceList.LaborRate1 ? numeral(parent.invoiceList.LaborRate1).value() : 0;
        parent.salesTax.Expense = parent.invoiceList.ExpenseRate1 ? numeral(parent.invoiceList.ExpenseRate1).value() : 0;
      }
    }
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.companyId).subscribe(
      (data: any) => {
        this.companyLocations = data.map((location) => {
          return {
            value: location.CompanyMangoLocationID,
            label: location.CompanyLocation,
            laborRate: location.TaxRateLaborL,
            expenseRate: location.TaxRateExpenseL,
          }
        });
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }
}
