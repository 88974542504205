<div class="layout-content">
    <div class="clientform-btn-top">
        <button pButton pRipple label="{{'save_button' | translate}}" [disabled]="(!isFormValid)" icon="fal fa-check"
        class="p-button-sm p-pr-2 p-mr-2" (click)="saveScheduleData(scheduleForm.value)">
        </button>
        <p-button pRipple label="{{'user.user_list' | translate}}" icon="fal fa-user-friends" styleClass="p-button-sm"
            class="p-button-help p-pr-2" (click)="redirectToUserList()"></p-button>
    </div>
    <form role="form" [formGroup]="scheduleForm" novalidate>
        <div class="p-fluid p-formgrid p-grid p-mx-2">
            <div class="p-col-6">
                <div class="card p-mb-3 users-sch-pref-mh">
                    <h4 class="label-text p-mb-3">{{'user.Calendar_Options' | translate}}</h4>
                    <div class="p-field p-col-4 p-mt-4">
                        <label class="label-text">{{'user.First-Day-of-Work-Week' | translate}}</label>
                        <p-dropdown [options]="daysDataSet" scrollHeight="400px" formControlName="FirstDayofWeek"
                            placeholder="{{'user.Select-a-Week' | translate}}"></p-dropdown>
                    </div>
                    <div class="p-field p-col-4">
                        <label class="label-text">{{'user.Default-View' | translate}}</label>
                        <p-dropdown [options]="weekDataSet" formControlName="DefaultView"
                            placeholder="{{'user.Select-a-View' | translate}}"></p-dropdown>
                    </div>
                    <div class="p-field p-col-4">
                        <label class="label-text">{{'user.Default-Time-Interval' | translate}}</label>
                        <p-dropdown [options]="intervalDataSet" formControlName="DefaultTimeInterval"
                            placeholder="{{'user.Select-a-Interval' | translate}}"></p-dropdown>
                    </div>
                    <div class="p-field p-col-4">
                        <label class="label-text">{{'user.AutoRefresh-Calendar' | translate}}</label>
                        <p-dropdown [options]="ScheduleRefreshTimerItem" formControlName="ScheduleRefreshTimer"
                            placeholder="{{'user.Select-a-Interval' | translate}}"></p-dropdown>
                    </div>
                    <div class="p-field p-col-9 p-mt-5">
                        <label class="label-text p-col-6 p-pl-0">{{'user.My-Appointment-Color' | translate}}</label>
                        <p-colorPicker formControlName="Color" format="hex" styleClass="p-col-1"></p-colorPicker>
                    </div>
                </div>
            </div>
            <div class="p-col-6">
                <div class="card p-mb-3">
                    <h4 class="label-text p-mb-3">{{'user.Visible-Work-Times-on-Calendar' | translate}}<i
                            tooltipPosition="top" pTooltip="{{'user.Visible-work-time-tooltip' | translate}}"
                            tooltipStyleClass="tooltip-width-800"
                            class="fa fa-question-circle ic-blue cursor-hand  p-ml-1"></i></h4>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-4">
                            <label class="label-text p-mt-3">{{'user.Start-Time' | translate}}</label>
                            <p-dropdown [options]="timeIntervalDataSet" formControlName="StartTimeWork"
                                placeholder="-- : -- --"></p-dropdown>
                        </div>
                        <div class="p-field p-col-4">
                            <label class="label-text p-mt-3">{{'user.End-Time' | translate}}</label>
                            <p-dropdown [options]="timeIntervalDataSet" formControlName="EndTimeWork"
                                placeholder="-- : -- --"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="card p-mb-3">
                    <h4 class="label-text p-mb-3">{{'user.Days-to-Display' | translate}} <i tooltipPosition="top"
                            pTooltip="{{'user.Days-to-display-tooltip' | translate}}"
                            tooltipStyleClass="tooltip-width-300" class="fa fa-question-circle ic-blue cursor-hand"></i>
                    </h4>

                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="0" label="{{'Sunday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="1" label="{{'Monday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="2" label="{{'Tuesday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="3" label="{{'Wednesday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="4" label="{{'Thursday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="5" label="{{'Friday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="6" label="{{'Saturday' | translate}}" class="label-text"
                            [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}"
                            formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>