<div class="imports">
  <div class="layout-content p-pt-0">
    <span class="pull-right p-mr-3 clientform-btn-top">
      <button pButton pRipple class="
          p-button-sm

          p-pr-2
          p-mr-2
        " icon="fa-cloud-upload fas" label="{{ 'Upload' | translate }}" [disabled]="!selectedType"
        (click)="showFiles()">
      </button>
    </span>
    <div class="p-grid p-mx-2">
      <div class="p-col-12">
        <div class="card p-mb-1 p-pl-5 p-py-3">
          <label class="label-text p-mr-4">
            {{ 'Import_Type' | translate }}
          </label>
          <p-dropdown placeholder="Select Import Type" styleClass="width-14p" [(ngModel)]="selectedType"
            [ngModelOptions]="{standalone: true}" [options]="importList" (onChange)="selectType($event)">
          </p-dropdown>
          <label *ngIf="selectedType" class="label-text p-mx-3">
            File Name:
          </label>
          <label *ngIf="selectedType" class="label-text p-mr-4 ic-red">
            {{ selectedFileType }}
          </label>
        </div>
        <div class="card p-pt-0">
          <div class="p-col-12 p-pt-0">
            <p-table #dt sortField="UploadedAt" styleClass="
                p-datatable-hoverable-rows
                p-datatable-sm
                p-datatable-responsive-demo
              " [value]="csvData" [responsive]="true" [globalFilterFields]="
                [
                  'FilePath',
                  'UploadedAt',
                  'InProgress',
                  'StartedAt',
                  'IsCompleted',
                  'TotalRecords',
                  'NumSuccess',
                  'NumFailed',
                  'CompletedAt'
                ]
              " [sortOrder]="-1" [rowHover]="true" [rows]="csvData.length" [scrollable]="true"
              [scrollHeight]="'calc(90vh - 350px)'" (onFilter)="onFilter($event)">
              <ng-template pTemplate="caption">
                <div class="
                    table-header

                    p-grid
                  ">
                  <div class="
                      p-col-6

                      p-d-inline
                    ">
                    <span class="
                        p-input-icon-left
                      ">
                      <i class="
                          fa-search
                          fal
                        ">
                      </i>
                      <input #searchValue pInputText type="text" placeholder="{{ 'globalsearch' | translate }}"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" />
                    </span>
                    <span style="
                        margin-left: 5px;
                      ">
                      <button pButton pRipple type="button" class="
                          p-button-rounded
                          p-button-danger
                          p-button-text
                        " icon="pi pi-times" tooltipPosition="top" pTooltip="{{ 'clear' | translate }}" (click)="
                          dt.reset();clearSearchFilter()
                        ">
                      </button>
                    </span>
                    <p-message *ngIf="filteredItemsSize > -1"
                      text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2"
                      severity="success">
                    </p-message>
                  </div>
                  <div class="
                      p-col-6

                      p-d-inline
                    ">
                    <button pButton pRipple class="
                        p-button-sm

                        p-pr-2
                        p-mr-2
                      " icon="fa-sync fas" label="{{ 'Refresh' | translate }}" [disabled]="!selectedType" style="
                        float: right;
                      " (click)="
                        refreshList( );
                      ">
                    </button>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-12p p-text-center">
                    {{ 'actions' | translate }}
                  </th>
                  <th class="width-12p p-text-left" pSortableColumn="FilePath" pTooltip="File Name"
                    tooltipPosition="top">
                    {{ 'File' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="FilePath">
                    </p-sortIcon>
                  </th>
                  <th class="width-8p p-text-left" pSortableColumn="UploadedAt" pTooltip="{{'Uploaded_At' | translate}}"
                    tooltipPosition="top">
                    {{ 'Uploaded' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="UploadedAt">
                    </p-sortIcon>
                  </th>
                  <th class="width-8p p-text-left" pSortableColumn="StartedAt" pTooltip="Started At"
                    tooltipPosition="top">
                    {{ 'Started' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="StartedAt">
                    </p-sortIcon>
                  </th>
                  <th class="width-6p p-text-center" pSortableColumn="TotalRecords" pTooltip="Total Records"
                    tooltipPosition="top">
                    {{ 'Total' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="TotalRecords">
                    </p-sortIcon>
                  </th>
                  <th class="width-6p p-text-center" pSortableColumn="NumSuccess" pTooltip="Num Success"
                    tooltipPosition="top">
                    {{ 'Success' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="NumSuccess">
                    </p-sortIcon>
                  </th>
                  <th class="width-6p p-text-center" pSortableColumn="NumFailed" pTooltip="Num Failed"
                    tooltipPosition="top">
                    {{ 'Failed' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="NumFailed">
                    </p-sortIcon>
                  </th>
                  <th class="width-8p p-text-left" pSortableColumn="CompletedAt" pTooltip="Completed At"
                    tooltipPosition="top">
                    {{ 'completed' | translate }}
                    <i class="fa fa-question-circle ic-blue">
                    </i>
                    <p-sortIcon field="CompletedAt">
                    </p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template let-rowData let-rowIndex="rowIndex" pTemplate="body">
                <tr>
                  <td class="width-12p p-text-center">
                    <div class="p-formgroup-inline">
                      <div class="
                          p-col
                          p-p-0
                        ">
                        <button *ngIf="
                                !rowData.InProgress
                            &&  !rowData.IsCompleted
                          " pButton type="button" class="
                            ic-white
                            bg-green
                            p-button-sm
                          " label="{{ 'Start' | translate }}" style="
                            height: 30px;
                          " (click)="
                            processCSV( rowData.CustomerCSVID, rowData.FileName );
                          ">
                        </button>
                      </div>
                      <div class="
                          p-col
                          p-p-0
                        ">
                        <button *ngIf="
                            rowData.InProgress
                          " pButton type="button" class="
                            ic-white
                            bg-blue
                            p-button-sm
                          " label="{{ 'Refresh' | translate }}" style="
                            height: 30px;
                          " (click)="
                            refreshCSV( rowData.CustomerCSVID );
                          ">
                        </button>
                      </div>
                      <div class="
                          p-col
                          p-p-0
                        ">
                        <button pButton type="button" class="
                            ic-white
                            bg-red
                            p-button-sm
                          " label="{{ 'mgmt-delete' | translate }}" style="
                            height: 30px;
                          " (click)="
                            removeCSV(rowData);$event.stopPropagation();
                          ">
                        </button>
                      </div>
                      <div class="
                          p-col
                          p-p-0
                        ">
                        <button pButton *ngIf="
                            rowData.ImportLogCount > 0
                          " type="button" class="
                            ic-white
                            bg-orange
                            p-button-sm
                          " label="Error Log" style="
                            height: 30px;
                          " (click)="
                            onShowErrorLogSidebar(rowData);$event.stopPropagation();
                          ">
                        </button>
                      </div>
                    </div>
                  </td>
                  <td class="width-12p p-text-left" showDelay="800" pTooltip="{{rowData.FileName}}"
                    tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                    {{ rowData.FileName }}
                  </td>
                  <td class="width-8p p-text-left" pTooltip="{{rowData.UploadedAt | date: 'MM/dd/yyy HH:mm' : 'UTC'}}"
                    tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                    {{ rowData.UploadedAt | date: 'MM/dd/yyy HH:mm' : 'UTC' }}
                  </td>
                  <td class="width-8p p-text-left" pTooltip="{{rowData.StartedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC'}}"
                    tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                    {{ rowData.StartedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC' }}
                  </td>
                  <td class="width-6p p-text-center" showDelay="800" pTooltip="{{rowData.TotalRecords}}"
                    tooltipPosition="top">
                    {{ rowData.TotalRecords }}
                  </td>
                  <td class="width-6p p-text-center" showDelay="800" pTooltip="{{rowData.NumSuccess}}"
                    tooltipPosition="top">
                    {{ rowData.NumSuccess }}
                  </td>
                  <td class="width-6p p-text-center" showDelay="800" pTooltip="{{rowData.NumFailed}}"
                    tooltipPosition="top">
                    {{ rowData.NumFailed }}
                  </td>
                  <td class="width-8p p-text-left" pTooltip="{{rowData.CompletedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC'}}"
                    tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                    {{ rowData.CompletedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC' }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8">
                    <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                    </p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar position="bottom" [(visible)]="showAttachedFiles" [style]="{'min-height':'320px'}">
  <div class="card">
    <div class="header-color text-nowrap col-sm-9">
      <h4 class="label-text">
        {{ 'Upload' | translate }} {{ 'Files' | translate }}
      </h4>
    </div>
    <div class="p-grid p-mx-2">
      <div class="card flex justify-content-center" style="width: 100%;">
        <p-fileUpload name="fileupload" #fileUpload [auto]="true" chooseLabel="Upload" (onSelect)="fileEvent($event)"
          [multiple]="false" accept="text/csv" maxFileSize="3145728">
          <ng-template pTemplate="content">
            <div class="p p-d-flex p-jc-center" *ngIf="files.length == 0" style="height: 40px;">
              Drag file(s) here
            </div>
            <ul *ngIf="files.length > 0">
              <li *ngFor="let file of files">
                {{ file.name }} - {{ file.size }} bytes
              </li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="isShowErrorLogSidebar" position="right" [closeOnEscape]="true"
  (onHide)="onCloseErrorLogSidebar()"
  [style]="{'min-width':'60%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div *ngIf="isShowErrorLogSidebar" class="card p-pt-6" style="min-height: calc(100vh - 35px)">
    <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
      <div *ngIf="!!!csvErrorLogs">
        <p-message severity="info" text="{{ 'data_not_found' | translate }}." styleClass="p-px-2">
        </p-message>
      </div>
      <p-accordion *ngIf="csvErrorLogs?.length > 0">
        <ng-template ngFor let-csvErrorLog let-i="index" [ngForOf]="csvErrorLogs">
          <p-accordionTab [selected]="i === 0" header="{{csvErrorLog?.Description}}">
            <p-table #dt [value]="csvErrorLog.DisplayData"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" [rowHover]="true"
              dataKey="key" sortField="key" [sortOrder]="1" #wildcardTable>
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-30p p-text-left" pSortableColumn="headerName">
                    Header Name
                    <p-sortIcon styleClass="p-ml-0" field="headerName"></p-sortIcon>
                  </th>
                  <th class="width-70p p-text-left" pSortableColumn="value">
                    Value
                    <p-sortIcon styleClass="p-ml-0" field="value"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="width-30p p-text-left" tooltipPosition="top" pTooltip="{{ rowData.headerName }} "
                    tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                    {{ rowData.headerName }}
                  </td>
                  <td class="width-70p p-text-left" tooltipPosition="top" pTooltip="{{ rowData.value }} "
                    tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                    {{rowData.value}}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="2">
                    <p-message severity="info" text="{{ 'data_not_found' | translate }}." styleClass="p-px-2">
                    </p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-accordionTab>
        </ng-template>
      </p-accordion>
    </div>
  </div>
</p-sidebar>