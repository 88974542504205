<div class="{{!isFromDMS ? 'p-card p-mx-3 p-my-2 p-p-3' : '' }}">
    <div class="p-grid p-mt-3">
        <div class="p-col-8">
            <p-checkbox [(ngModel)]="iShareObj.isIntegrateIS" binary="true"
                label="{{ 'activate-imagineshare-integration' | translate }}"
                (onChange)="onChangeIsIntegrateIS($event)">
            </p-checkbox>
        </div>
        <div class="p-col-4 p-text-right">
            <img alt="IShare Logo" src="assets/img/mangoshare_icon.png" height="30px" width="200px" />
        </div>
        <div class="p-col-12" *ngIf="iShareObj.isAddExistingFirm || iShareObj.isIShareTokenVerified">
            <div class="p-field p-grid p-mb-0">
                <label for="imageshareapi" class="p-ml-2">MangoShare Firm ID<i
                    pTooltip="Get the Firm ID From MangoShare" tooltipStyleClass="tooltip-no-wrap"
                    class="fa fa-question-circle ic-blue question-tooltip-icon p-ml-2"
                    tooltipPosition="right"></i></label>
                <div class="p-ml-6">
                    <input type="text" [(ngModel)]="iShareObj.firmID" style="width: 25vw"
                        [ngModelOptions]="{standalone: true}" [disabled]="iShareObj.isIShareTokenVerified" pInputText />
                </div>
            </div>
            <div class="p-field p-grid p-mb-0 p-mt-3">
                <label for="imageshareapi" class="p-ml-2">MangoShare API Key<i
                        pTooltip="Paste API Key From MangoShare Settings" tooltipStyleClass="tooltip-no-wrap"
                        class="fa fa-question-circle ic-blue question-tooltip-icon p-ml-2"
                        tooltipPosition="right"></i></label>
                <div class="p-ml-6">
                    <input type="text" [(ngModel)]="iShareObj.vendorAPIKeyIShare" style="width: 25vw"
                        [ngModelOptions]="{standalone: true}" [disabled]="iShareObj.isIShareTokenVerified" pInputText />
                </div>
            </div>
            <div class="p-field p-grid p-mb-0">
                <label for="imageshareapi" class="width-16p"> &nbsp; </label>
                <label class="p-text-center ic-size-10 p-ml-3 p-text-italic">(Generated API key from
                    MangoShare App)</label>
            </div>
            <div class="p-field p-grid p-mb-0 p-mt-3">
                <label for="imageshareapi" class="width-16p"> &nbsp; </label>
                <button pButton pRipple type="button" label="Connect" *ngIf="!iShareObj.isIShareTokenVerified" icon="fal fa-external-link"
                    class="p-button-sm p-pr-2" [disabled]="!iShareObj.vendorAPIKeyIShare || !iShareObj.firmID"
                    (click)="verifyISToken(iShareObj)"></button>
                <button pButton pRipple type="button" label="Remove Token" *ngIf="iShareObj.isIShareTokenVerified" icon="fal fa-times"
                    class="p-button-sm p-pr-2 p-button-danger" (click)="removeISToken()"></button>
            </div>
            <div class="p-field p-mt-3" *ngIf="iShareObj.isIShareTokenVerified">
                <p-table styleClass="p-datatable-sm p-datatable-responsive-demo" [rowHover]="true" #tableIshareClients
                    [value]="iShareIntegrationTable" dataKey="id"
                    [(selection)]="selectedIShareItems"
                    [scrollable]="true" [responsive]="true" [scrollHeight]="'calc(56vh - 100px)'">
                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <div class="p-d-flex p-jc-end">
                                <button pButton pRipple type="button" label="Sync Record(s)" icon="fal fa-sync"
                                    class="p-button-sm p-pr-2 p-mr-2" [disabled]="selectedIShareItems?.length === 0"
                                    (click)="syncRecords()"></button>
                                <button pButton pRipple type="button" *ngIf="!this.iShareObj.isDMSIShare" label="Enable MangoShare" icon="fal fa-folders"
                                    class="p-button-sm p-pr-2" (click)="enableImagineShareDMS()"></button>
                                <button pButton pRipple type="button" *ngIf="this.iShareObj.isDMSIShare" label="Disable MangoShare" icon="fal fa-folders"
                                    class="p-button-sm p-pr-2 p-button-danger" (click)="disableImagineShareDMS()"></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-8p p-text-center">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th class="width-40p">Mango Client Name</th>
                            <th class="width-40p">MangoShare Client Name</th>
                            <th class="p-text-center width-12p">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td class="width-8p p-text-center">
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <td *ngIf="rowData.isMangoClient" class="width-40p p-text-truncate p-text-nowrap" pTooltip="{{ rowData.MangoClient?.name }}"
                                tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                                {{ rowData.MangoClient?.name }}
                            </td>
                            <td *ngIf="rowData.isIShareClient" class="width-40p p-text-truncate p-text-nowrap" pTooltip="{{ rowData.MangoClient?.name }}"
                                tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800" pEditableColumn>
                                <!-- {{ rowData.IShareClient?.name }} -->
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="iShareObj.mangoClients"
                                            placeholder="{{rowData.MangoClient?.name}}"
                                            [filter]="true" filterBy="ClientName" [showClear]="false"
                                            [(ngModel)]="rowData.MangoClient.id"
                                            [ngModelOptions]="{ standalone: true }"
                                            (onChange)="handleSelectMangoClient($event, rowData)"
                                            appendTo="body" [style]="{'width':'200px !important'}" class="p-text-left">
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div *ngIf="rowData.MangoClient?.id">
                                            {{rowData.MangoClient?.name}}
                                        </div>
                                        <div class="client-link cursor-hand" *ngIf="!rowData.MangoClient?.id">
                                            Click to select Mango Client
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td *ngIf="rowData.isIShareClient" class="width-40p p-text-truncate p-text-nowrap" pTooltip="{{ rowData.IShareClient?.name }}"
                                tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                                {{ rowData.IShareClient?.name }}
                            </td>
                            <td *ngIf="rowData.isMangoClient" class="width-40p p-text-truncate p-text-nowrap" pTooltip="{{ rowData.IShareClient?.name }}"
                                tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800" pEditableColumn>
                                <!-- {{ rowData.IShareClient?.name }} -->
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="iShareObj.iShareClients"
                                            placeholder="{{rowData.IShareClient?.name}}"
                                            [filter]="true" filterBy="name" [showClear]="false"
                                            [(ngModel)]="rowData.IShareClient.id"
                                            [ngModelOptions]="{ standalone: true }"
                                            (onChange)="handleSelectIShareClient($event, rowData)"
                                            appendTo="body" [style]="{'width':'200px !important'}" class="p-text-left">
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div *ngIf="rowData.IShareClient?.id">
                                            {{rowData.IShareClient?.name}}
                                        </div>
                                        <div class="client-link cursor-hand" *ngIf="!rowData.IShareClient?.id">
                                            Click to select MangoShare Client
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td class="width-12p p-text-center">
                                {{ rowData.matchStatus }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">
                                <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                    styleClass="p-px-2"></p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>