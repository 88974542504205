import { Component, OnInit, HostListener, Inject } from "@angular/core";
import { AppMainComponent } from "@app/app.main.component";
import {
  MangoApiService,
  EncrDecrService,
  mangoUtils,
  AuthGuard,
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { Subscription } from "rxjs";
import { PrimeNGConfig } from "primeng/api";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  providers: [MangoApiService],
})
export class AppComponent implements OnInit {
  isLoggedInUser: any = false;
  loggedInSubscription: Subscription;
  screenWidth: number;

  constructor(
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private authGuard: AuthGuard,
    public app: AppMainComponent,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private mangoUtils: mangoUtils
  ) {
    this.loadResponsiveCSS();
    this.initialise();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    const tempisLoggedInUser = this.encrDecSrvc.getObject(
      AppConstants.isLoggingUser
    );
    this.isLoggedInUser = tempisLoggedInUser
      ? JSON.parse(tempisLoggedInUser)
      : false;
    this.loggedInSubscription = this.mangoAPISrvc.isLoggedIn$.subscribe(
      (data) => {
        // Notifies mangoUtils when user is logged in, to fetch shortcut codes
        this.mangoUtils.shouldFetchCode.next(data ? true : false);

        //console.log("isLoggedInUser >> ", data);
        if (data) {
          const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
          this.mangoAPISrvc.clearPreviewModeInvoices(companyId)
            .subscribe(result=>{
            })
          this.isLoggedInUser = data;
          this.encrDecSrvc.loadDefaultAPI();
          // let isClientListFetched = this.encrDecSrvc.getObject(AppConstants.isClientListFetched);
          // if ((this.encrDecSrvc.clientList.length == 0 && companyId) || !isClientListFetched) {
          //     this.encrDecSrvc.addObject(AppConstants.isClientListFetched, true);
          //     this.encrDecSrvc.getClientList();
          // }
        } else {
          this.encrDecSrvc.clientList = [];
          this.isLoggedInUser = false;
          const url = window.location.href;
          if (url.indexOf("/activate") == -1 && url.indexOf("/hosted-page") == -1 &&  url.indexOf("/filesAndfolders") == -1) {
            this.router.navigate(["/login"]);
          }
        }
      }
    );

    const isReload = sessionStorage.getItem( "isReload" );
    if(
          (
            !isReload
          )
    ){
      sessionStorage.removeItem( "isReload" );

      const url = (
        window.location.href
      );
      if(
            url.indexOf( `/${ AppConstants.loginRoutePath }` ) == -1
        &&
            url.indexOf( `/${ AppConstants.setUp }` ) == -1
        &&
            url.indexOf( `/${ AppConstants.dmsRoutePath }` ) == -1
        &&
            url.indexOf( `/${ AppConstants.registerRoutePath }` ) == -1
        &&
            url.indexOf( `/${ AppConstants.paymentsRoutePath }` ) == -1
        &&
            (
                  !this.encrDecSrvc.getObject( AppConstants.token )
              ||
                  url.indexOf( `${ AppConstants.superAdmin}/${AppConstants.calendarIntegration }` ) == -1
              ||
                  url.indexOf( `${ AppConstants.superAdmin}/${AppConstants.microsoftIntegration }` ) == -1
            )
      ){
        const timerTasks = this.encrDecSrvc.getObject( AppConstants.timerTasks );

        const timerOwner = this.encrDecSrvc.getObject( AppConstants.timerOwner );

        this.router.navigate( [ "/login" ] )
        .then(
          (
            ( result ) => {
              this.authGuard.logout( );

              setTimeout(
                (
                  ( ) => {
                    this.mangoAPISrvc.notifyLogging( false );

                    if( timerTasks ){
                      this.encrDecSrvc.addObject( AppConstants.timerOwner, timerOwner );

                      this.encrDecSrvc.addObject( AppConstants.timerTasks, timerTasks );
                    }
                  }
                ),

                (
                  100
                )
              );
            }
          )
        );
      }
    }
    else{
      sessionStorage.removeItem( "isReload" );
    }
  }

  @HostListener("window:beforeunload")
  saveFlagIfReload() {
    if (this.encrDecSrvc.getObject(AppConstants.token))
      sessionStorage.setItem("isReload", "true");
  }

  @HostListener("window:keyup", ["$event"])
  @HostListener("window:click", ["$event"])
  @HostListener("window:wheel", ["$event"])
  resetTimer(event: KeyboardEvent) {
    this.encrDecSrvc.notifyUserAction();
  }

  loadResponsiveCSS() {
    const head = document.getElementsByTagName("head")[0];
    const style = this.document.createElement("link");
    this.encrDecSrvc.addObject(AppConstants.isLaptop, false);
    style.rel = "stylesheet";
    if (window.screen.width >= 1700 && window.screen.width < 4000) {
      style.href = `assets/mango/css/large.32.screen.css`;
    } else if (window.screen.width >= 1500 && window.screen.width < 1650) {
      style.href = `assets/mango/css/large.27.screen.css`;
    } else {
      style.href = `assets/mango/css/default.screen.css`;
      this.encrDecSrvc.addObject(AppConstants.isLaptop, true);
    }
    head.appendChild(style);
  }

  initialise() {
    this.translate.addLangs(["en", "es"]);
    const lang = localStorage.getItem("language");
    if (lang == "Spanish") {
      this.translate.setDefaultLang("es");
      this.translate.use("es");
    } else {
      this.translate.setDefaultLang("en");
      this.translate.use("en");
    }
    this.authGuard.changeLang.subscribe((lang) => {
      switch (lang) {
        case "Spanish":
          this.translate.use("es");
          break;
        case "English":
        default:
          this.translate.use("en");
          break;
      }
    });
  }
}
