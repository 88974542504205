<div class="layout-content p-pt-0">
    <!-- buttons -->
    <span class="pull-right p-mr-3 clientform-btn-top">
        <button pButton pRipple   label="Save" icon="fas fa-check" class="p-button-sm p-pr-2 p-mr-2" pTooltip="Save" tooltipPosition="top" [disabled]="saveDisabled" (click)="saveSignature()"></button>
    </span>
    <div class="p-grid">
        <div class="p-field p-col-11 p-mx-3 p-mt-2">
            <ejs-richtexteditor #customRTE (change)="onChange()" [(value)]="editorValue" [toolbarSettings]='emailTools' [insertImageSettings]='imageSettings' [height]="442" [saveInterval]="500" [autoSaveOnIdle]="true">
            </ejs-richtexteditor>
        </div>
    </div>
</div>