import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs'
import { TranslateService } from '@ngx-translate/core';
import { CashReceiptsComponent } from './cash-receipts/cash-receipts.component';
import Swal from 'sweetalert2';
import { EncrDecrService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { MakeDepositsComponent } from './make-deposits/make-deposits.component';
import { STaxComponent } from './s-tax/s-tax.component';

@Injectable()
export class CanDeactivateForm  {
  constructor(private _translate: TranslateService, private _service: EncrDecrService) { }

  canDeactivate(
    component: CashReceiptsComponent | MakeDepositsComponent | STaxComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {

    let labelStr = this._translate.instant('You have unsaved changes. Are you sure you want to leave this page?');
    let yessyr = 'Yes, leave without saving';

    const isFormChanged = this._service.getObject(AppConstants.isFormChanged);
    if (!isFormChanged) {
      return true;
    }

    if (currentState && currentState.url == '/accounting/cards-view') {
      labelStr = 'Are you sure you want to cancel Enrollment?';
      yessyr = "Yes"
    }
    return Observable.create((observer: Observer<boolean>) => {
      Swal.fire({
        title: this._translate.instant('Warning'),
        text: labelStr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: yessyr,
        cancelButtonText: 'No, stay here!'
      }).then((result) => {
        if (result.value) {
          this._service.addObject(AppConstants.isFormChanged, false);
          observer.next(true);
          observer.complete();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          observer.next(false);
          observer.complete();
        }
      })
    });
  }
}
