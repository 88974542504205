import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { MangoApiService, EncrDecrService, mangoUtils, AuthGuard } from '@app/_services';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html'
})
export class TaskComponent implements OnInit {
  showTaskDialog: boolean = false;
  isNotShowSurcharging: boolean = false;
  defaultTaskRecord;
  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private _router: Router) {
    this.mangoAPISrvc.applyLanguage();
  }
  ngOnInit(): void {
  }

  openTaskDialog(data) {
    this.showTaskDialog = true;
    this.defaultTaskRecord = data;
    this.isNotShowSurcharging = data.isNotShowSurcharging;
  }

  saveIsNotShowSurcharge() {
    const staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.mangoAPISrvc.updateUsrSelFiltersByUserId(staffID, { isNotShowSurcharging: this.isNotShowSurcharging }).subscribe((userData: any) => {
    });
  }

  redirectToStaxViewScreen(isRedirect) {
    this.saveIsNotShowSurcharge();
    this.showTaskDialog = false;
    if (isRedirect) {
      this._router.navigate(["accounting/cards-view"]);
    }
  }
}
