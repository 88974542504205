<div class="client-statements">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple icon="fa fa-play-circle" label="Fetch Data" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="getData();">
            </p-button>
        </span>
        <div class="p-grid p-mx-2">
            <div class="p-col-12">
                <div class="card p-mb-1 p-pl-5 p-py-3">
                    <label class="label-text p-mr-4 required-field">Query : </label>
                    <div>
                        <textarea rows="5" cols="100" pInputTextarea autoResize="autoResize"
                            [(ngModel)]="query"></textarea>
                    </div>
                </div>
                <div class="card p-mb-1 p-pl-5 p-py-3">
                    <label class="label-text p-mr-4">Response : </label>
                    <div>
                        <textarea rows="15" cols="200" pInputTextarea autoResize="autoResize"
                            [(ngModel)]="responseData"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>