import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { Table } from 'primeng/table';
declare let numeral: any;
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html'
})
export class EducationComponent implements OnInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  public selectedUserNameStr: any;
  public myEducationForm: UntypedFormGroup;
  public isFormValid: boolean = false;
  public dialogDisplay: boolean = false;
  public title: any = '';
  public videoDisplay: boolean = false;
  educationDataSources: any = [];
  public StaffID: number = null;
  public totalNumberOfHrs: number = 0;

  constructor(private router: Router, private _fb: UntypedFormBuilder, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService,public translate: TranslateService) {
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.selectedUserName);
    this.StaffID = this.encrDecSrvc.getObject(AppConstants.usersID);
    
    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('user.user_list')},
        { label: this.translate.instant('education_cpe') },
        { label: this.selectedUserNameStr, icon: 'ic-red'}
      ]);
    })
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.educationRoutePath + '_' + AppConstants.SearchString);
    this.initializeUserForm();
  }

  ngOnInit(): void {
    this.loadUsersEducationData();
  }

  formatHrs() {
    const formObj = this.myEducationForm.value['HoursCompleted'];
    const str = numeral(formObj).value();
    if (str) {
      this.myEducationForm.controls['HoursCompleted'].setValue(str);
    } else {
      this.myEducationForm.controls['HoursCompleted'].setValue('00.00');
    }
  }

  /* Loading Users Education data */
  loadUsersEducationData() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getEducationData(this.StaffID).subscribe((EducationData:any) => {
      this.educationDataSources = EducationData;
      this.educationDataSources.map(function (obj) { obj.HoursCompleted = numeral(obj.HoursCompleted).format('0.00'); return obj; });
      const totals = this.educationDataSources.reduce(function (a, b) { return a + +b.HoursCompleted; }, 0);
      this.totalNumberOfHrs = numeral(totals.toFixed(2)).format('0.00');
      this.loadFilterGrid();
      this.mangoAPISrvc.showLoader(false);
    }, error => {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    });
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.educationRoutePath + '_' + AppConstants.SearchString, "");
    this.getFilteredData();
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.educationRoutePath + '_' + AppConstants.SearchString, obj.filters.global?.value);
    this.getFilteredData();
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  redirectToUserList() {
    this.encrDecSrvc.addObject(AppConstants.usersID, -1);
    this.router.navigate([AppConstants.users + '/' + AppConstants.listRoutePath]);
    setTimeout(() => {
      this.mangoAPISrvc.fireUserView(true);
    }, 100);
  }

  ngOnDestroy() {
    if (this.isFormValid) {
      this.saveUsersEducationData();
    }
  }

  initializeUserForm() {
    this.myEducationForm = this._fb.group({
      CertificationType: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      ClassDescription: ['', [<any>Validators.required, <any>Validators.maxLength(70)]],
      Comments: ['', [<any>Validators.maxLength(150)]],
      DateCompleted: ['', [<any>Validators.required]],
      HoursCompleted: ['', [<any>Validators.required, <any>Validators.maxLength(12)]],
      StaffCertificationExpirationDate: [''],
      StaffCertificationsID: [''],
      YearCompleted: ['']
    });

    this.myEducationForm.valueChanges.subscribe(data => {
      this.validateForm();
    })
  }

  /* Saving Users Education data */
  saveUsersEducationData(dialogAction = false) {
    const obj = this.myEducationForm.value;
    this.isFormValid = false;
    const resetForm = function (_this){
      _this.myEducationForm.markAsPristine();
      _this.myEducationForm.reset();
      _this.myEducationForm.markAsUntouched();
      _this.myEducationForm.updateValueAndValidity();
      _this.myEducationForm.controls['DateCompleted'].setValue(new Date());
    }
    // Edit Flow
    if (obj['StaffCertificationsID']) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateEducationData(obj['StaffCertificationsID'], obj).subscribe((data:any) => {
        if (data.message) {
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
          this.loadUsersEducationData();
          this.dialogDisplay = dialogAction;
          resetForm(this);
          this.mangoAPISrvc.showLoader(false);
        }
      }, error => {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      });
    } else {
      obj['HoursCompleted'] = (obj['HoursCompleted']) ? numeral(obj['HoursCompleted']).format('0.00') : null;
      obj['YearCompleted'] = (obj['DateCompleted']) ? (new Date(obj['DateCompleted']).getFullYear()) : null;
      obj['StaffCertificationsID'] = (obj['StaffCertificationsID']) ? parseInt(obj['StaffCertificationsID']) : null;
      obj['StaffCertificationExpirationDate'] = (obj['StaffCertificationExpirationDate']) ? (obj['StaffCertificationExpirationDate']) : null;
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createEducationDataUser(this.StaffID, obj).subscribe((data:any) => {
        if (data.message) {
          this.dialogDisplay = dialogAction;
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.createMsg);
          this.loadUsersEducationData();
          resetForm(this);
          this.myEducationForm.controls['DateCompleted'].setValue(new Date());
          this.mangoAPISrvc.showLoader(false);
        }
      }, error => {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      });
    }
  }

  /* Edit User Education data */
  editUsersEducationData(event, itemData: any) {
    this.title = 'Edit';
    if (itemData == null) {
      itemData = event.data;
    }
    if (itemData) {
      /* default values */
      this.myEducationForm.controls['CertificationType'].setValue(itemData['CertificationType']);
      this.myEducationForm.controls['ClassDescription'].setValue(itemData['ClassDescription']);
      this.myEducationForm.controls['Comments'].setValue(itemData['Comments']);
      this.myEducationForm.controls['HoursCompleted'].setValue(parseFloat(itemData['HoursCompleted']).toFixed(2));
      this.myEducationForm.controls['StaffCertificationsID'].setValue(itemData['StaffCertificationsID']);
      this.myEducationForm.controls['YearCompleted'].setValue(itemData['YearCompleted']);

      itemData['DateCompleted'] = (itemData['DateCompleted']) ? new Date(itemData['DateCompleted']) : itemData['DateCompleted'];
      this.myEducationForm.controls['DateCompleted'].setValue(itemData['DateCompleted']);

      itemData['StaffCertificationExpirationDate'] = (itemData['StaffCertificationExpirationDate']) ? new Date(itemData['StaffCertificationExpirationDate']) : itemData['StaffCertificationExpirationDate'];
      this.myEducationForm.controls['StaffCertificationExpirationDate'].setValue(itemData['StaffCertificationExpirationDate']);
    }
    this.dialogDisplay = true;
  }


  /* Create User Education data */
  createUsersEducationData() {
    this.title = 'Add';
    this.myEducationForm.controls['CertificationType'].setValue('');
    this.myEducationForm.controls['ClassDescription'].setValue('');
    this.myEducationForm.controls['Comments'].setValue('');
    this.myEducationForm.controls['HoursCompleted'].setValue('');
    this.myEducationForm.controls['StaffCertificationsID'].setValue('');
    this.myEducationForm.controls['YearCompleted'].setValue('');
    this.myEducationForm.controls['DateCompleted'].setValue(new Date());
    this.myEducationForm.controls['StaffCertificationExpirationDate'].setValue('');
    this.dialogDisplay = true;

  }

  /*
    Verifing the form
  */
  validateForm() {
    let isInValidData = false;
    Object.keys(this.myEducationForm.controls).forEach(key => {
      if (this.myEducationForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData && this.myEducationForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  /* Delete User Education data */
  deleteUsersEducationData(event: any, id) {
    event.stopPropagation();
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      html: parent.translate.instant('default_email_changed_warning'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_continue'),
      cancelButtonText: parent.translate.instant('no_cancel')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteEducationData(id).subscribe((data:any) => {
          if (data.message) {
            this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.deleteMessage);
          }
          this.loadUsersEducationData();
          this.mangoAPISrvc.showLoader(false);
        }, error => {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.deleteErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }
  /*
  Total Values
 */
  getFilteredData() {
    const temp = this.dataTableComponent.filteredValue? this.dataTableComponent.filteredValue : this.dataTableComponent._value;
    const totals = temp.reduce(function (a, b) { return a + +b.HoursCompleted; }, 0);
    this.totalNumberOfHrs = numeral(totals.toFixed(2)).format('0.00');
  }
}
