<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">
    <div class="login-content">
        <div class="login-panel">
            <div class="login-panel-content">
                <div>
                    <img src="assets/mango/img/MangoLogo.png" alt="Welcome to Mango Practice Management" />
                </div>

                <div>Reset Password</div>

                <form [formGroup]="resetPasswordForm" class="p-mt-5">
                    <div class="p-fluid p-text-left">
                        <!-- Email -->
                        <div class="p-field">
                            <label for="username" class="label-text">Email</label>
                            <input pInputText type="text" autocomplete="new-password" formControlName="email" placeholder="Email Address" name="email" disabled>
                            <div *ngIf="resetPasswordForm.controls['email'].touched">
                                <small *ngIf="resetPasswordForm.controls['email'].hasError('pattern')"
                                    class="p-invalid p-mt-1">
                                    Email is invalid
                                </small>
                                <small *ngIf="resetPasswordForm.controls['email'].hasError('required')"
                                    class="p-invalid p-mt-1">
                                    Email is required
                                </small>
                            </div>
                        </div>

                        <!-- Password -->
                        <div class="p-field">
                            <label for="username" class="label-text">Password</label>
                            <input pInputText type="password" autocomplete="new-password" formControlName="password" placeholder="Password"
                            name="password">
                            <div *ngIf="resetPasswordForm.controls['password'].touched">
                                <small *ngIf="resetPasswordForm.controls['password'].hasError('required')"
                                    class="p-invalid p-mt-1">
                                    Password is required
                                </small>
                            </div>
                        </div>

                        <!-- Confirm -->
                        <div class="p-field">
                            <label for="username" class="label-text">Confirm</label>
                            <input pInputText type="password" autocomplete="new-password" placeholder="Confirm Password" name="confirmpassword"
                                formControlName="confirmPassword">
                            <div *ngIf="resetPasswordForm.controls['confirmPassword'].touched">
                                <small *ngIf="resetPasswordForm.hasError('passwordNotEqual')" class="p-invalid p-mt-1">
                                    Password do not match
                                </small>
                            </div>
                        </div>

                        <div class="p-d-flex p-jc-center p-ai-center">
                            <p-button pRipple label="Submit" class="p-button-help p-mr-2" tooltipPosition="top" pTooltip="Submit"
                                [disabled]="!resetPasswordForm.valid || !isEmailExists" (click)="reset()"></p-button>
                            <p-button pRipple type="button" label="Cancel" routerLink="['/login']"
                                class="p-button-help p-ml-2" tooltipPosition="top" pTooltip="Cancel"></p-button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>