<li class="p-px-3 p-pt-2 layout-submenu-item" *ngFor="let task of tasksList; let i = index;">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-6 p-d-flex p-jc-start timer-padding">
            <!-- <i title="{{'mgmt-delete' | translate}}" tooltipPosition="top"
                class="fal fa-trash-alt ic-red cursor-hand ic-size-18 p-mr-3"
                (click)="convertToTimeEntry(task.id);$event.stopPropagation();"></i> -->

            <i pTooltip="{{'timer.edit-timer' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                class="fal fa-pencil ic-green cursor-hand ic-size-18 p-mr-3" (click)="editTask(task.id);$event.stopPropagation();"></i>
            <i pTooltip="{{'mgmt-delete' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                class="fal fa-trash-alt ic-red cursor-hand ic-size-18 p-mr-3" (click)="deleteTimer(task?.TimerID);$event.stopPropagation();"></i>
            <i pTooltip="{{'save_button' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" class="fal fa-save cursor-hand ic-blue ic-size-18 p-mr-3"
                (click)="convertToTimeEntry(task.id);$event.stopPropagation();"></i>
            <i *ngIf="!task.isStarted" class="fal fa-play cursor-hand ic-size-18 ic-green" pTooltip="{{'Start' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                (click)="startTimer(task.id, true, true);$event.stopPropagation();"></i>
            <i *ngIf="task.isStarted" class="fal fa-pause cursor-hand  ic-red ic-size-18" pTooltip="{{'pause' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                (click)="stopTimer(task.id,false,true);$event.stopPropagation();"></i>
        </div>
        <div class="p-col-6">
            <div class="p-text-right">
                <span class="text-nowrap ic-size-25 cursor-hand">{{task.DisplayTime}}</span>
            </div>
        </div>
        <div class="p-col-12 p-text-nowrap p-text-left p-text-truncate timer-border-bottom p-pt-2" pTooltip="{{task.ClientName}} / {{task.ServiceDesc}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800">
            {{task.ClientName}} / {{task.ServiceDesc}}
        </div>
    </div>
</li>
<li class="p-p-2">
    <p-button class="p-mx-2 p-mt-2" icon="fal fa-plus" label="{{ 'timer.add-timer' | translate }}" (click)="openTimerModal()"></p-button>
    <p-button *ngIf="tasksList?.length > 0" class="p-mx-2 p-mt-2" icon="fal fa-save" label="{{ 'Save-All' | translate }}" (click)="saveAllOpenTimers(tasksList);$event.stopPropagation();"></p-button>
</li>
