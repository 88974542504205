<div class="retainer-invoice">
  <div class="layout-content p-pt-0">
    <span class="pull-right p-mr-3 clientform-btn-top">
      <button
        pButton
        pRipple
        label="{{ 'save_button' | translate }}"
        icon="fas fa-check"
        class="p-button-sm p-px-2 p-mr-2"
        [disabled]="IsEnableSave"
        (click)="saveData()"
      ></button>
      <button
        pButton
        pRipple
        label="{{ 'clear' | translate }}"
        icon="fad fa-eraser"
        class="p-button-sm p-px-2 p-mr-2"
        [disabled]="IsEnableSave"
        (click)="clearData()"
        tooltipPosition="top"
        pTooltip="{{ 'clear' | translate }}"
      ></button>
      <button
        pButton
        pRipple
        label="{{ 'billing-invoicing.invoice-history' | translate }}"
        icon="fal fa-check"
        class="p-button-sm p-px-2 p-mr-2"
        [disabled]="IsEnableHistory"
        (click)="onInvoiceHistoryClick()"
      ></button>
    </span>
    <div class="p-fluid p-formgrid p-grid p-mx-2">
      <div class="p-col-6">
        <div
          class="box manual-invoice-mh"
          [ngClass]="timeAndExpense.ActivateLaborRates ? 'retainer-invoice-mh' : ''"
        >
          <div class="p-field p-col-12">
            <label class="required-field label-text p-col-12">
              <span class="pull-left">{{ "client.name" | translate }}</span>
            </label>
            <p-autoComplete
              [(ngModel)]="selClient"
              [ngModelOptions]="{ standalone: true }"
              [suggestions]="filteredClients"
              autocomplete="off"
              (completeMethod)="filterClients($event)"
              field="ClientName"
              (onSelect)="handleSelectClick($event.value)"
              ngDefaultControl
              placeholder="{{ 'Search-Clients' | translate }}"
              (onFocus)="$event.target.select()"
              [dropdown]="true"
              required
            >
              <ng-template let-client pTemplate="item">
                <div>
                  {{ client.ClientName
                  }}{{ client.ClientNumber ? " - " + client.ClientNumber : "" }}
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="p-field p-col-12" *ngIf="clientProfile">
            <div class="p-ml-1">{{ clientProfile.BusStreet1 }}</div>
            <div class="p-ml-1" *ngIf="clientProfile.BusStreet2">
              {{ clientProfile.BusStreet2 }}
            </div>
            <div class="p-ml-1">
              <span *ngIf="clientProfile.BusCity">{{ clientProfile["BusCity"] }}, </span>
              <span *ngIf="clientProfile.BusState">{{ clientProfile["BusState"] }}, </span>
              <span *ngIf="clientProfile.BusZip">{{ clientProfile["BusZip"] }}</span>
            </div>
          </div>
          <div class="p-field p-col-6" *ngIf="isSalesTaxOn"></div>
        </div>
      </div>
      <div class="p-col-6">
        <div
          class="box manual-invoice-mh p-pb-1"
          [ngClass]="timeAndExpense.ActivateLaborRates ? 'retainer-invoice-mh' : ''"
        >
          <div class="p-formgroup-inline">
            <div class="p-field p-col-6">
              <label class="label-text required-field">{{
                "Accounts.invoice-date" | translate
              }}</label>
              <p-calendar
                [monthNavigator]="true"
                [yearNavigator]="true"
                (ngModelChange)="validateForm()"
                (onSelect)="onSelectDate(); verifySystemLocking($event)"
                yearRange="2000:2090"
                [(ngModel)]="InvoiceDate"
                showButtonBar="true"
              ></p-calendar>
            </div>
            <div class="p-field p-col-6">
              <label class="label-text">{{ "Accounts.invoice-number" | translate }}</label>
              <input
                type="text"
                class="p-text-right"
                pInputText
                [ngModelOptions]="{ standalone: true }"
                [disabled]="true"
                [(ngModel)]="nextInvoiceNumber"
              />
            </div>
            <div class="p-field p-col-6">
              <label class="label-text required-field">{{ "Retainer-Amount" | translate }}</label>
              <input
                type="text"
                pInputText
                required
                [(ngModel)]="InvoiceAmount"
                maxlength="12"
                (blur)="changeAmount()"
                (focus)="$event.target.select()"
                class="p-text-right p-d-block p-text-bold"
              />
            </div>
            <div class="p-field p-col-6">
              <label class="label-text">{{ "FinalizeAction" | translate }}</label>
              <p-dropdown [options]="finalizeActions" [(ngModel)]="FinalizeAction"></p-dropdown>
            </div>
            <div class="p-field p-col-6" *ngIf="timeAndExpense.ActivateLaborRates">
              <p-checkbox
                [binary]="true"
                label="{{ 'calculate-sales-tax' | translate }}"
                [(ngModel)]="isSalesTaxOn"
                (onChange)="showSalesTaxAmount($event)"
                class="p-text-left p-d-block p-text-bold label-text p-mt-3"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-6" *ngIf="isSalesTaxOn">
              <label class="label-text">{{ "sales-tax-amount" | translate }}</label>
              <input
                type="text"
                pInputText
                class="p-text-right p-d-block p-text-bold"
                [(ngModel)]="salesTaxAmount"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid p-mx-2 p-mt-1">
      <div class="p-col-6">
        <div class="box">
          <label class="label-text required-field">
            {{ "billing.Invoice-Memo" | translate }}
            <i
              pTooltip="{{ 'billing.invoice-description-tooltip' | translate }}"
              tooltipStyleClass="tooltip-width-800"
              tooltipPosition="right"
              class="fa fa-question-circle ic-blue question-tooltip-icon"
              aria-hidden="true"
            ></i>
          </label>
          <div class="p-mt-2">
            <app-mentions [rows]="6" [(inputText)]="DescriptionShort" (inputTextChange)="validateForm()"
                style="width:100% !important; resize: vertical;"></app-mentions>
          </div>
        </div>
      </div>
      <div class="p-col-6">
        <div class="box">
          <label class="label-text required-field">
            {{ "billing.Invoice-Description" | translate }}
            <i
              pTooltip="{{ 'billing.invoice-description-tooltip' | translate }}"
              tooltipStyleClass="tooltip-width-800"
              tooltipPosition="right"
              class="fa fa-question-circle ic-blue question-tooltip-icon"
            ></i>
          </label>
          <span class="p-ml-2" (click)="onCopyMemo()">
            <i
              pTooltip="{{ 'billing.invoice-copy-memo' | translate }}"
              tooltipStyleClass="tooltip-width-800"
              tooltipPosition="right"
              class="fa fa-copy ic-grey p-mr-3"
            ></i>
          </span>
          <div class="p-mt-2">
            <app-mentions [rows]="6" [(inputText)]="InvoiceMemo" (inputTextChange)="validateForm()"
                style="width:100% !important; resize: vertical;"></app-mentions>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  [(visible)]="historyDisplay"
  [style]="{ width: '58vw' }"
  [modal]="true"
  [maximizable]="false"
  showEffect="fade"
  [baseZIndex]="10000"
  [draggable]="true"
  [resizable]="false"
  header="{{ selectedHeaderText }}"
>
  <p-table
    [rowHover]="true"
    [responsive]="true"
    [rows]="7"
    [value]="historyDataSource"
    [showCurrentPageReport]="true"
    [pageLinks]="3"
    [filterDelay]="0"
    [scrollHeight]="'calc(90vh - 360px)'"
    [scrollable]="true"
    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
    dataKey="InvoiceNumber"
    [globalFilterFields]="['InvoiceDate', 'InvoiceNumber', 'InvoiceAmount', 'DescriptionShort']"
    (onFilter)="onFilter($event)"
    sortField="InvoiceDate"
    [sortOrder]="-1"
    #historydt
  >
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="p-input-icon-left">
          <i class="fal fa-search"></i>
          <input
            #searchValue
            pInputText
            type="text"
            (input)="historydt.filterGlobal($event.target.value, 'contains')"
            placeholder="{{ 'globalsearch' | translate }}"
          />
        </span>
        <span>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="historydt.reset(); clearSearchFilter()"
            tooltipPosition="top"
            pTooltip="{{ 'clear' | translate }}"
          ></button>
        </span>
        <p-message
          *ngIf="filteredItemsSize > -1"
          severity="success"
          text="{{ filteredItemsSize }} {{ 'records_found' | translate }}."
          styleClass="p-ml-2"
        ></p-message>
      </div>
    </ng-template>
    <!-- Table Header -->
    <ng-template pTemplate="header">
      <tr>
        <th class="p-text-center width-7p" pSortableColumn="InvoiceDate">
          {{ "Accounts.invoice-date" | translate }}
          <p-sortIcon field="InvoiceDate"></p-sortIcon>
        </th>
        <th class="p-text-center width-7p" pSortableColumn="InvoiceNumber">
          {{ "Invoice" | translate }} #
          <p-sortIcon field="InvoiceNumber"></p-sortIcon>
        </th>
        <th class="p-text-center width-20p">
          {{ "invoice-memo" | translate }}
        </th>
        <th class="p-text-right width-10p" pSortableColumn="InvoiceAmount">
          {{ "Accounts.invoice-amount" | translate }}
          <p-sortIcon field="InvoiceAmount"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <!-- Rows -->
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="p-text-center">
          {{ mangoUtils.formatDateString(rowData.InvoiceDate) | date: "MM/dd/yyyy" }}
        </td>
        <td class="p-text-center">
          {{ rowData.InvoiceNumber }}
        </td>
        <td
          class="p-text-left p-text-nowrap p-text-truncate"
          showDelay="800"
          tooltipPosition="top"
          pTooltip="{{ rowData.DescriptionShort }}"
          tooltipStyleClass="tooltip-no-wrap"
        >
          {{ rowData.DescriptionShort }}
        </td>
        <td class="p-text-right">${{ rowData.InvoiceAmount }}</td>
      </tr>
    </ng-template>
    <!-- No data -->
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="4">
          <p-message
            severity="info"
            text="{{ 'data_not_found' | translate }}."
            styleClass="p-px-2"
          ></p-message>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      label="{{ 'cancel_button' | translate }}"
      (click)="historyDisplay = false"
    ></button>
  </p-footer>
</p-dialog>
