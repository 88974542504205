import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MangoApiService, EncrDecrService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { environment } from '@environments/environment';
import moment from 'moment';
import Swal from 'sweetalert2';
declare let $: any;
declare let FattJs: any;
declare let numeral: any;

@Component({
  selector: 'app-hosted-page',
  templateUrl: './hosted-page.component.html',
  styleUrls: ['./hosted-page.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HostedPageComponent implements OnInit {
  public isInvalidToken = false;
  public showClients = false;
  public isApiLoaded = false;
  public nativeWindow: any;
  public companyData = null;
  public imageUrl: any = null;
  public selClient: any = null;
  public modeOfPayment;
  public cardOptionsList: any = [];
  public selectedItems: any = [];
  public allEmailContacts: any = [];
  public openInvoices: any = [];
  public paymentProfileData: any = {};
  public isSelctedPaymentIndex = -1;
  public cardProfile = null;
  public selectedModeOfPayment;
  public totalNumberAmt: number = 0;
  public isBusiness: string = 'false';
  public fattJs: any;
  public isSTaxEnabled: boolean = false;
  public isFullPayment: string = 'true';
  public transactionData: any = null;
  public myPaymentForm: UntypedFormGroup;
  public isStaxNewCard: boolean = false;
  public isProcessing: boolean = false;
  public isCrCard: boolean = false;
  public isDialogFormValid: boolean = false;
  public transCodeTypes: any = [];
  public invoicesList: any = [];
  public isPaymentCompleted: boolean = false;
  public paidAmount: any = 0;
  public token: any = 0;
  public isMobileResolution: boolean;
  public currentInvoiceBalance: any = null;
  public AllUsers: any = [];
  public isDebitCard: boolean = false;
  cashReceiptEmailTemplate = null;
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  clientEmail: any = '';
  clientPasscode: any = '';
  public isEmailProvided: boolean = false;
  public isValidServerCode: boolean = false;
  public isStatementFlow: boolean = false;
  public clients: any = [];
  public selectedClient: any;
  public superAdminVisible: number[] = AppConstants.superAdminVisible;
  public PassWordOtp;
  public merchantResponse;
  public requiredcardFields = [
    'expDate',
    'FirstName',
    'LastName',
    'Address1',
    'City',
    'State',
    'Zip'
  ];

  public requiredACHFields = [
    'RoutingNumber',
    'AccountNumber',
    'TransCode',
    'FirstName',
    'LastName'
  ];

  public allrequiredFields = [
    'RoutingNumber',
    'AccountNumber',
    'expDate',
    'TransCode',
    'FirstName',
    'LastName'
  ];
  public processData = {
    Amount: null,
    ClientID: null,
    CompanyID: null,
    PaymentType: null,
    PaymentNote: null,
    BillingPartnerID: null,
    OriginatingPartnerID: null,
    StaffAssignedID: null,
    GroupDescriptionIDArray: null,
    GroupDescriptionID: null,
    ClientTypeID: null,
    BillingHeaderID: null,
    InvoiceType: null,
    invoices: []
  };


  constructor(public mangoUtils: mangoUtils, private _fb: UntypedFormBuilder, public translate: TranslateService, private _router: Router, private activatedRoute: ActivatedRoute, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService) {
    ///this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.mangoAPISrvc.notifyLogging(false);
    this.transCodeTypes = [
      { label: 'None', value: null },
      { label: 'Checking', value: 27 },
      { label: 'Savings', value: 37 }
    ];
  }

  onResize(event) {
    setTimeout(() => {
      if ($('.card').width()) {
        if ($('.card').width() < 768) {
          this.isMobileResolution = true;
        } else {
          console.log($('.card').width());
          this.isMobileResolution = false;
        }
      }
    }, 1500);
  }

  ngOnInit(): void {
    const parent = this;
    this.activatedRoute.params.subscribe(params => {
      parent.token = params.token;
      this.fetchDataFromToken(params.token);
    });
    this.initializeForm();
  }

  fetchDataFromToken(token) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getHostedPaymentDetailsByToken(token).subscribe(
      (data: any) => {
        self.companyData = data;
        self.cashReceiptEmailTemplate = data?.emailTemplate;
        self.isInvalidToken = data.company ? true : false;
        self.imageUrl = data.company ? data.company.CompanyLogoWeb : null;
        self.isStaxNewCard = true;
        if (data.company) {
          self.mangoAPISrvc.showLoader(false);
          self.getCustomerPortalSettings();
          self.checkStaxEnable();
          self.getAllStaff();
        } else {
          self.mangoAPISrvc.showLoader(false);
          self.isApiLoaded = true;
        }
      },
      err => {
        self.imageUrl = null;
        self.isApiLoaded = true;
        self.isInvalidToken = false;
        self.mangoAPISrvc.showLoader(false);
        this.onResize({});
      }
    );
  }

  getEmailcontactsList(clientId) {
    const parent = this;
    parent.allEmailContacts = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailcontactsByClientID(clientId).subscribe((response: any) => {
      parent.allEmailContacts = response.filter(item => item.isClientPortalAccess == true);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  checkStaxEnable() {
    const parent = this;
    if (parent.companyData.company.isStMIdPresents) {
      parent.mangoAPISrvc.showLoader(true);
      parent.myPaymentForm.controls['CompanyID'].setValue(parent.companyData.company.CompanyID);
      parent.myPaymentForm.controls['Amount'].setValue(parent.companyData.company.InvoiceBalance);

      parent.mangoAPISrvc
        .performStaxClickPayActions(
          { isFullData: true },
          parent.companyData.company.CompanyID,
          'lrv'
        )
        .subscribe(
          async (response: any) => {
            parent.isApiLoaded = true;
            parent.onResize({});
            parent.mangoAPISrvc.showLoader(false);
            if (
              response &&
              response.underwriting_status != 'PENDED' &&
              parent.companyData.company.StaxStatus == 'ACTIVE'
            ) {
              parent.isSTaxEnabled = true;
              parent.merchantResponse = response;
              let email = parent.companyData.company.Email
                ? parent.companyData.company.Email
                : response.business_email;
              let address = parent.companyData.company.BusStreet1
                ? parent.companyData.company.BusStreet1
                : response.business_address_1;
              let address2 = parent.companyData.company.BusStreet2
                ? parent.companyData.company.BusStreet2
                : response.business_address_2;
              let city = parent.companyData.company.BusCity
                ? parent.companyData.company.BusCity
                : response.business_address_city;
              let state = parent.companyData.company.BusState
                ? parent.companyData.company.BusState
                : response.business_address_state;
              let country = parent.companyData.company.BusCountry
                ? parent.companyData.company.BusCountry
                : response.business_address_country;
              let zip = parent.companyData.company.BusZip
                ? parent.companyData.company.BusZip
                : response.business_address_zip;
              let phNo = parent.companyData.company.Telephone
                ? parent.companyData.company.Telephone
                : parent.companyData.company.Mobile;
              phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
              phNo = phNo ? phNo : '555-555-5555';

              parent.myPaymentForm.controls['EmailAddress'].setValue(email);
              parent.myPaymentForm.controls['Address1'].setValue(address);
              parent.myPaymentForm.controls['Address2'].setValue(address2);
              parent.myPaymentForm.controls['City'].setValue(city);
              parent.myPaymentForm.controls['State'].setValue(state);
              parent.myPaymentForm.controls['Country'].setValue(country);
              parent.myPaymentForm.controls['Zip'].setValue(zip);
              parent.myPaymentForm.controls['phone'].setValue(phNo);

              let surchargeData = parent.companyData.cards.filter(item => item.TransType == 'CC');
              if (
                surchargeData &&
                parent.modeOfPayment == 0 &&
                surchargeData.length > 0 &&
                surchargeData[0] &&
                parent.companyData.company.isSurchargeEnabled &&
                parent.myPaymentForm.controls['Amount'].value > 0
              ) {
                let surchargeObj = await parent.getSurchargeAmount(
                  surchargeData[0].CustomerCardID,
                  parent.myPaymentForm.controls['Amount'].value,
                  parent.companyData.company.CompanyID,
                  'sc'
                );
                if (surchargeObj) {
                  parent.myPaymentForm.controls['SurChargeAmount'].setValue(
                    surchargeObj['surcharge_amount']
                  );
                  parent.myPaymentForm.controls['withSurcharge'].setValue(
                    surchargeObj['total_with_surcharge_amount']
                  );
                  parent.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
                }
              }
            } else {
              if (response && response.underwriting_status == 'PENDED') {
                parent.isSTaxEnabled = false;
                Swal.fire({
                  title: 'confirmation',
                  html: `<div>Your MangoPayments enrollment has some underwriting issues that require immediate attention!</div>`,
                  icon: 'warning',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  confirmButtonText: 'Review Now'
                }).then(result => {});
              } else {
                parent.isSTaxEnabled = false;
              }
            }
          },
          error => {
            this.mangoAPISrvc.showLoader(false);
          }
        );
    } else {
      parent.isApiLoaded = true;
      parent.onResize({});
      parent.mangoAPISrvc.showLoader(false);
      parent.isSTaxEnabled = false;
    }
  }

  async getCustomerPortalSettings() {
    const self = this;
    self.isSelctedPaymentIndex = -1;
    self.isCrCard = false;
    if (self.companyData.company && self.companyData.company.modeOfPayment == 4) {
      self.modeOfPayment =
        self.companyData.company.firm_modeofpayment != null
          ? self.companyData.company.firm_modeofpayment
          : 3;
    } else {
      self.modeOfPayment =
        self.companyData.company.modeOfPayment != null ? self.companyData.company.modeOfPayment : 3;
    }
    //self.selectedModeOfPayment = (self.modeOfPayment == 2) ? 0 : self.modeOfPayment;
    if (self.modeOfPayment == 0) {
      self.isSelctedPaymentIndex = 0;
      self.isCrCard = true;
      const card = self.companyData.cards.filter((item) => item.TransType == 'CC');
      self.cardProfile = card.length > 0 ? card[0] : null
      self.cardOptionsList.push({ label: 'Credit Card', value: 0 });
      self.genarateIFrames();
    } else if (self.modeOfPayment == 1) {
      self.isSelctedPaymentIndex = 1;
      const card = self.companyData.cards.filter((item) => item.TransType == 'ACH');
      self.cardProfile = card.length > 0 ? card[0] : null
      self.cardOptionsList.push({ label: 'ACH', value: 1 });
      self.fattJs = new FattJs(self.companyData.company.spkuid, {});
    } else if (self.modeOfPayment == 2) {
      self.cardOptionsList.push({ label: 'Select Payment Type', value: -1 });
      self.cardOptionsList.push({ label: 'Credit Card', value: 0 });
      self.cardOptionsList.push({ label: 'ACH', value: 1 });
      self.fattJs = new FattJs(self.companyData.company.spkuid, {});
    } else {
      self.cardOptionsList = [];
    }
  }

  getAllStaff() {
    this.mangoAPISrvc
      .getMobilePaymentsPMAllStaffList(this.companyData.company.CompanyID)
      .subscribe((allStaff: any) => {
        for (let i = 0; i < allStaff.length; i++) {
          if (allStaff[i].Inactive)
            continue;
          const obj = {};
          obj["StaffID"] = allStaff[i]["StaffID"];
          obj["StaffName"] = allStaff[i]["StaffName"];
          obj["StaffNumber"] = allStaff[i]["StaffNumber"];
          obj["Email"] = allStaff[i]["Email"];
          this.AllUsers.push(obj);
        }
      });
  }

  initializeForm() {
    const parent = this;
    parent.myPaymentForm = parent._fb.group({
      CompanyID: [''],
      BankAcctNo: [''],
      CardNo: [''],
      expDate: [''],
      NameOnCard: [''],
      CardType: [''],
      securityCode: [''],
      RoutingNumber: [''],
      AccountNumber: [''],
      TransCode: [null, [<any>Validators.required]],
      phone: [''],
      Amount: [0, [<any>Validators.required]],
      PaymentUnapplied: [0],
      PaymentApplied: [0],
      FirstName: [''],
      LastName: [''],
      EmailAddress: ['', [<any>Validators.required]],
      Address1: ['', [<any>Validators.required]],
      Address2: [''],
      City: ['', [<any>Validators.required]],
      State: ['', [<any>Validators.required]],
      Zip: ['', [<any>Validators.required]],
      memo: [''],
      Country: ['US'],
      SurChargeAmount: [0],
      withSurcharge: [0],
      isBusiness: [false]
    });

    this.myPaymentForm.valueChanges.subscribe(data => {
      this.validateDialogForm();
      this.isValidStaxForm();
    });
  }

  validateDialogForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myPaymentForm.controls).forEach(key => {
      if (this.myPaymentForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myPaymentForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && (this.cardProfile || this.isStaxNewCard)) {
      this.isDialogFormValid = true;
    } else {
      this.isDialogFormValid = false;
    }
  }

  isValidStaxForm() {
    const paymentFormData = this.myPaymentForm.value;
    this.isProcessing = false;
    if (
      (this.isCrCard &&
        this.isStaxNewCard &&
        (!paymentFormData.FirstName ||
          !paymentFormData.LastName ||
          !paymentFormData.Address1 ||
          !paymentFormData.City ||
          !paymentFormData.State ||
          !paymentFormData.Zip ||
          !paymentFormData.expDate)) ||
      numeral(paymentFormData.Amount).value() == 0
    ) {
      this.isProcessing = true;
    } else if (
      !this.isCrCard &&
      this.isStaxNewCard &&
      (!paymentFormData.RoutingNumber ||
        !paymentFormData.AccountNumber ||
        !paymentFormData.TransCode ||
        !paymentFormData.FirstName ||
        !paymentFormData.LastName ||
        numeral(paymentFormData.Amount).value() == 0)
    ) {
      this.isProcessing = true;
    }
  }

  async getSurchargeAmount(merchantId, Amount, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxClickPayActions({ ccid: merchantId, amount: Amount }, companyId, eventType)
          .subscribe(function (data) {
            data = data['bin_type'] ? data : null;
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  genarateIFrames() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      self.fattJs = new FattJs(self.companyData.company.spkuid, {
        number: {
          id: 'fattjs-number',
          placeholder: '0000 0000 0000 0000',
          format: 'prettyFormat',
          style:
            'width: 96%; height:90%; border-radius: 3px; border: 1px solid #ced4da; padding: .5em .5em; font-size: 91%;'
        },
        cvv: {
          id: 'fattjs-cvv',
          placeholder: '000',
          style:
            'width:92%; height:90%; border-radius: 3px; border: 1px solid #ced4da; padding: .5em .5em; font-size: 91%;'
        }
      });

      self.fattJs
        .showCardForm()
        .then(handler => {})
        .catch(err => {});

      self.fattJs.on('card_form_complete', message => {});

      self.fattJs.on('card_form_incomplete', message => {});
    }, 500);
  }

  isValidEmail(data) {
    this.isEmailProvided = false;
    this.clientPasscode = '';
    if (data.valid) {
      const reqObj = { email: this.clientEmail, token: this.token, companyID: this.companyData.company.CompanyID };
      const self = this;
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc.createHostedPaymentToken(reqObj).subscribe(
        (data: any) => {
          self.isEmailProvided = true;
          self.PassWordOtp = data.code;
          self.mangoAPISrvc.notify('info', 'Info!', data.message);
          self.mangoAPISrvc.showLoader(false);
        },
        err => {
          self.isEmailProvided = false;
          self.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      this.isEmailProvided = false;
    }
  }
  isValidPasscode() {
    const self = this;
    const reqObj = { email: this.clientEmail, token: this.token, companyID: this.companyData.company.CompanyID, passcode: this.clientPasscode };
    if (this.clientPasscode && this.clientPasscode && this.clientPasscode.length > 3) {
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc.isHostedPaymentTokenValid(reqObj).subscribe(
        (data: any) => {
          self.mangoAPISrvc.showLoader(false);
          if (!data.isValid) {
            self.mangoAPISrvc.notify('error', 'Error!', data.message);
          }
          self.isValidServerCode = data.isValid;
          self.selectedModeOfPayment = -1;
          self.isSelctedPaymentIndex = -1;

          self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
          self.myPaymentForm.controls['withSurcharge'].setValue(0);
          self.myPaymentForm.controls['FirstName'].setValue(null);
          self.myPaymentForm.controls['LastName'].setValue(null);
          self.myPaymentForm.controls['Address1'].setValue(null);
          self.myPaymentForm.controls['City'].setValue(null);
          self.myPaymentForm.controls['State'].setValue(null);
          self.myPaymentForm.controls['Country'].setValue(null);
          self.myPaymentForm.controls['Zip'].setValue(null);
          self.myPaymentForm.controls['RoutingNumber'].setValue(null);
          self.myPaymentForm.controls['AccountNumber'].setValue(null);

          for (let i = 0; i < data.clients.length; i++) {
            self.clients.push({ label: data.clients[i]['ClientName'], value: data.clients[i] });
          }

          if (data.clients?.length > 1) {
            self.clients.unshift({ label: 'Select Company', value: null });
          } else {
            self.selectedClient = self.clients[0];
            self.changeClientName();
          }
        },
        err => {
          self.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  changeClientName() {
    const self = this;
    self.companyData.invoices = [];
    self.companyData.selectedItems = [];
    self.companyData.cards = [];
    self.companyData.emails = [];
    self.allEmailContacts = [];
    self.selectedModeOfPayment = -1;
    self.selClient = self.selectedClient?.value;
    self.myPaymentForm.controls['Amount'].setValue(0);
    self.myPaymentForm.controls['PaymentApplied'].setValue(0);
    self.myPaymentForm.controls['PaymentUnapplied'].setValue(0);
    self.cardProfile = null;
    if (self.selectedClient && self.selectedClient.value?.ClientID) {
      self.mangoAPISrvc.showLoader(true);
      self.cardOptionsList = [];
      self.mangoAPISrvc.getOpenInvoices(self.selectedClient.value.ClientID).subscribe((data: any) => {
        data.invoices.map(function (item) { item.isChecked = false; });
        const totalsAmt = data.invoices.reduce(function (a, b) { return numeral(a).value() + numeral(b.InvoiceBalance).value(); }, 0);
        this.totalNumberAmt = numeral(totalsAmt).format('0,0.00');
        self.isStaxNewCard = (data.cards.length == 0) ? true : false;
        self.companyData.invoices = self.mangoUtils.sortByFiled(data.invoices, 'InvoiceDate');
        // if (self.companyData.invoices.length > 0) {
        //   //self.companyData.cards = data.cards;
        //   //self.companyData.emails = data.emails;
        //   //self.getCustomerPortalSettings();
        // }
        self.companyData.cards = data.cards;
        self.companyData.emails = data.emails;
        self.getCustomerPortalSettings();
        self.checkStaxEnable();
        self.getEmailcontactsList(self.selectedClient.value.ClientID);
        self.mangoAPISrvc.showLoader(false);
      }, (err) => {
        self.mangoAPISrvc.showLoader(false);
      });

    }
  }

  calculateAppliedSurcharge() {
    const processAmt = numeral(this.myPaymentForm.get("Amount").value).value();
    this.myPaymentForm.controls["PaymentUnapplied"].setValue(processAmt);
    this.calculateSurcharge();
  }

  onAmountChange(item) {

    const formObj = this.myPaymentForm.value;
    const unappliedAmt = numeral(formObj.PaymentUnapplied).value();
    const invoiceBal = numeral(item.InvoiceBalance).value();
    const totalPayments = numeral(item.TotalPayments).value();
    const paymentsapplied = numeral(item.PaymentsApplied).value();
    if (this.selectedModeOfPayment == -1) {
      Swal.fire({
        icon: 'error',
        text: 'Select Payment Type and Enter the amount',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timer: 7000
      });

      setTimeout(() => {
        item.isChecked = false;
        this.selectedItems = this.openInvoices.filter(item => item.isChecked == true);
      }, 50);
      return false;
    } else if (item.isChecked && unappliedAmt === 0) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        this.translate.instant('The Unapplied Balance is Zero. No Payment can be Applied.')
      );
      setTimeout(() => {
        item.isChecked = false;
        this.selectedItems = this.openInvoices.filter(item => item.isChecked == true);
      }, 50);
      return false;
    } else {
      this.selectedItems = this.openInvoices.filter(item => item.isChecked == true);
      if (item.isChecked) {
        const computedBalace = (invoiceBal - unappliedAmt);
        if (computedBalace >= 0) {
          item.PaymentsApplied = unappliedAmt;
          const valueStr = (unappliedAmt - numeral(item.PaymentsApplied).value()).toFixed(2);
          this.myPaymentForm.controls['PaymentUnapplied'].setValue(valueStr);
          item.InvoiceBalance = numeral((invoiceBal - unappliedAmt).toFixed(2)).format('0,0.00');
          item.TotalPayments = numeral((totalPayments + unappliedAmt).toFixed(2)).format('0,0.00');
        } else {
          item.PaymentsApplied = invoiceBal;
          const valueStr = Math.abs(numeral(computedBalace).value()).toFixed(2);
          this.myPaymentForm.controls['PaymentUnapplied'].setValue(valueStr);
          item.InvoiceBalance = numeral().format('0,0.00');
          item.TotalPayments = numeral((totalPayments + invoiceBal).toFixed(2)).format('0,0.00');
        }
      } else {
        item.PaymentsApplied = 0;
        const valueStr = (unappliedAmt + paymentsapplied).toFixed(2);
        this.myPaymentForm.get('PaymentUnapplied').setValue(valueStr);
        item.InvoiceBalance = numeral((invoiceBal + paymentsapplied).toFixed(2)).format('0,0.00');
        item.TotalPayments = numeral((totalPayments - paymentsapplied).toFixed(2)).format('0,0.00');
      }
      this.applytoInvoices();
    }
  }

  async getCardType(customerCardID, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxClickPayActions({ ccid: customerCardID }, companyId, eventType)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  addCardValidators(requiredcardFields) {
    for (let i = 0; i < this.allrequiredFields.length; i++) {
      const fieldName = this.allrequiredFields[i];
      if (requiredcardFields.indexOf(fieldName) > -1) {
        this.myPaymentForm.controls[fieldName].setValidators([Validators.required]);
      } else {
        this.myPaymentForm.controls[fieldName].setValidators([]);
      }
      this.myPaymentForm.get(fieldName).updateValueAndValidity();
    }
  }

  focusCard(index, element) {
    const iframeRef = $('iframe')[index];
    $(iframeRef).focus();
    $(iframeRef).contents().find(`#${element}`).focus();
  }

  async changePaymentType() {
    this.isBusiness = 'false';
    this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
    this.myPaymentForm.controls['withSurcharge'].setValue(0);
    this.myPaymentForm.controls['FirstName'].setValue(null);
    this.myPaymentForm.controls['LastName'].setValue(null);
    this.myPaymentForm.controls['expDate'].setValue(null);
    this.isDebitCard = false;
    const type = this.selectedModeOfPayment > -1 ? ((this.selectedModeOfPayment == 0) ? 'CC' : 'ACH') : null;
    this.isCrCard = (type == "CC") ? true : false;
    this.isSelctedPaymentIndex = this.selectedModeOfPayment;
    if (this.isSelctedPaymentIndex == -1) {
      this.myPaymentForm.controls['Amount'].setValue(0);
    }
    //this.myPaymentForm.controls['Amount'].setValue(0);
    // Company flow
    if (!this.isEmailProvided && !this.isValidServerCode) {
      const parent = this;
      parent.genarateIFrames();
      parent.cardProfile = null;
      parent.isStaxNewCard = true;

      parent.myPaymentForm.controls['Address1'].setValue(null);
      parent.myPaymentForm.controls['City'].setValue(null);
      parent.myPaymentForm.controls['State'].setValue(null);
      parent.myPaymentForm.controls['Country'].setValue(null);
      parent.myPaymentForm.controls['Zip'].setValue(null);
      if (type == 'CC') {
        this.addNewStax('card');
      } else {
        const address = parent.companyData.company.BusStreet1 ? parent.companyData.company.BusStreet1 : parent.merchantResponse.business_address_1;
        const city = parent.companyData.company.BusCity ? parent.companyData.company.BusCity : parent.merchantResponse.business_address_city;
        const state = parent.companyData.company.BusState ? parent.companyData.company.BusState : parent.merchantResponse.business_address_state;
        const country = parent.companyData.company.BusCountry ? parent.companyData.company.BusCountry : parent.merchantResponse.business_address_country;
        const zip = parent.companyData.company.BusZip ? parent.companyData.company.BusZip : parent.merchantResponse.business_address_zip;

        parent.myPaymentForm.controls['Address1'].setValue(address);
        parent.myPaymentForm.controls['City'].setValue(city);
        parent.myPaymentForm.controls['State'].setValue(state);
        parent.myPaymentForm.controls['Country'].setValue(country);
        parent.myPaymentForm.controls['Zip'].setValue(zip);

        this.addNewStax('bank');
      }
    } else {
      if (!this.selectedClient) {
        this.selectedModeOfPayment = -1;
        this.isSelctedPaymentIndex = -1;
      } else {
        this.isStaxNewCard = false;
        const card = this.companyData.cards.filter((item) => item.TransType == type);
        this.cardProfile = card.length > 0 ? card[0] : null;
        this.isDialogFormValid = this.cardProfile ? true : false;

        if (this.cardProfile == null) {
          this.genarateIFrames();
          if (type == 'CC') {
            this.myPaymentForm.controls['Address1'].setValue(null);
            this.myPaymentForm.controls['City'].setValue(null);
            this.myPaymentForm.controls['State'].setValue(null);
            this.myPaymentForm.controls['Country'].setValue(null);
            this.myPaymentForm.controls['Zip'].setValue(null);
            this.addNewStax('card');
          } else {
            this.addNewStax('bank');
          }
        } else {
          if (this.cardProfile && this.cardProfile.CustomerCardID) {
            this.isBusiness = (!this.isCrCard && this.cardProfile.isBusiness == true) ? 'true' : 'false';
            const cardTypeObj = await this.getCardType(this.cardProfile.CustomerCardID, this.companyData.company.CompanyID, 'ct');
            this.isDebitCard = (cardTypeObj['bin_type'] == 'DEBIT') ? true : false;
            if (!this.cardProfile['CardType']) {
              if (type == 'CC') {
                this.cardProfile['CardType'] = cardTypeObj['card_type']
                  ? cardTypeObj['card_type']
                  : null;
              } else {
                this.cardProfile['CardType'] = cardTypeObj['bank_type']
                  ? cardTypeObj['bank_type']
                  : null;
              }
              this.mangoAPISrvc
                .updateCreditCardDetails(this.cardProfile.CustomerCardID, this.cardProfile)
                .subscribe((resultRes: any) => {});
            }
          }

          if (this.isCrCard && this.cardProfile) {
            if (
              this.cardProfile &&
              this.companyData.company.isSurchargeEnabled &&
              this.myPaymentForm.controls['Amount'].value > 0
            ) {
              this.mangoAPISrvc.showLoader(true);
              const surchargeObj = await this.getSurchargeAmount(this.cardProfile.CustomerCardID, this.myPaymentForm.controls['Amount'].value, this.companyData.company.CompanyID, 'sc');
              this.mangoAPISrvc.showLoader(false);
              if (surchargeObj) {
                this.myPaymentForm.controls['SurChargeAmount'].setValue(
                  surchargeObj['surcharge_amount']
                );
                this.myPaymentForm.controls['withSurcharge'].setValue(
                  surchargeObj['total_with_surcharge_amount']
                );
                this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
              }
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.isDialogFormValid = true;
    }, 200);
  }

  addNewStax(type) {
    this.isStaxNewCard = true;
    this.isCrCard = false;
    this.isDebitCard = false;
    if (type == 'card') {
      this.isCrCard = true;
      this.addCardValidators(this.requiredcardFields);
      this.myPaymentForm.controls['Address1'].setValue(null);
      this.myPaymentForm.controls['City'].setValue(null);
      this.myPaymentForm.controls['State'].setValue(null);
      this.myPaymentForm.controls['Country'].setValue(null);
      this.myPaymentForm.controls['Zip'].setValue(null);
    } else {
      this.addCardValidators(this.requiredACHFields);
    }
    this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
    this.myPaymentForm.controls['withSurcharge'].setValue(0);
  }

  async processSTaxUsingToken() {
    const parent = this;
    let cardData;
    parent.mangoAPISrvc.showLoader(true);
    const formData = parent.myPaymentForm.value;
    const clientName = parent.selClient ? parent.selClient.ClientName : 'Hosted Payment';
    const otherField2 = parent.selClient ? (parent.selClient.ClientID + ' | ' + formData.memo) : ('Hosted Payment | ' + formData.memo);
    if (parent.selClient && parent.selClient.ClientID) {
      cardData = {
        method: parent.isCrCard ? 'card' : 'bank',
        CustomerCardID: parent.cardProfile.CustomerCardID,
        send_receipt: false,
        meta: {
          tax: 0,
          shippingAmount: 0,
          customerCode: parent.selClient.ClientID,
          otherField2: otherField2,
          otherField1:
            'from Hosted  --  | InvoiceNumber : ' +
            this.companyData.company.InvoiceNumber +
            ' | ClientName :' +
            clientName,
          subtotal: numeral(formData.Amount).value()
        },
        total: numeral(formData.Amount).value()
      };
    } else {
      cardData = {
        method: parent.isCrCard ? 'card' : 'bank',
        CustomerCardID: parent.cardProfile.uid,
        send_receipt: false,
        meta: {
          otherField2: otherField2,
          otherField1:
            'from Hosted  --  | InvoiceNumber : ' +
            this.companyData.company.InvoiceNumber +
            ' | ClientName :' +
            clientName,
          subtotal: numeral(formData.Amount).value(),
          tax: 0
        },
        total: numeral(formData.Amount).value()
      };
    }

    const cardObj = { mangodata: null, cardData: cardData };

    cardObj.mangodata = await parent.prepareMangoData();
    parent.mangoAPISrvc
      .performStaxClickPayActions(cardObj, parent.companyData.company.CompanyID, 'c')
      .subscribe(
        (result: any) => {
          parent.transactionData = result;
          parent.mangoAPISrvc.showLoader(false);
          if (this.isEmailProvided && this.isValidServerCode) {
            parent.sendEmailToFirmAndCustomer(
              parent.transactionData['PaymentAmount'],
              parent.transactionData['PaymentHeaderID']
            );
            let obj = {};
            obj['uid'] = parent.transactionData ? parent.transactionData.receiptID : null;
            obj['isBusiness'] = !parent.isCrCard && parent.isBusiness == 'true' ? true : false;
            parent.mangoAPISrvc
              .updateCreditCardDetails(parent.cardProfile.CustomerCardID, obj)
              .subscribe((resultRes: any) => {});
          }
          parent.isProcessing = false;
          parent.isPaymentCompleted = true;
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
        },
        (err: any) => {
          parent.isProcessing = false;
          parent.mangoAPISrvc.showLoader(false);
          let msg = err['message'] ? err['message'] : err;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg =
                  typeof err === 'object'
                    ? Object.keys(err)
                        .map(k => err[k].join(' '))
                        .join(' ')
                    : JSON.stringify(err);
              } catch (error) {}
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent.translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
        }
      );
  }

  async createPaymentHeader(newHeader) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc.createMobilePayPaymentHeader(newHeader).subscribe(function (data: any) {
          resolve(data.data);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async createBillingHeader(newDetails) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .createBillingHeaderMobilePayment(newDetails)
          .subscribe(function (data: any) {
            resolve(data.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  async prepareMangoData() {
    const parent = this;
    if (this.isEmailProvided && this.isValidServerCode) {
      const formData = parent.myPaymentForm.value;
      parent.paidAmount = numeral(formData.Amount).value();
      let paymentUnapplied = numeral(formData.PaymentUnapplied).value();
      parent.processData.invoices = parent.openInvoices
        .filter(item => item.isChecked == true)
        .map(item => ({
          ...item,
          InvoiceBalance: numeral(item.InvoiceBalance).value(),
          InvoiceAmount: numeral(item.InvoiceAmount).value(),
          TotalPayments: numeral(item.TotalPayments).value()
        }));

      let selectedInvoices = parent.openInvoices.filter(item => item.isChecked == true);
      // Advance Payment Only.  No Payment Applied and No Retainer Applied - Create a Retainer
      //if (selectedInvoices.length == 0) {
      parent.processData['ClientID'] = parent.selClient['ClientID'];
      parent.processData['CompanyID'] = parent.selClient.CompanyID;
      parent.processData['InvoiceAmount'] = formData['PaymentUnapplied'] * -1;
      parent.processData['BillingPartnerID'] = parent.selClient['BillingPartnerID'];
      parent.processData['OriginatingPartnerID'] = parent.selClient['OriginatingPartnerID'];
      parent.processData['StaffAssignedID'] = parent.selClient['StaffAssignedID'];
      parent.processData['GroupDescriptionID'] = parent.selClient['GroupDescriptionID'];
      parent.processData['GroupDescriptionIDArray'] = parent.selClient['GroupDescriptionIDArray'];

      parent.processData['SurChargeAmount'] = 0;
      let surchargeObj = await parent.getSurchargeAmount(
        parent.cardProfile.CustomerCardID,
        parent.paidAmount,
        parent.companyData.company.CompanyID,
        'sc'
      );
      if (surchargeObj) {
        parent.processData['SurChargeAmount'] = numeral(surchargeObj['surcharge_amount']).value();
      }
      parent.processData['DescriptionShort'] =
        'Advance Payment' + (formData['memo'] ? ` - ${formData['memo']}` : '');
      parent.processData.PaymentType = parent.isCrCard ? 'Credit Card' : 'ACH';
      if (parent.processData['PaymentType'] == 'Credit Card' && this.isDebitCard) {
        parent.processData['PaymentType'] = 'Debit card';
      }

      parent.processData['PaymentUnapplied'] = paymentUnapplied;
      parent.processData.Amount = parent.paidAmount;
      parent.processData['memo'] = formData['memo'];
      parent.processData['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      var note;
      note = `Paid : ${parent.cardProfile.NameOnCard}`;
      parent.processData['PaymentNoteH'] = this.isCrCard ? note + ' (HP)' : note + ') (HP)';

      // handle customer mark deposited applied
      if (!parent.selClient['MarkDepositApplied']) {
        parent.processData['PaymentUnappliedHos'] = paymentUnapplied;
      } else {
        parent.processData['PaymentUnappliedHos'] = 0;
      }

      for (let i = 0; i < selectedInvoices.length; ++i) {
        let itemData = selectedInvoices[i];
        if (itemData.InvoiceType == 'Retainer Invoice') {
          parent.processData['PaymentUnappliedHos'] += numeral(itemData.PaymentsApplied).value();
        }
      }

      /////////////////////////////

      parent.processData.Amount = parent.processData['PaymentAmount'] = numeral(
        formData.Amount
      ).value();
      parent.processData.ClientID = parent.selClient['ClientID'];
      parent.processData.CompanyID = parent.companyData.company.CompanyID;
      parent.processData.BillingPartnerID = parent.companyData.company.BillingPartnerID;
      parent.processData.OriginatingPartnerID = parent.companyData.company.OriginatingPartnerID;
      parent.processData.StaffAssignedID = parent.companyData.company.StaffAssignedID;
      parent.processData.GroupDescriptionIDArray =
        parent.companyData.company.GroupDescriptionIDArray;
      parent.processData.GroupDescriptionID = parent.companyData.company.GroupDescriptionID;
      parent.processData.ClientTypeID = parent.companyData.company.ClientTypeID;
      parent.processData.BillingHeaderID = parent.companyData.company.BillingHeaderID;
      parent.processData.PaymentType = parent.isCrCard ? 'Credit Card' : 'ACH';
      parent.processData.PaymentNote =
        'Paid By: ' +
        this.cardProfile.NameOnCard +
        ', ' +
        'using ' +
        parent.processData['PaymentType'] +
        ':  ****' +
        this.cardProfile.CardNo +
        ' (HP)';
      parent.processData['SurChargeAmount'] = numeral(formData.SurChargeAmount).value();
      parent.processData['PaymentUnapplied'] = 0;

      if (parent.processData.InvoiceType == 'Retainer Invoice') {
        parent.processData['PaymentUnapplied'] = numeral(formData.Amount).value();
      }

      parent.processData['isStatementFlow'] = parent.isStatementFlow;
      parent.processData['DescriptionShort'] = parent.companyData.company.DescriptionShort;
      parent.processData['InvoiceNumber'] = parent.companyData.company.InvoiceNumber;
      parent.processData['InvoiceAmount'] = parent.companyData.company.InvoiceAmount;
      parent.processData['TotalPayments'] = parent.companyData.company.TotalPayments;
      parent.processData['BillingHeaderID'] = parent.companyData.company.BillingHeaderID;
      parent.processData['InvoiceBalance'] = parent.companyData.company.InvoiceBalance;
      parent.processData['InvoiceDate'] = parent.companyData.company.InvoiceDate;
      //}
      return parent.processData;
    } else {
      return null;
    }
  }

  async updateMango() {
    const parent = this;
    const formData = parent.myPaymentForm.value;
    parent.paidAmount = numeral(formData.Amount).value();
    const paymentUnapplied = numeral(formData.PaymentUnapplied).value();

    const selectedInvoices = parent.companyData.invoices.filter((item) => item.isChecked == true);
    // Advance Payment Only.  No Payment Applied and No Retainer Applied - Create a Retainer
    if (selectedInvoices.length == 0) {
      // Build new record in billing header
      var newHeader = {};
      newHeader['ClientID'] = this.selClient['ClientID'];
      newHeader['CompanyID'] = this.selClient.CompanyID;
      newHeader['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newHeader['InvoiceAmount'] = formData['PaymentUnapplied'] * -1;
      newHeader['TotalServices'] = newHeader['InvoiceAmount'];
      newHeader['InvoiceBalance'] = newHeader['InvoiceAmount'];
      newHeader['PaymentsApplied'] = 0;
      newHeader['TotalStaffCost'] = 0;
      newHeader['TotalPayments'] = 0;
      newHeader['InvoicePosted'] = true;

      newHeader['CreatedbyStaffID'] = null;
      newHeader['BillingPartnerID'] = this.selClient['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = this.selClient['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = this.selClient['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = this.selClient['GroupDescriptionID'];
      newHeader['GroupDescriptionIDArray'] = this.selClient['GroupDescriptionIDArray'];
      newHeader['SurChargeAmount'] = 0;
      const surchargeObj = await parent.getSurchargeAmount(parent.cardProfile.CustomerCardID, parent.paidAmount, parent.companyData.company.CompanyID, 'sc');
      if (surchargeObj) {
        newHeader['SurChargeAmount'] = numeral(surchargeObj['surcharge_amount']).value();
      }
      newHeader['InvoiceType'] = 'Retainer';
      newHeader['DescriptionShort'] =
        'Advance Payment' + (formData['memo'] ? ` - ${formData['memo']}` : '');

      parent.mangoAPISrvc.showLoader(true);
      // No Payment Applied and No Retainer Applied - Create advance Payment
      // Create billing Header
      const billingHeaderRes = await this.createBillingHeader(newHeader);
      // Create PaymentHeader
      let note;
      const paymentHeader = {};
      paymentHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      paymentHeader['ClientID'] = newHeader['ClientID'];
      paymentHeader['CompanyID'] = newHeader['CompanyID'];
      paymentHeader['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      paymentHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
      paymentHeader['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
      paymentHeader['PaymentAmount'] = parent.paidAmount;
      paymentHeader['SelectedForDepositToBank'] = false;
      paymentHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      note = `Paid : ${parent.cardProfile.NameOnCard}`;
      paymentHeader['PaymentNote'] = this.isCrCard ? note + ' (HP)' : note + ') (HP)';
      if (this.isCrCard && parent.isDebitCard) {
        paymentHeader['PaymentType'] = 'Debit card';
      }

      paymentHeader['SurChargeAmount'] = newHeader['SurChargeAmount'];
      paymentHeader['SelectedForDepositToBank'] = false;
      paymentHeader['NSFtrue'] = false;
      paymentHeader['BillingPartnerID'] = parent.selClient.BillingPartnerID;
      paymentHeader['OriginatingPartnerID'] = parent.selClient.OriginatingPartnerID;
      paymentHeader['StaffAssignedID'] = parent.selClient.StaffAssignedID;
      paymentHeader['GroupDescriptionIDArray'] = parent.selClient.GroupDescriptionIDArray;
      paymentHeader['GroupDescriptionID'] = parent.selClient.GroupDescriptionID;
      paymentHeader['ClientTypeID'] = parent.selClient.ClientTypeID;
      paymentHeader['PaymentUnapplied'] = paymentUnapplied;


      const paymentHeaderres = await this.createPaymentHeader(paymentHeader);

      parent.isProcessing = false;
      parent.isPaymentCompleted = true;
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
      parent.sendEmailToFirmAndCustomer(
        newHeader['PaymentAmount'],
        paymentHeaderres['PaymentHeaderID']
      );
      // BillingHeader needs to be created first if there is an unapplied balance.  Create Advance Payment.
    } else if (paymentUnapplied == 0) {
      var newHeader = {};
      newHeader['ClientID'] = this.selClient['ClientID'];
      newHeader['CompanyID'] = this.selClient.CompanyID;
      newHeader['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
      newHeader['PaymentNote'] = formData['memo'];
      newHeader['PaymentUnapplied'] = paymentUnapplied;
      newHeader['BillingPartnerID'] = this.selClient['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = this.selClient['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = this.selClient['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = this.selClient['GroupDescriptionID'];
      newHeader['ClientTypeID'] = this.selClient['ClientTypeID'];
      newHeader['SelectedForDepositToBank'] = false;
      newHeader['NSFtrue'] = false;
      newHeader['PaymentAmount'] = parent.paidAmount;
      newHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      newHeader['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      var note;
      note = `Paid : ${parent.cardProfile.NameOnCard}`;
      newHeader['PaymentNote'] = this.isCrCard ? note + ' (HP)' : note + ') (HP)';
      if (this.isCrCard && parent.isDebitCard) {
        newHeader['PaymentType'] = 'Debit card';
      }

      newHeader['SurChargeAmount'] = newHeader['SurChargeAmount'];

      // handle customer mark deposited applied
      if (!parent.selClient['MarkDepositApplied']) {
        newHeader['PaymentUnapplied'] = paymentUnapplied;
      } else {
        newHeader['PaymentUnapplied'] = 0;
      }

      parent.mangoAPISrvc.showLoader(true);
      for (let i = 0; i < selectedInvoices.length; ++i) {
        const itemData = selectedInvoices[i];
        if (itemData.InvoiceType == "Retainer Invoice") {
          newHeader['PaymentUnapplied'] += numeral(itemData.PaymentsApplied).value();
        }
      }

      const paymentHeaderObj = await this.createPaymentHeader(newHeader);

      for (let index = 0; index < selectedInvoices.length; ++index) {
        const itemData = selectedInvoices[index];

        if (itemData.InvoiceType == "Retainer Invoice") {
          const billingHeaderRequest = {};
          billingHeaderRequest['ClientID'] = this.selClient['ClientID'];
          billingHeaderRequest['CompanyID'] = this.selClient.CompanyID;
          billingHeaderRequest['InvoiceDate'] = itemData.InvoiceDate;
          billingHeaderRequest['DescriptionShort'] =
            'Advance Payment' + (formData['memo'] ? ` - ${formData['memo']}` : '');
          billingHeaderRequest['InvoiceType'] = 'Retainer Payment';
          billingHeaderRequest['InvoiceAmount'] = numeral(itemData.PaymentsApplied).value() * -1;
          billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
          billingHeaderRequest['PaymentsApplied'] = 0;
          billingHeaderRequest['TotalStaffCost'] = 0;
          billingHeaderRequest['TotalPayments'] = 0;
          billingHeaderRequest['InvoicePosted'] = true;
          billingHeaderRequest['NSFtrue'] = false;
          itemData['CompanyID'] = this.selClient.CompanyID;
          // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
          if (numeral(itemData.InvoiceBalance).value() <= 0) {
            parent.mangoAPISrvc
              .deleteBillingHeaderMobilePayment(
                itemData.BillingHeaderID,
                billingHeaderRequest['CompanyID']
              )
              .subscribe(response => {});
          } else {
            const cloneObj = Object.assign({}, billingHeaderRequest);
            cloneObj['AppliedCheckEdit'] = 0;
            cloneObj['PaymentsApplied'] = 0;
            cloneObj['DescriptionShort'] = itemData.DescriptionShort;
            cloneObj['InvoiceType'] = 'Retainer Invoice';
            cloneObj['CompanyID'] = this.selClient.CompanyID;
            cloneObj['InvoiceAmount'] = numeral(itemData.InvoiceAmount).value();
            cloneObj['InvoiceBalance'] = numeral(itemData.InvoiceBalance).value();
            cloneObj['PaymentAmount'] = numeral(itemData.PaymentsApplied).value();
            cloneObj['PaymentUnapplied'] = numeral(itemData.PaymentUnapplied).value();
            cloneObj['TotalPayments'] = numeral(itemData.TotalPayments).value();
            cloneObj['LastModifiedStaffID'] = null;
            parent.mangoAPISrvc
              .billingHeaderMobilePayment(cloneObj, itemData.BillingHeaderID)
              .subscribe(item => {});
          }

          billingHeaderRequest['CreatedbyStaffID'] = null;
          billingHeaderRequest['SurChargeAmount'] = formData['SurChargeAmount']
            ? formData['SurChargeAmount']
            : 0;

          const billingHeaderRes = await this.createBillingHeader(billingHeaderRequest);

          //paymentHeaderObj['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
          //paymentHeaderObj['RetainerHeaderID'] = itemData.BillingHeaderID;

          // Need to create PaymentDetail record for the Retainer for association to the BH record
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeaderObj['PaymentHeaderID'];
          if (numeral(itemData.InvoiceBalance).value() != 0) {
            PaymentDetailsEntity['ParentHeaderID'] = itemData.BillingHeaderID;
          }
          PaymentDetailsEntity['ClientID'] = this.selClient['ClientID'];
          PaymentDetailsEntity['CompanyID'] = this.selClient.CompanyID;
          PaymentDetailsEntity['CheckRef'] = null;
          PaymentDetailsEntity['PaymentNote'] = newHeader['PaymentNote'];
          PaymentDetailsEntity['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
          PaymentDetailsEntity['PaymentType'] = newHeader['PaymentType'];
          PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = itemData.InvoiceDate;
          PaymentDetailsEntity['isAdvancePayment'] = true;
          PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
          PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

          await this.createPaymentDetail(PaymentDetailsEntity);
        } else {
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = itemData.BillingHeaderID;
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeaderObj['PaymentHeaderID'];
          PaymentDetailsEntity['ClientID'] = this.selClient.ClientID;
          PaymentDetailsEntity['CompanyID'] = this.selClient.CompanyID;
          PaymentDetailsEntity['CheckRef'] = null;
          PaymentDetailsEntity['PaymentNote'] = paymentHeaderObj['PaymentNote'];
          PaymentDetailsEntity['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
          PaymentDetailsEntity['PaymentType'] = paymentHeaderObj['PaymentType'];
          PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = itemData.InvoiceDate;
          PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
          PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

          await this.createPaymentDetail(PaymentDetailsEntity);

          itemData.AppliedCheckEdit = 0;
          itemData.PaymentsApplied = 0;
          itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
          itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
          itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
          itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
          itemData.TotalPayments = numeral(itemData.TotalPayments).value();
          itemData['CompanyID'] = this.selClient.CompanyID;

          const parent = this;
          // updating BillingHeader for each selected Open Invoice
          itemData['LastModifiedStaffID'] = null;
          itemData['SurChargeAmount'] = formData['SurChargeAmount']
            ? formData['SurChargeAmount']
            : 0;
          parent.mangoAPISrvc
            .billingHeaderMobilePayment(itemData, itemData.BillingHeaderID)
            .subscribe(item => {});
        }

        if (index == selectedInvoices.length - 1) {
          parent.isProcessing = false;
          parent.isPaymentCompleted = true;
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
          parent.sendEmailToFirmAndCustomer(
            newHeader['PaymentAmount'],
            paymentHeaderObj['PaymentHeaderID']
          );
        }
      }
    } else {
      var newHeader = {};
      newHeader['ClientID'] = this.selClient['ClientID'];
      newHeader['CompanyID'] = this.selClient.CompanyID;
      newHeader['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
      newHeader['PaymentNote'] = formData['memo'];
      newHeader['DescriptionShort'] =
        'Advance Payment' + (formData['memo'] ? ` - ${formData['memo']}` : '');

      // let PmtUnapplied = numeral(formData['PaymentUnapplied']).value();
      newHeader['InvoiceAmount'] = paymentUnapplied * -1;
      newHeader['TotalServices'] = newHeader['InvoiceAmount'];
      newHeader['TotalPayments'] = 0;
      newHeader['InvoiceBalance'] = newHeader['InvoiceAmount'];
      newHeader['PaymentsApplied'] = 0;
      newHeader['TotalStaffCost'] = 0;
      newHeader['InvoicePosted'] = true;
      newHeader['InvoiceType'] = 'Retainer';
      newHeader['NSFtrue'] = false;
      newHeader['CreatedbyStaffID'] = null;
      newHeader['SurChargeAmount'] = formData['SurChargeAmount'] ? formData['SurChargeAmount'] : 0;

      parent.mangoAPISrvc.showLoader(true);
      const billingHeaderRes = await this.createBillingHeader(newHeader);

      // create new record in paymentHeader
      const newPaymentHeader = {};
      newPaymentHeader['ClientID'] = newHeader['ClientID'];
      newPaymentHeader['CompanyID'] = newHeader['CompanyID'];
      newPaymentHeader['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newPaymentHeader['PaymentType'] = newHeader['PaymentType'];
      newPaymentHeader['PaymentNote'] = newHeader['PaymentNote'];
      newPaymentHeader['PaymentUnapplied'] = paymentUnapplied;
      newPaymentHeader['CheckRef'] = null;

      // handle customer mark deposited applied
      if (!this.selClient['MarkDepositApplied']) {
        newPaymentHeader['PaymentUnapplied'] = paymentUnapplied;
      } else {
        newPaymentHeader['PaymentUnapplied'] = 0;
      }
      newPaymentHeader['PaymentUnapplied'] = newPaymentHeader['PaymentUnapplied'];
      newPaymentHeader['BillingPartnerID'] = newPaymentHeader['CompanyID'];
      newPaymentHeader['OriginatingPartnerID'] = this.selClient['OriginatingPartnerID'];
      newPaymentHeader['StaffAssignedID'] = this.selClient['StaffAssignedID'];
      newPaymentHeader['GroupDescriptionID'] = this.selClient['GroupDescriptionID'];
      newPaymentHeader['ClientTypeID'] = this.selClient['ClientTypeID'];
      newPaymentHeader['SelectedForDepositToBank'] = false;
      newPaymentHeader['NSFtrue'] = false;
      newPaymentHeader['PaymentAmount'] = parent.paidAmount;

      // insert paymentHeader record (This is needed only if Advance Payment Done PaymentUnapplied > 0)
      if (billingHeaderRes['BillingHeaderID']) {
        newPaymentHeader['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
        newPaymentHeader['RetainerHeaderID'] = billingHeaderRes['BillingHeaderID'];
      }
      newPaymentHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      var note;
      if (parent.transactionData) {
        note = `Paid : ${parent.cardProfile.NameOnCard}`;
        newPaymentHeader['PaymentNote'] =
          newPaymentHeader['PaymentType'] == 'Credit Card' ? note + ' (HP)' : note + ') (HP)';
        if (parent.isCrCard && parent.isDebitCard) {
          newPaymentHeader['PaymentType'] = 'Debit card';
        }
      }
      newPaymentHeader['CreatedbyStaffID'] = null;
      newPaymentHeader['SurChargeAmount'] = formData['SurChargeAmount']
        ? formData['SurChargeAmount']
        : 0;

      const paymentHeaderObj = await this.createPaymentHeader(newPaymentHeader);

      for (let index = 0; index < selectedInvoices.length; ++index) {
        const itemData = selectedInvoices[index];
        if (itemData.InvoiceType == "Retainer Invoice") {
          const billingHeaderRequest = {};
          billingHeaderRequest['ClientID'] = newPaymentHeader['ClientID'];
          billingHeaderRequest['CompanyID'] = newPaymentHeader['CompanyID'];
          billingHeaderRequest['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
          billingHeaderRequest['DescriptionShort'] =
            'Advance Payment' + (formData['memo'] ? ` - ${formData['memo']}` : '');
          billingHeaderRequest['InvoiceAmount'] = numeral(itemData.TotalPayments).value() * -1;
          billingHeaderRequest['TotalServices'] = billingHeaderRequest['InvoiceAmount'];
          billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
          billingHeaderRequest['PaymentsApplied'] = 0;
          billingHeaderRequest['TotalStaffCost'] = 0;
          billingHeaderRequest['TotalPayments'] = 0;
          billingHeaderRequest['InvoicePosted'] = true;
          billingHeaderRequest['NSFtrue'] = false;
          itemData['CompanyID'] = this.selClient.CompanyID;
          // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
          if (numeral(itemData.InvoiceBalance).value() <= 0) {
            this.mangoAPISrvc
              .deleteBillingHeaderMobilePayment(
                itemData.BillingHeaderID,
                billingHeaderRequest['CompanyID']
              )
              .subscribe(item => {});
          } else {
            const cloneObj = Object.assign({}, billingHeaderRequest);
            cloneObj['ClientID'] = this.selClient.ClientID;
            cloneObj['AppliedCheckEdit'] = 0;
            cloneObj['PaymentsApplied'] = 0;
            cloneObj['DescriptionShort'] = itemData.DescriptionShort;
            cloneObj['CompanyID'] = this.selClient.CompanyID;
            cloneObj['InvoiceAmount'] = numeral(itemData.InvoiceAmount).value();
            cloneObj['InvoiceBalance'] = numeral(itemData.InvoiceBalance).value();
            cloneObj['PaymentAmount'] = numeral(itemData.PaymentAmount).value();
            cloneObj['PaymentUnapplied'] = numeral(itemData.PaymentUnapplied).value();
            cloneObj['TotalPayments'] = numeral(itemData.TotalPayments).value();
            cloneObj['LastModifiedStaffID'] = null;
            this.mangoAPISrvc
              .billingHeaderMobilePayment(cloneObj, itemData.BillingHeaderID)
              .subscribe(item => {});
          }
          billingHeaderRequest['CreatedbyStaffID'] = null;
          billingHeaderRequest['SurChargeAmount'] = formData['SurChargeAmount']
            ? formData['SurChargeAmount']
            : 0;

          await this.createBillingHeader(billingHeaderRequest);
        } else {
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = itemData.BillingHeaderID;
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeaderObj['PaymentHeaderID'];
          PaymentDetailsEntity['ClientID'] = this.selClient.ClientID;
          PaymentDetailsEntity['CompanyID'] = this.selClient.CompanyID;
          PaymentDetailsEntity['CheckRef'] = null;
          PaymentDetailsEntity['PaymentNote'] = paymentHeaderObj['PaymentNote'];
          PaymentDetailsEntity['PaymentDate'] = paymentHeaderObj['PaymentDate'];
          PaymentDetailsEntity['PaymentType'] = paymentHeaderObj['PaymentType'];
          PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = itemData.InvoiceDate;
          PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
          PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

          await this.createPaymentDetail(PaymentDetailsEntity);

          itemData.AppliedCheckEdit = 0;
          itemData.PaymentsApplied = 0;
          itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
          itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
          itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
          itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
          itemData.TotalPayments = numeral(itemData.TotalPayments).value();
          itemData.LastModifiedStaffID = null;
          itemData['CompanyID'] = this.selClient.CompanyID;
          // updating BillingHeader for each selected Open Invoice
          this.mangoAPISrvc
            .billingHeaderMobilePayment(itemData, itemData.BillingHeaderID)
            .subscribe(item => {});
        }

        if (index == selectedInvoices.length - 1) {
          parent.isProcessing = false;
          parent.isPaymentCompleted = true;
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
          parent.sendEmailToFirmAndCustomer(
            newHeader['PaymentAmount'],
            paymentHeaderObj['PaymentHeaderID']
          );
        }
      }

      if (selectedInvoices.length == 0) {
        parent.isProcessing = false;
        parent.isPaymentCompleted = true;
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
        parent.sendEmailToFirmAndCustomer(
          newHeader['PaymentAmount'],
          paymentHeaderObj['PaymentHeaderID']
        );
      }

      parent.mangoAPISrvc.showLoader(false);
    }
  }

  async sendEmailToFirmAndCustomer(amt, paymentHeaderId) {
    const parent = this;
    if (parent.companyData.company.PortalPaymentNotifications?.length > 0) {
      parent.mangoAPISrvc
        .getUserInfoByCompanyID(parent.companyData.company['CompanyID'])
        .subscribe((response: any) => {
          if (response?.length > 0) {
            let toArr = [];
            response.forEach(staff => {
              if (
                parent.companyData.company['PortalPaymentNotifications'].includes(
                  staff.StaffID.toString()
                )
              ) {
                if (staff.Email) {
                  toArr.push({ email: staff.Email, name: staff.StaffName });
                }
              }
            });
            parent.sendMailtoCompany(amt, toArr);
          }
        });
    }
    if (
      parent.allEmailContacts.length > 0 &&
      parent.companyData.company['EmailPaymentPortal'] == true
    ) {
      parent.sendMailtoCustomers(amt, paymentHeaderId);
    }
    parent.mangoAPISrvc.showLoader(false);
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      showCancelButton: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'OK',
      text: 'Transaction Processed Successfully!'
    });
  }

  sendMailtoCompany(amt, toArr) {
    const parent = this;
    const companysendInBlueObj = {
      sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      to: [{ email: "", name: "" }],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 17,
      params: {}
    };
    amt = '$' + numeral(amt).format('0,0.00');

    companysendInBlueObj.to = toArr;
    companysendInBlueObj.params['COMPANYNAME'] = parent.selClient.ClientName;
    companysendInBlueObj.params['PAYMENT_TYPE'] = parent.isCrCard ? 'Credit Card' : 'ACH';
    companysendInBlueObj.params['PAYMENTAMOUNT'] =
      '$' + numeral(parent.paidAmount).format('0,0.00');
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
      data => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      },
      err => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  sendMailtoCustomers(amt, paymentHeaderId) {
    const parent = this;
    const sendInBlueObj = {};
    let toObj = { email: '', name: '' };
    var toArr = [];
    const senderObj = { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER }
    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['replyTo'] = { email: environment.EMAIL_RETURN_SENDER };

    for (let index = 0; index < parent.allEmailContacts.length; index++) {
      const element = parent.allEmailContacts[index];
      toObj = { email: '', name: '' };
      toObj['name'] = element['ContactName'] ? element['ContactName'] : element['Email'];
      toObj['email'] = element['Email'];
      toArr.push(toObj);
    }

    sendInBlueObj['to'] = parent.mangoUtils.deduplicateEmail(toArr);
    const tags = [];

    var toArr = [];
    tags.push(parent.selClient['ClientID'].toString());
    tags.push('none');
    tags.push(paymentHeaderId.toString());
    tags.push(parent.companyData.company.CompanyID.toString());
    tags.push(parent.selClient['ClientName']);

    sendInBlueObj['tags'] = tags;
    amt = '$' + numeral(amt).format('0,0.00');

    if (sendInBlueObj['to'].length > 0) {
      if (!parent.cashReceiptEmailTemplate['EmailTemplateID']) {
        parent.cashReceiptEmailTemplate = parent.mangoUtils.getDefaultTemplate(
          'cashReceipt',
          parent.companyData.company.CompanyID
        ); // default
      }
      let htmlContent = parent.mangoUtils.getCashReceiptEmailTemplate();
      sendInBlueObj['subject'] = parent.cashReceiptEmailTemplate['EmailSubject'];

      const formData = parent.myPaymentForm.value;
      if (parent.companyData.company.isSurchargeEnabled && formData.SurChargeAmount > 0) {
        this.cashReceiptEmailTemplate[
          'htmlBody'
        ] += `</br></br><div style="text-align: center;font-size: 17px;color: #cb0000;">Transaction subject to a surcharge in the amount of <strong>$${formData.SurChargeAmount}</strong>.</div>`;
      }
      htmlContent = this.mangoUtils.replaceAll(
        htmlContent,
        '%InjectTemplate%',
        parent.cashReceiptEmailTemplate['htmlBody']
      );
      htmlContent = this.mangoUtils.replaceAll(
        htmlContent,
        '%ContactName%',
        parent.selClient['ContactPerson']
      );
      htmlContent = this.mangoUtils.replaceAll(
        htmlContent,
        '%YourFirmName%',
        parent.companyData.company.CompanyName
      );
      htmlContent = this.mangoUtils.replaceAll(
        htmlContent,
        '%ClientName%',
        parent.selClient['ClientName']
      );
      htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Amount%', amt);
      htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Email%', parent.selClient['Email']);
      sendInBlueObj['htmlContent'] = htmlContent;

      sendInBlueObj['subject'] = parent.mangoUtils.replaceAll(
        sendInBlueObj['subject'],
        '%ClientName%',
        parent.selClient['ClientName']
      );

      this.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(data => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      });
    }
  }
  async createPaymentDetail(PaymentDetailsEntity) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .createMobilePayPaymentDetail(PaymentDetailsEntity)
          .subscribe(function (data: any) {
            resolve(data.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async authorizeSTaxNewCard() {
    if (this.isStaxNewCard) {
      this.addSTaxNewCard();
    } else {
      await this.processSTaxUsingToken();
    }
  }

  addSTaxNewCard() {
    const parent = this;
    let extraDetails = null;
    parent.myPaymentForm.controls['isBusiness'].setValue(parent.isBusiness);
    const formData = parent.myPaymentForm.value;
    const cardObj = {};
    const clientName = parent.selClient ? parent.selClient.ClientName : 'Hosted Payment';
    parent.mangoAPISrvc.showLoader(true);
    if (this.isCrCard) {
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.FirstName,
        lastname: formData.LastName,
        email: formData.EmailAddress,
        month: parseInt(formData.expDate.split('/')[0]),
        year: parseInt(formData.expDate.split('/')[1]),
        phone: formData.phone,
        address_1: formData.Address1,
        //address_2: formData.Address2 ? formData.Address2 : "",
        address_city: formData.City,
        address_state: formData.State,
        address_zip: formData.Zip,
        method: 'card',
        validate: false,
        send_receipt: false,
        meta: {
          otherField1:
            'from Hosted Page : New card :  | InvoiceNumber : null | ClientName : ' + clientName
        }
      };
    } else {
      const address = parent.selClient?.BusStreet1 ? parent.selClient.BusStreet1 : parent.merchantResponse.business_address_1;
      const address2 = parent.selClient?.BusStreet2 ? parent.selClient.BusStreet2 : parent.merchantResponse.business_address_2;
      const city = parent.selClient?.BusCity ? parent.selClient.BusCity : parent.merchantResponse.business_address_city;
      const state = parent.selClient?.BusState ? parent.selClient.BusState : parent.merchantResponse.business_address_state;
      const zip = parent.selClient?.BusZip ? parent.selClient.BusZip : parent.merchantResponse.business_address_zip;
      let phNo = parent.selClient?.Mobile ? parent.selClient.Mobile : parent.companyData.company.Office;
      phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
      phNo = phNo ? phNo : '555-555-5555';
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.FirstName,
        lastname: formData.LastName,
        person_name: formData.FirstName + ' ' + formData.LastName,
        phone: phNo,
        address_1: address,
        address_2: address2 ? address2 : '',
        address_city: city,
        address_state: state,
        address_zip: zip,
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        method: 'bank',
        validate: false,
        send_receipt: false,
        url: 'https://omni.fattmerchant.com/#/bill/',
        meta: {
          otherField1:
            'from Hosted Page : New card :  | InvoiceNumber : null | ClientName : ' + clientName
        }
      };
    }
    this.fattJs
      .tokenize(extraDetails)
      .then(async result => {
        if (result.id) {
          parent.isProcessing = false;
          cardObj['CardNo'] = result.card_last_four;
          cardObj['ExpiryDate'] = result.card_exp;
          cardObj['NameOnCard'] = result.person_name;
          cardObj['TransType'] = parent.isCrCard ? 'CC' : 'ACH';
          cardObj['FirstName'] = formData.FirstName;
          cardObj['LastName'] = formData.LastName;
          cardObj['uid'] = result.id;
          cardObj['scid'] = result.customer_id;
          cardObj['CardType'] = parent.isCrCard ? result['card_type'] : result['bank_type'];
          if (!this.isEmailProvided && !this.isValidServerCode) {
            parent.mangoAPISrvc.showLoader(false);
            parent.isStaxNewCard = false;
            parent.cardProfile = cardObj;
            await parent.calculateSurcharge();
          } else {
            cardObj['CompanyID'] = parent.selClient.CompanyID;
            cardObj['ClientID'] = parent.selClient.ClientID;
            if (!parent.isCrCard) {
              cardObj['BankAcctNo'] = formData['AccountNumber'].substr(
                formData['AccountNumber'].length - 4,
                formData['AccountNumber'].length
              );
              cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
            }
            parent.cardProfile = parent.companyData.cards.filter(
              item => item.TransType == cardObj['TransType']
            );
            if (parent.cardProfile == null || parent.cardProfile.length == 0) {
              parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
                async (resultRes: any) => {
                  parent.cardProfile = cardObj;
                  parent.cardProfile['CustomerCardID'] = resultRes.id;
                  parent.companyData.cards.push(parent.cardProfile);
                  parent.mangoAPISrvc.showLoader(false);
                  parent.isStaxNewCard = false;
                  await parent.calculateSurcharge();
                },
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
            } else {
              parent.cardProfile = parent.cardProfile[0];
              parent.mangoAPISrvc
                .updateCreditCardDetails(parent.cardProfile.CustomerCardID, cardObj)
                .subscribe(
                  async (resultRes: any) => {
                    parent.cardProfile['NameOnCard'] = cardObj['NameOnCard'];
                    parent.cardProfile['CardNo'] = cardObj['CardNo'];
                    parent.cardProfile['CardType'] = cardObj['CardType'];
                    parent.mangoAPISrvc.showLoader(false);
                    parent.isStaxNewCard = false;
                    await parent.calculateSurcharge();
                  },
                  (data: any) => {
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
            }
          }
        }
      })
      .catch(err => {
        parent.isProcessing = false;
        parent.mangoAPISrvc.showLoader(false);
        parent.isDialogFormValid = true;
        let msg = err['message'] ? err['message'] : null;
        if (!msg) {
          if (err.payment_attempt_message) {
            msg = err.payment_attempt_message;
          } else if (err.child_transactions && err.child_transactions.length > 0) {
            msg = err.child_transactions[0].message;
          } else if (err.error_description) {
            msg = err.error_description;
          } else {
            try {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            } catch (error) {}
          }
        }
        Swal.fire({
          icon: 'error',
          title: parent.translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
      });
  }

  async processPayment() {
    if (this.isProcessing) {
      return false;
    }
    this.isProcessing = true;
    if (this.isStaxNewCard) {
      this.addSTaxNewCard();
    } else {
      await this.processSTaxUsingToken();
    }
  }

  async calculateSurcharge() {
    this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
    this.myPaymentForm.controls['withSurcharge'].setValue(0);
    this.isDebitCard = false;
    let surchargeObj = null;
    const processAmt = numeral(this.myPaymentForm.get("Amount").value).value();
    if (this.cardProfile && !this.isStaxNewCard && this.companyData.company.isSurchargeEnabled && processAmt && processAmt > 0) {
      this.mangoAPISrvc.showLoader(true);

      if (!this.isEmailProvided && !this.isValidServerCode) {
        surchargeObj = await this.getSurchargeAmount(
          this.cardProfile.uid,
          processAmt,
          this.companyData.company.CompanyID,
          'scwc'
        );
      } else {
        surchargeObj = await this.getSurchargeAmount(
          this.cardProfile.CustomerCardID,
          processAmt,
          this.companyData.company.CompanyID,
          'sc'
        );
      }
      if (surchargeObj) {
        this.myPaymentForm.controls['SurChargeAmount'].setValue(surchargeObj['surcharge_amount']);
        this.myPaymentForm.controls['withSurcharge'].setValue(
          surchargeObj['total_with_surcharge_amount']
        );
        this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
      }
      this.mangoAPISrvc.showLoader(false);
    }
  }

  closeWindow() {
    this.nativeWindow.close();
  }

  showSideBar() {
    this.openInvoices;
    this.showClients = true;
  }

  applytoInvoices() {
    let formObj = this.myPaymentForm.value;
    let unappliedAmt = numeral(formObj.PaymentUnapplied).value();
    let amt = numeral(formObj.Amount).value();
    let valueStr = this.mangoUtils.subtractFloat(amt, unappliedAmt);
    this.myPaymentForm.controls['PaymentApplied'].setValue(valueStr);
  }

  clearSelectedInvoices() {
    const processAmt = numeral(this.myPaymentForm.get('Amount').value).value();
    this.openInvoices = this.openInvoices.map(item => ({
      ...item,
      isChecked: false,
      InvoiceBalance: this.mangoUtils.addFloat(item.InvoiceBalance, item.PaymentsApplied),
      TotalPayments: this.mangoUtils.subtractFloat(item.TotalPayments, item.PaymentsApplied),
      PaymentsApplied: 0
    }));
    this.selectedItems = [];
    const totalAppliedAmount = this.openInvoices
      .filter(invoice => invoice.isChecked)
      .reduce((a, b) => {
        return numeral(a).value() + numeral(b.PaymentsApplied).value();
      }, 0);
    const totalUnappliedAmount = this.mangoUtils.subtractFloat(processAmt, totalAppliedAmount);
    this.myPaymentForm.controls['PaymentApplied'].setValue(totalAppliedAmount);
    this.myPaymentForm.controls['PaymentUnapplied'].setValue(totalUnappliedAmount);
  }

  closeSelectInvoiceWindow() {
    this.showClients = false;
  }

  isAmountDisabled() {
    return this.openInvoices.some(item => item.isChecked);
  }
}
