import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { EmailAutomationRoutingModule } from './email-automation-routing.module';

import { EmailAutomationComponent } from './email-automation.component';
import { EmailAutomationSetupComponent } from './email-automation-setup/email-automation-setup.component';
import { EmailAutomationEmailComponent } from './email-automation-email/email-automation-email.component';

@NgModule({
  declarations: [EmailAutomationComponent, EmailAutomationSetupComponent, EmailAutomationEmailComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    EmailAutomationRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class EmailAutomationModule { }
