import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedComponentsService {
  sharedCompVisibility: any = {
    timesEntry: false,
    notes: false,
    expenseEntry: false,
    engagement: false,
    shortcuts: false,
    contact: false,
    task: false,
    invoice: false,
    activity: false,
    expenses: false,
    toDo: false,
    timer: false,
    languageKeys: false,
    inactivity: false,
    upcomingEvents: false,
  }

  toggleDMSIshareSub: Subject<any> = new Subject<any>();

  notesDialogSub: Subject<any> = new Subject<any>();
  notesDialogData: Subject<any> = new Subject<any>();

  engagementDialogSub: Subject<any> = new Subject<any>();
  engagementDialogData: Subject<any> = new Subject<any>();

  shortCutsDialogSub: Subject<any> = new Subject<any>();
  shortCutsDialogData: Subject<any> = new Subject<any>();

  contactDialogSub: Subject<any> = new Subject<any>();
  contactDialogData: Subject<any> = new Subject<any>();

  taskDialogSub: Subject<any> = new Subject<any>();
  taskDialogData: Subject<any> = new Subject<any>();

  timerDialogSub: Subject<any> = new Subject<any>();
  timerDialogData: Subject<any> = new Subject<any>();


  timeEntryDialogSub: Subject<any> = new Subject<any>();
  timeEntryDialogData: Subject<any> = new Subject<any>();

  expenseDialogSub: Subject<any> = new Subject<any>();
  expenseDialogData: Subject<any> = new Subject<any>();

  toDoDialogSub: Subject<any> = new Subject<any>();
  toDoDialogData: Subject<any> = new Subject<any>();

  expensesDialogSub: Subject<any> = new Subject<any>();
  expensesDialogData: Subject<any> = new Subject<any>();

  activityDialogSub: Subject<any> = new Subject<any>();
  activityDialogData: Subject<any> = new Subject<any>();

  private _timerTask$ = new BehaviorSubject<any>({});

  invoiceDialogSub: Subject<any> = new Subject<any>();
  invoiceDialogData: Subject<any> = new Subject<any>();

  languageKeysSub: Subject<any> = new Subject<any>();

  invoiceInactivitySub: Subject<any> = new Subject<any>();

  userActivity;

  resetInvoiceBusyStaffDateEvery;

  leaveAndSaveSub: Subject<any> = new Subject<any>();

  inActivitySub: Subject<any> = new Subject<any>();

  filterDialogSub: Subject<any> = new Subject<any>();

  filterTaskDialogSub: Subject<any> = new Subject<any>();

  upcomingEventSub: Subject<any> = new Subject<any>();

  isTableView: boolean = false;

  clientViewTrigger: Subject<any> = new Subject<any>();

  timersSub: Subject<any> = new Subject<any>();

  selClient = null;

  elSaveFunction = () => {};

  elIsSaveDisabled = true;

  filterProjectListDialog: Subject<any> = new Subject<any>();

  filterProjectListEventData: any = {};

  filterProjectTaskListDialog: Subject<any> = new Subject<any>();

  filterProjectTaskListEventData: any = {};

  constructor() { }

  toggleDMSIshare(data?: any) {
    this.toggleDMSIshareSub.next({ data });
  }

  openNotesDialog(data?: any) {
    this.notesDialogSub.next({ display: true, data });
  }

  setNotesDialogData(data: any) {
    this.notesDialogData.next(data);
  }

  saveTasksToSession(data: any) {
    this.timersSub.next(data);
  }

  openEngagementDialog(data?: any) {
    this.engagementDialogSub.next({ display: true, data });
  }

  setEngagementDialogData(data: any) {
    this.engagementDialogData.next(data);
  }

  openShortCutsDialog(data?: any) {
    this.shortCutsDialogSub.next({ display: true, data });
  }

  setShortCutsDialogData(data: any) {
    this.shortCutsDialogData.next(data);
  }

  openContactDialog(data?: any, contactsList?) {
    this.contactDialogSub.next({ display: true, data, contacts: contactsList });
  }

  setContactDialogData(data: any) {
    this.contactDialogData.next(data);
  }

  openTaskDialog(data?: any) {
    this.taskDialogSub.next({ display: true, data });
  }

  setTaskDialogData(data: any) {
    this.taskDialogData.next(data);
  }

  openTimerDialog(data?: any) {
    this.timerDialogSub.next({ display: true, data });
  }

  setTimerDialogData(data: any) {
    this.timerDialogData.next(data);
  }

  openTimeEntryDialog(data?: any) {
    this.timeEntryDialogSub.next({ display: true, data });
  }

  openInvoiceDialog(data?: any) {
    this.invoiceDialogSub.next({ display: true, data });
  }

  setTimeEntryDialogData(data: any) {
    this.timeEntryDialogData.next(data);
  }

  setInvoiceDialogData(data: any) {
    this.invoiceDialogData.next(data);
  }

  openExpenseDialog(data?: any) {
    this.expenseDialogSub.next({ display: true, data });
  }

  setExpenseDialogData(data: any) {
    this.expenseDialogData.next(data);
  }

  openToDoDialog(data?: any) {
    this.toDoDialogSub.next({ display: true, data });
  }

  setToDoDialogData(data: any) {
    this.toDoDialogData.next(data);
  }

  setTSDialogData(data: any) {
    this.timerDialogData.next(data);
  }

  setEGDialogData(data: any) {
    this.engagementDialogData.next(data);
  }

  setEEDialogData(data: any) {
    this.expenseDialogSub.next(data);
  }

  openLanguageKeys() {
    this.languageKeysSub.next({ display: true });
  }

  openInActivityDialog() {
    this.inActivitySub.next({ display: true });
  }

  openActivityDialog(data?: any) {
    this.activityDialogSub.next({ display: true, data });
  }

  setActivityDialogData(data: any) {
    this.activityDialogData.next(data);
  }

  openExpensesDialog(data?: any) {
    this.expensesDialogSub.next({ display: true, data });
  }

  setExpensesDialogData(data: any) {
    this.expensesDialogData.next(data);
  }

  set timerTask(value) {
    this._timerTask$.next(value);
  }
  get timerTask() {
    return this._timerTask$.asObservable();
  }

  triggerFilterProjectListDialog(option: any) {
    option = option || {};

    if (option.resetStatus === true) {
      Object.keys(option).forEach((field) => {
        this.filterProjectListEventData[field] = undefined;
      });
    } else {
      Object.keys(option).forEach((field) => {
        this.filterProjectListEventData[field] = option[field];
      });
    }

    this.filterProjectListDialog.next(this.filterProjectListEventData);
  }

  openFilterProjectListDialog() {
    this.triggerFilterProjectListDialog({ dialogStatus: true });
  }

  triggerFilterProjectTaskListDialog(option: any) {
    option = option || {};

    if (option.resetStatus === true) {
      Object.keys(option).forEach((field) => {
        this.filterProjectTaskListEventData[field] = undefined;
      });
    } else {
      Object.keys(option).forEach((field) => {
        this.filterProjectTaskListEventData[field] = option[field];
      });
    }

    this.filterProjectTaskListDialog.next(this.filterProjectTaskListEventData);
  }

  openFilterProjectTaskListDialog() {
    this.triggerFilterProjectTaskListDialog({ dialogStatus: true });
  }

  openUpcomingEventsDialogSub() {
    this.upcomingEventSub.next({ display: true });
  }
}
