import { Component, OnInit } from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { MangoApiService } from '@app/_services';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html'
})
export class ImportExportComponent implements OnInit {
  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;

  constructor(
    private router: Router,private mangoAPISrvc: MangoApiService
  ) { 
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    this.setMenuIndex();
    this.loadMenu();
  }

  loadMenu() {
    this.items = [
      {
        label: "Imports",
        routerLink: [`/${AppConstants.importExportRoutePath}/${AppConstants.importsRoutePath}`],
      },
      {
        label: "Exports",
        routerLink: [`/${AppConstants.importExportRoutePath}/${AppConstants.exportsRoutePath}`],
      },
    ];
    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;
    switch (url) {
      case `/${AppConstants.importExportRoutePath}/${AppConstants.exportsRoutePath}`: {
        this.selectedMenuIndex = 1;
        break;
      }
      default: {
        this.selectedMenuIndex = 0;
        break;
      }
    }
  }

}
