import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecurrenceEditorChangeEventArgs } from "@syncfusion/ej2-angular-schedule";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import moment from "moment";
import { RRule } from "rrule";

@Component({
  selector: 'app-rrule-generator',
  templateUrl: './rrule-generator.component.html'
})
export class RruleGeneratorComponent implements OnInit {
  isDisplayRuleDialog: boolean = false;
  projHeaderRule: string = ''
  currentRuleStr: any = "";
  ruledateSource: any = null;
  ruleTwodateSource: any = null;
  rule: any;
  dueDate: any;
  nextDueDate: any;

  @Input() selectedDueDate = new Date();
  @Input() selectedRuleString = '';
  @Output() onCompleteRrule = new EventEmitter<{
    isCompleted: boolean,
    dueDate: any,
    nextDueDate: any,
    rrule: string
  }>();
  constructor() { }

  ngOnInit(): void {
    this.currentRuleStr = this.selectedRuleString ? this.selectedRuleString : '';
    this.projHeaderRule = this.currentRuleStr.replace('RRULE:', '').replace('COUNT=2', '');
  }

  onChange(args: RecurrenceEditorChangeEventArgs): void {
    if(!this.isDisplayRuleDialog)
      return;

    if (!isNullOrUndefined(args.value)) {
      if(args.value == "") {
          this.projHeaderRule = '';
      } else {
        this.projHeaderRule = args.value;
        setTimeout(() => {
          this.currentRuleStr = `RRULE:${this.projHeaderRule}COUNT=2`;
          this.generateRules(this.selectedDueDate);
        }, 300)
      }
    }
  }

  generateRules(date: any, currentRuleStr?: string, isNextDueDate?: boolean) {
    if(currentRuleStr)
      this.currentRuleStr = currentRuleStr

    if (this.currentRuleStr == "" || !this.currentRuleStr) {
      this.ruledateSource = null;
      this.ruleTwodateSource = null;
      return false;
    }
    const dateStart: any = date ? moment(date).format("YYYYMMDDThhmmss") : null;

    this.rule = RRule.fromString(
      dateStart
        ? `${this.currentRuleStr};DTSTART=${dateStart}`
        : this.currentRuleStr
    );
    this.rule.noCache = true;
    if(!isNextDueDate || this.compareDates(date, this.rule.all()[0])) {
      this.ruledateSource = this.rule.all()[0]
        ? this.convertUTCDate(this.rule.all()[0])
        : null;
      this.ruleTwodateSource = this.rule.all()[1]
        ? this.convertUTCDate(this.rule.all()[1])
        : null;
      this.ruledateSource = this.ruledateSource
        ? this.getNearestWeekday(this.ruledateSource)
        : null;
      this.ruleTwodateSource = this.ruleTwodateSource
        ? this.getNearestWeekday(this.ruleTwodateSource)
        : null;
    } else {
      this.ruleTwodateSource = this.rule.all()[0]
        ? this.convertUTCDate(this.rule.all()[0])
        : null;
      this.ruleTwodateSource = this.ruleTwodateSource
        ? this.getNearestWeekday(this.ruleTwodateSource)
        : null;
    }

    if(isNextDueDate)
      return this.ruleTwodateSource ? new Date(this.ruleTwodateSource) : null;
  }

  getNearestWeekday(dateString: string) {
    const dateMoment = moment(dateString?.substring(0, 8));
    if (
      (this.currentRuleStr?.toLowerCase()?.indexOf("freq=yearly") == -1 &&
        this.currentRuleStr?.toLowerCase()?.indexOf("freq=monthly") == -1) ||
      this.currentRuleStr?.toLowerCase()?.indexOf("byday=") > -1
    )
      return dateMoment.format("YYYY/MM/DD");

    if (
      dateMoment.format("dddd") === "Sunday" ||
      dateMoment.format("dddd") === "Saturday"
    ) {
      dateMoment.add(1, "days");
      return this.getNearestWeekday(dateMoment.format("YYYYMMDD"));
    } else return dateMoment.format("YYYY/MM/DD");
  }

  compareDates(date1, date2) {
    return moment(date1).format('YYYY/MM/DD') == moment(date2).format('YYYY/MM/DD')
  }
  
  convertUTCDate(date) {
    const check = moment(date, "YYYY/MM/DD");
    const month = parseInt(check.format("M"));
    const day = parseInt(check.format("D"));
    const year = parseInt(check.format("YYYY"));
    return new RRule({
      dtstart: new Date(Date.UTC(year, month - 1, day, 0, 0, 0)),
    })
      .toString()
      .split("DTSTART:")[1];
  }

  createRule() {
    this.dueDate = this.ruledateSource ? new Date(this.ruledateSource) : null
    this.nextDueDate = this.ruleTwodateSource ? new Date(this.ruleTwodateSource) : null
    
    this.onCloseDialog(true)
  }

  onCloseDialog(isCompleted = false) {
    this.onCompleteRrule.emit({
      isCompleted,
      dueDate: this.dueDate,
      nextDueDate: this.nextDueDate,
      rrule: this.currentRuleStr
    })
    this.clearValues()
    this.isDisplayRuleDialog = false
  }

  clearValues() {
    this.currentRuleStr = ''
    this.dueDate = null
    this.nextDueDate = null
    this.projHeaderRule = ''
    this.ruledateSource = null
    this.ruleTwodateSource = null
    this.rule = null
  }
}
