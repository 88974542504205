<div class="pmslist">
    <!-- buttons -->
    <span class="pull-right p-mr-3 clientform-btn-top">
        <span>
            <p-button *ngIf="shouldShowButton()" pRipple class="p-button-help p-pr-2"
                label="{{ 'filters' | translate }}" icon="fal fa-filter" styleClass="p-button-sm"
                [badge]="hasFilters ? '!' : null" [badgeClass]="hasFilters ? 'p-badge-success' : null"
                (click)="sendData()">
            </p-button>
        </span>
        <!--
        <p-button pRipple label="{{ 'capacity-planning' | translate }}" icon="fal fa-head-side-brain"
            styleClass="p-button-sm" class="p-button-help p-pr-2" (click)="bulkBudget()">
        </p-button>
        -->
        <p-button pRipple label="{{ 'dashboard' | translate }}" icon="fal fa-tachometer-alt-fastest"
            styleClass="p-button-sm" class="p-button-help p-pr-2" routerLink="/project-management/dashboard"></p-button>
        <p-button pRipple label="{{ 'Clients' | translate }}" icon="fal fa-users" styleClass="p-button-sm "
            class="p-button-help p-pr-2" routerLink="/project-management/clientHistory"></p-button>
        <p-button pRipple label="{{ 'PM-Settings' | translate }}" icon="fal fa-cog" styleClass="p-button-sm"
            class="p-button-help p-pr-2" routerLink="/project-management/settings"></p-button>
        <p-splitButton *ngIf="isAllowCreateProject" label=" {{ 'New-Projects' | translate }}" icon="fal fa-plus"
            styleClass="p-button-sm split-button-size" class="p-button-help p-pr-2" [model]="buttonsList"
            (onClick)="redirectProject()" [menuStyleClass]="'new-project-button-list'">
        </p-splitButton>
    </span>

    <div class="      p-grid    ">
        <div class="        p-col-12      ">
            <p-tabMenu [model]="items" [activeItem]="selectedMenu">
            </p-tabMenu>

            <router-outlet>
            </router-outlet>
        </div>
    </div>
</div>


<!-- <p-dialog [(visible)]="isDisplayDetailsTable" [modal]="true" [style]="{width: '50vw'}" [responsive]="true"
    [draggable]="false" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
    <p-header>{{ 'pm.Create-Project-From-Company-Template' | translate }}</p-header>
    <p-table #dt [value]="templateDataSource" (onFilter)="onFilter($event)" [rows]="10" [totalRecords]="templateDataSource.length"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
        [scrollable]="true" scrollHeight="calc(90vh - 280px)" [rowHover]="true" dataKey="MasterTemplateDetailsID"
        [globalFilterFields]="['TemplateName']"  sortField="TemplateName" [sortOrder]="1">
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchValue pInputText type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'Search-by' | translate }}" />
                </span>
                <span>
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="Clear"></button>
                </span>
                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                    text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                </p-message>
            </div>
        </ng-template>
        <!- Table Header ->
        <ng-template pTemplate="header">
            <tr>
                <th class="width-8p p-text-center">
                </th>
                <th class="width-85p p-text-center" pSortableColumn="TemplateName">
                    {{ 'Template-Names' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="TemplateName"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <!- Rows ->
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="width-8p p-text-center">
                    <p-radioButton name="groupname" [(ngModel)]="selectedTemplate" [value]="rowData" [inputId]="rowData.ProjectHeaderID"
                        (click)="radioCompanyClicked($event,rowData)">
                    </p-radioButton>
                </td>
                <td class="width-85p p-text-left">
                    {{rowData.TemplateName}}
                </td>
            </tr>
        </ng-template>
        <!- No data ->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">
                    <p-message severity="info" text="{{ 'data_not_found' | translate }}." styleClass="p-px-2">
                    </p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton label="Create" (click)="createProjectScreen()" [disabled]="!isRadioSelected"></button>
        <button pButton label="Cancel" (click)="isDisplayDetailsTable = false"></button>

    </p-footer>
</p-dialog> -->

<div *ngIf="isDisplayDetailsTable">
    <app-create-project-from-template (onClose)="isDisplayDetailsTable = false">
    </app-create-project-from-template>
</div>