import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DecimalPipe, CurrencyPipe, DatePipe ,AsyncPipe  } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from "@ngx-translate/core";

// PrimeNG Components for demos
import {AccordionModule} from 'primeng/accordion';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import {DividerModule} from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CardModule } from 'primeng/card';
//import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
//import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
// import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PaginatorModule } from 'primeng/paginator';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
//import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import {ChipsModule} from 'primeng/chips';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MegaMenuModule} from 'primeng/megamenu';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ChartModule } from 'primeng/chart';
import { StepsModule } from 'primeng/steps';
import { KeyFilterModule } from 'primeng/keyfilter';
import { BadgeModule } from 'primeng/badge';
import {TimelineModule} from 'primeng/timeline';
import {TreeModule} from 'primeng/tree';
import { ChipModule } from 'primeng/chip';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TieredMenuModule} from 'primeng/tieredmenu';

//import { ToggleButtonModule } from 'primeng/togglebutton';
//import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
//import { TreeModule } from 'primeng/tree';
//import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { BlockUIModule } from 'primeng/blockui';

// Mango compoennets
import { AppNotfoundComponent } from '../../pages/app.notfound.component';
import { AppErrorComponent } from '../../pages/app.error.component';
import { AppAccessdeniedComponent } from '../../pages/app.accessdenied.component';
import { AppMenuComponent } from '../../app.menu.component';
import { AppMenuitemComponent } from '../../app.menuitem.component';
import { AppBreadcrumbComponent } from '../../app.breadcrumb.component';
import { AppConfigComponent } from '../../app.config.component';
import { AppRightPanelComponent } from '../../app.rightpanel.component';
import { AppTopBarComponent } from '../../app.topbar.component';
import { AppMainComponent } from '../../app.main.component';
import { BatchOptionsTimeComponent } from '../components/batch-options-time/batch-options-time.component';
import { DebitMemoDialogComponent } from '../components/debit-memo-dialog/debit-memo-dialog.component';
import { ApplyAdvanceToInvoiceComponent } from '../components/apply-advance-to-invoice/apply-advance-to-invoice.component';
// Common Components
import { FilterPipe, OrderByPipe, RoundoffPipe, AbsolutePipe, ShortNumberPipe } from '@app/shared/pipes';
import { TimerListComponent } from '../../timer-list.component';

// syncfusion modules
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule, ToolbarAllModule } from "@syncfusion/ej2-angular-navigations";
import { ButtonAllModule,CheckBoxAllModule } from "@syncfusion/ej2-angular-buttons";
import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
import { TreeGridAllModule } from "@syncfusion/ej2-angular-treegrid";
// import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
import { polyfill as keyboardEventKeyPolyfill } from 'keyboardevent-key-polyfill';
import { AppTranslationModule } from './app.translation.module';
import { DemoVideo } from '../components/demo-video/demo-video';
import { NewUpdatesAvailableComponent } from '../components/new-updates-available/new-updates-available.component';
import { MentionsComponent } from '../components/mentions/mentions.component';
keyboardEventKeyPolyfill();

@NgModule({
  imports: [CommonModule, TranslateModule,RouterModule, MenuModule, PaginatorModule, ToolbarModule, TabMenuModule, AppTranslationModule, DialogModule, FileUploadModule ,
    ConfirmDialogModule, AutoCompleteModule,CardModule,StepsModule, KeyFilterModule, BadgeModule, TimelineModule, TreeModule, ChipModule, AvatarModule, ProgressSpinnerModule, ToastModule, ButtonModule, DividerModule, TreeGridAllModule, MessagesModule, RadioButtonModule, SelectButtonModule, SplitButtonModule, ChipsModule, SplitterModule, RichTextEditorModule, ColorPickerModule, ToolbarAllModule, ButtonAllModule, CheckBoxAllModule, PickListModule, MegaMenuModule, OverlayPanelModule, ToggleButtonModule, ScrollPanelModule, ChartModule, InputTextareaModule, InputNumberModule, TooltipModule, SidebarModule, TableModule, PanelModule, CheckboxModule, InputTextModule, InputSwitchModule, TieredMenuModule],
  exports: [
    FilterPipe,
    TranslateModule,
    CardModule,
    OrderByPipe,
    RoundoffPipe,
    AbsolutePipe,
    ShortNumberPipe,
    RichTextEditorModule,
    TreeGridAllModule,
    SplitterModule,
    ToolbarAllModule,
    StepsModule,
    KeyFilterModule,
    BadgeModule,
    TimelineModule,
    TreeModule,
    ChipModule,
    AvatarModule,
    ProgressSpinnerModule,
    ButtonAllModule,
    CheckBoxAllModule,
    ReactiveFormsModule,
    BlockUIModule,
    OverlayPanelModule,
    PanelModule,
    MenuModule,
    MegaMenuModule,
    BreadcrumbModule,
    AccordionModule,
    FileUploadModule,
    PickListModule,
    ListboxModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    DataViewModule,
    DialogModule,
    ToggleButtonModule,
    DropdownModule,
    MessageModule,
    MessagesModule,
    ColorPickerModule,
    MultiSelectModule,
    RippleModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    DecimalPipe,
    CurrencyPipe,
    DatePipe, AsyncPipe,
    FormsModule,
    ToastModule,
    HttpClientModule,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent,
    AppRightPanelComponent,
    AppConfigComponent,
    AppBreadcrumbComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    BatchOptionsTimeComponent,
    DebitMemoDialogComponent,
    ApplyAdvanceToInvoiceComponent,
    DemoVideo,
    TabViewModule,
    TableModule,
    InputTextModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    RadioButtonModule,
    SelectButtonModule,
    ButtonModule,
    DividerModule,
    CalendarModule,
    CheckboxModule,
    TooltipModule,
    ToolbarModule,
    SidebarModule,
    SplitButtonModule,
    ChipsModule,
    TabMenuModule,
    ScrollPanelModule,
    ChartModule,
    InputSwitchModule,
    TieredMenuModule,
    MentionsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [AppMainComponent],
  declarations: [
    FilterPipe,
    OrderByPipe,
    MentionsComponent,
    RoundoffPipe,
    AbsolutePipe,
    ShortNumberPipe,
    BatchOptionsTimeComponent,
    DebitMemoDialogComponent,
    ApplyAdvanceToInvoiceComponent,
    DemoVideo,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent,
    AppRightPanelComponent,
    AppConfigComponent,
    AppBreadcrumbComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    TimerListComponent,
    NewUpdatesAvailableComponent
  ]
})
export class AppSharedModule {
  constructor() {
  }

  static forRoot(): ModuleWithProviders<AppSharedModule> {
    return {
      ngModule: AppSharedModule
    };
  }
}
