
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, MangoApiService, EncrDecrService } from "@app/_services";
import { AppConstants } from '@app/_helpers/api-constants';
declare let numeral: any;

@Component({
  selector: 'app-mb-revenue',
  templateUrl: './mb-revenue.component.html'
})
export class MBRevenueComponent implements OnInit {
  data: any;
  options: any;
  colors: any = ['#e57368', '#9ccd65', '#339a65'];
  revenueDetails: any = {
    thismonthsales: "",
    lastyearmonthsales: "",
    lastyearsales: "",
    thisyearsales: "",
    trialsmonth: "",
    cancelled: "",
    convertedtrials: "",
    newcustomers: "",

  };
  billedRevenue: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: "Super Admin Dashboard" },
      { label: "MB Revenue", icon: "ic-red" },
    ]);

    this.options = {
      title: {
        display: true,
        text: "Year on Year Comparison",
        fontSize: 16,
      },
      legend: {
        position: "bottom",
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
                label += ': $';
            }
            label += numeral(tooltipItem.yLabel).format("0,0");
            return label;
        }
        }
      }
    };
  }

  ngOnInit() {
    this.getRevenue()
  }

  private drawCharts() {
    const series = this.billedRevenue.map((yearStats, index) => {
      return {
        label: yearStats.name,
        backgroundColor: this.colors[index],
        borderColor: this.colors[index],
        data: yearStats.data,
      };
    });

    this.data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: series,
    };
  }

  multiplyBy12(data: any) {
    if(typeof(data) === "number")
      return parseFloat(data.toString()) * 12;
    else if (typeof(data) === "string")
      return parseFloat(data.replace("$", "").replace(",", "")) * 12;
    else
      return data;
  }

  changeUSMoneyData(str) {
    const myNumeral = numeral(str);
    if (myNumeral.value() === null) {
      if (str) {
        str = str = "$0.00";
      } else {
        str = "$0.00";
      }
    } else {
      if (str) {
        str = "$" + numeral(str).format("0,0.00");
      } else {
        if (myNumeral.value() < 0) {
          str = 0;
        }
        str = "$" + numeral(str).format("0,0.00");
      }
    }
    return str;
  }

  getRevenue() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getRevenueDetails().subscribe((data: any) => {
      this.revenueDetails = data[0];
      this.revenueDetails.lastyearmonthsales = data[0].lastyearmonthsales ? this.changeUSMoneyData(this.multiplyBy12(data[0].lastyearmonthsales)) : '$0.00'
      this.revenueDetails.lastyearsales = data[0].lastyearsales ? data[0].lastyearsales : '$0.00'
      this.revenueDetails.thismonthsales = data[0].thismonthsales ? this.changeUSMoneyData(this.multiplyBy12(data[0].thismonthsales)) : '$0.00'
      this.revenueDetails.thisyearsales = data[0].thisyearsales ? data[0].thisyearsales : '$0.00'
      this.mangoAPISrvc.getBilledRevenueDetails().subscribe((data: any) => {
        this.billedRevenue = data;
        this.drawCharts()
        this.mangoAPISrvc.showLoader(false)
      }, err => {
        this.mangoAPISrvc.showLoader(false)
        this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg)
      })
    }, err => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg)
    })
  }
}