
<button type="button" pButton pRipple label="{{label}}" [disabled]="disabled" pTooltip="{{tooltip}}" tooltipPosition="left" tooltipStyleClass="tooltip-no-wrap"
    icon="fal fa-file-invoice-dollar" class="p-mr-1 p-button-sm" (click)="showDialog()"></button>

<p-sidebar [(visible)]="isShowDialog" *ngIf="isShowDialog" position="right" (onHide)="onCloseDialog()"
    [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
    <div class="card p-pt-4" style="min-height: calc(100vh - 35px)">
        <div class="p-col-12 p-pl-3 ic-size-15">
            <label class="label-text p-mr-5 p-pr-1">{{ 'customer_name' | translate }}:</label>
            <strong>{{ selectedClient.ClientName }}</strong>
        </div>
        <div class="p-col-12 p-pl-3 ic-size-15">
            <label class="label-text p-mr-5 p-pr-1">{{ 'Accounts.amount_applied' | translate }}:</label>
            <strong>{{ totalApplied | currency : 'USD' : true : '1.2-2' }}</strong>
        </div>
        <div class="p-col-12 p-d-flex p-jc-between p-pl-3">
            <div class="p-py-0 ic-size-15">
                <label class="label-text p-mr-3">{{ 'Accounts.unapplied-amount' | translate }}:</label>
                <strong>{{ totalUnapplied | currency : 'USD' : true : '1.2-2' }}</strong>
            </div>
            <div>
                <button type="button" pButton pRipple label="{{ 'save_button' | translate }}" 
                    [disabled]="selectedPayments.length === 0 || selectedInvoices.length === 0" 
                    tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                    icon="fal fa-check" class="p-mr-2 p-button-sm" (click)="onSave()"></button>
                <button type="button" pButton pRipple label="{{ 'clear' | translate }}" 
                    [disabled]="selectedPayments.length === 0 && selectedInvoices.length === 0" 
                    tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                    icon="fad fa-eraser" class="p-mr-1 p-button-sm" (click)="onClear()"></button>
            </div>
        </div>
        <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
            <p-panel header="{{ 'Accounts.open-retainers' | translate }}" [toggleable]="true">
                <p-table [value]="paymentsDatasource" [responsive]="true" [(selection)]="selectedPayments"
                    (onHeaderCheckboxToggle)="checkPmtHeaderCheckbox()" [rowHover]="true"
                    sortField="PaymentDate" [sortOrder]="1"
                    dataKey="PaymentHeaderID" [scrollable]="true" [scrollHeight]="'calc(48vh - 200px)'"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-3p p-text-center">
                                <!-- <p-tableHeaderCheckbox [disabled]="selectedInvoices.length > 0"></p-tableHeaderCheckbox> -->
                            </th>
                            <th class="width-9p p-text-center" pSortableColumn="PaymentDate">
                                {{ 'Accounts.payment-date' | translate }}
                                <p-sortIcon field="PaymentDate"></p-sortIcon>
                            </th>
                            <th class="width-9p p-text-left" pSortableColumn="CheckRef">
                                {{ 'Accounts.check-reference' | translate }}
                                <p-sortIcon field="CheckRef"></p-sortIcon>
                            </th>
                            <th class="width-7p p-text-left" pSortableColumn="PaymentType">
                                {{ 'Accounts.payment-type' | translate }}
                                <p-sortIcon field="PaymentType"></p-sortIcon>
                            </th>
                            <th class="width-7p p-text-right">{{ 'Accounts.payment-amount' | translate }}</th>
                            <th class="width-7p p-text-right">{{ 'Accounts.retainer_balance' | translate }}</th>
                            <th class="width-7p p-text-right">{{ 'Account.written_off' | translate }}</th>
                            <th class="width-25p p-text-left">{{ 'Account.payment_note' | translate }}</th>
                            <th class="width-5p p-text-center">{{ 'Account.deposited' | translate }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
                        <tr class="cursor-hand">
                            <td class="width-3p p-text-center">
                                <p-tableCheckbox [value]="rowData" [disabled]="selectedInvoices.length > 0" (click)="checkPmtCheckbox(rowData)">
                                </p-tableCheckbox>
                            </td>
                            <td class="width-9p p-text-center">
                                {{ mangoUtils.formatDateString(rowData.PaymentDate) | date:'MM/dd/yyyy'}}
                            </td>
                            <td class="width-9p p-text-left">{{rowData.CheckRef}}</td>
                            <td class="width-7p p-text-left"> {{rowData.PaymentType}}</td>
                            <td class="width-7p p-text-right">{{ rowData.PaymentAmount | currency:'USD':true:'1.2-2'
                                }}</td>
                            <td class="width-7p p-text-right">{{ rowData.PaymentUnapplied |
                                currency:'USD':true:'1.2-2' }}</td>
                            <td class="width-7p p-text-right">{{ rowData.WriteOffAmount |
                                currency:'USD':true:'1.2-2' }}</td>
                            <td class="width-25p p-text-left p-text-nowrap p-text-truncate" showDelay="800"
                                tooltipPosition="top" pTooltip="{{rowData.PaymentNote}}"
                                tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.PaymentNote}}
                            </td>
                            <td class="width-5p p-text-center">
                                <i class="fal fa-university ic-orange" *ngIf="rowData.PaymentToBankID"></i>
                                <i class="fal fa-university" *ngIf="!rowData.PaymentToBankID"
                                    style="opacity: 0.2"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">
                                <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                </p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-panel>
        </div>
        <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
            <p-panel header="{{ 'Accounts.open-invoices' | translate }}" [toggleable]="true">
                <p-table [value]="invoicesDatasource" [responsive]="true" [(selection)]="selectedInvoices"
                    [rowHover]="true" sortField="InvoiceDate" [sortOrder]="1"
                    dataKey="BillingHeaderID" [scrollable]="true" [scrollHeight]="'calc(48vh - 200px)'"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                    <ng-template pTemplate="caption">
                        <!-- <p-checkbox [(ngModel)]="isAutoApply" binary="true" inputId="binary"
                            (click)="onAutoApply()" label="{{'auto_apply' | translate}}">
                        </p-checkbox> -->
                        <button [disabled]="selectedInvoices.length > 0"
                            pButton pRipple icon="fal fa-check" class="p-button-sm"
                            label="{{'auto_apply' | translate}}" (click)="onAutoApply();"></button>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-5p p-text-center">
                                {{ 'Accounts.apply' | translate }}
                            </th>
                            <th pSortableColumn="InvoiceDate" class="width-8p p-text-left">{{
                                'Accounts.invoice-date' | translate }}
                                <p-sortIcon field="InvoiceDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="InvoiceNumber" class="width-8p p-text-left">{{
                                'Accounts.invoice-number' | translate }}
                                <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                            </th>
                            <th class="width-9p p-text-left">{{ 'engagement' | translate }}</th>
                            <th class="width-25p p-text-left">{{
                                'billing-invoicing.invoice-description' | translate }}</th>
                            <th class="width-7p p-text-right">{{ 'Accounts.invoice-amount' |
                                translate }}</th>
                            <th class="width-7p p-text-right">{{ 'billing-invoicing.discount' |
                                translate }}</th>
                            <th class="width-7p p-text-right">{{ 'client.payments' | translate }}
                            </th>
                            <th pSortableColumn="InvoiceBalance" class="width-7p p-text-right">
                                {{ 'balance' | translate }}
                                <p-sortIcon field="InvoiceBalance"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rIndex="rowIndex" let-rowData let-expanded="expanded">
                        <tr class="cursor-hand">
                            <td class="width-5p p-text-center">
                                <p-tableCheckbox [value]="rowData" class="inv-checkbox-{{rowData.BillingHeaderID}}" (click)="checkInvCheckbox(rowData)">
                                </p-tableCheckbox>
                            </td>
                            <td class="width-8p p-text-left"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.InvoiceDate | date:'MM/dd/yyyy'}}
                            </td>
                            <td class="width-8p p-text-left"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.InvoiceNumber}}
                            </td>
                            <td class="width-9p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                pTooltip="{{rowData.EngagementName}}" showDelay="1000"
                                tooltipStyleClass="tooltip-no-wrap"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.EngagementName}}
                            </td>
                            <td class="width-25p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                pTooltip="{{rowData.DescriptionShort}}" tooltipStyleClass="tooltip-width-800"
                                showDelay="1000"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.DescriptionShort}}
                            </td>
                            <td class="width-7p p-text-right"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.InvoiceAmount | currency : 'USD' : true : '1.2-2' }}
                            </td>
                            <td class="width-7p p-text-right"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.Discount | currency : 'USD' : true : '1.2-2' }}
                            </td>
                            <td class="width-7p p-text-right"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{rowData.TotalPayments | currency : 'USD' : true : '1.2-2' }}
                            </td>
                            <td class="width-7p p-text-right"
                                [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                {{(rowData.InvoiceBalance) | currency : 'USD' : true : '1.2-2' }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">
                                <p-message severity="info" text="{{ 'data_not_found' | translate }}" styleClass="p-px-2">
                                </p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-panel>
        </div>
    </div>
</p-sidebar>

