import { Component, OnInit } from '@angular/core';
import {
  BreadcrumbService,
  MangoApiService
} from '@app/_services';

@Component({
  selector: 'app-mail-integration',
  templateUrl: './mail-integration.component.html'
})
export class MailIntegrationComponent implements OnInit {

  email: string;
  emailList: any;
  readContacts: any;
  unreadContacts: any;
  showContactsBox = false;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private breadcrumbService: BreadcrumbService
  ) {
  }

  async ngOnInit() {
    this.getNylasContacts();
  }

  async OnChangeEnableDisableToggle (email) {
    this.mangoAPISrvc.showLoader(true);
    await this.mangoAPISrvc.updateNylasContact(email);
    this.emailList = await this.mangoAPISrvc.getNylasContactsByStaff();
    this.mangoAPISrvc.showLoader(false);
  }

  async getNylasContacts () {
    try {
      this.mangoAPISrvc.showLoader(true);
      const allow = await this.mangoAPISrvc.checkAllowCompanyEmials();
      this.showContactsBox = !!allow;
      if (!allow) {
        this.mangoAPISrvc.showLoader(false);
        return;
      }
      this.readContacts = await this.mangoAPISrvc.getNylasReadContacts();
      this.unreadContacts = await this.mangoAPISrvc.getNylasUnreadContacts();
      this.emailList = await this.mangoAPISrvc.getNylasContactsByStaff();
      this.mangoAPISrvc.showLoader(false);
    }
    catch (err) {
      console.log(err);
    }
  }

  async redirectForAuth () {
    this.mangoAPISrvc.showLoader(true);
    const redirectURL = await this.mangoAPISrvc
      .nylasAuthLinkGenerator({email_address: this.email});
    window.location.href = redirectURL.toString();
  }

  async onMoveToTarget (event: any) {
    const contacts = event.items;
    contacts.forEach(async (contact) => {
      await this.mangoAPISrvc
        .addUnreadContact({nylas_id: contact.nylas_id});
    });
    await this.getNylasContacts();  
  }

  async onMoveToSource (event: any) {
    const contacts = event.items;
    contacts.forEach(async (contact) => {
      await this.mangoAPISrvc
        .removeUnreadContact({nylas_id: contact.nylas_id});
    });
    this.getNylasContacts();
  }
}
