<div class="engagement-list">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <demo-video videoCode="nq1q1tln1h"></demo-video>
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addEngagementPopUp();" tooltipPosition="top"
                pTooltip="{{ 'add' | translate }}"></p-button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mx-2 p-pt-0">
                    <p-table #dt [columns]="selectedColumns" [value]="projectsList" [rowHover]="true" [responsive]="true" [rows]="10"
                        dataKey="ProjectMasterID" selectionMode="single" [showCurrentPageReport]="true"
                        [filterDelay]="0" [(selection)]="selectedProjects"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="ClientName"
                        [globalFilterFields]="['ClientName','EngagementName', 'Description', 'StaffName', 'StartDate', 'Status', 'ProjectMasterID']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)" [rows]="projectsList.length"
                        [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" [virtualScroll]="true">
                        <!-- <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns">
                            </colgroup>
                        </ng-template> -->
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <div class="p-grid">
                                    <div class="p-col-6" style="display: flex; align-items: center;">
                                        <div class="p-mr-5">
                                            <span class="p-input-icon-left">
                                                <i class="fal fa-search"></i>
                                                <input #searchValue pInputText type="text" *ngIf="!isDataLoading"
                                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                    placeholder="{{ 'globalsearch' | translate }}" />
                                                <input #searchValue2 pInputText type="text" [(ngModel)]="searchInputText" 
                                                    [ngModelOptions]="{standalone: true}" (blur)="onInputSearchChange($event)"
                                                    *ngIf="isDataLoading"
                                                    placeholder="{{ 'globalsearch' | translate }}" />
                                            </span>
                                            <span>
                                                <button pButton pRipple type="button" icon="pi pi-times" *ngIf="!isDataLoading"
                                                    class="p-button-rounded p-button-danger p-button-text" (click)="dt.reset();clearSearchFilter()"
                                                    tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                                <button pButton pRipple type="button" icon="pi pi-times" *ngIf="isDataLoading"
                                                    class="p-button-rounded p-button-danger p-button-text" (click)="dt.reset();clearSearchFilter2()"
                                                    tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                            </span>
                                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                                text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                        </div>
                                        <div class="p-mr-5" style="display: flex;align-items: center;" *ngIf="isDataLoading">
                                            <p-progressSpinner [style]="{width: '25px', height: '25px'}" styleClass="custom-spinner" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
                                            <label style="font: smaller;font-weight: 200; color: #87888a;">Loading more data...</label>
                                        </div>
                                       
                                    </div>
                                    <div class="p-col-6">
                                        <div class="pull-right p-mt-1">
                                            <p-multiSelect [options]="dataCols" class="p-pr-1"
                                                [(ngModel)]="selectedColumns"
                                                optionLabel="header"
                                                selectedItemsLabel="{0} {{'columns_selected' | translate}}"
                                                [style]="{minWidth: '200px'}"
                                                placeholder="{{'Choose_Columns' | translate}}"></p-multiSelect>
                                            <button pButton pRipple label="Assign Budget" icon="fal fa-plus" *ngIf="(subscriptionLevel === 'ENTERPRISE' || subscriptionLevel === 'FREE') && isBudgetingAllowed"
                                                [disabled]="selectedProjects?.length == 0" class="p-pr-3 p-mr-3 p-button-sm"
                                                (click)="onShowBudgetAssign();" tooltipPosition="top"
                                                pTooltip="Assign Budget"></button>
                                            <p-checkbox [(ngModel)]="showInactiveClients" (onChange)="onStatusChange()"
                                                [ngModelOptions]="{standalone: true}" [binary]="true"
                                                label="{{ 'show_inactive' | translate }}" class="label-text p-mb-1">
                                            </p-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-4p p-text-center" *ngIf="(subscriptionLevel === 'ENTERPRISE' || subscriptionLevel === 'FREE') && isBudgetingAllowed">
                                    <p-tableHeaderCheckbox>
                                    </p-tableHeaderCheckbox>
                                </th>
                                <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                                <!-- <th pSortableColumn="EngagementName" pResizableColumn class="width-14p p-text-left">
                                    {{ 'client.engagement' | translate }}
                                    <p-sortIcon field="EngagementName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Description" pResizableColumn class="width-15p p-text-left">
                                    {{ 'engagement.type' | translate }}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ClientName" pResizableColumn class="width-20p p-text-left">
                                    {{ 'name' | translate }}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th> -->
                                <!-- <th class="width-8p p-text-left" pResizableColumn>{{ 'assigned_to' | translate}}</th>
                                <th class="width-8p p-text-left" pResizableColumn>{{ 'Billing_Partner' | translate}}</th>
                                <th class="width-8p p-text-left" pResizableColumn>{{ 'Created' | translate }}</th>
                                <th class="width-8p p-text-center" pResizableColumn>{{ 'status' | translate }}</th>
                                <th class="width-8p p-text-right" pResizableColumn>ID#</th> -->
                                <th *ngFor="let col of selectedColumns" [pSortableColumn]="col.field" class="{{col.classes ? col.classes : ''}}">
                                    <div [ngClass]="col.canFilter ? 'p-d-flex p-jc-between' : ''">
                                        <div class="p-mt-1">
                                            {{col.header}}
                                            <p-sortIcon *ngIf="col.canSort" [field]="col.field"></p-sortIcon>

                                        </div>
                                        <p-columnFilter *ngIf="col.canFilter" [type]="col.type || 'text'" [field]="col.field"
                                            display="menu"></p-columnFilter>
                                    </div>
                                </th>
                            </tr> 
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-4p p-text-center" *ngIf="(subscriptionLevel === 'ENTERPRISE' || subscriptionLevel === 'FREE') && isBudgetingAllowed">
                                    <p-tableCheckbox [value]="rowData">
                                    </p-tableCheckbox>
                                </td>
                                <td class="width-6p">
                                    <div class="p-text-center">
                                        <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editEngagement(rowData);$event.stopPropagation();"
                                            tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                        <i pRipple
                                            (click)="deleteEngagement(rowData.ProjectMasterID, rowData);$event.stopPropagation();"
                                            class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                    </div>
                                </td>
                                <!-- <td class="width-14p p-text-left"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.EngagementName}}
                                </td>
                                <td class="width-15p p-text-left"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.Description}}
                                </td>
                                <td class="width-20p p-text-left"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.ClientName}}
                                </td>
                                <td class="width-8p p-text-left"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.StaffName}}
                                </td>
                                <td class="width-8p p-text-left"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.BillingPartnerName}}
                                </td>
                                <td class="width-8p p-text-left"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{ mangoUtils.formatDateString(rowData.StartDate) | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-8p p-text-center"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.Status}}
                                </td>
                                <td class="width-8p p-text-right"
                                    (click)="editEngagement(rowData);$event.stopPropagation();">
                                    {{rowData.ProjectMasterID}}
                                </td> -->

                                <td *ngFor="let col of selectedColumns" class="{{col.classes ? col.classes : ''}} p-text-nowrap p-text-truncate"
                                    (click)="editEngagement(rowData);$event.stopPropagation();"
                                    tooltipPosition="top" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    {{ checkRowData(col.field, rowData[col.field]) }}
            
                                </td>
                            </tr>
                        </ng-template>
                        <!-- <ng-template pTemplate="footer">
                            <tr class="p-text-center">
                                <div *ngIf="isDataLoading">
                                    <p-progressSpinner></p-progressSpinner>
                                </div>
                                <div *ngIf="!isDataLoading && isShowLoadMore">
                                    <button pButton pRipple label="Load More..." 
                                        class="p-pr-3 p-mr-3 p-button-sm"
                                        (click)="getProjects();" tooltipPosition="top"
                                pTooltip="Load More Record"></button>
                                </div>
                               
                            </tr>
                        </ng-template> -->
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">
                                    <p-message severity="info" text="Data not found." styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-batch-assign-budget *ngIf="showAssignBudgetDialog && (subscriptionLevel === 'ENTERPRISE' || subscriptionLevel === 'FREE')" [isBudgetingProjectLevel]="ddmSettings?.isBudgetingProjectLevel"
    [selectedItems]="selectedProjects" (onAssignBudgetsCompleted)="onAssignBudgetsCompleted($event)">
</app-batch-assign-budget>