import { Component, OnInit } from '@angular/core';
import { MangoApiService } from '@app/_services';

@Component(
  (
    {
      selector: 'app-loader',
      template: (
        `
          <div
            *ngIf="
              loadingStatus
            "

            class="spinner"
            style="
              position:sticky;
            "
          >
            <span class="ball-1">
            </span>
            <span class="ball-2">
            </span>
            <span class="ball-3">
            </span>
            <span class="ball-4">
            </span>
            <span class="ball-5">
            </span>
            <span class="ball-6">
            </span>
            <span class="ball-7">
            </span>
            <span class="ball-8">
            </span>
          </div>
          <p-blockUI
            [blocked]="loadingStatus"
          >
          </p-blockUI>
        `
      ),
    }
  )
)
export class LoaderComponent implements OnInit {
  loadingStatus: boolean = false;

  loaderLimitTimeout: any | undefined = undefined;

  constructor( private mangoAPISrvc: MangoApiService ){
    this.mangoAPISrvc.applyLanguage( );
  }

  ngOnInit( ): void {
    this.mangoAPISrvc.isLoading.subscribe(
      (
        ( status ) => {
          this.loadingStatus = status;

          if(
                (
                      status
                  === true
                )
          ){
            if(
                  (
                        typeof
                        this.loaderLimitTimeout
                    !=  "undefined"
                  )
            ){
              clearTimeout( this.loaderLimitTimeout );
              this.loaderLimitTimeout = undefined;
            }

            (
                  this.loaderLimitTimeout
              =   (
                    setTimeout(
                      (
                        ( ) => {
                          this.loadingStatus = false;

                          if(
                                (
                                      typeof
                                      this.loaderLimitTimeout
                                  !=  "undefined"
                                )
                          ){
                            clearTimeout( this.loaderLimitTimeout );
                            this.loaderLimitTimeout = undefined;
                          }
                        }
                      ),

                      (
                          1000
                        *
                          60
                      )
                    )
                  )
            );
          }
          else{
            if(
                  (
                        typeof
                        this.loaderLimitTimeout
                    !=  "undefined"
                  )
            ){
              clearTimeout( this.loaderLimitTimeout );
              this.loaderLimitTimeout = undefined;
            }
          }
        }
      )
    );
  }
}
