<div class="p-grid vertical-container p-mt-6" *ngIf="!isInvalidToken && isApiLoaded">
  <div class="p-col p-as-start" *ngIf="!isMobileResolution">
      <div>&nbsp;</div>
  </div>
  <div class="p-col p-as-center" *ngIf="!isInvalidToken">
      <div class="mobilepayment">
          <div>
              <div class="p-text-center">
                  <img src="assets/mango/img/denied.png" alt="denied" width="350" height="350" class="opacity-4">
              </div>
          </div>
      </div>
  </div>
  <div class="p-col p-as-end" *ngIf="!isMobileResolution">
      <div>&nbsp;</div>
  </div>
</div>
<div class="card" [ngClass]="(isMobileResolution)?'':'p-my-3 p-mx-8'" *ngIf="isInvalidToken && isApiLoaded">
  <div class="p-grid">
      <div class="p-col-12 p-md-8 p-lg-8">
          <h3 class="ic-blue">{{companyData['company'].CompanyName}}</h3>
          <h5>Process Electronic Payment</h5>
      </div>
      <div class="p-col-12 p-md-3 p-lg-3">
          <span class="profile-image-wrapper">
              <img [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"
                  onError="this.src='assets/mango/img/user.png'"
                  alt="{{companyData['company'] && companyData['company']?.CompanyName}}"
                  [ngClass]="{'user-header-img': !imageUrl}" style="height:125px" />
          </span>
      </div>
  </div>
  <div class="p-field" *ngIf="isPaymentCompleted">
      <div class="p-field" *ngIf="isValidServerCode">
          <label for="name" class="label-text width-15p">{{ 'client.name' | translate }}</label>
          <span class="p-text-nowrap p-text-truncate  p-text-bold">
              : {{selClient.ClientName}}</span>
      </div>
      <!-- <div class="p-field">
          <label for="name" class="label-text width-15p">{{ 'Accounts.invoice-date' | translate
              }}</label>
          <span class="p-text-nowrap p-text-truncate  p-text-bold">
              : {{transactionData.created_at | date:'MM/dd/yyyy'}}</span>
      </div> -->
      <div class="p-field">
          <label for="name" class="label-text width-15p">{{ 'Amount Paid' | translate
              }}</label>
          <span class="p-text-nowrap p-text-truncate  p-text-bold">
              : {{transactionData.meta.subtotal || 0 | currency : 'USD' : true :
              '1.2-2'}}</span>
      </div>
      <div class="p-field">
          <label for="name" class="label-text width-15p">{{ 'billing-invoicing.name-on-card' | translate
              }}</label>
          <span class="p-text-nowrap p-text-truncate  p-text-bold">
              : {{transactionData.payment_method.person_name}}</span>
      </div>
      <div class="p-field">
          <label for="name" class="label-text width-15p">{{ 'billing.last-4-digits' | translate
              }}</label>
          <span class="p-text-nowrap p-text-truncate  p-text-bold">
              : XXX{{transactionData.last_four}}</span>
      </div>
      <div class="p-field">
          <label for="name" class="label-text width-15p">{{ 'Card-Type' | translate
              }}</label>
          <span class="p-text-nowrap p-text-truncate  p-text-bold">
              : {{transactionData.payment_method.bin_type === 'CREDIT' ? 'Credit Card' : 'ACH'}}</span>
      </div>
      <!-- <div class="p-field">
          <label for="name" class="label-text width-15p">{{ 'memo' | translate
              }}</label>
          <span class="p-text-nowrap p-text-truncate p-text-bold">
              : {{transactionData.meta.otherField2}}</span>
      </div> -->
  </div>
  <div class="p-text-center" *ngIf="isPaymentCompleted">
      <img src="assets/mango/img/paid_business.svg" alt="Paid" width="150" height="150" class="opacity-4">
  </div>
  <div class="p-fluid p-formgrid p-grid" *ngIf="!isPaymentCompleted">
      <!-- Email -->
      <div class="p-field p-col-12 p-md-6  p-mb-0 p-pb-0" *ngIf="isSTaxEnabled">
          <label class="label-text">Email <span class="ic-red imges">(Optional)</span></label>
          <input [(ngModel)]="clientEmail" autocomplete="off" [pattern]="emailPattern"
              [disabled]="(isEmailProvided && isValidServerCode)" placeholder="Enter Valid Email"
              (blur)="isValidEmail(userEmail)" type="email" name="emailId" required #userEmail="ngModel" pInputText
              [email]="true" style="width:92%">
          <span *ngIf="isEmailProvided && isValidServerCode ">
              <i class="fas p-pl-2 ic-size-20 cursor-hand fa-check-circle ic-green"></i>
          </span>
          <div *ngIf="userEmail.errors?.pattern" class="ic-red p-pl-1">
              Email not valid.
          </div>
      </div>
      <!-- Secure code -->
      <div class="p-field p-col-12 p-md-6 p-mb-0 p-pb-0" *ngIf="isEmailProvided && !isValidServerCode">
          <label class="label-text">Enter Security Code
              <span class="ic-red p-pl-1 imges">(from Email)</span>

              <!-- <span class="ic-red p-pl-3"
                  *ngIf="PassWordOtp && superAdminVisible.includes(companyData.company.CompanyID)">  Security Code :
                  {{PassWordOtp}} </span> -->
          </label>
          <input id="lastname3" type="text" [(ngModel)]="clientPasscode" autocomplete="off" name="passcode"
              (blur)="isValidPasscode()" #passcode="ngModel" maxlength="4"
              [disabled]="(isEmailProvided && isValidServerCode)" style="width: 86%" required pInputText>
      </div>
      <!-- Select Company -->
      <div class="p-field p-col-12  p-md-6 p-mb-0 p-pb-0" *ngIf="isEmailProvided && isValidServerCode">
          <label class="label-text required-field">Select Company</label>
          <p-dropdown [options]="clients" [(ngModel)]="selectedClient" optionLabel="label" filter="true"
              [disabled]="clients.length <= 1" (onChange)="changeClientName()">
          </p-dropdown>
      </div>
      <!-- table -->
      <div class="p-field p-col-12 hostedPayments p-mt-2"
          *ngIf="isSTaxEnabled && isEmailProvided && isValidServerCode && selectedClient && selectedClient.value && companyData.invoices.length == 0">
          <div>
              <p-message severity="info" text="There is no invoice to pay." styleClass="p-px-2">
              </p-message>
          </div>
      </div>
      <div class="p-field p-col-12 p-md-12 p-pl-0">
          <form role="form" [formGroup]="myPaymentForm" autocomplete="off" novalidate>
              <div class="p-d-flex p-field">
                  <!-- Single Option -->
                  <div *ngIf="cardOptionsList.length == 1 && isSTaxEnabled" class="p-field p-md-6">
                      <label for="paymenttype" class="label-text p-d-block">{{ 'Accounts.payment-type' |
                          translate }} </label>
                      <div class="p-text-nowrap p-text-truncate">
                          {{cardOptionsList[0].label}}</div>
                  </div>
                  <!-- multiple Option -->
                  <div *ngIf="cardOptionsList.length > 1 && isSTaxEnabled" class="p-field p-md-6">
                      <label for="paymenttype" class="label-text p-d-block required-field">{{ 'Accounts.payment-type' |
                          translate }}</label>
                      <p-dropdown [options]="cardOptionsList" [(ngModel)]="selectedModeOfPayment"
                          *ngIf="cardOptionsList.length > 1" [style]="{'width':'92%'}"
                          [ngModelOptions]="{ standalone: true }" (onChange)="changePaymentType()">
                      </p-dropdown>
                  </div>
                  <div class="p-field p-md-2 p-mt-2" *ngIf="companyData.invoices.length > 0">
                      <label for="total-amount" class="label-text p-d-block">{{ 'total-amount' |
                          translate }}</label>
                      <div class="p-text-nowrap p-text-truncate">
                          <strong>${{totalNumberAmt}}</strong>
                      </div>
                  </div>
                  <div class="p-field p-md-4" *ngIf="companyData.invoices.length > 0">
                      <button pButton pRipple type="button" label="{{ 'Accounts.apply' |
                      translate }}" class="p-button-sm p-pr-3 p-mr-2 p-my-4 host-btn" (click)="showSideBar();"
                          [disabled]="selectedModeOfPayment == -1 || myPaymentForm.get('Amount').value <= 0 || !myPaymentForm.get('Amount').value"></button>
                  </div>
              </div>
              <div class="p-d-flex p-field" *ngIf="selectedModeOfPayment > -1">
                  <div class="p-mx-2">
                      <label for="amt" class="label-text p-d-block p-mb-1 required-field">Enter {{ 'amount' |
                          translate }}</label>
                      <div>
                          <p-inputNumber formControlName="Amount" mode="currency" currency="USD" locale="en-US"
                              [disabled]="selectedItems.length > 0" [maxFractionDigits]="2" placeholder="$0.00"
                              inputStyleClass="width-120px" (onBlur)="calculateAppliedSurcharge()"
                              (focus)="$event.target.select()">
                          </p-inputNumber>
                      </div>
                  </div>
                  <div class="p-mr-2" *ngIf="isValidServerCode">
                      <label for="amt" class="label-text p-d-block p-mb-1">{{ 'Accounts.amount_applied' |
                          translate }}</label>
                      <div *ngIf="!isPaymentCompleted">
                          <p-inputNumber disabled="true" inputStyleClass="width-120px"
                              formControlName="PaymentApplied" mode="currency" currency="USD" locale="en-US" placeholder="$0.00"
                              [maxFractionDigits]="2">
                          </p-inputNumber>
                      </div>
                  </div>
                  <div class="p-mr-2" *ngIf="isValidServerCode">
                      <label for="amt" class="label-text p-d-block p-mb-1">{{ 'Applied' |
                          translate }} {{ 'balance' |
                          translate }}</label>
                      <div *ngIf="!isPaymentCompleted">
                          <p-inputNumber disabled="true" inputStyleClass="width-120px"
                              formControlName="PaymentUnapplied" mode="currency" currency="USD" locale="en-US" placeholder="$0.00"
                              [maxFractionDigits]="2">
                          </p-inputNumber>
                      </div>
                  </div>
                  <div class="p-mr-2"
                      *ngIf="cardProfile && isCrCard && myPaymentForm.get('SurChargeAmount').value > 0">
                      <label for="amt" class="label-text p-d-block p-mb-1">Surcharge</label>
                      <div *ngIf="!isPaymentCompleted">
                          <p-inputNumber disabled="true" inputStyleClass="width-120px"
                              formControlName="SurChargeAmount" mode="currency" currency="USD" locale="en-US"
                              [maxFractionDigits]="2">
                          </p-inputNumber>
                      </div>
                  </div>
                  <div *ngIf="cardProfile && isCrCard && myPaymentForm.get('SurChargeAmount').value > 0">
                      <label for="amt" class="label-text p-d-block p-mb-1">Total Amount</label>
                      <div *ngIf="!isPaymentCompleted">
                          <p-inputNumber disabled="true" inputStyleClass="width-120px" formControlName="withSurcharge"
                              mode="currency" currency="USD" locale="en-US" [maxFractionDigits]="2">
                          </p-inputNumber>
                      </div>
                  </div>
              </div>
              <div class="p-field p-col-7" *ngIf="selectedModeOfPayment > -1">
                  <label for="paymenttype" class="label-text p-d-block">{{
                      'payment-memo-description' | translate
                      }}</label>

                  <input type="text" pInputText formControlName="memo" />
              </div>
              <div *ngIf="cardProfile && isSelctedPaymentIndex > -1 && !isStaxNewCard">
                  <div class="p-field">
                      <label for="paymenttype" class="label-text p-d-block" *ngIf="isCrCard">{{
                          'billing-invoicing.name-on-card' | translate
                          }}</label>
                      <label for="paymenttype" class="label-text p-d-block" *ngIf="!isCrCard">{{
                          'user.Name' | translate
                          }}</label>
                      <div>{{cardProfile.NameOnCard}}</div>
                  </div>
                  <div class="p-field p-mb-0">
                      <label for="paymenttype" class="label-text p-d-block" *ngIf="isCrCard">
                          <span *ngIf="!isDebitCard">
                              <label for="uname" class="label-text">{{ 'last-4digits-crcard' | translate
                                  }}</label>
                          </span>
                          <span *ngIf="isDebitCard">
                              <label for="uname" class="label-text">{{ 'last-4digits-dbcard' | translate
                                  }}</label>
                          </span>
                      </label>
                      <label for="paymenttype" class="label-text p-d-block" *ngIf="!isCrCard">{{
                          'last-4digits-bank' |
                          translate }}</label>
                      <div> ****{{cardProfile.CardNo}}
                          <span *ngIf="cardProfile['CardType'] && !isCrCard">
                              <i class="far fa-money-check ic-green p-ml-2 ic-size-22" tooltipPosition="top"
                                  pTooltip="{{cardProfile['CardType']}}" showDelay="800"
                                  tooltipStyleClass="tooltip-no-wrap"></i>
                          </span>
                          <span *ngIf="isCrCard && cardProfile['CardType'] === 'visa'" tooltipPosition="top"
                              pTooltip="Visa" showDelay="800" class="p-ml-2 crcardimg"
                              tooltipStyleClass="tooltip-no-wrap">
                              <img src="assets/mango/svg/visa.svg">
                          </span>
                          <span *ngIf="isCrCard && cardProfile['CardType'] === 'mastercard'" tooltipPosition="top"
                              pTooltip="Mastercard" showDelay="800" class="p-ml-2 crcardimg"
                              tooltipStyleClass="tooltip-no-wrap">
                              <img src="assets/mango/svg/mastercard.svg">
                          </span>
                          <span *ngIf="isCrCard && cardProfile['CardType'] === 'discover'" tooltipPosition="top"
                              pTooltip="Discover" showDelay="800" class="p-ml-2 crcardimg"
                              tooltipStyleClass="tooltip-no-wrap">
                              <img src="assets/mango/svg/discover.svg">
                          </span>
                          <span *ngIf="isCrCard && cardProfile['CardType'] === 'amex'" tooltipPosition="top"
                              pTooltip="Amex" showDelay="800" class="p-ml-2 crcardimg"
                              tooltipStyleClass="tooltip-no-wrap">
                              <img src="assets/mango/svg/amex.svg">
                          </span>

                      </div>
                  </div>
              </div>
              <div *ngIf="isStaxNewCard && isSelctedPaymentIndex > -1" class="p-col-7">
                  <div class="p-fluid p-formgrid p-grid">
                      <div class="p-field p-col">
                          <label for="amt" class="required-field label-text p-d-block">{{ 'fname' | translate
                              }}</label>
                          <input type="text" pInputText formControlName="FirstName" />
                      </div>
                      <div class="p-field p-col">
                          <label for="amt" class="required-field label-text p-d-block">{{ 'lname' | translate
                              }}</label>
                          <input type="text" pInputText formControlName="LastName" />
                      </div>
                  </div>
                  <div *ngIf="isCrCard">
                      <div class="p-field">
                          <label for="cardNumber" class="required-field label-text">{{ 'user.user_address' |
                              translate }}</label> <span class="p-text-italic p-ml-2 ic-red"
                              style="font-size: 11px">(Address must match billing
                              address on Credit Card)</span>
                          <input type="text" pInputText formControlName="Address1" />
                      </div>

                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-4 p-pl-0">
                              <label for="cardExpiry" class="required-field label-text"><span>{{
                                      'client.city' | translate }}</span></label>
                              <input type="text" formControlName="City" pInputText autocomplete="nope" maxlength="50">
                          </div>
                          <div class="p-field p-col-4 p-pr-0">
                              <label for="cardExpiry" class="required-field label-text"><span>{{
                                      'client.state' | translate }}</span></label>
                              <input type="text" formControlName="State" pInputText autocomplete="nope"
                                  maxlength="50">
                          </div>
                          <div class="p-field p-col-4 p-pr-0">
                              <label class="label-text required-field">{{ 'client.zip' | translate }}</label>
                              <input type="text" pInputText class="p-text-right" formControlName="Zip" maxlength="10"
                                  (blur)="focusCard(0,'card_number')" autocomplete="nope"
                                  (blur)="focusCard(0,'card_number')">
                          </div>
                      </div>
                      <div class="p-field p-col-12 p-pl-0">
                          <label for="cardNumber" class="required-field label-text p-d-block">{{
                              'billing-invoicing.card-number' | translate }}</label>
                          <div id="fattjs-number" style="height:35px;">
                          </div>
                      </div>
                      <div class="p-fluid p-formgrid p-grid">
                          <div class="p-field p-col">
                              <label for="amt" class="required-field label-text p-d-block">{{
                                  'billing-invoicing.expiration-date' | translate }}</label>
                              <p-inputMask mask="99/9999" formControlName="expDate" autofocus
                                  (onBlur)="focusCard(1,'cvv')">
                              </p-inputMask>
                          </div>
                          <div class="p-field p-col">
                              <label for="amt" class="required-field label-text p-d-block">{{ 'cv-code' |
                                  translate }}</label>
                              <div id="fattjs-cvv" style="height:35px;">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div *ngIf="!isCrCard">
                      <div class="p-field">
                          <label for="amt" class="required-field label-text p-d-block">Account to
                              Debit</label>
                          <p-dropdown [options]="transCodeTypes" formControlName="TransCode">
                          </p-dropdown>
                      </div>
                      <div class="p-fluid p-formgrid p-grid">
                          <div class="p-field p-col">
                              <label for="amt" class="required-field label-text p-d-block">Routing
                                  Number</label>
                              <input type="text" pInputText formControlName="RoutingNumber" />
                          </div>
                          <div class="p-field p-col">
                              <label for="amt" class="required-field label-text p-d-block">Account
                                  Number</label>
                              <input type="text" pInputText formControlName="AccountNumber" />
                          </div>
                      </div>
                      <div class="p-field">
                          <p-radioButton name="groupname" value="false" [(ngModel)]="isBusiness"
                              [ngModelOptions]="{standalone: true}" label="Personal" labelStyleClass="p-pr-2">
                          </p-radioButton>
                          <p-radioButton name="groupname" value="true" [(ngModel)]="isBusiness"
                              [ngModelOptions]="{standalone: true}" label="Business">
                          </p-radioButton>
                      </div>
                  </div>
              </div>
              <!-- no Option -->
              <div *ngIf="cardOptionsList.length == 0 || !isSTaxEnabled " class="p-fluid">
                  <div class="p-field ic-red" *ngIf="!selectedClient">
                      <p-message severity="info" text=" Payment Options are not enabled." styleClass="p-px-2">
                      </p-message>
                  </div>
              </div>
          </form>
      </div>
      <div class="p-field p-pl-3" *ngIf="isApiLoaded && isInvalidToken && selectedModeOfPayment > -1">
          <button pButton pRipple type="button" label="Pay Now" class="p-button-sm p-pr-3 p-mr-2 p-my-2 host-btn"
              *ngIf="isInvalidToken && isSTaxEnabled && cardOptionsList.length > 0 && isSelctedPaymentIndex > -1  && !companyData.company.isSurchargeEnabled"
              (click)="processPayment();"></button>

          <button pButton pRipple type="button" label="Pay Now" class="p-button-sm p-pr-3 p-mr-2 p-my-2  host-btn"
              *ngIf="isInvalidToken && isSTaxEnabled && cardOptionsList.length > 0 && isSelctedPaymentIndex > -1 && !isStaxNewCard && companyData.company.isSurchargeEnabled"
              [disabled]="isProcessing" (click)="processPayment();"></button>

          <button pButton pRipple type="button" label="{{ 'authorize-card' | translate }}"
              class="p-button-sm p-pr-3 p-mr-2 p-my-2  host-btn"
              *ngIf="isInvalidToken && isSTaxEnabled && isCrCard &&  cardOptionsList.length > 0 && isSelctedPaymentIndex > -1 && isStaxNewCard && companyData.company.isSurchargeEnabled"
              [disabled]="isProcessing" (click)="authorizeSTaxNewCard();"></button>

          <button pButton pRipple type="button" label="{{ 'authorize-bank' | translate }}"
              class="p-button-sm p-pr-3 p-mr-2 p-my-2  host-btn"
              *ngIf="isInvalidToken && isSTaxEnabled && !isCrCard &&  cardOptionsList.length > 0 && isSelctedPaymentIndex > -1 && isStaxNewCard && companyData.company.isSurchargeEnabled"
              [disabled]="isProcessing" (click)="authorizeSTaxNewCard();"></button>

          <button pButton pRipple type="button" label="{{ 'add-new-bank' | translate }}"
              class="p-button-sm p-pr-3 p-mr-2 p-my-2  host-btn"
              *ngIf="isInvalidToken && isSTaxEnabled && !isStaxNewCard &&  cardOptionsList.length > 0 && isSelctedPaymentIndex == 1 && companyData.cards.length > 0"
              (click)="addNewStax('bank')" [disabled]="isStaxNewCard"></button>

          <button pButton pRipple type="button" label="{{ 'Add-New-Card' | translate }}"
              class="p-button-sm p-pr-3 p-mr-2 p-my-2  host-btn"
              *ngIf="isInvalidToken && isSTaxEnabled && !isStaxNewCard && cardOptionsList.length > 0 && isSelctedPaymentIndex == 0 && companyData.cards.length > 0"
              (click)="genarateIFrames();addNewStax('card')" [disabled]="isStaxNewCard"></button>

          <button pButton pRipple type="button" label="Close Window"
              class="p-button-sm p-pr-3 p-mr-2 p-my-2  host-btn"
              *ngIf="!isInvalidToken || cardOptionsList.length == 0 || !isSTaxEnabled"
              (click)="closeWindow()"></button>
      </div>
  </div>
</div>

<p-sidebar [(visible)]="showClients" position="right" [closeOnEscape]="false " [dismissible]="false "
  [showCloseIcon]="false" [style]="{'min-width':'60%','overflow': 'auto'}">
  <div class="p-grid">
      <div class="p-col-12">
          <div class="card" *ngIf="openInvoices.length > 0">
              <h3 class="first">{{selClient.ClientName}}(Open Invoices)</h3>
              <p-table [value]="openInvoices" [scrollable]="true" [scrollHeight]="'calc(90vh - 150px)'"
                  styleClass="p-datatable-responsive-demo" [(selection)]="selectedItems">
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="width-12p p-text-center">{{'Accounts.invoice-number' | translate}}</th>
                          <th class="width-12p p-text-center">{{'Accounts.invoice-date' | translate}}</th>
                          <th class="width-20p p-text-left">{{
                              'Accounts.invoice-memo' | translate }}</th>
                          <th class="width-12p p-text-right">{{'Accounts.invoice-amount' | translate}}</th>
                          <th class="width-12p p-text-right">{{'Accounts.invoice-balance' | translate}}</th>
                          <th class="width-15p p-text-right">{{ 'total-payments' | translate }}</th>
                          <th class="width-10p p-text-right">{{'Accounts.apply' | translate}}</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-invoice>
                      <tr>
                          <td class="width-12p p-text-center">{{invoice.InvoiceNumber}}</td>
                          <td class="width-12p p-text-center">{{invoice.InvoiceDate | date:'MM/dd/yyyy'}}</td>
                          <td class="width-20p p-text-leftr">{{invoice.DescriptionShort}}</td>
                          <td class="width-12p p-text-right">{{invoice.InvoiceAmount | currency : 'USD' : true : '1.2-2'}}
                      </td>
                          <td class="width-12p p-text-right">{{invoice.InvoiceBalance | currency : 'USD' : true :
                              '1.2-2'}}</td>
                          <td class="width-15p p-text-right">{{invoice.TotalPayments | currency : 'USD' : true :
                          '1.2-2'}}</td>
                          <td class="width-10p p-text-right">
                              <p-checkbox [(ngModel)]="invoice['isChecked']" binary="true"
                                  [ngModelOptions]="{ standalone: true }" (onChange)="onAmountChange(invoice)">
                              </p-checkbox>
                          </td>
                      </tr>
                  </ng-template>
                  <!-- Footer -->
                  <ng-template pTemplate="footer">
                      <tr *ngIf="openInvoices.length > 1">
                          <td class="width-12p">&nbsp;
                          </td>
                          <td class="width-12p p-text-right ic-red">{{ 'Totals' | translate }}</td>
                          <td class="width-12p p-text-right ic-red p-pr-2">
                              <strong>${{totalNumberAmt}}</strong>
                          </td>
                          <td class="width-10p">&nbsp;
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="4">
                              <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                  styleClass="p-px-2">
                              </p-message>
                          </td>
                      </tr>
                  </ng-template>
                  <!-- No data -->
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td [attr.colspan]="3">
                              <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                              </p-message>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
      </div>
  </div>
  <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
          <button type="button" pButton pRipple (click)="applytoInvoices()" class="p-mr-2"
              label="{{ 'save_and_close' | translate }}" [disabled]="selectedItems.length == 0"></button>
          <button type="button" pButton pRipple (click)="cancelClientSelection()"
              label="{{ 'cancel' | translate }}"></button>
      </div>
  </ng-template>
</p-sidebar>
