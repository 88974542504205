import { Component, OnInit } from '@angular/core';
import { MangoApiService,EncrDecrService } from '@app/_services';
import { AppConstants } from "@app/_helpers/api-constants";

@Component({
  selector: 'app-time-sheet-entry',
  templateUrl: './time-sheet-entry.component.html'
})
export class TimeSheetEntryComponent implements OnInit {

  constructor(private mangoAPISrvc: MangoApiService,private encrDecSrvc: EncrDecrService, ) { 
    this.mangoAPISrvc.applyLanguage();
    this.mangoAPISrvc.notifyLogging(
      this.encrDecSrvc.getObject(AppConstants.isLoggingUser)
    );
  }
  ngOnInit() { }
}
