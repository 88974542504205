import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-dms',
  templateUrl: './document-management-system.component.html'
})
export class dmsComponent implements OnInit {

  constructor(private mangoAPISrvc: MangoApiService, private translate: TranslateService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService) {
    this.mangoAPISrvc.applyLanguage();
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }
  
  ngOnInit() {
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Document-Management-System') }
    ]);
  }

}
