import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '../shared-components-service';

declare let numeral: any;
@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html'
})
export class ExpensesComponent implements OnInit {
  displayAddExpenseModal: boolean = false;
  expense: any = {};
  expenseList: any = [];
  expenseCode: any = '';
  isEditExpenses;
  public timeAndExpenseData: any = null;
  @Output() closeExpensesModal = new EventEmitter<any>();
  @ViewChild('ExpenseMainForm') ngForm;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService,
    private router: Router,
    public sharedSrvc: SharedComponentsService,
  ) { }

  ngOnInit(): void {
    this.timeAndExpenseData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
  }

  openAddExpenses(data) {
    this.expense = {};
    this.isEditExpenses = data.isEditFlow;
    this.expense = data.expense
    this.expense.cost = this.doMoneyFormate(data.expense.Cost);
    this.displayAddExpenseModal = true;
  }

  onChangeInputCode() {
    this.expense.ExpenseCode = this.expense.ExpenseCode.toUpperCase();
    this.expenseCode =  this.expense.ExpenseCode;
  }

  saveAndNew(IsEdit) {
    this.saveExpenses(!IsEdit, false);
  }

  getExpenses() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getExpenses().subscribe(function (data:any) {
      const expenseList = data;
      for (let i = 0; i < data.length; i++) {
        expenseList[i].index = i;
      }
      _this.encrDecSrvc.addObject(AppConstants.expenses, expenseList);
      _this.sharedSrvc.activityDialogData.next(true);
      _this.mangoAPISrvc.showLoader(false);

    }, error => {
      _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  doMoneyFormate(inputValue) {
    const value = inputValue;
    const myNumeral = numeral(value);
    if (myNumeral.value() === null) {
      this.expense.Cost = 0.0;
    }
    this.expense.Cost = "$" + numeral(myNumeral.value()).format("0,0.00");
  }

  saveExpenses(isSave, isClose) {
    const parent = this;
    parent.expense.Cost = numeral(parent.expense.Cost).value()?.toString();
    parent.expense.Markup = numeral(parent.expense.Markup).value()?.toString();
    if (typeof (parent.expense.TaxMap1) == "object") {
      //parent.expense.TaxMap2 = false;
      parent.expense.TaxMap1 = true;
    }
    // if (typeof (parent.expense.TaxMap2) == "object") {
    //   parent.expense.TaxMap1 = false;
    //   //parent.expense.TaxMap2 = true;
    // }
    parent.mangoAPISrvc.showLoader(true);
    if (isSave || parent.expense['ExpenseCodeID'] === null) {
      delete this.expense['ExpenseCodeID'];
      parent.mangoAPISrvc.saveExpenses(parent.expense).subscribe(function (data) {
        parent.getExpenses();
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.createMsg);
        parent.mangoAPISrvc.showLoader(false);
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      }, error => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.createErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    } else {
      if (parent.expense.Markup == null)
      parent.expense.Markup = "0.0%";
      parent.mangoAPISrvc.updateExpenses(parent.expense.ExpenseCodeID, parent.expense).subscribe(function (data) {
        parent.getExpenses();
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.mangoAPISrvc.showLoader(false);
      }, error => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    }
    if (isClose) {
      parent.displayAddExpenseModal = false;
    } else {
      this.isEditExpenses = false;
      this.expense = { "ExpenseCodeID": null, "TaxMap1": false, "Billable": true, "Inactive": false, "Description": "", "ExpenseCode": "", "Cost": "0", "Markup": "0" };
    }
  }

  closeExpenses(doNotRefresh?) {
    const url = this.router.url;
    const urls = ['/settings/expenses']
    if ((this.ngForm.valid || this.ngForm.dirty) && (urls.includes(url))) {
      this.sharedSrvc.setTimeEntryDialogData(true);
    }

    if (!doNotRefresh)
      this.closeExpensesModal.emit(true);

    this.expense = {};
    this.expense.TaxMap1 = false;
    // this.expense.TaxMap2 = false;
    // this.expense.TaxMap3 = false;
    this.expense.Billable = true;
    this.expense.Inactive = false;

    this.ngForm.form.markAsPristine();
    this.ngForm.form.markAsUntouched();
    this.ngForm.form.updateValueAndValidity();

    this.expense.Description = "";
    this.expense.ExpenseCode = "";
    this.expense.Cost = "";
    this.expense.Markup = " 0";
    this.displayAddExpenseModal = false;
    this.sharedSrvc.sharedCompVisibility.expenses = false;
  }

}
