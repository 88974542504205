<div class="editDeposit">
    <div class="layout-content p-pt-0">
        <div class="p-grid p-mx-2">
            <div class="p-col-12 p-mt-2">
                <div class="card p-py-3">
                    <p-table [value]="depositDatasource" [responsive]="true" sortField="DepositDate"
                        (onFilter)="onFilter($event, 'header')"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        dataKey="PaymentToBankID" [sortOrder]="-1" sortMode="single"  editMode="row"
                        [globalFilterFields]="['DepositDate', 'DepositAmount', 'DepositMemo', 'BankName']"
                        [rowHover]="true" #dt [scrollable]="true" [scrollHeight]="'calc(90vh - 350px)'">
                        <ng-template pTemplate="caption">
                            <div class="table-header p-d-flex p-jc-between">
                                <div>
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter('header')" tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                    </p-message>
                                </div>
                                <div>
                                    <label class="label-text p-mr-2">{{'All_Dates' | translate}}</label>
                                    <p-dropdown [options]="dateRangeList" [style]="{width: '140px'}" [ngModelOptions]="{standalone: true}" [(ngModel)]="DateRange"
                                        (onChange)="changeDateTypes()"
                                        scrollHeight="500px" placeholder="Select Date" required></p-dropdown>
                                    <label class="label-text p-mx-2">{{'date-from' | translate}}</label>
                                    <p-calendar [monthNavigator]="true" [ngModelOptions]="{standalone: true}" [yearNavigator]="true" yearRange="2000:2090"
                                        [(ngModel)]="DateFrom" [style]="{width: '100px'}" [disabled]="IsDateTypeReadOnly" required>
                                    </p-calendar>
                                    <label class="label-text p-mx-2">{{'date-to' | translate}}</label>
                                    <p-calendar [monthNavigator]="true" [ngModelOptions]="{standalone: true}" [yearNavigator]="true" yearRange="2000:2090"
                                        [(ngModel)]="DateTo" [style]="{width: '100px'}" [disabled]="IsDateTypeReadOnly"></p-calendar>
                                    <label class="label-text p-mx-2">{{'settings.Bank-Name' | translate}}</label>
                                    <p-dropdown placeholder="Select Bank Name" [options]="bankList" [(ngModel)]="selectedBank" scrollHeight="350px"
                                        name="bankName" [style]="{width: '250px'}" id="bankName" (onChange)="onChangeFilters($event, 'bankName')" #bankName="ngModel"
                                        [ngModelOptions]="{standalone: true}"></p-dropdown>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-6p p-text-center">
                                    {{'action' | translate}}
                                </th>
                                <th
                                  class="
                                    width-7p
                                    p-text-left
                                  "

                                  pSortableColumn="DepositDate"
                                >
                                  {{ 'deposit-date' | translate }}
                                  <p-sortIcon
                                    field="DepositDate"
                                  >
                                  </p-sortIcon>
                                </th>
                                <th class="width-20p p-text-left">{{'settings.Bank-Name' | translate}}</th>
                                <th class="width-40p p-text-left">{{'memo' | translate}}</th>
                                <th class="width-7p p-text-right">{{'amount' | translate}}</th>
                                <th class="width-10p p-text-center"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-editing="editing">
                            <tr class="cursor-hand" [pEditableRow]="rowData">
                                <td class="width-6p p-text-center">
                                    <div class="p-text-center">
                                        <i class="fal fa-dollar-sign ic-red p-pr-3" pTooltip="{{'edit_payments' | translate}}"
                                            tooltipPosition="top" (click)="editDeposit(rowData)"></i>
                                        <i class="fal fa-pencil ic-green p-pr-3" pTooltip="{{'accounts.edit-deposit' | translate}}"
                                            tooltipPosition="top" pInitEditableRow (click)="onRowEditInit(rowData)"></i>
                                        <i class="fal fa-trash-alt ic-red p-pr-2" pTooltip="{{'accounts.delete-deposit' | translate}}"
                                            tooltipPosition="top" (click)="onRowDelete(rowData)"></i>
                                    </div>
                                </td>

                                <td
                                  pInitEditableRow

                                  class="
                                    width-7p
                                    p-text-left
                                  "

                                  (click)="onRowEditInit(rowData)"
                                >
                                  <p-cellEditor>
                                    <ng-template
                                      pTemplate="input"
                                    >
                                      <p-calendar
                                        class="calender-component"

                                        showButtonBar="true"
                                        appendTo="body"
                                        yearRange="1000:2099"
                                        dateFormat="mm/dd/yy"

                                        [monthNavigator]="true"
                                        [yearNavigator]="true"
                                        [ngModelOptions]="{standalone: true}"

                                        [(ngModel)]="rowData['DepositDate']"

                                        (onSelect)="
                                          onSelectDate(rowData, 'DepositDate');
                                          verifySystemLocking($event);
                                          validateDepositDate( rowData[ 'DepositDate' ] );
                                        "
                                      >
                                      </p-calendar>
                                    </ng-template>
                                    <ng-template
                                      pTemplate="output"
                                    >
                                      {{ rowData['DepositDate'] | date:'MM/dd/yyyy'}}
                                    </ng-template>
                                  </p-cellEditor>
                                </td>

                                <td pInitEditableRow class="width-20p p-text-left" (click)="onRowEditInit(rowData)"
                                    showDelay="800" pTooltip="{{ rowData.BankName }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [(ngModel)]="rowData['BankID']" [options]="filteredBankList"
                                                [autoWidth]="false" [style]="{'width':'96%'}" required="true"
                                                appendTo="body"  id="BankName"
                                                name="BankName" #BankName="ngModel"
                                                (onChange)="onDropdownSelected($event, rowData, 'BankName')"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData['BankName']}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow class="width-40p p-text-left" [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
                                    (click)="onRowEditInit(rowData)"
                                    showDelay="800" pTooltip="{{ rowData.DepositMemo }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                                <span>
                                                    <app-mentions [(inputText)]="rowData['DepositMemo']"
                                                        class="width-100p p-text-left p-text-ellipse"
                                                        style="width:100% !important; resize: vertical;"></app-mentions>
                                                </span>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData.DepositMemo}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-7p p-text-right">
                                    {{rowData.DepositAmount | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td class="width-10p p-text-center">
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                        (click)="onRowEditSave($event, rowData, 'deposit');"
                                        class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                        (click)="onRowEditCancel(rowData, 'deposit')"
                                        class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="depositDatasource?.length > 0">
                                <td class="width-6p"></td>
                                <td class="width-7p"></td>
                                <td class="width-20p"></td>
                                <td class="p-text-left width-40p ic-red">
                                    Total
                                </td>
                                <td class="width-7p p-text-right ic-red">
                                    <strong>{{ totalDepositAmount | currency:'USD':'symbol':'1.2-2' }}</strong>
                                </td>
                                <td class="width-10p"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

    <p-sidebar [(visible)]="isShowDetailsDialog" *ngIf="isShowDetailsDialog" position="right" (onHide)="onCloseDetailsDialog()"
        [style]="{'min-width':'90%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <div class="card p-pt-4" style="min-height: calc(100vh - 35px)">
            <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual p-formgroup-inline">
                <div class="p-col-6 p-pl-0 ic-size-15">
                    <label class="label-text p-mr-4 p-pr-0">{{ 'settings.Bank-Name' | translate }}:</label>
                    <strong>{{ selectedDeposit.BankName }}</strong>
                </div>

                <div
                  class="p-col-6 p-pl-0 ic-size-15"
                >
                  <label
                    class="label-text p-mr-5 p-pr-1"
                  >
                    {{ 'deposit-date' | translate }}:
                  </label>
                  <strong>
                    {{ selectedDeposit.DepositDate | date:'MM/dd/yyyy' }}
                  </strong>
                </div>

                <div class="p-col-6 p-pl-0 ic-size-15">
                    <label class="label-text p-mr-6 p-pr-0">{{ 'amount' | translate }}:</label>
                    <strong>{{ selectedDeposit.DepositAmount | currency:'USD':'symbol':'1.2-2' }}</strong>
                </div>
                <div class="p-col-6 p-pl-0 p-mb-3 ic-size-15 p-text-truncate p-text-nowrap"
                    pTooltip={{selectedDeposit.DepositMemo}} tooltipStyleClass="tooltip-no-wrap"
                    tooltipPosition="top" showDelay="800" *ngIf="!!selectedDeposit.DepositMemo">
                    <label class="label-text p-mr-4 p-pr-0">{{ 'accounts.deposit_memo' | translate }}:</label>
                    <strong>{{ selectedDeposit.DepositMemo }}</strong>
                </div>
                <p-panel header="{{ 'accounts.deposit_details' | translate }}" [toggleable]="true">
                    <p-table [value]="detailsDatasource" [responsive]="true" sortField="PaymentDate"
                        (onFilter)="onFilter($event, 'details')"  editMode="row"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        dataKey="PaymentHeaderID" [sortOrder]="-1" sortMode="single"
                        [globalFilterFields]="['PaymentType', 'PaymentNote', 'PaymentAmount', 'ClientName', 'PaymentDate']"
                        [rowHover]="true" #dtDetails [scrollable]="true" [scrollHeight]="'calc(90vh - 350px)'">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <div>
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValueDetails pInputText type="text"
                                            (input)="dtDetails.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dtDetails.reset();clearSearchFilter('details')" tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsDetailsSize > -1" severity="success"
                                        text="{{filteredItemsDetailsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                    </p-message>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-5p p-text-center">
                                    {{'action' | translate}}
                                </th>
                                <th class="width-9p p-text-left" pSortableColumn="PaymentDate">
                                    {{'Accounts.payment-date' | translate}}
                                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                                </th>
                                <th class="width-20p p-text-left">{{'client.name' | translate}}</th>
                                <th class="width-10p p-text-left">{{'Accounts.payment-type' | translate}}</th>
                                <th class="width-35p p-text-left">{{'Account.payment_note' | translate}}</th>
                                <th class="width-9p p-text-right">{{'Accounts.payment-amount' | translate}}</th>
                                <th class="width-10p p-text-center"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-editing="editing">
                            <tr class="cursor-hand" [pEditableRow]="rowData">
                                <td class="width-5p p-text-center">
                                    <div class="p-text-center">
                                        <i class="fal fa-university ic-orange p-pr-2" pTooltip="{{'deposit.change_bank' | translate}}"
                                            tooltipPosition="top" (click)="onChangeBank(rowData)"></i>
                                    </div>
                                </td>
                                <!-- <td class="width-9p p-text-left">
                                    {{ mangoUtils.formatDateString(rowData.PaymentDate) | date:'MM/dd/yyyy'}}
                                </td> -->
                                <td pInitEditableRow class="width-9p p-text-left" (click)="onDetailEditInit(rowData)">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                [(ngModel)]="rowData['PaymentDate']" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                yearRange="1000:2099" dateFormat="mm/dd/yy"
                                                (onSelect)="onSelectDate(rowData, 'PaymentDate');"
                                                class="calender-component" showButtonBar="true">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData.PaymentDate | date:'MM/dd/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-20p p-text-left"
                                    showDelay="800" pTooltip="{{ rowData.ClientName }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.ClientName}}
                                </td>
                                <td class="width-10p p-text-left">
                                    {{rowData.PaymentType}}
                                </td>
                                <td class="width-35p p-text-left"
                                    showDelay="800" pTooltip="{{ rowData.PaymentNote }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.PaymentNote}}
                                </td>
                                <td class="width-9p p-text-right">
                                    {{rowData.PaymentAmount | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td class="width-10p p-text-center">
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                        (click)="onDetailSave(rowData);"
                                        class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                        (click)="onDetailCancel(rowData)"
                                        class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-panel>
            </div>
        </div>
    </p-sidebar>

    <p-dialog
        [(visible)]="isShowReassignDialog"
        *ngIf="isShowReassignDialog"
        [modal]="true"
        [style]="{ width: '60vw' }"
        [contentStyle]="{overflow: 'visible'}"
        [baseZIndex]="1"
        [maximizable]="false"
        showEffect="fade"
        [draggable]="true"
        [resizable]="false"
        (onHide)="onCloseReassignDialog()"
    >
        <p-header>
            {{ "deposit.change_bank" | translate }}
        </p-header>
        <p-table [value]="filteredDepositDatasource" [responsive]="true" sortField="DepositDate"
            (onFilter)="onFilter($event, 'header')"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            dataKey="PaymentToBankID" [sortOrder]="-1" sortMode="single"
            [globalFilterFields]="['DepositDate', 'DepositAmount', 'DepositMemo', 'BankName']"
            [rowHover]="true" #dt [scrollable]="true" [scrollHeight]="'calc(90vh - 350px)'">
            <ng-template pTemplate="caption">
                <div class="table-header p-d-flex p-jc-between">
                    <div class="p-mt-3">
                        <label class="label-text p-mr-6 p-pr-0">{{ 'Accounts.payment-amount' | translate }}:</label>
                        <strong>{{ selectedPaymentHeader.PaymentAmount }}</strong>
                    </div>
                    <div>
                        <label class="label-text p-mr-2">{{'filter' | translate}}</label>
                        <p-dropdown [options]="reassignRecordList" [style]="{width: '200px'}" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedRecords"
                            (onChange)="onChangeReassignDropdown()"
                            scrollHeight="500px" placeholder="Select Filter" required></p-dropdown>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width-6p p-text-center">
                    </th>
                    <th class="width-11p p-text-left" pSortableColumn="DepositDate">
                        {{'deposit-date' | translate}}
                        <p-sortIcon field="DepositDate"></p-sortIcon>
                    </th>
                    <th class="width-20p p-text-left">{{'settings.Bank-Name' | translate}}</th>
                    <th class="width-35p p-text-left">{{'memo' | translate}}</th>
                    <th class="width-9p p-text-right">{{'amount' | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr class="cursor-hand">
                    <td class="width-6p p-text-center">
                        <p-radioButton name="groupname" [(ngModel)]="selectedRadBtn" [value]="rowData" [inputId]="rowData.PaymentToBankID">
                        </p-radioButton>
                    </td>
                    <td class="width-11p p-text-left">
                        {{ rowData['DepositDate'] | date:'MM/dd/yyyy'}}
                    </td>
                    <td class="width-20p p-text-left"
                        showDelay="800" pTooltip="{{ rowData.BankName }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                        {{rowData.BankName}}
                    </td>
                    <td class="width-35p p-text-left"
                        showDelay="800" pTooltip="{{ rowData.DepositMemo }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                        {{rowData.DepositMemo}}
                    </td>
                    <td class="width-9p p-text-right">
                        {{rowData.DepositAmount | currency:'USD':'symbol':'1.2-2'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
            <div class="p-d-flex p-jc-end">
              <button
                type="button"
                pButton
                pRipple
                label="{{ 'Save' | translate }}"
                (click)="onChangeDeposit()"
                [disabled]="!selectedRadBtn"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                label="{{ 'cancel' | translate }}"
                (click)="selectedRadBtn = null;isShowReassignDialog = false;"
              ></button>
            </div>
          </ng-template>
    </p-dialog>
</div>
