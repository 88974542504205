import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  AuthGuard,
  BreadcrumbService,
  EncrDecrService,
  MangoApiService
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { Table } from "primeng/table";
import { SharedComponentsService } from "@app/shared/components";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
})
export class ProjectListComponent implements OnInit, OnDestroy {
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  companyId;
  buttonsList: MenuItem[];
  selectedTemplate;
  items: MenuItem[];
  selectedMenu: MenuItem = { } as MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;
  public isDisplayDetailsTable: boolean = false;
  public isRadioSelected: boolean = false;
  public templateDataSource: any = [];

  public filterProjectTaskListEventData: any = { };
  public filterProjectTaskListDialog: any;

  public filterProjectListEventData: any = { };
  public filterProjectListDialog: any;

  hasFilters: boolean = false;

  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private sharedService: SharedComponentsService
  ) {
    this.breadcrumbService.setItems([
      { label: "Project Management" },
      { label: "Project List", icon: "ic-red" },
    ]);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.getCompanyTemplateDetails();
  }

  initTranslations() {
    this.buttonsList = [
      {
        label: this.translate.instant('New-Project'), icon: 'fal fa-plus', routerLink: ['/project-management/projectDetails'],
      },
      {
        label: this.translate.instant('create_company_template'), icon: 'fal fa-plus', command: () => {
          this.isDisplayDetailsTable = true;
        }
      },
      {
        label: this.translate.instant('create_new_template'), icon: 'fal fa-plus', routerLink: ['/project-management/createTemplate'],
      },
    ];

    this.loadMenu();
  }

  createProjectScreen() {
    this.router.navigate(['project-management/projectDetails']);
  }

  getCompanyTemplateDetails() {
    this.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyId).subscribe(function (data) {
      this.templateDataSource = data;
    }, () => this.mangoAPISrvc.showLoader(false));
  }

  ngOnInit(): void {
    this.searchTextStr = this.encrDecSrvc.getObject("projTemplate_" + AppConstants.SearchString);

    this.setMenuIndex();

    this.filterProjectTaskListDialog = this.sharedService.filterProjectTaskListDialog.subscribe(
      (eventData) => {
        this.filterProjectTaskListEventData = eventData;
        if (eventData.filterStatus) {
          this.hasFilters = true;
        } else {
          this.hasFilters = false;
        }
      }
    );

    this.filterProjectListDialog = this.sharedService.filterProjectListDialog.subscribe(
      (eventData) => {
        this.filterProjectListEventData = eventData;
        if (eventData.filterStatus) {
          this.hasFilters = true;
        } else {
          this.hasFilters = false;
        }
      }
    );
  }

  loadMenu() {
    console.log('load menu');
    this.items = [
      {
        id: "project-list",
        label: this.translate.instant("Projects"),
        routerLink: ["/project-management/projectList/projects"],
        icon: "fal fa-building",
        command: (event) => {
          this.selectedMenu = event.item;
        }
      },
      {
        id: "task-list",
        label: this.translate.instant("Tasks"),
        routerLink: ["/project-management/projectList/tasks"],
        icon: "fal fa-tasks",
        command: (event) => {
          this.selectedMenu = event.item;
        }
      },
    ];

    this.selectedMenu = this.items[this.selectedMenuIndex];
    console.log('selectedMenu', this.selectedMenu);
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;

    if (url.startsWith("/project-management/projectList/tasks")) {
      this.selectedMenuIndex = 1;
    } else {
      this.selectedMenuIndex = 0;
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject("projTemplate_" + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject('projTemplate_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event("input", {
          bubbles: true,
          cancelable: true,
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  shouldShowButton(): boolean {
    const isProjectList = this.selectedMenu?.id === 'project-list';
    const isTaskList = this.selectedMenu?.id === 'task-list';

    if (isProjectList || isTaskList) {
      return true;
    }
    return false;
  }

  redirectProject() {
    this.router.navigate(["project-management/projectDetails"]);
  }

  radioCompanyClicked(event, item) {
    this.isRadioSelected = true;
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(item));
  }

  sendData() {
    const url = this.router.url;

    if (url.startsWith("/project-management/projectList/tasks")) {
      this.sharedService.openFilterProjectTaskListDialog();
    } else {
      this.sharedService.openFilterProjectListDialog();
    }
  }

  get isAllowCreateProject() {
    return this.authGuard.isAllowAccess(41);
  }

  ngOnDestroy(): void {
    this.sharedService.triggerFilterProjectListDialog({ resetStatus: true });

    this.filterProjectListDialog.unsubscribe();

    this.sharedService.triggerFilterProjectTaskListDialog({ resetStatus: true });

    this.filterProjectTaskListDialog.unsubscribe();
  }

  bulkBudget() {

  }
}
