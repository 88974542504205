import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MangoApiService } from '@app/_services';

@Component({
  selector: 'app-message',
  template: `<p-toast position="top-right"></p-toast>`

})
export class MessageComponent implements OnInit {
  subscription: Subscription;


  constructor(private messageService: MessageService, private mangoAPISrvc: MangoApiService) { 
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    const self = this;
    self.subscription = this.mangoAPISrvc.notificationChange
      .subscribe(notification => {
        self.messageService.clear();
        self.messageService.add({ severity: notification['type'], summary: notification['text'], detail: notification['message'], closable: false, life: 3000 });
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
