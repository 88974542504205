import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
declare let numeral: any;

@Component({
  selector: 'app-client-history',
  templateUrl: './client-history.component.html'
})
export class ClientHistoryComponent implements OnInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  companyId;

  public clientsCols: any[];
  public clientsHistoryDataSource: any = [];
  public dueTodayTotal: any = 0;
  public thisWkTotal: any = 0;
  public NextWkTotal: any = 0;
  public overdueTotal: any = 0;
  public pendingTotal: any = 0;
  public inProgressTotal: any = 0;
  public reviewTotal: any = 0;
  public completedTotal: any = 0;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService) {
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.projectManagementRoutePath + 'CH&' + AppConstants.SearchString);
  }
  
  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('client_history'), icon: 'ic-red' },
    ]);
  }

  ngOnInit(): void {
    this.getClientHistory(this.companyId);
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.projectManagementRoutePath + 'CH&' + AppConstants.SearchString, "");
    this.footerTotals(this.clientsHistoryDataSource);
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.projectManagementRoutePath + 'CH&' + AppConstants.SearchString, obj?.filters?.global?.value);
    this.footerTotals(obj.filteredValue);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  getClientHistory(cId) {
    const _this = this;

    _this.mangoAPISrvc.showLoader(true);

    _this.mangoAPISrvc.getClientHistoryByCompanyId(cId).subscribe((data: any) => {
      _this.clientsHistoryDataSource = data;
      _this.footerTotals(_this.clientsHistoryDataSource);

      _this.mangoAPISrvc.showLoader(false);

      if (data.length > 0) {
        _this.loadFilterGrid();
      }
    }, (err) => {
      _this.mangoAPISrvc.showLoader(false);
    })
  }

  footerTotals(data) {
    this.dueTodayTotal = numeral(data?.reduce(function (a, b) { return a + +b['today']; }, 0)).value() || 0;
    this.thisWkTotal = numeral(data?.reduce(function (a, b) { return a + +b['thisweek']; }, 0)).value() || 0;
    this.NextWkTotal = numeral(data?.reduce(function (a, b) { return a + +b['nextweek']; }, 0)).value() || 0;
    this.overdueTotal = numeral(data?.reduce(function (a, b) { return a + +b['pastdue']; }, 0)).value() || 0;
    this.pendingTotal = numeral(data?.reduce(function (a, b) { return a + +b['pendingcount']; }, 0)).value() || 0;
    this.inProgressTotal = numeral(data?.reduce(function (a, b) { return a + +b['inprogresscount']; }, 0)).value() || 0;
    this.reviewTotal = numeral(data?.reduce(function (a, b) { return a + +b['reviewcount']; }, 0)).value() || 0;
    this.completedTotal = numeral(data?.reduce(function (a, b) { return a + +b['completedcount']; }, 0)).value() || 0;
  }
}
