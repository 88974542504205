<div class="layout-content p-px-3">
  <div class="p-grid p-p-0">
    <div class="p-col-12 p-d-flex p-jc-between p-pb-1">
      <div class="width-21p p-pr-1 p-fluid">
        <!-- <p-autoComplete id="companyID" name="companyID" placeholder="Search Company" field="CompanyName"
            #company="ngModel" [suggestions]="filteredCompanyList" [minLength]="1" [dropdown]="true"
            [(ngModel)]="companySel" (onSelect)="handleCompanySelect($event)"
            (completeMethod)="filterCompanyList($event)" required>
          <ng-template let-row pTemplate="item">
            <div class="ui-helper-clearfix">
              {{ row.CompanyName + " - " + row.CompanyID }}
            </div>
          </ng-template>
        </p-autoComplete> -->
      </div>
    </div>
    <p-table
      [columns]="selectedColumnsMain"
      #dt
      [value]="billingDataSource"
      dataKey="ClientID"
      [responsive]="true"
      [rows]="selectedRowsPerPage"
      [rowsPerPageOptions]="[10,25,50]"
      [paginator]="billingDataSource?.length > 10"
      rowExpandMode="single"
      [rowHover]="true"
      [sortOrder]="1"
      [reorderableColumns]="true"
      styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
      [filterDelay]="0"
      (onFilter)="onFilter($event)"
      [globalFilterFields]="globalFilterColumnsMain"
      (onPage)="onTablePageChange($event)">
      <ng-template pTemplate="caption">
        <div class="p-grid">
          <div class="p-col-4">
            <span class="p-input-icon-left">
              <i class="fal fa-search"></i>
              <input
                #searchValue
                pInputText
                type="text"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                (click)="$event.target.select()"
                placeholder="{{ 'globalsearch' | translate }}" />
            </span>
            <span>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="dt.reset();clearSearchFilter()"
                tooltipPosition="top"
                pTooltip="{{ 'clear' | translate }}"></button>
            </span>
            <p-message
              *ngIf="filteredItemsSize > -1"
              severity="success"
              text="{{filteredItemsSize}} Record(s) found."
              styleClass="p-ml-2"></p-message>
          </div>
          <div class="p-col-8">
            <span class="p-d-flex p-jc-end">
              <label class="label-text p-mr-2 p-mt-2"> Date Range </label>
              <p-dropdown
                [options]="dueDateList"
                [(ngModel)]="dueDateID"
                scrollHeight="350px"
                class="p-mr-2"
                name="itemdueDateSelected"
                id="itemdueDateSelected"
                (onChange)="fetchBillingSource()"
                #itemdueDateSelected="ngModel"
                [ngModelOptions]="{standalone: true}">
              </p-dropdown>
              <div *ngIf="dueDateID == 'custom'">
                <label class="label-text p-mr-3"> {{ 'date-from' | translate }} </label>
                <p-calendar
                  (onSelect)="onChangeFilters($event, 'dateFrom')"
                  (onClearClick)="onChangeFilters($event, 'dateFrom')"
                  (onBlur)="onChangeFilters($event, 'dateFrom')"
                  [monthNavigator]="true"
                  [ngModelOptions]="{standalone: true}"
                  [yearNavigator]="true"
                  yearRange="2000:2090"
                  [(ngModel)]="DateFrom"
                  class="p-mr-2">
                </p-calendar>
                <label class="label-text p-mr-3"> {{ 'date-to' | translate }} </label>
                <p-calendar
                  (onSelect)="onChangeFilters($event, 'dateTo')"
                  (onClearClick)="onChangeFilters($event, 'dateTo')"
                  (onBlur)="onChangeFilters($event, 'dateTo')"
                  [monthNavigator]="true"
                  [ngModelOptions]="{standalone: true}"
                  [yearNavigator]="true"
                  yearRange="2000:2090"
                  [(ngModel)]="DateTo"
                  class="p-mr-2">
                </p-calendar>
                <button
                  pButton
                  pRipple
                  class="p-mr-2"
                  type="button"
                  icon="fal fa-sync"
                  label="{{ 'fetch' | translate }}"
                  styleClass="p-button-sm"
                  [disabled]="(dueDateID == 'custom' && (!DateTo || !DateFrom))"
                  (click)="fetchBillingSource( );"></button>
                <button
                  pButton
                  pRipple
                  class="p-mr-2"
                  styleClass="p-button-sm"
                  type="button"
                  icon="fal fa-undo"
                  label="{{ 'Reset' | translate }}"
                  (click)="onResetFilters( );"></button>
                <button
                  pButton
                  pRipple
                  class="p-mr-2"
                  styleClass="p-button-sm"
                  type="button"
                  icon="fal fa-times"
                  label="{{ 'cancel_button' | translate }}"
                  (click)="onCloseFilter( );"></button>
              </div>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-copy"
                (click)="dt.exportCSV()"
                class="p-mr-2"
                pTooltip="Export to CSV"
                tooltipPosition="bottom"
                *ngIf="billingDataSource?.length > 0"></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-pdf"
                (click)="exportPdf('current')"
                class="p-button-warning p-mr-2"
                pTooltip="Export PDF"
                tooltipPosition="bottom"
                *ngIf="billingDataSource?.length > 0"></button>
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="width-2p"></th>

          <th
            *ngFor="let col of columns"
            pReorderableColumn
            [pSortableColumn]="col.field"
            class="{{col.rowClass}}">
            {{col.header}}
            <i
              *ngIf="col.hasTooltip"
              class="fa fa-question-circle ic-blue"
              tooltipPosition="top"
              [pTooltip]="col.tooltip"></i>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-i="rowIndex"
        let-expanded="expanded"
        let-columns="columns">
        <tr class="cursor-hand">
          <td class="width-2p">
            <i
              *ngIf="rowData.ClientList?.length > 0"
              class="far fa-chevron-right"
              tooltipPosition="top"
              pTooltip="Show details"
              (click)="openRowData(rowData)"></i>
          </td>
          <td *ngFor="let col of columns" class="{{col.rowClass}}">
            <span *ngIf="col.field !== 'billedAmount'">{{rowData[col.field]}}</span>
            <span *ngIf="col.field == 'billedAmount'"
              >{{rowData[col.field] | currency : 'USD' : true : '1.2-2'}}</span
            >
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-columns>
        <tr *ngIf="billingDataSource?.length > 0">
          <td class="p-text-right p-text-bold ic-red" colspan="10">{{'Totals' | translate}}</td>
          <td class="p-text-right p-text-bold ic-red">
            {{getTotalMain() | currency : 'USD' : true : '1.2-2'}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="11">
            <p-message
              severity="info"
              text="{{ 'data_not_found' | translate }}."
              styleClass="p-px-2"></p-message>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar
  [(visible)]="sidePanelDisplay"
  position="right"
  (onHide)="cancelShowRow()"
  [style]="{'min-width':'70%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" style="min-height: calc(100vh - 34px)">
    <div class="p-col-12 p-formgroup-inline">
      <label class="p-col-5 label-text ic-size-18 p-pb-4"> Client List </label>
    </div>
    <div class="card p-py-1">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-mt-3 p-pr-0 manual">
          <p-table
            #dt2
            [value]="selectedClientRowData"
            [responsive]="true"
            dataKey="ELProposalID"
            [rowHover]="true"
            [globalFilterFields]="globalFilterColumns"
            [columns]="selectedColumns"
            sortMode="single"
            [sortField]="sortField"
            (onSort)="onSort($event)"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
            <ng-template pTemplate="caption">
              <div class="p-formgroup-inline">
                <span class="p-input-icon-left" style="width: 30% !important">
                  <i class="fal fa-search"></i>
                  <input
                    #searchValue
                    pInputText
                    type="text"
                    placeholder="{{ 'Search-by' | translate }}"
                    (input)="dt2.filterGlobal($event.target.value, 'contains')" />
                </span>
                <span>
                  <button
                    pButton
                    pRipple
                    class="p-button-rounded p-button-danger p-button-text"
                    type="button"
                    icon="pi pi-times"
                    tooltipPosition="top"
                    pTooltip="{{ 'clear' | translate }}"
                    (click)="dt2.reset(); clearSearchFilter();"></button>
                </span>
                <p-message
                  *ngIf="(( filteredItemsSize > -1 ))"
                  severity="success"
                  text="{{ filteredItemsSize }} {{ 'records_found' | translate }}."
                  styleClass="p-ml-2">
                </p-message>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th class="width-5p">Row</th>

                <th class="width-15p p-text-left">
                  Client Name
                  <!-- <p-sortIcon [field]="ClientName"></p-sortIcon> -->
                </th>
                <th class="width-15p">Billing Date</th>
                <th class="width-15p">Next Bill Date</th>
                <th class="width-15p p-text-right">Total # of Engagement Letters</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
                <td class="width-5p p-text-left">{{rowIndex + 1}}</td>
                <td class="width-15p p-text-left">{{rowData.ClientName}}</td>
                <td class="width-15p p-text-left">{{rowData.BillingDate | date:'MM/dd/yyyy'}}</td>
                <td class="width-15p p-text-left">
                  {{rowData.AnniversaryDate | date:'MM/dd/yyyy'}}
                </td>
                <td class="width-15p p-text-right">{{rowData.noOfELs}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">
                  <p-message severity="info" text="Data not found." styleClass="p-px-2">
                  </p-message>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
