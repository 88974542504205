import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Subject, timer } from 'rxjs';
import Swal from 'sweetalert2';
declare let numeral: any;

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html'
})
export class ClientSettingsComponent implements OnInit, AfterViewInit {

  clientSettingForm: UntypedFormGroup;
  clientID: number = -1;
  editableClient: any = {};
  public clientName: string = null;
  invoiceTemplates: SelectItem[] = [];
  finalizeActions: SelectItem[];

  companyLocations: SelectItem[] = [];
  selectedCompanyLocation: any;
  selectedSalesTax: any;

  @ViewChild('searchValue') searchValue;
  invoiceOpt: any = {};
  formattedAmount: string = '';
  MarkDepositApplied: any;
  tempMarkDepositApplied;
  temparCreditLimit;
  tempmarkSlipBilled;
  tempnoServiceCharges;
  tempwipCreditLimit;
  salesTax;
  formateNumber;
  selectedDescription;
  selectedSalesTax1;
  selectedSalesTax2;
  selectedSalesTax3;
  noServiceCharges;
  markSlipBilled;
  markDepositApplied;
  arCreditLimit;
  wipCreditLimit;
  startTimer;
  markClientRates;

  isInvoiceTemplate: boolean;
  isFinalizeAction: boolean;
  isUseDefaultTerms: boolean;
  isNoLateFees: boolean;
  isInterestRate: boolean;
  isSelectedTerm: boolean;
  isGraceDays: boolean;
  isModeOfPayment: boolean;
  isEnableStatementReminder: boolean;
  isNoServiceCharges: boolean;
  isMarkSlipBilled: boolean;
  isShowTearOffFooter: boolean = false;
  isShowTearOffFooterChanged: boolean = false;
  isMarkDepositApplied: boolean;
  isBlockTimeAndExpense: boolean;
  isSelectedPriorBalOption: boolean;
  isSelectedShowBeforeInvoiceDate: boolean;
  isSelectedCompanyLocation: boolean;
  isSelectedSalesTax: boolean;
  isTax1ID: boolean;
  isTax2ID: boolean;
  isClientRate: boolean;
  isBillingRate: boolean;

  serviceCodeId = null;
  selectedActivityDesc: string = null;
  selectedRateClientsID: number = null;

  clientRates: any = [];
  clientRate: any = {};
  isEditClientRate: boolean;
  ActivateClientRates: boolean;
  displayClientRateModal: boolean = false;
  currentPageName: string;
  selectedSalesTax1Description: string;
  selectedSalesTax2Description: string;
  selectedSalesTax3Description: string;
  isFromEdit: any;
  toastMessage: any;
  selectedTerm: any;
  selectedInvoiceTemplate: any;
  FinalizeAction: any;
  NoLateFees: any;
  InterestRate: any;
  GraceDays: any;
  Tax1ID;
  Tax2ID;
  IsInterestRate: any;
  UseDefaultTerms: any;
  isManaging: any;

  isEditClient: any = [];
  openInput: any = [];
  hidePriorBalOptions: any = [];
  showBeforeInvoiceDateOptions: any = [];
  selectedShowBeforeInvoiceDateOption: any [];
  selectedPriorBalOption: any = [];
  public clientRatesCols: any[];
  companyID: any;

  public termsList: any = [];
  activities: any;
  settings: any = {};
  @ViewChild('clientSettingForm') form;
  @ViewChild('rateMainnForm') ngForm;
  public filteredActivities: any[];
  isFirmDefaultPayments = false;
  rateFormValid = false;
  modeOfPayment = null;
  salesTaxOptions;
  companyGlobalSetting: any = {};
  enableStatementReminder: boolean = false;
  InvoiceReminderActivate: boolean = false;
  BlockTimeAndExpense: boolean = false;

  constructor(
    protected mangoUtils: mangoUtils,
    private fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private _translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private sharedService: SharedComponentsService) {
    const interval = setInterval(() => {
      if (!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.getCompanyGlobalSetting();
    this.sharedService.leaveAndSaveSub = new Subject<any>();
    this.sharedService.leaveAndSaveSub.subscribe(()=>{
      this.saveSetting();
    })
  }

  getCompanyGlobalSetting() {
    this.companyGlobalSetting = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    //console.log(this.companyGlobalSetting)
    if (this.companyGlobalSetting) {
      this.companyGlobalSetting.isWorkLocationRequired = this.companyGlobalSetting.isWorkLocationRequired ? this.companyGlobalSetting.isWorkLocationRequired : false;
    }
  }

  initTranslations() {
    this.finalizeActions = [{ label: this._translate.instant('print'), value: 'Print' }, { label: this._translate.instant('email'), value: 'Email' }, { label: this._translate.instant('finalize_only'), value: 'Finalize Only' }];
    this.breadcrumbService.setItems([
      { label: this._translate.instant('client') },
      { label: this._translate.instant('Settings') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
    this.termsList = [{ label: this._translate.instant('select_term'), value: null, GraceDays: 0 }];
    this.invoiceTemplates = [
      { value: '2', label: this._translate.instant('client.narrative') },
      { value: '9', label: this._translate.instant('client.narrative_summary') },
      { value: '10', label: this._translate.instant('client.narrative_detail') },
      { value: '4', label: this._translate.instant('client.narrative_mini_statement') },
      { value: '0', label: this._translate.instant('client.detail') },
      { value: '6', label: this._translate.instant('client.detail_no_rate') },
      { value: '7', label: this._translate.instant('client.detail_no_rate_time') },
      { value: '11', label: this._translate.instant('client.detail_no_rate_time_amount') },
      { value: '12', label: this._translate.instant('client.narrative_simple_no_detail') },
      { value: '13', label: this._translate.instant('client.narrative_simple_with_detail') },
      { value: '14', label: this._translate.instant('client.narrative_simple_no_remit') },
      { value: '15', label: this._translate.instant('client.invoice_detail_summary') },
      { value: '16', label: this._translate.instant('client.invoice_narrative_summary_by_activity') },
    ];
    this.salesTaxOptions = [
      { value: 'None', label: this._translate.instant("None") },
      { value: "FirmRate", label: this._translate.instant("Firm_Rate") },
      { value: "CompanyLocationRate", label: this._translate.instant("CompanyLocationRate") },
      { value: "ClientRate", label: this._translate.instant("ClientRate") },
    ]

    this.hidePriorBalOptions = [
      { value: 'firmDefaults', label: this._translate.instant("client.use_company_defaults") },
      { value: "hidePreviousBalance", label: this._translate.instant("settings.Hide-Prior-Balance-on-Client-Invoices") }
    ]

    this.showBeforeInvoiceDateOptions = [
      { value: 'firmDefaults', label: this._translate.instant("client.use_company_defaults") },
      { value: "showBeforeInvoiceDate", label: this._translate.instant("settings.Show-Before-Invoice-Date") }
    ]
    //this.selectedSalesTax = 1;
    this.getCompanyLocations();
  }

  ngOnDestroy() {
    this.sharedService.leaveAndSaveSub.unsubscribe();
  }

  ngOnInit(): void {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.editableClient = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.activities = this.encrDecSrvc.getObject(AppConstants.activityList);
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
    this.activities = this.activities.sort(this.mangoUtils.compareValues('ServiceCode', 'asc'));

    this.initializeForm();
    

    this.arCreditLimit = "";
    this.wipCreditLimit = "";
    this.selectedSalesTax1Description = "Select Tax 1";
    this.selectedSalesTax2Description = "Select Tax 2";
    this.selectedSalesTax3Description = "Select Tax 3";

    this.currentPageName = 'Setting';
    this.isEditClient[0] = true;

    this.selectedSalesTax1 = 1;
    this.selectedSalesTax2 = 1;
    this.selectedSalesTax3 = 1;

    this.noServiceCharges = false;
    this.markSlipBilled = false;
    this.markDepositApplied = false;
    this.markClientRates = false;
    this.mangoAPISrvc.isFormChanged = false;

    this.isInvoiceTemplate = false;
    this.isFinalizeAction = false;
    this.isUseDefaultTerms = false;
    this.isNoLateFees = false;
    this.isInterestRate = false;
    this.isSelectedTerm = false;
    this.isGraceDays = false;
    this.isModeOfPayment = false;
    this.isEnableStatementReminder = false;
    this.isNoServiceCharges = false;
    this.isMarkSlipBilled = false;
    this.isMarkDepositApplied = false;
    this.isBlockTimeAndExpense = false;
    this.isSelectedPriorBalOption = false;
    this.isSelectedShowBeforeInvoiceDate = false;
    this.isSelectedCompanyLocation = false;
    this.isSelectedSalesTax = false;
    this.isTax1ID = false;
    this.isTax2ID = false;
    this.isClientRate = false;
    this.isBillingRate = false;
  }

  ngAfterViewInit() {
    this.form.control.valueChanges
      .subscribe(values => {
        if (this.form.valid && this.form.dirty) {
          this.mangoAPISrvc.isFormChanged = true;
          this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
        }
      });

    this.ngForm.control.valueChanges
      .subscribe(values => {
        if (this.ngForm.valid && this.ngForm.dirty) {
          this.rateFormValid = true;
        }
      });
    this.searchValue.nativeElement.focus();
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.companyID).subscribe(
      (data: any) => {
        this.companyLocations = data.map((location) => {
          return {
            value: location.CompanyMangoLocationID,
            label: location.CompanyLocation
          }
        });
        this.companyLocations.unshift({ label: "Default", value: null });
        this.mangoAPISrvc.showLoader(false);
        this.loadClientDefaultSetting();
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  saveSetting() {
    if (isNaN(this.selectedInvoiceTemplate)) {
      this.selectedInvoiceTemplate = this.invoiceTemplates.filter((client) => client['label'] == this.selectedInvoiceTemplate)[0]?.value;
    } else {
      this.selectedInvoiceTemplate = this.invoiceTemplates.filter((client) => client['value'] == this.selectedInvoiceTemplate)[0]?.value;
    }

    if (this.companyLocations.length > 0) {
      if (isNaN(this.selectedCompanyLocation)) {
        this.selectedCompanyLocation = this.companyLocations.filter((location) => location['label'] == this.selectedCompanyLocation)[0]?.value;
      } else {
        this.selectedCompanyLocation = this.companyLocations.filter((location) => location['value'] == this.selectedCompanyLocation)[0]?.value;
      }
    } else {
      this.selectedCompanyLocation = null;
    }


    if (this.NoLateFees == 'false') {
      this.IsInterestRate = true;
      this.NoLateFees = false;
    }
    if (this.NoLateFees == 'true') {
      this.InterestRate = null;
      this.NoLateFees = true;
      this.IsInterestRate = false;
    }

    const parent = this;
    const obj = {
      'invoiceTemplate': this.selectedInvoiceTemplate,
      'customerTermId': this.selectedTerm,
      'arCreditLimit': this.arCreditLimit,
      'wipCreditLimit': this.wipCreditLimit,
      'salesTax1': this.Tax1ID,
      'activateClientRates': this.ActivateClientRates,
      'salesTax2': this.Tax2ID,
      'salesTax3': this.selectedSalesTax3,
      'markSlipBilled': this.markSlipBilled,
      'markDepositApplied': this.markDepositApplied,
      'noServiceCharges': this.noServiceCharges,
      'FinalizeAction': this.FinalizeAction,
      'NoLateFees': this.NoLateFees,
      'InterestRate': this.InterestRate,
      "IsInterestRate": this.IsInterestRate,
      'GraceDays': this.GraceDays,
      'SalesTaxLevel': this.selectedSalesTax,
      // 'Tax1ID': this.Tax1ID,
      // 'Tax2ID': this.Tax2ID,
      'UseDefaultTerms': this.UseDefaultTerms,
      'isFirmDefaultPayments': this.isFirmDefaultPayments,
      'modeOfPayment': this.modeOfPayment,
      'CompanyMangoLocationID': this.selectedCompanyLocation,
      'InvoiceReminderActivate': this.enableStatementReminder,
      'isHidePriorBalance': this.selectedPriorBalOption,
      'BlockTimeExpenseEntry': this.BlockTimeAndExpense,
      'isShowTearOffFooter': this.isShowTearOffFooter,
      'isShowBeforeInvoiceDate': this.selectedShowBeforeInvoiceDateOption,
    };
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.saveSettingData(parent.clientID, obj).subscribe((data) => {
      // let changedFields = this.getChangedProperties();
      // console.log(changedFields);
      const logdata = {}
      logdata['Action'] = "Update Client Settings from #"+parent.clientID;
      logdata['Description'] = "Update";

      if(this.isInvoiceTemplate == true) {
        logdata['Description'] += " - Invoice Template: "+obj.invoiceTemplate;
      }
      if(this.isFinalizeAction == true){
        logdata['Description'] += " - Finalize Action: "+obj.FinalizeAction;
      }
      if(this.isUseDefaultTerms == true){
        logdata['Description'] += " - Use Company Defaults: "+obj.UseDefaultTerms;
      }
      if(this.isNoLateFees == true){
        logdata['Description'] += " - No Late Fees: "+obj.NoLateFees;
      }
      if(this.isInterestRate == true){
        logdata['Description'] += " - Interest Rate: "+obj.InterestRate;
      }
      if(this.isSelectedTerm == true){
        logdata['Description'] += " - Selected Term: "+obj.customerTermId;
      }
      if(this.isGraceDays == true){
        logdata['Description'] += " - Grace Days: "+obj.GraceDays;
      }
      if(this.isModeOfPayment == true){
        logdata['Description'] += " - Mode of Payment: "+obj.modeOfPayment;
      }
      if(this.isEnableStatementReminder == true){
        logdata['Description'] += " - Enable Statement Reminder: "+obj.InvoiceReminderActivate;
      }
      if(this.isShowTearOffFooterChanged){
        logdata['Description'] += " - Show Optional Tear Off Footer: "+obj.isShowTearOffFooter;
      }
      if(this.isNoServiceCharges == true){
        logdata['Description'] += " - No Service Charges: "+obj.noServiceCharges;
      }
      if(this.isMarkSlipBilled == true){
        logdata['Description'] += " - Mark Slip Billed: "+obj.markSlipBilled;
      }
      if(this.isMarkDepositApplied == true){
        logdata['Description'] += " - Mark Deposit Applied: "+obj.markDepositApplied;
      }
      if(this.isBlockTimeAndExpense == true){
        logdata['Description'] += " - Block Time and Expense: "+obj.BlockTimeExpenseEntry;
      }
      if(this.isSelectedPriorBalOption == true){
        logdata['Description'] += " - Prior Balance Option: "+obj.isHidePriorBalance;
      }
      if(this.isSelectedShowBeforeInvoiceDate) {
        logdata['Description'] += " - Show Before Invoice Date: "+obj.isShowBeforeInvoiceDate;
      }
      if(this.isSelectedCompanyLocation == true){
        logdata['Description'] += " - Company Location: "+obj.CompanyMangoLocationID;
      }
      if(this.isSelectedSalesTax == true){
        logdata['Description'] += " - Sales Tax: "+obj.SalesTaxLevel;
      }
      if(this.isTax1ID == true){
        logdata['Description'] += " - Sales Tax on Labor: "+obj.salesTax1;
      }
      if(this.isTax2ID == true){
        logdata['Description'] += " - Sales Tax on Expenses: "+obj.salesTax2;
      }
      logdata['Table'] = "";

      if (!parent.isManaging) {
        parent.mangoAPISrvc.addUserLogs(logdata).subscribe((res) => { }, (err) => {
          console.log(err);
        });
      }
      if(parent.encrDecSrvc.clientList?.length > 0)  {
        parent.encrDecSrvc.clientList.forEach((client) => {
          if(client.ClientID == parent.clientID) {
            client.BlockTimeExpenseEntry = parent.BlockTimeAndExpense
            return;
          }
        })
      }
      parent.loadClientDefaultSetting();
      parent.mangoAPISrvc.notify('success', parent._translate.instant('Success'), AppConstants.updateMsg);
      parent.mangoAPISrvc.isFormChanged = false;
      parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false); 
      parent.NoLateFees = parent.NoLateFees.toString();
      parent.form.form.markAsPristine();
      parent.mangoAPISrvc.showLoader(false);

      this.isInvoiceTemplate = false;
      this.isFinalizeAction = false;
      this.isUseDefaultTerms = false;
      this.isNoLateFees = false;
      this.isInterestRate = false;
      this.isSelectedTerm = false;
      this.isGraceDays = false;
      this.isModeOfPayment = false;
      this.isEnableStatementReminder = false;
      this.isNoServiceCharges = false;
      this.isMarkSlipBilled = false;
      this.isMarkDepositApplied = false;
      this.isBlockTimeAndExpense = false;
      this.isSelectedPriorBalOption = false;
      this.isSelectedShowBeforeInvoiceDate = false;
      this.isSelectedCompanyLocation = false;
      this.isSelectedSalesTax = false;
      this.isTax1ID = false;
      this.isTax2ID = false;
      this.isClientRate = false;
      this.isBillingRate = false;
    }, error => {
      parent.mangoAPISrvc.notify('error', parent._translate.instant('error'), AppConstants.updateErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    })
  }

  changefinalizeActions() {
    this.isFinalizeAction = true;
    if (this.FinalizeAction == 'Email') {
      this.mangoAPISrvc.showLoader(true)
      this.mangoAPISrvc.getEmailNames(this.clientID).subscribe((emailsList: any) => {
        this.mangoAPISrvc.showLoader(false)
        if (emailsList.length == 0) {
          Swal.fire({
            icon: "error",
            title: this._translate.instant("Information"),
            text:
              this._translate.instant("client_email_required_message"),
            showConfirmButton: true
          });
          this.FinalizeAction = 'Print';
        }
      }, err => {
        this.mangoAPISrvc.showLoader(false)
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      })
    }
  }

  initializeForm() {
    const parent = this;
    this.clientSettingForm = this.fb.group({
      ClientID: [parent.clientID],
      NoLateFees: [false],
      InterestRate: [''],
      GraceDays: [0],
      Tax1ID: [0.00],
      Tax2ID: [0.00],
      ActivateClientRates: [''],
      MarkDepositApplied: [false],
      MarkSlipsBilled: [false],
      FinalizeAction: ['Print'],
      modeOfPayment: [-1],
      UseDefaultTerms: [''],
      SalesTaxLevel: [''],
      isFirmDefaultPayments: [true],
      DefaultInvoiceTemplate: [2],
      TermsID: [''],
      NoWIP: [''],
      selectedPriorBalOption: ['firmDefaults'],
      selectedShowBeforeInvoiceDateOption: ['firmDefaults'],
      isShowTearOffFooter: [false],
    })

    this.clientSettingForm.get('modeOfPayment').valueChanges.subscribe(val => {
      this.clientSettingForm.controls['modeOfPayment'].setValue(val, { emitEvent: false });
    });
    this.clientSettingForm.statusChanges.subscribe(x => {
      setTimeout(() => {
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.clientSettingForm.pristine);
      }, 0);
    })
  }

  getInvoiceOption(data) {
    const _this = this;
    data.NoLateFees = data.NoLateFees != null ? data.NoLateFees.toString() : "true";
    _this.invoiceOpt = data;
    _this.InvoiceReminderActivate = data.InvoiceReminderActivate;
  }

  updateGraceDays() {
    const template = this.termsList.filter((item) => item['value'] == this.selectedTerm);
    if (template.length > 0 && template[0]) {
      this.GraceDays = template[0].GraceDays;
    }

  }

  loadClientDefaultSetting() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.loadClientSettings(self.clientID).subscribe((results: any) => {
      self.getInvoiceOption(results[4]);
      self.getTerms(results[0], results[3]);
      self.getClientRates(results[2]);
      self.getSettingData(results[0], results[1]);
      self.mangoAPISrvc.showLoader(false);
    });
  }

  getSalesTax(data, tax) {
    const _this = this;
    _this.salesTax = tax;

    for (let i = 0; i < _this.salesTax.length; i++) {
      if (_this.salesTax[i].SalesTaxID == data.Tax1ID) {
        _this.selectedSalesTax1Description = _this.salesTax[i].Description;
      }
      if (_this.salesTax[i].SalesTaxID == data.Tax2ID) {
        _this.selectedSalesTax2Description = _this.salesTax[i].Description;
      }
      if (_this.salesTax[i].SalesTaxID == data.Tax3ID) {
        _this.selectedSalesTax3Description = _this.salesTax[i].Description;
      }
    }
  }

  getSettingData(data, salesTax) {
    const _this = this;
    _this.settings = data;
    if (data.ARCreditLimitAmount) {
      _this.arCreditLimit = numeral(data.ARCreditLimitAmount).value();
    } else {
      _this.arCreditLimit = 0;
    }
    _this.temparCreditLimit = _this.arCreditLimit;
    _this.ActivateClientRates = data.ActivateClientRates;

    if (data.DefaultInvoiceTemplate != null) {
      if (isNaN(data.DefaultInvoiceTemplate)) {
        _this.selectedInvoiceTemplate = _this.invoiceTemplates?.filter((client) => client['label'] == data.DefaultInvoiceTemplate)[0]?.value;
      } else {
        _this.selectedInvoiceTemplate = _this.invoiceTemplates?.filter((client) => client['value'] == data.DefaultInvoiceTemplate)[0]?.value;
      }
    }

    if (data.CompanyMangoLocationID != null && this.companyLocations.length > 0) {
      if (isNaN(data.CompanyMangoLocationID)) {
        _this.selectedCompanyLocation = _this.companyLocations?.filter((location) => location['label'] == data.CompanyMangoLocationID)[0]?.value;
      } else {
        _this.selectedCompanyLocation = _this.companyLocations?.filter((location) => location['value'] == data.CompanyMangoLocationID)[0]?.value;
      }
    } else {
      _this.selectedCompanyLocation = null;
    }
    
    _this.selectedPriorBalOption = data.isHidePriorBalance;
    _this.selectedShowBeforeInvoiceDateOption = data.isShowBeforeInvoiceDate;

    if (data.FinalizeAction != null) {
      _this.FinalizeAction = data.FinalizeAction;
      if(_this.FinalizeAction == 'Email') {
        // this.clientID
        this.mangoAPISrvc.showLoader(true)
        this.mangoAPISrvc.getEmailNames(this.clientID).subscribe((emailsList: any) => {
          this.mangoAPISrvc.showLoader(false)
          if (emailsList.length == 0) {
            Swal.fire({
              icon: "error",
              title: this._translate.instant("Information"),
              text:
                this._translate.instant("client_email_required_message"),
              showConfirmButton: true
            });
            data.FinalizeAction = 'Print';
            _this.FinalizeAction = data.FinalizeAction;
          }
        }, err => {
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        })
      }
    }

    _this.markDepositApplied = data.MarkDepositApplied;

    _this.tempMarkDepositApplied = _this.markDepositApplied;
    _this.markSlipBilled = data.MarkSlipsBilled;
    _this.isShowTearOffFooter = data.isShowTearOffFooter;

    _this.tempmarkSlipBilled = _this.markSlipBilled;
    _this.noServiceCharges = data.NoServiceCharges;

    _this.tempnoServiceCharges = _this.noServiceCharges;

    _this.isFirmDefaultPayments = data.isFirmDefaultPayments ? data.isFirmDefaultPayments : false;

    _this.modeOfPayment = data.modeOfPayment;
    

    _this.getSalesTax(data, salesTax);
    if (data.WIPCreditLimitAmount) {
      _this.wipCreditLimit = numeral(data.WIPCreditLimitAmount).value();
    } else {
      _this.wipCreditLimit = 0;
    }
    _this.tempwipCreditLimit = _this.wipCreditLimit;
    _this.NoLateFees = data.NoLateFees != null ? data.NoLateFees.toString() : null;
    if (_this.NoLateFees == null) {
      _this.NoLateFees = data.IsInterestRate != null ? 'false' : 'true';
    }

    _this.InterestRate = data.InterestRate != null ? data.InterestRate : 0;
    _this.GraceDays = data.GraceDays != null ? data.GraceDays : 0;
    _this.selectedSalesTax = data.SalesTaxLevel ? data.SalesTaxLevel : 'FirmRate'
    _this.Tax1ID = data.Tax1ID != null ? data.Tax1ID : 0.00;
    _this.Tax2ID = data.Tax2ID != null ? data.Tax2ID : 0.00
    _this.UseDefaultTerms = data.UseDefaultTerms != null ? data.UseDefaultTerms : false;
    if (_this.UseDefaultTerms) {
      _this.loadFromCompany();
    }
    _this.enableStatementReminder = data.InvoiceReminderActivate;
    _this.BlockTimeAndExpense = data.BlockTimeExpenseEntry ? data.BlockTimeExpenseEntry : false;
  }

  submitClientForm() {
    const formObj = this.clientSettingForm.value;
    formObj.modeOfPayment = formObj.modeOfPayment == -1 ? null : formObj.modeOfPayment;
    formObj.isFirmDefaultPayments = formObj.modeOfPayment ? false : true;
  }

  saveClientRate(isSave) {
    const _this = this;
    if (isSave) {
      _this.mangoAPISrvc.saveClientRate(_this.clientID, _this.clientRate).subscribe(function (data) {
        _this.getClientFetchRates();
        _this.mangoAPISrvc.notify('success', _this._translate.instant('Success'), AppConstants.createMsg);
      }, error => {
        _this.mangoAPISrvc.notify('error', _this._translate.instant('error'), AppConstants.createErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });

    } else {
      _this.mangoAPISrvc.updateClientRate(_this.selectedRateClientsID, _this.clientRate).subscribe(function (data) {
        _this.getClientFetchRates();
        _this.mangoAPISrvc.notify('success', _this._translate.instant('Success'), AppConstants.updateMsg);
      }, error => {
        _this.mangoAPISrvc.notify('error', this._translate.instant('error'), AppConstants.updateErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });
    }
    this.displayClientRateModal = false;
  }

  deleteClientRate(id) {
    const parent = this;
    Swal.fire({
      title: this._translate.instant('confirmation'),
      text: this._translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this._translate.instant('yes_delete'),
      cancelButtonText: this._translate.instant('no_cancel')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.deleteClientRate(id).subscribe(function (data) {
          parent.getClientFetchRates();
          parent.mangoAPISrvc.notify('success', this._translate.instant('deleted'), AppConstants.deleteMessage);
        }, error => {
          parent.mangoAPISrvc.notify('error', this._translate.instant('error'), AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

  getClientFetchRates() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getClientRates(this.clientID).subscribe(function (data: any) {
      _this.clientRates = data;
      for (let i = 0; i < data.length; i++) {
        _this.clientRates[i].index = i;
      }
      _this.mangoAPISrvc.showLoader(false);
    }, error => {
      _this.mangoAPISrvc.notify('error', this._translate.instant('error'), AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  getClientRates(data) {
    const _this = this;
    _this.clientRates = data;
    for (let i = 0; i < data.length; i++) {
      _this.clientRates[i].index = i;
    }
  }

  getTerms(data, obj) {
    const _this = this;
    for (let i = 0; i < obj.length; i++) {
      if (!obj[i].Inactive) {
        _this.termsList.push({ label: obj[i].TermsDescription, value: obj[i].TermsID, GraceDays: obj[i].GraceDays })
      }
    }
    if (data.TermsID != null) {
      _this.selectedTerm = data.TermsID;
    } else {
      _this.selectedTerm = _this.termsList[0].value;
    }
  }

  loadFromCompany(changed?) {
    if(changed == true) {
      this.isUseDefaultTerms = true;
    }
    if (this.UseDefaultTerms) {
      this.NoLateFees = this.invoiceOpt.NoLateFees;
      this.InterestRate = this.invoiceOpt.InterestRate;
      this.GraceDays = this.invoiceOpt.GraceDays;
      this.IsInterestRate = this.invoiceOpt.IsInterestRate;
      this.UseDefaultTerms = true;
    } else {
      this.NoLateFees = this.settings.NoLateFees ? this.settings.NoLateFees.toString() : 'true';
      this.InterestRate = this.settings.InterestRate;
      this.GraceDays = this.settings.GraceDays;
      this.IsInterestRate = this.settings.IsInterestRate;
      this.UseDefaultTerms = false;
    }
  }

  transformarCreditLimit(event) {
    const myNumeral = numeral(this.arCreditLimit);
    if (myNumeral.value() === null) {
      this.arCreditLimit = '0.00';
      return;
    }
    this.formattedAmount = numeral(myNumeral.value()).format('0,0.00');
    this.arCreditLimit = this.formattedAmount;
    event.target.value = this.formattedAmount;
  }

  transformwipCreditLimit(event) {
    const myNumeral = numeral(this.wipCreditLimit);
    if (myNumeral.value() === null) {
      this.wipCreditLimit = '0.00';
      return;
    }
    this.formattedAmount = numeral(myNumeral.value()).format('0,0.00');
    this.wipCreditLimit = this.formattedAmount;
    event.target.value = this.formattedAmount;
  }

  transformbillingRate(event) {
    const myNumeral = numeral(this.clientRate.BillingRate);
    if (myNumeral.value() === null) {
      this.clientRate.BillingRate = '0.00';
      return;
    }
    this.formattedAmount = numeral(myNumeral.value()).format('0,0.00');
    this.clientRate.BillingRate = this.formattedAmount;
    event.target.value = this.formattedAmount;
  }

  selectedSalesTaxDropDown(tax, id) {
    if (id == 1) {
      this.selectedSalesTax1Description = tax.Description;
      this.selectedSalesTax1 = tax.SalesTaxID;
    }
    else if (id == 2) {
      this.selectedSalesTax2Description = tax.Description;
      this.selectedSalesTax2 = tax.SalesTaxID;
    }
    else {
      this.selectedSalesTax3Description = tax.Description;
      this.selectedSalesTax3 = tax.SalesTaxID;
    }
  }
  addClientRate() {
    this.clientRate = {}

    this.serviceCodeId = null;
    this.selectedRateClientsID = null;
    this.selectedActivityDesc = null;
    this.isFromEdit = false;
    this.isEditClient = false;
    this.displayClientRateModal = true;
  }

  editClientRate(obj) {
    this.selectedRateClientsID = obj.RateClientsID;
    this.selectedActivityDesc = obj.Description;
    this.serviceCodeId = { serviceCodeID: obj.ServiceCodeID, description: obj.Description };
    this.clientRate = { ServiceCode: obj.ServiceCode, ServiceCodeID: obj.ServiceCodeID, BillingRate: numeral(obj.BillingRate).value() };
    this.clientRate['ProjectMasterID'] = obj['ProjectMasterID'];
    this.isEditClient = true;
    this.displayClientRateModal = true;
  }

  cancelClientRate() {
    this.rateFormValid = false;
    this.ngForm.form.markAsPristine();
    this.ngForm.form.markAsUntouched();
    this.ngForm.form.updateValueAndValidity();
    this.displayClientRateModal = false;
  }

  updateActivityDesc(event) {
    const data = event.value;
    if (data == null) {
      this.selectedActivityDesc = null;
    } else {
      this.selectedActivityDesc = data.description;
      this.clientRate.ServiceCodeID = data.serviceCodeID;
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterActivities(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const tempObj = [];
      this.filteredActivities = [];
      for (let i = 0; i < this.activities.length; i++) {
        const activity = this.activities[i];
        if (activity['ServiceCode'] && activity['ServiceCode'].toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
          tempObj.push(activity);
        }
      }
      this.filteredActivities = tempObj.sort(this.mangoUtils.compareValues('ServiceCode', 'asc'));
      this.filterTimeout.unsubscribe();
    })
  }

  handleActivitySelectClick(obj) {
    this.selectedActivityDesc = obj.Description;
    this.clientRate.ServiceCodeID = obj.ServiceCodeID;
    this.clientRate.ServiceCode = obj.ServiceCode;
  }

  /*
  Click Autocomplete
  */
  handleActivityDropdownClick(event) {
    this.filteredActivities = [];
    //mimic remote call
    setTimeout(() => {
      this.filteredActivities = this.activities;
    }, 1)
  }

  formatePercentage() {
    let enteredValue = numeral(this.InterestRate).value();
    enteredValue = enteredValue ? enteredValue : 0;
    this.InterestRate = numeral(enteredValue).format('0.00');
  }
  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
  }

  selectedTermChange(event){
    this.isSelectedTerm = true;
    const selectedItem = this.termsList.filter(itm => itm.value === event.value);
    if(selectedItem.length > 0){
      this.GraceDays = selectedItem[0].GraceDays;
    }
  }

  changeInvoiceTemplates(event) {
    this.isInvoiceTemplate = true;
  }

  changeLateFees(event) {
    this.isNoLateFees = true;
  }

  changeInterestRate(event) {
    this.isInterestRate = true;
  }

  changeGraceDays(event) {
    this.isGraceDays = true;
  }

  changeStatementReminder(event) {
    this.isEnableStatementReminder = true;
  }

  changeServiceCharges(event) {
    this.isNoServiceCharges = true;
  }

  changeSlipBilled(event) {
    this.isMarkSlipBilled = true;
  }

  changeDepositApplied(event) {
    this.isMarkDepositApplied = true;
  }

  changeBlockTimeAndExpense(event) {
    this.isBlockTimeAndExpense = true;
  }

  changePriorBalOption(event) {
    this.isSelectedPriorBalOption = true;
  }

  changeShowBeforeInvoiceDateOption(event) {
    this.isSelectedShowBeforeInvoiceDate = true;
  }

  changeCompanyLocation(event) {
    this.isSelectedCompanyLocation = true;
  }

  changeSalesTax(event) {
    this.isSelectedSalesTax = true;
  }

  changeTax1ID(event) {
    this.isTax1ID = true;
  }

  changeTax2ID(event) {
    this.isTax2ID = true;
  }

  changeModeOfPayment(event) {
    this.isModeOfPayment = true;
  }
}
