import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { AppConstants } from '@app/_helpers/api-constants';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html'
})
export class ScheduleComponent implements OnInit {
  public StaffID: number = null;
  busy: Promise<any>;
  public daysDataSet: SelectItem[];
  public weekDataSet: SelectItem[];
  public intervalDataSet: SelectItem[];
  public timeIntervalDataSet: SelectItem[];
  public scheduleForm: UntypedFormGroup;
  public selectedCategories: string[];
  public selectedUserNameStr: any;
  public isNewschedulePreferenceData: boolean = true;
  public isFormValid: boolean = false;
  public appointmentsResourceData: any = {};
  public ScheduleRefreshTimerItem: SelectItem[];
  public userDetails: any = {};

  constructor(private router: Router, private _fb: UntypedFormBuilder,private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public translate: TranslateService) {
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.selectedUserName);
    this.StaffID = this.encrDecSrvc.getObject(AppConstants.usersID);
    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('user.user_list')},
        { label: this.translate.instant('schedule_preferences')},
        { label: this.selectedUserNameStr, icon: 'ic-red' }
      ]);
      this.initializeScheduleFormData();
    })
    this.initializeScheduleForm();
  }

  ngOnInit(): void {
    this.initEditableUser();
    this.getAppointmentsResource(this.StaffID);
    this.getStaffDetails(this.StaffID);
  }

  saveScheduleData(arg1: any) {
    this.mangoAPISrvc.isFormChanged = false;
    const obj = this.scheduleForm.value;
    obj['EndTimeVisible'] = obj['EndTimeWork'];
    obj['StartTimeVisible'] = obj['StartTimeWork'];
    obj.WebAppDaysofWeek = this.selectedCategories.map(Number);
    this.appointmentsResourceData.Color = this.scheduleForm.value['Color'];
    if (this.StaffID) {
      if (this.isNewschedulePreferenceData) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.addSchedulePreference(this.StaffID, obj).subscribe((data: any) => {
          this.updateAppointmentsResource();
          if (data.message) {
            this.mangoAPISrvc.notify('success', this.translate.instant('Success'), data.message);
            this.scheduleForm.markAsPristine();
            this.scheduleForm.markAsUntouched();
            this.scheduleForm.updateValueAndValidity();
          }
        }, error => {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        })
      } else {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.updateSchedulePreferenceById(this.StaffID, obj).subscribe((data: any) => {
          this.updateAppointmentsResource();
          if (data.message) {
            this.mangoAPISrvc.notify('success', this.translate.instant('Success'), data.message);
            this.scheduleForm.markAsPristine();
            this.scheduleForm.markAsUntouched();
            this.scheduleForm.updateValueAndValidity();
            this.mangoAPISrvc.showLoader(false);
          }
        }, error => {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        })
      }

      const userDetails = this.userDetails;
      userDetails['ScheduleRefreshTimer'] = obj['ScheduleRefreshTimer'];
      this.mangoAPISrvc.updateUserByAdmin(this.StaffID, userDetails).subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
      }, err=>{
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      })

    }
  }

  initializeScheduleForm() {
    this.scheduleForm = this._fb.group({
      FirstDayofWeek: ['Monday', [<any>Validators.required]],
      DefaultView: ['Work Week', [<any>Validators.required]],
      DefaultTimeInterval: ['30 minutes', [<any>Validators.required]],
      EndTimeVisible: ['1970-01-01T16:00:00Z'],
      EndTimeWork: ['1970-01-01T16:00:00Z', [<any>Validators.required]],
      StartTimeVisible: ['1970-01-01T09:00:00Z'],
      StartTimeWork: ['1970-01-01T09:00:00Z', [<any>Validators.required]],
      WebAppDaysofWeek: ['[1, 2, 3, 4, 5]', [<any>Validators.required]],
      ScheduleRefreshTimer: 0, //in seconds
      Color: ['#51749e']
    });
    this.selectedCategories = ['1', '2', '3', '4', '5'];

    this.scheduleForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.scheduleForm.pristine);
      this.validateForm();
    })
  }

  getAppointmentsResource(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getUniqueIdFromResourceId(id).subscribe((response) => {
      const data = response[0];
      data['Color'] = data['Color'] ? data['Color'] : '#ffffff';
      parent.scheduleForm.controls['Color'].setValue(data['Color']);
      parent.appointmentsResourceData = data;
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.deleteErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  getStaffDetails(id){
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getUserById(id).subscribe((data: any)=>{
        parent.userDetails = data;
        const schedTime = data.ScheduleRefreshTimer ? data.ScheduleRefreshTimer : 0;
        this.scheduleForm.controls['ScheduleRefreshTimer'].setValue(schedTime);
    });
  }

  updateAppointmentsResource() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateAppointmentResource(parent.appointmentsResourceData).subscribe((data) => {
      parent.appointmentsResourceData = data;
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.deleteErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  updateScheduleForm(itemData: any) {
    if (Object.keys(itemData).length > 0) {
      this.isNewschedulePreferenceData = false;
      this.scheduleForm.controls['DefaultTimeInterval'].setValue(itemData['DefaultTimeInterval']);
      this.scheduleForm.controls['DefaultView'].setValue(itemData['DefaultView']);
      this.scheduleForm.controls['EndTimeVisible'].setValue(itemData['EndTimeVisible']);
      this.scheduleForm.controls['EndTimeWork'].setValue(itemData['EndTimeWork']);
      this.scheduleForm.controls['FirstDayofWeek'].setValue(itemData['FirstDayofWeek']);
      this.scheduleForm.controls['StartTimeVisible'].setValue(itemData['StartTimeVisible']);
      this.scheduleForm.controls['StartTimeWork'].setValue(itemData['StartTimeWork']);
      this.scheduleForm.controls['WebAppDaysofWeek'].setValue(itemData['WebAppDaysofWeek'].map(String));
      this.selectedCategories = itemData['WebAppDaysofWeek'].map(String);
    }
  }

  initializeScheduleFormData() {
    this.daysDataSet = [
      { label: this.translate.instant('Monday'), value: 'Monday' }, 
      { label: this.translate.instant('Tuesday'), value: 'Tuesday' }, 
      { label: this.translate.instant('Wednesday'), value: 'Wednesday' }, 
      { label: this.translate.instant('Thursday'), value: 'Thursday' }, 
      { label: this.translate.instant('Friday'), value: 'Friday' }, 
      { label: this.translate.instant('Saturday'), value: 'Saturday' }, 
      { label: this.translate.instant('Sunday'), value: 'Sunday' }];
    this.weekDataSet = [
      { label: this.translate.instant('day'), value: 'Day' }, 
      { label: this.translate.instant('work_week'), value: 'Work Week' }, 
      { label: this.translate.instant('Week'), value: 'Week' }, 
      { label: this.translate.instant('Month'), value: 'Month' }];
    this.intervalDataSet = [
      { label: `15 ${this.translate.instant('minutes')}`, value: '15 minutes' }, 
      { label: `30 ${this.translate.instant('minutes')}`, value: '30 minutes' }, 
      { label: `1 ${this.translate.instant('billable-hrs')}`, value: '1 hour' }, 
      { label: `2 ${this.translate.instant('billable-hrs')}`, value: '2 hours' }];

    this.timeIntervalDataSet = [{ label: '12:00 AM', value: '1970-01-01T00:00:00Z' }, { label: '12:30 AM', value: '1970-01-01T00:30:00Z' }, { label: '1:00 AM', value: '1970-01-01T01:00:00Z' }, { label: '1:30 AM', value: '1970-01-01T01:30:00Z' }, { label: '2:00 AM', value: '1970-01-01T02:00:00Z' }, { label: '2:30 AM', value: '1970-01-01T02:30:00Z' }, { label: '3:00 AM', value: '1970-01-01T03:00:00Z' }, { label: '3:30 AM', value: '1970-01-01T03:30:00Z' },
    { label: '4:00 AM', value: '1970-01-01T04:00:00Z' }, { label: '4:30 AM', value: '1970-01-01T04:30:00Z' }, { label: '5:00 AM', value: '1970-01-01T05:00:00Z' }, { label: '5:30 AM', value: '1970-01-01T05:30:00Z' }, { label: '6:00 AM', value: '1970-01-01T06:00:00Z' }, { label: '6:30 AM', value: '1970-01-01T106:30:00Z' }, { label: '7:00 AM', value: '1970-01-01T07:00:00Z' }, { label: '7:30 AM', value: '1970-01-01T07:30:00Z' },
    { label: '8:00 AM', value: '1970-01-01T08:00:00Z' }, { label: '8:30 AM', value: '1970-01-01T08:30:00Z' }, { label: '9:00 AM', value: '1970-01-01T09:00:00Z' }, { label: '9:30 AM', value: '1970-01-01T09:30:00Z' }, { label: '10:00 AM', value: '1970-01-01T10:00:00Z' }, { label: '10:30 AM', value: '1970-01-01T10:30:00Z' }, { label: '11:00 AM', value: '1970-01-01T11:00:00Z' }, { label: '11:30 AM', value: '1970-01-01T11:30:00Z' },
    { label: '12:00 PM', value: '1970-01-01T12:00:00Z' }, { label: '12:30 PM', value: '1970-01-01T12:30:00Z' }, { label: '1:00 PM', value: '1970-01-01T13:00:00Z' }, { label: '1:30 PM', value: '1970-01-01T13:30:00Z' }, { label: '2:00 PM', value: '1970-01-01T14:00:00Z' }, { label: '2:30 PM', value: '1970-01-01T14:30:00Z' }, { label: '3:00 PM', value: '1970-01-01T15:00:00Z' }, { label: '3:30 PM', value: '1970-01-01T15:30:00Z' },
    { label: '4:00 PM', value: '1970-01-01T16:00:00Z' }, { label: '4:30 PM', value: '1970-01-01T16:30:00Z' }, { label: '5:00 PM', value: '1970-01-01T17:00:00Z' }, { label: '5:30 PM', value: '1970-01-01T17:30:00Z' }, { label: '6:00 PM', value: '1970-01-01T18:00:00Z' }, { label: '6:30 PM', value: '1970-01-01T18:30:00Z' }, { label: '7:00 PM', value: '1970-01-01T19:00:00Z' }, { label: '7:30 PM', value: '1970-01-01T19:30:00Z' },
    { label: '8:00 PM', value: '1970-01-01T20:00:00Z' }, { label: '8:30 PM', value: '1970-01-01T20:30:00Z' }, { label: '9:00 PM', value: '1970-01-01T21:00:00Z' }, { label: '9:30 PM', value: '1970-01-01T21:30:00Z' }, { label: '10:00 PM', value: '1970-01-01T22:00:00Z' }, { label: '10:30 PM', value: '1970-01-01T22:30:00Z' }, { label: '11:00 PM', value: '1970-01-01T23:00:00Z' }, { label: '11:30 PM', value: '1970-01-01T23:30:00Z' },
    ];

    this.ScheduleRefreshTimerItem =  [
      { label: `None`, value: 0 },
      { label: `Every 5 ${this.translate.instant('seconds')}`, value: 5 },
      { label: `Every 15 ${this.translate.instant('seconds')}`, value: 15 }, 
      { label: `Every 30 ${this.translate.instant('seconds')}`, value: 30 },
      { label: `Every 60 ${this.translate.instant('seconds')}`, value: 60 },
      { label: `Every 5 ${this.translate.instant('minutes')}`, value: 60*5 },
      { label: `Every 15 ${this.translate.instant('minutes')}`, value: 60*15 },
      { label: `Every 30 ${this.translate.instant('minutes')}`, value: 60*30 },
      { label: `Every 45 ${this.translate.instant('minutes')}`, value: 60*45 },
      { label: `Every 1 hour`, value: (60*60) }
    ]
  }

  initEditableUser() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getSchedulePreference(this.StaffID).subscribe((data) => {
      this.updateScheduleForm(data);
      this.mangoAPISrvc.showLoader(false);
    }, error => {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    });

  }

  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.scheduleForm.controls).forEach(key => {
      if (this.scheduleForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.scheduleForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.scheduleForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
    this.mangoAPISrvc.isFormChanged = istouchedData;
  }

  redirectToUserList() {
    this.encrDecSrvc.addObject(AppConstants.usersID, -1);
    this.router.navigate([AppConstants.users + '/' + AppConstants.listRoutePath]);
    setTimeout(() => {
      this.mangoAPISrvc.fireUserView(true);
    }, 100);
  }
}
