<div class="client-schedule">
    <div class="layout-content">
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt id="schedule-table" [value]="schedule" [responsive]="true" sortField="ClientName" [columns]="selectedColumns"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        dataKey="ClientID" [filterDelay]="0" [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                        [paginator]="schedule.length > 10"
                        [globalFilterFields]="globalFilterColumns"
                        (onFilter)="onFilter($event)" [sortOrder]="0"
                        [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-6">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{ 'Search-by' | translate }}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                                        </p-message>
                                    </div>
                                </div>
                                <div class="p-col-6 p-text-right">
                                    <p-dropdown [options]="dateRangeOptions" [(ngModel)]="selectedDateRange" class="width-60p" (onChange)="getAppointments()">
                                    </p-dropdown>
                                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"  styleClass="p-ml-3" (onChange)="onChangeSelectedCols($event)"
                                    selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                                    placeholder="Choose Columns"></p-multiSelect>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-2p p-text-left"></th>
                                <th class="width-5p p-text-left" pSortableColumn="StartDate">
                                    {{ 'Start' | translate }}
                                    <p-sortIcon field="StartDate"></p-sortIcon>
                                </th>
                                <th class="width-5p p-text-left" pSortableColumn="EndDate">
                                    {{ 'End' | translate }}
                                    <p-sortIcon field="EndDate"></p-sortIcon>
                                </th>
                                <th class="width-9p p-text-left p-text-nowrap p-text-truncate" pSortableColumn="Subject">
                                    {{ 'Subject' | translate }}
                                    <p-sortIcon field="Subject"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left">
                                    {{ 'attendees' | translate }}
                                </th>
                                <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="{{col.class}}">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                </th>
                                <!-- <th class="width-7p p-text-left" pSortableColumn="Location">
                                    {{'Location' | translate}}
                                    <p-sortIcon field="Location"></p-sortIcon>
                                </th>
                                
                                <th class="width-9p p-text-left p-text-nowrap p-text-truncate" pSortableColumn="Description">
                                    {{ 'Description' | translate }}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="Rules">
                                    {{ 'Rules' | translate }}
                                    <p-sortIcon field="Rules"></p-sortIcon>
                                </th>
                                <th class="width-2p p-text-left" pSortableColumn="AllDay">
                                    {{ 'All_Day' | translate }}
                                </th> -->
                               
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td class="width-2p"></td>
                                <td class="width-5p p-text-left">
                                    <span *ngIf="!rowData.AllDay">{{rowData.StartDate}}</span>
                                    <span *ngIf="rowData.AllDay" style="color: red;">--</span>
                                </td>
                                <td class="width-5p p-text-left">
                                    <span *ngIf="!rowData.AllDay">{{rowData.EndDate}}</span>
                                    <span *ngIf="rowData.AllDay" style="color: red;">--</span>
                                </td>
                                <td class="width-9p p-text-left" pTooltip="{{rowData.Subject}}" showDelay="800" tooltipPosition="top">
                                    <span>{{rowData.Subject}}</span>
                                </td>
                                <td class="width-7p p-text-left">
                                    <span class="flag" *ngFor="let staff of rowData.AttendeesName; let index = index" [ngStyle]="{backgroundColor: staff.color }">{{staff.name}}</span>
                                </td>
                                <td *ngFor="let col of columns" class="{{col.class}}" pTooltip="{{rowData[col.field]}}"
                                    tooltipPosition="top" showDelay="800">
                                    <span *ngIf="col.field !== 'AllDay'">{{rowData[col.field]}}</span>
                                    <span *ngIf="col.field === 'AllDay' && rowData['AllDay']">
                                        <i class="fal fa-check ic-green cursor-hand" style="padding-left:8px;"></i>
                                    </span>
                                </td>
                                <!-- <td class="width-7p p-text-left"  pTooltip="{{rowData.Location}}" showDelay="800" tooltipPosition="top" >
                                    <span>{{rowData.Location}}</span>
                                </td>
                               
                                <td class="width-9p p-text-left"  pTooltip="{{rowData.Description}}" showDelay="800" tooltipPosition="top">
                                    <span>{{rowData.Description}}</span>
                                </td>
                               
                                <td class="width-7p p-text-left" pTooltip="{{rowData.Rules}}" showDelay="800" tooltipPosition="top" >
                                    <span>{{rowData.Rules}}</span>
                                </td>
                                <td class="width-2p p-text-left">
                                    <span *ngIf="rowData['AllDay']">
                                        <i class="fal fa-check ic-green cursor-hand" style="padding-left:8px;"></i>
                                    </span>
                                </td> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="9">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>