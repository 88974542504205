import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { Table } from 'primeng/table';
import moment from 'moment';
import { forkJoin, of, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, SortEvent } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
interface ICachedEmailBody {
  messageId: string;
  content: string;
}

@Component({
  selector: 'app-client-mails',
  templateUrl: './client-mails.component.html'
})
export class ClientMailsComponent implements OnInit {
  @ViewChild('htmlBody', { static: false }) emailContent: ElementRef;
  public logsData = [];
  public logsTempData: any[] = [];
  public clientID: number = null;
  public clientEmail: string = null;
  public viewDetails: boolean = false;
  public selectedRowData: any = {};
  public companyName: any = '';
  cachedEmailBodies: ICachedEmailBody[] = [];
  dateFrom: Date;
  dateTo: Date;
  dateRange = null;
  maxDate = new Date();
  public dateRangeList: SelectItem[];
  showDefault: boolean = false;
  companyId;

  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = '';
  htmlBody: any;
  companyTelephone: string = '';
  staffData: any;

  constructor(
    private http: HttpClient,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    protected mangoUtils: mangoUtils,
    private translate: TranslateService,
    private renderer: Renderer2
  ) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.dateTo = new Date();
    this.dateFrom = moment(new Date()).subtract(30, 'days').toDate();
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.searchTextStr = this.encrDecSrvc.getObject(
      AppConstants.mailRoutePath + '_' + AppConstants.SearchString
    );
    this.companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.companyTelephone = this.encrDecSrvc.getObject(AppConstants.telephone);
    this.staffData = this.encrDecSrvc.getObject(AppConstants.staffData);

    if (this.staffData?.DefaultDateOption) {
      this.dateRange = this.staffData.DefaultDateOption;
      this.changeDateTypes();
    }
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('email_logs') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
    this.dateRangeList = [
      { label: this.translate.instant('Last 30 days'), value: null },
      { label: this.translate.instant('reports.Todays_Date'), value: 'Today' },
      { label: this.translate.instant('This-Week'), value: 'Week' },
      { label: this.translate.instant('reports.This_Month'), value: 'Month' },
      // { label: this.translate.instant("rpt.This_Quarter"), value: "Quarter" },
      // { label: this.translate.instant("rpt.This_Year"), value: "Year" },
      { label: this.translate.instant('rpt.Last_Week'), value: 'lastweek' },
      { label: this.translate.instant('rpt.Last_Month'), value: 'lastmonth' },
      // { label: this.translate.instant("rpt.Last_Quarter"), value: "lastquarter" },
      // { label: this.translate.instant("rpt.Last_Year"), value: "lastyear" },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];
  }

  changeDateTypes() {
    const obj = this.dateRange;
    if (obj == 'Today') {
      this.dateFrom = new Date();
      this.dateTo = new Date();
    } else if (obj == 'Week') {
      this.dateFrom = new Date(moment().startOf('isoWeek').format());
      this.dateTo = new Date();
    } else if (obj == 'lastweek') {
      this.dateFrom = new Date(moment().startOf('isoWeek').subtract(1, 'week').format());
      this.dateTo = new Date(moment().endOf('isoWeek').subtract(1, 'week').format());
    } else if (obj == 'Month') {
      this.dateFrom = new Date(moment().startOf('month').format());
      // this.dateTo = new Date(moment().endOf('month').format());
      this.dateTo = new Date();
    } else if (obj == 'lastmonth') {
      this.dateFrom = new Date(moment().subtract(1, 'months').startOf('month').format());
      this.dateTo = new Date(moment().subtract(1, 'months').endOf('month').format());
    } else if (obj == 'Quarter') {
      this.dateFrom = new Date(moment().startOf('quarter').format());
      this.dateTo = new Date(moment().endOf('quarter').format());
    } else if (obj == 'lastquarter') {
      this.dateFrom = new Date(moment().subtract(1, 'quarters').startOf('quarter').format());
      this.dateTo = new Date(moment().subtract(1, 'quarters').endOf('quarter').format());
    } else if (obj == 'Year') {
      this.dateFrom = new Date(moment().startOf('year').format());
      this.dateTo = new Date(moment().endOf('year').format());
    } else if (obj == 'lastyear') {
      this.dateFrom = new Date(moment().subtract(1, 'years').startOf('year').format());
      this.dateTo = new Date(moment().subtract(1, 'years').endOf('year').format());
    } else if (obj == 'custom') {
      this.dateFrom = new Date();
      this.dateTo = new Date();
    } else {
      this.dateTo = new Date();
      this.dateFrom = moment(new Date()).subtract(30, 'days').toDate();
      return;
    }
    // this.prevDateFrom = this.myReportsForm.controls['DateFrom'].value;
    // this.prevDateTo =  this.myReportsForm.controls['DateTo'].value;
    this.changeDateFrom();
  }

  ngOnInit(): void {
    this.getEventReports();
  }

  changeDateFrom() {
    const now = moment(this.dateTo);
    const end = moment(this.dateFrom);
    if (now && end) {
      const duration = moment.duration(now.diff(end));
      const days = duration.asDays();
      if (days > 30) {
        this.dateTo = moment(this.dateFrom).add(30, 'days').toDate();
        if (this.dateTo > this.maxDate) {
          this.dateTo = this.maxDate;
        }
      }
      this.getEventReports();
    }
  }

  changeDateTo() {
    const now = moment(this.dateTo);
    const end = moment(this.dateFrom);
    if (now && end) {
      const duration = moment.duration(now.diff(end));
      const days = duration.asDays();
      if (days > 30) {
        this.dateFrom = moment(this.dateTo).subtract(30, 'days').toDate();
      }
      this.getEventReports();
    }
  }

  getEventReports() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    const fromDate = moment(this.dateFrom).format("YYYY-MM-DD");
    const toDate = moment(this.dateTo).format("YYYY-MM-DD");
    const deliveryUrl = `limit=100&startDate=${fromDate}&endDate=${toDate}&tags=${this.clientID}`;

    const delviUrls = this.mangoAPISrvc.getEmailLogs(deliveryUrl);

    self.logsTempData = [];
    self.logsData = [];
    forkJoin([delviUrls]).subscribe(
      (data: any) => {
        console.log(data);

        if (data[0].events && data[0].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(
            data[0].events.filter(mail => mail.event != 'loadedByProxy' && mail.event != 'requests')
          );
        }

        let tempArr = [];
        for (let index = 0; index < self.logsTempData.length; index++) {
          const element = self.logsTempData[index];
          let clientId = element['tag'].split(',')[0];
          if (self.clientID == clientId) {
            element.date = moment(element.date).format('MMM D YYYY, h:mm:ss A');
            tempArr.push(element);
          }
        }
        self.mangoAPISrvc.showLoader(false);

        /**
         * Default data type of `date` coming from API is string. For sorting based on date to work, cast it to `Date` type first.
         *
         * Otherwise its sort behavior will be like string, it will based on the first letter of data.
         * e.g. In Ascending "April 2021" will come first before "January 2021"
         */
        self.logsData = tempArr.map(log => {
          return {
            ...log,
            date: new Date(log.date)
          };
        });

        self.loadFilterGrid();
      },
      error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  async onRowSelect(data) {
    const cachedBody = this.cachedEmailBodies.find((item) => item.messageId === data?.messageId);
    if (cachedBody) {
      this.viewDetails = true;
      this.renderer.setProperty(
        this.emailContent.nativeElement,
        'innerHTML',
        cachedBody["content"]
      );
      return;
    }
    this.mangoAPISrvc.showLoader(true);
    const match = data?.messageId.match(/<(\d{8})\d*[\.\-\w]*@[a-zA-Z0-9.-]+>/);
    let fromDate;
    if (match && match[1]) {
      const rawDate = match[1]; 
      fromDate = moment(rawDate, 'YYYYMMDD').format('YYYY-MM-DD');
    } else {
      fromDate = moment(this.dateFrom).format('YYYY-MM-DD');
    }
    let toDate = moment(fromDate).format('YYYY-MM-DD');
    let differenceInDays = moment(toDate).diff(moment(fromDate), 'days');
    if (differenceInDays >= 30) {
      toDate = moment(toDate).subtract(1, 'days').format('YYYY-MM-DD');
    }
    let deliveryUrl = `sort=desc&limit=1&offset=0&startDate=${fromDate}&endDate=${toDate}&tags=${this.clientID}`;
    try {
      const result = await this.mangoAPISrvc.fetchEmailBody({ messageId: data?.messageId, event: deliveryUrl });
      if (result) {
        this.cachedEmailBodies.push({
          messageId: data?.messageId,
          content: result["data"]
        });
        this.viewDetails = true;
        this.renderer.setProperty(
          this.emailContent.nativeElement,
          'innerHTML',
          result['data']
        );
      }
    } catch (error) {
      this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg);
    } finally {
      this.mangoAPISrvc.showLoader(false);
    }
  }

  clearContent(elementID) {
    document.getElementById(elementID).innerHTML = '<p></p>';
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.mailRoutePath + '_' + AppConstants.SearchString, '');
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      AppConstants.mailRoutePath + '_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || '';
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  customSort(event: SortEvent) {
    this.mangoUtils.tableColumnsSort(event);
  }

  getEmailTemplates(templateType) {
    return this.mangoAPISrvc.getEmailTemplateByTypeAndCompany(templateType, this.companyId);
  }

  getPreviousBalance(date) {
    return this.mangoAPISrvc.getPreviousBalance(
      this.selectedRowData['ClientID'],
      moment(date).format(),
      0
    );
  }

  getContacts() {
    if (this.selectedRowData['ClientID'])
      return this.mangoAPISrvc.getContactsByClientId(this.selectedRowData['ClientID']);
  }

  parseHTMLTokens(htmlBody, type, data) {
    console.log({ htmlBody, type, data, selectedRowData: this.selectedRowData });
    htmlBody = htmlBody.replaceAll('%YourFirmName%', this.companyName ?? '');
    htmlBody = htmlBody.replaceAll(
      '%Date%',
      moment(this.selectedRowData['InvoiceDate']).format('L') ?? ''
    );
    htmlBody = htmlBody.replaceAll(
      '%Balance%',
      type == 'statement'
        ? this.selectedRowData['PreviousBalance'] ?? ''
        : this.selectedRowData['InvoiceBalance'] ?? ''
    );
    htmlBody = htmlBody.replaceAll(
      '%Amount%',
      type == 'invoice'
        ? this.selectedRowData['InvoiceAmount']
        : this.selectedRowData['PaymentAmount'] ?? ''
    );
    htmlBody = htmlBody.replaceAll('%InvoiceNumber%', this.selectedRowData['InvoiceNumber'] ?? '');
    htmlBody = htmlBody.replaceAll('%Email%', data.email ?? '');
    htmlBody = htmlBody.replaceAll('%Summary%', this.selectedRowData['DescriptionShort'] ?? '');
    htmlBody = htmlBody.replaceAll(
      '%ContactName%',
      data['contactDetails'].ContactName ? data['contactDetails'].ContactName : ''
    );
    htmlBody = htmlBody.replaceAll(
      '%ClientName%',
      data['contactDetails'].Company ? data['contactDetails'].Company : ''
    );
    htmlBody = htmlBody.replaceAll('%Mobile%', this.companyTelephone ? this.companyTelephone : '');
    return htmlBody;
  }
}
