<div class="email-automation-setup">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="Save" [disabled]="!isValid" icon="fas fa-check" class="p-button-sm p-pr-2 p-mr-2"
                (click)="saveOptions()"></button>
        </span>

        <div class="p-grid">
            <div class="p-col-12 p-pb-0">
                <div class="card p-mx-3">
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-2 p-mt-2 p-mb-0">
                            <p-checkbox class="label-text" [(ngModel)]="ActivateEmailAutomation" name="activateEmail"
                                label="Activate Automation" (onChange)="onToggle()" binary="true">
                            </p-checkbox><i class="fa fa-question-circle ic-blue cursor-hand p-ml-2"
                                pTooltip="Activate Email Automation" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"></i>
                        </div>
                        <ng-template [ngIf]="ActivateEmailAutomation">
                            <div class="p-field p-col-3 p-mb-0">
                                <label class="label-text">Email Automation</label>
                                <p-dropdown placeholder="Select Option" [options]="optionsSet" styleClass="width-55p"
                                    [(ngModel)]="EmailAutomationIntegration" (onChange)="onChangeOptions($event)">
                                </p-dropdown>
                            </div>
                            <div class="p-field p-col-7 p-mb-0">
                                <div class="p-formgroup-inline">
                                    <label class="label-text p-mt-2">API Key
                                        <i pTooltip="Notification: We are storing your API Key in our database."
                                            tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" class="fa fa-question-circle ic-blue cursor-hand"></i>
                                    </label>
                                    <div class="p-col p-fluid p-pt-0">
                                        <input type="password" [disabled]="!EmailAutomationIntegration" pInputText class="p-text-left"
                                            [(ngModel)]="EmailAutomationKey" (change)="onAPIKeyChange()">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>