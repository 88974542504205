import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, AuthGuard } from '@app/_services';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { HtmlEditorService, ImageService, LinkService, RichTextEditorComponent, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from "primeng/table";
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import $ from "jquery";
import { ClientComponent } from '../client.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class ClientViewComponent implements OnInit, OnDestroy {
  clientID: number = -1;
  public editorInitialValue: any = null;
  public editorValue: any = null;
  editableClient: any = {};
  invoiceOpt: any = {};
  isPasswordReset: boolean = false;
  clientForm: UntypedFormGroup;
  clientType: SelectItem[];
  clientGroupCategory: SelectItem[];
  billingGroupCategory: SelectItem[];
  staffList: SelectItem[];
  staffListWithInactive: SelectItem[];
  public clientName: string = null;
  stat_openBalance: any = 0;
  stat_bal_over30: any = 0;
  stat_revenueCy: any = 0;
  stat_revenuePy: any = 0;
  stat_paymentsCy: any = 0;
  stat_paymentsPy: any = 0;
  stat_unbilledTime: any = 0;
  stat_unbilledExp: any = 0;
  userDefinedFieldsEnabled: boolean = false;
  showGMap: boolean = false;
  ValidEmail = false;
  scratchPadEnabled: boolean = false;
  isContactRecord: boolean = false;
  @ViewChild('clientEditor')
  public rteObj: RichTextEditorComponent;
  isFormValid: boolean = false;
  contacts: any = [];
  resetForm = false;
  isRedirect: boolean = false;
  gMapOptions: any;
  overlays: any[];
  map: google.maps.Map;
  public clientAddresses: any = [];
  showRecordsSideBar: boolean = false;
  editing: boolean = true;
  staffPermission = null;
  clientViewTriggerSubscription = new Subscription();

  public showCloneDialog: boolean = false;
  public cloneClientName;
  companyData: any = {};
  public defaultBillAdd: any = [];

  public selectedItems: any = [];
  checkboxDisabled: boolean = true;

  public isLoading: boolean = false;

  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
  };

  customTableClientObj = {}
  customTemplatesCompany = [];
  customTemplatesCompanyOptions = [];
  companyId = null;

  @ViewChild("dt") table: Table;
  canDeleteClient: boolean;
  allCounts: any;

  constructor(
    private router: Router, route: ActivatedRoute,
    public translate: TranslateService,
    private mangoAPISrvc: MangoApiService,
    public auth: AuthGuard,
    private confirmationSrvc: ConfirmationService,
    private encrDecSrvc: EncrDecrService,
    private fb: UntypedFormBuilder,
    private breadcrumbService: BreadcrumbService,
    private clientCmp: ClientComponent,
    public sharedSrvc: SharedComponentsService) {
    const userPermissionObj = this.encrDecSrvc.getObject(AppConstants.userPermissionObj);
    this.staffPermission = parseInt(userPermissionObj.Permission);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.sharedSrvc.engagementDialogData
      .subscribe((data) => {
        if (this.resetForm) {
          this.resetClientView(-1);
        }
        setTimeout(() => {
          this.mangoAPISrvc.fireClientBadgeView(true);
        }, 100);
      })
    //if(this.sharedSrvc.isTableView)
      this.initClient();
    this.clientViewTriggerSubscription = this.sharedSrvc.clientViewTrigger.subscribe(() => {
      this.initClient();
      this.mangoAPISrvc.fireClientBadgeView(true);
    })
    this.gMapOptions = {
      center: { lat: 36.879466, lng: 30.667648 },
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.sharedSrvc.leaveAndSaveSub = new Subject<any>();
    this.sharedSrvc.leaveAndSaveSub.subscribe(() => {
      this.submitClientForm(false, true)
    })
  }

  async getClientFullinformation(clientId) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .getClientFullinformation(clientId)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async initClient(){
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.initTranslations();
    this.initializeForm();
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID) || null;
    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.getClientGroupCategory();
    this.getClientType();
    this.getAllStaffList();
    if (this.clientID > 0) {
      this.editableClient = await this.getClientFullinformation(this.clientID);//this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
      this.updateclientForm();
      this.encrDecSrvc.addObject('selected-ClientEmail', this.editableClient['Email']);
      this.encrDecSrvc.addObject(AppConstants.ClientNameInvoice, this.editableClient['ClientNameInvoice']);
      this.isContactRecord = this.editableClient['ContactRecord'] == true ? true : false;
    }
    this.invoiceOpt = this.encrDecSrvc.getObject(AppConstants.invoiceOptions);
    this.getClientsStats();
    this.getContacts();
    this.getCustomTableCompany();
    this.getCanDeleteClients();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getCanDeleteClients() {
    this.mangoAPISrvc.getClientWithNoData(this.companyId)
    .subscribe((result: any)=>{
      this.canDeleteClient = result.clientRecords.filter((x) => { return x.ClientID == this.clientID})[0] ? true : false;
    });
  }

  getCustomTableCompany(){
    if(this.clientID == -1 || !this.clientID) return;
    this.mangoAPISrvc.getCustomTableTemplatesWithClient(this.companyId, this.clientID)
      .subscribe((result: any)=>{
        if(result && result.length > 0){
          this.customTemplatesCompany = result;
          this.getCustomTableClient(this.customTemplatesCompany[0]);
          this.customTemplatesCompanyOptions = this.customTemplatesCompany.map(item=>{
            return { label: item['FirmTemplateName'] || item['TemplateName'], value: item['CustomTablesCompanyID']}
          });
        }
      })
  }

  customTableCompanyTemplateChange(event){
    const clientCustomTable = this.customTemplatesCompany.find(item=>item['CustomTablesCompanyID'] === event.value);
    this.getCustomTableClient(clientCustomTable);
  }

  resetCustomTableClient(){
    if(!this.customTableClientObj || !this.customTableClientObj['CustomTablesClientID']) return;
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('reset_custom_table_client'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('No')
    }).then((result) => {
        if(result.value){
          this.customTableClientObj['Description'] = JSON.parse(JSON.stringify(this.customTableClientObj['FirmDescription']));
          // this.customTableClientObj['Value'] = this.customTableClientObj['Value'].splice(this.customTableClientObj['Description'].length)
          this.customTableClientObj['IsChanged'] = true;
          this.buildValueMatrix();
        }
    })
  }

  deleteRowCustomTableClient(rowData, index){
    if(!this.customTableClientObj || !this.customTableClientObj['CustomTablesClientID']) return;
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if(result.value){
        this.customTableClientObj['Description'][index]="";
        this.customTableClientObj['Value'][index] = "";
        this.customTableClientObj['IsChanged'] = true;
        rowData['Description'] = rowData['Value'] = "";
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
      }
    })
  }

  addRowCustomTableClient(){
    if(!this.customTableClientObj || !this.customTableClientObj['CustomTablesClientID']) return;
    this.customTableClientObj['Value'].push("")
    this.customTableClientObj['Description'].push("")
    if (this.customTableClientObj['Description'].length > 15) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant('Maximum descriptions length is reached. Unable to add a new entry.'),
        icon: 'warning',
        confirmButtonText: this.translate.instant('Ok'),
      });
      this.customTableClientObj['Value'].splice(15);
      this.customTableClientObj['Description'].splice(15);
    }
    this.customTableClientObj['IsChanged'] = true;
    this.buildValueMatrix();
  }

  updateCustomTablesClient(items) {
    const observableBatch = [];
    items.forEach((changedItem, key) => {
      changedItem['IsChanged'] = false;
      const obj = {}
      obj['OverRide'] = changedItem['OverRide'];
      obj['Description'] = `{${changedItem['Description'].map(item=>item == ""? "-": item).join(",")}}`
      obj['Value'] = `{${changedItem['Value'].map(item=>item == ""? "-": item).join(",")}}`
      if(changedItem['CustomTablesClientID'] === -1 ){
        obj['ClientID'] = changedItem['ClientID']
        obj['CompanyID'] = changedItem['CompanyID']
        obj['TemplateName'] = changedItem['TemplateName']
        obj['CustomTablesCompanyID'] = changedItem['CustomTablesCompanyID']
        observableBatch.push(this.mangoAPISrvc.postCustomTableClient(obj));
      } else {
        observableBatch.push(this.mangoAPISrvc.putCustomTableClient(changedItem['CustomTablesClientID'], obj));
      }
    });
    return forkJoin(observableBatch);
  }

  deleteCustomTableClient(){
    if(!this.customTableClientObj || !this.customTableClientObj['CustomTablesClientID']) return;
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('delete_alert_custom_table'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if(result.value){
        this.mangoAPISrvc.showLoader(true)
        this.mangoAPISrvc.deleteCustomTableClient(this.customTableClientObj['CustomTablesClientID'])
          .subscribe(result=>{
            this.mangoAPISrvc.showLoader(false)
            this.customTemplatesCompany = this.customTemplatesCompany.filter(item=> item['CustomTablesClientID'] != this.customTableClientObj['CustomTablesClientID']);
            this.customTemplatesCompanyOptions = this.customTemplatesCompany.map(item=>{
              return { label: item['FirmTemplateName'] || item['TemplateName'], value: item['CustomTablesCompanyID']}
            });
            this.getCustomTableClient(this.customTemplatesCompany[0]);
            this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
          })
      }
    })
  }

  openUserDefinedFields(){
    if(this.customTemplatesCompany.filter(item=>item['CustomTablesCompanyID'] == null ).length == 0){
      this.customTableClientObj = {
        ClientID: this.clientID,
        CompanyID: this.companyId,
        CustomTablesClientID: -1,
        OverRide: true,
        CustomTablesCompanyID: null,
        FirmDescription: ["","","","","","","",""],
        Description: ["","","","","","","",""],
        Value: ["","","","","","","",""],
        CustomTablesCompanyIDSelected: null,
        TemplateName: "Default"
      }
      this.customTemplatesCompany.splice(0, 0, this.customTableClientObj)
      this.customTemplatesCompanyOptions.splice(0, 0, { label: "Default", value: null });
      this.getCustomTableClient(this.customTableClientObj);
      // Swal.fire({
      //   icon: "warning",
      //   title: this.translate.instant('confirmation'),
      //   html: this.translate.instant('no_custom_table_warning'),
      //   showConfirmButton: false,
      //   timer: 4000,
      // });
      // return;
    }
    this.userDefinedFieldsEnabled = true;
  }

  closeUserDefinedFields(){
    this.userDefinedFieldsEnabled = false;
    const changedItems = this.customTemplatesCompany.filter(item=>item['IsChanged']);
    if(changedItems.length > 0){
      this.updateCustomTablesClient(changedItems).subscribe(data=>{
        const newTemplate = this.customTemplatesCompany.find(item=>item['CustomTablesClientID']==-1);
        const createdtemplate = data.find(item=>item['CustomTablesClientID']);
        if(newTemplate && createdtemplate){
          newTemplate['CustomTablesClientID'] = createdtemplate['CustomTablesClientID'];
        }
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
    }
  }

  changeMatrix(type, data){
    this.customTableClientObj[type][data['index']] = data[type];
    this.customTableClientObj['IsChanged'] = true
  }

  onRowEditInit1(index, dt, type?) {
    dt.editingRowKeys = {};
    if (dt) dt.editingRowKeys[index] = true;
    if(this.customTableClientObj['last_index'] == index)
      return;
    this.customTableClientObj['last_index'] = index;

    if(type) this.selectCell(`custom-table-${type}`, index);
    // else this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Unable to edit firm level template.');
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`
      colClass += xtraEl ? ` > ${xtraEl}` : ''
      $(colClass).select();
    }, 50);
  }

  buildValueMatrix(){
    const length = Math.max(this.customTableClientObj['FirmDescription'].length, this.customTableClientObj['Description'].length, this.customTableClientObj['Value'].length)
    this.customTableClientObj['ValueMatrix'] = [];
    for(let i = 0; i < length; i ++){
      const obj = {
        index: i,
        FirmDescription: this.customTableClientObj['FirmDescription'][i] || "",
        Description: this.customTableClientObj['Description'][i] || "",
        Value: this.customTableClientObj['Value'][i] || "",
      }
      this.customTableClientObj['ValueMatrix'].push(obj);
    }
  }

  getCustomTableClient(data){
    if(this.clientID == -1 || !this.clientID || !data) return;
    // this.customTableClientObj = JSON.parse(JSON.stringify(data));
    this.customTableClientObj = data
    this.customTableClientObj['CustomTablesCompanyIDSelected'] = data['CustomTablesCompanyID'];
    this.customTableClientObj['FirmDescription']  = data['CustomTablesCompanyID']? this.customTableClientObj['FirmDescription'].map(item=> item == "-"? "": item) : [];
    this.customTableClientObj['Value']  = this.customTableClientObj['Value'].map(item=> item == "-"? "": item)
    this.customTableClientObj['Description']  = this.customTableClientObj['Description'].map(item=> item == "-"? "": item);
    this.customTableClientObj['last_index']  = null;
    //merge table of desc and values
    this.buildValueMatrix();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client-list') },
      { label: this.translate.instant('view') },
      // { label: this.clientName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.mangoAPISrvc.showLoader(true);
    this.isLoading = true;
    const lang = this.translate.currentLang || localStorage.getItem('language') || 'en'; // Use 'en' as default

    this.translate.use(lang).subscribe(() => {
      this.translate.getTranslation(lang).subscribe(
        (translations: any) => {  
          // Access specific translation if necessary
          console.log('Specific translation for Serch-Clients:', translations['Search-Clients']);
          this.mangoAPISrvc.showLoader(false);
          this.isLoading = false;
        },
        (error: any) => {
          console.error('Error loading translations:', error);
          this.mangoAPISrvc.showLoader(false);
          this.isLoading = false;
        }
      );
    });
    // this.updateclientForm();
  }

  ngOnDestroy() {
    this.sharedSrvc.leaveAndSaveSub.unsubscribe();
    this.clientViewTriggerSubscription.unsubscribe();
  }

  getClientAddresses(ClientID) {
    this.mangoAPISrvc.getCustomerAddresses(ClientID).subscribe((data: any) => {
      data = data.sort((a, b) => a.CustomerAddressID - b.CustomerAddressID);
      for (let i = 0; i < data.length; i++) {
        data[i].IsRowEditing = false;
        data[i].TaskRow = i;
      }
      this.clientAddresses = data;

      this.defaultBillAdd = data.filter(itm => itm.isBillingAddress == true);
      if (this.defaultBillAdd.length > 0) {
        this.clientForm.controls['BusStreet1'].setValue(this.defaultBillAdd[0].Street1);
        this.clientForm.controls['BusStreet2'].setValue(this.defaultBillAdd[0].Street2);
        this.clientForm.controls['BusCity'].setValue(this.defaultBillAdd[0].City);
        this.clientForm.controls['BusState'].setValue(this.defaultBillAdd[0].State);
        this.clientForm.controls['BusZip'].setValue(this.defaultBillAdd[0].Zip);
      }

    });
  }

  showMap() {
    this.getLocation(
      `${this.clientForm.controls["BusStreet1"].value}, ${this.clientForm.controls["BusStreet2"].value}, ${this.clientForm.controls["BusCity"].value}, ${this.clientForm.controls["BusState"].value}, ${this.clientForm.controls["BusZip"].value}`
    );
    this.showGMap = true;
  }

  setMap(event) {
    this.map = event.map;
  }

  isValidLocation() {
    return this.clientForm.controls["BusStreet1"].value || this.clientForm.controls["BusStreet2"].value || this.clientForm.controls["BusCity"].value || this.clientForm.controls["BusState"].value || this.clientForm.controls["BusZip"].value;
  }

  getLocation(address: string) {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getLocation(address).subscribe((data: any) => {
      if (data.results) {
        const latitude = data.results[0]?.geometry?.location?.lat
        const longitude = data.results[0]?.geometry?.location?.lng
        this.map.setCenter({
          lat: Number(latitude),
          lng: Number(longitude)
        });

        this.overlays = [
          new google.maps.Marker({ position: { lat: latitude, lng: longitude }, title: this.clientForm.controls["ClientName"].value })
        ]
      }
      this.mangoAPISrvc.showLoader(false)
    }, err => this.mangoAPISrvc.showLoader(false))
  }

  onClone() {
    this.cloneClientName = this.clientForm.controls['ClientName'].value
    this.showCloneDialog = !this.showCloneDialog;
  }

  sendEmailToClient() {
    const formObj = this.clientForm.value;
    window.location.href = "mailto:" + formObj.Email;
  }

  initializeForm() {
    this.clientForm = this.fb.group({
      ClientNumber: [null, [Validators.maxLength(15)]],
      ClientID: [null],
      ClientTypeID: [null],
      ClientName: [null, [Validators.required, Validators.minLength(3), Validators.required, Validators.maxLength(80)]],
      ClientNameInvoice: [null],
      ClientPassword: [null],
      BusStreet1: [null],
      Office: [null],
      ContactRecord: [false],
      BusStreet2: [null],
      BusCity: [null],
      BusState: [null],
      BusZip: [null],
      BusCountry: [null],
      Fax: [null],
      Mobile: [null],
      Home: [null],
      Tele1: [null],
      Tele2: [null],
      Tele3: [null],
      Tele1Descr: [null],
      Tele2Descr: [null],
      isRegistered: [false],
      Email: [null, [Validators.email]],
      Tele3Descr: [null],
      Website: [null],
      ContactPerson: [null],
      ReferredBy: [null],
      Billable: [true],
      Inactive: [false],
      DateCreated: [null],
      DateInactive: [null],
      ScratchPad: [null],
      User1Descr: [null],
      User2Descr: [null],
      User3Descr: [null],
      User4Descr: [null],
      User5Descr: [null],
      User6Descr: [null],
      User7Descr: [null],
      User8Descr: [null],
      User1Data: [null],
      User2Data: [null],
      User3Data: [null],
      User4Data: [null],
      User5Data: [null],
      User6Data: [null],
      User7Data: [null],
      User8Data: [null],
      OriginatingPartnerID: [null],
      BillingPartnerID: [null],
      BillingGroupID: [null],
      StaffAssignedID: [null],
      CompanyID: [this.encrDecSrvc.getObject(AppConstants.companyID)],
      GroupDescriptionIDArray: [null],
      isClient: [true],
      ClientDOB: [null],
      IncorporationDate: [null],
      isEmailChanged: [null]
    })

    this.clientForm.statusChanges.subscribe(x => {
      this.validateForm()
    })
  }

  validateForm(newRecord?) {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.clientForm.controls).forEach((key) => {
      if (this.clientForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.clientForm.get(key).dirty) {
        istouchedData = true;
      }
    });

    if ((!isInValidData && istouchedData) || this.isPasswordReset) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }

    if (!newRecord && !this.mangoAPISrvc.isFormChanged && this.isFormValid) {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, istouchedData);
    }

    if (!this.isFormValid) {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    }

    this.ValidEmail = (this.editableClient['ClientID'] > 0 && this.clientForm.get('Email').valid && this.clientForm.get('Email').value) ? true : false;
  }

  getClientType() {
    const klassObj = this;
    klassObj.clientType = [];
    const tempList = this.encrDecSrvc.getObject(AppConstants.customerType);
    for (let i = 0; i < tempList.length; i++) {
      if (!tempList[i].Inactive) {
        klassObj.clientType.push({ label: tempList[i].CustomerTypeDescription, value: tempList[i].CustomerTypeID });
      }
    }
    klassObj.clientType = [{ label: "Unassigned", value: null }, ...klassObj.clientType]
  }

  onClickResetPw() {
    this.confirmationSrvc.confirm({
      message: "Do you want to reset the password to empty?",
      header: "Reset Password",
      accept: () => {
        this.isPasswordReset = true;
        this.clientForm.controls['ClientPassword'].setValue("reset");
        this.clientForm.controls['isRegistered'].setValue(false);
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
      }
    });
  }

  getClientGroupCategory() {
    this.clientGroupCategory = [];
    this.billingGroupCategory = [];
    const tempList = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive != true) this.clientGroupCategory.push({ label: tempList[i].GroupDescription, value: tempList[i].CustomerGroupCategoryID })
      if (tempList[i].Inactive != true) this.billingGroupCategory.push({ label: tempList[i].GroupDescription, value: tempList[i].CustomerGroupCategoryID })
    }
    this.billingGroupCategory.unshift({ label: "Unassigned", value: null })

  }

  getAllStaffList() {
    const klassObj = this;
    klassObj.staffList = [];
    klassObj.staffListWithInactive = [];
    const tempList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    for (let i = 0; i < tempList.length; i++) {
      klassObj.staffListWithInactive.push({ label: tempList[i].StaffName, value: tempList[i].StaffID })
      if (!tempList[i].Inactive) {
        klassObj.staffList.push({ label: tempList[i].StaffName, value: tempList[i].StaffID })
      }
    }
    klassObj.staffListWithInactive = [{ label: "Unassigned", value: null }, ...klassObj.staffListWithInactive]
    klassObj.staffList = [{ label: "Unassigned", value: null }, ...klassObj.staffList]
  }
  getClientsStats() {
    const _this = this;
    if (!_this.clientID || _this.clientID == -1) {
      return false;
    }
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.loadDefaultClientViewAPI(_this.clientID).subscribe((data: any) => {
      _this.stat_openBalance = data[0][0]['balance'];
      _this.stat_paymentsCy = data[1][0]['cytdpay'];
      _this.stat_paymentsPy = data[1][0]['pytdpay'];
      _this.stat_revenueCy = data[2][0]['cytdrev'];
      _this.stat_revenuePy = data[2][0]['pytdrev'];
      _this.stat_unbilledTime = data[3][0]['wip'];
      _this.stat_unbilledExp = data[3][0]['billableexpenses'];
      _this.stat_bal_over30 = data[4][0]['over30balance'];
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  updateclientForm() {
    if (this.editableClient && Object.keys(this.editableClient).length > 0) {
      this.clientForm.controls['ClientName'].setValue(this.editableClient['ClientName']);
      this.clientForm.controls['ClientID'].setValue(this.editableClient['ClientID']);
      this.clientForm.controls['ClientNameInvoice'].setValue(this.editableClient['ClientNameInvoice']);
      this.clientForm.controls['ClientTypeID'].setValue(this.editableClient['ClientTypeID']);
      /*     this.clientForm.controls['BusStreet1'].setValue(this.editableClient['BusStreet1']);
          this.clientForm.controls['BusStreet2'].setValue(this.editableClient['BusStreet2']);
          this.clientForm.controls['BusCity'].setValue(this.editableClient['BusCity']);
          this.clientForm.controls['BusState'].setValue(this.editableClient['BusState']);
          this.clientForm.controls['BusZip'].setValue(this.editableClient['BusZip']); */
      this.clientForm.controls['ClientNumber'].setValue(this.editableClient['ClientNumber']);
      this.clientForm.controls['Office'].setValue(this.editableClient['Office']);
      this.clientForm.controls['ContactPerson'].setValue(this.editableClient['ContactPerson']);
      this.clientForm.controls['Fax'].setValue(this.editableClient['Fax']);
      this.clientForm.controls['Mobile'].setValue(this.editableClient['Mobile']);
      this.clientForm.controls['Home'].setValue(this.editableClient['Home']);
      this.clientForm.controls['Email'].setValue(this.editableClient['Email']);
      this.clientForm.controls['Tele1Descr'].setValue(this.editableClient['Tele1Descr']);
      this.clientForm.controls['Tele2Descr'].setValue(this.editableClient['Tele2Descr']);
      this.clientForm.controls['Tele3Descr'].setValue(this.editableClient['Tele3Descr']);
      this.clientForm.controls['Tele1'].setValue(this.editableClient['Tele1']);
      this.clientForm.controls['Tele2'].setValue(this.editableClient['Tele2']);
      this.clientForm.controls['Tele3'].setValue(this.editableClient['Tele3']);
      this.clientForm.controls['ScratchPad'].setValue(this.editableClient['ScratchPad']);
      this.clientForm.controls['User1Descr'].setValue(this.editableClient['User1Descr']);
      this.clientForm.controls['User1Data'].setValue(this.editableClient['User1Data']);
      this.clientForm.controls['User2Descr'].setValue(this.editableClient['User2Descr']);
      this.clientForm.controls['User2Data'].setValue(this.editableClient['User2Data']);
      this.clientForm.controls['User3Descr'].setValue(this.editableClient['User3Descr']);
      this.clientForm.controls['User3Data'].setValue(this.editableClient['User3Data']);
      this.clientForm.controls['User4Descr'].setValue(this.editableClient['User4Descr']);
      this.clientForm.controls['User4Data'].setValue(this.editableClient['User4Data']);
      this.clientForm.controls['User5Descr'].setValue(this.editableClient['User5Descr']);
      this.clientForm.controls['User5Data'].setValue(this.editableClient['User5Data']);
      this.clientForm.controls['User6Descr'].setValue(this.editableClient['User6Descr']);
      this.clientForm.controls['User6Data'].setValue(this.editableClient['User6Data']);
      this.clientForm.controls['User7Descr'].setValue(this.editableClient['User7Descr']);
      this.clientForm.controls['User7Data'].setValue(this.editableClient['User7Data']);
      this.clientForm.controls['User8Descr'].setValue(this.editableClient['User8Descr']);
      this.clientForm.controls['User8Data'].setValue(this.editableClient['User8Data']);
      this.clientForm.controls['Website'].setValue(this.editableClient['Website']);
      this.clientForm.controls['ReferredBy'].setValue(this.editableClient['ReferredBy']);
      const dateCr = this.editableClient['DateCreated'] ? new Date(this.editableClient['DateCreated']) : null;
      const dateInactiveCr = this.editableClient['DateInactive'] ? new Date(this.editableClient['DateInactive']) : null;
      this.clientForm.controls['DateCreated'].setValue(dateCr);
      this.clientForm.controls['DateInactive'].setValue(dateInactiveCr);
      this.clientForm.controls['BillingPartnerID'].setValue(this.editableClient['BillingPartnerID']);
      this.clientForm.controls['BillingGroupID'].setValue(this.editableClient['BillingGroupID']);
      this.clientForm.controls['StaffAssignedID'].setValue(this.editableClient['StaffAssignedID']);
      this.clientForm.controls['OriginatingPartnerID'].setValue(this.editableClient['OriginatingPartnerID']);
      this.clientForm.controls['GroupDescriptionIDArray'].setValue(this.editableClient['GroupDescriptionIDArray']);
      this.clientForm.controls['isClient'].setValue(this.editableClient['isClient']);
      this.clientForm.controls['ClientDOB'].setValue(this.editableClient['ClientDOB']);
      this.clientForm.controls['IncorporationDate'].setValue(this.editableClient['IncorporationDate']);
      this.clientForm.controls['ContactRecord'].setValue(this.editableClient['ContactRecord']);
      this.clientForm.controls['Inactive'].setValue(this.editableClient['Inactive']);
      this.clientForm.controls['Billable'].setValue(this.editableClient['Billable']);
      this.clientForm.controls['isEmailChanged'].setValue(this.editableClient['isEmailChanged']);
      this.editorValue = this.editableClient['ScratchPad'] ? this.editableClient['ScratchPad'] : '';
      this.editorInitialValue = this.editableClient['ScratchPad'] ? this.editableClient['ScratchPad'] : '';
      this.clientForm.controls['isRegistered'].setValue(this.editableClient['isRegistered']);
      this.getClientAddresses(this.editableClient['ClientID']);
    } else {
      this.clientForm.controls['Billable'].setValue(true);
    }
    const selectedClientTabIndex = this.encrDecSrvc.getObject(AppConstants.selectedClientTabIndex);
    if (this.clientID == -1 && selectedClientTabIndex == 2) {
      this.clientForm.controls['ContactRecord'].setValue(true);
      this.changeClientActiveState();
    }
    if (this.clientID == -1) {
      this.clientForm.controls['DateCreated'].setValue(new Date());
    }
  }

  saveClient() {
    const self = this;
    // self.mangoAPISrvc.showLoader(true);
    const formObj = self.clientForm.value;
    formObj.GroupDescriptionIDArray = (formObj.GroupDescriptionIDArray && formObj.GroupDescriptionIDArray.length > 0) ? formObj.GroupDescriptionIDArray : null;
    formObj.DateInactive = formObj.DateInactive ? formObj.DateInactive : null;
    formObj.ClientDOB = formObj.ClientDOB ? formObj.ClientDOB : null;
    formObj.IncorporationDate = formObj.IncorporationDate ? formObj.IncorporationDate : null;
    formObj.Inactive = formObj.Inactive ? formObj.Inactive : false;
    formObj['ContactRecord'] = formObj['ContactRecord'] ? formObj['ContactRecord'] : false;
    formObj.ScratchPad = self.editorValue ? self.editorValue : null;
    // formObj.Mobile.toString().replace(/[{(___)-}]/g, '').trim();
    // formObj.Home.toString().replace(/[{(___)-}]/g, '').trim();

    if (self.isPasswordReset)
      formObj.ClientPassword = "reset";

    if (formObj.Inactive == true) {
      formObj.DateInactive = new Date();
    } else if (formObj.Inactive == false) {
      formObj.DateInactive = null;
    }
    formObj['isEmailChanged'] = false;
    formObj['ClientID'] = this.clientID;

    if (self.editableClient.Email && formObj.Email && formObj.Email !== self.editableClient.Email) {
      Swal.fire({
        title: self.translate.instant('confirmation'),
        html: self.translate.instant('default_email_changed_warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: self.translate.instant('yes_continue'),
        cancelButtonText: self.translate.instant('no_cancel')
      }).then((result) => {
        self.mangoAPISrvc.showLoader(true);
        if (result.value) {
          formObj['isEmailChanged'] = true;
          self.updateClientRecord(formObj);
        } else {
          formObj.Email = self.editableClient.Email;
          self.clientForm.controls['Email'].setValue(self.editableClient.Email);
          self.updateClientRecord(formObj);
        }
      });
    } else {
      self.updateClientRecord(formObj);
    }

  }

  updateClientRecord(formObj) {
    const _this = this;
    formObj['IShareCompanyID'] = _this.companyData.CompanyIDImagineShare;
    formObj['vendorAPIKeyIS'] = _this.companyData.vendorAPIKeyIS;
    _this.mangoAPISrvc.updateClient(formObj).subscribe(function (data) {

      const userLogs = {}
      userLogs['Action'] = "Update Customer";
      userLogs['Description'] = data.data[0]['ClientName'] + " ##" + data.data[0]['ClientID'] + ` --${this.clientName}`;
      userLogs['Table'] = "Customers";

      const isManaging = _this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
      if(!isManaging){
        _this.mangoAPISrvc.addUserLogs(userLogs).subscribe(()=>{},(err)=>{
          console.log(err);
        });
      }


      const defaultClient = {
        CustomerAddressID: _this.defaultBillAdd.length > 0 ? _this.defaultBillAdd[0].CustomerAddressID : null,
        ClientID: _this.clientID,
        Street1: formObj["BusStreet1"],
        Street2: formObj["BusStreet2"],
        City: formObj["BusCity"],
        State: formObj["BusState"],
        Zip: formObj["BusZip"],
        LabelDescription: _this.defaultBillAdd.length > 0 ? _this.defaultBillAdd[0].LabelDescription : "",
        isBillingAddress: true
      }
      _this.updateClientAddress(defaultClient);
      _this.mangoAPISrvc.showLoader(false);
      _this.encrDecSrvc.addObject(AppConstants.ClientNameInvoice, data.data[0]['ClientNameInvoice']);
      _this.encrDecSrvc.addObject(AppConstants.ClientName, data.data[0]['ClientName']);
      _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, data.data[0]);
      _this.encrDecSrvc.addObject(AppConstants.clientUpdated, true);
      _this.sharedSrvc.selClient = data.data[0]
      if (formObj['Email']) {
        _this.saveContact(formObj['ClientID'], formObj, false);
      }
      _this.clientForm.markAsPristine();
      _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), data.message);
      _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      _this.refreshCache();
      // if (!isResetNew) {
      _this.isFormValid = false;
      //   // localStorage.setItem('alldataCounts', JSON.stringify(_this.allCounts));
      //   // _this.editableClient = {};
      //   // _this.router.navigate([`/clients/new`]);
      // }
    }, (error) => {
      _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), _this.translate.instant('error_while_saving_client'));
    });
  }

  saveContact(clientID, formObj, isNew?) {
    const _this = this;
    const contactName = formObj.ContactPerson != null ? formObj.ContactPerson : formObj.Email;
    if (clientID == null || contactName == null || contactName == "") return;
    const existingContactsWithSameEmail = _this.contacts.filter(contact => contact['Email'] == formObj.Email)
    if (isNew || existingContactsWithSameEmail.length == 0) {
      const contactObj = {
        "ClientID": clientID,
        "ContactName": contactName,
        "Street1": formObj.BusStreet1,
        "Street2": formObj.BusStreet2,
        "City": formObj.BusCity,
        "State": formObj.BusState,
        "Zip": formObj.BusZip,
        "Country": formObj.BusCountry,
        "Office": formObj.Office,
        "Fax": formObj.Fax,
        "Mobile": formObj.Mobile,
        "Home": formObj.Home,
        "Email": formObj.Email,
        "Company": formObj.ClientName,
        "ContactDescription": null,
        "Inactive": false,
        "CustomerContactTypeID": "-1",
        "isEmailInvoices": true,
        "isEmailStatements": true,
        "isClientPortalAccess": true,
        "isDefaultContact": true,
        "isEmailPaymentNotification": true,
        "isFirmDefaultPayments": false
      };
      _this.mangoAPISrvc.addContact(clientID, contactObj).subscribe(function (data) {
        _this.mangoAPISrvc.notify('success', 'Success!', 'Successfully Created Contact.');
        setTimeout(() => {
          _this.mangoAPISrvc.fireClientBadgeView(true);
        }, 100);
      }, error => {
        _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });
    } else {
      const contact = existingContactsWithSameEmail[0];
      const contactObj = {
        "CustomerContactID": contact['CustomerContactID'],
        "ClientID": clientID,
        "ContactName": contactName,
        "Street1": formObj.BusStreet1,
        "Street2": formObj.BusStreet2,
        "City": formObj.BusCity,
        "State": formObj.BusState,
        "Zip": formObj.BusZip,
        "Country": formObj.BusCountry,
        "Office": formObj.Office,
        "Fax": formObj.Fax,
        "Mobile": formObj.Mobile,
        "Home": formObj.Home,
        "Email": formObj.Email,
        "Company": formObj.ClientName,
      };
      contact['ContactName'] = contactName;
      _this.mangoAPISrvc.updateContact(contact['CustomerContactID'], contactObj)
        .subscribe(function (data) {
          _this.mangoAPISrvc.notify('success', 'Success!', 'Successfully Updated Contact.');
          setTimeout(() => {
            _this.mangoAPISrvc.fireClientBadgeView(true);
          }, 100);
        }, error => {
          _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          _this.mangoAPISrvc.showLoader(false);
        });
    }
  }

  createClient() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    const formObj = _this.clientForm.value;
    formObj.GroupDescriptionIDArray = (formObj.GroupDescriptionIDArray && formObj.GroupDescriptionIDArray.length > 0) ? formObj.GroupDescriptionIDArray : null;
    formObj['FinalizeAction'] = _this.invoiceOpt.FinalizeAction;
    formObj['TermsID'] = _this.invoiceOpt.TermsID;
    formObj['UseDefaultTerms'] = true;
    formObj['GraceDays'] = _this.invoiceOpt.GraceDays;
    formObj['InterestRate'] = _this.invoiceOpt.InterestRate;
    formObj['DefaultInvoiceTemplate'] = _this.invoiceOpt.GlobalInvoiceTemplate;
    formObj['Billable'] = formObj['Billable'] ? formObj['Billable'] : true;
    formObj['Inactive'] = formObj['Inactive'] ? formObj['Inactive'] : false;
    formObj['ContactRecord'] = formObj['ContactRecord'] ? formObj['ContactRecord'] : false;
    formObj.ScratchPad = _this.editorValue ? _this.editorValue : null;
    formObj['IShareCompanyID'] = _this.companyData.CompanyIDImagineShare;
    formObj['vendorAPIKeyIS'] = _this.companyData.vendorAPIKeyIS;
    // formObj.Mobile.toString().replace(/[{(___)-}]/g, '').trim();
    // formObj.Home.toString().replace(/[{(___)-}]/g, '').trim();

    delete formObj.ClientID
    _this.mangoAPISrvc.addClient(formObj).subscribe(function (data: any) {
      const userLogs = {}
      userLogs['Action'] = "Add Customer";
      userLogs['Description'] = data.data.ClientName + " ##" + data.data.ClientID + ` --${this.clientName}`;
      userLogs['Table'] = "Customers";
      const isManaging = _this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
      if(!isManaging){
        _this.mangoAPISrvc.addUserLogs(userLogs).subscribe(()=>{},(err)=>{
          console.log(err);
        });
      }

      _this.saveContact(data.data.ClientID, formObj, true);
      const defaultClientAddress = {
        CustomerAddressID: null,
        ClientID: data.data.ClientID,
        Street1: formObj["BusStreet1"],
        Street2: formObj["BusStreet2"],
        City: formObj["BusCity"],
        State: formObj["BusState"],
        Zip: formObj["BusZip"],
        LabelDescription: _this.defaultBillAdd.length > 0 ? _this.defaultBillAdd[0].LabelDescription : "",
        isBillingAddress: true
      }
      _this.updateClientAddress(defaultClientAddress);
      _this.mangoAPISrvc.showLoader(false);
      _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), data.message);
      _this.clientID = data.data.ClientID;
      _this.refreshCache();
      _this.encrDecSrvc.clientList.push(data.data);

      if(!formObj['ContactRecord']){
        setTimeout(() => {
          Swal.fire({
            title: _this.translate.instant('confirmation'),
            html: _this.translate.instant('engagements_required_warning'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: _this.translate.instant('yes_continue'),
            cancelButtonText: _this.translate.instant('no_cancel')
          }).then((result) => {
            _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            if (result.isConfirmed) {
              // this.clientService.openDialogDirectly = true;
              _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
              _this.addEngagementPopUp(data.data);
            } else {
              const form = data.data;
              _this.sharedSrvc.engagementDialogData.next(true);
              _this.encrDecSrvc.addObject(AppConstants.clientID, form.ClientID);
              _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, form);
              _this.editableClient = form;
              _this.encrDecSrvc.addObject(AppConstants.ClientName, form.ClientName);
              _this.clientForm.controls['ClientID'].setValue(form.ClientID, { emitEvent: false });
            }
            setTimeout(() => {
              _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
              _this.clientForm.markAsPristine();
              _this.isFormValid = false;
              _this.mangoAPISrvc.fireClientView(true);
              _this.mangoAPISrvc.fireClientBadgeView(true);
            }, 500);
          });
        }, 1400);
      }
      else{
        const form = data.data;
        _this.sharedSrvc.engagementDialogData.next(true);
        _this.encrDecSrvc.addObject(AppConstants.clientID, form.ClientID);
        _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, form);
        _this.editableClient = form;
        _this.encrDecSrvc.addObject(AppConstants.ClientName, form.ClientName);
        _this.clientForm.controls['ClientID'].setValue(form.ClientID, { emitEvent: false });
        setTimeout(() => {
          _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          _this.clientForm.markAsPristine();
          _this.isFormValid = false;
          _this.mangoAPISrvc.fireClientView(true);
          _this.mangoAPISrvc.fireClientBadgeView(true);
        }, 500);
      }

    }, (error) => {
      this.mangoAPISrvc.notify('error', _this.translate.instant('error'), _this.translate.instant('error_while_saving_client'));
    });
  }

  cloneClient() {
    const _this = this;
    // _this.mangoAPISrvc.showLoader(true);
    const formObj = _this.clientForm.value;
    formObj.GroupDescriptionIDArray = (formObj.GroupDescriptionIDArray && formObj.GroupDescriptionIDArray.length > 0) ? formObj.GroupDescriptionIDArray : null;
    formObj['FinalizeAction'] = _this.invoiceOpt.FinalizeAction;
    formObj['TermsID'] = _this.invoiceOpt.TermsID;
    formObj['UseDefaultTerms'] = true;
    formObj['GraceDays'] = _this.invoiceOpt.GraceDays;
    formObj['InterestRate'] = _this.invoiceOpt.InterestRate;
    formObj['DefaultInvoiceTemplate'] = _this.invoiceOpt.GlobalInvoiceTemplate;
    formObj['Billable'] = formObj['Billable'] ? formObj['Billable'] : true;
    formObj['Inactive'] = formObj['Inactive'] ? formObj['Inactive'] : false;
    formObj['ContactRecord'] = formObj['ContactRecord'] ? formObj['ContactRecord'] : false;
    formObj.ScratchPad = _this.editorValue ? _this.editorValue : null;
    formObj['IShareCompanyID'] = _this.companyData.CompanyIDImagineShare;
    formObj['vendorAPIKeyIS'] = _this.companyData.vendorAPIKeyIS;
    delete formObj.ClientID
    formObj['ClientName'] = this.cloneClientName;
    _this.mangoAPISrvc.addClient(formObj).subscribe(function (data: any) {
      _this.mangoAPISrvc.showLoader(false);
      _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), data.message);
      _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      _this.refreshCache()
      if(data?.data) {
        _this.encrDecSrvc.clientList = [..._this.encrDecSrvc.clientList, data.data]
      }
        
      setTimeout(() => {
        Swal.fire({
          title: _this.translate.instant('confirmation'),
          html: "Do you want to clone customer contacts?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: _this.translate.instant('yes_continue'),
          cancelButtonText: _this.translate.instant('no_cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            _this.cloneContacts(data.data.ClientID);
          }
          if (_this.clientAddresses.length > 0) _this.promptCloneContacts(data.data.ClientID);
        });
      }, 1400);
    }, (error) => {
      this.mangoAPISrvc.notify('error', _this.translate.instant('error'), _this.translate.instant('error_while_saving_client'));
    });
  }

  promptCloneContacts(ClientId) {
    const _this = this;
    setTimeout(() => {
      Swal.fire({
        title: _this.translate.instant('confirmation'),
        html: "Do you want to clone customer addresses?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: _this.translate.instant('yes_continue'),
        cancelButtonText: _this.translate.instant('no_cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          _this.cloneAddress(ClientId);
        }
      });
    }, 1400);
  }

  cloneContacts(clientId) {
    const _this = this;
    if (!clientId) return;
    _this.mangoAPISrvc.showLoader(true);
    this.contacts.map(function (obj) {
      delete obj.CustomerContactID
      delete obj.ClientID
      obj.ClientID = clientId;
      _this.mangoAPISrvc.addContact(clientId, obj).subscribe(function (data) {
        //_this.getAllCounts(_this.selectedClientID);
        //_this.notificationsService.notify('success', data.message);
      }, error => {
        _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      });
    })
    _this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
    _this.mangoAPISrvc.showLoader(false);
  }

  cloneAddress(clientId) {
    const _this = this;
    if (!clientId) return;
    _this.mangoAPISrvc.showLoader(true);
    _this.clientAddresses.map(itm => {
      delete itm.CustomerAddressID;
      delete itm.ClientID;
      itm.ClientID = clientId;
      this.updateClientAddress(itm);
    }, err => {
      _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
    _this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
    _this.mangoAPISrvc.showLoader(false);
  }

  getContacts() {
    const _this = this;
    if (!_this.clientID) return;
    _this.mangoAPISrvc.getContactsByClientId(_this.clientID).subscribe(function (data: any) {
      _this.contacts = data.map(function (obj) {
        obj['Company'] = obj['Company'] ? obj['Company'] : "";
        obj['ContactDescription'] = obj['ContactDescription'] ? obj['ContactDescription'] : "";
        obj['ContactName'] = obj['ContactName'] ? obj['ContactName'] : "";
        obj['ContactType'] = obj['ContactType'] ? obj['ContactType'] : "";
        obj['Email'] = obj['Email'] ? obj['Email'] : "";
        obj['Fax'] = obj['Fax'] ? obj['Fax'] : "";
        obj['Home'] = obj['Home'] ? obj['Home'] : "";
        obj['Mobile'] = obj['Mobile'] ? obj['Mobile'] : "";
        return obj;
      });
    }, error => {
      _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    });
  }

  addEngagementPopUp(formObj) {
    const _this = this;

    if(!_this.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: _this.translate.instant('client.engagement.permission')
      });
      return;
    }

    _this.encrDecSrvc.addObject(AppConstants.clientID, formObj.ClientID);
    _this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, formObj);
    this.encrDecSrvc.addObject(AppConstants.ClientName, formObj.ClientName);
    const obj = {};
    obj['isClientFlow'] = true;
    obj['ClientID'] = formObj.ClientID;
    obj['ClientName'] = formObj.ClientName;
    obj['isClientFlow'] = true;
    obj['isBilling'] = true;
    _this.editableClient['isClientFlow'] = true;
    _this.sharedSrvc.openEngagementDialog(obj);

    if (!this.isRedirect)
      return;

    this.isRedirect = false;
    _this.router.navigate([`/client/engagements`]);
  }

  getAllCounts(clientId) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getAllDataCounts(clientId).subscribe(function (data) {
      parent.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
      parent.mangoAPISrvc.fireClientView(true);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  submitClientForm(reset, isRedirect) {
    this.resetForm = reset;
    this.isRedirect = isRedirect;
    if (this.clientID && this.clientID != -1) {
      this.saveClient();
    } else {
      this.createClient()
    }
  }

  deleteClient() {
    if (this.canDeleteClient) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: `<div>${this.mangoAPISrvc.translate.instant('assignment.deletion-reminder')}</div>`,
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc.deleteClientsWithNoData([this.clientID]).subscribe(result => {
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
            //redirect to client list & remove in cache
            this.refreshCache(true);
            this.encrDecSrvc.clientList = this.encrDecSrvc.clientList.filter((x) => x.ClientID != this.clientID);

            this.mangoAPISrvc.showLoader(false);
          }, err => {
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
            this.mangoAPISrvc.showLoader(false);
          })
        }
      })
    }
    else {
      //mark as inactive instead
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('client_delete_has_open_records_warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_continue'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then((result) => {
        if (result.value) {
          this.clientForm.controls['Inactive'].setValue(true);
          this.saveClient();
          this.refreshCache();
        } 
      });
    }
  }

  resetClientView(id) {
    if (id == -1) {
      this.breadcrumbService.setItems([
        { label: this.translate.instant('client-list') },
        { label: this.translate.instant('view') }
      ]);
    }
    this.clientID = id;
    this.encrDecSrvc.addObject(AppConstants.clientID, id);
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, "");
    this.clientForm.reset();
    if (id == -1) {
      this.clientForm.controls['DateCreated'].setValue(new Date());
      this.clientForm.controls['Billable'].setValue(true);
    }
    this.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath]);
    setTimeout(() => {
      this.mangoAPISrvc.fireClientView(true);
    }, 100);
  }

  changeClientActiveState() {
    const newInactive = this.clientForm.get('ContactRecord').value ? false : this.clientForm.get('Inactive').value;
    const newBillable = this.clientForm.get('ContactRecord').value ? false : this.clientForm.get('Billable').value;

    this.clientForm.controls['Inactive'].setValue(newInactive);
    this.clientForm.controls['Billable'].setValue(newBillable);
  }

  clientInactiveClick() {
    const allCounts = this.encrDecSrvc.getObject(AppConstants.allDataCountsForClient);
    const isInactive = this.clientForm.get('Inactive').value;

    if (isInactive && allCounts.timeslips > 0) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('client_has_open_records_warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_continue'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then((result) => {
        if (!result.value) {
          this.clientForm.controls['Inactive'].setValue(false);
        }
      });
    }
    else {
      if (isInactive) {
        this.clientForm.controls['DateInactive'].setValue(new Date());
      } else {
        this.clientForm.controls['DateInactive'].setValue(null);
      }
    }
  }

  refreshCache(isReset?: boolean) {
    const parent = this;
    // parent.encrDecSrvc.clientList = [];
    parent.encrDecSrvc.loadDefaultAPI();
    
    parent.encrDecSrvc.removeObject(AppConstants.clientCount);
    parent.mangoAPISrvc.fetchClientsCount().subscribe(function (data) {
      parent.mangoAPISrvc.showLoader(false);
      parent.encrDecSrvc.addObject(AppConstants.clientCount, data);
      parent.clientCmp.fetchClients();

      if (isReset) {//delete client
        parent.router.navigate([`/client/list`]);
        parent.sharedSrvc.selClient = null;
      }
    })
  }

  /*
  copy the general desc data to clipboard\
*/
  copyClipBoard(inputElement) {
    const copyValue = this.clientForm.controls["Email"].value;
    if (copyValue) {
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.mangoAPISrvc.notify(
        "success",
        this.translate.instant("Success"),
        "Copied to Clipboard"
      );
    } else {
      document.execCommand("Unselect");
    }
  }

  onAddressClick() {
    this.showRecordsSideBar = true;
  }

  onRowEditInit(rowData) {
    this.removeRowEditMode();
    for (let i = 0; i < this.clientAddresses.length; i++) {
      this.clientAddresses[i].IsRowEditing = false;
    }

    rowData['IsRowEditing'] = true;
    this.table.editingRowKeys[rowData.TaskRow] = true;
  }

  removeRowEditMode() {
    this.table.editingRowKeys = {};
  }

  addRow(desc?: any, dueDate?: any, dt?: any) {
    const lineObj = {};
    lineObj["City"] = ""
    lineObj["ClientID"] = this.clientID;
    lineObj["CustomerAddressID"] = null;
    lineObj["IsRowEditing"] = false;
    lineObj["LabelDescription"] = "";
    lineObj["State"] = "";
    lineObj["Street1"] = "";
    lineObj["Street2"] = "";
    lineObj["TaskRow"] = this.clientAddresses.length;
    lineObj["Zip"] = "";
    lineObj["isBillingAddress"] = false;

    //this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.clientAddresses.push(lineObj);
    //this.table.editingRowKeys[this.clientAddresses.length - 1] = true;
    if (dt) {
      dt.editingRowKeys = {};
      dt.editingRowKeys[this.clientAddresses.length - 1] = true;
    }
    else
      this.table.editingRowKeys[this.clientAddresses.length - 1] = true;
  }

  updateClientAddress(data) {
    this.mangoAPISrvc.showLoader(true);
    this.isLoading = true;
    if (!data.CustomerAddressID) {
      if (this.defaultBillAdd.length > 0 && data.isBillingAddress && this.defaultBillAdd[0].CustomerAddressID != data.CustomerAddressID) {
        this.defaultBillAdd[0].isBillingAddress = false;
        this.mangoAPISrvc.updateCustomerAddresses(this.defaultBillAdd[0].CustomerAddressID, this.defaultBillAdd[0]).subscribe(result => {
          this.getClientAddresses(this.clientID);
          this.isLoading = false;
          this.mangoAPISrvc.showLoader(false);

        }, err => {
          this.mangoAPISrvc.showLoader(false);
          this.isLoading = false;
        });
      }
      const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
      data = { ...data, CompanyID: companyId }
      this.mangoAPISrvc.addCustomerAddresses(data).subscribe(result => {
        data['IsRowEditing'] = false;
        this.getClientAddresses(this.clientID);
        this.isLoading = false;
        this.mangoAPISrvc.showLoader(false);
      }, err => {
        this.mangoAPISrvc.showLoader(false);
        this.isLoading = false;
      });
    }
    else {
      this.mangoAPISrvc.updateCustomerAddresses(data.CustomerAddressID, data).subscribe(result => {
        this.getClientAddresses(this.clientID);
        this.isLoading = false;
        this.mangoAPISrvc.showLoader(false);
      }, err => {
        this.mangoAPISrvc.showLoader(false);
        this.isLoading = false;
      });
      if (this.defaultBillAdd.length > 0 && data.isBillingAddress && this.defaultBillAdd[0].CustomerAddressID != data.CustomerAddressID) {
        if (data.isBillingAddress) this.defaultBillAdd[0].isBillingAddress = false; //set the new default address;
        this.mangoAPISrvc.updateCustomerAddresses(this.defaultBillAdd[0].CustomerAddressID, this.defaultBillAdd[0]).subscribe(result => {
          this.getClientAddresses(this.clientID);
          this.isLoading = false;
          this.mangoAPISrvc.showLoader(false);
        }, err => this.mangoAPISrvc.showLoader(false));
      }

    }

  }

  onRowEditSave(event: any, data: any) {
    this.mangoAPISrvc.showLoader(true);
    this.updateClientAddress(data);
    setTimeout(() => {
      const formObj = this.clientForm.value;
      delete formObj["GroupDescriptionIDArray"];
      this.mangoAPISrvc.updateClient(formObj).subscribe(res=>{
        this.mangoAPISrvc.showLoader(false);
      });
    }, 2500);
    this.table.editingRowKeys = {};
  }

  onChecked(event: any, rowData: any, rowIndex: number) {
    if (event.checked != undefined) {
      for (let i = 0; i < this.clientAddresses.length; i++) {
        this.clientAddresses[i].isBillingAddress = false;
        this.clientAddresses[i].IsRowEditing = false;
      }

      rowData['isBillingAddress'] = event.checked;
      rowData['IsRowEditing'] = true;

      if (rowData.CustomerAddressID) this.onRowEditSave(null, rowData) //this.updateClientAddress(rowData);

      //this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    }
  }

  deleteRow(event: any, rowData: any) {
    const self = this;
    event.stopPropagation();
    if (this.defaultBillAdd.length > 0 && this.defaultBillAdd[0].CustomerAddressID === rowData.CustomerAddressID) {
      Swal.fire({
        icon: "warning",
        title: this.translate.instant("Warning"),
        text:
          "Deleting the default Billing Address is not allowed.",
        showConfirmButton: true,
      });
    }
    else {
      if (rowData.CustomerAddressID) {
        Swal.fire({
          title: self.translate.instant('confirmation'),
          text: self.translate.instant('pm.do-you-want-to-delete-this-record'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: self.translate.instant('yes_delete'),
          cancelButtonText: self.translate.instant('no_delete'),
        })
          .then((result) => {
            if (result.value) {
              self.mangoAPISrvc.showLoader(true);
              self.mangoAPISrvc
                .deleteCustomerAddresses(rowData.CustomerAddressID)
                .subscribe(
                  (data: any) => {
                    const index = self.clientAddresses.indexOf(rowData);
                    self.clientAddresses.splice(index, 1);
                    self.mangoAPISrvc.notify("success", self.translate.instant('Success'), AppConstants.deleteMessage)
                    self.mangoAPISrvc.showLoader(false);
                  },
                  (err) => {
                    const res = err.json();
                    self.mangoAPISrvc.notify("error", self.translate.instant('error'), AppConstants.deleteErrorMsg)
                    self.mangoAPISrvc.showLoader(false);
                  }
                );
            }
          });
      }
      else {
        const index = self.clientAddresses.indexOf(rowData);
        self.clientAddresses.splice(index, 1);
      }

    }

  }

  onDetectChange(row) {
    row["IsRowEditing"] = true;
  }

  applyOverlay() {
    return this.isLoading ? 'addSidebar' : '';
  }

  redirectToDMS() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getIsDMSIshareValue().subscribe((result: any) => {
      if (result?.data?.isDMSIShare) {
        const { IShareClientID } = this.editableClient;
        this.mangoAPISrvc.getIShareUserApiToken().subscribe(
          (result: any) => {
            this.mangoAPISrvc.showLoader(false);
            const url = `${environment.BASE_IMAGINETIME}/firm/${result.data?.firmID}/workspaces/${IShareClientID}/files?userapitoken=${result.data?.userapitoken}`;
            window.open(url, "_blank").focus();
          },
          (error) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify("error", "Error", AppConstants.fetchErrorMsg);
          }
        );
      } else {
        this.encrDecSrvc.addObject(AppConstants.redirectToDMSFromClient, true);
        this.router.navigate([`${AppConstants.dmsRoutePath}/${AppConstants.dmsUIMain}`]);
      }
    });
  }

  getStaffName(staffID: number) {
    return this.staffListWithInactive.find(staff => staff.value === staffID)?.label
  }

  showClientDefinedRow(rowData, selectedCustomCompanyTable, isOverride) {
    if(!!selectedCustomCompanyTable && rowData.FirmDescription === '' && !isOverride) return false
    return true
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38)
  }
}
