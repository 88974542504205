import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription, timer as ObservabeTimer } from 'rxjs';
import {
  EncrDecrService,
  MangoApiService,
  mangoUtils,
} from "@app/_services";
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import moment from 'moment';
import { timer } from 'rxjs';
import * as workerTimers from 'worker-timers';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
/**
 * TODO:
 * 1. Bug on timer after clicking and closing
 * 2. On Time Entry Form - Engagement is blank
 */

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html'
})
export class TimerComponent implements OnInit {
  showTimerDialog: boolean = false;
  isShowMemo = false;
  public clients: any[] = [];
  public filteredClients: any[] = [];
  projectsList: any = [];
  busy: Promise<any>;
  public selClient: any = null;
  public activityListSub: Subscription;
  private TimeandExpenseSub: Subscription = null;
  public activityList: any[] = [];
  public tasksList: any[] = [];
  public selActivity: any = {};
  public convert: boolean = false;
  isFromProject: boolean = false;
  public companyGlobalSetting: any = {};
  intervalid: any;
  public timerTask: any = {
    TimeStart: new Date()
  };
  public displayTimerModal: boolean = false;
  private shortcutSub: Subscription = null;
  public isSaved: boolean = false;
  beforeSlash: string | null = null;
  afterSlash: string | null = null;

  constructor(
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    public mangoUtils: mangoUtils,
    private sharedSrvc: SharedComponentsService,
    private changeDetectorRef: ChangeDetectorRef,
    private _translate: TranslateService
  ) {
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
    this.getActivityGroups();
    this.getCompanyGlobalSetting();
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clients.length == 0 || this.clients.length !== list.length) {
      this.clients = [];
      const list = this.encrDecSrvc.clientList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item['ContactRecord'] != true) {
          this.clients.push(item);
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  ngOnDestroy() {
    this.shortcutSub?.unsubscribe();
    this.TimeandExpenseSub?.unsubscribe();
    this.tasksList = [];
    this.timerTask = {};
  }

  openTimeDialog(data) {
    this.showTimerDialog = true;
  }

  getProjects(clientId) {
    const _this = this;
    _this.projectsList = [];
    _this.getActivityGroups();
    _this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(function (data: any) {
      const filterData = data.filter((note) => note.Inactive == false);
      _this.projectsList = [];
      for (let i = 0; i < filterData.length; i++) {
        _this.projectsList.push({ label: filterData[i].EngagementName, value: filterData[i].ProjectMasterID, isBillable: filterData[i].isBillable })
      }
      if(_this.projectsList.length == 1){
        _this.timerTask.ProjectMasterID = _this.projectsList[0].value;
      }
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  replaceCaret(desc, type) {
    desc[type] = this.mangoUtils.replaceCaretTemplate(desc[type]);
  }

  replaceShortcuts(value, type) {
    if (!value) {
      return;
    }
    let newtextParse = '';

      if (value.includes('/')) {
        const indiceSlash = value.indexOf('/');
        if (indiceSlash !== -1) {
          this.beforeSlash = value.substring(0, indiceSlash);
          this.afterSlash = value.substring(indiceSlash);
          const value2 = this.convertShortcuts(this.afterSlash);
          newtextParse += this.beforeSlash + value2;
          this.timerTask[type] = newtextParse;
        } else {
          this.beforeSlash = null;
          this.afterSlash = null;
          newtextParse += value;

          this.timerTask[type] = newtextParse;
        }
      } else {
        const value2 = this.convertShortcuts(value);
        if(value2) {
          newtextParse += value2;
          if(newtextParse.includes('@')){
            this.timerTask[type] = newtextParse;
            return
          }
        }
        this.timerTask[type] = newtextParse;

      }

  }

  convertShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(" ");
  }

  handleTimerDialog(args: any) {
    this.getCompanyGlobalSetting();
    this.tasksList = [];
    if (this.timerTask.timer) {
      // this.timerTask.timer.unsubscribe();
      workerTimers.clearInterval(this.timerTask.timer);
      delete this.timerTask.timer;
    }
    if (args.display == false) {
      this.timerTask = {
        TimeStart: new Date()
      };
      this.displayTimerModal = args.display;
      return;
    }

    if(args?.convert)
      this.convert = true;
    else
      this.convert = false;

    this.isFromProject = false;
    if (args.timerTask && args.timerTask.id) {
      this.tasksList.push(args.timerTask);
      this.startTimer(args.timerTask['id'], true);
      this.changeDetectorRef.detectChanges();

      this.timerTask = args.timerTask;
      this.timerTask.ProjectMasterID = this.timerTask.ProjectMasterID;
      this.getProjects(args.timerTask.ClientID);
      this.selClient = {
        ClientID: args.timerTask.ClientID,
        ClientName: args.timerTask.ClientName
      };
      this.selActivity = {
        ServiceCode: args.timerTask.ServiceCode,
        ServiceCodeID: args.timerTask.ServiceCodeID,
        Description: args.timerTask.ServiceDesc,
        label: args.timerTask.ServiceDesc
      };
    } else {
      this.timerTask = {
        TimeStart: new Date(),
        TotalTime: new Date()
      };
      this.selClient = null;
      this.selActivity = {};
      //
      const timer = ObservabeTimer(0, 997);

      const startTime = Date.now() - (this.timerTask.counter || 0)
      this.timerTask.timer = workerTimers.setInterval(() => {
        this.timerTask.counter = Date.now() -  startTime
        const [hours, minutes, seconds] = this.mangoUtils.msToTime(this.timerTask.counter)

        this.timerTask.hours = hours;
        this.timerTask.minutes = minutes;
        this.timerTask.seconds = seconds;
        this.timerTask.TotalTime = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);
      }, 1000);
      this.timerTask.isStarted = true;

      if(args?.isFromProject) {
        this.isFromProject = true
        this.timerTask['ClientID'] = args['projectObject']['ClientID'];
        this.timerTask['ProjectMasterID'] = args['projectObject']['ProjectMasterID'];
        this.timerTask['ddmProjectHeaderID'] = args['projectObject']['ddmProjectHeaderID'];
        this.timerTask['ddmProjectDetailID'] = args['projectObject']['ddmProjectDetailID'];
        this.timerTask['Memo'] = args['projectObject']['Memo'];
        this.getProjects(this.timerTask.ClientID);
        this.selClient = {
          ClientID: args['projectObject']['ClientID'],
          ClientName: args['projectObject']['ClientName'],
          MarkSlipsBilled: args['projectObject']['MarkSlipsBilled'],
          Billable: args['projectObject']['Billable'],
        };
      }
    }

    this.displayTimerModal = args.display;

  }

  changeStartTime(item) {
    if (!item) {
      const hours = this.timerTask.hours ? this.timerTask.hours : 0;
      const minutes = this.timerTask.minutes ? this.timerTask.minutes : 0;
      const seconds = this.timerTask.seconds ? this.timerTask.seconds : 1;
      this.timerTask.TotalTime = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);
    }
  }

  convertToTimeEntry() {
    if (this.timerTask.timer) {
      // this.timerTask.timer.unsubscribe();
      workerTimers.clearInterval(this.timerTask.timer);
    }
    this.timerTask.isStarted = false;
    delete this.timerTask.timer;
    this.encrDecSrvc.addObject(AppConstants.timerTaskSelected, JSON.stringify(this.timerTask))
    const data = {}
    data['isEditFlow'] = false;
    data['ClientID'] = "timer";
    this.sharedSrvc.openTimeEntryDialog(data);
  }

  closeTimer(id) {
    if (!id || !this.isSaved) {
      if (this.timerTask.timer) {
        // this.timerTask.timer.unsubscribe();
        workerTimers.clearInterval(this.timerTask.timer);
        this.sharedSrvc.timerTask = {...this.timerTask, isCanceled: true};
      }
    } else {
      if (Object.keys(this.selClient).length == 0) {
        return;
      }

      this.selActivity = this.activityList.filter((item) => {
        return item.fullobj.ServiceCodeID == this.timerTask.ServiceCodeID;
      })[0]['fullobj'];

      if (Object.keys(this.selActivity).length == 0) {
        return;
      }

      const timerTask = this.timerTask;
      if (timerTask.timer) {
        // timerTask.timer.unsubscribe();
        workerTimers.clearInterval(timerTask.timer);
        delete timerTask.timer;
      }
      if (this.tasksList.length == 0) {
        this.tasksList.push(timerTask);
        this.tasksList.map((task) => {
          if (task.isStarted) {
            delete task.timer;
          }
        });

        this.encrDecSrvc.addObject(AppConstants.timerTasks, JSON.stringify(this.tasksList));
      }
      this.sharedSrvc.timerTask = this.timerTask;
      this.timerTask = {};
      return true;
    }
    // // this._timeAndExpenseDialog.timerTask = this.timerTask;
    // // this.tasksList = [];
    // // this.timerTask = {};

    this.displayTimerModal = false;
    this.sharedSrvc.sharedCompVisibility.timer = false;
  }

  submitAddTimer() {
    if (Object.keys(this.selClient).length == 0) {
      return;
    }

    this.selActivity = this.activityList.filter((item) => {
      return item.fullobj.ServiceCodeID == this.timerTask.ServiceCodeID;
    })[0]['fullobj'];

    if (Object.keys(this.selActivity).length == 0) {
      return;
    }

    const timerTask = this.timerTask;
    timerTask.ClientID = this.selClient.ClientID;
    timerTask.ClientName = this.selClient.ClientName;
    timerTask.OriginatingPartnerID = this.selClient.OriginatingPartnerID;
    timerTask.BillingPartnerID = this.selClient.BillingPartnerID;
    timerTask.GroupDescriptionID = this.selClient.BillingGroupID;
    timerTask.GroupDescriptionIDArray = this.selClient.GroupDescriptionIDArray;
    timerTask.ClientTypeID = this.selClient.ClientTypeID;
    timerTask.MarkSlipsBilled = this.selClient.MarkSlipsBilled ? this.selClient.MarkSlipsBilled : false;
    timerTask['Billable'] = this.selClient.Billable ? this.selClient.Billable : false;

    const engagementBillabeValue = this.projectsList.filter((item) => item.value == this.timerTask.ProjectMasterID)[0]['isBillable'];
    timerTask['isBillable'] = engagementBillabeValue ? engagementBillabeValue : false;

    timerTask.ServiceCode = this.selActivity.ServiceCode;
    timerTask.ServiceCodeID = this.selActivity.ServiceCodeID;
    timerTask.ServiceBillable = this.selActivity?.Billable;
    timerTask.ServiceDesc = this.selActivity.Description;
    timerTask.submitted = true;
    timerTask.counter = this.mangoUtils.convertDateTimeToMS(timerTask.TotalTime)
    if (timerTask.timer) {
      // timerTask.timer.unsubscribe();
      workerTimers.clearInterval(timerTask.timer);
      delete timerTask.timer;
    }
    if (this.tasksList.length == 0) {
      this.tasksList.push(timerTask);
      this.tasksList.map((task) => {
        if (task.isStarted) {
          delete task.timer;
        }
      });

      this.encrDecSrvc.addObject(AppConstants.timerTasks, JSON.stringify(this.tasksList));
    }
    this.sharedSrvc.timerTask = this.timerTask;
    this.timerTask = {};
    return true;
  }

  deleteTimer() {
    this.timerTask.deleted = true;
    if (this.timerTask.timer) {
      // this.timerTask.timer.unsubscribe();
      workerTimers.clearInterval(this.timerTask.timer);
      delete this.timerTask.timer;
    }
    this.timerTask.isStarted = false;
    delete this.timerTask.timer;
    const tasks = this.encrDecSrvc.getObject(AppConstants.timerTasks);
    const Data = JSON.parse(tasks);
    if (Data) {
      this.encrDecSrvc.addObject(AppConstants.timerTasks, JSON.stringify(Data.filter(i => i.id !== this.timerTask.id)));
    }
    this.sharedSrvc.timerTask = this.timerTask;
    this.timerTask = {};
  }

  convertHoursToNumericHours(time) {
    const arrTime = time.split(":");
    let minute: any = Math.round(arrTime[1] * 100 / 60);
    if (minute < 9) minute = "0" + minute;
    return arrTime[0] + "." + minute;
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clients.length; i++) {
        const client = this.clients[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false
        ) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  fetchClientList(searchString: string, callback: any) {
    const self = this;

    const result = this.encrDecSrvc.clientList;
    self.clients = result;
    callback();
  }

  handleSelectClick(obj) {
    if(obj.BlockTimeExpenseEntry){
      this.selClient = this.timerTask?.ClientID
        ? {
            ClientID: this.timerTask.ClientID,
            ClientName: this.timerTask.ClientName,
            MarkSlipsBilled: this.timerTask.MarkSlipsBilled,
            Billable: this.timerTask.Billable,
          }
        : {ClientID: null};
      Swal.fire({
        icon: 'warning',
        title: `${this._translate.instant('Warning')}`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: `${this._translate.instant('block-time-entry')}!`,
      });
      return;
    }
    this.selClient = obj;
    this.getProjects(obj.ClientID);
  }

  handleClientDropdownClick(event) {
    this.filteredClients = [];
    //mimic remote call
    setTimeout(() => {
      this.filteredClients = this.clients;
    }, 1)
  }

  getActivityGroups() {
    const self = this;
    const companyId = self.encrDecSrvc.getObject(AppConstants.companyID);
    if (!companyId) {
      return false;
    }
    // NOTE: Refactored
    this.mangoAPISrvc.getActivityList().subscribe((data: any) => {
      self.selActivity = {};
      self.activityList = [];
      const tempObj = data.sort(self.mangoUtils.compareValues('Description', 'asc'));
      for (let i = 0; i < tempObj.length; i++) {
        self.activityList.push({ label: tempObj[i].ServiceCode + ' - ' + tempObj[i].Description, value: tempObj[i].ServiceCodeID, fullobj: tempObj[i] })
      }
    });
  }

  handleActivitySelectClick(obj) {
    const activity = this.activityList.filter((item) => {
      return item.fullobj.ServiceCodeID == obj.value;
    })[0]['fullobj'];
    this.selActivity = activity;
    if(!this.isFromProject || !this.timerTask.Memo)
      this.timerTask.Memo = activity['DefaultMemo'] ? this.mangoUtils.replaceCaretTemplate(activity['DefaultMemo']) : "";
  }

  startTimer(id, isLoading) {
    const srchTask = this.tasksList.filter((item) => {
      return item.id == id;
    });

    if (srchTask.length == 0 && id) {
      return;
    }
    if (id) {
      this.timerTask = {}
      this.timerTask = srchTask[0];
    }
    if (this.timerTask && this.timerTask.isStarted && !isLoading) {
      return;
    }

    if (this.timerTask && this.timerTask.timer) {
      // this.timerTask.timer.unsubscribe();
      workerTimers.clearInterval(this.timerTask.timer);
      delete this.timerTask.timer;
    }
    const timer = ObservabeTimer(0, 997);
    if (!isLoading) {
      const obj = moment(this.timerTask.TotalTime, 'hh:mm:ss');
      this.timerTask.hours = obj.hours();
      this.timerTask.minutes = obj.minutes();
      this.timerTask.seconds = obj.seconds();
    }
    this.timerTask.isStarted = true;
    const startTime = Date.now() - (this.timerTask.counter || 0)
    this.timerTask.timer = workerTimers.setInterval(() => {
      if (!this.timerTask.isStarted) {
        return;
      }
      this.timerTask.counter = Date.now() -  startTime
      const [hours, minutes, seconds] = this.mangoUtils.msToTime(this.timerTask.counter)

      this.timerTask.hours = hours;
      this.timerTask.minutes = minutes;
      this.timerTask.seconds = seconds;
      this.timerTask.TotalTime = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);
    }, 1000);
  }

  stopTimer(id) {
    const srchTask = this.tasksList.filter((item) => {
      return item.id == id;
    });

    if (srchTask.length == 0) {
      if (!id) {
        // this.timerTask.timer.unsubscribe();
        workerTimers.clearInterval(this.timerTask.timer);
        this.timerTask.isStarted = false;
        delete this.timerTask.timer;
      }
      return;
    }

    const task = srchTask[0];
    if (!task.isStarted) {
      task.isStarted = false;
      return;
    }
    if (task.timer) {
      //todo - update DB
      // task.timer.unsubscribe();
      workerTimers.clearInterval(task.timer);
    }
    task.isStarted = false;
    delete task.timer;
  }

  getCompanyGlobalSetting() {
    const klassObj = this;
    // NOTE: Refactored
    const data = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    klassObj.companyGlobalSetting = data;
    if (klassObj.companyGlobalSetting) {
      klassObj.companyGlobalSetting['TimeMemoRequired'] = klassObj.companyGlobalSetting['TimeMemoRequired'] ? klassObj.companyGlobalSetting['TimeMemoRequired'] : false;
    }
  }
}
