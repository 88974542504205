<div class="dashboard">
    <div class="layout-content p-px-2">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{'Company_Dashboard' | translate}}" icon="fal fa-tachometer"
                styleClass="p-button-sm" routerLink="/admin/companyDashboard" class="p-button-help p-pr-2"></p-button>
            <p-button pRipple label="{{'Add_Time' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="openTimeSheetModal()"></p-button>
            <p-button pRipple label="{{'Add_Expense' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="openExpenseModal()"></p-button>
        </span>
        <div class="p-grid p-p-0">
            <!-- <div class="p-col-2" style="width: 15%;">
                <div class="box box-stretched">
                    <div class="p-col-12 p-mt-5 p-text-center">
                        <img class="dashboard-profile-img" [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"
                            onError="this.src='assets/img/userForTable.png'" />
                    </div>
                    <div class="p-col-12 p-text-center">
                        <label class="label-text ic-size-16">
                            {{loggedInUser}}
                        </label>
                    </div>
                    <div class="p-col-12 p-text-center">
                        <label class="label-text ic-size-13">
                            {{userDept}}
                        </label>
                    </div>
                    <hr>
                    <div class="p-col-12 p-text-center">
                        <p-button pRipple label="{{'My_Profile' | translate}}" icon="pi pi-user" class="p-button-help"
                            routerLink="/admin/myProfile"></p-button>
                    </div>
                </div>
            </div> -->
            <div class="p-col-12 p-pl-0">
                <div class="p-grid">
                    <div class="p-col-12 manual p-ml-1" *ngIf="!companyData.IsHideToDo">
                        <p-panel header="To-Do" [toggleable]="true">
                            <div class="card p-p-0">
                                <div class="p-grid" id="todoSection" style="position: relative;">
                                    <div class="p-col-12 p-p-0">
                                        <div class="table-header p-formgroup-inline">
                                            <div class="p-col-10">
                                                <div class="p-col-12" *ngIf="!isKanbanView">
                                                    <div class="colorBoxesDiv bg-blue ic-white p-text-center">
                                                        <p class="p-text-center">{{totalTasks}}</p>
                                                        <span>{{'Total' | translate}} {{'Items' | translate}}</span>
                                                    </div>
                                                    <div class="colorBoxesDiv bg-red ic-white p-text-center">
                                                        <p>{{highPriorityCount}}</p>
                                                        <span>{{'High'| translate}} {{'Priority' | translate}}</span>
                                                    </div>
                                                    <div class="colorBoxesDiv bg-orange ic-white p-text-center">
                                                        <p>{{mediumPriorityCount}}</p>
                                                        <span>{{'Medium' | translate}} {{'Priority' | translate}}</span>
                                                    </div>
                                                    <div class="colorBoxesDiv bg-neon-green ic-white p-text-center">
                                                        <p>{{lowPriorityCount}}</p>
                                                        <span>{{'Low' | translate}} {{'Priority' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="p-grid p-formgroup-inline">
                                                        <div class="p-col-2 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">Search</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">{{'staff-name' |
                                                                translate}}</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">{{'Customer' |
                                                                translate}}</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field"
                                                            *ngIf="!isKanbanView">
                                                            <label class="p-mr-3 label-text">{{'Priority' |
                                                                translate}}</label>
                                                        </div>
                                                        <div class="p-col-2 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">{{'Category' |
                                                                translate}}</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">Open Items</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">Completed</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field">
                                                            <label class="p-mr-3 label-text">Due Date</label>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-field"
                                                            *ngIf="isKanbanView">
                                                            <label class="p-mr-3 label-text"></label>
                                                        </div>
                                                    </div>
                                                    <div class="p-grid p-formgroup-inline">
                                                        <div class="p-col-2 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <span class="p-input-icon-left">
                                                                <i class="fal fa-search"></i>
                                                                <input #searchValue pInputText type="text"
                                                                    class="p-pr-0"
                                                                    (input)="globalSearchListener($event.target.value)"
                                                                    placeholder="{{ 'globalsearch' | translate }}" />
                                                                <span style="margin-left:-20px;">
                                                                    <a (click)="clearSearchFilter()">
                                                                        <i class="fa fa-times"
                                                                            style="color: rgb(149, 149, 153); cursor: pointer;"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <p-dropdown [options]="staffListOptions" styleClass="p-mr-1"
                                                                (onChange)="selectEditStaff($event)"
                                                                [(ngModel)]="filterStaffId">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <p-autoComplete [(ngModel)]="selClient"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                [suggestions]="filteredClients" autocomplete="off"
                                                                (completeMethod)="filterClients($event)"
                                                                field="ClientName"
                                                                (onSelect)="handleSelectClick($event.value)"
                                                                ngDefaultControl
                                                                placeholder="{{'Search-Clients' | translate }}"
                                                                (onFocus)="$event.target.select()" [dropdown]="true"
                                                                required>
                                                                <ng-template let-client pTemplate="item">
                                                                    <div>{{client.ClientName}}{{ client.ClientNumber ? '
                                                                        - ' + client.ClientNumber : '' }}</div>
                                                                </ng-template>
                                                            </p-autoComplete>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field"
                                                            *ngIf="!isKanbanView">
                                                            <p-multiSelect [options]="priorityOptions" [filter]="false"
                                                                defaultLabel="{{'dashboard.Select-Priority' | translate}}"
                                                                [(ngModel)]="selectedPriorities"
                                                                (onChange)="onChangeFilter('priority', $event)"
                                                                styleClass="p-mr-1" [virtualScroll]="true">
                                                                <ng-template let-item pTemplate="item">
                                                                    <div>
                                                                        <i
                                                                            [class]="'fa fa-flag ic-size-16 p-pr-2 ' + item.colorCode"></i>
                                                                        <span>{{item.value}}</span>
                                                                    </div>
                                                                </ng-template>
                                                            </p-multiSelect>
                                                        </div>
                                                        <div class="p-col-2 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <p-multiSelect [options]="categoryOptions"
                                                                defaultLabel="{{'dashboard.Select-Category' | translate}}"
                                                                [(ngModel)]="selectedCategories"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                selectedItemsLabel="{0} item selected"
                                                                (onChange)="onChangeFilter('category', $event)"
                                                                styleClass="p-mr-1"
                                                                [virtualScroll]="true"></p-multiSelect>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <p-dropdown [options]="dateRangeOptions" styleClass="p-mr-1"
                                                                (onChange)="selectDateRange($event, false)"
                                                                [(ngModel)]="todoSelectedDate">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <p-dropdown [options]="dateRangeOptions" styleClass="p-mr-1"
                                                                (onChange)="selectDateRange($event, true)"
                                                                [(ngModel)]="completedSelectedDate">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <p-dropdown [options]="dueDateRange" styleClass="p-mr-1"
                                                                (onChange)="dueDateChange($event)"
                                                                [(ngModel)]="dueDateSelectedDate">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="p-col-1 p-pb-0 p-mb-0 p-pr-0 p-fluid p-field">
                                                            <button pButton pRipple name="syncIcon"
                                                                pTooltip="{{'Reset-Filters' | translate}}"
                                                                (click)="resetFilters()" type="button"
                                                                icon="fal fa-sync" class="p-button-rounded"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-2">
                                                <div class="p-mr-4 p-mt-4 pull-right">
                                                    <p-button pRipple label="{{'dashboard.Add-To-Do' | translate}}"
                                                        icon="fal fa-plus" styleClass="p-button-sm"
                                                        class="p-button-help p-pr-2" (click)="addTodo()">
                                                    </p-button>
                                                    <p-button pRipple
                                                        label="{{!isKanbanView ? 'Kanban View': 'List View'}}"
                                                        icon="fal fa-list" styleClass="p-button-sm"
                                                        class="p-button-help p-pr-2" (click)="changeView()">
                                                    </p-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div *ngIf="isKanbanView">
                                                <div class="content-wrapper">
                                                    <ejs-kanban #kanbanObj cssClass='kanban-overview'
                                                        keyField="Priority" [dataSource]='kanbanData'
                                                        [cardSettings]='cardSettings'
                                                        (cardDoubleClick)='OnCardDoubleClick($event)'
                                                        enableTooltip='true' (cardRendered)='cardRendered($event)'
                                                        (dragStop)="OnDragStop($event)" allowScrolling="true"
                                                        (created)="onCreated($event)"
                                                        [tooltipTemplate]="tooltipTemplate">

                                                        <e-columns>
                                                            <e-column [headerText]="columns[0].headerText"
                                                                [keyField]="columns[0].keyField"
                                                                [allowToggle]="columns[0].allowToggle">
                                                                <ng-template #template let-data>
                                                                    <div class="header-template-wrap">
                                                                        <div
                                                                            class="header-icon e-icons {{data.keyField}}-icons">
                                                                        </div>
                                                                        <div class="header-text">{{data.headerText}}
                                                                            ({{lowPriorityCount}})</div>
                                                                    </div>
                                                                </ng-template>
                                                            </e-column>

                                                            <e-column [headerText]="columns[1].headerText"
                                                                [keyField]="columns[1].keyField"
                                                                [allowToggle]="columns[1].allowToggle">
                                                                <ng-template #template let-data>
                                                                    <div class="header-template-wrap">
                                                                        <div
                                                                            class="header-icon e-icons {{data.keyField}}-icons">
                                                                        </div>
                                                                        <div class="header-text">{{data.headerText}}
                                                                            ({{mediumPriorityCount}})</div>
                                                                    </div>
                                                                </ng-template>
                                                            </e-column>

                                                            <e-column [headerText]="columns[2].headerText"
                                                                [keyField]="columns[2].keyField"
                                                                [allowToggle]="columns[2].allowToggle">
                                                                <ng-template #template let-data>
                                                                    <div class="header-template-wrap">
                                                                        <div
                                                                            class="header-icon e-icons {{data.keyField}}-icons">
                                                                        </div>
                                                                        <div class="header-text">{{data.headerText}}
                                                                            ({{highPriorityCount}})</div>
                                                                    </div>
                                                                </ng-template>
                                                            </e-column>

                                                            <e-column [headerText]="columns[3].headerText"
                                                                [keyField]="columns[3].keyField"
                                                                [allowToggle]="columns[3].allowToggle"
                                                                [isExpanded]='columns[3].isExpanded'>
                                                                <ng-template #template let-data>
                                                                    <div class="header-template-wrap">
                                                                        <div
                                                                            class="header-icon e-icons {{data.keyField}}-icons">
                                                                        </div>
                                                                        <div class="header-text">{{data.headerText}} ({{
                                                                            completedNotesCount }})</div>
                                                                    </div>
                                                                </ng-template>
                                                            </e-column>
                                                        </e-columns>

                                                        <ng-template #cardSettingsTemplate let-data>
                                                            <div class='card-template e-tooltip-text'>
                                                                <div class='e-card-header'
                                                                    style="padding: 25px 10px 10px 10px;max-height: 20px;">
                                                                    <div class='e-card-header-caption card-header'
                                                                        style="align-items: flex-start;">
                                                                        <div class='e-card-header-title'
                                                                            style="font-size: small;">
                                                                            {{data.Assignee}}
                                                                        </div>
                                                                    </div>
                                                                    <div class='e-card-header-caption card-header'
                                                                        style="align-items: flex-end;">
                                                                        <div class='e-card-header-title card-date'
                                                                            style="font-style: italic;font-size: small;">
                                                                            {{data.DateCreated}}
                                                                        </div>
                                                                        <div class="hover-buttons">
                                                                            <button pButton type="button"
                                                                                class="p-button-rounded p-button-icon-only p-button-text ic-red"
                                                                                icon="pi pi-trash" iconPos="left"
                                                                                (click)="deleteTodoAlert(data)"></button>
                                                                            <button pButton type="button"
                                                                                class="p-button-rounded p-button-icon-only p-button-text ic-green"
                                                                                icon="pi pi-pencil" iconPos="left"
                                                                                (click)="editToDoModal(data)"></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class='e-card-header'
                                                                    style="padding: 0 0 10px 10px;max-height: 27px;">
                                                                    <div class='e-card-header-caption'>
                                                                        <div target="descTarget"
                                                                            style="font-weight: 500; font-size: 12px; display: inline-block; width: 300px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;">
                                                                            {{data.Description}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class='e-card-custom-footer'>
                                                                    <div class="e-card-tag-field"
                                                                        [style.background-color]="data.CategoryColor"
                                                                        *ngIf="data.Tags">{{data.Tags}}
                                                                    </div>
                                                                    <div class="e-card-tag-field"
                                                                        style="background-color: #f84e4e;"
                                                                        *ngIf="data.DueDate">
                                                                        <span
                                                                            style="display: flex; align-items: center;">
                                                                            <i class="pi pi-clock"
                                                                                style="margin-right: 5px"></i>
                                                                            {{ data.DueDateText }}
                                                                        </span>
                                                                    </div>
                                                                    <i class="pi pi-lock ic-red p-mt-1"
                                                                        *ngIf="data.isPrivate"
                                                                        style="right: 0;position: absolute; margin-right: 50px;"></i>
                                                                    <div class='e-card-avatar'
                                                                        [style.background-color]="data.StaffColor"
                                                                        [style.color]="data.InitialsColor">
                                                                        <div target="target">{{getString(data.Staff)}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </ejs-kanban>
                                                </div>
                                            </div>
                                            <p-table *ngIf="!isKanbanView" #dt [value]="todoListFinal"
                                                [responsive]="true" [rows]="20" dataKey="ToDoID" [filterDelay]="0"
                                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo todo-table"
                                                sortField="ClientName" [scrollable]="true"
                                                scrollHeight="calc(82vh - 300px)"
                                                [globalFilterFields]="['Status','Priority','ClientName','Description','DueDate']"
                                                (onFilter)="onFilter($event)" [sortOrder]="1"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th class="width-4p p-text-center">
                                                            {{'actions' | translate}}
                                                        </th>
                                                        <th class="width-5p p-text-center">
                                                            {{'status' | translate}}
                                                        </th>
                                                        <th class="width-6p p-text-center" pSortableColumn="Priority">
                                                            {{'Priority' | translate}}
                                                            <p-sortIcon field="Priority"></p-sortIcon>
                                                        </th>
                                                        <th class="width-30p p-text-left" pSortableColumn="ClientName">
                                                            {{'timer.client' | translate}}
                                                            <p-sortIcon field="ClientName"></p-sortIcon>
                                                        </th>
                                                        <th class="width-10p p-text-left" pSortableColumn="DateCreated">
                                                            {{'Created' | translate}}
                                                            <p-sortIcon field="DateCreated"></p-sortIcon>
                                                        </th>
                                                        <th class="width-30p p-text-left" pSortableColumn="Description">
                                                            {{'memo' | translate}}
                                                            <p-sortIcon field="Description"></p-sortIcon>
                                                        </th>
                                                        <th class="width-5p p-text-center">
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr class="cursor-hand">
                                                        <td class="width-4p p-text-center">
                                                            <i pRipple
                                                                class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                                                (click)="editTodo(rowData);$event.stopPropagation();"
                                                                tooltipPosition="top"
                                                                pTooltip="{{'Edit' | translate}}"></i>
                                                            <i pRipple
                                                                (click)="deleteTodoAlert(rowData);$event.stopPropagation();"
                                                                class="fal fa-trash-alt ic-red cursor-hand p-pl-2"
                                                                tooltipPosition="top"
                                                                pTooltip="{{'mgmt-delete' | translate}}"></i>
                                                        </td>
                                                        <td class="width-5p p-text-center">
                                                            <i (click)="selectedStatus(false, rowData);$event.stopPropagation();"
                                                                *ngIf="rowData.Status === 'Complete'"
                                                                class="fal fa-check ic-green ic-size-16"
                                                                aria-hidden="true"></i>
                                                            <p-checkbox
                                                                (click)="selectedStatus(true, rowData);$event.stopPropagation();"
                                                                *ngIf="rowData.Status !== 'Complete'"></p-checkbox>
                                                        </td>
                                                        <td class="width-6p p-text-center"
                                                            (click)="onRowSelect(rowData)">
                                                            <i class="fa fa-flag ic-red ic-size-16" aria-hidden="true"
                                                                *ngIf="rowData.Priority === 'High'"></i>
                                                            <i class="fa fa-flag ic-yellow ic-size-16"
                                                                aria-hidden="true"
                                                                *ngIf="rowData.Priority === 'Medium'"></i>
                                                            <i class="fa fa-flag ic-green ic-size-16" aria-hidden="true"
                                                                *ngIf="rowData.Priority === 'Low'"></i>
                                                        </td>
                                                        <td class="width-30p p-text-left p-text-nowrap p-text-truncate"
                                                            pTooltip="{{rowData.ClientName}}"
                                                            tooltipStyleClass="tooltip-no-wrap" showDelay="800"
                                                            tooltipPosition="top" (click)="onRowSelect(rowData)">
                                                            {{rowData.ClientName}}
                                                        </td>
                                                        <td class="width-10p p-text-left p-text-nowrap p-text-truncate"
                                                            (click)="onRowSelect(rowData)">
                                                            {{rowData.DateCreated | date:'MM/dd/yyyy'}}
                                                        </td>
                                                        <td class="width-30p p-text-left p-text-nowrap p-text-truncate"
                                                            pTooltip="{{rowData.Description}}"
                                                            tooltipStyleClass="tooltip-no-wrap" showDelay="800"
                                                            tooltipPosition="top" (click)="onRowSelect(rowData)">
                                                            {{rowData.Description}}
                                                        </td>
                                                        <td class="width-5p p-text-center">
                                                            <i class="pi pi-lock ic-red" *ngIf="rowData.isPrivate"
                                                                tooltipPosition="top"
                                                                pTooltip="{{'private' | translate}}"
                                                                showDelay="800"></i>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="6">
                                                            <p-message severity="info"
                                                                text="{{'data_not_found' | translate}}."
                                                                styleClass="p-px-2">
                                                            </p-message>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-panel>
                    </div>
                    <div class="p-col-12 p-formgroup-inline">
                        <div class="p-col-3 p-pr-0">
                            <div class="contentSpeedo" id="containerToday"></div>
                            <div class="box p-text-left p-p-3">
                                <h3 class="ic-blue ic-size-25">{{'Today' | translate}}</h3>
                                <div class="p-d-flex p-ai-end p-jc-between">
                                    <div class="p-pb-2"><label class="label-text dashboard-box1">{{'Target' |
                                            translate}}:
                                            {{targetHoursForToday}} {{'Hrs' | translate}}</label></div>
                                    <div>
                                        <label class="ic-black pull-right">
                                            <div class="p-text-bold db-hours-font p-text-right">{{actualHoursForToday}}
                                            </div>
                                            <span class="db-billable-hours p-text-right">{{'Billable_Hrs' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-3 p-pr-0">
                            <div class="contentSpeedo" id="containerWeek"></div>
                            <div class="box p-text-left p-p-3">
                                <h3 class="ic-orange ic-size-25">{{'This' | translate}} {{'Week' | translate}}</h3>
                                <div class="p-d-flex p-ai-end p-jc-between">
                                    <div class="p-pb-2"><label class="label-text dashboard-box2">{{'Target' |
                                            translate}}:
                                            {{targetHoursForWeek}} {{'Hrs' | translate}}</label></div>
                                    <div>
                                        <label class="ic-black pull-right">
                                            <div class="p-text-bold db-hours-font p-text-right">{{actualHoursForWeek}}
                                            </div>
                                            <span class="db-billable-hours p-text-right">{{'Billable_Hrs' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-3 p-pr-0">
                            <div class="contentSpeedo" id="containerMonth"></div>
                            <div class="box p-text-left p-p-3">
                                <h3 class="ic-light-green ic-size-25">{{'This' | translate}} {{'Month' | translate}}
                                </h3>
                                <div class="p-d-flex p-ai-end p-jc-between">
                                    <div class="p-pb-2"><label class="label-text dashboard-box3">{{'Target' |
                                            translate}}:
                                            {{targetHoursForMonth}} {{'Hrs' | translate}}</label></div>
                                    <div>
                                        <label class="ic-black pull-right">
                                            <div class="p-text-bold db-hours-font p-text-right">{{actualHoursForMonth}}
                                            </div>
                                            <span class="db-billable-hours p-text-right">{{'Billable_Hrs' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-3 p-pr-0">
                            <div class="contentSpeedo" id="containerYear"></div>
                            <div class="box p-text-left p-p-3">
                                <h3 class="ic-light-blue ic-size-25">{{'dashboard.year-to-date' | translate}}</h3>
                                <div class="p-d-flex p-ai-end p-jc-between">
                                    <div class="p-pb-2"><label class="label-text dashboard-box4">{{'Target' |
                                            translate}}:
                                            {{targetHoursForYear}} {{'Hrs' | translate}}</label></div>
                                    <div>
                                        <label class="ic-black pull-right">
                                            <div class="p-text-bold db-hours-font p-text-right">{{actualHoursForYear}}
                                            </div>
                                            <span class="db-billable-hours p-text-right">{{'Billable_Hrs' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 manual p-ml-1">
                        <p-panel header="{{'dashboard.Staff-Billable-NonBillable-Hours-This-Week' | translate}}"
                            [toggleable]="true">
                            <p-chart responsive="true" height="300px" type="bar" [data]="data"></p-chart>
                        </p-panel>
                    </div>
                    <div class="p-col-12 p-formgroup-inline">
                        <div class="p-col-6 p-pb-0 manual" *ngIf="companyData['IsHideAgenda'] !== true">
                            <p-panel styleClass="p-p-0" header="{{'dashboard.Agenda' | translate}}" [toggleable]="true">
                                <div class="dashboard-box box box-stretched p-fluid p-p-0">
                                    <ejs-schedule #scheduleObj height='100%' width='100%' [selectedDate]="selectedDate"
                                        (actionComplete)="onActionComplete($event)" [eventSettings]="eventSettings"
                                        [(currentView)]="currentView">
                                        <e-views>
                                            <e-view option="Agenda" [allowVirtualScrolling]="virtualscroll"></e-view>
                                        </e-views>
                                        <e-resources>
                                            <e-resource field='OwnerId' tooltipPosition="top"
                                                pTooltip='{{"Owners" | translate}}' [dataSource]='resourceDataSource'
                                                [allowMultiple]='allowMultiple' name='Owners' textField='OwnerText'
                                                idField='OwnerId' colorField='Color'>
                                            </e-resource>
                                        </e-resources>
                                    </ejs-schedule>
                                </div>
                            </p-panel>
                        </div>

                        <!--
                            Please don't be confused with the three database fields
                            "IsLeaderboardVisible", "IsHideToDo", "IsHideAgenda".

                            The "IsHideToDo" and "IsHideAgenda" suggests that the section should be hidden.
                            Hence the condition *ngIf="companyData['IsHideToDo'] !== true" and *ngIf="companyData['IsHideAgenda'] !== true"

                            The "IsLeaderboardVisible" suggests that the section should be visible.
                            Hence the condition *ngIf="companyData['IsLeaderboardVisible'] === true"
                        -->

                        <div class="p-col-6 p-pb-0 manual"
                            *ngIf="(companyData['isShowDashboardAdmin'] && _authGuard.isAllowAccess(20)) || companyData['IsLeaderboardVisible'] === true">
                            <p-panel header="{{'dashboard.leaderboard' | translate}}" [toggleable]="true">
                                <div class="dashboard-box box box-stretched p-fluid p-p-0">
                                    <div class="userListContent commonTable">
                                        <table class="staffList">
                                            <thead>
                                                <tr>
                                                    <th class="bg-light-blue p-text-bold">{{'This' | translate}}
                                                        {{'Week' |
                                                        translate}}</th>
                                                    <th class="bg-light-blue p-text-bold">{{'This' | translate}}
                                                        {{'Month' |
                                                        translate}}</th>
                                                    <th class="bg-light-blue p-text-bold">{{'This' | translate}}
                                                        {{'Year' |
                                                        translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of maxBillableHoursLength; let i = index;">
                                                    <td class="p-text-left" *ngIf="billableHours.weekData[i]">
                                                        <div class="db-img-container">
                                                            <img *ngIf="billableHours.weekData[i].ProfilePic"
                                                                class="db-profile-img"
                                                                src="{{billableHours.weekData[i].ProfilePic}}">
                                                            <img *ngIf="!billableHours.weekData[i].ProfilePic"
                                                                class="db-profile-img"
                                                                src="assets/img/userForTable.png">
                                                            <div class="db-leaderboard-badge">
                                                                {{i + 1}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>{{billableHours.weekData[i].StaffName}}</p>
                                                            <p class="p-mt-3">
                                                                <strong>{{billableHours.weekData[i].TotalTime}}
                                                                    {{'billable-hrs' | translate}}</strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td class="p-text-left" *ngIf="!billableHours.weekData[i]">
                                                    </td>
                                                    <td *ngIf="billableHours.monthData[i]">
                                                        <div class="db-img-container">
                                                            <img *ngIf="billableHours.monthData[i].ProfilePic"
                                                                class="db-profile-img"
                                                                src="{{billableHours.monthData[i].ProfilePic}}">
                                                            <img *ngIf="!billableHours.monthData[i].ProfilePic"
                                                                class="db-profile-img"
                                                                src="assets/img/userForTable.png">
                                                            <div class="db-leaderboard-badge">
                                                                {{i + 1}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>{{billableHours.monthData[i].StaffName}}</p>
                                                            <p class="p-mt-3">
                                                                <strong>{{billableHours.monthData[i].TotalTime}}
                                                                    {{'billable-hrs' | translate}}</strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td class="p-text-left" *ngIf="!billableHours.monthData[i]">
                                                    </td>
                                                    <td *ngIf="billableHours.yearData[i]">
                                                        <div class="db-img-container">
                                                            <img *ngIf="billableHours.yearData[i].ProfilePic"
                                                                class="db-profile-img"
                                                                src="{{billableHours.yearData[i].ProfilePic}}">
                                                            <img *ngIf="!billableHours.yearData[i].ProfilePic"
                                                                class="db-profile-img"
                                                                src="assets/img/userForTable.png">
                                                            <div class="db-leaderboard-badge">
                                                                {{i + 1}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>{{billableHours.yearData[i].StaffName}}</p>
                                                            <p class="p-mt-3">
                                                                <strong>{{billableHours.yearData[i].TotalTime}}
                                                                    {{'billable-hrs' | translate}}</strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td class="p-text-left" *ngIf="!billableHours.yearData[i]">
                                                    </td>
                                                </tr>
                                                <tr *ngIf="maxBillableHoursLength.length == 0" class="p-text-center">
                                                    <!-- <img width="120" height="120" src="/assets/img/timelist.png" class="p-mt-40 p-ml-10 p-mb-10 p-mr-10"> -->
                                                    <p class="p-pb-20" (click)="openTimeSheetModal()">
                                                        {{'dashboard.add-new-time-record' | translate}}.</p>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>