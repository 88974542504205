<p-dialog [modal]="true" [(visible)]="isDisplayDetailsTable" [style]="{width: '35vw'}" [responsive]="true" (onHide)="onCloseDialog()"
  [draggable]="false" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
  <p-header>{{ 'pm.Create-Project-From-Company-Template' | translate }}</p-header>
  <p-table #dt [value]="templateDataSource" (onFilter)="onFilter($event)" [totalRecords]="templateDataSource.length"
    [pageLinks]="3" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
    [scrollHeight]="'calc(90vh - 360px)'" [scrollable]="true" [rowHover]="true" dataKey="MasterTemplateDetailsID"
    [globalFilterFields]="['TemplateName']" sortField="TemplateName" [sortOrder]="1" #detailtab>
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="p-input-icon-left">
          <i class="fal fa-search"></i>
          <input #searchValue pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search by" />
        </span>
        <span>
          <button pButton pRipple type="button" icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text" (click)="dt.reset();clearSearchFilter()"
            tooltipPosition="top" pTooltip="Clear"></button>
        </span>
        <p-message *ngIf="filteredItemsSize > -1" severity="success" text="{{filteredItemsSize}} Record(s) found."
          styleClass="p-ml-2">
        </p-message>
      </div>
    </ng-template>
    <!-- Table Header -->
    <ng-template pTemplate="header">
      <tr>
        <th class="width-15p p-text-center">
        </th>
        <th class="width-85p p-text-left" pSortableColumn="TemplateName">
          {{ 'Template-Names' | translate }}
          <p-sortIcon styleClass="p-ml-0" field="TemplateName"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <!-- Rows -->
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="width-15p p-text-center">
          <p-radioButton name="groupname" [(ngModel)]="selectedTemplate" [value]="rowData"
            [inputId]="rowData.ProjectHeaderID" (click)="radioCompanyClicked(rowData)">
          </p-radioButton>
        </td>
        <td class="width-85p p-text-left p-text-truncate p-text-nowrap" pTooltip="{{ rowData.TemplateName }}"
          tooltipPosition="top" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
          {{rowData.TemplateName}}
        </td>
      </tr>
    </ng-template>
    <!-- No data -->
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="2">
          <p-message severity="info" text="Data not found." styleClass="p-px-2">
          </p-message>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button pButton label="{{ 'create' | translate }}" (click)="createProjectScreen()"
      [disabled]="!isRadioSelected"></button>
    <button pButton label="{{ 'update_button' | translate }}"
      routerLink="/project-management/{{companyTemplateID}}/createTemplate" [disabled]="!isRadioSelected"></button>
    <button pButton label="{{ 'cancel_button' | translate }}" (click)="onCloseDialog()"></button>
  </p-footer>
</p-dialog>