<form [formGroup]="clientForm" autocomplete="nope">
    <!-- buttons -->
    <span class="pull-right p-mr-3 clientform-btn-top">
        <button type="button" pButton pRipple label="{{ 'save_button' | translate }}" [disabled]="!isFormValid"
            icon="fas fa-check" class="p-mr-1 p-button-sm" (click)="submitClientForm(false, true)"></button>
        <button type="button" pButton pRipple label="{{ 'new' | translate }}" *ngIf="clientID > 0"
            (click)="resetClientView(-1)" icon="fal fa-plus" class="p-mr-1 p-button-sm"></button>
        <button type="button" pButton pRipple (click)="submitClientForm(true, false)"
            label="{{ 'save_and_new' | translate }}" [disabled]="clientForm.invalid" icon="fas fa-check"
            class="p-mr-1 p-button-sm" *ngIf="clientID === -1"></button>
        <button type="button" pButton pRipple (click)="deleteClient()"
            label="{{ 'delete' | translate }}" [disabled]="clientForm.invalid" icon="fas fa-trash-alt"
            class="p-mr-1 p-button-sm" *ngIf="clientID !== -1 && (canDeleteClient == true || canDeleteClient == false)"></button>
        <button type="button" pButton pRipple (click)="onClone()" label="{{ 'Clone' | translate }}"
            [disabled]="clientForm.invalid" icon="fal fa-copy" class="p-mr-1 p-button-sm" *ngIf="clientID > 0"></button>
        <button type="button" pButton pRipple (click)="redirectToDMS()" label="{{ 'dms' | translate }}"
            icon="fal fa-folders" class="p-mr-1 p-button-sm" *ngIf="clientID > 0"></button>
        <button type="button" pButton pRipple icon="fal fa-poll-h" tooltipPosition="top"
            pTooltip="{{ 'scratch_pad' | translate }}" class="p-mr-1 p-button-sm"
            (click)="scratchPadEnabled = !scratchPadEnabled"></button>
        <button type="button" pButton pRipple icon="fal fa-table" tooltipPosition="top"
            pTooltip="{{'custom_fields' | translate }}" (click)="openUserDefinedFields()"
            class="p-mr-1 p-button-sm"></button>
        <!-- <button type="button" pButton pRipple icon="fal fa-map-marked-alt" tooltipPosition="top"
            pTooltip="{{'Map' | translate }}" (click)="showMap()" [disabled]="!isValidLocation()"
            class="p-mr-1 p-button-sm"></button> -->
    </span>
    <!-- form -->
    <div class="p-fluid p-formgrid p-grid p-mx-2 common-dialog">
        <div class="p-col-4 ">
            <div class="card p-mt-2">
                <div class="p-field p-col-12">
                    <label class="required-field label-text">{{ 'name' | translate }}
                        <i pTooltip="{{ 'timesheetentry.staff-tooltip' | translate }}"
                            tooltipStyleClass="tooltip-width-300"
                            class="fa fa-question-circle ic-blue question-tooltip-icon" tooltipPosition="right"></i>
                    </label>
                    <input type="text" formControlName="ClientName" pInputText maxlength="80" autocomplete="nope">
                    <small
                        *ngIf="clientForm?.get('ClientName').touched && clientForm?.get('ClientName')?.hasError('minlength')"
                        class="text-danger">
                        <p-message severity="error" text="Name must be at least 3 characters long."></p-message>
                    </small>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'client.invoice_name' | translate }}</label>
                    <input type="text" formControlName="ClientNameInvoice" pInputText autocomplete="nope">
                </div>

                <div class="p-field p-col-12" style="position: relative;">

                    <label class="label-text">{{ 'client.billing_street1' | translate }}</label>
                    <!-- <i tooltipPosition="top" 
                    pTooltip="{{ 'address.tooltip' | translate }}" tooltipStyleClass="tooltip-no-wrap"
                    (click)="onAddressClick()" class='fal fa-address-book pull-right ic-red cursor-hand p-pt-2' tooltipPosition="top">
                    </i> -->
                    <div style="position: absolute;top: 0;right: 0;">
                        <button tooltipPosition="top" pTooltip="{{ 'address.tooltip' | translate }}"
                            tooltipStyleClass="tooltip-no-wrap" pButton pRipple type="button" (click)="onAddressClick()"
                            icon="fal fa-address-book"
                            class="p-button-rounded p-button-success p-mr-2 bg-green addressButton"></button>
                        <p-badge *ngIf="clientAddresses.length > 1" [value]="clientAddresses.length" styleClass="p-mr-2"
                            [style]="{'margin-left':'2px'}"></p-badge>
                    </div>
                    <input type="text" formControlName="BusStreet1" pInputText autocomplete="nope">
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'Street' | translate }} 2</label>
                    <input type="text" formControlName="BusStreet2" pInputText autocomplete="nope">
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'client.city' | translate }}</label>
                    <input type="text" formControlName="BusCity" pInputText autocomplete="nope">
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.state' | translate }}</label>
                        <input type="text" formControlName="BusState" pInputText autocomplete="nope" maxlength="2">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.zip' | translate }}</label>
                        <input type="text" pInputText class="p-text-right" formControlName="BusZip" maxlength="10"
                            autocomplete="nope">
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.client_number' | translate }} ({{'Optional' |
                            translate}})</label>
                        <input type="text" formControlName="ClientNumber" pInputText autocomplete="nope" maxlength="15">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'sys_id' | translate }}</label>
                        <input type="text" formControlName="ClientID" class="p-text-right" pInputText
                            autocomplete="nope" disabled readonly>
                    </div>
                </div>
                <!--  end of card -->
            </div>
        </div>
        <div class="p-col-4">
            <div class="card p-mt-2">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'work' | translate }}</label>
                        <p-inputMask mask="(999) 999-9999? x99999" formControlName="Office" autocomplete="nope">
                        </p-inputMask>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'Fax-Number' | translate }}</label>
                        <p-inputMask mask="(999) 999-9999" formControlName="Fax" autocomplete="nope"></p-inputMask>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'mobile' | translate }}</label>
                        <p-inputMask mask="(999) 999-9999" formControlName="Mobile" autocomplete="nope"></p-inputMask>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'home' | translate }}</label>
                        <p-inputMask mask="(999) 999-9999" formControlName="Home" autocomplete="nope"></p-inputMask>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'description' | translate }}</label>
                        <input type="text" formControlName="Tele1Descr" pInputText autocomplete="nope">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.telephone_number' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999" formControlName="Tele1" autocomplete="nope"></p-inputMask>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'description' | translate }}</label>
                        <input type="text" formControlName="Tele2Descr" pInputText autocomplete="nope">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.telephone_number' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999" formControlName="Tele2" autocomplete="nope"></p-inputMask>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'description' | translate }}</label>
                        <input type="text" formControlName="Tele3Descr" pInputText autocomplete="nope">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.telephone_number' | translate}}</label>
                        <p-inputMask mask="(999) 999-9999" formControlName="Tele3" autocomplete="nope"></p-inputMask>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.contact_name' | translate }}</label>
                        <input type="text" formControlName="ContactPerson" pInputText autocomplete="nope">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'email' | translate }}
                            <i pTooltip="The default email is used for logging into the Client Portal and to receive emailed Invoices and Statements.  Additional contacts can be added in Customer > Contacts."
                                tooltipStyleClass="tooltip-width-300"
                                class="fa fa-question-circle ic-blue question-tooltip-icon" tooltipPosition="top"></i>
                        </label>
                        <!-- <button pButton pRipple (click)="onClickResetPw()" *ngIf="clientID !== -1" tooltipPosition="top"
                            pTooltip="{{ 'reset_password_tooltip_message' | translate }}"
                            tooltipStyleClass="tooltip-no-wrap" type="button" icon="far fa-key"
                            class="p-button-rounded p-button-danger p-button-text pull-right height-button">
                        </button> -->
                        <!-- <i tooltipPosition="top" 
                            pTooltip="{{ 'reset_password_tooltip_message' | translate }}" tooltipStyleClass="tooltip-no-wrap"
                            (click)="onClickResetPw()" class="{{(ValidEmail) ? 'fal fa-key pull-right p-button-danger cursor-hand p-pt-2' : 'fal fa-key pull-right p-button-danger cursor-hand p-pt-2 notAllowed low-opacity'}}" tooltipPosition="top">
                        </i> -->
                        <i tooltipPosition="top" pTooltip="{{'company-setup.Copy-URL-to-Clipboard' | translate}}"
                            class="{{(ValidEmail) ? 'fal fa-copy pull-right ic-green cursor-hand p-pt-2 p-pr-3' : 'fal fa-copy pull-right ic-green cursor-hand p-pt-2 p-pr-2 notAllowed low-opacity'}}"
                            (click)="copyClipBoard(userinput)">
                        </i>
                        <i tooltipPosition="top" pTooltip="{{'email_client' | translate}}"
                            class="{{(ValidEmail) ? 'fal fa-envelope pull-right ic-red cursor-hand p-pt-2 p-pr-3' : 'fal fa-envelope pull-right ic-red cursor-hand p-pt-2 p-pr-2 notAllowed low-opacity'}}"
                            (click)="sendEmailToClient();"></i>
                        <input type="text" #userinput formControlName="Email" pInputText autocomplete="nope">
                        <small
                                *ngIf="clientForm?.get('Email')?.hasError('email')"
                                class="text-danger">
                                <p-message severity="error" text="Email should be valid."></p-message>
                        </small>
                    </div>
                </div>
                <div class="p-field p-col-12">
                    <label class="label-text">{{ 'website' | translate }}</label>
                    <input type="text" formControlName="Website" pInputText autocomplete="nope">
                </div>
            </div>
        </div>
        <div class="p-col-4" [ngClass]="(auth.isAllowAccess(3))? '' : 'notAllowedCursor'">
            <div class="card p-mt-2 p-pb-5" [ngClass]="(auth.isAllowAccess(3))? '' : 'wrapper'">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6" *ngIf="!clientForm.get('ContactRecord').value">
                        <label class="p-d-block label-text">{{ 'Billing_Partner' | translate }}</label>
                        <p-dropdown [options]="staffList" formControlName="BillingPartnerID"
                            (onChange)="clientForm.markAsDirty();"
                            [placeholder]="clientForm.get('BillingPartnerID')?.value ? getStaffName(clientForm.get('BillingPartnerID').value) : 'Select Billing Partner'">
                        </p-dropdown>
                    </div>
                    <div class="p-field p-col-6" *ngIf="!clientForm.get('ContactRecord').value">
                        <label class="p-d-block label-text">{{ 'Originating_Partner' | translate }}</label>
                        <p-dropdown [options]="staffListWithInactive" formControlName="OriginatingPartnerID"
                            (onChange)="clientForm.markAsDirty();"
                            [placeholder]="clientForm.get('OriginatingPartnerID')?.value ? getStaffName(clientForm.get('OriginatingPartnerID').value) : 'Select Originating Partner'">
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6" *ngIf="!clientForm.get('ContactRecord').value">
                        <label class="p-d-block label-text">{{ 'staff-assigned' | translate }}</label>
                        <p-dropdown [options]="staffList" formControlName="StaffAssignedID" (onChange)="clientForm.markAsDirty();"
                            [placeholder]="clientForm.get('StaffAssignedID')?.value ? getStaffName(clientForm.get('StaffAssignedID').value) : 'Select Staff'">
                        </p-dropdown>
                    </div>
                    <div class="p-field p-col-6" *ngIf="!clientForm.get('ContactRecord').value">
                        <label class="p-d-block label-text">{{ 'client.type' | translate }}</label>
                        <p-dropdown [options]="clientType" formControlName="ClientTypeID"
                            placeholder="Select ClientType" (onChange)="clientForm.markAsDirty();">
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-field p-col-12">
                    <label class="p-d-block label-text">{{ 'billing-group-multiple' | translate}}
                        <i pTooltip="{{ 'client.billing-group' | translate }}"
                            tooltipStyleClass="tooltip-width-300"
                            class="fa fa-question-circle ic-blue question-tooltip-icon" tooltipPosition="right"></i>
                    </label>
                    <p-dropdown [options]="billingGroupCategory" (onChange)="clientForm.markAsDirty();" formControlName="BillingGroupID"
                        placeholder="Select Billing Group">
                    </p-dropdown>
                </div>
                <div class="p-field p-col-12" *ngIf="!clientForm.get('ContactRecord').value">
                    <label class="p-d-block label-text">{{ 'client-group-only-one' | translate}}
                        <i pTooltip="{{ 'client.client-group' | translate }}"
                            tooltipStyleClass="tooltip-width-300"
                            class="fa fa-question-circle ic-blue question-tooltip-icon" tooltipPosition="right"></i>
                    </label>
                    <p-multiSelect [options]="clientGroupCategory" formControlName="GroupDescriptionIDArray">
                    </p-multiSelect>
                </div>
                <div class="p-field p-col-12" *ngIf="!clientForm.get('ContactRecord').value">
                    <label class="label-text">{{ 'referred_by' | translate }}</label>
                    <input type="text" formControlName="ReferredBy" pInputText autocomplete="nope">
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.date_created' | translate }}</label>
                        <p-calendar formControlName="DateCreated" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2090" readonly></p-calendar>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.date_inactive' | translate }}</label>
                        <p-calendar formControlName="DateInactive" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2090" readonly></p-calendar>
                    </div>
                </div>

                <div class="p-formgroup-inline p-mt-4">
                    <div class="p-field-checkbox p-col-4 p-mr-0" *ngIf="!clientForm.get('ContactRecord').value">
                        <p-checkbox formControlName="Billable" binary="true" name="Billable" id="Billable"
                            [disabled]="clientForm.get('ContactRecord').value"></p-checkbox>
                        <label class="p-mt-1" for="ch">{{ 'billable' | translate }}</label>
                        <span pTooltip="{{ 'client_billable_tooltip_message' | translate }}" tooltipPosition="top">
                            <i class="fa fa-question-circle ic-blue"></i>
                        </span>
                    </div>
                    <div class="p-field-checkbox p-col-4 p-mr-0">
                        <p-checkbox formControlName="ContactRecord" binary="true" name="ContactRecord"
                            id="ContactRecord" (onChange)="changeClientActiveState()"></p-checkbox>
                        <label class="p-mt-1" for="ch">{{ 'client.contact' | translate }}</label>
                        <span pTooltip="{{ 'mark_record_tooltip_message' | translate }}" tooltipPosition="top">
                            <i class="fa fa-question-circle ic-blue"></i>
                        </span>
                    </div>
                    <div class="p-field-checkbox p-col-4 p-mr-0" *ngIf="!clientForm.get('ContactRecord').value">
                        <p-checkbox formControlName="Inactive" binary="true" name="Inactive" id="Inactive"
                            [disabled]="clientForm.get('ContactRecord').value" (onChange)="clientInactiveClick()">
                        </p-checkbox>
                        <label class="p-mt-1" for="ch">{{ 'inactive' | translate }}</label>
                        <span pTooltip="{{ 'mark_inactive_tooltip_message' | translate }}" tooltipPosition="top">
                            <i class="fa fa-question-circle ic-blue"></i>
                        </span>
                    </div>

                </div>
            </div>
        </div>
        <div class="p-col-12  p-mb-2" *ngIf="!clientForm.get('ContactRecord').value">
            <div class="card p-mt-3">
                <div class="p-formgroup-inline p-ml-2">
                    <div class="rGrid bg-blue">
                        {{ 'client.open_balance' | translate }}
                        <div class="price p-mb-ob">{{stat_openBalance | roundoff | currency:'USD':'symbol':'1.0-0'}}
                        </div>
                        <img class="summary-box-graph-payments" src="assets/mango/img/graph-checkins.svg"
                            alt="Welcome to Mango Practice Management" />
                    </div>
                    <div class="rGrid bg-blue">
                        {{ 'balance' | translate }} > 30
                        <div class="price p-mb-ob">{{stat_bal_over30 | roundoff | currency:'USD':'symbol':'1.0-0'}}
                        </div>
                        <img class="summary-box-graph-payments" src="assets/mango/img/graph-checkins.svg" />
                    </div>
                    <div class="rGrid bg-green">
                        {{ 'client.revenue' | translate }} - (CY)
                        <div class="price p-mb-revenue">{{stat_revenueCy | roundoff | currency:'USD':'symbol':'1.0-0'}}
                        </div>
                        <img alt="Revenue" class="summary-box-graph-payments"
                            src="assets/mango/img/graph-checkins.svg" />
                    </div>
                    <div class="rGrid bg-green">
                        {{ 'client.revenue' | translate }} - (PY)
                        <div class="price p-mb-revenue">{{stat_revenuePy | roundoff | currency:'USD':'symbol':'1.0-0'}}
                        </div>
                        <img alt="Revenue" class="summary-box-graph-payments"
                            src="assets/mango/img/graph-checkins.svg" />
                    </div>
                    <div class="rGrid bg-orange">
                        {{ 'client.payments' | translate }} (CY)
                        <div class="price p-mb-payments">{{stat_paymentsCy | roundoff |
                            currency:'USD':'symbol':'1.0-0'}}</div>
                        <img alt="Revenue" class="summary-box-graph-payments"
                            src="assets/mango/img/graph-checkins.svg" />
                    </div>
                    <div class="rGrid bg-orange">
                        {{ 'client.payments' | translate }} (PY)
                        <div class="price p-mb-payments">{{stat_paymentsPy | roundoff |
                            currency:'USD':'symbol':'1.0-0'}}</div>
                        <img alt="Revenue" class="summary-box-graph-payments"
                            src="assets/mango/img/graph-checkins.svg" />
                    </div>
                    <div class="rGrid bg-purple">
                        {{ 'client.unbilled_time' | translate }}
                        <div class="price p-mb-payments">{{stat_unbilledTime | roundoff |
                            currency:'USD':'symbol':'1.0-0'}}</div>
                        <img alt="Revenue" class="summary-box-graph-payments"
                            src="assets/mango/img/graph-checkins.svg" />
                    </div>
                    <div class="rGrid bg-purple p-px-0">
                        <span>{{ 'client.unbilled_expenses' | translate }}</span>
                        <div class="price p-mb-payments">{{stat_unbilledExp | roundoff |
                            currency:'USD':'symbol':'1.0-0'}}</div>
                        <img alt="Revenue" class="summary-box-graph-payments"
                            src="assets/mango/img/graph-checkins.svg" />
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="p-field p-col-4">
                <label class="p-d-block">&nbsp;</label>
                <p-checkbox formControlName="ContactRecord" [binary]="true" label="My Contact"></p-checkbox>
            </div> -->
    </div>
    <!-- ScratchPad sidebar -->
    <p-sidebar [(visible)]="scratchPadEnabled" position="bottom"
        [style]="{'min-height':'522px','background':'#8e8f92'}">
        <div class="card">
            <div class="control-label header-color text-nowrap p-col-12">
                <h4 class="label-text">{{ 'scratch_pad' | translate }}</h4>
            </div>
            <div class="p-col-12 col-md-12">
                <ejs-richtexteditor id='clientEditor' formControlName="ScratchPad" #clientEditor [(value)]="editorValue"
                    [toolbarSettings]='tools' [height]="400">
                </ejs-richtexteditor>
            </div>
        </div>
    </p-sidebar>
    <!-- User Defined Fields -->
    <p-sidebar [(visible)]="userDefinedFieldsEnabled" position="right"
        [style]="{'width':'60%','background':'#8e8f92'}" (onHide)="closeUserDefinedFields()">
        <div class="card" style="height: 95vh">
            <div class="header-color text-nowrap col-sm-9">
                <h4 class="label-text">{{ 'user.user_defined_fields' | translate }}</h4>
            </div>
            <div class="p-mx-3 p-grid">
                <div class="p-col-12">
                    <div class="p-col-12">
                        <label class="label-text">{{ 'dm.Template_Name' | translate }}</label>
                    </div>
                    <div class="p-col-12 p-formgroup-inline">
                        <p-dropdown [ngModelOptions]="{ standalone: true }" [options]="customTemplatesCompanyOptions" [style]="{width: '200px'}" 
                            placeholder="{{ 'Select Company Template' | translate }}" class="p-ml-2" (onChange)="customTableCompanyTemplateChange($event)"
                            [(ngModel)]="customTableClientObj['CustomTablesCompanyIDSelected']" scrollHeight="400px" styleClass="width-120p">
                        </p-dropdown>
                        <button pButton pRipple label="{{ 'Reset' | translate }}" icon="fal fa-undo" [disabled]="!customTableClientObj['OverRide'] || !customTableClientObj['CustomTablesCompanyID']" class="p-ml-3 p-button-sm"
                            (click)="resetCustomTableClient()"></button>
                        <button pButton pRipple label="{{ 'Delete' | translate }}" icon="fal fa-trash-alt" [disabled]="!customTableClientObj['OverRide'] || !customTableClientObj['CustomTablesCompanyID']" class="p-ml-3 p-button-sm"
                            (click)="deleteCustomTableClient()"></button>
                        <p-checkbox *ngIf="customTableClientObj['CustomTablesClientID'] && customTableClientObj['CustomTablesCompanyID']" [(ngModel)]="customTableClientObj['OverRide']" binary="true" class="p-ml-3 p-mt-1 label-text" (ngModelChange)="customTableClientObj['IsChanged'] = true"
                            [ngModelOptions]="{standalone: true}" label="{{ 'override_custom_table' | translate }}">
                        </p-checkbox>
                    </div> 
                </div>
            </div>
            <div style="max-height: 40vh;" class="p-fluid p-formgrid p-grid p-mx-3 p-mt-3" *ngIf="customTableClientObj['CustomTablesClientID']">
                <div class="p-col-4">
                    <div class="p-col-12 manual" style="width: 50vw;">
                        <p-panel header="{{ 'description' | translate }} from {{ customTableClientObj['OverRide']? 'Client': 'Firm'}}" [toggleable]="true">
                            <p-table #customTableMatrix [value]="customTableClientObj['ValueMatrix']" [rowHover]="true" [responsive]="true" [rows]="10"
                                dataKey="index" selectionMode="single" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" 
                                [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" [resizableColumns]="true"[sortOrder]="1" editMode="row">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-3p p-text-center">{{ 'actions' | translate }}</th>
                                        <th class="width-10p p-text-left">{{ 'description' | translate }}</th>
                                        <!-- <th class="width-10p p-text-left"  *ngIf="customTableClientObj['OverRide']">{{ 'Description' | translate }}</th> -->
                                        <th class="width-10p p-text-left">{{ 'Value' | translate }}</th>
                                        <th class="width-5p p-text-right"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-editing="editing" let-rowData let-i="rowIndex">
                                    <tr class="cursor-hand" [pEditableRow]="rowData" *ngIf="showClientDefinedRow(rowData, customTableClientObj['CustomTablesCompanyIDSelected'], customTableClientObj['OverRide'])">
                                        <td class="width-3p p-text-center">
                                            <span><i [ngClass]="{'notAllowed': !customTableClientObj['OverRide'], '': customTableClientObj['OverRide']}" class="fal fa-trash-alt ic-red ic-size-15" (click)="deleteRowCustomTableClient(rowData, i)"
                                                    tooltipPosition="top" pTooltip="{{'mgmt-delete' | translate}}"></i></span>
                                            <span [ngClass]="{'notAllowed': !customTableClientObj['OverRide'], '': customTableClientObj['OverRide']}" *ngIf="customTableClientObj['Value'].length - 1 == i">
                                                <i class="fal fa-plus ic-blue p-ml-3 ic-size-15 cursor-hand" aria-hidden="true" (click)="addRowCustomTableClient()"
                                                    pTooltip="Add New" tooltipPosition="top" showDelay="800"></i>
                                            </span>
                                        </td>
                                        <td *ngIf="!customTableClientObj['OverRide']" class="width-10p p-text-left" pInitEditableRow (click)="onRowEditInit1(rowData['index'], customTableMatrix)" [ngClass]="(rowData['FirmDescription']) === ''?'ic-green':''">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText class="width-80p" type="text" [disabled]="true" [ngModelOptions]="{ standalone: true }"  
                                                        [(ngModel)]="rowData['FirmDescription']"
                                                        autocomplete="nope" >
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ rowData.FirmDescription === ''? '(No Description)' : rowData.FirmDescription }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td  *ngIf="customTableClientObj['OverRide']" class="width-10p p-text-left" pInitEditableRow (click)="onRowEditInit1(rowData['index'], customTableMatrix, 'desc')"  [ngClass]="(rowData['Description']) === ''?'ic-green':''">
                                            <p-cellEditor> 
                                                <ng-template pTemplate="input">
                                                    <input pInputText class="width-80p custom-table-desc-{{i}}" type="text" [ngModelOptions]="{ standalone: true }"  
                                                        [(ngModel)]="rowData['Description']" (ngModelChange)="changeMatrix('Description', rowData);" 
                                                        autocomplete="nope" placeholder="Enter Description">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ rowData.Description === ''? '(No Description)' : rowData.Description }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td class="width-10p p-text-left" pInitEditableRow (click)="onRowEditInit1(rowData['index'], customTableMatrix, 'value')"  [ngClass]="(rowData['Value']) === ''?'ic-green':''">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText class="width-80p custom-table-value-{{i}}" type="text" [ngModelOptions]="{ standalone: true }"  
                                                        [(ngModel)]="rowData['Value']" (ngModelChange)="changeMatrix('Value', rowData);" 
                                                        placeholder="Enter Value"
                                                        autocomplete="nope" >
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ rowData.Value === ''? '(No Value)' : rowData.Value }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td class="width-5p p-text-center">
                                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow (click)="customTableClientObj['last_index'] = null"
                                                icon="pi pi-check" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                            <!-- <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow
                                                icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button> -->
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4">
                                            <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                                styleClass="p-px-2"></p-message>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>
                    </div>
                    <!-- <div *ngIf="!customTableClientObj['OverRide']" >
                        <div class="p-field p-col-12" *ngFor="let item of customTableClientObj['FirmDescription']; let i = index; trackBy:trackByFn">
                            <input pInputText type="text" class="width-80p" [disabled]="true" value="{{item}}" autocomplete="nope" >
                        </div>
                    </div>
                    <div *ngIf="customTableClientObj['OverRide'] == true">
                        <div class="p-field p-col-12" *ngFor="let item of customTableClientObj['Description']; let i = index; trackBy:trackByFn">
                            <input pInputText class="width-80p" type="text" [ngModelOptions]="{ standalone: true }"  [(ngModel)]="customTableClientObj['Description'][i]" (ngModelChange)="customTableClientObj['IsChanged'] = true" autocomplete="nope" >
                        </div>
                    </div> -->
                    <!-- <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User2Descr" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User3Descr" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User4Descr" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User5Descr" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User6Descr" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User7Descr" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User8Descr" autocomplete="nope">
                    </div> -->
                </div>
                <!-- <div class="p-col-4"> -->
                    <!-- <div class="p-field p-col-12">
                        <label class="label-text">{{ 'Value' | translate }}</label>
                    </div>

                    <div class="p-field p-col-12" *ngFor="let item of customTableClientObj['Value']; let i = index; trackBy:trackByFn">
                        <input pInputText type="text" class="width-80p" [ngModelOptions]="{ standalone: true }" [(ngModel)]="customTableClientObj['Value'][i]" (ngModelChange)="customTableClientObj['IsChanged'] = true" autocomplete="nope">
                        <span *ngIf="customTableClientObj['OverRide'] == true">
                            <i class="fal fa-trash-alt ic-red p-ml-3 ic-size-15 cursor-hand" aria-hidden="true" (click)="deleteRowCustomTableClient(i)"
                                pTooltip="Delete" tooltipPosition="top" showDelay="800"></i>
                        </span>
                        <span *ngIf="customTableClientObj['OverRide'] == true && customTableClientObj['Value'].length - 1 == i">
                            <i class="fal fa-plus ic-blue p-ml-3 ic-size-15 cursor-hand" aria-hidden="true" (click)="addRowCustomTableClient()"
                                pTooltip="Delete" tooltipPosition="top" showDelay="800"></i>
                        </span>
                    </div> -->
                    <!-- <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User1Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User2Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User3Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User4Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User5Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User6Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User7Data" autocomplete="nope">
                    </div>
                    <div class="p-field p-col-12">
                        <input pInputText type="text" formControlName="User8Data" autocomplete="nope">
                    </div> -->
                <!-- </div> -->
            </div>
        </div>
    </p-sidebar>
    <!-- GMap -->
    <p-sidebar [(visible)]="showGMap" position="bottom" (onHide)="showGMap = false;"
        [style]="{'min-height':'600px','background':'#8e8f92'}">
        <div class="card">
            <div class="header-color text-nowrap col-sm-9">
                <h4 class="label-text">{{ 'user.user_address' | translate }}</h4>
            </div>
            <div class="p-fluid p-formgrid p-grid p-mx-2">
                <div class="p-col-12">
                    <p-gmap [options]="gMapOptions" [overlays]="overlays" (onMapReady)="setMap($event)"
                        [style]="{'width':'100%','height':'500px'}"></p-gmap>
                </div>
            </div>
        </div>
    </p-sidebar>
    <!-- Address -->
    <p-sidebar [(visible)]="showRecordsSideBar" position="right" [styleClass]="applyOverlay()"
        [style]="{'min-width':'85%','height':'95%','background':'#8e8f92','overflow': 'auto'}">
        <div class="card" style="min-height: 91vh;">
            <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
                <div class="p-col-12 p-pr-0 manual">
                    <p-panel [toggleable]="true" header="{{ 'client.billing_address' | translate }}">
                        <p-table [value]="clientAddresses" [responsive]="true" [rows]="10" [rowHover]="true"
                            dataKey="TaskRow" [virtualRowHeight]="34" [resizableColumns]="true" metaKeySelection="true"
                            editMode="row"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [scrollable]="true" [scrollHeight]="'calc(85vh - 200px)'" [(selection)]="selectedItems" #dt>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-7p p-text-center"
                                        pTooltip="{{ 'Default.Billing_Address' | translate }}" showDelay="800"
                                        tooltipPosition="top">
                                        {{'client.billing' | translate}}
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                    <th class="width-7p p-text-left">
                                        {{'Actions' | translate}}
                                    </th>
                                    <th class="width-20p p-text-left">
                                        {{'description' | translate}}
                                    </th>
                                    <th class="width-25p p-text-left">
                                        {{'Street1' | translate}}
                                    </th>
                                    <th class="width-20p p-text-left">
                                        {{'Street2' | translate}}
                                    </th>
                                    <th class="width-15p p-text-left">
                                        {{'client.city' | translate}}
                                    </th>
                                    <th class="width-7p p-text-left">
                                        {{'client.state' | translate}}
                                    </th>
                                    <th class="width-7p p-text-left">
                                        {{'client.zip' | translate}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-editing="editing">
                                <tr [pReorderableRow]="rowIndex" [pEditableRow]="rowData">
                                    <td class="width-7p p-text-center">
                                        <p-checkbox [(ngModel)]="rowData.isBillingAddress"
                                            [ngModelOptions]="{ standalone: true }" binary="true" showDelay="800"
                                            (onChange)="onChecked($event,rowData,rowIndex);"
                                            [disabled]="rowData.isBillingAddress" name="itemIsBillingAddress"
                                            id="itemIsBillingAddress" #itemIsBillingAddress="ngModel">
                                        </p-checkbox>
                                    </td>
                                    <td class="width-7p p-text-left">
                                        <span>
                                            <i class="fal fa-trash-alt p-pr-2 cursor-hand ic-red"
                                                pTooltip="{{ 'Delete-Row' | translate }}" showDelay="800"
                                                (click)="deleteRow($event,rowData)" tooltipPosition="top"></i>
                                        </span>
                                        <span>
                                            <i class="fal fa-save ic-blue p-pr-2 cursor-hand" showDelay="800"
                                                pTooltip="{{'save_button' | translate}}"
                                                (click)="onRowEditSave($event, rowData)" *ngIf="rowData['IsRowEditing']"
                                                tooltipPosition="top"></i>
                                        </span>
                                        <span>
                                            <i class="fal fa-plus cursor-hand"
                                                pTooltip="{{ 'add_new_row' | translate }}" showDelay="800"
                                                (click)="addRow(null, null, dt)"
                                                *ngIf="((clientAddresses.length-1) == rowIndex && rowData.CustomerAddressID)"
                                                tooltipPosition="top"></i>
                                        </span>

                                    </td>
                                    <td style="overflow: visible;"
                                        [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
                                        pInitEditableRow [tooltipDisabled]="rowData.IsRowEditing"
                                        (click)="onRowEditInit(rowData)"
                                        class="width-20p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                        pTooltip="{{rowData['LabelDescription']}}" tooltipPosition="top" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData['LabelDescription']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select();"
                                                    (ngModelChange)="onDetectChange(rowData)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['LabelDescription']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pInitEditableRow
                                        class="width-25p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                        [tooltipDisabled]="rowData.IsRowEditing" (click)="onRowEditInit(rowData)"
                                        pTooltip="{{rowData['Street1']}}" tooltipPosition="top" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData['Street1']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select();"
                                                    (ngModelChange)="onDetectChange(rowData)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['Street1']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pInitEditableRow
                                        class="width-20p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                        [tooltipDisabled]="rowData.IsRowEditing" (click)="onRowEditInit(rowData)"
                                        pTooltip="{{rowData['Street1']}}" tooltipPosition="top" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData['Street2']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select();"
                                                    (ngModelChange)="onDetectChange(rowData)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['Street2']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pInitEditableRow
                                        class=" width-15p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                        [tooltipDisabled]="rowData.IsRowEditing" (click)="onRowEditInit(rowData)"
                                        pTooltip="{{rowData['City']}}" tooltipPosition="top" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData['City']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select();"
                                                    (ngModelChange)="onDetectChange(rowData)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['City']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pInitEditableRow
                                        class="width-7p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                        [tooltipDisabled]="rowData.IsRowEditing" (click)="onRowEditInit(rowData)"
                                        pTooltip="{{rowData['State']}}" tooltipPosition="top" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData['State']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select();"
                                                    (ngModelChange)="onDetectChange(rowData)" maxlength="2">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['State']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pInitEditableRow
                                        class="width-7p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                        [tooltipDisabled]="rowData.IsRowEditing" (click)="onRowEditInit(rowData)"
                                        pTooltip="{{rowData['Zip']}}" tooltipPosition="top" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData['Zip']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select();"
                                                    (ngModelChange)="onDetectChange(rowData)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['Zip']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
        </div>
    </p-sidebar>
</form>

<p-dialog [(visible)]="showCloneDialog" [modal]="true" [style]="{width: '35vw'}" [responsive]="true"
    (onHide)="showCloneDialog = false" [draggable]="true" [resizable]="false" showEffect="fade" [baseZIndex]="1"
    [maximizable]="false" header="{{ 'clone_client' | translate }}">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-field">
            <label class="label-text required-field">{{ 'name' | translate }}</label>
            <input type="text" pInputText [(ngModel)]="cloneClientName" maxlength="100" autocomplete="off">
        </div>
    </div>
    <p-footer>
        <button pButton label="{{'create' | translate}}" (click)="cloneClient();showCloneDialog = false;"
            [disabled]="!cloneClientName"></button>
        <button pButton label="{{'cancel_button' | translate}}" (click)="showCloneDialog = false"></button>
    </p-footer>
</p-dialog>