import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils
} from "@app/_services";
import { environment } from "@environments/environment";
import { Table } from "primeng/table";
import * as AWS from "aws-sdk";
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: "app-imports",
  templateUrl: "./imports.component.html",
})
export class ImportsComponent implements OnInit, AfterViewInit {
  public importList: any;
  @ViewChild("fileUpload") fileUpload: any;
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  public csvData: any[] = [];
  public csvItem: any = {};
  public selectedFileType: any;
  public showFileUploaded: boolean = false;
  public busy: Promise<any>;
  public cols: any[];
  showAttachedFiles;
  files: File[] = [];
  public awsBucket: any;
  public companyId;
  public selectedType: any = null;
  public superAdminVisible: number[] = AppConstants.superAdminVisible;
  public isShowErrorLogSidebar: boolean = false;
  public csvErrorLogs = [];
  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private mangoUtils: mangoUtils,
    public translate: TranslateService
  ) {
    this.companyId = parseInt(
      this.encrDecSrvc.getObject(AppConstants.companyID)
    );

    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems(
        [
          {
            label: this.translate.instant('Imports')
          },
          {
            label: this.translate.instant('Clients'),
            icon: 'ic-red'
          },
        ]
      );

      this.importList = [
        {
          label: this.translate.instant("Clients"),
          value: "C-Mango-Billing",
          fileName: 'Mango Billing Client Import Worksheet.csv'
        },

        {
          label: this.translate.instant("invoices"),
          value: "OI-Mango-Billing",
          fileName: 'Mango Billing Open Invoice Import Worksheet.csv'
        },

        {
          label: this.translate.instant("client.advpayments"),
          value: "PM-Mango-Billing",
          fileName: 'Mango Billing Payments Import Worksheet.csv'
        },

        {
          label: this.translate.instant("Open-Time"),
          value: "OT-Mango-Billing",
          fileName: 'Mango Billing Open Time Expenses Import Worksheet.csv'
        },

        /*
        {
          label: this.translate.instant("clients-update"),
          value: "CU-Mango-Billing",
          fileName: 'Mango Billing Update Client Import Worksheet.csv'
        },
        */

        {
          label: this.translate.instant("QuickLinks.client-notes"),
          value: "CN-Mango-Billing",
          fileName: 'Mango Billing Client Notes Import Worksheet.csv'
        },

        {
          label: this.translate.instant("IT_Payment"),
          value: "PMIT-Mango-Billing",
          fileName: 'Mango Billing Payments and Invoices IT Import Worksheet.csv'
        },

        {
          label: this.translate.instant("IT_Payment_Details"),
          value: "PDIT-Mango-Billing",
          fileName: 'Mango Billing Payment Details IT Import Worksheet.csv'
        },

        {
          label: this.translate.instant("Engagement_Budgets"),
          value: "EB-Mango-Billing-Engagement-Budget",
          fileName: 'Mango Billing Engagement Budget Import.csv'
        },

        /*
        {
          label: this.translate.instant("Users"),
          value: "Staff"
        },

        {
          label: this.translate.instant("imports.Activity_Codes"),
          value: "Activity"
        },

        {
          label: this.translate.instant("Expense_Codes"),
          value: "Expense"
        },

        {
          label: this.translate.instant("Company_Groups"),
          value: "Company"
        },

        {
          label: this.translate.instant("Client_Types"),
          value: "Client"
        },
        */
      ];

      /*
      if(this.superAdminVisible.includes(this.companyId)) {
        this.importList = [
          ...this.importList,
          {
            label: this.translate.instant("IT_Payment"),
            value: "PMIT-Mango-Billing",
            fileName: 'Mango Billing Payments and Invoices IT Import Worksheet.csv'
          },

          {
            label: this.translate.instant("IT_Payment_Details"),
            value: "PDIT-Mango-Billing",
            fileName: 'Mango Billing Payment Details IT Import Worksheet.csv'
          },
        ]
      }

      this.selectedType = this.importList[0].value;
      */
    })

    this.setAWSOBject();

    this.searchTextStr = this.encrDecSrvc.getObject(
      "importOpenInvoices_" + AppConstants.SearchString
    );

    //this.reloadListing(this.selectedType);
  }

  ngOnInit(): void {

  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      "importOpenInvoices_" + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(
      "importOpenInvoices_" + AppConstants.SearchString,
      ""
    );
  }

  selectType(opt) {
    const importType = this.importList.filter((type: any) => type.value === opt.value);
    this.selectedFileType = importType[0]['fileName'];
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Imports') },
        { label: importType[0].label, icon: "ic-red" },
      ]);
    })
    this.reloadListing(this.selectedType);
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = environment.AWS_REGION;
    this.awsBucket = new AWS.S3({
      params: { Bucket: environment.AWS_BUCKET_NAME },
    });
    */
    this.awsBucket = (
      AWSBucketMask
    );
  }

  showFiles() {
    this.showAttachedFiles = !this.showAttachedFiles;
  }

  removeCSV(csvItem) {
    this.csvItem = csvItem;

    Swal.fire({
      title: this.translate.instant("confirmation"),
      text: this.translate.instant('imports.Do_you_really_want_to_delete_file') + `: ${csvItem.FileName}?`,
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: this.translate.instant("yes_delete"),
      cancelButtonText: this.translate.instant("no_cancel"),
    })
    .then((result) => {
      if (result.value) {
        this.confirmRemoveCSV();
      }
    });
  }

  confirmRemoveCSV() {
    const self = this;
    const csvItem = self.csvItem;

    self.mangoAPISrvc.removeCSV(csvItem.CustomerCSVID)
    .subscribe(
      (data: any) => {
        self.mangoAPISrvc.notify(
          "success",
          self.translate.instant("Success_notify"),
          AppConstants.deleteMessage
        );
        self.reloadListing(self.selectedType);
      },
      (response) => {
        //let data = response.json();
        self.mangoAPISrvc.notify(
          "error",
          self.translate.instant("error"),
          AppConstants.deleteErrorMsg
        );
      }
    );
  }

  fileEvent(event) {
    const self = this;
    const listOfAcceptedFiles = ["csv"].toString();

    const filePos = event.files[0].name.lastIndexOf(".");

    const fileType = event.files[0].name.substring(
      filePos + 1,
      event.files[0].name.length
    );

    if (self.selectedType == 'C-Mango-Billing') {
      if (event.files[0].name.indexOf('Client') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }
    else if (self.selectedType == 'OI-Mango-Billing') {
      if (event.files[0].name.indexOf('Invoice') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }
    else if (self.selectedType == 'OT-Mango-Billing') {
      if (event.files[0].name.indexOf('Time') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }
    else if (self.selectedType == 'CU-Mango-Billing') {
      if (event.files[0].name.indexOf('Update') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }
    else if (self.selectedType == 'CN-Mango-Billing') {
      if (event.files[0].name.indexOf('Notes') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }

    else if (self.selectedType == 'PM-Mango-Billing') {
      if (event.files[0].name.indexOf('Payments') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }

    else if (self.selectedType == 'PMIT-Mango-Billing') {
      if (event.files[0].name.indexOf('Payments and Invoices') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }

    else if (self.selectedType == 'PDIT-Mango-Billing') {
      if (event.files[0].name.indexOf('Payment Details') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }

    else if (self.selectedType == 'EB-Mango-Billing-Engagement-Budget') {
      if (event.files[0].name.indexOf('Engagement Budget') == -1) {
        Swal.fire({
          icon: "error",
          title: self.translate.instant("error"),
          text: self.translate.instant("imports.The_uploaded_file_is_not_correct"),
          showConfirmButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: self.translate.instant("ok"),
          timer: 4000,
        });
        this.fileUpload.clear()
        return false;
      }
    }

    //size in mb - Mond - replaced from 1mb to 3mb
    const fileSize = event.files[0].size / 3145728;

    if (
      event.files &&
      listOfAcceptedFiles.indexOf(fileType) > -1 &&
      fileSize <= 30
    ) {
      this.files.push(...event.files);
      //this.file = event.addedFiles[0];
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index];
        setTimeout(() => {
          this.uploadFile(file);
        }, 200);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: this.translate.instant("warning"),
        text: this.translate.instant("imports.Accepted_File_is_CSV_only"),
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant("ok"),
        timer: 2000,
      });
      this.fileUpload.clear()
    }
  }

  async uploadFile(fileObj) {
    const self = this;

    //let defaultObj = { "FName": "", "UniqueName": "", "Size": "", "FileType": "" };

    const UniqueName = this.mangoUtils.generateUUID() + "_" + fileObj.name;

    const filePos = fileObj.name.lastIndexOf(".");
    const fileType = fileObj.name.substring(filePos + 1, fileObj.name.length);

    const params = {
      Bucket: environment.AWS_BUCKET_NAME,
      Key: "documents/" + UniqueName,
      // ACL: "public-read",
      Body: fileObj,
    };

    self.mangoAPISrvc.showLoader(true);

    self.awsBucket.upload(
      params,

      function (err, data) {
        if (err) {
          console.log("error while saving file on s3 server", err);
          self.fileUpload.clear()
          self.mangoAPISrvc.showLoader(false)
          return;
        }
        self.onRemove(fileObj);
        setTimeout(() => {
          let uploadType: string = "";
          switch (self.selectedType) {
            case 'C-Mango-Billing':
              uploadType = 'C'
              break;
            case 'OI-Mango-Billing':
              uploadType = 'OI'
              break;
            case 'OT-Mango-Billing':
              uploadType = 'OT'
              break;
            case 'Staff':
              uploadType = 'Staff'
              break;
            case 'CU-Mango-Billing':
              uploadType = 'CU'
              break;
            case 'CN-Mango-Billing':
              uploadType = 'CN'
              break;
            case 'PM-Mango-Billing':
              uploadType = 'PM'
              break;
            case 'PMIT-Mango-Billing':
              uploadType = 'PMIT'
              break;
            case 'PDIT-Mango-Billing':
              uploadType = 'PDIT'
              break;
            case 'EB-Mango-Billing-Engagement-Budget':
              uploadType = 'EB'
              break;

            //todo - No API for these values yet
            /*
            case 'Activity':
              uploadType = 'Activity'
              break;
            case 'Expense':
              uploadType = 'Expense'
              break;
            case 'Company':
              uploadType = 'Company'
              break;
            case 'Client':
              uploadType = 'Client'
              break;
            */

            default:
              break;
          }

          const formData = new FormData();
          formData.append("csvFile", fileObj);

          self.mangoAPISrvc.uploadCSV(formData, uploadType)
          .subscribe((data) => {
            self.mangoAPISrvc.notify(
              "success",
              self.translate.instant("Success_notify"),
              self.translate.instant("imports.file_has_been_uploaded_successfully")
            )

            self.reloadListing(self.selectedType);
            self.fileUpload.clear()
          }, (data) => {
            self.fileUpload.clear()
            self.mangoAPISrvc.notify('error', self.translate.instant('error'), data.message);
          });

          self.showFiles();

          self.mangoAPISrvc.showLoader(false);
        }, 200);
      },

      self.mangoAPISrvc
    );
  }

  reloadListing(prefix: string) {
    const self = this;

    self.mangoAPISrvc.showLoader( true );

    self.mangoAPISrvc.getCSVList( )
    .subscribe(
      (results: any) => {
        self.csvData = (
          results.filter(
            ( item ) => (
              item.FileName.indexOf( prefix ) > 1
            )
          )
        );

        self.mangoAPISrvc.showLoader( false );
      }
    );
  }

  refreshList( ){
    this.reloadListing( this.selectedType );
  }

  refreshCSV( customerCSVReference: string ){
    this.mangoAPISrvc.showLoader( true );

    this.mangoAPISrvc.getCSVImportData( customerCSVReference )
    .subscribe(
      ( csvImportData: any ) => {
        this.csvData.forEach(
          function( csvItemData ){
            if(
                    csvImportData.CustomerCSVID
                === csvItemData.CustomerCSVID
            ){
              Object.assign(
                csvItemData,
                csvImportData
              );
            }
          }
        );

        this.mangoAPISrvc.showLoader( false );
      }
    );
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  refreshCache() {
		const self = this;

    self.encrDecSrvc.clientList = [];
		self.encrDecSrvc.activitiesList = [];
		self.encrDecSrvc.companyData = [];
		self.encrDecSrvc.termsList = [];

    const staffId = this.encrDecSrvc.getObject(AppConstants.staffID);

    self.mangoAPISrvc.showLoader(true);

    self.encrDecSrvc.removeObject(AppConstants.clientCount);
		self.encrDecSrvc.removeObject(AppConstants.clientGroupCategory);
		self.encrDecSrvc.removeObject(AppConstants.staffList);
		self.encrDecSrvc.removeObject(AppConstants.customerType);
		self.encrDecSrvc.removeObject(AppConstants.shotHands);
		self.encrDecSrvc.removeObject(AppConstants.timeAndExpenses);
		self.encrDecSrvc.removeObject(AppConstants.activityList);
		self.encrDecSrvc.removeObject(AppConstants.expenses);
		self.encrDecSrvc.removeObject(AppConstants.invoiceOptions);
		self.encrDecSrvc.removeObject(AppConstants.noteCategories);
		self.encrDecSrvc.removeObject(AppConstants.planInfo);
		self.encrDecSrvc.removeObject(AppConstants.userPermissionObj);
		self.encrDecSrvc.removeObject(AppConstants.dmsEmailTemplate);
		self.encrDecSrvc.removeObject(AppConstants.workLocations);
    self.encrDecSrvc.removeObject(AppConstants.userPermissions);

    self.encrDecSrvc.loadDefaultAPI();

    self.mangoAPISrvc.loadDefaultAPI(staffId)
    .subscribe((results: any) => {
			self.encrDecSrvc.addObject(AppConstants.clientCount, results[0]);
			self.encrDecSrvc.addObject(AppConstants.clientGroupCategory, results[1]);
			self.encrDecSrvc.addObject(AppConstants.staffList, results[2]);
      self.encrDecSrvc.addObject(AppConstants.allStaffList, results[14]);
			//self.encrDecSrvc.addObject(AppConstants.dmsStaffList, results[3]);
			self.encrDecSrvc.addObject(AppConstants.customerType, results[3]);
			self.encrDecSrvc.addObject(AppConstants.shotHands, results[4]);
			self.encrDecSrvc.addObject(AppConstants.timeAndExpenses, results[5]);
			self.encrDecSrvc.addObject(AppConstants.activityList, results[6]);
			self.encrDecSrvc.addObject(AppConstants.expenses, results[7]);
			self.encrDecSrvc.addObject(AppConstants.invoiceOptions, results[8]);
			self.encrDecSrvc.addObject(AppConstants.noteCategories, results[9]);
			self.encrDecSrvc.addObject(AppConstants.planInfo, results[10]);
			self.encrDecSrvc.addObject(AppConstants.userPermissionObj, results[11]);
			self.encrDecSrvc.addObject(AppConstants.dmsEmailTemplate, results[12]);
			self.encrDecSrvc.addObject(AppConstants.workLocations, results[13]);
      self.encrDecSrvc.addObject(AppConstants.userPermissions, results[15]);

			setTimeout(() => {
				self.mangoAPISrvc.showLoader(false);
			}, 100);
		});
	}

  processCSV(csvID, fileName) {
    const self = this;

    const postData = {
      CsvID: csvID,
      FileName: fileName,
    };

    self.mangoAPISrvc.showLoader(true);

    self.mangoAPISrvc.processCSV(postData, self.selectedType)
    .subscribe(
      (data) => {
        self.mangoAPISrvc.notify(
          "success",
          this.translate.instant("Success_notify"),
          this.translate.instant("imports.Your_file_is_queued_for_processing")
        );

        self.mangoAPISrvc.showLoader(false);

        //self.notificationsService.notify("success", this._translate.instant(`File "${fileName}" is queued for processing.`));

        self.reloadListing(this.selectedType);
        self.refreshCache();
      },
      (response) => {
        //let data = response.json();
        self.mangoAPISrvc.notify("error", self.translate.instant("error"), 'Error');
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  onShowErrorLogSidebar(rowData) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCsvImportLogsById(rowData.CustomerCSVID).subscribe(
      (result: any) => {
        this.mangoAPISrvc.showLoader(false);
        this.csvErrorLogs = result?.map((item) => {
          let displayData = null;
          const data = item.Data ? JSON.parse(item.Data) : null;
          if (data) {
            const keys = Object.keys(data);
            const values = Object.values(data);
            displayData = values
              .map((val, index) => {
                return {
                  key: index,
                  headerName: keys[index],
                  value: val,
                };
              })
              .filter((item) => item.value);
          }
          return {
            ...item,
            DisplayData: displayData,
            Description: `System Log ID: ${item.CsvImportLogID} - ${item?.Description?.replaceAll(
              "SequelizeDatabaseError: ",
              ""
            )}`,
            Data: data,
          }
        })
        this.isShowErrorLogSidebar = true;
      },
      () => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify("error", this.translate.instant("error"), "Error");
      }
    );
  }

  onCloseErrorLogSidebar() {
    this.isShowErrorLogSidebar = false;
    this.csvErrorLogs = []
  }
}
