<div class="engagement-list">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{ 'add' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addExpensePopUp();" tooltipPosition="top"></p-button>
            <button pButton pRipple label="{{ 'list' | translate }}" icon="fal fa-project-diagram"
                class="p-mr-1 p-button-sm" (click)="redirectListView()"></button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="expensesList" [rowHover]="true" [responsive]="true"
                        dataKey="PaymentHeaderID" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                        [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="PaymentDate" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" 
                        [globalFilterFields]="['PaymentDate','ExpenseCode','Memo','Units','Cost','Markup','Tax','StandardAmount']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)" (onEditInit)="onEditInit($event)"
                        (onEditComplete)="onEditComplete()" (onEditCancel)="onEditCancel()">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                                <th pSortableColumn="PaymentDate" pResizableColumn class="width-10p p-text-left">{{
                                    'date' | translate }}
                                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ExpenseCode" pResizableColumn class="width-20p p-text-left">{{
                                    'expense-code' | translate }}
                                    <p-sortIcon field="ExpenseCode"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-25p p-text-left">{{ 'description' | translate }}</th>
                                <th pResizableColumn class="width-8p p-text-right">{{ 'units' | translate }}</th>
                                <th pResizableColumn class="width-8p p-text-right">{{ 'Cost' | translate }}</th>
                                <th pResizableColumn class="width-8p p-text-right">{{ 'mark_up' | translate }}%</th>
                                <th pResizableColumn class="width-8p p-text-right">{{ 'tax' | translate }}%</th>
                                <th pResizableColumn class="width-8p p-text-right">{{ 'amount' | translate }}</th>
                                <th pResizableColumn class="width-5p p-text-center">{{ 'billable' | translate }}</th>
                                <th pResizableColumn class="width-5p p-text-center">{{ 'approved' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex>
                            <tr class="cursor-hand">
                                <td class="width-6p">
                                    <div class="p-text-center">
                                        <i class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                                            (click)="openExpenseEntryDailog(rowData)" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}"></i>
                                        <i (click)="deleteExpense(rowData.SlipMasterID)"
                                            class="fal fa-trash-alt ic-red p-mr-2 cursor-hand" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate}}"></i>
                                        <i class="fas fa-save ic-blue ic-size-15"
                                            (click)="saveTimeExpense($event, rowData)"
                                            *ngIf="rowData['IsColumnChanges']"
                                            pTooltip="{{ 'save_button' | translate }}" tooltipPosition="top"></i>
                                    </div>
                                </td>
                                <td class="width-10p p-text-left" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                (ngModelChange)="rowData['IsColumnChanges'] = true"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="rowData['Ddate']"
                                                appendTo="body" yearRange="2000:2099" dateFormat="mm/dd/yy">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{ mangoUtils.formatDateString(rowData['Ddate']) | date:'MM/dd/yyyy'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="width-20p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{ rowData.ExpenseCode }}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="expList" placeholder="{{ rowData.ExpenseCode }}"
                                                [(ngModel)]="rowData.ExpenseCodeID" id="ExpDescription"
                                                styleClass="width-100p" name="ExpDescription" [filter]="true"
                                                #ExpDescription="ngModel" (onChange)="handleExpenseCode($event,rowData)"
                                                appendTo="body" [style]="{'width':'200px !important'}"
                                                class="p-text-left"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData['ExpenseCode'] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-25p p-text-left p-text-nowrap p-text-truncate"
                                    [ngStyle]="{'overflow': (cellFocused && cellFocused.field === 'Memo' && cellFocused.index === rowIndex) ? 'visible' : 'hidden'}"
                                    [pEditableColumn]="rowData" [pEditableColumnField]="'Memo'"
                                    [pEditableColumnRowIndex]="rowIndex">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span>
                                                <app-mentions [rows]="1" [(inputText)]="rowData['Memo']" (inputTextChange)="rowData['IsColumnChanges']=true"
                                                    style="width:100% !important; resize: vertical;"></app-mentions>
                                            </span>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData.Memo }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-8p p-text-right">
                                    {{rowData.Units | number:'1.2-2'}}
                                </td>
                                <td (click)="openExpenseEntryDailog(rowData)" class="width-8p p-text-right">
                                    {{rowData.Cost | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td (click)="openExpenseEntryDailog(rowData)" class="width-8p p-text-right">
                                    {{rowData.Markup}}
                                </td>
                                <td (click)="openExpenseEntryDailog(rowData)" class="width-8p p-text-right">
                                    {{rowData.Tax ? rowData.Tax.replace("$ ", " ") : " "}}
                                </td>
                                <td (click)="openExpenseEntryDailog(rowData)"
                                    class="width-8p p-text-right">
                                    {{rowData.StandardAmount}}
                                </td>
                                <td class="width-5p p-text-center">
                                    <p-checkbox [(ngModel)]="rowData['Billable']" binary="true" disabled="true">
                                    </p-checkbox>
                                </td>
                                <td class="width-5p  p-text-center">
                                    <i class="fal fa-check ic-red" *ngIf="rowData[ 'Approved'] "></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="13">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>