<div class="pmsclientdetails">

    <span class="pull-right p-mr-3 clientform-btn-top">
        <p-button pRipple label="{{'dashboard' | translate}}" icon="fal fa-tachometer-alt-fastest"
            styleClass="p-button-sm" class="p-button-help p-pr-2" routerLink="/project-management/dashboard"></p-button>
        <p-button pRipple label="{{'Clients' | translate}}" icon="fal fa-users" styleClass="p-button-sm"
            class="p-button-help p-pr-2" routerLink="/project-management/clientHistory"></p-button>
        <p-button pRipple label="{{'Projects' | translate}}/{{'Tasks' | translate}} List" icon="fal fa-building" styleClass="p-button-sm"
            class="p-button-help p-pr-2" routerLink="/project-management/projectList"></p-button>
        <p-button pRipple label="{{'PM-Settings' | translate}}" icon="fal fa-cog" styleClass="p-button-sm"
            class="p-button-help p-pr-2" routerLink="/project-management/settings"></p-button>
        <p-splitButton *ngIf="isAllowCreateProject" label="{{'New-Projects' | translate}}" icon="fal fa-plus" styleClass="p-button-sm split-button-size"
            class="p-pr-2" [model]="buttonsList" (onClick)="redirectProject()" [menuStyleClass]="'new-project-button-list'">
        </p-splitButton>
    </span>

    <div class="layout-content">
        <div class="p-px-2">
            <!-- Client Details -->
            <div class="card p-mb-2 p-pb-1">
                <div class="p-grid">
                    <div class="form-group p-sm-3">
                        <label for="client">{{'client' | translate}}</label>
                        <div><strong>{{clientDetails.ClientName}}</strong></div>
                    </div>
                    <div class="form-group p-sm-3">
                        <label for="address">{{'user.user_address' | translate}}</label>
                        <div>
                            <strong>
                                <div>{{clientDetails.BusStreet1}} </div>
                                <span *ngIf="clientDetails.BusStreet2">{{clientDetails.BusStreet2}}, </span>
                                <span *ngIf="clientDetails['BusCity']">{{clientDetails['BusCity']}}, </span>
                                <span *ngIf="clientDetails['BusState']">{{clientDetails['BusState']}}, </span>
                                <span *ngIf="clientDetails['BusZip']">{{clientDetails['BusZip']}}</span>
                            </strong>
                        </div>
                    </div>
                    <div class="form-group p-sm-2">
                        <label for="address">{{'Work' | translate}}</label>
                        <div><strong>{{clientDetails.Office}}</strong></div>
                    </div>
                    <div class="form-group p-sm-2">
                        <label for="address">{{'user.mobile_phone' | translate}}</label>
                        <div><strong>{{clientDetails.Mobile}}</strong></div>
                    </div>
                    <div class="form-group p-sm-2">
                        <label for="address">{{'email' | translate}}</label>
                        <div><strong>{{clientDetails.Email}}</strong></div>
                    </div>
                </div>
            </div>

            <!-- Project Dropdowns -->
            <div class="card card p-mb-2 p-pb-0">
                <div class="p-grid">
                    <div class="p-sm-11 p-grid p-py-0">
                        <div class="p-field p-sm-2">
                            <label for="client" class="label-text">{{'project' | translate}}</label>
                            <p-dropdown [options]="projectsList" [style]="{'width':'100%'}"
                                [(ngModel)]="projectObj.TemplateName" name="itemProjectSelected" [filter]="true"
                                id="itemProjectSelected" #itemProjectSelected="ngModel"
                                [ngModelOptions]="{standalone: true}" (onChange)="refreshTable()"></p-dropdown>
                        </div>
                        <div class="p-field p-sm-2">
                            <label for="client" class="label-text">{{'Tag' | translate}}</label>
                            <p-dropdown [options]="tagsList" [style]="{'width':'100%'}" [(ngModel)]="projectObj.tagID"
                                name="itemTagSelected" id="itemTagSelected" #itemTagSelected="ngModel"
                                [ngModelOptions]="{standalone: true}" (onChange)="refreshTable()">
                                <ng-template let-item pTemplate="selectedItem">
                                    <span class="text-center  {{item.color}}">{{item.label}}</span>
                                </ng-template>
                                <ng-template let-car pTemplate="item">
                                    <div class="ui-helper-clearfix p-text-center p-p-1 {{car.color}}">
                                        {{car.label}}
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="p-field p-sm-2">
                            <label for="client" class="label-text">{{'due-date' | translate}}</label>
                            <p-dropdown [options]="dueDateList" [style]="{'width':'100%'}"
                                [(ngModel)]="projectObj.dueDateID" name="itemdueDateSelected" id="itemdueDateSelected"
                                #itemdueDateSelected="ngModel" [ngModelOptions]="{standalone: true}"
                                (onChange)="refreshTable()"></p-dropdown>
                        </div>
                        <div class="p-field p-sm-2">
                            <label for="client" class="label-text">{{'status' | translate}}</label>
                            <p-dropdown [options]="statusList" [style]="{'width':'100%'}"
                                [(ngModel)]="projectObj.statusID" name="itemStatusSelected" id="itemStatusSelected"
                                #itemStatusSelected="ngModel" [ngModelOptions]="{standalone: true}"
                                (onChange)="refreshTable()"></p-dropdown>
                        </div>
                        <div class="p-field p-sm-2">
                            <label for="client" class="label-text">{{'partner' | translate}}</label>
                            <p-dropdown [options]="AllStaffsTypes" [style]="{'width':'100%'}"
                                [(ngModel)]="projectObj.staffId" name="itemStaffIdSelected" id="itemStaffIdSelected"
                                #itemStaffIdSelected="ngModel" [ngModelOptions]="{standalone: true}"
                                (onChange)="refreshTable()"></p-dropdown>
                        </div>
                        <div class="p-field p-sm-2">
                            <label for="client" class="label-text">{{'manager' | translate}}</label>
                            <p-dropdown [options]="AllStaffsTypes" [style]="{'width':'100%'}"
                                [(ngModel)]="projectObj.managerId" name="itemManagerIdSelected"
                                id="itemManagerIdSelected" #itemManagerIdSelected="ngModel"
                                [ngModelOptions]="{standalone: true}" (onChange)="refreshTable()"></p-dropdown>
                        </div>

                    </div>
                    <div class="p-sm-1 p-ml-1 p-mt-3">
                        <button pButton pRipple name="syncIcon" pTooltip="{{'Reset-Filters' | translate}}"
                            (click)="resetFileters()" type="button" icon="fal fa-sync"
                            class="p-button-rounded p-mr-3"></button>
                    </div>
                </div>
            </div>

            <!-- Table -->
            <div class="card p-pt-1">
                <p-table [value]="projectsDataSource" dataKey="ProjectHeaderID" [sortOrder]="1"
                    [globalFilterFields]="['TemplateName', 'completedcount', 'detailcount', 'DateReceived', 'CompletionDate', 'DueDate', 'ExtensionDate', 'Status', 'StaffName']"
                    [filterDelay]="0" [(selection)]="selectedClientsItems"
                    [totalRecords]="projectsDataSource.length" [pageLinks]="3"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    [scrollable]="true" [scrollHeight]="'calc(70vh - 280px)'"
                    [responsive]="true" (onFilter)="onFilter($event)" #dt>

                    <!-- Search Bar -->
                    <ng-template pTemplate="caption">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                        styleClass="p-ml-2"></p-message>
                                </div>
                            </div>
                            <div class="p-col-6 p-text-right">
                                <p-splitButton label="{{'mgmt-delete' | translate}}" (onClick)="deleteItems()"
                                    icon="fa fa-trash-o" [model]="buttonsDeleteList" styleClass="p-button-sm split-button-size"
                                    [disabled]="selectedClientsItems.length == 0">
                                </p-splitButton>
                            </div>
                        </div>
                    </ng-template>

                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-3p p-text-center">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th class="width-12p p-text-left">{{'Projects' | translate}}</th>
                            <th class="width-3p p-text-center">{{'Tasks' | translate}}</th>
                            <th class="width-8p p-text-left">{{'Tags' | translate}}</th>
                            <th class="width-6p p-text-center" pSortableColumn="DateReceived">
                                {{'pm.received-date' | translate}}
                                <p-sortIcon field="DateReceived"></p-sortIcon>
                            </th>
                            <th class="width-6p p-text-center" pSortableColumn="CompletionDate" *ngIf="projectObj.statusID === 'Completed'">
                                {{'Completion-Date' | translate}}
                                <p-sortIcon field="CompletionDate"></p-sortIcon>
                            </th>
                            <th class="width-6p p-text-center" pSortableColumn="SortableDueDate">
                                {{'due-date' | translate}}
                                <p-sortIcon field="SortableDueDate"></p-sortIcon>
                            </th>
                            <th class="width-8p p-text-center">{{'status' | translate}}</th>
                            <th class="width-10p p-text-left">{{'partner' | translate}}</th>
                            <th class="width-10p p-text-left">{{'manager' | translate}}</th>
                        </tr>
                    </ng-template>

                    <!-- Rows -->
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                        <tr>
                            <td class="width-3p p-text-center">
                                <p-tableCheckbox [value]="rowData">
                                </p-tableCheckbox>
                            </td>
                            <td class="width-12p p-text-left cursor-hand p-text-truncate">
                                <span class="project-link" showDelay="800" pTooltip="{{rowData.TemplateName}}"
                                    tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                    (click)="redirectToProjectDetails(rowData)">{{rowData.TemplateName}}</span>
                            </td>
                            <td class="width-3p p-text-center" showDelay="800" pTooltip="{{rowData.completedcount}}" tooltipPosition="top"
                                tooltipStyleClass="tooltip-no-wrap">
                                <span
                                    *ngIf="rowData.completedcount >= 0">{{rowData.completedcount}}/</span>{{rowData.detailcount}}
                            </td>
                            <td class="width-8p p-text-left">
                                <span *ngFor="let tag of rowData['Tags']">
                                    <span *ngIf="findTagByValue(tag,'label') !== ''" class="p-text-nowrap pTag"
                                        [style.background-color]="findTagByValue(tag,'color')"
                                        pTooltip="{{findTagByValue(tag,'label')}}" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">&nbsp;</span>
                                </span>
                            </td>
                            <td class="width-6p p-text-center">
                                <span>{{rowData['DateReceived'] | date:'MM/dd/yyyy'}}</span>
                            </td>
                            <td *ngIf="projectObj.statusID === 'Completed'" class="width-6p p-text-center">
                                {{rowData.CompletionDate | date:'MM/dd/yyyy'}}
                            </td>
                            <td *ngIf="rowData.ExtensionDate == null && rowData.DueDate && rowData.DueDate < todayDate"
                                class="width-6p p-text-center {{ rowData.Status !== 'Completed' ? 'dueDate_critical' : ''}}" pTooltip="{{getToolTip(rowData)}}" tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                                {{rowData.DueDate | date:'MM/dd/yyyy'}}
                            </td>
                            <td *ngIf="rowData.ExtensionDate == null && rowData.DueDate && rowData.DueDate >= todayDate"
                                class="width-6p p-text-center" pTooltip="{{getToolTip(rowData)}}" tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                                {{rowData.DueDate | date:'MM/dd/yyyy'}}
                            </td>
                            <td *ngIf="rowData.ExtensionDate == null && rowData.DueDate == null" class="width-6p p-text-center">
                                -
                            </td>
                            <td *ngIf="rowData.ExtensionDate != null && rowData.ExtensionDate  < todayDate"
                                class="width-6p p-text-center {{ rowData.Status !== 'Completed' ? 'dueDate_critical' : ''}}"
                                pTooltip="{{getToolTip(rowData)}}"
                                tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                                {{rowData.ExtensionDate | date:'MM/dd/yyyy'}}
                            </td>
                            <td *ngIf="rowData.ExtensionDate != null && rowData.ExtensionDate >= todayDate"
                                pTooltip="{{getToolTip(rowData)}}" tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top"
                                class="width-6p p-text-center" style="color: #8217fd !important">
                                {{rowData.ExtensionDate | date:'MM/dd/yyyy'}}
                            </td>
                            <td class="width-8p p-text-center p-text-truncate" pTooltip="{{rowData.Status}}"
                                tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                                <span *ngIf="rowData.Status === 'Pending'" class="pending">
                                    {{rowData.Status}}
                                </span>
                                <span *ngIf="rowData.Status === 'In Progress'" class="inprogress">
                                    {{rowData.Status}}
                                </span>
                                <span *ngIf="rowData.Status === 'Ready for Review'" class="review">
                                    {{rowData.Status}}
                                </span>
                                <span *ngIf="rowData.Status === 'Completed'">
                                    {{rowData.Status}}
                                </span>
                            </td>
                            <td class="width-10p p-text-left remove-border" showDelay="800" pTooltip="{{rowData.StaffName}}" tooltipPosition="top"
                                tooltipStyleClass="tooltip-no-wrap">
                                {{rowData.managername}}
                            </td>
                            <td class="width-10p p-text-left remove-border">
                                {{rowData.username}}
                            </td>
                        </tr>
                    </ng-template>

                    <!-- No data -->
                    <ng-template pTemplate="emptymessage">
                        <tr class="emptymessage">
                            <td [attr.colspan]="projectObj.statusID === 'Completed' ? 10 : 9"
                                class="p-text-center p-pt-4">
                                <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                </p-message>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </div>
    </div>
</div>

<!-- <p-dialog [(visible)]="isDisplayDetailsTable" [modal]="true" [style]="{width: '50vw'}" [responsive]="true"
    [draggable]="true" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
    <p-header>{{'pm.Create-Project-From-Company-Template' | translate}}</p-header>
    <p-table [value]="templateDataSource" (onFilter)="onTemplateFilter($event)"
        [totalRecords]="templateDataSource.length" [pageLinks]="3"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
        [scrollHeight]="'calc(90vh - 360px)'" [scrollable]="true" [rowHover]="true"
        dataKey="MasterTemplateDetailsID" [globalFilterFields]="['TemplateName']" sortField="TemplateName"
        [sortOrder]="1" #detailtab>
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchTemplateValue pInputText type="text"
                        (input)="detailtab.filterGlobal($event.target.value, 'contains')" placeholder="Search by" />
                </span>
                <span>
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="detailtab.reset();clearSearchTemplateFilter()" tooltipPosition="top"
                        pTooltip="Clear"></button>
                </span>
                <p-message *ngIf="filteredTemplateItemsSize > -1" severity="success"
                    text="{{filteredTemplateItemsSize}} Record(s) found." styleClass="p-ml-2">
                </p-message>
            </div>
        </ng-template>
        <!- Table Header ->
        <ng-template pTemplate="header">
            <tr>
                <th class="width-8p p-text-center">
                </th>
                <th class="width-85p p-text-center" pSortableColumn="TemplateName">
                    Template Names
                    <p-sortIcon styleClass="p-ml-0" field="TemplateName"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <!- Rows ->
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="width-8p p-text-center">
                    <p-radioButton name="groupname" [(ngModel)]="selectedTemplate" [value]="rowData" [inputId]="rowData.ProjectHeaderID"
                        (click)="radioCompanyClicked($event,rowData)">
                    </p-radioButton>
                </td>
                <td class="width-85p p-text-left">
                    {{rowData.TemplateName}}
                </td>
            </tr>
        </ng-template>
        <!- No data ->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">
                    <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                    </p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton label="{{'create' | translate}}" (click)="createProjectScreen()"
            [disabled]="!isRadioSelected"></button>
        <button pButton label="{{'cancel_button' | translate}}" (click)="isDisplayDetailsTable = false"></button>

    </p-footer>
</p-dialog> -->

<div *ngIf="isDisplayDetailsTable">
    <app-create-project-from-template 
        [clientID]="clientId" 
        [clientName]="clientDetails['ClientName']" 
        (onClose)="isDisplayDetailsTable = false">
    </app-create-project-from-template>
</div>