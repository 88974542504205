<div class="p-mx-6 p-bx-1 p-mb-4  p-mt-3">
    <div class="p-d-inline-block">
        <h4 class="p-mb-1">Welcome to Mango Practice Management!</h4>
        <div>Please take the time to finish required setups.</div>
    </div>
    <div class="p-d-inline-block  pull-right">
        <img src="assets/img/mango-logo-transparent.png" style="height: 45px;width: 280px;"/>
    </div>
</div>
<div class="card p-mx-6 p-mt-1 p-bx-1 p-mb-0">
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>
<router-outlet></router-outlet>