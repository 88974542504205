import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CashReceiptsComponent } from './cash-receipts/cash-receipts.component';
import { accountingComponent } from './accounting';
import { AppConstants } from '@app/_helpers/api-constants';
import { MakeDepositsComponent } from './make-deposits/make-deposits.component';
import { EditDepositComponent } from './edit-deposit/edit-deposit.component';
import { AuthGuard } from '@app/_services';
import { CanDeactivateForm } from './can-deactivate';
import { MerchantSetupComponent } from './merchant-setup/merchant-setup.component';
import { STaxComponent } from './s-tax/s-tax.component';
import { StaxViewTransactionHistoryComponent } from './stax-view-transaction-history/stax-view-transaction-history.component';


const routes: Routes = [
  {
    path: '', component: accountingComponent,
    canActivate: [AuthGuard],
    children: [
      { path: AppConstants.receiptsRoutePath, component: CashReceiptsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'roles': ["manager", "Senior Manager"], 'featID': 12 } },
      { path: AppConstants.merchantSetupRoutePath, component: MerchantSetupComponent, canActivate: [AuthGuard], data: { 'roles': ["administrator"], 'featID': 12 } },
      { path: AppConstants.depositsRoutePath, component: MakeDepositsComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "administrator", "Senior Manager"], 'featID': 12 } },
      { path: AppConstants.editDepositRoutePath, component: EditDepositComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "administrator", "Senior Manager"], 'featID': 12 } },
      { path: AppConstants.staxRoutePath, component: STaxComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'roles': ["manager", "administrator", "Senior Manager"], 'featID': 12 } },
      { path: AppConstants.staxTransationsRoutePath, component: StaxViewTransactionHistoryComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "administrator", "Senior Manager"], 'featID': 12 } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class AccountingRoutingModule { }
