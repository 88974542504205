<p-sidebar [(visible)]="isDisplayRuleDialog" position="right"
    [style]="{'min-width':'60%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
    <div *ngIf="isDisplayRuleDialog" class="card">
        <div style="height: 1px;color: #fff;">
            <div id="rule-output" style="height: 1px;color: #fff;">{{projHeaderRule}}</div>
        </div>
        <div id="RecurrenceEditor"></div>
        <ejs-recurrenceeditor #recurrenceObj [value]="projHeaderRule" [selectedType]='0'
            (change)="onChange($event)"></ejs-recurrenceeditor>
        <div class="project-details">
        </div>
        <div class="p-my-2" *ngIf="ruledateSource">
            <span class="label-text">{{ 'Current-Due-Date' | translate }}: </span> <span><strong> {{ruledateSource | date:
                "MM/dd/yyyy"}} </strong></span>
        </div>
        <div class="p-my-2" *ngIf="ruleTwodateSource">
            <span class="label-text">{{ 'Next-Due-Date' | translate }} : </span> <span><strong> {{ruleTwodateSource | date:
                "MM/dd/yyyy"}} </strong></span>
        </div>
        
        <div class="p-d-flex p-jc-end p-mr-2 p-mt-6 filter-btns">
            <button type="button" pButton label="{{'Apply' | translate}}" icon="fal fa-check" class="p-mr-2" (click)="createRule()" [disabled]="!ruledateSource"></button>
            <button type="button" pButton label="{{'Cancel' | translate}}" icon="fal fa-times" (click)="onCloseDialog()"></button>
        </div>
    </div>
</p-sidebar>