import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  RichTextEditorComponent,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
declare let numeral: any;

@Component({
  selector: 'app-engagements-view',
  templateUrl: './engagements-view.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class EngagementsViewComponent implements OnInit {
  public selectedClientName: string = null;
  projectClientID: number = -1;
  projectID: number = -1;
  selectedEngagementName: string = null;
  clientForm: UntypedFormGroup;
  public statusTypes;
  public billingRates: SelectItem[];
  isScratchPadChanged = false;

  @ViewChild('engagementMainForm') engagementMainForm;
  public defaultClientSetting = {
    ARCreditLimitAmount: null,
    ActivateClientRates: null,
    DefaultInvoiceTemplate: null,
    FinalizeAction: null,
    MarkDepositApplied: null,
    MarkSlipsBilled: null,
    NoServiceCharges: null,
    Tax1ID: null,
    Tax2ID: null,
    Tax3ID: null,
    TermsID: null,
    WIPCreditLimitAmount: null
  };
  public activityObj = {
    ProjectMasterID: null,
    Description: null,
    Inactive: false,
    ServiceCodeID: null,
    ServiceCode: null,
    Tax1: null,
    Tax2: null,
    Tax3: null,
    Billable: false,
    BillRate: null,
    BillingRate: null
  };
  public defaultRecord: any = {
    ClientID: '',
    AssignedTo: '',
    Status: 'Open',
    SalesTaxOption: '',
    EngagementName: '',
    StartDate: '',
    DueDate: '',
    ProjectStreet: 'null',
    ProjectStreet2: 'null',
    ProjectCity: 'null',
    ProjectState: 'null',
    ProjectZip: 'null',
    ProjectCountry: 'null',
    CustomerContactID: -1,
    ContactEmail: '',
    Inactive: false,
    EngagementTypeID: '',
    EngagementNumber: '',
    EngagementDescription: '',
    EngagementInvoiceDescription: '',
    FlatFeeAmount: 0.0,
    BillingMethod: 'Hourly',
    BillTo: 'Client',
    TermsID: 0,
    LaborTaxRate1: 0.0,
    LaborTaxRate2: 0.0,
    ExpenseRate1: 0.0,
    ExpenseRate2: 0.0,
    DefaultTaxRates: true,
    isBillable: true
  };
  custmorContacts = [{ label: 'Select Contact', value: null }];
  companyId: any;
  displayClientRateModal: boolean = false;
  public termsList: SelectItem[];
  public engagementList: any = [];
  public staffList: any = [];
  invoiceTemplates: SelectItem[];
  public finalizeActions: SelectItem[];
  public clientRatesCols: any[];
  selectedActivityDesc = '';
  isEditClientRate: boolean;
  scratchPadEnabled: boolean = false;
  @ViewChild('clientEditor')
  public rteObj: RichTextEditorComponent;
  FinalizeAction: any;
  public engagementObj: any = {};
  public filteredClients: any[];
  clientRates: any = [];
  clientList: any = [];
  activities: any;
  defaultActivity: any = {};

  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo'
    ]
  };

  clientRouteUrl: string = '/client/engagements';
  public filteredActivities: any[];
  isFormValid: boolean = false;
  isEmailValid: boolean = true;
  isFormLoaded: boolean = false;
  isEditClient: boolean = false;
  timeexp;
  salesTaxCalcOptions: SelectItem[];

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private _translate: TranslateService,
    public mangoUtils: mangoUtils,
    public translate: TranslateService
  ) {
    this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.projectClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.selectedEngagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);
    this.activities = this.encrDecSrvc.getObject(AppConstants.activityList);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.defaultRecord.AssignedTo = this.encrDecSrvc.getObject(AppConstants.staffID);

    this.mangoAPISrvc.isFormChanged = false;
    this.engagementObj = this.defaultRecord;
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.getAllStaffList();
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
  }

  initTranslations() {
    this.statusTypes = [
      { label: this._translate.instant('mgmt-open'), value: 'Open' },
      { label: this._translate.instant('close'), value: 'Close' },
      { label: this._translate.instant('Pending'), value: 'Pending' }
    ];
    this.finalizeActions = [
      { label: 'Select Option', value: null },
      { label: this._translate.instant('print'), value: 'Print' },
      { label: this._translate.instant('email'), value: 'Email' },
      { label: this._translate.instant('finalize_only'), value: 'Finalize Only' }
    ];
    this.invoiceTemplates = [
      { label: this._translate.instant('Select Invoice Template'), value: null },
      { value: '0', label: this._translate.instant('client.detail') },
      { value: '1', label: this._translate.instant('engagement.detail_with_memo') },
      { value: '2', label: this._translate.instant('client.narrative') },
      { value: '3', label: this._translate.instant('engagement.narrative_no_advice') },
      { value: '4', label: this._translate.instant('client.narrative_mini_statement') },
      { value: '5', label: this._translate.instant('engagement.narrative_with_previous_balance') },
      { value: '6', label: this._translate.instant('client.detail_no_rate') },
      { value: '7', label: this._translate.instant('client.detail_no_rate_time') }
    ];
    this.defaultClientSetting.DefaultInvoiceTemplate = this.invoiceTemplates[0].value;
    this.billingRates = [
      { value: 'Hourly', label: this._translate.instant('hourly') },
      { value: 'Fixed Fee', label: this._translate.instant('fixed_fee') }
    ];
    this.breadcrumbService.setItems([
      { label: this.translate.instant('engagement') },
      { label: this.translate.instant('view') },
      { label: this.selectedClientName, icon: 'ic-red' },
      { label: this.selectedEngagementName, icon: 'ic-red' }
    ]);

    this.salesTaxCalcOptions = [
      {
        label: this.translate.instant('settings.Calculate-Sales-Tax-Time-Record'),
        value: 'timeRecord'
      },
      {
        label: this.translate.instant('settings.Calculate-Sales-Tax-Engagement'),
        value: 'engagement'
      }
    ];

    this.loadDefaults();
  }

  formChanged() {
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  ngOnInit(): void {
    // parent.engagementMainForm.control.valueChanges
    //   .subscribe(values => {
    //     this.isFormValid = this.isFormLoaded && this.engagementMainForm.valid && this.engagementMainForm.dirty;
    //     // if (this.engagementMainForm.dirty && !this.mangoAPISrvc.isFormChanged) {
    //     //   this.mangoAPISrvc.isFormChanged = true;
    //     // }
    //   });
  }

  loadDefaults(){
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.fireEngagementBadgeView(true);
    parent.mangoAPISrvc
      .loadEngagementViewSettings(parent.projectID, parent.projectClientID, parent.companyId)
      .subscribe(
        function (data: any) {
          parent.getClientRates(data[0]);
          parent.getSettingData(data[1]);
          parent.getTerms(data[2]);
          parent.getProjectById(data[3][0], data[5]);
          parent.getContacts(data[4]);
          parent.mangoAPISrvc.showLoader(false);
        },
        error => {
          parent.mangoAPISrvc.notify(
            'error',
            parent.translate.instant('error'),
            AppConstants.fetchErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        }
      );
  }

  saveEngagement() {
    const parent = this;
    let templateValue = [];
    if (parent.engagementObj.Status == 'Close') {
      parent.engagementObj.Inactive = true;
    } else {
      parent.engagementObj.Inactive = false;
    }
    if (isNaN(parent.defaultClientSetting.DefaultInvoiceTemplate)) {
      templateValue = parent.invoiceTemplates.filter(
        client => client['label'] == parent.defaultClientSetting.DefaultInvoiceTemplate
      );
      if (templateValue.length > 0) {
        parent.defaultClientSetting.DefaultInvoiceTemplate = templateValue[0].value;
      }
    } else {
      templateValue = parent.invoiceTemplates.filter(
        client => client['value'] == parent.defaultClientSetting.DefaultInvoiceTemplate
      );
      if (templateValue.length > 0) {
        parent.defaultClientSetting.DefaultInvoiceTemplate = templateValue[0].value;
      }
    }
    const obj = {
      'invoiceTemplate': parent.defaultClientSetting.DefaultInvoiceTemplate,
      'customerTermId': parent.defaultClientSetting.TermsID,
      'arCreditLimit': parent.defaultClientSetting.ARCreditLimitAmount,
      'wipCreditLimit': parent.defaultClientSetting.WIPCreditLimitAmount,
      'salesTax1': parent.defaultClientSetting.Tax1ID,
      'activateClientRates': parent.defaultClientSetting.ActivateClientRates,
      'salesTax2': parent.defaultClientSetting.Tax2ID,
      'salesTax3': parent.defaultClientSetting.Tax3ID,
      'markSlipBilled': parent.defaultClientSetting.MarkSlipsBilled,
      'markDepositApplied': parent.defaultClientSetting.MarkDepositApplied,
      'noServiceCharges': parent.defaultClientSetting.NoServiceCharges,
      'FinalizeAction': parent.defaultClientSetting.FinalizeAction,
    };
    parent.engagementObj.FlatFeeAmount =
      parent.engagementObj.BillingMethod == 'Hourly' ? 0 : parent.engagementObj.FlatFeeAmount;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateProject(parent.engagementObj).subscribe(
      function (ItemData: any) {
        parent.isScratchPadChanged = false;
        let userLogs = {};
        userLogs['Action'] = 'Update Engagement';
        userLogs['Description'] =
          ItemData.data[0].EngagementName +
          ' ##' +
          ItemData.data[0].ProjectMasterID +
          ' to Client: ##' +
          ItemData.data[0].ClientID +
          ` --${parent.selectedClientName}`;
        userLogs['Table'] = 'Engagement';
        let isManaging = parent.encrDecSrvc.getObject(AppConstants.isManagingAccount);
        if (!isManaging) {
          parent.mangoAPISrvc.addUserLogs(userLogs).subscribe(
            () => {},
            err => {
              console.log(err);
            }
          );
        }

        parent.mangoAPISrvc.saveSettingData(parent.projectClientID, obj).subscribe(
          function (data: any) {
            parent.mangoAPISrvc.notify(
              'success',
              parent.translate.instant('Success'),
              AppConstants.updateMsg
            );
            parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            parent.isFormValid = false;
            parent.mangoAPISrvc.isFormChanged = false;
            parent.engagementMainForm.form.markAsPristine();
            parent.engagementMainForm.form.markAsUntouched();
            parent.engagementMainForm.form.updateValueAndValidity();
            parent.mangoAPISrvc.showLoader(false);
          },
          error => {
            parent.mangoAPISrvc.notify(
              'error',
              parent.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  saveClientRate(isSave) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    if (isSave) {
      parent.defaultActivity.ProjectMasterID = parent.defaultActivity.ProjectMasterID
        ? parent.defaultActivity.ProjectMasterID
        : parent.projectID;
      parent.mangoAPISrvc.saveClientRate(parent.projectClientID, parent.defaultActivity).subscribe(
        function (data) {
          parent.getDataClientRates();
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify(
            'success',
            parent.translate.instant('Success'),
            AppConstants.createMsg
          );
        },
        error => {
          parent.mangoAPISrvc.notify(
            'error',
            parent.translate.instant('error'),
            AppConstants.updateErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.mangoAPISrvc
        .updateClientRate(parent.defaultActivity['RateClientsID'], parent.defaultActivity)
        .subscribe(
          function (data) {
            parent.getDataClientRates();
            parent.mangoAPISrvc.notify(
              'success',
              parent.translate.instant('Success'),
              AppConstants.updateMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          },
          error => {
            parent.mangoAPISrvc.notify(
              'error',
              parent.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          }
        );
    }
    this.displayClientRateModal = false;
  }

  deleteClientRate(id) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then(result => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteClientRate(id).subscribe(
          function (data) {
            parent.getDataClientRates();
            parent.mangoAPISrvc.notify(
              'success',
              parent.translate.instant('Success'),
              AppConstants.deleteMsg
            );
          },
          error => {
            parent.mangoAPISrvc.notify(
              'error',
              parent.translate.instant('error'),
              AppConstants.deleteErrorMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      }
    });
  }

  getDataClientRates() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getClientRates(this.projectClientID).subscribe(
      function (data: any) {
        parent.clientRates = data;
        for (let i = 0; i < data.length; i++) {
          parent.clientRates[i].index = i;
        }
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  changefinalizeActions() {
    const tempEmail = this.encrDecSrvc.getObject("selected-ClientEmail");
    if (this.FinalizeAction == 'Email') {
      if (!tempEmail) {
        this.FinalizeAction = null;
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('email_required'),
          text: this.translate.instant('client_email_required_message'),
          showConfirmButton: false,
          timer: 1900
        });
      }
    }
  }

  redirectListView() {
    this.encrDecSrvc.addObject(AppConstants.projectID, '');
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }

  getAllStaffList() {
    const parent = this;
    if (parent.staffList.length > 0) {
      return false;
    }
    const tempList = parent.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive) continue;

      parent.staffList.push({ label: tempList[i].StaffName, value: tempList[i].StaffID });
    }
  }

  getTerms(obj) {
    const parent = this;
    parent.termsList = [{ label: "Select Terms", value: null }];
    for (let i = 0; i < obj.length; i++) {
      if (!obj[i].Inactive) {
        parent.termsList.push({ label: obj[i].TermsDescription, value: obj[i].TermsID });
      }
    }
  }

  getSettingData(data) {
    const parent = this;
    let templateValue = [];
    parent.defaultClientSetting = data;
    if (data.DefaultInvoiceTemplate != null) {
      if (isNaN(data.DefaultInvoiceTemplate)) {
        templateValue = parent.invoiceTemplates.filter(
          client => client['label'] == data.DefaultInvoiceTemplate
        );
        if (templateValue.length > 0) {
          parent.defaultClientSetting.DefaultInvoiceTemplate = templateValue[0].value;
        }
      } else {
        templateValue = parent.invoiceTemplates.filter(
          client => client['value'] == data.DefaultInvoiceTemplate
        );
        if (templateValue.length > 0) {
          parent.defaultClientSetting.DefaultInvoiceTemplate = templateValue[0].value;
        }
      }
    }
  }

  getClientRates(data) {
    const parent = this;
    parent.clientRates = data;
    for (let i = 0; i < data.length; i++) {
      parent.clientRates[i].index = i;
    }
  }

  getProjectById(data,dataTwo) {
    const parent = this;
    parent.engagementObj = data;
    parent.engagementObj['StartDate'] = new Date(data.StartDate);
    parent.engagementObj['DueDate'] = new Date(data.DueDate);
    //if value of isTaxable is null, it is equal to true. This condition is to handle newly created customers.
    parent.engagementObj['isTaxable'] =
      parent.engagementObj['isTaxable'] || parent.engagementObj['isTaxable'] == null ? true : false;
    //if value of isCalcTaxNoTime is null, it is equal to true. This condition is to handle newly created customers.
    parent.engagementObj['isCalcTaxNoTime'] =
      parent.engagementObj['isCalcTaxNoTime'] || parent.engagementObj['isCalcTaxNoTime'] == null
        ? true
        : false;
    parent.selectedClientName = parent.engagementObj['ClientName'];
    parent.getEngagements(parent.engagementObj['EngagementTypeID'], dataTwo);
  }

  getContacts(data) {
    const parent = this;
    for (let i = 0; i < data.length; i++) {
      parent.custmorContacts.push({
        label: data[i].ContactName,
        value: data[i].CustomerContactID
      });
    }
  }

  getEngagements(id, data) {
    this.engagementList = [];
    for (let i = 0; i < data.length; i++) {
      if (id == data[i].EngagementTypeID) {
        this.engagementList.push({
          label: data[i].Description,
          value: data[i].EngagementTypeID,
          data: { EngagementTypeID: data[i].EngagementTypeID, EngagementName: data[i].Description },
        });
      }
    }
  }

  filterActivities(event) {
    const tempObj = [];
    this.filteredActivities = [];
    for (let i = 0; i < this.activities.length; i++) {
      const activity = this.activities[i];
      if (activity['ServiceCode'] && activity['ServiceCode'].toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
        tempObj.push(activity);
      }
    }
    this.filteredActivities = tempObj.sort(this.mangoUtils.compareValues('ServiceCode', 'asc'));
  }

  handleActivitySelectClick(obj) {
    this.defaultActivity.Description = obj.Description;
    this.defaultActivity.ServiceCodeID = obj.ServiceCodeID;
    this.defaultActivity.ServiceCode = obj.ServiceCode;
  }

  handleActivityDropdownClick(event) {
    this.filteredActivities = [];
    setTimeout(() => {
      this.filteredActivities = this.activities;
    }, 1);
  }

  replaceShortcuts(value, field) {
    this.isFormValid = true;
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    this.engagementObj[field] = valueArr.join(' ');
    this.isFormValid = true;
  }

  isValidEmail(email) {
    this.isEmailValid = true;
    const mailPattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (email == "" || email === null || email.trim() == "") {
      return null;
    } else if (mailPattern.test(email.trim())) {
      return null;
    } else {
      this.isFormValid = false;
      this.isEmailValid = false;
    }
  }

  addClientRate() {
    this.isEditClient = false;
    this.defaultActivity = this.activityObj;
    this.defaultActivity['ProjectMasterID'] = this.projectID;
    this.displayClientRateModal = true;
  }

  editClientRate(obj) {
    this.defaultActivity = obj;
    this.defaultActivity.BillingRate = numeral(numeral(obj.BillingRate).value()).format('0,0.00');
    this.defaultActivity['ProjectMasterID'] = obj['ProjectMasterID'];
    this.isEditClient = true;
    this.displayClientRateModal = true;
  }

  cancelClientRate() {
    this.defaultActivity = this.activityObj;
    this.displayClientRateModal = false;
  }

  findChoices(searchText: string) {
    return this['viewContainerRef']['parentInjector']['view']['component'][
      'rawshortcutLabels'
    ].filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
  }

  getChoiceLabel(choice: string) {
    return `${choice} `;
  }

  getOpacity(value) {
    return value ? '1.0' : '0.5';
  }

  onEditorChange() {
    this.isScratchPadChanged = true;
  }
}
