import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailUsername'
})
export class EmailUsernamePipe implements PipeTransform {
  transform(email: string): string {
    if (email && email.includes('@')) {
      const parts = email.split('@');
      if (parts.length === 2) {
        return `@${parts[0]}`;
      }
    }
    return email;
  }
}
