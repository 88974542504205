import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  AuthGuard
} from "@app/_services";
import { environment } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";
import WebViewer from "@pdftron/webviewer";
import * as AWS from "aws-sdk";
import $ from "jquery";
import moment from "moment";
import { SelectItem } from "primeng/api";
import { Calendar } from "primeng/calendar";
import Swal from "sweetalert2";

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

declare let numeral: any;

@Component({
  selector: 'app-expense-manager',
  templateUrl: './expense-manager.component.html'
})
export class ExpenseManagerComponent implements OnInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dummyViewer') dummyViewer: ElementRef;
  @ViewChild('preiewviewer') preiewviewer: ElementRef;
  dummyPDFInstance: any;
  wvPreviewInstance: any;

  public expenseTotals: any = 0;
  public dateRangeList: SelectItem[];
  public clients: any = [];
  public selectedItems: any = [];
  public isApiLoaded: boolean = false;
  public DateFrom: any = new Date('1901-01-01');
  public DateTo: any = new Date('2099-12-30');
  public DateRange: any = 'Month';
  public isAllowUser = false;
  public UserLevel: any = null;
  public awsBucket: any;
  public isFilePreview: boolean = false;
  public isShowfileName: boolean = false;
  public isPreviewViewerShow: boolean = false;
  public fileName = '';
  public selectedObj = null;
  public IsDateTypeReadOnly: boolean = true;
  private eventExpenseSubscription;
  public globalFilterColumns: any = [];

  filteredItemsSize = -1;
  searchTextStr: any = '';

  @ViewChild('DateFromCalendar') fromCalendar: Calendar;
  @ViewChild('DateToCalendar') toCalendar: Calendar;

  cols: any[];
  public _selectedColumns: any[];

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public sharedSrvc: SharedComponentsService,
    private _translate: TranslateService,
    private auth: AuthGuard,
    public mangoUtils: mangoUtils
  ) {
    this._translate.reloadLang(this._translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this._translate.instant('Time_&_Expense') },
        { label: this._translate.instant('Expense-Manager'), icon: 'ic-red' }
      ]);

      this.dateRangeList = [
        { label: this._translate.instant('none'), value: null },
        { label: this._translate.instant('Today'), value: 'Today' },
        { label: this._translate.instant('This-Week'), value: 'Week' },
        { label: this._translate.instant('reports.This_Month'), value: 'Month' },
        { label: this._translate.instant('rpt.This_Quarter'), value: 'Quarter' },
        { label: this._translate.instant('rpt.This_Year'), value: 'Year' },
        { label: this._translate.instant('rpt.Last_Week'), value: 'lastweek' },
        { label: this._translate.instant('rpt.Last_Month'), value: 'lastmonth' },
        { label: this._translate.instant('rpt.Last_Quarter'), value: 'lastquarter' },
        { label: this._translate.instant('rpt.Last_Year'), value: 'lastyear' },
        { label: this._translate.instant('Custom'), value: 'custom' }
      ];
    });

    this.mangoUtils.shouldFetchCode.next(true);
    this.UserLevel = this.encrDecSrvc.getObject(AppConstants.staffPermission);
    this.isAllowUser = this.auth.isAllowAccess(27);

    this.setAWSOBject();
    //this.changeDateTypes();
  }

  ngOnInit(): void {
    // this.getExpenseMangerData();
    this.eventExpenseSubscription = this.sharedSrvc.expenseDialogSub.subscribe(data => {
      if (data['ClientID']) {
        this.getExpenseMangerData();
      }
    });

    this.initializeColumn();
    this.changeDateTypes();
  }

  ngAfterViewInit(): void {
    this.createDummyPDFInstance('../../../../assets/dummyPDF.pdf');
  }

  ngOnDestroy() {
    this.eventExpenseSubscription.unsubscribe();
  }

  initializeColumn() {
    const allCols = [
      /*  { field: 'Ddate', header: "Date", class: 'width-7p p-text-center', sortable: true },
      { field: 'ClientName', header: this._translate.instant('name'), class: 'width-10p p-text-left', sortable: true }, */
      {
        field: 'EngagementName',
        header: this._translate.instant('Engagements'),
        class: 'width-8p p-text-left',
        sortable: true
      },
      {
        field: 'StaffName',
        header: this._translate.instant('Users'),
        class: 'width-8p p-text-left',
        sortable: true
      },
      {
        field: 'ExpenseCode',
        header: this._translate.instant('Expense'),
        class: 'width-6p p-text-left',
        sortable: true
      },
      /* { field: 'Description', header: this._translate.instant('Description'), class: 'width-5p p-text-center p-text-nowrap p-text-truncate', sortable: false }, */
      {
        field: 'Memo',
        header: 'Expense Memo',
        class: 'width-7p p-text-center p-text-nowrap p-text-truncate',
        sortable: false
      }
      /* { field: 'StandardAmount', header: this._translate.instant('amount'), class: 'width-5p p-text-right', sortable: true },
      { field: 'Billable', header: this._translate.instant('billable'), class: 'width-5p p-text-center', sortable: true },
      { field: 'Reimburseable', header: this._translate.instant('Reimbursable'), class: 'width-9p p-text-center', sortable: true },
      { field: 'Reimbursed', header: this._translate.instant('Reimbursed'), class: 'width-8p p-text-center', sortable: true },
      { field: 'Approved', header: this._translate.instant('Approve'), class: 'width-5p p-text-center p-text-nowrap p-text-truncate', sortable: false },
      { field: 'Billed', header: this._translate.instant('billed'), class: 'width-5p p-text-center', sortable: false }, */
    ];

    let selectedCols = [];

    const defaultCols = [
      {
        field: 'Ddate',
        header: this._translate.instant('date'),
        class: 'width-7p p-text-center',
        sortable: true
      },
      {
        field: 'ClientName',
        header: this._translate.instant('client.name'),
        class: 'width-10p p-text-left',
        sortable: true
      },
      {
        field: 'EngagementName',
        header: this._translate.instant('Engagements'),
        class: 'width-8p p-text-left',
        sortable: true
      },
      {
        field: 'StaffName',
        header: this._translate.instant('Users'),
        class: 'width-8p p-text-left',
        sortable: true
      },
      {
        field: 'ExpenseCode',
        header: this._translate.instant('Expense'),
        class: 'width-6p p-text-left',
        sortable: true
      },
      {
        field: 'Description',
        header: this._translate.instant('Description'),
        class: 'width-5p p-text-center p-text-nowrap p-text-truncate',
        sortable: true
      },
      {
        field: 'Memo',
        header: 'Expense Memo',
        class: 'width-5p p-text-center p-text-nowrap p-text-truncate',
        sortable: false
      },
      {
        field: 'StandardAmount',
        header: this._translate.instant('amount'),
        class: 'width-5p p-text-right',
        sortable: true
      },
      {
        field: 'Billable',
        header: this._translate.instant('billable'),
        class: 'width-5p p-text-center',
        sortable: true
      },
      {
        field: 'Reimburseable',
        header: this._translate.instant('Reimbursable'),
        class: 'width-9p p-text-center',
        sortable: true
      },
      {
        field: 'Reimbursed',
        header: this._translate.instant('Reimbursed'),
        class: 'width-8p p-text-center',
        sortable: true
      },
      {
        field: 'Approved',
        header: this._translate.instant('Approve'),
        class: 'width-5p p-text-center p-text-nowrap p-text-truncate',
        sortable: true
      },
      {
        field: 'Billed',
        header: this._translate.instant('billed'),
        class: 'width-5p p-text-center',
        sortable: true
      }
    ];

    selectedCols = [...defaultCols];

    this.cols = [...allCols];

    this.globalFilterColumns = [
      'Ddate',
      'ClientName',
      'EngagementName',
      'UserName',
      'ExpenseCode',
      'StandardAmount',
      'Reimburseable',
      'Reimbursed'
    ];
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.globalFilterColumns = [
      ...[
        'Ddate',
        'ClientName',
        'EngagementName',
        'UserName',
        'ExpenseCode',
        'StandardAmount',
        'Reimburseable',
        'Reimbursed'
      ],
      ...this._selectedColumns.map(col => col.field)
    ];
  }

  openTimeEntryOrExpenseEntryDailog(data?: any) {
    const selectedDate = null;
    data["isEditFlow"] = true;
    data["selectedDate"] = selectedDate;
    this.sharedSrvc.openExpenseDialog(data);
  }

  getExpenseMangerData() {
    const parent = this;
    parent.isApiLoaded = false;
    const startDate = moment(parent.DateFrom).format("MM-DD-YYYY");
    const endDate = moment(parent.DateTo).format("MM-DD-YYYY");
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getExpenseMangerData(startDate, endDate).subscribe(
      (data: any) => {
        parent.clients = data;

        parent.mangoAPISrvc
          .getDocCountByDate(
            startDate,
            endDate,
            parent.encrDecSrvc.getObject(AppConstants.companyID)
          )
          .subscribe(
            (result: any) => {
              parent.clients = parent.clients.map(item => {
                const expenseCount = result.find(
                  res => res.ExpenseMasterID == item.SlipMasterID
                )?.count;
                return {
                  ...item,
                  StandardAmount: +item.StandardAmount,
                  childItem: [],
                  hasChildren:
                    numeral(expenseCount).value() && numeral(expenseCount).value() > 0
                      ? true
                      : false
                };
              });
              parent.getTotalAmount(parent.clients);
              parent.isApiLoaded = true;
              parent.mangoAPISrvc.showLoader(false);
            },
            err => parent.mangoAPISrvc.showLoader(false)
          );

        // parent.getTotalAmount(parent.clients);
        // parent.isApiLoaded = true;
        // parent.mangoAPISrvc.showLoader(false);
      },
      err => parent.mangoAPISrvc.showLoader(false)
    );
  }

  getTotalAmount(data) {
    const totalsAmt = data.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.StandardAmount).value();
    }, 0);
    this.expenseTotals = numeral(totalsAmt.toFixed(2)).format('0,0.00');
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  onRowExpandEvent(event) {
    const self = this;
    if (event.data["childItem"].length > 0) {
      return false;
    }
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc
      .getClientsExpensedocuments(event.data['SlipMasterID'])
      .subscribe((data: any) => {
        event.data['hasChildren'] = data.length > 0 ? true : false;
        event.data['childItem'] = data;
        self.mangoAPISrvc.showLoader(false);
      });
  }

  deleteDocRow(obj, parentObj) {
    const params = {
      Bucket: environment.AWS_BUCKET_NAME,
      Key: 'documents/' + obj.UniqueName
    };
    const self = this;
    self.awsBucket.deleteObject(
      params,

      function (err, data) {
        if (err) {
          return;
        }
        parentObj.mangoAPISrvc.showLoader(true);

        self.mangoAPISrvc.deleteDMSParentFolder(obj.dmsParentID).subscribe(
          (objItem) => {
            const index = parentObj.childItem.indexOf(obj);
            parentObj.childItem.splice(index, 1);
            self.mangoAPISrvc.showLoader(false);
            self.mangoAPISrvc.notify(
              'success',
              self._translate.instant('Success'),
              AppConstants.deleteMessage
            );
          },
          error => {
            parentObj.mangoAPISrvc.notify(
              'error',
              self._translate.instant('error'),
              AppConstants.deleteErrorMsg
            );
            parentObj.mangoAPISrvc.showLoader(false);
          }
        );
      },

      self.mangoAPISrvc
    );
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = environment.AWS_REGION;
    this.awsBucket = new AWS.S3({
      params: { Bucket: environment.AWS_BUCKET_NAME },
    });
    */
    this.awsBucket = AWSBucketMask;
  }

  deleteClientAlert(rowData, id) {
    const parentObj = this;
    if(rowData["Billed"]) {
      Swal.fire({
        icon: 'warning',
        title: `${parentObj._translate.instant('warning')}!`,
        text: `${parentObj._translate.instant('slips.delete_billed_record')}`,
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok'
      });
      return;
    }
    Swal.fire({
      title: parentObj._translate.instant('confirmation'),
      text: parentObj._translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parentObj._translate.instant('yes_delete'),
      cancelButtonText: parentObj._translate.instant('no_delete')
    }).then(result => {
      if (result.value) {
        const values = [];
        const uniqueNames = [];
        if (!rowData.hasChildren && rowData.childItem.length == 0) {
          parentObj.mangoAPISrvc.getClientsExpensedocuments(id).subscribe((data: any) => {
            if (data.length > 0) {
              for (let index = 0; index < data.length; index++) {
                const element = data[index];
                values.push(element['dmsParentID']);
                uniqueNames.push(element['UniqueName']);
              }
            }
            parentObj.mangoAPISrvc.deleteTimeSheet(id).subscribe(
              function (data) {
                if (values.length > 0) {
                  parentObj.bulkDelete(uniqueNames);
                  parentObj.deleteDMSParentFolder(values.toString());
                } else {
                  parentObj.getExpenseMangerData();
                }
                parentObj.mangoAPISrvc.notify(
                  'success',
                  parentObj._translate.instant('Success'),
                  AppConstants.deleteMessage
                );
              },
              error => {
                parentObj.mangoAPISrvc.notify(
                  'error',
                  parentObj._translate.instant('error'),
                  AppConstants.deleteErrorMsg
                );
                parentObj.mangoAPISrvc.showLoader(false);
              }
            );
          });
        } else {
          for (let index = 0; index < rowData.childItem.length; index++) {
            const element = rowData.childItem[index];
            values.push(element['dmsParentID']);
            uniqueNames.push(element['UniqueName']);
          }
          parentObj.mangoAPISrvc.deleteTimeSheet(id).subscribe(
            function (data) {
              if (values.length > 0) {
                parentObj.deleteDMSParentFolder(values.toString());
                parentObj.bulkDelete(uniqueNames);
              } else {
                parentObj.getExpenseMangerData();
              }
              parentObj.mangoAPISrvc.notify(
                'success',
                parentObj._translate.instant('Success'),
                AppConstants.deleteMessage
              );
            },
            error => {
              parentObj.mangoAPISrvc.notify(
                'error',
                parentObj._translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              parentObj.mangoAPISrvc.showLoader(false);
            }
          );
        }
      }
    });
  }

  deleteDMSParentFolder(id) {
    const parent = this;
    parent.mangoAPISrvc.deleteDMSParentFolder(id).subscribe((objItem) => {
      parent.getExpenseMangerData();
    });
  }

  bulkDelete(filesList): void {
    const self = this;
    const params = {
      Bucket: environment.AWS_BUCKET_NAME,
      Delete: { Objects: [] }
    };
    for (let index = 0; index < filesList.length; index++) {
      const element = filesList[index];
      const obj = {};
      obj["Key"] = "documents/" + element;
      params.Delete.Objects.push(obj);
    }
    self.awsBucket.deleteObjects(
      params,

      function (err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else console.log(data); // successful response
      },

      self.mangoAPISrvc
    );
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.Notes + '_' + AppConstants.SearchString, '');
  }

  changeDateTypes() {
    const obj = this.DateRange;
    this.IsDateTypeReadOnly = true;
    // this.fromCalendar.disabled = true;
    // this.toCalendar.disabled = true;

    if (obj == 'Today') {
      this.DateFrom = new Date();
      this.DateTo = new Date();
    } else if (obj == 'Week') {
      this.DateFrom = new Date(moment().startOf('isoWeek').format());
      this.DateTo = new Date(moment().endOf('isoWeek').format());
    } else if (obj == 'lastweek') {
      this.DateFrom = new Date(moment().startOf('isoWeek').subtract(1, 'week').format());
      this.DateTo = new Date(moment().endOf('isoWeek').subtract(1, 'week').format());
    } else if (obj == 'Month') {
      this.DateFrom = new Date(moment().startOf('month').format());
      this.DateTo = new Date(moment().endOf('month').format());
    } else if (obj == 'lastmonth') {
      this.DateFrom = new Date(moment().subtract(1, 'months').startOf('month').format());
      this.DateTo = new Date(moment().subtract(1, 'months').endOf('month').format());
    } else if (obj == 'Quarter') {
      this.DateFrom = new Date(moment().startOf('quarter').format());
      this.DateTo = new Date(moment().endOf('quarter').format());
    } else if (obj == 'lastquarter') {
      this.DateFrom = new Date(moment().subtract(1, 'quarters').startOf('quarter').format());
      this.DateTo = new Date(moment().subtract(1, 'quarters').endOf('quarter').format());
    } else if (obj == 'Year') {
      this.DateFrom = new Date(moment().startOf('year').format());
      this.DateTo = new Date(moment().endOf('year').format());
    } else if (obj == 'lastyear') {
      this.DateFrom = new Date(moment().subtract(1, 'years').startOf('year').format());
      this.DateTo = new Date(moment().subtract(1, 'years').endOf('year').format());
    } else if (obj == 'custom') {
      this.IsDateTypeReadOnly = false;
      this.fromCalendar.disabled = false;
      this.toCalendar.disabled = false;
      this.DateFrom = new Date();
      this.DateTo = new Date();
    } else {
      this.DateFrom = new Date('1901-01-01');
      this.DateTo = new Date('2099-12-30');
    }
    this.getExpenseMangerData();
  }

  createDummyPDFInstance(docUrl) {
    const self = this;
    if (self.dummyPDFInstance) {
      self.dummyPDFInstance.loadDocument(docUrl).then((instance) => {
        const docViewer = instance.docViewer;
        docViewer.on("documentLoaded", function () {
          instance.setZoomLevel("100%");
        });
      });
    } else {
      WebViewer(
        {
          licenseKey: environment.PDF_WebViewer_KEY,
          path: '../../../../wv-resources/lib',
          initialDoc: docUrl
        },
        self.dummyViewer.nativeElement
      ).then(instance => {
        self.dummyPDFInstance = instance;
      });
    }
  }

  createWebPreviewViewer(docUrl) {
    const self = this;
    self.isPreviewViewerShow = true;

    if (self.wvPreviewInstance) {
      self.wvPreviewInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    } else {
      WebViewer(
        {
          licenseKey: environment.PDF_WebViewer_KEY,
          path: '../../../../wv-resources/lib',
          initialDoc: docUrl
        },
        self.preiewviewer.nativeElement
      ).then(instance => {
        self.wvPreviewInstance = instance;
        self.wvPreviewInstance.disableElements(['leftPanel', 'leftPanelButton', 'panToolButton', 'toolsButton', 'signatureToolButton', 'freeHandToolGroupButton', , 'signatureTool', 'freeTextToolButton', 'eraserToolButton', 'shapeToolGroupButton', 'textToolGroupButton', 'miscToolGroupButton', 'stickyToolButton']);
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    }
  }

  closePreviewDialog() {
    this.isFilePreview = false;
    this.isPreviewViewerShow = false;
  }

  previewRow(obj) {
    const params = {
      Bucket: environment.AWS_BUCKET_NAME,
      Key: 'documents/' + obj['UniqueName']
    };
    const self = this;
    self.isFilePreview = true;
    self.isPreviewViewerShow = true;
    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        $('#overlay').hide();
        if (err) {
          console.log('error while saving file on s3 server', err);
          return;
        }
        self.createWebPreviewViewer(docUrl);
      },

      self.mangoAPISrvc
    );
  }

  editRow(obj) {
    this.isShowfileName = true;
    this.fileName = obj.FName;
    this.selectedObj = obj;
  }

  saveFileName() {
    const parent = this;
    parent.selectedObj["FName"] = parent.fileName;
    parent.mangoAPISrvc
      .updateDMSParentFolder(parent.selectedObj)
      .subscribe((response) => {
        parent.isShowfileName = false;
        parent.mangoAPISrvc.notify(
          "success",
          parent._translate.instant('Success'),
          AppConstants.updateMsg
        );
      });
  }

  approve() {
    const items = this.selectedItems;
    const idForApprove = [];
    for (let i = 0; i < items.length; i++) {
      idForApprove.push(items[i].SlipMasterID);
    }

    const obj = { slipMasterIdArr: idForApprove };
    const parent = this;
    parent.mangoAPISrvc.approveExpenseEntry(obj).subscribe(function (data) {
      parent.selectedItems = [];
      parent.getExpenseMangerData();
    });
  }

  addExpensePopUp() {
    const data = {};
    data["isEditFlow"] = false;
    data["ClientID"] = "Header";
    data["selectedDate"] = new Date();
    this.sharedSrvc.openExpenseDialog(data);
  }

  saveExpenseData(data) {
    const parent = this;
    parent.mangoAPISrvc
      .updateTimeSheet(data.SlipMasterID, data)
      .subscribe((data) => {
        parent.getExpenseMangerData();
      });
  }

  uncheckRow(event?: any) {
    const item = event.data;
    item.check = false;
  }

  onDateChange(event: any) {
    this.getExpenseMangerData();
  }
}
