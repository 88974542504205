<div class="pmsdashboard">
    <!-- buttons -->
    <span class="pull-right p-mr-3 clientform-btn-top">
        <demo-video videoCode="ajgfbrc8lh"></demo-video>
        <p-button pRipple label="{{ 'Clients' | translate }}" icon="fal fa-users" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management/clientHistory"></p-button>
        <p-button pRipple label="{{ 'Projects' | translate }}/{{ 'Tasks' | translate }} List" icon="fal fa-building" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management/projectList"></p-button>
        <p-button pRipple label="{{ 'PM-Settings' | translate }}" icon="fal fa-cog" styleClass="p-button-sm" class="p-button-help p-pr-2"
            routerLink="/project-management/settings"></p-button>
        <p-splitButton *ngIf="isAllowCreateProject" label=" {{ 'New-Projects' | translate }}" styleClass="p-button-sm split-button-size" icon="fal fa-plus" class="p-pr-2"
            [model]="buttonsList" (onClick)="redirectProject()" [menuStyleClass]="'new-project-button-list'">
        </p-splitButton>
    </span>
    <div class="layout-content p-p-2">
        <form role="form" [formGroup]="ddmMainForm" novalidate>
            <div class="p-grid nested-grid">
                <div [ngClass]="(ddmMainForm.value?.ViewType !== 'tasks') ? 'p-col-9' : 'p-col-12'">
                    <div class="p-grid">
                        <div class="p-col-12 p-pb-0">
                            <div class="card p-py-1">
                                <div class="p-grid p-fluid">
                                    <div class="p-col-4">
                                        <label class="label-text" for="view">{{ 'view' | translate }}</label>
                                        <p-dropdown [options]="typeofViews" formControlName="ViewType" class="width-60p" (onChange)="viewChanges(false)"></p-dropdown>
                                    </div>
                                    <div class="p-col-4">
                                        <label class="label-text" for="userlabel">{{ (ddmMainForm.get('ViewType').value === 'tasks'? 'assigned_to' : 'bill-partner-or-manager') | translate }}</label>
                                        <p class="ic-size-16 p-mt-2" *ngIf="!isAllowFilterOtherUser"><strong>{{staffName}}</strong>
                                        </p>
                                        <p-dropdown [options]="AllStaffsTypes" *ngIf="isAllowFilterOtherUser" formControlName="staffId" class="width-60p" (onChange)="viewChanges(false, true)">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-col-4">
                                        <label class="label-text" for="dateRange">{{ 'Selected-Date-Range' | translate }}</label>
                                        <p-dropdown [options]="dateRangeOptions" formControlName="dateRange" class="width-60p" (onChange)="onChangeSelDateRange(true)">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                          class="
                            p-col-12
                            p-pb-0
                          "
                        >
                          <div
                            class="
                              p-grid
                            "
                          >
                            <div
                              class="
                                p-col
                              "
                            >
                              <!--
                                Due Today
                              -->
                              <p-panel
                                header="{{ 'Due-Today' | translate }}"
                              >
                                <div
                                  class="
                                    p-text-center
                                    project-stats-data
                                  "

                                  [ngClass]="
                                    (
                                        (
                                          dashBoardObj.today != 0
                                        )
                                      ? 'cursor-hand'
                                      : 'notAllowed'
                                    )
                                  "

                                  [routerLink]="
                                    (
                                        (
                                          dashBoardObj.today != 0
                                        )
                                      ? [ '/project-management/projectList/' + ddmMainForm.get( 'ViewType' ).value ]
                                      : [ ]
                                    )
                                  "

                                  [queryParams]="
                                    {
                                      redirectStatus: true,
                                      filterStatus: false,

                                      Due: 'duetoday',
                                      StaffID: ddmMainForm.get( 'staffId' ).value,
                                      isProjectTab: ddmMainForm.get( 'ViewType' ).value,
                                      DateRange: ddmMainForm.get( 'dateRange' ).value,
                                      filterDateField: 'ActualDueDate'
                                    }
                                  "
                                >
                                  {{ dashBoardObj.today }} {{ ddmMainForm.get( 'ViewType' ).value === 'tasks' ? "Tasks" : "Projects" | translate }}
                                </div>
                              </p-panel>
                            </div>

                            <div
                              class="
                                p-col
                              "
                            >
                              <!--
                                Due This Week
                              -->
                              <p-panel
                                header="{{ 'Due-This-Week' | translate }}"
                              >
                                <div
                                  class="
                                    p-text-center
                                    project-stats-data
                                  "

                                  [ngClass]="
                                    (
                                        (
                                          dashBoardObj.thisweek != 0
                                        )
                                      ? 'cursor-hand'
                                      : 'notAllowed'
                                    )
                                  "

                                  [routerLink]="
                                    (
                                        (
                                          dashBoardObj.thisweek != 0
                                        )
                                      ? [ '/project-management/projectList/' + ddmMainForm.get( 'ViewType' ).value ]
                                      : [ ]
                                    )
                                  "

                                  [queryParams]="
                                    {
                                      redirectStatus: true,
                                      filterStatus: false,

                                      Due: 'duethisWeek',
                                      StaffID: ddmMainForm.get( 'staffId' ).value,
                                      isProjectTab: ddmMainForm.get( 'ViewType' ).value,
                                      DateRange: ddmMainForm.get( 'dateRange' ).value,
                                      filterDateField: 'ActualDueDate'
                                    }
                                  "
                                >
                                  {{ dashBoardObj.thisweek }} {{ ddmMainForm.get( 'ViewType' ).value === 'tasks' ? "Tasks" : "Projects" | translate }}
                                </div>
                              </p-panel>
                            </div>

                            <div
                              class="
                                p-col
                              "
                            >
                              <!--
                                Due Next Week
                              -->
                              <p-panel
                                header="{{ 'Due-Next-Week' | translate }}"
                              >
                                <div
                                  class="
                                    p-text-center
                                    project-stats-data
                                  "

                                  [ngClass]="
                                    (
                                        (
                                          dashBoardObj.nextweek != 0
                                        )
                                      ? 'cursor-hand'
                                      : 'notAllowed'
                                    )
                                  "

                                  [routerLink]="
                                    (
                                        (
                                          dashBoardObj.nextweek != 0
                                        )
                                      ? [ '/project-management/projectList/' + ddmMainForm.get( 'ViewType' ).value ]
                                      : [ ]
                                    )
                                  "

                                  [queryParams]="
                                    {
                                      redirectStatus: true,
                                      filterStatus: false,

                                      Due: 'duetNextWeek',
                                      StaffID: ddmMainForm.get( 'staffId' ).value,
                                      isProjectTab: ddmMainForm.get( 'ViewType' ).value,
                                      DateRange: ddmMainForm.get( 'dateRange' ).value,
                                      filterDateField: 'ActualDueDate'
                                    }
                                  "
                                >
                                  {{ dashBoardObj.nextweek }} {{ ddmMainForm.get( 'ViewType' ).value === 'tasks' ? "Tasks" : "Projects" | translate }}
                                </div>
                              </p-panel>
                            </div>

                            <div
                              class="
                                p-col
                              ">
                                <!--
                                  Overdue
                                -->
                                <p-panel
                                  header="{{ 'Overdue' | translate }}"
                                >
                                  <div
                                    class="
                                      p-text-center
                                      project-stats-data
                                      ic-red
                                    "

                                    [ngClass]="
                                      (
                                          (
                                            dashBoardObj.pastdue != 0
                                          )
                                        ? 'cursor-hand'
                                        : 'notAllowed'
                                      )
                                    "

                                    [routerLink]="
                                      (
                                          (
                                            dashBoardObj.pastdue != 0
                                          )
                                        ? [ '/project-management/projectList/' + ddmMainForm.get( 'ViewType' ).value ]
                                        : [ ]
                                      )
                                    "

                                    [queryParams]="
                                      {
                                        redirectStatus: true,
                                        filterStatus: false,

                                        Due: 'pastdue',
                                        StaffID: ddmMainForm.get( 'staffId' ).value,
                                        isProjectTab: ddmMainForm.get( 'ViewType' ).value,
                                        DateRange: ddmMainForm.get( 'dateRange' ).value,
                                        filterDateField: 'ActualDueDate'
                                      }
                                    "
                                  >
                                    {{ dashBoardObj.pastdue }} {{ ddmMainForm.get('ViewType').value === 'tasks' ? "Tasks" : "Projects" | translate }}
                                  </div>
                                </p-panel>
                            </div>
                          </div>
                        </div>

                        <!--
                          Project Status Summary
                        -->
                        <div
                          *ngIf="
                            ddmMainForm.value?.ViewType !== 'tasks'
                          "

                          class="
                            p-col-12
                            p-pt-0
                            p-pb-0
                          "
                        >
                          <p-panel
                            header="{{ 'Project-Status-Summary' | translate }}"
                          >
                            <div
                              class="
                                p-grid
                              "
                            >
                              <div
                                class="
                                  p-d-flex
                                  p-flex-column
                                  p-jc-center
                                  p-text-center
                                  p-sm-3
                                  project-status-pending
                                "
                              >
                                <div
                                  class="
                                    project-status-underline
                                  "

                                  [ngClass]="
                                      dashBoardObj.pending != 0
                                    ? 'cursor-hand'
                                    : 'notAllowed'
                                  "

                                  [routerLink]="
                                      (dashBoardObj.pending != 0)
                                    ? [ '/project-management/projectList/projects' ]
                                    : [ ]
                                  "

                                  [queryParams]="
                                    {
                                      redirectStatus: true,
                                      filterStatus: false,

                                      Status: 'Pending',
                                      StaffID: ddmMainForm.get( 'staffId' ).value,
                                      DateRange: ddmMainForm.get( 'dateRange' ).value
                                    }
                                  "
                                >
                                  {{ "Pending" | translate }}
                                </div>
                                <div
                                  class="
                                    p-mb-3
                                  "
                                >
                                  {{ dashBoardObj.pending }} {{ "project" | translate }}(s)
                                </div>
                                <i
                                  class="
                                    ic-grey
                                  "
                                >
                                  {{ "Projects-Not-Started" | translate }}
                                </i>
                              </div>

                              <div
                                class="
                                  p-d-flex
                                  p-flex-column
                                  p-jc-center
                                  p-text-center
                                  p-sm-3
                                  project-status-inprogress
                                "
                              >
                                <div
                                  class="
                                    project-status-underline
                                  "

                                  [ngClass]="
                                      dashBoardObj.inprogress != 0
                                    ? 'cursor-hand'
                                    : 'notAllowed'
                                  "

                                  [routerLink]="
                                      ( dashBoardObj.inprogress != 0 )
                                    ? [ '/project-management/projectList/projects' ]
                                    : [ ]
                                  "

                                  [queryParams]="
                                    {
                                      redirectStatus: true,
                                      filterStatus: false,

                                      Status: 'In Progress',
                                      StaffID:ddmMainForm.get( 'staffId' ).value,
                                      DateRange: ddmMainForm.get( 'dateRange' ).value
                                    }
                                  "
                                >
                                  {{ 'mgmt-inprogress' | translate }}
                                </div>
                                <div
                                  class="
                                    p-mb-3
                                  "
                                >
                                  {{ dashBoardObj.inprogress }} {{ "project" | translate }}(s)
                                </div>
                                <i
                                  class="
                                    ic-grey
                                  "
                                >
                                  {{ "Projects-Started" | translate }}
                                </i>
                              </div>
                              <div
                                class="
                                  p-d-flex
                                  p-flex-column
                                  p-jc-center
                                  p-text-center
                                  p-sm-3
                                  project-status-readyforreview
                                "
                              >
                                <div
                                  class="
                                    p-text-center
                                    project-status-underline
                                  "

                                  [ngClass]="
                                      dashBoardObj.review != 0
                                    ? 'cursor-hand'
                                    : 'notAllowed'
                                  "

                                  [routerLink]="
                                      ( dashBoardObj.review != 0 )
                                    ? [ '/project-management/projectList/projects' ]
                                    : [ ]
                                  "

                                  [queryParams]="
                                    {
                                      redirectStatus: true,
                                      filterStatus: false,

                                      Status: 'Ready for Review',
                                      StaffID: ddmMainForm.get( 'staffId' ).value,
                                      DateRange: ddmMainForm.get( 'dateRange' ).value
                                    }
                                  "
                                >
                                  {{ 'Ready-for-Review' | translate }}
                                </div>
                                <div
                                  class="
                                    p-mb-3
                                  "
                                >
                                  {{ dashBoardObj.review }} {{ "project" | translate }}(s)
                                </div>
                                <i
                                  class="
                                    ic-grey
                                  "
                                >
                                  {{ "Projects-Ready-for-Review" | translate }}
                                </i>
                              </div>
                              <div
                                class="
                                  p-d-flex
                                  p-flex-column
                                  p-jc-center
                                  p-text-center
                                  p-sm-3
                                  project-status-chart
                                "
                              >
                                <p-chart
                                  type="doughnut"

                                  height="100px"

                                  [data]="chartData"

                                  [responsive]="true"

                                  [options]="options"
                                >
                                </p-chart>
                              </div>
                            </div>
                          </p-panel>
                        </div>

                        <!-- Task Status Summary -->
                        <div class="p-col-12 p-pt-0 p-pb-0" *ngIf="ddmMainForm.value?.ViewType === 'tasks'">
                            <p-panel header="{{ 'Task-Status-Summary' | translate }}">
                                <div class="p-grid">
                                    <div class="p-d-flex p-flex-column p-jc-center p-text-center p-sm-3 project-status-pending">
                                        <div class="project-status-underline" [ngClass]="dashBoardObj.pending != 0? 'cursor-hand' : 'notAllowed'" [routerLink]="(dashBoardObj.pending != 0)? ['/project-management/projectList/tasks'] : []" [queryParams]="{dateRange: null, statusIDs: ['Pending'],StaffID:ddmMainForm.get('staffId').value, DateRange: ddmMainForm.get('dateRange').value}">
                                            {{ 'Pending' | translate }}</div>
                                        <div class="p-mb-3">{{dashBoardObj.pending}} {{ 'task' | translate }}(s)
                                        </div>
                                        <i class="ic-grey">{{ 'Tasks-Not-Started' | translate }}</i>
                                    </div>
                                    <div class="p-d-flex p-flex-column p-jc-center p-text-center p-sm-3 project-status-inprogress">
                                        <div class="project-status-underline" [ngClass]="dashBoardObj.inprogress != 0? 'cursor-hand' : 'notAllowed'" [routerLink]="(dashBoardObj.inprogress != 0)? ['/project-management/projectList/tasks'] : []" [queryParams]="{dateRange: null, statusIDs: ['In Progress'],StaffID:ddmMainForm.get('staffId').value, DateRange: ddmMainForm.get('dateRange').value}">
                                            {{ 'mgmt-inprogress' | translate }}</div>
                                        <div class="p-mb-3">{{dashBoardObj.inprogress}} {{ 'task' | translate }}(s)
                                        </div>
                                        <i class="ic-grey">{{ 'tasks-ready' | translate }}</i>
                                    </div>
                                    <div class="p-d-flex p-flex-column p-jc-center p-text-center p-sm-3 project-status-readyforreview">
                                        <div class="p-text-center project-status-underline"  [ngClass]="dashBoardObj.review != 0? 'cursor-hand' : 'notAllowed'" [routerLink]="(dashBoardObj.review != 0)? ['/project-management/projectList/tasks'] : []" routerLink="/project-management/projectList/tasks" [queryParams]="{StatusTask: 'Active',StaffID:ddmMainForm.get('staffId').value, DateRange: ddmMainForm.get('dateRange').value}">
                                            {{ 'Active' | translate }}
                                        </div>
                                        <div class="p-mb-3">{{dashBoardObj.review}} {{ 'task' | translate }}(s)
                                        </div>
                                        <i class="ic-grey">{{ 'all-open-tasks' | translate }}</i>
                                    </div>
                                    <div class="p-d-flex p-flex-column p-jc-center p-text-center p-sm-3 project-status-chart">
                                        <p-chart type="doughnut" [data]="chartData" [responsive]="true" [options]="options" height="100px"></p-chart>
                                    </div>
                                </div>
                            </p-panel>
                        </div>

                        <!-- Projects Summary By User -->
                        <div class="p-col-12 p-pb-0" *ngIf="ddmMainForm.value?.ViewType !== 'tasks'">
                            <div class="p-grid">
                                <div class="p-col p-pb-0">
                                    <p-panel header="{{ 'Project-Summary-by-Bill-Partner-Or-Manager' | translate }}">
                                        <div>
                                            <p-table #dt [value]="usersDataSource" dataKey="CustomerContactID"
                                                [rowHover]="true" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                                dataKey="BillingHeaderID" [scrollHeight]="'calc(90vh - 390px)'" [scrollable]="true">
                                                <!-- Table Header -->
                                                <ng-template pTemplate="caption" let-columns>
                                                    <div class="p-grid">
                                                        <label class="p-col-3 p-mb-2 p-py-0 p-text-center"></label>
                                                        <label class="p-col-3 p-mb-2 p-py-0 p-pl-3 p-text-center">---- {{ 'Project-Status' | translate }} ----</label>
                                                        <label class="p-col p-mb-2 p-py-0 p-pl-3 p-text-center">---- {{ 'Projects-Due' | translate }} ----</label>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th class="width-27p p-text-left bg-white" style="color: black !important;">{{ 'bill-partner-or-manager' | translate }}</th>
                                                        <th class="width-7p p-text-center project-summary-pending" style="color: black !important;">
                                                            {{ 'Pending' | translate }}
                                                        </th>
                                                        <th class="width-8p p-text-center project-summary-inprogress" style="color: black !important;">
                                                            {{ 'mgmt-inprogress' | translate }}
                                                        </th>
                                                        <th class="width-15p p-text-center project-summary-review" style="color: black !important;">{{ 'Ready-for-Review' | translate }}
                                                        </th>
                                                        <th class="width-7p p-text-center bg-white" style="color: black !important;">{{ 'Today' | translate }}</th>
                                                        <th class="width-12p p-text-center bg-white" style="color: black !important;">{{ 'This-Week' | translate }}
                                                        </th>
                                                        <th class="width-12p p-text-center bg-white" style="color: black !important;">{{ 'Next-Week' | translate }}
                                                        </th>
                                                        <th class="width-12p p-text-center project-summary-overdue" style="color: black !important;">
                                                            {{ 'Overdue' | translate }}
                                                        </th>
                                                    </tr>
                                                </ng-template>

                                                <ng-template
                                                  pTemplate="body"
                                                  let-rowData
                                                  let-columns="columns"
                                                  let-rowIndex="rowIndex"
                                                >
                                                  <tr>
                                                    <td
                                                      class="
                                                        width-27p
                                                        p-text-left
                                                        p-text-nowrap
                                                      "
                                                    >
                                                      <a
                                                        class="
                                                          hyperlink-text
                                                        "

                                                        routerLink="/project-management/projectList/{{ ddmMainForm.get( 'ViewType' ).value }}"

                                                        [queryParams]="
                                                          {
                                                            redirectStatus: true,
                                                            filterStatus: false,

                                                            StaffID: rowData.StaffID,
                                                            DateRange: ddmMainForm.get( 'dateRange' ).value
                                                          }
                                                        "
                                                      >
                                                        {{ rowData.StaffName }}
                                                      </a>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-7p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-pending
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.pending }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-8p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-inprogress
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.inprogress }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-15p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-review
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.review }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-7p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          bg-blue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.today }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-12p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          bg-blue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.thisweek }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-12p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          bg-blue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.nextweek }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-12p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-overdue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ rowData.pastdue }}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </ng-template>

                                                <ng-template
                                                  pTemplate="footer"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      (
                                                            false
                                                        &&
                                                            (
                                                                  usersDataSource.length
                                                              >   0
                                                            )
                                                      )
                                                    "
                                                  >
                                                    <td
                                                      class="
                                                        width-27p
                                                        p-text-left
                                                        p-text-nowrap
                                                      "
                                                    >
                                                      Total
                                                    </td>

                                                    <td
                                                      class="
                                                        width-7p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-pending
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.pendingProjectSummaryCount }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-8p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-inprogress
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.inProgressProjectSummaryCount }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-15p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-review
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.forReviewProjectSummaryCount }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-7p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          bg-blue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.dueTodayProjectSummaryCount }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-12p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          bg-blue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.dueThisWeekProjectSummaryCount }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-12p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          bg-blue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.dueNextWeekProjectSummaryCount }}
                                                      </div>
                                                    </td>

                                                    <td
                                                      class="
                                                        width-12p
                                                        p-text-center
                                                      "
                                                    >
                                                      <div
                                                        class="
                                                          badge-number
                                                          project-summary-overdue
                                                        "

                                                        style="
                                                          color: black !important;
                                                        "
                                                      >
                                                        {{ staffStatisticData.overdueProjectSummaryCount }}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </ng-template>

                                                <ng-template
                                                  pTemplate="emptymessage"
                                                >
                                                  <tr
                                                    class="emptymessage"
                                                  >
                                                    <td
                                                      [attr.colspan]="8"
                                                      class="p-text-center"
                                                    >
                                                      <i
                                                        class="fa fa-list-alt"
                                                        style="font-size:50px;color:rgba(92, 92, 92, 0.18);margin: 40px 0 5px;"
                                                      >
                                                      </i>
                                                      <p
                                                        style="padding-bottom:30px"
                                                      >
                                                        <strong>
                                                          {{ 'No-Projects-to-Display' | translate }}.
                                                        </strong>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </p-panel>
                                </div>
                            </div>
                        </div>

                        <!-- Tasks Summary By User -->
                        <div class="p-col-12 p-pb-0" *ngIf="ddmMainForm.value?.ViewType === 'tasks'">
                            <div class="p-grid">
                                <div class="p-col p-pb-0">
                                    <p-panel header="{{ 'Task-Summary-by-User' | translate }}">
                                        <div>
                                            <p-table #dt [value]="tasksDataSource" dataKey="TaskDescription"
                                                [rowHover]="true" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                                [globalFilterFields]="globalFilterColumns"
                                                [scrollHeight]="'calc(90vh - 390px)'" [scrollable]="true">
                                                <!-- Table Header -->
                                                <ng-template pTemplate="caption" let-columns>
                                                    <div class="p-grid">
                                                        <span class="p-col-3 p-mb-2 p-py-0">
                                                            <span class="p-input-icon-left">
                                                                <i class="fal fa-search"></i>
                                                                <input #searchValue pInputText type="text"
                                                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                                    placeholder="{{ 'Search-by' | translate }}" />
                                                            </span>
                                                            <span>
                                                                <button pButton pRipple type="button" icon="pi pi-times"
                                                                    class="p-button-rounded p-button-danger p-button-text"
                                                                    (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                                    pTooltip="{{'clear' | translate}}"></button>
                                                            </span>
                                                        </span>
                                                        <label class="p-col-4 p-mb-2 p-py-0 p-pl-3 p-text-center">---- {{ 'task-status' | translate }} ----</label>
                                                        <label class="p-col-4 p-mb-2 p-py-0 p-pl-3 p-text-center">---- {{ 'Tasks-Due' | translate }} ----</label>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th class="width-27p p-text-left bg-white" style="color: black !important;">{{ 'Task-Description' | translate }}</th>
                                                        <th class="width-7p p-text-center project-summary-pending" style="color: black !important;">
                                                            {{ 'Pending' | translate }}
                                                        </th>
                                                        <th class="width-8p p-text-center project-summary-inprogress" style="color: black !important;">
                                                            {{ 'ready-to-work' | translate }}
                                                        </th>
                                                        <th class="width-8p p-text-center project-summary-review" style="color: black !important;">{{ 'Active' | translate }}
                                                        </th>
                                                        <th class="width-7p p-text-center bg-white" style="color: black !important;">{{ 'Today' | translate }}</th>
                                                        <th class="width-12p p-text-center bg-white" style="color: black !important;">{{ 'This-Week' | translate }}
                                                        </th>
                                                        <th class="width-12p p-text-center bg-white" style="color: black !important;">{{ 'Next-Week' | translate }}
                                                        </th>
                                                        <th class="width-12p p-text-center project-summary-overdue" style="color: black !important;">
                                                            {{ 'Overdue' | translate }}
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <!-- Rows -->
                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                                                    <tr>
                                                        <td
                                                          class="
                                                            width-27p
                                                            p-text-left
                                                            p-text-nowrap
                                                          "
                                                        >
                                                          <a
                                                            class="
                                                              hyperlink-text
                                                            "

                                                            routerLink="/project-management/projectList/{{ ddmMainForm.get( 'ViewType' ).value }}"

                                                            [queryParams]="
                                                              {
                                                                redirectStatus: true,
                                                                filterStatus: false,

                                                                StatusTask: rowData.TaskDescription,
                                                                StaffID: ddmMainForm.get( 'staffId' ).value,
                                                                DateRange: ddmMainForm.get( 'dateRange' ).value
                                                              }
                                                            "
                                                          >
                                                            {{ rowData.TaskDescription }}
                                                          </a>
                                                        </td>
                                                        <td class="width-7p p-text-center">
                                                            <div class="badge-number project-summary-pending" style="color: black !important">
                                                                {{rowData.pending}}
                                                            </div>
                                                        </td>
                                                        <td class="width-8p p-text-center">
                                                            <div class="badge-number project-summary-inprogress" style="color: black !important">
                                                                {{rowData.ready}}
                                                            </div>
                                                        </td>
                                                        <td class="width-8p p-text-center">
                                                            <div class="badge-number project-summary-review" style="color: black !important">
                                                                {{rowData.active}}
                                                            </div>
                                                        </td>
                                                        <td class="width-7p p-text-center">
                                                            <div class="badge-number bg-blue" style="color: black !important">
                                                                {{rowData.today}}
                                                            </div>
                                                        </td>
                                                        <td class="width-12p p-text-center">
                                                            <div class="badge-number bg-blue" style="color: black !important">
                                                                {{rowData.thisweek}}
                                                            </div>
                                                        </td>
                                                        <td class="width-12p p-text-center">
                                                            <div class="badge-number bg-blue" style="color: black !important">
                                                                {{rowData.nextweek}}
                                                            </div>
                                                        </td>
                                                        <td class="width-12p p-text-center">
                                                            <div class="badge-number project-summary-overdue" style="color: black !important">
                                                                {{rowData.pastdue}}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <!-- No data -->
                                                <ng-template pTemplate="emptymessage">
                                                    <tr class="emptymessage">
                                                        <td [attr.colspan]="8" class="p-text-center">
                                                            <i class="fa fa-list-alt" style="font-size:50px;color:rgba(92, 92, 92, 0.18);margin: 40px 0 5px;"></i>
                                                            <p style="padding-bottom:30px"><strong>{{ 'No-Task-to-Display' | translate }}.</strong></p>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>

                                        </div>
                                    </p-panel>
                                </div>
                            </div>
                        </div>

                        <!-- Recent Activity for Project & Tasks -->
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col" style="overflow: hidden;">
                                    <p-panel>
                                        <p-header class="p-grid p-jc-between">
                                            <div>
                                                {{ (ddmMainForm.value.ViewType !== 'tasks') ? ('Recent-Activity-Project-Tasks' | translate) : ('Recent-Activity-Tasks' | translate) }}
                                                <i class="fa fa-refresh p-pl-5" tooltipPosition="top" pTooltip="{{ 'refresh' | translate }}" aria-hidden="true" (click)="getProjectFeed()"></i>
                                            </div>
                                            <div class="recent-activity-last-30 p-mt-2">
                                                {{ 'Last-15-Days' | translate }}
                                            </div>
                                        </p-header>

                                        <p-scrollPanel [style]="{width: '100%', height: '264px'}">
                                            <div *ngIf="ddmMainForm.value?.ViewType !== 'tasks'">
                                                <div style="line-height:1.8">
                                                    <div *ngFor="let item of projectFeedDataSource" class="recent-activity-line">
                                                        <i class="fal fa-file-alt p-mx-2 recent-activity-task-color" aria-hidden="true" *ngIf="item.isProject === 'T'"> </i>
                                                        <span *ngIf="item.isProject === 'T'">Task - <strong
                                                                class="recent-activity-task-color">{{item.TemplateName}}
                                                            </strong>
                                                            <span>on Project - </span>
                                                        <span class="recent-activity-project-color"> <strong>
                                                                    {{item.projectname}}</strong></span>
                                                        </span>
                                                        <i class="fal fa-file-alt p-mx-2 recent-activity-project-color" aria-hidden="true" *ngIf="item.isProject === 'P'"> </i>
                                                        <span *ngIf="item.isProject === 'P'">Project - <strong
                                                                class="recent-activity-project-color">{{item.TemplateName}}</strong></span> completed
                                                        <span *ngIf="item.ClientName"> for <strong
                                                                class="recent-activity-client-color">
                                                                {{item.ClientName}}</strong>
                                                        </span>
                                                        <span *ngIf="item.isProject === 'T' && item.StaffNames"> by <strong> {{item.StaffNames}}
                                                            </strong>
                                                        </span>
                                                        <span *ngIf="item.isProject === 'P' && item.StaffName"> by <strong> {{item.StaffName}}
                                                            </strong>
                                                        </span>
                                                        <span> on </span>
                                                        <span class="recent-activity-date-color-project p-mr-3" *ngIf="item.isProject === 'P'"  style="color: black !important">{{item.CompletionDate |
                                                            date:'medium'
                                                            :'UTC' }}</span>

                                                        <span class="recent-activity-date-color-task p-mr-3" *ngIf="item.isProject === 'T'" style="color: black !important">{{item.CompletionDate |
                                                            date:'medium'
                                                            :'UTC'}}</span>
                                                    </div>
                                                </div>
                                                <div style="font-size: 14px;" *ngIf="(projectFeedDataSource.length == 0)">No Completed Projects or Tasks to Display.</div>
                                            </div>
                                            <div *ngIf="ddmMainForm.value?.ViewType === 'tasks'">
                                                <div style="line-height:1.8">
                                                    <div *ngFor="let item of taskFeedDataSource" class="recent-activity-line">
                                                        <i class="fal fa-file-alt p-mx-2 recent-activity-task-color" aria-hidden="true" *ngIf="item.isProject === 'T'"> </i>
                                                        <span *ngIf="item.isProject === 'T'">Task - <strong
                                                                class="recent-activity-task-color">{{item.TemplateName}}
                                                            </strong>
                                                            <span>on Project - </span>
                                                        <span class="recent-activity-project-color"> <strong>
                                                                    {{item.projectname}}</strong></span>
                                                        </span>
                                                         completed
                                                        <span *ngIf="item.ClientName"> for <strong
                                                                class="recent-activity-client-color">
                                                                {{item.ClientName}}</strong>
                                                        </span>
                                                        <span *ngIf="item.isProject === 'T' && item.StaffNames"> by <strong> {{item.StaffNames}}
                                                            </strong>
                                                        </span>
                                                        <span> on </span>

                                                        <span class="recent-activity-date-color-task p-mr-3" *ngIf="item.isProject === 'T'" style="color: black !important">{{item.CompletionDate |
                                                            date:'medium'
                                                            :'UTC'}}</span>
                                                    </div>
                                                </div>
                                                <div style="font-size: 14px;" *ngIf="(taskFeedDataSource.length == 0)">No Completed Tasks to Display.</div>
                                            </div>
                                        </p-scrollPanel>

                                    </p-panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Project Tags -->
                <div
                  *ngIf="
                        (
                              ddmMainForm.value?.ViewType
                          !== 'tasks'
                        )
                  "

                  class="
                    p-col-3
                  "
                >
                  <div
                    class="
                      p-grid
                    "
                  >
                    <div
                      class="
                        p-col
                      "
                    >
                      <p-panel
                        header="{{ 'Project-Tags' | translate }}"
                      >
                        <div
                          class="
                            data
                            ddm-ph
                          "
                        >
                          <div
                            *ngFor="
                              let tag of tagsList
                            "
                          >
                            <div
                              *ngIf="
                                tag.projCount > 0
                              "

                              class="
                                p-text-center
                                p-pb-4
                              "
                            >
                              <div
                                class="
                                  p-mb-2
                                  p-text-nowrap
                                  Tag
                                  cursor-hand
                                "

                                routerLink="/project-management/projectList"

                                [style.color]="tag.textColor"
                                [style.background-color]="
                                      tag.color
                                  &&
                                      (
                                          tag.color !== ''
                                        ? tag.color
                                        : null
                                      )
                                "

                                [queryParams]="
                                  {
                                    redirectStatus: true,
                                    filterStatus: false,

                                    from: 'tags',
                                    tag: tag.index,
                                    StaffID: ddmMainForm.get( 'staffId' ).value,
                                    isProjectTab: ddmMainForm.get( 'ViewType' ).value,
                                    DateRange: ddmMainForm.get( 'dateRange' ).value
                                  }
                                "
                              >
                                {{ tag.label }}
                              </div>
                              <div
                                style="
                                  font-size: 17px;
                                  margin-bottom: 5px
                                "
                              >
                                {{ tag.value }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </p-panel>
                    </div>
                  </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- <p-dialog [(visible)]="isDisplayDetailsTable" [modal]="true" [style]="{width: '50vw'}" [responsive]="true" [draggable]="false" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
    <p-header>{{ 'pm.Create-Project-From-Company-Template' | translate }}</p-header>
    <p-table #dt [value]="templateDataSource" (onFilter)="onFilter($event)" [totalRecords]="templateDataSource.length"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" [scrollable]="true" scrollHeight="calc(90vh - 280px)"
        [rowHover]="true" dataKey="MasterTemplateDetailsID" [globalFilterFields]="['TemplateName']"  sortField="TemplateName" [sortOrder]="1" #detailtab>
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchValue pInputText type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search by" />
                </span>
                <span>
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="Clear"></button>
                </span>
                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2">
                </p-message>
            </div>
        </ng-template>
        <!- Table Header ->
        <ng-template pTemplate="header">
            <tr>
                <th class="width-8p p-text-center">
                </th>
                <th class="width-85p p-text-center" pSortableColumn="TemplateName">
                    {{ 'Template-Names' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="TemplateName"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <!- Rows ->
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="width-8p p-text-center">
                    <p-radioButton name="groupname" [(ngModel)]="selectedTemplate" [value]="rowData" [inputId]="rowData.ProjectHeaderID"
                        (click)="radioCompanyClicked($event,rowData)">
                    </p-radioButton>
                </td>
                <td class="width-85p p-text-left">
                    {{rowData.TemplateName}}
                </td>
            </tr>
        </ng-template>
        <!- No data ->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">
                    <p-message severity="info" text="Data not found." styleClass="p-px-2">
                    </p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton label="{{ 'create' | translate }}" (click)="createProjectScreen()" [disabled]="!isRadioSelected"></button>
        <button pButton label="{{ 'cancel_button' | translate }}" (click)="isDisplayDetailsTable = false"></button>
    </p-footer>
</p-dialog> -->

<div *ngIf="isDisplayDetailsTable">
    <app-create-project-from-template
        (onClose)="isDisplayDetailsTable = false">
    </app-create-project-from-template>
</div>
