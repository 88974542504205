// round.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundNumber'
})
export class RoundNumberPipe implements PipeTransform {
  transform(value: number | undefined): string {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    console.log(numericValue)

    if (isNaN(numericValue)) {
      console.error(`El valor "${numericValue}" no es un número válido.`);
      return '0';
    }

    const digitBeforeDecimal = Math.floor((numericValue * 100) % 10);

    if (digitBeforeDecimal === 9) {
       const val = Math.ceil(numericValue / 0.05) * 0.05;

       return val.toFixed(2).toString();
    } else {
      return numericValue.toFixed(2).toString();
    }
  }
}
