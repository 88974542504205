import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import $ from "jquery";
import swal from "sweetalert2";
import { TasksBudgetComponent } from "../../../shared/components/budgets/tasks-budget/tasks-budget.component"

import { RruleGeneratorComponent } from '../../../shared/components/rrule-generator/rrule-generator.component';
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  RepeatEnum
} from "@app/_services";
import { Table } from "primeng/table";
import { TranslateService } from "@ngx-translate/core";
import { ProjectDetailsComponent } from "../project-details/project-details.component";
declare let numeral: any;
@Component({
  providers:[ProjectDetailsComponent],
  selector: "app-duplicate-projects",
  templateUrl: "./duplicate-projects.component.html",
})
export class DuplicateProjectsComponent implements OnInit {
  @ViewChild("clientsGB") searchClientValue;
  @ViewChild("cientslist") dataTabclientsList: Table;
  @ViewChild("dttask") dt: Table;
  @ViewChild(TasksBudgetComponent) tasksBudgetComponent: TasksBudgetComponent
  public isRowEdit: boolean = false;
  public lastRowIndex: number = -1;
  public isCheckTriggered: boolean = false;
  /* Start - For fetchDdmSettings */
  public companyTags;
  public ddmSettingsObj: any = {};
  /* End - For fetchDdmSettings */

  /* Start - For Fetching Clients */
  public selClient: any = { ClientID: null, ClientName: "" };
  public clientListDatasource: any = [];
  public filteredClients: any[];
  intervalid: any;
  /* End - For Fetching Clients */
  public tasksDataSource: any = [];
  public selectedClientsItems: any = [];

  isDisplayWildcardsTable: boolean = false

  companyId;
  clientGroupCategory: any = [];
  clientsList: any = [];
  CompanyTemplateHeaderID: any = null;
  clientfilteredItemsSize = -1;
  intervalId: any = null;
  dataKey = 'ClientID'
  isManaging: any;

  public settingsData = { UserAssignedID: null, ManagerID: null };
  public tagsList: any = [{ label: "All", value: null, color: "" }];
  public AllStaffsTypes: any = [];
  public StaffOptionsForTasks: any = [];
  public dateRangeList: any = [];
  public projectObj = {
    TurnAround: null,
    ProjectMemo: null,
    isReviewRequired: false,
    TemplateName: "",
    DueDate: null,
    DateReceived: null,
    tagID: null,
    Repeat: "none",
    UserAssignedID: null,
    ManagerID: null,
    IsCompleteInOrder: false,
    isTrackTimeTaskLevel: false,
    TimeAmount: null,
    EngagementTypeID: null,
    RuleString: "",
    BudgetYear: new Date().getFullYear(),
    automaticComputeDuedate: false,
    isPreviousPeriodYearPolicy: true
  };
  public overrideBillingPartner: boolean = false;
  public overrideManager: boolean = false;

  cols: any[];
  _selectedColumns: any = [];
  globalFilterColumns: any = [];

  public isShowBudgetsDialog: boolean = false;
  public previousBudgetObj: any;
  public tasksBudgetChanged: boolean = false;
  public isEngagementChanged: boolean = false;
  public engagementList: any;
  public engValidators = [];

  public showRollOverDialog: boolean = false;
  public templateObj: {
    CompanyTemplateHeaderID: number,
    isEnableRollOver: boolean,
    isFromProject: boolean,
    isDefaultBudgetCompanyRollover: boolean,
    RollOverOption: string,
    PctOptionTime: number,
    PctOptionRev: number
  }
  subscriptionLevel
  yearsList: any = []

  @ViewChild(RruleGeneratorComponent) rruleComponent;
  isProcessing: boolean;
  templateDetails: any;
  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    public mangoUtils: mangoUtils,
    private projectDetailsComponent: ProjectDetailsComponent
  ) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.getYears();
    this.getEngagements()
    this.fetchDdmSettings();
    this.getClientGroupCategory();
    // this.getAllClientsDataSource();

    const url = this.router.url;
    const urlMatch = url.match(/([0-9]+)/);
    if (urlMatch && urlMatch.length) {
      this.CompanyTemplateHeaderID = Number(urlMatch[0]);
      this.getCompanyTemplateDetails(this.CompanyTemplateHeaderID);
      this.getCompanyTemplateHeader(this.CompanyTemplateHeaderID);
    }

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.getStaffList();
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      //restore original order
      const arr = val.map(col => col.field)
      this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
      this.globalFilterColumns = [...['ClientName', 'CustomerTypeDescription'], ...this._selectedColumns.map(col => col.field)];
      this.dataTabclientsList.filterGlobal(this.searchClientValue.nativeElement.value, 'contains');
  }

  initializeColumns(){
    this.cols = this._selectedColumns = [
      // { field: 'ClientName', header: this.translate.instant('client.name'), rowClass:'width-4p p-text-center'},
      { field: 'EngagementName', header: this.translate.instant('engagement.name'), rowClass:'width-12p p-text-left'},
      { field: 'GroupDescriptionIDArrayDesc', header: this.translate.instant('client.group'), rowClass:'width-8p p-text-left'},
      { field: 'StaffAssignedName', header: this.translate.instant('manager'), rowClass:'width-9p p-text-left'},
      { field: 'BillingPartnerName', header: this.translate.instant('Billing_Partner'), rowClass:'width-9p p-text-left'},
    ]
    this.globalFilterColumns = [...['ClientName', 'CustomerTypeDescription'], ...this._selectedColumns.map(col => col.field)]
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management')},
      { label: this.translate.instant('create_duplicate')},
      { label: this.projectObj.TemplateName, icon: "ic-red" }
    ]);
    this.dateRangeList = [
      { label: this.translate.instant('none'), value: "none" },
      { label: this.translate.instant('yearly'), value: "year" },
      { label: this.translate.instant('quarterly'), value: "quarterly" },
      { label: this.translate.instant('user.monthly'), value: "monthly" },
      { label: this.translate.instant('semi_monthly'), value: "semi-monthly" },
      { label: this.translate.instant('user.weekly'), value: "week" },
      { label: this.translate.instant('semi_weekly'), value: "semi-weekly" },
      { label: this.translate.instant('user.daily'), value: "daily" },
      { label: this.translate.instant('Custom'), value: "custom" }
    ];
    this.initializeColumns();
  }

  ngOnInit(): void {
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
  }

  ngAfterViewInit(): void {
    this.searchClientValue.nativeElement.value = this.encrDecSrvc.getObject(
      "duplicateProjects_" + AppConstants.SearchString
    );
    this.searchClientValue.nativeElement.value = '';
    this.dataTabclientsList.filterGlobal(this.searchClientValue.nativeElement.value, 'contains');
  }

  getEngagements() {
    this.engagementList = [];
    this.mangoAPISrvc.getEngagementTypes(this.companyId).subscribe((result: any) => {
      for (let i = 0; i < result.length; i++) {
        if (!result[i].Inactive) {
          this.engagementList.push({
            label: result[i].Description,
            value: result[i].EngagementTypeID
          });
        }
      }

      this.engagementList.unshift({ label: "Select Engagement", value: null })
    })
  }

  handleDropSelectClick(event: any, data: any) {
    if(event.value){
      data["UserAssignedIDArray"] = event.value;
    }
    this.getStaffNames(event.value, data)

    // data["UserAssigned"] = event.value;
    // var label = this.AllStaffsTypes.filter(
    //   (item) => item["value"] == event.value
    // );
    // if (label.length > 0) {
    //   data["StaffName"] = label[0]["label"];
    // } else {
    //   data["StaffName"] = "";
    // }
  }

  getStaffNames(ids, data){
    if(!ids || ids.length == 0){
      data["StaffNames"] = "Unassigned";
      return;
    }
    const labels = this.AllStaffsTypes.filter(
      (item) => ids.includes(item["value"])
    ).map(item => item['StaffName']);
    data["StaffNames"] = labels.join(', ');
  }


  getCompanyTemplateHeader(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateHeader(id).subscribe(
      (data: any) => {
        data.map(function (lineObj, index) {
          parent.getStaffNames(lineObj['UserAssignedIDArray'], lineObj);
          lineObj.DueDate = lineObj.DueDate ? new Date(lineObj.DueDate) : null;
          lineObj.DateReceived = lineObj.DateReceived ? new Date(lineObj.DateReceived) : null;
          lineObj['DetailID'] = lineObj.CompanyTemplateDetailsID
          delete lineObj.CompanyTemplateDetailsID;
          delete lineObj.CompanyTemplateHeaderID;
        });
        parent.tasksDataSource = data;
        parent.breadcrumbService.setItems([
          { label: this.translate.instant('Project-Management')},
          { label: this.translate.instant('create_duplicate')},
          { label: parent.projectObj.TemplateName, icon: "ic-red" }
        ]);
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => parent.mangoAPISrvc.showLoader(false)
    );
  }

  findTagByValue(tagValue, property) {
    const temp = this.tagsList.filter((item) => item["value"] == tagValue);
    return temp[0] ? temp[0][property] : "";
  }

  replaceShortcuts(value, property?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }

    this.projectObj.ProjectMemo = valueArr.join(" ");
  }
  /* End - For Shortcut labels */

  getCompanyTemplateDetails(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateDetails(id).subscribe(
      (data: any) => {
        parent.templateDetails =  Object.assign({}, data[0]);
        parent.projectObj = data[0];
        // @NOTE: SHould not replace wildcards on load.
        // Should only replace once the project template has been duplicated to a client.
        // parent.projectObj.TemplateName = this.mangoUtils.replacePMCaretTemplate(
        //   parent.projectObj.TemplateName,
        //   parent.projectObj.DueDate,
        //   parent.projectObj.isPreviousPeriodYearPolicy,
        //   repeatParse
        // ),
        parent.projectObj.DueDate = data[0]["DueDate"]
          ? this.mangoUtils.addOffset(data[0]["DueDate"])
          : null;
        parent.projectObj.DateReceived = data[0]["DateReceived"]
          // ? moment(data[0]["DateReceived"]).toDate()
          ? this.mangoUtils.removeOffset(data[0]["DateReceived"])
          : null;

        parent.projectObj["UserAssignedID"] = parent.overrideManager ? (parent.projectObj["UserAssignedID"] ?? parent.settingsData["UserAssignedID"]) : null;
        parent.projectObj["ManagerID"] = parent.overrideBillingPartner ? (parent.projectObj["ManagerID"] ?? parent.settingsData["ManagerID"]) : null;

        parent.templateObj = {
          CompanyTemplateHeaderID: parent.CompanyTemplateHeaderID,
          isEnableRollOver: data[0].isEnableRollOver || true,
          isFromProject: true,
          isDefaultBudgetCompanyRollover:
            data[0].isDefaultBudgetCompanyRollover || false,
          RollOverOption: data[0].RollOverOption || "existing",
          PctOptionTime: data[0].PctOptionTime || 0,
          PctOptionRev: data[0].PctOptionRev || 0,
        };

        parent.projectObj["BudgetYear"] =
          parent.projectObj["BudgetYear"] || new Date().getFullYear();
        parent.previousBudgetObj = {
          isTrackTimeTaskLevel: parent.projectObj["isTrackTimeTaskLevel"],
          TimeAmount: parent.projectObj["TimeAmount"],
          BudgetYear: parent.projectObj["BudgetYear"]
        }

        const intervalDDMSettings = setInterval(() => {
          if(Object.keys(parent.ddmSettingsObj).length > 0) {
            clearInterval(intervalDDMSettings)
            if(!!parent.projectObj['EngagementTypeID'] && parent.ddmSettingsObj?.isTrackingTimebyProjects)
              parent.onEngagementChanged()
            else  {
              parent.projectObj['EngagementTypeID'] = null;
              parent.getAllClientsDataSource();
            }
          }
        }, 200)

        parent.mangoAPISrvc.showLoader(false);
      },
      (err) => parent.mangoAPISrvc.showLoader(false)
    );
  }

  onchangeOverride() {
    const parent = this;
    parent.projectObj["UserAssignedID"] = parent.overrideManager ? (parent.templateDetails["UserAssignedID"] ?? parent.settingsData["UserAssignedID"]) : null;
    parent.projectObj["ManagerID"] = parent.overrideBillingPartner ? (parent.templateDetails["ManagerID"] ?? parent.settingsData["ManagerID"]) : null;
  }

  onFilter(obj) {
    this.clientfilteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      "duplicateProjects_" + AppConstants.SearchString,
      obj.filters.global?.value
    );
  }

  clearClientsSearchFilter() {
    this.searchClientValue.nativeElement.value = "";
    // this.dataTabclientsList.reset();
    this.selectedClientsItems = [];
    this.clientfilteredItemsSize = -1;
    this.encrDecSrvc.addObject(
      "duplicateProjects_" + AppConstants.SearchString,
      ""
    );
  }

  calculateRepeatData(item) {
    let newDate = null;

    if (item.Repeat != "none") {
      if (item.Repeat == "year") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY")
          .add(1, "years")
          .utc()
          .toDate();
      } else if (item.Repeat == "quarterly") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY")
          .add(3, "months")
          .utc()
          .toDate();
      } else if (item.Repeat == "monthly") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY")
          .add(1, "months")
          .utc()
          .toDate();
      } else if (item.Repeat == "onetime") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY")
          .add(1, "days")
          .utc()
          .toDate();
      } else if (item.Repeat == "week") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY").add(7, "days").toDate();
      } else if (item.Repeat == "daily") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY").add(1, "days").toDate();
      } else if (item.Repeat == "semi-weekly") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY").add(14, "days").toDate();
      } else if (item.Repeat == "semi-monthly") {
        newDate = moment(item["DueDate"], "DD-MM-YYYY").add(15, "days").utc().toDate();
      }
    }
    item["NextDueDate"] = newDate;
  }

  createDuplicateRecords() {
    if (this.isProcessing) return;

    this.isProcessing = true;
    const self = this;
    const observableBatch = [];

    if (
      self.projectObj.Repeat !== null &&
      self.projectObj.Repeat !== "" &&
      self.projectObj.Repeat !== "none" &&
      self.projectObj.Repeat !== "custom"
    ) {
      const isValid = self.projectDetailsComponent.validateEnteredWildcards(
        self.projectObj["TemplateName"],
        self.projectObj["Repeat"] as RepeatEnum
      );
      if (!isValid) {
        self.isProcessing = false;
        return;
      }
    }

    for (let i = 0; i < self.selectedClientsItems.length; ++i) {
      const clientObj = self.selectedClientsItems[i];
      const newObj = {};
      const tagsArr = [];
      if (self.projectObj.tagID) {
        tagsArr.push(parseInt(self.projectObj.tagID));
      }

      newObj["DueDate"] = self.projectObj.DueDate;
      newObj["DateReceived"] =  moment.utc(self.projectObj.DateReceived, "YYYY-MM-DD").toISOString();
      newObj["automaticComputeDuedate"] = self.projectObj.automaticComputeDuedate;
      newObj["isPreviousPeriodYearPolicy"] = self.projectObj.isPreviousPeriodYearPolicy;
      newObj["ProjectMemo"] = self.projectObj.ProjectMemo;
      newObj["ActualDueDate"] = self.projectObj.DueDate;
      newObj["Repeat"] = self.projectObj.Repeat;
      newObj["RuleString"] = self.projectObj.RuleString;
      if(newObj["Repeat"] !== 'custom')
        self.calculateRepeatData(newObj);
      else {
        newObj["NextDueDate"] = self.rruleComponent.generateRules(newObj["DueDate"], newObj['RuleString'] || '', true)
      }
      newObj["ClientID"] = clientObj.ClientID;
      //@NOTE: TemplateWildcards should have TemplateName before replacing Caret wildcards.
      newObj["TemplateWildcards"] = self.projectObj.TemplateName
      if (
        self.projectObj.Repeat === null ||
        self.projectObj.Repeat === "" ||
        self.projectObj.Repeat === "none" ||
        self.projectObj.Repeat === "custom"
      ) {
        newObj["TemplateName"] = self.projectObj.TemplateName
      } else {
        newObj["TemplateName"] = self.mangoUtils.replacePMCaretTemplate(
          self.projectObj.TemplateName,
          newObj["DueDate"],
          newObj["isPreviousPeriodYearPolicy"],
          newObj["Repeat"]
        );
      }
      newObj["Status"] = "Pending";
      newObj["CompletionDate"] = null;
      newObj["ExtensionDate"] = null;
      // newObj["DateReceived"] = null;
      newObj["CompanyID"] = clientObj.CompanyID;
      newObj["ManagerID"] = this.overrideBillingPartner ? self.projectObj.ManagerID : clientObj['BillingPartnerID'];
      newObj["UserAssignedID"] = this.overrideManager ? self.projectObj.UserAssignedID : clientObj['StaffAssignedID']
      newObj["Tags"] = tagsArr.length == 0 ? null : tagsArr;
      newObj["isCompleted"] = false;
      newObj["isProject"] = "P";
      newObj["isReviewRequired"] = self.projectObj.isReviewRequired;
      newObj["IsCompleteInOrder"] = self.projectObj.IsCompleteInOrder;
      newObj["isPreviousPeriodYearPolicy"] = self.projectObj.isPreviousPeriodYearPolicy;
      newObj["EngagementTypeID"] = self.projectObj.EngagementTypeID;
      newObj["ProjectMemo"] = self.projectObj.ProjectMemo;
      if(newObj["EngagementTypeID"])
        newObj["ProjectMasterID"] = clientObj['ProjectMasterID'];

      if(!!newObj["EngagementTypeID"] && (numeral(self.projectObj.TimeAmount).value() > 0)) {
        newObj["isTrackTimeTaskLevel"] = self.projectObj.isTrackTimeTaskLevel;
        newObj["Budget"] = {
          CompanyID: self.companyId,
          Year: self.projectObj.BudgetYear,
          ProjectMasterID: clientObj["ProjectMasterID"],
          ClientID: clientObj["ClientID"],
          RevenueAmount: 0,
          TimeAmount: numeral(self.projectObj.TimeAmount).value(),
          ddmProjectHeaderID: null,
          isEnableRollOver: self.templateObj.isEnableRollOver,
          RollOverOption: self.templateObj.RollOverOption,
          isDefaultBudgetCompanyRollover:
            self.templateObj.isDefaultBudgetCompanyRollover,
          PctOptionTime: self.templateObj.PctOptionTime,
        };

      } else {
        newObj["Budget"] = null
      }

      newObj["projectTasks"] = self.tasksDataSource.map(task=>{
        task['UserAssignedIDArray'] = task['UserAssignedIDArray']?.length > 0 ? task['UserAssignedIDArray'] : null;
        task["DueDate"] = moment.utc(task["DueDate"], "YYYY-MM-DD").toISOString()
        task["IsTaskReady"] = self.projectObj.IsCompleteInOrder
                              ? (task.TaskRow == 1 ? true : false)
                              : true;
        return task;
      });
      newObj["TasksCompleted"] = 0;
      newObj["TurnAround"] = self.projectObj.TurnAround;
      newObj["TotalNotes"] = 0;
      newObj["TotalTasks"] = self.tasksDataSource.length;
      observableBatch.push(newObj);
    }

    if (observableBatch.length > 0) {
      self.intervalId = setInterval(
        () => self.createProjectHeader(observableBatch),
        2000
      );
    } else {
      self.isProcessing = false;
    }
  }

  getYears() {
    if (this.yearsList?.length > 0) return;

    const value = 5;
    const yearToday = new Date().getFullYear();
    for (let x = yearToday + value; x >= yearToday - 3; x--) {
      this.yearsList.push({ label: x.toString(), value: x });
    }
  }

  createProjectHeader(bulkData) {
    const self = this;
    const observableBatch = bulkData.splice(0, 25);

    if (observableBatch.length == 0) {
      clearInterval(self.intervalId);
      self.isProcessing = false;
      return;
    }
    self.mangoAPISrvc.showLoader(true);

    self.mangoAPISrvc
      .bulkCreateProjectHeaderAndDetails({ observableBatch })
      .subscribe(
        (data: any) => {
          if (bulkData.length > 0) {
            return false;
          }
          swal
            .fire({
              icon: "success",
              title: "Success",
              text: self.selectedClientsItems.length + " Project(s) Created.",
              showCancelButton: false,
              confirmButtonText: "Ok",
            })
            .then((result) => {
              self.selectedClientsItems = [];
            });
          self.mangoAPISrvc.showLoader(false);

          observableBatch.forEach((project) => {
            const logdata = {}
            logdata['Action'] = "Duplicate to Client #"+project.ClientID;
            logdata['Description'] = "Template Name: "+project.TemplateName+ " - CompanyTemplateHeaderID: "+this.CompanyTemplateHeaderID;
            logdata['Table'] = "";

            if (!this.isManaging) {
              this.mangoAPISrvc.addUserLogs(logdata).subscribe((res) => { }, (err) => {
                console.log(err);
              });
            }
          });
        },
        (error) => {
          self.mangoAPISrvc.showLoader(false);
        }
      );
  }

  getAllClientsDataSource() {
    const parent = this;
    const tempList = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);

    parent.clientsList = [];
    parent.dataKey = 'ClientID'
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getAllClientsByCompanyId(parent.companyId).subscribe(
      (clients: any) => {
        clients = clients.filter(
          (clients) =>
            clients.Inactive == false && clients.ContactRecord == false
        );
        clients.map(function (obj) {
          obj["GroupDescriptionIDArrayDesc"] = "";
          obj["GroupDescriptionIDArray"] = obj["GroupDescriptionIDArray"]
            ? obj["GroupDescriptionIDArray"]
            : [];
          if (obj["GroupDescriptionIDArray"].length > 0) {
            const tempValues = obj["GroupDescriptionIDArray"]
              .toString()
              .split(",");
            for (var i = 0; i < tempValues.length; ++i) {
              if (tempValues[i]) {
                const matchedArr = tempList.filter(
                  (item) => item["CustomerGroupCategoryID"] == tempValues[i]
                );
                if (matchedArr.length > 0) {
                  if (i == 0) {
                    obj[
                      "GroupDescriptionIDArrayDesc"
                    ] += tempList.filter(
                      (item) => item["CustomerGroupCategoryID"] == tempValues[i]
                    )[0]["GroupDescription"];
                  } else {
                    obj["GroupDescriptionIDArrayDesc"] =
                      obj["GroupDescriptionIDArrayDesc"] +
                      " , " +
                      tempList.filter(
                        (item) =>
                          item["CustomerGroupCategoryID"] == tempValues[i]
                      )[0]["GroupDescription"];
                  }
                }
              }
            }
          }
        });
        parent.clientsList = clients;
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getClientGroupCategory() {
    const parent = this;
    parent.clientGroupCategory = [
      { label: "Select Group Category", value: -1 },
    ];
    const tempList = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    for (let i = 0; i < tempList.length; i++) {
      parent.clientGroupCategory.push({
        label: tempList[i].GroupDescription,
        value: tempList[i].CustomerGroupCategoryID,
      });
    }
  }

  fetchDdmSettings() {
    const parent = this;

    parent.companyTags = parent.encrDecSrvc.getObject(AppConstants.companyTags);

    if (!parent.companyTags) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
        (res: any) => {
          if (res.length > 0) parent.companyTags = res[0];
          else {
            parent.companyTags = {
              CompanyID: "",
              IsEmailProjectAssigned: false,
              IsEmailTaskAssigned: false,
              IsEmailTaskReady: false,
              IsManagerProjectInProgress: false,
              IsManagerReadyReview: false,
              IsProjectRejected: false,
              Tag1: "Available for Work",
              Tag2: "Extended",
              Tag3: "Urgent",
              Tag4: "Missing Information",
              Tag5: "Waiting on Client",
              Tag6: "Notice",
              Tag7: "Telephone Call",
              Tag8: "Tag 8",
              Tag9: "Tag 9 ",
              ddmSettingsID: null,
              DefaultUserID: null,
              DefaultManagerID: null,
            };
          }

          parent.encrDecSrvc.addObject(
            AppConstants.companyTags,
            parent.companyTags
          );

          // if(parent.companyTags?.isBudgetingProjectLevel) {
          //   this
          //   parent.myCreateForm.get('EngagementTypeID').setValidators(parent.engValidators.concat(Validators.required))
          // } else {
          //   parent.myCreateForm.get('EngagementTypeID').setValidators(parent.engValidators);
          // }
          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      // if(parent.companyTags?.isBudgetingProjectLevel) {
      //   parent.myCreateForm.get('EngagementTypeID').setValidators(parent.engValidators.concat(Validators.required))
      // } else {
      //   parent.myCreateForm.get('EngagementTypeID').setValidators(parent.engValidators);
      // }
    }
    parent.ddmSettingsObj = parent.companyTags;
    if (
      parent.ddmSettingsObj &&
      Object.keys(parent.ddmSettingsObj).length > 0
    ) {
      const tagArray = parent.ddmSettingsObj.TagArray2;
      tagArray?.map((item, i)=>{
        if(item[0] != ''){
          const obj = {};
          obj["label"] = item[0];
          obj["value"] = (i + 1).toString();
          obj["color"] = item[1];
          obj["textColor"] =  item[1] != '' ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(item[1])) : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb('#ffffff'));
          parent.tagsList.push(obj);
        }
      });
      /* for (
        let index = 0;
        index < Object.keys(parent.ddmSettingsObj).length;
        index++
      ) {
        const element = Object.keys(parent.ddmSettingsObj)[index];
        if (element.indexOf("Tag") > -1 && parent.ddmSettingsObj[element]) {
          let obj = {};
          obj["label"] = parent.ddmSettingsObj[element];
          obj["value"] = element.substring(3);
          obj["color"] = element;
          parent.tagsList.push(obj);
        }
      } */
    }
  }

  getStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    this.AllStaffsTypes.unshift({ label: "Unassigned", StaffName: "Unassigned", value: -1, StaffID: -1, Email: null, });
    this.StaffOptionsForTasks = [];
    for (let i = 0; i < item.length; ++i) {
      if(item[i].Inactive)
        continue;
      const obj = {};
      obj["label"] = item[i]["StaffName"];
      obj["value"] = item[i]["StaffID"];
      obj["StaffID"] = item[i]["StaffID"];
      obj["StaffName"] = item[i]["StaffName"];
      obj["StaffNumber"] = item[i]["StaffNumber"];
      obj["Email"] = item[i]["Email"];
      this.AllStaffsTypes.push(obj);
      this.StaffOptionsForTasks.push(obj)
    }
  }

  onRowEditInit1(data, column, index){
    this.removeRowEditMode(true)
    this.dt.editingRowKeys[data.TaskRow] = true;

    if(this.isRowEdit && this.lastRowIndex == index)
      return;

    this.lastRowIndex = index;

    if (column === 'DueDate') {
      this.selectCell('due-date', index);
    } else if (column === 'StaffName') {
      this.selectCell('staff-name', index, '.p-multiselect-trigger');
    } else if (column === 'OffsetDays') {
      this.selectCell('offset-days', index);
    }

    this.isRowEdit = true;
  }

  removeRowEditMode(doNotRemoveFlag?) {
    this.dt.editingRowKeys = {};

    if(!doNotRemoveFlag) this.isRowEdit = false
  }

  checkIfRemoveEdit() {
    if(this.isCheckTriggered)
      return;

    this.isCheckTriggered = true;

    setTimeout(() => {
      const matches =
        document.activeElement.className.match(/p-datepicker/) ||
        document.activeElement.className.match(/p-inputtext/) ||
        document.activeElement?.tagName == 'INPUT' ? [1] : []
      if(!matches || matches?.length == 0) {
        this.removeRowEditMode();
      }
      this.isCheckTriggered = false;
    }, 150)
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`
      colClass += xtraEl ? ` > ${xtraEl}` : ''
      if(clss == 'staff-name'){
        $(colClass).click();
      } else
        $(colClass).select();
    }, 50);
  }

  onEngagementChanged() {
    if(!this.projectObj.EngagementTypeID) {
      this.getAllClientsDataSource();
      return;
    }

    this.dataKey = 'ProjectMasterID'
    this.clientsList = []

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getCustomersByEngagementTypeID(
        this.projectObj.EngagementTypeID,
        this.companyId
      )
      .subscribe(
        (result: any) => {
          const tempList = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
          result.map((obj) => {
            obj["GroupDescriptionIDArrayDesc"] = "";
            obj["GroupDescriptionIDArray"] = obj["GroupDescriptionIDArray"]
              ? obj["GroupDescriptionIDArray"]
              : [];
            if (obj["GroupDescriptionIDArray"].length > 0) {
              const tempValues = obj["GroupDescriptionIDArray"]
                .toString()
                .split(",");
              for (var i = 0; i < tempValues.length; ++i) {
                if (tempValues[i]) {
                  const matchedArr = tempList.filter(
                    (item) => item["CustomerGroupCategoryID"] == tempValues[i]
                  );
                  if (matchedArr.length > 0) {
                    if (i == 0) {
                      obj[
                        "GroupDescriptionIDArrayDesc"
                      ] += tempList.filter(
                        (item) => item["CustomerGroupCategoryID"] == tempValues[i]
                      )[0]["GroupDescription"];
                    } else {
                      obj["GroupDescriptionIDArrayDesc"] =
                        obj["GroupDescriptionIDArrayDesc"] +
                        " , " +
                        tempList.filter(
                          (item) =>
                            item["CustomerGroupCategoryID"] == tempValues[i]
                        )[0]["GroupDescription"];
                    }
                  }
                }
              }
            }
          });
          this.clientsList = result;
          this.mangoAPISrvc.showLoader(false);

          if (this.clientsList.length == 0) {
            swal.fire({
              title: this.translate.instant('Warning'),
              text: this.translate.instant(`pm.duplicates_no_results`),
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: this.translate.instant('Ok'),
            });
          }

        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            "Error",
            AppConstants.fetchErrorMsg
          );
        }
      );
  }

  onShowTasksBudget() {
    if (
      this.subscriptionLevel !== "ENTERPRISE" &&
      this.subscriptionLevel !== "FREE"
    ) {
      swal.fire({
        title: this.translate.instant('access_denied'),
        html: '<div>This feature is only available for <strong>PRO</strong> plan.</div>', //ENTERPRISE is now labeled as PRO
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok'
      })
      return;
    }
    this.isShowBudgetsDialog = true
  }

  onCloseBudgetDialog() {
    this.isShowBudgetsDialog = false
    if(this.shouldDisableBudget()) {
      this.tasksBudgetChanged = false
      this.projectObj.isTrackTimeTaskLevel = this.previousBudgetObj.isTrackTimeTaskLevel
      this.projectObj.TimeAmount = this.previousBudgetObj.TimeAmount
      this.projectObj.BudgetYear = this.previousBudgetObj.BudgetYear
    }
  }

  shouldDisableBudget() {
    const obj = {
      isTrackTimeTaskLevel: this.projectObj.isTrackTimeTaskLevel,
      TimeAmount: this.projectObj.TimeAmount,
      BudgetYear: this.projectObj.BudgetYear,
    }

    return !(
      (this.previousBudgetObj.isTrackTimeTaskLevel !== obj.isTrackTimeTaskLevel) ||
      obj.TimeAmount && (this.previousBudgetObj.TimeAmount !== obj.TimeAmount) ||
      obj.BudgetYear && (this.previousBudgetObj.BudgetYear !== obj.BudgetYear) || this.tasksBudgetChanged
    )
  }

  onTrackTimeChanged() {

  }

  onSaveBudget() {
    const obj = {
      isTrackTimeTaskLevel: this.projectObj['isTrackTimeTaskLevel'],
      BudgetYear: this.projectObj['BudgetYear'],
      CompanyTemplateHeaderID: this.projectObj['CompanyTemplateHeaderID']
    }

    if(!obj.isTrackTimeTaskLevel) {
      obj['TimeAmount'] = this.projectObj['TimeAmount'] || '0.00'
    }

    console.log("Saving...", obj)
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateCompanyTemplateDetails(obj).subscribe(
      (data: any) => {
        this.previousBudgetObj = {...obj}
        // this.isFormValid = false

        if(obj.isTrackTimeTaskLevel && this.tasksBudgetChanged) {
          this.tasksBudgetComponent.onSaveTemplate()
          this.tasksBudgetChanged = false;
        } else {
          this.mangoAPISrvc.notify("success", this.translate.instant('Success'), AppConstants.updateMsg);
          this.mangoAPISrvc.showLoader(false);
        }
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify("error", this.translate.instant('Error'), AppConstants.updateErrorMsg);
      }
    );
  }

  onTasksBudgetSaved(event) {
    const totalTimeAmount = event.totalTimeAmount || 0;
    this.projectObj["TimeAmount"] = this.mangoUtils.doMoneyFormat(
      totalTimeAmount,
      "time"
    );
    this.previousBudgetObj.TimeAmount = this.projectObj["TimeAmount"];
    this.previousBudgetObj.BudgetYear = this.projectObj["BudgetYear"];

    if(event.budgetTasks?.length > 0) {
      this.tasksDataSource = this.tasksDataSource.map((templateTask) => {
        return {
          ...templateTask,
          TimeAmount: event.budgetTasks.filter(
            (budgetTask) =>
              budgetTask.CompanyTemplateDetailsID ==
              templateTask.DetailID
          )[0]?.SavedTimeAmount || 0,
        };
      });
    }
  }

  onTasksBudgetUpdated({isUpdated}) {
    this.tasksBudgetChanged = isUpdated;
  }

  onShowRolloverDialog() {
    this.showRollOverDialog = true
  }

  onCloseRolloverDialog(event) {
    if(event.isSaved) {
      this.templateObj = {
        CompanyTemplateHeaderID: this.CompanyTemplateHeaderID,
        isEnableRollOver: event.isEnableRollOver,
        isFromProject: true,
        isDefaultBudgetCompanyRollover:
          event.isDefaultBudgetCompanyRollover,
        RollOverOption: event.RollOverOption,
        PctOptionTime: event.PctOptionTime,
        PctOptionRev: event.PctOptionRev,
      }
    }
    this.showRollOverDialog = false
  }

  onCompleteRruleDialog(event) {
    if(event.isCompleted) {
      this.projectObj["DueDate"] = event.dueDate
      this.projectObj["RuleString"] = event.rrule
    }
  }

  onChangeRepeat() {
    if(this.projectObj['Repeat'] == 'custom') {
      this.rruleComponent.isDisplayRuleDialog = true
      this.rruleComponent.ngOnInit()
    }
  }

  onOffsetDaysChange(evt, index) {
    if (evt < 1 && index >= 0) this.tasksDataSource[index].dueDateOffsetDaysIndicator = 1;
  }

  onAutoComputeDueDate(evt) {
    if (this.projectObj.DateReceived){
      this.computeDueDate(evt, 0);
    } else if (evt.checked && !this.projectObj.DateReceived) {
      this.projectObj.automaticComputeDuedate = false;
      this.mangoAPISrvc.notify('error', this.translate.instant('Error'), 'Please input In Date.');
    }
  }

  computeDueDate(e, index) {
    if ((e.checked || e > 0) && this.projectObj.automaticComputeDuedate == true) {
      let inDate = this.projectObj.DateReceived;
      this.tasksDataSource.forEach((e, i, a) => {
        if (i < index) {
          inDate = e.DueDate;
          return
        }
        if (!e.dueDateOffsetDaysIndicator) e.dueDateOffsetDaysIndicator = 1;
        e.DueDate = new Date(moment(inDate).add(e.dueDateOffsetDaysIndicator, 'days').format("YYYY-MM-DD"));
        inDate = e.DueDate;
      });
    }
  }

  onOpenWildcardsDialog() {
    if (
      this.projectObj.Repeat === null ||
      this.projectObj.Repeat === "" ||
      this.projectObj.Repeat === "none" ||
      this.projectObj.Repeat === "custom"
    ) {
      swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant(`pm.wildcards_not_allowed_on_empty_repeat`),
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: this.translate.instant('Ok'),
      });
      return
    }

    this.isDisplayWildcardsTable = true
  }
}
