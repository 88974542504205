import { Component, EventEmitter, OnInit } from "@angular/core";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import { Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

enum Exceptions {
  ALWAYS_ALLOW = "Always Allow",
  ALWAYS_DENY = "Always Deny",
  NONE = "NONE",
}
@Component({
  selector: "app-permissions",
  templateUrl: "./permissions.component.html",
})
export class PermissionsComponent implements OnInit {
  public get exceptionLabels(): typeof Exceptions {
    return Exceptions;
  }

  public StaffID: number = null;
  selectedValue: any;
  busy: Promise<any>;
  public selectedUserNameStr: any;
  permissionProfiles: any = [];
  masterPermissions: any = [];
  cols: any[];
  selectedProfile: any;
  selectedProfileToManage: any;
  filteredItemsSize: any;
  isShowAddRoleDialog: boolean = false;
  isShowManageDialog: boolean = false;
  companyID: any;
  groupedPermissions: any;
  formObj: any = { title: "" };
  inputSwitch: boolean = false;

  userExceptionsArr = [];

  userExceptions = {};

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.selectedUserNameStr = this.encrDecSrvc.getObject(
      AppConstants.selectedUserName
    );
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.selectedProfile =
      this.encrDecSrvc.getObject(AppConstants.staffRoleID) || null;
    this.StaffID = this.encrDecSrvc.getObject(AppConstants.usersID);
    this.breadcrumbService.setItems([
      { label: "User List" },
      { label: "User Permissions" },
      { label: this.selectedUserNameStr, icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {
    // this.loadUsersPermissionsData(); //removed - old process
    this.cols = [
      { field: "Description", header: "Module/Feature" },
      { field: "MasterPermissionID", header: "Status" },
    ];

    this.getUserPermissions();
  }

  onCloseAddRoleDialog() {
    this.formObj.title = "";
    this.isShowAddRoleDialog = false;
  }

  getUserPermissions() {
    this.mangoAPISrvc.showLoader(true);
    const data = {};
    this.mangoAPISrvc
      .getUserExceptions(this.StaffID)
      .subscribe(
        (result: any) => {
          this.userExceptionsArr = result?.data ?? [];
        },
        (err) => this.mangoAPISrvc.showLoader(false)
      )
      .add(() => {
        this.mangoAPISrvc.getUserPermissions().subscribe(
          (result: any) => {
            // this.userExceptions = result?.userExceptions;
            this.groupedPermissions = result?.groupedPermissions;
            this.masterPermissions = result?.masterPermissions;
            this.permissionProfiles = result?.staffRoles?.map((role) => {
              return {
                label:
                  role.Title === "Administrator"
                    ? "Administrator (Non-Modifiable)"
                    : role.Title,
                value: role.StaffRoleID,
                role,
              };
            });

            this.masterPermissions?.forEach((masterPermission) => {
              const exception = this.userExceptionsArr?.find(
                (ex) => ex.id == masterPermission.MasterPermissionID
              );
              if (exception) {
                this.userExceptions[exception.id] = {
                  label: this.getExceptionValue(exception.val),
                  val: exception.val,
                };
              } else {
                this.userExceptions[masterPermission.MasterPermissionID] = {
                  label: "NONE",
                  val: null,
                };
              }
            });

            if (!this.selectedProfile) {
              this.selectedProfile = this.permissionProfiles?.find(
                (item) => item.label == "Administrator (Non-Modifiable)"
              )?.value;
            }

            // this.expandNodes(this.userPermissions);
            this.mangoAPISrvc.showLoader(false);
          },
          (err) => {
            this.mangoAPISrvc.notify(
              "error",
              "Error!",
              AppConstants.fetchErrorMsg
            );
            this.mangoAPISrvc.showLoader(false);
          }
        );
      });
  }

  getExceptionValue(value: boolean | null): string {
    if (value === null) {
      return Exceptions.NONE;
    } else if (value === true) {
      return Exceptions.ALWAYS_ALLOW;
    } else {
      return Exceptions.ALWAYS_DENY;
    }
  }

  onChangeException(data) {
    data.label = this.getExceptionValue(data.val);

    let columnsToSave = "";
    Object.keys(this.userExceptions).forEach((key, index) => {
      if (index > 0)
        columnsToSave += `, {${key}, ${this.userExceptions[key].val}}`;
      else columnsToSave += `{${key}, ${this.userExceptions[key].val}}`;
    });

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .updateUserExceptions(
        {
          PermissionExceptions: `{${columnsToSave}}`,
        },
        this.StaffID
      )
      .subscribe(
        (result) => {
          this.mangoAPISrvc.showLoader(false);
        },
        (err) => this.mangoAPISrvc.showLoader(false)
      );
  }

  expandNodes(nodes) {
    for (const node of nodes) {
      node.expanded = true;
    }
  }

  onChangePermissionProfile(event) {
    const value = event.value;
    if (value) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc
        .updateUserByAdmin(this.StaffID, {
          StaffName: this.encrDecSrvc.getObject(AppConstants.selectedUserName),
          StaffRoleID: value,
        })
        .subscribe(
          (result) => {
            this.encrDecSrvc.addObject(AppConstants.staffRoleID, value);
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "success",
              "Success",
              AppConstants.updateMsg
            );
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "error",
              "Error",
              AppConstants.updateErrorMsg
            );
          }
        );
    }
  }

  isAllowed(data: any) {
    return data?.val;
  }

  onCloseManageDialog() {
    this.isShowManageDialog = false;
  }

  showManageDialog() {
    this.selectedProfileToManage = this.selectedProfile;
    this.isShowManageDialog = true;
  }

  showAddDialog() {
    this.isShowAddRoleDialog = true;
  }

  get isManageRoleReadOnly(): boolean {
    const roleSelected = this.permissionProfiles?.find(
      (item) => item.value == this.selectedProfileToManage
    )?.role;
    if (roleSelected) {
      return roleSelected?.isReadOnly;
    }
    return false;
  }

  // onClickAllowDenyAccess(data) {
  //   if (this.isManageRoleReadOnly) {
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Read Only!",
  //       text: `This role cannot be modified!`,
  //       showConfirmButton: true,
  //       allowEscapeKey: true,
  //       allowEnterKey: true,
  //       confirmButtonText: "Ok",
  //     });
  //     return;
  //   }

  //   data.val = !data.val;

  //   if (!data?.masterPermission?.ParentPermissionID) {
  //     const parentPermissionID = data?.id;

  //     this.groupedPermissions[this.selectedProfileToManage]?.forEach(
  //       (item, index) => {
  //         if (item?.masterPermission?.ParentPermissionID === parentPermissionID)
  //           item["val"] = data.val;
  //       }
  //     );
  //   } else {
  //     const parentPermissionID = data?.masterPermission?.ParentPermissionID;

  //     if (parentPermissionID) {
  //       this.groupedPermissions[this.selectedProfileToManage]?.forEach(
  //         (item, index) => {
  //           if (parentPermissionID === item?.id) {
  //             const noOfChildAllowed = this.groupedPermissions[
  //               this.selectedProfileToManage
  //             ]?.reduce((a, b) => {
  //               return b?.masterPermission?.ParentPermissionID ===
  //                 parentPermissionID && b?.val
  //                 ? a + 1
  //                 : a;
  //             }, 0);

  //             item["val"] = noOfChildAllowed > 0 ? true : false;
  //           }
  //         }
  //       );
  //     }
  //   }

  //   let columnsToSave = "";
  //   this.groupedPermissions[this.selectedProfileToManage]?.map(
  //     (item, index) => {
  //       if (index > 0) columnsToSave += `, {${item.id}, ${item.val}}`;
  //       else columnsToSave += `{${item.id}, ${item.val}}`;
  //     }
  //   );

  //   this.mangoAPISrvc.showLoader(true);
  //   this.mangoAPISrvc
  //     .updateStaffRole(
  //       {
  //         RoleValues: `{${columnsToSave}}`,
  //       },
  //       this.selectedProfileToManage
  //     )
  //     .subscribe(
  //       (result: any) => {
  //         this.mangoAPISrvc.notify(
  //           "success",
  //           "Success",
  //           AppConstants.updateMsg
  //         );
  //         this.mangoAPISrvc.showLoader(false);
  //       },
  //       (err) => {
  //         this.mangoAPISrvc.notify(
  //           "error",
  //           "Error",
  //           AppConstants.updateErrorMsg
  //         );
  //         this.mangoAPISrvc.showLoader(false);
  //       }
  //     );
  // }

  onCreateRole() {
    const roleValues = [];
    let rolesToSave = "";
    this.masterPermissions?.forEach((item) => {
      roleValues.push({
        id: item.MasterPermissionID,
        val: true,
        masterPermission: item,
      });
    });

    roleValues.map((item, index) => {
      const nesArr = [];
      nesArr.push(item.id != "" ? item.id : `""`);
      nesArr.push(item.val != "" ? item.val : `false`);
      if (index > 0) rolesToSave += `, {${nesArr.toString()}}`;
      else rolesToSave += `{${nesArr.toString()}}`;
    });
    const obj = {
      RoleValues: `{${rolesToSave}}`,
      Title: this.formObj.title,
      CompanyID: this.companyID,
    };

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.createStaffRole(obj).subscribe(
      (result: any) => {
        this.permissionProfiles.push({
          label: result.data.Title,
          value: result.data.StaffRoleID,
          role: result.data,
        });
        this.selectedProfileToManage = result.data.StaffRoleID;
        this.groupedPermissions[result.data.StaffRoleID] = roleValues;

        this.onCloseAddRoleDialog();
        this.mangoAPISrvc.notify("success", "Success!", AppConstants.createMsg);
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.createErrorMsg
        );
      }
    );
  }

  /*
     Load User Permission Data
   */
  loadUsersPermissionsData() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getUserPermissonsData(this.StaffID)
      .subscribe((data: any) => {
        this.selectedValue = data.Permission;
        this.mangoAPISrvc.showLoader(false);
      }),
      (error) => {
        this.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      };
  }

  /*
    saving User Permission
  */

  savePermissions(id) {
    if (id === 1) {
      var obj = { Permission: 1, PermissionDescription: "Administrator" };
    } else if (id === 2) {
      var obj = { Permission: 2, PermissionDescription: "Manager" };
    } else if (id === 3) {
      var obj = { Permission: 3, PermissionDescription: "Standard User" };
    } else if (id === 4) {
      var obj = { Permission: 4, PermissionDescription: "Limited User" };
    } else {
      var obj = { Permission: 5, PermissionDescription: "Senior Manager" };
    }

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .updateUserPermissonsData(this.StaffID, obj)
      .subscribe((data) => {
        this.mangoAPISrvc.notify("success", "Success", AppConstants.updateMsg);
        this.mangoAPISrvc.showLoader(false);
      }),
      (error) => {
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      };
  }

  deleteRole() {
    const roleSelected = this.permissionProfiles?.find(
      (item) => item.value == this.selectedProfileToManage
    )?.role;
    if (roleSelected?.canDelete) {
      Swal.fire({
        title: this.translate.instant("confirmation"),
        html: this.translate.instant("permissions.delete_staff_role"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.translate.instant("Yes_Proceed"),
        cancelButtonText: this.translate.instant("no_cancel"),
      }).then((result) => {
        if (result.value) {
          this.mangoAPISrvc
            .deleteStaffRole(this.selectedProfileToManage)
            .subscribe(
              (res) => {},
              (err) => console.log("Failed to delete", err)
            );
          this.permissionProfiles = this.permissionProfiles?.filter(
            (profile) => profile.value !== this.selectedProfileToManage
          );
          this.selectedProfile = this.selectedProfileToManage =
            this.permissionProfiles?.length > 0
              ? this.permissionProfiles[0]?.value
              : null;
        }
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Failed to Delete",
        text: `This role cannot be deleted!`,
        showConfirmButton: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: "Ok",
      });
    }
  }

  redirectToUserList() {
    this.encrDecSrvc.addObject(AppConstants.usersID, -1);
    this.router.navigate([
      AppConstants.users + "/" + AppConstants.listRoutePath,
    ]);
    setTimeout(() => {
      this.mangoAPISrvc.fireUserView(true);
    }, 100);
  }
}
