import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_services';
import { CompanyComponent } from '@app/modules/company';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: CompanyComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import("./company-setup/company-setup.module").then((mod) => mod.CompanySetupModule),
    data: { 'plan': ["free", "enterprise"] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CompanyRoutingModule { }
