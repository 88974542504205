import { Component, OnInit } from '@angular/core';
import { MangoApiService } from '@app/_services';

@Component({
    selector: 'app-billing-invoicing',
    templateUrl: './billing-invoicing.component.html'
})
export class billingInvoicingComponent implements OnInit {
    constructor(private mangoAPISrvc: MangoApiService) {
        this.mangoAPISrvc.applyLanguage();
    }
    ngOnInit(): void {

    }
}
