import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-todo',
  templateUrl: './client-todo.component.html'
})
export class ClientTodoComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dt: Table;
  public filteredItemsSize = -1;
  public clientName: string = null;
  public companyId;
  clientID: number = -1;
  public todoList: any = [];
  public staffList: any = [];
  public statusOptions:  SelectItem[] = [];
  defaultFilter: any = "Open";
  private eventTodoSubscription;
  public dateRangeOptions: any[] = [
    { label: "All Dates", value: "All" },
    { label: "Today", value: "Today" },
    { label: "This Month", value: "Month" },
    { label: "This Quarter", value: "Quarter" },
    { label: "This Year", value: "Year" },
    { label: "Next Month", value: "NextMonth" },
    { label: "Next Quarter", value: "NextQuarter" },
    { label: "Next Year", value: "NextYear" },
  ];
  
  public selectedDateRange = "All";

  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, private translate: TranslateService, private router: Router,  public mangoUtils: mangoUtils) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffList = this.encrDecSrvc.getObject(AppConstants.staffList).filter((staff) => !staff.Inactive);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('Todo') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
    
    this.statusOptions = [
      { label: this.translate.instant('all'), value: null },
      { label: this.translate.instant('mgmt-open'), value: "Open" },
      { label: this.translate.instant('closed'), value: "Complete" }
    ];

    this.getTodoListByClient();
  }

  ngOnInit(){
    this.eventTodoSubscription = this.sharedSrvc.toDoDialogData.subscribe(
      (data) => {
        if (data && data["StaffID"]) {
          this.getTodoListByClient();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if(this.eventTodoSubscription) this.eventTodoSubscription.unsubscribe();
  }

  getTodoSchedule(){
    this.getTodoListByClient();
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    this.filteredItemsSize = -1;
  }

  getTodoListByClient(){
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getTodoListByClientID(this.clientID, this.defaultFilter, this.selectedDateRange).subscribe((data: any)=>{
      this.todoList = data.map(itm=>{
        itm['DueDate'] = itm['DueDate'] ? this.mangoUtils.removeOffset(itm.DueDate) : null;
        return itm;
      });
      this.mangoAPISrvc.fireClientBadgeView(true);
      this.mangoAPISrvc.showLoader(false);
    }, err=>{
      this.mangoAPISrvc.showLoader(false);
    })
  }

  editTodo(obj, type?) {
    const selectedDate = null;
    obj["isEditFlow"] = true;
    obj["selectedDate"] = selectedDate;
    this.sharedSrvc.openToDoDialog(obj);
  }

  deleteTodoAlert(obj) {
    const parent = this;
    Swal.fire({
      title: this.translate.instant("confirmation"),
      text: this.translate.instant("Do_you_want_to_delete_this_item"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("yes_delete"),
      cancelButtonText: this.translate.instant("no_delete"),
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true)
        parent.mangoAPISrvc.deleteTodo(obj.ToDoID).subscribe(
          (data: any) => {
            parent.mangoAPISrvc.notify(
              "success",
              this.translate.instant("Success_notify"),
              AppConstants.deleteMessage
            );
            parent.getTodoListByClient();
            parent.mangoAPISrvc.showLoader(false)
            parent.mangoAPISrvc.fireClientBadgeView(true);
          },
          (data) => {
            parent.mangoAPISrvc.showLoader(false)
            parent.mangoAPISrvc.notify("error", this.translate.instant("error"), data.message);
          }
        );
      }
    });
  }

  addTodo() {
    const todoObj = {};
    todoObj["isEditFlow"] = false;
    todoObj["ClientID"] = this.clientID;
    todoObj["ClientName"] = this.clientName;
    // todoObj["isDisableOptions"] = true;
    todoObj["selectedDate"] = new Date();
    todoObj['clientFieldDisabled'] = true;
    this.sharedSrvc.openToDoDialog(todoObj);
  }

  changeStatus(event){
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getTodoListByClientID(this.clientID, this.defaultFilter, this.selectedDateRange).subscribe((data: any)=>{
      this.todoList = data.map(itm=>{
        itm['DueDate'] = this.mangoUtils.removeOffset(itm.DueDate);
        return itm;
      });
      this.mangoAPISrvc.showLoader(false);
    }, err=>{
      this.mangoAPISrvc.showLoader(false);
    })
  }

}
