<div class="layout-content">
  <div class="clientform-btn-top p-mr-3">
    <button
      pButton
      pRipple
      label="{{'save_button' | translate}}"
      icon="fal fa-check"
      [disabled]="(!isFormValid)"
      class="p-button-sm p-pr-2 p-mr-2"
      (click)="saveCompnayData()"></button>
  </div>
  <form [formGroup]="companyDetailForm" novalidate>
    <div class="p-fluid p-formgrid p-grid p-mx-2">
      <div class="p-col-4">
        <div class="card p-mb-3">
          <div class="p-field p-col-12">
            <label class="label-text">{{'company-setup.Company-Name' | translate}}</label>
            <input
              type="text"
              pInputText
              maxlength="70"
              autocomplete="off"
              formControlName="companyName"
              (blur)="buildPortalUrl()" />
          </div>
          <div class="p-field p-col-12">
            <label class="label-text">{{'Street' | translate}} 1</label>
            <input
              type="text"
              pInputText
              autocomplete="nope"
              maxlength="70"
              formControlName="street1" />
          </div>
          <div class="p-field p-col-12">
            <label class="label-text">{{'Street' | translate}} 2</label>
            <input
              type="text"
              pInputText
              autocomplete="nope"
              maxlength="70"
              formControlName="street2" />
          </div>
          <div class="p-field p-col-12">
            <label class="label-text">{{'client.city' | translate}}</label>
            <input type="text" pInputText autocomplete="nope" formControlName="city" />
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field p-col-6">
              <label class="label-text">{{'client.state' | translate}}</label>
              <input
                type="text"
                pInputText
                autocomplete="nope"
                (blur)="companyDetailForm.controls['state'].setValue($event.target.value.toUpperCase())"
                maxlength="2"
                formControlName="state" />
            </div>
            <div class="p-field p-col-6">
              <label class="label-text">{{'client.zip' | translate}}</label>
              <input
                type="text"
                pInputText
                class="p-text-right"
                formControlName="zip"
                maxlength="10"
                autocomplete="nope" />
            </div>
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field p-col-6">
              <label class="label-text">{{'client.time_zone' | translate}}</label>
              <p-dropdown [options]="timeZoneDataSet" formControlName="timezone"></p-dropdown>
            </div>
            <div class="p-field p-col-6">
              <label class="label-text">{{'companyid' | translate}}</label>
              <input
                type="text"
                pInputText
                autocomplete="nope"
                class="p-text-right"
                formControlName="companyId"
                disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <div class="card p-mb-3 p-pb-3">
          <div class="p-formgroup-inline">
            <div class="p-field p-col-6">
              <label class="label-text">{{'Office-Telephone-Number' | translate}}</label>
              <p-inputMask
                mask="(999) 999-9999? x99999"
                autocomplete="nope"
                formControlName="phone">
              </p-inputMask>
            </div>
            <div class="p-field p-col-6">
              <label class="label-text">{{'Fax-Number' | translate}}</label>
              <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="faxNumber">
              </p-inputMask>
            </div>
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field p-col-6">
              <label class="label-text">{{'client.contact_name' | translate}}</label>
              <input
                type="text"
                pInputText
                autocomplete="nope"
                maxlength="50"
                formControlName="companyContact" />
            </div>
            <div class="p-field p-col-6">
              <label class="label-text required-field"
                >{{'email' | translate}}
                <i
                  pTooltip="{{'company-setup.email-tooltip' | translate}}"
                  tooltipStyleClass="tooltip-width-300"
                  class="fa fa-question-circle ic-blue question-tooltip-icon"
                  tooltipPosition="right"></i>
              </label>
              <input type="text" pInputText autocomplete="nope" formControlName="email" required />
            </div>
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field p-col-12">
              <label class="label-text">{{'settings.Year_End_Month' | translate}}</label>
              <p-dropdown [options]="monthOptions" formControlName="yearEndMonth"></p-dropdown>
            </div>
          </div>

          <div class="p-field p-col-12">
            <label class="label-text"
              >{{'company-setup.Client-Portal-URL' | translate}}
              <i
                pTooltip="{{'company-setup.client-portal-tooltip' | translate}}"
                tooltipPosition="right"
                tooltipStyleClass="tooltip-width-450"
                class="fa fa-question-circle ic-blue question-tooltip-icon"
                aria-hidden="true"></i>
            </label>
            <i
              class="fal fa-copy pull-right ic-red cursor-hand p-pt-2"
              tooltipPosition="top"
              pTooltip="{{'company-setup.Copy-URL-to-Clipboard' | translate}}"
              (click)="copyClipBoard(userinput)"></i>
            <i
              class="fa fa-external-link pull-right ic-red cursor-hand p-pt-2 p-pr-2"
              tooltipPosition="top"
              pTooltip="Launch client portal"
              (click)="openHPClipBoard(true)"></i>
            <input
              type="text"
              #userinput
              pInputText
              autocomplete="nope"
              formControlName="PortalURL" />
          </div>

          <div class="p-field p-col-12" *ngIf="isStaxEnrollmentStarted">
            <label class="label-text">{{'Hosted Payments URL' | translate}} </label>

            <i
              class="fal fa-copy pull-right ic-red cursor-hand p-pt-2"
              tooltipPosition="top"
              pTooltip="{{'company-setup.Copy-URL-to-Clipboard' | translate}}"
              (click)="copyHPClipBoard(userhpinput)"></i>
            <i
              class="fa fa-external-link pull-right ic-red cursor-hand p-pt-2 p-pr-2"
              tooltipPosition="top"
              pTooltip="Launch Hosted Payments"
              (click)="openHPClipBoard(false)"></i>
            <input
              type="text"
              #userhpinput
              pInputText
              autocomplete="nope"
              formControlName="HostedPaymentToken"
              readonly />
          </div>
          <div class="p-field p-col-12">
            <label class="label-text"
              >{{'Company-Website' | translate}}
              <i
                pTooltip="{{'company-setup.company-website-tooltip' | translate}}"
                tooltipPosition="right"
                tooltipStyleClass="tooltip-width-450"
                class="fa fa-question-circle ic-blue question-tooltip-icon"
                aria-hidden="true"></i>
            </label>
            <input type="text" pInputText autocomplete="nope" formControlName="WebsiteCompany" />
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <div class="card p-mb-3">
          <h4 class="label-text">{{ 'Company-Logo' | translate }}</h4>
          <div class="p-col-12 timeline-images">
            <div class="p-grid p-my-5">
              <div class="p-col-12 p-text-center p-mb-3">
                <img
                  *ngIf="isImageUrl"
                  class="company-img-app"
                  [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"
                  onError="this.src='assets/img/userForTable.png'" />
                <i *ngIf="!isImageUrl" class="fal fa-user-circle ic-size-70"> </i>
              </div>
              <div class="p-col-12 p-text-center p-mb-3">
                <label class="label-text" style="font-style: italic; font-size: 10px">
                  Note: {{ 'logo_upload-tooltip' | translate }}
                </label>
              </div>
              <div class="p-col-9 p-text-left p-mt-4">
                <p-fileUpload
                  #fileUpload
                  mode="basic"
                  accept="image/*"
                  chooseLabel="{{ 'Upload' | translate }}"
                  [auto]="true"
                  (onSelect)="
                          fileEvent( $event );
                        ">
                </p-fileUpload>
              </div>
              <div class="p-col-3 p-text-right p-mt-4">
                <p-button
                  pRipple
                  label="{{ 'Remove' | translate }}"
                  pTooltip="{{ 'Remove' | translate }}"
                  icon="fal fa-trash-alt"
                  styleClass="p-button-sm p-button-danger"
                  tooltipPosition="top"
                  (click)="
                          removeImage( );
                        ">
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
