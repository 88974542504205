import { Component, OnInit, NgZone, Compiler } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MangoApiService, EncrDecrService, mangoUtils, AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
declare let numeral: any;
import { AppMainComponent } from '@app/app.main.component';
import { SharedComponentsService } from '@app/shared/components';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html'
})
export class AppLoginComponent implements OnInit {
  dark: boolean;

  isForgotPassword: boolean = false;
  public emailNew: string;

  public user = { email: '', password: '' };
  public rememberMe: any = false;
  public superAdminVisible: number[] = AppConstants.superAdminVisible;

  showOTP: boolean = false;
  authcode: string = '';
  tfa: any;
  loginInfo: any;
  staffID: any;
  showEnrollDialog: boolean = false;
  isNotShowElecPayments: boolean = false;
  startScreenPath: string = null;
  userData: any;

  token: string | undefined;
  forgotToken: string | undefined;

  constructor(
    public app: AppMainComponent,
    public sharedSrvc: SharedComponentsService,
    public translate: TranslateService,
    public _authGuard: AuthGuard,
    public mangoUtils: mangoUtils,
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private zone: NgZone,
    private _compiler: Compiler,
  ) {
    this.token = undefined;
    this.forgotToken = undefined;
    localStorage.clear();
    sessionStorage.clear();

    this._compiler.clearCache();

    this.translate.addLangs(['en', 'es']);

    let userDetails = {};

    const isRemember = this.encrDecSrvc.getObject(AppConstants.rememberMe);
    if (isRemember) {
      userDetails = this.encrDecSrvc.getObject(AppConstants.userDetails);
    }

    this.encrDecSrvc.clearStorage();

    if (isRemember) {
      this.encrDecSrvc.addObject(AppConstants.rememberMe, isRemember);
      this.encrDecSrvc.addObject(AppConstants.userDetails, userDetails);
    }

    this.mangoAPISrvc.showLoader(false);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const code = params['code'];
      const scope = params['scope'];

      if (code) {
        this.mangoAPISrvc.showLoader(true);

        this.mangoAPISrvc.loginWithMicrosoftToken({ code }).subscribe(
          result => {
            this.continueLogin(result, true);
          },
          error => {
            this.mangoAPISrvc.showLoader(false);

            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: this.translate.instant('login.Microsoft_not_allowed'),
              showConfirmButton: true
            }).then(result => {
              this._router.navigate(['/login']).then(() => {
                this.logout();

                this.mangoAPISrvc.notifyLogging(
                  this.encrDecSrvc.getObject(AppConstants.isLoggingUser)
                );
              });
            });
          }
        );
      } else {
        this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
      }
    });
  }

  loadGainsightScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (window['aptrinsic']) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = 'https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=AP-TDXTSZT95GLE-2-3&cache_bust=' + new Date().getTime();
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Failed to load Gainsight script'));
        document.head.appendChild(script);
      }
    });
  }

  ngOnInit(): void {
    this._compiler.clearCache();
    this.loadGainsightScript();
    if (this.encrDecSrvc.getObject(AppConstants.rememberMe)) {
      this.rememberMe = true;
      this.user = this.encrDecSrvc.getObject(AppConstants.userDetails);
    }
  }

  onSubmitOTP() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.verifyTFA({
      staffID: this.staffID,
      token: this.authcode,
      secret: this.tfa
    }).subscribe(
      data => {
        this.showOTP = false;
        this.finalLogin(this.loginInfo);
      },
      err => {
        this.mangoAPISrvc.notify('error', 'Error!', err);
        this.clearOTP();
        this.mangoAPISrvc.showLoader(false);
        this.encrDecSrvc.removeObject(AppConstants.token);
      }
    );
  }

  clearOTP() {
    this.showOTP = false;
    this.loginInfo = null;
    this.authcode = '';
  }

  closeOTP() {
    this.clearOTP();
    this.encrDecSrvc.removeObject(AppConstants.token);
  }

  onChangeRememberMe() {
    if (!this.rememberMe) {
      this.encrDecSrvc.removeObject(AppConstants.userDetails);
      this.encrDecSrvc.removeObject(AppConstants.rememberMe);
    }
  }

  continueLogin(res: any, isMicrosoftLogin?: boolean) {
    const self = this;
    const data = res.data;
    if (data?.Inactive == true) {
      self.mangoAPISrvc.showLoader(false);
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'User Inactive.  Login is Not Allowed.',
        showConfirmButton: false,
        timer: 4000
      });
      return false;
    }

    if (!res) {
      self.mangoAPISrvc.showLoader(false);
      Swal.fire({
        icon: 'warning',
        title: 'Information!',
        text: 'Incorrect Email or Password!',
        showConfirmButton: false,
        timer: 3000,
        position: 'top-end'
      });
      return false;
    }

    self.staffID = data.StaffID;

    self.encrDecSrvc.addObject(AppConstants.token, data.token);

    if (isMicrosoftLogin) {
      self.finalLogin(data);
    } else {
      self.mangoAPISrvc.getIsEnableTFA(data.CompanyID).subscribe(
        (resEnableTFA: any) => {
          if (resEnableTFA.isEnableTFA) {
            self.mangoAPISrvc.getTFA().subscribe(
              resTFA => {
                self.tfa = resTFA ? resTFA : null;
                self.loginInfo = data;
                if (self.tfa) {
                  self.showOTP = true;
                  self.mangoAPISrvc.showLoader(false);
                } else self.finalLogin(data);
              },
              err => {
                self.mangoAPISrvc.showLoader(false);
                self.mangoAPISrvc.notifyLogging(false);
              }
            );
          } else self.finalLogin(data);
        },
        err => {
          self.mangoAPISrvc.showLoader(false);
          self.mangoAPISrvc.notifyLogging(false);
        }
      );
    }
  }

  finalLogin(data) {
    let intercomUserReference = '';
    let intercomUserName = '';
    let intercomUserMailAddress = '';
    let intercomSubscriptionPlan = '';
    let intercomUserCreatedAt = 0;
    let intercomCompanyReference = '';
    let intercomCompanyName = '';
    let intercomCompanyContact = '';
    let intercomCompanyMailAddress = '';
    let intercomCompanySize = 0;

    const self = this;

    self.encrDecSrvc.addObject(AppConstants.userName, data.StaffName);
    self.encrDecSrvc.addObject(AppConstants.userEmail, data.Email);
    self.encrDecSrvc.addObject(AppConstants.isLoggingUser, 'true');
    self.encrDecSrvc.addObject(AppConstants.companyID, data.CompanyID);
    self.encrDecSrvc.addObject(AppConstants.resourceID, data.StaffID);
    self.encrDecSrvc.addObject(AppConstants.setupStep, data.SetupStep);
    self.encrDecSrvc.addObject(AppConstants.profileIMGURL, data.ProfilePic);
    self.encrDecSrvc.addObject(AppConstants.staffID, data.StaffID);
    self.encrDecSrvc.addObject(AppConstants.userJustLoggedIn, true);
    self.encrDecSrvc.addObject(AppConstants.isNotShowElecPayments, data.isNotShowElecPayments);

    self.mangoAPISrvc.getIsDMSIshareValue().subscribe((result: any) => {
      self.encrDecSrvc.addObject(AppConstants.isDMSIshare, result?.data?.isDMSIShare);
    });

    self.isNotShowElecPayments = data.isNotShowElecPayments;

    self.encrDecSrvc.addObject(AppConstants.subscriptionLevel, data.SubscriptionLevel);

    intercomUserReference = data.StaffID;
    intercomUserName = data.StaffName;
    intercomUserMailAddress = data.Email;
    intercomSubscriptionPlan = data.SubscriptionLevel;
    intercomUserCreatedAt = data.DatePlanActivated
      ? Math.round(new Date(data.DatePlanActivated).valueOf() / 1000)
      : Math.round(new Date(data.DateSubscribed).valueOf() / 1000);
    intercomCompanyReference = data.CompanyID;
    intercomCompanySize = data.NumberofUsers;

    data.isLockMenu = data.isLockMenu !== undefined ? data.isLockMenu : true;

    localStorage.setItem('language', data.Language);
    localStorage.setItem('isLockMenu', data.isLockMenu);
    localStorage.setItem('isTopMenu', data.isTopMenu);

    if (data.Language == 'Spanish') {
      this.translate.setDefaultLang('es');
      this.translate.use('es');
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

    self.encrDecSrvc.loadDefaultAPI();
    if (data.DateCancelled) {
      self.encrDecSrvc.addObject(AppConstants.dateCancelled, data.DateCancelled);
    }

    if (data.ProfilePic) {
      self.encrDecSrvc.addObject(AppConstants.imageUrl, data.ProfilePic);
    }

    if (data.DateSubscribed) {
      self.encrDecSrvc.addObject(AppConstants.dateSubscribed, data.DateSubscribed);
    }

    if (data.DatePlanActivated) {
      self.encrDecSrvc.addObject(AppConstants.datePlanActivated, data.DatePlanActivated);
    }

    if (self.rememberMe) {
      self.encrDecSrvc.addObject(AppConstants.rememberMe, self.rememberMe);
      self.encrDecSrvc.addObject(AppConstants.userDetails, self.user);
    }

    self.mangoAPISrvc.getProfileData().subscribe((userData: any) => {
      userData['DateLastLogin'] = new Date();

      self.encrDecSrvc.addObject(AppConstants.staffData, userData);

      self.userData = userData;

      self.encrDecSrvc.addObject(
        AppConstants.staffClientTableView,
        userData.isCLientTable || false
      );

      self.mangoAPISrvc.updateProfileView(userData).subscribe(
        (userData: any) => {},
        error => self.mangoAPISrvc.showLoader(false)
      );
    });

    self.mangoAPISrvc.getCompanyDetails(data.CompanyID).subscribe(
      (res: any) => {
        self.app.updateTopMenuView();
        self.app.setLockMenu();
        self.encrDecSrvc.addObject(AppConstants.companyName, res.CompanyName);
        self.encrDecSrvc.addObject(AppConstants.linkToPortal, res.PortalURL);
        self.encrDecSrvc.addObject(AppConstants.companyEmail, res.EmailCompany);
        self.encrDecSrvc.addObject(AppConstants.telephone, res.Telephone);

        intercomCompanyName = res.CompanyName;
        intercomCompanyContact = res.Telephone;
        intercomCompanyMailAddress = res.EmailCompany;
        const salesforce_id = res.salesforce_id === 'f' ? null : res.salesforce_id;

        if (data) {
          const userId = data.salesforce_id ? data.salesforce_id : data.StaffID;
          const accountId = data.CompanyID;
          const userEmail = data.Email;
          const userName = data.StaffName;
          const companyName = intercomCompanyName;
          const userPlan = data.SubscriptionLevel;
          if (userId && accountId && userEmail && userName && companyName && userPlan) {
            window["aptrinsic"]("identify", {
                "id": `mango-user-${userId}`,
                "email": userEmail,
                "firstName": userName
              },
              {
                "id": `mango-account-${accountId}`,
                "name": companyName,
                "Plan": userPlan,
                "sfdcId": salesforce_id
              }
            );
          } else {
            console.error('Error: Critical information missing to initialize Gainsight PX Tag');
          }
        } else {
          console.error("Error: The 'data' object is undefined to initialize Gainsight PX Tag");
        }

        self.mangoAPISrvc.loadDefaultAPI(data.StaffID).subscribe(
          (results: any) => {
            self.encrDecSrvc.addObject(AppConstants.clientCount, results[0]);
            self.encrDecSrvc.addObject(AppConstants.clientGroupCategory, results[1]);
            self.encrDecSrvc.addObject(AppConstants.staffList, results[2]);
            self.encrDecSrvc.addObject(AppConstants.allStaffList, results[14]);
            self.encrDecSrvc.addObject(AppConstants.customerType, results[3]);
            self.encrDecSrvc.addObject(AppConstants.shotHands, results[4]);
            self.encrDecSrvc.addObject(AppConstants.timeAndExpenses, results[5]);
            self.encrDecSrvc.addObject(
              AppConstants.isStaxEnrollmentStarted,
              results[5]['isStaxEnrollmentStarted']
            );
            self.encrDecSrvc.addObject(AppConstants.activityList, results[6]);
            self.encrDecSrvc.addObject(AppConstants.expenses, results[7]);
            self.encrDecSrvc.addObject(AppConstants.invoiceOptions, results[8]);
            self.encrDecSrvc.addObject(AppConstants.noteCategories, results[9]);
            self.encrDecSrvc.addObject(AppConstants.planInfo, results[10]);
            self.encrDecSrvc.addObject(AppConstants.userPermissionObj, results[11]);
            self.encrDecSrvc.addObject(AppConstants.dmsEmailTemplate, results[12]);
            self.encrDecSrvc.addObject(AppConstants.workLocations, results[13]);
            self.encrDecSrvc.addObject(AppConstants.userPermissions, results[15]);

            if (window.screen.width >= 1700 && window.screen.width < 4000) {
              self.encrDecSrvc.addObject(AppConstants.isLaptop, false);
            } else if (window.screen.width >= 1500 && window.screen.width < 1650) {
              self.encrDecSrvc.addObject(AppConstants.isLaptop, false);
            } else {
              self.encrDecSrvc.addObject(AppConstants.isLaptop, true);
            }

            let userPermissionObj = self.encrDecSrvc.getObject(AppConstants.userPermissionObj);

            let userPermissions = self.encrDecSrvc.getObject(
              AppConstants.userPermissions
            )?.RoleValues;

            let staffPermission = parseInt(userPermissionObj.Permission);

            self.mangoAPISrvc.getCompanyMangoDetails(data.CompanyID).subscribe(
              (Pdata: any) => {
                const startScreenPath = results[11].StartScreen
                  ? results[11].StartScreen
                  : Pdata[0].LoadScreen
                  ? Pdata[0].LoadScreen
                  : 'DB';
                self.encrDecSrvc.addObject(AppConstants.companySettingsData, Pdata[0]);

                let msg = this.translate.instant('promotion-update.message');
                if (
                  msg &&
                  !['-', '', 'promotion-update.message'].includes(msg) &&
                  !this.userData.isPromoDoNotShowAgain
                )
                  self.showPromotion(data.StaffID);

                if (Pdata[0].isEnableSystemLocking == true) {
                  let locking = {
                    isEnableSystemLocking: Pdata[0].isEnableSystemLocking ?? false,
                    monthsPreviousToLock: Pdata[0].monthsPreviousToLock ?? 0,
                    effectiveLockDay: Pdata[0].effectiveLockDay ?? 0,
                    warnNumberOfDays: Pdata[0].warnNumberOfDays ?? 0,
                    CompanyID: data.CompanyID,
                    StaffID: data.StaffID
                  };
                  self.encrDecSrvc.addObject(AppConstants.systemLocking, locking);
                  self.verifySystemLocking(locking);
                }

                if (
                  !Pdata[0].isStMIdPresents &&
                  data.SubscriptionLevel != 'FREE' &&
                  !data.isNotShowElecPayments &&
                  data.SetupStep == 5 &&
                  (!userPermissions ||
                    userPermissions?.find(itemPermission => itemPermission.MasterPermissionID == 12)
                      ?.isAccessAllowed)
                ) {
                  self.mangoAPISrvc.showLoader(false);
                  self.encrDecSrvc.addObject('isPaymentScreenShown', true);

                  self.showEnrollDialog = true;
                  self.startScreenPath = startScreenPath;

                  self.updateIntercomContact({
                    userReference: `${intercomUserReference}`,
                    userName: `${intercomUserName}`,
                    userMailAddress: `${intercomUserMailAddress}`,
                    userCreatedAt: intercomUserCreatedAt,
                    subscriptionPlan: `${intercomSubscriptionPlan}`,
                    companyReference: `${intercomCompanyReference}`,
                    companyName: `${intercomCompanyName}`,
                    companyContact: `${intercomCompanyContact}`,
                    companyMailAddress: `${intercomCompanyMailAddress}`,
                    companySize: intercomCompanySize
                  });
                } else {
                  self.mangoAPISrvc.showLoader(false);
                  let routeValue = startScreenPath;

                  setTimeout(function () {
                    self.updateIntercomContact({
                      userReference: `${intercomUserReference}`,
                      userName: `${intercomUserName}`,
                      userMailAddress: `${intercomUserMailAddress}`,
                      userCreatedAt: intercomUserCreatedAt,
                      subscriptionPlan: `${intercomSubscriptionPlan}`,
                      companyReference: `${intercomCompanyReference}`,
                      companyName: `${intercomCompanyName}`,
                      companyContact: `${intercomCompanyContact}`,
                      companyMailAddress: `${intercomCompanyMailAddress}`,
                      companySize: intercomCompanySize
                    });

                    const isTFARequired = Pdata[0].isTFARequired;
                    const isMSRequired = Pdata[0].isMSRequired;
                    const staffInfo = results[2]?.find(staff => staff.StaffID == data.StaffID);
                    if (isTFARequired && !staffInfo?.TFASecret) {
                      self._router.navigate([AppConstants.superAdmin + '/' + AppConstants.twoFA]);
                    } else if (isMSRequired && !staffInfo?.MSEmail) {
                      self._router.navigate([
                        AppConstants.superAdmin + '/' + AppConstants.microsoftIntegration
                      ]);
                    } else if (routeValue == 'DB') {
                      self._router.navigate([AppConstants.dashBoardRoutePath]);
                    } else if (routeValue == 'CL') {
                      self._router.navigate([AppConstants.clientRoutePath]);
                    } else if (routeValue == 'SCH') {
                      self._router.navigate([AppConstants.schedulingRoutePath]);
                    } else if (routeValue == 'DDM') {
                      self._router.navigate([AppConstants.projectManagementRoutePath]);
                    } else if (routeValue == 'TS') {
                      self._router.navigate([AppConstants.timeExpenseRoutePath]);
                    }
                  }, 1000);
                }
              },
              error => {
                self.mangoAPISrvc.showLoader(false);
                console.log(error);
              }
            );
          },
          error => {
            self.mangoAPISrvc.showLoader(false);
          }
        );
      },
      error => {
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  redirectToStaxEnrollScreen() {
    this.saveIsNotShowElecPayments();
    this.showEnrollDialog = false;
    this._router.navigate(['accounting/cards-view']);
  }

  cancelEnrollDialog() {
    this.saveIsNotShowElecPayments();
    this.showEnrollDialog = false;
    if (this.startScreenPath == 'DB') {
      this._router.navigate([AppConstants.dashBoardRoutePath]);
    } else if (this.startScreenPath == 'CL') {
      this._router.navigate([AppConstants.clientRoutePath]);
    } else if (this.startScreenPath == 'SCH') {
      this._router.navigate([AppConstants.schedulingRoutePath]);
    } else if (this.startScreenPath == 'DDM') {
      this._router.navigate([AppConstants.projectManagementRoutePath]);
    } else if (this.startScreenPath == 'TS') {
      this._router.navigate([AppConstants.timeExpenseRoutePath]);
    }
  }

  saveIsNotShowElecPayments() {
    const staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.mangoAPISrvc.updateUsrSelFiltersByUserId(staffID, {
      isNotShowElecPayments: this.isNotShowElecPayments
    }).subscribe(() => {});
  }

  login(isValidForm, form) {
    const self = this;
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    if (!isValidForm) {
      return false;
    }
    self.mangoAPISrvc.showLoader(true);
    const hashPassword = self.encrDecSrvc.hashPassword(self.user.password);
    self.mangoAPISrvc.login({ email: self.user.email, password: hashPassword }).subscribe(
      (res: any) => {
        self.continueLogin(res);

        const userLogs = {};
        userLogs['Action'] = 'User Login';
        userLogs['Description'] = 'User Logged to Mango';
        userLogs['Table'] = '';
        this.mangoAPISrvc.addUserLogs(userLogs).subscribe(
          () => {},
          err => {
            console.log(err);
          }
        );
        this.encrDecSrvc.addObject(AppConstants.isManagingAccount, false);
      },
      err => {
        this.mangoAPISrvc.notify('error', 'Error!', err);
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  forgetPasswd(forgetForm) {
    if (forgetForm.invalid) {
      for (const control of Object.keys(forgetForm.controls)) {
        forgetForm.controls[control].markAsTouched();
      }
      return;
    }
    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.forgotPassword({ email: this.emailNew }).subscribe(
      () => {
        this.mangoAPISrvc.showLoader(false);

        Swal.fire({
          icon: 'warning',
          title: 'Information!',
          text: 'Please check your inbox or spam folder for an email to reset your password.',
          showConfirmButton: false,
          timer: 3000
        });
      },
      err => {
        this.mangoAPISrvc.showLoader(false);

        const res = JSON.parse(err._body);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: res.message,
          showConfirmButton: false,
          timer: 3000
        });
      }
    );
  }

  redirect(page) {
    switch (page) {
      case 'login':
        this._router.navigate(['/login']);
        break;
      case 'subscribe':
        this._router.navigate(['/companySettings/Subscription']);
        break;
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  onMicrosoftSignIn() {
    console.log('Signing in with microsoft...');

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getMicrosoftLogin().subscribe(
      (result: any) => {
        this.mangoAPISrvc.showLoader(false);

        if (result && result.redirectUrl) {
          sessionStorage.setItem('isReload', 'true');

          this.mangoAPISrvc.notify(
            'info',
            'Info!',
            'You will be redirected to Microsoft login page for authentication. Please wait...'
          );

          setTimeout(
            function () {
              window.location.href = result.redirectUrl;
            },
            1000
          );
        }
      },
      error => {
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateIntercomContact(intercomData) {
    if (typeof window['Intercom'] === 'function') {
      window['Intercom']('update', {
        user_id: intercomData.userReference,
        name: intercomData.userName,
        email: intercomData.userMailAddress,
        phone: intercomData.companyContact,

        company: {
          company_id: intercomData.companyReference,
          name: intercomData.companyName,
          phone: intercomData.companyContact,
          plan: intercomData.subscriptionPlan,
          email: intercomData.companyMailAddress,
          size: intercomData.companySize
        },

        created_at: intercomData.userCreatedAt,
        updated_at: Math.round(Date.now() / 1000)
      });
    }
  }

  verifySystemLocking(e) {
    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(e.effectiveLockDay));
    const warningDay = new Date(new Date(lockDay).setDate(e.effectiveLockDay - e.warnNumberOfDays));
    const monthToLock = new Date(
      new Date(lockDay).setMonth(lockDay.getMonth() - e.monthsPreviousToLock)
    ).getMonth();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    if (
      warningDay.toLocaleDateString('en-US') == dayToday.toLocaleDateString('en-US') &&
      !this.userData.lockingDoNotShowAgain
    ) {
      Swal.fire({
        icon: 'warning',
        title: `System Lockdown in ${e.warnNumberOfDays} day(s)!`,
        html: `<p>The system will not allow entries (Time Entry, Invoicing & Cash Receipts) for the month of ${
          months[monthToLock]
        } and previous months.<br><br> Please make sure to put in your transactions before ${lockDay.toLocaleDateString(
          'en-US'
        )}</p>`,
        showDenyButton: true,
        denyButtonText: 'Do not show again',
        showConfirmButton: true
      }).then(result => {
        if (result.isDenied) {
          const userData = this.userData;
          userData.lockingDoNotShowAgain = true;
          this.mangoAPISrvc.updateUser(userData).subscribe(() => {});
        }
      });
    } else if (dayToday >= lockDay) {
      this.mangoAPISrvc.resetSystemWarningLock(e.CompanyID).subscribe(() => {});
    }
  }

  showPromotion(staffID) {
    Swal.fire({
      html: this.translate.instant('promotion-update.message'),
      showConfirmButton: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: 'Close',
      showDenyButton: true,
      denyButtonText: 'Do not show again'
    }).then(result => {
      if (result.isDenied) {
        const userData = this.userData;
        userData.isPromoDoNotShowAgain = true;
        this.mangoAPISrvc.updateUser(userData).subscribe(() => {});
      }
    });
  }
}