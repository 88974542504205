import { mangoUtils } from "@app/_services";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-mentions",
  templateUrl: "./mentions.component.html",
  styleUrls: ["./mentions.component.css"],
})
export class MentionsComponent {
  @Input() inputText!: string;
  @Input() required: boolean;
  @Input() rows: number;
  @Input() id: string;
  @Input() class: string;
  @Input() maxlength: string;
  @Input() disabled: boolean;
  @Input() style: string;
  @Output() inputTextChange = new EventEmitter<string>();
  @ViewChild("inputBox") inputBox;
  showSuggestions = false;
  suggestions: string[] = [];
  mentionRegex = /@([A-Za-z]*)/g;
  constructor(public mangoUtils: mangoUtils) {}

  onInput(text: string): void {
    if (text.includes("@")) {
      const matches = text.match(this.mentionRegex);
      if (matches) {
        const text = matches.map((match) => match.substring(1));
        this.suggestions = this.getSuggestions(text);
        this.showSuggestions = true;
      } else {
        this.showSuggestions = false;
      }
    } else {
      this.showSuggestions = false;
    }
  }

  onInputTextChange() {
    this.inputTextChange.emit(this.inputText)
  }

  getSuggestions(text: string[]): string[] {
    return this.mangoUtils.findChoices(text.toString());
  }

  selectSuggestion(str: string): void {
    const text = this.getText();
    let replaceWord = "";
    if (this.mangoUtils.shortcutLabels.some((shortcut) => shortcut.ShortCutCode == str)) {
      replaceWord = this.mangoUtils.shortcutLabels.find(
        (shortcut) => shortcut.ShortCutCode == str
      )?.Phrase;
    }
    if (replaceWord) {
      const newText = text.replace(this.mentionRegex, `${replaceWord} `);
      this.setInputValue(newText);
      this.onInputTextChange()
    }
    this.inputBox.nativeElement.focus()
    this.showSuggestions = false;
  }

  getText(): string {
    return this.inputText;
  }

  setInputValue(value: string): void {
    this.inputText = value;
  }
}
