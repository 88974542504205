import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { CommonModule } from '@angular/common';

import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { BudgetsModule } from '../../shared/components/budgets/budgets.module';
import { ClientComponent, ClientListComponent, ClientPaymentsComponent, ClientNotesComponent, ClientMailsComponent, ClientTimeSlipsComponent, ClientSettingsComponent, ClientInvoicesComponent, ClientEngagementsComponent,ClientViewComponent, ClientContactsComponent, ClientProjectsComponent, ClientScheduleComponent, ClientTodoComponent, ClientBudgetsComponent } from '@app/modules/client';
import { ClientRoutingModule } from './client-routing.module';
import { CreateProjectFromTemplateModule } from '@app/shared/components/create-project-from-template/create-project-from-template.module';
@NgModule({
  declarations: [ClientComponent, ClientListComponent, ClientViewComponent,  ClientContactsComponent, ClientInvoicesComponent, ClientMailsComponent, ClientNotesComponent, ClientPaymentsComponent, ClientSettingsComponent, ClientTimeSlipsComponent, ClientEngagementsComponent, ClientProjectsComponent, ClientScheduleComponent, ClientTodoComponent, ClientBudgetsComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    ClientRoutingModule,
    BudgetsModule,
    AppTranslationModule,
    CreateProjectFromTemplateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ClientModule { }
