<p-dialog [(visible)]="displayTimerModal" [modal]="true" [style]="{width: '35vw'}"
    [contentStyle]="{overflow: 'visible'}" [maximizable]="false" showEffect="fade" [draggable]="true"
    [resizable]="false" (onHide)="closeTimer(timerTask.id)">
    <p-header *ngIf="!timerTask.id">{{ 'timer.add-timer' | translate }}</p-header>
    <p-header *ngIf="!!timerTask.id">{{ 'timer.edit-timer' | translate }}</p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card">
                <form class="form-horizontal" #timerForm="ngForm">
                    <div class="p-fluid">

                        <!-- Start: Hours-->
                        <div class="p-field">
                            <label class="label-text required-field" for="formattedStr">
                                {{ 'timer.hours' | translate }}
                            </label>
                            <div class="p-grid">
                                <div [ngClass]="!!timerTask.id ? 'p-col-11' : 'p-col-12'">
                                    <p-calendar timeOnly="true" showTime="true" hourFormat="24" [utc]="true"
                                        [showSeconds]="true" [(ngModel)]="timerTask.TotalTime" name="totalTime"
                                        id="totalTime" [disabled]="timerTask.isStarted"
                                        [inputStyle]="{'font-size':'18px'}"
                                        [ngClass]="{'green-text-color':timerTask['isStarted'],'red-text-color':!timerTask['isStarted']}"
                                        (onBlur)="changeStartTime(timerTask.TotalTime)" required></p-calendar>
                                </div>
                                <div class="p-col-1 p-d-flex p-jc-center p-ai-center" *ngIf="!!timerTask.id">
                                    <i *ngIf="!timerTask.isStarted && !!timerTask.id" class="fa fa-play-circle-o"
                                        aria-hidden="true" style="font-size: 25px;color: #808080f5;"
                                        (click)="startTimer(timerTask.id,false);$event.stopPropagation();"></i>
                                    <i *ngIf="timerTask.isStarted  && !!timerTask.id" class="fa fa-pause-circle-o"
                                        aria-hidden="true" style="font-size: 25px;color: #808080f5;"
                                        (click)="stopTimer(timerTask.id);$event.stopPropagation();"></i>
                                </div>
                            </div>
                        </div>
                        <!-- End: Hours-->

                        <!-- Start: Client-->
                        <div class="p-field">
                            <label class="label-text required-field" for="formattedStr">
                                {{ 'timer.client' | translate }}
                            </label>
                            <p-autoComplete [(ngModel)]="selClient" [inputStyle]="{'width':'220px'}"
                                [suggestions]="filteredClients" autocomplete="off" (completeMethod)="filterClients($event)" name="ClientID"
                                id="clientList" (completeMethod)="filterClients($event)" [minLength]="1"
                                placeholder="{{ 'Search-Clients' | translate }}" #client="ngModel" required [dropdown]="true"
                                (onSelect)="handleSelectClick($event.value)" field="ClientName">
                                <ng-template let-client pTemplate="item">
                                    <div class="ui-helper-clearfix">
                                        <div style="margin:8px 0 0 0">{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <!-- End: Client-->

                        <!-- Start: Engagement-->
                        <div class="p-field">
                            <label class="label-text required-field" for="formattedStr">
                                {{ 'timer.engagement' | translate }}
                            </label>
                            <p-dropdown [options]="projectsList" placeholder="{{ 'select-engagement' | translate }}"
                                [(ngModel)]="timerTask.ProjectMasterID" name="itemProjectSelected" [filter]="true"
                                id="itemProjectSelected" #itemProjectSelected="ngModel"
                                [disabled]="selClient?.ClientID == null" required>
                            </p-dropdown>
                        </div>
                        <!-- End: Engagement-->

                        <!-- Start: Activity-->
                        <div class="p-field">
                            <label class="label-text required-field" for="formattedStr">
                                {{ 'timer.activity' | translate }}
                            </label>
                            <p-dropdown [options]="activityList" placeholder="{{ 'select_activity' | translate }}"
                                [(ngModel)]="timerTask.ServiceCodeID" appendTo="body" name="itemServiceCodeSelected"
                                [filter]="true" id="itemServiceCodeSelected" #itemServiceCodeSelected="ngModel"
                                [disabled]="selClient==null" (onChange)="handleActivitySelectClick($event);" required>
                            </p-dropdown>
                        </div>
                        <!-- End: Activity-->

                        <!-- Start: Work Description -->
                        <div class="p-field">
                            <label class="label-text required-field" for="formattedStr">
                                {{ 'timer.work-description' | translate }}
                            </label>
                            <app-mentions [(inputText)]="timerTask.Memo" [required]="companyGlobalSetting.TimeMemoRequired ? 'required' : null"></app-mentions>
                        </div>
                        <!-- End: Work Description -->

                    </div>
                </form>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-file-alt" label="{{ 'Private-Memo' | translate }}"
            (click)="isShowMemo=!isShowMemo" class="pull-left"></button>
        <button pButton pRipple icon="fal fa-plus" label="{{'timer.Convert_to_Time_Entry' | translate}}" *ngIf="!!timerTask.id && convert"
            (click)="convertToTimeEntry()" [disabled]="!timerForm.valid || (companyGlobalSetting.TimeMemoRequired &&!timerTask.Memo)"></button>
        <button pButton pRipple icon="fal fa-check" label="{{ 'save_button' | translate }}" (click)="submitAddTimer();isSaved=true;" *ngIf="!convert" [disabled]="!timerForm.valid || (companyGlobalSetting.TimeMemoRequired &&!timerTask.Memo)">
        </button>
        <button pButton pRipple icon="fal fa-trash-alt" label="{{ 'mgmt-delete' | translate }}" *ngIf="!!timerTask.id && !convert" (click)="deleteTimer()">
        </button>
        <button pButton pRipple icon="fal fa-times" label="{{ 'cancel_button' | translate }}" (click)="closeTimer(timerTask.id)"></button>
    </ng-template>
</p-dialog>
<p-sidebar [(visible)]="isShowMemo" [baseZIndex]="10001" position="bottom" styleClass="p-sidebar-md">
    <div class="card">
        <div class="p-field p-col-12">
            <label class="label-text">{{ 'Private-Memo' | translate }}</label>
            <app-mentions [(inputText)]="timerTask.PrivateMemo" style="width: 100%; resize: vertical;"></app-mentions>
        </div>
    </div>
</p-sidebar>