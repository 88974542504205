import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { LinkService, ImageService, HtmlEditorService, ToolbarService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { AppConstants } from '@app/_helpers/api-constants';

@Component({
  selector: 'app-email-signature',
  templateUrl: './email-signature.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class EmailSignatureComponent implements OnInit {

  @ViewChild('customRTE')
  public rteObj: RichTextEditorComponent;

  public editorValue: any;
  public target: HTMLElement = document.getElementById('rteSection');
  public selectedUserNameStr: any;
  public imageSettings = {
    saveFormat: "Base64"
  };
  public emailTools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', '|',
      'FontName', 'FontSize', 'FontColor',
      'Alignments', 'Image', '|', 'OrderedList', 'UnorderedList',
      'CreateLink']
  };
  public emailSingnatureObj: any = { "EmailSignatureID": null, "CompanyID": null, "StaffID": null, "HtmlBodySignature": '' };
  public saveDisabled: boolean = true;

  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService) {
    this.emailSingnatureObj['CompanyID'] = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.emailSingnatureObj['StaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.userName);
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'My Profile' },
      { label: 'Email Signature' },
      { label: this.selectedUserNameStr, icon: 'ic-red' }
    ]);

    this.getEmailSignature();
  }

  getEmailSignature() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailSignature(parent.emailSingnatureObj.StaffID).subscribe((response:any) => {
      parent.emailSingnatureObj = response;
      if (Object.keys(response).length == 0) {
        parent.emailSingnatureObj["CompanyID"] = this.encrDecSrvc.getObject(AppConstants.companyID);
        parent.emailSingnatureObj["StaffID"] = this.encrDecSrvc.getObject(AppConstants.staffID);
        parent.emailSingnatureObj["HtmlBodySignature"] = '';
      } else {
        parent.editorValue = response.HtmlBodySignature;
      }
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  saveSignature() {
    const parent = this;
    parent.emailSingnatureObj['HtmlBodySignature'] = this.editorValue;
    parent.mangoAPISrvc.showLoader(true);
    if (parent.emailSingnatureObj.EmailSignatureID) {
      parent.mangoAPISrvc.updateEmailSignature(parent.emailSingnatureObj).subscribe((data) => {
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        parent.mangoAPISrvc.showLoader(false);
      }, (error) => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
       parent.mangoAPISrvc.showLoader(false);
     })
    } else {
      parent.mangoAPISrvc.createEmailSignature(parent.emailSingnatureObj).subscribe((data:any) => {
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
        parent.emailSingnatureObj = data.data;
        parent.mangoAPISrvc.showLoader(false);
      }, (error) => {
         parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
         parent.mangoAPISrvc.showLoader(false);
      })
    }
  }

  onChange() {
    this.saveDisabled = false;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }
}
