import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyRoutingModule } from './company-routing.module';
import { AppSharedModule } from '@app/shared/modules';
import { CompanyComponent, CompanySetupModule } from '@app/modules/company';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
@NgModule({
  declarations: [CompanyComponent],
  imports: [
    CommonModule,
    CompanyRoutingModule,
    AppSharedModule,
    AppTranslationModule,
    CompanySetupModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CompanyModule { }
