
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavigationStart, Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService } from '@app/_services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'engagements',
	templateUrl: './engagements.component.html'
})
export class engagementsComponent implements OnInit, AfterViewInit {
	items: MenuItem[];
	projectID: number = -1;
	editMode: boolean = false;
	isEngagementList: boolean = false;
	engagementViewChangeSubscription: Subscription;
	engagementBadgeChangeSubscription: Subscription;
	selectedMenu: MenuItem;
	selectedMenuIndex: number = 0;
	selectedUrl: any;
	allCounts: any = { "contacts": 0, "timeslips": 0, "notes": 0, "invoices": 0, "payments": 0, "clientID": -1, "projects": 0 };

	routerSubscription: Subscription;

	constructor(private router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private translate: TranslateService) {
		this.mangoAPISrvc.applyLanguage();
		this.routerSubscription = router.events.subscribe((event) => {
			if(event instanceof NavigationStart) {
				this.isEngagementList = event.url === `/${AppConstants.engagementsRoutePath}/${AppConstants.listRoutePath}`;
			}
		})

		this.engagementViewChangeSubscription = this.mangoAPISrvc.engagementViewChange$.subscribe((data) => {
			this.redirectScreens();
		});

		this.engagementBadgeChangeSubscription = this.mangoAPISrvc.engagementBadgeViewChange$.subscribe((data) => {
			this.getAllCounts();
		});
	}

	ngOnInit() {

	}

	ngAfterViewInit() {
		
	}

	ngOnDestroy() {
		this.routerSubscription.unsubscribe();
	}

	removeEngagementCache() {
		this.encrDecSrvc.removeObject(AppConstants.selectedClientRecord);
		this.encrDecSrvc.removeObject(AppConstants.projectID);
		this.encrDecSrvc.removeObject(AppConstants.clientID);
		this.encrDecSrvc.removeObject(AppConstants.ClientName);
		this.encrDecSrvc.removeObject(AppConstants.selectedEngagementName);
		this.encrDecSrvc.removeObject(AppConstants.engagementsRoutePath + 'C' + AppConstants.SearchString);
		this.encrDecSrvc.removeObject(AppConstants.engagementsRoutePath + 'E' + AppConstants.SearchString);
		this.encrDecSrvc.removeObject(AppConstants.engagementsRoutePath + 'H' + AppConstants.SearchString);
		this.encrDecSrvc.removeObject(AppConstants.engagementsRoutePath + 'U' + AppConstants.SearchString);
		this.encrDecSrvc.removeObject(AppConstants.engagementsRoutePath + 'N' + AppConstants.SearchString);		
	}

	redirectScreens() {
		this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
		this.isEngagementList = this.projectID ? false : true;
		if (this.isEngagementList) {
			this.removeEngagementCache();
			this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
		} else if (this.projectID === -1) {
			this.removeEngagementCache();
			this.encrDecSrvc.removeObject(AppConstants.selectedClientRecord);
			this.editMode = false;
			this.items = [
				{ label: AppConstants.Main, routerLink: [AppConstants.viewRoutePath], icon: 'fas fa-user-circle' }
			];
			this.selectedMenu = this.items[0];
			this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.viewRoutePath]);
		} else {
			this.setMenuIndex();
			this.editMode = true;
			const interval = setInterval(() => {
				if(!this.translate.translations[this.translate.currentLang])
				  return;
				clearInterval(interval);
				this.loadMenu();
			  }, 300);
			this.router.navigate([this.selectedUrl]);
			const interval1 = setInterval(() => {
				const els = document.querySelectorAll('[class*=add-badge-]');
				if(els.length == 0) return;
				  clearInterval(interval1);
				  this.addBadges();
			}, 300);
		}
	}

	loadMenu() {
		const subscription = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
		this.items = [
			{ label: AppConstants.Main, routerLink: [AppConstants.viewRoutePath], icon: 'fal fa-user', },
			{ label: this.translate.instant('billing-invoicing.invoice-history'), routerLink: [AppConstants.InvoiceHistory], icon: `fal fa-file-check b-badge-invoices add-badge-${this.allCounts.invoices} badge-class-danger` },
			{ label: this.translate.instant('billing-invoicing.apply-to-expenses'), routerLink: [AppConstants.expenses], icon: `fal fa-sack-dollar b-badge-payments add-badge-${this.allCounts.payments} badge-class-danger` },
			{ label: this.translate.instant('Open-Time'), routerLink: [AppConstants.UnbilledTime], icon: `fal fa-clock b-badge-timeslips add-badge-${this.allCounts.timeslips} badge-class-danger` },
			{ label: this.translate.instant('client.contacts'), routerLink: [AppConstants.contactsRoutePath], icon: `fal fa-users b-badge-contacts add-badge-${this.allCounts.contacts} badge-class-danger` },
			{ label: this.translate.instant('notes'), routerLink: [AppConstants.noteRoutePath], icon: `fal fa-sticky-note b-badge-notes add-badge-${this.allCounts.notes} badge-class-danger` }
		];
		if(subscription.toUpperCase() == "PRO" || subscription.toUpperCase() == "ENTERPRISE"  || subscription.toUpperCase() == "FREE"){
			this.items.push({ label: this.translate.instant('Projects'), routerLink: [AppConstants.projectsRoutePath], icon: `fal fa-building b-badge-projects add-badge-${this.allCounts.projects} badge-class-danger` })
		  }
		this.selectedMenu = this.items[this.selectedMenuIndex];
	}

	setMenuIndex() {
		const url = this.router.url;
		this.selectedUrl = "";
		switch (url) {
			case "/engagements/InvoiceHistory":
				this.selectedMenuIndex = 1;
				this.selectedUrl = "engagements/InvoiceHistory";
				break;
			case "/engagements/expenses":
				this.selectedMenuIndex = 2;
				this.selectedUrl = "engagements/expenses";
				break;
			case "/engagements/UnbilledTime":
				this.selectedMenuIndex = 3;
				this.selectedUrl = "engagements/UnbilledTime";
				break;
			case "/engagements/contacts":
				this.selectedMenuIndex = 4;
				this.selectedUrl = "engagements/contacts";
				break;
			case "/engagements/notes":
				this.selectedMenuIndex = 5;
				this.selectedUrl = "engagements/notes";
				break;
			case "/engagements/projects":
				this.selectedMenuIndex = 6;
				this.selectedUrl = "engagements/projects";
				break;
			default:
				this.selectedMenuIndex = 0;
				this.selectedUrl = "engagements/view";
		}
	}

	getAllCounts() {
		const parent = this;
		const projectID = parent.encrDecSrvc.getObject(AppConstants.projectID);
		parent.encrDecSrvc.removeObject(AppConstants.allDataCountsFoEnagagement);
		if (projectID && projectID > 0) {
			parent.mangoAPISrvc.showLoader(true);
			parent.mangoAPISrvc.getAllEngagementDataCounts(projectID).subscribe(function (data) {
				parent.allCounts = data;
				parent.encrDecSrvc.addObject(AppConstants.allDataCountsFoEnagagement, data);
				parent.updateBadge();
				parent.mangoAPISrvc.showLoader(false);
			});
		}
	}

	updateBadge() {
		const parent = this;
		const els = document.querySelectorAll('[class*=z-badge-]');
		for (const el of Array.from(els)) {
			const matches = el.className.match(/z-badge-(\S+)/)
			const badgeVal = matches ? matches[1] : '';
			const newValue = parent.allCounts[badgeVal];
			el.classList.remove('bg-white');
			if (newValue > 0) {
				el.classList.remove('bg-white', 'p-d-none');
				el.classList.add('bg-dark-blue');
				el.textContent = newValue;
			} else {
				el.textContent = '0';
				el.classList.add('bg-white');
				el.classList.add('p-d-none');
			}
		}
	}

	addBadges() {
		const els = document.querySelectorAll('[class*=add-badge-]');
		for (const el of Array.from(els)) {
			let matches = el.className.match(/add-badge-(\S+)/);
			const badgeVal = matches ? matches[1] : '';
			const bmatches = el.className.match(/b-badge-(\S+)/);
			const badgebVal = bmatches ? bmatches[1] : '';

			const badgeText = badgeVal.replace(/\\\-/g, '__dash__').replace(/\-/g, ' ').replace('__dash__', '-');
			const badgeTextNode = document.createTextNode(badgeText);
			matches = el.className.match(/badge-class-(\S+)/);
			const badgeClass = matches ? matches[1] : 'danger';
			const badge = document.createElement('span');
			badge.classList.add('p-badge');
			badge.classList.add('p-ml-2');
			badge.classList.add('z-badge-' + badgebVal);
			if (parseInt(badgeVal) > 0) {
				badge.classList.add('bg-dark-blue');
			} else {
				badge.classList.add('bg-white');
				badge.classList.add('p-d-none');
			}
			badgeClass && badgeClass !== 'none' ? badge.classList.add('p-badge-' + badgeClass) : null;
			badge.appendChild(badgeTextNode);

			el.nextElementSibling.appendChild(badge);
			el.classList.remove('add-badge-' + badgeVal, 'badge-class-' + badgeClass);
		}
	}

}
