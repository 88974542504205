import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService } from '@syncfusion/ej2-angular-schedule';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MenuModule, ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { KanbanModule, KanbanAllModule  } from '@syncfusion/ej2-angular-kanban';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InboxComponent } from './inbox/inbox.component';
import { PaginatorModule } from 'primeng/paginator';
import { MenubarModule } from 'primeng/menubar';
import { EmailUsernamePipe } from './email-username.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { EmailSendBoxComponent } from './email-send-box/email-send-box.component';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [DashboardComponent,InboxComponent,EmailUsernamePipe, EmailSendBoxComponent],
  imports: [
    AutoCompleteModule,
    ReactiveFormsModule,
    MessageModule,
    MessagesModule,
    InputTextModule,
    EditorModule,
    DropdownModule,
    FormsModule,
    TooltipModule,
    KanbanAllModule,
    KanbanModule,
    ProgressSpinnerModule,
    PaginatorModule,
    MenubarModule,
    CommonModule,
    AppSharedModule,
    DashboardRoutingModule,
    ScheduleModule,
    RecurrenceEditorModule,
    ButtonModule,
    CheckBoxModule,
    GridModule,
    AppTranslationModule,
    MenuModule,
    ContextMenuModule
  ],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DashboardModule { }
