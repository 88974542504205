<div class="debit-memo-dialog">
  <p-dialog
    [(visible)]="showDebitMemoDialog"
    [modal]="true"
    [style]="{ width: '30vw' }"
    [contentStyle]="{overflow: 'visible'}"
    [baseZIndex]="1"
    [maximizable]="false"
    showEffect="fade"
    [draggable]="true"
    [resizable]="false"
    (onHide)="onCloseDebitMemoDialog()"
  >
    <p-header>
      {{ "debit_memo" | translate }}
    </p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <form #reassignForm="ngForm" class="p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-col-4 p-mt-4">
            <label class="label-text">{{ "amount" | translate }}</label>
          </div>
          <div class="p-col-8 p-mt-4">
            <p-inputNumber [(ngModel)]="amountUnapplied" mode="currency" type="number"
                inputStyleClass="p-text-right" currency="USD" locale="en-US" autocomplete="off"
                disabled="true">
            </p-inputNumber>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="p-col-4 p-mt-4">
            <label class="required-field label-text">{{
              "description" | translate
            }}</label>
          </div>
          <div class="p-col-8 p-mt-4">
            <app-mentions [(inputText)]="formObj['description']" id="desc" [rows]="3" style="width:100% !important; resize: vertical;"></app-mentions>
          </div>
        </div>
      </form>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
        <button
          type="button"
          pButton
          pRipple
          label="{{ 'Save' | translate }}"
          (click)="onSave()"
          [disabled]="formObj.description?.length == 0"
        ></button>
      </div>
    </ng-template>
  </p-dialog>
</div>
