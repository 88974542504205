import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { MenuItem } from 'primeng/api';
import { environment } from "@environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RRule } from "rrule";

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html'
})
export class ClientDetailsComponent implements OnInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('searchTemplateValue') searchTemplateValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  filteredTemplateItemsSize = -1;
  searchTextStr: any = "";
  searchTemplateTextStr: any = "";

  public projectsList: any = [{ label: "All", value: null }];
  public clientsCols: any[];
  public projectsDataSource: any = [];
  public clientDetails: any = { ClientName: '', Email: '', BusStreet1: '', BusStreet2: '', Office: '' };
  public tagsList: any = [{ label: "All", value: null, color: '' }];
  busy: Promise<any>;
  public clientId;
  public companyId ;
  public projectObj = { "TemplateName": null, "tagID": null, "dueDateID": null, "statusID": null, "staffId": null, "managerId": null };
  public selectedClientsItems: any = [];
  public todayDate = moment().format('YYYY-MM-DD');
  buttonsList: MenuItem[];
  public templateDataSource: any = [];
  buttonsDeleteList: MenuItem[];
  selectedTemplate

  public dueDateList: any = [{ label: "All", value: null }, { label: 'No Due Date', value: 'noduedate' }, { label: 'Past Due', value: 'pastdue' }, { label: 'Due Today', value: 'duetoday' }, { label: 'Due Tomorrow', value: 'duetomorrow' }, { label: 'Due This Week', value: 'duethisWeek' }, { label: 'Due Next Week', value: 'duetNextWeek' }, { label: 'Due This Month', value: 'duethismonth' }, { label: 'Due Next Month', value: 'duetNextMonth' }, { label: 'Due This Year', value: 'duethisyear' }];
  public statusList: any = [{ label: "All Active", value: null }, { label: 'Pending', value: 'Pending' }, { label: 'In Progress', value: 'In Progress' }, { label: 'Ready For Review', value: 'Ready for Review' }, { label: 'Completed', value: 'Completed' }];
  public AllStaffsTypes: any = [{ label: "All", value: null }];
  public isDisplayDetailsTable: boolean = false;
  public isRadioSelected: boolean = false;
  isManaging: any;


  constructor(
    private authGuard: AuthGuard,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    public mangoUtils: mangoUtils,
  ) {
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.projectManagementRoutePath + 'CD&' + AppConstants.SearchString);
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('client_details'), icon: 'ic-red' },
    ]);
  }

  ngOnInit(): void {
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
    this.searchTemplateTextStr = this.encrDecSrvc.getObject(
      "projTemplate_" + AppConstants.SearchString
    );

    const parent = this;

    const url = parent.router.url;
    const urlMatch = url.match(/([0-9]+)/);
    if (urlMatch && urlMatch.length) {
      parent.clientId = Number(urlMatch[0]);
      parent.ddmCustomerbyClientID();
      parent.ddmProjectHeaderbyClientID(null, null, null, null, parent.clientId, null, null, parent.companyId);
    }

    parent.getProjectNames(parent.companyId);
    parent.getStaffList();
    parent.fetchDdmSettings();
    parent.getCompanyTemplateDetails();
    this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);

    this.buttonsList = [
      {
        label: 'New Project', icon: 'fa fa-plus', command: () => {
          parent.redirectProject();
        }
      },
      {
        label: 'Create from Company Template', icon: 'fa fa-plus', command: () => {
          parent.isDisplayDetailsTable = true;
        }
      }
    ];

    this.buttonsDeleteList = [
      {
        label: 'Delete', icon: 'fa fa-trash-o', command: () => {
          parent.deleteItems();
        }
      },
      {
        label: 'Complete', icon: 'fa fa-cloud-upload', disabled: (parent.selectedClientsItems.length > 1),
        command: () => {
          parent.completeItems();
        }
      }
    ];
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.projectManagementRoutePath + 'CD&' + AppConstants.SearchString, "");
  }

  getToolTip(obj) {
    return obj.NextDueDate
      ? "Next Due Date : " + moment(obj.NextDueDate).format("MM-DD-YYYY")
      : "";
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.projectManagementRoutePath + 'CD&' + AppConstants.SearchString, obj.filters.global.value);
  }

  clearSearchTemplateFilter() {
    this.searchTemplateValue.nativeElement.value = this.searchTemplateTextStr = "";
    this.filteredTemplateItemsSize = -1;
    this.encrDecSrvc.addObject(
      "projTemplate_" + AppConstants.SearchString,
      ""
    );
  }

  onTemplateFilter(obj) {
    this.filteredTemplateItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject('projTemplate_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  completeItems() {
    const self = this;
    event.stopPropagation();
    Swal.fire({
      title: 'Confirmation',
      text: 'Do you want to Process this record(s)?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, complete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        for (let i = 0; i < self.selectedClientsItems.length; i++) {
          const selectedOneObj = self.selectedClientsItems[i];

          (function (index, selectedOneObj, size) {
            setTimeout(() => {
              self.radioClicked(selectedOneObj);
              const logdata = {}
              logdata['Action'] = (selectedOneObj.Status == "Completed") ? "Completed Project/s" : "Project/s Ready for Review";
              logdata['Description'] = "ProjectID: " + selectedOneObj.ProjectHeaderID + ` --${self.clientDetails.ClientName}`
              logdata['Table'] = "";

              if (!self.isManaging) {
                self.mangoAPISrvc.addUserLogs(logdata).subscribe((res) => {}, (err) => {
                  console.log(err);
                });
              }
            }, i * 10);
            if (index == size) {
              setTimeout(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Information!',
                  text: self.translate.instant('records-processed-successfully'),
                  showConfirmButton: true,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  confirmButtonText: 'Ok'
                }).then((result) => {
                  if (result.value) {
                    self.refreshTable();
                  }
                })
              }, 500);
            }
          })(i, selectedOneObj, (self.selectedClientsItems.length - 1));
        }
      }
    })
  }


  batchSaveAllTasks(data) {
    const observableBatch = [];
    const parent = this;
    data.forEach((selectedItem, key) => {
      observableBatch.push(parent.mangoAPISrvc.updateProjectDetails(selectedItem['ProjectDetailsID'], selectedItem))
    });

    return forkJoin(observableBatch);
  }

  saveAllProjectDetails(data, rowObj) {
    const parent = this;
    if (data.length > 0) {
      parent.batchSaveAllTasks(data).subscribe((data) => {
        if (
          rowObj.Repeat != null &&
          rowObj.Repeat != "" &&
          rowObj.Repeat != "none" &&
          rowObj.Status == "Completed"
        ) {
          parent.createAllProjectDetails(data, rowObj);
        } else {
          parent.refreshTable();
        }
        parent.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
      });
    } else {
      if (
        rowObj.Repeat != null &&
        rowObj.Repeat != "" &&
        rowObj.Repeat != "none" &&
        rowObj.Status == "Completed"
      ) {
        parent.createAllProjectDetails(data, rowObj);
      } else {
        parent.refreshTable();
      }
      parent.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
    }
  }

  calculateRepeatData(item) {
    let newDate = null;
    delete item.dueDate;
    item["DueDate"] = item.NextDueDate;
    item["ActualDueDate"] = item["DueDate"];
    if (item.Repeat != "none" && item.Repeat != null && item.Repeat != "") {
      if (item.Repeat == "year") {
        newDate = moment(item["DueDate"]).add(1, "years").utc().toDate();
      } else if (item.Repeat == "quarterly") {
        newDate = moment(item["DueDate"]).add(3, "months").utc().toDate();
      } else if (item.Repeat == "monthly") {
        newDate = moment(item["DueDate"]).add(1, "months").utc().toDate();
      } else if (item.Repeat == "onetime") {
        newDate = moment(item["DueDate"]).add(1, "days").utc().toDate();
      } else if (item.Repeat == "week") {
        newDate = moment(item["DueDate"]).add(7, "days").toDate();
      } else if (item.Repeat == "daily") {
        newDate = moment(item["DueDate"]).add(1, "days").toDate();
      } else if (item.Repeat == "semi-weekly") {
        newDate = moment(item["DueDate"]).add(14, "days").toDate();
      } else if (item.Repeat == "semi-monthly") {
        newDate = moment(item["DueDate"]).add(15, "days").utc().toDate();
      }
    }
    item["NextDueDate"] = newDate;
    item["CompletionDate"] = null;
    item["ExtensionDate"] = null;
    item["DateReceived"] = null;
    item["isCompleted"] = false;
    item["Status"] = "Pending";
    item["Tags"] = null;
  }

  generateRules(date: any, currentRuleStr: string, isNextDueDate?: boolean) {
    let ruledateSource = null;
    let ruleTwodateSource = null;
    let rule = null;
    if (currentRuleStr == "" || !currentRuleStr) {
      ruledateSource = null;
      ruleTwodateSource = null;
      return false;
    }
    const dateStart: any = date ? moment(date).format("YYYYMMDDThhmmss") : null;

    rule = RRule.fromString(
      dateStart
        ? `${currentRuleStr};DTSTART=${dateStart}`
        : currentRuleStr
    );
    rule.noCache = true;
    if(!isNextDueDate || this.compareDates(date, rule.all()[0])) {
      ruleTwodateSource = rule.all()[1]
        ? this.convertUTCDate(rule.all()[1])
        : null;
      ruleTwodateSource = ruleTwodateSource
        ? this.getNearestWeekday(ruleTwodateSource, currentRuleStr)
        : null;
    } else {
      ruleTwodateSource = rule.all()[0]
        ? this.convertUTCDate(rule.all()[0])
        : null;
      ruleTwodateSource = ruleTwodateSource
        ? this.getNearestWeekday(ruleTwodateSource, currentRuleStr)
        : null;
    }

    return ruleTwodateSource ? new Date(ruleTwodateSource) : null;
  }

  compareDates(date1, date2) {
    return moment(date1).format('YYYY/MM/DD') == moment(date2).format('YYYY/MM/DD')
  }

  getNearestWeekday(dateString: string, currentRuleStr: string) {
    const dateMoment = moment(dateString?.substring(0, 8));
    if (
      (currentRuleStr?.toLowerCase()?.indexOf("freq=yearly") == -1 &&
        currentRuleStr?.toLowerCase()?.indexOf("freq=monthly") == -1) ||
      currentRuleStr?.toLowerCase()?.indexOf("byday=") > -1
    )
      return dateMoment.format("YYYY/MM/DD");

    if (
      dateMoment.format("dddd") === "Sunday" ||
      dateMoment.format("dddd") === "Saturday"
    ) {
      dateMoment.add(1, "days");
      return this.getNearestWeekday(dateMoment.format("YYYYMMDD"), currentRuleStr);
    } else return dateMoment.format("YYYY/MM/DD");
  }

  convertUTCDate(date) {
    const check = moment(date, "YYYY/MM/DD");
    const month = parseInt(check.format("M"));
    const day = parseInt(check.format("D"));
    const year = parseInt(check.format("YYYY"));
    return new RRule({
      dtstart: new Date(Date.UTC(year, month - 1, day, 0, 0, 0)),
    })
      .toString()
      .split("DTSTART:")[1];
  }

  createAllProjectDetails(dataList, rowObj) {
    const parent = this;
    // create project Header and project details
    delete rowObj["ProjectHeaderID"];
    parent.calculateRepeatData(rowObj);
    rowObj["TasksCompleted"] = 0;
    rowObj["FinishDate"] = null;
    rowObj["TotalNotes"] = 0;
    rowObj["TotalTasks"] = rowObj["TotalTasks"];

    if(rowObj.Repeat == 'custom' && rowObj.RuleString) {
      rowObj["NextDueDate"] = parent.generateRules(rowObj["DueDate"], rowObj.RuleString, true);

      if(!rowObj['NextDueDate'] || !rowObj['DueDate']) {
        rowObj['NextDueDate'] = null;
        rowObj["Repeat"] = null;
        rowObj["RuleString"] = null;
      }
    }

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createProjectHeader(rowObj).subscribe(
      (Item: any) => {
        rowObj["ProjectHeaderID"] = Item.data["ProjectHeaderID"];
        if (dataList.length == 0) {

          parent.refreshTable();
        }
        parent.batchCreateAllTasks(dataList, rowObj).subscribe((data) => {

          parent.refreshTable();
          parent.mangoAPISrvc.notify(
            "success",
            "Success!",
            AppConstants.updateMsg
          );
        });
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => parent.mangoAPISrvc.showLoader(false)
    );
  }

  calculateTaskRepeatData(dueDate, repeat) {
    let newDate = null;
    dueDate = moment(dueDate).format("DD-MM-YYYY HH:mm:ss")
    if (repeat != "none") {
      if (repeat == "year") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(1, "years")
          .utc()
          .toDate();
      } else if (repeat == "quarterly") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(3, "months")
          .utc()
          .toDate();
      } else if (repeat == "monthly") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(1, "months")
          .utc()
          .toDate();
      } else if (repeat == "onetime") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(1, "days")
          .utc()
          .toDate();
      } else if (repeat == "week") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(7, "days")
          .toDate();
      } else if (repeat == "semi-weekly") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(14, "days")
          .toDate();
      } else if (repeat == "semi-monthly") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(15, "days")
          .utc()
          .toDate();
      } else if (repeat == "daily") {
        newDate = moment(dueDate, "DD-MM-YYYY")
          .add(1, "days")
          .toDate();
      }
    }

    return newDate;
  }

  batchCreateAllTasks(dataList, rowObj) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject(AppConstants.token));
    const repeat = rowObj["Repeat"] ? rowObj["Repeat"] : null;
    dataList.forEach((selectedItem, key) => {
      if (selectedItem["isReviewTask"]) return;
      if(selectedItem["IsRepeatTask"]) {
        selectedItem["DueDate"] = selectedItem["DueDate"]
          ? parent.calculateTaskRepeatData(selectedItem["DueDate"], repeat)
          : parent.calculateTaskRepeatData(moment().format("MM/DD/YYYY HH:mm:ss"), repeat);
      } else {
        selectedItem["DueDate"] = null;
      }

      if(selectedItem?.TaskRow !== 1)
        selectedItem["IsTaskReady"] = false;
      else
        selectedItem["IsTaskReady"] = true;

      selectedItem["IsRepeatTask"] = false
      selectedItem["IsCompleted"] = false;
      selectedItem["CompletionDate"] = null;
      selectedItem["CompanyID"] = rowObj.CompanyID;
      selectedItem["ClientID"] = rowObj.ClientID;
      selectedItem["ProjectHeaderID"] = rowObj.ProjectHeaderID;
      // selectedItem["DueDate"] = null;
      selectedItem["IsTaskAssignedSent"] = false;
      delete selectedItem.ProjectDetailsID;

      observableBatch.push(
        parent.http.post(
          `${environment.API_URL}/ddm/projectDetails/create`,
          selectedItem,
          { headers: headers, withCredentials: true }
        )
        // .map((res: Response) => res.json())
      );
    });
    return forkJoin(observableBatch);
  }

  radioClicked(item) {
    item.isReviewRequired = item.isReviewRequired ? item.isReviewRequired : false;
    if (item.isReviewRequired && item.Status !== "Ready for Review")   {
      item.Status = "Ready for Review";
      item.isCompleted = false;
    } else {
      item.Status = "Completed";
      item.isCompleted = true;
      item.CompletionDate = moment().format("MM/DD/YYYY HH:mm:ss");
    }
    delete item.completedcount;
    delete item.detailcount;
    delete item.ClientName;
    delete item.StaffName;

    const parent = this;

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.updateProjectHeader(item.ProjectHeaderID, item).subscribe((data) => {
      parent.mangoAPISrvc.fetchprojectDetailsByHeaderId(item.ProjectHeaderID).subscribe((responseData: any) => {
        const taskList = (
          responseData.taskList
        );
        const data = taskList;

        // if (data.length > 0) {
        data?.map(function (lineObj, index) {
          lineObj.IsCompleted = true;
          lineObj.CompletionDate = moment().format("MM/DD/YYYY HH:mm:ss");
        });
        parent.saveAllProjectDetails(data, item);
      }, (error) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('error', 'Error', error.message);
      })
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('error', 'Error', error.message);
    })
  }


  deleteItems() {
    const parent = this;
    event.stopPropagation();
    Swal.fire({
      title: 'Confirmation',
      text: 'Do you want to delete this record(s)?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        parent.mangoAPISrvc.showLoader(true);

        parent.bulkDelete()
          .subscribe(data => {
            parent.refreshTable();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'Deleted',
              showConfirmButton: false,
              timer: 1500
            });
            parent.mangoAPISrvc.showLoader(false);
          }, (err) => {
            parent.mangoAPISrvc.showLoader(false);
          });
      }
    })
  }


  bulkDelete() {
    const observableBatch = [];
    const parent = this;
    const logdata = {}
    logdata['Action'] = "Delete Project/s" ;
    logdata['Description'] = "ProjectID: " + parent.selectedClientsItems.map((x) => {return x.ProjectHeaderID}).join(", ") + ` --${parent.clientDetails.ClientName}`
    logdata['Table'] = "";
    if (!this.isManaging) {
      this.mangoAPISrvc.addUserLogs(logdata).subscribe((res) => {}, (err) => {
        console.log(err);
      });
    }

    parent.selectedClientsItems.forEach((selectedItem, key) => {
      observableBatch.push(parent.mangoAPISrvc.ddmDeleteProjectHeader(selectedItem.ProjectHeaderID));
    });
    return forkJoin(observableBatch);
  }


  bulkComplete() {
    const observableBatch = [];
    const parent = this;
    const logdata = {}
    logdata['Action'] = "Completed Project/s" ;
    logdata['Description'] = "ProjectID: " + + parent.selectedClientsItems.map((x) => {return x.ProjectHeaderID}).join(", ") + ` --${parent.clientDetails.ClientName}`
    logdata['Table'] = "";
    if (!this.isManaging) {
      this.mangoAPISrvc.addUserLogs(logdata).subscribe((res) => {}, (err) => {
        console.log(err);
      });
    }

    parent.selectedClientsItems.forEach((selectedItem, key) => {
      observableBatch.push(parent.mangoAPISrvc.updateProjectHeader(selectedItem.ProjectHeaderID, selectedItem))
    });
    return forkJoin(observableBatch);
  }

  redirectProject() {
    this.encrDecSrvc.addObject(AppConstants.ddmClientName, JSON.stringify({ 'ClientID': this.clientId, 'ClientName': this.clientDetails.ClientName }));
    this.router.navigate(['project-management/projectDetails']);
  }

  getStaffList() {
    const item = this.encrDecSrvc.getObject(AppConstants.staffList);

    for (let i = 0; i < item.length; ++i) {
      if(item[i].Inactive)
        continue;

      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      this.AllStaffsTypes.push(obj);
    }
  }

  redirectToProjectDetails(item) {
    item['ClientID'] = this.clientId;
    item['ClientName'] = this.clientDetails['ClientName'];
    this.encrDecSrvc.addObject(AppConstants.ddmProjectDetails, JSON.stringify(item));
    this.router.navigate([`/project-management/${item.ProjectHeaderID}/projectDetails`]);
  }

  findTagByValue(tagValue, property) {
    const temp = this.tagsList.filter((item) => item['value'] == tagValue);
    return temp[0] ? temp[0][property] : "";
  }

  fetchDdmSettings() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
      (data: any) => {
        if (data.length > 0) {
          const item = data[0];

          parent.encrDecSrvc.addObject(AppConstants.companyTags, item);
          if (item && Object.keys(item).length > 0) {
            const allTags = item.TagArray2;
            allTags?.map((item, i) => {
              if (item[0] != "") {
                const obj = {};
                obj["label"] = item[0];
                obj["value"] = (i + 1).toString();
                obj["color"] = item[1];
                obj["textColor"] =
                  item[1] != ""
                    ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(item[1]))
                    : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb("#ffffff"));
                parent.tagsList.push(obj);
              }
            });
          }
        }
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getProjectNames(cId) {
    const parent = this;

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getProjectNames(cId).subscribe(function (data: any) {
      for (let i = 0; i < data.length; ++i) {
        const obj = {};
        obj['label'] = data[i]['TemplateName'];
        obj['value'] = data[i]['TemplateName'];
        parent.projectsList.push(obj);
      }

      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  ddmCustomerbyClientID() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.ddmCustomerbyClientID(this.clientId).subscribe(function (data: any) {
      if (data && data.length > 0) {
        parent.clientDetails = data[0];
      }

      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  ddmProjectHeaderbyClientID(dueDate, status,
    userAssignedId,
    managerID,
    clientID,
    projectHeaderID,
    tagID,
    companyId) {

    const parent = this;
    parent.projectsDataSource = [];
    parent.selectedClientsItems = [];

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc
      .getProjectList(
        dueDate,
        status,
        managerID,
        userAssignedId,
        clientID,
        projectHeaderID,
        tagID,
        companyId,
        null,
        false,
        'All',
        null,
        null,
        null,
        null,
        null,

        { }
      )
      .subscribe(
        ( responseData: any ) => {
          const data = (
            responseData.projectList
          );

          data.map((item) => {
            item["compareDueDate"] = item["ActualDueDate"]
              ? moment(moment.utc(item["ActualDueDate"]).toDate()).format(
                "YYYY-MM-DD"
              )
              : null;
            item["compareExtensionDate"] = item["ExtensionDate"]
              ? moment(moment.utc(item["ExtensionDate"]).toDate()).format(
                "YYYY-MM-DD"
              )
              : null;
            item.managername = item.ManagerID == -1 ? "Unassigned" : item.managername;
            item.username = item.UserAssignedID == -1 ? "Unassigned" : item.username;
            item.SortableDueDate = item.ExtensionDate != null ? item.ExtensionDate : (item.DueDate != null ? item.DueDate : null);
            switch (item.Status) {
              case "Pending": item.statusClass = "pending";
                break;
              case "In Progress": item.statusClass = "inprogress";
                break;
              case "Ready for Review": item.statusClass = "review";
                break;
              case "Completed": item.statusClass = "completed";
                break;
            }
          });

          parent.projectsDataSource = data;

          parent.mangoAPISrvc.getTotalAndCompletedTasks().subscribe((list: any) => {
            parent.projectsDataSource = parent.projectsDataSource.map((item) => {
              const res = list.filter((task) => task.ProjectHeaderID == item.ProjectHeaderID)[0];
              return {
                ...item,
                completedcount: res?.TasksCompleted ? res.TasksCompleted : 0 ,
                detailcount: res?.TotalTasks ? res.TotalTasks : 0
              }
            })
          })
          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => parent.mangoAPISrvc.showLoader(false)
      );
  }

  resetFileters() {
    this.projectObj = { "TemplateName": null, "tagID": null, "dueDateID": null, "statusID": null, "staffId": null, "managerId": null };
    this.refreshTable();
  }

  refreshTable() {
    const parent = this;

    console.log({
      dueDateID: parent.projectObj.dueDateID,
      statusID: parent.projectObj.statusID,
      staffId: parent.projectObj.staffId,
      managerId: parent.projectObj.managerId,
      clientId: parent.clientId,
      TemplateName: parent.projectObj.TemplateName,
      tagID: parent.projectObj.tagID,
      companyId: parent.companyId
    })

    this.ddmProjectHeaderbyClientID(
      parent.projectObj.dueDateID,
      parent.projectObj.statusID,
      parent.projectObj.staffId,
      parent.projectObj.managerId,
      parent.clientId,
      parent.projectObj.TemplateName,
      parent.projectObj.tagID,
      parent.companyId
    );
  }

  ngOnDestroy() {
    // this.staffSub.unsubscribe();
    //this.tagsSub.unsubscribe();
  }

  getCompanyTemplateDetails() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyId).subscribe(function (data) {
      parent.templateDataSource = data;
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  get isAllowCreateProject() {
    return this.authGuard.isAllowAccess(41)
  }

  radioCompanyClicked(event, item) {
    this.isRadioSelected = true;
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(item));
  }
  createProjectScreen() {
    this.router.navigate(['project-management/projectDetails']);
  }

}
