import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-engagement-projects',
  templateUrl: './engagement-projects.component.html'
})
export class EngagementProjectsComponent implements OnInit {
  @ViewChild("searchValue") searchValue;
  @ViewChild("searchValueDetails") searchValueDetails;
  @ViewChild("dt") dt: Table;
  @ViewChild("dtDetails") dtDetails: Table;
  filteredItemsSize = -1;
  filteredItemsDetailsSize = -1;
  public engagementName: string = '';
  public companyId;
  projectID: number = -1;
  searchTextStr: any = "";
  project: any = [];
  unassignedProjects: any = [];
  selectedProjects: any = [];
  cols: any[];
  public _selectedColumns: any[];
  public tagsList: any = [{ label: "All", value: null, color: "" }];
  groupList: SelectItem[];
  public selectedRadBtn: any;

  projectFilterOptions: any = [];
  defaultFilter: any = null;
  isShowAddProjectDialog = false;

  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, private translate: TranslateService, private router: Router,) {
    this.engagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
  }

  ngOnInit(): void {
    this.getGroups();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('Projects') },
      { label: this.engagementName, icon: 'ic-red' }
    ]);

    this.projectFilterOptions = [
      { label: this.translate.instant('all_active'), value: null, color: null },
      { label: this.translate.instant('Pending'), value: "Pending", color: "Pending" },
      { label: this.translate.instant('mgmt-inprogress'), value: "In Progress", color: "InProgress" },
      { label: this.translate.instant('Ready-for-Review'), value: "Ready for Review", color: "review" },
      { label: this.translate.instant('completed'), value: "Completed", color: "Completed" },
    ];
    this.defaultFilter = this.projectFilterOptions[0];

  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getGroups().subscribe((data: any) => {
      this.groupList = data.map((group) => {
        return {
          label: group.GroupDescription,
          value: group.CustomerGroupCategoryID
        }
      });
      this.groupList.unshift({ label: "All", value: null })
      this.getProjectList();
      this.mangoAPISrvc.showLoader(false);
    }, error => {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    })
  }

  getProjectList(defaultFilter?) {
    if(!this.projectID) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Error occured.');
      return;
    }
    const argsObj = {
      TemplateName: null,
      tagID: null,
      dueDateID: null,
      statusID: null,
      staffId: null,
      managerId: null,
      clientGroup: null
    };

    if(defaultFilter){
      if(defaultFilter.value != 'All'){
        argsObj.statusID = defaultFilter.value;
      }
    }

    if (argsObj.statusID == 'Completed') {
      this.cols?.splice(5, 0, { field: 'Completion', header: this.translate.instant('Completion'), rowClass: 'p-text-center cursor-hand' });
    } else {
      this.cols = this.cols?.filter(col => col.field !== 'Completion');
      this._selectedColumns = this._selectedColumns?.filter(col => col.field !== 'Completion');
    }

    this.project = [];
    this.filteredItemsSize = -1;

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getProjectList(
        argsObj.dueDateID,
        argsObj.statusID,
        argsObj.staffId,
        argsObj.managerId,
        null,
        argsObj.TemplateName,
        argsObj.tagID,
        this.companyId,
        argsObj.clientGroup,
        false,
        'All',
        null,
        null,
        null,
        null,
        this.projectID,

        { }
      )
      .subscribe(
        ( responseData: any ) => {
          const data = (
            responseData.projectList
          );

          data.map((item) => {
            item["compareDueDate"] = item["ActualDueDate"]
              ? moment(moment.utc(item["ActualDueDate"]).toDate()).format(
                "YYYY-MM-DD"
              )
              : null;
            item["compareExtensionDate"] = item["ExtensionDate"]
              ? moment(moment.utc(item["ExtensionDate"]).toDate()).format(
                "YYYY-MM-DD"
              )
              : null;
            item.managername = item.ManagerID == -1 ? "Unassigned" : item.managername;
            item.username = item.UserAssignedID == -1 ? "Unassigned" : item.username;
            item.SortableDueDate = item.ExtensionDate != null ? item.ExtensionDate : (item.DueDate != null ? item.DueDate : null);
            switch (item.Status) {
              case "Pending": item.statusClass = "pending";
                break;
              case "In Progress": item.statusClass = "inprogress";
                break;
              case "Ready for Review": item.statusClass = "review";
                break;
              case "Completed": item.statusClass = "completed";
                break;
            }
            if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
              item['GroupDescriptionValues'] = this.groupList
                .filter(group => item['GroupDescriptionIDArray'].includes(group['value']))
                .map(group => group['label']).join(", ");
            }
          });

          this.project = data;

          this.mangoAPISrvc.getTotalAndCompletedTasks().subscribe((list: any) => {
            this.project = this.project.map((item) => {
              const res = list.filter((task) => task.ProjectHeaderID == item.ProjectHeaderID)[0];
              return {
                ...item,
                TasksCompleted: res?.TasksCompleted ? res.TasksCompleted : 0 ,
                TotalTasks: res?.TotalTasks ? res.TotalTasks : 0
              }
            })
          })

          this.mangoAPISrvc.showLoader(false);
        },
        (error) => this.mangoAPISrvc.showLoader(false)
      );
  }

  clearSearchFilter(type) {
    if(type == 'header') {
      this.searchValue.nativeElement.value = this.searchTextStr = "";
      this.filteredItemsSize = -1;
      this.encrDecSrvc.addObject('eng_proj_' + AppConstants.SearchString, "");
    } else {
      this.searchValueDetails.nativeElement.value = this.searchTextStr = "";
      this.filteredItemsDetailsSize = -1;
      this.encrDecSrvc.addObject('eng_proj_details' + AppConstants.SearchString, "");
    }
  }

  onFilter(obj, type) {
    if(type == 'header') {
      this.filteredItemsSize = obj.filteredValue.length;
      this.encrDecSrvc.addObject('eng_proj_' + AppConstants.SearchString, obj.filters.global.value);
    } else {
      this.filteredItemsDetailsSize = obj.filteredValue.length;
      this.encrDecSrvc.addObject('eng_proj_details' + AppConstants.SearchString, obj.filters.global.value);
    }
  }


  findTagByValue(tagValue, property) {
    const temp = this.tagsList.filter((item) => item["value"] == tagValue);
    return temp[0] ? temp[0][property] : "";
  }

  getToolTip(obj) {
    if (obj.ExtensionDate) return 'Due Date: ' + moment(obj.DueDate).format("MM/DD/YYYY")

    return obj.NextDueDate
      ? "Next Due Date : " + moment(obj.NextDueDate).format("MM/DD/YYYY")
      : "";
  }


  redirectToProjectDetails(item) {
    this.encrDecSrvc.addObject(
      AppConstants.ddmProjectDetails,
      JSON.stringify(item)
    );
    this.router.navigate([
      `/project-management/${item.ProjectHeaderID}/projectDetails`,
    ]);
  }

  changeProjectFilter(event: any) {
    this.getProjectList(this.defaultFilter);
  }

  showAddProjectDialog() {
    const clientID = this.encrDecSrvc.getObject(AppConstants.clientID)
    this.isShowAddProjectDialog = true
    //load projects by ProjectMasterID != null and ClientID = ?
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getUnassignedProjectsByClientID(clientID).subscribe((result: any) => {
      result.map((item) => {
        item["compareDueDate"] = item["ActualDueDate"]
          ? moment(moment.utc(item["ActualDueDate"]).toDate()).format(
            "YYYY-MM-DD"
          )
          : null;
        item["compareExtensionDate"] = item["ExtensionDate"]
          ? moment(moment.utc(item["ExtensionDate"]).toDate()).format(
            "YYYY-MM-DD"
          )
          : null;
        item.managername = item.ManagerID == -1 ? "Unassigned" : item.managername;
        item.username = item.UserAssignedID == -1 ? "Unassigned" : item.username;
        item.SortableDueDate = item.ExtensionDate != null ? item.ExtensionDate : (item.DueDate != null ? item.DueDate : null);
        switch (item.Status) {
          case "Pending": item.statusClass = "pending";
            break;
          case "In Progress": item.statusClass = "inprogress";
            break;
          case "Ready for Review": item.statusClass = "review";
            break;
          case "Completed": item.statusClass = "completed";
            break;
        }
        if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
          item['GroupDescriptionValues'] = this.groupList
            .filter(group => item['GroupDescriptionIDArray'].includes(group['value']))
            .map(group => group['label']).join(", ");
        }
      });

      this.unassignedProjects = result;

      this.mangoAPISrvc.getTotalAndCompletedTasks().subscribe((list: any) => {
        this.unassignedProjects = this.unassignedProjects.map((item) => {
          const res = list.filter((task) => task.ProjectHeaderID == item.ProjectHeaderID)[0];
          return {
            ...item,
            TasksCompleted: res?.TasksCompleted ? res.TasksCompleted : 0 ,
            TotalTasks: res?.TotalTasks ? res.TotalTasks : 0
          }
        })
      })

      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false)
    })
  }

  closeAddProjectDialog() {
    this.selectedProjects = [];
    this.isShowAddProjectDialog = false
  }

  checkDueDate(obj) {
    const dueDate = obj.ExtensionDate || obj.DueDate
    return dueDate && dueDate < moment().format("YYYY-MM-DD");
  }

  onAddProject(selectedProjects) {
    const observableBatch = [];

    selectedProjects.forEach(project => {
      observableBatch.push({ ProjectHeaderID: project.ProjectHeaderID, ProjectMasterID: this.projectID })
    })

    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.bulkUpdateBulkProjectHeader({observableBatch}).subscribe((data) => {
      this.mangoAPISrvc.showLoader(false)
      this.project = [...this.project, ...selectedProjects]
      this.mangoAPISrvc.notify('success', this.translate.instant('success'), AppConstants.updateMsg);
    }, err => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
    })
    this.closeAddProjectDialog()
  }
}
