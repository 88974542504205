<div class="layout-wrapper" [ngClass]="{'layout-horizontal': app.horizontalMenu && isLoggedInUser,
                'layout-wrapper-static': app.staticMenuActive && isLoggedInUser,
                'layout-rightpanel-active': app.rightPanelActive && isLoggedInUser,
                'layout-topbar-mobile-active': app.topbarMobileMenuActive && isLoggedInUser,
                'layout-megamenu-mobile-active': app.megaMenuMobileActive && isLoggedInUser,
                'layout-sidebar-mobile-active': app.menuMobileActive && isLoggedInUser,
                'p-input-filled': isLoggedInUser && app.inputStyle === 'filled',
                'p-ripple-disabled': !app.ripple && isLoggedInUser}" [class]="app.menuColor">

    <app-loader></app-loader>
    <app-message></app-message>

    <p-confirmDialog [style]="{width: '25vw'}" [transitionOptions]="'0ms'" [baseZIndex]="10000"></p-confirmDialog>
    <p-toast position="top-right"></p-toast>
    <app-menu *ngIf="isLoggedInUser"></app-menu>
    <div [ngClass]="(isLoggedInUser)?'layout-main':''" (click)="app.onLayoutClick()" class="securitylayout">
        <app-topbar *ngIf="isLoggedInUser"></app-topbar>
        <app-breadcrumb *ngIf="isLoggedInUser"></app-breadcrumb>
        <div [ngClass]="(isLoggedInUser)?'layout-content':''">
            <router-outlet></router-outlet>
            <!-- <app-config></app-config> -->
            <app-shared-dialogs *ngIf="isLoggedInUser"></app-shared-dialogs>
        </div>
    </div>
</div>