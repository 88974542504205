import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { EngagementsRoutingModule } from './engagements-routing.module';

import { engagementsComponent } from './engagements.component';
import { EngagementsListComponent } from './engagements-list/engagements-list.component';
import { EngagementsViewComponent } from './engagements-view/engagements-view.component';
import { EngagementsHistoryComponent } from './engagements-history/engagements-history.component';
import { EngagementsExpenseComponent } from './engagements-expense/engagements-expense.component';
import { EngagementsNotesComponent } from './engagements-notes/engagements-notes.component';
import { EngagementsUnbilledTimeComponent } from './engagements-unbilled-time/engagements-unbilled-time.component';
import { EngagementsContactComponent } from './engagements-contact/engagements-contact.component';
import { AppTranslationModule } from '@app/shared/modules';
import { EngagementProjectsComponent } from './engagement-projects/engagement-projects.component';

import { BudgetsModule } from '../../shared/components/budgets/budgets.module';

@NgModule({
  declarations: [engagementsComponent, EngagementsListComponent, EngagementsViewComponent, EngagementsHistoryComponent, EngagementsExpenseComponent, EngagementsNotesComponent, EngagementsUnbilledTimeComponent, EngagementsContactComponent, EngagementProjectsComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    EngagementsRoutingModule,
    AppTranslationModule,
    BudgetsModule
  ]
})
export class EngagementsModule { }
