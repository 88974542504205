import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { SuperDashboardComponent } from './super-dashboard.component';
import { MBRevenueComponent } from './mb-revenue/mb-revenue.component'
import { ManageAccountsComponent } from './manage-accounts/manage-accounts.component';
import { CustomersComponent } from './customers/customers.component';
import { CpapayEnrollmentsComponent } from './cpapay-enrollments/cpapay-enrollments.component';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard } from '@app/_services';
import { UsageMeteringComponent } from './usage-metering/usage-metering.component';

const routes: Routes = [
  {
    path: '',
    component: SuperDashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ManageAccountsComponent, canActivate: [AuthGuard], data: { isSuperAdminOnly: true } },
      { path: AppConstants.manageAccounts, component: ManageAccountsComponent, canActivate: [AuthGuard], data: { isSuperAdminOnly: true } },
      { path: AppConstants.mbRevenue, component: MBRevenueComponent, canActivate: [AuthGuard], data: { isSuperAdminOnly: true } },
      { path: AppConstants.customers, component: CustomersComponent, canActivate: [AuthGuard], data: { isSuperAdminOnly: true } },
      { path: AppConstants.cpaEnrollments, component: CpapayEnrollmentsComponent, canActivate: [AuthGuard], data: { isSuperAdminOnly: true } },
      { path: AppConstants.elUsageMetering, component: UsageMeteringComponent, canActivate: [AuthGuard], data: { isSuperAdminOnly: true } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperDashboardRoutingModule { }
