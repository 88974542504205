<div class="client-projects">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <!-- <p-button pRipple label="{{ 'create_project' | translate }}" icon="fal fa-plus" styleClass="p-button-sm" *ngIf="createProjectFromCompanyTemplate"
                class="p-button-help p-pr-2" (click)="createFromCompanyTemplate();"></p-button> -->
            <p-splitButton *ngIf="isAllowCreateProject" label=" {{ 'New-Projects' | translate }}" styleClass="p-button-sm split-button-size" icon="fal fa-plus" class="p-pr-2"
                [model]="buttonsList" (onClick)="redirectProject()" [menuStyleClass]="'new-project-button-list'">
            </p-splitButton>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt id="project-table" [value]="project" [responsive]="true" sortField="ClientName"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        dataKey="ProjectHeaderID" [filterDelay]="0" [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                        [paginator]="project.length > 10"
                        [globalFilterFields]="['ClientName', 'TemplateName', 'TasksCompleted', 'TotalTasks', 'DateReceived', 'FinishDate', 'compareDueDate', 'Status', 'managername', 'username', 'GroupDescriptionValues']"
                        (onFilter)="onFilter($event)" [sortOrder]="0"
                        [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'Search-by' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                                </p-message>
                                <span class="pull-right">
                                    <span class="pull-right p-mr-4">
                                        <p-dropdown [options]="projectFilterOptions" [(ngModel)]="defaultFilter" optionLabel="label"
                                            (onChange)="changeProjectFilter($event)" [ngModelOptions]="{standalone: true}">
                                            <ng-template let-item pTemplate="item">
                                                <div class="ui-helper-clearfix">
                                                    <div class="p-text-center drop_{{item.color | lowercase}}">{{item.label}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </span>
                                </span>
                            </div>

                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-4p p-text-center">
                                    {{'action' | translate}}
                                </th>
                                <th class="width-9p p-text-left" pSortableColumn="ClientName">
                                    {{ 'client' | translate }}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pSortableColumn="TemplateName">
                                    {{ 'project' | translate }}
                                    <p-sortIcon field="TemplateName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left">
                                    {{ 'Tags' | translate }}
                                </th>
                                <th class="width-4p p-text-center">
                                    {{ 'Tasks' | translate }}
                                </th>
                                <th class="width-4p p-text-center">
                                    {{ 'memo' | translate }}
                                </th>
                                <th class="width-4p p-text-center">
                                    {{ 'notes' | translate }}
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="DateReceived">
                                    {{ 'In-Date' | translate }}
                                    <p-sortIcon field="DateReceived"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="FinishDate">
                                    {{ 'Out-Date' | translate }}
                                    <p-sortIcon field="FinishDate"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pTooltip="{{ 'Completion-Date' | translate }}"
                                    tooltipPosition="top" *ngIf="defaultFilter?.value === 'Completed' || defaultFilter?.value === 'All'">
                                    {{ 'Completion' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="SortableDueDate">
                                    {{ 'due-date' | translate }}
                                    <p-sortIcon field="SortableDueDate"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pSortableColumn="Status">
                                    {{ 'status' | translate }}
                                    <p-sortIcon field="Status"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pTooltip="{{ 'Manager-Assigned' | translate }}"
                                    tooltipPosition="top" pSortableColumn="username">
                                    {{ 'manager' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                    <p-sortIcon field="username"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pTooltip="{{ 'Partner-Assigned' | translate }}"
                                    tooltipPosition="top" pSortableColumn="managername">
                                    {{ 'partner' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                    <p-sortIcon field="managername"></p-sortIcon>
                                </th>
                                <!--  <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field"
                                    class="p-text-left">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td class="p-text-center width-4p">
                                    <span class="project-link cursor-hand" routerLink="/project-management/{{rowData['ClientID']}}/clientDetails">
                                        <i class="fal fa-pencil p-mr-3 ic-green" tooltipPosition="top" pTooltip="{{ 'Edit' | translate }}"></i>
                                    </span>
                                </td>
                                <td class="width-9p p-text-left p-text-nowrap project-link cursor-hand"
                                    pTooltip="{{rowData.ClientName}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <span class="project-link cursor-hand"
                                        routerLink="/project-management/{{rowData['ClientID']}}/clientDetails">{{rowData.ClientName}}</span>
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate project-link cursor-hand"
                                    pTooltip="{{rowData.TemplateName}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <span class="project-link cursor-hand"
                                    (click)="redirectToProjectDetails(rowData)">{{getFormattedProjectName(rowData.TemplateName)}}</span>
                                </td>
                                <td class="width-10p p-text-left">
                                    <span *ngFor="let tag of rowData['Tags']">
                                        <span *ngIf="findTagByValue(tag,'label') !== ''" class="p-text-nowrap pTag"
                                                [style.background-color]="findTagByValue(tag,'color')"
                                                pTooltip="{{findTagByValue(tag,'label')}}" showDelay="800"
                                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">&nbsp;</span>
                                    </span>
                                </td>
                                <td class="width-4p p-text-center">
                                    <span
                                        *ngIf="rowData.TasksCompleted >= 0">{{rowData.TasksCompleted}}/</span>{{rowData.TotalTasks}}
                                </td>
                                <td class="width-4p p-text-center">
                                    <span *ngIf="rowData.ProjectMemo" showDelay="800" pTooltip="{{rowData.ProjectMemo}}"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        tooltipStyleClass="tooltip-no-wrap"><i
                                            class="fa fa-sticky-note ic-orange cursor-hand"></i></span>
                                </td>
                                <td class="width-4p p-text-center">
                                    <span *ngIf="rowData.TotalNotes > 0" class="notes">{{rowData.TotalNotes}}</span>
                                </td>
                                <td class="width-7p p-text-left" pTooltip="{{rowData.DateReceived | date:'MM/dd'}}"
                                    showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{rowData.DateReceived | date:'MM/dd'}}
                                </td>
                                <td class="width-7p p-text-left" pTooltip="{{rowData.FinishDate | date:'MM/dd'}}"
                                    showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{rowData.FinishDate | date:'MM/dd'}}
                                </td>
                                <td *ngIf="defaultFilter?.value === 'Completed' || defaultFilter?.value === 'All'" class="p-text-left cursor-hand"
                                    pTooltip="{{getToolTip(rowData)}}" tooltipPosition="top" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.CompletionDate | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="p-text-left" tooltipPosition="top" showDelay="800"
                                    pTooltip="{{getToolTip(rowData)}}"
                                    [ngClass]="{'dueDate_violet': rowData.ExtensionDate, 'dueDate_critical': !rowData.ExtensionDate && checkDueDate(rowData) }"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{ (rowData.ExtensionDate ? 'Ext. ' + (rowData.ExtensionDate | date:'MM/dd/yyyy') : (rowData.DueDate | date:'MM/dd/yyyy') ) ||
                                    '-'}}
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.Status}}" showDelay="800" tooltipPosition="top">
                                    <div class="flag project-summary-{{rowData.statusClass | lowercase}}">
                                        {{rowData.Status}}</div>
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.username}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.username}}
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.managername}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.managername}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="13">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog [(visible)]="isDisplayDetailsTable" [modal]="true" [style]="{width: '50vw'}" [responsive]="true" [draggable]="false" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
    <p-header>{{ 'pm.Create-Project-From-Company-Template' | translate }}</p-header>
    <p-table #dt [value]="templateDataSource" (onFilter)="onFilter($event)" 
        [totalRecords]="templateDataSource.length" [pageLinks]="3" 
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" 
        [scrollHeight]="'calc(90vh - 360px)'" [scrollable]="true"
        [rowHover]="true" dataKey="MasterTemplateDetailsID" [globalFilterFields]="['TemplateName']"  sortField="TemplateName" [sortOrder]="1" #detailtab>
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchValue pInputText type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search by" />
                </span>
                <span>
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="Clear"></button>
                </span>
                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2">
                </p-message>
            </div>
        </ng-template>
        <!-- Table Header -->
        <ng-template pTemplate="header">
            <tr>
                <th class="width-8p p-text-center">
                </th>
                <th class="width-85p p-text-center" pSortableColumn="TemplateName">
                    {{ 'Template-Names' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="TemplateName"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <!-- Rows -->
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="width-8p p-text-center">
                    <p-radioButton name="groupname" [(ngModel)]="selectedTemplate" [value]="rowData" [inputId]="rowData.ProjectHeaderID"
                        (click)="radioCompanyClicked($event,rowData)">
                    </p-radioButton>
                </td>
                <td class="width-85p p-text-left">
                    {{rowData.TemplateName}}
                </td>
            </tr>
        </ng-template>
        <!-- No data -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">
                    <p-message severity="info" text="Data not found." styleClass="p-px-2">
                    </p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton label="{{ 'create' | translate }}" (click)="createProjectScreen()" [disabled]="!isRadioSelected"></button>
        <button pButton label="{{ 'update_button' | translate }}" routerLink="/project-management/{{companyTemplateID}}/createTemplate" [disabled]="!isRadioSelected"></button>
        <button pButton label="{{ 'cancel_button' | translate }}" (click)="isDisplayDetailsTable = false"></button>
    </p-footer>
</p-dialog>