import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import * as AWS from 'aws-sdk';
import { AppMainComponent } from '@app/app.main.component';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit, AfterViewInit{
  public myProfileForm: UntypedFormGroup;
  staffID: any;
  tempid: any;
  companyID: any;
  public resourceId: any;
  public isValidForm: boolean = true;
  public isImageUrl: boolean = false;
  public isFormValid: boolean = false;
  public imageUrl: any = '';
  public profileDataSource: any;
  public file: any;
  public fileInput: any
  public fileName: any;
  @ViewChild("fileUpload") fileUpload: any;

  constructor(public app: AppMainComponent,private _fb: UntypedFormBuilder, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, private mangoUtils: mangoUtils) {
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.initializeProfileForm();
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'My Profile' },
      { label: 'User Information' }
    ]);
    this.getProfileData();
  }

  ngAfterViewInit() {
  }

  initializeProfileForm() {
    this.myProfileForm = this._fb.group({
      BitAddCalendarResource: [false],
      City: [''],
      Country: [''],
      DateAnniversary: [''],
      DateBirthday: [''],
      DateHired: [''],
      DateTerminated: [''],
      Email: ['', [<any>Validators.required, Validators.email, <any>Validators.maxLength(50)]],
      Inactive: [false],
      ManagerID: [''],
      MemoStaff: [''],
      ProfilePic: [''],
      StaffDeptID: [''],
      StaffID: [''],
      StaffName: ['', [<any>Validators.required, <any>Validators.maxLength(70), <any>Validators.minLength(2)]],
      StaffNumber: [''],
      StaffTitleID: [''],
      State: [''],
      Street1: ['', [<any>Validators.maxLength(100), <any>Validators.minLength(5)]],
      Street2: ['', [<any>Validators.maxLength(100)]],
      SystemPassword: ['', [<any>Validators.maxLength(70), <any>Validators.minLength(3)]],
      ConfirmPassword: ['',],
      TargetDayHours: [''],
      TargetMonthHours: [''],
      TargetWeekHours: [''],
      TargetYearHours: [''],
      Tele1Descr: ['', [<any>Validators.maxLength(70)]],
      Tele2Descr: ['', [<any>Validators.maxLength(70)]],
      Tele1: [''],
      Tele2: [''],
      TeleHome: [''],
      TeleMobile: [''],
      TeleOffice: [''],
      Zip: [''],
      EmergencyName: ['', [<any>Validators.maxLength(50)]],
      EmergencyPhone1: [''],
      EmergencyPhone2: [''],
      EmergencyRelation: ['', [<any>Validators.maxLength(50)]]
    }, {
      validator: this.matchingPasswords('SystemPassword', 'ConfirmPassword')
    });
  }

  matchingPasswords(SystemPasswordKey: string, ConfirmPasswordKey: string) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[SystemPasswordKey];
      const passwordConfirmationInput = group.controls[ConfirmPasswordKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ mismatchedPasswords: true })
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  validateForm(flag) {
    let isInValidData = false;
    Object.keys(this.myProfileForm.controls).forEach(key => {
      if (this.myProfileForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData && this.myProfileForm.dirty) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }

    if (!isInValidData && flag) {
      this.isFormValid = true;
    }
    //this.encrDecSrvc.addObject(AppConstants.isFormChanged, this.myProfileForm.dirty);
  }

  removeImage() {
    if (this.imageUrl) {
      this.myProfileForm.controls['ProfilePic'].setValue(null);
      this.imageUrl = null;
      this.isImageUrl = false;
      this.encrDecSrvc.addObject(AppConstants.imageUrl, null);
      this.saveProfileData();
    }
  }

  updateProfileForm(itemData: any) {
    if (Object.keys(itemData).length > 0) {
      /* default values */
      this.myProfileForm.controls['BitAddCalendarResource'].setValue(itemData['BitAddCalendarResource']);
      this.myProfileForm.controls['DateAnniversary'].setValue(itemData['DateAnniversary']);
      this.myProfileForm.controls['DateBirthday'].setValue(itemData['DateBirthday']);
      this.myProfileForm.controls['DateHired'].setValue(itemData['DateHired']);
      this.myProfileForm.controls['DateTerminated'].setValue(itemData['DateTerminated']);
      this.myProfileForm.controls['Inactive'].setValue(itemData['Inactive']);
      this.myProfileForm.controls['ManagerID'].setValue(itemData['ManagerID']);
      this.myProfileForm.controls['StaffDeptID'].setValue(itemData['StaffDeptID']);
      this.myProfileForm.controls['StaffID'].setValue(itemData['StaffID']);
      this.myProfileForm.controls['StaffNumber'].setValue(itemData['StaffNumber']);
      this.myProfileForm.controls['StaffTitleID'].setValue(itemData['StaffTitleID']);
      this.myProfileForm.controls['TargetDayHours'].setValue(itemData['TargetDayHours']);
      this.myProfileForm.controls['TargetMonthHours'].setValue(itemData['TargetMonthHours']);
      this.myProfileForm.controls['TargetWeekHours'].setValue(itemData['TargetWeekHours']);
      this.myProfileForm.controls['TargetYearHours'].setValue(itemData['TargetYearHours']);

      /* User Information Data*/
      this.myProfileForm.controls['StaffName'].setValue(itemData['StaffName'].trim());
      this.myProfileForm.controls['Email'].setValue(itemData['Email']);
      this.myProfileForm.controls['Street1'].setValue(itemData['Street1']);
      this.myProfileForm.controls['Street2'].setValue(itemData['Street2']);
      this.myProfileForm.controls['City'].setValue(itemData['City']);
      this.myProfileForm.controls['State'].setValue(itemData['State']);
      this.myProfileForm.controls['Zip'].setValue(itemData['Zip']);
      this.myProfileForm.controls['Country'].setValue(itemData['Country']);
      this.myProfileForm.controls['TeleOffice'].setValue(itemData['TeleOffice']);
      this.myProfileForm.controls['TeleMobile'].setValue(itemData['TeleMobile']);
      this.myProfileForm.controls['TeleHome'].setValue(itemData['TeleHome']);
      this.myProfileForm.controls['Tele1'].setValue(itemData['Tele1']);
      this.myProfileForm.controls['Tele2'].setValue(itemData['Tele2']);
      this.myProfileForm.controls['Tele1Descr'].setValue(itemData['Tele1Descr']);
      this.myProfileForm.controls['Tele2Descr'].setValue(itemData['Tele2Descr']);
      if (itemData['SystemPassword']) {
        this.myProfileForm.controls['SystemPassword'].setValue(itemData['SystemPassword']);
      }
      this.myProfileForm.controls['ProfilePic'].setValue(itemData['ProfilePic']);

      /* Emergency Contact Data*/
      this.myProfileForm.controls['EmergencyName'].setValue(itemData['EmergencyName']);
      this.myProfileForm.controls['EmergencyRelation'].setValue(itemData['EmergencyRelation']);
      this.myProfileForm.controls['EmergencyPhone1'].setValue(itemData['EmergencyPhone1']);
      this.myProfileForm.controls['EmergencyPhone2'].setValue(itemData['EmergencyPhone2']);


      if (itemData['ProfilePic']) {
        this.isImageUrl = true;
        this.imageUrl = itemData['ProfilePic'];
      }
    }
    this.myProfileForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.myProfileForm.pristine);
      if(data?.SystemPassword && !data?.ConfirmPassword) {
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      }
      this.validateForm(false);
    });
  }

  uploadImage(): void {
    const uniqueName = `${this.mangoUtils.generateUUID()}_${this.encrDecSrvc.getObject(AppConstants.companyID)}_${this.file.name}`
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = 'us-east-1'
    let bucket = new AWS.S3({ params: { Bucket: 'mangobillinglogos' } });
    */
    const bucket = (
      AWSBucketMask
    );

    const params = { Bucket: 'mangobillinglogos', Key: 'userprofilepic/' + uniqueName, ACL: "public-read", Body: this.file };
    const self = this;
    bucket.upload(
      params,

      function (err, data) {
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.isImageUrl = true;
        self.imageUrl = data.Location;
        self.myProfileForm.controls['ProfilePic'].setValue(self.imageUrl);
        self.encrDecSrvc.addObject(AppConstants.imageUrl, self.imageUrl);
        self.mangoAPISrvc.fireImageView(true);
        self.saveProfileData();
        self.fileUpload.clear()
      },

      self.mangoAPISrvc
    );
  }

  fileEvent(fileInput: any) {
    for (const file of fileInput.files) {
      this.file = file;
      this.uploadImage();
    }
  }

  /*
      Fetching Profile data from Server
    */
  getProfileData() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getProfileData().subscribe((data) => {
      parent.profileDataSource = data;
      parent.updateProfileForm(data);
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    })
  }

  saveProfileData(arg1?: any) {
    const parent = this;
    parent.isFormValid = false;
    let obj = parent.myProfileForm.value;
    if (obj.StaffName === "") {
      obj = parent.profileDataSource;
    }

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateProfileView(obj).subscribe((data: any) => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

      if (data.message) {
        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        parent.encrDecSrvc.addObject(AppConstants.userName, obj['StaffName']);
        parent.mangoAPISrvc.fireImageView(true);
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.mangoAPISrvc.showLoader(false);
      }
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    })
  }
}
