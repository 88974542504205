<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive}" (click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
    <div class="sidebar-logo" style="width: 10px;">
        <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="app.onToggleMenuClick($event);">
            <i class="pi pi-lock" *ngIf="app.staticMenuActive"></i>
            <i class="pi pi-lock-open" *ngIf="!app.staticMenuActive"></i>
        </a>
        <a routerLink="dashboard">
            <!--Temporary Logo-->
            <img alt="logo" src="assets/img/new-logo.svg" style="width: 170px; height: 53px;" *ngIf="app.staticMenuActive || app.sidebarActive"/>
            <img alt="logo" src="favicon.ico" style="width: 38px; height: 38px;" *ngIf="!app.staticMenuActive && !app.sidebarActive"/>
        </a>
    </div>

    <div class="layout-menu-container" (click)="onMenuClick()">
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    </div>
</div>