<div class="mb-revenue">
    <div class="layout-content p-px-3">
        <div class="p-grid p-p-0 p-mt-1">

            <!-- Start: Mango Billing Revenue -->
            <div class="p-col-12 p-pb-0">
                <h3>Mango Billing Revenue</h3>
            </div>

            <div class="p-col-12 p-grid p-pt-0">
                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.thismonthsales }}</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-end">
                            <label class="p-text-bold">This Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.lastyearmonthsales }}</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-end">
                            <label class="p-text-bold">Last Year Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.thisyearsales }}</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-end">
                            <label class="p-text-bold">This Year-To-Date</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.lastyearsales }}</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-end">
                            <label class="p-text-bold">Last Year-To-Date</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End: Mango Billing Revenue -->

            <!-- Start: MB Revenue Chart -->
            <div class="p-col-12 manual p-pr-4">
                <p-panel header="Billed Revenue - 3 Year Comparison" [toggleable]="true">
                    <p-chart responsive="true" height="300px" type="bar" [data]="data" [options]="options">
                    </p-chart>
                </p-panel>
            </div>
            <!-- End: MB Revenue Chart -->

            <!-- Start: Trials -->
            <div class="p-col-12 p-grid p-pt-0">
                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-text-center">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2 pull-left"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.trialsmonth }}</label>
                        </div>
                        <div class="p-col-12 p-text-right">
                            <label class="p-text-bold">Trials Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-text-center">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2 pull-left"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.convertedtrials }}</label>
                        </div>
                        <div class="p-col-12 p-text-right">
                            <label class="p-text-bold">Trials Converted This Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-text-center">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2 pull-left"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.newcustomers }}</label>
                        </div>
                        <div class="p-col-12 p-text-right">
                            <label class="p-text-bold">New Customers This Year</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-text-center">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2 pull-left"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">{{ revenueDetails.cancelled }}</label>
                        </div>
                        <div class="p-col-12 p-text-right">
                            <label class="p-text-bold">Cancellations This Year</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End: Trials -->

            <!-- Start: CC Payments Processed -->
            <div class="p-col-12 p-pb-0">
                <h3>CC Payments Processed</h3>
            </div>

            <div class="p-col-12 p-grid p-pt-0">
                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$12, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">2830</label>
                            <label class="p-text-bold">This Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$22, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">1810</label>
                            <label class="p-text-bold">Last Year Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$122, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">18238</label>
                            <label class="p-text-bold">This Year-To-Date</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$322, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">16111</label>
                            <label class="p-text-bold">Last Year-To-Date</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End: CC Payments Processed -->

            <!-- Start: ACH Payments Processed -->
            <div class="p-col-12 p-pb-0">
                <h3>ACH Payments Processed</h3>
            </div>

            <div class="p-col-12 p-grid p-pt-0">
                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$12, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">2830</label>
                            <label class="p-text-bold">This Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$22, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">1810</label>
                            <label class="p-text-bold">Last Year Month</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$122, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">18238</label>
                            <label class="p-text-bold">This Year-To-Date</label>
                        </div>
                    </div>
                </div>

                <div class="p-col-3">
                    <div class="box p-p-3">
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                            <label class="ic-size-30 p-text-bold ic-blue">$322, 620</label>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-between p-ai-end">
                            <label class="p-text-bold ic-size-20">16111</label>
                            <label class="p-text-bold">Last Year-To-Date</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End: ACH Payments Processed -->

        </div>
    </div>
</div>