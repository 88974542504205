import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { AppSharedModule } from '@app/shared/modules';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import { UsersComponent, ViewComponent, ScheduleComponent, ListComponent, UserRatesComponent, EducationComponent, PermissionsComponent } from '@app/modules/users';
import { ManageRolesModule } from '@app/shared/components/manage-roles/manage-roles.module';


@NgModule({
  declarations: [UsersComponent, ViewComponent, ScheduleComponent, EducationComponent, ListComponent, UserRatesComponent, EducationComponent, PermissionsComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule,
    TriStateCheckboxModule,
    UsersRoutingModule,
    ManageRolesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class UsersModule { }
