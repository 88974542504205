<div class="pmsdashboard">
    <!-- buttons -->
    <span class="pull-right p-mr-3 clientform-btn-top">
        <p-button pRipple label="{{'dashboard' | translate}}" icon="fal fa-tachometer-alt-fastest"
            styleClass="p-button-sm" class="p-button-help p-pr-2" routerLink="/project-management/dashboard"></p-button>
        <p-button pRipple label="{{'Projects' | translate}}/{{'Tasks' | translate}} List" icon="fal fa-building" styleClass="p-button-sm"
            class="p-button-help p-pr-2" routerLink="/project-management/projectList"></p-button>
        <p-button pRipple label="{{'PM-Settings' | translate}}" icon="fal fa-cog" styleClass="p-button-sm"
            class="p-button-help p-pr-2" routerLink="/project-management/settings"></p-button>
    </span>
    <div class="layout-content p-p-2">
        <div class="card p-pt-1">
            <p-table #dt [value]="clientsHistoryDataSource" [rowHover]="true" [responsive]="true" [rows]="10"
                dataKey="ClientName" [pageLinks]="3" [paginator]="clientsHistoryDataSource.length > 10"
                styleClass="p-datatable-sm p-datatable-responsive-demo" [rowsPerPageOptions]="[10,15,20]"
                [filterDelay]="0" (onFilter)="onFilter($event)" [sortOrder]="1"
                [globalFilterFields]="['ClientName', 'CustomerTypeDescription', 'today', 'thisweek', 'nextweek', 'pastdue', 'pendingcount', 'inprogresscount', 'reviewcount', 'completedcount']">

                <ng-template pTemplate="caption">
                    <div class="table-header">
                        <span class="p-input-icon-left">
                            <i class="fal fa-search"></i>
                            <input #searchValue pInputText type="text"
                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="{{'globalsearch' | translate}}" />
                        </span>
                        <span>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                pTooltip="{{'clear' | translate}}"></button>
                        </span>
                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                            text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                        </p-message>
                    </div>
                </ng-template>

                <!-- Table Header -->
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="width-21p" pSortableColumn="ClientName">
                            {{'client.name' | translate}}
                            <p-sortIcon field="ClientName"></p-sortIcon>
                        </th>
                        <th class="width-15p" pSortableColumn="CustomerTypeDescription">
                            {{'client.type' | translate}}
                            <p-sortIcon field="CustomerTypeDescription"></p-sortIcon>
                        </th>
                        <th class="p-text-center width-8p" pTooltip="{{'Due-Today' | translate}}" tooltipPosition="top">
                            {{'Today' | translate}}
                            <i class="fa fa-question-circle ic-blue"></i>
                        </th>
                        <th class="p-text-center width-8p" pTooltip="{{'Due-This-Week' | translate}}"
                            tooltipPosition="top">
                            {{'This-Week' | translate}}
                            <i class="fa fa-question-circle ic-blue"></i>
                        </th>
                        <th class="p-text-center width-8p" pTooltip="{{'Due-Next-Week' | translate}}"
                            tooltipPosition="top">
                            {{'Next-Week' | translate}}
                            <i class="fa fa-question-circle ic-blue"></i>
                        </th>
                        <th class="p-text-center width-8p">
                            {{'Overdue' | translate}}
                        </th>
                        <th class="p-text-center width-8p">
                            {{'Pending' | translate}}
                        </th>
                        <th class="p-text-center width-8p">{{'mgmt-inprogress' | translate}}
                        </th>
                        <th class="p-text-center width-8p">
                            {{'mgmt-review' | translate}}
                        </th>
                        <th class="p-text-center width-8p">
                            {{'mgmt-completed' | translate}}
                        </th>
                    </tr>
                </ng-template>

                <!-- Table Body -->
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr>
                        <td>
                            <a class="hyperlink-text p-text-truncate"
                                routerLink="/project-management/{{rowData['ClientID']}}/clientDetails">{{rowData.ClientName}}</a>
                        </td>
                        <td class="p-text-truncate" showDelay="800" pTooltip="{{ rowData.CustomerTypeDescription }}"
                            tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                            {{rowData.CustomerTypeDescription}}
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.today > 0">
                            <div class="badge-number bg-orange">{{rowData.today}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.today == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.thisweek > 0">
                            <div class="badge-number bg-orange">{{rowData.thisweek}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.thisweek == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.nextweek > 0">
                            <div class="badge-number bg-orange">{{rowData.nextweek}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.nextweek == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.pastdue > 0">
                            <div class="badge-number bg-red">{{rowData.pastdue}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.pastdue == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.pendingcount > 0">
                            <div class="badge-number bg-blue">{{rowData.pendingcount}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.pendingcount == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.inprogresscount > 0">
                            <div class="badge-number bg-green">{{rowData.inprogresscount}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.inprogresscount == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.reviewcount > 0">
                            <div class="badge-number bg-purple">{{rowData.reviewcount}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.reviewcount == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center p-px-3" *ngIf="rowData.completedcount > 0">
                            <div class="badge-number bg-light-blue">{{rowData.completedcount}}</div>
                        </td>
                        <td class="p-text-center" *ngIf="rowData.completedcount == 0">
                            &nbsp;
                        </td>
                    </tr>
                </ng-template>

                <!-- Table Footer-->
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="2" class="p-text-right ic-red">{{'Totals' | translate}}</td>
                        <td class="p-text-center ic-red" *ngIf="dueTodayTotal > 0">
                            {{ dueTodayTotal }}
                        </td>
                        <td *ngIf="dueTodayTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="dueTodayTotal > 0">
                            {{ thisWkTotal}}
                        </td>
                        <td *ngIf="dueTodayTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="NextWkTotal > 0">
                            {{ NextWkTotal}}
                        </td>
                        <td *ngIf="NextWkTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="overdueTotal > 0">
                            {{ overdueTotal}}
                        </td>
                        <td *ngIf="overdueTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="pendingTotal > 0">
                            {{ pendingTotal}}
                        </td>
                        <td *ngIf="pendingTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="inProgressTotal > 0">
                            {{ inProgressTotal}}
                        </td>
                        <td *ngIf="inProgressTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="reviewTotal > 0">
                            {{ reviewTotal}}
                        </td>
                        <td *ngIf="reviewTotal == 0">
                            &nbsp;
                        </td>
                        <td class="p-text-center ic-red" *ngIf="completedTotal > 0">
                            {{ completedTotal}}
                        </td>
                        <td *ngIf="completedTotal == 0">
                            &nbsp;
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">
                            <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                            </p-message>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>