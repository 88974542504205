<div class="layout-content p-pt-0">
    <form [formGroup]="myProfileForm" autocomplete="nope" novalidate>
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
        <button pButton pRipple label="Save" icon="fas fa-check" [disabled]="(!isFormValid)" class="p-button-sm p-pr-2 p-mr-2"  tooltipPosition="top" pTooltip="Save" (click)="saveProfileData(myProfileForm.value)"></button>
        </span>
        <div class="p-fluid p-formgrid p-grid p-mx-2">
            <div class="p-col-4">
                <div class="card p-mt-2 p-mb-0">
                    <h4 class="label-text">Name & Address</h4>
                    <div class="p-field p-col-12">
                        <label class="required-field label-text">User Name</label>
                        <input type="text" pInputText maxlength="80" autocomplete="nope" formControlName="StaffName" />
                        <small *ngIf="((myProfileForm.get('StaffName').invalid && myProfileForm.get('StaffName').touched) || myProfileForm.get('StaffName').dirty) && myProfileForm.get('StaffName').errors?.required" class="text-danger ic-red">User Name is required</small>
                        <small *ngIf="((myProfileForm.get('StaffName').invalid && myProfileForm.get('StaffName').touched) || myProfileForm.get('StaffName').dirty) && myProfileForm.get('StaffName').errors?.minlength" class="text-danger ic-red">User Name should contain at least 2 characters</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label class="label-text">Street 1</label>
                        <input type="text" pInputText maxlength="100" autocomplete="nope" formControlName="Street1" />
                        <small *ngIf="((myProfileForm.get('Street1').invalid && myProfileForm.get('Street1').touched) || myProfileForm.get('Street1').dirty) && myProfileForm.get('Street1').hasError('minlength')" class="text-danger ic-red">
                            Street 1 should contain at least 5 characters.
                        </small>
                    </div>
                    <div class="p-field p-col-12">
                        <label class="label-text">Street 2</label>
                        <input type="text" pInputText maxlength="100" autocomplete="nope" formControlName="Street2" />
                    </div>
                    <div class="p-field p-col-12">
                        <label class="label-text">City</label>
                        <input type="text" pInputText autocomplete="nope" formControlName="City" />
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">State</label>
                            <input type="text" pInputText autocomplete="nope" maxlength="2" formControlName="State" />
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Zip</label>
                            <input type="text" pInputText pKeyFilter="num" class="p-text-right" formControlName="Zip" maxlength="10" autocomplete="nope">
                        </div>
                    </div>
                    <!--  end of card -->
                </div>
                <div class="card p-my-2">
                    <h4 class="label-text">Login Information</h4>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-12">
                            <label class="label-text">Login Email</label>
                            <input type="text" pInputText autocomplete="off" maxlength="50" formControlName="Email" />
                            <small *ngIf="((myProfileForm.get('Email').invalid && myProfileForm.get('Email').touched) || myProfileForm.get('Email').dirty) && myProfileForm.get('Email').errors?.required" class="text-danger ic-red">Email is required</small>
                        <small *ngIf="((myProfileForm.get('Email').invalid && myProfileForm.get('Email').touched) || myProfileForm.get('Email').dirty) && myProfileForm.get('Email').errors?.email" class="text-danger ic-red">Please provide a valid email address</small>
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">Login Password</label>
                            <input type="password" pInputText autocomplete="new-password" formControlName="SystemPassword" />
                            <small *ngIf="((myProfileForm.get('SystemPassword').invalid && myProfileForm.get('SystemPassword').touched) || myProfileForm.get('SystemPassword').dirty) && myProfileForm.get('SystemPassword').hasError('minlength')" class="text-danger ic-red">
                                Password should contain at least 3 characters.
                            </small>
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Confirm Password</label>
                            <input type="password" pInputText autocomplete="off" formControlName="ConfirmPassword" />
                            <small *ngIf="myProfileForm.get('ConfirmPassword').touched && myProfileForm.get('ConfirmPassword').hasError('mismatchedPasswords')" class="text-danger">
                              Password do not match.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="card p-mt-2 p-mb-0">
                    <h4 class="label-text">Contact Information</h4>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">Office</label>
                            <p-inputMask mask="(999) 999-9999? x99999" autocomplete="nope" formControlName="TeleOffice"></p-inputMask>
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Mobile</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="TeleMobile"></p-inputMask>
                        </div>
                    </div>
                    <div class="p-field p-col-12">
                        <label class="label-text">Home</label>
                        <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="TeleHome"></p-inputMask>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">Description</label>
                            <input type="text" pInputText autocomplete="nope" maxlength="50" formControlName="Tele1Descr" />
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Telephone Number</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="Tele1"></p-inputMask>
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">Description</label>
                            <input type="text" pInputText autocomplete="nope" maxlength="50" formControlName="Tele2Descr" />
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Telephone Number</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="nope" formControlName="Tele2"></p-inputMask>
                        </div>
                    </div>
                </div>
                <div class="card p-mt-2">
                    <h4 class="label-text">Emergency Contact</h4>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">Name</label>
                            <input type="text" pInputText autocomplete="off" maxlength="50" formControlName="EmergencyName" />
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Relation</label>
                            <input type="text" pInputText autocomplete="off" maxlength="50" formControlName="EmergencyRelation" />
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6">
                            <label class="label-text">Phone 1</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="off" formControlName="EmergencyPhone1"></p-inputMask>
                        </div>
                        <div class="p-field p-col-6">
                            <label class="label-text">Phone 2</label>
                            <p-inputMask mask="(999) 999-9999" autocomplete="off" formControlName="EmergencyPhone2"></p-inputMask>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="card p-mt-2 p-pb-4">
                    <h4 class="label-text">Profile Picture</h4>
                    <div class="p-col-12 timeline-images">
                        <div class="p-grid p-my-5">
                            <div class="p-col-12 p-text-center p-mb-3">
                                <img *ngIf="isImageUrl" class="user-img-app" [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'" onError="this.src='assets/img/userForTable.png'" />
                                <i *ngIf="!isImageUrl" class="fal fa-user-circle ic-size-70"></i>
                            </div>
                            <div class="p-col-9 p-text-left p-mt-4">
                                <p-fileUpload #fileUpload mode="basic" accept="image/*" [auto]="true" maxFileSize="1000000" (onSelect)="fileEvent($event);" chooseLabel="Upload"></p-fileUpload>
                            </div>
                            <div class="p-col-3 p-text-right p-mt-4">
                                <p-button pRipple label="Remove" icon="fal fa-trash-alt" styleClass="p-button-sm p-button-danger" tooltipPosition="top" pTooltip="Remove" (click)="removeImage()"></p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>