<div class="layout-content p-pt-2">
    <!-- buttons -->
    <div class="clientform-btn-top">
        <button pButton pRipple label="Save" [disabled]="(!isFormValid)" icon="fal fa-check" class="p-button-sm p-pr-2 p-mr-2" (click)="saveScheduleData(scheduleForm.value)"></button>
    </div>
    <form role="form" [formGroup]="scheduleForm" novalidate>
        <div class="p-fluid p-formgrid p-grid p-mx-2">
            <div class="p-col-6">
                <div class="card sch-pref-mh">
                    <h4 class="label-text p-mb-3">Calendar Options</h4>
                    <div class="p-field p-col-4 p-mt-4">
                        <label class="label-text">First Day of Work Week</label>
                        <p-dropdown [options]="daysDataSet" scrollHeight="400px" formControlName="FirstDayofWeek" placeholder="Select a Week"></p-dropdown>
                    </div>
                    <div class="p-field p-col-4">
                        <label class="label-text">Default View</label>
                        <p-dropdown [options]="weekDataSet" formControlName="DefaultView" placeholder="Select a View"></p-dropdown>
                    </div>
                    <div class="p-field p-col-4">
                        <label class="label-text">Default Time Interval</label>
                        <p-dropdown [options]="intervalDataSet" formControlName="DefaultTimeInterval" placeholder="Select a Interval"></p-dropdown>
                    </div>
                    <div class="p-field p-col-4">
                        <label class="label-text">{{'user.AutoRefresh-Calendar' | translate}}</label>
                        <p-dropdown [options]="ScheduleRefreshTimerItem" formControlName="ScheduleRefreshTimer"
                            placeholder="{{'user.Select-a-Interval' | translate}}"></p-dropdown>
                    </div>
                    <div class="p-field p-col-9 p-mt-5">
                        <label class="label-text p-col-6 p-pl-0">My Appointment Color</label>
                        <p-colorPicker formControlName="Color" format="hex" styleClass="p-col-1"></p-colorPicker>
                    </div>
                    <!-- <div class="p-field p-col-9 p-mt-5">
                        <label class="label-text p-col-6 p-pl-0">Schedule - Show Me Only</label>
                        <p-checkbox formControlName="ShowOnlyMeOnSchedule" class="p-col-2" binary="true"></p-checkbox>
                    </div> -->
                </div>
            </div>
            <div class="p-col-6">
                <div class="card p-mb-3">
                    <h4 class="label-text p-mb-3">Visible Work Times on Calendar<i tooltipPosition="top" tooltipStyleClass="tooltip-width-800" pTooltip="User defined Start Time and End Times can be defined in order to limit hours of the day that are visible on the schedule.  There is still an option to Show Full Day in the schedule." class="fa fa-question-circle ic-blue cursor-hand  p-ml-1"></i></h4>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-4">
                            <label class="label-text p-mt-3">Start Time</label>
                            <p-dropdown [options]="timeIntervalDataSet" formControlName="StartTimeWork" placeholder="-- : -- --"></p-dropdown>
                        </div>
                        <div class="p-field p-col-4">
                            <label class="label-text p-mt-3">End Time</label>
                            <p-dropdown [options]="timeIntervalDataSet" formControlName="EndTimeWork" placeholder="-- : -- --"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="card p-mb-3">
                    <h4 class="label-text p-mb-3">Days to Display for Work Week View <i tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" pTooltip="User defined days can be set as default when in Work Week View." class="fa fa-question-circle ic-blue cursor-hand"></i></h4>

                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="0" label="Sunday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="1" label="Monday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="2" label="Tuesday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="3" label="Wednesday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="4" label="Thursday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="5" label="Friday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                    <div class="p-field p-col-12">
                        <p-checkbox name="group1" value="6" label="Saturday" class="label-text" [(ngModel)]="selectedCategories" [ngModelOptions]="{standalone: true}" formControlName="WebAppDaysofWeek"></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>