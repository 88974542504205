import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { HtmlEditorService, ImageService, LinkService, ResizeService, RichTextEditorComponent, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import * as AWS from 'aws-sdk';
import { SelectItem } from 'primeng/api';
import { fromEventPattern, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { SharedComponentsService } from '../shared-components-service';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';
import moment from 'moment';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService,ResizeService],
  encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements OnInit {
  public defaultNotesObj: any = { "ClientID": null, "CompanyID": null, "Ddate": null, "Description": null, "Inactive": false, "NoteCategory": null, "NoteCategoryID": null, "ProjectMasterID": null, "StaffID": null, "Subject": null };
  @Output() closeNotesModal = new EventEmitter<any>();
  @Input() displayAddNotesModal: boolean = false;
  originatingPartner: SelectItem[];
  projectsList: any = [];
  @ViewChild('addNotesForm') form;
  noteCategories: SelectItem[];
  public awsBucket: any;
  isFormValid: boolean = true;
  isEditNote: boolean = false;
  isProjectEnable: boolean = false;
  public showAttachedFiles: boolean = false;
  isFromMenu: boolean = false;
  shortcutLabels: any = [];
  filteredClients: any[];
  clientList: any = [];
  intervalid: any;
  filesStack: any = [];
  public notesObj: any = {};
  public selClient = null;
  public companyId;
  public resourceId;
  public userName;
  public staffID;
  files: File[] = [];
  fileNames = []

  @ViewChild('notesEditor')
  public rteObj: RichTextEditorComponent;

  public tools: ToolbarModule = {
    type: 'MultiRow',
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo'
    ]
  };

  public imageSettings = {
    saveFormat: "Base64"
  };
  @ViewChild("fileUpload") fileUpload: any;
  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    protected mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService,
    private router: Router,
    private translate: TranslateService,
    private auth: AuthGuard,
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.sharedSrvc.engagementDialogData
      .subscribe((data) => {
        if (this.selClient && this.selClient['ClientID']) {
          this.getProjects(this.selClient['ClientID']);
        }
      })
  }

  ngOnInit(): void {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.defaultNotesObj.Ddate = new Date();
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
    this.loadDefault();
    this.setAWSOBject();
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item['ContactRecord'] != true) {
          this.clientList.push(item);
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  loadDefault(){
    this.getAllStaffList();
    this.getCategories();
  }

  openNotesModal(data) {
    const parent = this;
    parent.selClient = null;
    parent.isProjectEnable = false;
    parent.showAttachedFiles = false;
    parent.filesStack = [];
    parent.fileNames = [];
    parent.files = [];
    
    if(data.isEditNote) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getClientsdocuments(data['CustomerNotesID']).subscribe(function (data:any) {
        parent.mangoAPISrvc.showLoader(false);
        if (data.length > 0) {
          parent.fileNames = data;
        }
      });
      
    }

    // parent.clientList = parent.encrDecSrvc.clientList;
    // parent.filteredClients = parent.encrDecSrvc.clientList;
    parent.defaultNotesObj.Ddate = new Date();
    parent.defaultNotesObj.Description = parent.defaultNotesObj['Description'] ? parent.defaultNotesObj['Description'] : '<div>&nbsp;</div>';
    parent.defaultNotesObj.StaffID = parent.staffID;
    if (this.originatingPartner.length <= 1) {
      this.getAllStaffList();
    }
    if (this.noteCategories.length == 0) {
      this.getCategories();
    }
    parent.getProjects(data.ClientID);
    setTimeout(() => {
      parent.displayAddNotesModal = true;
      parent.isEditNote = data.isEditNote;
      parent.isFromMenu = data.isFromMenu;
      parent.isProjectEnable = data.isProjectEnable ? data.isProjectEnable : false;
      parent.defaultNotesObj.ProjectMasterID = data.ProjectMasterID ? data.ProjectMasterID : null;
      if (data.isEditNote == false) {
        if (data.isFromMenu == false) {
          parent.defaultNotesObj.ClientID = data.ClientID;
          parent.selClient = { ClientName: data.ClientName, ClientID: data.ClientID };
        }
      } else {
        delete data['EngagementName'];
        parent.defaultNotesObj = data;
        parent.defaultNotesObj['Ddate'] = new Date(parent.defaultNotesObj['Ddate']);
        parent.selClient = { ClientName: data.ClientName, ClientID: data.ClientID };
        delete parent.defaultNotesObj.isEditNote;
      }

    }, 100);
  }

  createOrUpdateNotes(isCreate,isSaveAndNew) {
    const parent = this;
    for (let i = 0; i < this.noteCategories.length; i++) {
      if (this.noteCategories[i].value == this.defaultNotesObj.NoteCategoryID) {
        this.defaultNotesObj.NoteCategory = this.noteCategories[i].label;
      }
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.defaultNotesObj['Ddate'] = moment(parent.defaultNotesObj['Ddate']).format('YYYY/MM/DD')
    if (isCreate) {
      parent.mangoAPISrvc.addNote(parent.defaultNotesObj.ClientID, parent.defaultNotesObj).subscribe(function (data:any) {
        parent.mangoAPISrvc.notify('success', 'Created!', AppConstants.createMsg);
        parent.mangoAPISrvc.showLoader(false);
        if (parent.filesStack.length > 0) {
          parent.createDMSParent(true, data.id, isSaveAndNew);
        } else {
          if (isSaveAndNew) {
            parent.saveAndNew();
          } else {
            parent.closeNotesDialog(true);
          }

        }
      }, error => {
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
      });
    } else {
      parent.mangoAPISrvc.updateNote(parent.defaultNotesObj.CustomerNotesID, this.defaultNotesObj).subscribe(function (data) {
        parent.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
        parent.mangoAPISrvc.showLoader(false);
        if (parent.filesStack.length > 0) {
          parent.createDMSParent(true, parent.defaultNotesObj.CustomerNotesID, isSaveAndNew);
        } else {
          parent.closeNotesDialog(true);
        }
      }, error => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    }
  }

  async onSelect(event) {
    if ((this.selClient && !this.selClient.ClientID) || !this.defaultNotesObj.Ddate || !this.defaultNotesObj.Subject || !this.defaultNotesObj.NoteCategoryID || !this.defaultNotesObj.StaffID) {
      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('warning')}!`,
        text: `${this.translate.instant('all_fields_required')}`,
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok',
        timer: 2000
      })
      this.fileUpload.clear()
      return false;
    }
    const parent = this;
    const listOfAcceptedFiles = ['pdf', 'txt', 'xls', 'xlsx', 'doc', 'docx', 'csv', 'jpg', 'jpeg', 'png', 'tif', 'rtf'].toString();
    const filePos = event.files[0].name.lastIndexOf('.');
    const fileType = event.files[0].name.substring(filePos + 1, event.files[0].name.length);
    const fileSize = event.files[0].size / 1048576; //size in mb
    parent.mangoAPISrvc.showLoader(true);
    if (event.files && listOfAcceptedFiles.indexOf(fileType) > -1 && fileSize <= 30) {
      parent.files.push(...event.files);
      if (parent.defaultNotesObj.CustomerNotesID) {
        for (let index = 0; index < parent.files.length; index++) {
          const file = parent.files[index];
          setTimeout(() => {
            parent.uploadFile(file);
            parent.fileUpload.clear()
            parent.mangoAPISrvc.showLoader(false);
          }, 200);
        }
      } else {
        parent.mangoAPISrvc.addNote(parent.defaultNotesObj.ClientID, parent.defaultNotesObj).subscribe(function (data:any) {
          parent.isEditNote = true;
          parent.defaultNotesObj = data.data;
          for (let index = 0; index < parent.files.length; index++) {
            const file = parent.files[index];
            setTimeout(() => {
              parent.uploadFile(file);
              parent.fileUpload.clear()
              parent.mangoAPISrvc.showLoader(false);
            }, 200);
          }
        }, error => {
          parent.fileUpload.clear()
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
        });
      }


    } else {
      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('warning')}!`,
        text: `${this.translate.instant('accepted-files')}`,
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok',
        timer: 2000
      })
    }
  }

  ngAfterViewInit() {
    this.form.control.valueChanges
      .subscribe(values => {
        this.isFormValid = this.form.valid && this.form.dirty;
      });
  }

  editorChange(event){
    this.isFormValid = this.form.valid;
  }

  async uploadFile(fileObj) {
    const self = this;
    const UniqueName = this.mangoUtils.generateUUID() + '_' + fileObj.name;
    const filePos = fileObj.name.lastIndexOf('.');
    const fileType = fileObj.name.substring(filePos + 1, fileObj.name.length);
    const params = { Bucket: environment.AWS_BUCKET_NAME, Key: 'documents/' + UniqueName, Body: fileObj };
    self.isFormValid = false;
    self.awsBucket.upload(
      params,

      function (err, data) {
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.onRemove(fileObj);
        setTimeout(() => {
          const cloneObj = {};
          cloneObj['FName'] = fileObj.name;
          cloneObj['UniqueName'] = UniqueName;
          cloneObj['Size'] = self.mangoUtils.formatBytes(fileObj.size, 0);
          cloneObj['ModifyOn'] = new Date();
          cloneObj['FileType'] = fileType;
          cloneObj['ShowInPortal'] = false;
          cloneObj['IsCustomerNote'] = true;
          cloneObj['CustomerNotesID'] = self.defaultNotesObj.CustomerNotesID;
          cloneObj['ClientID'] = self.selClient.ClientID;
          cloneObj['CompanyID'] = self.companyId;
          cloneObj['CreatedOn'] = new Date();
          cloneObj['ModifyBy'] = self.userName;
          cloneObj['isLastRecord'] = self.files.length == 0 ? true : false;
          self.filesStack.push(cloneObj);
          self.fileNames.push(cloneObj);
          self.isFormValid = true;
          self.mangoAPISrvc.showLoader(false);
        }, 200);
      },

      self.mangoAPISrvc
    );
  }

  addDMSParentFolder(obj, isLastRecord?: any) {
    const parent = this;
    this.mangoAPISrvc.addDMSParentFolder(obj).subscribe((response) => {
      if (isLastRecord) {
        parent.filesStack = [];
        parent.fileNames = [];
        parent.isFormValid = true;
        parent.closeNotesDialog(true);
        parent.mangoAPISrvc.notify('success', 'Uploaded!', "Successfully Uploaded.");
        parent.mangoAPISrvc.showLoader(false);
      }
    });
  }

  createDMSParent(prm, customerNotesId, isSaveAndNew) {
    if (customerNotesId && this.filesStack.length > 0) {
      for (let index = 0; index < this.filesStack.length; index++) {
        const element = this.filesStack[index];
        element['CustomerNotesID'] = customerNotesId;
        const isLastRecord = (this.filesStack.length - 1 == index) ? true : false;
        this.addDMSParentFolder(element, isLastRecord);
        if (isSaveAndNew) {
          setTimeout(() => {
            this.saveAndNew();
          }, 50);
        }
      }
    }
  }

  onRemove(event) {
    this.isFormValid = true;
    this.files.splice(this.files.indexOf(event), 1);
  }

  getProjects(clientId) {
    const _this = this;
    _this.projectsList = [];
    if (!clientId) {
      return false;
    }
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(function (data: any) {
      const filterData = data.filter((note) => note.Inactive == false);
      _this.projectsList = [{ label: 'Select Project', value: null }]
      for (let i = 0; i < filterData.length; i++) {
        _this.projectsList.push({ label: filterData[i].EngagementName, value: filterData[i].ProjectMasterID })
      }
      _this.defaultNotesObj.ProjectMasterID = _this.defaultNotesObj.ProjectMasterID ? _this.defaultNotesObj.ProjectMasterID : _this.projectsList[0].value;
      _this.isProjectEnable = true;
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  handleSelectClick(obj) {
    this.selClient = obj;
    this.defaultNotesObj.ClientID = obj.ClientID;
    this.getProjects(obj.ClientID);
  }

  showFiles() {
    this.showAttachedFiles = !this.showAttachedFiles;
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (client.ClientName.toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  getAllStaffList() {
    const _this = this;
    _this.originatingPartner = [];
    const tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < tempList.length; i++) {
      if(tempList[i].Inactive)
        continue;

      _this.originatingPartner.push({ label: tempList[i].StaffName, value: tempList[i].StaffID })
    }
    _this.defaultNotesObj.StaffID = _this.staffID;
  }

  getCategories() {
    const _this = this;
    _this.noteCategories = [];
    const tempList = this.encrDecSrvc.getObject(AppConstants.noteCategories);
    for (let i = 0; i < tempList.length; i++) {
      _this.noteCategories.push({ label: tempList[i].NoteCategory, value: tempList[i].NoteCategoryID })
    }
    if (_this.noteCategories.length > 0) {
      _this.defaultNotesObj.NoteCategoryID = _this.noteCategories[0].value;
      _this.defaultNotesObj.NoteCategory = _this.noteCategories[0].label;
    }
  }

  saveAndNew() {
    this.showAttachedFiles = false;
    this.fileNames = [];
    this.files = [];
    this.defaultNotesObj.Subject = null;
    this.defaultNotesObj.Description = null;
    this.isEditNote = false;
    this.mangoAPISrvc.showLoader(false);
    this.sharedSrvc.setNotesDialogData(this.selClient);
  }

  closeNotesDialog(isRefresh) {
    this.form.reset();
     const url = this.router.url;
    if (this.isFormValid && (url == "/client/notes" || url == "/engagements/notes")) {
      this.sharedSrvc.setNotesDialogData(this.selClient);
    }
    this.defaultNotesObj = { "ClientID": null, "CompanyID": null, "Ddate": null, "Description": null, "Inactive": false, "NoteCategory": null, "NoteCategoryID": null, "ProjectMasterID": null, "StaffID": null, "Subject": null };
    this.showAttachedFiles = false;
    this.fileNames = [];
    this.files = [];
    this.selClient = null;
    if (isRefresh) {
      this.closeNotesModal.emit(this.defaultNotesObj);
    }
    this.displayAddNotesModal = false;
    this.bulkDelete();
    this.sharedSrvc.sharedCompVisibility.notes = false;
  }

  deleteFile(obj) {
    this.isFormValid = true;
    const params = { Bucket: environment.AWS_BUCKET_NAME, Key: 'documents/' + obj.UniqueName };
    const self = this;
    self.awsBucket.deleteObject(
      params,

      function (err, data) {
        if (err) {
          return;
        }
        self.isFormValid = true;
        if (!self.isEditNote || !obj.dmsParentID) {
          const index = self.fileNames.indexOf(obj);
          self.fileNames.splice(index, 1);
          self.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
          return false;
        }
        self.mangoAPISrvc.deleteDMSParentFolder(obj.dmsParentID).subscribe((objItem) => {
          const index = self.fileNames.indexOf(obj);
          self.fileNames.splice(index, 1);
          self.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
          self.isFormValid = true;
        });
      },

      self.mangoAPISrvc
    );
  }

  bulkDelete(): void {
    this.fileNames = [];
    if (this.filesStack.length > 0) {
      const self = this;
      const params = { Bucket: environment.AWS_BUCKET_NAME, Delete: { Objects: [] } };
      for (let index = 0; index < self.filesStack.length; index++) {
        const element = self.filesStack[index]['UniqueName'];
        const obj = {};
        obj['Key'] = 'documents/' + element;
        params.Delete.Objects.push(obj);
      }
      self.awsBucket.deleteObjects(
        params,

        function (err, data) {
          self.filesStack = [];
          if (err) console.log(err, err.stack); // an error occurred
          else console.log(data);           // successful response
        },

        self.mangoAPISrvc
      );
    }
  }
  // adding new engagent
  addEngagementPopUp() {
    const parent = this;

    if(!parent.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: parent.translate.instant('client.engagement.permission')
      });
      return
    }

    const obj = {};
    obj['isClientFlow'] = true;
    obj['isEditFlow'] = true;
    obj['ClientID'] = this.selClient['ClientID'];
    obj['ClientName'] = this.selClient['ClientName'];
    obj['AssignedTo'] = this.staffID;
    parent.sharedSrvc.openEngagementDialog(obj);
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = environment.AWS_REGION;
    this.awsBucket = new AWS.S3({ params: { Bucket: environment.AWS_BUCKET_NAME } });
    */
    this.awsBucket = (
      AWSBucketMask
    );
  }

  rteCreated(): void {
    this.rteObj.element.focus();
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38)
  }
}
