import {
  NgModule,

  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";

import {
  CommonModule,
} from "@angular/common";

import { 
  PickListModule 
} from 'primeng/picklist';

import {
  AdminRoutingModule,
} from "./admin-routing.module";

import {
  AdminComponent,
} from "./admin.component";

import {
  EmailAutomationModule,
} from "./email-automation/email-automation.module";

import {
  LabelPrintingComponent,
} from "./label-printing/label-printing.component";

import {
  ProfileComponent,
} from "./profile/profile.component";

import {
  AppSharedModule,
} from "@app/shared/modules";

import {
  SchedulePreferencesComponent,
} from "./schedule-preferences/schedule-preferences.component";

import {
  CalendarIntegrationComponent,
} from "./calendar-integration/calendar-integration.component";

import {
  MailIntegrationComponent,
} from "./mail-integration/mail-integration.component";

import {
  EmailSignatureComponent,
} from "./email-signature/email-signature.component";

import {
  CompanyDashboardModule,
} from "./company-dashboard/company-dashboard.module"

import {
  SuperDashboardModule,
} from "./super-dashboard/super-dashboard.module";

import {
  MicrosoftLoginComponent,
} from "./microsoft-login/microsoft-login.component";

import {
  TwoFactorAuthComponent,
} from "./two-factor-auth/two-factor-auth.component";

import {
  OthersComponent,
} from "./others/others.component";

import {
  UpdateHistorySubmitRequestsComponent,
} from "./update-history-submit-requests/update-history-submit-requests.component";

import {
  UserLogsComponent,
} from "./user-logs/user-logs.component";

@NgModule(
  {
    declarations: [
      AdminComponent,
      LabelPrintingComponent,
      ProfileComponent,
      SchedulePreferencesComponent,
      CalendarIntegrationComponent,
      MailIntegrationComponent,
      EmailSignatureComponent,
      TwoFactorAuthComponent,
      MicrosoftLoginComponent,
      OthersComponent,
      UpdateHistorySubmitRequestsComponent,
      UserLogsComponent,
    ],

    imports: [
      CommonModule,
      PickListModule,
      AppSharedModule,
      AdminRoutingModule,
      CompanyDashboardModule,
      EmailAutomationModule,
      SuperDashboardModule,
    ],

    schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA,
    ],
  }
)
export class AdminModule { }
