import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { RecurrenceEditorChangeEventArgs } from '@syncfusion/ej2-angular-schedule';
import { RRule } from 'rrule';
@Component({
  selector: 'app-rule-generator',
  templateUrl: './rule-generator.component.html'
})
export class RuleGeneratorComponent implements OnInit {
  public selectedType: number = 1;
  public rule: any;
  public dateSource: any = [];

  constructor(private breadcrumbService: BreadcrumbService, private translate: TranslateService) {
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('Rule-Generator'), icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {}

  onChange(args: RecurrenceEditorChangeEventArgs): void {
    const outputElement: HTMLElement = <HTMLElement>document.querySelector('#rule-output');
    outputElement.innerText = args.value;
    setTimeout(() => {
      this.genarateRules(outputElement.innerText);
    }, 500);
  }

  genarateRules(str) {
    if (str == "" || !str) {
      this.dateSource = [];
      return false;
    }
    const max = 50;
    this.rule = RRule.fromString(`RRULE:${str}COUNT=20`);
    this.rule.noCache = true;
    this.dateSource = this.rule.all();
  }
}
