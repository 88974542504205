<div class="center-box">
    <div class="layout-content p-mx-6 p-my-0 p-col-4">
        <p-card>
            <ng-template pTemplate="title">
                User Settings
            </ng-template>
            <ng-template pTemplate="subtitle">
                Please review and update your default billing rate
            </ng-template>
            <ng-template pTemplate="content">
                <form #settingMainForm="ngForm">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12">
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <label class="label-text required-field">First Name</label>
                                    <i pTooltip="This user will be set as the default Administrator" tooltipPosition="top"
                                        class="fa fa-question-circle p-ml-1 ic-blue"></i>
                                </div>
                                <div class="p-field p-col-6">
                                    <input pInputText type="text" [(ngModel)]="staffFirstName" name="firstName"
                                        id="firstName" #firstName="ngModel" required minlength="3" maxlength="70"
                                        readonly />
                                    <div *ngIf="firstName.touched && firstName.errors?.required" class="ic-orange">
                                        First name is required.</div>
                                    <div *ngIf="firstName.touched && firstName.errors?.minlength" class="ic-orange">
                                        Minimum of 3 characters</div>
                                    <div *ngIf="firstName.touched && firstName.errors?.maxlength" class="ic-orange">
                                        Maximum of 70 characters</div>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <label class="label-text required-field">Last Name</label>
                                </div>
                                <div class="p-field p-col-6">
                                    <input pInputText type="text" [(ngModel)]="staffLastName" name="lastName"
                                        #lastName="ngModel" id="lastName" required minlength="3" maxlength="70"
                                        readonly />
                                    <div *ngIf="lastName.touched && lastName.errors?.required" class="ic-orange">
                                        Last name is required.</div>
                                    <div *ngIf="lastName.touched && lastName.errors?.minlength" class="ic-orange">
                                        Minimum of 3 characters</div>
                                    <div *ngIf="lastName.touched && lastName.errors?.maxlength" class="ic-orange">
                                        Maximum of 70 characters</div>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <label class="label-text required-field">User Initials</label>
                                </div>
                                <div class="p-field p-col-6">
                                    <input pInputText class="text-uppercase" type="text" name="staffNumber"
                                        id="staffNumber" [(ngModel)]="user.StaffNumber" #staffNumber="ngModel" required
                                        minlength="2" maxlength="3" readonly />
                                    <div *ngIf="staffNumber.touched && staffNumber.errors?.required" class="ic-orange">
                                        Initials is required.</div>
                                    <div *ngIf="staffNumber.touched && staffNumber.errors?.minlength" class="ic-orange">
                                        Minimum of 2 characters</div>
                                    <div *ngIf="staffNumber.touched && staffNumber.errors?.maxlength" class="ic-orange">
                                        Maximum of 3 characters</div>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <label class="label-text required-field">Default Billing Rate</label>
                                </div>
                                <div class="p-field p-col-6">
                                    <input pInputText class="ht p-text-right" placeholder="0.00"
                                        (blur)="transformbillingrate($event,settingMainForm)" name="billingRate"
                                        id="billingRate" [(ngModel)]="userRatesDataSet.DefaultBillingRate" type="text"
                                        autocomplete="off" (focus)="$event.target.select()" maxlength="9"
                                        #billingRate="ngModel" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid p-nogutter p-justify-end">
                        <p-button label="Previous" (onClick)="previousStep()" class="p-pr-2" icon="pi pi-angle-left"
                            iconPos="left">
                        </p-button>
                        <p-button label="Next" (onClick)="saveUserData()" [disabled]="!isValidForm"
                            icon="pi pi-angle-right" iconPos="right">
                        </p-button>
                    </div>
                </form>
            </ng-template>
        </p-card>
    </div>
</div>