import { Injectable } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { HttpClient } from '@angular/common/http';
import ls from 'localstorage-slim';
import { AES } from 'crypto-js';
import encUTF8 from 'crypto-js/enc-utf8';
import { forkJoin, Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { MangoApiService} from '@app/_services';

@Injectable({
    providedIn: 'root'
})

export class EncrDecrService {
    public ls;
    public clientList: any = [];
    public activitiesList: any = [];
    public termsList: any = [];
    public companyData: any = {};
    private privatePhrase = environment.PRIVATE_PHRASE;

    _userActionOccured: Subject<void> = new Subject();
    get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() }

    constructor(private http: HttpClient, private mangoAPISrvc: MangoApiService) {
        // update localstorage-slim
        ls.config.encrypt = true; // global encryption
        ls.config.secret = AppConstants.encryptKey; // global secret
        // update encrypter to use AES encryption
        ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();
        // update decrypter to decrypt AES-encrypted data
        ls.config.decrypter = (data, secret) => {
            try {
            return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
            } catch (e) {
            // incorrect/missing secret, return the encrypted data instead
            return data;
            }
        };
    }

    hashPassword(password: string) {
        return AES.encrypt(password, this.privatePhrase.trim()).toString();
    }

    removeObject(key: string) {
        ls.remove(AppConstants.encryptKey + 'M' + key.split("").reverse().join("") + 'M' + AppConstants.encryptKey.split("").reverse().join(""));
    }

    getObject(key: string): any {
        return ls.get(AppConstants.encryptKey + 'M' + key.split("").reverse().join("") + 'M' + AppConstants.encryptKey.split("").reverse().join(""));
    }

    addObject(key: string, value: any) {
        ls.set(AppConstants.encryptKey + 'M' + key.split("").reverse().join("") + 'M' + AppConstants.encryptKey.split("").reverse().join(""), value)
    }

    //  getClientList() {
    //     let companyId = this.getObject(AppConstants.companyID);
    //     if (this.clientList.length == 0 && companyId) {
    //         this.http.get(`${environment.API_URL}/staff/getAllClientListByCompanyId/${companyId}`).subscribe(
    //         restItems => {
    //             this.clientList = restItems;                  
    //         }); 
    //     }             
    // }

    loadDefaultAPI() {
        const parent = this;
        const companyId = parent.getObject(AppConstants.companyID);
        const apisList: any = [];
        const clientUpdated = parent.getObject(AppConstants.clientUpdated);
        if ((parent.clientList.length == 0 && companyId) || clientUpdated) { 
            parent.mangoAPISrvc.showLoader(true);
            apisList.push(parent.http.get(`${environment.API_URL}/staff/getAllClientListByCompanyId/${companyId}`));
            apisList.push(parent.http.get(`${environment.API_URL}/company/setting/activityList/`));
            apisList.push(parent.http.get(`${environment.API_URL}/company/setting/companyMango/${companyId}`));
            apisList.push(parent.http.get(`${environment.API_URL}/company/terms`));

            forkJoin(apisList).subscribe(results => {
                parent.clientList = results[0];
                parent.activitiesList = results[1];
                parent.companyData = results[2][0];
                parent.termsList = results[3];
                parent.mangoAPISrvc.showLoader(false);
                
                this.addObject(AppConstants.companySettings, parent.companyData);

            });
        }
    }

    clearStorage() {
        sessionStorage.clear();
        localStorage.clear();
        this.clientList = [];
        ls.clear();
    }

    generateUUID() {
        let d = new Date().getTime();
        const uuid = AppConstants.uidKey.replace(/[xy]/g, function (c) {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    notifyUserAction() {
        this._userActionOccured.next();
    }   
}