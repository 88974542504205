import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
  MangoApiService,
  mangoUtils,
} from "@app/_services";
import { forkJoin } from 'rxjs'
import { AppConstants } from "@app/_helpers/api-constants";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
declare let numeral: any;
declare let $: any;

@Component({
  selector: "app-apply-advance-to-invoice",
  templateUrl: "./apply-advance-to-invoice.component.html",
})
export class ApplyAdvanceToInvoiceComponent implements OnInit {
  @Input() label: string;
  @Input() tooltip: string;
  @Input() selectedClient: any;
  @Input() disabled: boolean = false;
  @Output() onCloseApplyAdvanceDialog = new EventEmitter<{}>();
  isShowDialog: boolean = false;
  selectedPayments: any = [];
  paymentsDatasource: any = [];
  selectedInvoices: any = [];
  invoicesDatasource: any = [];
  paymentDetailsArr: any = [];
  bhPaymentDetailsArr: any = [];
  totalUnapplied: number = 0;
  totalApplied: number = 0;

  constructor(
    private mangoAPISrvc: MangoApiService,
    public mangoUtils: mangoUtils,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    
  }

  showDialog() {
    this.selectedInvoices = [];
    this.selectedPayments = [];
    this.paymentDetailsArr = [];
    this.bhPaymentDetailsArr = [];
    this.totalUnapplied = 0;
    this.totalApplied = 0;
    
    this.mangoAPISrvc.showLoader(true);
    if (this.selectedClient?.ClientID) {
      this.mangoAPISrvc
        .getOpenRetainersById(this.selectedClient.ClientID)
        .subscribe(
          (data: any) => {
            this.paymentsDatasource = data.map((obj) => {
              obj.PaymentUnapplied = obj.PaymentUnapplied.replace("$", "");
              
              return {
                ...obj,
                prevUnapplied: obj.PaymentUnapplied
              };
            });
            this.isShowDialog = true;
          },
          (err) => this.mangoAPISrvc.showLoader(false)
        );

      this.mangoAPISrvc.getInvoices(this.selectedClient.ClientID).subscribe(
        (data: any) => {
          this.invoicesDatasource = data.map((item) => {
            if(item.paymentDetails?.length > 0 && item.InvoiceBalance < 0) {
              item.paymentDetails = item.paymentDetails.map((paymentDetail) => {
                return {
                  ...paymentDetail,
                  bhInvoiceType: item.InvoiceType,
                  bhInvoiceBalance: item.InvoiceBalance
                }
              })

              this.bhPaymentDetailsArr.push(...item.paymentDetails)
            }

            return {
              ...item,
              checked: false,
              prevBalance: item.InvoiceBalance,
              prevPayments: item.TotalPayments,
              totalApplied: 0
            }
          }).filter((item) => {
            let invoiceBal = numeral(item.InvoiceBalance).value();
            invoiceBal = invoiceBal ? invoiceBal : 0.0;
            return (invoiceBal != 0 && invoiceBal > 0 && item.InvoiceType != 'Retainer Invoice');
          })

          this.mangoAPISrvc.showLoader(false);
          this.isShowDialog = true;
        },
        (err) => this.mangoAPISrvc.showLoader(false)
      );
    } else {
      this.mangoAPISrvc.showLoader(false);
    }
  }

  checkPmtHeaderCheckbox() {
    if(this.selectedInvoices.length > 0)
      return;

    this.computeTotalUnapplyAmt();
  }

  checkPmtCheckbox(rowData) {
    if(this.selectedInvoices.length > 0)
      return;

    this.computeTotalUnapplyAmt();
  }

  checkInvCheckbox(rowData) {
    rowData['checked'] = !rowData['checked'];

    if(rowData['checked']) {
      if(this.totalUnapplied == 0) {
        Swal.fire({
          icon: 'warning',
          title: `Warning!`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: this.translate.instant(`cr.zero_unapplied_amount`),
        });
        rowData['checked'] = false;
        this.selectedInvoices = this.selectedInvoices.filter(
          (invoice) => invoice.BillingHeaderID !== rowData.BillingHeaderID
        );
        return;
      }

      const invBalance = numeral(rowData['InvoiceBalance']).value()
      if(this.totalUnapplied > invBalance) {
        this.totalUnapplied = this.mangoUtils.subtractFloat(this.totalUnapplied, invBalance)
        rowData['totalApplied'] = this.mangoUtils.addFloat(rowData['totalApplied'], invBalance)
        rowData['TotalPayments'] = this.mangoUtils.addFloat(rowData['TotalPayments'], invBalance)
        rowData['InvoiceBalance'] = '0.00'
      } else {
        rowData['totalApplied'] = this.mangoUtils.addFloat(rowData['totalApplied'], this.totalUnapplied)
        rowData['InvoiceBalance'] = this.mangoUtils.subtractFloat(invBalance, this.totalUnapplied)
        rowData['TotalPayments'] = this.mangoUtils.addFloat(rowData['TotalPayments'], this.totalUnapplied)
        this.totalUnapplied = 0;
      }

      this.totalApplied = this.mangoUtils.addFloat(this.totalApplied, rowData['totalApplied']);

    } else {
      this.totalUnapplied = this.mangoUtils.addFloat(rowData['totalApplied'], this.totalUnapplied)
      
      rowData['TotalPayments'] = rowData['prevPayments']
      rowData['InvoiceBalance'] = rowData['prevBalance']

      this.totalApplied = this.mangoUtils.subtractFloat(this.totalApplied, rowData['totalApplied'])
      rowData['totalApplied'] = 0;

    }

    let appliedPayment = this.totalApplied
    let appliedToInvoice = rowData['totalApplied']
    this.selectedPayments.forEach(payment => {
      //create payment details
      if(rowData['checked']) {
        if(payment.PaymentUnapplied > 0) {
          payment['paymentAmt'] = 0;
          if(appliedToInvoice > 0) {
            if(appliedToInvoice > payment.PaymentUnapplied) {
              payment['paymentAmt'] = payment.PaymentUnapplied
              appliedToInvoice = this.mangoUtils.subtractFloat(appliedToInvoice, payment.PaymentUnapplied)
            } else {
              payment['paymentAmt'] = appliedToInvoice
              appliedToInvoice = 0;
            }
          }
  
          const paymentDetails = {
            BillingHeaderID: rowData['BillingHeaderID'],
            InvoiceNumber: rowData['InvoiceNumber'],
            InvoiceDate: rowData['InvoiceDate'],
            ClientID: rowData['ClientID'],
            PaymentNote: "Advance Payment" + (payment['PaymentNote'] ? ` - ${payment['PaymentNote']}` : ''),
            PaymentHeaderID: payment.PaymentHeaderID,
            PaymentType: payment.PaymentType,
            PaymentAmount: payment['paymentAmt'],
            AppliedAmount: payment['paymentAmt'],
            PaymentDate: payment.PaymentDate || this.mangoUtils.removeOffset(new Date()),
          }
  
          if(paymentDetails.PaymentAmount > 0)
            this.paymentDetailsArr.push(paymentDetails)
        }
        
      } else {
        //remove payment details where BillingHeaderID equal to
        //rowData['BillingHeaderID] from the list
        this.paymentDetailsArr = this.paymentDetailsArr.filter(
          (payment) => payment.BillingHeaderID !== rowData["BillingHeaderID"]
        );
      }

      if(payment.prevUnapplied > appliedPayment) {
        payment.PaymentUnapplied = this.mangoUtils.subtractFloat(payment.prevUnapplied, appliedPayment)
        appliedPayment = 0
      } else {
        payment.PaymentUnapplied = 0
        appliedPayment = this.mangoUtils.subtractFloat(appliedPayment, payment.prevUnapplied)
      }
    })

    console.log(this.selectedInvoices)
  }

  computeTotalUnapplyAmt() {
    this.totalUnapplied = 0;
    this.selectedPayments.forEach((payment) => {
      this.totalUnapplied = this.mangoUtils.addFloat(this.totalUnapplied, payment.PaymentUnapplied);
    })
  }

  onSave() {
    console.log({ paymentDetails: this.paymentDetailsArr, invoices: this.selectedInvoices, payments: this.selectedPayments})
    
    this.mangoAPISrvc.showLoader(true);
    const isProcessDone = {
      payments: false,
      invoices: false
    }
    let countIntervalPmt = 0;
    let countIntervalInv = 0;
    const intervalPmt = setInterval(() => {
      if(countIntervalPmt < this.selectedPayments.length) return;
      isProcessDone.payments = true;
      clearInterval(intervalPmt);
    }, 500)

    const intervalInv = setInterval(() => {
      if(countIntervalInv < this.selectedInvoices.length) return;
      isProcessDone.invoices = true;
      clearInterval(intervalInv);
    }, 500)

    const intervalFinal = setInterval(() => {
      if(isProcessDone.invoices && isProcessDone.payments) {
        clearInterval(intervalFinal);
        this.createPaymentDetails(this.paymentDetailsArr).subscribe((pdRes) => {
          this.mangoAPISrvc.showLoader(false);
          Swal.fire({
            icon: 'success',
            title: `Success`,
            showCancelButton: false,
            allowEscapeKey: true,
            allowEnterKey: true,
            confirmButtonText: 'OK',
            text: this.translate.instant(`cr.advance_payment_applied_successfully`),
          }).then((res) => {
            this.showDialog();
          })
        }, error => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        })
      } else
        return;
    }, 1000)
    
    this.selectedPayments.forEach((payment) => {
      if(payment.prevUnapplied != payment.PaymentUnapplied) {
        this.mangoAPISrvc.updatePaymentHeader({ PaymentUnapplied: payment.PaymentUnapplied }, payment.PaymentHeaderID).subscribe((itemdata) => {
          if(payment.BillingHeaderID) {
            this.mangoAPISrvc.getBillingHeaderById(payment.BillingHeaderID).subscribe((advPaymentData: any) => {
              if(payment.PaymentUnapplied != 0) {
                advPaymentData.InvoiceAmount = numeral(payment['PaymentUnapplied']).value() * -1
                advPaymentData.InvoiceBalance = numeral(advPaymentData.InvoiceAmount).value()
                
                this.mangoAPISrvc.updateBillingHeader(advPaymentData, advPaymentData.BillingHeaderID).subscribe((data) => {
                  countIntervalPmt++
                }, error => {
                  this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                  this.mangoAPISrvc.showLoader(false);
                })
              } else {
                if (advPaymentData) {
                  this.mangoAPISrvc.deleteBillingHeader(payment.BillingHeaderID).subscribe((data) => {
                    countIntervalPmt++
                  }, error => {
                    this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                    this.mangoAPISrvc.showLoader(false);
                  });
                } else {
                  countIntervalPmt++
                }
              }
            })
          } else {
            const paymentHeaderDetails = this.bhPaymentDetailsArr.filter((item) => item.PaymentHeaderID == payment.PaymentHeaderID)
            if(paymentHeaderDetails?.length > 0) {
              const newPaymentDetailArr = this.paymentDetailsArr.filter((item) => item.PaymentHeaderID == payment.PaymentHeaderID)
              let totalUnapplied = newPaymentDetailArr.reduce((a, b) => { return a + +numeral(b.PaymentAmount).value(); }, 0) || 0

              paymentHeaderDetails.forEach((detail) => {
                detail['amount'] = 0;
                totalUnapplied = this.mangoUtils.roundOffDecimals(totalUnapplied)
                if(totalUnapplied > 0) {
                  // this.mangoAPISrvc.getBillingHeaderById(detail.BillingHeaderID).subscribe((advPaymentData: any) => {
                  const advBalance = Math.abs(numeral(detail.bhInvoiceBalance).value())
                  if(totalUnapplied > advBalance) {
                    detail['amount'] = 0;
                    totalUnapplied = this.mangoUtils.subtractFloat(totalUnapplied, advBalance)
                  } else {
                    detail['amount'] = this.mangoUtils.subtractFloat(advBalance, totalUnapplied)
                    totalUnapplied = 0
                  }
                
                  this.mangoAPISrvc.getBillingHeaderById(detail.BillingHeaderID).subscribe((advPaymentData: any) => {

                    advPaymentData.InvoiceAmount = Math.abs(detail['amount']) * -1;
                    advPaymentData.InvoiceBalance = numeral(advPaymentData.InvoiceAmount).value()

                    if(advPaymentData.InvoiceBalance == 0) {
                      //delete payment detail
                      this.mangoAPISrvc.deletePaymentDetails(detail.PaymentDetailID).subscribe((res) => {
                        this.mangoAPISrvc.deleteBillingHeader(advPaymentData.BillingHeaderID).subscribe((res) => {
                          
                        }, error => {
                          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                          this.mangoAPISrvc.showLoader(false);
                        })
                      }, error => {
                        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                        this.mangoAPISrvc.showLoader(false);
                      })
                    } else {
                      const paymentDetailObj = {
                        AppliedAmount: Math.abs(advPaymentData.InvoiceBalance),
                        PaymentAmount: Math.abs(advPaymentData.InvoiceBalance)
                      }

                      this.mangoAPISrvc.updatePaymentDetail(paymentDetailObj, detail.PaymentDetailID).subscribe((res) => {
                        this.mangoAPISrvc.updateBillingHeader(advPaymentData, advPaymentData.BillingHeaderID).subscribe((data) => {
                          
                        }, error => {
                          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                          this.mangoAPISrvc.showLoader(false);
                        })
                      }, error => {
                        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                        this.mangoAPISrvc.showLoader(false);
                      })
                    }
                  }, error => {
                    this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
                    this.mangoAPISrvc.showLoader(false);
                  })
                }
              })
              countIntervalPmt++
            }
          }
        }, error => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        })
      } else countIntervalPmt++
    })

    this.selectedInvoices.forEach((invoice) => {
      if(invoice.InvoiceBalance != invoice.prevBalance) {
        this.mangoAPISrvc.updateBillingHeader(invoice, invoice.BillingHeaderID).subscribe((data: any) => {
          countIntervalInv++
        }, error => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        })
      } else countIntervalInv++
    })
  }

  createPaymentDetails(paymentDetailsArr) {
    const observableBatch = [];
    paymentDetailsArr.forEach((paymentDetail) => {
      observableBatch.push(this.mangoAPISrvc.createPaymentDetails(paymentDetail))
    });
    return forkJoin(observableBatch);
  }

  resetInvoices() {
    this.selectedInvoices = [];
    this.paymentDetailsArr = [];
    this.totalApplied = 0;

    this.invoicesDatasource = this.invoicesDatasource.map((invoice) => {
      return {
        ...invoice,
        checked: false,
        InvoiceBalance: invoice.prevBalance,
        TotalPayments: invoice.prevPayments,
        totalApplied: 0
      }
    })

    this.computeTotalUnapplyAmt();
  }

  onClear() {
    console.log({details: this.bhPaymentDetailsArr})
    this.selectedInvoices = [];
    this.selectedPayments = [];
    this.paymentDetailsArr = [];
    this.totalUnapplied = 0;
    this.totalApplied = 0;

    this.invoicesDatasource = this.invoicesDatasource.map((invoice) => {
      return {
        ...invoice,
        checked: false,
        InvoiceBalance: invoice.prevBalance,
        TotalPayments: invoice.prevPayments,
        totalApplied: 0
      }
    })

    this.paymentsDatasource = this.paymentsDatasource.map((payment) => {
      return {
        ...payment,
        PaymentUnapplied: payment.prevUnapplied
      }
    })
  }

  onAutoApply() {
    if(this.totalUnapplied == 0) {
      Swal.fire({
        icon: 'warning',
        title: `Warning!`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this.translate.instant(`cr.zero_unapplied_amount`),
      });
      
      return;
    }

    if(this.invoicesDatasource?.length == 0) {
      Swal.fire({
        icon: 'warning',
        title: `Warning!`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this.translate.instant(`cr.no_invoice`),
      });
      
      return;
    }

    this.resetInvoices()
    this.invoicesDatasource.forEach((invoice, index) => {
      setTimeout(() => {
        if(this.totalUnapplied > 0) {
          $(`.inv-checkbox-${invoice.BillingHeaderID}`).click()
          this.selectedInvoices = [...this.selectedInvoices, invoice]
        }
      }, 100)
    })
  }

  onCloseDialog() {
    if(this.isShowDialog)
      this.onCloseApplyAdvanceDialog.emit({});

    this.isShowDialog = false
  }
}

