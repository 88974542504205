<div
  class="
    p-grid
  "
>
  <div
    class="
      p-col-12
    "
  >
    <p-tabMenu
      *ngIf="
        isProfileView
      "

      [model]="items"
      [activeItem]="selectedMenu"
    >
    </p-tabMenu>
    <router-outlet>
    </router-outlet>
  </div>
</div>
