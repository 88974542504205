<div class="merchant-setup">
    <div class="layout-content">
        <div class="p-grid p-justify-center p-mx-2" *ngIf="!url">
            <div class="p-col-4 p-mt-2">
                <div class="box box-stretched">
                    <span>
                        <h3 class="label-text">
                            {{ 'credit-cards' | translate }}
                            <img class="img-responsive pull-right user-box" src="assets/mango/img/cards.png" />
                        </h3>
                    </span>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.start-accepting-credit-cards' | translate }}
                    </div>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.flat-rate-289' | translate }}.
                    </div>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.settles-to-your-bank' | translate }} {{ 'MerchantSetup.24-48-hours' |
                        translate }}.
                    </div>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.accept-payments' | translate }}
                    </div>
                </div>
            </div>
            <div class="p-col-4 p-mt-2">
                <div class="box box-stretched">
                    <h3 class="label-text">
                        ACH {{ 'processing' | translate }}
                        <i class="fal fa-university pull-right ic-size-40 user-box"></i>
                    </h3>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.flat-rate-2' | translate }}.
                    </div>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.settles-to-your-bank' | translate }} {{ 'MerchantSetup.3-5-days' | translate
                        }}.
                    </div>
                    <div class="p-pb-2 ic-size-16">
                        * {{ 'MerchantSetup.accept-ach' | translate }}
                    </div>
                </div>
            </div>

            <div class="p-col-12 p-pb-2 ic-size-17 p-text-center p-mt-6" *ngIf="!isErolled">
                <span class="p-text-bold p-text-center ">{{ 'MerchantSetup.never-leave-this-site' | translate }}.
                </span>{{
                'MerchantSetup.fill-out-your-information' | translate }}
            </div>
            <div class="p-col-4 p-pb-1 ic-size-17 p-text-center p-ml-6" *ngIf="!isErolled">
                {{ 'MerchantSetup.no-contract' | translate }}.
            </div>
            <!-- <div class="p-col-12 p-pb-1 ic-size-17 p-text-center p-ml-6 p-pt-5" *ngIf="!isErolled">
                <button *ngIf="superAdminVisible.includes(logincompanyId)" pButton pRipple type="button"
                    icon="fal fa-credit-card" label="{{ 'staxenrollment' | translate }} 3" class="pull-right"
                    class="p-button" routerLink="/accounting/cards-view"></button>
            </div> -->
            <div class="p-col-8 p-mt-1" *ngIf="isErolled">
                <div class="card">
                    <h3 class="label-text">
                        {{'MerchantSetup.electronic-payments-enroll-information' | translate}}
                        <i class="fal fa-info-square pull-right ic-size-40 user-box"></i>
                    </h3>
                    <div class="p-field p-grid">
                        <label for="lastname3" class="p-col-2 ic-size-18">{{'Provider' | translate}}</label>
                        <div class="p-col ic-red p-text-left ic-size-18">
                            <span>: &nbsp;{{serviceProvider}}</span>
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="firstname3" class="p-col-2 ic-size-18">{{ 'status' | translate }}</label>
                        <div class="p-col ic-red p-text-left ic-size-18 p-text-capitalize">
                            <span>: &nbsp;{{enrollStatus}}</span>
                        </div>

                    </div>
                    <div class="p-text-center" *ngIf="serviceProvider === 'CPAPAY'">
                        <img src="assets/img/cpapayLogoLarge.png[89].png" class="login-image"
                            alt="{{ 'Welcome-to-Mango-Billing' | translate }}" />
                    </div>
                    <div class="p-text-center" *ngIf="serviceProvider !== 'CPAPAY'">
                        <img src="assets/img/final-usio-color.png" class="login-image-usio"
                            alt="{{ 'Welcome-to-Mango-Billing' | translate }}" />
                    </div>
                    <div class="p-field p-grid p-d-flex p-jc-between">
                        <p-button pRipple label="CPAPAY {{'Login' | translate}}" *ngIf="serviceProvider === 'CPAPAY'"
                            styleClass="p-button-sm p-px-2 pull-right" class="p-button-help p-px-2"
                            tooltipPosition="top" pTooltip="CPAPAY {{'Login' | translate}}" (click)="redirectToLogin()">
                        </p-button>
                        <button *ngIf="serviceProvider !== 'CPAPAY'" pButton pRipple type="button"
                            icon="fal fa-hand-holding-usd" label="{{ 'MerchantSetup.transactions' | translate }}"
                            class="p-button" routerLink="/transactions"></button>

                        <button pButton pRipple type="button" icon="fal fa-credit-card"
                            label="{{ 'staxenrollment' | translate }}" class="pull-right" class="p-button"
                            (click)="redirectStaxToLogin()"></button>
                    </div>
                </div>
                <!-- <div *ngIf="serviceProvider =='USIO'" class="p-text-center">
                    <button pButton pRipple type="button" icon="fal fa-credit-card"
                        label="{{ 'MerchantSetup.switch-process-enrollment-cpapay' | translate }}"
                        class="p-button p-m-4" (click)="enrollNow()"></button>
                    <h5 class="p-text-left">{{ 'MerchantSetup.enroll-in-cpapay' | translate }}</h5>
                </div>  -->
            </div>
            <!-- <div class="p-col-12 p-text-center p-m-4" *ngIf="!isErolled">
                <button pButton pRipple type="button" icon="fal fa-credit-card"
                    label="{{ 'MerchantSetup.process-enrollment-cpapay' | translate }}" class="p-button p-m-4"
                    (click)="enrollNow()"></button>
                <h5 class="p-text-center">{{ 'MerchantSetup.enroll-in-cpapay' | translate }}</h5>
            </div> -->
            <div class="p-col-12 p-text-center p-m-4" *ngIf="!isErolled">
                <button pButton pRipple type="button" icon="fal fa-credit-card"
                    label="{{ 'staxenrollment' | translate }}" class="pull-right" class="p-button"
                    (click)="redirectStaxToLogin()"></button>
            </div>
        </div>
        <div class="p-grid p-justify-center p-mx-2" *ngIf="url">
            <div class="p-col-12 p-text-center p-mt-5">
                <iframe [attr.src]="url ? url : null" frameBorder="0" style="width: 100%; height: 700px"></iframe>
            </div>
        </div>
    </div>
</div>