import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-roles-settings',
  templateUrl: './manage-roles.component.html'
})
export class ManageRolesComponent implements OnInit {

  groupedPermissions
  masterPermissions
  permissionProfiles
  selectedProfileToManage

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: "Settings" },
      { label: "Manage Roles", icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {
    this.getUserPermissions()
  }

  getUserPermissions() {
    this.mangoAPISrvc.getUserPermissions().subscribe(
      (result: any) => {
        this.groupedPermissions = result?.groupedPermissions;
        this.masterPermissions = result?.masterPermissions;
        this.permissionProfiles = result?.staffRoles?.map((role) => {
          return {
            label:
              role.Title === "Administrator"
                ? "Administrator (Non-Modifiable)"
                : role.Title,
            value: role.StaffRoleID,
            role,
          };
        });

        if (!this.selectedProfileToManage) {
          this.selectedProfileToManage = this.permissionProfiles?.find(
            (item) => item.label == "Administrator (Non-Modifiable)"
          )?.value;
        }

        // this.expandNodes(this.userPermissions);
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.fetchErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

}
