<div class="layout-content">
    <span class="pull-right p-mr-3 clientform-btn-top">
        <button pButton pRipple type="button" icon="fal fa-cloud-upload"
            class="p-button-rounded p-button-text ic-size-17 p-mx-2" pTooltip="{{'dm.Upload_File' | translate}}"
            tooltipPosition="top" (click)="isUploadShow = !isUploadShow"
            [ngClass]="(selectedRow && selectedRow['LineType'] === 'C' && selectedRow['isUploadEnable'] == true)?'':'wrapper notAllowed'"></button>
        <button pButton pRipple type="button" icon="fal fa-columns"
            class="p-button-rounded p-button-text ic-size-17 p-mr-2" pTooltip="{{'dm.PDF_View' | translate}}"
            tooltipPosition="top" (click)="hideRightPanel();"></button>
        <button pButton pRipple type="button" icon="fal fa-cloud-download"
            class="p-button-rounded p-button-text ic-size-17" pTooltip="{{'pm.Download_Selected_File' | translate}}"
            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
            [ngClass]="(selectedRow && selectedRow['LineType'] === 'F' && selectedRow['isDownLoadEnable'] == true) && this.rowIndexes.length > 0?'':'wrapper notAllowed'"
            (click)="downloadAWSFile()"></button>
        <button pButton pRipple type="button" icon="fal fa-users-cog"
            class="p-button-rounded p-button-text ic-size-17 p-mx-2" pTooltip="{{'DMS-Settings' | translate}}"
            tooltipPosition="top"
            [ngClass]="(isApiReady && (staffPermission == 1 || staffPermission == 2))?'':'wrapper'"
            (click)="visibleSidebar = true"></button>
    </span>
    <div class="p-col-12">
        <div class="card p-mx-1">
            <label class="label-text p-mr-4 required-field">{{'client.name' | translate}}</label>
            <p-autoComplete [(ngModel)]="selClient" [ngModelOptions]="{standalone: true}" [style]="{'width':'20%'}"
                panelStyleClass="client-dropdown-autocomplete" autocomplete="off" [suggestions]="filteredClients"
                (completeMethod)="filterClients($event)" field="ClientName" (onSelect)="onRowSelect($event,true);"
                ngDefaultControl placeholder="{{'Search-Clients' | translate}}" (onFocus)="$event.target.select()"
                [dropdown]="true" required>
                <ng-template let-client pTemplate="item">
                    <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                </ng-template>
            </p-autoComplete>

            <span>
                <button pButton pRipple label="{{'clear' | translate}}" icon="fad fa-eraser"
                    class="p-button-sm p-pr-3 p-mx-2" tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"
                    (click)="onRowSelect($event,false)"></button>
            </span>
        </div>
    </div>
    <div class="p-col-12" class="panelHeight">
        <div class="p-mx-3 p-my-1">
            <ejs-splitter>
                <e-panes>
                    <e-pane size='{{panelsSettings.panel2_size}}' min='55%' max='60%'>
                        <ng-template #content>
                            <div class="content" [ngStyle]="{'width':isRightPanelShow? '100%' : '100%' }">
                                <div class="p-m-2">
                                    <span>
                                        <i class="fa fa-folders ic-yellow ic-size-22"></i>
                                    </span>
                                    <span class="p-mx-2">
                                        <label for="Files" class="label-text">{{'dm.Folders_and_Files' |
                                            translate}}</label>
                                    </span>
                                </div>
                                <div id='treeparent'>
                                    <div class="control-sectio treeparent">
                                        <ejs-treegrid #treeGrid [dataSource]='data' idMapping='dmsParentID'
                                            enableCollapseAll="true" [allowRowDragAndDrop]='true' [allowSorting]="false"
                                            parentIdMapping='ParentID' [treeColumnIndex]='1'
                                            [editSettings]='editSettings' [toolbar]='toolbar'
                                            [selectionSettings]='selectOptions' (actionBegin)="actionBegin($event)"
                                            (actionComplete)="actionComplete($event)" gridLines='None'
                                            allowResizing=true isResponsive='true' (rowDrop)="rowDropEvent($event)"
                                            [enableHover]='true' rowHeight='25' allowScrolling=true width='100%'
                                            height='100%' (toolbarClick)='clickHandler($event)'
                                            (rowSelected)='rowSelected($event)' (rowDeselected)='rowSelected($event)'
                                            [contextMenuItems]='contextMenuItems'
                                            (contextMenuOpen)='contextMenuOpen($event)'
                                            (contextMenuClick)='contextMenuClick($event)'
                                            (headerCellInfo)='headerCellInfo($event)'>
                                            <e-columns>
                                                <e-column field='dmsParentID' width='1' textAlign='Left'
                                                    isPrimaryKey='true' [visible]="false">
                                                </e-column>
                                                <e-column field='LineType' headerText="{{'file_name' | translate}}"
                                                    textAlign='Left' width=100>
                                                    <ng-template #template let-data>
                                                        <span *ngIf="data.LineType !== 'F'">
                                                            <span *ngIf="data.LineType === 'P'">
                                                                <i
                                                                    class="fa fa-folder ic-size-15 ic-yellow cursor-hand"></i>
                                                                {{data.FName}}
                                                            </span>
                                                            <span *ngIf="data.LineType === 'C'">
                                                                <i
                                                                    class="fa fa-folder ic-size-15 ic-blue cursor-hand"></i>
                                                                {{data.FName}}
                                                            </span>
                                                        </span>
                                                        <span *ngIf="data.LineType === 'F'">
                                                            <ejs-checkbox id='check{{data.dmsParentID}}' #checkbox
                                                                (change)="changeHandler($event, data)"
                                                                [checked]=data.isChecked>

                                                            </ejs-checkbox>
                                                            <span class="p-text-truncate" style="max-width: 135px"
                                                                title="{{data.FName}}">
                                                                <i *ngIf="data.FileType === 'xls' || data.FileType === 'xlsx' || data.FileType === 'csv'"
                                                                    class="fa fa-file-excel-o ic-size-15 folder-file excel cursor-hand imgspace"></i>
                                                                <i *ngIf="data.FileType === 'jpeg' || data.FileType === 'jpg' || data.FileType === 'bmp' || data.FileType === 'tif' || data.FileType === 'png'"
                                                                    class="fa fa-file-image-o ic-size-15 folder-file imges cursor-hand imgspace"></i>
                                                                <i *ngIf="data.FileType === 'txt'"
                                                                    class="fa fa-file-text-o ic-size-15 folder-file cursor-hand imgspace"></i>
                                                                <i *ngIf="data.FileType === 'pdf'"
                                                                    class="fa fa-file-pdf-o ic-size-15 folder-file pdf cursor-hand imgspace"></i>
                                                                {{data.FName}}
                                                            </span>
                                                        </span>

                                                    </ng-template>
                                                </e-column>
                                                <e-column field='ShowInPortal' headerText='SIP' width='30'
                                                    textAlign='Center' [allowFiltering]='false'>
                                                    <ng-template #template let-data> <span *ngIf="data.ShowInPortal"><i
                                                                class="fa fa-check folder-file"></i></span> <span
                                                            *ngIf="!data.ShowInPortal"></span> </ng-template>
                                                </e-column>
                                                <e-column field='FileType' headerText="{{'time.type' | translate}}"
                                                    width=30 textAlign='Left' [allowFiltering]='false'
                                                    [allowEditing]='false'>
                                                </e-column>
                                                <e-column field='Size' headerText="{{'Size' | translate}}" width=30
                                                    textAlign='Left' [allowFiltering]='false' [allowEditing]='false'>
                                                </e-column>
                                                <e-column field='ModifyBy' headerText="{{'dm.Modified_By' | translate}}"
                                                    width=50 textAlign='Left' [allowFiltering]='false'
                                                    [allowEditing]='false'>
                                                </e-column>
                                                <e-column field='ModifyOn' headerText="{{'dm.Modified_On' | translate}}"
                                                    width=50 textAlign='Left' format="MM/dd/yyyy hh:mm a" type='date'
                                                    [allowFiltering]='false' [allowEditing]='false'>
                                                </e-column>
                                                <e-column field='CreatedOn' headerText="{{'Created-On' | translate}}"
                                                    width=50 textAlign='Left' [allowFiltering]='false'
                                                    [allowEditing]='false' type='date' format="MM/dd/yyyy hh:mm a">
                                                </e-column>
                                            </e-columns>
                                        </ejs-treegrid>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </e-pane>
                    <e-pane [ngStyle]="{'visibility':isRightPanelShow? 'visible' : 'hidden' }"
                        size='{{panelsSettings.panel3_size}}' min='45%' max='40%' [resizable]='false'>
                        <ng-template #content>
                            <div class="content">
                                <div #viewer class="viewer"
                                    [ngStyle]="{'visibility':isViewerShow? 'visible' : 'hidden' }">
                                </div>
                            </div>
                        </ng-template>
                    </e-pane>
                </e-panes>
            </ejs-splitter>
        </div>
    </div>
    <div #dummyViewer class="dummyViewer"></div>
</div>

<p-sidebar [(visible)]="visibleSidebar" position="right" styleClass="p-sidebar-md" [dismissible]="true"
    [showCloseIcon]="true" (onHide)="closeDialog()" [closeOnEscape]="false" [style]="{width:'80%','overflow-y':'auto'}">
    <p-tabView (onChange)="handleChange($event)">
        <p-tabPanel header="{{'dm.Folder_Template' | translate}}">
            <div class="p-grid">
                <div class="p-col-12 pull-right p-text-right">
                    <span class="p-buttonset">
                        <!-- <button pButton pRipple icon="fal fa-arrow-left" label="Go Back" (click)="closeDialog()"></button> -->
                        <button pButton pRipple icon="fal fa-plus" label="{{'dm.Create_Template' | translate}}"
                            [ngClass]="(isCreateNew)?'':'wrapper'" (click)="createTemplate()"></button>
                        <button pButton pRipple icon="fal fa-clipboard" label="{{'dm.Add_to_My_Templates' | translate}}"
                            [ngClass]="(isMyTemplateEnabled)?'':'wrapper'" (click)="addToMyTemplate()"></button>
                        <button pButton pRipple icon="fal fa-clone" label="{{'pm.Duplicate_to_Clients' | translate}}"
                            [ngClass]="(isDuplicateEnabled)?'':'wrapper'" (click)="duplicateTemplate()"></button>
                        <button pButton pRipple icon="fal fa-trash-alt" label="{{'dm.Delete_Template' | translate}}"
                            [ngClass]="(showSaveButtons)?'':'wrapper'" (click)="deleteClientTemplateNames()"></button>
                    </span>
                </div>
            </div>
            <div class="p-grid">
                <!-- Dialog Left Grid  -->
                <div class="p-col-4 templateGrid dialoglefttree">
                    <ejs-treegrid #treetempleteGrid [dataSource]='settingsData' idMapping='UniqueID'
                        parentIdMapping='ParentID' [treeColumnIndex]='1' [selectionSettings]='selectOptions'
                        gridLines='None' enableHover='true' rowHeight='25' hideColumns
                        (rowSelected)='rowTempleteSelected($event,true)'
                        (rowDeselected)='rowTempleteSelected($event,false)' (queryCellInfo)="queryCellInfo($event)"
                        (actionComplete)="templeteActionComplete($event)">
                        <e-columns>
                            <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true' textAlign='Right'
                                width=70 [visible]="false"> </e-column>
                            <e-column field='TemplateName' headerText='' textAlign='Left' width=200></e-column>
                        </e-columns>
                    </ejs-treegrid>
                </div>
                <!-- Dialog Right Grid  -->
                <div class="p-col-8 p-mt-2">
                    <div *ngIf="!showDuplicate" [ngStyle]="{'visibility':showRightContent? 'visible' : 'hidden' }">
                        <div>
                            <div>
                                <label for="Template Name" class="ic-red">{{'dm.Template_Name' | translate}}</label>
                                <span class="pull-right" *ngIf="showSaveButtons">
                                    <button pButton type="button" label="Save" class="marin-right-2 p-mb-2 blue-btn"
                                        (click)="saveData()"></button>
                                </span>
                            </div>
                            <div class="p-my-2">
                                <input type="text" pInputText autocomplete="off"
                                    [(ngModel)]="selectedSettings['TemplateName']" name="itemtemplateName"
                                    id="itemtemplateName" #itemtemplateName="ngModel" (focus)="$event.target.select()"
                                    [disabled]="selectedSettings['readOnly']" class="inputText width-100p">
                            </div>
                        </div>
                        <div [ngStyle]="{'visibility':isShowGrid? 'visible' : 'hidden' }">
                            <div style="margin-top:20px;">
                                <label for="Folder Structure" class="ic-red">{{'dm.Folder_Structure' |
                                    translate}}</label>
                            </div>
                            <div class="rightSideGrid p-mt-2">
                                <ejs-treegrid #treeSettingtempleteGrid [dataSource]='settingsRightSideData'
                                    idMapping='UniqueID' parentIdMapping='ParentID' [treeColumnIndex]='1'
                                    gridLines='None' enableHover='true' rowHeight='25' [pageSettings]='pageSettings'
                                    [editSettings]='editSettings' [toolbar]='dialogToolbar'
                                    (queryCellInfo)="queryCellInfo($event)"
                                    (actionComplete)="templeteActionComplete($event)"
                                    (toolbarClick)='clickHandler($event)' (actionBegin)="templateactionBegin($event)"
                                    (rowSelected)='dialogRowSelected($event)' locale='en-US'
                                    (rowDeselected)='dialogRowSelected($event)'
                                    [contextMenuItems]='contextMenuDialogItems'
                                    (contextMenuOpen)='settingContextMenuOpen($event)'
                                    (contextMenuClick)='contextMenuClick($event)'>
                                    <e-columns>
                                        <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true'
                                            textAlign='Right' width='7' [visible]="false"> </e-column>
                                        <e-column field='FolderName' headerText="{{'dm.Folder_Name' | translate}}"
                                            textAlign='Left' width='70'></e-column>
                                        <!-- <e-column field='isGlobal' headerText='Action' width='15' textAlign='Left'></e-column> -->
                                    </e-columns>
                                </ejs-treegrid>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showDuplicate">
                        <div class="p-col-12">
                            <label class="p-pull-left label-text">{{'pm.Duplicate_Client' | translate}}</label>
                        </div>
                        <div class="p-col-12">
                            <p-table [value]="tasksDataSource" [totalRecords]="tasksDataSource.length"
                                [(selection)]="selectedClientsItems" [rowHover]="true" [responsive]="true"
                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                dataKey="ClientID" sortField="ClientName" [sortOrder]="1" [scrollable]="true"
                                (onFilter)="onDuplicateClientFilter($event)" [scrollHeight]="'calc(100vh - 310px)'"
                                [globalFilterFields]="['ClientName','CustomerTypeDescription','GroupDescriptionIDArrayDesc']"
                                #dttask>
                                <!-- Filter -->
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchTaskValue pInputText type="text"
                                                (input)="dttask.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dttask.reset();clearTaskSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <span>
                                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                                text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                                styleClass="p-ml-2">
                                            </p-message>
                                        </span>
                                        <span class="pull-right">
                                            <button pButton icon="fas fa-check"
                                                [disabled]="selectedClientsItems.length == 0"
                                                label="{{'process' | translate}}" (click)="bulkSave(true)"></button>
                                            <button pButton type="button" icon="fal fa-times"
                                                label="{{'close' | translate}}" class="p-mx-2"
                                                (click)="showDuplicate = false"></button>
                                        </span>
                                    </div>
                                </ng-template>
                                <!-- Table Header -->
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-3p p-text-center">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th class="width-25p p-text-left">{{'client.name' | translate}}</th>
                                        <th class="width-15p p-text-left">{{'client.type' | translate}}</th>
                                        <th class="width-15p p-text-left">{{'group' | translate}}</th>
                                    </tr>
                                </ng-template>
                                <!-- Rows -->
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td class="width-3p p-text-center">
                                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                        </td>
                                        <td class="width-25p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{rowData.ClientName}}" showDelay="800"
                                            tooltipStyleClass="tooltip-no-wrap">{{rowData.ClientName}}</td>
                                        <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{rowData.CustomerTypeDescription}}"
                                            showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                            {{rowData.CustomerTypeDescription}} </td>
                                        <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{rowData.GroupDescriptionIDArrayDesc}}"
                                            showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                            {{rowData.GroupDescriptionIDArrayDesc}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'dm.Group_Permissions' | translate}}">
            <form class="form-horizontal user-form" role="form" [formGroup]="myGroupForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 pull-right p-text-right">
                        <button pButton type="button" label="{{'save_button' | translate}}" icon="fal fa-check"
                            styleClass="p-button-sm" class="p-pr-2" (click)="saveGroupData(myGroupForm.value)"
                            [disabled]="!myGroupForm.dirty"></button>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12">
                        <label for="Default Folder Permissions" class="ic-red">{{'dm.Default_Folder_Permissions' |
                            translate}}</label>
                    </div>
                    <div class="p-datatable-wrapper">
                        <table role="grid" class="p-datatable p-component plain-table">
                            <thead class="p-datatable-thead">
                                <tr>
                                    <th>{{'dm.Permission_Profile' | translate}}</th>
                                    <th class="p-text-center">{{'dm.Full_Access' | translate}}</th>
                                    <th class="p-text-center">{{'Read' | translate}} <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                                            pTooltip="{{'dm.Allows_users_to_view_files_only' | translate}}"></i></th>
                                    <th class="p-text-center">
                                        {{'create' | translate}} <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_create_and_edit_folders' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i>
                                    </th>
                                    <th class="p-text-center">
                                        {{'mgmt-delete' | translate}} <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_delete_files_and_folders' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i>
                                    </th>
                                    <th class="p-text-center">{{'Upload' | translate}} <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_upload_files' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                    <th class="p-text-center">{{'Download' | translate}} <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_download_files' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                </tr>
                            </thead>
                            <tbody class="p-datatable-tbody">
                                <tr>
                                    <td class="p-text-left p-pt-2">{{'Admin' | translate}}</td>
                                    <td class="p-text-center p-pt-2">
                                        <p-checkbox binary="true" formControlName="admin_Full"
                                            (onChange)="applyFormAll('admin_')"></p-checkbox>
                                    </td>
                                    <td class="p-text-center p-pt-2">
                                        <p-checkbox binary="true" formControlName="admin_Read"
                                            (onChange)="checkPermission(myGroupForm.value,'admin_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center p-pt-2">
                                        <p-checkbox binary="true" formControlName="admin_Create"
                                            (onChange)="checkPermission(myGroupForm.value,'admin_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center p-pt-2">
                                        <p-checkbox binary="true" formControlName="admin_Delete"
                                            (onChange)="checkPermission(myGroupForm.value,'admin_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center p-pt-2">
                                        <p-checkbox binary="true" formControlName="admin_Upload"
                                            (onChange)="checkPermission(myGroupForm.value,'admin_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center p-pt-2">
                                        <p-checkbox binary="true" formControlName="admin_Download"
                                            (onChange)="checkPermission(myGroupForm.value,'admin_',true)"></p-checkbox>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-text-left">{{'manager' | translate}}</td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="mgr_Full"
                                            (onChange)="applyFormAll('mgr_')"> </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="mgr_Read"
                                            (onChange)="checkPermission(myGroupForm.value,'mgr_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="mgr_Create"
                                            (onChange)="checkPermission(myGroupForm.value,'mgr_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="mgr_Delete"
                                            (onChange)="checkPermission(myGroupForm.value,'mgr_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="mgr_Upload"
                                            (onChange)="checkPermission(myGroupForm.value,'mgr_',true)"></p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="mgr_Download"
                                            (onChange)="checkPermission(myGroupForm.value,'mgr_',true)"></p-checkbox>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-text-left">{{'Standard' | translate}}</td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="standard_Full"
                                            (onChange)="applyFormAll('standard_')"> </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="standard_Read"
                                            (onChange)="checkPermission(myGroupForm.value,'standard_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="standard_Create"
                                            (onChange)="checkPermission(myGroupForm.value,'standard_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="standard_Delete"
                                            (onChange)="checkPermission(myGroupForm.value,'standard_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="standard_Upload"
                                            (onChange)="checkPermission(myGroupForm.value,'standard_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="standard_Download"
                                            (onChange)="checkPermission(myGroupForm.value,'standard_',true)">
                                        </p-checkbox>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-text-left">{{'Limited_User' | translate}}</td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="limited_Full"
                                            (onChange)="applyFormAll('limited_')"> </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="limited_Read"
                                            (onChange)="checkPermission(myGroupForm.value,'limited_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="limited_Create"
                                            (onChange)="checkPermission(myGroupForm.value,'limited_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="limited_Delete"
                                            (onChange)="checkPermission(myGroupForm.value,'limited_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="limited_Upload"
                                            (onChange)="checkPermission(myGroupForm.value,'limited_',true)">
                                        </p-checkbox>
                                    </td>
                                    <td class="p-text-center">
                                        <p-checkbox binary="true" formControlName="limited_Download"
                                            (onChange)="checkPermission(myGroupForm.value,'limited_',true)">
                                        </p-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </p-tabPanel>
        <p-tabPanel header="{{'dm.Email_Templates' | translate}}">
            <div class="p-grid">
                <div class="p-col-12 pull-right p-text-right">
                    <button pButton type="button" label="{{'save_button' | translate}}" icon="fal fa-check"
                        styleClass="p-button-sm" class="p-pr-2" (click)="saveEmailTemplate()"
                        [disabled]="emailTemplateObj['EmailTemplateID'] == null || !isUserSettingsTouched"></button>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-4 templateGrid client_table dialoglefttree p-mt-2">
                    <p-table [columns]="emailCols" [value]="emailtemplateList" [totalRecords]="emailtemplateList.length"
                        dataKey="EmailTemplateID" selectionMode="single" [(selection)]="selectedObjs"
                        sortField="TemplateName" [sortOrder]="1" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 220px)'" #dtemailtask>
                        <!-- Table Header -->
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="p-text-center bg-blue">{{'dm.Email_Templates' | translate}}</th>
                            </tr>
                        </ng-template>
                        <!-- Rows -->
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <td class="p-text-left cursor-hand" (click)="onEmailRowSelect(rowData)">
                                    {{rowData.TemplateName}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="p-col-8" *ngIf="editorValue">
                    <div class="p-col-12">
                        <label for="Email Subject" class="ic-red p-mb-2">{{'dm.Email_Subject' | translate}}</label>
                        <div class="p-mt-2">
                            <input type="text" autocomplete="off" [(ngModel)]="emailTemplateObj['EmailSubject']"
                                name="itememailtemplateName" id="itememailtemplateName" #itemtemplateName="ngModel"
                                (focus)="$event.target.select()" class="inputText width-100p" pInputText>
                        </div>
                    </div>
                    <div class="p-my-2 p-col-12">
                        <ejs-richtexteditor id='customRTE' #customRTE [(value)]="editorValue"
                            [insertImageSettings]='imageSettings' (change)="isUserSettingsTouched = true"
                            [toolbarSettings]='tools'>
                        </ejs-richtexteditor>
                        <ejs-dialog #Dialog id="rteDialog" [buttons]='dlgButtons' (overlayClick)="dialogOverlay()"
                            [header]='header' [visible]='false' [showCloseIcon]='false' [target]='target'
                            (created)="dialogCreate()" [isModal]='true'>
                            <ng-template #content>
                                <div id="rteSpecial_char">
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'client.name' | translate}}"> %ClientName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'client.contact_name' | translate}}"> %ContactName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'client.name' | translate}}">
                                        %EngagementFolder% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Folder_Name' | translate}}"> %FolderName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top" pTooltip="{{'date' | translate}}">
                                        %Date% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Your_Firm_Name' | translate}}">
                                        %YourFirmName% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Link_To_Portal' | translate}}">
                                        %LinkToPortal% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'file_name' | translate}}"> %FileName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Confidentiality_Notice' | translate}}">
                                        %ConfidentialityNotice% </div>
                                </div>
                            </ng-template>
                        </ejs-dialog>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</p-sidebar>
<p-dialog header="{{defaultPermisssionObj['FolderName']}}" [(visible)]="settingsDisplay" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="1" [draggable]="true"
    [resizable]="false" (onHide)="settingsDisplay=isUserSettingsTouched=false">
    <div class="card p-pt-0">
        <div class="header-color text-nowrap col-sm-9">
            <h4 class="label-text ic-red">{{'dm.Folder_Access_Permissions' | translate}}</h4>
        </div>
        <div class="p-grid">
            <div class="p-datatable-wrapper ">
                <table role="grid" class="p-datatable p-component plain-table">
                    <thead class="p-datatable-thead">
                        <tr>
                            <th>{{'dm.Permission_Profiles' | translate}}</th>
                            <th *ngIf="defaultPermisssionObj['LineType'] !== 'P'">{{'dm.Full_Access' | translate}}</th>
                            <th *ngIf="defaultPermisssionObj['LineType'] !== 'P'">{{'Read' | translate}} <i
                                    tooltipPosition="top"
                                    pTooltip="{{'dm.Allows_users_to_view_files_only' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"></i>
                            </th>
                            <th>{{'create' | translate}} <i tooltipPosition="top"
                                    pTooltip="{{'dm.Allows_users_to_create_and_edit_folders' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"></i> </th>
                            <th>{{'mgmt-delete' | translate}} <i tooltipPosition="top"
                                    pTooltip="{{'dm.Allows_users_to_delete_files_and_folders' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"></i> </th>
                            <th *ngIf="defaultPermisssionObj['LineType'] !== 'P'">{{'Download' | translate}} <i
                                    tooltipPosition="top" pTooltip="{{'dm.Allows_users_Download_files' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"></i> </th>
                            <th *ngIf="defaultPermisssionObj['LineType'] !== 'P'">{{'Upload' | translate}} <i
                                    tooltipPosition="top" pTooltip="{{'dm.Allows_users_Upload_files' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"></i> </th>
                        </tr>
                    </thead>
                    <tbody class="p-datatable-tbody">
                        <tr>
                            <td class="p-pt-2">{{'Admin' | translate}}</td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['admin_Full']"
                                    (onChange)="applyAll('admin_')"></p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['admin_Read']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'admin_',false)"></p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['admin_Create']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'admin_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['admin_Delete']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'admin_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['admin_Download']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'admin_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['admin_Upload']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'admin_',false)"> </p-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-pt-2">{{'manager' | translate}}</td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['mgr_Full']"
                                    (onChange)="applyAll('mgr_')"></p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['mgr_Read']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'mgr_',false)"></p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['mgr_Create']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'mgr_',false)"></p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['mgr_Delete']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'mgr_',false)"></p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['mgr_Download']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'mgr_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['mgr_Upload']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'mgr_',false)"> </p-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-pt-2">{{'Standard' | translate}}</td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['standard_Full']"
                                    (onChange)="applyAll('standard_')"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['standard_Read']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'standard_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['standard_Create']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'standard_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['standard_Delete']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'standard_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['standard_Download']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'standard_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['standard_Upload']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'standard_',false)"> </p-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-pt-2">{{'Limited_User' | translate}}</td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['limited_Full']"
                                    (onChange)="applyAll('limited_')"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['limited_Read']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'limited_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['limited_Create']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'limited_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['limited_Delete']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'limited_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['limited_Download']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'limited_',false)"> </p-checkbox>
                            </td>
                            <td class="p-text-center" *ngIf="defaultPermisssionObj['LineType'] !== 'P'">
                                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['limited_Upload']"
                                    (onChange)="checkPermission(defaultPermisssionObj,'limited_',false)"> </p-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="p-grid p-m-2 p-pt-4">
            <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['ShowInPortal']"
                label="{{'dm.Show_Folder_In_Client_Portal' | translate}}" (onChange)="isUserSettingsTouched = true">
            </p-checkbox>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="saveSettingsData()"
                [disabled]="!isUserSettingsTouched" label="Save"></button>
            <button type="button" pButton pRipple icon="fal fa-times" label="{{'cancel_button' | translate}}"
                (click)="settingsDisplay=isUserSettingsTouched=false"></button>
        </ng-template>
    </div>
</p-dialog>
<p-sidebar [(visible)]="isUploadShow" position="bottom" [style]="{'min-height':'300px'}">
    <div class="card">
        <div class="header-color text-nowrap col-sm-9">
            <h4 class="label-text">{{'dm.Upload_Files' | translate}}</h4>
        </div>
        <div class="p-grid p-mx-2">
            <ngx-dropzone (change)="onSelect($event)"
                class="{{(selectedRow && selectedRow['LineType'] === 'C' && selectedRow['isUploadEnable'] == true) ? 'ic-blue dotted-border width-100p  p-mt-3' : 'p-mt-3 width-100p notAllowed'}}">
                <ngx-dropzone-label>
                    <button pButton pRipple type="button" icon="fal fa-cloud-upload p-pt-3 ic-size-18"
                        class="p-button-rounded p-button-danger p-button-text"></button>
                    <span>{{'Drag_Files_Here' | translate}} </span> <span class="ic-green">{{'Click_To_Browse' |
                        translate}} </span>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" class="dz-preview" [removable]="true"
                    (removed)="onRemove(f)">
                    <ngx-dropzone-label tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                        pTooltip="{{ f.name }} ">{{ f.name }}</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
    </div>
</p-sidebar>
<p-sidebar [(visible)]="isFilePreview" [position]="'right'" [style]="{width:'75%'}" [dismissible]="true"
    [showCloseIcon]="true" (onHide)="closePreviewDialog()" [closeOnEscape]="false">
    <div class="card p-px-0">
        <div #preiewviewer class="preiewviewer" [ngStyle]="{'visibility':isPreviewViewerShow? 'visible' : 'hidden' }">
        </div>
    </div>
</p-sidebar>
<p-dialog header="{{'dm.Invite_Client' | translate}}" [(visible)]="isInviteClient" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="closeClient()">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label for="From" class="required-field label-text">{{'From' | translate}}</label>
                <input [(ngModel)]="inviteClientEmailTemplate['from']" type="text" required readonly pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="To" class="required-field label-text">{{'To' | translate}}</label>
                <input type="text" [(ngModel)]="inviteClientEmailTemplate['to']" required pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="Subject" class="required-field label-text">{{'Subject' | translate}}</label>
                <input type="text" [(ngModel)]="inviteClientEmailTemplate['subject']" pInputText>
            </div>
            <div class="p-field p-col-12">
                <ejs-richtexteditor id='inviteRTE' #inviteRTE [(value)]="inviteEditorValue"
                    [toolbarSettings]='emailTools' [enabled]='true'> </ejs-richtexteditor>
                <ng-template #valueTemplate>&nbsp;</ng-template>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="sendInviteClient()"
                label="{{'Send' | translate}}"></button>
        </ng-template>
    </div>
</p-dialog>

<p-dialog header="{{'dm.New_Message' | translate}}" [(visible)]="isEmailDialog" [modal]="true" [style]="{width: '55vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="isEmailDialog=isUserSettingsTouched=false">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label for="From" class="required-field label-text">{{'From' | translate}}</label>
                <input [(ngModel)]="emailTemplate['from']" type="text" required (ngModelChange)="validateEmailForm()"
                    pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="To" class="required-field label-text">{{'To' | translate}}</label>
                <input type="text" [(ngModel)]="emailTemplate['to']" (ngModelChange)="validateEmailForm()" required
                    pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="Subject" class="required-field label-text">{{'Subject' | translate}}</label>
                <input type="text" [(ngModel)]="emailTemplate['subject']" required (ngModelChange)="validateEmailForm()"
                    pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="Subject" class="label-text">{{'Attachment' | translate}}</label>
                <div>
                    <span class="ic-red">{{emailTemplate['fileName']}}</span>
                </div>
            </div>
            <div class="p-field p-col-6">
                <p-checkbox [(ngModel)]="filePasswordchecked" (click)="showPassword()" binary="true"
                    label="{{'dm.Password_Protect_and_Encrypt' | translate}}">
                </p-checkbox>
            </div>
            <div class="p-field p-col-12">
                <ejs-richtexteditor id='emailRTE' #emailRTE [(value)]="emailEditorValue"
                    (ngModelChange)="validateEmailForm()" [toolbarSettings]='emailTools' [enabled]='true'>
                </ejs-richtexteditor>
                <ng-template #valueTemplate>&nbsp;</ng-template>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!isUserSettingsTouched"
                (click)="sendEmailWithAttachment()" label="{{'Send' | translate}}"></button>
        </ng-template>
    </div>
</p-dialog>

<p-dialog header="{{'dm.Add_Template' | translate}}" [(visible)]="isAddTemplate" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="isAddTemplate=isUserSettingsTouched=isTemplateShowGrid=false">
    <div class="p-fluid p-formgrid p-grid" *ngIf="isApiReady">
        <div class="p-field p-col-6">
            <div class="card p-pt-0">
                <ejs-treegrid #treeAddTemplateGrid [dataSource]='globalClientTemplateArr' idMapping='UniqueID'
                    parentIdMapping='ParentID' [treeColumnIndex]='1' gridLines='None' enableHover='true' rowHeight='25'
                    (rowSelected)='rowAddTemplateSelected($event,true)'
                    (rowDeselected)='rowAddTemplateSelected($event,false)' (queryCellInfo)="queryCellInfo($event)">
                    <e-columns>
                        <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true' textAlign='Right' width=70
                            [visible]="false"> </e-column>
                        <e-column field='TemplateName' headerText='' textAlign='Left' width=200></e-column>
                    </e-columns>
                </ejs-treegrid>
            </div>
        </div>
        <div class="p-field p-col-6">
            <div class="card p-pt-0" [ngStyle]="{'visibility':isTemplateShowGrid? 'visible' : 'hidden' }">
                <label for="Folder Structure" class="label-text">{{'dm.Folder_Structure' | translate}}</label>
                <ejs-treegrid [dataSource]='settingsRightSideData' idMapping='UniqueID' parentIdMapping='ParentID'
                    [treeColumnIndex]='1' gridLines='None' enableHover='true' rowHeight='25'
                    [pageSettings]='pageSettings' (queryCellInfo)="queryCellInfo($event)">
                    <e-columns>
                        <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true' textAlign='Right' width='7'
                            [visible]="false"> </e-column>
                        <e-column field='FolderName' headerText='FolderName' textAlign='Left' width='70'></e-column>
                    </e-columns>
                </ejs-treegrid>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" (click)="bulkSave(false)"
            [disabled]="settingsRightSideData.length == 0" label="{{'save_button' | translate}}"></button>
    </ng-template>
</p-dialog>

<p-dialog header="{{'dm.Create_File_Attachment_Password' | translate}}" [(visible)]="isShowPassword" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="isShowPassword=false;filePassword = '';">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12" *ngIf="selectedRow">
                <label for="Subject" class="label-text">{{'Attachment' | translate}}</label>
                <div class="p-col-8 p-pl-0">
                    <span class="ic-red">{{selectedRow['FName']}}</span>
                </div>
            </div>
            <div class="p-field p-col-12">
                <label for="Subject" class="label-text required-field">{{'password' | translate}}</label>
                <input type="text" [(ngModel)]="filePassword" required pInputText>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="savePassword()"
                label="{{'save_button' | translate}}"></button>
            <button type="button" pButton pRipple icon="fal fa-times" label="{{'clear' | translate}}"
                (click)="clearPwd()"></button>
        </ng-template>
    </div>
</p-dialog>

<p-dialog header="{{defaultPermisssionObj['FolderName']}}" [(visible)]="settingsNotifications" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="hideNotifications()">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['isNotifyClientUpload']"
                    label="{{'dm.Notify_Client_by_email' | translate}}" class="ic-red"
                    (onChange)="isUserSettingsTouched = true">
                </p-checkbox>
            </div>
            <div class="p-field p-col-6">
                <label for="Subject" class="label-text">{{'dm.Upload_Notification' | translate}}</label>
                <div class="p-col-12 p-pl-0">
                    <p-dropdown [options]="uploadList" [(ngModel)]="defaultPermisssionObj['EmailUploadTemplateID']" placeholder="{{ 'dms.Select_Email_Template' | translate }}"
                        (onChange)="changeUpload($event,true)" styleClass="width-100p"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-col-6">
                <label for="Subject" class="label-text">{{'dm.Download_Notification' | translate}}</label>
                <div class="p-col-12 p-pl-0">
                    <p-dropdown [options]="downloadList" [(ngModel)]="defaultPermisssionObj['EmailDownloadTemplateID']" placeholder="{{ 'dms.Select_Email_Template' | translate }}"
                        (onChange)="changeUpload($event,false)" styleClass="width-100p"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-col-12">
                <label class="ic-red">{{'dm.Select_Users_to_Receive_Email_Notifications' | translate}}</label>
                <p-pickList [source]="sourceList" [target]="defaultPermisssionObj['StaffID']"
                    sourceHeader="{{'dm.Available_Users' | translate}}"
                    targetHeader="{{'dm.Selected_Users' | translate}}" [responsive]="true" filterBy="StaffName"
                    dragdrop="true" sourceFilterPlaceholder="{{'dm.Search_by_User' | translate}}"
                    targetFilterPlaceholder="{{'dm.Search_by_User' | translate}}" [showSourceControls]="false"
                    [showTargetControls]="false" (onMoveToTarget)="isUserSettingsTouched =true"
                    [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}"
                    (onMoveToSource)="isUserSettingsTouched =true">
                    <ng-template let-rowData pTemplate="item">
                        <div class="ui-helper-clearfix" *ngIf="rowData && rowData.StaffName">
                            <img class="list-thumb-img profile-img"
                                [attr.src]="rowData['ProfilePic']? rowData['ProfilePic'] : 'assets/mango/img/userForTable.png'"
                                onError="this.src='assets/mango/img/userForTable.png'"
                                style="display:inline-block;margin:2px 0 2px 2px;border-radius:15px" width="30">
                            <div class="pull-right p-m-2">{{rowData.StaffName}}</div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="saveSettingsData()"
                [disabled]="!isUserSettingsTouched" label="{{'save_button' | translate}}"></button>
        </ng-template>
    </div>
</p-dialog>