import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { ActivitiesComponent } from './activities/activities.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { BanksComponent } from './banks/banks.component';
import { BillrateComponent } from './billrate/billrate.component';
import { ClientPortalComponent } from './client-portal/client-portal.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ListComponent } from './list/list.component';
import { MailConfigurationComponent } from './mail-configuration/mail-configuration.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { TimeAndExpensesComponent } from './time-and-expenses/time-and-expenses.component';
import { SettingsComponent } from './settings.component';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { OtherComponent } from './other/other.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ContextMenuModule ,ToolbarModule  } from '@syncfusion/ej2-angular-navigations';
import { RateTemplates } from './rate-templates/rate-templates.component';
import { ManageRolesModule } from '@app/shared/components/manage-roles/manage-roles.module';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { ModuleDateExclusionComponent } from './integrations/module-date-exclusion/module-date-exclusion.component';
import { MangoshareIntegrationComponent } from './mangoshare-integration/mangoshare-integration.component';

@NgModule({
  declarations: [SettingsComponent, ActivitiesComponent, AssignmentsComponent, BanksComponent, BillrateComponent, RateTemplates, ClientPortalComponent, ExpensesComponent, InvoicesComponent, ListComponent, MailConfigurationComponent, IntegrationsComponent, TimeAndExpensesComponent, OtherComponent, ManageRolesComponent, ModuleDateExclusionComponent, MangoshareIntegrationComponent],
  imports: [
    DialogModule,
    ContextMenuModule ,ToolbarModule,
    CommonModule,
    AppSharedModule,
    SettingsRoutingModule,
    AppTranslationModule,
    ManageRolesModule
  ], exports: [
    MangoshareIntegrationComponent  
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SettingsModule { }
