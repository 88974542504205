<div class="cb-staff">
    <div class="layout-content p-px-3">
        <span class="width-14p pull-right p-mr-3 p-fluid staff-form-dropdown-top">

            <p-dropdown [options]="staffList" [(ngModel)]="selectedStaff" optionLabel="label" [filter]="true"
                filterBy="label" (onChange)="onSelectStaff($event)" placeholder="Select a Staff">
                <ng-template let-staff pTemplate="selectedItem">
                    {{selectedStaff.label}}
                </ng-template>
                <ng-template let-staff pTemplate="item">
                    {{staff.label}}
                </ng-template>
            </p-dropdown>
        </span>
        <div class="p-grid p-p-0">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-3">
                        <div class="box p-p-3">
                            <div class="p-col-12 p-d-flex p-jc-between">
                                <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                                <label class="ic-size-30 p-text-bold ic-blue">{{statistics.billedRevenue.currentMonth |
                                    roundoff | currency:'USD':'symbol':'1.0-0'}}</label>
                            </div>
                            <div class="p-col-12 p-d-flex p-jc-end">
                                <label class="p-text-bold">This Month</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-3">
                        <div class="box p-p-3">
                            <div class="p-col-12 p-d-flex p-jc-between">
                                <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                                <label class="ic-size-30 p-text-bold ic-blue">{{statistics.billedRevenue.lastYearMonth |
                                    roundoff | currency:'USD':'symbol':'1.0-0'}}</label>
                            </div>
                            <div class="p-col-12 p-d-flex p-jc-end">
                                <label class="p-text-bold">Last Year Month</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-3">
                        <div class="box p-p-3">
                            <div class="p-col-12 p-d-flex p-jc-between">
                                <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                                <label class="ic-size-30 p-text-bold ic-blue">{{statistics.billedRevenue.currentYear |
                                    roundoff | currency:'USD':'symbol':'1.0-0'}}</label>
                            </div>
                            <div class="p-col-12 p-d-flex p-jc-end">
                                <label class="p-text-bold">This Year-To-Date</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-3">
                        <div class="box p-p-3">
                            <div class="p-col-12 p-d-flex p-jc-between">
                                <i class="fas fa-chart-bar ic-light-grey ic-size-28 p-mt-2"></i>
                                <label class="ic-size-30 p-text-bold ic-blue">{{statistics.billedRevenue.lastYear |
                                    roundoff | currency:'USD':'symbol':'1.0-0'}}</label>
                            </div>
                            <div class="p-col-12 p-d-flex p-jc-end">
                                <label class="p-text-bold">Last Year-To-Date</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 manual p-py-0">
                        <p-panel header="Billed Revenue - 3 Year Comparison" [toggleable]="true">
                            <p-chart responsive="true" height="300px" type="bar" [data]="data" [options]=options>
                            </p-chart>
                        </p-panel>
                    </div>
                    <div class="p-col-3">
                        <p-panel header="Unbilled Revenue">
                            <div class="p-text-center ic-blue ic-size-30">
                                {{statistics.billedRevenue.unbilled | roundoff | currency:'USD':'symbol':'1.0-0'}}
                            </div>
                            <div class="ic-size-20">&nbsp;</div>
                        </p-panel>
                    </div>
                    <div class="p-col-3">
                        <p-panel header="Realization Rate">
                            <div class="p-text-center ic-blue ic-size-30">
                                {{statistics.billedRevenue.realizationRate * 100 | number:'1.0-0'}}%
                            </div>
                            <div class="p-text-right ic-blue ic-size-20">YTD</div>
                        </p-panel>
                    </div>
                    <div class="p-col-3">
                        <p-panel header="Gross Profit Margin">
                            <div class="p-text-center ic-blue ic-size-30">
                                {{(statistics.billedRevenue.grossProfitMargin * 100) | number:'1.0-0'}}%
                            </div>
                            <div class="p-text-right ic-blue ic-size-20">YTD</div>
                        </p-panel>
                    </div>
                    <div class="p-col-3">
                        <p-panel header="Net Write Up / (Down)">
                            <div class="p-text-center ic-blue ic-size-30"
                                *ngIf="statistics.billedRevenue.writeUpDown >= 0">
                                {{statistics.billedRevenue.writeUpDown | roundoff | currency:'USD':'symbol':'1.0-0'}}
                            </div>
                            <div class="p-text-center ic-blue ic-size-30"
                                *ngIf="statistics.billedRevenue.writeUpDown < 0">
                                {{statistics.billedRevenue.writeUpDown | roundoff | currency:'USD':'symbol':'1.0-0'}}
                            </div>
                            <div class="p-text-right ic-blue ic-size-20">YTD</div>
                        </p-panel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>