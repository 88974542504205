import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem, MenuItem } from 'primeng/api';
import moment from 'moment';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  ResizeService,
  RichTextEditorComponent,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import Swal from 'sweetalert2';
import { __makeTemplateObject } from 'tslib';
import { environment } from "@environments/environment";
declare let Trello: any;

@Component({
  selector: 'app-update-history-submit-requests',
  templateUrl: './update-history-submit-requests.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, ResizeService]
})
export class UpdateHistorySubmitRequestsComponent implements OnInit {
  filteredItemsSize = -1;
  searchTextStr: any = '';
  @ViewChild('searchValueDetails') searchValue;
  buttonsDeleteList: MenuItem[];
  public dateRangeList: SelectItem[];
  public typeOneDatasource: any = [];
  public typeTwoDatasource: any = [];
  public typeThreeDatasource: any = [];
  public requestsDatasource: any = [];
  public selectedRequests: any = [];
  public statusOptions: any = [];
  public priorityOptions: any = [];
  public requestForm: UntypedFormGroup;
  public typeList: SelectItem[];
  public requestList: SelectItem[];
  public DateRange: any = 'Year';
  public DateRangeReq: any = 'Month';
  public DateFrom: any = new Date('1901/01/01');
  public DateFromReq: any = new Date('1901/01/01');
  public DateTo: any = new Date('2099/12/30');
  public DateToReq: any = new Date('2099/12/30');
  public updateType: any = 1;
  public companyID: any;
  public staffID: any;
  public companyData: { FirmName: string; ContactNumber: string };
  public isShowHistoryDialog: boolean = false;
  public isShowRequestSidebar: boolean = false;
  public isShowUserRequestsSidebar: boolean = false;
  public isShowAssignDialog: boolean = false;
  public isEdit: boolean = false;
  public isFormValid: boolean = false;
  canUpdateHistory = false;
  public assignType: any = null;
  public assignObj: any = {
    Status: null,
    Priority: null
  };
  public historyObj: any = {
    Date: new Date(),
    Description: '',
    UpdateHistoryID: null
  };
  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'Formats',
      'Alignments',
      'Print'
    ]
  };

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private fb: UntypedFormBuilder,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);

    this.buttonsDeleteList = [
      {
        label: 'Delete Selected Items',
        icon: 'fal fa-trash-alt',
        command: () => {
          this.batchDeleteRequests();
        }
      },
      {
        label: 'Assign Priority',
        icon: 'fal fa-chart-bar',
        command: () => {
          this.batchAssign('priority');
        }
      },
      {
        label: 'Assign Status',
        icon: 'fal fa-info-circle',
        command: () => {
          this.batchAssign('status');
        }
      }
    ];

    this.statusOptions = [
      { label: 'Select Status', value: null },
      { label: 'New', value: 'new' },
      { label: 'Notify Received', value: 'received' },
      { label: 'In Development', value: 'dev' },
      { label: 'On-Hold', value: 'hold' },
      { label: 'Working as Intended', value: 'intended' },
      { label: 'Initial QA', value: 'testing' },
      { label: 'Completed', value: 'completed' },
      { label: 'No Action Required', value: 'noActionRequired' }
    ];

    this.priorityOptions = [
      { label: 'Select Priority', value: null },
      { label: 'Low', value: 'low' },
      { label: 'Medium', value: 'medium' },
      { label: 'High', value: 'high' }
    ];

    this.mangoAPISrvc.canUpdateHistory().subscribe((result: any) => {
      this.canUpdateHistory = result.data.canUpdateHistory;
    });

    this.mangoAPISrvc.getCompanyMangoDetails(this.companyID).subscribe(
      data => {
        const compData = data[0];
        this.companyData = {
          FirmName: compData.CompanyName,
          ContactNumber: compData.Telephone
        };
        console.log({ data });
      },
      err => {
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      }
    );
    this.initializeForm();
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.changeDateTypes('history');
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('admin.update_history_subtitle'), icon: 'ic-red' }
    ]);

    this.dateRangeList = [
      { label: this.translate.instant('All_Dates'), value: null },
      { label: this.translate.instant('reports.Todays_Date'), value: 'Today' },
      { label: this.translate.instant('This-Week'), value: 'Week' },
      { label: this.translate.instant('reports.This_Month'), value: 'Month' },
      { label: this.translate.instant('rpt.This_Quarter'), value: 'Quarter' },
      { label: this.translate.instant('rpt.This_Year'), value: 'Year' },
      { label: this.translate.instant('rpt.Last_Week'), value: 'lastweek' },
      { label: this.translate.instant('rpt.Last_Month'), value: 'lastmonth' },
      { label: this.translate.instant('rpt.Last_Quarter'), value: 'lastquarter' },
      { label: this.translate.instant('rpt.Last_Year'), value: 'lastyear' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];

    this.typeList = [
      { label: this.translate.instant('type.fix_update_enhancement'), value: 1 },
      { label: this.translate.instant('type.coming_soon'), value: 2 },
      { label: this.translate.instant('type.dev_roadmap'), value: 3 }
    ];

    this.requestList = [
      { label: this.translate.instant('feature_request'), value: 1 },
      { label: this.translate.instant('report_bug'), value: 2 }
    ];
  }

  getUpdateHistoryList() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getAllUpdateHistories({
        From: moment(this.DateFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        To: moment(this.DateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
      })
      .subscribe(
        (result: any) => {
          const datasource = result.map(item => {
            return {
              ...item,
              Ddate: this.mangoUtils.addOffset(new Date(item.Ddate))
            };
          });
          this.typeOneDatasource = datasource.filter(item => item.Type == 1);
          this.typeTwoDatasource = datasource.filter(item => item.Type == 2);
          this.typeThreeDatasource = datasource.filter(item => item.Type == 3);
          this.mangoAPISrvc.showLoader(false);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        }
      );
  }

  validateForm() {
    let isInValidData = false;
    let istouchedData = false;

    Object.keys(this.requestForm.controls).forEach(key => {
      if (this.requestForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.requestForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && istouchedData) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  initializeForm() {
    this.requestForm = this.fb.group({
      Ddate: [new Date()],
      FirmName: ['', [<any>Validators.required]],
      ContactNumber: ['', [<any>Validators.required, Validators.minLength(10)]],
      Type: [1, [<any>Validators.required]],
      Description: ['', [<any>Validators.required]],
      Email: ['']
    });

    this.requestForm.statusChanges.subscribe(x => {
      this.validateForm();
    });
  }

  resetUpdateHistoryForm() {
    this.historyObj.Date = new Date();
    this.historyObj.Description = '';
    this.historyObj.UpdateHistoryID = null;
    this.updateType = 1;
  }

  resetRequestForm() {
    this.requestForm.controls['FirmName'].setValue('');
    this.requestForm.controls['ContactNumber'].setValue('');
    this.requestForm.controls['Type'].setValue(1);
    this.requestForm.controls['Description'].setValue('');
    this.requestForm.controls['Email'].setValue('');
  }

  changeDateTypes(type) {
    const obj = type == 'history' ? this.DateRange : this.DateRangeReq;
    if (obj == 'Today') {
      if (type == 'history') {
        this.DateFrom = new Date();
        this.DateTo = new Date();
      } else {
        this.DateFromReq = new Date();
        this.DateToReq = new Date();
      }
    } else if (obj == 'Week') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().startOf('isoWeek').format());
        this.DateTo = new Date(moment().endOf('isoWeek').format());
      } else {
        this.DateFromReq = new Date(moment().startOf('isoWeek').format());
        this.DateToReq = new Date(moment().endOf('isoWeek').format());
      }
    } else if (obj == 'lastweek') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().startOf('isoWeek').subtract(1, 'week').format());
        this.DateTo = new Date(moment().endOf('isoWeek').subtract(1, 'week').format());
      } else {
        this.DateFromReq = new Date(moment().startOf('isoWeek').subtract(1, 'week').format());
        this.DateToReq = new Date(moment().endOf('isoWeek').subtract(1, 'week').format());
      }
    } else if (obj == 'Month') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().startOf('month').format());
        this.DateTo = new Date(moment().endOf('month').format());
      } else {
        this.DateFromReq = new Date(moment().startOf('month').format());
        this.DateToReq = new Date(moment().endOf('month').format());
      }
    } else if (obj == 'lastmonth') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().subtract(1, 'months').startOf('month').format());
        this.DateTo = new Date(moment().subtract(1, 'months').endOf('month').format());
      } else {
        this.DateFromReq = new Date(moment().subtract(1, 'months').startOf('month').format());
        this.DateToReq = new Date(moment().subtract(1, 'months').endOf('month').format());
      }
    } else if (obj == 'Quarter') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().startOf('quarter').format());
        this.DateTo = new Date(moment().endOf('quarter').format());
      } else {
        this.DateFromReq = new Date(moment().startOf('quarter').format());
        this.DateToReq = new Date(moment().endOf('quarter').format());
      }
    } else if (obj == 'lastquarter') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().subtract(1, 'quarters').startOf('quarter').format());
        this.DateTo = new Date(moment().subtract(1, 'quarters').endOf('quarter').format());
      } else {
        this.DateFromReq = new Date(moment().subtract(1, 'quarters').startOf('quarter').format());
        this.DateToReq = new Date(moment().subtract(1, 'quarters').endOf('quarter').format());
      }
    } else if (obj == 'Year') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().startOf('year').format());
        this.DateTo = new Date(moment().endOf('year').format());
      } else {
        this.DateFromReq = new Date(moment().startOf('year').format());
        this.DateToReq = new Date(moment().endOf('year').format());
      }
    } else if (obj == 'lastyear') {
      if (type == 'history') {
        this.DateFrom = new Date(moment().subtract(1, 'years').startOf('year').format());
        this.DateTo = new Date(moment().subtract(1, 'years').endOf('year').format());
      } else {
        this.DateFromReq = new Date(moment().subtract(1, 'years').startOf('year').format());
        this.DateToReq = new Date(moment().subtract(1, 'years').endOf('year').format());
      }
    } else if (obj == 'custom') {
      if (type == 'history') {
        this.DateFrom = new Date();
        this.DateTo = new Date();
      } else {
        this.DateFromReq = new Date();
        this.DateToReq = new Date();
      }
    } else {
      if (type == 'history') {
        this.DateFrom = new Date('1901-01-01');
        this.DateTo = new Date('2099-12-30');
      } else {
        this.DateFromReq = new Date('1901-01-01');
        this.DateToReq = new Date('2099-12-30');
      }
    }

    if (type == 'history') {
      this.getUpdateHistoryList();
    } else {
      this.getUserRequestList();
    }
  }

  onAddHistoryDialog() {
    this.resetUpdateHistoryForm();
    this.isEdit = false;
    this.isShowHistoryDialog = true;
  }

  onCloseHistoryDialog() {
    this.resetUpdateHistoryForm();
    this.isShowHistoryDialog = false;
  }

  editUpdateHistory(data) {
    this.isEdit = true;
    this.historyObj.UpdateHistoryID = data.UpdateHistoryID;
    this.historyObj.Date = data.Ddate;
    this.historyObj.Description = data.Description;
    this.updateType = data.Type;
    this.isShowHistoryDialog = true;
  }

  deleteUpdateHistory(data) {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: AppConstants.deleteMsg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteUpdateHistory(data.UpdateHistoryID).subscribe(
          (result: any) => {
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
            this.mangoAPISrvc.showLoader(false);
            if (data.Type == 1) {
              this.typeOneDatasource = this.typeOneDatasource.filter(
                item => item.UpdateHistoryID !== data.UpdateHistoryID
              );
            } else if (data.Type == 2) {
              this.typeTwoDatasource = this.typeTwoDatasource.filter(
                item => item.UpdateHistoryID !== data.UpdateHistoryID
              );
            } else {
              this.typeThreeDatasource = this.typeThreeDatasource.filter(
                item => item.UpdateHistoryID !== data.UpdateHistoryID
              );
            }
          },
          err => {
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteErrorMsg);
            this.mangoAPISrvc.showLoader(false);
          }
        );
      }
    });
  }

  saveHistoryToDB(arrObj) {
    if (arrObj.length > 0) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createBatchUpdateHistory({ list: arrObj }).subscribe(
        (result: any) => {
          this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
          this.mangoAPISrvc.showLoader(false);
          this.resetUpdateHistoryForm();
          this.getUpdateHistoryList();
        },
        err => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  replaceEntities(content: any) {
    return content.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
  }

  prepareMatch(matches): any {
    const arr = [];
    matches.forEach((match) => {
      const para = match[1]
      const obj = {
        Type: '',
        Description: '',
        Ddate: this.mangoUtils.removeOffset(this.historyObj.Date)
      };

      obj.Description = para ? this.replaceEntities(para) : '';
      obj.Type = this.updateType;

      arr.push(obj);
    });

    return arr;
  }

  onSaveHistory() {
    const obj = {
      Description: this.historyObj.Description
        ? this.replaceEntities(this.historyObj.Description)
        : '',
      Ddate: this.mangoUtils.removeOffset(this.historyObj.Date),
      Type: this.updateType,
      UpdateHistoryID: this.historyObj.UpdateHistoryID
    };

    if (this.isEdit) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.editUpdateHistory(obj, obj.UpdateHistoryID).subscribe(
        (result: any) => {
          this.mangoAPISrvc.showLoader(false);
          this.resetUpdateHistoryForm();
          this.onCloseHistoryDialog();
          this.getUpdateHistoryList();
          this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        },
        err => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      delete obj.UpdateHistoryID;
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createUpdateHistory(obj).subscribe(
        (result: any) => {
          this.mangoAPISrvc.showLoader(false);
          this.resetUpdateHistoryForm();
          this.onCloseHistoryDialog();
          this.getUpdateHistoryList();
          this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
        },
        err => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  onShowRequestSidebar() {
    this.resetRequestForm();
    this.requestForm.controls['FirmName'].setValue(this.companyData.FirmName);
    this.requestForm.controls['ContactNumber'].setValue(this.companyData.ContactNumber);
    this.requestForm.controls['Email'].setValue(this.encrDecSrvc.getObject(AppConstants.userEmail));
    this.isShowRequestSidebar = true;
  }

  onCloseRequestSidebar() {
    this.resetRequestForm();
    this.isShowRequestSidebar = false;
  }

  onSubmitRequest() {
    const requestFormObj = this.requestForm.value;
    const obj = {
      Ddate: this.mangoUtils.removeOffset(requestFormObj.Ddate),
      FirmName: requestFormObj.FirmName,
      ContactNumber: requestFormObj.ContactNumber,
      Type: requestFormObj.Type,
      Description: requestFormObj.Description,
      CompanyID: this.companyID,
      Status: 'new', // default
      StaffID: this.staffID
    };

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.createFeatureRequest(obj).subscribe(
      (resultFeat: any) => {
        const userEmailObj = {
          StaffID: this.staffID,
          CompanyID: this.companyID,
          Status: obj.Status,
          Description: requestFormObj.Description
        };

        this.mangoAPISrvc.sendUserEmailForRequest(userEmailObj).subscribe(
          result => {},
          err => {
            console.log(err);
          }
        );

        // create trello card - User Request(s)/Report(s) list
        const myList = "61797dd007011430269f34dc";
        const newCard = {
          name: `${moment(requestFormObj.Ddate).format("MM/DD/YYYY")} - ${
            requestFormObj.Type == 1 ? "Feature Request" : "Report a Bug"
          } - ${requestFormObj.FirmName}`,
          desc: `${requestFormObj.Description} \n\n\n\n\n\n${requestFormObj.Email}`,
          idList: myList,
          // Place this card at the top of our list
          pos: 'top'
        };

        this.mangoAPISrvc.addTrelloCard(newCard).subscribe(
          (data: any) => {
            const featRequest = resultFeat?.data;
            this.mangoAPISrvc
              .updateFeatureRequestBatch({
                list: [{ ...featRequest, TrelloID: data?.id }]
              })
              .subscribe(
                (result: any) => {
                  console.log('Card successfully created!');
                },
                err => this.mangoAPISrvc.showLoader(false)
              );
          },
          err => {
            this.mangoAPISrvc.showLoader(false);
          }
        );

        const sendInBlueObj = {
          sender: { name: "Mango Billing", email: requestFormObj.Email },
          to: [{ email: "", name: "" }],
          replyTo: { email: requestFormObj.Email },
          templateId: 53,
          params: {}
        };
        const toArr = AppConstants.featureEmailList;

        sendInBlueObj.to = toArr;
        sendInBlueObj.params['Type'] = obj.Type == 1 ? 'Feature Request' : 'Bug Report';
        sendInBlueObj.params['Ddate'] = moment(obj.Ddate).format('MM/DD/YYYY');
        sendInBlueObj.params['Description'] = obj.Description;
        sendInBlueObj.params['StaffName'] = this.encrDecSrvc.getObject(AppConstants.userName);
        sendInBlueObj.params['ContactNumber'] = requestFormObj.ContactNumber;
        sendInBlueObj.params['FirmName'] = requestFormObj.FirmName;
        this.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(
          data => {
            this.onCloseRequestSidebar();
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
            this.mangoAPISrvc.showLoader(false);
          },
          err => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
          }
        );
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
      }
    );
  }

  onShowUserRequestsSideBar() {
    this.changeDateTypes('request');
    this.getUserRequestList();
    this.isShowUserRequestsSidebar = true;
    const interval = setInterval(()=>{
      if(!this.searchValue) return;
      clearInterval(interval);
      this.searchValue.nativeElement.focus();
    });
  }

  getUserRequestList() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getAllFeatureRequests({
        From: moment(this.DateFromReq).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        To: moment(this.DateToReq).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
      })
      .subscribe(
        (result: any) => {
          this.requestsDatasource = result.map(item => {
            return {
              ...item,
              SortableDdate: moment(this.mangoUtils.addOffset(new Date(item.Ddate))).format(
                'MM/DD/YYYY'
              ),
              SortableStatus: item.Status ? this.getStatus(item.Status)?.label : null,
              Ddate: this.mangoUtils.addOffset(new Date(item.Ddate)),
              SortableType: item.Type == 1 ? 'Feature Request' : 'Report a Bug'
            };
          });
          this.mangoAPISrvc.showLoader(false);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        }
      );
  }

  getStatus(key) {
    return this.statusOptions.find(item => item.value == key);
  }

  getPriority(key) {
    return this.priorityOptions.find(item => item.value == key);
  }

  onCloseUserRequestsSidebar() {
    this.selectedRequests = [];
    this.isShowUserRequestsSidebar = false;
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      'userRequests' + AppConstants.SearchString,
      obj?.filters?.global?.value
    );
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject('userRequests' + AppConstants.SearchString, '');
  }

  decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  deleteRequest(rowData) {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: AppConstants.deleteMsg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result?.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteFeatureRequest(rowData.FeatureRequestID).subscribe(
          (result: any) => {
            this.selectedRequests = [];
            this.getUserRequestList();
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
          },
          err => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
          }
        );
      }
    });
  }

  batchDeleteRequests() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('question.delete_selected_items'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result?.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteFeatureRequestBatch({ list: this.selectedRequests }).subscribe(
          (result: any) => {
            this.selectedRequests = [];
            this.getUserRequestList();
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
          },
          err => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
          }
        );
      }
    });
  }

  clearAssignObj() {
    this.assignObj = {
      Priority: null,
      Status: null
    };
  }

  batchAssign(type) {
    this.assignType = type;
    this.isShowAssignDialog = true;
  }

  onCloseAssignDialog() {
    this.assignType = null;
    this.clearAssignObj();
    this.isShowAssignDialog = false;
  }

  onAssign() {
    const obj = {}
    if(this.assignType == 'priority')
      obj['Priority'] = this.assignObj.Priority
    else {
      obj['Status'] = this.assignObj.Status;
      obj['SortableStatus'] = this.getStatus(this.assignObj.Status)?.label;
    }

    const list = this.selectedRequests.map(item => ({
      ...item,
      ...obj,
      isStatus: this.assignType == 'status' ? true : false
    }));
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateFeatureRequestBatch({ list }).subscribe(
      (result: any) => {
        const selectedIDs = this.selectedRequests.map(item => item.FeatureRequestID);
        this.requestsDatasource = this.requestsDatasource.map(request => {
          if (selectedIDs.includes(request.FeatureRequestID)) {
            return { ...request, ...obj };
          } else return { ...request };
        });
        this.onCloseAssignDialog();
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      }
    );
  }

  shouldDisable() {
    return !(this.assignObj.Priority || this.assignObj.Status);
  }

  onChangeDropdown(event, data, type) {
    const obj = {}
    if(type == 'priority')
      obj['Priority'] = data.Priority
    else {
      obj['Status'] = data.Status;
      obj['isStatus'] = true;
      data['SortableStatus'] = obj['SortableStatus'] = this.getStatus(data.Status)?.label;
    }

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateFeatureRequestBatch({ list: [{ ...data, ...obj }] }).subscribe(
      (result: any) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      }
    );
  }
}
