import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService, MangoApiService } from "@app/_services";
import { TranslateService } from '@ngx-translate/core';

import FileSaver from "file-saver";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: 'app-cpapay-enrollments',
  templateUrl: './cpapay-enrollments.component.html'
})
export class CpapayEnrollmentsComponent implements OnInit {
  @ViewChild("searchValue") searchValue;
  cols: any = [];
  dataSource: any = [];
  filteredItemsSize = -1;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private breadcrumbService: BreadcrumbService,
    public translate: TranslateService
  ) {
    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('user.super-admin-dashboard')},
        { label: this.translate.instant('cpaPayEnrollments'), icon: 'ic-red' },
      ]);

      this.cols = [
        { field: "CompanyName", header: "Company Name" },
        { field: "LexStatusMessage", header: "Status" }
      ];
    })
  }

  ngOnInit(): void {
    this.getData();
  }

  onFilter(obj) {
    if (!this.searchValue?.nativeElement?.value) return;

    this.filteredItemsSize = obj.filteredValue.length;
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = "";
    this.filteredItemsSize = -1;
  }

  getData() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.fetchSuperAdminCPA().subscribe(
      (data: any) => {
        this.dataSource = data;
        this.filteredItemsSize = -1;
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  exportExcel() {
    const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "cpa");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportPdf() {
    const doc: any = new jsPDF("l", "pt");

    const columns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    const body = this.dataSource;

    doc.autoTable(columns, body);
    doc.save(`cpa_export_${new Date().getTime()}.pdf`);
  }
}
