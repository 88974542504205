<div class="client-list p-pt-2" *ngIf="isClientList">
    <span class="pull-right clientform-btn-top">
        <button type="button" pButton pRipple label="{{ sharedSrvc.isTableView? 'Dropdown View' : 'Table View' | translate }}"  [icon]="isClientListTableLoading && !sharedSrvc.isTableView? 'fa fa-spinner fa-spin': 'fal fa-list p-mr-2'"
            class="p-pr-2 p-mr-2" style="height: 30px" (click)="toggleClientListView()" [disabled]="isClientListTableLoading && !sharedSrvc.isTableView"></button>
        <demo-video videoCode="xprosp9e2n"></demo-video>
        <p-button pRipple label="{{ 'new' | translate }}" icon="fal fa-plus" styleClass="p-button-sm"
            class="p-button-help p-pr-2" (click)="redirectToClientView(-1,'')"></p-button>
    </span>
    <div *ngIf="!sharedSrvc.isTableView" class="p-text-center p-mt-6 p-pt-6" >
        <!-- <i class="topbar-icon fal fa-user-friends client-list-nodata" ></i> -->
        <img alt="logo" style="opacity: 0.3;" class="p-mt-6 p-pt-6" src="assets/img/mango-logo-transparent.png" />
    </div>
    <p-tabView (onChange)="handleChange($event)" class="p-mb-1" *ngIf="sharedSrvc.isTableView">
        <p-tabPanel header="{{ 'clients.tabs.active' | translate }} ({{clientsCount.active}})"
            leftIcon="ic-size-14 ic-green fb fal fa-users" tooltip="{{clientsCount.active}}">
            <div *ngTemplateOutlet="clientTable">
            </div>
            <div *ngIf="!sharedSrvc.isTableView" class="p-text-center" >
                <i class="topbar-icon fal fa-user-friends" ></i>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'clients.tabs.inactive' | translate }} ({{clientsCount.inactive}})"
            leftIcon="ic-size-14 ic-red fb fal fa-user-times" tooltip="{{clientsCount.inactive}}">
            <div *ngTemplateOutlet="clientTable">
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'client.contacts' | translate }} ({{clientsCount.contacts}})"
            leftIcon="ic-size-14 ic-blue fal fb fa-address-card" tooltip="{{clientsCount.contacts}}">
            <div *ngTemplateOutlet="clientTable">
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'all' | translate }} ({{clientsCount.all + clientsCount.contacts}})"
            leftIcon="ic-size-14 ic-yellow fb fas fa-star" tooltip="{{clientsCount.all + clientsCount.contacts}}"
            class="p-mt-2">
            <div *ngTemplateOutlet="clientTable">
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
<!-- <div class="p-col-12">
    <p-autoComplete [(ngModel)]="selClient" [ngModelOptions]="{standalone: true}"
        panelStyleClass="client-dropdown-autocomplete" autocomplete="off"
        [suggestions]="filteredClients" (completeMethod)="filterClients($event)"
        field="ClientName" ngDefaultControl (onSelect)="redirectToClientView(selClient.ClientID, selClient);"
        placeholder="{{'Search-Clients' | translate}}" (onFocus)="$event.target.select()"
        [dropdown]="true" required>
    <ng-template let-client pTemplate="item">
        <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : ''}}</div>
    </ng-template>
</p-autoComplete>
<div class="p-mr-5" style="display: flex;align-items: center;" *ngIf="isClientListDropdownLoading">
    <p-progressSpinner [style]="{width: '25px', height: '25px'}" styleClass="custom-spinner" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
    <label style="font: smaller;font-weight: 200; color: #87888a;">Loading clients...</label>
</div> -->
<!-- </div> -->
<ng-template #clientTable>   
    <p-table  *ngIf="sharedSrvc.isTableView" [columns]="selectedColumns" #dt [value]="totalClientsDataSource" dataKey="ClientID" [responsive]="true"
        [rows]="selectedRowsPerPage" [rowsPerPageOptions]="[10,25,50]" [paginator]="totalClientsDataSource.length > 10" rowExpandMode="single"
        [rowHover]="true" [sortOrder]="1" (onColReorder)="onColReorder($event)" [reorderableColumns]="true"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" [filterDelay]="0" [loading]="isClientListTableLoading"
        (onFilter)="onFilter($event)" [globalFilterFields]="globalFilterColumns" (onPage)="onTablePageChange($event)">
        <ng-template pTemplate="caption">
            <div class="p-grid">
                <div class="p-col-6">
                    <span class="p-input-icon-left">
                        <i class="fal fa-search"></i>
                        <input #searchValue pInputText type="text" id="searchValue-{{selectedTabIndex}}"
                            (input)="dt.filterGlobal($event.target.value, 'contains')" (click)="$event.target.select()"
                            placeholder="{{ 'globalsearch' | translate }}" />
                    </span>
                    <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                            class="p-button-rounded p-button-danger p-button-text"
                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                            pTooltip="{{ 'clear' | translate }}"></button>
                    </span>
                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                        text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                </div>
                <div class="p-col-6 p-text-right">
                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                        selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                        placeholder="Choose Columns"></p-multiSelect>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="width-2p"></th>
                <th class="width-5p p-text-center">{{ 'actions' | translate }}</th>
                <th class="width-14p p-text-left" pSortableColumn="ClientName">
                    {{ 'Clients' | translate }}
                    <p-sortIcon field="ClientName"></p-sortIcon>
                </th>
                <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="{{col.class}} p-text-left">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th class="width-4p p-text-center"></th>
                <th class="width-3p p-text-center">{{ 'email' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-expanded="expanded" let-columns="columns">
            <tr class="cursor-hand">
                <td class="width-2p">
                    <i *ngIf="rowData.contactcount > 0" pTooltip="{{ 'Show-Client-Contacts' | translate }}" pRipple
                        tooltipPosition="right" showDelay="800" class="far fa-chevron-right cursor-hand cursor-hand"
                        (click)="showClientContacts(rowData)">
                    </i>
                    <!-- <span [pRowToggler]="rowData" *ngIf="rowData.contactcount > 0">
                        <i pTooltip="{{ 'Show-Client-Contacts' | translate }}" pRipple tooltipPosition="right" showDelay="900"
                            [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                        </i>
                    </span> -->
                </td>
                <td class="width-5p">
                    <div class="p-text-center">
                        <i pRipple class="fal fa-pencil p-mr-2 cursor-hand  ic-green"
                            (click)="redirectToClientView(rowData.ClientID,rowData);" tooltipPosition="top"
                            pTooltip="{{ 'Edit' | translate }}"></i>
                        <i pRipple (click)="deleteClient(rowData,i);$event.stopPropagation();"
                            class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top"
                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                    </div>
                </td>
                <td (click)="redirectToClientView(rowData.ClientID,rowData);" class="width-14p p-text-left">
                    <div class="p-text-truncate" tooltipPosition="top" pTooltip="{{rowData.ClientName}}" showDelay="800"
                        tooltipStyleClass="tooltip-no-wrap">
                        {{rowData.ClientName}}
                    </div>
                </td>

                <td *ngFor="let col of columns" class="{{col.class}}" pTooltip="{{rowData[col.field]}}" showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap" (click)="redirectToClientView(rowData.ClientID,rowData);"
                    tooltipPosition="top">
                    <span *ngIf="col.field === 'TotalInvBal' || col.field === 'unbilledtime' || col.field === 'unbilledexpenses'">{{ rowData[col.field] | currency: "USD":true:"1.2-2" }}</span>
                    <span *ngIf="col.field !== 'TotalInvBal' && col.field !== 'unbilledtime' && col.field !== 'unbilledexpenses'">{{rowData[col.field]}}</span>   
                    
                </td>
                <td class="width-4p p-text-center">
                    <span> <i class="fas fa-user user-opc ic-green"
                            *ngIf="!rowData.Inactive && !rowData.ContactRecord"></i> </span>
                    <span><i class="fas fa-user-times user-opc ic-red"
                            *ngIf="rowData.Inactive && !rowData.ContactRecord"></i></span>
                    <span><i class="fas fal fa-address-card user-opc ic-blue" *ngIf="rowData.ContactRecord"></i></span>
                </td>
                <td class="width-3p p-text-center">
                    <i class="far fa-envelope ic-red" (click)="sendEmailToClient(rowData);" tooltipPosition="top"
                        pTooltip="{{ 'email_client' | translate }}"></i>
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="rowexpansion" let-rowData>
            <tr *ngIf="rowData.hasChildren">
                <td></td>
                <td [attr.colspan]="selectedColumns.length + 3">
                    <div class="p-p-2 bg-blue">
                        <p-table [value]="rowData.childItem" [showCurrentPageReport]="true" [pageLinks]="3"
                            dataKey="CustomerContactID" [rowsPerPageOptions]="[5,10,20]" [rowHover]="true" [rows]="5"
                            [paginator]="rowData.childItem.length > 5" [paginatorPosition]="'bottom'"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-20p p-text-left">
                                        {{ 'company' | translate }}
                                    </th>
                                    <th class="width-20p p-text-left">
                                        {{ 'client.contact_name' | translate }}
                                    </th>
                                    <th class="p-text-left">
                                        {{ 'user.office' | translate }}
                                    </th>
                                    <th class="p-text-left">
                                        {{ 'mobile' | translate }}
                                    </th>
                                    <th class="p-text-left">
                                        {{ 'Fax-Number' | translate }}
                                    </th>
                                    <th class="width-20p p-text-left">
                                        {{ 'email' | translate }}
                                    </th>
                                    <th class="p-text-center">
                                        &nbsp;
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-userData>
                                <tr>
                                    <td class="p-text-left" pTooltip="{{userData.Company}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{userData.Company}}
                                    </td>
                                    <td class="p-text-left" pTooltip="{{userData.ContactName}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{userData.ContactName}}
                                    </td>
                                    <td class="p-text-left">
                                        {{userData.Office}}
                                    </td>
                                    <td class="p-text-left">
                                        {{userData.Mobile}}
                                    </td>
                                    <td class="p-text-left">
                                        {{userData.Fax}}
                                    </td>
                                    <td class="p-text-left" pTooltip="{{userData.Email}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{userData.Email}}
                                    </td>
                                    <td class="p-text-center">
                                        <i class="far fa-envelope user-opc ic-red"
                                            (click)="sendEmailToClient(userData);$event.stopPropagation();" pTooltip="{{ 'email_invoice' | translate }}"></i>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
    </td>
    <td></td>
    </tr>
</ng-template> -->
        <ng-template pTemplate="footer" let-columns>
            <tr *ngIf="totalClientsDataSource.length > 0 && ClientListCols?.includes('TotalInvBal')">
                <td class="width-2p"></td>
                <td class="width-5p"></td>
                <td class="width-14p p-text-right p-text-bold ic-red">{{'Totals' | translate}}</td>
                <td *ngFor="let col of columns" class="{{col.class}}">
                    <span *ngIf="col.field === 'TotalInvBal'" class="p-text-bold ic-red">{{ totalInvoiceBal | currency: "USD":true:"1.2-2" }}</span>
                    <span *ngIf="col.field === 'unbilledtime'" class="p-text-bold ic-red">{{ totalUnbilledTime | currency: "USD":true:"1.2-2" }}</span>
                    <span *ngIf="col.field === 'unbilledexpenses'" class="p-text-bold ic-red">{{ totalUnbilledExpense | currency: "USD":true:"1.2-2" }}</span>
                </td>
                <td class="width-4p p-text-center"></td>
                <td class="width-3p p-text-center"></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">
                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                        styleClass="p-px-2"></p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>    
</ng-template>
<p-sidebar [(visible)]="showContacts" position="right" [style]="{'min-width':'90%','height':'95%','background':'#8e8f92','overflow': 'auto'}" (onHide)="cancelClientContacts()">
        <div class="card" style="min-height: 91vh;">
            <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
                <div class="p-col-12 p-pr-0 manual">
                    <p-panel header="{{ 'client.contacts' | translate }}">
                        <p-table [value]="clientContacts" dataKey="CustomerContactID" [rowHover]="true" 
                            [scrollHeight]="'calc(90vh - 200px)'" [scrollable]="true" [columns]="selectedColumns1"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                            <ng-template pTemplate="caption">
                                <div class="table-header" style="margin-top: -10px;">
                                        <p-multiSelect [options]="cols1" [(ngModel)]="selectedColumns1" optionLabel="header"
                                        selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                                        placeholder="Choose Columns" class="p-mr-2 p-d-flex p-jc-end"></p-multiSelect>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-15p p-text-left">
                                        {{ 'company' | translate }}
                                    </th>
                                    <th class="width-20p p-text-left">
                                        {{ 'client.contact_name' | translate }}
                                    </th>
                                    <th class="width-20p p-text-left">
                                        {{ 'user.office' | translate }}
                                    </th>
                                    <th class="width-18p p-text-left">
                                        {{ 'mobile' | translate }}
                                    </th>
                                    <th class="width-18p p-text-left">
                                        {{ 'Fax-Number' | translate }}
                                    </th>
                                    <th class="width-15p p-text-left">
                                        {{ 'email' | translate }}
                                    </th>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="{{col.class}}">
                                        {{col.header}}
                                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th class="width-7p p-text-center">
                                        &nbsp;
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-userData let-columns="columns">
                                <tr>
                                    <td class="width-15p p-text-left" pTooltip="{{userData.Company}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{userData.Company}}
                                    </td>
                                    <td class="width-20p p-text-left" pTooltip="{{userData.ContactName}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{userData.ContactName}}
                                    </td>
                                    <td class="width-20p p-text-left">
                                        {{userData.Office}}
                                    </td>
                                    <td class="width-18p p-text-left">
                                        {{userData.Mobile}}
                                    </td>
                                    <td class="width-18p p-text-left">
                                        {{userData.Fax}}
                                    </td>
                                    <td class="width-15p p-text-left" pTooltip="{{userData.Email}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{userData.Email}}
                                    </td>
                                    <td *ngFor="let col of columns" class="{{col.class}} p-text-left p-text-truncate"
                                        pTooltip="{{userData[col.field]}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{userData[col.field]}}
                                    </td>
                                    <td class="width-7p p-text-center">
                                        <i class="far fa-envelope user-opc ic-red"
                                            (click)="sendEmailToClient(userData);$event.stopPropagation();" pTooltip="{{ 'email_invoice' | translate }}"></i>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
               
            </div>
        </div>
</p-sidebar>
