<div
  class="
    layout-content

    p-pt-0
  "
>
  <div
    class="
      p-grid
      p-formgrid

      p-fluid

      p-mx-2
    "
  >
    <div
      class="
        p-col-5
      "
    >
      <div
        class="
          profile-calendar-mh

          card

          p-mt-2
        "
      >
        <div
          class="
            p-d-flex

            p-jc-between
          "
        >
          <div>
              <h5>
                Outlook Calendar Integration
              </h5>

              <div
                class="
                  p-my-3
                "
              >
                <p-checkbox
                  name="isOutlookCalendarIntegration"

                  label="Activate Outlook Calendar Integration"

                  binary="true"

                  [(ngModel)]="isOutlookCalendarIntegration"

                  [ngModelOptions]="{standalone: true}"
                >
                </p-checkbox>
              </div>
          </div>

          <div>
            <img
              class="
                cal-integrations
              "

              alt="Outlook Calendar Integration"

              src="assets/img/outlook.png"
            />
          </div>
        </div>

        <div
          *ngIf="
            isOutlookCalendarIntegration
          "

          class="
            p-fluid
          "
        >
          <div
            class="
              p-grid

              p-field
            "
          >
            <label
              class="
                label-text

                p-col-10
              "

              for="firstname4"
            >
              {{
                    !! hasOLAccessToken
                  ? 'Successfully activated!'
                  : ''
              }}
            </label>

            <div
              *ngIf="
                !! hasOLAccessToken
              "

              class="
                p-col-2

                p-px-0
                p-pt-2
              "
            >
              <p-button
                pRipple

                label="Remove"

                icon="fal fa-trash-alt"

                styleClass="p-button-sm p-button-danger"

                tooltipPosition="top"
                pTooltip="Remove"

                (click)="
                  removeOLToken( );
                "
              >
              </p-button>
            </div>

            <div
              *ngIf="
                ! hasOLAccessToken
              "

              class="
                p-col-2

                p-px-0
                p-pt-2
              "
            >
              <p-button
                pRipple

                label="Generate"
                icon="fal fa-external-link"

                tooltipPosition="top"
                pTooltip="Generate"

                styleClass="p-button-sm p-button-danger"

                (click)="
                  generateOutLookToken( );
                "
              >
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        p-col-5
      "
    >
      <div
        class="
          profile-calendar-mh

          card

          p-mt-2
        "
      >
        <div
          class="
            p-d-flex

            p-jc-between
          "
        >
          <div>
            <h5>
              Google Calendar Integration
            </h5>

            <div
              class="
                p-my-3
              "
            >
              <p-checkbox
                name="isGoogleCalendarIntegration"

                label="Activate Google Calendar Integration"

                binary="true"

                [(ngModel)]="isGoogleCalendarIntegration"

                [ngModelOptions]="{standalone: true}"

                (onChange)="
                  saveGapiSettings( );
                "
              >
              </p-checkbox>
            </div>
          </div>

          <div>
            <img
              class="
                cal-integrations
              "

              alt="Google Calendar Integration"

              src="assets/img/google-calendar-seeklogo.com.png"
            />
          </div>
        </div>

        <div
          *ngIf="
            !! isGoogleCalendarIntegration
          "

          class="
            p-fluid
          "
        >
          <div
            class="
              p-grid

              p-field
            "
          >
            <label
              class="
                label-text

                p-col-10
              "

              for="firstname4"
            >
              {{
                    !! hasAccessToken
                  ? 'Successfully activated!'
                  : ''
              }}
            </label>

            <div
              *ngIf="
                !! hasAccessToken
              "

              class="
                p-col-2

                p-px-0
                p-pt-2
              "
            >
              <p-button
                pRipple

                label="Remove"

                icon="fal fa-trash-alt"

                tooltipPosition="top"
                pTooltip="Remove"

                styleClass="p-button-sm p-button-danger"

                (click)="
                  removeToken( );
                "
              >
              </p-button>
            </div>

            <div
              *ngIf="
                ! hasAccessToken
              "

              class="
                p-col-2

                p-px-0
                p-pt-2
              "
            >
              <p-button
                pRipple

                label="Generate"

                icon="fal fa-external-link"

                tooltipPosition="top"
                pTooltip="Generate"

                styleClass="p-button-sm p-button-danger"

                (click)="
                  generateToken( );
                "
              >
              </p-button>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="
              nylasIntegrationFeatureStatus
          === true
        "

        class="
          my-profile-calendar-integration-card

          card

          p-mt-2
        "
      >
        <div
          class="
            p-d-flex

            p-flex-column
          "
        >
          <div
            class="
            "
          >
              <div
                class="
                "
              >
                <h5>
                  Nylas Calendar Integration
                </h5>

                <!--
                <div>
                  <img
                    class="
                      nylas-calendar-integration-icon
                    "

                    alt="Nylas Calendar Integration"

                    src="assets/img/outlook.png"
                  />
                </div>
                -->
              </div>

              <div
                class="
                  p-my-3
                "
              >
                <p-checkbox
                  name="nylasCalendarIntegrationSupportStatus"

                  label="Activate Nylas Calendar Integration"

                  binary="true"

                  [(ngModel)]="nylasCalendarIntegrationSupportStatus"

                  [ngModelOptions]="{standalone: true}"
                >
                </p-checkbox>
              </div>
          </div>

          <div
            *ngIf="
              (
                    nylasCalendarIntegrationSupportStatus
                === true
              )
            "

            class="
              p-grid
            "
          >
            <p-button
              class="
                p-col-4
              "

              label="Link Account"
              icon="fal fa-external-link"

              tooltipPosition="right"
              pTooltip="Link Account"

              (click)="
                true
              "
            >
            </p-button>
          </div>
        </div>

        <div
          *ngIf="
            (
                  nylasCalendarIntegrationSupportStatus
              === true
            )
          "

          class="
            p-d-flex

            p-flex-column

            p-mt-3
          "
        >
          <div
            class="
              p-mb-3
            "
          >
            <h6
              class="
              "
            >
              Nylas Microsoft Calendar Integration
            </h6>

            <div
              class="
                p-grid
              "
            >
              <p-button
                class="
                  p-col-4
                "

                label="Account Linked"
                icon="fal fa-check"

                tooltipPosition="right"
                pTooltip="Account Linked"

                styleClass="p-button-success"

                disabled="true"
              >
              </p-button>

              <p-button
                class="
                  p-col-3
                "

                label="Disable"

                tooltipPosition="right"
                pTooltip="Disable"

                styleClass="p-button-warning"

                (click)="
                  true
                "
              >
              </p-button>

              <!--
              <p-button
                class="
                  p-col-3
                "

                label="Enable"

                tooltipPosition="right"
                pTooltip="Enable"

                (click)="
                  true
                "
              >
              </p-button>
              -->

              <p-button
                class="
                  p-col-3
                "

                label="Unlink"

                tooltipPosition="right"
                pTooltip="Unlink"

                styleClass="p-button-danger"

                (click)="
                  true
                "
              >
              </p-button>
            </div>
          </div>

          <div
            class="
            "
          >
            <h6
              class="
              "
            >
              Nylas Google Calendar Integration
            </h6>

            <div
              class="
                p-grid
              "
            >
              <p-button
                class="
                  p-col-4
                "

                label="Account Linked"
                icon="fal fa-check"

                tooltipPosition="right"
                pTooltip="Account Linked"

                styleClass="p-button-success"

                disabled="true"
              >
              </p-button>

              <p-button
                class="
                  p-col-3
                "

                label="Disable"

                tooltipPosition="right"
                pTooltip="Disable"

                styleClass="p-button-warning"

                (click)="
                  true
                "
              >
              </p-button>

              <!--
              <p-button
                class="
                  p-col-3
                "

                label="Enable"

                tooltipPosition="right"
                pTooltip="Enable"

                (click)="
                  true
                "
              >
              </p-button>
              -->

              <p-button
                class="
                  p-col-3
                "

                label="Unlink"

                tooltipPosition="right"
                pTooltip="Unlink"

                styleClass="p-button-danger"

                (click)="
                  true
                "
              >
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
