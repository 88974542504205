import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, CookieService } from '@app/_services';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Router } from "@angular/router";
declare let numeral: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class AppRegisterComponent implements OnInit {
  dark: boolean;
  isFormValid: boolean = false;

  public nativeWindow: any;
  public agreeTerms: boolean = true;
  public display: boolean = false;
  public sendInBlueObj: any = {
    email: '',
    attributes: {},
    listid: ''
  };
  public signupForm: UntypedFormGroup;
  public emailExists: boolean = false;
  hsutk: string | null;
  token: string | undefined;
  dynamicFieldName: string;

  constructor(
    private cookieService: CookieService,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.hsutk = this.cookieService.getCookie('hubspotutk');
    this.nativeWindow = window;
    this.dynamicFieldName = Math.random().toString(36).substring(7);

    this.signupForm = this.fb.group(
      {
        companyName: [
          null,
          [Validators.required, Validators.minLength(1), Validators.maxLength(50)]
        ],

        firstName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],

        lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],

        email: [
          null,
          [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+(\\.[a-z]{2,4})?$')]
        ],

        telephone: [null, [Validators.required]],

        password: [null, [Validators.required]],

        confirmPassword: [null],

        howDidYouHear: [null, [Validators.required, Validators.maxLength(255)]],

        recaptcha: [null, Validators.required], // Captcha field

        honeypot: [''], // Honeypot field
      },

      {
        validators: [this.checkPassword, this.checkTelephone]
      }
    );

    this.signupForm.valueChanges.subscribe(values => {
      this.validateForm();
    });
  }

  validateForm() {
    this.isFormValid = this.signupForm.valid && (this.signupForm.dirty || this.emailExists);
  }

  checkPassword(group: UntypedFormGroup) {
    const pass = group.get("password").value;
    const confirmPass = group.get("confirmPassword").value;

    return pass === confirmPass ? null : { passwordNotEqual: true };
  }

  checkTelephone(group: UntypedFormGroup) {
    const telephone = group.get("telephone").value;
    const value = telephone ? numeral(telephone).value() : null;
    if (value && value.toString().length === 10) {
      return null;
    }
    return { telephoneInvalid: true };
  }

  changeText(event, control: any, type: any) {
    let enteredValue = control['value'];

    if (enteredValue === "" || enteredValue === null || control.invalid) {
      return null;
    }

    if (type === "lower") {
      const obj = {};
      obj["email"] = enteredValue.toLowerCase();

      this.mangoAPISrvc.showLoader(true);

      this.mangoAPISrvc.isUserExitsOrNot(obj['email']).subscribe(
        data => {
          if (data['Email'] && data['Email'].length > 0) {
            this.isFormValid = false;
            this.emailExists = true;
            this.mangoAPISrvc.notify(
              'error',
              'Error!',
              'This email address is no longer available!'
            );
          } else {
            this.emailExists = false;
            control.setValue(enteredValue.toLowerCase());
          }
          this.mangoAPISrvc.showLoader(false);
        },
        error => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      control.setValue(enteredValue.toUpperCase());
    }
  }

  resendEMail() {
    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc
      .sendTrialWelcomeEmail({
        email: this.signupForm.value.email,
        name: this.signupForm.value.firstName,
        password: this.signupForm.value.password
      })
      .subscribe(
        trialResponse => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("success", "Success!", "We have resent an email.");
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
        }
      );
  }

  redirection(url) {
    const newWindow = this.nativeWindow.open(decodeURI(url));
    newWindow.location = url;
  }

  async signUp(form) {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    if (form.value.honeypot) {
      console.warn('Bot detected!');
      return;
    }

    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.register(this.signupForm.value).subscribe(
      async (res: any) => {
        const data = res.data;
        this.encrDecSrvc.addObject(AppConstants.token, data.token);
        this.encrDecSrvc.addObject(AppConstants.userName, data.StaffName);
        this.encrDecSrvc.addObject(AppConstants.companyID, data.CompanyID);
        this.encrDecSrvc.addObject(AppConstants.resourceID, data.StaffID);
        this.encrDecSrvc.addObject(AppConstants.setupStep, data.SetupStep);

        // HubSpot register
        if (this.hsutk && this.token) { // Check if token is valid before sending to HubSpot
          const hubSpotObj = {
            submittedAt: moment().toISOString(),
            fields: [
              {
                name: "email",
                value: data.Email,
              },
              {
                name: "firstname",
                value: this.signupForm.value.firstName
              },
              {
                name: "lastname",
                value: this.signupForm.value.lastName
              },
              {
                name: "phone",
                value: this.signupForm.value.telephone
              },
              {
                name: "company",
                value: this.signupForm.value.companyName
              },
              {
                name: "full_entry_source_attribution",
                value: this.signupForm.value.howDidYouHear
              },
              {
                name: "trial_start_date",
                value: data.DateSubscribed
              }
            ],
            context: {
              hutk: this.hsutk,
              pageUri: "mangobilling.com/register",
              pageName: "Mango Billing Signup"
            }
          }
          const response = await this.mangoAPISrvc.registerUserToHubSpot(hubSpotObj);
        }

        const resourceObj = {};
        resourceObj["resourceId"] = data.StaffID;
        resourceObj["ResourceName"] = data.StaffName;
        resourceObj["Color"] = "#0000ff";
        resourceObj["CustomField1"] = null;

        const userObj = {};
        userObj["LNAME"] = this.signupForm.value.lastName;
        userObj["FNAME"] = this.signupForm.value.firstName;

        this.sendInBlueObj['email'] = data.Email;
        this.sendInBlueObj['attributes'] = userObj;
        this.sendInBlueObj['listid'] = ['26'];
        this.sendInBlueObj['listid_unlink'] = [];
        this.sendInBlueObj['updateEnabled'] = false;

        const activeCampaignObj = {};
        activeCampaignObj["email"] = data.Email;
        activeCampaignObj["first_name"] = this.signupForm.value.firstName;
        activeCampaignObj["last_name"] = this.signupForm.value.lastName;
        activeCampaignObj["orgname"] = this.signupForm.value.companyName;
        activeCampaignObj["listid"] = 7;

        this.mangoAPISrvc.createAppointmentResource(resourceObj).subscribe(
          () => {
            this.mangoAPISrvc.createOrUpdateUser(this.sendInBlueObj).subscribe(
              () => {
                this.mangoAPISrvc.showLoader(false);
              },
              (error) => {
                this.mangoAPISrvc.showLoader(false);
              }
            );
            this.display = true;

            this.mangoAPISrvc
              .sendTrialWelcomeEmail({
                email: data.Email,
                name: this.signupForm.value.firstName,
                password: this.signupForm.value.password
              })
              .subscribe(
                (trialResponse) => {
                  this.mangoAPISrvc.showLoader(false);
                },
                (err) => {
                  this.mangoAPISrvc.showLoader(false);
                }
              );
          },
          (error) => {
            this.mangoAPISrvc.showLoader(false);
          }
        );
      },
      (error) => {
        this.mangoAPISrvc.showLoader(false);

        let response = undefined;

        try {
          response = JSON.parse(error._body);
        } catch (parseError) {}

        const errorMessage = response.message;
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessage || error.toString(),
          showConfirmButton: false,
          timer: 3000
        });
      }
    );
  }

  redirectLogin() {
    this.router.navigate(['/login']);
  }
}
