import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  BreadcrumbService,
  EncrDecrService,
  AuthGuard,
  MangoApiService,
  mangoUtils
} from '@app/_services';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Subscription, forkJoin, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { SharedComponentsService } from '@app/shared/components';
import $ from "jquery";
import { Table } from 'primeng/table';
//import { AppConstants } from '';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  public libDataSource: any = [];
  public selectedLibRecords: any = [];
  public completionOptions: any;
  public dashboardTagsOptions: any;
  public selecteItems: any = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  public invoiceLibCols: any[];
  private tagsSub: Subscription = null;
  busy: Promise<any>;
  public mySettingsForm: UntypedFormGroup;
  public isFormValid: boolean = false;
  public isTouched: boolean = false;
  public companyCols: any = [];
  public companyDS: any = [];
  public AllManagerTypes: any = [];
  buttonsList: MenuItem[];
  companyId;
  staffId;
  companyData: any = {};
  ddmSettings: any = {};
  subscriptionLevel;
  public tagArray: any = [];
  projectAlertsToggle: any[];
  taskAlertsToggle: any[];
  filteredItemsSize = -1;
  searchTextStr: any = '';
  isManaging: any;
  ddmSettingsEdited: any = [];
  isCompanyIDEnabled: boolean = false;
  isCapacityPlanningAllowed: boolean = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private auth: AuthGuard,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private sharedService: SharedComponentsService,
    private mangoUtils: mangoUtils
  ) {
    this.sharedService.leaveAndSaveSub = new Subject<any>();
    this.sharedService.leaveAndSaveSub.subscribe(() => {
      this.saveSettingsData();
    });

    this.projectAlertsToggle = [
      { label: 'Disable', value: 'disable' },
      { label: 'Enable', value: 'enable' }
    ];
    this.taskAlertsToggle = [
      { label: 'Disable', value: 'disable' },
      { label: 'Enable', value: 'enable' }
    ];

    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
  }

  clearSearchFilter() {
    this.encrDecSrvc.removeObject('ProjectMaster' + AppConstants.SearchString);
    this.encrDecSrvc.addObject('ProjectMaster' + AppConstants.SearchString, '');
    this.searchValue.nativeElement.value = '';
    this.filteredItemsSize = -1;
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('Settings'), icon: 'ic-red' }
    ]);

    this.completionOptions = [
      { label: this.translate.instant('All_Staffs'), value: null },
      { label: this.translate.instant('Admin_Only'), value: 1 },
      { label: this.translate.instant('Admin_Manager'), value: 2 }
    ];

    this.dashboardTagsOptions = [
      { label: this.translate.instant('Show_for_Both'), value: 'both' },
      { label: this.translate.instant('Show_for_Managers'), value: 'manager' },
      { label: this.translate.instant('Show_for_Partners'), value: 'partner' }
    ];
  }

  ngOnDestroy() {
    this.sharedService.leaveAndSaveSub.unsubscribe();
  }

  ngOnInit() {
    // this.cache.fetchDdmSettings(this.companyId);
    // this.cache.fetchStaffList();
    // if(!this.auth.isAllowAccess(35)) {
    //   Swal.fire({
    //     icon: 'info',
    //     title: `${this.translate.instant('Information')}!`,
    //     showCancelButton: false,
    //     allowEscapeKey: true,
    //     allowEnterKey: true,
    //     confirmButtonText: 'OK',
    //     text: `Access Denied! This page can only be accessed by Admin/Manager.`,
    //   }).then((res) => {
    //     this.router.navigate([AppConstants.projectManagementRoutePath + '/' + AppConstants.dashBoardRoutePath])
    //   })
    // }

    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);

    this.getStaffList();    
    this.initializeForm();
    this.getDisplayCompanyByCompanyId(this.companyId);
    this.fetchDdmSettings();
    this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
    this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe((companyData: any) => {
      this.isCapacityPlanningAllowed = Boolean(companyData[0].CapacityPlanningEnabled);
    });

    this.buttonsList = [
      {
        label: 'Delete Selected Template(s)',
        icon: 'fa fa-trash-o',
        command: () => {
          this.deleteRow();
        }
      },
      {
        label: 'Duplicate to Clients',
        icon: 'fa fa-copy',
        command: () => {
          this.duplicateRecords();
        }
      }
    ];

    this.searchTextStr = this.encrDecSrvc.getObject(
      "ProjectMaster" + AppConstants.SearchString
    );
    
    //enables capacity planning based on the array of super admins
    //TODO move this to a web service.
    AppConstants.superAdminVisible.forEach(e => {
      if (e == this.companyId)
        this.isCompanyIDEnabled = true;
    });

    //enables capacity planning based on the array of super admins
    //TODO move this to a web service.
    AppConstants.superAdminVisible.forEach(e => {
      if (e == this.companyId) this.isCompanyIDEnabled = true;
    });
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || '';
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  getStaffList() {
    const item = this.encrDecSrvc.getObject(AppConstants.staffList);
    this.AllManagerTypes.unshift({
      label: 'Unassigned',
      StaffName: 'Unassigned',
      value: -1,
      StaffID: -1,
      Email: null
    });
    for (let i = 0; i < item.length; ++i) {
      if(item[i].Inactive)
        continue;

      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      obj['Email'] = item[i]['Email'];
      this.AllManagerTypes.push(obj);
    }
  }

  duplicateRecords() {
    const parent = this;
    if (parent.selecteItems.length == 1) {
      parent.duplicateProjects(parent.selecteItems[0]['CompanyTemplateHeaderID']);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Only 1 Company Template can be duplicated to clients.',
        showConfirmButton: false,
        timer: 2000
      });
    }
  }

  fetchDdmSettings() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.fetchDDMSettings(this.companyId).subscribe((data: any) => {
      if (data.length > 0) {
        this.encrDecSrvc.addObject(AppConstants.companyTags, data[0]);
        this.ddmSettings = data[0];
        this.updateSettingsForm(data[0]);
        this.fetchTagArray(data[0]);
      }
      else{
        this.fetchTagArray(data[0]);
      }

      this.mangoAPISrvc.showLoader(false);
    });
  }

  initializeForm() {
    this.mySettingsForm = this._fb.group({
      ddmSettingsID: [null],
      CompanyID: [this.companyId],
      DefaultUserID: [this.staffId],
      DefaultManagerID: [this.staffId],
      /*  Tag1: ['Available for Work'],
      Tag2: ['Extended'],
      Tag3: [''],
      Tag4: [''],
      Tag5: [''],
      Tag6: [''],
      Tag7: [''],
      Tag8: [''],
      Tag9: [''],
      Tag10: [''],
      Tag11: [''],
      Tag12: [''], */
      IsEmailProjectAssigned: [false],
      IsEmailTaskAssigned: [false],
      IsEmailTaskReady: [false],
      IsManagerReadyReview: [false],
      IsManagerProjectInProgress: [false],
      IsProjectRejected: [false],
      DashboardProjectTags: ['both'],
      CompletionPermission: [null],
      EnableProjectEmailAlerts: false,
      EnableTaskEmailAlerts: false,
      DaysBeforePastDueDateHeader: [0],
      DaysBeforePastDueDateHeaderBool: false,
      ProjectDaysToRemind: [0],
      TasksDaysToRemind: [0],
      PastDueHeader: [false],
      isNotifyManagerHeader: [false],
      isNotifyBillingPartnerHeader: [false],
      DaysBeforePastDueDateDetail: [0],
      DaysBeforePastDueDateDetailBool: false,
      PastDueDetail: [false],
      isNotifyManagerDetail: [false],
      isNotifyBillingPartnerDetail: [false],
      isNotifyStaffAssignedDetail: [false],
      isTrackingTimebyProjects: [false],
      isBudgetingProjectLevel: [false],
      isCapacityPlanningEnabled: [false],
      isTaskBudgetEnabled: [false],
      isShowProjectsView: [false],
    });

    this.mySettingsForm.valueChanges.subscribe(val => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.mySettingsForm.pristine);
    });
  }

  validateEnableFlag(){
    const formObj = this.mySettingsForm.value;
    const enableProjectEmailAlerts = formObj['DaysBeforePastDueDateHeader'] != null ||
      formObj['DaysBeforePastDueDateHeaderBool'] ||
      formObj['PastDueHeader'] ||
      formObj['isNotifyManagerHeader'] ||
      formObj['ProjectDaysToRemind'] ||
      formObj['isNotifyBillingPartnerHeader'];

    const enableTaskEmailAlerts = formObj['DaysBeforePastDueDateDetail'] != null ||
      formObj['DaysBeforePastDueDetailBool'] ||
      formObj['PastDueDetail'] ||
      formObj['isNotifyManagerDetail'] ||
      formObj['isNotifyBillingPartnerDetail'] ||
      formObj['TasksDaysToRemind'] ||
      formObj['isNotifyStaffAssignedDetail'];

    this.mySettingsForm.controls['EnableProjectEmailAlerts'].setValue(enableProjectEmailAlerts);
    this.mySettingsForm.controls['EnableTaskEmailAlerts'].setValue(enableTaskEmailAlerts);

    this.isTouched=true;
    this.validateForm()
  }

  enableProjectEmailAlert(event) {
    if (event.checked) {
      this.mySettingsForm.controls['DaysBeforePastDueDateHeader'].setValue(0, { emitEvent: false });
      this.mySettingsForm.controls['DaysBeforePastDueDateHeaderBool'].setValue(true, {
        emitEvent: false
      });
      this.mySettingsForm.controls['PastDueHeader'].setValue(true, { emitEvent: false });
      this.mySettingsForm.controls['ProjectDaysToRemind'].setValue(0, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyManagerHeader'].setValue(true, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyBillingPartnerHeader'].setValue(true, {
        emitEvent: false
      });
    } else {
      this.mySettingsForm.controls['DaysBeforePastDueDateHeader'].setValue(null, {
        emitEvent: false
      });
      this.mySettingsForm.controls['DaysBeforePastDueDateHeaderBool'].setValue(false, {
        emitEvent: false
      });
      this.mySettingsForm.controls['PastDueHeader'].setValue(false, { emitEvent: false });
      this.mySettingsForm.controls['ProjectDaysToRemind'].setValue(null, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyManagerHeader'].setValue(false, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyBillingPartnerHeader'].setValue(false, {
        emitEvent: false
      });
    }
    setTimeout(() => {
      this.mySettingsForm.controls['EnableProjectEmailAlerts'].setValue(event.checked, {
        emitEvent: false
      });
    }, 50);
    this.isTouched = true;
    this.validateForm();
  }

  enableTaskEmailAlert(event) {
    if (event.checked) {
      this.mySettingsForm.controls['DaysBeforePastDueDateDetail'].setValue(0, { emitEvent: false });
      this.mySettingsForm.controls['DaysBeforePastDueDateDetailBool'].setValue(true, {
        emitEvent: false
      });
      this.mySettingsForm.controls['PastDueDetail'].setValue(true, { emitEvent: false });
      this.mySettingsForm.controls['TasksDaysToRemind'].setValue(0, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyManagerDetail'].setValue(true, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyBillingPartnerDetail'].setValue(true, {
        emitEvent: false
      });
      this.mySettingsForm.controls['isNotifyStaffAssignedDetail'].setValue(true, {
        emitEvent: false
      });
    } else {
      this.mySettingsForm.controls['DaysBeforePastDueDateDetail'].setValue(null, {
        emitEvent: false
      });
      this.mySettingsForm.controls['DaysBeforePastDueDateDetailBool'].setValue(false, {
        emitEvent: false
      });
      this.mySettingsForm.controls['PastDueDetail'].setValue(false, { emitEvent: false });
      this.mySettingsForm.controls['TasksDaysToRemind'].setValue(null, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyManagerDetail'].setValue(false, { emitEvent: false });
      this.mySettingsForm.controls['isNotifyBillingPartnerDetail'].setValue(false, {
        emitEvent: false
      });
      this.mySettingsForm.controls['isNotifyStaffAssignedDetail'].setValue(false, {
        emitEvent: false
      });
    }
    setTimeout(() => {
      this.mySettingsForm.controls['EnableTaskEmailAlerts'].setValue(event.checked, {
        emitEvent: false
      });
    }, 50);
    this.isTouched = true;
    this.validateForm();
  }

  toggleCheckBoxWithInt(event, type) {
    this.mySettingsForm.controls[type].setValue(event.checked ? 0 : null, { emitEvent: false });
  }

  updateSettingsForm(itemData) {
    if (itemData && Object.keys(itemData).length > 0) {
      this.mySettingsForm.controls['ddmSettingsID'].setValue(itemData['ddmSettingsID']);
      /*  let value1 = itemData['Tag1'] ? itemData['Tag1'] : 'Available for Work';
      let value2 = itemData['Tag2'] ? itemData['Tag2'] : 'Extended';
      this.mySettingsForm.controls['Tag1'].setValue(value1);
      this.mySettingsForm.controls['Tag2'].setValue(value2);
      this.mySettingsForm.controls['Tag3'].setValue(itemData['Tag3']);
      this.mySettingsForm.controls['Tag4'].setValue(itemData['Tag4']);
      this.mySettingsForm.controls['Tag5'].setValue(itemData['Tag5']);
      this.mySettingsForm.controls['Tag6'].setValue(itemData['Tag6']);
      this.mySettingsForm.controls['Tag7'].setValue(itemData['Tag7']);
      this.mySettingsForm.controls['Tag8'].setValue(itemData['Tag8']);
      this.mySettingsForm.controls['Tag9'].setValue(itemData['Tag9']);
      this.mySettingsForm.controls['Tag10'].setValue(itemData['Tag10']);
      this.mySettingsForm.controls['Tag11'].setValue(itemData['Tag11']);
      this.mySettingsForm.controls['Tag12'].setValue(itemData['Tag12']); */
      this.mySettingsForm.controls['IsEmailProjectAssigned'].setValue(
        itemData['IsEmailProjectAssigned']
      );
      this.mySettingsForm.controls['IsEmailTaskAssigned'].setValue(itemData['IsEmailTaskAssigned']);
      this.mySettingsForm.controls['IsEmailTaskReady'].setValue(itemData['IsEmailTaskReady']);
      this.mySettingsForm.controls['IsManagerReadyReview'].setValue(
        itemData['IsManagerReadyReview']
      );
      this.mySettingsForm.controls['IsManagerProjectInProgress'].setValue(
        itemData['IsManagerProjectInProgress']
      );
      this.mySettingsForm.controls['IsProjectRejected'].setValue(itemData['IsProjectRejected']);
      this.mySettingsForm.controls['DefaultUserID'].setValue(itemData['DefaultUserID']);
      this.mySettingsForm.controls['DefaultManagerID'].setValue(itemData['DefaultManagerID']);
      this.mySettingsForm.controls['DashboardProjectTags'].setValue(itemData['DashboardProjectTags'] ? itemData['DashboardProjectTags'] : 'both');
      this.mySettingsForm.controls['CompletionPermission'].setValue(itemData['CompletionPermission'] ? itemData['CompletionPermission'] : null);
      this.mySettingsForm.controls['isTrackingTimebyProjects'].setValue(itemData['isTrackingTimebyProjects']);
      this.mySettingsForm.controls['isBudgetingProjectLevel'].setValue(itemData['isBudgetingProjectLevel']);
      this.mySettingsForm.controls['isCapacityPlanningEnabled'].setValue(itemData['isCapacityPlanningEnabled']);
      this.mySettingsForm.controls['isTaskBudgetEnabled'].setValue(itemData['isTaskBudgetEnabled']);
      this.mySettingsForm.controls['isShowProjectsView'].setValue(itemData['isShowProjectsView']);
      this.mySettingsForm.controls['DaysBeforePastDueDateHeader'].setValue(
        this.companyData['DaysBeforePastDueDateHeader']
      );
      this.mySettingsForm.controls['DaysBeforePastDueDateHeaderBool'].setValue(
        this.companyData['DaysBeforePastDueDateHeader'] != null
      );
      this.mySettingsForm.controls['PastDueHeader'].setValue(this.companyData['PastDueHeader']);
      this.mySettingsForm.controls['isNotifyManagerHeader'].setValue(
        this.companyData['isNotifyManagerHeader']
      );
      this.mySettingsForm.controls['isNotifyBillingPartnerHeader'].setValue(
        this.companyData['isNotifyBillingPartnerHeader']
      );

      this.mySettingsForm.controls['DaysBeforePastDueDateDetail'].setValue(
        this.companyData['DaysBeforePastDueDateDetail']
      );
      this.mySettingsForm.controls['DaysBeforePastDueDateDetailBool'].setValue(
        this.companyData['DaysBeforePastDueDateDetail'] != null
      );
      this.mySettingsForm.controls['PastDueDetail'].setValue(this.companyData['PastDueDetail']);
      this.mySettingsForm.controls['isNotifyManagerDetail'].setValue(this.companyData['isNotifyManagerDetail']);
      this.mySettingsForm.controls['isNotifyBillingPartnerDetail'].setValue(this.companyData['isNotifyBillingPartnerDetail']);
      this.mySettingsForm.controls['isNotifyStaffAssignedDetail'].setValue(this.companyData['isNotifyStaffAssignedDetail']);

      const enableProjectEmailAlerts = this.companyData['DaysBeforePastDueDateHeader'] ||
        this.companyData['PastDueHeader'] ||
        this.companyData['isNotifyManagerHeader'] ||
        this.companyData['isNotifyBillingPartnerHeader'];

      const enableTaskEmailAlerts = this.companyData['DaysBeforePastDueDateDetail'] ||
        this.companyData['PastDueDetail'] ||
        this.companyData['isNotifyManagerDetail'] ||
        this.companyData['isNotifyBillingPartnerDetail'] ||
        this.companyData['isNotifyStaffAssignedDetail'];

      this.mySettingsForm.controls['EnableProjectEmailAlerts'].setValue(enableProjectEmailAlerts);
      this.mySettingsForm.controls['EnableTaskEmailAlerts'].setValue(enableTaskEmailAlerts);

      this.mySettingsForm.controls['ProjectDaysToRemind'].setValue(this.companyData['ProjectDaysToRemind']);
      this.mySettingsForm.controls['TasksDaysToRemind'].setValue(this.companyData['TasksDaysToRemind']);

    }
  }



  fetchTagArray(itemData){
    if(itemData && itemData['TagArray2']){
      this.tagArray = itemData['TagArray2']?.map((itm, idx) =>{
        return { Index: idx+1, Tag: itm[0], Class: "width-90p p-mr-2 form-control", name: "Tag" + (idx+1), isShowTag: itm[0] != '', isEdit: false, color: itm[1] != '' ? itm[1] : '#ffffff', textColor: itm[1] != '' ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(itm[1])) : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb('#ffffff')) }
      })
    }
    else{
      const defaultTags =  ["Available for Work", "Extended", '','','','','','','','','','','','','','','','','',''];
      const defaultColor = ["#00cc99", "#9966ff"];
      this.tagArray = defaultTags.map((itm, idx) =>{
        let defColor = '#ffffff';
        if (idx == 0) {
          defColor = defaultColor[0];
        } else if (idx == 1) {
          defColor = defaultColor[1];
        }
        return {
          Index: idx + 1,
          Tag: itm,
          Class: 'width-90p p-mr-2 form-control',
          name: 'Tag' + (idx + 1),
          isShowTag: itm != '',
          isEdit: false,
          color: defColor,
          textColor: this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(defColor))
        };
      });
    }
  }

  bulkDelete() {
    const observableBatch = [];
    const parent = this;
    parent.selecteItems.forEach((selectedItem) => {
      observableBatch.push(this.mangoAPISrvc.deleteCompanyTemplateHeader(selectedItem.CompanyTemplateHeaderID));
    });
    return forkJoin(observableBatch);
  }

  duplicateProjects(id) {
    this.router.navigate([`project-management/` + id + `/createDuplicate`]);
  }

  deleteRow() {
    const self = this;
    event.stopPropagation();
    Swal.fire({
      title: 'Confirmation',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(result => {
      if (result.value) {
        self.bulkDelete().subscribe(data => {
          self.getDisplayCompanyByCompanyId(self.companyId);
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Successfully Deleted',
            showConfirmButton: false,
            timer: 1500
          });
          data.forEach((template: any) => {
            let logdata = {};
            logdata['Action'] = 'Delete Project Template';
            logdata['Description'] = 'CompanyTemplateHeaderID: ' + template.id;
            logdata['Table'] = '';

            if (!this.isManaging) {
              this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                res => {},
                err => {
                  console.log(err);
                }
              );
            }
          });
        });
      }
    });
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      'ProjectMaster' + AppConstants.SearchString,
      obj.filters.global?.value
    );
  }

  getDisplayCompanyByCompanyId(cId) {
    const _this = this;
    _this.companyDS = [];
    _this.selecteItems = [];
    _this.mangoAPISrvc.getDisplayCompanyByCompanyId(cId).subscribe(function (data: any) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const alreadyExits = _this.companyDS.filter((item) => item['CompanyTemplateHeaderID'] == element['CompanyTemplateHeaderID']);
        const allElements = data.filter((item) => item['CompanyTemplateHeaderID'] == element['CompanyTemplateHeaderID']);
        allElements.forEach(item => {
          item.StaffName = item.StaffName != null ? item.StaffName : 'Unassigned';
        });

        const AllSearch = [];
        for(let i = 0; i < allElements.length; i++){
          if(allElements[i].StaffName != "Unassigned") AllSearch.push(allElements[i].StaffName);
          AllSearch.push(allElements[i].TaskDescription);
          AllSearch.push(allElements[i].TemplateName);
        }

        const defaultParentRecord = { "hasChildrens": (parseInt(element['detailcount']) > 0 ? true : false), "CompanyTemplateHeaderID": element['CompanyTemplateHeaderID'], "TemplateName": element['TemplateName'], "detailcount": element['detailcount'], 'childItems': allElements, "SearchField": AllSearch.join() };
        if (alreadyExits.length == 0) {
          _this.companyDS.push(defaultParentRecord);
        }
        _this.companyDS.sort(function (a, b) {
          return b.hasChildrens - a.hasChildrens;
        });
      }

      _this.loadFilterGrid();
    });
  }

  saveSettingsData() {
    const parent = this;
    parent.mangoAPISrvc.isFormChanged = false;
    parent.isFormValid = false;
    parent.isTouched = false;

    if (parent.mySettingsForm.value.ddmSettingsID == null) {
      parent.mangoAPISrvc.showLoader(true);
      const saveObj = parent.mySettingsForm.value;
      const saveTagArray = this.tagArray.sort((a,b) => a.Index - b.Index);
      let columnsToSave = '';
      saveTagArray.map((cols, index) => {
        const nesArr = [];
        nesArr.push(cols.Tag != '' ? cols.Tag :  ``);
        nesArr.push(cols.color != '' ? cols.color :  ``);
        const tagStr = nesArr[0] ? `"${nesArr[0]}"` : null
        if (index > 0)
          columnsToSave += `, {${tagStr}, ${nesArr[1]}}`;
        else
          columnsToSave += `{${tagStr}, ${nesArr[1]}}`;
      });
      saveObj['TagArray2'] = `{${columnsToSave}}`;
      const newTags = saveTagArray.filter((item) => item['isEdit'] == true);
      parent.mangoAPISrvc.createDDMSettings(saveObj)
      .subscribe((data: any) => {
        newTags.forEach((tags) => {
          const logdata = {}
          logdata['Action'] = "Add PM Settings from #"+saveObj.ddmSettingsID;
          logdata['Description'] = tags.name+": "+tags.Tag+" - Color: "+tags.color;
          logdata['Table'] = "";

            if (!this.isManaging) {
              this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                res => {},
                err => {
                  console.log(err);
                }
              );
            }
          });

          parent.encrDecSrvc.addObject(AppConstants.companyTags, parent.mySettingsForm.value);
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          parent.mangoAPISrvc.isFormChanged = false;
          parent.mySettingsForm.controls['ddmSettingsID'].setValue(data.data['ddmSettingsID']);
          parent.fetchDdmSettings();
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', 'Success!', data.message);
        },
        () => {
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.mangoAPISrvc.showLoader(true);
      const saveObj = parent.mySettingsForm.value;

      const saveTagArray = this.tagArray.sort((a,b) => a.Index - b.Index);
      let columnsToSave = '';
      saveTagArray.map((cols, index) => {
        const nesArr = [];
        nesArr.push(cols.Tag != '' ? cols.Tag :  ``);
        nesArr.push(cols.color != '' ? cols.color :  ``);
        const tagStr = nesArr[0] ? `"${nesArr[0]}"` : null
        if (index > 0)
          columnsToSave += `, {${tagStr}, ${nesArr[1]}}`;
        else
          columnsToSave += `{${tagStr}, ${nesArr[1]}}`;
      });
      saveObj['TagArray2'] = `{${columnsToSave}}`
      const editedTags = saveTagArray.filter((item) => item['isEdit'] == true);
      parent.encrDecSrvc.addObject(AppConstants.companyTags, {...this.ddmSettings,...saveObj} );
      this.ddmSettings = {...this.ddmSettings,...saveObj};
      parent.mangoAPISrvc.updateDDMSettings(saveObj).subscribe((data: any) => {
        editedTags.forEach((tags) => {
          const logdata = {}
          logdata['Action'] = "Update PM Settings from #"+saveObj.ddmSettingsID;
          logdata['Description'] = tags.name+": "+tags.Tag+" - Color: "+tags.color;
          logdata['Table'] = "";

            if (!this.isManaging) {
              this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                res => {},
                err => {
                  console.log(err);
                }
              );
            }
          });

        this.ddmSettingsEdited.forEach((settings) => {
          const logdata = {}
          logdata['Action'] = "Update PM Settings from #"+saveObj.ddmSettingsID;
          logdata['Description'] = settings.name+": "+settings.value;
          logdata['Table'] = "";

            if (!this.isManaging) {
              this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                res => {},
                err => {
                  console.log(err);
                }
              );
            }
          });

        parent.encrDecSrvc.addObject(AppConstants.companyTags, parent.mySettingsForm.value);
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.mangoAPISrvc.isFormChanged = false;
        parent.fetchDdmSettings();
        parent.mangoAPISrvc.notify('success', 'Success!', data.message);
        parent.mangoAPISrvc.showLoader(false);
      }, () => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      });
    const formObj = this.mySettingsForm.value;
    const obj = {
      DaysBeforePastDueDateHeader: formObj['DaysBeforePastDueDateHeader'],
      ProjectDaysToRemind: formObj['ProjectDaysToRemind'],
      TasksDaysToRemind: formObj['TasksDaysToRemind'],
      PastDueHeader: formObj['PastDueHeader'],
      isNotifyManagerHeader: formObj['isNotifyManagerHeader'],
      isNotifyBillingPartnerHeader: formObj['isNotifyBillingPartnerHeader'],
      DaysBeforePastDueDateDetail: formObj['DaysBeforePastDueDateDetail'],
      PastDueDetail: formObj['PastDueDetail'],
      isNotifyManagerDetail: formObj['isNotifyManagerDetail'],
      isNotifyBillingPartnerDetail: formObj['isNotifyBillingPartnerDetail'],
      isNotifyStaffAssignedDetail: formObj['isNotifyStaffAssignedDetail'],
    }
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateTimeAndExpenses(obj)
      .subscribe(result=>{
        const companyDataTemp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
        companyDataTemp['DaysBeforePastDueDateHeader'] = obj['DaysBeforePastDueDateHeader'],
        companyDataTemp['ProjectDaysToRemind'] = obj['ProjectDaysToRemind'],
        companyDataTemp['TasksDaysToRemind'] = obj['TasksDaysToRemind'],
        companyDataTemp['PastDueHeader'] = obj['PastDueHeader'];
        companyDataTemp['isNotifyManagerHeader'] = obj['isNotifyManagerHeader'];
        companyDataTemp['isNotifyBillingPartnerHeader'] = obj['isNotifyBillingPartnerHeader'];
        companyDataTemp['DaysBeforePastDueDateDetail'] = obj['DaysBeforePastDueDateDetail'];
        companyDataTemp['PastDueDetail'] = obj['PastDueDetail'];
        companyDataTemp['isNotifyManagerDetail'] = obj['isNotifyManagerDetail'];
        companyDataTemp['isNotifyBillingPartnerDetail'] = obj['isNotifyBillingPartnerDetail'];
        companyDataTemp['isNotifyStaffAssignedDetail'] = obj['isNotifyStaffAssignedDetail'];
        this.encrDecSrvc.addObject(AppConstants.timeAndExpenses, companyDataTemp);
        if (formObj.ddmSettingsID == null) {
          parent.mangoAPISrvc.createDDMSettings(formObj).subscribe(
            (data: any) => {
              parent.encrDecSrvc.addObject(AppConstants.companyTags, formObj);
              parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
              parent.mangoAPISrvc.isFormChanged = false;
              parent.mySettingsForm.controls['ddmSettingsID'].setValue(data.data['ddmSettingsID']);
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('success', 'Success!', data.message);
            },
            () => {
              parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
              parent.mangoAPISrvc.showLoader(false);
            }
          );
        } else {
          parent.encrDecSrvc.addObject(AppConstants.companyTags, {
            ...this.ddmSettings,
            ...formObj
          });
          this.ddmSettings = { ...this.ddmSettings, ...formObj };
          parent.mangoAPISrvc.updateDDMSettings(formObj).subscribe(
            (data: any) => {
              parent.encrDecSrvc.addObject(AppConstants.companyTags, formObj);
              parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
              this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
              parent.mangoAPISrvc.isFormChanged = false;
              parent.mangoAPISrvc.notify('success', 'Success!', data.message);
              parent.mangoAPISrvc.showLoader(false);
            },
            () => {
              parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
              parent.mangoAPISrvc.showLoader(false);
            }
          );
        }
      });
    }
  }

  onTagChange(event, index) {
    for (let i = 0; i < this.tagArray.length; i++) {
      if (this.tagArray[i].Index == index) {
        this.tagArray[i].Tag = event;
        this.tagArray[i].isEdit = true;
        break;
      }
    }
    return;
  }



  onColorChange(event, index) {
    for (let i = 0; i < this.tagArray.length; i++) {
      if (this.tagArray[i].Index == index) {
        this.tagArray[i].color = event.value;
        this.tagArray[i].isEdit = true;
        this.tagArray[i].textColor = this.mangoUtils.setTextColor(
          this.mangoUtils.hexToRgb(event.value)
        );
        break;
      }
    }
    return;
  }

  onDeleteTag(index) {
    Swal.fire({
      title: this.translate.instant('Warning'),
      text: this.translate.instant('delete_tag_warning'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        for (let i = 0; i < this.tagArray.length; i++) {
          if (this.tagArray[i].Index == index) {
            this.tagArray[i].Tag = '';
            this.tagArray[i].isShowTag = false;
            this.tagArray[i].isEdit = true;
            break;
          }
        }
        this.validateForm();
        return;
      }
      else if(result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  }

  onAddNewTag() {
    for (let i = 0; i < this.tagArray.length; i++) {
      if (this.tagArray[i].Tag == '') {
        this.tagArray[i].isShowTag = true;
        this.tagArray[i].isEdit = true;
        break;
      }
    }

    return;
  }

  validateForm() {
    let isInValidData = false;
    Object.keys(this.mySettingsForm.controls).forEach(field => {
      if (this.mySettingsForm.get(field).invalid) {
        isInValidData = true;
      }
    });
    /* if (!isInValidData && this.mySettingsForm.dirty && this.isTouched) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    } */
    this.isFormValid = true;
    //this.mangoAPISrvc.isFormChanged = this.mySettingsForm.dirty;
  }

  onRadioSettingsClicked(event, type) {
    const form = this.mySettingsForm.value;
    const obj = {};
    obj['ddmSettingsID'] = form.ddmSettingsID
    if(type == 'dashboardTags') {
      obj['DashboardProjectTags'] = form.DashboardProjectTags
    } else if(type == 'trackTime') {
      obj['isTrackingTimebyProjects'] = form.isTrackingTimebyProjects
    } else if(type == 'projectBudget') {
      if (this.mySettingsForm.controls['isBudgetingProjectLevel'].value == false){
        obj['isTaskBudgetEnabled'] = form.isTaskBudgetEnabled = false;
      }
      obj['isBudgetingProjectLevel'] = form.isBudgetingProjectLevel;
    }else if (type == 'capacityPlanning'){
      obj['isCapacityPlanningEnabled'] = form.isCapacityPlanningEnabled
      this.mangoAPISrvc.showCapacityPlanningMenuChange(event.checked);
    }else if (type == 'taskBudget'){

      if (!form.isTaskBudgetEnabled){
        Swal.fire({
          icon: "warning",
          title: "Warning!",
          html: ` <div>
                    <p>By disabling the task budget, by default you're disabling capacity planning<p>
                  </div>`,
          showConfirmButton: true,
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: this.translate.instant("Yes"),
          cancelButtonText: this.translate.instant("no_cancel"),
        }).then((result) => {
          if (!result.isConfirmed) {
            //console.log('canceled, rollback value');

            const currentValue = this.mySettingsForm.controls['isTaskBudgetEnabled'].value;
            this.mySettingsForm.controls['isTaskBudgetEnabled'].setValue(!currentValue);
              
          }else {
            //console.log('not canceled, value changed');
              obj['isTaskBudgetEnabled'] = form.isTaskBudgetEnabled;

              const currentValue = this.mySettingsForm.get('isCapacityPlanningEnabled').value;
              this.mySettingsForm.get('isCapacityPlanningEnabled').setValue(!currentValue);
              this.mangoAPISrvc.showCapacityPlanningMenuChange(false);                      
          }
        });
      }else{
        obj['isTaskBudgetEnabled'] = form.isTaskBudgetEnabled;        
      }
    }  else {
      obj['CompletionPermission'] = form.CompletionPermission ? parseInt(form.CompletionPermission) : null
    }
    const companyTags = this.encrDecSrvc.getObject(AppConstants.companyTags);

    this.encrDecSrvc.addObject(AppConstants.companyTags, {...this.ddmSettings,...obj});
    this.ddmSettings = {...this.ddmSettings,...obj};

    if(form.ddmSettingsID){
      this.mangoAPISrvc.updateDDMSettings(obj).subscribe((data: any) => {
        this.mangoAPISrvc.isFormChanged = false;
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        this.mangoAPISrvc.notify('success', 'Success!', data.message);
        this.mangoAPISrvc.showLoader(false);
      }, (data) => {
        this.mangoAPISrvc.isFormChanged = false;
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      });
    }
    else{
      this.mangoAPISrvc.createDDMSettings(form).subscribe((data: any)=>{
        this.mangoAPISrvc.isFormChanged = false;
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        this.mangoAPISrvc.notify('success', 'Success!', data.message);
        this.mySettingsForm.controls['ddmSettingsID'].setValue(data.data['ddmSettingsID']);
        this.fetchDdmSettings();
        this.mangoAPISrvc.showLoader(false);
      }, ()=>{
        this.mangoAPISrvc.isFormChanged = false;
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      })
    }

  }

  onCheckboxChanged( event, name ){
    const settings: any = { };
    settings.name = name;
    settings.value = event.checked;
    const index = (this.ddmSettingsEdited.findIndex(((item) => (item.name === name))));
    if (index < 0) {
      this.ddmSettingsEdited.push( settings );
    }
    else{
      this.ddmSettingsEdited.splice( index, 1, settings );
    }
  }

  onInputNumberChange(event, name) {
    const settings:any = {}
    settings.name = name;
    settings.value = event.value ? event.value : event;
    const index = this.ddmSettingsEdited.findIndex((item) => item.name === name );
    if(index < 0)
      this.ddmSettingsEdited.push(settings)
    else
      this.ddmSettingsEdited.splice(index, 1, settings)
  }
}
