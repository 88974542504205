<div class="client-projects">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button (click)="showAddProjectDialog()" type="button" pButton pRipple pTooltip="Add"
                label="{{ 'Add Project' | translate }}" icon="fal fa-plus" class="p-button-sm p-mr-2">
            </button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt id="project-table" [value]="project" [responsive]="true" sortField="ClientName"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        dataKey="ProjectHeaderID" [filterDelay]="0"
                        [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" 
                        [globalFilterFields]="['ClientName', 'TemplateName', 'TasksCompleted', 'TotalTasks', 'DateReceived', 'FinishDate', 'compareDueDate', 'Status', 'managername', 'username', 'GroupDescriptionValues']"
                        (onFilter)="onFilter($event, 'header')" [sortOrder]="0"
                        [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'Search-by' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter('header')" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                                </p-message>
                                <span class="pull-right">
                                    <span class="pull-right p-mr-4">
                                        <p-dropdown [options]="projectFilterOptions" [(ngModel)]="defaultFilter" optionLabel="label"
                                            (onChange)="changeProjectFilter($event)" [ngModelOptions]="{standalone: true}">
                                            <ng-template let-item pTemplate="item">
                                                <div class="ui-helper-clearfix">
                                                    <div class="p-text-center drop_{{item.color | lowercase}}">{{item.label}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </span>
                                </span>
                            </div>

                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-9p p-text-left" pSortableColumn="ClientName">
                                    {{ 'client' | translate }}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pSortableColumn="TemplateName">
                                    {{ 'project' | translate }}
                                    <p-sortIcon field="TemplateName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left">
                                    {{ 'Tags' | translate }}
                                </th>
                                <th class="width-4p p-text-center">
                                    {{ 'Tasks' | translate }}
                                </th>
                                <th class="width-4p p-text-center">
                                    {{ 'memo' | translate }}
                                </th>
                                <th class="width-4p p-text-center">
                                    {{ 'notes' | translate }}
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="DateReceived">
                                    {{ 'In-Date' | translate }}
                                    <p-sortIcon field="DateReceived"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="FinishDate">
                                    {{ 'Out-Date' | translate }}
                                    <p-sortIcon field="FinishDate"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pTooltip="{{ 'Completion-Date' | translate }}"
                                    tooltipPosition="top" *ngIf="defaultFilter?.value === 'Completed' || defaultFilter?.value === 'All'">
                                    {{ 'Completion' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="SortableDueDate">
                                    {{ 'due-date' | translate }}
                                    <p-sortIcon field="SortableDueDate"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pSortableColumn="Status">
                                    {{ 'status' | translate }}
                                    <p-sortIcon field="Status"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pTooltip="{{ 'Manager-Assigned' | translate }}"
                                    tooltipPosition="top" pSortableColumn="username">
                                    {{ 'manager' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                    <p-sortIcon field="username"></p-sortIcon>
                                </th>
                                <th class="width-8p p-text-left" pTooltip="{{ 'Partner-Assigned' | translate }}"
                                    tooltipPosition="top" pSortableColumn="managername">
                                    {{ 'partner' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                    <p-sortIcon field="managername"></p-sortIcon>
                                </th>
                                <!--  <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field"
                                    class="p-text-left">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td class="width-9p p-text-left p-text-nowrap project-link cursor-hand"
                                    pTooltip="{{rowData.ClientName}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <span class="project-link cursor-hand"
                                        routerLink="/project-management/{{rowData['ClientID']}}/clientDetails">{{rowData.ClientName}}</span>
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate project-link cursor-hand"
                                    pTooltip="{{rowData.TemplateName}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <span class="project-link cursor-hand"
                                        (click)="redirectToProjectDetails(rowData)">{{rowData.TemplateName}}</span>
                                </td>
                                <td class="width-10p p-text-left">
                                    <span *ngFor="let tag of rowData['Tags']">
                                        <span class="p-text-nowrap pTag Tag{{tag}}"
                                            pTooltip="{{findTagByValue(tag,'label')}}" showDelay="800"
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">&nbsp;</span>
                                    </span>
                                </td>
                                <td class="width-4p p-text-center">
                                    <span
                                        *ngIf="rowData.TasksCompleted >= 0">{{rowData.TasksCompleted}}/</span>{{rowData.TotalTasks}}
                                </td>
                                <td class="width-4p p-text-center">
                                    <span *ngIf="rowData.ProjectMemo" showDelay="800" pTooltip="{{rowData.ProjectMemo}}"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        tooltipStyleClass="tooltip-no-wrap"><i
                                            class="fa fa-sticky-note ic-orange cursor-hand"></i></span>
                                </td>
                                <td class="width-4p p-text-center">
                                    <span *ngIf="rowData.TotalNotes > 0" class="notes">{{rowData.TotalNotes}}</span>
                                </td>
                                <td class="width-7p p-text-left" pTooltip="{{rowData.DateReceived | date:'MM/dd'}}"
                                    showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{rowData.DateReceived | date:'MM/dd'}}
                                </td>
                                <td class="width-7p p-text-left" pTooltip="{{rowData.FinishDate | date:'MM/dd'}}"
                                    showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{rowData.FinishDate | date:'MM/dd'}}
                                </td>
                                <td *ngIf="defaultFilter?.value === 'Completed' || defaultFilter?.value === 'All'" class="width-7p p-text-left cursor-hand"
                                    pTooltip="{{getToolTip(rowData)}}" tooltipPosition="top" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.CompletionDate | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-7p p-text-left" tooltipPosition="top" showDelay="800"
                                    pTooltip="{{getToolTip(rowData)}}"
                                    [ngClass]="{'dueDate_violet': rowData.ExtensionDate, 'dueDate_critical': !rowData.ExtensionDate && checkDueDate(rowData) }"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{ (rowData.ExtensionDate ? 'Ext. ' + (rowData.ExtensionDate | date:'MM/dd/yyyy') : (rowData.DueDate | date:'MM/dd/yyyy') ) ||
                                    '-'}}
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.Status}}" showDelay="800" tooltipPosition="top">
                                    <div class="flag project-summary-{{rowData.statusClass | lowercase}}">
                                        {{rowData.Status}}</div>
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.username}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.username}}
                                </td>
                                <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.managername}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.managername}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="12">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

    <p-dialog
        [(visible)]="isShowAddProjectDialog"
        *ngIf="isShowAddProjectDialog"
        [modal]="true"
        [style]="{ width: '70vw' }"
        [contentStyle]="{overflow: 'visible'}"
        [baseZIndex]="1"
        [maximizable]="false"
        showEffect="fade"
        [draggable]="true"
        [resizable]="false"
        (onHide)="closeAddProjectDialog()"
    >
        <p-header>
            {{ engagementName }} - Add Project
        </p-header>
        <p-table [value]="unassignedProjects" [responsive]="true" sortField="TemplateName"
            (onFilter)="onFilter($event, 'details')"  [(selection)]="selectedProjects"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            dataKey="ProjectHeaderID" [sortOrder]="1" sortMode="single"
            [globalFilterFields]="['TemplateName', 'Status', 'DueDate', 'ExtensionDate', 'DateReceived', 'ProjectMemo', 'managername', 'username']"
            [rowHover]="true" #dtDetails [scrollable]="true" [scrollHeight]="'calc(90vh - 350px)'">
            <ng-template pTemplate="caption">
                <div class="table-header">
                    <div>
                        <span class="p-input-icon-left">
                            <i class="fal fa-search"></i>
                            <input #searchValueDetails pInputText type="text"
                                (input)="dtDetails.filterGlobal($event.target.value, 'contains')"
                                placeholder="{{'globalsearch' | translate}}" />
                        </span>
                        <span>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="dtDetails.reset();clearSearchFilter('details')" tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                        </span>
                        <p-message *ngIf="filteredItemsDetailsSize > -1" severity="success"
                            text="{{filteredItemsDetailsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                        </p-message>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width-5p p-text-center">
                        <p-tableHeaderCheckbox [disabled]="searchTextStr && selectedProjects.length > 0"></p-tableHeaderCheckbox>
                    </th>
                    <th class="width-8p p-text-left" pSortableColumn="TemplateName">
                        {{ 'project' | translate }}
                        <p-sortIcon field="TemplateName"></p-sortIcon>
                    </th>
                    <th class="width-10p p-text-left">
                        {{ 'Tags' | translate }}
                    </th>
                    <th class="width-4p p-text-center">
                        {{ 'Tasks' | translate }}
                    </th>
                    <th class="width-4p p-text-center">
                        {{ 'memo' | translate }}
                    </th>
                    <th class="width-7p p-text-left" pSortableColumn="DateReceived">
                        {{ 'In-Date' | translate }}
                        <p-sortIcon field="DateReceived"></p-sortIcon>
                    </th>
                    <th class="width-7p p-text-left" pSortableColumn="FinishDate">
                        {{ 'Out-Date' | translate }}
                        <p-sortIcon field="FinishDate"></p-sortIcon>
                    </th>
                    <th class="width-7p p-text-left" pSortableColumn="SortableDueDate">
                        {{ 'due-date' | translate }}
                        <p-sortIcon field="SortableDueDate"></p-sortIcon>
                    </th>
                    <th class="width-8p p-text-left" pSortableColumn="Status">
                        {{ 'status' | translate }}
                        <p-sortIcon field="Status"></p-sortIcon>
                    </th>
                    <th class="width-8p p-text-left" pTooltip="{{ 'Manager-Assigned' | translate }}"
                        tooltipPosition="top" pSortableColumn="username">
                        {{ 'manager' | translate }}
                        <i class="fa fa-question-circle ic-blue"></i>
                        <p-sortIcon field="username"></p-sortIcon>
                    </th>
                    <th class="width-8p p-text-left" pTooltip="{{ 'Partner-Assigned' | translate }}"
                        tooltipPosition="top" pSortableColumn="managername">
                        {{ 'partner' | translate }}
                        <i class="fa fa-question-circle ic-blue"></i>
                        <p-sortIcon field="managername"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr class="cursor-hand">
                    <td class="width-5p p-text-center">
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                        pTooltip="{{rowData.TemplateName}}" showDelay="800" tooltipPosition="top"
                        tooltipStyleClass="tooltip-no-wrap">
                        {{rowData.TemplateName}}
                    </td>
                    <td class="width-10p p-text-left">
                        <span *ngFor="let tag of rowData['Tags']">
                            <span class="p-text-nowrap pTag Tag{{tag}}"
                                pTooltip="{{findTagByValue(tag,'label')}}" showDelay="800"
                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">&nbsp;</span>
                        </span>
                    </td>
                    <td class="width-4p p-text-center">
                        <span
                            *ngIf="rowData.TasksCompleted >= 0">{{rowData.TasksCompleted}}/</span>{{rowData.TotalTasks}}
                    </td>
                    <td class="width-4p p-text-center">
                        <span *ngIf="rowData.ProjectMemo" showDelay="800" pTooltip="{{rowData.ProjectMemo}}"
                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                            tooltipStyleClass="tooltip-no-wrap"><i
                                class="fa fa-sticky-note ic-orange cursor-hand"></i></span>
                    </td>
                    <td class="width-7p p-text-left" pTooltip="{{rowData.DateReceived | date:'MM/dd'}}"
                        showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                        {{rowData.DateReceived | date:'MM/dd'}}
                    </td>
                    <td class="width-7p p-text-left" pTooltip="{{rowData.FinishDate | date:'MM/dd'}}"
                        showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                        {{rowData.FinishDate | date:'MM/dd'}}
                    </td>
                    <td class="width-7p p-text-left" tooltipPosition="top" showDelay="800"
                        pTooltip="{{getToolTip(rowData)}}"
                        [ngClass]="{'dueDate_violet': rowData.ExtensionDate != null }"
                        tooltipStyleClass="tooltip-no-wrap">
                        {{ ((rowData.ExtensionDate != null? rowData.ExtensionDate : (rowData.DueDate !=
                        null? rowData.DueDate : null )) | date:'MM/dd/yyyy' ) || '-'}}
                    </td>
                    <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                        pTooltip="{{rowData.Status}}" showDelay="800" tooltipPosition="top">
                        <div class="flag project-summary-{{rowData.statusClass | lowercase}}">
                            {{rowData.Status}}</div>
                    </td>
                    <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                        pTooltip="{{rowData.username}}" showDelay="800" tooltipPosition="top"
                        tooltipStyleClass="tooltip-no-wrap">
                        {{rowData.username}}
                    </td>
                    <td class="width-8p p-text-left p-text-nowrap p-text-truncate"
                        pTooltip="{{rowData.managername}}" showDelay="800" tooltipPosition="top"
                        tooltipStyleClass="tooltip-no-wrap">
                        {{rowData.managername}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="11">
                        <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                        </p-message>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
            <div class="p-d-flex p-jc-end">
              <button
                type="button"
                pButton
                pRipple
                label="{{ 'Save' | translate }}"
                (click)="onAddProject(selectedProjects)"
                [disabled]="selectedProjects.length == 0"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                label="{{ 'cancel' | translate }}"
                (click)="closeAddProjectDialog()"
              ></button>
            </div>
          </ng-template>
    </p-dialog>
</div>