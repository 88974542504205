import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule, AppTranslationModule } from '@app/shared/modules';
import { CreateProjectFromTemplateComponent } from './create-project-from-template.component';



@NgModule({
  declarations: [CreateProjectFromTemplateComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule
  ],
  exports: [
    CreateProjectFromTemplateComponent
  ]
})
export class CreateProjectFromTemplateModule { }
