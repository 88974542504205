
export class AWSBucketMask {
  public static async upload( option, callback, service ){
    const fileReader = new FileReader( );

    fileReader.onloadend = (
      ( ) => {
        const dataURI = (
          `${ fileReader.result }`
        );

        const [ entityType, mimeType, baseType, data ] = (
          dataURI.split( /[\:\;\,]/ )
        );

        option.ContentEncoding = baseType;
        option.ContentType = mimeType;
        option.Body = data;

        service.awsBucketMaskUpload( option )
        .subscribe(
          function( responseData ){
            callback( null, responseData.data );
          },

          function( error ){
            callback( error );
          }
        );
      }
    );

    fileReader.readAsDataURL( option.Body );
  }

  public static async deleteObject( option, callback, service ){
    service.awsBucketMaskDeleteObject( option )
    .subscribe(
      function( responseData ){
        callback( null, responseData );
      },

      function( error ){
        callback( error );
      }
    );
  }

  public static async deleteObjects( option, callback, service ){
    service.awsBucketMaskDeleteObjects( option )
    .subscribe(
      function( responseData ){
        callback( null, responseData );
      },

      function( error ){
        callback( error );
      }
    );
  }

  public static async getSignedUrl( namespace, option, callback, service ){
    service.awsBucketMaskGetSignedUrl( namespace, option )
    .subscribe(
      function( responseData ){
        callback(null, responseData.data);
      },

      function( error ){
        callback( error );
      }
    );
  }
}
