import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
@Component({
  selector: "demo-video",
  templateUrl: "./demo-video.html",
})
export class DemoVideo implements OnInit {
  @Input() videoCode: string = "";
  constructor() {}
  ngOnInit(): void {
  }
}
