import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { ELSharedService } from '@app/modules/engagement-letters/el-shared-service';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import moment from 'moment';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { timer } from'rxjs';
 
declare var numeral: any;
@Component({
  selector: 'app-usage-metering',
  templateUrl: './usage-metering.component.html'
})
export class UsageMeteringComponent implements OnInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dt: Table;
  billingDataSource: any[] = [];
  public filteredCompanyList: any = [];
  private timer: any;
  private timeoutSrch1: any = null;
  public companyList: any = [];
  public dueDateList: any = [];
  public rangeType: any;
  onFilterDisplay: boolean = false;
  dueDateID: any;
  DateFrom: Date;
  DateTo: Date;
  companySel: any;
  filteredItemsSize: number = -1;
  selectedColumns: any[];
  selectedColumnsMain: any[] = [];
  globalFilterColumns: string[];
  globalFilterColumnsMain: string[];
  sortField: any;
  sidePanelDisplay: boolean;
  selectedRow;
  selectedClientRowData: any[] = [];
  selectedRowsPerPage: any[] = [];
  constructor(
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private mangoUtils: mangoUtils,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private currencyPipe: CurrencyPipe,
    private elSharedSvc: ELSharedService
  ) {
    this.breadcrumbService.setItems([
      { label: 'Super Admin Dashboard' },
      { label: 'Engagement Letters  Billing', icon: 'ic-red' }
    ]);
    this.timer = timer (0.5 * 1000);
    this.dueDateList = [
      { label: this.translate.instant('all'), value: null },
      { label: this.translate.instant('This-Week'), value: 'this-week' },
      { label: this.translate.instant('This-Month'), value: 'this-month' },
      { label: this.translate.instant('This-Quarter'), value: 'this-quarter' },
      { label: this.translate.instant('This-Year'), value: 'this-year' },
      { label: this.translate.instant('Last-Week'), value: 'last-week' },
      { label: this.translate.instant('Last-Month'), value: 'last-month' },
      { label: this.translate.instant('Last-Quarter'), value: 'last-quarter' },
      { label: this.translate.instant('Last-Year'), value: 'last-year' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];
    this.selectedColumns = [
      {
        field: 'ClientName',
        header: 'Client Name',
        rowClass: 'width-15p p-text-left',
        canSort: true
      },
      {
        field: 'BillingDate',
        header: 'Billing Date',
        rowClass: 'width-15p p-text-left'
      },
      {
        field: 'AnniversaryDate',
        header: 'Anniversary Date',
        rowClass: 'width-15p p-text-left'
      },
      {
        field: 'BilledAmount',
        header: 'Billed Amount',
        rowClass: 'width-8p p-text-left',
        canSort: true,
        isMoney: true
      },
      {
        field: 'noOfELs',
        header: 'Total # of Engagement Letters',
        rowClass: 'width-15p p-text-left'
      },
      {
        field: 'noOfELs',
        header: 'Total # of Engagement Letters',
        rowClass: 'width-15p p-text-left'
      }
    ];

    this.selectedColumnsMain = [
      {
        field: 'CompanyID',
        header: 'System ID',
        rowClass: 'width-8p p-text-left',
        canSort: true
      },
      {
        field: 'CompanyName',
        header: 'Company Name',
        rowClass: 'width-15p p-text-left',
        canSort: true
      },
      // {
      //   field: "address",
      //   header: "Address",
      //   rowClass: 'width-15p p-text-left', canSort: true
      // },
      {
        field: 'CompanyContact',
        header: 'Contact Person',
        rowClass: 'width-10p p-text-left',
        canSort: true
      },
      {
        field: 'EmailCompany',
        header: 'Email',
        rowClass: 'width-10p p-text-left',
        canSort: true
      },
      {
        field: 'Telephone',
        header: 'Telephone',
        rowClass: 'width-10p p-text-left',
        canSort: true
      },
      {
        field: 'TierRateInfo',
        header: 'Tier',
        rowClass: 'width-10p p-text-left',
        canSort: true
      },
      {
        field: 'RemainingCredits',
        header: 'Remaining Free Credits',
        rowClass: 'width-12p p-text-right',
        canSort: true
      },
      {
        field: 'ClientsBilledCount',
        header: 'Current # of Clients Billed',
        rowClass: 'width-15p p-text-right',
        canSort: true
      },
      {
        field: 'TotalClients',
        header: 'Total # of Clients',
        rowClass: 'width-15p p-text-right',
        canSort: true
      },
      {
        field: 'billedAmount',
        header: 'Total Billing Amount',
        rowClass: 'width-15p p-text-right',
        canSort: true
      }
    ];

    this.globalFilterColumns = [...this.selectedColumns.map(col => col.field)];

    this.globalFilterColumnsMain = [...this.selectedColumnsMain.map(col => col.field)];
  }

  ngOnInit(): void {
    this.dueDateID = 'this-month';

    const company = this.encrDecSrvc.getObject('UsageMetering_CompanySelect');
    if (company) {
      this.companySel = company;
    }

    const filters = this.encrDecSrvc.getObject('UsageMetering_Filters');
    this.dueDateID = filters?.dueDate ?? 'this-month';
    this.DateFrom =
      moment(filters?.dateFrom).startOf('month').toDate() ?? moment().startOf('month').toDate();
    this.DateTo =
      moment(filters?.dateTo).startOf('month').toDate() ?? moment().endOf('month').toDate();

    this.fetchBillingSource();
  }

  filterCompanyList(event) {
    let self = this;

    if (self.timeoutSrch1) {
      self.timeoutSrch1.unsubscribe();
    }

    self.timeoutSrch1 = self.timer.subscribe(t => {
      self.fetchCompanyList(event.query, () => {
        self.filteredCompanyList = self.companyList.sort(
          self.mangoUtils.compareValues('CompanyName', 'asc')
        );
        self.timeoutSrch1.unsubscribe();
      });
    });
  }

  private fetchCompanyList(searchString: string, callback: any) {
    let self = this;

    this.mangoAPISrvc.getCompanyNames(`search=${searchString}`).subscribe(
      (result: any) => {
        self.companyList = result.filter(item => item.CompanyID !== 1);

        callback();
      },

      err => {
        self.mangoAPISrvc.notify(
          'error',
          'Error!',
          'Error while fetching data from server. Please review the inputs.'
        );

        self.timeoutSrch1.unsubscribe();
      }
    );
  }

  onChangeFilters(e, type: string) {}

  fetchBillingSource() {
    this.encrDecSrvc.addObject('UsageMetering_Filters', {
      dueDate: this.dueDateID,
      dateFrom: moment(this.DateFrom).format('yyyy-MM-DD'),
      dateTo: moment(this.DateTo).format('yyyy-MM-DD')
    });

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .fetchClientBilling(
        this.dueDateID ?? 'all',
        this.DateFrom ? moment(this.DateFrom).format('yyyy-MM-DD') : '',
        this.DateTo ? moment(this.DateTo).format('yyyy-MM-DD') : ''
      )
      .subscribe(
        (result: any) => {
          this.mangoAPISrvc.showLoader(false);
          this.billingDataSource = result?.data.map(col => {
            let freeClients = this.elSharedSvc.getNoOfFreeClientsPerTier(col.Tier) ?? 0;
            col.TierRateInfo = `${col.Tier} - $${numeral(col.TierRate).format('0.00')}/Client`;
            col.TotalClients = +col.TotalClients;

            col.RemainingCredits =
              freeClients <= col.TotalClients ? 0 : freeClients - col.TotalClients;

            col.ClientsBilledCount = col.ClientsBilledCount ? +col.ClientsBilledCount : 0;
            // 0-5
            let clientCount =
              col.RemainingCredits == 0
                ? col.ClientsBilledCount
                : col.ClientsBilledCount - col.RemainingCredits;
            col.billedAmount = this.getTotal(clientCount < 0 ? 0 : clientCount, +col.TierRate);
            return col;
          });
        },
        error => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        }
      );
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      'superAdmin_usageMetering' + '_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  onCloseFilter() {
    this.onFilterDisplay = false;
  }

  onTablePageChange(e) {}

  onResetFilters() {
    this.dueDateID = 'this-month';
    this.DateFrom = moment().startOf('month').toDate();
    this.DateTo = moment().endOf('month').toDate();
    this.fetchBillingSource();
  }

  handleCompanySelect(e) {
    this.encrDecSrvc.addObject('UsageMetering_CompanySelect', e);

    this.fetchBillingSource();
  }

  clearSearchFilter() {
    this.filteredItemsSize = -1;
  }

  async exportCSVFile() {
    this.mangoAPISrvc.showLoader(true);

    setTimeout(() => {
      this.dt.exportCSV();
      this.mangoAPISrvc.showLoader(false);
    }, 1000);
  }

  async exportPdf(reportType: string) {
    const doc: any = new jsPDF('l', 'pt');
    const columns = [
      ...this.selectedColumnsMain.map(col => ({
        title: col.header,
        dataKey: col.field
      }))
    ];

    const data = this.billingDataSource.map(x => {
      x.billedAmount = this.currencyPipe.transform(x.billedAmount, 'USD').replace('USD', '');
      return x;
    });
    doc.autoTable(columns, data);
    doc.save(`Billing_export_${new Date().getTime()}.pdf`);
  }

  onSort(event) {
    this.sortField = event.multisortmeta;
  }

  getTotal(clientCount, tierRate) {
    return clientCount * tierRate;
  }

  openFilter() {
    this.onFilterDisplay = !this.onFilterDisplay;
  }

  openRowData(data) {
    this.sidePanelDisplay = true;
    this.selectedClientRowData = data.ClientList;
    this.selectedRow = data;
  }

  cancelShowRow() {
    this.sidePanelDisplay = false;
  }

  getTotalMain() {
    return this.billingDataSource.reduce((accumulator, item) => {
      return (accumulator += item.billedAmount);
    }, 0);
  }

  getTotalPerClient(rowData) {
    return rowData.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.BilledAmount).value();
    }, 0);
  }
}
