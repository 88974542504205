import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailAutomationComponent } from './email-automation.component';
import { EmailAutomationSetupComponent } from './email-automation-setup/email-automation-setup.component';
import { EmailAutomationEmailComponent } from './email-automation-email/email-automation-email.component';
import { AuthGuard } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: EmailAutomationComponent,
    children: [
      { path: '', component: EmailAutomationSetupComponent },
      { path: AppConstants.setup, component: EmailAutomationSetupComponent },
      { path: AppConstants.emailLists, component: EmailAutomationEmailComponent },
    ],
    canActivate: [AuthGuard],
    data: { 'plans': ["free", "enterprise", "pm"] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailAutomationRoutingModule { }
