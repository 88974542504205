import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyDashboardComponent } from './company-dashboard.component';
import { RevenueComponent } from './revenue/revenue.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { StaffComponent } from './staff/staff.component';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: CompanyDashboardComponent,
    children: [
      { path: '', component: RevenueComponent },
      { path: AppConstants.revenue, component: RevenueComponent },
      { path: AppConstants.receipts, component: ReceiptsComponent },
      { path: AppConstants.staff, component: StaffComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyDashboardRoutingModule { }
