import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { dmsComponent } from './document-management-system.component';
import { AuthGuard } from '@app/_services';
import { DmsMainComponent } from './dms-main/dms-main.component';
import { AppConstants } from '@app/_helpers/api-constants';
import { DmsUiComponent } from './dms-ui/dms-ui.component';

const routes: Routes = [
  {
    path: '',
    component: dmsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DmsMainComponent },
      { path: AppConstants.dmsMain, component: DmsMainComponent, canActivate: [AuthGuard] },
      { path: AppConstants.dmsUIMain, component: DmsUiComponent, canActivate: [AuthGuard] },
    ],
    data: { 'roles': ['manager', "staff","Senior Manager"], 'plans': ["free", 'enterprise'], 'featID': 24 }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentManagementRoutingModule { }
