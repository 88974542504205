import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils } from "@app/_services";
import { SelectItem } from "primeng/api";
import { forkJoin, timer } from "rxjs";
import { environment } from "@environments/environment";
import { AppConstants } from "@app/_helpers/api-constants";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { SharedComponentsService } from "@app/shared/components";
import moment from "moment";
declare let numeral: any;

@Component({
  selector: "retainer-invoice",
  templateUrl: "./retainer-invoice.component.html",
})
export class RetainerInvoiceComponent implements OnInit, AfterViewInit {
  public clientListDatasource: any = [];
  @ViewChild("searchValue") searchValue;
  filteredItemsSize = -1;
  public loginCompanyId;
  public selClient: any = null;
  public filteredClients: any[];
  public clientsList: string[];

  public nextInvoiceNumber: any = 0;
  public InvoiceDate: any = new Date();
  public InvoiceAmount: any = 0;
  public FinalizeAction: any = 0;
  public DescriptionShort: any = null;
  public InvoiceMemo: any = null;
  public clientProfile: any = null;
  public timeAndExpense: any = null;

  public finalizeActions: SelectItem[];
  public IsEnableHistory: boolean = true;
  public historyDataSource: any = [];
  public historyDisplay: boolean = false;
  public IsEnableSave: boolean = true;
  public IsEnableSaveNew: boolean = true;
  public isRedirect: boolean = false;
  public selectedHeaderText: any = "";
  public isSalesTaxOn: boolean = false;
  public mangoCompanyData: any = {};
  companyLocations: SelectItem[] = [];
  public salesTaxAmount: any;
  public salesTax: any = {
    Labor: 0,
    Expense: 0,
    taxableAmtService: 0,
    taxableAmtExpense: 0,
    serviceTax: 0,
    expenseTax: 0,
  };
  intervalid: any;
  searchTextStr: any = "";
  companySetting: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private http: HttpClient,
    public mangoUtils: mangoUtils,
    private translate: TranslateService,
    private router: Router,
    public sharedSrvc: SharedComponentsService
  ) {
    this.translate.reloadLang(this.translate.currentLang).subscribe((data) => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant("Billing-&-Invoicing") },
        { label: this.translate.instant("retainer-invoice"), icon: "ic-red" },
      ]);

      this.finalizeActions = [
        { label: this.translate.instant("print"), value: "Print" },
        { label: this.translate.instant("email"), value: "Email" },
        { label: this.translate.instant("finalize_only"), value: "Finalize Only" },
      ];
    });

    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.InvoiceDate =
      this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate) != null &&
      this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate) != ""
        ? new Date(this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate))
        : new Date();
    this.InvoiceAmount = 0;
    this.timeAndExpense = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);

    this.loadDefaults();
  }

  @HostListener("mouseup", ["$event"])
  @HostListener("mousemove", ["$event"])
  refreshUserState(event: MouseEvent) {
    if (!this.sharedSrvc.invoiceInactivitySub.closed)
      this.sharedSrvc.invoiceInactivitySub.next(null);
  }

  ngOnInit(): void {
    this.getLastInvoiceNumber();
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
  }

  fetchClients() {
    let list = this.encrDecSrvc.clientList;
    list = list.filter((item) => !item["Inactive"]);
    if (this.clientListDatasource.length == 0 || this.clientListDatasource.length !== list.length) {
      this.clientListDatasource = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientListDatasource.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  getLastInvoiceNumber() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getInvoiceOption().subscribe((data: any) => {
      parent.nextInvoiceNumber = data["NextInvoiceNumber"] + 1;
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientListDatasource.length; i++) {
        const client = this.clientListDatasource[i];
        if (client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        } else if (client["ClientNumber"]?.toLowerCase()?.indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  /*
  dropDownselect
  */
  handleSelectClick(event) {
    this.selClient = event;
    const parent = this;
    parent.IsEnableHistory = true;
    parent.IsEnableSaveNew = false;
    parent.fetchClientInformation(event["ClientID"]);
    parent.mangoAPISrvc.getSettingData(event["ClientID"]).subscribe((data: any) => {
      parent.FinalizeAction = data.FinalizeAction ? data.FinalizeAction : "Print";

      parent.validateForm();
      parent.mangoAPISrvc.showLoader(false);
    });

    parent.historyData();
  }

  fetchClientInformation(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getClientFullinformation(id).subscribe((data: any) => {
      parent.clientProfile = data;
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  validateForm() {
    this.IsEnableSave = true;
    const valueAmt = numeral(this.InvoiceAmount).value();
    if (
      this.selClient &&
      this.InvoiceDate &&
      parseFloat(valueAmt) > 0 &&
      this.InvoiceMemo &&
      this.DescriptionShort
    ) {
      this.IsEnableSave = false;
    }
  }

  changeAmount() {
    const parent = this;
    const myNumeral = numeral(this.InvoiceAmount);
    if (myNumeral.value() === null) {
      this.InvoiceAmount = 0.0;
    } else {
      if (parent.isSalesTaxOn) {
        parent.setLaborRates();
        parent.salesTaxAmount =
          "$" +
          numeral(numeral(this.InvoiceAmount).value() * (parent.salesTax.Labor / 100)).format(
            "0,0.00"
          );
      }
      this.InvoiceAmount = "$" + numeral(this.InvoiceAmount).format("0,0.00");
    }
    this.validateForm();
  }

  replaceShortcuts(value, property) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    if (property == "DescriptionShort") {
      this.DescriptionShort = valueArr.join(" ");
    } else {
      this.InvoiceMemo = valueArr.join(" ");
    }
    this.validateForm();
  }

  historyData() {
    const parent = this;
    parent.historyDataSource = [];
    parent.selectedHeaderText = "";
    if (parent.selClient) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc
        .getInvoiceHistory2(parent.selClient["ClientID"])
        .subscribe((data: any) => {
          if (data.length > 0) parent.IsEnableHistory = false;
          parent.selectedHeaderText =
            "Customer Invoice History - " + parent.selClient["ClientName"];
          parent.historyDataSource = [...data];
          parent.mangoAPISrvc.showLoader(false);
        });
    }
  }

  clearData() {
    this.validateForm();
    this.IsEnableHistory = true;
    this.IsEnableSave = true;
    this.IsEnableSaveNew = true;
    this.selClient = null;
    this.historyDataSource = [];
    this.FinalizeAction = "Print";
    this.InvoiceDate =
      this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate) != null &&
      this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate) != ""
        ? new Date(this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate))
        : new Date();
    this.InvoiceAmount = 0;
    this.DescriptionShort = null;
    this.InvoiceMemo = null;
    this.clientProfile = null;
  }

  resetForm() {
    this.selClient = null;
    this.historyDataSource = [];
    this.FinalizeAction = "Print";
    this.InvoiceDate =
      this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate) != null &&
      this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate) != ""
        ? new Date(this.encrDecSrvc.getObject(AppConstants.retainerLastInvoiceDate))
        : new Date();
    this.InvoiceAmount = 0;
    this.DescriptionShort = null;
    this.InvoiceMemo = null;
    this.clientProfile = null;
  }

  saveData() {
    const parent = this;
    if (numeral(this.InvoiceAmount).value() < 1) {
      swal.fire({
        icon: "warning",
        title: parent.translate.instant("Warning"),
        text: parent.translate.instant("positive_amount_message"),
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    } else {
      swal
        .fire({
          title: this.translate.instant("confirmation"),
          html: this.translate.instant("billing.save_retainer_invoice"),
          icon: "warning",
          showCancelButton: true,
          showDenyButton: true,
          width: "38em",
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: this.translate.instant("save_and_new"),
          denyButtonText: this.translate.instant("save_and_invoice_review"),
          cancelButtonText: this.translate.instant("no_cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            parent.isRedirect = false;
            parent.processInvoice();
          } else if (result.isDenied) {
            parent.isRedirect = true;
            parent.processInvoice();
          }
        });
    }
  }

  /*
    processing the invoice below are the steps

   1. updating the comapnymango table with NextInvoiceNumber

 */
  processInvoice() {
    this.saveBillingHeaderInfo();
  }

  /*
     Saving Billing Header information for the NEW manual invoice created. Step 1
     Have to get the BillingHeaderID to be used for Detail records and to update other records.
 */
  saveBillingHeaderInfo() {
    const parent = this;
    const headerObj = {};
    headerObj["ClientID"] = parent.selClient.ClientID;
    headerObj["CompanyID"] = parseInt(parent.encrDecSrvc.getObject(AppConstants.companyID));
    headerObj["QBReference"] = null;
    headerObj["InvoiceNumber"] = parent.nextInvoiceNumber;
    headerObj["InvoiceDate"] = new Date(parent.InvoiceDate);
    headerObj["DescriptionShort"] = parent.DescriptionShort;
    if (parent.isSalesTaxOn) {
      headerObj["InvoiceAmount"] = headerObj["TotalServices"] = numeral(
        parent.InvoiceAmount
      ).value();
      headerObj["InvoiceBalance"] =
        numeral(parent.InvoiceAmount).value() + numeral(parent.salesTaxAmount).value();
    } else {
      headerObj["InvoiceAmount"] =
        headerObj["InvoiceBalance"] =
        headerObj["TotalServices"] =
          numeral(parent.InvoiceAmount).value();
    }
    headerObj["TotalWUWD"] =
      headerObj["GraceDays"] =
      headerObj["TotalExpenses"] =
      headerObj["TotalPayments"] =
      headerObj["Discount"] =
        0;
    headerObj["TotalTax"] = numeral(parent.salesTaxAmount).value()
    headerObj["PreviousBalance"] = 0; //@NOTE: this has been set to 0 as per PSE-2546
    headerObj["BillNote"] = parent.mangoCompanyData['StandardFooterMessage'];
    headerObj["PeriodTo"] = null;
    headerObj["BillNoteTop"] = headerObj["ProjectMasterIDArray"] = null;
    headerObj["FinalizeAction"] = this.FinalizeAction;
    headerObj["InvoicePosted"] = (headerObj['FinalizeAction'] == "Finalize Only" && this.mangoCompanyData['SkipInvoiceReview']) || false;
    headerObj["InvoiceType"] = "Retainer Invoice";

    headerObj["OriginatingPartnerID"] = this.clientProfile["OriginatingPartnerID"];
    headerObj["BillingPartnerID"] = this.clientProfile["BillingPartnerID"];
    headerObj["StaffAssignedID"] = this.clientProfile["StaffAssignedID"];
    headerObj["GroupDescriptionID"] = this.clientProfile["GroupDescriptionID"];
    headerObj["GroupDescriptionIDArray"] = this.clientProfile["GroupDescriptionIDArray"];

    headerObj["ClientTypeID"] = this.clientProfile["ClientTypeID"];
    headerObj["InvoiceTemplate"] = "2";

    headerObj["CreatedbyStaffID"] = this.encrDecSrvc.getObject(AppConstants.staffID);
    headerObj["TotalTaxExpenses"] = 0;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createBillingHeader(headerObj).subscribe(
      (data: any) => {
        const billingHeaderId = data.data["BillingHeaderID"];
        const lastInvoiceObj = {};
        lastInvoiceObj["NextInvoiceNumber"] = data.data["InvoiceNumber"];
        parent.salesTaxAmount = 0
        headerObj["InvoiceNumber"] = data.data["InvoiceNumber"];
        parent.mangoAPISrvc.updateLastInvoiceNumber(lastInvoiceObj).subscribe((data: any) => {
          parent.nextInvoiceNumber = headerObj["InvoiceNumber"] + 1;
          /* BillingDetail must be created with a Manual Invoice
                The BillingDetail records is associated with the BillingHeader and billingHeaderID
            */
          parent.createBillingDetailRecord(billingHeaderId, headerObj);
          parent.mangoAPISrvc.showLoader(false);
        });
      },
      (err) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify("error", this.translate.instant("error"), err);
      }
    );
  }

  /*
     Process Invoice Service Line items in table
     Creating billing details record
   */
  createBillingDetailRecord(billingheaderId, headerObj) {
    const lineItemSaveList: any = [];
    /*
    If there is Service Line Amount is > 0 then Create Billing Detail Records
    If No Service Line Amount then then No need to Create Billing Detail Record
  */

    const detailObj = {};
    detailObj["BillingHeaderID"] = billingheaderId;
    detailObj["ClientID"] = headerObj.ClientID;
    detailObj["CompanyID"] = headerObj.CompanyID;
    detailObj["WorkCodeID"] = null;
    detailObj["ServiceCodeID"] = null;
    detailObj["Description"] = this.InvoiceMemo;
    detailObj["EngagementTypeID"] = null;
    detailObj["ProjectID"] = null;
    detailObj["InvoiceDate"] = headerObj.InvoiceDate;
    detailObj["InvoiceNumber"] = headerObj.InvoiceNumber;
    detailObj["SalesTaxAmount"] = 0;
    detailObj["StaffID"] = null;
    detailObj["LineItem"] = 1;
    detailObj["Amount"] = headerObj.InvoiceAmount;

    detailObj["OriginatingPartnerID"] = this.clientProfile["OriginatingPartnerID"];
    detailObj["BillingPartnerID"] = this.clientProfile["BillingPartnerID"];
    detailObj["GroupDescriptionIDArray"] = this.clientProfile["GroupDescriptionIDArray"];
    detailObj["ClientTypeID"] = this.clientProfile["ClientTypeID"];

    lineItemSaveList.push(detailObj);
    const parent = this;
    parent.processBillingDetailRecords(lineItemSaveList).subscribe((data) => {
      parent.mangoAPISrvc.notify("success", "Success!", "Retainer Invoice successfully created.");
      if (this.isRedirect) this.router.navigate([AppConstants.InvoiceReviewUrl]);
      parent.clearData();
    });
  }

  /*
      creating BillingDetail records
   */
  processBillingDetailRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject("token"));
    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.post(`${environment.API_URL}/billingDetail/create`, selectedItem, {
        headers: headers,
        withCredentials: true
      });
      observableBatch.push(urlOne);
    });

    return forkJoin(observableBatch);
  }

  onInvoiceHistoryClick() {
    this.historyDisplay = true;
    const interval = setInterval(() => {
      if (!this.searchValue) return;
      clearInterval(interval);
      this.searchValue.nativeElement.focus();
    });
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  onCopyMemo() {
    this.InvoiceMemo = this.DescriptionShort;
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  onSelectDate() {
    this.encrDecSrvc.addObject(AppConstants.retainerLastInvoiceDate, this.InvoiceDate);
  }

  verifySystemLocking(e) {
    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(
      new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)
    ).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        "error",
        "Error!",
        "System Locking is enabled on the selected date."
      );
    }
  }

  showSalesTaxAmount(event: any) {
    const parent = this;
    if (event.checked) {
      parent.isSalesTaxOn = true;
      parent.setLaborRates();
      parent.salesTaxAmount =
        "$" +
        numeral(numeral(this.InvoiceAmount).value() * (parent.salesTax.Labor / 100)).format(
          "0,0.00"
        );
    } else {
      parent.isSalesTaxOn = false;
    }
  }

  setLaborRates() {
    const parent = this;
    if (parent.mangoCompanyData.ActivateLaborRates == true) {
      if (parent.clientProfile.SalesTaxLevel == "ClientRate") {
        parent.salesTax.Labor = parent.clientProfile.Tax1ID
          ? numeral(parent.clientProfile.Tax1ID).value()
          : 0;
      } else if (parent.clientProfile.SalesTaxLevel == "CompanyLocationRate") {
        const selectedCompanyLocation = this.companyLocations.filter(
          (location) => location["value"] == parent.clientProfile.CompanyMangoLocationID
        )[0];
        parent.salesTax.Labor = selectedCompanyLocation["laborRate"]
          ? numeral(selectedCompanyLocation["laborRate"]).value()
          : 0;
      } else {
        parent.salesTax.Labor = parent.mangoCompanyData.LaborRate1
          ? numeral(parent.mangoCompanyData.LaborRate1).value()
          : 0;
      }
    }
  }

  loadDefaults() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.loadManualInvoice(parent.loginCompanyId).subscribe((data: any) => {
      parent.mangoCompanyData = data[1][0];
      parent.mangoAPISrvc.showLoader(false);
    }),
      (error) => {
        parent.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      };
  }
}
