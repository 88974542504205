import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedComponentsService } from "@app/shared/components";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, mangoUtils, MangoApiService } from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import Swal from "sweetalert2";
declare let numeral: any;
import $ from "jquery";

@Component({
  selector: "app-tasks-budget",
  templateUrl: "./tasks-budget.component.html",
})
export class TasksBudgetComponent implements OnInit {
  @Input() ddmProjectHeaderID: any;
  @Input("budget") budgetData: any;

  @Input() CompanyTemplateHeaderID: any;
  @Input() isFromTemplate: boolean = false;
  @Input() initialBudgetForTime: number = 0;
  @Output() onTasksBudgetUpdated: any = new EventEmitter<{
    isUpdated: boolean;
    totalTimeAmount: number;
  }>();
  @Output() onTasksBudgetSaved: any = new EventEmitter<{
    totalTimeAmount: number;
    isInitial: boolean;
  }>();
  tasksDataSource: any = [];

  clonedData: any = [];
  lastRowIndex: number = -1;

  footerTotal = {
    TimeAmount: 0,
    ActualAmount: 0,
    Difference: 0,
  };

  companyID: any = null;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public sharedSrvc: SharedComponentsService,
    private translate: TranslateService,
    public mangoUtils: mangoUtils
  ) {}

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.mangoAPISrvc.showLoader(true);
    if (this.isFromTemplate) {
      this.mangoAPISrvc
        .getBudgetsTasksTemplate(this.CompanyTemplateHeaderID, this.companyID)
        .subscribe(
          (result: any) => {
            this.tasksDataSource = result.map((item) => ({
              ...item,
              TimeAmount: item.TimeAmount || "0.00",
              SavedTimeAmount: item.TimeAmount || "0.00",
            }));
            this.emitTotalTimeAmount(this.tasksDataSource);
            this.mangoAPISrvc.showLoader(false);
            this.calculateFooters();
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "error",
              "Error",
              AppConstants.fetchErrorMsg
            );
          }
        );
    } else {
      this.mangoAPISrvc
        .getBudgetsTasks(
          this.ddmProjectHeaderID,
          this.budgetData.BudgetsID,
          this.companyID
        )
        .subscribe(
          (result: any) => {
            this.tasksDataSource = result.map((item) => ({
              ...item,
              TimeAmount: item.TimeAmount || "0.00",
              SavedTimeAmount: item.TimeAmount || "0.00",
              isLoadingActualAmt: true,
              isLoadingDifference: true,
            }));
            
            if (this.initialBudgetForTime > 0 && result?.length > 0) {
              //distribute initialBudgetForTime to tasks data source
              this.distributeInitialBudget(this.initialBudgetForTime);
            } else {
              this.fetchActualAmounts(true);
              this.emitTotalTimeAmount(this.tasksDataSource, true);
              this.mangoAPISrvc.showLoader(false);
              this.calculateFooters();
            }
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "error",
              "Error",
              AppConstants.fetchErrorMsg
            );
          }
        );
    }
  }

  fetchActualAmtAsync(task) {
    return new Promise((resolve, reject) => {
      this.mangoAPISrvc
        .getBudgetTaskActualAmt(task.Year, task.BudgetsID)
        .subscribe(
          (slipFigures) => {
            resolve(slipFigures);
          },
          (err) => reject(err)
        );
    });
  }

  async fetchActualAmounts(isCalculate: boolean = false) {
    try {
      if (this.tasksDataSource?.length === 0) return;

      const figures: any = await this.fetchActualAmtAsync(this.tasksDataSource[0])

      this.tasksDataSource.forEach((task, index) => {
        const amountFigure = figures?.find((figure) => figure.ddmProjectDetailID === task.ProjectDetailsID)
        this.tasksDataSource[index]["ActualAmount"] =
          amountFigure?.totaltime ?? 0;
        this.tasksDataSource[index]["Difference"] =
          this.mangoUtils.subtractFloat(
            task["TimeAmount"] || 0,
            amountFigure?.totaltime ?? 0
          );
        this.tasksDataSource[index]["isLoadingActualAmt"] = false;
      })
      if(isCalculate) {
        this.calculateDifference()
        this.calculateFooters()
      }
    } catch (err) {
      console.log(err);
    }
  }

  distributeInitialBudget(initialBudgetForTime) {
    const equalBudget = initialBudgetForTime / this.tasksDataSource.length;
    const difference = this.mangoUtils.subtractFloat(
      this.mangoUtils.roundOffDecimals(equalBudget) *
        this.tasksDataSource.length,
      initialBudgetForTime
    );
    this.tasksDataSource = this.tasksDataSource.map((task) => ({
      ...task,
      TimeAmount: this.mangoUtils.doMoneyFormat(equalBudget, "time"),
      SavedTimeAmount: this.mangoUtils.doMoneyFormat(equalBudget, "time"),
    }));
    const finalTimeAmount = this.mangoUtils.subtractFloat(
      this.tasksDataSource[this.tasksDataSource.length - 1]["TimeAmount"],
      difference
    );
    this.tasksDataSource[this.tasksDataSource.length - 1]["TimeAmount"] =
      this.tasksDataSource[this.tasksDataSource.length - 1]["SavedTimeAmount"] =
        this.mangoUtils.doMoneyFormat(finalTimeAmount, "time");

    this.onSaveAllBudgetDetails();
  }

  onSaveAllBudgetDetails() {
    const list = {
      add: [],
      update: [],
    };
    this.tasksDataSource.forEach((task) => {
      task.TimeAmount = numeral(task.TimeAmount).value();
      if (task?.BudgetsDetailID) list.update.push(task);
      else {
        task["BudgetsID"] = this.budgetData.BudgetsID;
        list.add.push(task);
      }
    });
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.batchCreateOrUpdateBudgetDetails({ list }).subscribe(
      (result: any) => {
        this.tasksDataSource.forEach((task) => {
          if (!task.BudgetsDetailID) {
            const budgetDetail = result?.data?.added?.find(
              (item) => item.ddmProjectDetailsID == task.ProjectDetailsID
            );
            task.BudgetsDetailID = budgetDetail?.BudgetsDetailID;
            task.BudgetsID = budgetDetail?.BudgetsID;
          }
        });
        this.fetchActualAmounts(true);
        // this.calculateDifference();
        // this.calculateFooters();
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        this.mangoAPISrvc.showLoader(true);
      }
    );
  }

  emitTotalTimeAmount(dataSource, isInit: boolean = false) {
    const totalTimeAmount = dataSource.reduce((a, b) => {
      return a + numeral(b["TimeAmount"]).value();
    }, 0);

    this.onTasksBudgetSaved.emit({
      totalTimeAmount: totalTimeAmount,
      isInitial: isInit,
    });
  }

  calculateFooters() {
    // footerTotal
    this.footerTotal.TimeAmount = this.tasksDataSource.reduce((a, b) => {
      return a + numeral(b.TimeAmount).value();
    }, 0);
    this.footerTotal.ActualAmount = this.tasksDataSource.reduce((a, b) => {
      return a + numeral(b.ActualAmount).value();
    }, 0);
    this.footerTotal.Difference = this.tasksDataSource.reduce((a, b) => {
      return a + numeral(b.Difference).value();
    }, 0);
  }

  onRowEditInit(rowData, index) {
    const column = "ProjectDetailsID";

    const filtered = this.clonedData.filter(
      (item) => item[column] == rowData[column]
    );
    if (filtered.length == 0) {
      this.clonedData.push({ ...rowData });
    }

    if (rowData["IsRowEditing"] && this.lastRowIndex == index) return;

    this.lastRowIndex = index;
    this.selectCell("rev", index);

    rowData["IsRowEditing"] = true;
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`;
      colClass += xtraEl ? ` > ${xtraEl}` : "";
      $(colClass).select();
    }, 50);
  }

  removeItemInClonedArray(id) {
    this.clonedData = this.clonedData.filter(
      (item) => item["ProjectDetailsID"] !== id
    );
  }

  onRowEditSave(rowData) {
    rowData["IsRowEditing"] = false;
    this.removeItemInClonedArray(rowData["ProjectDetailsID"]);

    if (!this.isFromTemplate) {
      this.mangoAPISrvc.showLoader(true);
      if (rowData.BudgetsDetailID) {
        const obj = {
          TimeAmount: numeral(rowData["TimeAmount"]).value(),
        };
        this.mangoAPISrvc
          .updateBudgetsTasks(obj, rowData["BudgetsDetailID"], this.companyID)
          .subscribe(
            (result: any) => {
              rowData["SavedTimeAmount"] = rowData["TimeAmount"];
              const totalTimeAmount = this.tasksDataSource.reduce((a, b) => {
                return a + numeral(b["SavedTimeAmount"]).value();
              }, 0);

              this.mangoAPISrvc
                .updateBudget(
                  { TimeAmount: totalTimeAmount || 0 },
                  rowData["BudgetsID"],
                  this.companyID
                )
                .subscribe(
                  (resultUpt) => {
                    this.onTasksBudgetSaved.emit({
                      totalTimeAmount: totalTimeAmount,
                      isInitial: false,
                    });

                    this.calculateDifference();
                    this.calculateFooters();
                    this.mangoAPISrvc.showLoader(false);
                    this.mangoAPISrvc.notify(
                      "success",
                      "Success",
                      AppConstants.updateMsg
                    );
                  },
                  (err) => {
                    this.mangoAPISrvc.showLoader(false);
                    this.mangoAPISrvc.notify(
                      "error",
                      "Error",
                      AppConstants.updateErrorMsg
                    );
                  }
                );
            },
            (err) => {
              this.mangoAPISrvc.showLoader(false);
              this.mangoAPISrvc.notify(
                "error",
                "Error",
                AppConstants.updateErrorMsg
              );
            }
          );
      } else {
        const obj = {
          TimeAmount: rowData["TimeAmount"],
          BudgetsID: this.budgetData.BudgetsID,
          ddmProjectDetailsID: rowData["ProjectDetailsID"],
          CompanyID: this.companyID,
          ClientID: rowData["ClientID"],
        };

        this.mangoAPISrvc.createBudgetsTasks(obj).subscribe(
          (result: any) => {
            rowData["BudgetsDetailID"] = result["BudgetsDetailID"];
            rowData["BudgetsID"] = result["BudgetsID"];
            rowData["SavedTimeAmount"] = rowData["TimeAmount"];
            const totalTimeAmount = this.tasksDataSource.reduce((a, b) => {
              return a + +b["SavedTimeAmount"];
            }, 0);
            this.mangoAPISrvc
              .updateBudget(
                { TimeAmount: totalTimeAmount || 0 },
                result["BudgetsID"],
                this.companyID
              )
              .subscribe(
                (resultUpt) => {
                  this.onTasksBudgetSaved.emit({
                    totalTimeAmount: totalTimeAmount,
                    isInitial: false,
                  });

                  this.calculateDifference();
                  this.calculateFooters();
                  this.mangoAPISrvc.showLoader(false);
                  this.mangoAPISrvc.notify(
                    "success",
                    "Success",
                    AppConstants.createMsg
                  );
                },
                (err) => {
                  this.mangoAPISrvc.showLoader(false);
                  this.mangoAPISrvc.notify(
                    "error",
                    "Error",
                    AppConstants.createErrorMsg
                  );
                }
              );
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              "error",
              "Error",
              AppConstants.createErrorMsg
            );
          }
        );
      }
    } else {
      rowData["SavedTimeAmount"] = rowData["TimeAmount"];
      this.onTasksBudgetUpdated.emit({
        isUpdated: true,
      });
      this.calculateDifference();
      this.calculateFooters();
    }
  }

  onTimeAmountChanged(rowData) {
    this.calculateFooters();
  }

  calculateDifference() {
    this.tasksDataSource = this.tasksDataSource.map((item) => ({
      ...item,
      Difference: this.mangoUtils.subtractFloat(
        item.TimeAmount || 0,
        item.ActualAmount || 0
      ),
      isLoadingDifference: false,
    }));
  }

  onRowEditCancel(rowData) {
    rowData["IsRowEditing"] = false;
    const column = "ProjectDetailsID";
    const cloned = this.clonedData.filter(
      (item) => item[column] === rowData[column]
    )[0];

    rowData.TimeAmount = cloned.TimeAmount;
    this.removeItemInClonedArray(rowData[column]);
    this.calculateDifference();
    this.calculateFooters();
  }

  onSaveTemplate() {
    if (this.isFromTemplate) {
      //this will be triggered from CreateTemplate or CreateDuplicate component.
      const list = {
        add: [],
        update: [],
      };
      let totalTimeAmount = 0;

      this.tasksDataSource.forEach((task) => {
        totalTimeAmount = this.mangoUtils.addFloat(
          task.SavedTimeAmount,
          totalTimeAmount
        );
        if (!task.CompanyTemplateDetailsID) list.add.push(task);
        else list.update.push(task);
      });
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.batchUpdateProjectTemplateDetails({ list }).subscribe(
        (result: any) => {
          const templateHeaderObj = {
            CompanyTemplateHeaderID: this.CompanyTemplateHeaderID,
            TimeAmount: totalTimeAmount,
          };

          this.mangoAPISrvc
            .updateCompanyTemplateDetails(templateHeaderObj)
            .subscribe(
              (resultUpt) => {
                this.onTasksBudgetSaved.emit({
                  totalTimeAmount: templateHeaderObj.TimeAmount,
                  isInitial: false,
                  budgetTasks: this.tasksDataSource,
                });
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify(
                  "success",
                  this.translate.instant("Success"),
                  AppConstants.updateMsg
                );
              },
              (err) => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify(
                  "error",
                  this.translate.instant("Error"),
                  AppConstants.updateErrorMsg
                );
              }
            );
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            "error",
            this.translate.instant("Error"),
            AppConstants.updateErrorMsg
          );
        }
      );
    }
  }
}
