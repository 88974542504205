import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BreadcrumbService,
  EncrDecrService,
  AuthGuard,
  MangoApiService,
  mangoUtils
} from '@app/_services';
import { forkJoin } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '@app/_helpers/api-constants';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import moment from 'moment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {
  public myReportsForm: UntypedFormGroup;
  public dateRangeList: SelectItem[];
  @ViewChild('searchValue') searchValue;
  @ViewChild('searchInvoiceValue') searchInvoiceValue;
  @ViewChild('searchTimeValue') searchTimeValue;
  @ViewChild('searchPerformanceValue') searchPerformanceValue;
  @ViewChild('searchUserValue') searchUserValue;
  @ViewChild('searchPayrollValue') searchPayrollValue;
  @ViewChild('searchMasterValue') searchMasterValue;
  @ViewChild('searchFavValue') searchFavValue;
  @ViewChild('searchMainValue') searchMainValue;

  @ViewChild('accountdt') accountdt: Table;
  @ViewChild('invoicedt') invoicedt: Table;
  @ViewChild('timedt') timedt: Table;
  @ViewChild('performancedt') performancedt: Table;
  @ViewChild('payrolldt') payrolldt: Table;
  @ViewChild('masterdt') masterdt: Table;
  @ViewChild('favdt') favdt: Table;
  @ViewChild('userdt') userdt: Table;

  public reportID: number;
  public selClient: any = {};
  public clientsList: any = [];
  public fullClientsList: any = [];
  public IsDateTypeReadOnly: boolean = true;
  public isIncludeActive: boolean = false;
  public selectedItem: any;

  public orientationList: SelectItem[] = []

  public allReportsDataSource: any = [];
  public allFavouriteDataSource: any = [];
  public reportsList: any = [];
  public timeAndExpensiveDataSource: any = [];
  public payRollDataSource: any = [];
  public performanceDataSource: any = [];
  public accountingDataSource: any = [];
  public invoiceDataSource: any = [];
  public masterListDataSource: any = [];
  public profitabilityDataSource: any = [];
  public AllStaffsTypes: any = [];
  public AllOriginatingPartners: any = [];
  public AllBillingPartners: any = [];
  public fullStaffList = [];
  public clientsTypesList: any = [];
  public allReportUsersDataSource: any = [];
  public logsTempData: any[] = [];
  public logsData = [];
  public locationsDataSource = [];
  public allStaffDepartments = [];

  public momentWeek: moment.unitOfTime.StartOf = 'isoWeek';
  companyData

  monthRangeLimit: number;
  rowDesc: string = "";
  public reportType: any = "";

  public nativeWindow: any;
  public reportsCols: any[];
  public engagementsTypes: any = [];
  public activityList: any = [];
  public allClientGroupsDataSource: any = [];
  reportTitle: any = '';
  public isRowclicked = false;
  p_activity: boolean = false;
  p_StaffTime: boolean = false;
  p_activityGroup: boolean = false;
  p_clientGroup: boolean = false;
  p_clientType: boolean = false;
  p_billingGroup: boolean = false;
  p_companyLocation: boolean = false;
  p_department: boolean = false;
  p_clients: boolean = false;
  p_user: boolean = false;
  p_partner: boolean = false;
  p_manager: boolean = false;
  p_engagement: boolean = false;
  loading: boolean;
  p_isCSV: boolean = false;
  p_isPortrait: boolean = false;
  isDisbleAll: boolean = false;
  p_dateFromTo: boolean = false;
  sidePanelDisplay: boolean = false;
  nextRecords: boolean = true;
  showYearFilter: boolean = false;
  hideDateFilters: boolean = false;

  companyId;
  staffId;
  staffName;
  staffEmail;
  cols: any[];
  initialOffsetValue: number = 0;
  totalEmailsCount: number = 0;
  dateFrom: Date;
  dateTo: Date;
  maxDate = new Date();
  public apisActionsArr = [
    'isDeliveredApi',
    'isOpenedApi',
    'isHardBouncesApi',
    'isClicksApi',
    'isSoftBouncesApi',
    'isBlockedApi',
    'isDeferredApi'
  ];
  public isLoadFirstTime = true;
  intervalid: any;
  defaultPageSize: number = 5;

  public isARReport: boolean = false;

  public dateToCheck: any = new Date();
  public dateFromCheck: any = new Date('1901-01-01');
  public isDateRangeChange: boolean = false;
  public prevDateTo: any = new Date();
  public prevDateFrom: any = new Date('1901-01-01');
  public years: any;

  orientationType: string = ''

  generateEmailReportObj = {
    url: "",
    fileName: "",
    reportID: null
  }

  constructor(private http: HttpClient, public translate: TranslateService, public auth: AuthGuard, private _fb: UntypedFormBuilder, private _router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, private mangoUtils: mangoUtils) {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.mangoAPISrvc.applyLanguage();
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.staffName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.staffEmail = this.encrDecSrvc.getObject(AppConstants.userEmail);
    const pageSize = this.encrDecSrvc.getObject(AppConstants.isLaptop);
    this.defaultPageSize = pageSize ? 5 : 10;
    this.years = this.mangoUtils.getYears();

    this.reportsList = [
      { value: '', label: 'PDF' },
      { value: 'csv_', label: 'Excel' }
    ];
    this.orientationList = [
      { value: '', label: 'Portrait' },
      { value: 'LS_', label: 'Landscape' }
    ]
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
  }

  initTranslations() {
    this.breadcrumbService.setItems([{ label: this.translate.instant('Reports'), icon: 'ic-red' }]);
    this.dateRangeList = [
      { label: this.translate.instant('All_Dates'), value: null },
      { label: this.translate.instant('reports.Todays_Date'), value: 'Today' },
      { label: this.translate.instant('This-Week'), value: 'Week' },
      { label: this.translate.instant('reports.This_Month'), value: 'Month' },
      { label: this.translate.instant('rpt.This_Quarter'), value: 'Quarter' },
      { label: this.translate.instant('rpt.This_Year'), value: 'Year' },
      { label: this.translate.instant('rpt.Last_Week'), value: 'lastweek' },
      { label: this.translate.instant('rpt.Last_Month'), value: 'lastmonth' },
      { label: this.translate.instant('rpt.Last_Quarter'), value: 'lastquarter' },
      { label: this.translate.instant('rpt.Last_Year'), value: 'lastyear' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];

    /*  this.myReportsForm
    .valueChanges.pipe(pairwise())
    .subscribe(([prev, next]: [any, any]) => {
      if((prev.DateFrom !== next.DateFrom || prev.DateTo !== next.DateTo) && this.isDateRangeChange){
        this.myReportsForm.controls['DateRange'].setValue('custom');
      }
      else{
        console.log("Retain....")
      }
    }); */
  }

  ngOnInit() {
    this.initializeForm();
    this.loadAllDefaultData();
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  checkFromDate(event) {
    setTimeout(() => {
      if (this.myReportsForm.controls['DateRange'].value !== 'custom') {
        this.myReportsForm.controls['DateRange'].setValue('custom');
      }
    }, 0);
  }

  loadAllDefaultData() {
    const self = this;
    self.getClientTypes();
    self.getActivityGroups();
    self.clientGroups();
    self.mangoAPISrvc.showLoader(true);
    self.getAllStaffList();
    self.mangoAPISrvc.loadReportsDefaults(self.companyId).subscribe(
      (results: any) => {
        self.getAllReportsData(results[0]);
        self.allFavouriteDataSource = results[1]; // .map((item) => { item['isSelected'] = false; })
        self.allReportUsersDataSource = results[2]; //.map((item) => { item['isSelected'] = false; });
        self.getEngagements(results[3]);
        self.getLocations(results[4]);
        self.getStaffDepartments(results[5]);
        setTimeout(() => {
          self.mangoAPISrvc.showLoader(false);
        }, 100);
      },
      error => {
        self.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  includeActive() {
    this.myReportsForm.controls['ClientID'].setValue(null);
    if (this.isIncludeActive) {
      this.clientsList = this.fullClientsList;
      this.AllStaffsTypes = this.fullStaffList;
    } else {
      this.clientsList = this.fullClientsList.filter(client => client.Inactive === false);
      this.AllStaffsTypes = this.fullStaffList.filter(client => client.Inactive !== true);
    }
  }

  onRedirectToExport() {
    this._router.navigate(['/manageData/exports']);
  }

  fetchClients() {
    if (
      this.clientsList.length == 0 ||
      this.clientsList.length !==
        this.encrDecSrvc.clientList.filter(
          client => client.ContactRecord !== true && client.Inactive === false
        ).length
    ) {
      const list = this.encrDecSrvc.clientList;
      const idArray = this.clientsList.map((client) => client.ClientID);
      // this.clientsList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item["ContactRecord"] != true && !idArray.includes(item.ClientID)) {
          this.fullClientsList.push({
            label: item.ClientName,
            value: item.ClientID,
            ClientID: item.ClientID,
            Inactive: item.Inactive
          });
          if (item['Inactive'] == false) {
            this.clientsList.push({
              label: item.ClientName,
              value: item.ClientID,
              ClientID: item.ClientID
            });
          }
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  isSameDate(date1, date2){
    const moment1 = moment(date1, 'MM/D/YYYY');
    const moment2 = moment(date2, 'MM/D/YYYY');
    return moment1.isSame(moment2, 'date'); //return true if same date
  }

  changeDate(event, dateType){
    const formObj = this.myReportsForm.value;

    if(dateType === 'From'){

      if((this.prevDateFrom && !this.isSameDate(this.prevDateFrom, event))){
        console.log("Change....")
        this.myReportsForm.controls['DateRange'].setValue('custom');
      } else {
        console.log('Retain....');
      }

      if (dateType === 'From') {
        this.prevDateFrom = event;
      }
    } else {
      if (this.prevDateTo && !this.isSameDate(this.prevDateTo, event)) {
        console.log('Change....');
        this.myReportsForm.controls['DateRange'].setValue('custom');
      } else {
        console.log('Retain....');
      }

      if (dateType === 'To') {
        this.prevDateTo = event;
      }
    }
  }

  changeDateTypes() {
    this.IsDateTypeReadOnly = true;
    const obj = this.myReportsForm.controls['DateRange'].value;
    this.isDateRangeChange = false;

    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.momentWeek = this.companyData?.TimeEntryFirstDayofWeek
    ? this.companyData.TimeEntryFirstDayofWeek == "monday"
      ? "isoWeek"
      : "week"
    : "isoWeek";

    if (obj == 'Today') {
      this.myReportsForm.controls['DateFrom'].setValue(new Date());
      this.myReportsForm.controls['DateTo'].setValue(new Date());
    } else if (obj == 'Week') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf(this.momentWeek).format())
      );
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf(this.momentWeek).format()));
    } else if (obj == 'lastweek') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf(this.momentWeek).subtract(1, 'week').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().endOf(this.momentWeek).subtract(1, 'week').format())
      );
    } else if (obj == 'Month') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf('month').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('month').format()));
    } else if (obj == 'lastmonth') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().subtract(1, 'months').startOf('month').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().subtract(1, 'months').endOf('month').format())
      );
    } else if (obj == 'Quarter') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf('quarter').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('quarter').format()));
    } else if (obj == 'lastquarter') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().subtract(1, 'quarters').startOf('quarter').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().subtract(1, 'quarters').endOf('quarter').format())
      );
    } else if (obj == 'Year') {
      this.myReportsForm.controls['DateFrom'].setValue(new Date(moment().startOf('year').format()));
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('year').format()));
    } else if (obj == 'lastyear') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().subtract(1, 'years').startOf('year').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().subtract(1, 'years').endOf('year').format())
      );
    } else if (obj == 'custom') {
      this.IsDateTypeReadOnly = false;
      this.myReportsForm.controls['DateFrom'].setValue(new Date());
      this.myReportsForm.controls['DateTo'].setValue(new Date());
    } else {
      this.myReportsForm.controls['DateFrom'].setValue(new Date('1901-01-01'));
      this.myReportsForm.controls['DateTo'].setValue(new Date('2099-12-30'));
    }

    this.prevDateFrom = this.myReportsForm.controls['DateFrom'].value;
    this.prevDateTo = this.myReportsForm.controls['DateTo'].value;

    this.dateFromCheck = this.myReportsForm.controls['DateFrom'].value;
    this.dateToCheck = this.myReportsForm.controls['DateTo'].value;
  }

  showSidePanel() {
    this.logsData = [];
    this.applyDueDate();
    this.sidePanelDisplay = !this.sidePanelDisplay;
  }

  loadNewRecords() {
    //this.logsData = [];
    this.logsTempData = [];
    this.initialOffsetValue = 0;
    setTimeout(() => {
      this.getEventReports(0);
    }, 200);
  }

  loadMoreRecords() {
    this.initialOffsetValue += 100;
    this.getEventReports(this.initialOffsetValue);
  }

  getAllStaffList() {
    this.AllStaffsTypes = [];
    this.AllOriginatingPartners = [];
    this.AllBillingPartners = [];

    this.mangoAPISrvc.getClientOrigAndBillingPartners(this.companyId).subscribe(
      (result: any) => {
        this.AllOriginatingPartners = result?.OriginatingPartners.map(partner => ({
          label: partner.StaffName,
          value: partner.StaffID,
          StaffID: partner.StaffID
        }));
        this.AllBillingPartners = result?.BillingPartners.map(partner => ({
          label: partner.StaffName,
          value: partner.StaffID,
          StaffID: partner.StaffID
        }));
      },
      err => {
        this.mangoAPISrvc.notify('error', 'Error', AppConstants.fetchErrorMsg);
      }
    );

    let tempList = [];
    if(this.isShowOtherStaffsToFilter) {
      tempList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
      tempList = tempList?.filter(
        staff => staff?.StaffID == this.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < tempList.length; i++) {
      const item = tempList[i];
      if(!item?.Inactive) {
        this.AllStaffsTypes.push({
          label: item.StaffName,
          value: item.StaffID,
          StaffID: item.StaffID,
          Inactive: item.Inactive
        });
      }

      this.fullStaffList.push({
        label: item.StaffName,
        value: item.StaffID,
        StaffID: item.StaffID,
        Inactive: item.Inactive
      });
      // this.AllOriginatingPartners.push({ label: item.StaffName, value: item.StaffID, StaffID: item.StaffID });
      // this.AllBillingPartners.push({ label: item.StaffName, value: item.StaffID, StaffID: item.StaffID });
    }
  }

  getClientTypes() {
    const parent = this;
    const tempList = this.encrDecSrvc.getObject(AppConstants.customerType);
    for (let i = 0; i < tempList.length; i++) {
      const item = tempList[i];
      parent.clientsTypesList.push({ label: item.CustomerTypeDescription, value: item.CustomerTypeID });
    }
  }

  getEngagements(data) {
    const parent = this;
    const list = data.filter((invoiceGroup) => invoiceGroup.Inactive == false);
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      parent.engagementsTypes.push({ label: item.Description, value: item.EngagementTypeID, EngagementTypeID: item.EngagementTypeID });
    }
  }

  clientGroups() {
    const parent = this;
    const tempList = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    for (let i = 0; i < tempList.length; i++) {
      const item = tempList[i];
      parent.allClientGroupsDataSource.push({ label: item.GroupDescription, value: item.CustomerGroupCategoryID });
    }
  }

  getLocations(data) {
    const parent = this;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      parent.locationsDataSource.push({ label: item.CompanyLocation, value: item.CompanyMangoLocationID });
    }
  }

  getStaffDepartments(data) {
    const parent = this;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      parent.allStaffDepartments.push({ label: item.DeptDescription, value: item.StaffDeptID });
    }
  }

  applyDueDate() {
    if (this.dateFrom) {
      this.dateTo = moment(this.dateFrom).endOf('week').toDate();
    } else {
      this.dateFrom = moment().startOf('week').toDate();
      this.dateTo = moment().endOf('week').toDate();
    }
    const toDate = moment(this.dateTo).format("YYYY-MM-DD");
    if (toDate > moment().format("YYYY-MM-DD")) {
      this.dateTo = moment().toDate();
    }
    this.validateToDate();
  }

  validateToDate() {
    const now = moment(this.dateTo);
    const end = moment(this.dateFrom);
    const duration = moment.duration(now.diff(end));
    const days = duration.asDays();
    if (days > 31) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('error'),
        text: this.translate.instant('rpt.Date_range_must_be_within_30_days'),
        showConfirmButton: false,
        timer: 2500
      });
    } else {
      this.apisActionsArr = [
        'isDeliveredApi',
        'isOpenedApi',
        'isHardBouncesApi',
        'isClicksApi',
        'isSoftBouncesApi',
        'isBlockedApi',
        'isDeferredApi'
      ];
      this.loadNewRecords();
    }
  }

  getActivityGroups() {
    const parent = this;
    const tempList = this.encrDecSrvc.getObject(AppConstants.activityList);
    for (let i = 0; i < tempList.length; i++) {
      parent.activityList.push({
        label: tempList[i].ServiceCode + ' - ' + tempList[i].Description,
        value: tempList[i].ServiceCodeID
      });
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = '';
    this.searchInvoiceValue.nativeElement.value = '';
    this.searchTimeValue.nativeElement.value = '';
    this.searchPerformanceValue.nativeElement.value = '';
    this.searchUserValue.nativeElement.value = '';
    this.searchPayrollValue.nativeElement.value = '';
    this.searchMasterValue.nativeElement.value = '';
    this.searchFavValue.nativeElement.value = '';
  }

  onTabChange(event) {
    //this.resetReports();
    this.clearSearchFilter();
    this.accountdt.reset();
    this.invoicedt.reset();
    this.timedt.reset();
    this.performancedt.reset();
    this.masterdt.reset();
    this.favdt.reset();
    this.payrolldt.reset();
    this.userdt.reset();
    this.isRowclicked = false;
  }

  initializeForm(isResetBtn = false) {
    const reportID = this.myReportsForm
      && this.myReportsForm.value.ReportID;
    const pentahoReport = this.myReportsForm
      && this.myReportsForm.value.PentahoReport;
    this.myReportsForm = this._fb.group({
      ReportID: reportID ? reportID
        : [null, [<any>Validators.required]],
      PentahoReport: pentahoReport
        ? pentahoReport :  [''],
      CustomerGroupCategoryID: [0],
      billingGroupID: [0],
      CompanyLocationID: [0],
      departmentID: [0],
      ClientID: [0],
      DateFrom: [new Date('1901-01-01'), [<any>Validators.required]],
      DateTo: [new Date(), [<any>Validators.required]],
      StaffID: [0],
      TimeStaffID: [0],
      year: [new Date().getFullYear().toString()],
      EngagementTypeID: [0],
      OriginatingPartnerID: [0],
      BillingPartnerID: [0],
      CustomerTypeID: [0],
      DateRange: ['Month'],
      ServiceCodeID: [0]
    });

    if (isResetBtn) {
      if (this.isARReport) {
        this.myReportsForm.controls['DateFrom'].setValue(new Date('1901-01-01'));
        this.myReportsForm.controls['DateTo'].setValue(new Date());
      } else {
        this.myReportsForm.controls['DateRange'].setValue('Month');
        this.changeDateTypes();
      }
    }
  }

  checkIfChange() {}

  getAllReportsData(data) {
    this.myReportsForm.controls['DateFrom'].setValue(new Date('1901-01-01'));
    this.myReportsForm.controls['DateTo'].setValue(new Date());
    if (data.length > 0) {
      data.map(item => {
        item['isSelected'] = false;
      });
      this.allReportsDataSource = data;
      this.payRollDataSource = data.filter(function (obj) {  return obj['RptCategory'] === 'Payroll'; });
      this.timeAndExpensiveDataSource = data.filter(function (obj) { return obj['RptCategory'] === 'Time & Expense'; });
      this.profitabilityDataSource = data.filter(function (obj) { return obj['RptCategory'] === 'Profitability'; });
      this.performanceDataSource = data.filter(function (obj) { return obj['RptCategory'] === 'Performance'; });
      this.accountingDataSource = data.filter(function (obj) { return obj['RptCategory'] === 'Accounting'; });
      this.invoiceDataSource = data.filter(function (obj) { return obj['RptCategory'] === 'Invoice'; });
      this.masterListDataSource = data.filter(function (obj) { return obj['RptCategory'] === 'Master List'; });
    }
  }

  getEventReports(offset) {
    const self = this;
    self.loading = self.nextRecords = true;
    self.logsTempData = [];
    const apisArr = [];

    const fromDate = moment(this.dateFrom).format("YYYY-MM-DD");
    let toDate = moment(this.dateTo).format("YYYY-MM-DD");
    if (toDate > moment().format("YYYY-MM-DD")) {
      toDate = moment().format("YYYY-MM-DD");
    }
    const deliveryUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=delivered&tags=` + this.companyId.toString();
    const eventUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=opened&tags=` + this.companyId.toString();
    const clickedUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=clicks&tags=` + this.companyId.toString();
    const softBounceUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=softBounces&tags=` + this.companyId.toString();
    const hardBounceUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=hardBounces&tags=` + this.companyId.toString();
    const blockedUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=blocked&tags=` + this.companyId.toString();
    const deferredUrl = `limit=100&offset=${offset}&startDate=${fromDate}&endDate=${toDate}&event=deferred&tags=` + this.companyId.toString();

    if (self.apisActionsArr.indexOf('isDeliveredApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(deliveryUrl));
    }

    if (self.apisActionsArr.indexOf('isOpenedApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(eventUrl));
    }

    if (self.apisActionsArr.indexOf('isHardBouncesApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(hardBounceUrl));
    }

    if (self.apisActionsArr.indexOf('isClicksApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(clickedUrl));
    }

    if (self.apisActionsArr.indexOf('isSoftBouncesApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(softBounceUrl));
    }

    if (self.apisActionsArr.indexOf('isBlockedApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(blockedUrl));
    }

    if (self.apisActionsArr.indexOf('isDeferredApi') > -1) {
      apisArr.push(this.mangoAPISrvc.getEmailLogs(deferredUrl));
    }

    if (apisArr.length > 0) {
      self.mangoAPISrvc.showLoader(true);
    }

    forkJoin(apisArr).subscribe(
      (data: any) => {
        for (let i = data.length - 1; i >= 0; i--) {
          if (Object.keys(data[i]).length == 0 || (Object.keys(data[i]).length > 0 && data[i].events && data[i].events.length < 100)) {
            self.apisActionsArr.splice(i, 1);
          }
        }
        if (data.length > 0 && data[0].events && data[0].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[0].events);
        }
        if (data.length > 1 && data[1].events && data[1].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[1].events);
        }
        if (data.length > 2 && data[2].events && data[2].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[2].events);
        }
        if (data.length > 3 && data[3].events && data[3].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[3].events);
        }
        if (data.length > 4 && data[4].events && data[4].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[4].events);
        }
        if (data.length > 4 && data[5].events && data[5].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[5].events);
        }
        if (data.length > 4 && data[6].events && data[6].events.length > 0) {
          self.logsTempData = self.logsTempData.concat(data[6].events);
        }

        if (self.apisActionsArr.length == 0) {
          self.nextRecords = false;
        }

        const tempArr = [];
        for (let index = 0; index < self.logsTempData.length; index++) {
          const element = self.logsTempData[index];
          const totalTags = element['tag'];
          const clientId = totalTags.split(',')[3];
          const nameStr = totalTags.split(',')[4];
          element.nameStr = "-";
          element.userNameStr = "-";

          if (totalTags.length > 4) {
            let findStr = '';
            for (let findIndex = 0; findIndex < 4; findIndex++) {
              findStr += totalTags.split(',')[findIndex] + ',';
            }
            const firstStr = element['tag'].substring(0, findStr.length).length;
            element.nameStr = element['tag'].substring(firstStr, element['tag'].length)
          } else {
            if (nameStr) {
              element.nameStr = nameStr;
            }
          }

          if (element.subject) {
            const nameIndex = element.subject.indexOf('Invoice');
            element.userNameStr = element.subject.substring(nameIndex, element.subject.length)
          }

          if (this.companyId == clientId) {
            element.date = new Date(element.date);
            tempArr.push(element);
          }
        }
        if (offset == 0) {
          self.logsData = tempArr;
        } else {
          self.logsData = [...tempArr, ...self.logsData];
        }

        self.logsData.sort(function (a, b) {
          if (a.nameStr === b.nameStr) {
            return a.userNameStr > b.userNameStr ? 1 : -1;
          }
          return a.nameStr > b.nameStr ? 1 : -1;
        });

        self.loading = false;
        self.mangoAPISrvc.showLoader(false);
      },
      error => {
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  clearMailSearchFilter() {
    this.searchMainValue.nativeElement.value = '';
  }

  onMultiSelectClientsChange() {
    const temp = this.myReportsForm.controls['ClientID'].value;
    if (temp.length > 100) {
      this.myReportsForm.controls['ClientID'].setValue(temp.slice(0, 100));
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant(
          'No more than 100 clients are allowed to be selected.  Try using Groups or another Filter.'
        ),
        icon: 'warning',
        confirmButtonText: this.translate.instant('ok')
      }).then(result => {});
    }
  }

  UpdateCustomer(bulkData, inter) {
    const self = this;
    const observableBatch = bulkData.splice(0, 100);
    if (bulkData.length == 0) {
      clearInterval(inter);
    }

    self.mangoAPISrvc.showLoader(true)
    self.mangoAPISrvc.bulkClientsUpdate({ observableBatch }).subscribe(function (data) {
      clearInterval(inter);
    
      self.mangoAPISrvc.showLoader(false)
    }, () => {
      self.mangoAPISrvc.showLoader(false)
    })
  }

  async openReport() {
    const formObj = this.myReportsForm.value;
    const obj = {
      timeStaffID: [],
      staffID: [],
      clientID: [],
      clientTypeID: [],
      billingPartnerID: [],
      originatingPartnerID: [],
      billingGroupID: [],
      staffDeptID: [],
      companyMangoLocationID: [],
      engagementTypeID: [],
      fromDate: formObj.DateFrom ? moment(formObj.DateFrom).format("MM/DD/YYYY") : moment("12/31/1900").format("MM/DD/YYYY"),
      toDate: formObj.DateTo ? moment(formObj.DateTo).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"),
    };
    if (this.monthRangeLimit || this.monthRangeLimit > 0) {
      const fromDate = moment(obj.fromDate, "MM/DD/YYYY");
      const toDate = moment(obj.toDate, "MM/DD/YYYY");
      const dateDifference = Math.abs(fromDate.diff(toDate, 'months')) + 1;
      if (this.monthRangeLimit < dateDifference) {
        Swal.fire({
          icon: 'error',
          title: this.translate.instant('error'),
          html: `<span style="font-size: 20px;">${this.translate.instant('rpt.Date_range_must_be_within', { months: this.monthRangeLimit })}</span>`,
          showConfirmButton: false,
          timer: 2500
        });
        return;
      }
    }
    let baseUrl;
    if (formObj.ReportID == 55 || formObj.ReportID == 131 || formObj.ReportID == 137 || formObj.ReportID == 132 || formObj.ReportID == 133 ) {
      this.encrDecSrvc.addObject("reportSettigs", { "ClientID": formObj.ClientID, "EngagementTypeID": formObj.EngagementTypeID, "CustomerTypeID": formObj.CustomerTypeID });
      this._router.navigate([`billing-invoicing/statement-printing`], { queryParams: { reportID: formObj.ReportID }});
    } else {
      if (this.reportType == "") {
        if(formObj.ReportID === 54 || formObj.ReportID === 36 || formObj.ReportID === 32) {
          baseUrl = `%3Ahome%3Atim%3A` + this.orientationType + formObj.PentahoReport + `.prpt/report?WorkCodeID=0&&output-target=pageable/pdf;page-mode=page&accepted-page=0&showParameters=false&renderMode=REPORT&htmlProportionalWidth=false&`;
        } else {
          baseUrl = environment.SERVICE_ADDRESS + `/api/pentaho-api-repos/%3Ahome%3Atim%3A`  + this.orientationType + formObj.PentahoReport + `.prpt/report?WorkCodeID=0&&output-target=pageable/pdf;page-mode=page&accepted-page=0&showParameters=false&renderMode=REPORT&htmlProportionalWidth=false&`;
        }
       if(this.showYearFilter) {
        baseUrl += `years=${formObj.year}`;
       } else {
          baseUrl += `FromDate=${(formObj.DateFrom ? moment(formObj.DateFrom).format("MM/DD/YYYY") : 0)}
            &ToDate=${(formObj.DateTo ? moment(formObj.DateTo).format("MM/DD/YYYY") : 0)}`;
          obj.fromDate=formObj.DateFrom ? moment(formObj.DateFrom).format("MM/DD/YYYY") : "0"
       }
      } else {
        if(formObj.ReportID == 54 || formObj.ReportID == 36 || formObj.ReportID == 32) {
          baseUrl = `%3Ahome%3Atim%3A` + this.reportType + formObj.PentahoReport + `.prpt/report?WorkCodeID=0&&output-target=pageable/pdf;page-mode=page&accepted-page=0&showParameters=false&renderMode=REPORT&htmlProportionalWidth=false&`;  
        } else {
          baseUrl = environment.SERVICE_ADDRESS + `/api/pentaho-api-repos/%3Ahome%3Atim%3A` + this.reportType + formObj.PentahoReport + `.prpt/report?WorkCodeID=0&&output-target=pageable/pdf;page-mode=page&accepted-page=0&showParameters=false&renderMode=REPORT&htmlProportionalWidth=false&`;
        }
        if(this.showYearFilter) {
          baseUrl += `years=${formObj.year}`;
         } else {
          baseUrl += `FromDate=${(formObj.DateFrom ? moment(formObj.DateFrom).format("MM/DD/YYYY") : 0)}
            &ToDate=${(formObj.DateTo ? moment(formObj.DateTo).format("MM/DD/YYYY") : 0)}`;
            obj.fromDate=formObj.DateFrom ? moment(formObj.DateFrom).format("MM/DD/YYYY") : "0"
         }
      }

      formObj.ClientID = !formObj.ClientID || formObj.ClientID?.length < 0  ? 0 : formObj.ClientID;

      var ids = formObj.ClientID?.toString().split(',');
      if (ids?.length) {

        if (formObj.ReportID == 128) {
          const parent = this;
          this.mangoAPISrvc.clearCustomerStatement().subscribe(function (item) {
            parent.mangoAPISrvc.showLoader(false);
            const observableBatch = formObj.ClientID.map((x) => {return {ClientID: x, IsProcessStmt: true}});
            const intervalId = setInterval(() => parent.UpdateCustomer(observableBatch, intervalId), 900);
          }, () => {
            this.mangoAPISrvc.showLoader(false);
          });

        } else {
          for (var i = 0; i < ids.length; ++i) {
            baseUrl += `&ClientID=` + ids[i];
            obj.clientID.push(ids[i])
          }
        }
      } else {
        baseUrl += `&ClientID=` + formObj.ClientID;
        obj.clientID.push(formObj.ClientID)
      }

      formObj.CustomerTypeID =
        formObj.CustomerTypeID && formObj.CustomerTypeID.length > 0 ? formObj.CustomerTypeID : 0;
      var ids = formObj.CustomerTypeID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&ClientTypeID=` + ids[i];
          obj.clientTypeID.push(ids[i])
        }
      } else {
        baseUrl += `&ClientTypeID=` + formObj.CustomerTypeID;
        obj.clientTypeID.push(formObj.CustomerTypeID)
      }
      if (this.isShowOtherStaffsToFilter) {
        formObj.StaffID = formObj.StaffID && formObj.StaffID.length > 0 ? formObj.StaffID : 0;
        formObj.TimeStaffID = formObj.TimeStaffID && formObj.TimeStaffID.length > 0 ? formObj.TimeStaffID : 0;
      }

      var ids = formObj.StaffID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&StaffID=` + ids[i];
          obj.staffID.push(ids[i])
        }
      } else {
        baseUrl += `&StaffID=` + formObj.StaffID;
        obj.staffID.push(formObj.StaffID)
      }

      var ids = formObj.TimeStaffID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&TimeStaffID=` + ids[i];
          obj.timeStaffID.push(ids[i])
        }
      } else {
        baseUrl += `&TimeStaffID=` + formObj.TimeStaffID;
        obj.timeStaffID.push(formObj.TimeStaffID)
      }

      var ids = formObj.TimeStaffID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&TimeStaffID=` + ids[i];
          obj.timeStaffID.push(ids[i]);
        }
      } else {
        baseUrl += `&TimeStaffID=` + formObj.TimeStaffID;
        obj.timeStaffID.push(formObj.TimeStaffID);
      }

      formObj.OriginatingPartnerID =
        formObj.OriginatingPartnerID && formObj.OriginatingPartnerID.length > 0
          ? formObj.OriginatingPartnerID
          : 0;
      var ids = formObj.OriginatingPartnerID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&OriginatingPartnerID=` + ids[i];
          obj.originatingPartnerID.push(ids[i])
        }
      } else {
        baseUrl += `&OriginatingPartnerID=` + formObj.OriginatingPartnerID;
        obj.originatingPartnerID.push(formObj.OriginatingPartnerID)
      }

      formObj.BillingPartnerID =
        formObj.BillingPartnerID && formObj.BillingPartnerID.length > 0
          ? formObj.BillingPartnerID
          : 0;
      var ids = formObj.BillingPartnerID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&BillingPartnerID=` + ids[i];
          obj.billingPartnerID.push(ids[i])
        }
      } else {
        baseUrl += `&BillingPartnerID=` + formObj.BillingPartnerID;
        obj.billingPartnerID.push(formObj.BillingPartnerID)
      }

      formObj.ServiceCodeID =
        formObj.ServiceCodeID && formObj.ServiceCodeID.length > 0 ? formObj.ServiceCodeID : 0;
      var ids = formObj.ServiceCodeID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&ServiceCodeID=` + ids[i];
        }
      } else {
        baseUrl += `&ServiceCodeID=` + formObj.ServiceCodeID;
      }

      formObj.EngagementTypeID =
        formObj.EngagementTypeID && formObj.EngagementTypeID.length > 0
          ? formObj.EngagementTypeID
          : 0;
      var ids = formObj.EngagementTypeID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&EngagementTypeID=` + ids[i];
          obj.engagementTypeID.push(ids[i])
        }
      } else {
        baseUrl += `&EngagementTypeID=` + formObj.EngagementTypeID;
        obj.engagementTypeID.push(formObj.EngagementTypeID)
      }

      formObj.CustomerGroupCategoryID =
        formObj.CustomerGroupCategoryID && formObj.CustomerGroupCategoryID.length > 0
          ? formObj.CustomerGroupCategoryID
          : 0;
      var ids = formObj.CustomerGroupCategoryID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&CustomerGroupCategoryID=` + ids[i];
        }
      } else {
        baseUrl += `&CustomerGroupCategoryID=` + formObj.CustomerGroupCategoryID;
      }

      formObj.billingGroupID =
        formObj.billingGroupID && formObj.billingGroupID.length > 0 ? formObj.billingGroupID : 0;
      var ids = formObj.billingGroupID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&BillingGroupID=` + ids[i];
          obj.billingGroupID.push(ids[i])
        }
      } else {
        baseUrl += `&BillingGroupID=` + formObj.billingGroupID;
        obj.billingGroupID.push(formObj.billingGroupID)
      }

      formObj.CompanyLocationID =
        formObj.CompanyLocationID && formObj.CompanyLocationID.length > 0
          ? formObj.CompanyLocationID
          : 0;
      var ids = formObj.CompanyLocationID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&CompanyMangoLocationID=` + ids[i];
          obj.companyMangoLocationID.push(ids[i])
        }
      } else {
        baseUrl += `&CompanyMangoLocationID=` + formObj.CompanyLocationID;
        obj.companyMangoLocationID.push(formObj.CompanyLocationID)
      }

      formObj.departmentID =
        formObj.departmentID && formObj.departmentID.length > 0 ? formObj.departmentID : 0;
      var ids = formObj.departmentID.toString().split(',');
      if (ids.length) {
        for (var i = 0; i < ids.length; ++i) {
          baseUrl += `&StaffDeptID=` + ids[i];
          obj.staffDeptID.push(ids[i])
        }
      } else {
        baseUrl += `&StaffDeptID=` + formObj.departmentID;
        obj.staffDeptID.push(formObj.departmentID)
      }

      if (formObj.ReportID === 32) {
        this.mangoAPISrvc.showLoader(true);
        const count: any = await this.mangoAPISrvc.countUserBillableNonBillableHours(obj);

        if (count > 1000) {
          this.mangoAPISrvc.notify(
            'success',
            'Your report is being processed and will be sent to your email shortly. Thank you for your patience.',
            AppConstants.updateMsg
          );
          if (this.reportType === 'csv_') {
            this.generateEmailReportObj.fileName = `csv_BillableNonbillableHours${this.companyId}.xlsx`;
          } else {
            this.generateEmailReportObj.fileName = `BillableNonbillableHours${this.companyId}.pdf`;
          }
          this.generateEmailReportObj.url = decodeURI(baseUrl);
          this.generateEmailReportObj.reportID = formObj.ReportID;
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.generateReportForEmail(this.generateEmailReportObj).subscribe(
            (result: any) => {},
            err => {
              console.log(err);
            }
          );
          Swal.fire({
            title: this.translate.instant('Information'),
            html: `<div>The User Billable/Nonbillable Hours & Amounts report will be sent to your email once the system has finished generating it.</div>`,
            icon: 'info',
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'Ok'
          });
        } else {
          baseUrl = `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/${baseUrl}`;
          var newWindow = this.nativeWindow.open(decodeURI(baseUrl));
          newWindow.location = baseUrl;
          this.mangoAPISrvc.showLoader(false);
        }
      } else if (formObj.ReportID == 54) {
        //billing worksheet
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.countBillingWorksheets(obj).subscribe(async (result: any) => {
          if (result > 1000) {
            this.mangoAPISrvc.notify(
              'success',
              'Your report is being processed and will be sent to your email shortly. Thank you for your patience.',
              AppConstants.updateMsg
            );
            if (this.reportType === 'csv_') {
              this.generateEmailReportObj.fileName = `csv_BillingWorksheet${this.companyId}.xlsx`;
            } else {
              this.generateEmailReportObj.fileName = `BillingWorksheet${this.companyId}.pdf`;
            }
            this.generateEmailReportObj.url = decodeURI(baseUrl);
            this.generateEmailReportObj.reportID = formObj.ReportID;
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.generateReportForEmail(this.generateEmailReportObj).subscribe(
              (result: any) => {},
              err => {
                console.log(err);
              }
            );
            Swal.fire({
              title: this.translate.instant('Information'),
              html: `<div>The Billing Worksheet report will be sent to your email once the system has finished generating it.</div>`,
              icon: 'info',
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: 'Ok'
            });
          } else {
            baseUrl = `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/${baseUrl}`;
            var newWindow = this.nativeWindow.open(decodeURI(baseUrl));
            newWindow.location = baseUrl;
            this.mangoAPISrvc.showLoader(false);
          }
        });
      } else if (formObj.ReportID == 36) {
        //Client Profit Summary
        this.mangoAPISrvc.showLoader(true);
        const count: any = await this.mangoAPISrvc.countClientProfitSummary(obj);
        if (count > 1000) {
          this.mangoAPISrvc.notify(
            'success',
            'Your report is being processed and will be sent to your email shortly. Thank you for your patience.',
            AppConstants.updateMsg
          );
          if (this.reportType === 'csv_') {
            this.generateEmailReportObj.fileName = `csv_ClientProfitSummary${this.companyId}.xlsx`;
          } else {
            this.generateEmailReportObj.fileName = `ClientProfitSummary${this.companyId}.pdf`;
          }
          this.generateEmailReportObj.url = decodeURI(baseUrl);
          this.generateEmailReportObj.reportID = formObj.ReportID;
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.generateReportForEmail(this.generateEmailReportObj).subscribe(
            (result: any) => {},
            err => {
              console.log(err);
            }
          );
          Swal.fire({
            title: this.translate.instant('Information'),
            html: `<div>The Client Profit Summary report will be sent to your email once the system has finished generating it.</div>`,
            icon: 'info',
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'Ok'
          });
        } else {
          //code to generate report in the UI
          baseUrl = `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/${baseUrl}`;
          const newWindow = this.nativeWindow.open(decodeURI(baseUrl));
          newWindow.location = baseUrl;
          this.mangoAPISrvc.showLoader(false);
        }
      } else if (formObj.ReportID == 89) {
        //Open Time Aging Summary Sorted by Balance
        this.mangoAPISrvc.showLoader(true);
        const count: any = await this.mangoAPISrvc.getOpenTimeAgingSummaryByBalance(obj);
        if (count > 5000) {
          this.mangoAPISrvc.notify(
            'success',
            'Your report is being processed and will be sent to your email shortly. Thank you for your patience.',
            AppConstants.updateMsg
          );
          if (this.reportType === 'csv_') {
            this.generateEmailReportObj.fileName = `csv_OpenTimeAgingSummarySortedByBalance${this.companyId}.xlsx`;
          } else {
            this.generateEmailReportObj.fileName = `OpenTimeAgingSummarySortedByBalance${this.companyId}.pdf`;
          }
          this.generateEmailReportObj.url = decodeURI(baseUrl);
          this.generateEmailReportObj.reportID = formObj.ReportID;
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.generateReportForEmail(this.generateEmailReportObj).subscribe(
            (result: any) => {},
            err => {
              console.log(err);
            }
          );
          Swal.fire({
            title: this.translate.instant('Information'),
            html: `<div>The Open Time Aging Summary Sorted By Balance report will be sent to your email once the system has finished generating it.</div>`,
            icon: 'info',
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'Ok'
          });
        } else {
          baseUrl = `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/${baseUrl}`;
          const newWindow = this.nativeWindow.open(decodeURI(baseUrl));
          newWindow.location = baseUrl;
          this.mangoAPISrvc.showLoader(false);
        }
      } else if (formObj.ReportID == 138) {
        //WIP detail
        this.mangoAPISrvc.showLoader(true);
        const count: any = await this.mangoAPISrvc.getWIPDetail(obj);
        if (count > 1000) {
          this.mangoAPISrvc.notify(
            'success',
            'Your report is being processed and will be sent to your email shortly. Thank you for your patience.',
            AppConstants.updateMsg
          );
          if (this.reportType === 'csv_') {
            this.generateEmailReportObj.fileName = `csv_WipDetail${this.companyId}.xlsx`;
          } else {
            this.generateEmailReportObj.fileName = `WipDetail${this.companyId}.pdf`;
          }
          this.generateEmailReportObj.url = decodeURI(baseUrl);
          this.generateEmailReportObj.reportID = formObj.ReportID;
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.generateReportForEmail(this.generateEmailReportObj).subscribe(
            (result: any) => {},
            err => {
              console.log(err);
            }
          );
          Swal.fire({
            title: this.translate.instant('Information'),
            html: `<div>The Work in Progress Details with A/R report will be sent to your email once the system has finished generating it.</div>`,
            icon: 'info',
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'Ok'
          });
        } else {
          baseUrl = `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/${baseUrl}`;
          const newWindow = this.nativeWindow.open(decodeURI(baseUrl));
          newWindow.location = baseUrl;
          this.mangoAPISrvc.showLoader(false);
        }
      } else {
        const newWindow = this.nativeWindow.open(decodeURI(baseUrl));
        newWindow.location = baseUrl;
      }
    }
  }

  redirectToReport(data: any) {
    this.monthRangeLimit = data.MonthRangeLimit;
    (data.ReportID === 118 && data.RptCategory === 'Performance') ?
      this.hideDateFilters = true : this.hideDateFilters = false;
    this.reportID = data.ReportID;
    ((data.ReportID === 97 || data.ReportID === 96 || data.ReportID === 101)
      && data.RptCategory === 'Invoice')  ?
      this.showYearFilter = true : this.showYearFilter = false;
    this.allReportsDataSource.map((item) => { item['isSelected'] = false; });
    this.allFavouriteDataSource.map((item) => { item['isSelected'] = false; });
    this.allReportUsersDataSource.map((item) => { item['isSelected'] = false; });
    this.isRowclicked = true;
    this.isARReport = data.RptDescription.includes('A/R') || data.RptDescription.includes('Aging');
    this.isDateRangeChange = false;
    this.reportType = '';
    this.isDisbleAll = data.NoParam;

    this.myReportsForm.reset();
    if(this.isARReport || this.reportID === 54) {
      this.myReportsForm.controls['DateFrom'].setValue(new Date('1901-01-01'));
      this.myReportsForm.controls['DateTo'].setValue(new Date());
    } else {
      this.myReportsForm.controls['DateRange'].setValue('Month');
      this.myReportsForm.controls['year'].setValue(new Date().getFullYear().toString());
      this.changeDateTypes();
    }
    this.myReportsForm.controls['ReportID'].setValue(data['ReportID']);
    this.myReportsForm.controls['PentahoReport'].setValue(data['PentahoReport']);
    if (!this.isShowOtherStaffsToFilter) {
      this.myReportsForm.controls['StaffID'].setValue(this.staffId);
      this.myReportsForm.controls['TimeStaffID'].setValue(this.staffId);
    }

    this.rowDesc = data.RptExplanation;
    this.p_isCSV = data.isCSV ? data.isCSV : false;
    this.p_isPortrait = data.isPortrait ? data.isPortrait : false;
    if (!data.NoParam) {
      this.p_activity = data.p_activity ? data.p_activity : false;
      this.p_activityGroup = data.p_activityGroup ? data.p_activityGroup : false;
      this.p_clientGroup = data.p_clientGroup ? data.p_clientGroup : false;
      this.p_billingGroup = data.p_billingGroup ? data.p_billingGroup : false;
      this.p_companyLocation = data.p_companyLocation ? data.p_companyLocation : false;
      this.p_department = data.p_department ? data.p_department : false;
      this.p_clientType = data.p_clientType ? data.p_clientType : false;
      this.p_clients = data.p_clients ? data.p_clients : false;
      this.p_user = data.p_user ? data.p_user : false;
      this.p_StaffTime = data.p_StaffTime ? data.p_StaffTime : false;
      this.p_partner = data.p_partner ? data.p_partner : false;
      this.p_manager = data.p_manager ? data.p_manager : false;
      this.p_engagement = data.p_engagement ? data.p_engagement : false;
      this.p_dateFromTo = data.p_dateFromTo;
    } else {
      this.p_activity = false;
      this.p_activityGroup = false;
      this.p_billingGroup = false;
      this.p_companyLocation = false;
      this.p_department = false;
      this.p_clientGroup = false;
      this.p_clientType = false;
      this.p_clients = false;
      this.p_user = false;
      this.p_StaffTime = false;
      this.p_manager = false;
      this.p_partner = false;
      this.p_engagement = false;
      this.p_dateFromTo = false;
    }

    data.isSelected = true;
    if (data.ReportID == 55 || data.ReportID == 131 || data.ReportID == 137 || data.ReportID == 132 || data.ReportID == 133 ) {
      this.openReport();
    }
    if (data.ReportID == 74) {
      this._router.navigate(['billing-invoicing/client-statements']);
    }
  }

  resetReports() {
    const parent = this;
    parent.isRowclicked = false;
    parent.myReportsForm.reset();
    if (!this.isShowOtherStaffsToFilter) {
      parent.myReportsForm.controls['StaffID'].setValue(this.staffId);
      parent.myReportsForm.controls['TimeStaffID'].setValue(this.staffId);
    }
    parent.rowDesc = '';
  }

  removeFavourite(event: any, obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.deleteFavorite(obj.ReportID).subscribe(
      (data: any) => {
        if (data.message) {
          parent.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success_notify'),
            AppConstants.deleteMessage
          );
          parent.getAllLoadReportsData();
          parent.getFavReports();
          parent.resetReports();
          parent.mangoAPISrvc.showLoader(false);
        }
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getFavReports() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getFavReportsById().subscribe(
      (data: any) => {
        data.map(item => {
          item['isSelected'] = false;
        });
        this.allFavouriteDataSource = data;
        this.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getAllLoadReportsData() {
    const parent = this;
    this.myReportsForm.controls['DateFrom'].setValue(new Date('1901-01-01'));
    this.myReportsForm.controls['DateTo'].setValue(new Date());
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getALLReports().subscribe(
      (data: any) => {
        if (data.length > 0) {
          data.map(item => {
            item['isSelected'] = false;
          });
          parent.allReportsDataSource = data;
          parent.payRollDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Payroll';
          });
          parent.timeAndExpensiveDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Time & Expense';
          });
          parent.profitabilityDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Profitability';
          });
          parent.performanceDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Performance';
          });
          parent.accountingDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Accounting';
          });
          parent.invoiceDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Invoice';
          });
          parent.masterListDataSource = data.filter(function (obj) {
            return obj['RptCategory'] === 'Master List';
          });
          parent.mangoAPISrvc.showLoader(false);
        }
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getUserReports() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getUserReports().subscribe(
      (data: any) => {
        data.map(item => {
          item['isSelected'] = false;
        });
        this.allReportUsersDataSource = data;
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  removeUserFavourite(event: any, obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.removeUserFavourite(obj.ReportID).subscribe(
      (data: any) => {
        if (data.message) {
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success_notify'),
            'Successfully Removed!'
          );
          parent.getAllLoadReportsData();
          parent.getFavReports();
          parent.getUserReports();
          parent.resetReports();
        }
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  addUserFavourite(event: any, obj) {
    const parent = this;
    obj['CompanyID'] = parseInt(parent.companyId);
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.addUserFavourite(obj).subscribe(
      (data: any) => {
        if (data.message) {
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success_notify'),
            AppConstants.updateMsg
          );
          parent.getAllLoadReportsData();
          parent.getFavReports();
          parent.getUserReports();
          parent.resetReports();
        }
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  addFavourite(event: any, obj) {
    const parent = this;
    obj['CompanyID'] = parseInt(parent.companyId);
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.addFavorite(obj).subscribe(
      (data: any) => {
        if (data.message) {
          parent.mangoAPISrvc.showLoader(false);
          parent.getAllLoadReportsData();
          parent.getFavReports();
          parent.resetReports();
          parent.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success_notify'),
            AppConstants.updateMsg
          );
        }
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.deleteErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  get isShowAllReports() {
    return this.auth.isAllowAccess(30);
  }

  get isShowOtherStaffsToFilter() {
    return this.auth.isAllowAccess(46);
  }
}
