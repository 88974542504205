<div class="layout-content">
    <span class="pull-right p-mr-3 clientform-btn-top">
        <button pButton pRipple label="{{ 'DMS-Settings' | translate }}" icon="fal fa-users-cog"
            class="p-button-sm p-px-2 p-mx-2" tooltipPosition="top"
            [ngClass]="(isApiReady && isDMSAllowed)?'':'wrapper'" pTooltip="{{ 'DMS-Settings' | translate }}"
            (click)="openDialog()"></button>
    </span>
    <div class="p-col-12">
        <div class="card p-mx-1">
            <label class="label-text p-mr-4 required-field">{{'client.name' | translate}}</label>
            <p-autoComplete [(ngModel)]="selClient" [ngModelOptions]="{standalone: true}" [style]="{'width':'20%'}"
                panelStyleClass="client-dropdown-autocomplete" autocomplete="off" [suggestions]="filteredClients"
                (completeMethod)="filterClients($event)" field="ClientName" (onSelect)="onRowSelect($event,true);"
                ngDefaultControl placeholder="{{'Search-Clients' | translate}}" (onFocus)="$event.target.select()"
                [dropdown]="true" required>
                <ng-template let-client pTemplate="item">
                    <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                </ng-template>
            </p-autoComplete>

            <span>
                <button pButton pRipple label="{{'clear' | translate}}" icon="fad fa-eraser"
                    class="p-button-sm p-pr-3 p-mx-2" tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"
                    (click)="onRowSelect($event,false)"></button>
            </span>
        </div>
    </div>
    <div class="p-col-12">
        <div class="p-mx-1 p-my-1">
            <p-tabView (onChange)="handleTabChange($event)">
                <p-tabPanel header="Active">
                    <div id='treeparent'>
                        <div class="control-sectio treeparent">
                            <div class="card p-mx-1">
                                <ejs-filemanager id='fileExplorer' #fileExplorer [ajaxSettings]='ajaxSettings'
                                    [detailsViewSettings]='detailsViewSettings' [view]='view'
                                    (uploadListCreate)="uploadListCreate($event)"
                                    [contextMenuSettings]='contextMenuSettings' (menuOpen)='menuOpen($event)'
                                    (menuClick)='menuClick($event)' (beforeSend)="beforeSend($event)"
                                    (success)="onAjaxSuccess($event)" [allowDragAndDrop]='true'
                                    [toolbarSettings]='toolbarSettings' (failure)='onAjaxFailure($event)'
                                    (beforeDownload)="beforeDownload($event)" (created)='onCreate($event)'
                                    [uploadSettings]='uploadSettings' [allowMultiSelection]='true'
                                    (fileSelect)='onFileSelect($event)'
                                    [navigationPaneSettings]='navigationPaneSettings'
                                    (toolbarClick)='toolbarClick($event)' (toolbarCreate)='toolbarCreate($event)'>
                                </ejs-filemanager>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Archive">
                    <div class="card p-mx-1">
                        <p-table [value]="archivedList" [(selection)]="selectearchivedList" dataKey="dmsParentID">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <div class="p-d-inline p-text-left">
                                        <button type="button" pButton pRipple label="Reinstate"
                                            [disabled]="selectearchivedList.length == 0" icon="pi pi-undo"
                                            class="p-mr-1 p-button-sm" (click)="undoSelectedFiles();"></button>
                                        <button type="button" pButton pRipple label="Delete"
                                            [disabled]="selectearchivedList.length == 0" icon="pi pi-trash"
                                            class="p-mr-1 p-button-sm" (click)="deleteSelectedFiles();"></button>
                                    </div>
                                    <!-- <div class="pull-right">
                                        <div class="ic-red p-pb-3">* Files & Folders that have been in Archive more than
                                            30 days will be
                                            automatically deleted.</div>
                                    </div> -->
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 5rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th>File Name</th>
                                    <th>Type</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-file>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="file"></p-tableCheckbox>
                                    </td>
                                    <td>{{file.FName}}</td>
                                    <td>{{file.FileType || 'Folder'}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">
                                        <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <div #dummyViewer class="dummyViewer"></div>
</div>

<p-sidebar [(visible)]="visibleSidebar" position="right" styleClass="p-sidebar-md" [dismissible]="true"
    [showCloseIcon]="true" (onHide)="closeDialog()" [closeOnEscape]="false" [style]="{width:'80%','overflow-y':'auto'}">
    <p-tabView (onChange)="handleChange($event)">
        <p-tabPanel header="{{'dm.Folder_Template' | translate}}">
            <div class="p-grid">
                <div class="p-col-12 pull-right p-text-right">
                    <span class="p-buttonset">
                        <!-- <button pButton pRipple icon="fal fa-arrow-left" label="Go Back" (click)="closeDialog()"></button> -->
                        <button pButton pRipple icon="fal fa-plus" label="{{'dm.Create_Template' | translate}}"
                            [ngClass]="(isCreateNew)?'':'wrapper'" (click)="createTemplate()"></button>
                        <button pButton pRipple icon="fal fa-clipboard" label="{{'dm.Add_to_My_Templates' | translate}}"
                            [ngClass]="(isMyTemplateEnabled)?'':'wrapper'" (click)="addToMyTemplate()"></button>
                        <button pButton pRipple icon="fal fa-clone" label="{{'pm.Duplicate_to_Clients' | translate}}"
                            [ngClass]="(isDuplicateEnabled)?'':'wrapper'" (click)="duplicateTemplate()"></button>
                        <button pButton pRipple icon="fal fa-trash-alt" label="{{'dm.Delete_Template' | translate}}"
                            [ngClass]="(showSaveButtons)?'':'wrapper'" (click)="deleteClientTemplateNames()"></button>
                    </span>
                </div>
            </div>
            <div class="p-grid">
                <!-- Dialog Left Grid  -->
                <div class="p-col-4 templateGrid dialoglefttree">
                    <ejs-treegrid #treetempleteGrid [dataSource]='settingsData' idMapping='UniqueID'
                        parentIdMapping='ParentID' [treeColumnIndex]='1' [selectionSettings]='selectOptions'
                        gridLines='None' enableHover='true' rowHeight='25' hideColumns
                        (rowSelected)='rowTempleteSelected($event,true)'
                        (rowDeselected)='rowTempleteSelected($event,false)' (queryCellInfo)="queryCellInfo($event)"
                        (actionComplete)="templeteActionComplete($event)">
                        <e-columns>
                            <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true' textAlign='Right'
                                width=70 [visible]="false"> </e-column>
                            <e-column field='TemplateName' headerText='' textAlign='Left' width=200></e-column>
                        </e-columns>
                    </ejs-treegrid>
                </div>
                <!-- Dialog Right Grid  -->
                <div class="p-col-8 p-mt-2">
                    <div *ngIf="!showDuplicate" [ngStyle]="{'visibility':showRightContent? 'visible' : 'hidden' }">
                        <div>
                            <div>
                                <label for="Template Name" class="ic-red">{{'dm.Template_Name' | translate}}</label>
                                <span class="pull-right" *ngIf="showSaveButtons">
                                    <button pButton type="button" label="Save" class="marin-right-2 p-mb-2 blue-btn"
                                        (click)="saveData()"></button>
                                </span>
                            </div>
                            <div class="p-my-2">
                                <input type="text" pInputText autocomplete="off"
                                    [(ngModel)]="selectedSettings['TemplateName']" name="itemtemplateName"
                                    id="itemtemplateName" #itemtemplateName="ngModel" (focus)="$event.target.select()"
                                    [disabled]="selectedSettings['readOnly']" class="inputText width-100p">
                            </div>
                        </div>
                        <div [ngStyle]="{'visibility':isShowGrid? 'visible' : 'hidden' }">
                            <div style="margin-top:20px;">
                                <label for="Folder Structure" class="ic-red">{{'dm.Folder_Structure' |
                                    translate}}</label>
                            </div>
                            <div class="rightSideGrid p-mt-2 dialoglefttree">
                                <ejs-treegrid #treeSettingtempleteGrid [dataSource]='settingsRightSideData'
                                    idMapping='UniqueID' parentIdMapping='ParentID' [treeColumnIndex]='1'
                                    gridLines='None' enableHover='true' rowHeight='25' [pageSettings]='pageSettings'
                                    [editSettings]='editSettings' [toolbar]='dialogToolbar'
                                    (queryCellInfo)="queryCellInfo($event)"
                                    (actionComplete)="templeteActionComplete($event)"
                                    (toolbarClick)='toolbarClick($event)' (actionBegin)="templateactionBegin($event)"
                                    (rowSelected)='dialogRowSelected($event)' locale='en-US'
                                    (rowDeselected)='dialogRowSelected($event)'
                                    [contextMenuItems]='contextMenuDialogItems'
                                    (contextMenuOpen)='settingContextMenuOpen($event)'
                                    (contextMenuClick)='contextMenuClick($event)'>
                                    <e-columns>
                                        <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true'
                                            textAlign='Right' width='7' [visible]="false"> </e-column>
                                        <e-column field='FolderName' headerText="Folder Name" textAlign='Left'
                                            width='70'></e-column>
                                        <!-- <e-column field='isGlobal' headerText='Action' width='15' textAlign='Left'></e-column> -->
                                    </e-columns>
                                </ejs-treegrid>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showDuplicate">
                        <div class="p-col-12">
                            <label class="p-pull-left label-text">{{'pm.Duplicate_Client' | translate}}</label>
                        </div>
                        <div class="p-col-12">
                            <p-table [value]="tasksDataSource" [totalRecords]="tasksDataSource.length"
                                [(selection)]="selectedClientsItems" [rowHover]="true" [responsive]="true"
                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                dataKey="ClientID" sortField="ClientName" [sortOrder]="1" [scrollable]="true"
                                (onFilter)="onDuplicateClientFilter($event)" [scrollHeight]="'calc(100vh - 310px)'"
                                [globalFilterFields]="['ClientName','CustomerTypeDescription','GroupDescriptionIDArrayDesc']"
                                #dttask>
                                <!-- Filter -->
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchTaskValue pInputText type="text"
                                                (input)="dttask.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dttask.reset();clearTaskSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <span>
                                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                                text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                                styleClass="p-ml-2">
                                            </p-message>
                                        </span>
                                        <span class="pull-right">
                                            <button pButton icon="fas fa-check"
                                                [disabled]="selectedClientsItems.length == 0"
                                                label="{{'Create Folders' | translate}}"
                                                (click)="bulkSave(true)"></button>
                                            <button pButton type="button" icon="fal fa-times"
                                                label="{{'close' | translate}}" class="p-mx-2"
                                                (click)="showDuplicate = false"></button>
                                        </span>
                                    </div>
                                </ng-template>
                                <!-- Table Header -->
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-3p p-text-center">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th class="width-25p p-text-left">{{'client.name' | translate}}</th>
                                        <th class="width-15p p-text-left">{{'client.type' | translate}}</th>
                                        <th class="width-15p p-text-left">{{'group' | translate}}</th>
                                    </tr>
                                </ng-template>
                                <!-- Rows -->
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td class="width-3p p-text-center">
                                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                        </td>
                                        <td class="width-25p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{rowData.ClientName}}" showDelay="800"
                                            tooltipStyleClass="tooltip-no-wrap">{{rowData.ClientName}}</td>
                                        <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{rowData.CustomerTypeDescription}}"
                                            showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                            {{rowData.CustomerTypeDescription}} </td>
                                        <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{rowData.GroupDescriptionIDArrayDesc}}"
                                            showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                            {{rowData.GroupDescriptionIDArrayDesc}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'dm.Group_Permissions' | translate}}">
            <form class="form-horizontal user-form" role="form" [formGroup]="myGroupForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 pull-right p-text-right">
                        <button pButton type="button" label="{{'save_button' | translate}}" icon="fal fa-check"
                            styleClass="p-button-sm" class="p-pr-2" (click)="saveGroupData(myGroupForm.value)"
                            [disabled]="!(myGroupForm.dirty || isUserSettingsTouched)"></button>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12">
                        <h4 class="label-text ic-red">{{'dm.Default_Folder_Permissions' |
                            translate}}</h4>
                    </div>
                    <div class="p-datatable-wrapper">
                        <p-table styleClass="p-datatable-sm p-datatable-responsive-demo" [rowHover]="true"
                            #tableProfileDefault [value]="loadGroupPermissionsObj" [scrollable]="true"
                            [responsive]="true" [scrollHeight]="'calc(56vh - 100px)'">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="ic-red width-30p">Title</th>
                                    <th class="p-text-center width-20p">Full Access</th>
                                    <th class="p-text-center width-20p">Read <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                                            pTooltip="{{'dm.Allows_users_to_view_files_only' | translate}}"></i></th>
                                    <th class="p-text-center width-20p">Create <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_create_and_edit_folders' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                    <th class="p-text-center width-20p">Update <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_create_and_edit_folders' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                    <th class="p-text-center width-20p">Delete <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_delete_files_and_folders' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                    <th class="p-text-center width-20p">Download <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_download_files' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                    <th class="p-text-center width-20p">Upload <i
                                            class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                            tooltipPosition="top"
                                            pTooltip="{{'dm.Allows_users_to_upload_files' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"></i></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td class="width-30p p-text-truncate p-text-nowrap" pTooltip="{{ rowData.Title }}"
                                        tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                                        {{ rowData.Title }}
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isFullAccess"
                                            [ngModelOptions]="{standalone: true}"
                                            (onChange)="onFullAccessClick($event, rowData, 'default')">
                                        </p-checkbox>
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isAllowReadDMSDefault"
                                            (onChange)="onCheckAllowClick(rowData, 'default')"
                                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isAllowCreateDMSDefault"
                                            (onChange)="onCheckAllowClick(rowData, 'default')"
                                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isAllowUpdateDMSDefault"
                                            (onChange)="onCheckAllowClick(rowData, 'default')"
                                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isAllowDeleteDMSDefault"
                                            (onChange)="onCheckAllowClick(rowData, 'default')"
                                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isAllowDownloadDMSDefault"
                                            (onChange)="onCheckAllowClick(rowData, 'default')"
                                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    </td>
                                    <td class="width-20p p-text-center">
                                        <p-checkbox binary="true" [(ngModel)]="rowData.isAllowUploadDMSDefault"
                                            (onChange)="onCheckAllowClick(rowData, 'default')"
                                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">
                                        <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12">
                        <h4 class="label-text ic-red">Default {{'client-portal-permissions' | translate}}</h4>
                    </div>
                    <div class="p-col-12">
                        <p-checkbox binary="true" formControlName="ShowInPortal"
                            label="{{'dm.Show_Folder_In_Client_Portal' | translate}}"
                            (onChange)="varifyShowPortalDefaultSettings(true);">
                        </p-checkbox>
                    </div>
                    <div class="p-col-12">
                        <p-checkbox binary="true" formControlName="isUploadAllowedPortal"
                            label="{{'AllowClientUpload' | translate}}"
                            (onChange)="varifyShowPortalDefaultSettings(false);"
                            [disabled]="myGroupForm.get('ShowInPortal').value == false">
                        </p-checkbox>
                    </div>
                </div>
            </form>
        </p-tabPanel>
        <p-tabPanel header="{{'dm.Email_Templates' | translate}}">
            <div class="p-grid">
                <div class="p-col-12 pull-right p-text-right">
                    <button pButton type="button" label="{{'save_button' | translate}}" icon="fal fa-check"
                        styleClass="p-button-sm" class="p-pr-2" (click)="saveEmailTemplate()"
                        [disabled]="emailTemplateObj['EmailTemplateID'] == null || !isUserSettingsTouched"></button>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-4 templateGrid client_table dialoglefttree p-mt-2">
                    <p-table [columns]="emailCols" [value]="emailtemplateList" [totalRecords]="emailtemplateList.length"
                        dataKey="EmailTemplateID" selectionMode="single" [(selection)]="selectedObjs"
                        sortField="TemplateName" [sortOrder]="1" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 220px)'" #dtemailtask>
                        <!-- Table Header -->
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="p-text-center bg-blue">{{'dm.Email_Templates' | translate}}</th>
                            </tr>
                        </ng-template>
                        <!-- Rows -->
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <td class="p-text-left cursor-hand" (click)="onEmailRowSelect(rowData)">
                                    {{rowData.TemplateName}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="p-col-8" *ngIf="editorValue">
                    <div class="p-col-12">
                        <label for="Email Subject" class="ic-red p-mb-2">{{'dm.Email_Subject' | translate}}</label>
                        <div class="p-mt-2">
                            <input type="text" autocomplete="off" [(ngModel)]="emailTemplateObj['EmailSubject']"
                                name="itememailtemplateName" id="itememailtemplateName" #itemtemplateName="ngModel"
                                (focus)="$event.target.select()" class="inputText width-100p" pInputText>
                        </div>
                    </div>
                    <div class="p-my-2 p-col-12">
                        <ejs-richtexteditor id='customRTE' #customRTE [(value)]="editorValue"
                            [insertImageSettings]='imageSettings' (change)="isUserSettingsTouched = true"
                            [toolbarSettings]='tools'>
                        </ejs-richtexteditor>
                        <ejs-dialog #Dialog id="rteDialog" [buttons]='dlgButtons' (overlayClick)="dialogOverlay()"
                            [header]='header' [visible]='false' [showCloseIcon]='false' [target]='target'
                            (created)="dialogCreate()" [isModal]='true'>
                            <ng-template #content>
                                <div id="rteSpecial_char">
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'client.name' | translate}}"> %ClientName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'client.contact_name' | translate}}"> %ContactName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'engagement.name' | translate}}">
                                        %EngagementFolder% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Folder_Name' | translate}}"> %FolderName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top" pTooltip="{{'date' | translate}}">
                                        %Date% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Your_Firm_Name' | translate}}">
                                        %YourFirmName% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Link_To_Portal' | translate}}">
                                        %LinkToPortal% </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'file_name' | translate}}"> %FileName%
                                    </div>
                                    <div class="char_block" tooltipPosition="top"
                                        pTooltip="{{'dm.Confidentiality_Notice' | translate}}">
                                        %ConfidentialityNotice% </div>
                                </div>
                            </ng-template>
                        </ejs-dialog>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'mass_invite' | translate}}">
            <div class="p-grid p-mt-3">
                <div class="p-col-8">
                    <div class="card p-pt-0">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-6">
                                <label for="From" class="required-field label-text">{{'From' | translate}}</label>
                                <input type="text" required readonly pInputText
                                    [(ngModel)]="inviteMultipleClients['from']">
                            </div>
                            <div class="p-field p-col-6">
                                <label for="To" class="required-field label-text">{{'To' | translate}}</label>
                                <p-multiSelect [(ngModel)]="selectedClientsInvite" [options]="clientListMultiSelect"
                                    placeholder="{{ 'Select Clients' | translate }}" scrollHeight="350px">
                                </p-multiSelect>
                            </div>
                            <div class="p-field p-col-12">
                                <label for="Subject" class="required-field label-text">{{'Subject' | translate}}</label>
                                <input type="text" pInputText [(ngModel)]="inviteMultipleClients['subject']">
                            </div>
                            <div class="p-field p-col-12">
                                <ejs-richtexteditor id='massInviteRTE' #massInviteRTE [(value)]="massInviteEditorValue"
                                    [toolbarSettings]='emailTools' [enabled]='true'> </ejs-richtexteditor>
                                <ng-template #valueTemplate>&nbsp;</ng-template>
                            </div>
                        </div>
                        <ng-template pTemplate="footer">
                            <button type="button" pButton pRipple icon="fal fa-check" (click)="sendInviteClient(true)"
                                [disabled]="selectedClientsInvite.length == 0" label="{{'Send' | translate}}"
                                class="p-ml-3"></button>
                        </ng-template>
                    </div>

                </div>
            </div>


        </p-tabPanel>
        <p-tabPanel header="{{'imagineshare-integration' | translate}}">
            <app-mangoshare-integration [isFromDMS]="true"></app-mangoshare-integration>
        </p-tabPanel>
    </p-tabView>
</p-sidebar>
<p-dialog header="{{defaultPermisssionObj['FolderName']}}" [(visible)]="settingsDisplay" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="1" [draggable]="true"
    [resizable]="false" (onHide)="settingsDisplay=isUserSettingsTouched=false">
    <div class="card p-pt-0">
        <div class="header-color text-nowrap col-sm-9">
            <h4 class="label-text ic-red">{{'dm.Folder_Access_Permissions' | translate}}</h4>
        </div>
        <div class="p-grid p-mb-3">
            <div class="p-datatable-wrapper ">
                <p-table styleClass="p-datatable-sm p-datatable-responsive-demo" [rowHover]="true" #tableProfile
                    [value]="dmsStaffRoles && dmsParentInt && dmsStaffRoles[dmsParentInt] ? dmsStaffRoles[dmsParentInt] : [{}]"
                    [scrollable]="true" [responsive]="true" [scrollHeight]="'calc(56vh - 100px)'">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="ic-red width-30p">Title</th>
                            <th class="p-text-center width-20p">Full Access</th>
                            <th class="p-text-center width-20p">Read <i
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                    tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
                                    pTooltip="{{'dm.Allows_users_to_view_files_only' | translate}}"></i></th>
                            <th class="p-text-center width-20p">Create <i
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                    tooltipPosition="top"
                                    pTooltip="{{'dm.Allows_users_to_create_and_edit_folders' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"></i></th>
                            <th class="p-text-center width-20p">Update <i
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                    tooltipPosition="top"
                                    pTooltip="{{'dm.Allows_users_to_create_and_edit_folders' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"></i></th>
                            <th class="p-text-center width-20p">Delete <i
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                    tooltipPosition="top"
                                    pTooltip="{{'dm.Allows_users_delete_files_and_folders' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"></i></th>
                            <th class="p-text-center width-20p">Download <i
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                    tooltipPosition="top" pTooltip="{{'dm.Allows_users_to_download_files' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"></i></th>
                            <th class="p-text-center width-20p">Upload <i
                                    class="fa fa-question-circle ic-blue question-tooltip-icon cursor-hand"
                                    tooltipPosition="top" pTooltip="{{'dm.Allows_users_to_upload_files' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"></i></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td class="width-30p p-text-truncate p-text-nowrap" pTooltip="{{ rowData.Title }}"
                                tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                                {{ rowData.Title }}
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isFullAccess"
                                    (onChange)="onFullAccessClick($event, rowData)"></p-checkbox>
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isAllowRead"
                                    (onChange)="onCheckAllowClick(rowData)" [ngModelOptions]="{standalone: true}">
                                </p-checkbox>
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isAllowCreate"
                                    (onChange)="onCheckAllowClick(rowData)" [ngModelOptions]="{standalone: true}">
                                </p-checkbox>
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isAllowUpdate"
                                    (onChange)="onCheckAllowClick(rowData)" [ngModelOptions]="{standalone: true}">
                                </p-checkbox>
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isAllowDelete"
                                    (onChange)="onCheckAllowClick(rowData)" [ngModelOptions]="{standalone: true}">
                                </p-checkbox>
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isAllowDownload"
                                    (onChange)="onCheckAllowClick(rowData)" [ngModelOptions]="{standalone: true}">
                                </p-checkbox>
                            </td>
                            <td class="width-20p p-text-center">
                                <p-checkbox binary="true" [(ngModel)]="rowData.isAllowUpload"
                                    (onChange)="onCheckAllowClick(rowData)" [ngModelOptions]="{standalone: true}">
                                </p-checkbox>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7">
                                <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                    styleClass="p-px-2"></p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="header-color text-nowrap col-sm-9">
            <h4 class="label-text ic-red">{{'client-portal-permissions' | translate}}</h4>
        </div>
        <div class="p-grid p-m-2 p-pt-1">
            <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['ShowInPortal']"
                label="{{'dm.Show_Folder_In_Client_Portal' | translate}}" (onChange)="varifyShowPortalSettings(true);">
            </p-checkbox>
        </div>
        <div class="p-grid p-m-2 p-pt-4">
            <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['isUploadAllowedPortal']"
                label="{{'AllowClientUpload' | translate}}" (onChange)="varifyShowPortalSettings(false);"
                [disabled]="defaultPermisssionObj['ShowInPortal'] == false">
            </p-checkbox>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="saveSettingsData(true)"
                [disabled]="!isUserSettingsTouched" label="Save to Subfolders"
                *ngIf="defaultPermisssionObj['LineType'] === 'P'"></button>
            <button type="button" pButton pRipple icon="fal fa-check" (click)="saveSettingsData(false)"
                [disabled]="!isUserSettingsTouched" label="Save"></button>
            <button type="button" pButton pRipple icon="fal fa-times" label="{{'cancel_button' | translate}}"
                (click)="settingsDisplay=isUserSettingsTouched=false;onCancelPermissions()"></button>
        </ng-template>
    </div>
</p-dialog>
<p-sidebar [(visible)]="isFilePreview" [position]="'right'" [style]="{width:'75%'}" [dismissible]="true"
    [showCloseIcon]="true" (onHide)="closePreviewDialog()" [closeOnEscape]="false">
    <div class="card p-px-0">
        <div #preiewviewer class="preiewviewer" [ngStyle]="{'visibility':isPreviewViewerShow? 'visible' : 'hidden' }">
        </div>
    </div>
</p-sidebar>
<p-dialog header="{{'dm.Invite_Client' | translate}}" [(visible)]="isInviteClient" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="closeClient()">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label for="From" class="required-field label-text">{{'From' | translate}}</label>
                <input [(ngModel)]="inviteClientEmailTemplate['from']" type="text" required readonly pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="To" class="required-field label-text">{{'To' | translate}}</label>
                <!-- <input type="text" [(ngModel)]="inviteClientEmailTemplate['to']" required pInputText> -->
                <p-multiSelect [(ngModel)]="selectedClientsEmail" [options]="clientsMultipleEmails"
                    placeholder="{{ 'Select Emails' | translate }}" scrollHeight="350px"></p-multiSelect>
            </div>
            <div class="p-field p-col-12">
                <label for="Subject" class="required-field label-text">{{'Subject' | translate}}</label>
                <input type="text" [(ngModel)]="inviteClientEmailTemplate['subject']" pInputText>
            </div>
            <div class="p-field p-col-12">
                <ejs-richtexteditor id='inviteRTE' #inviteRTE [(value)]="inviteEditorValue"
                    [toolbarSettings]='emailTools' [enabled]='true'> </ejs-richtexteditor>
                <ng-template #valueTemplate>&nbsp;</ng-template>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="sendInviteClient()"
                [disabled]="selectedClientsEmail.length == 0" label="{{'Send' | translate}}"></button>
        </ng-template>
    </div>
</p-dialog>

<p-dialog header="{{'dm.New_Message' | translate}}" [(visible)]="isEmailDialog" [modal]="true" [style]="{width: '55vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="isEmailDialog=isUserSettingsTouched=false">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label for="From" class="required-field label-text">{{'From' | translate}}</label>
                <input [(ngModel)]="emailTemplate['from']" type="text" required (ngModelChange)="validateEmailForm()"
                    pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="To" class="required-field label-text">{{'To' | translate}}</label>
                <input type="text" [(ngModel)]="emailTemplate['to']" (ngModelChange)="validateEmailForm()" required
                    pInputText>
            </div>
            <div class="p-field p-col-12">
                <label for="Subject" class="required-field label-text">{{'Subject' | translate}}</label>
                <input type="text" [(ngModel)]="emailTemplate['subject']" required (ngModelChange)="validateEmailForm()"
                    pInputText>
            </div>
            <div class="p-field p-col-6">
                <label for="Subject" class="label-text">{{'Attachment' | translate}}</label>
                <div>
                    <span class="ic-red">{{emailTemplate['fileName']}}</span>
                </div>
            </div>
            <div class="p-field p-col-6" *ngIf="emailTemplate['type'] === 'PDF'">
                <p-checkbox [(ngModel)]="filePasswordchecked" (click)="showPassword()" binary="true"
                    label="{{'dm.Password_Protect_and_Encrypt' | translate}}">
                </p-checkbox>
            </div>
            <div class="p-field p-col-12">
                <ejs-richtexteditor id='emailRTE' #emailRTE [(value)]="emailEditorValue" (change)="validateEmailForm()"
                    [toolbarSettings]='emailTools' [enabled]='true'>
                </ejs-richtexteditor>
                <ng-template #valueTemplate>&nbsp;</ng-template>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!isUserSettingsTouched"
                (click)="sendEmailWithAttachment()" label="{{'Send' | translate}}"></button>
        </ng-template>
    </div>
</p-dialog>

<p-sidebar header="{{'dm.Add_Template' | translate}}" [(visible)]="isAddTemplate" [position]="'right'"
    [blockScroll]="true" [style]="{width:'50%',height:'56vh'}" styleClass="p-sidebar-md" [showCloseIcon]="false"
    [closeOnEscape]="false" [dismissible]="false">
    <div class="card" style="min-height: 50vh">
        <div class="p-fluid p-formgrid p-grid" *ngIf="isApiReady">
            <div class="p-field p-col-6 dialoglefttree">
                <label for="Folder Structure" class="label-text">Templates</label>
                <ejs-treegrid #treeAddTemplateGrid [dataSource]='globalClientTemplateArr' idMapping='UniqueID'
                    [selectionSettings]='selectOptions' parentIdMapping='ParentID' [treeColumnIndex]='1'
                    gridLines='None' enableHover='true' rowHeight='25'
                    (rowSelected)='rowAddTemplateSelected($event,true)' (queryCellInfo)="queryCellInfo($event)"
                    (rowDeselected)='rowAddTemplateSelected($event,false)'>
                    <e-columns>
                        <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true' textAlign='Right' width=70
                            [visible]="false"> </e-column>
                        <e-column field='TemplateName' headerText='' textAlign='Left' width=200></e-column>
                    </e-columns>
                </ejs-treegrid>
            </div>
            <div class="p-field p-col-6 dialoglefttree"
                [ngStyle]="{'visibility':isTemplateShowGrid? 'visible' : 'hidden' }">
                <label for="Folder Structure" class="label-text">{{'dm.Folder_Structure' | translate}}</label>
                <ejs-treegrid [dataSource]='settingsRightSideData' idMapping='UniqueID' parentIdMapping='ParentID'
                    [treeColumnIndex]='1' gridLines='None' enableHover='true' rowHeight='25'
                    [pageSettings]='pageSettings' (queryCellInfo)="queryCellInfo($event)">
                    <e-columns>
                        <e-column field='UniqueID' headerText='UniqueID' isPrimaryKey='true' textAlign='Right' width='7'
                            [visible]="false"> </e-column>
                        <e-column field='FolderName' headerText='FolderName' textAlign='Left' width='70'></e-column>
                    </e-columns>
                </ejs-treegrid>
            </div>
        </div>
    </div>
    <div class="pull-right" style="position: absolute; bottom: 1px; right: 5px">
        <button type="button" pButton pRipple icon="fal fa-times"
            (click)="isAddTemplate=isNewTemplate=isUserSettingsTouched=isTemplateShowGrid=false"
            class="pull-right p-mt-2" label="{{'cancel' | translate}}"></button>
        <button type="button" pButton pRipple icon="fal fa-check" (click)="bulkSave(false)"
            class="pull-right p-mt-2 p-mr-2" [disabled]="!isTemplateShowGrid"
            label="{{'save_button' | translate}}"></button>
    </div>
</p-sidebar>

<p-dialog header="{{'dm.Create_File_Attachment_Password' | translate}}" [(visible)]="isShowPassword" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="isShowPassword=false;filePassword = '';">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12" *ngIf="selectedRow">
                <label for="Subject" class="label-text">{{'Attachment' | translate}}</label>
                <div class="p-col-8 p-pl-0">
                    <span class="ic-red">{{selectedRow['FName']}}</span>
                </div>
            </div>
            <div class="p-field p-col-12">
                <label for="Subject" class="label-text required-field">{{'password' | translate}}</label>
                <input type="text" [(ngModel)]="filePassword" required pInputText>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="savePassword()"
                label="{{'save_button' | translate}}"></button>
            <button type="button" pButton pRipple icon="fal fa-times" label="Clear Password"
                (click)="clearPwd()"></button>
        </ng-template>
    </div>
</p-dialog>

<p-dialog header="Folder - {{defaultPermisssionObj['FolderName']}}" [(visible)]="settingsNotifications" [modal]="true"
    [style]="{width: '55vw'}" [contentStyle]="{'overflow-y': 'visible'}" [maximizable]="false" showEffect="fade"
    [baseZIndex]="10000" [draggable]="true" [resizable]="false" (onHide)="hideNotifications()">
    <div class="card p-pt-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <h4 class="label-text">{{'Client Email Notifications' | translate}}</h4>
                <p-checkbox binary="true" [(ngModel)]="defaultPermisssionObj['isNotifyClientUpload']"
                    label="{{'dm.Notify_Client_by_email' | translate}}" class="ic-red"
                    (onChange)="isUserSettingsTouched = true">
                </p-checkbox>
            </div>
            <div class="p-field p-col-6">
                <h4 class="label-text">{{'Firm Email Notifications' | translate}}</h4>
                <p-multiSelect [options]="sourceList" class="multi-select"
                    (onChange)="onSelectStaffToNotifyChange($event)" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="defaultPermisssionObj['StaffID']" defaultLabel="Select Staff">
                    <ng-template let-engagement pTemplate="item">
                        <div style="width:100%;display:inline-block;vertical-align:middle">{{engagement.label}}
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>
            <br />
            <br />
            <div class="p-field p-col-12 p-mt-3">
                <h4 class="label-text">{{'Email Templates' | translate}}</h4>
                <div class="p-field p-col-6">
                    <label for="Subject" class="label-text">{{'dm.Upload_Notification' | translate}}</label>
                    <div class="p-col-12 p-pl-0">
                        <p-dropdown [options]="uploadList" [(ngModel)]="defaultPermisssionObj['EmailUploadTemplateID']"
                            [disabled]="!defaultPermisssionObj['isNotifyClientUpload'] && defaultPermisssionObj['StaffID']?.length == 0"
                            (onChange)="changeUpload($event,true)"
                            placeholder="{{ 'dms.Select_Email_Template' | translate }}" styleClass="width-100p">
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-field p-col-6">
                    <label for="Subject" class="label-text">{{'dm.Download_Notification' | translate}}</label>
                    <div class="p-col-12 p-pl-0">
                        <p-dropdown [options]="downloadList"
                            [(ngModel)]="defaultPermisssionObj['EmailDownloadTemplateID']"
                            [disabled]="!defaultPermisssionObj['isNotifyClientUpload'] && defaultPermisssionObj['StaffID']?.length == 0"
                            (onChange)="changeUpload($event,false)" styleClass="width-100p"
                            placeholder="{{ 'dms.Select_Email_Template' | translate }}"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="fal fa-check" (click)="saveSettingsData(true)"
                [disabled]="!isUserSettingsTouched || (defaultPermisssionObj['StaffID'].length == 0 && !defaultPermisssionObj['isNotifyClientUpload']) || (!defaultPermisssionObj['EmailUploadTemplateID'] && !defaultPermisssionObj['EmailDownloadTemplateID'])"
                label="Save to Subfolders" *ngIf="defaultPermisssionObj['LineType'] === 'P'"></button>
            <button type="button" pButton pRipple icon="fal fa-check" (click)="saveSettingsData(false)"
                [disabled]="!isUserSettingsTouched || (defaultPermisssionObj['StaffID'].length == 0 && !defaultPermisssionObj['isNotifyClientUpload']) || (!defaultPermisssionObj['EmailUploadTemplateID'] && !defaultPermisssionObj['EmailDownloadTemplateID'])"
                label="{{'save_button' | translate}}"></button>
            <button type="button" pButton pRipple icon="fal fa-times" (click)="hideNotifications()"
                label="{{'cancel' | translate}}"></button>
        </ng-template>
    </div>
</p-dialog>

<p-dialog [(visible)]="showShareDialog" header="{{FName}}" position="top" [modal]="true"
    [style]="{width: '50vw',height:'50vh'}" [maximizable]="false" showEffect="fade" [baseZIndex]="1"
    styleClass="sharedialog" [draggable]="true" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid p-m-2">
        <div class="p-col-12 p-mb-1">
            <div class="p-col-10 ic-blue font-14rem">
                <i class="fad fa-link p-mr-2"></i><span>Get link</span>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-field">
                <div class="p-inputgroup">
                    <input [(ngModel)]="SharePublicURL" type="text" class="width-100p" #userinput pInputText readonly />
                    <button type="button" tooltipPosition="top" pTooltip="Generate new Link" pButton pRipple
                        icon="pi pi-refresh" styleClass="p-button-warn" (click)="generateKey()"></button>
                    <button type="button" tooltipPosition="top" pTooltip="Copy to Clipboard" *ngIf="isSecurityLinkSaved"
                        pButton pRipple icon="pi pi-copy" styleClass="p-ml-2 p-button-warn" [disabled]="!isShareable"
                        (click)="copyClipBoard()"></button>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="access" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{'who-has-access' |
                        translate}}</label>
                    <div class="p-col-6">
                        <p-dropdown [options]="securityLinkOptions" [(ngModel)]="securityLink" styleClass="width-100p">
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-field p-grid" *ngIf="securityLink == 1">
                    <label for="securityq" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{'security-question' |
                        translate}}</label>
                    <div class="p-col-6">
                        <p-dropdown [options]="securityQuestionsList" [(ngModel)]="securityQuestion"
                            styleClass="width-100p" (onChange)="securityAnswer = ''">
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-field p-grid" *ngIf="securityLink == 1">
                    <label for="answer" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                        {{'answer' | translate}}</label>
                    <div class="p-col-6">
                        <input id="from" type="text" [(ngModel)]="securityAnswer" pInputText
                            placeholder="Enter your answer" autocomplete="nope">
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="expDate" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Expiration Date</label>
                    <div class="p-col-6">
                        <p-calendar [(ngModel)]="shareExpiryDate" [minDate]="minDate" [readonlyInput]="true"
                            inputId="min-max" appendTo="body" (onSelect)="validateExpDate()">
                        </p-calendar>
                    </div>
                    <div class="p-col-4 ic-red p-pt-2" *ngIf="dateErrorMsg">
                        {{dateErrorMsg}}
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="Email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{'email' | translate}}</label>
                    <div class="p-col-6">
                        <p-inputSwitch [disabled]="!isEmailShareable && !isValidEmailTemplate"
                            (onChange)="handleSwitchChange($event)" [(ngModel)]="isEmailShareable"></p-inputSwitch>
                    </div>
                </div>
                <div class="p-field p-grid" *ngIf="isEmailShareable">
                    <label for="To" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">To</label>
                    <div class="p-col-6">
                        <div class="p-formgroup-inline"
                            *ngFor="let value of values; let i = index;let first = first ;let last = last;">
                            <div class="p-field width-88p">
                                <p-autoComplete [(ngModel)]="value.value" #name="ngModel" [delay]="700"
                                    name="value{{i}}" [ngModelOptions]="{standalone: true}"
                                    (onSelect)="selectedEmailIds($event);" [suggestions]="filteredEmail"
                                    (completeMethod)="filterEmails($event)"
                                    (onBlur)="checkValidEmailID(value.value.label);"
                                    placeholder="Please enter valid Email Id" autocomplete="nope" field="label"
                                    [dropdown]="true">
                                    <ng-template let-email pTemplate="item">
                                        <div class="p-d-flex p-jc-between">
                                            <div>{{email['label']}}</div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                            <div class="p-field">
                                <i pRipple (click)="removevalue(i);$event.stopPropagation();" *ngIf="!first"
                                    class="fal fa-trash-alt ic-red cursor-hand p-pl-2 p-pt-3" tooltipPosition="top"
                                    pTooltip="{{ 'mgmt-delete' | translate }}"></i>


                                <i pRipple (click)="addvalue();$event.stopPropagation();" *ngIf="last"
                                    class="fas fa-plus ic-red cursor-hand p-pl-2 p-pt-3" tooltipPosition="top"
                                    pTooltip="{{ 'add-recipient' | translate }}"></i>

                                <!-- <button type="button" *ngIf="last" pButton pRipple icon="fas fa-plus"
                                    class="cursor-hand p-ml-2" (click)="addvalue();$event.stopPropagation();"
                                    pTooltip="{{ 'add-recipient' | translate }}" tooltipPosition="top"></button> -->
                            </div>
                        </div>
                        <div class="ic-red" id="hideDiv"> <span *ngIf="errormsg">{{errormsg}}</span></div>
                    </div>
                </div>
                <div class="p-field p-grid" *ngIf="isEmailShareable">
                    <label for="To" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{'email-message' | translate}}</label>
                    <div class="p-col-6">
                        <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"
                            [(ngModel)]="emailBody"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" *ngIf="isEmailShareable" pButton pRipple icon="fal fa-check"
            [disabled]="errormsg !== '' || !isValidEmailTemplate" label="{{'send-email' | translate}}"
            (click)="saveToken(false);sendEmail()"></button>
        <button type="button" pButton pRipple icon="fal fa-check" *ngIf="!isEmailShareable"
            [disabled]="!isValidEmailTemplate" label="{{'create-link' | translate}}"
            (click)="saveToken(true);"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="closeEmailShareDialog()"
            label="{{'cancel' | translate}}"></button>
    </ng-template>
</p-dialog>

<p-sidebar [(visible)]="isReviewCust" position="right" [showCloseIcon]="false" [closeOnEscape]="false"
    [dismissible]="false" [style]="{
    'min-width': '85%',
    height: '95%',
    background: '#8e8f92',
    overflow: 'auto'
  }">
    <div class="card" style="min-height: 91vh">
        <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
            <div class="p-col-12 p-pr-0 manual">
                <h5>Firm Integration</h5>
                <div>
                    <p-table [value]="mapIShareMango" dataKey="ClientID"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        selectionMode="single" rowExpandMode="single" #mapishare>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 5rem"></th>
                                <th>Mango Client Name</th>
                                <th>MangoShare Client Name</th>
                                <th class="p-text-center">Action</th>
                                <th class="p-text-center">Integration Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product let-expanded="expanded">
                            <tr *ngIf="product.isMapped == false">
                                <td class="p-p-0">
                                    <button type="button" pButton pRipple [pRowToggler]="product"
                                        *ngIf="product.children.length > 0"
                                        class="p-button-text p-button-rounded p-button-plain" [icon]="
                      expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                    "></button>
                                </td>
                                <td class="p-p-0" *ngIf="product.ClientName && !product.isMangoEdited">{{
                                    product.ClientName }}</td>
                                <td class="p-p-0" *ngIf="!product.ClientName || product.isMangoEdited" pEditableColumn
                                    class="width-15p p-text-left p-text-nowrap p-text-truncate">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-autoComplete [suggestions]="filteredMangoClients" autocomplete="off"
                                                (completeMethod)="filterMangoClients($event)" [minLength]="1"
                                                [dropdown]="true" placeholder="{{product.ClientName}}" appendTo="body"
                                                (onFocus)="$event.target.select()" class="p-text-truncate" field="label"
                                                placeholder="Select MangoBilling Client"
                                                (onSelect)="handleMangoSelectClick($event, product);">
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template let-item pTemplate="output">
                                            <div class="text-nowrap p-text-nowrap p-text-truncate">
                                                <span *ngIf="product.ClientName">
                                                    {{product.ClientName}}
                                                    <i *ngIf="product.isMangoEdited"
                                                        class="fal fa-pencil p-ml-2 cursor-hand edit-icon ic-red"
                                                        tooltipPosition="top" pTooltip="Click to change Client"></i>

                                                    <i *ngIf="product.isMangoEdited"
                                                        class="fal fa-times p-ml-2 cursor-hand ic-red"
                                                        style="font-size: 20px;" tooltipPosition="top"
                                                        (click)="clearClient(false,product)" pTooltip="Clear"></i>
                                                </span>
                                                <span class="client-link cursor-hand" *ngIf="!product.ClientName">
                                                    Click to Select Mango Client
                                                </span>
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td class="p-p-0" *ngIf="product.iShareName && !product.isIShareEdited">{{
                                    product.iShareName }}</td>
                                <td class="p-p-0" *ngIf="!product.iShareName || product.isIShareEdited" pEditableColumn
                                    class="width-15p p-text-left p-text-nowrap p-text-truncate">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-autoComplete [suggestions]="filteredIShareClients" autocomplete="off"
                                                (completeMethod)="filterIshareClients($event)" [minLength]="1"
                                                [dropdown]="true" appendTo="body" (onFocus)="$event.target.select()"
                                                class="p-text-truncate" field="label"
                                                placeholder="Select MangoShare Client"
                                                (onSelect)="handleIShareSelectClick($event, product);">
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template let-item pTemplate="output">
                                            <div class="text-nowrap p-text-nowrap p-text-truncate">
                                                <span *ngIf="product.iShareName">
                                                    {{product.iShareName}}
                                                    <i *ngIf="product.isIShareEdited"
                                                        class="fal fa-pencil p-ml-2 edit-icon cursor-hand ic-red"
                                                        tooltipPosition="top" pTooltip="Click to change Client"></i>
                                                    <i *ngIf="product.isIShareEdited" style="font-size: 20px;"
                                                        class="fal fa-times p-ml-2 cursor-hand ic-red"
                                                        tooltipPosition="top" pTooltip="Clear"
                                                        (click)="clearClient(true,product)"></i>
                                                </span>
                                                <span class="client-link cursor-hand" *ngIf="!product.iShareName">
                                                    Click to Select IShare Client
                                                </span>
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td class="p-text-center p-p-0" *ngIf="product.children.length > 0">
                                    <p-button label="Clear" styleClass="p-button-link ic-red"
                                        (click)="clearall(product)">
                                    </p-button>
                                </td>
                                <td class="p-text-center p-p-0"
                                    *ngIf="product.children.length == 0 && product.action === 'Update'">
                                    <p-button label="Matched" styleClass="p-button-link ic-green ic-size-16">
                                    </p-button>
                                </td>
                                <td class="p-text-center ic-red imges p-p-0"
                                    *ngIf="product.children.length == 0 && product.action === 'IShare_create'">
                                    Create in MangoShare
                                </td>
                                <td class="p-text-center ic-amex imges p-p-0"
                                    *ngIf="product.children.length == 0 && product.action === 'mango_create'">
                                    Create in MangoBilling
                                </td>
                                <td class="p-text-center ic-amex imges p-p-0">
                                    <i class="fas p-pr-2 ic-size-20 cursor-hand"
                                        [ngClass]="(product.isIntegrated) ? 'fa-check-circle ic-green' : 'fa-times-circle ic-red'"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-product>
                            <tr>
                                <td colspan="4">
                                    <div class="p-p-1">
                                        <p-table [value]="product.children" dataKey="_id"
                                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                            [paginator]="product.children.length > 5" [responsive]="true" [rows]="5"
                                            selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                                            [rowsPerPageOptions]="[10, 25, 50]">
                                            <ng-template pTemplate="header">
                            <tr>
                                <th class="p-text-center">IShare Client Name</th>
                                <th class="p-text-center">Matched</th>
                                <th class="p-text-center">NotMatched</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-order>
                            <tr>
                                <td class="p-text-center">{{ order.name }}</td>
                                <td class="p-text-center">
                                    <p-radioButton name="matched" [value]="order" [(ngModel)]="product.matchedRowValue"
                                        (click)="matchedObj(product, order)"></p-radioButton>
                                </td>
                                <td class="p-text-center">
                                    <p-checkbox [(ngModel)]="order.isNotMatched" binary="true" inputId="binary"
                                        [disabled]="product.matchedRowValue == order"
                                        (click)="notMatchedObj(product, order)">
                                    </p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    No Data
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                </td>
                </tr>
                </ng-template>
                </p-table>
            </div>
            <div class="pull-right" style="position: absolute; bottom: 1px; right: 5px">
                <button type="button" pButton pRipple icon="fal fa-check" class="p-mr-2" (click)="createOrUpdate()"
                    label="{{ 'Sync' | translate }}"></button>
                <button type="button" pButton pRipple icon="fal fa-times" (click)="closeISDialog()"
                    label="{{ 'cancel_button' | translate }}"></button>
            </div>
        </div>
    </div>
    </div>
</p-sidebar>


<p-dialog header="Select Client Folders" [(visible)]="showClientFolders" [modal]="true" [style]="{width: '50vw'}"
    [responsive]="true" [draggable]="true" [resizable]="false" showEffect="fade" [baseZIndex]="1" [maximizable]="false"
    (onHide)="showClientFolders = false">
    <div class="card" style="height: 50vh;">
        <div class="p-field p-col-12">
            <label class="label-text p-mr-4 required-field">{{'client.name' | translate}}</label>
            <p-autoComplete [(ngModel)]="selectedfileClient" [ngModelOptions]="{standalone: true}" [style]="{'width':'85%'}"
                panelStyleClass="client-dropdown-autocomplete" autocomplete="off" [suggestions]="filteredFileClients"
                (completeMethod)="filterFileClients($event)" field="ClientName" (onSelect)="onFileRowSelect($event);"
                ngDefaultControl placeholder="{{'Search-Clients' | translate}}" (onFocus)="$event.target.select()"
                [dropdown]="true" required>
                <ng-template let-client pTemplate="item">
                    <div>{{client.ClientName}}</div>
                </ng-template>
            </p-autoComplete>
        </div>
        <div class="p-field p-col-12">
            <p-tree selectionMode="single" (onNodeSelect)="nodeSelect($event)" [scrollHeight]="'calc(50vh - 100px)'"
                [value]="clientFiles"></p-tree>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="fas fa-check" class="p-pr-2" label="{{ 'Link to Client' | translate }}"
            [disabled]="!dmsParentIDToLink && !parentSelected" (click)="linkToClientDMSFolder()"></button>
        <button pButton type="button" label="Cancel" (click)="showClientFolders = false" class="p-pr-2"></button>
    </ng-template>
</p-dialog>
