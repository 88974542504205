import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService } from '@app/_services';
import Swal from 'sweetalert2';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html'
})
export class BanksComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  @ViewChild('bankMainForm') ngForm;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  bankRowObj: any = {};
  banksDataSource: any = [];
  bankInactive = false;
  isEditbank: boolean = false;
  displayAddBankModal: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService) {

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.Banks + '_' + AppConstants.SearchString);
    this.getBanks();
  }

  ngOnInit(): void {
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings')},
      { label: this.translate.instant('banks'), icon: 'ic-red' }
    ]);
  }

  getBanks() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getBanks().subscribe(function (data: any) {
      _this.mangoAPISrvc.showLoader(false);
      _this.banksDataSource = data;
      _this.tableDataSorce = data.filter((expense) => expense.Inactive == _this.bankInactive);
      _this.loadFilterGrid();
    });
  }


  onStatusChange() {
    this.tableDataSorce = this.banksDataSource.filter((banks) => banks.Inactive == this.bankInactive)
  }

  addBank() {
    this.bankRowObj = {};
    this.isEditbank = false;
    this.bankRowObj.BankName = "";
    this.bankRowObj.DefaultBank = this.bankRowObj.DefaultBank ? this.bankRowObj.DefaultBank : false;
    this.bankRowObj.NoDeposit = this.bankRowObj.NoDeposit ? this.bankRowObj.NoDeposit : false;
    this.bankRowObj.Inactive = this.bankRowObj.Inactive ? this.bankRowObj.Inactive : false;
    this.displayAddBankModal = true;
  }

  saveAndNew(IsEdit) {
    this.saveBanks(!IsEdit, false);
  }

  saveInactive(item) {
    this.bankRowObj = item;
    this.saveBanks(false, true, true);
  }

  saveBanks(isSave, isClose, saveInactive?) {
    const _this = this;
    if(!saveInactive && !this.bankRowObj['Inactive'] && this.bankRowObj.DefaultBank  && this.banksDataSource.filter(item => item.DefaultBank).length == 1){
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), "A default bank is already existing.");
        this.bankRowObj.DefaultBank = false;
    }else{
      if(this.bankRowObj['Inactive'] && this.bankRowObj['DefaultBank']){
        this.bankRowObj['DefaultBank'] = false;
      }
      _this.mangoAPISrvc.showLoader(true);
      if (isSave || _this.bankRowObj['BankID'] === null) {
        _this.mangoAPISrvc.saveBanks(_this.bankRowObj).subscribe(function (response) {
          _this.getBanks();
          _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), AppConstants.createMsg);
        }, (error) => {
          _this.mangoAPISrvc.showLoader(false);
          _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.createErrorMsg);
        });
      } else {
        _this.mangoAPISrvc.updateBanks(_this.bankRowObj.BankID, _this.bankRowObj).subscribe(function (response) {
          _this.getBanks();
          _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), AppConstants.updateMsg);
        }, (error) => {
          _this.mangoAPISrvc.showLoader(false);
          _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.updateErrorMsg);
        });
      }
    }
    
    if (isClose) {
      _this.displayAddBankModal = false;
    } else {
      _this.isEditbank = false;
      _this.bankRowObj = { "BankID": null, "BankName": "", "DefaultBank": false, "NoDeposit": false, "Inactive": false };
    }
  }

  deleteBank(id) {
    const parent = this;
    // We need to do an api and check to see if this bank is in history records
    parent.mangoAPISrvc.getTimeRecordsByServiceCodeId(id).subscribe(function (count: any) {
      if (count > 0) {
        Swal.fire({
          icon: 'warning',
          title: parent.translate.instant('warning'),
          text: parent.translate.instant('bank_cant_be_deleted'),
          showConfirmButton: false,
          timer: 3500
        })
      } else {
        Swal.fire({
          title: parent.translate.instant('confirmation'),
          text: parent.translate.instant('delete_alert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: parent.translate.instant('yes_delete'),
          cancelButtonText: parent.translate.instant('no_cancel')
        }).then((result) => {
          if (result.value) {
            parent.mangoAPISrvc.deleteBanks(id).subscribe(function (data) {
              parent.getBanks();
              parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
            });
          }
        })
      }
    });
  }


  closeBanks(isSave?: any) {
    this.bankRowObj = { "BankID": null, "BankName": "", "DefaultBank": false, "NoDeposit": false, "Inactive": false };
    this.ngForm.form.markAsPristine();
    this.ngForm.form.markAsUntouched();
    this.ngForm.form.updateValueAndValidity();
    this.displayAddBankModal = false;
  }

  editBank(data) {
    this.bankRowObj = Object.assign({}, data);
    this.isEditbank = true;
    this.displayAddBankModal = true;
  }


  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.Banks + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.Banks + '_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

}
