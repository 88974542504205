import { PmWildcardsDialogModule } from './../../shared/components/pm-wildcards-dialog/pm-wildcards-dialog.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { ProjectManagementRoutingModule } from './project-management-routing.module';

import { ProjectManagementComponent } from './project-management.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { DuplicateProjectsComponent } from './duplicate-projects/duplicate-projects.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { MasterTemplateLibraryComponent } from './master-template-library/master-template-library.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { RuleGeneratorComponent } from './rule-generator/rule-generator.component';
import { SettingsComponent } from './settings/settings.component';
import { ProjectListModule } from './project-list/project-list.module';
import { BudgetsModule } from '../../shared/components/budgets/budgets.module';

import { RruleGeneratorModule } from '../../shared/components/rrule-generator/rrule-generator.module';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { CreateProjectFromTemplateModule } from '@app/shared/components/create-project-from-template/create-project-from-template.module';

@NgModule({
  declarations: [ProjectManagementComponent, ClientDetailsComponent, ClientHistoryComponent, CreateTemplateComponent, DuplicateProjectsComponent, MainDashboardComponent, MasterTemplateLibraryComponent, ProjectDetailsComponent, RuleGeneratorComponent, SettingsComponent, ],
  imports: [
    CommonModule,
    AppSharedModule,
    ProjectManagementRoutingModule,
    ProjectListModule,
    AppTranslationModule,
    BudgetsModule,
    ScheduleAllModule, 
    RruleGeneratorModule,
    RecurrenceEditorAllModule,
    CreateProjectFromTemplateModule,
    PmWildcardsDialogModule
  ]
})
export class ProjectManagementModule { }
