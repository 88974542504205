import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { Table } from 'primeng/table';
import { AppConstants } from '@app/_helpers/api-constants';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { SortEvent } from 'primeng/api';
import { environment } from '@environments/environment';
declare let numeral: any;

@Component({
  selector: 'app-stax-view-transaction-history',
  templateUrl: './stax-view-transaction-history.component.html'
})
export class StaxViewTransactionHistoryComponent implements OnInit {
  public companyId;
  rangeDates: any = [];
  public methodTypes: any = [];
  public cards: any = [];
  public refundDialog: any = false;
  public visibleSidebar: any = false;
  public paymentProfileData: any = {};
  public selectedInvoice: any = {};
  public statusTypes: any = [];
  public selectedItems: any = [];
  public fullDataSource: any = [];
  public totlaDataSource: any = [];
  public successTypes: any = [];
  public transationsTypes: any = [];
  public totalsales: any = 0;
  public totalUnAmtApplied: any = 0;
  public searchObj: any = { method: null, type: null, success: null, transactions: null };
  public tableDataSorce = { data: [] };
  public isProcessing: boolean = false;
  public selClient: any = null;
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = '';
  actionStr: any = 'Refund';
  public mangoCompanyData: any;
  public transationMsg: any = '';
  public mangoElement: any = {
    PaymentHeaderData: null,
    detailsRecords: [],
    billingHeaderdata: null
  };
  public selectedInvoices: any = [];
  public isRefundFlow: boolean = false;
  cashReceiptEmailTemplate = null;
  public totalAmtApplied: any = 0;
  public selectedClient: any;
  public clients: any = [];
  public invoices: any = [];
  public emails: any = [];
  public allEmailContacts: any = [];
  public transactionData: any = null;
  intervalid: any;
  public isCrCard: boolean = false;

  constructor(
    protected mangoUtils: mangoUtils,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.successTypes.push(
      { label: 'All', value: null },
      { label: 'Success', value: 1 },
      { label: 'Failure', value: 0 }
    );
    this.transationsTypes.push({ label: 'All', value: null }, { label: 'UnApplied', value: 1 });
    this.methodTypes.push(
      { label: 'All', value: null },
      { label: 'Card', value: 'card' },
      { label: 'Bank', value: 'bank' }
    );
    this.statusTypes.push(
      { label: 'All', value: null },
      { label: 'Charge', value: 'charge' },
      { label: 'Refund', value: 'refund' },
      { label: 'Void', value: 'void' }
    );
    this.searchTextStr = this.encrDecSrvc.getObject(
      AppConstants.staxTransationsRoutePath + '_' + AppConstants.SearchString
    );

    const startDate = moment().subtract(1, 'months').startOf('month').toDate();
    const endDate = moment().toDate();
    this.rangeDates.push(new Date(startDate));
    this.rangeDates.push(new Date(endDate));

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  ngOnInit(): void {
    this.getCompanyInfo();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('accounting') },
      { label: 'View Transactions', icon: 'ic-red' }
    ]);
  }

  calculateTotals(data) {
    if (!data.net_sales) {
      this.totalsales = data.data.reduce(function (a, b) {
        return a + +b.total;
      }, 0);
    } else {
      this.totalsales = data.net_sales;
    }
  }

  async getClientNameByStaxId(companyId, id) {
    const self = this;
    return new Promise(async function (resolve, reject) {
      try {
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc.getClientNameByStaxId(companyId, id).subscribe(
          function (data: any) {
            self.mangoAPISrvc.showLoader(false);
            resolve(data);
          },
          error => {
            reject(error);
            self.mangoAPISrvc.showLoader(false);
          }
        );
      } catch (error) {
        self.mangoAPISrvc.showLoader(false);
        reject(error);
      }
    });
  }

  async requestMoreData(queryStr) {
    const self = this;
    return new Promise(async function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ queryStr: queryStr }, self.mangoCompanyData.CompanyID, 'sth')
          .subscribe(
            function (data: any) {
              resolve(data.data);
            },
            error => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }

  closeVoidDialog() {
    this.selectedInvoices = [];
    this.selectedInvoice = {};
    this.refundDialog = false;
  }

  getTransaction(queryStr) {
    const self = this;
    let tempData: any;
    self.fullDataSource = [];
    self.totlaDataSource = [];
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.performStaxActions({ queryStr: queryStr }, self.mangoCompanyData.CompanyID, 'sth').subscribe(async function (firstdata: any) {
      self.fullDataSource.push(...firstdata.data);
      if (firstdata.last_page > 1) {

        for (let from = 1; from < firstdata.last_page; from++) {
          const newQuery = queryStr + `&page=${from + 1}`;
          //`&from=${200*from}&to=${(200*from)+ 200}&current_page=${from+1}`;
          tempData = await self.requestMoreData(newQuery);
          self.fullDataSource.push(...tempData);
        }
      }
      self.fullDataSource.map(async function (obj) {
        obj['ClientName'] = "";
        obj['isError'] = false;
        obj['errorMessage'] = null;
        obj['isShow'] = true;
        obj['isTransationShow'] = true;
        obj['isHostedPayment'] = false;
        obj['HostedPaymentMemo'] = "-";
        if (obj.payment_method && Object.keys(obj.payment_method).length > 0 && obj.payment_method.person_name) {
          obj['userName'] = obj.payment_method.person_name;
        } else {
          obj['userName'] = obj['customer']['firstname'] + ' ' + obj['customer']['lastname'];
        }
        obj['bankType'] = obj['payment_method']['bank_type'];
        obj['refundAmount'] = obj.total;
        if (obj['meta'] && obj.meta.otherField1) {
          const pos = obj.meta.otherField1.indexOf("ClientName :") + 12;
          const newStr = obj.meta.otherField1.substring(pos);
          const clientstrPos = newStr.indexOf(" |");
          if (clientstrPos > 0) {
            obj['ClientName'] = newStr.substring(0, clientstrPos);
          } else {
            obj['ClientName'] = newStr;
          }
        }
        if (obj['meta'] && obj.meta.otherField2) {
          obj['isHostedPayment'] = obj.meta.otherField2.toString().indexOf("Hosted Payment |") > -1;
          if (obj['isHostedPayment'] == true) {
            const responseStr = await self.getClientNameByStaxId(self.companyId, obj.id);
            if (responseStr) {
              obj['ClientName'] = responseStr;
              obj['isHostedPayment'] = false;
            }
            obj['HostedPaymentMemo'] = obj.meta.otherField2.substring(16, obj.meta.otherField2.length).trim()
          }
        }
        // handling Errors
        try {
          if (obj.success == false) {
            obj['isError'] = true;
            obj['errorMessage'] = obj.message;
          }
          else if (obj.success == false && obj.payment_method.card_type) {
            obj['isError'] = true;
            obj['errorMessage'] = obj.message;
          }
          else if (obj['isError'] == false && obj.serverErrorMsg) {
            obj['isError'] = true;
            obj['errorMessage'] = obj.serverErrorMsg;
          }
        } catch (error) {
          console.log(error)
        }
        return obj;
      });
      //self.tableDataSorce = data;
      self.totlaDataSource.push(...self.fullDataSource);
      self.calculateTotals(firstdata);
      self.mangoAPISrvc.showLoader(false);
    }, (error) => {
      self.mangoAPISrvc.showLoader(false);
      self.mangoAPISrvc.notify('error', 'Error', 'Error while Expanding.');
    });
  }

  filterData(type) {
    const self = this;
    if (type == 'success') {
      if (this.searchObj.success == 0) {
        self.fullDataSource.map(function (obj) {
          obj['isShow'] = obj['isError'];
          return obj;
        });
        this.totalsales = self.fullDataSource
          .filter(code => code.isShow == true)
          .reduce(function (a, b) {
            return a + +b.total;
          }, 0);
      } else if (this.searchObj.success == 1) {
        self.fullDataSource.map(function (obj) {
          obj['isShow'] = !obj['isError'];
          return obj;
        });
        this.totalsales = self.fullDataSource
          .filter(code => code.isShow == false)
          .reduce(function (a, b) {
            return a + +b.total;
          }, 0);
      } else {
        self.fullDataSource.map(function (obj) {
          obj['isShow'] = true;
          return obj;
        });
        this.totalsales = self.fullDataSource.reduce(function (a, b) {
          return a + +b.total;
        }, 0);
      }
    } else if (type == 'Transactions') {
      if (this.searchObj.transactions == 1) {
        self.fullDataSource.map(function (obj) {
          obj['isTransationShow'] = obj.ClientName == '  -- ';
          return obj;
        });
        this.totalsales = self.fullDataSource
          .filter(code => code.isTransationShow == true)
          .reduce(function (a, b) {
            return a + +b.total;
          }, 0);
      } else {
        self.fullDataSource.map(function (obj) {
          obj['isTransationShow'] = true;
          return obj;
        });
        this.totalsales = self.fullDataSource
          .filter(code => code.isTransationShow == true)
          .reduce(function (a, b) {
            return a + +b.total;
          }, 0);
      }
    }
  }

  getCompanyInfo() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe(response => {
      parent.mangoCompanyData = response[0];
      parent.prepareQueryString(false);
      //parent.callFilter();
    });
  }

  prepareQueryString(isRefreshRequired) {
    const format = "YYYY-MM-DD HH:mm:ss";
    let queryStr = `?order=DESC&`;
    if (this.searchObj.method) {
      queryStr += `method=${this.searchObj.method}&`;
    }
    if (this.searchObj.type) {
      queryStr += `type=${this.searchObj.type}&`;
    }

    if (isRefreshRequired) {
      this.searchObj.success = null;
    }
    // if (this.searchObj.success == 0 || this.searchObj.success == 1) {
    //   queryStr += `success=${this.searchObj.success}&`
    // }

    if (this.rangeDates.length > 0 && this.rangeDates[0]) {
      queryStr += `startDate=${moment(this.rangeDates[0]).startOf('day').format(format)}&`;
    }

    if (this.rangeDates.length > 1 && this.rangeDates[1]) {
      queryStr += `endDate=${moment(this.rangeDates[1]).endOf('day').format(format)}&`;
    } else {
      queryStr += `endDate=${moment(this.rangeDates[0]).endOf('day').format(format)}&`;
    }

    queryStr += `resourceType=transaction&per_page=200`;
    this.getTransaction(queryStr);
  }

  customSort(event: SortEvent) {
    this.mangoUtils.tableColumnsSort(event);
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      AppConstants.staxTransationsRoutePath + '_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.Notes + '_' + AppConstants.SearchString, '');
  }

  callFilter() {
    const self = this;
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      this.searchValue.nativeElement.value = this.searchTextStr || '';
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
      }
      this.filteredItemsSize = -1;
    }, 100);
  }

  refundItem(rowData) {
    const self = this;
    const startTime = moment(rowData.created_at);
    const end = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
    const noOfDays = end.diff(startTime, 'days')
    self.actionStr = "Refund";
    if (rowData.method == 'bank' && noOfDays <= 10) {
      Swal.fire({
        icon: 'info',
        title: 'Info!',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'OK',
        text: `ACH transactions can only be refunded after10 days.  This transaction is ${noOfDays} day(s) old`
      });
      return false;
    }
    self.isRefundFlow = true;
    self.mangoAPISrvc.showLoader(true);
    // var duration = moment.duration(end.diff(startTime));
    // var hours = parseInt(duration.asHours().toString());
    // var minutes = parseInt(duration.asMinutes().toString()) - hours * 60;
    // self.transationMsg = 'Transaction was completed ' + hours + ' hour and ' + minutes + ' minutes ago.'
    self.selectedInvoice = rowData;
    let searchId = rowData.invoice_id ? rowData.invoice_id : rowData.id;
    if (rowData.meta.is_autopay) {
      searchId = rowData.id;
    }
    self.mangoAPISrvc.getPaymentHeaderAndDetailsByStaxID(searchId).subscribe(
      function (data: any) {
        self.refundDialog = true;
        self.mangoElement = data;
        self.selectedInvoice.refundAmount = data.detailsRecords.length == 0 ? rowData.total : 0;
        self.mangoAPISrvc.showLoader(false);
      },
      error => {
        self.refundDialog = true;
        self.mangoAPISrvc.showLoader(false);
        self.mangoAPISrvc.notify('error', 'Error', 'Error while fetching.');
      }
    );
  }

  async voidFund(rowData) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.isRefundFlow = false;
    self.selectedInvoice = rowData;
    self.actionStr = "Void";
    const searchId = rowData.invoice_id ? rowData.invoice_id : rowData.id;
    self.mangoAPISrvc.getPaymentHeaderAndDetailsByStaxID(searchId).subscribe(function (data: any) {
      self.refundDialog = true;
      self.mangoElement = data;
      self.selectedInvoice.refundAmount = rowData.total;
      self.selectedInvoices = self.mangoElement.detailsRecords;
      self.mangoAPISrvc.showLoader(false);
    }, (error) => {
      self.refundDialog = true;
      self.mangoAPISrvc.showLoader(false);
      self.mangoAPISrvc.notify('error', 'Error', 'Error while fetching.');
    });
  }

  CheckInvoice() {
    this.selectedInvoice.refundAmount = this.selectedInvoices.reduce(function (a, b) {
      return a + +numeral(b['AppliedAmount']).value();
    }, 0);
  }

  async updateMangoElements() {
    //this.refundDialog = false;
    const self = this;
    self.mangoAPISrvc.showLoader(true);

    // if (!self.mangoElement.PaymentHeaderData) {
    //   self.mangoAPISrvc.showLoader(false);
    //   self.mangoAPISrvc.notify('error', 'Error', 'Problem.  Unable to Update Mango Transactions.');
    //   return false;
    // }

    if (self.isRefundFlow) {
      const staxRefundRes = await self.reverseStaxFund(self.selectedInvoice);
    } else {
      const staxRefundRes = await self.staxVoid(self.selectedInvoice);
    }
    if (self.mangoElement.PaymentHeaderData) {
      // if only one details record
      if (self.mangoElement.detailsRecords.length == 1) {
        // Update the BH,  Delete the Payment Detail and PaymentHeader record
        self.mangoAPISrvc.getBillingHeaderSingleRecordById(self.mangoElement.detailsRecords[0].BillingHeaderID).subscribe((item: any) => {
          self.mangoElement.billingHeaderdata = item[0];

          const value1 = (self.mangoElement.billingHeaderdata['InvoiceBalance']) ? parseFloat(self.mangoElement.billingHeaderdata['InvoiceBalance']) : 0;
          const value2 = (self.mangoElement.billingHeaderdata['TotalPayments']) ? parseFloat(self.mangoElement.billingHeaderdata['TotalPayments']) : 0;

          self.mangoElement.billingHeaderdata['InvoiceBalance'] = value1 + parseFloat(self.selectedInvoice.refundAmount);
          self.mangoElement.billingHeaderdata['TotalPayments'] = value2 - parseFloat(self.selectedInvoice.refundAmount);

          self.mangoAPISrvc.updateBillingHeader(self.mangoElement.billingHeaderdata, self.mangoElement.billingHeaderdata.BillingHeaderID).subscribe((headerData) => {
            self.mangoAPISrvc.deletePaymentDetails(self.mangoElement.detailsRecords[0].PaymentDetailID).subscribe(function (data: any) {
              self.mangoAPISrvc.deletePaymentHeader(self.mangoElement.PaymentHeaderData.PaymentHeaderID).subscribe(function (data: any) {
                self.prepareQueryString(true);
                self.refundDialog = false;
                self.mangoElement = { PaymentHeaderData: null, detailsRecords: [], billingHeaderdata: null };
                self.mangoAPISrvc.showLoader(false);
              }),
                (error) => {
                  self.mangoAPISrvc.notify(
                    "error",
                    this.translate.instant("error"),
                    AppConstants.fetchErrorMsg
                  );
                  self.mangoAPISrvc.showLoader(false);
                };
            }),
              (error) => {
                self.mangoAPISrvc.notify(
                  "error",
                  this.translate.instant("error"),
                  AppConstants.fetchErrorMsg
                );
                self.mangoAPISrvc.showLoader(false);
              };
          }),
            (error) => {
              self.mangoAPISrvc.notify(
                "error",
                this.translate.instant("error"),
                AppConstants.fetchErrorMsg
              );
              self.mangoAPISrvc.showLoader(false);
            };
        }),
          (error) => {
            self.mangoAPISrvc.notify(
              "error",
              this.translate.instant("error"),
              AppConstants.fetchErrorMsg
            );
            self.mangoAPISrvc.showLoader(false);
          };
      } else if (self.mangoElement.detailsRecords.length > 1) {
        // Interate selectedItems, Update the BH InvoiceBalance, delete the PD record, update the PH record 
        for (let j = 0; j < self.selectedInvoices.length; ++j) {
          const parentDetailObj = self.selectedInvoices[j];

          self.mangoAPISrvc.getBillingHeaderSingleRecordById(parentDetailObj.BillingHeaderID).subscribe((item: any) => {

            self.mangoElement.billingHeaderdata = item[0];

            const value1 = (self.mangoElement.billingHeaderdata['InvoiceBalance']) ? parseFloat(self.mangoElement.billingHeaderdata['InvoiceBalance']) : 0;
            const value2 = (self.mangoElement.billingHeaderdata['TotalPayments']) ? parseFloat(self.mangoElement.billingHeaderdata['TotalPayments']) : 0;

            self.mangoElement.billingHeaderdata['InvoiceBalance'] = value1 + parseFloat(parentDetailObj.AppliedAmount);
            self.mangoElement.billingHeaderdata['TotalPayments'] = value2 - parseFloat(parentDetailObj.AppliedAmount);

            self.mangoAPISrvc.updateBillingHeader(self.mangoElement.billingHeaderdata, parentDetailObj.BillingHeaderID).subscribe((headerData) => {

              self.mangoAPISrvc.deletePaymentDetails(parentDetailObj.PaymentDetailID).subscribe(function (data: any) {

                // only time excute 
                if (self.mangoElement.detailsRecords.length == self.selectedInvoices.length && (self.selectedInvoices.length - 1 == j)) {
                  self.mangoAPISrvc.deletePaymentHeader(self.mangoElement.PaymentHeaderData.PaymentHeaderID).subscribe(function (data: any) {
                    self.prepareQueryString(true);
                    self.refundDialog = false;
                    self.mangoElement = { PaymentHeaderData: null, detailsRecords: [], billingHeaderdata: null };
                    self.mangoAPISrvc.showLoader(false);
                  })
                } else if (self.mangoElement.detailsRecords.length != self.selectedInvoices.length) {
                  const paymentHeaderObj = { PaymentAmount: 0 };
                  paymentHeaderObj.PaymentAmount = self.mangoElement.PaymentHeaderData['PaymentAmount'] - parseFloat(parentDetailObj.AppliedAmount);

                  self.mangoAPISrvc.updatePaymentHeader(paymentHeaderObj, parentDetailObj.PaymentHeaderID).subscribe((headerData) => {
                    if (self.selectedInvoices.length - 1 == j) {
                      self.prepareQueryString(true);
                      self.refundDialog = false;
                      self.mangoElement = { PaymentHeaderData: null, detailsRecords: [], billingHeaderdata: null };
                      self.mangoAPISrvc.showLoader(false);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        //Delete the PH and the BH - This would be an Advance Payment (No Details)
        self.mangoAPISrvc.deletePaymentHeader(self.mangoElement.PaymentHeaderData.PaymentHeaderID).subscribe(function (data: any) {
          self.mangoAPISrvc.deleteBillingHeader(self.mangoElement.PaymentHeaderData.BillingHeaderID).subscribe(function (data: any) {
            self.prepareQueryString(true);
            self.refundDialog = false;
            self.mangoElement = { PaymentHeaderData: null, detailsRecords: [], billingHeaderdata: null };
            self.mangoAPISrvc.showLoader(false);
          })
        })

      }
    } else {
      self.prepareQueryString(true);
      self.refundDialog = false;
    }
  }

  async reverseStaxFund(rowData) {
    const self = this;
    return new Promise(async function (resolve, reject) {
      try {
        const obj = { total: rowData.refundAmount };
        self.mangoAPISrvc.performStaxActions({ id: rowData.id, data: obj }, self.mangoCompanyData.CompanyID, 'sref').subscribe(function (data: any) {
          resolve(data);
        }, (error) => {
          reject(error)
          self.mangoAPISrvc.notify('error', 'Error', 'Error while Refund.');
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async staxVoid(rowData) {
    const self = this;
    return new Promise(async function (resolve, reject) {
      try {
        const obj = { total: rowData.refundAmount };
        self.mangoAPISrvc.performStaxActions({ id: rowData.id, data: obj }, self.mangoCompanyData.CompanyID, 'svoid').subscribe(function (data: any) {
          resolve(data);
        }, (error) => {
          reject(error)
          self.mangoAPISrvc.notify('error', 'Error', 'Error while Refund.');
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  applyClients(rowData) {
    this.transactionData = rowData;
    this.totalAmtApplied = 0;
    this.totalUnAmtApplied = 0;
    setTimeout(() => {
      this.totalAmtApplied = rowData.meta.subtotal;
      this.totalUnAmtApplied = rowData.meta.subtotal;
      this.visibleSidebar = true;
    }, 100);
  }

  onAmountChange(item) {
    const unappliedAmt = numeral(this.totalUnAmtApplied).value();
    const invoiceBal = numeral(item.InvoiceBalance).value();
    const totalPayments = numeral(item.TotalPayments).value();
    const paymentsapplied = numeral(item.PaymentsApplied).value();

    if (item.isChecked && this.totalUnAmtApplied === 0) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        this.translate.instant('The Unapplied Balance is Zero. No Payment can be Applied.')
      );
      setTimeout(() => {
        item.isChecked = false;
        this.selectedItems = this.invoices.filter(item => item.isChecked == true);
      }, 50);
      return false;
    } else {
      this.selectedItems = this.invoices.filter(item => item.isChecked == true);
      if (item.isChecked) {
        const computedBalace = (invoiceBal - unappliedAmt);
        if (computedBalace >= 0) {
          item.PaymentsApplied = unappliedAmt;
          const valueStr = (unappliedAmt - numeral(item.PaymentsApplied).value()).toFixed(2);
          this.totalUnAmtApplied = numeral(valueStr).value();
          item.InvoiceBalance = numeral((invoiceBal - unappliedAmt).toFixed(2)).format('0,0.00');
          item.TotalPayments = numeral((totalPayments + unappliedAmt).toFixed(2)).format('0,0.00');
        } else {
          item.PaymentsApplied = invoiceBal;
          const valueStr = Math.abs(numeral(computedBalace).value()).toFixed(2);
          this.totalUnAmtApplied = numeral(valueStr).value();
          item.InvoiceBalance = numeral().format('0,0.00');
          item.TotalPayments = numeral((totalPayments + invoiceBal).toFixed(2)).format('0,0.00');
        }
      } else {
        item.PaymentsApplied = 0;
        const valueStr = (unappliedAmt + paymentsapplied).toFixed(2);
        this.totalUnAmtApplied = numeral(valueStr).value();
        item.InvoiceBalance = numeral((invoiceBal + paymentsapplied).toFixed(2)).format('0,0.00');
        item.TotalPayments = numeral((totalPayments - paymentsapplied).toFixed(2)).format('0,0.00');
      }
    }
  }

  changeClientName() {
    const self = this;
    self.invoices = [];
    self.emails = [];
    self.totalUnAmtApplied = this.transactionData.meta.subtotal;
    self.totalAmtApplied = this.transactionData.meta.subtotal;
    self.selectedItems = 0;
    self.selClient = self.selectedClient.value;
    if (self.selectedClient.value?.ClientID) {
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc.getOpenInvoices(self.selectedClient.value.ClientID).subscribe(
        (data: any) => {
          data.invoices.map(function (item) {
            item.isChecked = false;
          });
          self.invoices = self.mangoUtils.sortByFiled(data.invoices, 'InvoiceDate');
          if (self.invoices.length > 0) {
            self.emails = data.emails;
            self.cards = data.cards;
          }
          self.getEmailcontactsList(self.selectedClient.value.ClientID);
          self.mangoAPISrvc.showLoader(false);
        },
        err => {
          self.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      self.selectedClient = null;
    }
  }

  getEmailcontactsList(clientId) {
    const parent = this;
    parent.allEmailContacts = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailcontactsByClientID(clientId).subscribe((response: any) => {
      parent.allEmailContacts = response.filter(item => item.isClientPortalAccess == true);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList?.filter(
      (item) => item["ContactRecord"] != true && item["Inactive"] != true
    );
    if (this.clients.length == 0 && list.length > 0) {
      this.clients = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item["ContactRecord"] != true && item["Inactive"] != true) {
          this.clients.push({ label: item['ClientName'], value: item });
        }
      }
      this.clients.unshift({ label: 'Select Client', value: null });
    } else {
      if (list.length > 0) {
        clearInterval(this.intervalid);
      }
    }
  }

  updateStaxTransaction() {
    const parent = this;
    const cardObj = { source: parent.transactionData.source, method: parent.transactionData.method, pre_auth: 0, total: parent.transactionData.meta.subtotal, success: true, type: parent.transactionData.type, customer_id: parent.transactionData.customer_id, meta: { otherField2: (parent.selClient.ClientID + ' | ' + parent.transactionData.HostedPaymentMemo) } };
    parent.mangoAPISrvc.performStaxActions({ id: parent.transactionData.id, data: cardObj }, parent.mangoCompanyData.CompanyID, 'utran').subscribe(function (data: any) {
    },
      (data: any) => { parent.mangoAPISrvc.showLoader(false) }
    );
  }

  createOrUpdateCard() {
    const parent = this;
    const cardObj = {};
    this.isCrCard = this.transactionData.payment_method.bin_type == "CREDIT" ? true : false;
    cardObj['CardNo'] = this.transactionData.payment_method.card_last_four;
    cardObj['ExpiryDate'] = this.transactionData.payment_method.card_exp;
    cardObj['NameOnCard'] = this.transactionData.payment_method.person_name;
    cardObj['TransType'] = this.isCrCard ? 'CC' : 'ACH';
    cardObj['FirstName'] = this.transactionData.customer.firstname;
    cardObj['LastName'] = this.transactionData.customer.lastname;
    // cardObj['StaxToken'] = this.transactionData.payment_method.id;
    // cardObj['StaxCustomerID'] = this.transactionData.customer_id;
    // cardObj['StaxPaymentMethodID'] = this.transactionData.payment_method_id;
    cardObj['CardType'] = this.isCrCard
      ? this.transactionData.payment_method['card_type']
      : this.transactionData.payment_method['bank_type'];

    cardObj['uid'] = this.transactionData.payment_method.id;
    cardObj['scid'] = this.transactionData.customer_id;

    cardObj['CompanyID'] = this.selClient.CompanyID;
    cardObj['ClientID'] = this.selClient.ClientID;

    if (!this.isCrCard) {
      cardObj['BankAcctNo'] = this.transactionData.payment_method.card_last_four;
      cardObj['isBusiness'] =
        this.transactionData.payment_method.bank_holder_type == 'business' ? true : false;
    }
    let cardProfile = this.cards.filter(item => item.TransType == cardObj['TransType']);
    parent.mangoAPISrvc.showLoader(true);
    if (cardProfile == null || cardProfile.length == 0) {
      parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
        async (resultRes: any) => {
          parent.mangoAPISrvc.showLoader(false);
        },
        (data: any) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      cardProfile = cardProfile[0];
      parent.mangoAPISrvc.updateCreditCardDetails(cardProfile.CustomerCardID, cardObj).subscribe(
        async (resultRes: any) => {
          parent.mangoAPISrvc.showLoader(false);
        },
        (data: any) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  async applyPayment() {
    if (this.isProcessing == true) {
      return false;
    }
    const parent = this;
    parent.isCrCard = parent.transactionData.payment_method.bin_type == "CREDIT" ? true : false;
    parent.totalAmtApplied = numeral(parent.transactionData.meta.subtotal).value();
    const paymentUnapplied = numeral(parent.totalUnAmtApplied).value();
    parent.isProcessing = true;

    const rowData = parent.fullDataSource.filter((item) => item.id == parent.transactionData.id)[0];
    rowData['ClientName'] = parent.selClient['ClientName'];
    rowData['isHostedPayment'] = false;

    // Advance Payment Only.  No Payment Applied and No Retainer Applied - Create a Retainer
    if (parent.selectedItems == 0 || parent.selectedItems.length == 0) {
      // Build new record in billing header
      var newHeader = {};
      newHeader['ClientID'] = parent.selClient['ClientID'];
      newHeader['CompanyID'] = parent.selClient.CompanyID;
      newHeader['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newHeader['InvoiceAmount'] = parent.transactionData.meta.subtotal * -1;
      newHeader['TotalServices'] = newHeader['InvoiceAmount'];
      newHeader['InvoiceBalance'] = newHeader['InvoiceAmount'];
      newHeader['PaymentsApplied'] = 0;
      newHeader['TotalStaffCost'] = 0;
      newHeader['TotalPayments'] = 0;
      newHeader['InvoicePosted'] = true;

      newHeader['CreatedbyStaffID'] = null;
      newHeader['BillingPartnerID'] = parent.selClient['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = parent.selClient['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = parent.selClient['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = parent.selClient['GroupDescriptionID'];
      newHeader['GroupDescriptionIDArray'] = parent.selClient['GroupDescriptionIDArray'];
      newHeader['SurChargeAmount'] = parent.transactionData.meta.surcharge;
      newHeader['InvoiceType'] = 'Retainer';
      newHeader['StaxTransationID'] = parent.transactionData.id;
      newHeader['DescriptionShort'] =
        'Advance Payment' +
        (parent.transactionData.HostedPaymentMemo
          ? ` - ${parent.transactionData.HostedPaymentMemo}`
          : '');

      parent.mangoAPISrvc.showLoader(true);
      // No Payment Applied and No Retainer Applied - Create advance Payment
      // Create billing Header
      const billingHeaderRes = await parent.createBillingHeader(newHeader);
      // Create PaymentHeader
      const paymentHeader = {};
      paymentHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      paymentHeader['ClientID'] = newHeader['ClientID'];
      paymentHeader['CompanyID'] = newHeader['CompanyID'];
      paymentHeader['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      paymentHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
      paymentHeader['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
      paymentHeader['PaymentAmount'] = parent.transactionData.meta.subtotal;
      paymentHeader['SelectedForDepositToBank'] = false;
      paymentHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      paymentHeader['PaymentNote'] =
        `Paid : ${parent.transactionData.payment_method.nickname}` + ' (HP)';
      if (parent.isCrCard && parent.transactionData.payment_method.bin_type == 'DEBIT') {
        paymentHeader['PaymentType'] = 'Debit card';
      }

      paymentHeader['SurChargeAmount'] = newHeader['SurChargeAmount'];
      paymentHeader['SelectedForDepositToBank'] = false;
      paymentHeader['NSFtrue'] = false;
      paymentHeader['BillingPartnerID'] = parent.selClient.BillingPartnerID;
      paymentHeader['OriginatingPartnerID'] = parent.selClient.OriginatingPartnerID;
      paymentHeader['StaffAssignedID'] = parent.selClient.StaffAssignedID;
      paymentHeader['GroupDescriptionIDArray'] = parent.selClient.GroupDescriptionIDArray;
      paymentHeader['GroupDescriptionID'] = parent.selClient.GroupDescriptionID;
      paymentHeader['ClientTypeID'] = parent.selClient.ClientTypeID;
      paymentHeader['PaymentUnapplied'] = paymentUnapplied;


      const paymentHeaderres = await parent.createPaymentHeader(paymentHeader);
      parent.isProcessing = false;
      parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
      parent.sendEmailToFirmAndCustomer(
        newHeader['PaymentAmount'],
        paymentHeaderres['PaymentHeaderID']
      );
      parent.createOrUpdateCard();
      //parent.updateStaxTransaction();
      setTimeout(() => {
        parent.mangoAPISrvc.showLoader(false);
        parent.cancelDialog();
      }, 1200);
      // BillingHeader needs to be created first if there is an unapplied balance.  Create Advance Payment.
    } else if (paymentUnapplied == 0) {
      var newHeader = {};
      newHeader['ClientID'] = parent.selClient['ClientID'];
      newHeader['CompanyID'] = parent.selClient.CompanyID;
      newHeader['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
      newHeader['PaymentNote'] = parent.transactionData.HostedPaymentMemo;
      newHeader['PaymentUnapplied'] = paymentUnapplied;
      newHeader['BillingPartnerID'] = parent.selClient['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = parent.selClient['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = parent.selClient['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = parent.selClient['GroupDescriptionID'];
      newHeader['ClientTypeID'] = parent.selClient['ClientTypeID'];
      newHeader['SelectedForDepositToBank'] = false;
      newHeader['NSFtrue'] = false;
      newHeader['PaymentAmount'] = parent.transactionData.meta.subtotal;
      newHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      newHeader['PaymentNote'] =
        `Paid : ${parent.transactionData.payment_method.nickname}` + ' (HP)';
      if (parent.isCrCard && parent.transactionData.payment_method.bin_type == 'DEBIT') {
        newHeader['PaymentType'] = 'Debit card';
      }

      newHeader['SurChargeAmount'] = newHeader['SurChargeAmount'];

      // handle customer mark deposited applied
      if (!parent.selClient['MarkDepositApplied']) {
        newHeader['PaymentUnapplied'] = paymentUnapplied;
      } else {
        newHeader['PaymentUnapplied'] = 0;
      }

      parent.mangoAPISrvc.showLoader(true);
      for (let i = 0; i < parent.selectedItems.length; ++i) {
        const itemData = parent.selectedItems[i];
        if (itemData.InvoiceType == "Retainer Invoice") {
          newHeader['PaymentUnapplied'] += numeral(itemData.PaymentsApplied).value();
        }
      }

      const paymentHeaderObj = await parent.createPaymentHeader(newHeader);

      for (let index = 0; index < parent.selectedItems.length; ++index) {
        const itemData = parent.selectedItems[index];

        if (itemData.InvoiceType == "Retainer Invoice") {
          const billingHeaderRequest = {};
          billingHeaderRequest['ClientID'] = parent.selClient['ClientID'];
          billingHeaderRequest['CompanyID'] = parent.selClient.CompanyID;
          billingHeaderRequest['InvoiceDate'] = itemData.InvoiceDate;
          billingHeaderRequest['DescriptionShort'] = "Advance Payment" + (parent.transactionData.HostedPaymentMemo ? ` - ${parent.transactionData.HostedPaymentMemo}` : '');
          billingHeaderRequest['InvoiceType'] = "Retainer Payment";
          billingHeaderRequest['InvoiceAmount'] = numeral(itemData.PaymentsApplied).value() * -1;
          billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
          billingHeaderRequest['PaymentsApplied'] = 0;
          billingHeaderRequest['TotalStaffCost'] = 0;
          billingHeaderRequest['TotalPayments'] = 0;
          billingHeaderRequest['InvoicePosted'] = true;
          billingHeaderRequest['NSFtrue'] = false;
          itemData['CompanyID'] = parent.selClient.CompanyID;
          // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
          if (numeral(itemData.InvoiceBalance).value() <= 0) {
            parent.mangoAPISrvc
              .deleteBillingHeaderMobilePayment(
                itemData.BillingHeaderID,
                billingHeaderRequest['CompanyID']
              )
              .subscribe(response => {});
          } else {
            const cloneObj = Object.assign({}, billingHeaderRequest);
            cloneObj['AppliedCheckEdit'] = 0;
            cloneObj['PaymentsApplied'] = 0;
            cloneObj['DescriptionShort'] = itemData.DescriptionShort;
            cloneObj['InvoiceType'] = 'Retainer Invoice';
            cloneObj['CompanyID'] = parent.selClient.CompanyID;
            cloneObj['InvoiceAmount'] = numeral(itemData.InvoiceAmount).value();
            cloneObj['InvoiceBalance'] = numeral(itemData.InvoiceBalance).value();
            cloneObj['PaymentAmount'] = numeral(itemData.PaymentsApplied).value();
            cloneObj['PaymentUnapplied'] = numeral(itemData.PaymentUnapplied).value();
            cloneObj['TotalPayments'] = numeral(itemData.TotalPayments).value();
            cloneObj['LastModifiedStaffID'] = null;
            cloneObj['StaxTransationID'] = parent.transactionData.id;

            parent.mangoAPISrvc
              .billingHeaderMobilePayment(cloneObj, itemData.BillingHeaderID)
              .subscribe(item => {});
          }

          billingHeaderRequest['CreatedbyStaffID'] = null;
          billingHeaderRequest['SurChargeAmount'] = parent.transactionData.meta.surcharge
            ? parent.transactionData.meta.surcharge
            : 0;
          billingHeaderRequest['StaxTransationID'] = parent.transactionData.id;

          const billingHeaderRes = await parent.createBillingHeader(billingHeaderRequest);

          //paymentHeaderObj['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
          //paymentHeaderObj['RetainerHeaderID'] = itemData.BillingHeaderID;

          // Need to create PaymentDetail record for the Retainer for association to the BH record
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeaderObj['PaymentHeaderID'];
          if (numeral(itemData.InvoiceBalance).value() != 0) {
            PaymentDetailsEntity['ParentHeaderID'] = itemData.BillingHeaderID;
          }
          PaymentDetailsEntity['ClientID'] = parent.selClient['ClientID'];
          PaymentDetailsEntity['CompanyID'] = parent.selClient.CompanyID;
          PaymentDetailsEntity['CheckRef'] = null;
          PaymentDetailsEntity['PaymentNote'] = newHeader['PaymentNote'];
          PaymentDetailsEntity['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
          PaymentDetailsEntity['PaymentType'] = newHeader['PaymentType'];
          PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = itemData.InvoiceDate;
          PaymentDetailsEntity['isAdvancePayment'] = true;
          PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
          PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

          await parent.createPaymentDetail(PaymentDetailsEntity);
        } else {
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = itemData.BillingHeaderID;
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeaderObj['PaymentHeaderID'];
          PaymentDetailsEntity['ClientID'] = this.selClient.ClientID;
          PaymentDetailsEntity['CompanyID'] = this.selClient.CompanyID;
          PaymentDetailsEntity['CheckRef'] = null;
          PaymentDetailsEntity['PaymentNote'] = paymentHeaderObj['PaymentNote'];
          PaymentDetailsEntity['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
          PaymentDetailsEntity['PaymentType'] = paymentHeaderObj['PaymentType'];
          PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = itemData.InvoiceDate;
          PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
          PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

          await this.createPaymentDetail(PaymentDetailsEntity);

          itemData.AppliedCheckEdit = 0;
          itemData.PaymentsApplied = 0;
          itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
          itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
          itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
          itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
          itemData.TotalPayments = numeral(itemData.TotalPayments).value();
          itemData['CompanyID'] = this.selClient.CompanyID;
          itemData['StaxTransationID'] = this.transactionData.id;

          const parent = this;
          // updating BillingHeader for each selected Open Invoice
          itemData['LastModifiedStaffID'] = null;
          itemData['SurChargeAmount'] = parent.transactionData.meta.surcharge
            ? parent.transactionData.meta.surcharge
            : 0;
          parent.mangoAPISrvc
            .billingHeaderMobilePayment(itemData, itemData.BillingHeaderID)
            .subscribe(item => {});
        }

        if (index == parent.selectedItems.length - 1) {
          parent.isProcessing = false;
          parent.sendEmailToFirmAndCustomer(
            newHeader['PaymentAmount'],
            paymentHeaderObj['PaymentHeaderID']
          );
          parent.createOrUpdateCard();
          //parent.updateStaxTransaction();
          setTimeout(() => {
            parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
            parent.mangoAPISrvc.showLoader(false);
            parent.cancelDialog();
          }, 1200);
        }
      }
    } else {
      var newHeader = {};
      newHeader['ClientID'] = parent.selClient['ClientID'];
      newHeader['CompanyID'] = parent.selClient.CompanyID;
      newHeader['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
      newHeader['PaymentNote'] = parent.transactionData.HostedPaymentMemo;
      newHeader['DescriptionShort'] =
        'Advance Payment' +
        (parent.transactionData.HostedPaymentMemo
          ? ` - ${parent.transactionData.HostedPaymentMemo}`
          : '');

      // let PmtUnapplied = numeral(formData['PaymentUnapplied']).value();
      newHeader['InvoiceAmount'] = paymentUnapplied * -1;
      newHeader['TotalServices'] = newHeader['InvoiceAmount'];
      newHeader['TotalPayments'] = 0;
      newHeader['InvoiceBalance'] = newHeader['InvoiceAmount'];
      newHeader['PaymentsApplied'] = 0;
      newHeader['TotalStaffCost'] = 0;
      newHeader['InvoicePosted'] = true;
      newHeader['InvoiceType'] = 'Retainer';
      newHeader['NSFtrue'] = false;
      newHeader['CreatedbyStaffID'] = null;
      newHeader['StaxTransationID'] = parent.transactionData.id;
      newHeader['SurChargeAmount'] = parent.transactionData.meta.surcharge
        ? parent.transactionData.meta.surcharge
        : 0;

      parent.mangoAPISrvc.showLoader(true);
      const billingHeaderRes = await parent.createBillingHeader(newHeader);

      // create new record in paymentHeader
      const newPaymentHeader = {};
      newPaymentHeader['ClientID'] = newHeader['ClientID'];
      newPaymentHeader['CompanyID'] = newHeader['CompanyID'];
      newPaymentHeader['PaymentDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
      newPaymentHeader['PaymentType'] = newHeader['PaymentType'];
      newPaymentHeader['PaymentNote'] = newHeader['PaymentNote'];
      newPaymentHeader['PaymentUnapplied'] = paymentUnapplied;
      newPaymentHeader['CheckRef'] = null;

      // handle customer mark deposited applied
      if (!parent.selClient['MarkDepositApplied']) {
        newPaymentHeader['PaymentUnapplied'] = paymentUnapplied;
      } else {
        newPaymentHeader['PaymentUnapplied'] = 0;
      }
      newPaymentHeader['PaymentUnapplied'] = newPaymentHeader['PaymentUnapplied'];
      newPaymentHeader['BillingPartnerID'] = newPaymentHeader['CompanyID'];
      newPaymentHeader['OriginatingPartnerID'] = parent.selClient['OriginatingPartnerID'];
      newPaymentHeader['StaffAssignedID'] = parent.selClient['StaffAssignedID'];
      newPaymentHeader['GroupDescriptionID'] = parent.selClient['GroupDescriptionID'];
      newPaymentHeader['ClientTypeID'] = parent.selClient['ClientTypeID'];
      newPaymentHeader['SelectedForDepositToBank'] = false;
      newPaymentHeader['NSFtrue'] = false;
      newPaymentHeader['PaymentAmount'] = parent.totalAmtApplied;

      // insert paymentHeader record (This is needed only if Advance Payment Done PaymentUnapplied > 0)
      if (billingHeaderRes['BillingHeaderID']) {
        newPaymentHeader['BillingHeaderID'] = billingHeaderRes['BillingHeaderID'];
        newPaymentHeader['RetainerHeaderID'] = billingHeaderRes['BillingHeaderID'];
      }
      newPaymentHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      if (parent.transactionData) {
        newPaymentHeader['PaymentNote'] =
          `Paid : ${parent.transactionData.payment_method.nickname}` + ' (HP)';
        if (parent.isCrCard && parent.transactionData.payment_method.bin_type == 'DEBIT') {
          newPaymentHeader['PaymentType'] = 'Debit card';
        }
      }
      newPaymentHeader['CreatedbyStaffID'] = null;
      newPaymentHeader['SurChargeAmount'] = parent.transactionData.meta.surcharg
        ? parent.transactionData.meta.surcharg
        : 0;

      const paymentHeaderObj = await this.createPaymentHeader(newPaymentHeader);

      for (let index = 0; index < parent.selectedItems.length; ++index) {
        const itemData = parent.selectedItems[index];
        if (itemData.InvoiceType == "Retainer Invoice") {
          const billingHeaderRequest = {};
          billingHeaderRequest['ClientID'] = newPaymentHeader['ClientID'];
          billingHeaderRequest['CompanyID'] = newPaymentHeader['CompanyID'];
          billingHeaderRequest['InvoiceDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
          billingHeaderRequest['DescriptionShort'] =
            'Advance Payment' +
            (parent.transactionData.HostedPaymentMemo
              ? ` - ${parent.transactionData.HostedPaymentMemo}`
              : '');
          billingHeaderRequest['InvoiceAmount'] = numeral(itemData.TotalPayments).value() * -1;
          billingHeaderRequest['TotalServices'] = billingHeaderRequest['InvoiceAmount'];
          billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
          billingHeaderRequest['PaymentsApplied'] = 0;
          billingHeaderRequest['TotalStaffCost'] = 0;
          billingHeaderRequest['TotalPayments'] = 0;
          billingHeaderRequest['InvoicePosted'] = true;
          billingHeaderRequest['NSFtrue'] = false;
          itemData['CompanyID'] = this.selClient.CompanyID;
          // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
          if (numeral(itemData.InvoiceBalance).value() <= 0) {
            this.mangoAPISrvc
              .deleteBillingHeaderMobilePayment(
                itemData.BillingHeaderID,
                billingHeaderRequest['CompanyID']
              )
              .subscribe(item => {});
          } else {
            const cloneObj = Object.assign({}, billingHeaderRequest);
            cloneObj['ClientID'] = this.selClient.ClientID;
            cloneObj['AppliedCheckEdit'] = 0;
            cloneObj['PaymentsApplied'] = 0;
            cloneObj['DescriptionShort'] = itemData.DescriptionShort;
            cloneObj['CompanyID'] = this.selClient.CompanyID;
            cloneObj['InvoiceAmount'] = numeral(itemData.InvoiceAmount).value();
            cloneObj['InvoiceBalance'] = numeral(itemData.InvoiceBalance).value();
            cloneObj['PaymentAmount'] = numeral(itemData.PaymentAmount).value();
            cloneObj['PaymentUnapplied'] = numeral(itemData.PaymentUnapplied).value();
            cloneObj['TotalPayments'] = numeral(itemData.TotalPayments).value();
            cloneObj['LastModifiedStaffID'] = null;
            cloneObj['StaxTransationID'] = parent.transactionData.id;
            this.mangoAPISrvc
              .billingHeaderMobilePayment(cloneObj, itemData.BillingHeaderID)
              .subscribe(item => {});
          }
          billingHeaderRequest['CreatedbyStaffID'] = null;
          billingHeaderRequest['SurChargeAmount'] = this.transactionData.meta.surcharge
            ? this.transactionData.meta.surcharge
            : 0;
          billingHeaderRequest['StaxTransationID'] = parent.transactionData.id;

          await this.createBillingHeader(billingHeaderRequest);
        } else {
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = itemData.BillingHeaderID;
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeaderObj['PaymentHeaderID'];
          PaymentDetailsEntity['ClientID'] = parent.selClient.ClientID;
          PaymentDetailsEntity['CompanyID'] = parent.selClient.CompanyID;
          PaymentDetailsEntity['CheckRef'] = null;
          PaymentDetailsEntity['PaymentNote'] = paymentHeaderObj['PaymentNote'];
          PaymentDetailsEntity['PaymentDate'] = paymentHeaderObj['PaymentDate'];
          PaymentDetailsEntity['PaymentType'] = paymentHeaderObj['PaymentType'];
          PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = itemData.InvoiceDate;
          PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
          PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

          await parent.createPaymentDetail(PaymentDetailsEntity);

          itemData.AppliedCheckEdit = 0;
          itemData.PaymentsApplied = 0;
          itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
          itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
          itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
          itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
          itemData.TotalPayments = numeral(itemData.TotalPayments).value();
          itemData.LastModifiedStaffID = null;
          itemData['CompanyID'] = parent.selClient.CompanyID;
          itemData['StaxTransationID'] = parent.transactionData.id;
          // updating BillingHeader for each selected Open Invoice
          parent.mangoAPISrvc
            .billingHeaderMobilePayment(itemData, itemData.BillingHeaderID)
            .subscribe(item => {});
        }

        if (index == parent.selectedItems.length - 1) {
          parent.isProcessing = false;
          parent.sendEmailToFirmAndCustomer(
            newHeader['PaymentAmount'],
            paymentHeaderObj['PaymentHeaderID']
          );
          parent.createOrUpdateCard();
          //parent.updateStaxTransaction();
          setTimeout(() => {
            parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
            parent.mangoAPISrvc.showLoader(false);
            parent.cancelDialog();
          }, 1200);
        }
      }

      if (parent.selectedItems == 0 || parent.selectedItems.length == 0) {
        parent.isProcessing = false;
        parent.sendEmailToFirmAndCustomer(
          newHeader['PaymentAmount'],
          paymentHeaderObj['PaymentHeaderID']
        );
        parent.createOrUpdateCard();
        parent.updateStaxTransaction();
        setTimeout(() => {
          parent.mangoAPISrvc.notify('success', 'Success!', 'Proccesed  successfully.');
          parent.mangoAPISrvc.showLoader(false);
          parent.cancelDialog();
        }, 1200);
      }
      parent.mangoAPISrvc.showLoader(false);
    }
  }

  cancelDialog() {
    this.transactionData = null;
    this.totalAmtApplied = this.totalUnAmtApplied = 0;
    this.selClient = null;
    this.selectedClient = null;
    this.isProcessing = false;
    this.selectedItems = [];
    this.invoices = [];
    this.visibleSidebar = false;
  }

  // async getSurchargeAmount(merchantId, Amount, companyId, eventType) {
  //   let self = this;
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       self.mangoAPISrvc
  //         .performStaxActions({ ccid: merchantId, amount: Amount }, companyId, eventType)
  //         .subscribe(function (data) {
  //           data = data['bin_type'] ? data : null;
  //           resolve(data);
  //         });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  async createPaymentHeader(newHeader) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc.createMobilePayPaymentHeader(newHeader).subscribe(function (data: any) {
          resolve(data.data);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async createBillingHeader(newDetails) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .createBillingHeaderMobilePayment(newDetails)
          .subscribe(function (data: any) {
            resolve(data.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async createPaymentDetail(PaymentDetailsEntity) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .createMobilePayPaymentDetail(PaymentDetailsEntity)
          .subscribe(function (data: any) {
            resolve(data.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getClientData(clientID) {
    return new Promise((resolve, reject) => {
      this.mangoAPISrvc.getClientFullinformation(clientID).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async sendEmailToFirmAndCustomer(amt, paymentHeaderId) {
    const parent = this;
    parent.paymentProfileData = await parent.getClientData(parent.selClient['ClientID']);
    if (parent.mangoCompanyData.PortalPaymentNotifications?.length > 0) {
      parent.mangoAPISrvc.getUserInfoByCompanyID(parent.mangoCompanyData['CompanyID']).subscribe((response: any) => {
        if (response?.length > 0) {
          const toArr = []
          response.forEach((staff) => {
            if (parent.mangoCompanyData['PortalPaymentNotifications'].includes((staff.StaffID).toString())) {
              if (staff.Email) {
                toArr.push({ email: staff.Email, name: staff.StaffName })
              }
            }
          })
          parent.sendMailtoCompany(amt, toArr);
        }
      })
    }
    if (parent.allEmailContacts.length > 0 && parent.mangoCompanyData['EmailPaymentPortal'] == true) {
      parent.sendMailtoCustomers(amt, paymentHeaderId);
    }
    parent.mangoAPISrvc.showLoader(false);
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      showCancelButton: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'OK',
      text: 'Transaction Processed Successfully!',
    });
  }

  sendMailtoCompany(amt, toArr) {
    const parent = this;
    const companysendInBlueObj = {
      sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      to: [{ email: "", name: "" }],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 17,
      params: {}
    };
    amt = '$' + numeral(amt).format('0,0.00');

    companysendInBlueObj.to = toArr;
    companysendInBlueObj.params['COMPANYNAME'] = parent.selClient.ClientName;
    companysendInBlueObj.params['PAYMENTAMOUNT'] =
      '$' + numeral(parent.totalAmtApplied).format('0,0.00');
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
      data => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      },
      err => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  sendMailtoCustomers(amt, paymentHeaderId) {
    const parent = this;
    const sendInBlueObj = {};
    let toObj = { email: '', name: '' };
    var toArr = [];
    const senderObj = { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER }
    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['replyTo'] = { email: environment.EMAIL_RETURN_SENDER };

    for (let index = 0; index < parent.allEmailContacts.length; index++) {
      const element = parent.allEmailContacts[index];
      toObj = { email: '', name: '' };
      toObj['name'] = element['ContactName'] ? element['ContactName'] : element['Email'];
      toObj['email'] = element['Email'];
      toArr.push(toObj);
    }

    sendInBlueObj['to'] = parent.mangoUtils.deduplicateEmail(toArr);
    const tags = [];

    var toArr = [];
    tags.push(parent.selClient['ClientID'].toString());
    tags.push('none');
    tags.push(paymentHeaderId.toString());
    tags.push(parent.mangoCompanyData.CompanyID.toString());
    tags.push(parent.selClient['ClientName']);

    sendInBlueObj['tags'] = tags;
    amt = '$' + numeral(amt).format('0,0.00');

    parent.mangoAPISrvc
      .getEmailTemplateByTypeAndCompany('cashReceipt', parent.mangoCompanyData.CompanyID)
      .subscribe(result => {
        if (sendInBlueObj['to'].length > 0) {
          if (!result['EmailTemplateID']) {
            parent.cashReceiptEmailTemplate = parent.mangoUtils.getDefaultTemplate(
              'cashReceipt',
              parent.mangoCompanyData.CompanyID
            ); // default
          } else {
            parent.cashReceiptEmailTemplate = result; // DB
          }
          let htmlContent = parent.mangoUtils.getCashReceiptEmailTemplate();
          sendInBlueObj['subject'] = parent.cashReceiptEmailTemplate['EmailSubject'];

          //let formData = parent.myPaymentForm.value;
          if (
            parent.mangoCompanyData.isSurchargeEnabled &&
            parent.transactionData.meta.surcharge > 0
          ) {
            this.cashReceiptEmailTemplate[
              'htmlBody'
            ] += `</br></br><div style="text-align: center;font-size: 17px;color: #cb0000;">Transaction subject to a surcharge in the amount of <strong>$${parent.transactionData.meta.surcharge}</strong>.</div>`;
          }
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%InjectTemplate%',
            parent.cashReceiptEmailTemplate['htmlBody']
          );
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%ContactName%',
            parent.paymentProfileData['ContactPerson']
          );
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%YourFirmName%',
            parent.mangoCompanyData.CompanyName
          );
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%ClientName%',
            parent.paymentProfileData['ClientName']
          );
          htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Amount%', amt);
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%Email%',
            parent.paymentProfileData['Email']
          );
          sendInBlueObj['htmlContent'] = htmlContent;

          sendInBlueObj['subject'] = parent.mangoUtils.replaceAll(
            sendInBlueObj['subject'],
            '%ClientName%',
            parent.paymentProfileData['ClientName']
          );

          this.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(data => {
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
          });
        }
      });
  }
}
