import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService } from '@app/_services';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit {

  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;

  constructor(private router: Router,private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService) {
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
  }

  loadMenu() {
  }

 

}
