import { Component, OnInit } from "@angular/core";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConstants } from "@app/_helpers/api-constants";

@Component({
  selector: "app-microsoft-login",
  templateUrl: "./microsoft-login.component.html",
})
export class MicrosoftLoginComponent implements OnInit {
  staffID: any;
  selectedUserNameStr: any;
  isEnabledMSSignIn: boolean = false;
  staffSettings: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.mangoAPISrvc.notifyLogging(
      this.encrDecSrvc.getObject(AppConstants.isLoggingUser)
    );
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.userName);
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'My Profile' },
      { label: 'Microsoft Integration' },
      { label: this.selectedUserNameStr, icon: 'ic-red' }
    ]);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const code = params['code'];

      if(code) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.getUserDetailsByCode({ code }).subscribe((result: any) => {
          const obj = {
            MSEmail: result.data.id
          }

          this.mangoAPISrvc.updateMSLoginSettings(obj, this.staffID).subscribe((result) => {
            this.isEnabledMSSignIn = true;
            this.mangoAPISrvc.showLoader(false)
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
          }, err => {
            this.mangoAPISrvc.showLoader(false)
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          })
          this.mangoAPISrvc.showLoader(false);
        }, err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', err);
        })
      } else {
        this.getStaffMSSettings()
      }
    })
  }

  getStaffMSSettings() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getStaffMSSettings(this.staffID).subscribe((result: any) => {
      if(result)
        this.isEnabledMSSignIn = true;
      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    })
  }

  onChangeMSSignIn(event) {
    console.log(event);
    if(event.checked) {
      this.mangoAPISrvc.getEnableMSLoginUrl().subscribe((result: any) => {
        this.mangoAPISrvc.showLoader(false);
        if (result && result.redirectUrl) {
          this.mangoAPISrvc.notify('info', 'Info!', "You will be redirected to Microsoft login page for authentication. Please wait...");
          window.location.href = result.redirectUrl;
        }
      }, error => {
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      })
    } else {
      const obj = {
        MSEmail: null
      }
      this.mangoAPISrvc.showLoader(true)
      this.mangoAPISrvc.updateMSLoginSettings(obj, this.staffID).subscribe((result) => {
        this.mangoAPISrvc.showLoader(false)
        this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      }, err => {
        this.mangoAPISrvc.showLoader(false)
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      })
    }

  }
}
