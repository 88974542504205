<form [formGroup]="exclusionForm" (ngSubmit)="onSubmit()">
  <div class="card p-grid p-mx-3 p-mb-2 p-mt-2">
    <div class="p-col-6">
      <h5 class="ic-blue">
        {{ "Select the modules you'd like to restrict how far back we look for data to sync." | translate }}
      </h5>

      <div *ngFor="let module of modules" class="p-col-12">
        <p-checkbox binary="true" class="font-size-checkbox_mailconfig"
          label="{{module.moduleName}}" formControlName="{{module.moduleName}}" (onChange)="onCheckboxChange(module, $event.checked)">
        </p-checkbox>
      </div>
    </div>

    <div class="p-col-6 d-flex flex-column align-items-center">
      <h5 class="ic-blue">
        {{ "Select a date that we should not look backwards for data to sync." | translate }}
      </h5>

      <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" formControlName="dateUntil"
        required [showIcon]="true" [inline]="true">
      </p-calendar>
    </div>
    
    <p-button type="submit" label="Save" icon="pi pi-save" [disabled]="!exclusionForm.valid"></p-button>

  </div>
</form>
