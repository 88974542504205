<p-dialog header="{{ 'timer.engagement' | translate }}" [(visible)]="displayEngagementModal" [modal]="true"
    [style]="{width: '50vw'}" showEffect="fade" [baseZIndex]="1002" (onHide)="closeTEModal(false)" [draggable]="true" [resizable]="false">
    <form #engagementMainForm="ngForm">
        <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
            <div class="p-col-12">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'engagement.type' | translate }}</label>
                        <p-dropdown [(ngModel)]="selEngagementType" (onChange)="selectEngagementType($event)"
                            placeholder="Select Engagement Type" [ngModelOptions]="{standalone: true}"
                            name="engagementType" id="engagementType" #engagementType="ngModel"
                            [options]="engagementList" [autoWidth]=false required>
                        </p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'engagement.billing_method' | translate }}</label>
                        <p-dropdown [options]="billingRates" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="engagementObj.BillingMethod"></p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'engagement.name' | translate }}</label>
                        <!-- <p-autoComplete name="EngagementName" [(ngModel)]="engagementObj.EngagementName" required>
                        </p-autoComplete> -->
                        <input pInputText type="text" maxlength="60" name="engagementName" id="engagementName"
                            autocomplete="off" [(ngModel)]="engagementObj.EngagementName" #engagementName="ngModel"
                            (focus)="$event.target.select()" required>
                    </div>
                    <div class="p-field p-col-6">
                        <div class="p-d-flex p-jc-between">
                            <p-checkbox [binary]="true" [(ngModel)]="engagementObj.isBillable"
                                [ngModelOptions]="{standalone: true}" label="{{ 'billable' | translate }}" class="label-text p-mt-5" name="billingRateChk">
                            </p-checkbox>
                            <div class="p-pl-4 p-mt-2" *ngIf="engagementObj.BillingMethod === 'Fixed Fee'">
                                <label class="label-text">{{ 'Flat-Rate-Amount' | translate }}</label>
                                <p-inputNumber prefix="$" maxlength="12" placeholder="0.00" inputId="integeronly"
                                    autocomplete="off" mode="decimal" locale="en-US" [minFractionDigits]="2"
                                    [(ngModel)]="engagementObj.FlatFeeAmount" name="flateRateAmount"
                                    #flateRateAmount="ngModel" (focus)="$event.target.select()"></p-inputNumber>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'client' | translate }}</label>

                        <p-autoComplete [(ngModel)]="selClient" [suggestions]="filteredClients" name="ClientIDstr"
                            id="ClientIDstr" #ClientIDstr="ngModel" autocomplete="off" (completeMethod)="filterClients($event)"
                            [minLength]="1" placeholder="Type Client Name" [dropdown]="true"
                            (onSelect)="handleSelectClick($event.value)" field="ClientName" [disabled]="isClientFlow" required>
                            <ng-template let-client pTemplate="item">
                                <div class="ui-helper-clearfix">
                                    <div style="margin:8px 0 0 0">{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'assigned_to' | translate }}</label>
                        <p-dropdown [options]="staffList" [autoWidth]=false [(ngModel)]="engagementObj.AssignedTo"
                            placeholder="Select User" name="staffID" id="staffID" filter="true"></p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.client_contact' | translate }}</label>
                        <p-dropdown [options]="contacts" [style]="{'width':'100%'}" [autoWidth]=false
                            [(ngModel)]="engagementObj.CustomerContactID" placeholder="Select Client Contact"
                            name="clientContact" id="clientContact"></p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client.email' | translate }}</label>
                        <input pInputText class="form-control" type="text" maxlength="50" name="clientEmail"
                            id="clientEmail" autocomplete="off" [(ngModel)]="engagementObj.ContactEmail"
                            #clientEmail="ngModel" (focus)="$event.target.select()"
                            (blur)="isValidEmail(engagementObj.ContactEmail)">
                        <div *ngIf="!isEmailValid" class="p-invalid">
                            {{ 'invalid_email' | translate }}
                        </div>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text"> {{'engagement.number' | translate }} ({{ 'Optional' | translate
                            }})</label>
                        <input pInputText type="text" maxlength="50" name="engagementID" id="engagementID"
                            autocomplete="off" [(ngModel)]="engagementObj.EngagementNumber" #engagementID="ngModel"
                            (focus)="$event.target.select()">
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'status' | translate }}</label>
                        <p-dropdown [options]="statusTypes" [(ngModel)]="engagementObj.Status" name="Status"
                            id="Status">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-ml-2">
                <label for="address" class="label-text">{{ 'billing-invoicing.invoice-description' | translate
                    }}</label>
                <app-mentions [(inputText)]="engagementObj.EngagementInvoiceDescription" id="Notes" [rows]="3" style="height:65px; resize: vertical;"></app-mentions>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_and_close' | translate }}"
            [disabled]="!isFormValid" (click)="createProject(true)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_and_new' | translate }}"
            [disabled]="!isFormValid" (click)="createProject(false)"></button>
        <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'cancel_button' | translate }}"
            (click)="closeTEModal(false)"></button>
    </ng-template>
</p-dialog>