import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html'
})
export class TwoFactorAuthComponent implements OnInit {
  staffID: number = null;
  selectedUserNameStr: string = null;
  tfa: any = {
    secret: null,
    tempSecret: null,
    dataURL: null,
    tfaURL: null,
    message: ""
  };
  profileDataSource: any = null;
  authcode: any = null;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService, 
    private breadcrumbService: BreadcrumbService
  ) {
    const tempStaffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.mangoAPISrvc.notifyLogging(
      this.encrDecSrvc.getObject(AppConstants.isLoggingUser)
    );
    if(typeof(tempStaffID) != 'number')
      this.staffID = parseInt(this.encrDecSrvc.getObject(AppConstants.staffID));
    else
      this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);

    this.selectedUserNameStr = this.encrDecSrvc.getObject(AppConstants.userName);
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'My Profile' },
      { label: 'Two Factor Auth' },
      { label: this.selectedUserNameStr, icon: 'ic-red' }
    ]);

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getProfileData().subscribe((data) => {
      this.profileDataSource = data;
      
      this.mangoAPISrvc.getTFA().subscribe((dataTFA: any) => {
        if (dataTFA == null) {
          this.setup();
        } else {
          this.mangoAPISrvc.showLoader(false);
          this.tfa.secret = dataTFA;
        }
      }, err => this.mangoAPISrvc.showLoader(false))

      this.mangoAPISrvc.showLoader(false);
    }, error => {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    })
  }

  setup() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.setupTFA({ email: this.profileDataSource?.Email }).subscribe((data: any) => {
      this.tfa = data;
      this.mangoAPISrvc.showLoader(false);
    }, err => this.mangoAPISrvc.showLoader(false));
  }

  onEnableTFA() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.verifyTFA({ staffID: this.staffID, token: this.authcode, secret: this.tfa.tempSecret}).subscribe((data) => {
      this.tfa.secret = this.tfa.tempSecret;
      this.tfa.tempSecret = "";
      this.mangoAPISrvc.showLoader(false);
    }, err => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', 'Error!', err);
    });
  }

  onDisableTFA() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.deleteTFA(this.staffID).subscribe((data) => {
      this.tfa.secret = "";
      this.setup();
    }, err => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', 'Error!', err);
    });
  }

}
