import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { SharedComponentsService } from '../shared-components-service'

@Component({
    selector: 'app-language-keys',
    templateUrl: './language-keys.component.html'
})
export class LanguageKeysComponent implements OnInit, AfterViewInit {
    showLanguageKeysDialog: boolean = false;

    filteredItemsSize: number = -1;
    selectedLanguage: string = "en";
    languageKeys: any[] = [];
    languageKeyItem: any = {};
    showFormDialog: boolean = false;
    isUpdate: boolean = false;
    languageOptions: any[] = [{ label: "English", value: "en" }, { label: "Spanish", value: "es" }];
    @ViewChild('searchValue') searchValue;

    constructor(private mangoAPISrvc: MangoApiService, private sharedSrvc: SharedComponentsService, private translate: TranslateService) { }

    ngOnInit() {
        this.getLanguageKeys();
    }

    getLanguageKeys() {
        this.mangoAPISrvc.fetchLangKeys(this.selectedLanguage).subscribe((data: any) => {
            this.languageKeys = Object.keys(data).map((key) => ({ key, value: data[key] }));
        }, () => {
            this.mangoAPISrvc.notify('error', 'Error', AppConstants.fetchErrorMsg);
        })
    }

    openDialog() {
        this.showLanguageKeysDialog = true;
        const interval = setInterval(()=>{
          if(!this.searchValue) return;
          clearInterval(interval);
          this.searchValue.nativeElement.focus();
        })
    }

    closeDialog() {
        this.showLanguageKeysDialog = false;
        this.searchValue.nativeElement.value = "";
        this.sharedSrvc.sharedCompVisibility.languageKeys = false;
    }

    closeForm(form) {
        if (form) {
            form.resetForm();
        }

        this.showFormDialog = false;
        this.languageKeyItem = {};
    }

    changeLanguage(event) {
        this.selectedLanguage = event.value;
        this.getLanguageKeys();
    }

    openAddLanguageKey() {
        this.showFormDialog = true;
        this.languageKeyItem = {
            lang: this.selectedLanguage
        };
        this.isUpdate = false;
    }

    openEditLanguageKey(data) {
        this.languageKeyItem = {
            lang: this.selectedLanguage,
            key: data.key,
            value: data.value
        };
        this.showFormDialog = true;
        this.isUpdate = true;
    }

    saveLangKey(langKeyForm) {
        const success = () => {
            this.closeForm(langKeyForm);
            this.getLanguageKeys();
        };

        const error = (err) => {
            err = err.json();
            const errorMsg = err.message || err.errmsg || 'Error while saving lang key and value. Please review the inputs.';
            this.mangoAPISrvc.notify('error', 'Error', errorMsg);
        }

        this.isUpdate
            ? this.mangoAPISrvc.updateLangKey(this.languageKeyItem).subscribe(success, error)
            : this.mangoAPISrvc.addLangKey(this.languageKeyItem).subscribe(success, error);
    }

    onRowSelect(data) {
        this.openEditLanguageKey(data);
    }

    removeLanguageKey(row) {
        const languageKeyItem = {
            language: this.selectedLanguage,
            key: row.key,
            value: row.value
        };

        Swal.fire({
            title: `${this.translate.instant('mgmt-delete')} ${this.translate.instant('Key')}`,
            html: `${this.translate.instant('LanguageKeys.do-you-want-to-delete')} <strong> ${languageKeyItem.key} </strong>? ${this.translate.instant('LanguageKeys.operation-cant-be-undone')}`,
            icon: 'warning',
            showCancelButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: this.translate.instant('yes_delete'),
            cancelButtonText: this.translate.instant('no_delete')
        }).then((result) => {
            if (result.value) {
                this.mangoAPISrvc.removeLangKey(languageKeyItem.language, languageKeyItem.key).subscribe((data: any) => {
                    this.getLanguageKeys();
                    Swal.fire({
                        icon: 'success',
                        title: `${this.translate.instant('Deleted')}!`,
                        text: languageKeyItem.key + ' succesfully deleted',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }, (err) => {
                    this.mangoAPISrvc.notify('error', 'Error', err.json().message);
                })
            }
        });
    }

    onFilter(event) {
        this.filteredItemsSize = event.filteredValue.length;
    }

    clearSearchFilter() {
        this.searchValue.nativeElement.value = "";
        this.filteredItemsSize = -1;
    }
  
    ngAfterViewInit() {
      this.searchValue.nativeElement.focus();
    }  
}
