import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { engagementsComponent } from './engagements.component';
import { EngagementsListComponent } from './engagements-list/engagements-list.component';
import { EngagementsViewComponent } from './engagements-view/engagements-view.component';
import { EngagementsHistoryComponent } from './engagements-history/engagements-history.component';
import { EngagementsExpenseComponent } from './engagements-expense/engagements-expense.component';
import { EngagementsNotesComponent } from './engagements-notes/engagements-notes.component';
import { EngagementsUnbilledTimeComponent } from './engagements-unbilled-time/engagements-unbilled-time.component';
import { EngagementsContactComponent } from './engagements-contact/engagements-contact.component';
import { EngagementProjectsComponent } from './engagement-projects/engagement-projects.component';
import { AuthGuard } from '@app/_services';
import { CanDeactivateForm } from './can-deactivate';
import { AppConstants } from '@app/_helpers/api-constants';

const routes: Routes = [
  {
    path: '',
    component: engagementsComponent,
    children: [
      { path: '', component: EngagementsListComponent },
      { path: AppConstants.list, component: EngagementsListComponent},
      { path: AppConstants.view, component: EngagementsViewComponent, canDeactivate: [CanDeactivateForm], data: { 'featID': 45 }},
      { path: AppConstants.contacts, component: EngagementsContactComponent, canActivate: [AuthGuard], data: { 'roles': ["manager"] } },
      { path: AppConstants.InvoiceHistory, component: EngagementsHistoryComponent, canActivate: [AuthGuard], data: { 'roles': ["manager"], 'featID': 17 }},
      { path: AppConstants.expenses, component: EngagementsExpenseComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff"], 'featID': 13 }},
      { path: AppConstants.UnbilledTime, component: EngagementsUnbilledTimeComponent, canActivate: [AuthGuard], data: { 'roles': ["manager", "staff"], 'featID': 13 }},
      { path: AppConstants.notes, component: EngagementsNotesComponent, canActivate: [AuthGuard], data: { 'roles': ["manager"] }},
      { path: AppConstants.projects, component: EngagementProjectsComponent, canActivate: [AuthGuard], data: { 'roles': ['manager', "staff","Senior Manager"], 'plans': ["free", 'enterprise', 'pro'], 'featID': 21 }},
    ],
    canActivate: [AuthGuard],
    data: { 'roles': ["manager", "staff","Senior Manager"] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class EngagementsRoutingModule { }
