import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@app/shared/modules';
import { AppTranslationModule } from '@shared-modules/app.translation.module';

import { TransactionComponent } from './transaction.component';
import { TransactionRoutingModule } from './transaction-routing.module';
import { TransactionCreditCardsComponent } from './transaction-credit-cards/transaction-credit-cards.component';
import { TransactionAchEnrollmentComponent } from './transaction-ach-enrollment/transaction-ach-enrollment.component';
import { TransactionReturnAndVoidComponent } from './transaction-return-and-void/transaction-return-and-void.component';

@NgModule({
  declarations: [TransactionComponent, TransactionCreditCardsComponent, TransactionAchEnrollmentComponent, TransactionReturnAndVoidComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule,
    TransactionRoutingModule
  ]
})
export class TransactionModule { }
