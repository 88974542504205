import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils, AuthGuard } from '@app/_services';
import { Table } from 'primeng/table';
import { SharedComponentsService } from '@app/shared/components';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
declare let numeral: any;

@Component({
  selector: 'app-engagements-unbilled-time',
  templateUrl: './engagements-unbilled-time.component.html'
})
export class EngagementsUnbilledTimeComponent implements OnInit {

  public timeSlips = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  public selectedClientName: string = null;
  projectClientID: number = -1;
  projectID: number = -1;
  selectedEngagementName: string = null;
  totalNonBillableAmount = 0;
  totalBillableTime = 0;
  totalTime = 0;
  timeexp

  public filteredStaffSingle: any[];
  public AllStaffsTypes: any = [];
  public activityList: any = [];
  cellFocused: any = null;

  constructor(private router: Router, public auth: AuthGuard, public sharedSrvc: SharedComponentsService,private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public mangoUtils: mangoUtils, public translate: TranslateService) {
    this.selectedClientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.projectClientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.projectID = this.encrDecSrvc.getObject(AppConstants.projectID);
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses)
    this.selectedEngagementName = this.encrDecSrvc.getObject(AppConstants.selectedEngagementName);
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.engagementsRoutePath + 'U' + AppConstants.SearchString);
    this.getProjectById();
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('engagement') },
      { label: this.translate.instant('Open-Time') },
      { label: this.selectedClientName, icon: 'ic-red' },
      { label: this.selectedEngagementName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.getAllStaffList();
    this.getActivityGroups();

    this.sharedSrvc.timeEntryDialogData
      .subscribe((data) => {
        if (data['ClientID'] && data['ClientID'] != null) {
          this.getProjectById();          
        }
      })
  }

  isLocked(ddate) {
    if(!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)){
      if(this.timeexp.isLockTime && parseInt(this.timeexp.LockTimeDays) > 0) {
        const dDate = moment(ddate).format("MM/DD/YYYY");
        const dateNow = moment().format('MM/DD/YYYY');
        return moment(dateNow).diff(moment(dDate), 'days') > this.timeexp.LockTimeDays
      }
    }
    return false
  }

  getProjectById() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getTimeRecordsByprojectMasterID(this.projectID).subscribe((data: any) => {

      this.timeSlips = data.map((timeSlip) => {
        return {
          ...timeSlip,
          Ddate: this.mangoUtils.addOffset(new Date(timeSlip.Ddate))
        }
      })

      this.loadFilterGrid();
      this.computeFooter();
      this.mangoAPISrvc.fireEngagementBadgeView(true);
      this.mangoAPISrvc.showLoader(false);
    }, (error) => {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    })
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'U' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.engagementsRoutePath + 'U' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  // adding new engagent
  addtimeSheetPopUp() {
    const data = {};
    data['ClientID'] = this.projectClientID;
    data['ClientName'] = this.selectedClientName;
    data['ProjectMasterID'] = this.projectID;
    data['isEditFlow'] = false;
    this.sharedSrvc.openTimeEntryDialog(data);
  }

  openTimeEntryDailog(data) {
    data['ClientID'] = this.projectClientID;
    data['ClientName'] = this.selectedClientName;
    data['ProjectMasterID'] = this.projectID;
    data['isEditFlow'] = true;
    this.sharedSrvc.openTimeEntryDialog(data);
  }

  redirectListView() {
    this.encrDecSrvc.addObject(AppConstants.projectID, "");
    this.mangoAPISrvc.fireEngagementView(true);
    this.router.navigate([AppConstants.engagementsRoutePath + '/' + AppConstants.listRoutePath]);
  }

  deleteEngagement(unique) {
    const parent = this;
    Swal.fire({
      title: parent.translate.instant('confirmation'),
      text: parent.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: parent.translate.instant('yes_delete'),
      cancelButtonText: parent.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.deleteTimeSheet(unique).subscribe(function (data) {
          parent.onDeleteSuccess(unique);
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.deleteMessage);
          parent.mangoAPISrvc.showLoader(false);
        }, error => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }
    })
  }

  getAllStaffList() {
    const parent = this;
    if (parent.AllStaffsTypes.length > 0) {
      return false;
    }
    parent.AllStaffsTypes = [{ label: 'All', value: null }];
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < item.length; ++i) {
      if(item[i].Inactive)
        continue;
        
      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      parent.AllStaffsTypes.push(obj);
    }    
  }

  getActivityGroups() {
    const parent = this;
    if (this.activityList.length > 0) {
      return false;
    }

    let tempList = this.encrDecSrvc.activitiesList;
    tempList = tempList.sort(parent.mangoUtils.compareValues("ServiceCode", "asc"));

    this.activityList = tempList.map((data) => {
      return {
        label: data.ServiceCode + " - " + data.Description,
        value: data.ServiceCodeID,
        fullobj: data,
      }
    });
  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  saveTimeEntry(evt, data) {
    data.StandardAmount = data.StandardAmount
      ? numeral(data.StandardAmount).value()
      : 0.0;

    const obj = {
      Ddate: moment(data.Ddate).format("MM/DD/YYYY"),
      TimeStart: this.removeOffset(new Date(data.TimeStart)),
      TimeStop: data.TimeStop
        ? this.removeOffset(new Date(data.TimeStop))
        : data.TimeStop,
      Approved: data.Approved,
      Billable: data.Billable,
      Billed: data.Billed,
      BillingRate: numeral(data.BillingRate).value().toString(),
      ClientID: data.ClientID,
      ClientName: data.ClientName,
      ElaspedTime: data.TotalTime,
      Description: data.Description,
      Memo: data.Memo,
      nonbillableamount: data.nonbillableamount,
      ServiceCode: data.ServiceCode,
      ServiceCodeID: data.ServiceCodeID,
      StandardAmount: data.StandardAmount.toString(),
      TotalTime: data.TotalTime,
      StaffID: data.StaffID,
      StaffName: data.StaffName,
      StaffDeptID: data.StaffDeptID,
      StaffPayRate: data.StaffPayRate,
      StaffCost: Number(data.TotalTime) * Number(data.StaffPayRate),
      WorkCodeID: data.WorkCodeID,
      OriginatingPartnerID: data.OriginatingPartnerID,
      BillingPartnerID: data.BillingPartnerID,
      GroupDescriptionID: data.GroupDescriptionID,
      GroupDescriptionIDArray: data.GroupDescriptionIDArray,
      ClientTypeID: data.ClientTypeID,
      ProjectMasterID: data.ProjectMasterID,
      PrivateMemo: data.PrivateMemo,
      EngagementTypeID: data.EngagementTypeID,
      IsTimeRecord: "T",
      WorkLocation: data.WorkLocation,
      isFlatFee: data.isFlatFee,
    };

    if (!obj.TimeStop) {
      delete obj.TimeStop;
    }

    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, data.WorkLocation);

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateTimeSheet(data.SlipMasterID, obj).subscribe((resp: any) => {
      this.computeFooter();
      this.mangoAPISrvc.notify("success", "Updated!", AppConstants.updateMsg);
      this.mangoAPISrvc.showLoader(false);
      data.IsColumnChanges = false;
    }, (err) => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
    });
  }

  filterData(query, staffListItems: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < staffListItems.length; i++) {
      const staffItem = staffListItems[i];
      if (staffItem.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(staffItem);
      }
    }
    return filtered;
  }

  filterStaffItems(event, data) {
    this.filteredStaffSingle = this.filterData(
      event.query,
      this.AllStaffsTypes
    );
  }

  handleDropStaffSelectClick(event, data, formtype) {
    if (formtype == "Staff") {
      data["StaffName"] = event.label;
      data["StaffID"] = event.value;
    } else if (formtype == "Activity") {
      data["ServiceCode"] = event.originalEvent.target.textContent;
      data["ServiceCodeID"] = event.value;
    }

    data["IsColumnChanges"] = true;
  }

  replaceShortcuts2(value, desc, type?) {
    desc['IsColumnChanges'] = true;

    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    if (type === "Memo") desc["Memo"] = valueArr.join(" ");
    else {
      desc["description"] = valueArr.join(" ");
    }
  }

  onEditInit(event) {
    this.cellFocused = event;
  }

  onEditComplete() {
    this.cellFocused = null;
  }

  onEditCancel() {
    this.cellFocused = null;
  }

  calculateStandardAmount(data) {
    if(data['BillingRate']) {
      if(data['Billable']) {
        data['billableamount'] = (parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])).toString()
        data['StandardAmount'] = data['billableamount']
      } else {
        data['nonbillableamount'] = (parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])).toString()
        data['StandardAmount'] = data['nonbillableamount']
      }
    }

    return data;
  }

  changeUSMoney(evt: any, data: any) {
    let enteredValue;
    if (evt["target"]) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt["value"] ? evt["value"] : evt["value"];
    }
    if (enteredValue == "" && enteredValue != 0) {
      enteredValue = data.amount;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt["target"]["value"] = data.amount = "$0.00";
      } else {
        evt.setValue("0.00");
      }
    } else {
      if (data) {
        evt["target"]["value"] = data.amount = "$" + numeral(enteredValue).format("0,0.00");
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format("0,0.00"));
      }
    }
  }

  // calls computerFooter when value changes
  onBillRateChange(evt, data) {
    data['BillingRate'] = evt.target.value ? evt.target.value.replace("$", "").replace(",","") : data['BillingRate']; 
    data = this.calculateStandardAmount(data)

    this.computeFooter();
  }

  // calls computerFooter when value changes
  onTotalTimeChange(evt, data) {
    data['TotalTime'] = evt.target.value ? evt.target.value.replace(",","") : data['TotalTime'];  
    data = this.calculateStandardAmount(data)
    this.computeFooter();
  }

  /**
   * onDeleteSuccess:
   * - Will remove the deleted timeSlip from the data instead of refetching data again
   * - After removing, will recompute the footer total using computeFooter function
   * 
   * computeFooter:
   * - In inline editing, FE changes the data source directly (this.timeSlips)
   * - Instead of refetching the data again after saving a row, FE can just re-compute the total based on the (this.timeSlips)
   */
  onDeleteSuccess(id) {
    const newTimeSlips = this.timeSlips.filter((timeSlip) => timeSlip.SlipMasterID !== id);

    this.timeSlips = newTimeSlips;
    this.computeFooter();
  }

  computeFooter() {
    let totalTime = 0;
    let totalBillableTime = 0;
    let totalNonBillableAmount = 0;

    this.timeSlips.forEach((timeSlip) => {
        totalTime += numeral(timeSlip.TotalTime).value();

        if (timeSlip.Billable) {
          totalBillableTime += numeral(timeSlip.StandardAmount).value();
        }
        else {
          totalNonBillableAmount += numeral(timeSlip.StandardAmount).value();
        }
    })

    this.totalTime = totalTime;
    this.totalBillableTime = totalBillableTime;
    this.totalNonBillableAmount = totalNonBillableAmount;
  }
}
