import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportExportComponent } from './import-export.component'
import { ImportExportRoutingModule } from './import-export-routing.module';
import { AppSharedModule } from '@app/shared/modules';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { ImportsComponent } from './imports/imports.component';
import { ExportsComponent } from './exports/exports.component';

@NgModule({
  declarations: [
    ImportExportComponent,
    ImportsComponent,
    ExportsComponent
  ],

  imports: [
    CommonModule,
    AppSharedModule,
    ImportExportRoutingModule,
    AppTranslationModule
  ]
})
export class ImportExportModule { }
