<p-dialog header="{{ 'QuickLinks.language-keys' | translate }}" [(visible)]="showLanguageKeysDialog" [modal]="true"
    [style]="{'width': '50vw'}" showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false"
    (onHide)="closeDialog()">
    <div class="layout-content p-mt-0">
        <p-table #dt [value]="languageKeys" [rowHover]="true" [responsive]="true" [rows]="10" dataKey="key"
            selectionMode="single" [filterDelay]="0" [rowHover]="true"
            styleClass="p-datatable-sm p-datatable-responsive-demo" rowExpandMode="single" sortField="key"
            [sortOrder]="1" [scrollable]="true" scrollHeight="380px" [globalFilterFields]="['key', 'value']"
            [resizableColumns]="true" (onFilter)="onFilter($event)">

            <ng-template pTemplate="caption">
                <div class="table-header p-grid">
                    <div class="p-col">
                        <span class="p-input-icon-left">
                            <i class="fal fa-search"></i>
                            <input #searchValue pInputText type="text"
                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="{{ 'global_filter' | translate }}" />
                        </span>
                        <span>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="dt.reset();clearSearchFilter()" pTooltip="Clear"
                                tooltipPosition="top"></button>
                        </span>
                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                            text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                        </p-message>
                    </div>
                    <div class="p-col-5 p-d-flex p-flex-row p-jc-end">
                        <div class="p-mr-2">
                            <p-dropdown id="lang" [options]="languageOptions" [style]="{'width':'150px'}"
                                [autoWidth]=false name="lang" [(ngModel)]="selectedLanguage"
                                (onChange)="changeLanguage($event)" #lang="ngModel" required></p-dropdown>
                        </div>
                        <div>
                            <button pButton type="button" icon="fa fa-plus"
                                label="{{ 'add' | translate }} {{ 'Key' | translate }}"
                                (click)="openAddLanguageKey()"></button>
                        </div>
                    </div>

                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="width-10p p-text-center">
                        {{ 'actions' | translate }}
                    </th>
                    <th class="p-text-left" pSortableColumn="key">
                        {{ 'Key' | translate }}
                        <p-sortIcon field="key"></p-sortIcon>
                    </th>
                    <th class="p-text-left" pSortableColumn="value">
                        {{ 'Value' | translate }}
                        <p-sortIcon field="value"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr class="cursor-hand">
                    <td class="width-10p p-text-left">
                        <i (click)="openEditLanguageKey(rowData)" class="fal fa-pencil p-mr-3 ic-green" title="Edit"
                            aria-hidden="true" pTooltip="Edit"></i>
                        <i (click)="removeLanguageKey(rowData)" class="fal fa-trash-alt ic-red" title="Delete"
                            aria-hidden="true" pTooltip="Delete"></i>
                    </td>
                    <td class="p-text-left" (click)="openEditLanguageKey(rowData)">
                        {{ rowData.key }}
                    </td>
                    <td class="p-text-left" (click)="openEditLanguageKey(rowData)">
                        {{ rowData.value }}
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="3">
                        No records found
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</p-dialog>

<p-dialog header="{{ 'LanguageKeys.add' | translate }}" [(visible)]="showFormDialog" modal="modal" width="500"
    [responsive]="true">
    <form #langKeyForm="ngForm" class="form-horizontal">
        <div class="p-fluid common-dialog">
            <div class="p-field">
                <label for="lang" class="label-text required-field">{{ 'LanguageKeys.choose' | translate }}</label>
                <div>
                    <p-dropdown id="lang2" [options]="languageOptions" [autoWidth]=false name="lang"
                        [(ngModel)]="languageKeyItem.lang" #lang2="ngModel" required></p-dropdown>
                    <div *ngIf="lang2.touched && lang2.errors?.required" class="p-invalid p-mt-1">
                        Language is Mandatory.
                    </div>
                </div>
            </div>
            <div class="p-field">
                <label for="key" class="label-text required-field">
                    {{ 'Key' | translate }}
                </label>
                <div class="col-sm-8">
                    <input pInputText autocomplete="off" type="text" name="key" [disabled]="isUpdate"
                        [(ngModel)]="languageKeyItem.key" #key="ngModel" required />
                    <div *ngIf="key.touched && key.errors?.required" class="p-invalid p-mt-1">
                        Key is Mandatory.
                    </div>
                </div>
            </div>

            <div class="p-field">
                <label for="value" class="label-text required-field">
                    {{ 'Value' | translate }}
                </label>
                <div class="col-sm-8" style="margin-top:5px">
                    <textarea pInputTextarea style="width: 300px;" name="value" [(ngModel)]="languageKeyItem.value"
                        #value="ngModel" required (focus)="$event.target.select()"></textarea>
                    <div *ngIf="value.touched && value.errors?.required" class="p-invalid p-mt-1">
                        Value is Mandatory.
                    </div>
                </div>
            </div>
        </div>
    </form>
    <p-footer>
        <button pButton icon="fa fa-check" label="{{ 'save_button' | translate }} & {{ 'close' | translate }}"
            [disabled]="!langKeyForm.valid || !langKeyForm.dirty" (click)="saveLangKey(langKeyForm)"></button>
        <button pButton icon="fa fa-close" label="{{ 'cancel_button' | translate }}"
            (click)="closeForm(langKeyForm)"></button>
    </p-footer>
</p-dialog>