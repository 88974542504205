import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: any, args: { searchField: any, searchText: any }): any {

    const searchField = args['searchField'];
    const searchText = args['searchText'];


    if (searchText == null || searchText == undefined) return items;

    return items.filter(function (item) {
      if (!item[searchField]) {
        return false;
      }
      return item[searchField].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    })
  }
}