<p-dialog header="{{'activity' | translate}}" [(visible)]="displayAddActivityModal" [modal]="true"
    [style]="{width: '45vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="1" [draggable]="true"
    [resizable]="false" (onHide)="closeActivity(true)">
    <div class="p-grid common-dialog">
        <div class="p-col-12">
            <div class="card">
                <form #ActivitiesMainForm="ngForm">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="activity" class="required-field label-text">{{'activity-code' |
                                translate}}</label>
                            <input type="text" pInputText class="p-inputtext p-component"
                                [(ngModel)]="activity.ServiceCode" name="serviceCode" (change)="onChangeInputCode()"
                                id="serviceCode" #serviceCode="ngModel" required minlength="2" maxlength="45"
                                (blur)="validateSC(activity)">
                        </div>
                        <div class="p-field p-col">
                            <label for="description" class="label-text">{{'Bill-Rate' | translate}}</label>
                            <span class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="fal fa-dollar-sign"></i></span>
                                <input pInputText [(ngModel)]="activity.BillRate" class="p-text-right"
                                (blur)="transformbillrate($event)" name="billRate" id="billRate" placeholder="0.00" maxlength="10"
                                (focus)="$event.target.select()" />
                            </span>
                        </div>
                        <div class="p-field p-col-12">
                            <label for="description" class="required-field label-text">{{'description' |
                                translate}}</label>
                            <input type="text" pInputText class="p-inputtext p-component"
                                [(ngModel)]="activity.Description" name="description" id="description" #desc="ngModel"
                                required minlength="3" maxlength="100">
                        </div>
                        <div class="p-col-6 p-my-3">
                            <p-dropdown [options]="activityRateTemplates" placeholder="{{ 'rate.select_rate_template' | translate }}" [ngModelOptions]="{ standalone: true }" 
                                scrollHeight="400px" styleClass="width-100p" [(ngModel)]="activity['RateTemplateNamesID']" (ngModelChange)="isActivityChanged = true;">
                            </p-dropdown>
                        </div>
                        <div class="p-grid p-col-6 p-my-4">
                            <div class="p-field p-col-4">
                                <p-checkbox [(ngModel)]="activity.Billable" [binary]="true" name="billable" id="billable" #billable="ngModel"
                                    label="{{'billable' | translate}}"></p-checkbox>
                            </div>
                            <div class="p-field p-col-4" *ngIf="isEditActivity">
                                <p-checkbox [(ngModel)]="activity.Inactive" [binary]="true" name="inactive" id="inactive" #inactive="ngModel"
                                    label="{{'inactive' | translate}}">
                                </p-checkbox>
                            </div>
                            <div class="p-field p-col-4">
                                <p-checkbox [(ngModel)]="activity.Tax1" name="tax1" id="tax1" #tax1="ngModel" [binary]="true" label="{{'taxable' | translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="p-field p-col-12">
                            <label for="description" class="label-text">{{'Default-Work-Memo' | translate}}
                                ({{'Optional' | translate}})</label>
                            <button pButton pRipple (click)="copyToWorkMemo()" tooltipPosition="top"
                                pTooltip="{{'Copy-Description-to-Work-Memo' | translate}}"
                                tooltipStyleClass="tooltip-no-wrap" type="button" icon="fas fa-copy"
                                [disabled]="!activity.Description"
                                class="p-button-rounded p-button-danger p-button-text pull-right height-button"></button>
                            <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="activity.DefaultMemo"
                                pInputTextarea></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_close' | translate}}"
             [disabled]="(!ActivitiesMainForm.valid || !ActivitiesMainForm.dirty ) && (!isActivityChanged || !ActivitiesMainForm.valid)"
            (click)="saveActivity(true,true)" *ngIf="!isEditActivity"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_new' | translate}}"
             [disabled]="(!ActivitiesMainForm.valid || !ActivitiesMainForm.dirty ) && (!isActivityChanged || !ActivitiesMainForm.valid)"
            (click)="saveAndNew(isEditActivity)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_button' | translate}}"
             [disabled]="(!ActivitiesMainForm.valid || !ActivitiesMainForm.dirty ) && (!isActivityChanged || !ActivitiesMainForm.valid)"
            (click)="saveActivity(false,true)" *ngIf="isEditActivity"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="closeActivity(true)"
            label="{{'close' | translate}}" ></button>
    </ng-template>
</p-dialog>