import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedComponentsService } from "@app/shared/components";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  EncrDecrService,
  mangoUtils,
  MangoApiService,
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import Swal from "sweetalert2";
declare let numeral: any;

@Component({
  selector: 'app-budget-rollover-options',
  templateUrl: './budget-rollover-options.component.html',
})

export class BudgetRolloverOptionsComponent implements OnInit {
  companyID: any = null
  isVisible: boolean = true
  rolloverOptions: string = 'existing'
  rolloverObj = {
    isUpdateRevenue: false,
    isUpdateTime: false,
    percentage: 0,
    timePercentage: 0,
    isEnableRollOver: true,
    isDefaultBudgetCompanyRollover: false
  };
  
  @Input() templateObj: {
    CompanyTemplateHeaderID: number,
    isFromProject: boolean,
    isDefaultBudgetCompanyRollover: boolean,
    isEnableRollOver: boolean,
    RollOverOption: string,
    PctOptionTime: number,
    PctOptionRev: number
  };
  @Output() onSaveDialog: any = new EventEmitter<{
    isSaved: boolean,
    isDefaultBudgetCompanyRollover: boolean,
    isEnableRollOver: boolean,
    RollOverOption: string,
    PctOptionTime: number,
    PctOptionRev: number
  }>();

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public sharedSrvc: SharedComponentsService,
    private translate: TranslateService,
    public mangoUtils: mangoUtils
  ) { }

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    if (this.templateObj) {
      this.rolloverOptions = this.templateObj.RollOverOption;
      this.rolloverObj = {
        timePercentage: this.templateObj.PctOptionTime,
        percentage: this.templateObj.PctOptionRev,
        isEnableRollOver: this.templateObj.isEnableRollOver,
        isDefaultBudgetCompanyRollover:
          this.templateObj.isDefaultBudgetCompanyRollover,
        isUpdateRevenue:
          this.templateObj.PctOptionRev &&
          numeral(this.templateObj.PctOptionRev).value() > 0
            ? true
            : false,
        isUpdateTime:
          this.templateObj.PctOptionTime &&
          numeral(this.templateObj.PctOptionTime).value() > 0
            ? true
            : false,
      };
    }
  }

  onHideDialog(isSaved: boolean = false) {
    this.onSaveDialog.emit({
      isSaved,
      RollOverOption: this.rolloverOptions,
      isDefaultBudgetCompanyRollover:
        this.rolloverObj.isDefaultBudgetCompanyRollover,
      isEnableRollOver: this.rolloverObj.isEnableRollOver,
      PctOptionTime: this.rolloverObj.timePercentage,
      PctOptionRev: this.rolloverObj.percentage,
    });
  }

  onSaveTemplate() {
    const obj = {
      //save Rollover options
      isDefaultBudgetCompanyRollover:
        this.rolloverObj.isDefaultBudgetCompanyRollover,
      isEnableRollOver: this.rolloverObj.isEnableRollOver,
      RollOverOption: this.rolloverOptions,
      PctOptionTime:
        this.rolloverObj.isUpdateTime && this.rolloverOptions == "percentage"
          ? numeral(this.rolloverObj.timePercentage).value()
          : 0,
      PctOptionRev:
        this.rolloverObj.isUpdateRevenue && this.rolloverOptions == "percentage"
          ? numeral(this.rolloverObj.percentage).value()
          : 0,
      CompanyTemplateHeaderID: this.templateObj.CompanyTemplateHeaderID
    }

    console.log("Saving...", obj)
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateCompanyTemplateDetails(obj).subscribe(
      (data: any) => {
        this.mangoAPISrvc.showLoader(false); 
        this.mangoAPISrvc.notify("success", this.translate.instant('Success'), AppConstants.updateMsg);
        this.onHideDialog(true);
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify("error", this.translate.instant('Error'), AppConstants.updateErrorMsg);
      }
    );
  }

  shouldDisable() {
    return !((this.rolloverOptions != "percentage") ||
    (this.rolloverObj.isDefaultBudgetCompanyRollover) ||
    (this.rolloverOptions == "percentage" &&
      ((!this.templateObj.isFromProject &&
        ((this.rolloverObj.isUpdateRevenue &&
          this.rolloverObj.percentage) ||
          (this.rolloverObj.isUpdateTime &&
            this.rolloverObj.timePercentage))) ||
        (this.templateObj.isFromProject &&
          this.rolloverObj.isUpdateTime &&
          this.rolloverObj.timePercentage))))
  }

}
