<p-dialog [showHeader]="false" [(visible)]="showTaskDialog" [modal]="true" [style]="{width: '700px'}"
    [maximizable]="true" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12 p-text-center">
            <div>
                <i class="fal fa-info-circle sur-icon ic-red"></i>
            </div>
            <div style="font-size: 14px;margin: 30px 0;">
                Surcharging is now available in Mango Payments. Would you like to review surcharging now?
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <span style="font-weight: 500;font-style: italic;position: relative;top: -8px;" class="p-pr-4">
            <p-checkbox [(ngModel)]="isNotShowSurcharging" binary="true" inputId="binary"></p-checkbox>
            <label for="binary" class="p-ml-2 ic-red">Don't Show Again</label>
        </span>
        <button type="button" pButton pRipple (click)="redirectToStaxViewScreen(true)" label="Review Now"></button>
        <button type="button" pButton pRipple label="{{ 'cancel_button' | translate }}"
            (click)="redirectToStaxViewScreen(false)"></button>
    </ng-template>
</p-dialog>