<div class="merchant-setup">
    <div class="layout-content">
        <div class="p-col-12 p-pt-0">
            <div class="card p-py-3 p-mb-2">
                <div class="p-grid">
                    <div class="p-col">
                        <label class="label-text p-d-block" for="range">Date Range</label>
                        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"
                            inputId="range" [style]="{'width':'100%'}" (onSelect)="prepareQueryString(true)">
                        </p-calendar>
                    </div>
                    <div class="p-col">
                        <label class="label-text p-d-block" for="Method">{{ 'Method' | translate }}</label>
                        <p-dropdown [options]="methodTypes" [(ngModel)]="searchObj.method" [ngModelOptions]="{ standalone: true }"
                            (onChange)="prepareQueryString(true)" [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                    <div class="p-col">
                        <label class="label-text p-d-block" for="Type">{{ 'Type' | translate }}</label>
                        <p-dropdown [options]="statusTypes" [(ngModel)]="searchObj.type" [ngModelOptions]="{ standalone: true }"
                            (onChange)="prepareQueryString(true)" [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                    <div class="p-col">
                        <label class="label-text p-d-block" for="Success">{{ 'Transation Status' | translate }}</label> 
                        <p-dropdown [options]="successTypes" [(ngModel)]="searchObj.success" (onChange)="filterData('success')" [ngModelOptions]="{ standalone: true }"
                            [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                    <div class="p-col">
                        <label class="label-text p-d-block" for="Success">{{ 'Transactions (All/Unapplied)' | translate }}</label> 
                        <p-dropdown [options]="transationsTypes" [(ngModel)]="searchObj.transactions" (onChange)="filterData('Transactions')" [ngModelOptions]="{ standalone: true }"
                            [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                    <div class="p-col p-text-center">
                        <label class="label-text p-d-block" for="tax">{{ 'Total Sales' | translate }}</label>
                        <div class="p-pt-2 p-text-bold ic-red ic-size-18">{{totalsales | currency : 'USD' : true :
                            '1.2-2'}}</div>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="card p-pt-0">
                <p-table #dt [value]="fullDataSource" dataKey="id" [responsive]="true" [rows]="10"
                    rowExpandMode="single" [rowHover]="true" sortField="created_at" [sortOrder]="-1"
                    (sortFunction)="customSort($event)" [customSort]="true" rowExpandMode="single"
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    [globalFilterFields]="['userName','total', 'ClientName', 'id', 'created_at']" [scrollable]="true"
                    [scrollHeight]="'calc(100vh - 350px)'" [filterDelay]="0" (onFilter)="onFilter($event)">
                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <span class="p-input-icon-left">
                                <i class="fal fa-search"></i>
                                <input #searchValue pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{ 'globalsearch' | translate }}" />
                            </span>
                            <span>
                                <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="dt.reset();clearSearchFilter()" tooltipPosition="to"
                                    pTooltip="{{ 'clear' | translate }}"></button>
                            </span>
                            <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            <p-button label="Electronic Payments Dashboard" [routerLink]="['/accounting/cards-view']"
                                styleClass="p-button-link ic-red ic-size-15 text-decoration-underline pull-right"
                                class="text-decoration-underline">
                            </p-button>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width-6p p-text-center" pSortableColumn="created_at">
                                {{ 'Date' | translate }}
                                <p-sortIcon field="created_at"></p-sortIcon>
                            </th>
                            <!-- <th class="width-20p p-text-left">
                                {{ 'Transaction Reference' | translate }}
                            </th> -->
                            <th class="width-10p p-text-left" pSortableColumn="userName">
                                {{ 'Customer' | translate }}
                                <p-sortIcon field="userName"></p-sortIcon>
                            </th>
                            <th class="width-10p p-text-left" pSortableColumn="ClientName">
                                {{ 'client.name' | translate }}
                                <p-sortIcon field="ClientName"></p-sortIcon>
                            </th>
                            <th class="width-10p p-text-center" pSortableColumn="bankType">
                                {{ 'Payment Method' | translate }}
                                <p-sortIcon field="bankType"></p-sortIcon>
                            </th>
                            <th class="width-8p p-text-center">
                                {{ 'bank_holder_type' | translate }}
                            </th>
                            <th class="width-8p p-text-right" pSortableColumn="total">
                                {{ 'Amount' | translate }}
                                <p-sortIcon field="total"></p-sortIcon>
                            </th>
                            <th class="width-8p p-text-center">
                                {{ 'Action' | translate }}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr class="cursor-hand" *ngIf="rowData.isShow && rowData.isTransationShow">
                            <td class="width-6p p-text-center">{{rowData.created_at | date:'MM/dd/yyyy'}}
                            </td>
                            <!-- <td class="width-20p p-text-left">{{rowData.id}}
                            </td> -->
                            <td class="width-9p p-text-left p-pl-0">
                                {{rowData.userName}}
                            </td>
                            <td class="width-11p p-text-left" pTooltip="{{rowData.ClientName}}" showDelay="800"
                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                {{rowData.ClientName}}
                            </td>
                            <td class="width-10p p-text-left p-text-capitalize">
                                <span *ngIf="!rowData.payment_method.card_type">
                                    <i class="far fa-university ic-green ic-size-22" tooltipPosition="top"
                                        pTooltip="{{rowData.payment_method.bank_type}}" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap"></i>
                                </span>
                                <span *ngIf="rowData.payment_method.card_type === 'visa'" tooltipPosition="top"
                                    pTooltip="Visa" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    <img src="assets/mango/svg/visa.svg">
                                </span>
                                <span *ngIf="rowData.payment_method.card_type === 'mastercard'" tooltipPosition="top"
                                    pTooltip="Mastercard" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    <img src="assets/mango/svg/mastercard.svg">
                                </span>
                                <span *ngIf="rowData.payment_method.card_type === 'discover'" tooltipPosition="top"
                                    pTooltip="Discover" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    <img src="assets/mango/svg/discover.svg">
                                </span>
                                <span *ngIf="rowData.payment_method.card_type === 'amex'" tooltipPosition="top"
                                    pTooltip="Amex" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    <img src="assets/mango/svg/amex.svg">
                                </span>
                                <span *ngIf="rowData.payment_method.card_type"
                                    class="stax-card-label">{{rowData.last_four}}</span>
                                <span *ngIf="!rowData.payment_method.card_type"
                                    class="stax-bank-label">{{rowData.last_four}}</span>
                            </td>
                            <td class="width-7p p-text-center" style="text-transform: capitalize">
                                {{rowData.payment_method.bank_holder_type}}</td>
                            <td class="width-8p p-text-right">{{rowData.total | currency : 'USD' : true :
                                '1.2-2'}}</td>
                            <td class="width-8p p-text-center">
                                <!-- <span *ngIf="!rowData.payment_method.card_type && rowData.isError == false"
                                    pTooltip="{{rowData.message}}" tooltipStyleClass="tooltip-width-800">
                                    -
                                </span>
                            && rowData.payment_method.card_type
                            
                            -->

                                <span *ngIf="!rowData.isChild_transactions && rowData.success == true">
                                    <button pButton pRipple label="{{ 'Refund' | translate }}"
                                        class="p-button-sm p-mr-2" (click)="refundItem(rowData)"
                                        [disabled]="!rowData.is_refundable"></button>
                                    <button pButton pRipple label="{{ 'Void' | translate }}" class="p-button-sm p-mr-2"
                                        (click)="voidFund(rowData)" [disabled]="rowData.is_refundable"
                                        [style.visibility]="rowData.method !== 'bank' ? 'visible' : 'hidden'"></button>
                                    <button pButton pRipple label="{{ 'Accounts.apply' | translate }}"
                                        class="p-button-sm" (click)="applyClients(rowData)"
                                        [ngStyle]="{visibility: rowData.isHostedPayment ? 'visible' : 'hidden'}"></button>
                                </span>
                                <span
                                    *ngIf="rowData.isChild_transactions && rowData.success == true && rowData.payment_method.card_type"
                                    class="ic-red">
                                    <span *ngIf="rowData.is_voided == true">Voided</span>
                                    <span *ngIf="rowData.is_voided == false">Refunded</span>
                                </span>
                                <span *ngIf="rowData.isError == true && rowData.errorMessage"
                                    title="{{rowData.errorMessage}}">
                                    <i class="fas fa-exclamation-circle ic-red ic-size-22" tooltipPosition="left"
                                        pTooltip="{{rowData.errorMessage}}" tooltipStyleClass="tooltip-width-800"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">
                                <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                    styleClass="p-px-2">
                                </p-message>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-dialog header="{{actionStr}} Transaction" [(visible)]="refundDialog" [modal]="true" [style]="{width: '50vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card">
                <div class="p-grid">
                    <div class="p-col-7">
                        <div class="p-fluid">
                            <div class="p-field">
                                <label for="customer" class="label-text p-text-bold">CUSTOMER</label>
                                <div>{{selectedInvoice.userName}}</div>
                            </div>
                            <div class="p-field">
                                <label for="lastname1" class="label-text p-text-bold">TRANSACTION ID</label>
                                <div>{{selectedInvoice.id}}</div>
                            </div>
                            <div class="p-field" *ngIf="selectedInvoice.meta">
                                <label for="lastname1" class="label-text p-text-bold">MEMO</label>
                                <div tooltipPosition="top" class="p-text-nowrap p-text-truncate"
                                    pTooltip="{{selectedInvoice.meta.memo}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap">{{selectedInvoice['meta']['memo']}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-5">
                        <div class="p-field">
                            <label for="customer" class="label-text p-text-bold">TOTAL</label>
                            <div class="ic-red ic-size-18">{{selectedInvoice.total | currency : 'USD' : true :
                                '1.2-2'}}</div>
                        </div>
                        <div class="p-field">
                            <label for="customer" class="label-text p-text-bold">REFUND TOTAL (NET:
                                {{selectedInvoice.total | currency : 'USD' : true :
                                '1.2-2'}})</label>
                            <p-inputNumber [(ngModel)]="selectedInvoice.refundAmount" mode="currency" currency="USD"
                                locale="en-US" [maxFractionDigits]="2" [min]="0.01" [max]="selectedInvoice.total"
                                [disabled]="true">
                            </p-inputNumber>
                            <!-- <label for="customer" class="ic-size-12 p-text-light">Only full refunds are available before
                                12AM EST.</label> -->
                            <!-- <label for="customer" class="ic-size-11 p-text-light ic-red">{{transationMsg}}</label> -->
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="mangoElement.detailsRecords.length > 0 && isRefundFlow">
                        <p-table [value]="mangoElement.detailsRecords" dataKey="PaymentDetailID"
                            [(selection)]="selectedInvoices" [responsive]="true" [rows]="10"
                            (onHeaderCheckboxToggle)="CheckInvoice()"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th>Invoice #</th>
                                    <th>Date</th>
                                    <th>Note</th>
                                    <th>Amount</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="product" (click)="CheckInvoice()"></p-tableCheckbox>
                                    </td>
                                    <td>{{product.InvoiceNumber}}</td>
                                    <td>{{product.InvoiceDate | date:'MM/dd/yyyy'}}</td>
                                    <td>{{product.PaymentNote}}</td>
                                    <td>{{product.AppliedAmount}}</td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check"
            [disabled]="(selectedInvoices.length == 0 && mangoElement.detailsRecords.length > 0)"
            (click)="updateMangoElements();" label="{{actionStr}} {{selectedInvoice.refundAmount | currency : 'USD' : true :
        '1.2-2'}}"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="closeVoidDialog()" label="Cancel"></button>
    </ng-template>
</p-dialog>
<p-sidebar [(visible)]="visibleSidebar" [closeOnEscape]="false" [dismissible]="false" [showCloseIcon]="false"
    position="right" [style]="{'min-width':'50%','overflow': 'hidden','max-height':'98%'}">
    <div class="p-fluid p-formgrid p-grid p-my-4 p-py-5 p-mx-3">
        <div class="p-grid p-col-12" *ngIf="transactionData">
            <div class="p-col-3">
                <label for="name" class="label-text p-d-block p-mb-1">{{ 'Accounts.invoice-date' | translate
                    }}</label>
                <span class="p-text-nowrap p-text-truncate  p-text-bold">
                    {{transactionData.created_at | date:'MM/dd/yyyy'}}</span>
            </div>
            <div class="p-col-3">
                <label for="name" class="label-text p-d-block p-mb-1">{{ 'Accounts.payment-amount' | translate
                    }}</label>
                <span class="p-text-nowrap p-text-truncate  p-text-bold">
                    {{transactionData.meta.subtotal || 0 | currency : 'USD' : true :
                    '1.2-2'}}</span>
            </div>
            <div class="p-col-3">
                <label for="name" class="label-text p-d-block p-mb-1">{{ 'billing-invoicing.name-on-card' | translate
                    }}</label>
                <span class="p-text-nowrap p-text-truncate  p-text-bold">
                    {{transactionData.payment_method.person_name}}</span>
            </div>
            <div class="p-col-3">
                <label for="name" class="label-text p-d-block p-mb-1">{{ 'billing.last-4-digits' | translate
                    }}</label>
                <span class="p-text-nowrap p-text-truncate  p-text-bold">
                    XXX{{transactionData.last_four}}</span>
            </div>
            <!-- <div class="p-field">
                <label for="name" class="label-text p-d-block">{{ 'Card-Type' | translate
                    }}</label>
                <span class="p-text-nowrap p-text-truncate  p-text-bold">
                     {{transactionData.payment_method.bin_type == 'CREDIT' ? 'Credit Card' : 'ACH'}}</span>
            </div> -->
            <div class="p-col-12 p-my-2">
                <label for="name" class="label-text p-d-block">{{ 'memo' | translate
                    }}</label>
                <span class="p-text-nowrap p-text-truncate p-text-bold">
                    {{transactionData.HostedPaymentMemo}}</span>
            </div>
        </div>
        <!-- <div class="p-field p-col-12 p-md-12">
            <button pButton pRipple type="button" label="Save" class="p-button-sm p-pr-3 p-mr-2 p-my-2 host-btn"
                [disabled]="!selectedClient" (click)="applyPayment();"></button>
            <button pButton pRipple type="button" label="Cancel" class="p-button-sm p-pr-3 p-mr-2 p-my-2 host-btn"
                [disabled]="!selectedClient" (click)="cancelDialog();"></button>
        </div> -->
        <div class="p-field p-col-12 p-md-3">
            <label class="label-text">Amount</label>
            <div class="ic-red p-text-bold">{{ totalAmtApplied | currency : 'USD' : true : '1.2-2'}}</div>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label class="label-text">Unapplied Amount</label>
            <div class="ic-red p-text-bold">{{ totalUnAmtApplied | currency : 'USD' : true : '1.2-2'}}</div>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label class="label-text">Clients</label>
            <div>
                <p-dropdown [options]="clients" [(ngModel)]="selectedClient" optionLabel="label" filter="true"
                    [disabled]="selectedItems.length > 0" (onChange)="changeClientName()">
                </p-dropdown>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <p-table [value]="invoices" [scrollable]="true" scrollHeight="320px">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="width-12p p-text-center">{{'Accounts.invoice-date' | translate}}</th>
                        <th class="width-12p p-text-center">{{'Accounts.invoice-number' | translate}}</th>
                        <th class="width-12p p-text-right">{{'Accounts.invoice-amount' | translate}}</th>
                        <th class="width-12p p-text-right">{{'Accounts.invoice-balance' | translate}}</th>
                        <th class="width-15p p-text-right">{{ 'total-payments' | translate }}</th>
                        <th class="width-10p p-text-right">{{'Accounts.apply' | translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr>
                        <td class="width-12p p-text-center">{{invoice.InvoiceDate | date:'MM/dd/yyyy'}}</td>
                        <td class="width-12p p-text-center">{{invoice.InvoiceNumber}}</td>
                        <td class="width-12p p-text-right">{{invoice.InvoiceAmount | currency : 'USD' : true : '1.2-2'}}
                        </td>
                        <td class="width-12p p-text-right">{{invoice.InvoiceBalance | currency : 'USD' : true :
                            '1.2-2'}}</td>
                        <td class="width-15p p-text-right">{{invoice.TotalPayments | currency : 'USD' : true :
                            '1.2-2'}}</td>
                        <td class="width-10p p-text-right">
                            <p-checkbox [(ngModel)]="invoice['isChecked']" binary="true"
                                [disabled]="invoice['isChecked'] == false && totalAmtApplied == 0"
                                [ngModelOptions]="{ standalone: true }" (onChange)="onAmountChange(invoice)">
                            </p-checkbox>
                        </td>
                    </tr>
                </ng-template>
                <!-- No data -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="5">
                            <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                            </p-message>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="p-field p-col-12 p-md-12" style="position: fixed;bottom: 0;">
            <button pButton pRipple type="button" label="Save" class="p-button-sm p-pr-3 p-mr-2 p-my-2 host-btn"
                [disabled]="!selectedClient" (click)="applyPayment();" [disabled]="!selClient"></button>
            <button pButton pRipple type="button" label="Cancel" class="p-button-sm p-pr-3 p-mr-2 p-my-2 host-btn"
                (click)="cancelDialog();"></button>
        </div>
    </div>
</p-sidebar>