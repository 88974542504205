import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PathLocationStrategy, LocationStrategy } from "@angular/common";
import { AppTranslationModule } from "@shared-modules/app.translation.module";
import { RouterModule } from "@angular/router";

// Application Components
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppLoginComponent } from "./pages/app.login.component";
import { AppRegisterComponent } from "./pages/register/register.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";

// Application services
import { MenuService } from "./app.menu.service";
// Modules
import { AppCodeModule } from "./app.code.component";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { ClientModule } from "./modules/client/client.module";
import { EngagementsModule } from "./modules/engagements/engagements.module";
import { TimeExpenseModule } from "./modules/time-expense/time-expense.module";
import { BillingInvoicingModule } from "./modules/billing-invoicing/billing-invoicing.module";
import { AccountingModule } from "./modules/accounting/accounting.module";
import { SchedulingModule } from "./modules/scheduling/scheduling.module";
import { ReportsModule } from "./modules/reports/reports.module";
import { ProjectManagementModule } from "./modules/project-management/project-management.module";
import { DocumentManagementModule } from "./modules/document-management/document-management.module";
import { UsersModule } from "./modules/users/users.module";
import { SettingsModule } from "./modules/settings/settings.module";
import { SetupWizardModule } from "./modules/setup-wizard/setup-wizard.module";
import { AdminModule } from "./modules/admin/admin.module";
import { CompanyModule } from "./modules/company/company.module";
//import { SubscriptionModule } from './modules/subscription/subscription.module'; //:@deprecate-stripe;
import { ImportExportModule } from "./modules/import-export/import-export.module";
import { TransactionModule } from "./modules/transaction/transaction.module";

import { AppSharedModule } from "@shared-modules/app-shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// Services
import { environment } from "@environments/environment";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
import {
  CookieService,
  MangoApiService,
  mangoUtils,
  AuthGuard,
  AuthService,
  BreadcrumbService,
  EncrDecrService,
} from "@app/_services";
import { MessageService, ConfirmationService } from "primeng/api";
import {
  NotesComponent,
  EngagementComponent,
  ShortcutsComponent,
  ContactComponent,
  TaskComponent,
  TimerComponent,
  LoaderComponent,
  MessageComponent,
  ToDoComponent,
  ExpenseEntryComponent,
  AppSharedDialogs,
  InvoiceComponent,
  SharedComponentsService,
  LanguageKeysComponent,
  TimesEntryComponent,
  UpcomingEventsComponents,
} from "@app/shared/components";
import { ActivityComponent } from "./shared/components/activity/activity.component";
import { ExpensesComponent } from "./shared/components/expenses/expenses.component";
import { InactivityComponent } from "./shared/components/inactivity/inactivity.component";
import { BadgeModule } from "primeng/badge";
import { AppPaymentComponent } from "./pages/app-payment/app-payment.component";
import { FilesandfoldersComponent } from "./pages/filesandfolders/filesandfolders.component";
import { FileManagerAllModule } from "@syncfusion/ej2-angular-filemanager";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { ContextMenuModule, ToolbarModule } from "@syncfusion/ej2-angular-navigations";
import { HostedPageComponent } from "./pages/hosted-page/hosted-page.component";

import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
import { ApmModule, ApmService } from '@elastic/apm-rum-angular'
export function createTranslateLoader(http: HttpClient) {
  const baseUrl: string = environment.API_URL;
  const str = new Date().getTime();
  return new TranslateHttpLoader(http, `${baseUrl}/language/keys/`, `?cache=${str}`);
}
// AOT compilation support
const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
  isolate: false,
};

@NgModule({
  imports: [
    BadgeModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AppCodeModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    DashboardModule,
    ClientModule,
    EngagementsModule,
    TimeExpenseModule,
    BillingInvoicingModule,
    AccountingModule,
    SchedulingModule,
    ReportsModule,
    ProjectManagementModule,
    DocumentManagementModule,
    UsersModule,
    SettingsModule,
    AppSharedModule,
    SetupWizardModule,
    AdminModule,
    CompanyModule,
    //SubscriptionModule, //:@deprecate-stripe;
    ImportExportModule,
    TransactionModule,
    AppTranslationModule,
    RouterModule,
    FileManagerAllModule,
    DialogModule,
    ContextMenuModule,
    ToolbarModule,
    TranslateModule.forRoot(translationOptions),
    ApmModule
  ],
  exports: [AppSharedModule, LoaderComponent, RouterModule],
  declarations: [
    AppComponent,
    LoaderComponent,
    AppLoginComponent,
    AppRegisterComponent,
    ResetPasswordComponent,
    MessageComponent,
    TimesEntryComponent,
    ExpenseEntryComponent,
    NotesComponent,
    TaskComponent,
    TimerComponent,
    EngagementComponent,
    ShortcutsComponent,
    ContactComponent,
    AppSharedDialogs,
    ToDoComponent,
    UpcomingEventsComponents,
    InvoiceComponent,
    LanguageKeysComponent,
    ActivityComponent,
    ExpensesComponent,
    InactivityComponent,
    AppPaymentComponent,
    FilesandfoldersComponent,
    HostedPageComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.RC_KEY,
      } as RecaptchaSettings,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    MenuService,
    BreadcrumbService,
    MangoApiService,
    CookieService,
    mangoUtils,
    EncrDecrService,
    AuthGuard,
    AuthService,
    MessageService,
    ConfirmationService,
    SharedComponentsService,
    ApmService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(service: ApmService) {
    // Agent API is exposed through this apm instance
    try {
      const apm = service.init({
        serviceName: 'mango-ui',
        serverUrl: 'https://apm.elk.mangobilling.com/',
        environment: environment.ENVIRONMENT_NAME,
        flushInterval: 3000,
        distributedTracing: false,
        active: ['production', 'test'].includes(environment.ENVIRONMENT_NAME),
      });
      console.log('APM RUM Agent initialized');
      
    } catch (error) {
      console.error('Error initializing APM RUM Agent', error);
      
    }
  }
}
