<div class="layout-content p-px-2">
  <div class="p-grid">
    <div class="p-col-6">
      <div class="card">
        <div class="content-wrapper">
          <div class="p-mb-2">
            <label class="label-text">{{ 'Salida de regla' | translate }}</label>
            <div id="rule-output">FREQ=DAILY;INTERVAL=1;</div>
          </div>
          <ejs-recurrenceeditor #recurrenceObj id='RecurrenceEditor' [selectedType]='selectedType' (change)="onChange($event)">
          </ejs-recurrenceeditor>
        </div>
      </div>
    </div>
    <div class="p-col-4">
      <div class="card">
        <div class="content-wrapper p-col-4">
          <h4 class="text-danger">{{'Dates' | translate}}</h4>
          <div class="p-mt-1" *ngFor="let dateStr of dateSource">{{dateStr | date: "MM/dd/yyyy" }}</div>
        </div>
      </div>
    </div>
  </div>
</div>