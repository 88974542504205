import { Component, OnInit } from "@angular/core";
import moment from "moment";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import { TranslateService } from "@ngx-translate/core";
declare let numeral: any;

@Component({
  selector: "app-cb-receipts",
  templateUrl: "./receipts.component.html",
})
export class ReceiptsComponent implements OnInit {
  data: any;
  options: any;
  statistics: any = {
    cashCollected: {
      currentMonth: 0,
      lastYearMonth: 0,
      currentYear: 0,
      lastYear: 0,
      writeOffAmt: 0,
      accountsReceivable: 0,
      AROver30Days: 0,
    },
    // customers: {
    //     new: 0,
    //     inactive: 0
    // },
    // tasks: {
    //     open: 0,
    //     pastDue: 0,
    //     completed: 0
    // },
    cashCollectedMonthly: [],
  };

  colors: any = ['#e57368', '#42a5f5', '#3366cc']

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private _translate: TranslateService
  ) {
    const interval = setInterval(() => {
      if(!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.options = {
      title: {
        display: true,
        text: "Year on Year Comparison",
        fontSize: 16,
      },
      legend: {
        position: "bottom",
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
                label += ': $';
            }
            label += numeral(tooltipItem.yLabel).format("0,0");
            return label;
        }
        }
      }
    };
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this._translate.instant('Company_Dashboard') },
      { label: this._translate.instant('Accounts.cash-receipts'), icon: "ic-red" },
    ]);
  }

  ngOnInit(): void {
    this.fetchStats();
  }

  private fetchStats() {
    const self = this;

    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.fetchStats(moment().format('YYYY-MM-DD hh:mm:ss')).subscribe(
      (result: any) => {
        self.statistics = result;
        self.drawCharts();
        this.mangoAPISrvc.showLoader(false)
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false)
        self.mangoAPISrvc.notify("error", "Error!", AppConstants.fetchErrorMsg);
      }
    );
  }

  private drawCharts() {
  
    const series = this.statistics.cashCollectedMonthly.map((yearStats, index) => {
      return {
        label: yearStats.name,
        backgroundColor: this.colors[index],
        borderColor: this.colors[index],
        data: yearStats.data,
      };
    });

    this.data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: series,
    };
  }
}
