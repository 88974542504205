<div class="client-settings">
    <form #clientSettingForm="ngForm" autocomplete="nope">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{ 'save_button' | translate }}" icon="fas fa-check"
                class="p-mr-1 p-button-sm" (click)="saveSetting()"
                [disabled]="!clientSettingForm.valid || !clientSettingForm.dirty"></button>
        </span>
        <div class="layout-content common-dialog">
            <div class="p-fluid p-formgrid p-grid p-mx-2">
                <div class="p-col-4">
                    <div class="card" style="min-height: 250px;">
                        <h4>{{ 'client.invoice_settings' | translate }}</h4>
                        <div class="p-field p-col-12">
                            <label class="p-d-block label-text">{{ 'client.default_invoice_template' | translate
                                }}</label>
                            <p-dropdown [options]="invoiceTemplates" placeholder="Select Template"
                                [(ngModel)]="selectedInvoiceTemplate" scrollHeight="400px"
                                name="selectedInvoiceTemplate" (ngModelChange)="changeInvoiceTemplates($event)"></p-dropdown>
                        </div>
                        <div class="p-field p-col-12">
                            <label class="p-d-block label-text">{{ 'client.default_action' | translate }}</label>
                            <p-dropdown [options]="finalizeActions" placeholder="Select Action"
                                [(ngModel)]="FinalizeAction" (onChange)="changefinalizeActions()" name="FinalizeAction">
                            </p-dropdown>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox [binary]="true" label="{{ 'settings.show_optional_tear_off_footer' | translate }}"
                                [(ngModel)]="isShowTearOffFooter" name="isShowTearOffFooter" (onChange)="isShowTearOffFooterChanged = !isShowTearOffFooterChanged">
                            </p-checkbox>
                        </div>
                        <!--  end of card -->
                    </div>
                </div>
                <div class="p-col-4">
                    <div class="card" style="min-height: 250px;">
                        <h4>{{ 'client.late_fees' | translate }}</h4>
                        <div class="p-field p-col-12">
                            <p-checkbox [binary]="true" label="{{ 'client.use_company_defaults' | translate }}"
                                [(ngModel)]="UseDefaultTerms" name="UseDefaultTerms" (onChange)="loadFromCompany(true)">
                            </p-checkbox>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="groupname" value="true" label="{{ 'none' | translate }}"
                                [(ngModel)]="NoLateFees" inputId="opt1" (ngModelChange)="changeLateFees($event)"></p-radioButton>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="groupname" value="false"
                                label="{{ 'client.interest_rate' | translate }}" [(ngModel)]="NoLateFees"
                                inputId="opt2" (ngModelChange)="changeLateFees($event)">
                            </p-radioButton>
                        </div>
                        <div class="p-field-radiobutton p-col-12"
                            [ngStyle]="{'display': NoLateFees === 'false' ? 'block' : 'none'}">
                            <label class="p-d-block label-text p-mb-2">{{'settings.Annual-Interest-Rate' |
                                translate}}</label>
                            <p-inputNumber name="InterestRate" [(ngModel)]="InterestRate" inputStyleClass="p-text-right"
                                inputId="percent" Suffix="%" mode="decimal" [minFractionDigits]="2" maxlength="20"
                                (ngModelChange)="changeInterestRate($event)">
                            </p-inputNumber>
                        </div>
                        <div class="p-field p-col-12">
                            <label class="label-text">{{ 'client.customer_terms' | translate }}
                                <i tooltipPosition="top"
                                    pTooltip="{{'client.customer_terms_tooltip' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                            </label>
                            <p-dropdown [options]="termsList" [(ngModel)]="selectedTerm" placeholder="Select Terms"
                                (onChange)="selectedTermChange($event)" scrollHeight="400px" name="selectedTerm">
                            </p-dropdown>
                        </div>
                        <div class="p-field p-col-12">
                            <label class="label-text">{{ 'client.grace_days' | translate }}
                                <i tooltipPosition="top"
                                    pTooltip="{{'client.grace_days_tooltip' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                            </label>
                            <p-inputNumber [(ngModel)]="GraceDays" mode="decimal" inputStyleClass="p-text-right"
                                name="graceDays" inputId="withoutgrouping" [useGrouping]="false"
                                (ngModelChange)="changeGraceDays($event)">
                            </p-inputNumber>
                        </div>
                        <!--  end of card -->
                    </div>
                </div>
                <div class="p-col-4">
                    <div class="card" style="height: 100%;">
                        <h4>{{ 'client_electronic_payment_options' | translate }}</h4>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="group2" value="4" label="{{ 'client.firm_defaults' | translate }}"
                                [(ngModel)]="modeOfPayment" inputId="preopt6"
                                (onClick)="modeOfPayment = 4;clientSettingForm.control.markAsDirty()"
                                (ngModelChange)="changeModeOfPayment($event)">
                            </p-radioButton>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="group2" value="0" label="{{ 'client.cc_only' | translate }}"
                                [(ngModel)]="modeOfPayment" inputId="preopt1"
                                (onClick)="modeOfPayment = 0;clientSettingForm.control.markAsDirty()"
                                (ngModelChange)="changeModeOfPayment($event)">
                            </p-radioButton>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="group2" value="1" label="{{ 'client.ach_only' | translate }}"
                                [(ngModel)]="modeOfPayment" inputId="preopt2"
                                (onClick)="modeOfPayment = 1;clientSettingForm.control.markAsDirty()"
                                (ngModelChange)="changeModeOfPayment($event)">
                            </p-radioButton>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="group2" value="2" label="{{ 'all_methods' | translate }}"
                                [(ngModel)]="modeOfPayment" inputId="preopt4"
                                (onClick)="modeOfPayment = 2;clientSettingForm.control.markAsDirty()"
                                (ngModelChange)="changeModeOfPayment($event)">
                            </p-radioButton>
                        </div>
                        <div class="p-field-radiobutton p-col-12">
                            <p-radioButton name="group2" value="3"
                                label="{{ 'client.no_payments_allowed' | translate }}" [(ngModel)]="modeOfPayment"
                                inputId="preopt5" (onClick)="modeOfPayment = 3;clientSettingForm.control.markAsDirty()"
                                (ngModelChange)="changeModeOfPayment($event)">
                            </p-radioButton>
                        </div>
                        <!--  end of card -->
                    </div>
                </div>
                <div class="p-col-4 p-my-2" style="padding-bottom: 200px;">
                    <div class="card" style="height: 100%;">
                        <h4>{{ 'client.other' | translate }}</h4>
                        <div class="p-field p-col-12" *ngIf="InvoiceReminderActivate">
                            <p-checkbox [binary]="true" label="{{ 'settings.Enable_Statement_Reminder' | translate }}"
                                [(ngModel)]="enableStatementReminder" name="enableStatementReminder"
                                (ngModelChange)="changeStatementReminder($event)"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox [binary]="true" label="{{ 'client.no_services_charge' | translate }}"
                                [(ngModel)]="noServiceCharges" name="noServiceCharges"
                                (ngModelChange)="changeServiceCharges($event)"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox [binary]="true" label="{{ 'client.mark_time_billed' | translate }}"
                                [(ngModel)]="markSlipBilled" name="markSlipBilled"
                                (ngModelChange)="changeSlipBilled($event)"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox [binary]="true" label="{{ 'client.make_deposits_applied' | translate }}"
                                [(ngModel)]="markDepositApplied" name="markDepositApplied"
                                (ngModelChange)="changeDepositApplied($event)"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <p-checkbox [binary]="true" label="{{ 'client.block_time_entry' | translate }}"
                                [(ngModel)]="BlockTimeAndExpense" name="BlockTimeAndExpense"
                                (ngModelChange)="changeBlockTimeAndExpense($event)"></p-checkbox>
                        </div>
                        <div class="p-field p-col-12">
                            <label class="label-text">
                                {{ 'settings.Hide-Prior-Balance-on-Client-Invoices' | translate }}
                                <i tooltipPosition="top"
                                    pTooltip="{{'settings.hide-prior-balance-tooltip' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                            </label>
                            <p-dropdown [options]="hidePriorBalOptions" placeholder="Select Option"
                                [(ngModel)]="selectedPriorBalOption" name="selectedPriorBalOption"
                                (ngModelChange)="changePriorBalOption($event)"></p-dropdown>
                        </div>
                        <div class="p-field p-col-12">
                            <label class="label-text">
                                {{ 'settings.Show-Before-Invoice-Date' | translate }}
                                <i tooltipPosition="top"
                                    pTooltip="{{'settings.show-before-invoice-date-tooltip' | translate}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                            </label>
                            <p-dropdown [options]="showBeforeInvoiceDateOptions" placeholder="Select Option"
                                [(ngModel)]="selectedShowBeforeInvoiceDateOption" name="selectedShowBeforeInvoiceDateOption"
                                (ngModelChange)="changeShowBeforeInvoiceDateOption($event)"></p-dropdown>
                        </div>
                        <!--  end of card -->
                    </div>
                </div>
                <div class="p-col-4 p-my-2" style="padding-bottom: 200px;">
                    <div class="card" style="height: 100%;">
                        <h4>{{ 'assignments' | translate }}</h4>
                        <div class="p-field p-col-12">
                            <label class="label-text">{{ 'company_location' | translate }}</label>
                            <p-dropdown [options]="companyLocations" placeholder="Select Location"
                                [(ngModel)]="selectedCompanyLocation" name="selectedCompanyLocation"
                                (ngModelChange)="changeCompanyLocation($event)"></p-dropdown>
                        </div>
                        <div *ngIf="companyGlobalSetting.ActivateExpenseRates || companyGlobalSetting.ActivateLaborRates"
                            class="p-field p-col-12">
                            <label class="label-text">{{ 'sales-tax' | translate }}</label>
                            <p-dropdown [options]="salesTaxOptions" placeholder="Select Option"
                                [(ngModel)]="selectedSalesTax" name="selectedSalesTax"
                                (ngModelChange)="changeSalesTax($event)"></p-dropdown>
                            <!-- <p-dropdown [options]="companyLocations" placeholder="Select Location"
                                [(ngModel)]="selectedCompanyLocation" name="selectedCompanyLocation"></p-dropdown> -->
                        </div>
                        <div class="p-field p-col-12"
                            *ngIf="selectedSalesTax === 'ClientRate' && (companyGlobalSetting.ActivateExpenseRates || companyGlobalSetting.ActivateLaborRates)">
                            <label class="label-text">{{ 'settings.Sales-Tax-on-Labor' | translate }}</label>
                            <p-inputNumber inputId="integeronly" [(ngModel)]="Tax1ID" name="Tax1ID"
                                (click)="$event.target.select()" autocomplete="nope" inputStyleClass="p-text-right"
                                maxlength="12" placeholder="0.00" mode="decimal" maxLength [minFractionDigits]="2"
                                [maxFractionDigits]="3" (ngModelChange)="changeTax1ID($event)">
                            </p-inputNumber>
                        </div>
                        <div class="p-field p-col-12"
                            *ngIf="selectedSalesTax === 'ClientRate' && (companyGlobalSetting.ActivateExpenseRates || companyGlobalSetting.ActivateLaborRates)">
                            <label class="label-text">{{ 'settings.Sales-Tax-on-Expenses' | translate }}</label>
                            <p-inputNumber inputId="integeronly" [(ngModel)]="Tax2ID" name="Tax2ID"
                                (click)="$event.target.select()" autocomplete="nope" inputStyleClass="p-text-right"
                                maxlength="12" placeholder="0.00" mode="decimal" [minFractionDigits]="2"
                                [maxFractionDigits]="3" (ngModelChange)="changeTax2ID($event)">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
                <!-- <div class="p-col-6">
                    <div class="card">
                        <div class="p-col-12">
                            <h4>{{ 'client.billing_rates' | translate }}</h4>
                            <p-checkbox [binary]="true" label="{{ 'activate' | translate }}"
                                (click)="clientSettingForm.control.markAsDirty()" [(ngModel)]="ActivateClientRates"
                                name="activateClientRates"></p-checkbox>
                        </div>
                        <div class="p-col-12" *ngIf="ActivateClientRates">
                            <p-table [value]="clientRates" [rows]="20" [totalRecords]="clientRates.length"
                                [pageLinks]="3" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" 
                                [resizableColumns]="true"
                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [responsive]="true" dataKey="RateClientsID" sortField="ServiceCode" [sortOrder]="-1"
                                #dt>
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <span class="p-input-icon-left width-40p p-d-inline-block">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{ 'globalsearch' | translate }}" />
                                        </span>
                                        <span class="p-d-inline-block width-8p clientsetting">
                                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                        </span>
                                        <span class="p-text-right width-15p p-d-inline-block pull-right clientsetting">
                                            <button pButton pRipple type="button" icon="fal fa-plus"
                                                (click)="addClientRate()" label="{{ 'add' | translate }}"></button>
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-10p p-text-center">{{ 'actions' | translate }}</th>
                                        <th class="width-25p p-text-left" pSortableColumn="ServiceCode">
                                            {{ 'activity-code' | translate }}
                                            <p-sortIcon field="ServiceCode"></p-sortIcon>
                                        </th>
                                        <th class="width-30p p-text-left" pSortableColumn="Description">
                                            {{ 'description' | translate }}
                                            <p-sortIcon field="Description"></p-sortIcon>
                                        </th>
                                        <th class="width-15p p-text-right" pSortableColumn="BillingRate">
                                            {{ 'time.Billing-Rate' | translate }}
                                            <p-sortIcon field="BillingRate"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr class="cursor-hand">
                                        <td class="width-10p">
                                            <div class="p-text-center">
                                                <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green" (click)="editClientRate(rowData)" tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></i>
                                                <i pRipple (click)="deleteClientRate(rowData.RateClientsID);" class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top" pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                            </div>
                                        </td>
                                        <td class="width-25p p-text-left">
                                            {{rowData.ServiceCode}}
                                        </td>
                                        <td class="width-30p p-text-left p-text-nowrap">
                                            {{rowData.Description}}
                                        </td>
                                        <td class="width-15p p-text-right">
                                            {{rowData.BillingRate}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td [attr.colspan]="4">
                                            {{ 'data_not_found' | translate }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </form>
</div>
<p-dialog header="{{ 'client.billing_rates' | translate }}" (onHide)="cancelClientRate()"
    [(visible)]="displayClientRateModal" [modal]="true" [style]="{width: '30vw'}" [maximizable]="false"
    showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <form #rateMainnForm="ngForm">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-12">
                        <label class="required-field label-text">{{ 'activity-code' | translate }}</label>
                        <p-autoComplete name="serviceCodeID" id="serviceCodeID" placeholder="Search Activity"
                            [(ngModel)]="clientRate" [suggestions]="filteredActivities"
                            (completeMethod)="filterActivities($event)"
                            (onSelect)="handleActivityDropdownClick($event);handleActivitySelectClick($event);"
                            appendTo="body" [dropdown]="true" field="ServiceCode" #serviceCodeID="ngModel" required>
                            <ng-template let-client pTemplate="item">
                                <div class="ui-helper-clearfix">
                                    <div style="margin:8px 0 0 0">{{client.ServiceCode}}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <div class="p-field p-col-12 p-p-0" *ngIf="selectedActivityDesc">
                        <label class="p-col-6 label-text">{{ 'description' | translate }}</label>
                        <div class="p-col-6 ">
                            {{ selectedActivityDesc }}
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-p-0">
                        <label class="p-col-6 label-text">{{ 'time.Billing-Rate' | translate }}</label>
                        <div class="p-col-6 ">
                            <p-inputNumber [(ngModel)]="clientRate.BillingRate" name="billingRate" inputId="currency-us"
                                mode="currency" currency="USD" locale="en-US" required>
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!rateFormValid"
                    label="{{ 'create' | translate }}" (click)="saveClientRate(true)" *ngIf="!isEditClient"></button>
                <button type="button" pButton pRipple icon="fal fa-check" [disabled]="!rateFormValid"
                    label="{{ 'update_button' | translate }}" (click)="saveClientRate(false)"
                    *ngIf="isEditClient"></button>
                <button type="button" pButton pRipple icon="fal fa-times" (click)="cancelClientRate()"
                    label="{{ 'cancel_button' | translate }}"></button>
            </ng-template>
        </div>
    </div>
</p-dialog>