import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ActivitiesComponent } from './activities/activities.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { BanksComponent } from './banks/banks.component';
import { BillrateComponent } from './billrate/billrate.component';
import { ClientPortalComponent } from './client-portal/client-portal.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ListComponent } from './list/list.component';
import { MailConfigurationComponent } from './mail-configuration/mail-configuration.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { TimeAndExpensesComponent } from './time-and-expenses/time-and-expenses.component';
import { SettingsComponent } from './settings.component';
import { AuthGuard } from '@app/_services';
import { OtherComponent } from './other/other.component';
import { CanDeactivateForm } from './can-deactivate';
import { AppConstants } from '@app/_helpers/api-constants';
import { RateTemplates } from './rate-templates/rate-templates.component';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { MangoshareIntegrationComponent } from './mangoshare-integration/mangoshare-integration.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TimeAndExpensesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 8 } },
      { path: AppConstants.timeAndExpenses, component: TimeAndExpensesComponent, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateForm], data: { 'featID': 8 }},
      { path: AppConstants.invoices, component: InvoicesComponent, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateForm], data: { 'featID': 8 }},
      { path: AppConstants.activities, component: ActivitiesComponent, canActivate: [AuthGuard], data: { 'featID': 8 } },
      { path: AppConstants.expenses, component: ExpensesComponent, canActivate: [AuthGuard], data: { 'featID': 8 } },
      { path: AppConstants.customLists, component: ListComponent, canActivate: [AuthGuard], data: { 'featID': 8 } },
      { path: AppConstants.banks, component: BanksComponent, canActivate: [AuthGuard], data: { 'featID': 8 } },
      { path: AppConstants.billrate, component: BillrateComponent, canActivate: [AuthGuard], data: { 'featID': 8 } },
      { path: AppConstants.rateTemplates, component: RateTemplates, canActivate: [AuthGuard], data: { 'featID': 8 } },
      { path: AppConstants.mailConfiguration, component: MailConfigurationComponent, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateForm], data: { 'featID': 8 }},
      { path: AppConstants.integrations, component: IntegrationsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'plans': ["free", "enterprise"], 'featID': 8 } },
      { path: AppConstants.mangoShareIntegration, component: MangoshareIntegrationComponent, canActivate: [AuthGuard], data: { 'plans': ["free", "enterprise"], 'featID': 8 } },
      { path: AppConstants.assignments, component: AssignmentsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateForm], data: { 'featID': 8 } },
      { path: AppConstants.clientPortal, component: ClientPortalComponent, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateForm], data: { 'featID': 8 }},
      { path: AppConstants.manageRoles, component: ManageRolesComponent, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateForm], data: { 'featID': 10 }},
      { path: AppConstants.other, component: OtherComponent, canActivate: [AuthGuard] , canDeactivate: [CanDeactivateForm], data: { 'featID': 8 }},

      // { path: 'DDMOptions', component: DDMOptionsComponent, canActivate: [AuthGuard] },
    ],
    data: { 'roles': ["administrator"] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateForm]
})
export class SettingsRoutingModule { }
