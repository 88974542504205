import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils } from '@app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Table, TableCheckbox } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import Swal from 'sweetalert2';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import * as workerTimers from 'worker-timers';
import { timer } from 'rxjs';
import { parentsUntil } from '@syncfusion/ej2-angular-grids';
import { SharedComponentsService } from '@app/shared/components';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html'
})
export class AssignmentsComponent implements OnInit, AfterViewInit {
  public tableDataSorce = [];
  @ViewChild('searchEngValue') searchEngValue;
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  @ViewChild('dtEng') dataEngTable: Table;
  @ViewChild('dtClientNoData') dtClientNoData: Table;
  @ViewChild('dtEngagementNoData') dtEngagementNoData: Table;
  @ViewChild('customTableTemplates') customTableTemplates: Table;
  filteredEngItemsSize = -1;
  filteredItemsSize = -1;
  searchTextStr: any = '';
  searchEngTextStr: any = '';
  optionsList: any = [];
  selectedOptions: any = 1;
  selectedIndex: number = 1;
  makeClientInactiveActive = false;
  clientsList: any = [];
  clientsListTemp: any = [];
  public defaultRecord: any = {
    ClientID: '',
    AssignedTo: -1,
    Status: 'Open',
    EngagementName: '',
    StartDate: '',
    DueDate: '',
    ProjectStreet: 'null',
    ProjectStreet2: 'null',
    ProjectCity: 'null',
    ProjectState: 'null',
    ProjectZip: 'null',
    ProjectCountry: 'null',
    CustomerContactID: -1,
    ContactEmail: '',
    Inactive: false,
    EngagementTypeID: '',
    EngagementNumber: '',
    EngagementDescription: '',
    FlatFeeAmount: 0.0,
    BillingMethod: 'Hourly',
    BillTo: 'Client',
    TermsID: 0,
    LaborTaxRate1: 0.0,
    LaborTaxRate2: 0.0,
    ExpenseRate1: 0.0,
    ExpenseRate2: 0.0,
    DefaultTaxRates: true
  };
  invoiceOpt: any = {};
  engagementsTypeList: any = [];
  public termsList: any[];
  public staffList: SelectItem[];
  invoiceTemplates: SelectItem[];
  finalizeAction: SelectItem[];
  public selectedClientTypesObj: any = {};
  public selectedCustomTableTemplate: any = {};
  public selectedCompanyLocationObj: any = {};
  editableClient: any = {
    BillingPartnerID: null,
    StaffAssignedID: null,
    OriginatingPartnerID: null
  };
  customerTypeList: any = [];
  companyLocations: any = [];
  customTableTemplate: any = [];
  groupList: any = [];
  companyContacts: any = [];
  createNewContact = false;
  selectedCompanyContacts = [];
  @ViewChild('contactMainForm') contactMainForm;
  contactsType: SelectItem[];
  emailExists: boolean = false;
  isEmailValid: boolean = true;
  public contactObj: any = {};
  currentProjectRecord: any = {};
  intervalId: any = null;
  @ViewChild('clientsGB') searchClientValue;
  public descInvoice: any = '';
  public selectedfinalizeAction: any;
  public isBillingPartnerIncluded: boolean = true;
  public isStaffAssignedIncluded: boolean = true;
  public isOriginatingPartnerIncluded: boolean = true;
  public allStaff: any = [];
  public allClients: any = [];
  public ClientID: number = 0;
  @ViewChild('engagementlist') dataTableengagementGroupsList: Table;
  @ViewChild('dt') dataTabclientsList: Table;
  public assignmentForm: UntypedFormGroup;
  public selectedItems: any = [];
  public selectedEngagementItems: any = [];
  public selectedGpItems: any = [];
  public selectedClientsItems: any = [];
  public isFormValid: boolean = false;
  companyId: any;
  clientfilteredItemsSize = -1;
  timeAndExpData: any = {};
  isValidTabs = true;
  selectedInvoiceTemplate: any;
  public engagementName: any = '';
  public isInvoiceTemplateSelected: boolean = false;
  public isFinalizeActionSelected: boolean = false;
  public isEnableStmtReminder: boolean = false;
  public isEnableStmtCompany: boolean = false;
  intervalid: any;
  staffID;
  lastLateFeeDate: any;
  public showClientsWithNoEngagements: boolean = false;
  public showInactiveClients: boolean = false;
  public showInactiveClients13: boolean = false;
  public showInactivveEngagements: boolean = false;
  public showStatementEnabled: boolean = false;
  selectedBillingGroup: any;
  showAddTemplate = false;
  showClientsWithTemplates = 1;
  customTableCompanyTemplateObj = {
    TemplateName: '',
    Inactive: false,
    Description: [],
    CustomTablesCompanyID: null,
    IsChanged: false,
    CompanyID: null
  };
  showInactiveTemplates = false;
  optionClientTemplates = [
    { label: 'No Templates', value: 1 },
    { label: 'With Templates', value: 2 },
    { label: 'All Clients', value: 3 }
  ];
  clientIDsWithProjects = null;

  public engagementsDatasource: any = [];
  public selectedEngagements: any = [];
  public selectedProjects: any = [];
  public ClientListNoData: any = [];
  public engagementsNoData: any = [];
  public selectedClientForDeletion: any = [];
  public yearsList: any = [];
  public selectedYear = parseInt(moment().format('YYYY'));
  subscriptionLevel
  public projectsWithNoEngagement: any;
  public selectedEngagementForDeletion: any = [];
  cols = [];
  isTaxable = false;
  isCalcTaxNoTime = false;
  _selectedColumns: any = [];
  globalFilterColumns: any = [
    'ClientName',
    'CustomerTypeDescription',
    'BillingGroup',
    'TemplateName'
  ];

  public timerSubscription: any;
  constructor(
    private _fb: UntypedFormBuilder,
    private http: HttpClient,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils,
    private translate: TranslateService,
    public sharedSrvc: SharedComponentsService
  ) {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    // this.clientsList = this.encrDecSrvc.clientList;
    this.defaultRecord.AssignedTo = this.staffID;
    this.fetchClientList();
  }

  getContactType() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getContactType().subscribe(
      function (data: any) {
        _this.contactsType = [];
        for (let i = 0; i < data.length; i++) {
          _this.contactsType.push({
            label: data[i].ContactType,
            value: data[i].CustomerContactTypeID
          });
        }
        _this.contactsType.sort(_this.mangoUtils.compareValues('label', 'asc'));
      },
      error => {
        _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        _this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  isValidEmail(email) {
    this.isEmailValid = true;
    if (!email || email == '' || email === null || email.trim() == '') {
      this.isFormValid = true;
      this.isEmailValid = true;
      return null;
    }
    this.contactObj.Email = email.trim();
    const mailPattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (mailPattern.test(email.trim())) {
      this.isEmailValid = true;
      this.isFormValid = true;
      return null;
    } else {
      this.isFormValid = false;
      this.isEmailValid = false;
    }
  }

  copyClipBoard(inputElement) {
    const copyValue = this.contactObj.Email;
    if (copyValue) {
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), 'Copied to Clipboard');
    } else {
      document.execCommand('Unselect');
    }
  }

  editContact(contact) {
    contact['isEditFlow'] = true;
    contact['isDisableOptions'] = false;
    contact['viewingOnly'] = true;
    this.sharedSrvc.openContactDialog(contact, []);
  }

  ngOnInit(): void {
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.initializeForm();
    setTimeout(() => {
      this.loadDefaultData();
    }, 500);
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);    
  }

  ngOnDestroy() {
    workerTimers.clearInterval(this.timerSubscription);
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field)
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.globalFilterColumns = [
      ...['ClientName', 'CustomerTypeDescription', 'BillingGroup', 'TemplateName'],
      ...this._selectedColumns.map(col => col.field)
    ];
    this.dataTabclientsList.filterGlobal(this.searchClientValue.nativeElement.value, 'contains');
  }

  initializeColumns() {
    this.cols = this._selectedColumns = [
      {
        field: 'BillingPartnerName',
        header: this.translate.instant('Billing_Partner'),
        rowClass: 'width-40p p-text-left'
      },
      {
        field: 'BusState',
        header: this.translate.instant('client.state'),
        rowClass: 'width-15p p-text-left'
      },
      {
        field: 'BusZip',
        header: this.translate.instant('client.zip'),
        rowClass: 'width-30p p-text-left'
      }
    ];
    this.globalFilterColumns = [
      ...['ClientName', 'CustomerTypeDescription', 'BillingGroup', 'TemplateName'],
      ...this._selectedColumns.map(col => col.field)
    ];
  }

  onChipsAdd() {
    if (this.customTableCompanyTemplateObj['Description'].length > 15) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant(
          'Maximum descriptions length is reached. Unable to add a new entry.'
        ),
        icon: 'warning',
        confirmButtonText: this.translate.instant('Ok')
      });
      this.customTableCompanyTemplateObj['Description'].splice(15);
    }
  }

  clientCustomTableChange(event) {
    if (event.value == 3) {
      //all clients
      this.dataTableComponent._value = this.clientsList;
    } else if (event.value == 1) {
      // no templates
      this.dataTableComponent._value = this.clientsList.filter(
        item => !item['TemplateName'] || item['TemplateName'] == ''
      );
    } else if (event.value == 2) {
      // with templates
      this.dataTableComponent._value = this.clientsList.filter(item => item['TemplateName']);
    }
  }

  toggleInactiveTemplates() {
    if (this.customTableTemplates)
      this.customTableTemplates._value = this.customTableTemplate.filter(
        item => this.showInactiveTemplates || !item['Inactive']
      );
  }

  onCloseAddTemplate() {
    this.showAddTemplate = false;
    this.customTableCompanyTemplateObj = {
      TemplateName: '',
      Inactive: false,
      Description: [],
      CustomTablesCompanyID: null,
      IsChanged: false,
      CompanyID: null
    };
  }

  openAddTemplateDialog(rowData?) {
    this.showAddTemplate = true;
    if (rowData) {
      this.customTableCompanyTemplateObj = {
        TemplateName: rowData['TemplateName'],
        Inactive: rowData['Inactive'],
        Description: rowData['Description'],
        CustomTablesCompanyID: rowData['CustomTablesCompanyID'],
        IsChanged: false,
        CompanyID: rowData['CompanyID']
      };
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addRowCustomTableCompany() {
    if (
      !this.customTableCompanyTemplateObj['CustomTablesCompanyID'] ||
      this.customTableCompanyTemplateObj['Description'].length == 0
    )
      return;
    this.customTableCompanyTemplateObj['Description'].push('');
    this.customTableCompanyTemplateObj['IsChanged'] = true;
    if (this.customTableCompanyTemplateObj['Description'].length > 15) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant(
          'Maximum descriptions length is reached. Unable to add a new entry.'
        ),
        icon: 'warning',
        confirmButtonText: this.translate.instant('Ok')
      });
      this.customTableCompanyTemplateObj['Description'].splice(15);
    }
  }

  deleteRowCustomTableCompany(index) {
    if (
      !this.customTableCompanyTemplateObj['CustomTablesCompanyID'] ||
      this.customTableCompanyTemplateObj['Description'].length <= 1
    )
      return;
    this.customTableCompanyTemplateObj['Description'].splice(index, 1);
    this.mangoAPISrvc.notify('success', this.translate.instant('Success'), 'Successfully deleted.');
    this.customTableCompanyTemplateObj['IsChanged'] = true;
  }

  deleteCompanyTemplate(rowData) {
    if (rowData['CustomTablesCompanyID']) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant('settings.delete_user_defined_templates'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_delete'),
        cancelButtonText: this.translate.instant('no_delete')
      }).then(result => {
        if (result.value) {
          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc
            .deleteCustomTableTemplates(rowData['CustomTablesCompanyID'])
            .subscribe((result: any) => {
              this.mangoAPISrvc.showLoader(false);
              if (result.deleted) {
                this.customTableTemplate = this.customTableTemplate.filter(
                  item => item['CustomTablesCompanyID'] != rowData['CustomTablesCompanyID']
                );
              } else {
                rowData['Inactive'] = true;
                setTimeout(() => {
                  this.toggleInactiveTemplates();
                }, 500);
              }
              this.mangoAPISrvc.notify(
                'success',
                this.translate.instant('Success'),
                'Successfully deleted.'
              );
            });
        }
      });
    }
  }

  saveClientCustomTable() {
    if (this.customTableCompanyTemplateObj['CustomTablesCompanyID']) {
      this.updateCompanyCustomTableRow();
    } else {
      this.addCompanyCustomTableRow();
    }
  }

  updateCompanyCustomTableRow() {
    if (
      !this.customTableCompanyTemplateObj['CustomTablesCompanyID'] ||
      !this.customTableCompanyTemplateObj['TemplateName'] ||
      this.customTableCompanyTemplateObj['TemplateName'] == '' ||
      this.customTableCompanyTemplateObj['Description'].length == 0
    )
      return;
    this.mangoAPISrvc.showLoader(true);
    const obj = JSON.parse(JSON.stringify(this.customTableCompanyTemplateObj))
    obj['Description'] = `{${obj['Description'].map(item => item == "" ? "-" : item).join(",")}}`
    this.mangoAPISrvc.putCustomTableTemplates(obj['CustomTablesCompanyID'], obj)
      .subscribe(result => {
        this.mangoAPISrvc.showLoader(false);
        if (result) {
          this.getCustomTableTemplates();
          this.clientsList = this.clientsList.map(item => {
            //update template name in clients list if ever it is edited.
            // if(item['CustomTablesCompanyID'] == this.customTableCompanyTemplateObj['CustomTablesCompanyID']){
            //   item['TemplateName'] = this.customTableCompanyTemplateObj['TemplateName'];
            // }
            if (item['CustomTableClients']) {
              const temp = item['CustomTableClients'].find(item1 => item1['CustomTablesCompanyID'] == this.customTableCompanyTemplateObj['CustomTablesCompanyID'])
              if (temp) {
                temp['FirmTemplateName'] = obj['TemplateName'];
                // item['CustomTableClients'].push(obj);
                item['TemplateName'] = item['CustomTableClients']
                  .map(item => item['FirmTemplateName'])
                  .join(', ');
              }
            }
            return item;
          });
          if (this.dataTableComponent) this.dataTableComponent._value = this.clientsList;
          this.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success'),
            this.translate.instant('notification_updated')
          );
        }
        this.onCloseAddTemplate();
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.onCloseAddTemplate();
      }
    );
  }

  addCompanyCustomTableRow() {
    if (
      this.customTableCompanyTemplateObj['CustomTablesCompanyID'] ||
      !this.customTableCompanyTemplateObj['TemplateName'] ||
      this.customTableCompanyTemplateObj['TemplateName'] == '' ||
      this.customTableCompanyTemplateObj['Description'].length == 0
    )
      return;
    this.mangoAPISrvc.showLoader(true);
    const obj = JSON.parse(JSON.stringify(this.customTableCompanyTemplateObj))
    obj['Description'] = `{${obj['Description'].join(",")}}`
    obj['CompanyID'] = this.companyId;
    this.mangoAPISrvc.postCustomTableTemplates(obj).subscribe(
      result => {
        this.mangoAPISrvc.showLoader(false);
        if (result) {
          this.getCustomTableTemplates(result);
          this.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success'),
            this.translate.instant('notification_created')
          );
        }
        this.onCloseAddTemplate();
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.onCloseAddTemplate();
      }
    );
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('Utilities'), icon: 'ic-red' }
    ]);
    this.optionsList = [
      { label: this.translate.instant('assign_inactive_active_clients'), value: 12 },
      { label: this.translate.instant('billing-group'), value: 17 },
      { label: this.translate.instant('Client-Engagements'), value: 1 },
      { label: this.translate.instant('Client_Groups'), value: 4 },
      { label: this.translate.instant('client_user_defined_fields'), value: 11 },
      { label: this.translate.instant('client_terms'), value: 5 },
      { label: this.translate.instant('Client_Types'), value: 2 },
      { label: this.translate.instant('company_locations'), value: 8 },
      { label: this.translate.instant('delete_inactive_clients'), value: 13 },
      { label: this.translate.instant('delete_inactive_engagements'), value: 14 },
      { label: this.translate.instant('billing-invoicing.invoice-template'), value: 6 },
      { label: this.translate.instant('Staff-Assignments'), value: 3 },
      { label: this.translate.instant('statement_reminders'), value: 9 },
      { label: this.translate.instant('update_engagements'), value: 7 },
      { label: this.translate.instant('settings.Update-Sales-Tax'), value: 16 },
      { label: this.translate.instant('assign_contacts_multiple_clients'), value: 18 },
      { label: this.translate.instant('assignments.update_last_late_fee'), value: 10 },
      { label: this.translate.instant('assignments.clearAllInvoices'), value: 19 },
      { label: this.translate.instant('Engagements-Projects'), value: 20 }
    ];

    if (this.subscriptionLevel == 'ENTERPRISE' || this.subscriptionLevel == 'FREE')
      this.optionsList.splice(11, 0, {
        label: this.translate.instant('Rollover_Budgets'),
        value: 15
      });

    this.invoiceTemplates = [
      { value: '2', label: this.translate.instant('client.narrative') },
      { value: '9', label: this.translate.instant('client.narrative_summary') },
      { value: '10', label: this.translate.instant('client.narrative_detail') },
      { value: '4', label: this.translate.instant('client.narrative_mini_statement') },
      { value: '0', label: this.translate.instant('client.detail') },
      { value: '6', label: this.translate.instant('client.detail_no_rate') },
      { value: '7', label: this.translate.instant('client.detail_no_rate_time') },
      { value: '11', label: this.translate.instant('client.detail_no_rate_time_amount') },
      { value: '12', label: this.translate.instant('client.narrative_simple_no_detail') },
      { value: '13', label: this.translate.instant('client.narrative_simple_with_detail') },
      { value: '14', label: this.translate.instant('client.narrative_simple_no_remit') },
      { value: '15', label: this.translate.instant('client.invoice_detail_summary') },
      { value: '16', label: this.translate.instant('client.invoice_narrative_summary_by_activity') }
    ];
    this.finalizeAction = [
      { label: this.translate.instant('print'), value: 'Print' },
      { label: this.translate.instant('email'), value: 'Email' },
      { label: this.translate.instant('finalize_only'), value: 'Finalize Only' }
    ];
    this.selectedfinalizeAction = this.finalizeAction[0].value;
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.companyId).subscribe(
      (data: any) => {
        this.companyLocations = data.map(location => {
          return {
            CompanyMangoLocationID: location.CompanyMangoLocationID,
            CompanyLocation: location.CompanyLocation
          };
        });
        // this.mangoAPISrvc.showLoader(false);
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  getCustomTableClients() {
    const interval = setInterval(() => {
      if (!this.clientsListTemp || this.clientsListTemp.length == 0 || !this.clientIDsWithProjects) return;
      clearInterval(interval)
      this.mangoAPISrvc.getCustomTableAllClients(this.companyId)
        .subscribe((result: any) => {
          if (result) {
            const groupByClient = result.reduce(function (r, a) {
              r[a.ClientID] = r[a.ClientID] || [];
              r[a.ClientID].push(a);
              return r;
            }, Object.create(null));
            this.clientsList = this.clientsListTemp.map(item => {
              if (groupByClient[item['ClientID']]) {
                item['CustomTableClients'] = groupByClient[item['ClientID']];
                item['TemplateName'] = groupByClient[item['ClientID']].map(clientTemplate => clientTemplate['FirmTemplateName']).join(", ");
              } else {
                item['CustomTableClients'] = []
                item['TemplateName'] = ""
              }
              return item;
            })
            setTimeout(() => {
              this.mangoAPISrvc.showLoader(false);
              this.showClientsWithNoEngagementsToggle();
            }, 50)
          }
        })
    })
  }

  getClientWithNoEngagement() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getClientsWithNoEngagement(this.companyId, this.showClientsWithNoEngagements).subscribe((result: any) => {
      const groupByClient = result.clientRecords.reduce(function (r, a) {
        r[a.ClientID] = r[a.ClientID] || [];
        r[a.ClientID].push(a);
        return r;
      }, Object.create(null));
      this.clientIDsWithProjects = Object.keys(groupByClient);

      const itm = result.clientRecords;
      const groups = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);

          for (let i = 0; i < itm.length; i++) {
            if (itm[i].ClientTypeID) {
              let clientDesc = this.customerTypeList.filter(
                cItm => cItm['CustomerTypeID'] === itm[i].ClientTypeID
              );
              if (clientDesc.length > 0) {
                itm[i].CustomerTypeDescription = clientDesc[0].CustomerTypeDescription;
              }
            }

            if (itm[i]['BillingGroupID']) {
              let group = groups.find(
                item => item['CustomerGroupCategoryID'] == itm[i]['BillingGroupID']
              );
              if (group) itm[i]['BillingGroup'] = group['GroupDescription'];
            }
          }

          this.clientsList = itm;
          setTimeout(() => {
            this.showClientsWithNoEngagementsToggle();
          }, 500);
          this.getCustomTableClients();
          this.mangoAPISrvc.showLoader(false);
        },
        err => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        }
      );
  }

  getProjects() {
    this.mangoAPISrvc.getProjects().subscribe((result: any) => {
      const groupByClient = result.reduce(function (r, a) {
        r[a.ClientID] = r[a.ClientID] || [];
        r[a.ClientID].push(a);
        return r;
      }, Object.create(null));
      this.clientIDsWithProjects = Object.keys(groupByClient);
    }, error => {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
    })
  }

  fetchClientList() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getAllClientsByCompanyId(this.companyId).subscribe(
      (data: any) => {
        this.allClients = data;
        this.mangoAPISrvc.showLoader(false);
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getCustomTableTemplates(selectedRow?) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCustomTableTemplates(this.companyId).subscribe(result => {
      // this.mangoAPISrvc.showLoader(false);
      this.customTableTemplate = result;
      this.customTableTemplate = this.customTableTemplate.map(item => {
        item['Description'] = item['Description'].map(item => (item == '-' ? '' : item));
        item['DescriptionJoin'] = item['Description'] ? item['Description'].join(', ') : '';
        if (
          selectedRow &&
          item['CustomTablesCompanyID'] == selectedRow['CustomTablesCompanyID'] &&
          !selectedRow['Inactive']
        ) {
          this.selectedCustomTableTemplate = item;
          this.onRowSelect();
        }
        return item;
      });
      setTimeout(() => {
        this.toggleInactiveTemplates();
      }, 500);
      this.mangoAPISrvc.showLoader(false);
    });
  }

  getCompanyContacts() {
    this.mangoAPISrvc.getContactsByCompanyId(this.companyId).subscribe(results => {
      this.companyContacts = results;
    });
  }

  loadDefaultData() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.getAllStaffList();
    parent.getCompanyLocations();
    parent.getCustomTableTemplates();
    parent.getClientWithNoEngagement();
    parent.getContactType();
    //parent.getProjects();
    // parent.fetchClientWithNoData();
    // parent.fetchEngagementsNoData();
    parent.getCompanyContacts();
    parent.currentProjectRecord = parent.defaultRecord;

    const customerTypeData = parent.encrDecSrvc.getObject(AppConstants.customerType);
    parent.customerTypeList = customerTypeData.filter((customerType) => customerType.Inactive == false);

    const groupListData = parent.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    parent.groupList = groupListData.filter((customerType) => customerType.Inactive == false);

    parent.timeAndExpData = parent.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    const invoiceOptionsData = parent.encrDecSrvc.getObject(AppConstants.invoiceOptions);
    invoiceOptionsData.NoLateFees = invoiceOptionsData.NoLateFees != null ? invoiceOptionsData.NoLateFees.toString() : "true";
    invoiceOptionsData.InterestRate = invoiceOptionsData.InterestRate != null ? invoiceOptionsData.InterestRate : 0;
    invoiceOptionsData.GraceDays = invoiceOptionsData.GraceDays != null ? invoiceOptionsData.GraceDays : 0;
    parent.invoiceOpt = invoiceOptionsData;

    parent.mangoAPISrvc.loadDefaultAssignmentSettings(parent.companyId).subscribe(
      function (data: any) {
        parent.engagementsTypeList = data[1].filter(invoiceGroup => invoiceGroup.Inactive == false);
        parent.termsList = [{ label: 'Select Terms', value: null, GraceDays: null }];
        for (let i = 0; i < data[0].length; i++) {
          if (!data[0][i].Inactive) {
            parent.termsList.push({
              label: data[0][i].TermsDescription,
              value: data[0][i].TermsID,
              GraceDays: data[0][i].GraceDays
            });
          }
        }
        // parent.mangoAPISrvc.showLoader(false);
        // parent.getCustomTableClients();
      },
      error => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  fetchClientWithNoData() {
    this.mangoAPISrvc.getClientWithNoData(this.companyId).subscribe(
      (result: any) => {
        this.ClientListNoData = result.clientRecords;
        setTimeout(() => {
          this.showInactiveOrActiveToggle13();
        }, 500);
        this.mangoAPISrvc.showLoader(false);
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  fetchEngagementsNoData() {
    const parent = this;
    parent.mangoAPISrvc.getEngagementCount(this.companyId).subscribe((res) => {
      const limit = res && res[0].count > 50000 ? 2000 : null; //
      parent.mangoAPISrvc.getEngagementsWithNoData(parent.companyId, null, null, limit).subscribe((result: any) => {
        parent.engagementsNoData = result
        parent.mangoAPISrvc.showLoader(false);
        if (limit != null) parent.fetchEngagementInBackground;
      })
    }, err => {

    })
  }

  fetchEngagementInBackground() {
    const parent = this;
    parent.timerSubscription = workerTimers.setInterval(() => {
      parent.mangoAPISrvc
        .getEngagementsWithNoData(parent.companyId, null, null, 5000)
        .subscribe((result: any) => {
          for (let i = 0; i < result.length; i++) {
            parent.engagementsNoData.push(result[i]);
          }

          if (result.length < 5000) {
            if (parent.timerSubscription) {
              workerTimers.clearInterval(parent.timerSubscription);
            }
            return;
          }
        });
    }, 5000);
  }

  fetchClients() {
    if (this.clientsListTemp.length == 0) {
      this.clientsListTemp = this.encrDecSrvc.clientList;
    } else {
      // AppConstants.clientGroupCategory
      const groups = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
      this.clientsListTemp = this.clientsListTemp.map(client => {
        if (client['BillingGroupID']) {
          const group = groups.find(item => item['CustomerGroupCategoryID'] == client['BillingGroupID']);
          if (group) client['BillingGroup'] = group['GroupDescription'];
        }
        return client;
      });
      clearInterval(this.intervalid);
    }
  }

  deleteClientRecords() {
    if (this.selectedClientForDeletion.length > 0) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: `<div>${this.mangoAPISrvc.translate.instant('assignment.deletion-reminder')}</div>`,
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then(result => {
        if (result.value) {
          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc
            .deleteClientsWithNoData(this.selectedClientForDeletion.map(itm => itm.ClientID))
            .subscribe(
              result => {
                this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
                this.fetchClientWithNoData();
                this.mangoAPISrvc.showLoader(false);
              },
              err => {
                this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                this.mangoAPISrvc.showLoader(false);
              }
            );
        }
      });
    }
  }

  deleteEngagementsWithNoData() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: `<div>${this.translate.instant('question.delete_selected_items')}</div>`,
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc
          .deleteEngagementsWithNoData(
            this.selectedEngagementForDeletion.map(item => item['ProjectMasterID'])
          )
          .subscribe(
            result => {
              this.mangoAPISrvc.notify('success', 'Success!', AppConstants.deleteMessage);
              // this.fetchClientWithNoData();
              this.engagementsNoData = this.engagementsNoData.filter(
                item =>
                  !this.selectedEngagementForDeletion
                    .map(item => item['ProjectMasterID'])
                    .includes(item['ProjectMasterID'])
              );
              if (this.dtEngagementNoData) this.dtEngagementNoData._value = this.engagementsNoData;
              this.selectedEngagementForDeletion = [];
              this.mangoAPISrvc.showLoader(false);
            },
            err => {
              this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
              this.mangoAPISrvc.showLoader(false);
            }
          );
      }
    });
  }

  getAllStaffList() {
    const klassObj = this;
    klassObj.staffList = [{ label: 'Unassigned', value: null }];
    const tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
    klassObj.allStaff = tempList;
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive) continue;

      klassObj.staffList.push({ label: tempList[i].StaffName, value: tempList[i].StaffID });
    }
    this.assignmentForm.controls['staffID'].setValue(this.staffID);
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
  }

  clearEngSearchFilter() {
    this.searchEngValue.nativeElement.value = this.searchEngTextStr = '';
    this.filteredEngItemsSize = -1;
  }

  ngAfterViewInit() {
    this.searchEngValue?.nativeElement?.focus();
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  showClientsWithNoEngagementsToggle() {
    let tempClientList = this.clientsList;
    if (!this.showClientsWithNoEngagements) {
      //show clients with engagements only!
      tempClientList = tempClientList.filter(
        item =>
          this.clientIDsWithProjects.includes(`${item['ClientID']}`) &&
          item['ContactRecord'] != true
      );
    } else {
      tempClientList = tempClientList.filter(
        item =>
          !this.clientIDsWithProjects.includes(`${item['ClientID']}`) &&
          item['ContactRecord'] != true
      );
    }
    this.showInactiveOrActiveToggle(tempClientList);
  }

  showInactiveOrActiveToggle(clientsList?) {
    const tempClientList = clientsList || this.clientsList;
    if (!this.dataTableComponent) return;
    if (this.showInactiveClients) {
      this.dataTableComponent._value = tempClientList.filter(
        item => item['Inactive'] == true && item['ContactRecord'] != true
      );
    } else {
      this.dataTableComponent._value = tempClientList.filter(
        item => item['Inactive'] != true && item['ContactRecord'] != true
      );
    }
    this.filteredItemsSize = this.dataTableComponent._value.length;
  }

  showInactiveOrActiveToggle13() {
    if (!this.dtClientNoData) return;
    this.selectedClientForDeletion = [];
    if (this.showInactiveClients13) {
      this.dtClientNoData._value = this.ClientListNoData.filter(item => item['Inactive'] == true);
    } else {
      this.dtClientNoData._value = this.ClientListNoData.filter(item => item['Inactive'] != true);
    }
    this.filteredItemsSize = this.dtClientNoData._value.length;
  }

  showInactiveOrActiveEngagements() {
    if (!this.dtEngagementNoData) return;
    this.selectedEngagementForDeletion = [];
    if (this.showInactivveEngagements) {
      this.dtEngagementNoData._value = this.engagementsNoData.filter(
        item => item['Inactive'] == true
      );
    } else {
      this.dtEngagementNoData._value = this.engagementsNoData.filter(
        item => item['Inactive'] == null || item['Inactive'] == false
      );
    }
  }

  showStatementEnabledToggle() {
    if (!this.dataTableComponent) return;
    if (this.showStatementEnabled) {
      this.dataTableComponent._value = this.clientsList.filter(
        item => item['InvoiceReminderActivate'] == true
      );
    } else {
      this.dataTableComponent._value = this.clientsList.filter(
        item => item['InvoiceReminderActivate'] == null || item['InvoiceReminderActivate'] == false
      );
    }
  }

 handleClick(event) {
    if (this.dataTableComponent) this.dataTableComponent._value = this.clientsList;
    this.selectedIndex = event.value;
    this.selectedClientsItems = [];
    this.selectedClientsItems.length = 0;
    this.showClientsWithNoEngagements = false;
    this.showInactiveClients = false;
    if (this.selectedIndex == 16) {
      this.getEngagements();
    }
    if (this.selectedIndex == 8) {
      this.initializeColumns();
    } else {
      this._selectedColumns = [];
      this.globalFilterColumns = [
        'ClientName',
        'CustomerTypeDescription',
        'BillingGroup',
        'TemplateName'
      ];
    }

    if (this.selectedIndex == 1) {
      this.getClientWithNoEngagement();
      this.isValidTabs =
        !this.isFormValid ||
        this.selectedItems.length == 0 ||
        this.selectedClientsItems.length == 0;
    } else if (this.selectedIndex == 2) {
      this.isValidTabs = true;
    } else if (this.selectedIndex == 4) {
      this.isValidTabs = this.selectedClientsItems.length == 0;
    } else if (this.selectedIndex == 5 || this.selectedIndex == 6) {
      this.isValidTabs = this.selectedClientsItems.length == 0;
    } else if (this.selectedIndex == 9) {
      setTimeout(() => {
        this.showStatementEnabledToggle();
      }, 500);
    } else if (this.selectedIndex == 7) {
      this.isValidTabs = true;
    } else if (this.selectedIndex == 8) {
      this.isValidTabs = true;
    } else if (this.selectedIndex == 11) {
      this.showClientsWithTemplates = 1;
      // this.getCustomTableClients();
      // this.getCustomTableTemplates();
      setTimeout(() => {
        this.clientCustomTableChange({ value: this.showClientsWithTemplates });
        this.toggleInactiveTemplates();
      }, 500);
    } else if (this.selectedIndex == 12) {
      setTimeout(() => {
        this.showInactiveOrActiveToggle();
      }, 500);
    } else if (this.selectedIndex == 13) {
      this.clearSearchFilter();
      this.mangoAPISrvc.showLoader(true);
      this.fetchClientWithNoData();
    } else if (this.selectedIndex == 14) {
      setTimeout(() => {
        this.clearSearchFilter();
        this.mangoAPISrvc.showLoader(true);
        this.fetchEngagementsNoData();
      }, 500);
    } else if (this.selectedIndex == 15) {
      this.getYears();
      this.isValidTabs = false;
    } else if (this.selectedIndex == 19) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('warningDeletion'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(result => {
        if (result.value) {
          if (result.isConfirmed) {
            this.deleteAllInvoicesPaymentTime();
          }
        }
      });
    }
    else if (this.selectedIndex == 20){
       this.loadProjectsWithNoEngagements();
    }
    else {
      this.isValidTabs = true;
    }
  }

  /**
   * Method to load all projects without engagements (left side of the screen)
   */
   loadProjectsWithNoEngagements(){
    if (this.selectedEngagementItems.EngagementTypeID > 0) {
      this.mangoAPISrvc.showLoader(true);
      this.selectedProjects = [];
      this.projectsWithNoEngagement = [];
      this.mangoAPISrvc
        .projectWithNoEngagement(this.companyId, this.selectedEngagementItems.EngagementTypeID)
        .subscribe((data:any) => {
          this.projectsWithNoEngagement = data[0].data;
          if (this.projectsWithNoEngagement.length == 0) {
            this.mangoAPISrvc.notify(
              "success",
              this.translate.instant("Warning"),
              "No relation for the engagement and the projects found."
            );
          }
          this.mangoAPISrvc.showLoader(false);
        });
    }
  }

  /**
   * delete all invoices payment and time method
   */
  deleteAllInvoicesPaymentTime() {
    if (this.companyId > 0 && this.companyId != undefined) {
      this.mangoAPISrvc.deleteAllInvoicesPaymentTime(this.companyId).subscribe(data => {
        const resp: any = data;
        Swal.fire({
          title: this.translate.instant('delete'),
          html: resp.message,
          icon: 'info',
          showCancelButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'OK'
        }).then(() => {});
      });
    }
  }

  getYears() {
    if (this.yearsList?.length > 0) return;

    const value = 5;
    const yearToday = parseInt(moment().format('YYYY'));
    for (let x = yearToday + value; x >= yearToday - value; x--) {
      this.yearsList.push({ label: x.toString(), value: x });
    }
  }

  createProjectData(bulkData) {
    const self = this;
    const observableBatch = bulkData.splice(0, 100);
    if (bulkData.length == 0) {
      clearInterval(this.intervalId);
    }
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.bulkProjectCreate({ observableBatch }).subscribe(
      function (data) {
        if (bulkData.length > 0) {
          return false;
        }
        self.mangoAPISrvc.showLoader(false);
        Swal.fire({
          icon: 'success',
          title: self.translate.instant('Success'),
          text:
            self.selectedClientsItems.length * self.selectedItems.length +
            ' Engagement(s) Created.',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then(result => {
          self.selectedClientsItems = [];
          self.selectedItems = [];
        });
      },
      error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  async updateProjectData(bulkData, interval) {
    const self = this;
    const observableBatch = bulkData.splice(0, 100);
    for (let i = 0; i < observableBatch.length; i++) {
      let groups = await self.mangoAPISrvc
        .getCustomerGroupsByClientId(observableBatch[i].ClientID);
      if (!Array.isArray(groups)) {
        groups = [];
      }

      const currentGroupIds = observableBatch[i].GroupDescriptionIDArray || [];

      const allGroupIds = currentGroupIds.concat(groups);
      
      const combinedGroupIds = allGroupIds.reduce((acc, current) => {
        if (!acc.includes(current)) {
          acc.push(current);
        }
        return acc;
      }, []);
      
      observableBatch[i].GroupDescriptionIDArray = combinedGroupIds;
    }    
    if (observableBatch.length < 100) {
      clearInterval(interval);
    }

    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.bulkClientsUpdate({ observableBatch }).subscribe(
      function (data) {
        if (bulkData.length > 0) {
          return false;
        }

        self.mangoAPISrvc.showLoader(false);
        Swal.fire({
          icon: 'success',
          title: self.translate.instant('Success'),
          text: self.selectedClientsItems.length + ' Customers(s) Updated.',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then(result => {
          self.selectedClientsItems = [];
          self.selectedItems = [];
        });
      },
      error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateEngagementsData(bulkData, interval) {
    const self = this;
    const observableBatch = bulkData.splice(0, 100);

    if (observableBatch.length < 100) {
      clearInterval(interval);
    }

    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.bulkEngagementUpdate({ observableBatch }).subscribe(
      function (data) {
        if (bulkData.length > 0) {
          return false;
        }

        self.mangoAPISrvc.showLoader(false);
        Swal.fire({
          icon: 'success',
          title: self.translate.instant('Success'),
          text: self.selectedEngagements.length + ' Project(s) Updated.',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then(result => {
          self.selectedEngagements = [];
          self.selectedItems = [];
        });
      },
      error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  validateForm() {
    let isInValidData = false;
    Object.keys(this.assignmentForm.controls).forEach(key => {
      if (this.assignmentForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  initializeForm() {
    this.assignmentForm = this._fb.group({
      startDate: [new Date(), [<any>Validators.required]],
      staffID: ['', [<any>Validators.required]],
      termID: ['']
    });
    const parent = this;
    parent.assignmentForm.valueChanges.subscribe(data => {
      parent.validateForm();
      if (parent.selectedIndex == 1 || parent.selectedIndex == 1) {
        parent.isValidTabs =
          !parent.isFormValid ||
          parent.selectedItems.length == 0 ||
          parent.selectedClientsItems.length == 0;
      } else if (parent.selectedIndex == 2) {
        parent.isValidTabs =
          parent.selectedClientTypesObj &&
          parent.selectedClientTypesObj['CustomerTypeDescription'] &&
          parent.selectedClientsItems.length > 0
            ? false
            : true;
      } else if (parent.selectedIndex == 4) {
        parent.isValidTabs = parent.selectedClientsItems.length == 0;
      } else if (parent.selectedIndex == 6) {
        parent.isValidTabs = parent.selectedClientsItems.length > 0 ? true : false;
      } else if (parent.selectedIndex == 7) {
        parent.isValidTabs = false;
      } else if (parent.selectedIndex == 8) {
        parent.isValidTabs =
          parent.selectedCompanyLocationObj &&
          parent.selectedCompanyLocationObj['CompanyLocation'] &&
          parent.selectedClientsItems.length > 0
            ? false
            : true;
      } else if (parent.selectedIndex == 9) {
        parent.isValidTabs = parent.selectedClientsItems.length > 0 ? false : true;
      } else {
        parent.isValidTabs =
          parent.selectedClientsItems.length > 0 &&
          (parent.editableClient['BillingPartnerID'] ||
            parent.editableClient['StaffAssignedID'] ||
            parent.editableClient['OriginatingPartnerID'])
            ? false
            : true;
      }
    });
  }

  getEngagements() {
    if (this.engagementsDatasource?.length == 0) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getProjects().subscribe(
        (data: any) => {
          this.engagementsDatasource = data;
          this.mangoAPISrvc.showLoader(false);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        }
      );
    }
  }

  saveData() {
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

    const self = this;
    const observableBatch = [];
    const formObj = self.assignmentForm.value;

    if (self.selectedIndex == 1 || self.selectedIndex == -1) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        //  engagements loop
        for (let j = 0; j < self.selectedItems.length; ++j) {
          const projectObj = self.selectedItems[j];
          var defaultObj = {};
          defaultObj['EngagementTypeID'] = projectObj.EngagementTypeID;
          defaultObj['EngagementName'] = projectObj.userDescription
            ? projectObj.userDescription
            : projectObj.Description;
          defaultObj['EngagementInvoiceDescription'] = projectObj.InvoiceDescription;
          defaultObj['ClientID'] = clientObj.ClientID;
          defaultObj['ContactEmail'] = clientObj.Email;
          defaultObj['ProjectStreet'] = clientObj.BusStreet1;
          defaultObj['ProjectStreet2'] = clientObj.BusStreet2;
          defaultObj['ProjectCity'] = clientObj.BusCity;
          defaultObj['ProjectState'] = clientObj.BusState;
          defaultObj['ProjectZip'] = clientObj.BusZip;
          defaultObj['ProjectCountry'] = clientObj.BusCountry;
          clientObj['TermsID'] = clientObj.TermsID ? clientObj.TermsID : formObj.termID;
          defaultObj['TermsID'] = clientObj.TermsID ? clientObj.TermsID : null;
          defaultObj['StartDate'] = formObj.startDate;
          defaultObj['AssignedTo'] = formObj.staffID ? formObj.staffID : self.staffID;
          defaultObj['BillingMethod'] = defaultObj['BillingMethod']
            ? defaultObj['BillingMethod']
            : 'Hourly';
          defaultObj['Status'] = 'Open';
          defaultObj['DueDate'] = moment().utc().toISOString();
          defaultObj['EngagementNumber'] = '';
          defaultObj['EngagementDescription'] = '';
          defaultObj['CompanyID'] = this.companyId;
          defaultObj['CustomerContactID'] = -1;
          defaultObj['Inactive'] = false;
          defaultObj['FlatFeeAmount'] = 0.0;
          defaultObj['BillTo'] = 'Client';
          defaultObj['LaborTaxRate1'] = 0.0;
          defaultObj['LaborTaxRate2'] = 0.0;
          defaultObj['ExpenseRate1'] = 0.0;
          defaultObj['ExpenseRate2'] = 0.0;
          defaultObj['DefaultTaxRates'] = true;
          defaultObj['isBillable'] = true;
          observableBatch.push(defaultObj);
        }
      }

      if (observableBatch.length > 0) {
        self.intervalId = setInterval(() => self.createProjectData(observableBatch), 900);
      }
    } else if (self.selectedIndex == 11 && self.selectedCustomTableTemplate) {
      const customTablesClient = [];
      this.selectedClientsItems = this.selectedClientsItems
        .map(item => {
          const obj = {
            CustomTablesCompanyID: this.selectedCustomTableTemplate['CustomTablesCompanyID'],
            TemplateName: this.selectedCustomTableTemplate['TemplateName'],
            FirmTemplateName: this.selectedCustomTableTemplate['TemplateName'],
            Description: `{${this.selectedCustomTableTemplate['Description'].map(item => item == "" ? "-" : item).join(", ")}}`,
            Value: `{${this.selectedCustomTableTemplate['Description'].map(desc => "-").join(",")}}`,
            CompanyID: this.companyId,
            ClientID: item['ClientID'],
            OverRide: false
          }
          customTablesClient.push(obj)
          if (item['CustomTableClients']) {
            const temp = item['CustomTableClients'].find(item1 => item1['CustomTablesCompanyID'] == obj['CustomTablesCompanyID'])
            if (!temp) {
              item['CustomTableClients'].push(obj);
              item['TemplateName'] = item['CustomTableClients'].map(item => item['FirmTemplateName']).join(", ")
            }
          }
          return item;
        })
      const observableBatch = [];
      customTablesClient.forEach((selectedItem, key) => {
        observableBatch.push(this.mangoAPISrvc.postCustomTableClient(selectedItem));
      });
      this.mangoAPISrvc.showLoader(true);
      forkJoin(observableBatch).subscribe(
        result => {
          this.mangoAPISrvc.showLoader(false);
          self.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
          this.selectedClientsItems = [];
          this.clientCustomTableChange({ value: this.showClientsWithTemplates }); //remove client with templates
          this.isValidTabs = true;
        },
        error => {
          this.mangoAPISrvc.showLoader(false);
          self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        }
      );
    } else if (self.selectedIndex == 2 && self.selectedClientTypesObj) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        defaultObj['ClientTypeID'] = self.selectedClientTypesObj.CustomerTypeID;
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }
      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (self.selectedIndex == 12 && this.makeClientInactiveActive) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        defaultObj['Inactive'] = !this.showInactiveClients;
        clientObj['Inactive'] = !this.showInactiveClients;
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }
      this.showInactiveOrActiveToggle();
      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (self.selectedIndex == 9) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        defaultObj['InvoiceReminderActivate'] = !self.showStatementEnabled;
        clientObj['InvoiceReminderActivate'] = !self.showStatementEnabled;
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }
      this.showStatementEnabledToggle();
      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (self.selectedIndex == 6 && self.selectedClientTypesObj) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        if (self.isInvoiceTemplateSelected) {
          defaultObj['DefaultInvoiceTemplate'] = self.selectedInvoiceTemplate || '2';
        }
        if (self.isFinalizeActionSelected) {
          defaultObj['FinalizeAction'] = self.selectedfinalizeAction;
        }
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }

      if (
        observableBatch.length > 0 &&
        (self.isInvoiceTemplateSelected || self.isFinalizeActionSelected)
      ) {
        const interval = setInterval(() => {
          self.updateProjectData(observableBatch, interval);
        }, 900);
      }
    } else if (self.selectedIndex == 4) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        defaultObj['GroupDescriptionIDArray'] =
          self.selectedGpItems?.length > 0
            ? self.selectedGpItems.map(a => a.CustomerGroupCategoryID)
            : null;
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }

      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (this.selectedIndex == 5) {
      const _this = this;
      if (this.invoiceOpt.NoLateFees == 'false') {
        this.invoiceOpt.IsInterestRate = true;
        this.invoiceOpt.InterestRate = this.invoiceOpt.InterestRate;
        this.invoiceOpt.NoLateFees = false;
      }
      if (this.invoiceOpt.NoLateFees == 'true') {
        this.invoiceOpt.IsInterestRate = false;
        this.invoiceOpt.InterestRate = null;
        this.invoiceOpt.NoLateFees = true;
      }

      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        const tempObj = {};
        tempObj['NoLateFees'] = this.invoiceOpt.NoLateFees;
        tempObj['IsInterestRate'] = this.invoiceOpt.IsInterestRate;
        tempObj['InterestRate'] = this.invoiceOpt.InterestRate;
        tempObj['GraceDays'] = this.invoiceOpt.GraceDays;
        tempObj['TermsID'] = this.invoiceOpt.TermsID;
        tempObj['UseDefaultTerms'] = false;
        tempObj['ClientID'] = clientObj.ClientID;
        tempObj['CompanyID'] = clientObj.CompanyID;
        observableBatch.push(tempObj);
      }

      this.invoiceOpt.NoLateFees = this.invoiceOpt.NoLateFees.toString();

      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (this.selectedIndex == 7) {
      self.mangoAPISrvc.showLoader(true);
      const obj = { engagementName: this.engagementName, descInvoice: this.descInvoice };
      self.mangoAPISrvc.updateEngagement(obj).subscribe(function (data) {
        self.mangoAPISrvc.showLoader(false);
        self.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      }, error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      });
    } else if (self.selectedIndex == 8 && self.selectedCompanyLocationObj) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        defaultObj['CompanyMangoLocationID'] =
          self.selectedCompanyLocationObj.CompanyMangoLocationID;
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }

      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (self.selectedIndex == 10) {
      self.isValidTabs = true;
      self.mangoAPISrvc.showLoader(true);
      const obj = {
        LastLateFeeDate: moment(self.lastLateFeeDate).format('YYYY-MM-DD')
      };
      self.mangoAPISrvc.updateLastLateFeeDate(self.companyId, obj).subscribe(
        data => {
          self.mangoAPISrvc.showLoader(false);
          self.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        },
        err => {
          self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          self.mangoAPISrvc.showLoader(false);
        }
      );
    } else if (self.selectedIndex == 16) {
      self.isValidTabs = true;
      self.mangoAPISrvc.showLoader(true);
      for (let i = 0; i < self.selectedEngagements.length; ++i) {
        const projObj = self.selectedEngagements[i];
        var defaultObj = {};
        defaultObj['isTaxable'] = self.isTaxable;
        defaultObj['isCalcTaxNoTime'] = self.isCalcTaxNoTime;
        defaultObj['ProjectMasterID'] = projObj.ProjectMasterID;
        observableBatch.push(defaultObj);
      }

      if (observableBatch.length > 0) {
        const interval = setInterval(
          () => self.updateEngagementsData(observableBatch, interval),
          900
        );
      }
    } else if (self.selectedIndex == 17) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        var defaultObj = {};
        defaultObj['BillingGroupID'] = self.selectedBillingGroup?.CustomerGroupCategoryID || null;
        defaultObj['ClientID'] = clientObj.ClientID;
        observableBatch.push(defaultObj);
      }
      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    } else if (self.selectedIndex == 18) {
      this.contactObj['CompanyID'] = this.companyId;
      if (this.createNewContact) {
        this.selectedCompanyContacts = [this.contactObj];
      }
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc
        .assignContactsToMultipleClients(
          this.selectedClientsItems.map(item => item['ClientID']),
          this.selectedCompanyContacts
        )
        .subscribe(result => {
          this.mangoAPISrvc.showLoader(false);
          this.selectedClientsItems = [];
          this.selectedCompanyContacts = [];
          this.contactObj = {};
          if (result) {
            const newContacts = result['newContacts'].filter(item => item['method'] == "create").length;
            const updatedContacts = result['newContacts'].filter(item => item['method'] == "update").length;
            Swal.fire({
              icon: 'success',
              title: `${this.translate.instant('Success')}!`,
              html: `${newContacts} new contacts created and ${updatedContacts} existing contacts updated.`,
              showConfirmButton: true,
              confirmButtonText: self.translate.instant('OK'),
              timer: 5000
            });
          }
        });
    } else if (self.selectedIndex == 3) {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        if (this.isBillingPartnerIncluded) {
          clientObj.BillingPartnerID = self.editableClient.BillingPartnerID;
        }
        if (this.isStaffAssignedIncluded) {
          clientObj.StaffAssignedID = self.editableClient.StaffAssignedID;
        }
        if (this.isOriginatingPartnerIncluded) {
          clientObj.OriginatingPartnerID = self.editableClient.OriginatingPartnerID;
        }
        observableBatch.push(clientObj);
      }

      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    }else if (self.selectedIndex == 20){

      const projects: any = {
        CompanyID: this.companyId,
        Engagement: this.selectedEngagementItems,
        Projects: this.selectedProjects
      }
      console.log(projects);
      this.mangoAPISrvc.updateProjectWithNoEngagement(projects).subscribe((data:any) =>{
        console.log(data);
        this.mangoAPISrvc.notify('success', 'Success!', data.message);
      });
      this.loadProjectsWithNoEngagements();
    }
    else {
      for (let i = 0; i < self.selectedClientsItems.length; ++i) {
        const clientObj = self.selectedClientsItems[i];
        if (self.editableClient.BillingPartnerID && this.isBillingPartnerIncluded) {
          clientObj.BillingPartnerID = self.editableClient.BillingPartnerID;
        }
        if (self.editableClient.StaffAssignedID && this.isStaffAssignedIncluded) {
          clientObj.StaffAssignedID = self.editableClient.StaffAssignedID;
        }
        if (self.editableClient.OriginatingPartnerID && this.isOriginatingPartnerIncluded) {
          clientObj.OriginatingPartnerID = self.editableClient.OriginatingPartnerID;
        }
        observableBatch.push(clientObj);
      }

      if (observableBatch.length > 0) {
        const interval = setInterval(() => self.updateProjectData(observableBatch, interval), 900);
      }
    }
  }

  toggleSelectCompanyContact(rowData) {
    if (
      this.selectedCompanyContacts.filter(contact => contact['Email'] == rowData['Email']).length >
      1
    ) {
      Swal.fire({
        title: this.translate.instant('warning'),
        text: this.translate.instant(`Multiple '${rowData['Email']}' emails were detected. `),
        icon: 'warning',
        confirmButtonText: this.translate.instant('Ok'),
        timer: 5000
      }).then(result => {
        this.selectedCompanyContacts = this.selectedCompanyContacts.filter(
          contact => contact['CustomerContactID'] != rowData['CustomerContactID']
        );
      });
    }
  }
  onRowSelect() {
    this.formChanged(null, false);

    const parent = this;
    if (parent.selectedIndex == 1 || parent.selectedIndex == -1) {
      parent.isValidTabs =
        !parent.isFormValid ||
        parent.selectedItems.length == 0 ||
        parent.selectedClientsItems.length == 0;
    } else if (parent.selectedIndex == 2) {
      parent.isValidTabs =
        parent.selectedClientTypesObj &&
        parent.selectedClientTypesObj['CustomerTypeDescription'] &&
        parent.selectedClientsItems.length > 0
          ? false
          : true;
    } else if (parent.selectedIndex == 3) {
      parent.isValidTabs = parent.selectedClientsItems.length == 0;
    } else if (parent.selectedIndex == 4) {
      parent.isValidTabs = parent.selectedClientsItems.length == 0;
    } else if (
      parent.selectedIndex == 5 ||
      parent.selectedIndex == 6 ||
      parent.selectedIndex == 9
    ) {
      parent.isValidTabs =
        parent.selectedClientsItems.length == 0 ||
        (parent.selectedIndex == 6 &&
          !(parent.isInvoiceTemplateSelected || parent.isFinalizeActionSelected));
    } else if (parent.selectedIndex == 8) {
      parent.isValidTabs =
        parent.selectedCompanyLocationObj &&
        parent.selectedCompanyLocationObj['CompanyLocation'] &&
        parent.selectedClientsItems.length > 0
          ? false
          : true;
    } else if (parent.selectedIndex == 11) {
      parent.isValidTabs = !(
        parent.selectedCustomTableTemplate &&
        parent.selectedCustomTableTemplate['CustomTablesCompanyID'] &&
        parent.selectedClientsItems.length > 0
      );
    } else if (parent.selectedIndex == 12) {
      parent.isValidTabs = !(parent.selectedClientsItems.length > 0);
    } else if (parent.selectedIndex == 16) {
      parent.isValidTabs = !(parent.selectedEngagements.length > 0);
    } else if (parent.selectedIndex == 17) {
      parent.isValidTabs = !(parent.selectedClientsItems.length > 0);
    } else if (parent.selectedIndex == 18) {
      if (!this.createNewContact) {
        parent.isValidTabs = !(
          parent.selectedClientsItems.length > 0 && parent.selectedCompanyContacts.length > 0
        );
      } else {
        parent.isValidTabs = !(
          parent.selectedClientsItems.length > 0 &&
          this.contactObj['ContactName'] &&
          this.contactObj['Email'] &&
          this.isEmailValid
        );
      }
    }  else {
      parent.isValidTabs = (parent.selectedClientsItems.length > 0 && (parent.editableClient['BillingPartnerID'] || parent.editableClient['StaffAssignedID'] || parent.editableClient['OriginatingPartnerID'])) ? false : true;
    }
  }

  refresh20(e:any){
    this.loadProjectsWithNoEngagements();
  }

  toggleCreateNewContact() {
    this.createNewContact = !this.createNewContact;
    this.selectedCompanyContacts = [];
    this.selectedClientsItems = [];
    this.isValidTabs = true;
    this.selectedProjects = [];
    // this.contactObj = {};
  }

  replaceShortcuts(value, obj?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    this.descInvoice = valueArr.join(' ');
    if (obj) obj['ScratchPad'] = valueArr.join(' ');
  }

  findChoices(searchText: string) {
    return this['viewContainerRef']['parentInjector']['view']['component'][
      'rawshortcutLabels'
    ].filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
  }

  getChoiceLabel(choice: string) {
    return `${choice} `;
  }

  validateEngagement() {
    if (!this.engagementName || !this.descInvoice) {
      this.isValidTabs = true;
    } else {
      this.isValidTabs = false;
    }
  }

  formChanged(event?: any, isFormChanged = true) {
    const self = this;
    if (event) {
      const selTerm = self.termsList.filter(itm => itm.value == event.value);
      if (selTerm.length > 0) {
        self.invoiceOpt.GraceDays = selTerm[0].GraceDays;
      }
    }
    if (self.selectedIndex == 6) {
      self.isValidTabs =
        self.selectedClientsItems.length == 0 ||
        !(self.isInvoiceTemplateSelected || self.isFinalizeActionSelected);
    }
    self.encrDecSrvc.addObject(AppConstants.isFormChanged, isFormChanged);
  }

  onSelectLastLateFee() {
    if (this.lastLateFeeDate) this.isValidTabs = false;
    else this.isValidTabs = true;
  }

  onRollOverBudgets() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('budget.rollover_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes_Proceed'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result.value) {
        const obj = {
          CompanyID: this.companyId,
          StaffID: this.staffID,
          year: this.selectedYear
        };
        this.mangoAPISrvc.showLoader(true);
        setTimeout(() => {
          //dummy loader to let the user know it has been triggered.
          this.mangoAPISrvc.showLoader(false);
        }, 1000);
        this.mangoAPISrvc.rollOverBudgets(obj).subscribe(
          (result: any) => {
            this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          },
          err => {}
        );
      }
    });
  }
}
