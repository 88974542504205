import { Component, OnInit} from "@angular/core";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { SelectItem } from "primeng/api";
import swal from "sweetalert2";
import { AppConstants } from "@app/_helpers/api-constants";

@Component({
  selector: "app-email-automation-setup",
  templateUrl: "./email-automation-setup.component.html",
})
export class EmailAutomationSetupComponent implements OnInit {
  public ActivateEmailAutomation: boolean = false;
  public EmailAutomationIntegration: string = "";
  public listDataSource: SelectItem[] = [];
  public EmailAutomationKey: string = "";
  public mangoCompanyDetails: any = {};
  public showAPIKey: boolean = false;
  public isValid: boolean = false;
  public optionsSet: SelectItem[] = [
    { label: "SendInBlue", value: "SendInBlue" },
    { label: "MailChimp", value: "MailChimp" },
  ];

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: "Email Automation" },
      { label: "Setup", icon: "ic-red" },
    ]);

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getCompanyMangoDetails(
        this.encrDecSrvc.getObject(AppConstants.companyID)
      )
      .subscribe((companyData) => {
        this.mangoCompanyDetails = companyData[0];
        if (this.mangoCompanyDetails.ActivateEmailAutomation) {
          this.ActivateEmailAutomation = this.mangoCompanyDetails.ActivateEmailAutomation;
          this.EmailAutomationIntegration = this.mangoCompanyDetails.EmailAutomationIntegration;
          this.EmailAutomationKey = this.mangoCompanyDetails.isEAKPresents ? 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx' : "";
          if (this.ActivateEmailAutomation && this.mangoCompanyDetails.isEAKPresents) {
            if (this.EmailAutomationIntegration == "SendInBlue") {
              this.mangoAPISrvc
                .getSndInBlueAccountDetails()
                .subscribe((result) => {
                  this.getDataList(true);
                });
            } else {
              this.mangoAPISrvc
                .getMailChimpAccountDetails()
                .subscribe((result) => {
                  this.getDataList(false);
                });
            }
          }
        }
        this.mangoAPISrvc.showLoader(false);
      });
  }

  ngOnInit(): void {}

  saveOptions() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    const obj = {};
    parent.listDataSource = [];
    obj["ActivateEmailAutomation"] = parent.ActivateEmailAutomation;
    obj["EmailAutomationIntegration"] = parent.EmailAutomationIntegration;
    obj["EmailAutomationKey"] = parent.EmailAutomationKey;
    if (
      parent.ActivateEmailAutomation &&
      (parent.EmailAutomationIntegration == "" ||
        parent.EmailAutomationKey == "")
    ) {
      return false;
    }
    parent.mangoAPISrvc.updateEmailautomation(obj).subscribe((data: any) => {
      if (parent.EmailAutomationIntegration == "SendInBlue") {
        if (parent.EmailAutomationKey) {
          parent.mangoAPISrvc
            .getSndInBlueAccountDetails()
            .subscribe(
              (result) => {
                swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Successfully Connected.",
                  showConfirmButton: false,
                  timer: 2500,
                });
                if (parent.listDataSource.length == 0) {
                  parent.getDataList(true);
                }
                parent.mangoAPISrvc.showLoader(false);
                window.location.reload();
              },
              (error) => {
                swal.fire({
                  icon: "warning",
                  title: "Error!",
                  text: "Unable to connect. Invalid API Key",
                  showConfirmButton: false,
                  timer: 2500,
                });
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        } else {
          swal.fire({
            icon: "success",
            title: "Success!",
            text: data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          parent.mangoAPISrvc.showLoader(false);
          window.location.reload();
        }
      } else {
        if (parent.EmailAutomationKey) {
          parent.mangoAPISrvc
            .getMailChimpAccountDetails()
            .subscribe(
              (result) => {
                swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Successfully Connected.",
                  showConfirmButton: false,
                  timer: 2500,
                });
                if (parent.listDataSource.length == 0) {
                  parent.getDataList(false);
                }
                parent.mangoAPISrvc.showLoader(false);
                window.location.reload();
              },
              (error) => {
                swal.fire({
                  icon: "warning",
                  title: "Error!",
                  text: "Unable to connect. Invalid API Key",
                  showConfirmButton: false,
                  timer: 2500,
                });
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        } else {
          swal.fire({
            icon: "success",
            title: "Success!",
            text: data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          parent.mangoAPISrvc.showLoader(false);
          window.location.reload();
        }
      }
    });
  }

  getDataList(isSendInBlue) {
    this.mangoAPISrvc.showLoader(true);
    if (isSendInBlue) {
      this.mangoAPISrvc
        .getSendinblueList()
        .subscribe((data: any) => {
          this.listDataSource = [...data.lists];
        });
      this.mangoAPISrvc.showLoader(false);
    } else {
      this.mangoAPISrvc
        .getMailChimpList()
        .subscribe((data: any) => {
          this.listDataSource = [...data.lists];
        });
      this.mangoAPISrvc.showLoader(false);
    }
  }

  onToggle() {
    if (!this.ActivateEmailAutomation) {
      this.EmailAutomationIntegration = "";
      this.EmailAutomationKey = "";
      this.isValid = true;
    }
  }

  onChangeOptions(event) {
    this.EmailAutomationKey = "";
    if (event.value) {
      this.showAPIKey = true;
    } else {
      this.showAPIKey = false;
    }
  }

  onAPIKeyChange() {
    if (this.EmailAutomationKey.length > 20) this.isValid = true;
  }
}
