import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { BadgeModule } from "primeng/badge";
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';

import { AppSharedModule } from '@shared-modules/app-shared.module';
import { ProjectListRoutingModule } from './project-list-routing.module';
import { ProjectListComponent } from './project-list.component';
import { ProjectListProjectsComponent } from './project-list-projects/project-list-projects.component';
import { ProjectListTasksComponent } from './project-list-tasks/project-list-tasks.component';
import { BudgetsModule } from '../../../shared/components/budgets/budgets.module';
import { RruleGeneratorModule } from '../../../shared/components/rrule-generator/rrule-generator.module';
import { CreateProjectFromTemplateModule } from '@app/shared/components/create-project-from-template/create-project-from-template.module';

@NgModule(
  {
    imports: [
      CommonModule,
      AppSharedModule,
      BudgetsModule,
      ProjectListRoutingModule,
      RruleGeneratorModule,
      CreateProjectFromTemplateModule,
      PaginatorModule,
      RadioButtonModule,
      BadgeModule
    ],

    declarations: [
      ProjectListComponent,
      ProjectListProjectsComponent,
      ProjectListTasksComponent,
    ],
  }
)
export class ProjectListModule { }
