import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "@app/_helpers/api-constants";
import { SortEvent } from 'primeng/api';
import {
  EncrDecrService,
  MangoApiService,
  BreadcrumbService,
  mangoUtils,
  AuthGuard
} from "@app/_services";
import { Table } from "primeng/table";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { SharedComponentsService } from "@app/shared/components";
declare let numeral: any;

@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
})
export class ActivitiesComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dataTableComponent: Table;
  @ViewChild('ActivitiesMainForm') ngForm;
  activitiesInactive: boolean = false;
  displayAddActivityModal: boolean = false;
  displayDeleteActivityModal: boolean = false;
  selectedServiceCode = "";
  isEditActivity: boolean = false;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  timeExpObject: any = {};
  activityList: any = [];
  activity: any = {};
  isCopyDescription: boolean = false;
  subscription;

  constructor(
    private authGuard: AuthGuard,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    protected mangoUtils: mangoUtils,
    private translate: TranslateService,
    public sharedSrvc: SharedComponentsService,
  ) {
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.searchTextStr = this.encrDecSrvc.getObject(
      AppConstants.Invoices + "_" + AppConstants.SearchString
    );
    this.timeExpObject = this.encrDecSrvc.getObject(
      AppConstants.timeAndExpenses
    );
    this.activityList = this.encrDecSrvc.getObject(AppConstants.activityList);
    // this.tableDataSorce = this.activityList.filter(
    //   (expense) => expense.Inactive == this.activitiesInactive
    // );
    this.loadFilterGrid();

    this.subscription = this.sharedSrvc.activityDialogData.subscribe((data) => {
      if (data) {
        this.reload();
      }
    });
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('activities'), icon: 'ic-red'  },
    ]);
  }

  saveInactive(item) {
    this.activity = item;
    this.saveActivity(false, true);
  }

  onChangeInputCode() {
    this.activity.ServiceCode = this.activity.ServiceCode.toUpperCase();
  }

  copyToWorkMemo() {
    // if(!this.isCopyDescription)
    //   return;

    // if (this.activity.Description) {
    //   if(this.activity.DefaultMemo)
    //     this.activity.DefaultMemo += this.activity.Description;
    //   else
    //     this.activity.DefaultMemo = this.activity.Description;
    // } else {
    //   if(this.activity.DefaultMemo)
    //     this.activity.DefaultMemo += this.activity.DefaultMemo.substring(this.activity.DefaultMemo.indexOf(this.activity.Description) + this.activity.Description.length, );
    //   else
    //     this.activity.DefaultMemo = this.activity.Description;
    // }
    // For button
    this.activity.DefaultMemo = this.activity.Description;
  }

  reload() {
    const _this = this;
    _this.encrDecSrvc.removeObject(AppConstants.activityList);
    this.mangoAPISrvc.getActivities().subscribe(function (data: any) {
      _this.mangoAPISrvc.showLoader(false);
      _this.activityList = data.map(item=>{
        item['TemplateName'] = item['RateTemplateNamesID']? item['TemplateName'] : "None Assigned"
        return item;
      });
      _this.encrDecSrvc.addObject(AppConstants.activityList, data);
      _this.tableDataSorce = _this.activityList.filter(
        (expense) => expense.Inactive == _this.activitiesInactive
      );
      //_this.activitiesInactive = true;
    });
  }

  saveAndNew(IsEdit) {
    this.saveActivity(!IsEdit, false);
  }

  saveActivity(isSave, isClose) {
    const _this = this;
    _this.activity.ServiceCode = _this.activity.ServiceCode.trim().toUpperCase();
    _this.activity.BillRate = _this.activity.BillRate
      ? _this.activity.BillRate.toString()
      : "0.00";
    if (typeof _this.activity.Tax1 == "object") {
      _this.activity.Tax2 = false;
      _this.activity.Tax1 = true;
    }
    if (typeof _this.activity.Tax2 == "object") {
      _this.activity.Tax1 = false;
      _this.activity.Tax2 = true;
    }
    _this.mangoAPISrvc.showLoader(true);
    if (isSave || _this.activity["ServiceCodeID"] === null) {
      delete _this.activity["ServiceCodeID"];
      _this.mangoAPISrvc.addActivity(_this.activity).subscribe(
        function (data) {
          _this.reload();
          _this.mangoAPISrvc.notify(
            "success",
            _this.translate.instant('Success'),
            AppConstants.createMsg
          );
        },
        (error) => {
          _this.mangoAPISrvc.showLoader(false);
          _this.mangoAPISrvc.notify(
            "error",
            _this.translate.instant('error'),
            AppConstants.createErrorMsg
          );
        }
      );
    } else {
      _this.mangoAPISrvc
        .updateActivity(_this.activity.ServiceCodeID, _this.activity)
        .subscribe(
          function (data) {
            _this.reload();
            _this.mangoAPISrvc.notify(
              "success",
              _this.translate.instant('Success'),
              AppConstants.updateMsg
            );
          },
          (error) => {
            _this.mangoAPISrvc.showLoader(false);
            _this.mangoAPISrvc.notify(
              "error",
              _this.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
          }
        );
    }
    if (isClose) {
      _this.displayAddActivityModal = false;
    } else {
      _this.isEditActivity = false;
      _this.activity = {
        ServiceCodeID: null,
        ServiceCode: "",
        Description: "",
        Tax1: false,
        Tax2: false,
        Billable: true,
        BillRate: "0",
        Inactive: false,
        DefaultMemo: "",
      };
    }
  }

  transformbillrate(event) {
    const value = numeral(this.activity.BillRate).value();
    if (value && value > 0) {
      event.target.value = numeral(value).format("0,0.00");
    } else {
      event.target.value = 0.0;
      this.activity.BillRate = null;
    }
  }

  customSort(event: SortEvent) {
    this.mangoUtils.tableColumnsSort(event);
  }

  closeActivity(isSave?: any) {
    // this.ngForm.form.markAsPristine();
    // this.ngForm.form.markAsUntouched();
    // this.ngForm.form.updateValueAndValidity();
    this.activity = {};
    this.displayAddActivityModal = false;
  }

  editActivity(data) {
    if(!this.isAllowUpdateActivity) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: 'Your permission level does not allow access to edit an activity.'
      });
      return
    }
    this.activity ={};
    this.activity = Object.assign({}, data);
    this.activity.BillRate = numeral(this.activity.BillRate).format("0,0.00");

    this.isEditActivity = true;
    this.selectedServiceCode = data.ServiceCode.toString().toUpperCase();
    this.activity.Tax1 = this.activity.Tax1 ? this.activity.Tax1 : false;
    this.activity.Tax2 = this.activity.Tax2 ? this.activity.Tax2 : false;

    this.sharedSrvc.openActivityDialog({isEditFlow: true, activity: this.activity})
    // this.displayAddActivityModal = true;
  }

  deleteActivity(id) {
    const parent = this;
    parent.mangoAPISrvc
      .getTimeRecordsByServiceCodeId(id)
      .subscribe(function (count: any) {
        if (count > 0) {
          Swal.fire({
            icon: "warning",
            title: parent.translate.instant('warning'),
            text: parent.translate.instant('activity_cant_be_deleted'),
            showConfirmButton: false,
            timer: 3500,
          });
        } else {
          Swal.fire({
            title: parent.translate.instant('confirmation'),
            text: parent.translate.instant('delete_alert'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: parent.translate.instant('yes_delete'),
            cancelButtonText: parent.translate.instant('no_cancel')
          }).then((result) => {
            if (result.value) {
              parent.mangoAPISrvc.deleteActivity(id).subscribe(function (data) {
                parent.reload();
                parent.mangoAPISrvc.notify(
                  "success",
                  parent.translate.instant('Success'),
                  AppConstants.deleteMessage
                );
              });
            }
          });
        }
      });
  }

  addActivity() {
    if(!this.isAllowCreateActivity) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: 'Your permission level does not allow access to create an activity.'
      });
      return
    }
    this.activity = {};
    this.isEditActivity = false;
    this.activity.ServiceCode = "";
    this.activity.Description = "";
    this.activity.Tax1 = this.timeExpObject.ActivateLaborRates
      ? this.timeExpObject.ActivateLaborRates
      : false;
    this.activity.Tax2 = false;
    this.activity.Billable = true;
    this.activity.BillRate = "0.00";
    this.activity.Inactive = false;
    // this.displayAddActivityModal = true;

    this.sharedSrvc.openActivityDialog({isEditFlow: false, activity: this.activity})
  }

  onStatusChange() {
    this.tableDataSorce = this.activityList.filter(
      (expense) => expense.Inactive == this.activitiesInactive
    );
  }

  validateSC(obj) {
    const result = this.activityList.filter(
      (item) =>
        item.ServiceCode.toString().toUpperCase() ==
        obj.ServiceCode.toString().toUpperCase()
    );
    if (result.length > 0) {
      if (
        this.selectedServiceCode == obj.ServiceCode.toString().toUpperCase()
      ) {
        return false;
      }
      obj.ServiceCode = null;
      Swal.fire({
        icon: "warning",
        title: this.translate.instant('warning'),
        text: this.translate.instant('duplicate_activity_not_allowed'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  onActivityAddStatusChange(index) {
    switch (index) {
      case 1:
        this.activity.Tax1 = !this.activity.Tax1;
        break;
      case 2:
        this.activity.Tax2 = !this.activity.Tax2;
        break;
      case 4:
        this.activity.Billable = !this.activity.Billable;
        break;
      case 5:
        this.activity.Inactive = !this.activity.Inactive;
        break;
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(
      AppConstants.Invoices + "_" + AppConstants.SearchString,
      ""
    );
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      AppConstants.Invoices + "_" + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event("input", {
          bubbles: true,
          cancelable: true,
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  get isAllowCreateActivity() {
    return this.authGuard.isAllowAccess(40)
  }

  get isAllowUpdateActivity() {
    return this.authGuard.isAllowAccess(44)
  }
}
