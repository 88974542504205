<div class="layout-content">
    <div class="clientform-btn-top">
        <p-button pRipple label="{{'user.user_list' | translate}}" icon="fal fa-user-friends" styleClass="p-button-sm"
            class="p-button-help p-pr-2" (click)="redirectToUserList()"></p-button>
    </div>
    <div class="p-grid p-px-3">
        <div class="p-col-9">
            <div class="card">
                <h4 class="label-text p-mb-3">
                    User Permission Role
                </h4>
                <div class="p-col-12">
                    <label class="label-text p-mr-3">Selected User Role</label>
                    <p-dropdown [options]="permissionProfiles" styleClass="width-50p" [(ngModel)]="selectedProfile"
                        (onChange)="onChangePermissionProfile($event)">
                    </p-dropdown>
                    <button type="button"
                        pButton pRipple label="{{ 'rate.manage' | translate }} Firm Roles" icon="fal fa-cogs"
                        class="p-ml-3 p-button-sm"
                        (click)="showManageDialog()" pTooltip="{{'changes-in-permission' | translate}}">
                    </button>
                    <div class="p-col-12" *ngIf="selectedProfile">
                        <p-table styleClass="p-datatable-sm p-datatable-responsive-demo" [rowHover]="true"
                            #tableProfile [value]="groupedPermissions && selectedProfile && groupedPermissions[selectedProfile] ? groupedPermissions[selectedProfile] : [{}]" [scrollable]="true" [responsive]="true" [scrollHeight]="'calc(56vh - 100px)'">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="ic-red width-70p">Module(s)/Feature(s)</th>
                                    <th class="p-text-center width-15p">Allow/Deny Access</th>
                                    <th class="p-text-left width-15p">
                                        Exceptions
                                        <i pTooltip="{{'permissions.exception_tooltip' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap"
                                            tooltipPosition="top" class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr>
                                    <td *ngIf="!!!rowData?.masterPermission?.ParentPermissionID" class="p-text-truncate ic-red p-text-nowrap width-70p p-text-left" 
                                        pTooltip="{{ rowData?.masterPermission?.Description }}" tooltipPosition="top" 
                                        tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                                        <strong>{{ rowData?.masterPermission?.Description }}</strong></td>
                                    <td *ngIf="!!rowData?.masterPermission?.ParentPermissionID" class="p-text-truncate p-text-nowrap width-70p p-text-left" 
                                        pTooltip="{{ rowData?.masterPermission?.Description }}" tooltipPosition="top" 
                                        tooltipStyleClass="tooltip-no-wrap" showDelay="800"><span class="p-pl-3">{{ rowData?.masterPermission?.Description }}</span></td>
                                    <td class="p-text-center width-15p">
                                        <i *ngIf="rowData?.val && !(rowData?.id == 11 || rowData?.id == 13)" class="fal fa-check ic-green ic-size-16"></i>
                                        <i *ngIf="!rowData?.val && !(rowData?.id == 11 || rowData?.id == 13)" class="fal fa-ban ic-red ic-size-16"></i>
                                    </td>

                                    <!-- use rowData?.id -->
                                    <td class="p-text-left width-15p">
                                        <p-triStateCheckbox
                                            *ngIf="rowData?.id && !(rowData?.id == 11 || rowData?.id == 13) && userExceptions && userExceptions[rowData.id]"
                                            [(ngModel)]="userExceptions[rowData.id].val"
                                            [ngClass]="userExceptions[rowData.id].label === exceptionLabels.NONE ? ' ' : userExceptions[rowData.id].label === exceptionLabels.ALWAYS_DENY ? 'ic-red' : 'ic-green'"
                                            (onChange)="onChangeException(userExceptions[rowData.id])" [label]="userExceptions[rowData.id].label">
                                        </p-triStateCheckbox>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{ 'data_not_found' | translate }}." styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-dialog [(visible)]="isShowAddRoleDialog" [modal]="true" [style]="{ width: '30vw' }"
        [contentStyle]="{overflow: 'visible'}" [baseZIndex]="1" [maximizable]="false" showEffect="fade" [draggable]="true"
        [resizable]="false" (onHide)="onCloseAddRoleDialog()">
        <p-header>
            Add Role
        </p-header>
        <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
            <div class="p-formgroup-inline p-col-12">
                <div class="p-col-2 p-mt-4">
                    <label class="label-text">Title</label>
                </div>
                <div class="p-col-8 p-mt-4">
                    <input class="width-100p p-text-left" type="text" pInputText [(ngModel)]="formObj.title"
                        (focus)="$event.target.select();" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex p-jc-end">
                <button type="button" pButton pRipple label="{{ 'Create' | translate }}" (click)="onCreateRole()"
                    [disabled]="formObj.title?.length == 0"></button>
                <button type="button" pButton pRipple label="{{ 'Cancel' | translate }}" 
                    (click)="onCloseAddRoleDialog()"></button>
            </div>
        </ng-template>
    </p-dialog>

    <p-sidebar [(visible)]="isShowManageDialog" position="right" (onHide)="onCloseManageDialog()"
        [style]="{'min-width':'70%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <!-- <div class="card p-pt-4" style="min-height: calc(100vh - 35px)">
            <h4 class="label-text p-mb-3">
                Manage Permission Roles
            </h4>
            <div class="p-col-12">
                <label class="label-text p-mr-3">Role to Manage</label>
                <p-dropdown [options]="permissionProfiles" placeholder="Select Role" styleClass="width-40p"
                    [(ngModel)]="selectedProfileToManage">
                </p-dropdown>
                <button type="button" pButton pRipple label="{{ 'add' | translate }} Role" icon="fal fa-plus"
                    class="p-ml-3 p-button-sm" (click)="showAddDialog()">
                </button>
                <button type="button" pButton
                    pRipple label="{{ 'mgmt-delete' | translate }} Role" icon="fal fa-trash-alt"
                    class="p-ml-3 p-button-sm" (click)="deleteRole()"></button>
                <div class="p-col-12" *ngIf="selectedProfileToManage">
                    <p-table styleClass="p-datatable-sm p-datatable-responsive-demo" #tableManage [rowHover]="true"
                        [value]="groupedPermissions && selectedProfileToManage && groupedPermissions[selectedProfileToManage] ? groupedPermissions[selectedProfileToManage] : [{}]"
                        [scrollable]="true" [responsive]="true" [scrollHeight]="'calc(80vh - 100px)'">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="ic-red width-80p">Module(s)/Feature(s)</th>
                                <th class="p-text-center width-20p">Allow/Deny Access</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td *ngIf="!!!rowData?.masterPermission?.ParentPermissionID"
                                    class="p-text-truncate p-text-nowrap width-80p p-text-left ic-red"
                                    pTooltip="{{ rowData?.masterPermission?.Description }}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                                    <strong>{{ rowData?.masterPermission?.Description }}</strong>
                                </td>
                                <td *ngIf="!!rowData?.masterPermission?.ParentPermissionID"
                                    class="p-text-truncate p-text-nowrap width-80p p-text-left"
                                    pTooltip="{{ rowData?.masterPermission?.Description }}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap" showDelay="800"><span class="p-pl-3">{{
                                        rowData?.masterPermission?.Description }}</span></td>
                                <td class="p-text-center width-20p">
                                    <div *ngIf="(rowData?.id !== 11 && rowData?.id !== 13)">
                                        <button *ngIf="rowData?.val" (click)="onClickAllowDenyAccess(rowData)" pTooltip="Click to Deny Access!"
                                            tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" pButton pRipple type="button" icon="fal fa-check"
                                            class="p-button-rounded p-button-success allow-deny-btn"></button>
                                        <button *ngIf="!rowData?.val" (click)="onClickAllowDenyAccess(rowData)" pTooltip="Click to Allow Access!"
                                            tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" pButton pRipple type="button" icon="fal fa-ban ic-white"
                                            class="p-button-rounded p-button-danger allow-deny-btn"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div> -->
        <app-manage-roles [permissionProfiles]="permissionProfiles" [selectedProfileToManage]="selectedProfileToManage" [groupedPermissions]="groupedPermissions" [masterPermissions]="masterPermissions" [isFromUsers]="true">
        </app-manage-roles>
    </p-sidebar>
</div>