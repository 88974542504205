<p-dialog [header]="(isEditFlow ? 'edit_to_do' : 'add_to_do') | translate" [(visible)]="displayTaskModal" [modal]="true"
    [style]="{width: '50vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000"
    [contentStyle]="{overflow: 'visible'}" [draggable]="true" [resizable]="false"
    (onHide)="closeTaskFormModal(false,true,'close');">
    <form #todoForm="ngForm" autocomplete="nope">
        <div class="p-fluid p-formgrid p-grid p-m-2 common-dialog">
            <div class="p-col-12">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'staff' | translate }}</label>
                        <p-dropdown name="staffName" #staffName="ngModel" placeholder="Select User"
                            [options]="staffListOptions" [(ngModel)]="taskObj.StaffID" [autoWidth]=false required>
                        </p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'Category' | translate }}</label>
                        <p-dropdown placeholder="Select Category" [options]="noteCategories"
                            [(ngModel)]="taskObj.NoteCategoryID" name="itemSelected" id="itemSelected"
                            #itemSelected="ngModel" required></p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'client' | translate }}</label>
                        <p-autoComplete [(ngModel)]="selClient"
                            [suggestions]="filteredClients" autocomplete="off" (completeMethod)="filterClients($event)" field="ClientName"
                            (onSelect)="handleSelectClick($event.value)" ngDefaultControl [disabled]="taskObj.clientFieldDisabled"
                            placeholder="{{'Search-Clients' | translate }}" (onFocus)="$event.target.select()"
                            [dropdown]="true">
                            <ng-template let-client pTemplate="item">
                                <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'Priority' | translate }}</label>
                        <p-dropdown id="priority" [options]="priorityOptions" [autoWidth]=false name="priority"
                            [(ngModel)]="taskObj.Priority" #priority="ngModel" required>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'Created' | translate }}</label>
                        <p-calendar name="dateCreated" [(ngModel)]="taskObj.DateCreated" disabled="true"></p-calendar>
                        <p class="ic-red p-mt-1" *ngIf="taskObj.CreatedByStaffID" style="font-size: 11px; font-weight: 400; font-style: italic;">*Created by: {{taskObj.CreatedByStaffName}}</p>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'due-date' | translate }}</label>
                        <p-calendar name="DueDate" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="taskObj.DueDate"
                        yearRange="1000:2099" dateFormat="mm/dd/yy" showButtonBar="true">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="label-text">{{ 'completed' | translate }}</label>
                        <p-calendar name="DateFinished" [monthNavigator]="true" [yearNavigator]="true"
                            [(ngModel)]="taskObj.DateFinished" (ngModelChange)="onDateFinishedChange()" yearRange="1000:2099"
                            dateFormat="mm/dd/yy" showButtonBar="true">
                        </p-calendar>
                    </div>
                    <div class="p-field p-col-4 p-pt-5">
                        <p-checkbox [(ngModel)]="taskObj.Status" id="status" name="status" #status="ngModel" [binary]="true"
                            label="{{ 'completed' | translate }}" class="label-text p-ml-3" (onChange)="onChangeStatus();">
                        </p-checkbox>
                        <p-checkbox [(ngModel)]="taskObj.isPrivate" id="isPrivate" name="isPrivate" #isPrivate="ngModel" [binary]="true"
                            label="{{ 'private' | translate }}" class="label-text p-ml-3">
                        </p-checkbox>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-12">
                        <label for="address" class="required-field label-text">{{'description' | translate}}</label>
                        <app-mentions [(inputText)]="taskObj.Description" [required]="true" style="resize: vertical;"></app-mentions>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_and_close' | translate }}"
             (click)="saveTodo('close')" *ngIf="!isEditFlow" [disabled]="!isFormValid || !companyDetails['CompanyID'] || !taskObj.Description"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_and_new' | translate }}"
             (click)="saveTodo('new')" *ngIf="!isEditFlow" [disabled]="!isFormValid || !companyDetails['CompanyID'] || !taskObj.Description"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'save_and_duplicate' | translate }}"
             (click)="saveTodo('duplicate')" *ngIf="!isEditFlow"
            [disabled]="!isFormValid || !companyDetails['CompanyID'] || !taskObj.Description"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'update_button' | translate }}"
             (click)="saveTodo('update')" *ngIf="isEditFlow" [disabled]="!isFormValid || !companyDetails['CompanyID'] || !taskObj.Description"></button>
        <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'cancel_button' | translate }}"
             (click)="closeTaskFormModal(false,true,'close')"></button>
    </ng-template>
</p-dialog>