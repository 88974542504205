import { Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { SharedComponentsService } from "../shared-components-service";
import { MenuItem } from "primeng/api";
import Swal from "sweetalert2";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@environments/environment";
import { forkJoin, timer } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-debit-memo-dialog",
  templateUrl: "./debit-memo-dialog.component.html",
})
export class DebitMemoDialogComponent implements OnInit {
  formObj: any = {
    description: ""
  };
  @Input() showDebitMemoDialog: boolean;
  @Input() amountUnapplied: any;
  @Output('onCloseDebitMemoDialog') closeDebitMemoDialog = new EventEmitter<{}>();
  @Output('onSaveDebitMemoDialog') saveDebitMemoDialog = new EventEmitter<{
    description: string
}>();

  constructor(
    private translate: TranslateService,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    private http: HttpClient,
    private router: Router,
    private sharedSrvc: SharedComponentsService
  ) {
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {}

  replaceShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }

    this.formObj['description'] = valueArr.join(" ");
  }

  replaceCaret(event) {
    this.formObj["description"] = this.mangoUtils.replaceCaretTemplate(
      this.formObj["description"]
    );
  }

  onSave() {
    this.saveDebitMemoDialog.emit({
        description: `(DM) ${this.formObj.description}`
    });
    this.showDebitMemoDialog = false;
    this.formObj = {
      description: ""
    };
  }

  onCloseDebitMemoDialog() {
    this.closeDebitMemoDialog.emit({});
    this.showDebitMemoDialog = false;
    this.formObj = {
      description: ""
    };
  }
}
