import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import moment from 'moment';
import Swal from 'sweetalert2';
import { SharedComponentsService } from '@app/shared/components';
import * as AWS from 'aws-sdk';
import WebViewer from '@pdftron/webviewer';
import { environment } from '@environments/environment';
import { PrimeIcons, SortEvent } from 'primeng/api';
import { checkDepth, columnSelectionComplete } from '@syncfusion/ej2-angular-grids';
import { time } from 'console';
import { rowdraging } from '@syncfusion/ej2-angular-treegrid';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: 'app-client-notes',
  templateUrl: './client-notes.component.html',
  styles: ['.clientform-btn-top {top: -38px;}']
})
export class ClientNotesComponent implements OnInit {
  public tableDataSorce = [];
  public noteTableDataSource = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";
  clientID: number = -1;
  globalFilterColumns: any = ['Ddate', 'EngagementName', 'NoteCategory', 'Subject', 'StaffName'];
  deletebleIndex;
  alertMessage;
  notes = [];
  colspan: number = 8;
  public isShowfileName: boolean = false;
  public isFilePreview: boolean = false;
  public isPreviewViewerShow: boolean = false;
  public fileName = "";
  public selectedObj = null;
  public viewAttachedFiles = false;
  public noteData = null;
  public awsBucket: any;
  userName :any;
  @ViewChild("preiewviewer") preiewviewer: ElementRef;
  @ViewChild('dummyViewer') dummyViewer: ElementRef;
  dummyPDFInstance: any;
  wvPreviewInstance: any;
  isTableView: boolean = true;
  isNotesSideBarShow: boolean = false;
  clientNoteView = null;

  constructor(private _translate: TranslateService, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, protected mangoUtils: mangoUtils) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);

    const interval = setInterval(() => {
      if(!this._translate.translations[this._translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.Notes + '_' + AppConstants.SearchString);
    this.setAWSOBject();

    this.sharedSrvc.notesDialogData
      .subscribe((data) => {
        this.getNotes();
        //this.getAllCounts(this.clientID);
      })
  }

  seeMore(rowData){
    this.isNotesSideBarShow = true;
    this.clientNoteView = rowData;
  }

  closeSeeMore(){
    this.isNotesSideBarShow = false;
    this.clientNoteView = null;
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this._translate.instant('client') },
      { label: this._translate.instant('notes') },
      { label: this.clientName, icon: 'ic-red'}
    ]);
  }
  ngOnInit(): void {
    this.getNotes();
  }

  ngAfterViewInit(): void {
    this.createDummyPDFInstance('../../../assets/dummyPDF.pdf');
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.Notes + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.Notes + '_' + AppConstants.SearchString, obj.filters.global.value);
  }

  onAttachedFilesClose(){
    this.noteData = null;
    this.viewAttachedFiles = false;
  }

  onRowExpandEvent(event, isTimelineView?) {
    const self = this;
    let obj = {}
    if(isTimelineView){
      obj = event;
    }else {
      obj = event.data;
    }
    if (obj['childItem'].length > 0) {
      if(isTimelineView){
        self.noteData = obj;
        self.viewAttachedFiles = true;
      }
      return false;
    }
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getClientsdocuments(obj['CustomerNotesID']).subscribe(function (data:any) {
      self.mangoAPISrvc.showLoader(false);
      obj['hasChildren'] = data.length > 0
      obj['childItem'] = data;
      if(isTimelineView){
        self.noteData = obj;
        self.viewAttachedFiles = true;
        event = obj;
      }else{
        event.data = obj
      }
    }, (error) => {
        self.mangoAPISrvc.showLoader(false);
        self.mangoAPISrvc.notify('error', 'Error', 'Error while Expanding.');
    });
  }

  showAttachedFiles(obj){
    this.onRowExpandEvent(obj, true);
  }

  editNote(obj) {
    obj['isEditNote'] = true;
    obj['isFromMenu'] = false;
    obj['isProjectEnable'] = true;
    obj['ClientName'] = this.clientName;
    this.sharedSrvc.openNotesDialog(obj);
  }

  addNoteModal() {
    const obj = {};
    obj['isEditNote'] = false;
    obj['ClientID'] = this.clientID;
    obj['ClientName'] = this.clientName;
    obj['ProjectMasterID'] = null;
    obj['isFromMenu'] = false;
    obj['isProjectEnable'] = true;
    this.sharedSrvc.openNotesDialog(obj);
  }

  toggleView(){
    this.isTableView = !this.isTableView;
    if(!this.isTableView){
      this.checkOverflows();
    }
  }

  checkOverflows(){
    setTimeout(()=>{
      const elements = document.querySelectorAll('.html-content');
      elements.forEach((item, index)=>{
        if(this.noteTableDataSource && this.noteTableDataSource[index]){
          this.noteTableDataSource[index]['overflow'] = this.checkOverflow(item);
        }
      })
    }, 100)
  }

  getNotes() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getNotes(self.clientID).subscribe(function (data: any) {

      //timeline view
      self.noteTableDataSource = [...data.sort((a, b) => new Date(b['Ddate']).getTime() - new Date(a['Ddate']).getTime())
        .map((obj, index) => {
          obj['documentcount'] = Number(obj['documentcount']);
          obj['childItem'] = [];
          obj['index'] = index;
          obj['isLight'] = self.wc_hex_is_light(obj['NoteColor']);
          if(obj['Ddate']){
            obj['DdateTimestamp'] = new Date(obj['Ddate']);
          }
          if(obj['Description']){
          obj['DescriptionClean'] = obj['Description'].replace(/<(?:.|\n)*?>/gm, '').replace('&nbsp;', '');
          }
          return obj;
        })];

      if(!self.isTableView){
        self.checkOverflows();
      }

      //table view
      self.tableDataSorce = data.map(function (obj, index) {
        obj['childItem'] = [];
        // let ddate = moment(obj['Ddate']).format('MM/DD/YYYY');
        obj['displayDate'] = moment(obj['Ddate']).format('MM/DD/YYYY')
        return obj;
      });

      if(self.tableDataSorce.length == 0) self.isTableView = true;
      self.mangoAPISrvc.fireClientBadgeView(true);
      self.callFilter();
    }, (error) => {
        self.mangoAPISrvc.showLoader(false);
        self.mangoAPISrvc.notify('error', 'Error', 'Error while Expanding.');
    });
  }

 checkOverflow(el){
    const curOverflow = el.style.overflow;

    if ( !curOverflow || curOverflow === "visible" )
        el.style.overflow = "hidden";

    const isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  }

  wc_hex_is_light(color) {
    if(!color) return true;
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
  }

  callFilter() {
    const self = this;
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      if(!this.searchValue) return;
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue?.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 100);
  }

  deleteNote(rowData, id) {
    const parent = this;
    Swal.fire({
      title: this._translate.instant('confirmation'),
      text: this._translate.instant('Are you sure you want to delete ?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const values = [];
        const uniqueNames = [];
        parent.mangoAPISrvc.showLoader(true);
        if (!rowData.hasChildren && rowData.childItem.length == 0) {
          parent.mangoAPISrvc.getClientsdocuments(id).subscribe(function (data: any) {
            if (data.length > 0) {
              for (let index = 0; index < data.length; index++) {
                const element = data[index];
                values.push(element['dmsParentID']);
                uniqueNames.push(element['UniqueName']);
              }
            }
            parent.mangoAPISrvc.deleteNote(id).subscribe(function (dataRes: any){
              if (values.length > 0) {
                parent.bulkDelete(uniqueNames);
                parent.deleteDMSParentFolder(values.toString());
              } else {
                parent.filterOutCustomerNote(id);
              }
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
            }, (error) => {
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
            });
          }, (error) => {
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
          });
        } else {
          for (let index = 0; index < rowData.childItem.length; index++) {
            const element = rowData.childItem[index];
            values.push(element['dmsParentID']);
            uniqueNames.push(element['UniqueName']);
          }

          parent.mangoAPISrvc.deleteNote(id).subscribe(function (dataRes: any){
            if (values.length > 0) {
              parent.deleteDMSParentFolder(values.toString());
             parent.bulkDelete(uniqueNames);
            } else {
              parent.filterOutCustomerNote(id);
            }
            parent.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
          }, (error) => {
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('error', 'Error', 'Error while deleting');
          });
        }

      }
    })
  }

  filterOutCustomerNote(id){
    this.tableDataSorce =
    this.tableDataSorce.filter(note => note['CustomerNotesID']!=id);
    this.noteTableDataSource = this.noteTableDataSource
      .filter(note => note['CustomerNotesID']!=id)
      .map((note, index) =>{
        note['index'] = index;
        return note;
      });

    this.mangoAPISrvc.fireClientBadgeView(true);
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = environment.AWS_REGION;
    this.awsBucket = new AWS.S3({ params: { Bucket: environment.AWS_BUCKET_NAME } });
    */
    this.awsBucket = (
      AWSBucketMask
    );
  }

  bulkDelete(filesList): void {
    const self = this;
    const params = { Bucket: environment.AWS_BUCKET_NAME, Delete: { Objects: [] } };
    for (let index = 0; index < filesList.length; index++) {
      const element = filesList[index];
      const obj = {};
      obj['Key'] = 'documents/' + element;
      params.Delete.Objects.push(obj);
    }
    self.awsBucket.deleteObjects(
      params,

      function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);           // successful response
      },

      self.mangoAPISrvc
    );

  }

  editRow(obj) {
    this.isShowfileName = true;
    this.fileName = obj.FName;
    this.selectedObj = obj;
  }

  deleteDocRow(obj, parentObj) {
    const params = { Bucket: environment.AWS_BUCKET_NAME, Key: 'documents/' + obj.UniqueName };
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.awsBucket.deleteObject(
      params,

      function (err, data) {
        if (err) {
          return;
        }
       self.mangoAPISrvc.deleteDMSParentFolder(obj.dmsParentID).subscribe((objItem) => {
         const index = parentObj.childItem.indexOf(obj);
          self.getNotes();
          parentObj.childItem.splice(index, 1);
          self.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
          self.mangoAPISrvc.showLoader(false);
        });
      },

      self.mangoAPISrvc
    );
  }

  previewRow(obj) {
    const params = { Bucket: environment.AWS_BUCKET_NAME, Key: 'documents/' + obj['UniqueName'] };
    const self = this;
    self.isFilePreview = true;
    self.isPreviewViewerShow = true;
    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        if (err) {
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.createWebPreviewViewer(docUrl);
      },

      self.mangoAPISrvc
    );
  }

  async createDummyPDFInstance(docUrl) {
    const self = this;
    if (self.dummyPDFInstance) {
      self.dummyPDFInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%');
        });
      });
    } else {
      WebViewer({
        licenseKey: environment.PDF_WebViewer_KEY,
        path: '../../../../wv-resources/lib',
        initialDoc: docUrl
      }, self.dummyViewer.nativeElement).then(instance => {
        self.dummyPDFInstance = instance;
        self.dummyPDFInstance.disableElements(['leftPanel', 'leftPanelButton', 'panToolButton', 'toolsButton', 'signatureToolButton', 'freeHandToolGroupButton', , 'signatureTool', 'freeTextToolButton', 'eraserToolButton', 'shapeToolGroupButton', 'textToolGroupButton', 'miscToolGroupButton', 'stickyToolButton']);
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%');
        });
      });
    }
  }

  createWebPreviewViewer(docUrl) {
    const self = this;
    self.isPreviewViewerShow = true;

    if (self.wvPreviewInstance) {
      self.wvPreviewInstance.loadDocument(docUrl).then((instance) => {
        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on("documentLoaded", function () {
          instance.setZoomLevel("100%"); // or setZoomLevel(1.5)
        });
      });
    } else {
      WebViewer(
        {
          licenseKey: environment.PDF_WebViewer_KEY,
          path: "../../../../wv-resources/lib",
          initialDoc: docUrl,
        },
        self.preiewviewer.nativeElement
      ).then((instance) => {
        self.wvPreviewInstance = instance;
        self.wvPreviewInstance.disableElements([
          "leftPanel",
          "leftPanelButton",
          "panToolButton",
          "toolsButton",
          "signatureToolButton",
          "freeHandToolGroupButton",
          ,
          "signatureTool",
          "freeTextToolButton",
          "eraserToolButton",
          "shapeToolGroupButton",
          "textToolGroupButton",
          "miscToolGroupButton",
          "stickyToolButton",
        ]);
        const docViewer = instance.docViewer;
        docViewer.on("documentLoaded", function () {
          instance.setZoomLevel("100%"); // or setZoomLevel(1.5)
        });
      });
    }
  }

  deleteDMSParentFolder(id) {
    const parent = this;
   parent.mangoAPISrvc.deleteDMSParentFolder(id).subscribe((objItem) => {
      parent.getNotes();
      //parent.getAllCounts(parent.clientID);
    });
  }

  closePreviewDialog() {
    this.isFilePreview = false;
    this.isPreviewViewerShow = false;
  }

  saveFileName() {
    const parent = this;
    parent.selectedObj['FName'] = parent.fileName;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateDMSParentFolder(parent.selectedObj).subscribe((response) => {
      parent.isShowfileName = false;
      parent.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.updateMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  customSort(event: SortEvent) {
    this.mangoUtils.tableColumnsSort(event);
  }
}
