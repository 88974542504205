import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { AppTranslationModule } from '@shared-modules/app.translation.module';
import { TimeExpenseRoutingModule } from './time-expense-routing.module';
import { BatchTimeEntryComponent } from './batch-time-entry/batch-time-entry.component';
import { ExpenseManagerComponent } from './expense-manager/expense-manager.component';
import { TimeEntryListComponent } from './time-entry-list/time-entry-list.component';
import { TimeSheetEntryComponent } from './time-sheet-entry.component';
import { TimeCardComponent } from './time-card/time-card.component';
import { RoundNumberPipe } from './round.pipe';

@NgModule({
  declarations: [RoundNumberPipe, TimeSheetEntryComponent,BatchTimeEntryComponent, ExpenseManagerComponent, TimeEntryListComponent, TimeCardComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    AppTranslationModule,
    TimeExpenseRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TimeExpenseModule { }
