<p-dialog [modal]="true" [style]="{ width: '33vw' }" [(visible)]="showAssignDialog"
  [contentStyle]="{overflow: 'visible'}" [baseZIndex]="1" [maximizable]="false" showEffect="fade" [draggable]="true"
  [closeOnEscape]="false" [closable]="false"
  [resizable]="false" (onHide)="onCloseAssignDialog(false)">
  <p-header>Assign Budget - {{ selectedItems?.length }} Selected</p-header>
  <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
    <div class="p-formgroup-inline p-col-12">
      <div class="p-col-4 p-mt-4">
        <label class="label-text">Year</label>
      </div>
      <div class="p-col-8 p-mt-4">
        <p-dropdown [options]="yearsList" autofocus [(ngModel)]="dialogObj.year" [ngModelOptions]="{ standalone: true }"
          styleClass="width-100p" placeholder="Select Year">
        </p-dropdown>
      </div>
    </div>
    <div class="p-formgroup-inline p-col-12" *ngIf="!isFromProject">
      <div class="p-col-4 p-mt-4">
        <label class="label-text">Revenue Budget</label>
      </div>
      <div class="p-col-8 p-mt-4">
        <input class="width-100p p-text-right" type="text" pInputText [(ngModel)]="dialogObj.revenueAmount"
          (focus)="$event.target.select();"
          (blur)="dialogObj.revenueAmount = mangoUtils.doMoneyFormat(dialogObj.revenueAmount)" pKeyFilter="num"
          [ngModelOptions]="{standalone: true}">
      </div>
    </div>
    <div class="p-formgroup-inline p-col-12" *ngIf="(!isFromProject && !isBudgetingProjectLevel) || (isFromProject)">
      <div class="p-col-4 p-mt-4">
        <label class="label-text">Time Budget</label>
      </div>
      <div class="p-col-8 p-mt-4">
        <input class="width-100p p-text-right" type="text" pInputText [(ngModel)]="dialogObj.timeAmount"
          (focus)="$event.target.select();"
          (blur)="dialogObj.timeAmount = mangoUtils.doMoneyFormat(dialogObj.timeAmount, 'time')" pKeyFilter="num"
          [ngModelOptions]="{standalone: true}">
      </div>
    </div>
    <div class="p-formgroup-inline p-col-12">
      <p-checkbox [(ngModel)]="dialogObj.isOverride" [binary]="true"
          label="Override if the Budget for Engagement and Year Already Exists" [ngModelOptions]="{ standalone: true }"
          class="label-text p-mt-4 p-ml-2">
      </p-checkbox>
      <!-- <i pTooltip="{{ 'pm.requires-final-review' | translate }}"
        tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
        class="fa fa-question-circle ic-blue p-ml-1"></i> -->
    </div>
    <h5 class="label-text p-mt-5 p-mb-0"><strong>Rollover Options</strong></h5>
    <div class="p-d-flex p-jc-start p-ml-3">
        <div class="p-d-flex">
          <p-checkbox [(ngModel)]="rolloverObj.isDefaultBudgetCompanyRollover" [binary]="true"
              label="Use Firm Defaults" [ngModelOptions]="{ standalone: true }"
              class="p-mt-4 label-text">
          </p-checkbox>
          <i pTooltip="{{ 'budgets.firm_defaults' | translate }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap"
              class="fa fa-question-circle ic-blue p-ml-2 ic-size-14 p-pt-1 p-mt-4"></i>
        </div>
        <p-checkbox [(ngModel)]="rolloverObj.isEnableRollover" [binary]="true" [disabled]="rolloverObj.isDefaultBudgetCompanyRollover"
            label="Enable Automatic Rollover to Next Year" [ngModelOptions]="{ standalone: true }"
            class="p-mt-4 p-ml-6 label-text">
        </p-checkbox>
    </div>
    <div class="p-ml-2">
        <div class="p-col-12 p-my-4 p-d-flex p-jc-between">
            <div class="p-col-12 p-pl-0">
                <p-radioButton name="BudgetTemplate" [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover" label="Increase by fixed percentage" [(ngModel)]="rolloverOptions"
                    value="percentage">
                </p-radioButton>
            </div>
        </div>
        <div class="p-col-12 p-pl-4 p-mb-4 p-pr-0 p-d-flex p-jc-between" *ngIf="!isFromProject">
            <div class="p-col-6">
                <p-checkbox [(ngModel)]="rolloverObj.isUpdateRevenue" [binary]="true"
                    label="Revenue Amount" [ngModelOptions]="{ standalone: true }"
                    class="label-text" [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollover">
                </p-checkbox>
            </div>
            <div class="p-col-6 p-pl-0 p-pr-2">
                <span class="p-inputgroup">
                    <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                        [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateRevenue || !rolloverObj.isEnableRollover"
                        [(ngModel)]="rolloverObj.percentage" mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                        [ngModelOptions]="{ standalone: true }" [minFractionDigits]="2"></p-inputNumber>
                    <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-pr-0 p-mb-4 p-d-flex p-pl-4 p-jc-between" *ngIf="(!isFromProject && !isBudgetingProjectLevel) || (isFromProject)">
            <div class="p-col-6">
                <p-checkbox [(ngModel)]="rolloverObj.isUpdateTime" [binary]="true"
                    label="Time Amount" [ngModelOptions]="{ standalone: true }"
                    class="label-text" [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || rolloverOptions !== 'percentage' || !rolloverObj.isEnableRollover">
                </p-checkbox>
            </div>
            <div class="p-col-6 p-pl-0 p-pr-2">
                <span class="p-inputgroup">
                    <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                        [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isUpdateTime || !rolloverObj.isEnableRollover"
                        [(ngModel)]="rolloverObj.timePercentage" mode="decimal" locale="en-US" inputStyleClass="p-text-right"
                        [ngModelOptions]="{ standalone: true }" [minFractionDigits]="2"></p-inputNumber>
                    <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-mb-4">
            <p-radioButton name="BudgetTemplate" [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover" label="Create from Existing Budget" [(ngModel)]="rolloverOptions"
                value="existing">
            </p-radioButton>
        </div>
        <div class="p-col-12 p-mb-4">
            <p-radioButton name="BudgetTemplate" [disabled]="rolloverObj.isDefaultBudgetCompanyRollover || !rolloverObj.isEnableRollover" label="Create based on Actual Amounts" [(ngModel)]="rolloverOptions"
                value="actual">
            </p-radioButton>
        </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-d-flex p-jc-end">
      <button type="button" pButton pRipple label="{{ 'Assign' | translate }}"
        icon="fal fa-check" (click)="onAssignBudgets()" [disabled]="shouldDisable()"></button>
      <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'Cancel' | translate }}"
        (click)="onCloseAssignDialog(false)"></button>
    </div>
  </ng-template>
</p-dialog>