<div class="inbox">
  <div class="layout-content p-px-2">
    <div class="p-fluid p-mx-2">
      <div class="container">
        <div class="menu">
          <button *ngIf="!setupMailBox" (click)="toggleComposer()" class="compose-button">
            <i class="fas fa-pencil-alt"></i> Compose
          </button>
          <ul class="menu-list">
            <li
              *ngIf="!setupMailBox"
              class="menu-item"
              [ngClass]="{ selected: selectedItem === 'inbox' }"
              (click)="selectedItem = 'inbox'"
            >
              <i class="fas fa-inbox"></i> Inbox
            </li>
            <!-- <li
              class="menu-item"
              [ngClass]="{ selected: selectedItem === 'starred' }"
              (click)="selectedItem = 'starred'"
            >
              <i class="fas fa-star"></i> Starred
            </li>
            <li
              class="menu-item"
              [ngClass]="{ selected: selectedItem === 'sent' }"
              (click)="selectedItem = 'sent'"
            >
              <i class="fas fa-paper-plane"></i> Sent
            </li> -->
          </ul>
        </div>
        <div class="content">
          <div *ngIf="!setupMailBox" class="p-field p-col-8">
            <div class="p-grid">
              <div class="p-col-3">
                <label for="EmailAddr" class="label-text">Select Email Account</label>
                <p-multiSelect
                  name="EmailAddr"
                  placeholder="Select Email Account"
                  (onChange)="onEmailSelected($event)"
                  [options]="emailItems"
                  [(ngModel)]="selectedEmail"
                ></p-multiSelect>
              </div>

              <div class="p-col-3">
                <label for="Engagements" class="label-text">Engagements Name</label>
                <p-dropdown
                  [options]="AllEngagements"
                  [(ngModel)]="selectedEngagementsItem"
                  optionLabel="name"
                  [filter]="true"
                  filterBy="name"
                  [showClear]="true"
                  placeholder="Choose"
                >
                  <ng-template pTemplate="selectedItem" let-selectedOption>
                    <div class="flex align-items-center gap-2">
                      <div>{{ selectedOption.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-engagement pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ engagement.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>

              <div class="p-col-3">
                <label for="Billing_Partner" class="label-text">{{
                  "Billing_Partner" | translate
                }}</label>
                <p-dropdown
                  [options]="Allbillingpartners"
                  [(ngModel)]="selectedbillingpartnerItem"
                  optionLabel="name"
                  [filter]="true"
                  filterBy="name"
                  [showClear]="true"
                  placeholder="Choose"
                >
                  <ng-template pTemplate="selectedItem" let-selectedOption>
                    <div class="flex align-items-center gap-2">
                      <div>{{ selectedOption.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-billingPartner pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ billingPartner.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>

              <div style="display: flex; align-items: center; margin-top: 20px">
                <div style="margin-left: 15px !important">
                  <p-button
                    (click)="getEmailsByFilter()"
                    label="Get Emails"
                    [rounded]="true"
                  ></p-button>
                </div>
              </div>

              <div style="display: flex; align-items: center; margin-top: 20px; margin-left: 15px">
                <p-button
                  [disabled]="!hasFilter"
                  (click)="removeFilters()"
                  [rounded]="true"
                  icon="pi pi-times-circle"
                  iconPos="left"
                ></p-button>
              </div>
            </div>
          </div>

          <div *ngIf="!isLoading">
            <span class="p-ml-2">
              <button
                pButton
                pRipple
                icon="fas fa-arrow-left"
                class="p-button-sm p-mb-2"
                *ngIf="showBackButton"
                (click)="goToMailBox()"
              ></button>
            </span>
            <div
              *ngIf="setupMailBox"
              class="p-d-flex p-jc-center p-ai-center"
              style="height: 100vh"
            >
              <p-card [style]="cardStyle">
                <p-header>Attention</p-header>
                Please enable the integration first.
              </p-card>
            </div>
            <nylas-mailbox
              ngi
              *ngIf="showMailBox && !setupMailBox"
              (manifestLoaded)="onManifestLoaded($event)"
              (onError)="onError($event)"
              (threadClicked)="onThreadClicked($event)"
              (onChangeSelectedReadStatus)="onChangeSelectedReadStatus($event)"
              (onDeleteSelected)="onDeleteSelected($event)"
              (onStarSelected)="onStarSelected($event)"
              (refreshClicked)="onRefreshClicked($event)"
              (onSelectOneClicked)="onSelectOneClicked($event)"
              (onSelectAllClicked)="onSelectAllClicked($event)"
              (returnToMailbox)="onReturnToMailbox($event)"
              [all_threads]="staticThreads"
            >
            </nylas-mailbox>

            <div
              *ngIf="noResultFilters"
              class="p-d-flex p-jc-center p-ai-center"
              style="height: 50vh"
            >
              <p-card [style]="cardStyle">
                <p>
                  We were unable to locate any results based on the filters you've applied. Please
                  ensure that your email addresses are properly configured in Nylas for successful
                  retrieval.
                </p>
              </p-card>
            </div>
          </div>

          <div class="single-mail-box">
            <nylas-email
              [show_thread_actions]="true"
              [show_close_button]="true"
              [message]="message"
            ></nylas-email>
          </div>

          <div class="email-windows-container">
            <div *ngFor="let emailWindow of emailWindows; let i = index"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
