import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuService {

    private menuSource = new Subject<string>();
    private resetSource = new Subject<void>();
    private horizontalSource = new Subject<void>();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();
    horizontalSource$ = this.horizontalSource.asObservable();

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource.next(undefined);
    }

    onMenuHorizontal() {
        this.horizontalSource.next(undefined);
    }
}
