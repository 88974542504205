import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MangoApiService } from "@app/_services";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-email-send-box",
  styleUrls: ["email-send-box.style.css"],
  templateUrl: "./email-send-box.component.html",
})
export class EmailSendBoxComponent implements OnInit {
  recipient: string = "";
  subject: string = "";
  message: string = "";

  offsetX = 0;

  text: any;
  showEmailEditor = false;

  @Input() isExpanded: boolean;
  @Input() isMinimized: boolean;
  @Input() emailForm: UntypedFormGroup;
  @Input() dynamicRigth: any;
  @Input() dynamicRigthBoxButtom: any;

  @Output() sendEmail: EventEmitter<void> = new EventEmitter<void>();
  @Output() minimizeWindow: EventEmitter<void> = new EventEmitter<void>();
  @Output() restoreWindow: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeWindow: EventEmitter<void> = new EventEmitter<void>();
  display: boolean = true;

  @Input() selectedEmail: any;
  @Input() currentAccountId: any;


  constructor(private fb: UntypedFormBuilder, private mangoAPISrvc: MangoApiService) {}

  ngOnInit() {
    this.emailForm = this.fb.group({
      to: ["", [Validators.required, Validators.email]],
      cc: [[], []],
      bcc: [[], []],
      subject: [],
      body: ["", Validators.required],
    });
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  searchCC(event) {
    const filtered: any[] = [];
    for (let i = 0; i < this.ccEmails.length; i++) {
      const email = this.ccEmails[i];
      if (email.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(email);
      }
    }
    if (this.isValidEmail(event.query) && !this.ccEmails.includes(event.query)) {
      filtered.push({ email: event.query });
    }
    this.ccEmails = filtered;
  }

  minimize2() {
    this.display = !this.display;
  }

  searchBCC(event) {
    const filtered: any[] = [];
    for (let i = 0; i < this.bccEmails.length; i++) {
      const email = this.bccEmails[i];
      if (email.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(email);
      }
    }
    if (this.isValidEmail(event.query) && !this.bccEmails.includes(event.query)) {
      filtered.push({ email: event.query });
    }
    this.bccEmails = filtered;
  }

  ccEmails: string[] = [];

  bccEmails: string[] = [];

  selectedEmails: any[] = [];

  toggleEmailEditor() {
    this.removeWindow.emit();
  }

  send() {
    this.mangoAPISrvc.showLoader(true);
    if (this.emailForm.valid) {
      const to = this.emailForm.get("to").value;
      const subject = this.emailForm.get("subject").value;
      const body = this.emailForm.get("body").value;
      const emails = [{ email: to }];
      const cc = this.ccEmails;
      const bcc = this.bccEmails;
      const nylas_id = this.currentAccountId;
      this.mangoAPISrvc
        .sendNylasEmail({ subject, body, emails, cc, bcc, nylas_id })
        .pipe(
          finalize(() => {
            this.mangoAPISrvc.showLoader(false);
          })
        )
        .subscribe({
          next: (res) => {
            this.mangoAPISrvc.notify("success", "Nylas", "Send email successfuly");
            this.removeWindow.emit();
          },
          error: (e) => {
            this.mangoAPISrvc.notify("error", "Nylas", `${e}`);
          },
        });
    } else {
      this.mangoAPISrvc.notify("error", "Error!", "Invalid form");
      this.emailForm.markAllAsTouched();
    }
  }

  minimize() {
    this.minimizeWindow.emit();
  }

  restore() {
    this.restoreWindow.emit();
  }

  remove() {
    this.removeWindow.emit();
  }
}
