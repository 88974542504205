import { Component, Input, OnInit } from '@angular/core';
import { ACTION_STATUS, CLIENT_TYPE } from '@app/modules/document-management/dms-ui/constants';
import { IObjectIShare } from '@app/modules/document-management/dms-ui/interface';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CheckBoxChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import Swal from 'sweetalert2';
import { SettingsComponent } from '../settings.component';

@Component({
  selector: 'app-mangoshare-integration',
  templateUrl: './mangoshare-integration.component.html'
})
export class MangoshareIntegrationComponent implements OnInit {
  @Input() isFromDMS: boolean;
  clientsList: any = [];
  clientListMultiSelect: any = [];
  clientGroupCategory: any = [];
  iShareObj: IObjectIShare = {
    isIntegrateIS: false,
    vendorAPIKeyIShare: '',
    isIShareTokenVerified: true,
    firmID: '',
    iShareClients: [],
    mangoClients: [],
    isCreateFirm: false,
    isAddExistingFirm: false,
    isDMSIShare: false
  }
  public filteredClients: any = [];

  selectedIShareItems = []
  iShareIntegrationTable = [];

  constructor(private sharedDialogSvc: SharedComponentsService, private mangoAPISrvc: MangoApiService, private translate: TranslateService, private auth: AuthGuard, private encrDecSrvc: EncrDecrService, protected mangoUtils: mangoUtils) { }

  ngOnInit(): void {
    this.clientGroupCategory = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    this.loadIShareSettings()
  }

  onChangeIsIntegrateIS(event: CheckBoxChangeEventArgs) {
    this.iShareObj.isCreateFirm = false
    this.iShareObj.isAddExistingFirm = false
    this.iShareObj.isIntegrateIS = event.checked;
    if(this.iShareObj.isIntegrateIS && !this.iShareObj.isIShareTokenVerified) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant("Do you want to create a new firm on MangoShare or you have an existing firm on MangoShare already?"),
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: this.translate.instant('Create a new firm on MangoShare'),
        cancelButtonText: this.translate.instant('I have existing firm on MangoShare')
      }).then((result) => {
        if (result.isConfirmed) {
          this.iShareObj.isCreateFirm = true
          this.mangoAPISrvc.showLoader(true)
          this.mangoAPISrvc.createIShareFirmAndAuthenticate({}).subscribe((result: any) => {
            this.iShareObj.firmID = result.data?.firm?._id
            this.iShareObj.vendorAPIKeyIShare = result?.data?.firm?.apiKey
            this.iShareObj.isIShareTokenVerified = true
            this.mangoAPISrvc.getIShareUserApiToken().subscribe(
              (resultUser: any) => {
                this.mangoAPISrvc.showLoader(false);
                const url = `${environment.BASE_IMAGINETIME}/firm/${this.iShareObj.firmID}/workspaces?userapitoken=${resultUser.data?.userapitoken}`;
                window.open(url, "_blank").focus();
              },
              (error) => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify("error", "Error", AppConstants.fetchErrorMsg);
              }
            );
          }, error => {
            console.error(error)
            this.mangoAPISrvc.showLoader(false)
          })
          
        } else {
          this.iShareObj.isAddExistingFirm = true
        }
      })
    }
    this.mangoAPISrvc.activateOrDeactivateIntegration({
      isIntegrateIS: this.iShareObj.isIntegrateIS
    }).subscribe((result) => {
    })
  }

  verifyISToken(obj: IObjectIShare) {
    const body = {
      vendorAPIKey: obj.vendorAPIKeyIShare,
      firmID: obj.firmID
    }
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.verifyISToken(body).subscribe((result) => {
      this.mangoAPISrvc.showLoader(false)
      this.iShareObj.isIShareTokenVerified = true;
      this.loadIShareSettings()
    }, err => {
      this.iShareObj.isIShareTokenVerified = false;
      this.mangoAPISrvc.notify("error", "Error!", "Error occurred while verifying MangoShare token.");
      this.mangoAPISrvc.showLoader(false)
    })
  }

  removeISToken() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.removeISToken().subscribe((result) => {
      this.mangoAPISrvc.showLoader(false)
      this.iShareObj.isIShareTokenVerified = false;
      this.iShareObj.firmID = ''
      this.iShareObj.vendorAPIKeyIShare= ''
    }, err => {
      this.mangoAPISrvc.notify("error", "Error!", "Error occurred while removing MangoShare token.");
      this.mangoAPISrvc.showLoader(false)
    })
  }

  loadIShareSettings() {
    this.selectedIShareItems = []
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getClientsForDMS().subscribe((results) => {
      this.getAllClientsDataSource(results)
      this.mangoAPISrvc.loadIShareSettings().subscribe(
        (result: any) => {
          const iShareClientIDsMatched = []
          this.iShareObj.isDMSIShare = result.data.isDMSIShare
          this.iShareObj.isIShareTokenVerified = !!result.data.VendorAPIKeyIShare;
          this.iShareObj.firmID = result.data.CompanyIDImagineShare;
          this.iShareObj.isIntegrateIS = result.data.isIntegrateIS;
          this.iShareObj.vendorAPIKeyIShare = result.data.VendorAPIKeyIShare;
          this.iShareObj.mangoClients = this.clientsList.map((client) => {
            if(client.IShareClientID) iShareClientIDsMatched.push(client.IShareClientID)
            return {
              ...client,
              label: client.ClientName,
              value: client.ClientID
            }
          });
          this.iShareObj.mangoClients?.unshift({ label: 'Select Mango client', value: null })

          if(this.iShareObj.isIntegrateIS && this.iShareObj.vendorAPIKeyIShare) {
            this.mangoAPISrvc.getIShareClients().subscribe((resultClients: any) => {
              if(resultClients?.data?.clients?.length > 0) {
                this.iShareObj.iShareClients = resultClients.data.clients.map((client) => {
                  return {
                    ...client,
                    label: client.name,
                    value: client._id
                  }
                }).filter((client) => client.externalId === '' && !client.mangoClientID).filter((client) => !iShareClientIDsMatched.includes(client._id))
                this.mangoUtils.sortByFiled(this.iShareObj.iShareClients, "name");
                this.iShareObj.iShareClients?.unshift({ label: 'Select IShare client', value: null })
              }
              this.processIShareIntegrationTable();
              this.mangoAPISrvc.showLoader(false);
            }, (err) => {
              this.mangoAPISrvc.notify(
                "error",
                "Error!",
                "Error occurred while fetching MangoShare clients."
              );
              this.mangoAPISrvc.showLoader(false);
            })
          } else this.mangoAPISrvc.showLoader(false);
        },
        (err) => {
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            "Error occurred while removing MangoShare token."
          );
          this.mangoAPISrvc.showLoader(false);
        }
      );
    })
  }

  handleSelectMangoClient(event, rowData) {
    const prevSelectedMangoClient = rowData["SelectedMangoClient"];
    const mangoClient = this.iShareObj.mangoClients.find((client) => client.ClientID === event.value);
    rowData["SelectedMangoClient"] = rowData["MangoClient"] = {
      id: mangoClient.ClientID,
      name: mangoClient.ClientName,
      ...mangoClient
    };

    if(rowData["MangoClient"]) {
      rowData["matchStatus"] = ACTION_STATUS.MATCHED
    } else {
      rowData["matchStatus"] = ACTION_STATUS.CREATE_IN_MANGO
    }

    if (prevSelectedMangoClient?.id) {
      this.iShareObj.mangoClients = [
        ...this.filterOutMangoClient(event.value),
        prevSelectedMangoClient,
      ];
      this.mangoUtils.sortByFiled(this.iShareObj.mangoClients, "ClientName");
    } else this.iShareObj.mangoClients = this.filterOutMangoClient(event.value);

    this.iShareIntegrationTable = this.filterOutClientFromTable(event.value, CLIENT_TYPE.MANGO)
  }

  handleSelectIShareClient(event, rowData) {
    const prevSelectedIShareClient = rowData["SelectedIShareClient"];
    const iShareClient = this.iShareObj.iShareClients.find((client) => client._id === event.value);
    rowData["SelectedIShareClient"] = rowData["IShareClient"] = {
      id: iShareClient._id,
      ...iShareClient
    };

    if(rowData["IShareClient"]) {
      rowData["matchStatus"] = ACTION_STATUS.MATCHED
    } else {
      rowData["matchStatus"] = ACTION_STATUS.CREATE_IN_ISHARE
    }

    if (prevSelectedIShareClient?.id) {
      this.iShareObj.iShareClients = [
        ...this.filterOutIShareClient(event.value),
        prevSelectedIShareClient,
      ];
      this.mangoUtils.sortByFiled(this.iShareObj.iShareClients, "name");
    } else this.iShareObj.iShareClients = this.filterOutIShareClient(event.value);

    this.iShareIntegrationTable = this.filterOutClientFromTable(event.value, CLIENT_TYPE.IMAGINESHARE)
  }

  filterOutIShareClient(id) {
    return this.iShareObj.iShareClients.filter(
      (iShareClient: any) => iShareClient._id !== id
    );
  }

  filterOutMangoClient(id) {
    return this.iShareObj.mangoClients.filter(
      (mangoClient: any) => mangoClient.ClientID !== id
    );
  }

  filterOutClientFromTable(id, clientType: CLIENT_TYPE) {
    if(clientType == CLIENT_TYPE.IMAGINESHARE) {
      return this.iShareIntegrationTable.filter((item) => item.id !== `IShare-${id}`)
    } else {
      return this.iShareIntegrationTable.filter((item) => item.id !== `Mango-${id}`)
    }
  }

  processIShareIntegrationTable() {
    this.iShareIntegrationTable = [];
    if (this.clientsList?.length > 0) {
      for (let i = 0; i < this.clientsList.length; i++) {
        const client = this.clientsList[i];
        if (!client.IShareClientID && client.ClientID) {
          const iShareClientMatched: any = this.iShareObj.iShareClients.find(
            (iShareClient: any) =>
              iShareClient.name?.toLowerCase() == client.ClientName?.toLowerCase()
          );

          this.iShareIntegrationTable.push({
            id: `Mango-${client.ClientID}`,
            MangoClient: {
              id: client.ClientID,
              name: client.ClientName,
            },
            isMangoClient: true,
            isIShareClient: false,
            IShareClient: {
              id: iShareClientMatched?._id ?? null,
              name: iShareClientMatched?.name ?? null,
            },
            matchStatus: iShareClientMatched?._id
              ? ACTION_STATUS.MATCHED
              : ACTION_STATUS.CREATE_IN_ISHARE,
          });

          if (iShareClientMatched?._id) {
            this.iShareObj.iShareClients = this.iShareObj.iShareClients.filter(
              (iShareClient: any) => iShareClient._id !== iShareClientMatched._id
            );
            this.iShareObj.mangoClients = this.iShareObj.mangoClients.filter(
              (mangoClient: any) => mangoClient.ClientID !== client.ClientID
            );
          }
        }
      }

      for (let i = 0; i < this.iShareObj.iShareClients.length; i++) {
        const iShareClient = this.iShareObj.iShareClients[i];
        if (!iShareClient.mangoClientID && iShareClient._id) {
          this.iShareIntegrationTable.push({
            id: `IShare-${iShareClient._id}`,
            MangoClient: {
              id: null,
              name: null,
            },
            isMangoClient: false,
            isIShareClient: true,
            IShareClient: {
              id: iShareClient._id,
              name: iShareClient.name,
            },
            matchStatus: ACTION_STATUS.CREATE_IN_MANGO,
          });
        }
      }
    }
  }

  syncRecords() {
    this.mangoAPISrvc.notify(
      "warn",
      "In progress...",
      "Your request is being processed in the background. It may take few minutes to complete this process."
    );
    this.mangoAPISrvc
      .syncMappedClients({
        clientsToSync: this.selectedIShareItems,
      })
      .subscribe(
        (result: any) => {
          this.selectedIShareItems = [];

          if (result?.data?.successfulArray?.length > 0) {
            result.data.successfulArray.map((item) => {
              if (item.isMangoClient) {
                this.iShareIntegrationTable = this.filterOutClientFromTable(
                  item.MangoClient.id,
                  CLIENT_TYPE.MANGO
                );
              } else {
                this.iShareIntegrationTable = this.filterOutClientFromTable(
                  item.IShareClient.id,
                  CLIENT_TYPE.IMAGINESHARE
                );
              }
              this.iShareObj.iShareClients = this.filterOutIShareClient(item.IShareClient?.id);
              this.iShareObj.mangoClients = this.filterOutMangoClient(item.MangoClient?.id);
            });
          }
        },
        (err) => {}
      );
  }

  toggleDMSIShare(isDMSIshare) {
    this.encrDecSrvc.addObject(AppConstants.isDMSIshare, isDMSIshare);
    this.sharedDialogSvc.toggleDMSIshare(isDMSIshare)
  }

  enableImagineShareDMS() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('dms.enable_ishare_dms_question'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: this.translate.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true)
        this.iShareObj.isDMSIShare = true
        this.mangoAPISrvc.toggleDMSIShare({
          isDMSIShare: this.iShareObj.isDMSIShare
        }).subscribe((result) => {
          this.toggleDMSIShare(this.iShareObj.isDMSIShare)
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), "Successfully activated MangoShare");          
        }, error => {
          console.error(error)
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify("error", "Error!", "Failed to activate MangoShare");          
        })
      }
    })
  }

  disableImagineShareDMS() {
    this.mangoAPISrvc.showLoader(true)
    this.iShareObj.isDMSIShare = false
    this.mangoAPISrvc.toggleDMSIShare({
      isDMSIShare: this.iShareObj.isDMSIShare
    }).subscribe((result) => {
      this.toggleDMSIShare(this.iShareObj.isDMSIShare)
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify('success', this.translate.instant('Success_notify'), "Successfully deactivated MangoShare DMS");          
    }, error => {
      console.error(error)
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify("error", "Error!", "Failed to deactivate MangoShare DMS");          
    })
  }

  getAllClientsDataSource(clients) {
    const parent = this;
    clients.map(function (obj) {
      obj['Inactive'] = obj['Inactive'] ? obj['Inactive'] : false;
      obj['ContactRecord'] = obj['ContactRecord'] ? obj['ContactRecord'] : false;
      obj['GroupDescriptionIDArrayDesc'] = "";
      obj['GroupDescriptionIDArray'] = obj['GroupDescriptionIDArray'] ? obj['GroupDescriptionIDArray'] : [];
      if (obj['GroupDescriptionIDArray'].length > 0) {
        const tempValues = obj['GroupDescriptionIDArray'].toString().split(',');
        for (var i = 0; i < tempValues.length; ++i) {
          if (tempValues[i]) {
            const matchedArr = parent.clientGroupCategory.filter((item) => item['CustomerGroupCategoryID'] == tempValues[i]);
            if (matchedArr.length > 0) {
              if (i == 0) {
                obj['GroupDescriptionIDArrayDesc'] += parent.clientGroupCategory.filter((item) => item['CustomerGroupCategoryID'] == tempValues[i])[0]['GroupDescription'];
              } else {
                obj['GroupDescriptionIDArrayDesc'] = obj['GroupDescriptionIDArrayDesc'] + " , " + parent.clientGroupCategory.filter((item) => item['CustomerGroupCategoryID'] == tempValues[i])[0]['GroupDescription'];
              }
            }
          }
        }
      }
      if (obj['EmailsWithAccessToPortal']?.length > 0)
        parent.clientListMultiSelect.push({
          label: `${obj['ClientName']} (${obj['EmailsWithAccessToPortal']?.length})`,
          value: obj['ClientID'],
        })
      return obj;
    });
    parent.clientsList = parent.filteredClients = clients
  }
}
