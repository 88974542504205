import { Component, OnInit } from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { MangoApiService } from '@app/_services';
@Component({
  selector: 'app-company-setup',
  templateUrl: './company-setup.component.html'
})
export class CompanySetupComponent implements OnInit {
  items: MenuItem[];
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  selectedUrl: any;

  constructor(
    private router: Router,private mangoAPISrvc: MangoApiService
  ) {  
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    this.setMenuIndex();
    this.loadMenu();
  }

  loadMenu() {
    this.items = [
      {
        label: "Company Settings",
        routerLink: [`/${AppConstants.companySettings}/${AppConstants.setupSettings}`],
      },
      {
        label: "Company Locations",
        routerLink: [`/${AppConstants.companySettings}/${AppConstants.setupLocations}`],
      },
    ];
    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  private setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = url;
    switch (url) {
      case `/${AppConstants.companySettings}/${AppConstants.setupLocations}`: {
        this.selectedMenuIndex = 1;
        break;
      }
      default: {
        this.selectedMenuIndex = 0;
        break;
      }
    }
  }
}


