<div class="client-payments">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="Print Label" icon="fal fa-print"
                class="p-mr-1 p-button-sm" (click)="onPrintLabels()"
                [disabled]="selectedRows.length == 0 || !this.formPrinter"></button>
            <!-- <button pButton pRipple label="Print Label" icon="fal fa-print"
                class="p-mr-1 p-button-sm" (click)="onPrintLabels()"
                [disabled]="selectedRows.length == 0"></button> -->
        </span>
        <div class="p-grid">
            <div class="p-col-8 p-pt-0">
                <div class="card p-my-2 p-ml-3 p-pt-0" style="height: 100%;">
                    <p-table #dt [value]="filteredDataSource" [rowHover]="true" [responsive]="true"
                        [dataKey]="selectedDisplay === 'contacts' ? 'CustomerContactID' : 'ClientID'" selectionMode="single"
                        [filterDelay]="0" [rowHover]="true"
                        styleClass="p-datatable-sm p-datatable-responsive-demo"
                        sortField="ClientName" [sortOrder]="1" [(selection)]="selectedRows"
                        [scrollable]="true" [scrollHeight]="'calc(60vh - 100px)'" (onHeaderCheckboxToggle)="onRowSelect(null, null, $event)"
                        [globalFilterFields]="['ClientName', 'ContactName', 'GroupDescriptionIDArrayDesc', 'CustomerTypeDescription']" [resizableColumns]="true"
                        (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Global Search" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" pTooltip="Clear" tooltipPosition="top"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                <span class="pull-right ic-blue">
                                    <p-dropdown [options]="displayList" [(ngModel)]="selectedDisplay"
                                        (onChange)="onChangeSelectedDisplay()" [style]="{'width':'350px'}" scrollHeight="500px"
                                        placeholder="Select Data to Display" required>
                                    </p-dropdown>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-5p p-text-center">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="width-20p p-text-left" *ngIf="selectedDisplay === 'contacts'" pSortableColumn="ContactName">
                                    Contact
                                    <p-sortIcon field="ContactName"></p-sortIcon>
                                </th>
                                <th class="{{selectedDisplay === 'contacts' ? 'width-30p' : 'width-40p'}} p-text-left" pSortableColumn="ClientName">
                                    Client Name
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th class="width-20p p-text-left">
                                    Group
                                </th>
                                <th class="width-20p p-text-left">
                                    Client Type
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-5p p-text-center">
                                    <p-tableCheckbox (click)="onRowSelect(rowData['ClientID'], 'parent')" [value]="rowData"></p-tableCheckbox>
                                </td>
                                <td class="width-20p p-text-left p-text-nowrap p-text-truncate"
                                    *ngIf="selectedDisplay === 'contacts'"
                                    pTooltip="{{ rowData.ContactName }}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{ rowData.ContactName }}
                                </td>
                                <td class="{{selectedDisplay === 'contacts' ? 'width-30p' : 'width-40p'}} p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{ rowData.ClientName }}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{ rowData.ClientName }}
                                </td>
                                <td class="width-20p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{ rowData.GroupDescriptionIDArrayDesc }}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{ rowData.GroupDescriptionIDArrayDesc }}
                                </td>
                                <td class="width-20p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{ rowData.CustomerTypeDescription }}" showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{ rowData.CustomerTypeDescription }}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colspan]="selectedDisplay === 'contacts' ? 5 : 4">
                                    <p-message severity="info" text="Data not found." styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-4 p-pt-0">
                <div class="card p-my-2 p-mr-3" style="height: 100%;">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="formPrinter">
                                <h4 class="label-text">Available Printer</h4>
                            </label>
                            <input id="formPrinter" type="text" pInputText [(ngModel)]="formPrinter" readonly />
                        </div>
                        <div class="p-field">
                            <label for="formPrinterLabel">
                                <h4 class="label-text"># Of Labels to Print</h4>
                            </label>
                            <input id="formPrinterLabel" type="number" min="1" max="50" pInputText
                                [(ngModel)]="formPrinterLabel" />
                        </div>
                        <div class="p-field p-mb-2">
                            <label for="formattedStr">
                                <h4 class="label-text">Address Information 
                                    <i pTooltip="{{'label.address_information_tooltip' | translate}}"
                                    tooltipStyleClass="tooltip-width-450" *ngIf="selectedRows?.length > 1"
                                    class="fa fa-question-circle question-tooltip-icon ic-red" tooltipPosition="top"></i>
                                </h4>
                            </label>
                            <textarea [rows]="8" [cols]="30" [disabled]="selectedRows?.length > 1" pInputTextarea autoResize="autoResize"
                                [(ngModel)]="formattedStr"></textarea>
                        </div>
                        <!-- <div class="p-d-flex p-jc-end p-field p-mb-2">
                            <p-button icon="fa fa-print" label="Print Label" class="p-button-help width-25p"
                                [disabled]="formPrinter == ''" (click)="PrintLabel()">
                            </p-button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Printing Form-->
    </div>
</div>