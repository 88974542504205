import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { BreadcrumbService, MangoApiService, EncrDecrService } from "@app/_services";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConstants } from "@app/_helpers/api-constants";
import { environment } from "@environments/environment";
import { forkJoin } from 'rxjs'
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finished',
  templateUrl: './finished.component.html',
})
export class FinishedComponent implements OnInit {
  observableBatch: any = [];
  companyId
  userId
  companyData: any = null;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    private http: HttpClient,
    private encrDecSrvc: EncrDecrService,
    private _translate: TranslateService
  ) {
    this.breadcrumbService.setItems([
      { label: "Great! Your settings have been saved.", icon: "ic-red" },
    ]);

    this.companyId = parseInt(this.encrDecSrvc.getObject(AppConstants.companyID));
    this.userId = parseInt(this.encrDecSrvc.getObject(AppConstants.resourceID));
    this.getCompanyDetails();
  }

  ngOnInit(): void {
  }

  getCompanyDetails() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe(response => {
      this.companyData = response[0];
      this.companyData['phone'] = this.companyData['Telephone'];
      this.companyData['email'] = this.companyData['EmailCompany'];
      this.companyData['state'] = this.companyData['State'];
      this.companyData['country'] = this.companyData['Country'];
      this.companyData['companyName'] = this.companyData['CompanyName'];
      this.mangoAPISrvc.showLoader(false);
    });
  }

  updateSetupWizardStatus() {
    const parent = this;
    const obj = { setupStep: 5 };
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.updateSetupStatus(obj).subscribe((data) => {
      parent.encrDecSrvc.addObject(AppConstants.setupStep, "5")
      parent.createDefaultData();
      //this.mangoAPISrvc.showLoader(false)
    }, (data) => {
      this.mangoAPISrvc.showLoader(false)
    });
  }

  previousStep() {
    this.router.navigate(['/setup-wizard/user-settings']);
  }

  nextStep() {
    const setupIndex = this.encrDecSrvc.getObject(AppConstants.setupStep);
    if (setupIndex != 5) {
      this.updateSetupWizardStatus();
    }
  }

  createDefaultData() {
    this.mangoAPISrvc.showLoader(true);
    const defaultClient = { "Inactive": false, "Billable": true, "DateInactive": null, "IncorporationDate": null, "ClientDOB": null, "DateCreated": "2018-12-17T19:41:58.884Z", "ClientName": "Test Client", "BusStreet1": "123 Test St.", "BusCity": "Anywhere", "BusState": "MD", "BusZip": "21701", "BusCountry": "USA", "Office": "(555) 444-7777", "Fax": "(444) 555-8888", "Mobile": "(333) 322-2255", "ContactPerson": "John Doe", "Email": "John@mangobilling.com", "FinalizeAction": "Print", "TermsID": null };
    const defaultProject = { "ClientID": "", "AssignedTo": null, "Status": null, "EngagementName": "", "StartDate": "", "DueDate": "null", "ProjectStreet": "null", "ProjectStreet2": "null", "ProjectCity": "null", "ProjectState": "null", "ProjectZip": "null", "ProjectCountry": "null", "CustomerContactID": null, "ContactEmail": "John@mangobilling.com", "Inactive": false, "EngagementTypeID": -1, "EngagementNumber": null, "EngagementDescription": "", "EngagementInvoiceDescription": null, "FlatFeeAmount": 0, "BillingMethod": "Hourly", "BillTo": "Client", "TermsID": 0, "LaborTaxRate1": 0, "LaborTaxRate2": 0, "ExpenseRate1": 0, "ExpenseRate2": 0, "DefaultTaxRates": true }

    const notesObj = { "NoteCategory": "General", "Inactive": false, "CompanyID": this.companyId };
    const contactOneObj = { "ContactType": "Employee", "Inactive": false, "CompanyID": this.companyId };
    const contactTwoObj = { "ContactType": "Lawyer", "Inactive": false, "CompanyID": this.companyId };
    const contactThreeObj = { "ContactType": "IT Consultant", "Inactive": false, "CompanyID": this.companyId };
    const contactFourObj = { "ContactType": "Bank", "Inactive": false, "CompanyID": this.companyId };
    const customerTypeOneObj = { "CustomerTypeDescription": "1040", "Inactive": false, "CompanyID": this.companyId };
    const customerTypeTwoObj = { "CustomerTypeDescription": "1120S", "Inactive": false, "CompanyID": this.companyId };
    const customerTypeThreeObj = { "CustomerTypeDescription": "1120", "Inactive": false, "CompanyID": this.companyId };
    const customerTypeFourObj = { "CustomerTypeDescription": "1065", "Inactive": false, "CompanyID": this.companyId };
    const staffTitlesOneObj = { "StaffTitleDescription": "Staff", "Inactive": false, "CompanyID": this.companyId };
    const staffTitlesTwoObj = { "StaffTitleDescription": "Manager", "Inactive": false, "CompanyID": this.companyId };
    const staffTitlesThreeObj = { "StaffTitleDescription": "Partner", "Inactive": false, "CompanyID": this.companyId };
    const termsOneObj = { "TermsDescription": "Upon Receipt", "Inactive": false, "GraceDays": 30, "CompanyID": this.companyId };
    const termsTwoObj = { "TermsDescription": "30 Days", "Inactive": false, "GraceDays": 30, "CompanyID": this.companyId };
    const termsThreeObj = { "TermsDescription": "45 Days", "Inactive": false, "GraceDays": 30, "CompanyID": this.companyId };
    const engagementOneObj = { "Description": "TAX SERVICES", "InvoiceDescription": "TAX SERVICES", "Inactive": false, "CompanyID": this.companyId };
    const engagementTwoObj = { "Description": "ACCOUNTING SERVICES", "InvoiceDescription": "ACCOUNTING SERVICES", "Inactive": false, "CompanyID": this.companyId };
    const engagementThreeObj = { "Description": "PAYROLL SERVICES", "InvoiceDescription": "PAYROLL SERVICES", "Inactive": false, "CompanyID": this.companyId };
    const engagementFourObj = { "Description": "CONSULTING", "InvoiceDescription": "CONSULTING", "Inactive": false, "CompanyID": this.companyId };
    const activitiesOneObj = { "ServiceCode": "MEETINGS", "Description": "Meetings", "Tax1": false, "Tax2": false, "Billable": true, "BillRate": "0.00", "Inactive": false, "CompanyID": this.companyId };
    const activitiesTwoObj = { "ServiceCode": "ASSEMBLY", "Description": "Assembly", "Tax1": false, "Tax2": false, "Billable": true, "BillRate": "0.00", "Inactive": false, "CompanyID": this.companyId };
    const activitiesThreeObj = { "ServiceCode": "PHONE CALL", "Description": "Phone Call", "Tax1": false, "Tax2": false, "Billable": true, "BillRate": "0.00", "Inactive": false, "CompanyID": this.companyId };
    const activitiesFourObj = { "ServiceCode": "DOCUMENT PREPARATION", "Description": "Document Preparation", "Tax1": false, "Tax2": false, "Billable": true, "BillRate": "0.00", "Inactive": false, "CompanyID": this.companyId };
    const expensesOneObj = { "Description": "Postage", "ExpenseCode": "Postage", "TaxMap1": false, "TaxMap2": false, "TaxMap3": false, "Billable": true, "Inactive": false, "Cost": "0", "Markup": "0", "CompanyID": this.companyId };
    const expensesTwoObj = { "Description": "Fees", "ExpenseCode": "Fees", "TaxMap1": false, "TaxMap2": false, "TaxMap3": false, "Billable": true, "Inactive": false, "Cost": "0", "Markup": "0", "CompanyID": this.companyId };
    const expensesThreeObj = { "Description": "Next Day Delivery", "ExpenseCode": "Next Day Delivery", "TaxMap1": false, "TaxMap2": false, "TaxMap3": false, "Billable": true, "Inactive": false, "Cost": "0", "Markup": "0", "CompanyID": this.companyId };
    const expensesFourObj = { "Description": "Mileage", "ExpenseCode": "Mileage", "TaxMap1": false, "TaxMap2": false, "TaxMap3": false, "Billable": true, "Inactive": false, "Cost": "0", "Markup": "0", "CompanyID": this.companyId };

    const paymentMethodObj1 = { PaymentDescription: "Check", Required: true, "CompanyID": this.companyId };
    const paymentMethodObj2 = { PaymentDescription: "Cash", Required: true, "CompanyID": this.companyId };
    const paymentMethodObj3 = { PaymentDescription: "ACH", Required: true, "CompanyID": this.companyId };
    const paymentMethodObj4 = { PaymentDescription: "Credit Card", Required: true, "CompanyID": this.companyId };
    const paymentMethodObj5 = { PaymentDescription: "Write-Off", Required: true, "CompanyID": this.companyId };
    const paymentMethodObj6 = { PaymentDescription: "Refund Advance Payment", Required: true, "CompanyID": this.companyId };
    const paymentMethodObj7 = { PaymentDescription: "Credit Memo", Required: true, "CompanyID": this.companyId };


    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", this.encrDecSrvc.getObject(AppConstants.token));

    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/expenses', expensesOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/expenses', expensesTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/expenses', expensesThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/expenses', expensesFourObj, { headers: headers, withCredentials: true }));

    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/activities', activitiesOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/activities', activitiesTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/activities', activitiesThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/setting/activities', activitiesFourObj, { headers: headers, withCredentials: true }));

    this.observableBatch.push(this.http.post(environment.API_URL + '/company/engagement/', engagementOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/engagement/', engagementTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/engagement/', engagementThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/engagement/', engagementFourObj, { headers: headers, withCredentials: true }));

    this.observableBatch.push(this.http.post(environment.API_URL + '/company/terms', termsOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/terms', termsTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/terms', termsThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/staffTitles', staffTitlesOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/staffTitles', staffTitlesTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/staffTitles', staffTitlesThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/customerType', customerTypeOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/customerType', customerTypeTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/customerType', customerTypeThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/customerType', customerTypeFourObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/contactType', contactFourObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/contactType', contactThreeObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/contactType', contactTwoObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/contactType', contactOneObj, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/noteCategories', notesObj, { headers: headers, withCredentials: true }));

    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj1, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj2, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj3, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj4, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj5, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj6, { headers: headers, withCredentials: true }));
    this.observableBatch.push(this.http.post(environment.API_URL + '/company/companypaymentmethods', paymentMethodObj7, { headers: headers, withCredentials: true }));


    const parent = this;
    //parent.mangoAPISrvc.showLoader(true);
    parent.createBatchTypes(parent.observableBatch)
      .subscribe(data => {
        /* parent.mangoAPISrvc.addClient(defaultClient).subscribe((data: any) => {
          defaultProject['ClientID'] = data.data.ClientID;
          defaultProject['AssignedTo'] = parent.userId;
          
        }, (data) => { 
            parent.mangoAPISrvc.showLoader(false)
        }); */
        parent.mangoAPISrvc.getEngagementTypes(parent.companyId).subscribe((itemData: any) => {
          defaultProject['EngagementTypeID'] = itemData[0]['EngagementTypeID'];
          defaultProject['EngagementName'] = itemData[0]['Description'];
          const defNotif = this.companyData;
          defNotif['PortalPaymentNotifications'] = `{${parent.userId.toString()}}`;
          defNotif['MangoPaymentNotification'] = `{${parent.userId.toString()}}`;
          parent.mangoAPISrvc
            .updateCompanyNotificationSetting(defNotif)
            .subscribe(
              (notif: any) => {
                parent.mangoAPISrvc
                  .insertIntialStaffRoles({ CompanyID: parent.companyId })
                  .subscribe(
                    (resultStaffRoles) => { },
                    (err) => {
                      console.log(err);
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  )
                  .add(() => {
                    parent.mangoAPISrvc
                      .createMangoData(parent.companyId, parent.userId)
                      .subscribe(
                        (createData: any) => {
                          parent.mangoAPISrvc.showLoader(false)
                          Swal.fire({
                            title: parent._translate.instant("confirmation"),
                            html: `<div>${parent._translate.instant(
                              "create_dummy_records"
                            )}</div>`,
                            icon: "warning",
                            showCancelButton: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            allowEnterKey: false,
                            confirmButtonText: "Confirm",
                          }).then((result) => {
                            if (result.value) {
                              parent.encrDecSrvc.addObject(
                                AppConstants.clientID,
                                -1
                              );
                              parent.encrDecSrvc.addObject(
                                AppConstants.ClientName,
                                ""
                              );
                              parent.router.navigate([
                                AppConstants.dashBoardRoutePath,
                              ]);
                              setTimeout(() => {
                                window.location.reload();
                              }, 100);
                              parent.mangoAPISrvc.showLoader(false);
                            }
                          });
                        },
                        (err) => {
                          parent.mangoAPISrvc.showLoader(false);
                        }
                      );
                  });
              },
              (err) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        }, (err) => {
          parent.mangoAPISrvc.showLoader(false)
        });
        parent.mangoAPISrvc.showLoader(false)
      });
  }

  createBatchTypes(selectedItemList) {
    return forkJoin(selectedItemList);
  }

}
