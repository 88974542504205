<div class="invoices">
    <form [formGroup]="settingInvoiceForm">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{'save_button' | translate}}" [disabled]="(!isFormValid)"
                icon="fas fa-check" class="p-mr-1 p-button-sm" (click)="saveInvoice()"></button>
        </span>
        <div class="layout-content p-pt-0">
            <div class="p-fluid p-formgrid p-grid p-mx-2">
                <div class="p-col-9">
                    <div class="card p-mt-2" style="height: 100%;">
                        <h4>{{'settings.Invoicing-Options' | translate}}</h4>
                        <div class="p-formgroup-inline">
                            <div class="p-col-5">
                                <div class="p-field p-col-5">
                                    <label class="label-text">{{'settings.Last-Invoice-Number' | translate}}</label>
                                    <input type="text" class="p-text-right" formControlName="NextInvoiceNumber"
                                        pInputText autocomplete="off">
                                </div>
                                <div class="p-field p-col-11">
                                    <label class="p-d-block label-text">{{'client.default_invoice_template' |
                                        translate}}
                                        <i tooltipPosition="top"
                                            pTooltip="{{'settings.new-client-is-added' | translate}}"
                                            tooltipStyleClass="tooltip-width-450"
                                            class="fa fa-question-circle ic-blue cursor-hand"></i>
                                    </label>
                                    <p-dropdown [options]="invoiceTemplates" scrollHeight="400px"
                                        formControlName="GlobalInvoiceTemplate">
                                    </p-dropdown>
                                </div>
                                <div class="p-field p-col-11">
                                    <label class="p-d-block label-text">{{'client.default_action' | translate}}
                                        <i tooltipPosition="top"
                                            pTooltip="{{'settings.new-client-is-added' | translate}}"
                                            tooltipStyleClass="tooltip-width-450"
                                            class="fa fa-question-circle ic-blue cursor-hand"></i>
                                    </label>
                                    <p-dropdown [options]="finalizeActions" formControlName="FinalizeAction">
                                    </p-dropdown>
                                </div>
                                <div class="p-field p-col-11"
                                    *ngIf="loginCompanyId == 1073 || loginCompanyId == 1 || loginCompanyId == 971">
                                    <p-checkbox formControlName="isSurchargeActivated" [binary]="true"
                                        label="{{'activate-sur-charge' | translate}}"></p-checkbox>
                                </div>
                                <div class="p-field p-col-12">
                                    Email Client <input class="width-15p p-mx-1 p-text-right" type="text" pInputText
                                        formControlName="EmailDaysBeforeProcessingPmt" (focus)="$event.target.select();"
                                        pKeyFilter="num"> day(s) before processing Scheduled Payments.
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isShowTearOffFooter" [binary]="true"
                                        label="{{'settings.show_optional_tear_off_footer' | translate}}"></p-checkbox>
                                </div>
                            </div>
                            <div class="p-col-7">
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="IsHidePriorBalance" [binary]="true"
                                        label="{{'settings.Hide-Prior-Balance-on-Client-Invoices' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'settings.hide-prior-balance-tooltip' | translate}}"
                                        tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isShowBeforeInvoiceDate" [binary]="true"
                                        label="{{'settings.Show-Before-Invoice-Date' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'settings.show-before-invoice-date-tooltip' | translate}}"
                                        tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="IsHideClientName" [binary]="true"
                                        label="{{'settings.Show-Alternate-Client-Name' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'settings.show-alternate-client-name-tooltip' | translate}}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isEditInvoiceAmount" [binary]="true"
                                        label="{{'settings.allow-invoice-edit' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'settings.allow-invoice-edit' | translate}}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <!-- <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isInvoiceBlocking" [binary]="true"
                                        label="{{'Enable Invoice Blocking' | translate}}">
                                    </p-checkbox>
                                    <i  *ngIf="!invoiceOpt.InvoicingBusyStaffName" tooltipPosition="top"
                                        pTooltip="{{ 'invoice-blocking-tooltip' | translate }}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                    <i *ngIf="invoiceOpt.InvoicingBusyStaffName" tooltipPosition="top"
                                        pTooltip="{{ 'invoice-blocking-currently-used-by' | translate : { name: invoiceOpt.InvoicingBusyStaffName, date: invoiceOpt.InvoiceBusyStaffDate} }}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div> -->
                                <!-- <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isClickPayOnly" [binary]="true"
                                        label="Invoice Email Template - Click to Pay Only"
                                        (onChange)="toggleOptions('isClickPayOnly')"></p-checkbox>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isClickPayandLogin" [binary]="true"
                                        label="Invoice Email Template -  Client Portal Login & Click to Pay"
                                        (onChange)="toggleOptions('isClickPayandLogin')"></p-checkbox>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isEmailNoPayment" [binary]="true"
                                        label="Invoice Email Template - No Payment Button"
                                        (onChange)="toggleOptions('isEmailNoPayment')"></p-checkbox>
                                </div> -->
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="AutoRollupExpenses" [binary]="true"
                                        label="{{'auto_rollup' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'auto_rollup_expenses' | translate}}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="SkipInvoiceReview" [binary]="true"
                                        label="{{'skip_invoice_review' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'skip_invoice_review_tooltip' | translate}}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="isPrintClientNumber" [binary]="true"
                                        label="{{'print' | translate}} {{'client.client_number' | translate}}">
                                    </p-checkbox><i tooltipPosition="top"
                                        pTooltip="{{'print' | translate}} {{'client.client_number' | translate}}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-checkbox formControlName="InvoiceReminderActivate" [binary]="true"
                                        label="{{'Enable Statement Reminder' | translate}}">
                                    </p-checkbox><i tooltipPosition="top" pTooltip="{{'stmt.reminder' | translate}}"
                                        tooltipStyleClass="tooltip-width-800"
                                        class="fa fa-question-circle ic-blue p-ml-1 ic-blue cursor-hand"></i>
                                </div>
                                <div>
                                </div>
                                <div class="p-field p-col-6"
                                    *ngIf="settingInvoiceForm.get('InvoiceReminderActivate').value">
                                    <label class="label-text">{{'Statement Reminder Interval' | translate}}</label>
                                    <div class="p-col-6">
                                        <p-inputNumber formControlName="InvoiceReminderInterval"
                                            inputStyleClass="p-text-right" [min]="0" [max]="365" autocomplete="off">
                                        </p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-start">
                            <div class="p-col-5 p-pr-3">
                                <div class="p-d-flex">
                                    <h4>{{'settings.Invoice-Email-Options' | translate}}</h4>
                                    <i style="margin-top:5px;" tooltipPosition="top"
                                        pTooltip="{{'settings.determines_buttons' | translate}}"
                                        tooltipStyleClass="tooltip-width-300"
                                        class="fa fa-question-circle ic-blue cursor-hand p-ml-2"></i>
                                </div>
                                <p-dropdown [options]="invoiceEmailOptions" scrollHeight="400px"
                                    formControlName="invoiceEmailOption">
                                </p-dropdown>
                            </div>
                            <div class="p-col-6">
                                <div class="p-d-flex">
                                    <h4>{{'setting.statement_reminder_button_options' | translate}}</h4>
                                    <i style="margin-top:5px;" tooltipPosition="top"
                                        pTooltip="{{'settings.determines_buttons' | translate}}"
                                        tooltipStyleClass="tooltip-width-300"
                                        class="fa fa-question-circle ic-blue cursor-hand p-ml-2"></i>
                                </div>
                                <!-- <p-dropdown [options]="statementEmailButtonOptions" scrollHeight="400px" *ngIf="!superAdminVisible.includes(1073)"
                                    formControlName="statementReminderOption">
                                </p-dropdown> -->

                                <p-dropdown [options]="statementEmailButtonOptions" scrollHeight="400px"
                                    formControlName="statementEmailPaymentOption">
                                </p-dropdown>

                            </div>
                        </div>
                        <!-- <div class="p-formgroup-inline">
                            <div class="p-col-5">
                                <div class="p-field p-col-11">
                                    <p-dropdown [options]="invoiceEmailOptions" scrollHeight="400px" formControlName="invoiceEmailOption">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="p-formgroup-inline">
                            <h4>{{'setting.statement_reminder_button_options' | translate}}</h4>
                            <i style="margin-top:5px;" tooltipPosition="top" pTooltip="{{'settings.determines_buttons' | translate}}"
                                tooltipStyleClass="tooltip-width-300" class="fa fa-question-circle ic-blue cursor-hand p-ml-2"></i>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-col-5">
                                <div class="p-field p-col-11">
                                    <p-dropdown [options]="statementEmailButtonOptions" scrollHeight="400px" formControlName="statementReminderOption">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="p-col-3">
                    <div class="card p-mt-2" style="height: 100%;">
                        <h4>{{'client.late_fees' | translate}}</h4>
                        <div class="p-field p-col-8">
                            <p-radioButton name="NoLateFees" value="true" label="None" formControlName="NoLateFees">
                            </p-radioButton>
                        </div>
                        <div class="p-field p-col-8 p-pb-3">
                            <p-radioButton name="NoLateFees" value="false"
                                label="{{'settings.Calculate-Late-Fees' | translate}}" formControlName="NoLateFees">
                            </p-radioButton>
                        </div>
                        <div class="p-field p-col-8" *ngIf="settingInvoiceForm.get('NoLateFees').value === 'false'">
                            <label class="label-text">{{'settings.Annual-Interest-Rate' | translate}}</label>
                            <span class="p-inputgroup">
                                <p-inputNumber maxlength="12" (focus)="$event.target.select()" autocomplete="off"
                                    formControlName="InterestRate" inputStyleClass="p-text-right" inputId="percent"
                                    mode="decimal" locale="en-US" [minFractionDigits]="2"></p-inputNumber>
                                <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                            </span>
                        </div>
                        <div class="p-field p-col-8">
                            <label class="label-text">{{'client.customer_terms' | translate}}</label>
                            <select formControlName="TermsID" class="custom-dropdown" (change)="updateGraceDays()">
                                <option value="" disabled selected>Select Terms</option> <!-- Placeholder option -->
                                <option *ngFor="let term of termsList" [value]="term.value">{{ term.label }}</option>
                            </select>
                        </div>
                        <div class="p-field p-col-8">
                            <label class="label-text">{{'client.grace_days' | translate}}</label>
                            <p-inputNumber formControlName="GraceDays" inputStyleClass="p-text-right" [min]="0"
                                [max]="365" autocomplete="off">
                            </p-inputNumber>
                        </div>
                        <!--  end of card -->
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="card p-mt-3">
                        <h4>{{'settings.Invoice-Messaging' | translate}}</h4>
                        <div class="p-formgroup-inline">
                            <p-button pRipple label="{{'settings.Edit-Standard-Message' | translate}}"
                                icon="fal fa-poll-h" class="p-button-sm p-mr-2" (click)="isStandardMsg = true">
                            </p-button>
                            <p-button pRipple label="{{'settings.Edit-Footer-Message' | translate}}" icon="fal fa-table"
                                class="p-button-sm" (click)="isFooterMsg = true"></p-button>
                        </div>
                        <!--  end of card -->
                    </div>
                </div>

                <!--
                  @note:
                    Card Panel for Edit Statement Footer Message control.
                  @note;
                -->
                <div class="p-col-6">
                    <div class="card p-mt-3">
                        <h4>
                          {{
                              ( "settings.Statement-Messaging" | translate )
                          }}
                        </h4>
                        <div class="p-formgroup-inline">
                            <p-button
                              pRipple
                              label="
                                {{
                                  ( 'settings.Edit-Statement-Footer-Message' | translate )
                                }}
                              "
                              icon="fal fa-table"
                              class="p-button-sm"
                              (click)="editStatementFooterMessageComponentStatus = true"
                            >
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Standard Message -->
        <p-sidebar [(visible)]="isStandardMsg" position="bottom"
            [style]="{'min-height':'522px','background':'#8e8f92'}">
            <div class="card">
                <div class="control-label header-color text-nowrap p-col-12">
                    <h4 class="label-text">{{'Standard-Message' | translate}}</h4>
                </div>
                <div class="p-col-12 col-md-12">
                    <ejs-richtexteditor id='standardEditor' #standardEditor [(value)]="editor1Value"
                        formControlName="InvoiceMessage" [toolbarSettings]='tools' [maxLength]="550" [showCharCount]="true">
                    </ejs-richtexteditor>
                </div>
            </div>
        </p-sidebar>

        <!-- Footer Message -->
        <p-sidebar [(visible)]="isFooterMsg" position="bottom" [style]="{'min-height':'522px','background':'#8e8f92'}">
            <div class="card">
                <div class="control-label header-color text-nowrap p-col-12">
                    <h4 class="label-text">{{'bottom-memo' | translate}}</h4>
                </div>
                <div class="p-col-12 col-md-12">
                    <ejs-richtexteditor id='footerEditor' #footerEditor formControlName="StandardFooterMessage"
                        [(value)]="editor2Value" [toolbarSettings]='tools' [maxLength]="550" [showCharCount]="true">
                    </ejs-richtexteditor>
                </div>
            </div>
        </p-sidebar>

        <!--
          @note:
            Statement Footer Message Editor
          @note;
        -->
        <p-sidebar
          position="bottom"

          [(visible)]="editStatementFooterMessageComponentStatus"

          [style]="{'min-height':'522px','background':'#8e8f92'}"
        >
            <div class="card">
                <div class="control-label header-color text-nowrap p-col-12">
                    <h4 class="label-text">
                      {{
                          ( 'bottom-statement-memo' | translate )
                      }}
                    </h4>
                </div>
                <div class="p-col-12 col-md-12">
                    <ejs-richtexteditor
                      #statementFooterEditor
                      id="statementFooterEditor"

                      formControlName="StmtFooterMessage"

                      [(value)]="statementFooterMessageEditorValue"
                      [toolbarSettings]='tools'
                    >
                    </ejs-richtexteditor>
                </div>
            </div>
        </p-sidebar>
    </form>
</div>
