import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard } from '@app/_services';

import { SchedulingComponent } from './scheduling.component';

const routes: Routes = [{
  path: '',
  component: SchedulingComponent,
  children: [
    { path: '', component: SchedulingComponent },
    { path: AppConstants.scheduling, component: SchedulingComponent}
  ],
  canActivate: [AuthGuard],
  data: { 'roles': ['manager', "staff","Senior Manager"], 'featID': 18, 'plans': ["free", 'enterprise', 'pro','solo'], 'calResource': true }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchedulingRoutingModule { }
