import { Component, OnInit } from "@angular/core";
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import { timer } from "rxjs";
import { ELSharedService } from "../../el-shared-service";
import moment from "moment";
import { IProposal } from "../el-proposal.component";
import { AppConstants } from "@app/_helpers/api-constants";
import { SharedComponentsService } from "@app/shared/components";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

export enum EffectiveFromEnum {
  ON_ACCEPTANCE = 'On acceptance',
  ON_SPECIFIC_DATE = 'On a specific date'
}

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html'
})
export class GeneralComponent implements OnInit {
  generalObj: IProposal;
  minContractTerm: string = '';
  effectiveFromOptions = [
    { label: EffectiveFromEnum.ON_ACCEPTANCE, value: EffectiveFromEnum.ON_ACCEPTANCE },
    { label: EffectiveFromEnum.ON_SPECIFIC_DATE, value: EffectiveFromEnum.ON_SPECIFIC_DATE }
  ];
  selClient = null;
  public clientsList: any = [];
  public filteredClients: any = [];
  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  intervalid = null;
  mangoCompanyData: any;
  isBatch: boolean;
  engagementList: any[];
  companyId: any;
  engagementTypeId: number;
  constructor(
    private _translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private elSharedSrvc: ELSharedService,
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private router: Router, 
  ) {

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.isBatch = this.router.url.includes('batch');
    if (this.isBatch) {
      this.mangoAPISrvc.showLoader(true);
      this.getEngagements();
    }

    const interval = setInterval(() => {
      if (!this._translate.translations[this._translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.mangoAPISrvc.showLoader(true);

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  public get effectiveFromEnum(): typeof EffectiveFromEnum {
    return EffectiveFromEnum;
  }

  getEngagements() {
    this.engagementList = [];
    this.mangoAPISrvc.getEngagementTypes(this.companyId).subscribe((result: any) => {
      this.mangoAPISrvc.showLoader(false);
      for (let i = 0; i < result.length; i++) {
        if (!result[i].Inactive) {
          this.engagementList.push({
            label: result[i].Description,
            value: result[i].EngagementTypeID,
          });
        }
      }

      if (this.isBatch && this.elSharedSrvc.engagementTypeId) {
        this.engagementTypeId = this.elSharedSrvc.engagementTypeId;
      }
    });
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this._translate.instant('engagement_letters') },
      { label: 'New Proposal' },
      { label: this.elSharedSrvc.proposalObj.ProposalName || 'Untitled Proposal', icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.loadValues();
  }

  onEngagementChanged() {
    this.mangoAPISrvc.showLoader(true);
    this.elSharedSrvc.engagementTypeId = this.engagementTypeId;
    this.elSharedSrvc.onEngagementChanged();
  }

  loadValues() {
    const d = moment(this.elSharedSrvc.proposalObj.SpecificStartDate).toDate();
    this.generalObj = { ...this.elSharedSrvc.proposalObj, SpecificStartDate: d };
    const obj = this.elSharedSrvc.proposalObj;
    const client = this.encrDecSrvc.clientList?.filter((x) => x.ClientID ==  this.generalObj.Client["ClientID"])[0];
    if (!this.isBatch && client) {
        this.generalObj.Client =  {
        ClientID: client["ClientID"],
        ClientName: client["ClientName"],
        ContactName: obj["ContactName"] ? obj["ContactName"] : client["ContactPerson"],
        ContactEmail: obj["ContactEmail"] ? obj["ContactEmail"] : client["Email"],
        ClientAddress: [client.BusStreet1?.trim(), client.BusStreet2?.trim(), client.BusCity?.trim(), client.BusState?.trim(), client.BusZip?.trim()]?.filter(x => x).join(" ")
      };
      this.elSharedSrvc.proposalObj = { ...this.elSharedSrvc.proposalObj, ...this.generalObj };
    } 
    // else {
    //   if (!this.isBatch && !client) this.elSharedSrvc.shouldDisableSave = true;
    //   else if (this.isBatch && this.elSharedSrvc.selectedClientList.length == 0) this.elSharedSrvc.shouldDisableSave = true;
    //   else this.elSharedSrvc.shouldDisableSave = false;
    // }
    this.onChangeContractMonth();

    this.mangoCompanyData = this.encrDecSrvc.getObject(AppConstants.companySettings);
    if (this.mangoCompanyData == '') this.getCompanyInfo();
  }

  onChangeObject() {
    delete this.generalObj.autoRenew;
    delete this.generalObj.notifyDays;
    delete this.generalObj.percentageIncrease;

    this.elSharedSrvc.proposalObj = { ...this.elSharedSrvc.proposalObj, ...this.generalObj };
  }

  onChangeContractMonth() {
    const startDate =
      this.generalObj.EffectiveFrom === EffectiveFromEnum.ON_ACCEPTANCE
        ? moment()
        : moment(this.generalObj.SpecificStartDate);
    const endDate = moment(startDate.format('YYYY-MM-DD')).add(
      this.generalObj.MinContractMonths,
      'months'
    );

    this.minContractTerm = `${startDate.format('MMM DD, YYYY')} - ${endDate.format(
      'MMM DD, YYYY'
    )}`;
  }

  getCompanyInfo() {
    const parent = this;
    const companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    parent.mangoAPISrvc.getCompanyMangoDetails(companyID).subscribe(response => {
      parent.mangoCompanyData = response[0];
      this.encrDecSrvc.addObject(AppConstants.companySettings, parent.mangoCompanyData);
      //parent.callFilter();
    });
  }

  async handleSelectClick(event) {
    // let contacts = event.ContactsList?.filter((x) => x.Email != null);
    // let altContact =  contacts?.length > 0 ? contacts[0] : null;
    const proceed = () => {
      this.elSharedSrvc.proposalObj = {
        ...this.elSharedSrvc.proposalObj,
        Client: {
          ClientID: event["ClientID"],
          ClientName: event["ClientName"],
          ContactName: event["ContactPerson"] ?? null,
          ContactEmail: event["Email"] ?? null,
          ClientAddress: [event.BusStreet1?.trim(), event.BusStreet2?.trim(), event.BusCity?.trim(), event.BusState?.trim(), event.BusZip?.trim()]?.filter(x => x).join(" ")
        },
        ContactName: event["ContactPerson"],
        ContactEmail: event["Email"]
      };

      this.generalObj = this.elSharedSrvc.proposalObj;
    };

    if (event.DateELBilling == null || moment(event.DateELBilling).add(1, "year") < moment() ) {
        Swal.fire({
          icon: 'info',
          title: this._translate.instant('el_date_billing_header'),
          html: this._translate.instant('el_date_billing_null').replace("%ELBillAmount%", event.ELBillAmount).replace("%DateELBilling%", moment().add(12, "months").format("MM/DD/YYYY")),
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'I Agree',
          cancelButtonText: 'Cancel',
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            proceed();
          } else if (result.isDismissed){
            this.router.navigate(['/engagements/letters/dashboard']);
          }
        })
      } else {
        proceed();
      }
  }

  getClientCount() {
    const parent = this;
    return new Promise<number>(async function (resolve, reject) {
      try {
        parent.mangoAPISrvc.getELClientCount().subscribe(
          function (data: any) {
            resolve(parseInt(data.count) ?? 0);
          },
          error => {
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  fetchClients() {
    if (
      this.clientsList.length == 0 ||
      this.clientsList.length !==
        this.encrDecSrvc.clientList?.filter(
          client => client.ContactRecord != true && client.Inactive != true
        ).length
    ) {
      const list = this.encrDecSrvc.clientList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item["ContactRecord"] != true && item["Inactive"] != true) {
          this.clientsList.push(item);
        }
      }
      this.loadValues();
      this.mangoAPISrvc.showLoader(false);
    } else {
      clearInterval(this.intervalid);
    }
  }

  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientsList.length; i++) {
        const client = this.clientsList[i];
        if (client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  toggleEmailReminderInterval(event) {
    this.generalObj.isEmailReminderEnabled = event.checked;
    if (!event.checked) {
      this.generalObj.ProposalReminderInterval = 1;
    }
    this.onChangeObject();
  }
}
