<div class="create-duplicate" (click)="checkIfRemoveEdit();">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{'pm.Clone-Project' | translate}}" icon="fal fa-copy" styleClass="p-button-sm" class="p-button-help p-pr-2"
                (click)="createDuplicateRecords()" [disabled]="(selectedClientsItems.length == 0 || !projectObj.TemplateName) || isProcessing"></p-button>
            <p-button pRipple label="{{'dashboard' | translate}}" icon="fal fa-tachometer-alt-fastest" styleClass="p-button-sm"
                class="p-button-help p-pr-2" routerLink="/project-management/dashboard"></p-button>
            <p-button pRipple label="{{'Clients' | translate}}" icon="fal fa-users" styleClass="p-button-sm " class="p-button-help p-pr-2"
                routerLink="/project-management/clientHistory"></p-button>
            <p-button pRipple label="{{'Projects' | translate}}/{{'Tasks' | translate}} List" icon="fal fa-building" styleClass="p-button-sm "
                class="p-button-help p-pr-2" routerLink="/project-management/projectList"></p-button>
            <p-button pRipple label="{{'PM-Settings' | translate}}" icon="fal fa-cog" styleClass="p-button-sm"
                class="p-button-help p-pr-2" routerLink="/project-management/settings"></p-button>
        </span>
        <div class="p-grid p-mx-2">
            <div class="p-col-5">
                <div class="card p-mt-2 p-mb-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12">
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <div class="p-d-flex p-jc-between p-mb-2">
                                        <label class="label-text required-field">{{'pm.Project-Template-Name' | translate}}</label>
                                        <i class="fal fa-table ic-green cursor-hand ic-size-18 p-pr-2"
                                            tooltipPosition="top"
                                            tooltipStyleClass="tooltip-no-wrap"
                                            pTooltip="{{ 'pm.show_wildcards_table' | translate }}" pRipple
                                            (click)="onOpenWildcardsDialog()"></i>
                                    </div>
                                    <input type="text" pInputText [(ngModel)]="projectObj.TemplateName" maxlength="100"
                                        (focus)="$event.target.select()" autocomplete="off">
                                    <!-- <label class="label-text">{{'due-date' | translate}}</label>
                                    <p-calendar [(ngModel)]="projectObj.DueDate" name="itemdueDateSelected" id="itemdueDateSelected" #itemdueDateSelected="ngModel" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2099" showButtonBar="true"></p-calendar> -->
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="label-text">{{'Tags' | translate}}</label>
                                    <p-dropdown [options]="tagsList" [(ngModel)]="projectObj.tagID"
                                        name="itemTagSelected" id="itemTagSelected" #itemTagSelected="ngModel"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template let-item pTemplate="selectedItem">
                                            <span class="p-text-center" [style.background-color]="item.color && item.color !== '' ? item.color : null" [style.color]="item.textColor">{{item.label}}</span>
                                        </ng-template>
                                        <ng-template let-car pTemplate="item">
                                            <div class="ui-helper-clearfix">
                                                <div class="p-text-center p-p-2" [style.background-color]="car.color && car.color !== '' ? car.color : null" [style.color]="car.textColor">{{car.label}}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <label class="label-text" style="width: 100%;">
                                        {{'Billing_Partner' | translate}}
                                        <div class="pull-right ic-size-10">
                                            <p-checkbox binary="true" [(ngModel)]="overrideBillingPartner" (onChange) ="onchangeOverride()" label="{{ 'Override_Client' | translate }}">
                                            </p-checkbox>
                                            <i pTooltip="{{'Override_Client_Tooltip' | translate}}" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" class="fa fa-question-circle ic-blue p-ml-1 p-pb-1"></i>
                                        </div>
                                    </label>
                                    <p-dropdown [options]="AllStaffsTypes" [(ngModel)]="projectObj.ManagerID"
                                        name="itemManagerIdSelected" id="itemManagerIdSelected" [disabled]="!overrideBillingPartner" placeholder="Client {{'Billing_Partner' | translate}}"
                                        #itemManagerIdSelected="ngModel" [ngModelOptions]="{standalone: true}">
                                    </p-dropdown>
                                </div>
                                <div class="p-field p-col-6 p-px-0 p-d-flex p-jc-between">
                                    <div class="p-col-6">
                                        <div class="p-d-flex p-jc-between">
                                            <label class="label-text">{{'repeat' | translate}}</label>
                                            <span class="pull-right">
                                                <i *ngIf="projectObj['Repeat'] === 'custom'"
                                                    class="fal fa-repeat ic-red cursor-hand ic-size-13"
                                                    tooltipPosition="top"
                                                    pTooltip="{{ 'view_custom' | translate }}" pRipple
                                                    (click)="rruleComponent.isDisplayRuleDialog = true;rruleComponent.ngOnInit();"></i>
                                            </span>
                                        </div>
                                        <p-dropdown [options]="dateRangeList" (onChange)="onChangeRepeat()" styleClass="p-mt-2" [disabled]="!!!projectObj['DueDate']"  [(ngModel)]="projectObj.Repeat" name="itemrepeatSelected" id="itemrepeatSelected" #itemrepeatSelected="ngModel" [ngModelOptions]="{standalone: true}">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-col-6">
                                        <label class="label-text">{{'Turnaround-Days' | translate}}</label>
                                        <p-inputNumber mode="decimal" styleClass="p-mt-2" placeholder="# of Days" autocomplete="off" [(ngModel)]="projectObj.TurnAround" (focus)="$event.target.select()" maxlength="3" name="itemturnAroundSelected" id="itemturnAroundSelected" #itemturnAroundSelected="ngModel" inputId="withoutgrouping"
                                            [useGrouping]="false">
                                        </p-inputNumber>
                                    </div>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6">
                                    <label class="label-text" style="width: 100%;">
                                        {{'manager' | translate}}
                                        <div class="pull-right ic-size-10">
                                            <p-checkbox binary="true" [(ngModel)]="overrideManager" (onChange) ="onchangeOverride()" label="{{ 'Override_Client' | translate }}"></p-checkbox>
                                            <i pTooltip="{{'Override_Client_Tooltip' | translate}}" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" class="fa fa-question-circle ic-blue p-ml-1 p-pb-1"></i>
                                        </div>
                                    </label>
                                    <p-dropdown [options]="AllStaffsTypes" [(ngModel)]="projectObj.UserAssignedID" [disabled]="!overrideManager" placeholder="Client {{'manager' | translate}}"
                                        name="itemStaffIdSelected" id="itemStaffIdSelected"
                                        #itemStaffIdSelected="ngModel" [ngModelOptions]="{standalone: true}">
                                    </p-dropdown>
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="label-text">{{'due-date' | translate}}</label>
                                    <p-calendar [(ngModel)]="projectObj.DueDate" name="itemdueDateSelected" id="itemdueDateSelected" #itemdueDateSelected="ngModel" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2099" showButtonBar="true"></p-calendar>
                                </div>
                            </div>
                            <div class="p-formgroup-inline"
                                *ngIf="ddmSettingsObj?.isTrackingTimebyProjects && ddmSettingsObj?.isBudgetingProjectLevel">
                                <div class="p-field p-col-6">
                                    <label class="label-text" style="width: 100%;">
                                        {{'engagement.type' | translate}}
                                    </label>
                                    <p-dropdown  *ngIf="engagementList && engagementList.length" optionLabel="label" optionValue="value"  [options]="engagementList" [(ngModel)]="projectObj.EngagementTypeID"
                                        name="EngagementTypeID" id="EngagementTypeID" #EngagementTypeID="ngModel"
                                        (onChange)="onEngagementChanged()">
                                    </p-dropdown>
                                </div>
                                <div class="p-field p-col-6">
                                    <br />
                                    <button type="button" pButton pRipple label="Show Budget"
                                        icon="fal fa-ballot" [disabled]="!projectObj.EngagementTypeID"
                                        class="p-button-sm p-mt-2" (click)="onShowTasksBudget()">
                                    </button>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6 p-pl-2 p-pt-1">
                                    <p-checkbox [(ngModel)]="projectObj.isReviewRequired" name="itemReviewSelected" id="itemReviewSelected" #itemReviewSelected="ngModel" [binary]="true" label="{{'pm.Requires-Final-Review' | translate}}" class="label-text">
                                    </p-checkbox><i pTooltip="{{'pm.Requires-Final-Review-tooltip' | translate}}" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" class="fa fa-question-circle ic-blue p-ml-1"></i>
                                    <!-- <label class="label-text">{{'Turnaround-Days' | translate}}</label>
                                    <p-inputNumber mode="decimal" placeholder="# of Days" autocomplete="off" [(ngModel)]="projectObj.TurnAround" (focus)="$event.target.select()" maxlength="3" name="itemturnAroundSelected" id="itemturnAroundSelected" #itemturnAroundSelected="ngModel" inputId="withoutgrouping"
                                        [useGrouping]="false">
                                    </p-inputNumber> -->
                                </div>
                                <div class="p-field p-col-6 p-pt-1">
                                    <p-checkbox [(ngModel)]="projectObj.IsCompleteInOrder" name="isCompleteInOrderSelected" id="isCompleteInOrderSelected" #isCompleteInOrderSelected="ngModel" [binary]="true" label="{{'pm.complete-tasks-in-order' | translate}}" class="label-text">
                                    </p-checkbox><i pTooltip="{{'pm.Complete-Tasks-tooltip' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" class="fa fa-question-circle ic-blue p-ml-1"></i>
                                </div>
                                <div class="p-field p-col-6 p-pt-1 p-mt-2">
                                    <p-checkbox [(ngModel)]="projectObj.automaticComputeDuedate" name="automaticComputeDuedate" id="automaticComputeDuedate" #automaticComputeDuedate="ngModel" [binary]="true"
                                        label="{{ 'auto_compute_due_date' | translate }}" class="label-text"></p-checkbox><i
                                        pTooltip="{{ 'auto_compute_due_date-tooltip' | translate }}"
                                        tooltipPosition="top"
                                        class="fa fa-question-circle ic-blue  p-ml-1"></i>
                                </div>
                                <div class="p-field p-col-6 p-pt-1 p-mt-2">
                                    <p-checkbox [(ngModel)]="projectObj.isPreviousPeriodYearPolicy" name="isPreviousPeriodYearPolicy" id="isPreviousPeriodYearPolicy" #isPreviousPeriodYearPolicy="ngModel" [binary]="true"
                                        label="{{ 'pm.previous_period_year_policy' | translate }}" class="label-text"></p-checkbox>
                                </div>

                                <div class="p-field p-col-12 p-mt-2 p-ml-2">
                                  <label class="label-text">{{'pm.Project-Memo' | translate}}</label>
                                  <app-mentions [rows]="5" [(inputText)]="projectObj.ProjectMemo"
                                    style="width:100% !important; resize: vertical;"></app-mentions>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="tasksDataSource.length > 0">
                    <p-table [value]="tasksDataSource" [responsive]="true" [rows]="5" [scrollable]="true"
                        [scrollHeight]="'calc(55vh - 240px)'" dataKey="TaskRow" editMode="row"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [sortOrder]="1" [rowHover]="true" #dttask>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-3p p-text-center">
                                    {{'task' | translate}} #
                                </th>
                                <th class="width-8p p-text-left">
                                    {{'Task-Description' | translate}}
                                </th>
                                <th class="width-7p p-text-center" *ngIf="projectObj.automaticComputeDuedate == true">
                                    {{ 'offset_days' | translate }}
                                </th>
                                <th class="width-6p p-text-center">
                                    {{'due-date' | translate}}
                                </th>
                                <th class="width-8p p-text-left">
                                    {{'user-assigned' | translate}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-editing="editing">
                            <tr [pEditableRow]="rowData">
                                <td class="p-text-center p-px-3 width-3p">
                                    <div class="badge-number bg-blue">{{rowData['TaskRow']}}</div>
                                </td>
                                <td class="p-text-left p-text-nowrap p-text-truncate width-8p" tooltipPosition="top"
                                    showDelay="800" pTooltip="{{rowData.TaskDescription}}"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData['TaskDescription']}}
                                </td>

                                <td pInitEditableRow (click)="onRowEditInit1(rowData, 'OffsetDays', rowIndex)"
                                        class="width-5p p-text-center p-text-nowrap p-text-truncate p-fluid"
                                        pTooltip="{{ 'offset_days' | translate}}" tooltipPosition="top" showDelay="800"
                                        *ngIf="projectObj.automaticComputeDuedate == true"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="number" [(ngModel)]="rowData['dueDateOffsetDaysIndicator']"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (focus)="$event.target.select()"
                                                    (ngModelChange)="onOffsetDaysChange($event, rowIndex);"
                                                    min="0"
                                                    class="tasks-row-edit offset-days-{{rowIndex}}" required>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['dueDateOffsetDaysIndicator']}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                <td pInitEditableRow tooltipPosition="top"
                                    class="p-text-center p-text-nowrap p-text-truncate width-6p" showDelay="800"
                                    pTooltip="{{rowData['DueDate'] | date:'MM/dd/yyyy'}}"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    (click)="onRowEditInit1(rowData, 'DueDate', rowIndex)">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="rowData['DueDate']"
                                                appendTo="body" yearRange="2000:2099" dateFormat="mm/dd/yy"
                                                showButtonBar="true" panelStyleClass="tasks-row-edit"
                                                inputStyleClass="tasks-row-edit due-date-{{rowIndex}}">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{rowData['DueDate'] | date:'MM/dd/yyyy'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow tooltipPosition="top" showDelay="800"
                                    pTooltip="{{rowData.StaffNames}}" tooltipStyleClass="tooltip-no-wrap"
                                    class="p-text-left p-text-nowrap p-text-truncate width-8p"
                                    (click)="onRowEditInit1(rowData, 'StaffName', rowIndex)">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <!-- <p-dropdown [(ngModel)]="rowData['UserAssigned']" [options]="AllStaffsTypes" name="itemUserAssigned" [filter]="true" id="itemUserAssigned" #itemUserAssigned="ngModel" appendTo="body" [ngModelOptions]="{standalone: true}" (onChange)="handleDropSelectClick($event,rowData)">
                                            </p-dropdown> -->
                                            <p-multiSelect #multiStaff [(ngModel)]="rowData['UserAssignedIDArray']"
                                                [options]="StaffOptionsForTasks" placeholder="Unassigned"
                                                (onChange)="handleDropSelectClick($event,rowData);" appendTo="body"
                                                id="itemUserAssigned" name="itemUserAssigned"
                                                styleClass="tasks-row-edit staff-name-{{rowIndex}}"
                                                panelStyleClass="tasks-row-edit"></p-multiSelect>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData['StaffNames']}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-7">
                <div class="card p-pt-0 p-mt-2">
                    <p-table [value]="clientsList" [responsive]="true" [rows]="10" [scrollable]="true"
                        [scrollHeight]="'calc(65vh - 56px)'" [dataKey]="dataKey" sortField="ClientName" [virtualScroll]="true"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [globalFilterFields]="globalFilterColumns" (onFilter)="onFilter($event)" [sortOrder]="1"
                        [rowHover]="true" [(selection)]="selectedClientsItems" #cientslist [columns]="selectedColumns">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #clientsGB pInputText type="text"
                                        (input)="cientslist.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'globalsearch' | translate}}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="cientslist.reset();clearClientsSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="clientfilteredItemsSize > -1" severity="success"
                                    text="{{clientfilteredItemsSize}} {{'records_found' | translate}}."
                                    styleClass="p-ml-2"></p-message>
                                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                    selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                                    placeholder="Choose Columns" class="pull-right"></p-multiSelect>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-4p p-text-center">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="width-12p p-text-left" pSortableColumn="ClientName">
                                    {{'client.name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-left" pSortableColumn="CustomerTypeDescription">
                                    {{'client.type' | translate}}
                                    <p-sortIcon field="CustomerTypeDescription"></p-sortIcon>
                                    <p-columnFilter type="text" field="CustomerTypeDescription"
                                                display="menu" (onFilter)="onFilter($event)"></p-columnFilter>
                                </th>
                                <!-- <th class="width-8p p-text-left">
                                    {{'client.group' | translate}}
                                </th>
                                <th class="width-9p p-text-left">
                                    {{'manager' | translate}}
                                </th>
                                <th class="width-9p p-text-left">
                                    {{'Billing_Partner' | translate}}
                                </th> -->
                                <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field"
                                    class="p-text-left">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td class="width-4p p-text-center">
                                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                </td>
                                <td class="width-12p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    showDelay="800" pTooltip="{{rowData?.ClientName}}"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.ClientName}}</td>
                                <td class="width-12p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    showDelay="800" pTooltip="{{rowData?.CustomerTypeDescription}}"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.CustomerTypeDescription}}</td>
                                <!-- <td class="width-8p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top" showDelay="800" pTooltip="{{rowData.GroupDescriptionIDArrayDesc}}" tooltipStyleClass="tooltip-no-wrap">
                                    {{rowData.GroupDescriptionIDArrayDesc}}</td>
                                <td class="width-9p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top" showDelay="800" pTooltip="{{rowData.StaffAssignedName}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.StaffAssignedName}}
                                </td>
                                <td class="width-9p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top" showDelay="800" pTooltip="{{rowData.BillingPartnerName}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.BillingPartnerName}}
                                </td> -->
                                <td *ngFor="let col of columns" class="{{col.rowClass}} p-text-truncate"
                                    pTooltip="{{rowData[col.field]}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

    <p-sidebar [(visible)]="isShowBudgetsDialog" position="right" (onHide)="onCloseBudgetDialog()" [baseZIndex]="2"
        [style]="{'min-width':'68%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <div class="card" *ngIf="isShowBudgetsDialog">
            <h4>Project Budget</h4>

            <div class="p-col-12 p-pb-0">
                <div class="p-col-12 p-pb-0 p-fluid p-formgrid p-grid">
                    <div class="p-field width-20p p-mr-4">
                        <label class="label-text p-mr-4">Project Budgeted Time</label>
                        <input type="text" (focus)="$event.target.select();" pKeyFilter="num" pInputText
                            (blur)="projectObj['TimeAmount'] = mangoUtils.doMoneyFormat(projectObj['TimeAmount'], 'time')"
                            [ngClass]="{'notAllowed': projectObj['isTrackTimeTaskLevel'], 'p-text-right': true}"
                            [(ngModel)]="projectObj.TimeAmount" maxlength="100" autocomplete="off">
                    </div>
                    <div class="p-field width-20p p-mr-4">
                        <label class="label-text p-mr-4">{{ 'budget.year' | translate }}</label>
                        <!-- todo -->
                        <p-dropdown [options]="yearsList" [(ngModel)]="projectObj.BudgetYear"
                            name="BudgetYear" id="BudgetYear"
                            #BudgetYear="ngModel" [ngModelOptions]="{standalone: true}">
                        </p-dropdown>
                    </div>
                    <div class="p-field p-mr-4 p-mt-5">
                        <p-checkbox [(ngModel)]="projectObj.isTrackTimeTaskLevel" [binary]="true"
                            (onChange)="onTrackTimeChanged()" label="Enable Task Budget" class="p-mt-2 label-text">
                        </p-checkbox>
                    </div>
                    <div class="p-field p-mr-4 p-mt-5">
                        <button type="button" pButton pRipple label="{{'save_button' | translate}}" icon="fal fa-check"
                            [disabled]="shouldDisableBudget()" class="p-button-sm" (click)="onSaveBudget()">
                        </button>
                    </div>
                    <div class="p-field p-mr-4 p-mt-5">
                        <button type="button" pButton pRipple label="Rollover Options" icon="fal fa-cogs"
                            class="p-button-sm" (click)="onShowRolloverDialog()">
                        </button>
                    </div>
                </div>
                <!-- <div class="p-col-12 p-fluid p-formgrid p-grid">

                </div> -->
            </div>

            <div class="p-col-12">
                <app-tasks-budget *ngIf="projectObj['isTrackTimeTaskLevel']"
                    (onTasksBudgetSaved)="onTasksBudgetSaved($event)"
                    (onTasksBudgetUpdated)="onTasksBudgetUpdated($event)" [isFromTemplate]="true"
                    [CompanyTemplateHeaderID]="CompanyTemplateHeaderID"></app-tasks-budget>
            </div>
        </div>
    </p-sidebar>

    <app-budget-rollover-options (onSaveDialog)="onCloseRolloverDialog($event)" *ngIf="showRollOverDialog"
        [templateObj]="templateObj"></app-budget-rollover-options>
</div>

<div *ngIf="isDisplayWildcardsTable">
    <app-pm-wildcards-dialog
        (onClose)="isDisplayWildcardsTable=false">
    </app-pm-wildcards-dialog>
</div>

<app-rrule-generator [selectedDueDate]="projectObj['DueDate']" [selectedRuleString]="projectObj['RuleString']"
    (onCompleteRrule)="onCompleteRruleDialog($event)"></app-rrule-generator>
