import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import { RRule, rrulestr } from "rrule";


@Component({
  selector: 'app-client-schedule',
  templateUrl: './client-schedule.component.html'
})
export class ClientScheduleComponent implements AfterViewInit {
  public tableDataSorce = [];
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dt: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  public companyId;
  clientID: number = -1;
  searchTextStr: any = "";
  schedule: any = [];
  
  public allStaff = [];
  public selectedDateRange = null;
  public _selectedColumns: any[];
  public globalFilterColumns: any = []
  cols: any[];
  public dateRangeOptions: any[] = [
    { label: "All Dates", value: "All" },
    { label: "This Month", value: "Month" },
    { label: "This Quarter", value: "Quarter" },
    { label: "This Year", value: "Year" },
    { label: "Next Month", value: "NextMonth" },
    { label: "Next Quarter", value: "NextQuarter" },
    { label: "Next Year", value: "NextYear" },
  ];

  constructor(private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, private translate: TranslateService, private router: Router) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.allStaff = this.encrDecSrvc.getObject(AppConstants.staffList);
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString);
  

  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('Schedule') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
    this.initializeColumn();
  }

  ngOnInit(): void {
      this.allStaff.map(itm=>{
        itm['Color'] = this.ColorLuminance('#' + Math.floor(Math.random()*16777215).toString(16), -0.5);
      });
  }

  ngAfterViewInit(): void {
    this.searchValue.nativeElement.focus();
    this.getAppointments();
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.globalFilterColumns = [...['ClientName', 'Subject', 'Attendees', 'StartDate', 'EndDate'], ...this._selectedColumns.map(col => col.field)];
  }

  initializeColumn() {
    const allCols = [
      { field: 'Location', header: this.translate.instant('Location'), class: 'width-7p p-text-left', sortable: true, type: "String" },
      { field: 'Description', header: this.translate.instant('Description'), class: 'width-9p p-text-left p-text-nowrap p-text-truncate', sortable: true, type: "String" },
      { field: 'Rules', header: this.translate.instant('Rules'), class: 'width-7p p-text-left', sortable: true, type: "String" },
      { field: 'AllDay', header: this.translate.instant('All_Day'), class: 'width-2p p-text-left', sortable: true, type: "String" },
    ];

    this.cols = [
      ...allCols
    ]
    let selectedCols = [];

    this.mangoAPISrvc.getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID)).subscribe((data: any) => {
      if(data.ClientSchedulingCols?.length > 0) {
        selectedCols = allCols.filter((col) => data.ClientSchedulingCols.includes(col.field));
      } else {
        selectedCols = [];
      }
      this.cols = [
        ...allCols
      ]
      this._selectedColumns = selectedCols;
      this.globalFilterColumns = [...['ClientName', 'Subject', 'Attendees', 'StartDate', 'EndDate'], ...this._selectedColumns]
    }, err => {
      selectedCols = [];
      this.cols = [
        ...allCols
      ]
      this.globalFilterColumns = ['ClientName', 'Subject', 'Attendees', 'StartDate', 'EndDate'];
    });

  }

  getAppointments(){
    const range = this.selectedDateRange ? this.selectedDateRange : null;
    this.mangoAPISrvc.getAppointmentByClientID(this.clientID, range).subscribe((data: any) =>{
      data.map(itm => {
        const staffName = [];
        for(let i = 0; i < itm.attendees.length; i++){
          const filterStaff = this.allStaff.filter(stf => stf.StaffID === itm.attendees[i]);
          if(filterStaff.length > 0){
            staffName.push({name: filterStaff[0].StaffName, color: filterStaff[0].Color});
          }
        }

        itm["AttendeesName"] = staffName;
        itm["StartDate"] = moment(moment(itm["StartDate"]), "DD/MM/YYYY hh:mm:ss").format("MM/DD/YYYY hh:mm");
        itm["EndDate"] = moment(moment(itm["EndDate"]), "DD/MM/YYYY hh:mm:ss").format("MM/DD/YYYY hh:mm");
        let rule = '';
        if(itm.recurrenceRule !== "" && itm.recurrenceRule != null){
          const insRule = String(itm.recurrenceRule).toString().slice(0, -1); 
          rule = rrulestr(insRule).toText().toUpperCase();
        }
        itm["Rules"] = rule;
      });
      this.schedule = data;
    });
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString, obj.filters.global.value);
  }


  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString, "");
  }

  ColorLuminance(hex, lum) {
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;
  
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }
  
    return rgb;
  }

  onChangeSelectedCols(event) {
    let columnsToSave = ''; 
    event.value.map((cols, index) => {
      if(index > 0)
        columnsToSave += `, ${cols.field}`
      else
        columnsToSave += cols.field
    })
    const objToSave = { ClientSchedulingCols: `{${columnsToSave}}`}
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(
        this.encrDecSrvc.getObject(AppConstants.staffID),
        objToSave
      )
      .subscribe(
        (data) => {},
        (err) => {}
      );
  }



}
