<div class="activities">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="{{'add' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addActivity();"></p-button>
        </span>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mx-3 p-mt-2 p-pt-0">
                    <p-table #dt [value]="tableDataSorce" [rowHover]="true" [responsive]="true" dataKey="ServiceCodeID"
                        selectionMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="ServiceCode" [sortOrder]="1"
                        [globalFilterFields]="['ServiceCode','Description','DefaultMemo','BillRate' , 'TemplateName']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)"
                        [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'globalsearch' | translate}}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                </p-message>
                                <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                    <p-checkbox [(ngModel)]="activitiesInactive" label="{{'show_inactive' | translate}}"
                                        binary="true" (onChange)="onStatusChange()">
                                    </p-checkbox>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-5p p-text-center">{{'actions' | translate}}</th>
                                <th class="width-15p" pSortableColumn="ServiceCode" pResizableColumn>{{'activity-code' |
                                    translate}}
                                    <p-sortIcon field="ServiceCode"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="Description" class="width-20p">
                                    {{'description' | translate}}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="DefaultMemo" class="width-30p">
                                    {{'Default-Work-Memo' | translate}}
                                    <p-sortIcon field="DefaultMemo"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="BillRate" class="width-15p p-text-left">
                                    {{'rate.rate_template' | translate}}
                                    <p-sortIcon field="BillRate"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="BillRate" class="width-6p p-text-right">
                                    {{'rate' | translate}}
                                    <p-sortIcon field="BillRate"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="Billable" class="width-7p p-text-center">
                                    {{'billable' | translate}}
                                    <p-sortIcon field="Billable"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="Tax1" class="width-6p p-text-center">
                                    {{'taxable' | translate}}
                                    <p-sortIcon field="Tax1"></p-sortIcon>
                                </th>
                                <th class="width-6p p-text-center">
                                    {{'inactive' | translate}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-childrowData>
                            <tr class="cursor-hand">
                                <td class="width-5p p-text-center">
                                    <div>
                                        <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green"
                                            (click)="editActivity(childrowData);" pTooltip="{{'Edit' | translate}}"
                                            tooltipPosition="top"></i>
                                        <i pRipple (click)="deleteActivity(childrowData.ServiceCodeID);"
                                            class="fal fa-trash-alt ic-red cursor-hand" style="padding-left:8px;"
                                            pTooltip="{{'mgmt-delete' | translate}}" tooltipPosition="top"></i>
                                    </div>
                                </td>
                                <td class="width-15p" (click)="editActivity(childrowData);">
                                    {{childrowData.ServiceCode}}
                                </td>
                                <td class="width-20p" (click)="editActivity(childrowData);">
                                    {{childrowData.Description}}
                                </td>
                                <td class="width-30p" (click)="editActivity(childrowData);">
                                    {{childrowData.DefaultMemo}}
                                </td>
                                <td (click)="editActivity(childrowData);"  [ngClass]="(childrowData['RateTemplateNamesID']) == null?'ic-green':''" class="p-text-left width-15p">
                                    {{childrowData.TemplateName}}
                                </td>
                                <td (click)="editActivity(childrowData);" class="p-text-right width-6p">
                                    {{childrowData.BillRate}}
                                </td>
                                <td (click)="editActivity(childrowData);" class="p-text-center width-7p">
                                    <p-checkbox [(ngModel)]="childrowData.Billable" binary="true" [disabled]="true">
                                    </p-checkbox>
                                </td>
                                <td (click)="editActivity(childrowData);" class="p-text-center width-6p">
                                    <p-checkbox [(ngModel)]="childrowData.Tax1" binary="true" [disabled]="true">
                                    </p-checkbox>
                                </td>
                                <td class="width-6p p-text-center">
                                    <p-checkbox [(ngModel)]="childrowData.Inactive" binary="true"
                                        (onChange)="saveInactive(childrowData)"></p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <p-dialog header="{{'activity' | translate}}" [(visible)]="displayAddActivityModal" [modal]="true"
    [style]="{width: '30vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="1" [draggable]="true"
    [resizable]="false" (onHide)="closeActivity()">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <form #ActivitiesMainForm="ngForm">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="activity" class="required-field label-text">{{'activity-code' |
                                translate}}</label>
                            <input type="text" pInputText class="p-inputtext p-component"
                                [(ngModel)]="activity.ServiceCode" name="serviceCode" (change)="onChangeInputCode()"
                                id="serviceCode" #serviceCode="ngModel" required minlength="2"
                                (blur)="validateSC(activity)">
                        </div>
                        <div class="p-field p-col">
                            <label for="description" class="label-text">{{'Bill-Rate' | translate}}</label>
                            <span class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="fal fa-dollar-sign"></i></span>
                                <input pInputText class="p-text-right" [(ngModel)]="activity.BillRate"
                                (blur)="transformbillrate($event)" name="billRate" id="billRate" placeholder="0.00" maxlength="10"
                                (focus)="$event.target.select()" />
                            </span>
                        </div>
                        <div class="p-field p-col-12">
                            <label for="description" class="required-field label-text">{{'description' |
                                translate}}</label>
                            <input type="text" pInputText class="p-inputtext p-component"
                                [(ngModel)]="activity.Description" name="description" id="description" #desc="ngModel"
                                required minlength="3" maxlength="200">
                        </div>
                        <div class="p-grid p-col-12 p-my-3">
                            <div class="p-field p-col">
                                <p-checkbox [(ngModel)]="activity.Billable" [binary]="true" name="billable" id="billable" #billable="ngModel"
                                    label="{{'billable' | translate}}"></p-checkbox>
                            </div>
                            <div class="p-field p-col" *ngIf="isEditActivity">
                                <p-checkbox [(ngModel)]="activity.Inactive" [binary]="true" name="inactive" id="inactive" #inactive="ngModel"
                                    label="{{'inactive' | translate}}">
                                </p-checkbox>
                            </div>
                            <div class="p-field p-col">
                                <p-checkbox [(ngModel)]="activity.Tax1" name="tax1" id="tax1" #tax1="ngModel" [binary]="true" label="{{'taxable' | translate}} 1">
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="p-field p-col-12">
                            <label for="description" class="label-text">{{'Default-Work-Memo' | translate}}
                                ({{'Optional' | translate}})</label>
                            <button pButton pRipple (click)="copyToWorkMemo()" tooltipPosition="top"
                                pTooltip="{{'Copy-Description-to-Work-Memo' | translate}}"
                                tooltipStyleClass="tooltip-no-wrap" type="button" icon="fas fa-copy"
                                [disabled]="!activity.Description"
                                class="p-button-rounded p-button-danger p-button-text pull-right height-button"></button>
                            <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="activity.DefaultMemo"
                                pInputTextarea></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_close' | translate}}"
             [disabled]="!ActivitiesMainForm.valid || !ActivitiesMainForm.dirty"
            (click)="saveActivity(true,true)" *ngIf="!isEditActivity"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_and_new' | translate}}"
             [disabled]="!ActivitiesMainForm.valid || !ActivitiesMainForm.dirty"
            (click)="saveAndNew(isEditActivity)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_button' | translate}}"
             [disabled]="!ActivitiesMainForm.valid || !ActivitiesMainForm.dirty"
            (click)="saveActivity(false,true)" *ngIf="isEditActivity"></button>
        <button type="button" pButton pRipple icon="fal fa-times" (click)="closeActivity()"
            label="{{'close' | translate}}" ></button>
    </ng-template>
</p-dialog> -->