import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { CompanyDashboardRoutingModule } from './company-dashboard-routing.module';

import { CompanyDashboardComponent } from './company-dashboard.component';
import { RevenueComponent } from './revenue/revenue.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { StaffComponent } from './staff/staff.component';

@NgModule({
  declarations: [CompanyDashboardComponent, RevenueComponent, ReceiptsComponent, StaffComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    CompanyDashboardRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CompanyDashboardModule { }
