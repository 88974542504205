import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils, AuthGuard } from '@app/_services';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import { SharedComponentsService } from '@app/shared/components';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
declare let numeral: any;

@Component({
  selector: 'app-client-time-slips',
  templateUrl: './client-time-slips.component.html'
})
export class ClientTimeSlipsComponent implements OnInit {
  public timeExpenseRecords: any = [];
  public slipRecords: any = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";
  clientID;
  clientStatus;
  itemSelected;
  timeSlipsDropDown;
  totalBillableAmount;
  totalBillableTimeChild;

  allCount = 0;
  totalTime = 0;
  openCount = 0;
  billedCount = 0;
  totalBillableTime = 0;
  totalNonBillableAmount = 0;

  timeSlipsGrid: any = [];

  grandExpenseAmt: any = 0.00;
  grandBillableTime: any = 0.00;
  grandNonBillableAmt: any = 0.00;
  grandBillableAmt: any = 0.00;
  isApiLoaded: boolean = false;

  public filteredStaffSingle: any[];
  public AllStaffsTypes: any = [];
  public activityList: any = [];
  cellFocused: any = null;
  timeexp;

  public recordsTable: any = [];
  public showRecordsSideBar: boolean = false;
  public selOpenTimeRecords: any = [];
  public selOpenExpRecords: any = [];
  companySetting: any;

  constructor(private mangoAPISrvc: MangoApiService, public auth: AuthGuard, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, public mangoUtils: mangoUtils,public translate: TranslateService) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses)
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.timeslipsRoutePath + '_' + AppConstants.SearchString);

    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.sharedSrvc.timeEntryDialogData
      .subscribe(() => {
        this.fetchData();
      })

    this.sharedSrvc.expenseDialogData
      .subscribe(() => {
        this.fetchData();
      })
  }

  initTranslations(){
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('Time_&_Expense') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.fetchData();
    this.getAllStaffList();
    this.getActivityGroups();
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
  }

  isLocked(ddate) {
    if(!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)){
      if(this.timeexp.isLockTime && parseInt(this.timeexp.LockTimeDays) > 0) {
        const dDate = moment(ddate).format("MM/DD/YYYY");
        const dateNow = moment().format('MM/DD/YYYY');
        return moment(dateNow).diff(moment(dDate), 'days') > this.timeexp.LockTimeDays
      }
    }
    return false
  }

  fetchData() {
    this.mangoAPISrvc.showLoader(true);
    const parent = this;

    forkJoin([
      this.mangoAPISrvc.timeSlipsParentByClientId(this.clientID),
      this.mangoAPISrvc.timeSlipsChildByClientId(this.clientID)
    ]).subscribe((results: any) => {
      const slipsrecords = results[1];
      const totals = results[0].map((obj) => {
        obj.laboramount = obj.laboramount ? numeral(obj.laboramount).value() : 0.00;
        obj.nonbillableamount = obj.nonbillableamount ? numeral(obj.nonbillableamount).value() : 0.00;
        obj.expenseamount = obj.expenseamount ? numeral(obj.expenseamount).value() : 0.00;
        obj.totaltime = obj.totaltime ? numeral(obj.totaltime).value() : 0;
        obj.allTimeSlips = [];
        obj.allExpenseSlips = [];


        const allRecords = slipsrecords[obj.ProjectMasterID] ? slipsrecords[obj.ProjectMasterID] : [];
        obj.allTimeSlips = allRecords.filter((item) => item.IsTimeRecord == 'T').map((item) => {
          return {
            ...item,
            displayDate : moment(item.Ddate.substr(0,10)).format("MM/DD/YYYY"),
            Ddate: new Date(item.Ddate)
          }
        });

        obj.allExpenseSlips = allRecords.filter((item) => item.IsTimeRecord == 'X').map((item) => {
          return {
            ...item,
            displayDate : moment(item.Ddate.substr(0,10)).format("MM/DD/YYYY"),
            Ddate: new Date(item.Ddate)
          }
        });

        obj['allSelectedTimeSlips'] = obj.allTimeSlips;
        obj['allSelectedExpenseSlips'] = obj.allExpenseSlips;

        parent.selOpenTimeRecords[obj['ProjectMasterID']] = [...obj['allSelectedTimeSlips']]
        parent.selOpenExpRecords[obj['ProjectMasterID']] =  [...obj['allSelectedExpenseSlips']]

        obj.hasChildrens = obj.allTimeSlips.length > 0 || obj.allExpenseSlips.length > 0;

        obj.grandExpenseTotalAmt = obj.allExpenseSlips.reduce(function (a, b) { return a + +numeral(b.StandardAmount).value(); }, 0);
        obj.allTimeSlips = obj.allTimeSlips.length > 0 ? obj.allTimeSlips : [];

        obj.grandExpenseHrs = obj.allTimeSlips.reduce(function (a, b) { return a + +numeral(b['TotalTime']).value(); }, 0);
        obj.grandBillableamount = obj.allTimeSlips.reduce(function (a, b) { return a + +numeral(b['billableamount']).value(); }, 0);
        obj.grandNonbillableamount = obj.allTimeSlips.reduce(function (a, b) { return a + +numeral(b['nonbillableamount']).value(); }, 0);

        return obj;
      });

      this.timeExpenseRecords = totals;
      this.slipRecords = slipsrecords;

      this.recordsTable =
        this.timeExpenseRecords[
          this.timeExpenseRecords.findIndex(
            (item) => item.ProjectMasterID == this.recordsTable?.ProjectMasterID
          )
        ];

      if(!this.recordsTable || (this.recordsTable?.allTimeSlips?.length === 0 && this.recordsTable?.allExpenseSlips?.length === 0))
        this.showRecordsSideBar = false;

      this.isApiLoaded = true;
      this.calculateFooters();

      this.loadFilterGrid();
      if(this.timeExpenseRecords.length === 0){
        this.onCloseRecords();
      }

      this.mangoAPISrvc.showLoader(false);
    }, () => {
      this.mangoAPISrvc.showLoader(false);
    });
  }

  calculateFooters() {
    this.grandExpenseAmt = this.timeExpenseRecords.reduce(function (a, b) { return a + +numeral(b['expenseamount']).value(); }, 0);
    this.grandBillableTime = this.timeExpenseRecords.reduce(function (a, b) { return a + +numeral(b['totaltime']).value(); }, 0);
    this.grandNonBillableAmt = this.timeExpenseRecords.reduce(function (a, b) { return a + +numeral(b['nonbillableamount']).value(); }, 0);
    this.grandBillableAmt = this.timeExpenseRecords.reduce(function (a, b) { return a + +numeral(b['laboramount']).value(); }, 0);
  }

  calculateParentAndChildFooters() {
    this.timeExpenseRecords.map((obj) => {
      const newTotalExpenses = obj.allExpenseSlips.reduce((a, b) => a + +numeral(b['StandardAmount']).value(), 0);
      const newTotalBillableTime = obj.allTimeSlips.reduce((a, b) => a + +numeral(b['TotalTime']).value(), 0);
      const newNonBillableAmount = obj.allTimeSlips.reduce((a, b) => a + +numeral(b['nonbillableamount']).value(), 0);
      const newBillableAmount = obj.allTimeSlips.reduce((a, b) => a + +numeral(b['billableamount']).value(), 0);

      obj.expenseamount = newTotalExpenses;
      obj.totaltime = newTotalBillableTime;
      obj.nonbillableamount = newNonBillableAmount;
      obj.laboramount = newBillableAmount;

      obj.grandExpenseTotalAmt = newTotalExpenses;
      obj.grandExpenseHrs = newTotalBillableTime;
      obj.grandNonbillableamount = newNonBillableAmount;
      obj.grandBillableamount = newBillableAmount;

      return obj;
    });

    this.calculateFooters();
  }

  deleteEngagement(id, data?) {
    const parent = this;
    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);
    if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(data.Ddate) <= lastDayOfMonth) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), "Unable to reverse invoice since the month is locked.")
    }
    else {
      Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.deleteTimeSheet(id).subscribe(function (data) {
            parent.fetchData();
            parent.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
          }, error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          });
        }
      })
    }
  }

  openTimeEntryDailog(data?: any, isTimeRecord?: any) {
    data['isEditFlow'] = true;
    if (isTimeRecord) {
      this.sharedSrvc.openTimeEntryDialog(data);
    } else {
      this.sharedSrvc.openExpenseDialog(data);
    }
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.timeslipsRoutePath + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.timeslipsRoutePath + '_' + AppConstants.SearchString, obj.filters?.global?.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  saveTimeEntry(evt, data) {
    const klassObj = this;
    data.StandardAmount = data.StandardAmount
      ? numeral(data.StandardAmount).value()
      : 0.0;
    const obj = {
      Ddate: moment(data.Ddate).format("MM/DD/YYYY"),
      TimeStart: this.removeOffset(new Date(data.TimeStart)),
      TimeStop: data.TimeStop
        ? this.removeOffset(new Date(data.TimeStop))
        : data.TimeStop,
      Approved: data.Approved,
      Billable: data.Billable,
      Billed: data.Billed,
      BillingRate: numeral(data.BillingRate).value().toString(),
      ClientID: data.ClientID,
      ClientName: data.ClientName,
      ElaspedTime: data.TotalTime,
      Description: data.Description,
      Memo: data.Memo,
      nonbillableamount: data.nonbillableamount,
      ServiceCode: data.ServiceCode,
      ServiceCodeID: data.ServiceCodeID,
      StandardAmount: data.StandardAmount.toString(),
      TotalTime: data.TotalTime,
      StaffID: data.StaffID,
      StaffName: data.StaffName,
      StaffDeptID: data.StaffDeptID,
      StaffPayRate: data.StaffPayRate,
      StaffCost: Number(data.TotalTime) * Number(data.StaffPayRate),
      WorkCodeID: data.WorkCodeID,
      OriginatingPartnerID: data.OriginatingPartnerID,
      BillingPartnerID: data.BillingPartnerID,
      GroupDescriptionID: data.GroupDescriptionID,
      GroupDescriptionIDArray: data.GroupDescriptionIDArray,
      ClientTypeID: data.ClientTypeID,
      ProjectMasterID: data.ProjectMasterID,
      PrivateMemo: data.PrivateMemo,
      EngagementTypeID: data.EngagementTypeID,
      IsTimeRecord: "T",
      WorkLocation: data.WorkLocation,
      isFlatFee: data.isFlatFee,
    };
    if (!obj.TimeStop) {
      delete obj.TimeStop;
    }
    this.encrDecSrvc.addObject(
      AppConstants.savedWorkLocation,
      data.WorkLocation
    );
    klassObj.mangoAPISrvc.showLoader(true);
    klassObj.mangoAPISrvc.updateTimeSheet(data.SlipMasterID, obj).subscribe(
      function (resp) {
        klassObj.mangoAPISrvc.sendBudgetAlert({
          ClientID: obj['ClientID'],
          ProjectMasterID: obj['ProjectMasterID'],
          CompanyID: klassObj.encrDecSrvc.getObject(AppConstants.companyID),
          Ddate: moment(obj.Ddate).format('YYYY-MM-DD')
        }).subscribe((data) => {}, err => { console.log(err) })
        klassObj.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        klassObj.mangoAPISrvc.notify(
          "success",
          "Updated!",
          AppConstants.updateMsg
        );
        data['IsColumnChanges'] = false
        klassObj.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        klassObj.mangoAPISrvc.showLoader(false)
        klassObj.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.updateErrorMsg
        );
      }
    );
    // data['IsColumnChanges'] = false
  }

  saveTimeExpense(evt, data) {
    const _this = this;
    data.Ddate = moment(data.Ddate).format("MM/DD/YYYY");
    data["ExpenseCodeID"] = data.ExpenseCodeID
      ? data.ExpenseCodeID
      : null;
    data["ExpenseCode"] = data.ExpenseCode
      ? data.ExpenseCode
      : null;
    data["Cost"] = data["Cost"] ? data["Cost"] : 0;
    data["Units"] = data["Units"] ? data["Units"] : 0;
    data["Markup"] = data["Markup"] ? data["Markup"] : 0;
    data["Tax"] = data["Tax"] ? data["Tax"] : 0;
    data["Reimbursed"] = data["Reimbursed"]
      ? data["Reimbursed"]
      : false;
    data["Reimburseable"] = data["Reimburseable"]
      ? data["Reimburseable"]
      : false;
    data["IsTimeRecord"] = "X";
    data["Description"] = data["Description"]
      ? data["Description"]
      : null;
    data["BillingRate"] = data["BillingRate"]
      ? data["BillingRate"]
      : null;
    data["TotalTime"] = data["TotalTime"]
      ? data["TotalTime"]
      : null;
    data["StaffPayRate"] = data["StaffPayRate"]
      ? data["StaffPayRate"]
      : 0;
    data["ElaspedTime"] = data["ElaspedTime"]
      ? data["ElaspedTime"]
      : null;
    data["WriteupDown"] = data["WriteupDown"]
      ? data["WriteupDown"]
      : 0;

    data["SelectedAmount"] = data["SelectedAmount"]
      ? data["SelectedAmount"]
      : 0;
    data["Billed"] = data["Billed"] ? data["Billed"] : false;

    data.StaffID = data.StaffID
    _this.mangoAPISrvc.showLoader(true);

    _this.mangoAPISrvc
      .updateTimeSheet(data.SlipMasterID, data)
      .subscribe(function (resp) {
        _this.mangoAPISrvc.sendBudgetAlert({
          ClientID: data['ClientID'],
          ProjectMasterID: data['ProjectMasterID'],
          CompanyID: _this.encrDecSrvc.getObject(AppConstants.companyID),
          Ddate: moment(data.Ddate).format('YYYY-MM-DD')
        }).subscribe((data) => {}, err => { console.log(err) })

        _this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

        data['IsColumnChanges'] = false
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify(
          "success",
          "Updated!",
          AppConstants.updateMsg
        );
      }),
      (error) => {
        data['IsColumnChanges'] = false
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.updateErrorMsg
        );
      };
  }

  onEditInit(event, table) {
    this.cellFocused = { ...event, table };
  }

  onEditComplete() {
    this.cellFocused = null;
  }

  onEditCancel() {
    this.cellFocused = null;
  }

  getAllStaffList() {
    const parent = this;
    if (parent.AllStaffsTypes.length > 0) {
      return false;
    }
    parent.AllStaffsTypes = [{ label: 'All', value: null }];
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < item.length; ++i) {
      if(item[i].Inactive)
        continue;

      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      parent.AllStaffsTypes.push(obj);
    }
  }

  getActivityGroups() {
    const parent = this;
    if (this.activityList.length > 0) {
      return false;
    }

    let tempList = this.encrDecSrvc.activitiesList;
    tempList = tempList.sort(parent.mangoUtils.compareValues("ServiceCode", "asc"));

    this.activityList = tempList.map((data) => {
      return {
        label: data.ServiceCode + " - " + data.Description,
        value: data.ServiceCodeID,
        fullobj: data,
      }
    });
  }

  handleDropStaffSelectClick(event, data, formtype) {
    if (formtype == "Staff") {
      data["StaffName"] = event.label;
      data["StaffID"] = event.value;
    } else if (formtype == "Activity") {
      const serviceCode = this.activityList.find((item) => item.value === event.value)
      data["ServiceCode"] = serviceCode?.fullobj.ServiceCode;
      data["ServiceCodeID"] = event.value;
    }

    data["IsColumnChanges"] = true;
  }

  filterData(query, staffListItems: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < staffListItems.length; i++) {
      const staffItem = staffListItems[i];
      if (staffItem.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(staffItem);
      }
    }
    return filtered;
  }

  filterStaffItems(event, data) {
    this.filteredStaffSingle = this.filterData(
      event.query,
      this.AllStaffsTypes
    );
  }

  replaceShortcuts2(value, desc, type?) {
    desc['IsColumnChanges'] = true;

    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    if (type === "Memo") desc["Memo"] = valueArr.join(" ");
    else {
      desc["description"] = valueArr.join(" ");
    }
  }

  calculateStandardAmount(data) {
    if(data['BillingRate']) {
      if(data['Billable']) {
        data['billableamount'] = (parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])).toString()
        data['StandardAmount'] = data['billableamount']
      } else {
        data['nonbillableamount'] = (parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])).toString()
        data['StandardAmount'] = data['nonbillableamount']
      }
    }

    return data;
  }

  changeUSMoney(evt: any, data: any) {
    let enteredValue;
    if (evt["target"]) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt["value"] ? evt["value"] : evt["value"];
    }
    if (enteredValue == "" && enteredValue != 0) {
      enteredValue = data.amount;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt["target"]["value"] = data.amount = "$0.00";
      } else {
        evt.setValue("0.00");
      }
    } else {
      if (data) {
        evt["target"]["value"] = data.amount = "$" + numeral(enteredValue).format("0,0.00");
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format("0,0.00"));
      }
    }
  }

  onTotalTimeChange(evt, data) {
    data['TotalTime'] = evt.target.value ? evt.target.value.replace("$", "").replace(",","") : data['TotalTime'];
    data = this.calculateStandardAmount(data);
    this.calculateParentAndChildFooters();
  }

  onBillRateChange(evt, data) {
    data['BillingRate'] = evt.target.value ? evt.target.value.replace("$", "").replace(",","") : data['BillingRate'];
    data = this.calculateStandardAmount(data);
    this.calculateParentAndChildFooters();
  }

  formChanged() {
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  onShowRecords(data): void {
    this.recordsTable = {};
    this.recordsTable = data;
    this.showRecordsSideBar = true;
  }

  onCloseRecords() {
    this.showRecordsSideBar = false;
  }

  openTimeEntryOrExpenseEntryDailog(data?: any, isCreate?: any) {
    if (!data.IsTimeRecord || data.IsTimeRecord == "T") {
      //this.timeEntryForm.isUpdated = false;
      data["isEditFlow"] = false;
      data["selectedDate"] = new Date();
      data["ClientID"] = this.clientID;
      data["ClientName"] = this.clientName;
      data["ProjectMasterID"] = null;
      this.mangoAPISrvc.showLoader(true)
      this.mangoAPISrvc.showLoader(false)
      this.sharedSrvc.openTimeEntryDialog(data);

      //this.displayTimeEntryModal = true;
    } else {
      data["isEditFlow"] = false;
      data["selectedDate"] = new Date();
      data["ClientID"] = this.clientID;
      data["ProjectMasterID"] = null;
      data["ClientName"] = this.clientName;
      this.sharedSrvc.openExpenseDialog(data);
      // this.displayExpenseEntryModal = true;
    }
  }
}
