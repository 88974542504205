import { Component, ComponentFactoryResolver, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NotesComponent,InactivityComponent, EngagementComponent, ShortcutsComponent, TimesEntryComponent, ContactComponent, TaskComponent, TimerComponent, ToDoComponent, InvoiceComponent, ExpenseEntryComponent, SharedComponentsService, LanguageKeysComponent, ActivityComponent, ExpensesComponent, UpcomingEventsComponents } from '@app/shared/components';
import { Subscription } from 'rxjs';
import { MangoApiService } from '@app/_services';


@Component({
  selector: 'app-shared-dialogs',
  templateUrl: './app-shared-dialogs.html'
})
export class AppSharedDialogs implements OnInit {
  @ViewChild(NotesComponent) notesForm: NotesComponent;
  @ViewChild(EngagementComponent) engagementForm: EngagementComponent;
  @ViewChild(ShortcutsComponent) shortcutsComponentForm: ShortcutsComponent;
  @ViewChild(ContactComponent) contactForm: ContactComponent;
  @ViewChild(TaskComponent) taskForm: TaskComponent;
  @ViewChild(TimerComponent) timerForm: TimerComponent;
  @ViewChild(TimesEntryComponent) timeEntryForm: TimesEntryComponent;
  // timeEntryForm: TimesEntryComponent = null;
  @ViewChild(ExpenseEntryComponent) expenseEntryForm: ExpenseEntryComponent;
  @ViewChild(ToDoComponent) toDoForm: ToDoComponent;
  @ViewChild(InvoiceComponent) invoiceForm: InvoiceComponent;
  @ViewChild(LanguageKeysComponent) languageKeys: LanguageKeysComponent;
  @ViewChild(ActivityComponent) activityForm: ActivityComponent;
  @ViewChild(ExpensesComponent) expensesForm: ExpensesComponent;
  @ViewChild(InactivityComponent) inActivityDialog: InactivityComponent;
  // @ViewChild(UpcomingEventsComponents) upcomingEvents: UpcomingEventsComponents;
  // @ViewChild('timesEntryContainer', {read: ViewContainerRef}) timesEntryContainer: ViewContainerRef

  notesDialogSub: Subscription;
  engagementDialogSub: Subscription;
  shortcutsDialogSub: Subscription;
  contactDialogSub: Subscription;
  taskDialogSub: Subscription;
  timerDialogSub: Subscription;
  timeEntryFormDialogSub: Subscription;
  expenseEntryDialogSub: Subscription;
  toDoDialogSub: Subscription;
  invoiceDialogSub: Subscription;
  languageKeysSub: Subscription;
  activityDialogSub: Subscription;
  expensesDialogSub: Subscription;
  inactivityDialogSub: Subscription;
  upcomingEventSub: Subscription;


  constructor(public _sharedDialogSvc: SharedComponentsService,private mangoAPISrvc: MangoApiService, private cfr: ComponentFactoryResolver, private injector: Injector) {
    this.mangoAPISrvc.applyLanguage();
    this.subscribeNotesDialog();
    this.subscribeEngagementDialog();
    this.subscribeShortCutsDialog();
    this.subscribeContactDialog();
    this.subscribeTaskDialog();
    this.subscribeTimerDialog();
    this.subscribeTimeEntryDialog();
    this.subscribeExpenseDialog();
    this.subscribeTodoDialog();
    this.subscribeInvoiceDialog();
    this.subscribeLanguageKeysSub();
    this.subscribeActivityDialog();
    this.subscribeExpensesDialog();
    this.subscribeInActivityDialog();
    this.subscribeUpcomingEvents();
   }

  ngOnInit(): void {
    this.mangoAPISrvc.applyLanguage();
  }


  subscribeActivityDialog() {
    this.activityDialogSub = this._sharedDialogSvc.activityDialogSub.subscribe((args) => {
      if(args.display === true)
        this.openActivityModal(args.data);
    })
  }

  openActivityModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.activity = true;
    setTimeout(() => {
      this.activityForm.addActivity(data);
    }, 50);
  }

  subscribeExpensesDialog() {
    this.expensesDialogSub = this._sharedDialogSvc.expensesDialogSub.subscribe((args) => {
      if(args.display === true)
        this.openExpensesModal(args.data);
    })
  }

  openExpensesModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.expenses = true;
    setTimeout(() => {
      this.expensesForm.openAddExpenses(data);
    }, 50);
  }


  openInActivityDialog() {
    this._sharedDialogSvc.sharedCompVisibility.inactivity = true;
  }

  subscribeInActivityDialog() {
    this.inactivityDialogSub = this._sharedDialogSvc.inActivitySub
      .subscribe((args: any) => {
        this.openInActivityDialog();
      });
  }

  subscribeNotesDialog() {
    this.notesDialogSub = this._sharedDialogSvc.notesDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openNotesModal(args.data);
        }
      });
  }

  openNotesModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.notes = true;
    setTimeout(() => {
      this.notesForm.openNotesModal(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  subscribeEngagementDialog() {
    this.engagementDialogSub = this._sharedDialogSvc.engagementDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openEngagementModal(args.data);
        }
      });
  }

  openEngagementModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.engagement = true;
    setTimeout(() => {
      this.engagementForm.openEngagementDialog(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  subscribeShortCutsDialog() {
    this.shortcutsDialogSub = this._sharedDialogSvc.shortCutsDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openShortCutsModal(args.data);
        }
      });
  }

  openShortCutsModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.shortcuts = true;
    setTimeout(() => {
      this.shortcutsComponentForm.openShortCutsDialog(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  subscribeContactDialog() {
    this.contactDialogSub = this._sharedDialogSvc.contactDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openContactModal({data: args.data, contacts: args.contacts});
        }
      });
  }

  openContactModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.contact = true;
    setTimeout(() => {
      this.contactForm.openContactModal(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  subscribeTaskDialog() {
    this.taskDialogSub = this._sharedDialogSvc.taskDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openTaskModal(args.data);
        }
      });
  }

  openTaskModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.task = true;
    setTimeout(() => {
      this.taskForm.openTaskDialog(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  subscribeTimerDialog() {
    this.timerDialogSub = this._sharedDialogSvc.timerDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openTimerModal(args.data);
        }
      });
  }

  openTimerModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.timer = true;
    setTimeout(() => {
      this.timerForm?.handleTimerDialog(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  subscribeTimeEntryDialog() {
    this.timeEntryFormDialogSub = this._sharedDialogSvc.timeEntryDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openTimeEntryModal(args.data);
        }
      });
  }

  subscribeInvoiceDialog() {
    this.invoiceDialogSub = this._sharedDialogSvc.invoiceDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openInvoiceModal(args.data);
        }
      });
  }

  subscribeLanguageKeysSub() {
    this.languageKeysSub = this._sharedDialogSvc.languageKeysSub.subscribe((args) => {
      if(args.display === true)
        this.openLangKeyModal();
    })
  }

  openLangKeyModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.languageKeys = true;
    setTimeout(() => {
      this.languageKeys.openDialog();
    }, 50);
  }

  openTimeEntryModal( data?: any ){
    this._sharedDialogSvc.sharedCompVisibility.timesEntry = true;

    const openModal = () => {
      if (data.isEditFlow) {
        this.timeEntryForm.staffID = data['StaffID'];
        this.timeEntryForm.staffName = data['StaffName'];
        this.timeEntryForm.isUpdated = false;
        this.timeEntryForm.editTSModal(JSON.parse(JSON.stringify(data)));
      } else {
        this.timeEntryForm.addTSModal(data);
      }
    };
    setTimeout(openModal, 50);
  }

  subscribeExpenseDialog() {
    this.expenseEntryDialogSub = this._sharedDialogSvc.expenseDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openExpenseModal(args.data);
        }
      });
  }

  openInvoiceModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.invoice = true;
    setTimeout(() => {
      if (data.isEditFlow) {
        this.invoiceForm.editInvoiceModal(Object.assign({}, data));
      } else {
        this.invoiceForm.openInVoiceModal(data);
      }
    }, 50);
  }

  openExpenseModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.expenseEntry = true;
    setTimeout(() => {
      if (data.isEditFlow) {
        this.expenseEntryForm?.editEEModal(Object.assign({}, data));
      } else {
        this.expenseEntryForm?.openEEModal(data);
      }
    }, 50);
  }

  subscribeTodoDialog() {
    this.toDoDialogSub = this._sharedDialogSvc.toDoDialogSub
      .subscribe((args: any) => {
        if (args.display == true) {
          this.openTodoModal(args.data);
        }
      });
  }

  openTodoModal(data?: any) {
    this._sharedDialogSvc.sharedCompVisibility.toDo = true;
    setTimeout(() => {
      this.toDoForm.openTodoDialog(JSON.parse(JSON.stringify(data)));
    }, 50);
  }

  closeTimeEntryModal() {
    // this.timesEntryContainer.clear();
    this._sharedDialogSvc.setTSDialogData(this.timeEntryForm.tse);
  }

  closeInvoiceModal() {
    this._sharedDialogSvc.setInvoiceDialogData(this.invoiceForm.tse);
  }

  closeEngagementModal() {
    this._sharedDialogSvc.setEGDialogData(this.engagementForm.engagementObj);
  }

  closeContactModal() {
    this._sharedDialogSvc.setContactDialogData(this.contactForm.defaultContactRecord);
  }

  closeTaskModal() {
    this._sharedDialogSvc.setTaskDialogData(this.taskForm.defaultTaskRecord);
  }

  closeNotesModal() {
    this._sharedDialogSvc.setNotesDialogData(this.notesForm.defaultNotesObj);
  }

  closeShortcutsModal() {
    this._sharedDialogSvc.setShortCutsDialogData({ "data": true });
  }

  closeExpenseEntryModal() {
    this._sharedDialogSvc.setEEDialogData(this.expenseEntryForm.tse);
  }

  closeActivityModal() {
    this._sharedDialogSvc.setActivityDialogData({ "data": true });
  }

  closeExpensesModal() {
    this._sharedDialogSvc.setExpensesDialogData({ "data": true });
  }

  subscribeUpcomingEvents(){
    this.upcomingEventSub = this._sharedDialogSvc.upcomingEventSub
      .subscribe((args: any) => {
        this._sharedDialogSvc.sharedCompVisibility.upcomingEvents = true;
      });
  }

}
