import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { timer } from "rxjs";
import { Table } from "primeng/table";
import moment from "moment";
import { SelectItem } from 'primeng/api';

import FileSaver from 'file-saver';
import * as xlsx from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TranslateService } from '@ngx-translate/core';
import { RRule, rrulestr } from 'rrule';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html'
})
export class ExportsComponent implements OnInit, AfterViewInit {
  public selectedType: any = null;
  public exportList: any;
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  public IsDateTypeReadOnly: boolean = true;
  public dateRangeList: SelectItem[];
  public dateRangeListLimited: SelectItem[];
  public myReportsForm: UntypedFormGroup;

  p_dateFromTo: boolean = false;
  isDisbleAll: boolean = false;
  filteredExportItemsSize = -1;
  searchTextStr: any = '';
  public filteredDataSource: any = [];
  public invoicesDataSource: any = [];
  public openTimeDataSource: any = [];
  public usersDataSource: any = [];
  public activitiesDataSource: any = [];
  public expensesDataSource: any = [];
  public groupDataSource: any = [];
  public customersDataSource: any = [];
  public engagementsDataSource: any = [];
  public paymentsDataSource: any = [];
  public appointmentsDataSource: any = [];
  showColumnSelector: boolean = true;
  dataCols: any;
  globalFilterColumns: any = [
    'Mobile',
    'Office',
    'ContactPerson',
    'ClientID',
    'ClientName',
    'CustomerTypeDescription',
    'GroupDescriptionIDArrayDesc',
    'ClientNumber',
    'Email',
    'BillingPartnerName',
    'StaffAssignedName',
    'BusCity',
    'BusStreet1',
    'BusStreet2',
    'BusState',
    'BusZip',
    'BillingGroupDescription',
    'Tele1',
    'Tele1Descr',
    'Tele2',
    'Tele2Descr',
    'Tele3',
    'Tele3Descr',
    'Home',
    'OriginatingPartnerName',
    'ClientNameInvoice',
    'Fax',
    'Website',
    'ReferredBy',
    'Billable'
  ];

  _selectedColumns: any[];
  public companyId: number;

  /* Start - For Fetching Clients */
  public selClient: any = { ClientID: null, ClientName: '' };
  public clientListDatasource: any = [];
  public filteredClients: any[];
  intervalid: any;
  clientGroupCategory: any = [];
  /* End - For Fetching Clients */

  public allStaff = [];
  clientListDatasourceCopy: any = [];
  filteredExportItems: any;
  sortColumnValue: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private _fb: UntypedFormBuilder,
    public translate: TranslateService
  ) {
    this.clientGroupCategory = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Exports') },
        { label: this.translate.instant('Clients'), icon: 'ic-red' }
      ]);

      this.exportList = [
        {
          label:
            this.translate.instant('Clients') +
            ' / ' +
            this.translate.instant('client.client_contact'),
          value: 'Clients'
        },
        { label: this.translate.instant('invoices'), value: 'Invoices' },
        { label: this.translate.instant('client.payments'), value: 'Payments' },
        { label: this.translate.instant('time_and_expense_records'), value: 'OpenTime' },
        { label: this.translate.instant('Users'), value: 'Users' },
        { label: this.translate.instant('imports.Activity_Codes'), value: 'Activity' },
        { label: this.translate.instant('Expense_Codes'), value: 'Expense' },
        { label: this.translate.instant('Company_Groups'), value: 'Company' },
        { label: this.translate.instant('Client_Types'), value: 'ClientTypes' },
        { label: this.translate.instant('engagement'), value: 'Engagement' },
        { label: this.translate.instant('Appointments'), value: 'Appointments' }
      ];

      this.dateRangeListLimited = [
        { label: this.translate.instant('rpt.This_Quarter'), value: 'Quarter' },
        { label: this.translate.instant('reports.Todays_Date'), value: 'Today' },
        { label: this.translate.instant('This-Week'), value: 'Week' },
        { label: this.translate.instant('reports.This_Month'), value: 'Month' },
        { label: this.translate.instant('rpt.Last_Week'), value: 'lastweek' },
        { label: this.translate.instant('rpt.Last_Month'), value: 'lastmonth' },
        { label: this.translate.instant('rpt.Last_Quarter'), value: 'lastquarter' },
        { label: this.translate.instant('Custom'), value: 'custom' }
      ];

      this.dateRangeList = [
        { label: this.translate.instant('All_Dates'), value: null },
        { label: this.translate.instant('reports.Todays_Date'), value: 'Today' },
        { label: this.translate.instant('This-Week'), value: 'Week' },
        { label: this.translate.instant('reports.This_Month'), value: 'Month' },
        { label: this.translate.instant('rpt.This_Quarter'), value: 'Quarter' },
        { label: this.translate.instant('rpt.This_Year'), value: 'Year' },
        { label: this.translate.instant('rpt.Last_Week'), value: 'lastweek' },
        { label: this.translate.instant('rpt.Last_Month'), value: 'lastmonth' },
        { label: this.translate.instant('rpt.Last_Quarter'), value: 'lastquarter' },
        { label: this.translate.instant('rpt.Last_Year'), value: 'lastyear' },
        { label: this.translate.instant('Custom'), value: 'custom' }
      ];
    });

    this.searchTextStr = this.encrDecSrvc.getObject('exports_' + AppConstants.SearchString);

    this.companyId = parseInt(this.encrDecSrvc.getObject(AppConstants.companyID));

    this.allStaff = this.encrDecSrvc.getObject(AppConstants.staffList);
  }

  ngOnInit(): void {
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.initializeColumn();
    });
    this.initializeForm();
  }

  initializeForm() {
    this.myReportsForm = this._fb.group({
      DateFrom: [new Date('1901-01-01'), [<any>Validators.required]],
      DateTo: [new Date(), [<any>Validators.required]],
      DateRange: ['Quarter']
    });
  }

  /* Start - For Fetching Clients */
  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientListDatasource.length; i++) {
        const client = this.clientListDatasource[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  fetchClients() {
    if (
      this.clientListDatasource.length == 0
      // ||  this.clientListDatasource.length !== this.encrDecSrvc.clientList.length
    ) {
      const list = JSON.parse(JSON.stringify(this.encrDecSrvc.clientList));

      for (let i = 0; i < list.length; i++) {
        const item = list[i];

        item['Billable'] = ['Yes', true, 'true'].includes(item['Billable']) ? 'Yes' : 'No';
        item['Inactive'] = ['Yes', true, 'true'].includes(item['Inactive']) ? 'Yes' : 'No';
        item['ContactRecord'] = ['Yes', true, 'true'].includes(item['ContactRecord'])
          ? 'Yes'
          : 'No';

        if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
          item['GroupDescriptionIDArray'].filter(item => item != -1);

          item['GroupDescriptionIDArrayDesc'] = this.clientGroupCategory
            ?.filter(group =>
              item['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID'])
            )
            .map(group => group['GroupDescription'])
            .join(', ');
        } else {
          item['GroupDescriptionIDArrayDesc'] = null;
        }

        // item["ContactPerson"] = item['ContactsList'] ?? "";
        if (item['ContactsList']?.length > 0) {
          const arr = item['ContactsList'];
          arr.forEach(e => {
            const i = {...item, ContactPerson: e.ContactName, Office: e.Office, Mobile: e.Mobile, Email: e.Email };
            this.clientListDatasourceCopy.push(i);
          });
          // continue;
        } else {
          this.clientListDatasourceCopy.push(item);
        }
        this.clientListDatasource.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }
  /* End - For Fetching Clients */

  initializeColumn() {
    const selectedCols = [
      { field: 'Email', header: this.translate.instant('email') },
      {
        field: 'GroupDescriptionIDArrayDesc',
        header: this.translate.instant('group'),
        canSort: false,
        canFilter: true,
        classes: 'p-text-left'
      },
      { field: 'ContactPerson', header: this.translate.instant('client.client_contact') },
      { field: 'Office', header: this.translate.instant('phone') },
      { field: 'Mobile', header: this.translate.instant('user.mobile_phone') },
      { field: 'ClientNumber', header: this.translate.instant('exports.Client_no') },
      { field: 'BillingPartnerName', header: this.translate.instant('client.billing_partner_name') }
    ];

    this.dataCols = [
      ...selectedCols,
      { field: 'ClientID', header: this.translate.instant('exports.Sys_ID') },
      { field: 'StaffAssignedName', header: this.translate.instant('client.staff_assigned_name') },
      { field: 'BusCity', header: this.translate.instant('client.business_city') },
      { field: 'BusStreet1', header: this.translate.instant('client.business_street1') },
      { field: 'BusStreet2', header: this.translate.instant('client.business_street2') },
      { field: 'BusState', header: this.translate.instant('client.business_state') },
      { field: 'BusZip', header: this.translate.instant('client.business_zip') },
      {
        field: 'BillingGroupDescription',
        header: this.translate.instant('client.billing_group_description')
      },
      { field: 'Tele1', header: this.translate.instant('client.tele1') },
      { field: 'Tele1Descr', header: this.translate.instant('client.teldesc1') },
      { field: 'Tele2', header: this.translate.instant('client.tele2') },
      { field: 'Tele2Descr', header: this.translate.instant('client.teldesc2') },
      { field: 'Tele3', header: this.translate.instant('client.tele3') },
      { field: 'Tele3Descr', header: this.translate.instant('client.teldesc3') },
      {
        field: 'Inactive',
        header: this.translate.instant('Inactive'),
        canSort: false,
        canFilter: true,
        classes: 'p-text-left'
      },
      {
        field: 'ContactRecord',
        header: this.translate.instant('client.contact_record'),
        canSort: false,
        canFilter: true,
        classes: 'p-text-left'
      },
      { field: 'DateCreated', header: this.translate.instant('client.date_created') },
      { field: 'DateInactive', header: this.translate.instant('client.date_inactive') },
      { field: 'Home', header: this.translate.instant('Home_Telephone') },
      { field: 'OriginatingPartnerName', header: this.translate.instant('Originator') },
      { field: 'ClientNameInvoice', header: this.translate.instant('client.invoice_name') },
      { field: 'Fax', header: this.translate.instant('Fax-Number') },
      { field: 'Website', header: this.translate.instant('website') },
      { field: 'ReferredBy', header: this.translate.instant('referred_by') },
      { field: 'Billable', header: this.translate.instant('billable') }
    ];

    this._selectedColumns = selectedCols;
    this._selectedColumns.unshift({
      field: 'CustomerTypeDescription',
      header: this.translate.instant('Customer_Type'),
      canSort: true,
      canFilter: true,
      classes: 'p-text-left'
    });
    this._selectedColumns.unshift({
      field: 'ClientName',
      header: this.translate.instant('client.name'),
      canSort: true,
      canFilter: true,
      classes: 'p-text-left width-20p'
    });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.dataCols.filter(col => val.includes(col));
  }

  changeDateTypes() {
    this.IsDateTypeReadOnly = true;
    const obj = this.myReportsForm.controls['DateRange'].value;
    if (obj == 'Today') {
      this.myReportsForm.controls['DateFrom'].setValue(new Date());
      this.myReportsForm.controls['DateTo'].setValue(new Date());
    } else if (obj == 'Week') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf('isoWeek').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('isoWeek').format()));
    } else if (obj == 'lastweek') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf('isoWeek').subtract(1, 'week').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().endOf('isoWeek').subtract(1, 'week').format())
      );
    } else if (obj == 'Month') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf('month').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('month').format()));
    } else if (obj == 'lastmonth') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().subtract(1, 'months').startOf('month').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().subtract(1, 'months').endOf('month').format())
      );
    } else if (obj == 'Quarter') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().startOf('quarter').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('quarter').format()));
    } else if (obj == 'lastquarter') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().subtract(1, 'quarters').startOf('quarter').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().subtract(1, 'quarters').endOf('quarter').format())
      );
    } else if (obj == 'Year') {
      this.myReportsForm.controls['DateFrom'].setValue(new Date(moment().startOf('year').format()));
      this.myReportsForm.controls['DateTo'].setValue(new Date(moment().endOf('year').format()));
    } else if (obj == 'lastyear') {
      this.myReportsForm.controls['DateFrom'].setValue(
        new Date(moment().subtract(1, 'years').startOf('year').format())
      );
      this.myReportsForm.controls['DateTo'].setValue(
        new Date(moment().subtract(1, 'years').endOf('year').format())
      );
    } else if (obj == 'custom') {
      this.IsDateTypeReadOnly = false;
      this.myReportsForm.controls['DateFrom'].setValue(new Date());
      this.myReportsForm.controls['DateTo'].setValue(new Date());
    } else {
      this.myReportsForm.controls['DateFrom'].setValue(new Date('1901-01-01'));
      this.myReportsForm.controls['DateTo'].setValue(new Date('2099-12-30'));
    }
    this.loadDefaultExportAPI(this.selectedType);
  }

  onFilter(obj) {
    if (!this.searchValue?.nativeElement?.value && obj.filteredValue.length == 0) return;

    this.filteredExportItemsSize = obj.filteredValue.length;
    this.filteredExportItems = obj.filteredValue;

    this.encrDecSrvc.addObject('exports_' + AppConstants.SearchString, obj.filters?.global?.value);
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredExportItemsSize = -1;
    this.encrDecSrvc.addObject('exports_' + AppConstants.SearchString, '');
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  selectType(opt) {
    if (!this.exportList) return;
    this.fetchClients();

    const exportType = this.exportList.filter((type: any) => type.value === opt.value);
    this.breadcrumbService.setItems([
      { label: 'Exports' },
      { label: exportType[0].label, icon: 'ic-red' }
    ]);
    if (opt.value == 'OpenTime') {
      this.myReportsForm.controls['DateRange'].setValue('Quarter');
    }
    this.changeDateTypes();
    // this.loadDefaultExportAPI(this.selectedType);
    this.setGlobalFilters();
    this.setDataCols();
    if (
      opt.value != 'Invoices' ||
      opt.value != 'OpenTime' ||
      opt.value != 'Payments' ||
      opt.value != 'Appointments'
    ) {
      this.getData();
    }
  }

  setDataCols() {
    this._selectedColumns = [];
    switch (this.selectedType) {
      case 'Clients':
        this._selectedColumns = [
          {
            field: 'Email',
            header: this.translate.instant('Email'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'GroupDescriptionIDArrayDesc',
            header: this.translate.instant('group'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'ContactPerson',
            header: this.translate.instant('client.client_contact'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Office',
            header: this.translate.instant('phone'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Mobile',
            header: this.translate.instant('user.mobile_phone'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ClientNumber',
            header: this.translate.instant('exports.Client_no'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BillingPartnerName',
            header: this.translate.instant('client.billing_partner_name'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [
          ...this._selectedColumns,
          {
            field: 'ClientID',
            header: this.translate.instant('exports.Sys_ID'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'StaffAssignedName',
            header: this.translate.instant('client.staff_assigned_name'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BusCity',
            header: this.translate.instant('client.business_city'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BusStreet1',
            header: this.translate.instant('client.business_street1'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BusStreet2',
            header: this.translate.instant('client.business_street2'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BusState',
            header: this.translate.instant('client.business_state'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BusZip',
            header: this.translate.instant('client.business_zip'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BillingGroupDescription',
            header: this.translate.instant('client.billing_group_description'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tele1',
            header: this.translate.instant('client.tele1'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tele1Descr',
            header: this.translate.instant('client.teldesc1'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tele2',
            header: this.translate.instant('client.tele2'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tele2Descr',
            header: this.translate.instant('client.teldesc2'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tele3',
            header: this.translate.instant('client.tele3'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tele3Descr',
            header: this.translate.instant('client.teldesc3'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Home',
            header: this.translate.instant('Home_Telephone'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'OriginatingPartnerName',
            header: this.translate.instant('Originator'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ClientNameInvoice',
            header: this.translate.instant('client.invoice_name'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Fax',
            header: this.translate.instant('Fax-Number'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Website',
            header: this.translate.instant('website'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ReferredBy',
            header: this.translate.instant('referred_by'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Billable',
            header: this.translate.instant('billable'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('Inactive'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'ContactRecord',
            header: this.translate.instant('client.contact_record'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'DateCreated',
            header: this.translate.instant('client.date_created'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'DateInactive',
            header: this.translate.instant('client.date_inactive'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this._selectedColumns.unshift({
          field: 'CustomerTypeDescription',
          header: this.translate.instant('exports.Customer_Type_Description'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left'
        });
        this._selectedColumns.unshift({
          field: 'ClientName',
          header: this.translate.instant('client.name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'Invoices':
        this._selectedColumns = [
          {
            field: 'BillingHeaderID',
            header: this.translate.instant('Sys.ID'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'InvoiceNumber',
            header: this.translate.instant('Accounts.invoice-number'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'TotalPayments',
            header: this.translate.instant('total-payments'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'DescriptionShort',
            header: this.translate.instant('exports.Short_Description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-20p'
          },
          {
            field: 'TotalServices',
            header: this.translate.instant('Total-Services'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'TotalExpenses',
            header: this.translate.instant('total-expenses'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          }
        ];
        this.dataCols = [
          ...this._selectedColumns,
          {
            field: 'BillingPartner',
            header: this.translate.instant('Billing_Partner'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BillingGroupDescription',
            header: this.translate.instant('client.billing_group_description'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'StaffAssigned',
            header: this.translate.instant('client.staff_assigned_name'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'OriginatingPartner',
            header: this.translate.instant('Originating_Partner'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Discount',
            header: this.translate.instant('billing-invoicing.discount'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'TotalWUWD',
            header: this.translate.instant('exports.Total_WUWD'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'TotalStaffCost',
            header: this.translate.instant('exports.Total_Staff_Cost'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          }
        ];
        this._selectedColumns.unshift({
          field: 'InvoiceBalance',
          header: this.translate.instant('Accounts.invoice-balance'),
          canSort: false,
          canFilter: false,
          classes: 'p-text-right',
          isMoney: true
        });
        this._selectedColumns.unshift({
          field: 'InvoiceAmount',
          header: this.translate.instant('Accounts.invoice-amount'),
          canSort: false,
          canFilter: false,
          classes: 'p-text-right',
          isMoney: true
        });
        this._selectedColumns.unshift({
          field: 'InvoiceDate',
          header: this.translate.instant('Accounts.invoice-date'),
          canSort: true,
          canFilter: false,
          classes: 'p-text-left'
        });
        this._selectedColumns.unshift({
          field: 'ClientName',
          header: this.translate.instant('client.name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'OpenTime':
        this._selectedColumns = [
          {
            field: 'StaffName',
            header: this.translate.instant('user-title'),
            canSort: true,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'Memo',
            header: this.translate.instant('memo'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-15p'
          },
          {
            field: 'Description',
            header: this.translate.instant('description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-20p'
          },
          {
            field: 'GroupDescriptionIDArrayDesc',
            header: this.translate.instant('group'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'StaffCost',
            header: this.translate.instant('Staff_Cost'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'TotalTime',
            header: this.translate.instant('Total-Time'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'Billable',
            header: this.translate.instant('billable'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-center'
          }
        ];

        this.dataCols = [
          ...this._selectedColumns,
          {
            field: 'ElaspedTime',
            header: this.translate.instant('Elasped_Time'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'BillingRate',
            header: this.translate.instant('time.Billing-Rate'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'StandardAmount',
            header: this.translate.instant('Standard_Amount'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'WriteUpDown',
            header: this.translate.instant('Write_Up_Down'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'BilledAmount',
            header: this.translate.instant('billed-amount'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'Billed',
            header: this.translate.instant('billed'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-center width-5p',
            type: 'boolean'
          },
          {
            field: 'StaffAssigned',
            header: this.translate.instant('client.staff_assigned_name'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BillingPartner',
            header: this.translate.instant('Billing_Partner'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'SlipMasterID',
            header: this.translate.instant('exports.Sys_ID'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'OriginatingPartner',
            header: this.translate.instant('Originator'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'GroupDescription',
            header: this.translate.instant('group-description'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'EngagementName',
            header: this.translate.instant('engagement.name'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ExpenseCode',
            header: this.translate.instant('expense-code'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Units',
            header: this.translate.instant('Units'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'Cost',
            header: this.translate.instant('Cost'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'Tax',
            header: this.translate.instant('tax'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'IsTimeRecord',
            header: this.translate.instant('Is_Time_Record'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'WorkLocation',
            header: this.translate.instant('Work-Location'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ServiceCode',
            header: this.translate.instant('Service_Code'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];

        this._selectedColumns.unshift({
          field: 'Ddate',
          header: this.translate.instant('date'),
          canSort: true,
          canFilter: false,
          classes: 'p-text-left'
        });
        this._selectedColumns.unshift({
          field: 'ClientName',
          header: this.translate.instant('client.name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });

        break;
      case 'Users':
        this._selectedColumns = [
          {
            field: 'DefaultBillingRate',
            header: this.translate.instant('default_billing_rate'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'DateHired',
            header: this.translate.instant('Date_Hired'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'RightsLevel',
            header: this.translate.instant('Rights_Level'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Permission',
            header: this.translate.instant('Permission'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-right'
          },
          {
            field: 'TeleHome',
            header: this.translate.instant('Home_Telephone'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'TeleMobile',
            header: this.translate.instant('user.mobile_phone'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [
          ...this._selectedColumns,
          {
            field: 'Email',
            header: this.translate.instant('Email'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Street1',
            header: this.translate.instant('Street1'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Street2',
            header: this.translate.instant('Street2'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'City',
            header: this.translate.instant('client.city'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'State',
            header: this.translate.instant('client.state'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Zip',
            header: this.translate.instant('Zip_Code'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('inactive'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];

        this._selectedColumns.unshift({
          field: 'StaffName',
          header: this.translate.instant('Staff_Name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });

        break;
      case 'Activity':
        this._selectedColumns = [
          {
            field: 'Description',
            header: this.translate.instant('description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-20p'
          },
          {
            field: 'Billable',
            header: this.translate.instant('billable'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BillRate',
            header: this.translate.instant('Bill-Rate'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'Tax1',
            header: this.translate.instant('Tax_1'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tax2',
            header: this.translate.instant('Tax_2'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Tax3',
            header: this.translate.instant('Tax_3'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [
          ...this._selectedColumns,
          {
            field: 'ServiceCodeID',
            header: this.translate.instant('Service_Code_ID'),
            canSort: true,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('inactive'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];

        this._selectedColumns.unshift({
          field: 'ServiceCode',
          header: this.translate.instant('Service_Code'),
          canSort: false,
          canFilter: false,
          classes: 'p-text-left width-20p'
        });

        break;
      case 'Expense':
        this._selectedColumns = [
          {
            field: 'Description',
            header: this.translate.instant('description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-20p'
          },
          {
            field: 'Cost',
            header: this.translate.instant('Cost'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'Markup',
            header: this.translate.instant('mark_up'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-right'
          },
          {
            field: 'Billable',
            header: this.translate.instant('billable'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'TaxMap1',
            header: this.translate.instant('TaxMap1'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'TaxMap2',
            header: this.translate.instant('TaxMap2'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'TaxMap3',
            header: this.translate.instant('TaxMap3'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [
          ...this._selectedColumns,
          {
            field: 'ExpenseCodeID',
            header: this.translate.instant('Expense_Code_ID'),
            canSort: true,
            canFilter: true,
            classes: 'p-text-left'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('inactive'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];

        this._selectedColumns.unshift({
          field: 'ExpenseCode',
          header: this.translate.instant('expense-code'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });

        break;
      case 'Company':
        this._selectedColumns = [
          {
            field: 'GroupDescription',
            header: this.translate.instant('group-description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-80p'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('inactive'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [...this._selectedColumns];
        break;
      case 'ClientTypes':
        this._selectedColumns = [
          {
            field: 'CustomerTypeDescription',
            header: this.translate.instant('exports.Customer_Type_Description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-80p'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('inactive'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [...this._selectedColumns];
        break;
      case 'Engagement':
        this._selectedColumns = [
          {
            field: 'Description',
            header: this.translate.instant('description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-40p'
          },
          {
            field: 'Inactive',
            header: this.translate.instant('inactive'),
            canSort: false,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'InvoiceDescription',
            header: this.translate.instant('billing-invoicing.invoice-description'),
            canSort: false,
            canFilter: true,
            classes: 'p-text-left width-40p'
          }
        ];
        this.dataCols = [...this._selectedColumns];
        break;
      case 'Payments':
        this._selectedColumns = [
          {
            field: 'ClientName',
            header: this.translate.instant('client.name'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'PaymentDate',
            header: this.translate.instant('Accounts.payment-date'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'PaymentAmount',
            header: this.translate.instant('Accounts.payment-amount'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-right',
            isMoney: true
          },
          {
            field: 'CheckRef',
            header: this.translate.instant('Accounts.check-reference'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'PaymentType',
            header: this.translate.instant('Accounts.payment-type'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ClientType',
            header: this.translate.instant('client.type'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'ReferredBy',
            header: this.translate.instant('referred_by'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'BillingPartner',
            header: this.translate.instant('Billing_Partner'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Manager',
            header: this.translate.instant('manager'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'OriginatingPartner',
            header: this.translate.instant('Originating_Partner'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'GroupDescription',
            header: this.translate.instant('group-description'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [...this._selectedColumns];
        break;
      case 'Appointments':
        this._selectedColumns = [
          {
            field: 'ClientName',
            header: this.translate.instant('client.name'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'StartDate',
            header: this.translate.instant('start_date'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'EndDate',
            header: this.translate.instant('end_date'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'AllDay',
            header: this.translate.instant('All_Day'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Subject',
            header: this.translate.instant('Subject'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Location',
            header: this.translate.instant('location'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Description',
            header: this.translate.instant('description'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'AttendeesName',
            header: this.translate.instant('attendees'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'RemindBeforeHand',
            header: this.translate.instant('remind_before_start'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          },
          {
            field: 'Rules',
            header: this.translate.instant('Rules'),
            canSort: true,
            canFilter: false,
            classes: 'p-text-left'
          }
        ];
        this.dataCols = [...this._selectedColumns];
        break;
      default:
        break;
    }
  }

  setGlobalFilters() {
    switch (this.selectedType) {
      case 'Clients':
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'Mobile',
          'Office',
          'ContactPerson',
          'ClientID',
          'ClientName',
          'CustomerTypeDescription',
          'GroupDescriptionIDArrayDesc',
          'ClientNumber',
          'Email',
          'BillingPartnerName',
          'StaffAssignedName',
          'BusCity',
          'BusStreet1',
          'BusStreet2',
          'BusState',
          'BusZip',
          'BillingGroupDescription',
          'Tele1',
          'Tele1Descr',
          'Tele2',
          'Tele2Descr',
          'Tele3',
          'Tele3Descr',
          'Inactive',
          'ContactRecord',
          'DateCreated',
          'DateInactive',
          'Home',
          'OriginatingPartnerName',
          'ClientNameInvoice',
          'Fax',
          'Website',
          'ReferredBy',
          'Billable'
        ];
        break;
      case 'Invoices':
        this.initializeForm();
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'BillingHeaderID',
          'ClientName',
          'InvoiceDate',
          'InvoiceAmount',
          'InvoiceBalance',
          'TotalPayments',
          'DescriptionShort',
          'BillingGroupDescription',
          'BillingPartner',
          'OriginatingPartner',
          'StaffAssigned',
          'TotalServices',
          'TotalExpenses',
          'Discount',
          'TotalWUWD',
          'TotalStaffCost'
        ];
        break;
      case 'Payments':
        this.initializeForm();
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'ClientName',
          'PaymentDate',
          'PaymentAmount',
          'CheckRef',
          'PaymentType',
          'ClientType',
          'ReferredBy',
          'BillingPartner',
          'Manager',
          'OriginatingPartner',
          'GroupDescription'
        ];
        break;
      case 'OpenTime':
        this.initializeForm();
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'ClientName',
          'ServiceCode',
          'StaffID',
          'Ddate',
          'Memo',
          'Description',
          'StaffCost',
          'TotalTime',
          'ElaspedTime',
          'BillingRate',
          'StandardAmount',
          'WriteUpDown',
          'BilledAmount',
          'Billed',
          'BillingPartnerID',
          'OriginatingPartnerID',
          'GroupDescriptionIDArrayDesc',
          'GroupDescriptionID',
          'EngagementName',
          'ExpenseCode',
          'Units',
          'Cost',
          'Tax',
          'IsTimeRecord',
          'WorkLocation',
          'SlipMasterID'
        ];
        break;
      case 'Users':
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'StaffName',
          'Inactive',
          'DefaultBillingRate',
          'DateHired',
          'RightsLevel',
          'Permission',
          'Street1',
          'Street2',
          'City',
          'State',
          'Zip',
          'TeleHome',
          'TeleMobile',
          'Email'
        ];
        break;
      case 'Activity':
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'ServiceCode',
          'Description',
          'Inactive',
          'Tax1',
          'Tax2',
          'Tax3',
          'Billable',
          'BillRate'
        ];
        break;
      case 'Expense':
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'ExpenseCode',
          'Description',
          'Inactive',
          'Cost',
          'Markup',
          'Billable',
          'TaxMap1',
          'TaxMap2',
          'TaxMap3'
        ];
        break;
      case 'Company':
        this.showColumnSelector = false;
        this.globalFilterColumns = ['GroupDescription', 'Inactive'];
        break;
      case 'ClientTypes':
        this.showColumnSelector = false;
        this.globalFilterColumns = ['CustomerTypeDescription', 'Inactive'];
        break;
      case 'Engagement':
        this.showColumnSelector = false;
        this.globalFilterColumns = ['Description', 'Inactive', 'InvoiceDescription'];
        break;
      case 'Payments':
        this.showColumnSelector = true;
        this.globalFilterColumns = [
          'ClientName',
          'PaymentDate',
          'PaymentAmount',
          'CheckRef',
          'PaymentType',
          'ClientType',
          'BillingPartner',
          'Manager',
          'OriginatingPartner',
          'GroupDescription'
        ];
        break;
      default:
        break;
    }

    this.globalFilterColumns = this.globalFilterColumns.filter(x => {
      return this._selectedColumns.find(y => y.field == x);
    });
  }

  filterClientDataSource() {
    if (this._selectedColumns.find(x => x.field == 'ContactPerson')) {
      return this.clientListDatasourceCopy;
    }

    return this.clientListDatasource;
  }

  getData() {
    switch (this.selectedType) {
      case 'Clients':
        this.filteredDataSource = this.filterClientDataSource(); // clientDataSource
        break;
      case 'Invoices':
        this.filteredDataSource = this.invoicesDataSource;
        break;
      case 'OpenTime':
        this.filteredDataSource = this.openTimeDataSource;
        break;
      case 'Users':
        this.filteredDataSource = this.usersDataSource;
        break;
      case 'Activity':
        this.filteredDataSource = this.activitiesDataSource;
        break;
      case 'Expense':
        this.filteredDataSource = this.expensesDataSource;
        break;
      case 'Company':
        this.filteredDataSource = this.groupDataSource;
        break;
      case 'ClientTypes':
        this.filteredDataSource = this.customersDataSource;
        break;
      case 'Engagement':
        this.filteredDataSource = this.engagementsDataSource;
        break;
      case 'Payments':
        this.filteredDataSource = this.paymentsDataSource;
        break;
      case 'Appointments':
        this.filteredDataSource = this.appointmentsDataSource;
      default:
        break;
    }
  }

  onChangeSelectedColumns(e) {
    switch (this.selectedType) {
      case 'Clients':
        if (e?.itemValue?.field == 'ContactPerson') this.getData();
        this._selectedColumns.unshift({
          field: 'CustomerTypeDescription',
          header: this.translate.instant('exports.Customer_Type_Description'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left'
        });
        this._selectedColumns.unshift({
          field: 'ClientName',
          header: this.translate.instant('client.name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'Invoices':
        this._selectedColumns.unshift({
          field: 'InvoiceBalance',
          header: this.translate.instant('Accounts.invoice-balance'),
          canSort: false,
          canFilter: false,
          classes: 'p-text-right',
          isMoney: true
        });
        this._selectedColumns.unshift({
          field: 'InvoiceAmount',
          header: this.translate.instant('Accounts.invoice-amount'),
          canSort: false,
          canFilter: false,
          classes: 'p-text-right',
          isMoney: true
        });
        this._selectedColumns.unshift({
          field: 'InvoiceDate',
          header: this.translate.instant('Accounts.invoice-date'),
          canSort: true,
          canFilter: false,
          classes: 'p-text-left'
        });
        this._selectedColumns.unshift({
          field: 'ClientName',
          header: this.translate.instant('client.name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        this._selectedColumns.unshift({
          field: 'BillingHeaderID',
          header: this.translate.instant('Sys.ID'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'OpenTime':
        this._selectedColumns.unshift({
          field: 'Ddate',
          header: this.translate.instant('date'),
          canSort: true,
          canFilter: false,
          classes: 'p-text-left'
        });
        this._selectedColumns.unshift({
          field: 'ClientName',
          header: this.translate.instant('client.name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'Users':
        this._selectedColumns.unshift({
          field: 'StaffName',
          header: this.translate.instant('Staff_Name'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'Activity':
        this._selectedColumns.unshift({
          field: 'ServiceCode',
          header: this.translate.instant('Service_Code'),
          canSort: false,
          canFilter: false,
          classes: 'p-text-left width-20p'
        });
        break;
      case 'Expense':
        this._selectedColumns.unshift({
          field: 'ExpenseCode',
          header: this.translate.instant('expense-code'),
          canSort: true,
          canFilter: true,
          classes: 'p-text-left width-20p'
        });
        break;
      default:
        break;
    }

    this.setGlobalFilters();
  }

  exportExcel() {
    const selectedColFields = this.selectedColumns.map(col => col.header);
    const body = this.generateDataForExport();
    const worksheet = xlsx.utils.json_to_sheet(body);
    const range = xlsx.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.r; C <= range.e.r; ++C) {
      const address = xlsx.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = selectedColFields[C]; // Change column headers to selectedColFields's header value
    }
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, `${this.selectedType}`);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportPdf() {
    const doc: any = new jsPDF('l', 'pt');

    let body = [];

    if (this.filteredExportItems.length == 0) body = this.filteredDataSource;
    else body = this.filteredExportItems;

    body = body.map(x => {
      x.DateCreated = x.DateCreated ? new Date(x.DateCreated).toLocaleDateString('en-US') : null;
      x.DateInactive = x.DateInactive ? new Date(x.DateInactive).toLocaleDateString('en-US') : null;
      return x;
    });
    //sort columns
    body = this.sortColumns(body);

    //filter columns
    const columns = this.selectedColumns.map(col => {
      return { title: col.header, dataKey: col.field };
    });
    doc.autoTable(columns, body);

    doc.save(`${this.selectedType}_export_${new Date().getTime()}.pdf`);
  }

  onSort(e) {
    this.sortColumnValue = e;
  }

  sortColumns(rows) {
    if (!this.sortColumnValue) return;
    const field = this.sortColumnValue.field;
    const order = this.sortColumnValue.order;

    const newRows = rows.sort(function(a, b) { 
      if (order == 1) return a[field] - b[field];
      else if (order == -1) return b[field] - a[field];
    });

    return newRows;
  }

  generateDataForExport() {
    /* This function will filter the filteredDataSource with values of headers
     ** that are only required based on the selectedColumns
     */

    const selectedColFields = this.selectedColumns.map(col => col.field);
    const filteredArray = [];

    this.filteredDataSource.map(obj => {
      const filteredObj = {};
      selectedColFields.map(col => {
        if (Object.keys(obj).includes(col)) {
          filteredObj[col] = obj[col];
        }
      });
      filteredArray.push(filteredObj);
    });
    return filteredArray;
  }

  loadDefaultExportAPI(type?) {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    const startDate = moment(this.myReportsForm.controls['DateFrom'].value).format("YYYY-MM-DD");
    const endDate = moment(this.myReportsForm.controls['DateTo'].value).format("YYYY-MM-DD");
    if (type === 'Invoices') {
      this.mangoAPISrvc.showLoader(true);

      this.mangoAPISrvc.getInvoiceDataFromDate(startDate, endDate).subscribe(
        (data: any) => {
          this.invoicesDataSource = data.map((data: any) => {
            return {
              ...data,
              ClientName: this.getClientName(data.ClientID)
            };
          });

          this.getData();

          this.mangoAPISrvc.showLoader(false);
        },

        err => this.mangoAPISrvc.showLoader(false)
      );
    } else if (type === 'OpenTime') {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getOpenTimeRecordsFromDate(startDate, endDate).subscribe(
        (data: any) => {
          this.openTimeDataSource = data.map((data: any) => {
            if (data['GroupDescriptionIDArray'] && data['GroupDescriptionIDArray'].length > 0) {
              data['GroupDescriptionIDArray'].filter(item => item != -1);
              data['GroupDescriptionIDArrayDesc'] = _this.clientGroupCategory
                ?.filter(group =>
                  data['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID'])
                )
                .map(group => group['GroupDescription'])
                .join(', ');
            } else {
              data['GroupDescriptionIDArrayDesc'] = null;
            }
            return { ...data, ClientName: this.getClientName(data.ClientID) };
          });
          this.getData();
          this.mangoAPISrvc.showLoader(false);
        },
        err => this.mangoAPISrvc.showLoader(false)
      );
    } else if (type === 'Payments') {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getPaymentsFromDate(startDate, endDate, this.companyId).subscribe(
        (data: any) => {
          this.paymentsDataSource = data;
          this.getData();
          this.mangoAPISrvc.showLoader(false);
        },
        err => this.mangoAPISrvc.showLoader(false)
      );
    } else if (type === 'Appointments') {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getScheduleFromDate(startDate, endDate, this.companyId).subscribe(
        (data: any) => {
          let appointmentData = data.map(itm => {
            let staffName = [];
            for (let i = 0; i < itm.attendees.length; i++) {
              let filterStaff = this.allStaff.filter(stf => stf.StaffID === itm.attendees[i]);
              if (filterStaff.length > 0) {
                staffName.push(filterStaff[0].StaffName);
              }
            }

            itm['AttendeesName'] = staffName.toString();
            itm['StartDate'] = moment(moment(itm['StartDate']), 'DD/MM/YYYY hh:mm:ss').format(
              'MM/DD/YYYY hh:mm'
            );
            itm['EndDate'] = moment(moment(itm['EndDate']), 'DD/MM/YYYY hh:mm:ss').format(
              'MM/DD/YYYY hh:mm'
            );
            let rule = '';
            if (itm.recurrenceRule !== '' && itm.recurrenceRule != null) {
              let insRule = String(itm.recurrenceRule).toString();
              insRule = insRule.replace(/;\s*$/, '');
              rule = rrulestr(insRule).toText().toUpperCase();
            }
            itm['Rules'] = rule;
            return itm;
          });
          this.appointmentsDataSource = appointmentData;
          this.getData();
          this.mangoAPISrvc.showLoader(false);
        },
        err => this.mangoAPISrvc.showLoader(false)
      );
    } else {
      _this.mangoAPISrvc
        .loadDefaultExportAPI(_this.companyId, startDate, endDate)
        .subscribe((data: any) => {
          _this.invoicesDataSource = data[0].map((data: any) => {
            return { ...data, ClientName: this.getClientName(data.ClientID) };
          });
          _this.openTimeDataSource = data[1].map((data: any) => {
            if (data['GroupDescriptionIDArray'] && data['GroupDescriptionIDArray'].length > 0) {
              data['GroupDescriptionIDArray'].filter(item => item != -1);
              data['GroupDescriptionIDArrayDesc'] = _this.clientGroupCategory
                ?.filter(group =>
                  data['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID'])
                )
                .map(group => group['GroupDescription'])
                .join(', ');
            } else {
              data['GroupDescriptionIDArrayDesc'] = null;
            }
            return { ...data, ClientName: this.getClientName(data.ClientID) };
          });
          _this.usersDataSource = data[2];
          _this.activitiesDataSource = data[3];
          _this.expensesDataSource = data[4];
          _this.groupDataSource = data[5];
          _this.customersDataSource = data[6];
          _this.engagementsDataSource = data[7];
          _this.paymentsDataSource = data[8];

          let appointmentData = data[9].map(itm => {
            let staffName = [];
            for (let i = 0; i < itm.attendees.length; i++) {
              let filterStaff = this.allStaff.filter(stf => stf.StaffID === itm.attendees[i]);
              if (filterStaff.length > 0) {
                staffName.push(filterStaff[0].StaffName);
              }
            }

            itm['AttendeesName'] = staffName.toString();
            itm['StartDate'] = moment(moment(itm['StartDate']), 'DD/MM/YYYY hh:mm:ss').format(
              'MM/DD/YYYY hh:mm'
            );
            itm['EndDate'] = moment(moment(itm['EndDate']), 'DD/MM/YYYY hh:mm:ss').format(
              'MM/DD/YYYY hh:mm'
            );
            let rule = '';
            if (itm.recurrenceRule !== '' && itm.recurrenceRule != null) {
              let insRule = String(itm.recurrenceRule).toString();
              insRule = insRule.replace(/;\s*$/, '');
              rule = rrulestr(insRule).toText().toUpperCase();
            }
            itm['Rules'] = rule;
            return itm;
          });
          _this.appointmentsDataSource = appointmentData;
          this.getData();
          _this.mangoAPISrvc.showLoader(false);
        });
    }
  }

  checkRowData(header, data) {
    // if(header === 'ClientID') {
    //   return this.getClientName(data)
    if (header.toLowerCase().includes('date')) {
      return this.getDisplayDate(data);
    } else {
      return data;
    }
  }

  getClientName(id) {
    if (
      !this.clientListDatasource ||
      !this.clientListDatasource.filter(client => client.ClientID === id)[0]
    )
      return '';

    return this.clientListDatasource.filter(client => client.ClientID === id)[0].ClientName;
  }

  getDisplayDate(text) {
    const date = moment(text);
    if (!text && !date.isValid())
      return text;

    // return moment(text).format("MM/DD/YYYY")
    return moment(text?.substr(0, 10)).format('MM/DD/YYYY');
  }

  onRefresh() {
    const parent = this;
    const startDate = moment(this.myReportsForm.controls['DateFrom'].value).format("MM-DD-YYYY")
    const endDate = moment(this.myReportsForm.controls['DateTo'].value).format("MM-DD-YYYY")
    if (parent.selectedType === 'Invoices') {
      parent.mangoAPISrvc.showLoader(true);

      parent.mangoAPISrvc.getInvoiceDataFromDate(startDate, endDate).subscribe(
        (data: any) => {
          parent.invoicesDataSource = data.map((data: any) => {
            return {
              ...data,
              ClientName: this.getClientName(data.ClientID)
            };
          });

          parent.getData();

          parent.mangoAPISrvc.showLoader(false);
        },

        err => parent.mangoAPISrvc.showLoader(false)
      );
    } else if (parent.selectedType === 'OpenTime') {
      if (
        parent.myReportsForm.controls['DateRange'].value === 'custom' &&
        moment(endDate).diff(moment(startDate), 'months') > 2
      ) {
        Swal.fire({
          icon: 'warning',
          title: `${parent.translate.instant('Warning')}`,
          text: `${parent.translate.instant('exports.three_months_gap')}`,
          confirmButtonText: 'OK',
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true
        });
        return;
      }
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getOpenTimeRecordsFromDate(startDate, endDate).subscribe(
        (data: any) => {
          parent.openTimeDataSource = data.map((data: any) => {
            return { ...data, ClientName: this.getClientName(data.ClientID) };
          });
          parent.getData();
          parent.mangoAPISrvc.showLoader(false);
        },
        err => parent.mangoAPISrvc.showLoader(false)
      );
    } else if (parent.selectedType === 'Payments') {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getPaymentsFromDate(startDate, endDate, this.companyId).subscribe(
        (data: any) => {
          parent.paymentsDataSource = data;
          parent.getData();
          parent.mangoAPISrvc.showLoader(false);
        },
        err => parent.mangoAPISrvc.showLoader(false)
      );
    } else if (parent.selectedType === 'Appointments') {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc
        .getScheduleFromDate(startDate, endDate, this.companyId)
        .subscribe((data: any) => {
          let appointmentData = data.map(itm => {
            let staffName = [];
            for (let i = 0; i < itm.attendees.length; i++) {
              let filterStaff = this.allStaff.filter(stf => stf.StaffID === itm.attendees[i]);
              if (filterStaff.length > 0) {
                staffName.push(filterStaff[0].StaffName);
              }
            }

            itm['AttendeesName'] = staffName.toString();
            itm['StartDate'] = moment(moment(itm['StartDate']), 'DD/MM/YYYY hh:mm:ss').format(
              'MM/DD/YYYY hh:mm'
            );
            itm['EndDate'] = moment(moment(itm['EndDate']), 'DD/MM/YYYY hh:mm:ss').format(
              'MM/DD/YYYY hh:mm'
            );
            let rule = '';
            if (itm.recurrenceRule !== '' && itm.recurrenceRule != null) {
              let insRule = String(itm.recurrenceRule).toString();
              insRule = insRule.replace(/;\s*$/, '');
              rule = rrulestr(insRule).toText().toUpperCase();
            }
            itm['Rules'] = rule;
            return itm;
          });
          parent.appointmentsDataSource = appointmentData;
          parent.getData();
          parent.mangoAPISrvc.showLoader(false);
        });
    }
  }

  handleSelectDates() {
    this.myReportsForm.controls['DateRange'].setValue('custom');
  }
}
