import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { AppMainComponent } from '@app/app.main.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html'
})
export class OthersComponent implements OnInit {
  public isUsedLastStartTime: boolean = false;
  public isNotifyTodo: boolean = false;
  staffID: any;
  companyID: any;
  loadAfterLogin: any;
  dateRangeList: any;
  staffpermission: any;
  LoadScreen: any;
  DefaultDateOption: any;
  isLockMenu: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public app: AppMainComponent,
    private translate: TranslateService
  ) {
    this.breadcrumbService.setItems([{ label: 'My Profile' }, { label: 'Other' }]);

    this.dateRangeList = [
      { label: this.translate.instant('Last 30 days'), value: null },
      { label: this.translate.instant('reports.Todays_Date'), value: 'Today' },
      { label: this.translate.instant('This-Week'), value: 'Week' },
      { label: this.translate.instant('reports.This_Month'), value: 'Month' },
      { label: this.translate.instant('rpt.Last_Week'), value: 'lastweek' },
      { label: this.translate.instant('rpt.Last_Month'), value: 'lastmonth' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];
  }

  ngOnInit(): void {
    const permissionObj = this.encrDecSrvc.getObject(AppConstants.userPermissionObj);
    const subLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel)
    this.staffpermission = permissionObj.Permission ? parseInt(permissionObj.Permission) : 0;
    if (this.staffpermission == 4) {
      this.loadAfterLogin = [
        { label: 'Dashboard', value: 'DB' },
        { label: 'Schedule', value: 'SCH' },
        { label: 'Due Date Management', value: 'DDM' },
        { label: 'Time Sheet Entry', value: 'TS' }
      ];
    } else {
      this.loadAfterLogin = [
        { label: 'Dashboard', value: 'DB' },
        { label: 'Client List', value: 'CL' },
        { label: 'Schedule', value: 'SCH' },
        { label: 'Due Date Management', value: 'DDM' },
        { label: 'Time Sheet Entry', value: 'TS' }
      ];
    }
    this.loadAfterLogin.unshift({ label: 'Select Load Screen', value: null });

    if (subLevel == 'SOLO') {
      this.loadAfterLogin = this.loadAfterLogin.filter(item => item.value != 'DDM');
    }

    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getStaffOtherSettings().subscribe(
      (result: any) => {
        this.isUsedLastStartTime = result.isUsedLastStartTime;
        this.isNotifyTodo = result.isNotifyTodo || result.isNotifyTodo === null;
        this.isLockMenu = result.isLockMenu;
        this.LoadScreen = result.StartScreen;
        this.DefaultDateOption = result.DefaultDateOption;
        this.mangoAPISrvc.showLoader(false);
        // this.mangoAPISrvc.getCompanyMangoDetails(this.companyID).subscribe((data) => {
        //   this.LoadScreen = data[0]['LoadScreen'] ? data[0]['LoadScreen'] : 'DB';
        //   this.mangoAPISrvc.showLoader(false);
        // }, error => {
        //   this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        //   this.mangoAPISrvc.showLoader(false);
        // })
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      }
    );
  }

  onChangeSettings(event, isCompany: boolean) {
    if (isCompany) {
      const obj = {
        LoadScreen: this.LoadScreen
      };
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateCompanyMangoDetails(obj).subscribe(
        data => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        }
      );
    } else {
      const obj = {
        isUsedLastStartTime: this.isUsedLastStartTime,
        isNotifyTodo: this.isNotifyTodo,
        isLockMenu: this.isLockMenu,
        StartScreen: this.LoadScreen,
        DefaultDateOption: this.DefaultDateOption
      };
      localStorage.setItem('isLockMenu', obj['isLockMenu']);
      this.app.setLockMenu();

      const staffData = this.encrDecSrvc.getObject(AppConstants.staffData);

      if (staffData?.DefaultDateOption) {
        this.encrDecSrvc.addObject(AppConstants.staffData, { ...staffData, ...obj });
      }

      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateMSLoginSettings(obj, this.staffID).subscribe(
        result => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        }
      );
    }
  }
}
