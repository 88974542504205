<p-sidebar
  [(visible)]="isPreviewVisible"
  [closeOnEscape]="false"
  [dismissible]="false"
  [showCloseIcon]="false"
  [fullScreen]="true"
  [style]="{ 'background': '#8e8f92'}">
  <div class="card">
    <div class="pull-right p-pr-4">
      <button
        type="button"
        pButton
        pRipple
        icon="fal fa-times p-mr-2"
        label="{{ 'close' | translate }}"
        (click)="closeDialog()"></button>
    </div>
    <div>
      <p-tabView>
        <p-tabPanel
          *ngIf="dialogTab1Display"
          header="{{'Narrative-Invoices' | translate}}"
          [selected]="dialogTab1Display">
          <div>
            <!--<h1>{{pdfUrl}}</h1>-->
            <ngx-extended-pdf-viewer
              [src]="pdfUrl"
              *ngIf="dialogTab1Display"
              id="ClientInvoices1"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125"></ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>

        <p-tabPanel
          *ngIf="dialogTab2Display"
          header="{{'Detail-Invoices' | translate}}"
          [selected]="dialogTab2Display">
          <div>
            <!--<h1>{{pdfUrl}}</h1>-->
            <ngx-extended-pdf-viewer
              [src]="pdfUrl"
              *ngIf="dialogTab2Display"
              id="ClientInvoices2"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125"></ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>

        <p-tabPanel
          *ngIf="dialogTab3Display"
          header="{{'Simple-Narrative-Invoices' | translate}}"
          [selected]="dialogTab3Display">
          <div>
            <!--<h1>{{pdfUrl}}</h1>-->
            <ngx-extended-pdf-viewer
              [src]="pdfUrl"
              *ngIf="dialogTab3Display"
              id="ClientInvoices3"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125"></ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>

        <p-tabPanel
          *ngIf="dialogTab4Display"
          header="{{'Narrative-Summary-Invoices' | translate}}"
          [selected]="dialogTab4Display">
          <div>
            <!--<h1>{{pdfUrl}}</h1>-->
            <ngx-extended-pdf-viewer
              [src]="pdfUrl"
              *ngIf="dialogTab4Display"
              id="ClientInvoices4"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125"></ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>

        <p-tabPanel
          *ngIf="dialogTab5Display"
          header="{{'SimpleNoRemit' | translate}}"
          [selected]="dialogTab5Display">
          <div>
            <!--<h1>{{pdfUrl}}</h1>-->
            <ngx-extended-pdf-viewer
              [src]="pdfUrl"
              *ngIf="dialogTab5Display"
              id="ClientInvoices5"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125"></ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</p-sidebar>

<div class="manualinvoice">
  <div class="layout-content p-pt-0 common-dialog">
    <form autocomplete="nope" role="form" [formGroup]="manualInvoiceForm" novalidate>
      <!-- buttons -->
      <span class="pull-right p-mr-3 clientform-btn-top">
        <!-- <button pButton pRipple icon="fal fa-play" class="p-button-sm p-pr-3 p-mr-2" (click)="isShowVideo = true"></button>         -->
        <demo-video videoCode="2u8tunn0t0"></demo-video>
        <button
          pButton
          pRipple
          label="{{'save_button' | translate}}"
          icon="fal fa-check"
          class="p-button-sm p-pr-3 p-mr-2"
          [disabled]="!isFormValid"
          (click)="finalAndReviewAction(false)"></button>
        <button
          pButton
          pRipple
          label="{{'clear' | translate}}"
          icon="fad fa-eraser"
          class="p-button-sm p-pr-3 p-mr-2"
          tooltipPosition="top"
          pTooltip="{{ 'clear' | translate }}"
          [disabled]="IsEnableHistory"
          (click)="clearData()"
          [disabled]="isPaymentFlowRequired"></button>
        <button
          pButton
          pRipple
          label="{{'billing-invoicing.review-invoices' | translate}}"
          icon="fal fa-file-invoice-dollar"
          class="p-button-sm p-pr-3 p-mr-2"
          [disabled]="isPaymentFlowRequired"
          routerLink="/billing-invoicing/invoiceReview"></button>
        <button
          pButton
          pRipple
          label="{{'preview' | translate}}"
          icon="fal fa-print"
          class="p-button-sm p-px-2 p-mr-2"
          (click)="previewInvoice();"
          [disabled]="!isClientSelected || !isFormValid"></button>
        <button
          pButton
          pRipple
          label="{{'billing-invoicing.invoice-history' | translate}}"
          icon="fal fa-history"
          (click)="historyData();"
          class="p-button-sm p-pr-3 p-mr-2"
          Tooltip="{{'billing-invoicing.invoice-history' | translate}}"
          [disabled]="IsEnableHistory"></button>
        <button
          pButton
          pRipple
          label="{{'billing-invoicing.process-payment' | translate}}"
          icon="fal fa-credit-card"
          class="p-button-sm p-pr-3 p-mr-2"
          [disabled]="(grandInvAmt == 0 && grandExpenseAmt == 0) || transactionData"
          (click)="processData()"></button>
      </span>
      <div class="p-fluid p-formgrid p-grid p-mx-2">
        <div class="p-col-4" style="padding-right: 0">
          <div class="card manual-invoice-mh p-pb-2">
            <div class="p-field p-col-12">
              <label class="required-field label-text p-col-12">
                <span class="pull-left">{{'client.name' | translate}}</span>
                <span class="pull-right">
                  <i
                    *ngIf="clientRouteUrl !== ''"
                    class="fal fa-external-link ic-red cursor-hand p-pt-1"
                    tooltipPosition="top"
                    pTooltip="{{'Go-to-Client' | translate}}"
                    pRipple
                    (click)="loadClientView()"></i>
                </span>
              </label>
              <p-autoComplete
                #clientAutocomplete
                [(ngModel)]="selClient"
                [ngModelOptions]="{standalone: true}"
                panelStyleClass="client-dropdown-autocomplete"
                autocomplete="off"
                [suggestions]="filteredClients"
                (completeMethod)="filterClients($event)"
                field="ClientName"
                (onSelect)="handleSelectClick($event.value);"
                ngDefaultControl
                placeholder="{{'Search-Clients' | translate}}"
                (onFocus)="$event.target.select()"
                [dropdown]="true"
                [disabled]="transactionData"
                required>
                <ng-template let-client pTemplate="item">
                  <div>
                    {{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : ''
                    }}
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-12" *ngIf="clientProfile">
              <div>
                <div class="p-pl-3" *ngIf="clientProfile.Mobile">
                  <i class="fas fa-phone p-pr-2" aria-hidden="true"></i>
                  <span>{{clientProfile.Mobile}}</span>
                </div>
                <div class="p-pl-3 p-pt-2" *ngIf="clientProfile.Email">
                  <i class="fas fa-envelope p-pr-2" aria-hidden="true"></i>{{clientProfile.Email}}
                </div>
                <div class="p-pl-3 p-pt-2 p-pb-0">
                  <i
                    class="fas fa-map-marker-alt p-pr-2"
                    aria-hidden="true"
                    *ngIf="clientProfile.BusStreet1"></i>
                  <span>{{clientProfile.BusStreet1}}&nbsp;</span>
                  <span>{{clientProfile.BusStreet2}}&nbsp;</span>
                  <span>{{clientProfile.BusCity}},&nbsp;</span>
                  <span>{{clientProfile.BusState}}&nbsp;</span>
                  <span>{{clientProfile.BusZip}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-8 p-fluid p-formgrid p-grid p-m-0 p-p-0">
          <div class="p-col-6">
            <div class="card manual-invoice-mh">
              <div class="p-formgroup-inline">
                <div class="p-field p-col-5">
                  <label class="label-text required-field">
                    {{ 'Accounts.invoice-date' | translate }}
                  </label>
                  <p-calendar
                    formControlName="InvoiceDate"
                    yearRange="2000:2090"
                    showButtonBar="true"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    (onSelect)="
                                      onSelectDate( $event, 'InvoiceDate' );
                                      verifySystemLocking( $event );
                                      validateInvoiceDate( );
                                    ">
                  </p-calendar>
                </div>
                <div class="p-field p-col-7">
                  <label class="label-text">{{'Accounts.invoice-number' | translate}}</label>
                  <input
                    type="text"
                    pInputText
                    class="p-text-right"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="(userTitle === 'Administrator' || !isClientSelected) ? false:true"
                    [(ngModel)]="NextInvoiceNumber" />
                </div>
                <div class="p-field p-col-5" *ngIf="selClient?.ClientID">
                  <label class="label-text"
                    >{{'billing-invoicing.apply-discount-to' | translate}}</label
                  >
                  <p-dropdown
                    [options]="typeofDiscounts"
                    formControlName="DiscountType"
                    (onChange)="changePaymentType(manualInvoiceForm.get('DiscountType'))">
                  </p-dropdown>
                </div>
                <div class="p-field p-col-7" *ngIf="selClient?.ClientID">
                  <label class="label-text"
                    >{{(manualInvoiceForm.get('DiscountType').value === 'Flat Amount' ? 'Flat
                    Discount Amount' : '% Discount') | translate}}</label
                  >
                  <div class="p-d-flex p-jc-between">
                    <div class="p-inputgroup p-mr-3">
                      <span class="p-inputgroup-addon" *ngIf="IsAmount">$</span>
                      <input
                        type="text"
                        pInputText
                        formControlName="DiscountValue"
                        class="p-text-right"
                        maxlength="12"
                        (focus)="$event.target.select()" />
                      <span class="p-inputgroup-addon" *ngIf="!IsAmount">%</span>
                    </div>
                    <button
                      type="button"
                      style="height: fit-content"
                      class="p-mr-3 p-p-1 p-button-sm width-30p"
                      [disabled]="manualInvoiceForm.get('DiscountValue').value <= 0 || grandInvAmt == 0"
                      pButton
                      pRipple
                      label="{{ 'Calc' | translate }}"
                      (click)="distributeDiscount()"></button>
                    <button
                      type="button"
                      style="height: fit-content"
                      class="p-button-sm p-p-1 width-30p"
                      pButton
                      pRipple
                      label="{{ 'clear' | translate }}"
                      (click)="clearDiscount()"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-6 p-m-0 p-p-0">
            <div class="card manual-invoice-mh">
              <div class="p-formgroup-inline">
                <div class="p-field p-col-6">
                  <label class="required-field label-text"
                    >{{ 'billing-invoicing.terms' | translate }}</label
                  >
                  <select formControlName="selectedTerm" class="custom-dropdown">
                    <option *ngFor="let term of termsList" [value]="term.value">
                      {{ term.label }}
                    </option>
                  </select>
                </div>
                <div class="p-field p-col-6">
                  <label class="required-field label-text">{{'FinalizeAction' | translate}}</label>
                  <p-dropdown
                    [options]="finalizeActions"
                    formControlName="FinalizeAction"
                    (onChange)="
                                            changefinalizeActions(manualInvoiceForm.get('FinalizeAction'))
                                        "></p-dropdown>
                </div>
              </div>
              <div class="p-field p-col-12">
                <label class="required-field label-text"
                  >{{'billing-invoicing.invoice-template' | translate}}</label
                >
                <p-dropdown
                  scrollHeight="400px"
                  [options]="invoiceTemplateTypes"
                  (onChange)="checkNoTimeRecordsWarning()"
                  formControlName="invoiceTemplate">
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="p-grid" style="width: 100%; margin: 0px 1px">
          <div class="p-col p-mt-3 p-pl-0">
            <div class="p-grid nested-grid miGrid bg-blue p-m-0 p-py-3">
              <div class="p-col-2">
                <div><i class="fal fa-badge-dollar ic-size-40"></i></div>
              </div>
              <div class="p-col-10 p-text-right">
                <div class="p-grid">
                  <div class="p-col-12 ic-size-15">{{'Accounts.invoice-amount' | translate}}</div>
                  <div class="p-col-12">
                    <div class="price p-mb-ob">
                      {{grnadInvoiceAmt | currency: "USD":"symbol":"1.2-2"}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col p-mt-3 p-pl-0">
            <div class="p-grid nested-grid miGrid bg-orange p-m-0 p-py-3">
              <div class="p-col-2">
                <div><i class="fal fa-clock ic-size-40"></i></div>
              </div>
              <div class="p-col-10 p-text-right">
                <div class="p-grid">
                  <div class="p-col-12 ic-size-15">{{'Open-Time' | translate}}</div>
                  <div class="p-col-12">
                    <div class="price p-mb-ob">
                      {{ grandBillableAmt | currency: "USD":"symbol":"1.2-2" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col p-mt-3 p-pl-0">
            <div class="p-grid nested-grid miGrid bg-green p-m-0 p-py-3">
              <div class="p-col-2">
                <div><i class="fal fa-money-check-edit ic-size-40"></i></div>
              </div>
              <div class="p-col-10 p-text-right">
                <div class="p-grid">
                  <div class="p-col-12 ic-size-15">
                    {{'Accounts.advance-payments-credits' | translate}}
                  </div>
                  <div class="p-col-12">
                    <div class="price p-mb-ob">
                      {{ totalNumberPaymentUnapplied | currency: "USD":"symbol":"1.2-2" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col p-mt-3 p-pl-0">
            <div class="p-grid nested-grid miGrid bg-purple p-m-0 p-py-3">
              <div class="p-col-2">
                <div><i class="fal fa-user ic-size-40"></i></div>
              </div>
              <div class="p-col-10 p-text-right">
                <div class="p-grid">
                  <div class="p-col-12 ic-size-15">{{'Account.customer_balance' | translate}}</div>
                  <div class="p-col-12">
                    <div class="price p-mb-ob">
                      {{ totalCustomerBalance | currency: "USD":"symbol":"1.2-2" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col p-mt-3 p-pl-0">
            <div class="p-grid nested-grid miGrid bg-salmon p-m-0 p-py-3">
              <div class="p-col-2">
                <div><i class="fal fa-file-invoice-dollar ic-size-40"></i></div>
              </div>
              <div class="p-col-10 p-text-right">
                <div class="p-grid">
                  <div class="p-col-12 ic-size-15">
                    {{'Account.last_invoice_amount' | translate}}
                  </div>
                  <div class="p-col-12">
                    <div class="price p-mb-ob">
                      {{ totalLastInvoiceAmount | currency: "USD":"symbol":"1.2-2" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-col-12 p-mt-3 p-pr-0 manual" *ngIf="isClientSelected">
          <p-panel
            header="{{'Open-Time-&-Expense-Records' | translate}}"
            [toggleable]="true"
            [collapsed]="timeExpenseRecords.length == 0">
            <div class="p-col-12 p-grid p-mb-3">
              <div class="p-col-7 p-fluid p-formgrid p-grid">
                <div class="p-col-4">
                  <label class="label-text required-field p-d-inline"
                    >{{'Bill-Through-Date' | translate}}</label
                  >
                  <p-calendar
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="2000:2090"
                    formControlName="InvoiceStartDate"
                    (onSelect)="refreshTimeandExpenseRecords()"
                    showButtonBar="true"></p-calendar>
                </div>
                <div class="p-col-4 p-d-inline">
                  <label class="label-text required-field">{{'For-Staff' | translate}}</label>
                  <p-dropdown
                    [options]="AllStaffsTypes"
                    formControlName="staffId"
                    (onChange)="refreshTimeandExpenseRecords()"></p-dropdown>
                </div>
                <div class="p-col-4 p-d-inline">
                  <label class="label-text required-field p-d-inline"
                    >{{'Engagements' | translate}}
                  </label>
                  <p-multiSelect
                    [options]="engagementsTypes"
                    class="multi-select"
                    (onChange)="refreshTimeandExpenseRecords()"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selEngagementType"
                    defaultLabel="{{'All_Engagements' | translate}}"
                    maxSelectedLabels="1"
                    (onPanelHide)="refreshTimeandExpenseRecords()">
                    <ng-template let-engagement pTemplate="item">
                      <div style="width: 100%; display: inline-block; vertical-align: middle">
                        {{ engagement.label }}
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
            </div>
            <p-table
              #timeSlipsdt
              [rowHover]="true"
              [value]="timeExpenseRecords"
              [responsive]="true"
              [rows]="10"
              dataKey="ProjectMasterID"
              [showCurrentPageReport]="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[10,25,50]"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              [sortOrder]="0"
              metaKeySelection="true"
              [(selection)]="selectedItems"
              sortField="EngagementName"
              [paginatorPosition]="'top'"
              rowExpandMode="single"
              (onHeaderCheckboxToggle)="checkUncheckRow($event, true)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-3p p-text-left">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="width-2p p-text-center">&nbsp;</th>
                  <th class="width-20p p-text-left" pSortableColumn="EngagementName">
                    {{'Engagement' | translate}}
                    <p-sortIcon field="EngagementName"></p-sortIcon>
                  </th>
                  <th
                    class="width-4p p-text-center"
                    pTooltip="{{'NDE' | translate}}"
                    tooltipPosition="top">
                    Exp.
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th class="width-9p p-text-left" pSortableColumn="BillingMethod">
                    {{'engagement.billing_method' | translate}}
                    <p-sortIcon field="BillingMethod"></p-sortIcon>
                  </th>
                  <th class="width-9p p-text-right" pSortableColumn="expenseamount">
                    {{'billing-invoicing.apply-to-expenses' | translate}}
                    <p-sortIcon field="expenseamount"></p-sortIcon>
                  </th>
                  <th
                    class="width-8p p-text-right"
                    pSortableColumn="totaltime"
                    pTooltip="{{'billable-time' | translate}}"
                    tooltipPosition="top">
                    {{'Time' | translate}}
                    <i class="fa fa-question-circle ic-blue"></i>
                    <p-sortIcon field="totaltime"></p-sortIcon>
                  </th>
                  <!-- <th class="width-8p p-text-right" pTooltip="{{'Non-Billable-Amount' | translate}}"
                                        tooltipPosition="top">{{'Non-Billable' | translate}}
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th> -->
                  <th
                    class="width-10p p-text-right"
                    pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable' | translate}}"
                    tooltipPosition="top">
                    {{'Standard_Amount' | translate}}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-8p p-text-right"
                    pTooltip="{{'writes-up-down' | translate}}"
                    tooltipPosition="top">
                    WUWD
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-8p p-text-right"
                    pTooltip="{{'Accounts.invoice-amount' | translate}}"
                    tooltipPosition="top">
                    {{'amount' | translate}}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-parentRecord let-expanded="expanded">
                <tr class="cursor-hand">
                  <td>
                    <p-tableCheckbox
                      [value]="parentRecord"
                      (click)="checkUncheckRow(parentRecord, false);checkNoTimeRecordsWarning();">
                    </p-tableCheckbox>
                  </td>
                  <td>
                    <i
                      *ngIf="parentRecord.hasChildrens"
                      pTooltip="{{'expand.time' | translate}}"
                      pRipple
                      tooltipPosition="right"
                      (click)="onSpanRowClick(parentRecord)"
                      showDelay="900"
                      [ngClass]="{'notAllowed': !parentRecord.isSelected, '': parentRecord.isSelected}"
                      class="fal fa-pencil-alt ic-red cursor-hand p-pl-2">
                    </i>
                  </td>
                  <td class="p-text-left">{{ parentRecord.EngagementName }}</td>
                  <td class="p-text-center">
                    <p-checkbox
                      [(ngModel)]="parentRecord.IsExpenseRollUp"
                      binary="true"
                      [ngModelOptions]="{ standalone: true }"
                      (onChange)="updateExpenseRollUp($event,parentRecord)">
                    </p-checkbox>
                  </td>
                  <td class="p-text-left">{{ parentRecord.BillingMethod }}</td>
                  <td class="p-text-right">
                    {{ parentRecord.grandExpenseTotalAmt | currency: "USD":true:"1.2-2" }}
                  </td>
                  <td class="p-text-right">{{ parentRecord.totaltime | number: "1.2-2" }}</td>
                  <!-- <td class="p-text-right">
                                        {{ parentRecord.nonbillableamount | currency: "USD":true:"1.2-2" }}
                                    </td> -->
                  <td class="p-text-right">
                    {{ parentRecord.laboramount + parentRecord.nonbillableamount | currency:
                    "USD":true:"1.2-2" }}
                  </td>
                  <td class="p-text-right">
                    <span [style.color]="setWriteUpDownColor(parentRecord.writeupValue)">
                      <span *ngIf="parentRecord.writeupValue < 0">
                        ({{ parentRecord.writeupValue | currency: "USD":"symbol":"1.2-2" }})
                      </span>
                      <span *ngIf="parentRecord.writeupValue >= 0"
                        >{{ parentRecord.writeupValue | currency: "USD":"symbol":"1.2-2" }}
                      </span>
                    </span>
                  </td>
                  <td class="p-text-right ic-red">
                    {{ parentRecord.invoiceAmount | currency: "USD":true:"1.2-2" }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="10">
                    <p-message
                      severity="info"
                      text="{{'data_not_found' | translate}}."
                      styleClass="p-px-2">
                    </p-message>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="timeExpenseRecords.length > 1">
                  <td>&nbsp;</td>
                  <td colspan="4" class="p-text-right p-text-bold ic-red">
                    {{'Totals' | translate}}
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ grandBillableExpenseAmt | currency: "USD":true:"1.2-2" }}</span
                    >
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ grandBillableTime | number: "1.2-2" }}</span
                    >
                  </td>
                  <!-- <td class="p-text-right p-text-bold ic-red">
                                        <span *ngIf="timeExpenseRecords.length > 0">{{ grandNonBillableAmt | currency:
                                            "USD":true:"1.2-2" }}</span>
                                    </td> -->
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ grandBillableAmt + grandNonBillableAmt | currency: "USD":true:"1.2-2"
                      }}</span
                    >
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span [style.color]="setWriteUpDownColor(grandWUWDAmt)">
                      <span *ngIf="grandWUWDAmt < 0">
                        ({{ grandWUWDAmt | currency: "USD":"symbol":"1.2-2" }})
                      </span>
                      <span *ngIf="grandWUWDAmt >= 0"
                        >{{ grandWUWDAmt | currency: "USD":"symbol":"1.2-2" }}</span
                      >
                    </span>
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ grandInvAmt | currency: "USD":true:"1.2-2" }}</span
                    >
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
          <!--  end of card -->
          <!-- </div> -->
        </div>
        <div class="p-col-12 p-mt-3 p-pr-0 manual" *ngIf="isClientSelected">
          <p-panel header="Invoice Items" [toggleable]="true">
            <p-table
              [rowHover]="true"
              [responsive]="true"
              [rows]="10"
              [value]="lineItems"
              [showCurrentPageReport]="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[10,25,50]"
              [filterDelay]="0"
              dataKey="uuid"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              [paginatorPosition]="'top'"
              [paginator]="lineItems.length > 5"
              [reorderableColumns]="true"
              (onRowReorder)="onRowChange($event)"
              editMode="row"
              #servicelinetable>
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-5p p-text-center">{{'Line' | translate}} #</th>
                  <th class="width-5p p-text-center">{{'action' | translate}}</th>
                  <th class="width-4p p-text-center"></th>
                  <th
                    class="width-22p p-text-left"
                    pSortableColumn="EngagementName"
                    class="{{IsLinesItemsRequired ? 'required-field': 'NoClass p-text-center'}}">
                    {{'Client-Engagements' | translate}}
                    <p-sortIcon field="EngagementName"></p-sortIcon>
                  </th>
                  <th class="width-52p p-text-left">{{'activity-description' | translate}}</th>
                  <th class="width-5p p-text-right">Discount</th>
                  <th
                    class="{{IsLinesItemsRequired ? 'width-9p required-field p-text-right'
                                    : 'width-7p NoClass p-text-right'}}">
                    {{'Accounts.invoice-amount' | translate}}
                  </th>
                  <th class="width-7p"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="rowData" [pReorderableRow]="ri">
                  <td class="p-text-center">
                    <span><strong>{{rowData.LineItem}}</strong></span>
                  </td>
                  <td>
                    <div class="p-text-center">
                      <i
                        class="fal fa-plus ic-blue ic-size-17 p-mr-3 cursor-hand manual-invoice-action"
                        (click)="addRow()"
                        pTooltip="{{'Add-Row' | translate}}"
                        tooltipPosition="top"></i>
                      <i
                        class="ic-size-15 fal fa-trash-alt ic-red cursor-hand manual-invoice-action"
                        pTooltip="{{'Remove' | translate}}"
                        tooltipPosition="top"
                        *ngIf="
                                            (!rowData.isDisabled && lineItems.length > 1) ||
                                            !IsLinesItemsRequired
                                            "
                        (click)="deleteRow(rowData, true)"></i>
                    </div>
                  </td>
                  <td class="p-text-center">
                    <i
                      class="fal fa-bars ic-green ic-size-17"
                      pTooltip="{{'Reorder-Row' | translate}}"
                      pReorderableRowHandle></i>
                  </td>
                  <td class="p-text-left" pInitEditableRow (click)="onRowEditInit(rowData, $event)">
                    <p-cellEditor id="engagement_column_{{rowData['uuid']}}">
                      <ng-template pTemplate="input">
                        <p-dropdown
                          [options]="activitiesTypes"
                          [style]="{ width: '100%' }"
                          [(ngModel)]="rowData.ProjectMasterID"
                          name="itemProjectSelected"
                          placeholder="{{ 'select-engagement' | translate }}"
                          [filter]="true"
                          id="itemProjectSelected"
                          #itemProjectSelected="ngModel"
                          (onChange)="handleDropSelectClick($event, rowData, 'EngagementName')"
                          [ngModelOptions]="{ standalone: true }"
                          appendTo="body"
                          class="text-ellipse"
                          [disabled]="selClient.ClientID == null">
                        </p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div>{{ rowData.EngagementName }}</div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="p-text-left p-text-nowrap p-text-truncate"
                    [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'InvoiceDesc', ri)">
                    <p-cellEditor id="invoice_desc_column_{{rowData['uuid']}}">
                      <ng-template pTemplate="input">
                        <span>
                          <app-mentions
                            [rows]="1"
                            [(inputText)]="rowData['description']"
                            (inputTextChange)="replaceCaret($event, rowData)"
                            class="p-text-left invoice-desc-{{ri}}"
                            style="width: 100% !important; resize: vertical"></app-mentions>
                        </span>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span
                          class="p-text-truncate"
                          pTooltip="{{ rowData.description }}"
                          tooltipPosition="top"
                          showDelay="800"
                          tooltipStyleClass="tooltip"
                          >{{ rowData.description }}</span
                        >
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="p-text-right"
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'Discount', ri)">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          type="text"
                          pInputText
                          class="p-text-right discount-{{ri}}"
                          id="discount_{{ rowData['uuid'] }}"
                          [(ngModel)]="rowData['discount']"
                          [ngModelOptions]="{ standalone: true }"
                          (blur)="doMoneyFormate(rowData, 'discount')"
                          [disabled]="rowData.isDisabled"
                          maxlength="14"
                          (focus)="$event.target.select();" />
                      </ng-template>
                      <ng-template pTemplate="output"> {{ rowData.discount }} </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="p-text-right"
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'InvoiceAmount', ri)">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          type="text"
                          pInputText
                          class="p-text-right invoice-amount-{{ri}}"
                          id="amt_{{ rowData['uuid'] }}"
                          [(ngModel)]="rowData['amount']"
                          (ngModelChange)="amountChanged = true;"
                          [ngModelOptions]="{ standalone: true }"
                          (keydown.Tab)="doMoneyFormate(rowData, 'amount');"
                          (blur)="doMoneyFormate(rowData, 'amount')"
                          [disabled]="rowData.isDisabled"
                          maxlength="14"
                          (focus)="$event.target.select();" />
                      </ng-template>
                      <ng-template pTemplate="output"> {{ rowData.amount }} </ng-template>
                    </p-cellEditor>
                  </td>
                  <td class="p-text-center">
                    <!-- <button *ngIf="!editing" pButton pRipple type="button" icon="fal fa-pencil"
                                            (click)="onRowEditInit(rowData)" class="ic-green p-button-rounded p-button-text"></button> -->
                    <button
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      (click)="onRowEditSave($event, rowData, 'InvoiceItems')"
                      class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pCancelEditableRow
                      icon="pi pi-times"
                      (click)="onRowEditCancel(rowData, ri)"
                      class="p-button-rounded p-button-text p-button-danger"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
        <div class="p-col-8 p-mt-3 p-mb-6 p-pr-0" *ngIf="isClientSelected">
          <div class="card manual-invoice-descr-mh p-mb-0">
            <h4 class="label-text p-m-0 p-p-0 required-field">
              {{'Internal-Invoice-Memo' | translate}}
              <i
                pTooltip="{{'Internal-Invoice-Memo-tooltip' | translate}}"
                tooltipStyleClass="tooltip-width-450"
                class="fa fa-question-circle question-tooltip-icon"
                tooltipPosition="right"></i>
            </h4>
            <span class="pull-right ic-size-15 p-text-bold">
              <span [ngStyle]="{'color':(descCount > 250)? '#f28686' : '#83cc44' }"
                >{{ descCount }}</span
              >/250
            </span>
            <app-mentions
              [rows]="7"
              maxlength="250"
              [(inputText)]="manualInvoiceForm.controls['DescriptionShort'].value"
              (inputTextChange)="manualInvoiceForm.controls['DescriptionShort'].setValue($event);charCount()"
              class="p-text-left invoice-desc-{{ri}}"
              [ngStyle]="{'border-color':(descCount > 250)? '#f28686' : '#ced4da' }"
              style="width: 100% !important; resize: vertical"></app-mentions>
          </div>
          <div class="card p-mt-3">
            <div class="p-fluid p-formgrid p-grid" *ngIf="isClientSelected">
              <div class="p-col-2">
                <p-button
                  pRipple
                  label="{{'billing-invoicing.top-invoice-note' | translate}}"
                  styleClass="p-button-sm"
                  class="p-button-help"
                  tooltipPosition="top"
                  pTooltip="{{'billing-invoicing.top-invoice-note' | translate}}"
                  showDelay="100"
                  (click)="showHideTable(true)"></p-button>
              </div>
              <div class="p-col-2 p-px-0">
                <p-button
                  pRipple
                  label="{{'billing-invoicing.btm-invoice-note' | translate}}"
                  styleClass="p-button-sm"
                  class="p-button-help"
                  tooltipPosition="top"
                  pTooltip="{{'billing-invoicing.btm-invoice-note' | translate}}"
                  showDelay="100"
                  (click)="showHideTable(false)"></p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-4 p-mt-3 p-pr-0" *ngIf="isClientSelected">
          <div class="card">
            <h4 class="label-text p-my-0 p-py-2">
              {{'billing-invoicing.invoice-summary' | translate}}
            </h4>
            <div class="ui-datatable ui-widget" style="margin-top: 2px">
              <table class="p-datatable p-component" style="width: 100%">
                <tbody class="p-datatable-tbody">
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Total Services">{{'Total-Services' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span>
                        <strong>{{ grandInvAmt | currency: "USD":"symbol":"1.2-2" }}</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Total Expenses">{{'total-expenses' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span>
                        <strong>{{ grandExpenseAmt | currency: "USD":"symbol":"1.2-2" }}</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Discount">{{'less-discount' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span>
                        <strong [ngStyle]="{ color: discount_color }">{{ lessDiscount }}</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Invoice Amount">{{'Accounts.invoice-amount' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span class="p-text-right">
                        <strong>{{ invoiceAmount }}</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Tax">{{'tax' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span class="p-text-right">
                        <strong>{{ invoiceTax | currency: "USD":"symbol":"1.2-2" }}</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Payments">{{'less-payments' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span [ngStyle]="{ color: lessPayments_color }">
                        <strong>{{ invoiceLessPayments }}</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-my-0 p-py-2">
                      <label for="Balance Due">{{'balance-due' | translate}}</label>
                    </td>
                    <td class="p-my-0 p-py-2 p-text-right">
                      <span>
                        <strong>{{ invoiceBalance }}</strong>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="p-col-12 p-mt-3 p-pr-0 manual"
          *ngIf="isClientSelected  && openRetainersDataSource.length > 0">
          <p-panel header="Open Advance Payments" [toggleable]="true">
            <p-table
              #openretainer
              [rowHover]="true"
              [value]="openRetainersDataSource"
              [responsive]="true"
              dataKey="PaymentHeaderID"
              selectionMode="single"
              [showCurrentPageReport]="true"
              [scrollable]="true"
              [scrollHeight]="'calc(40vh - 100px)'"
              [filterDelay]="0"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              [sortOrder]="-1"
              sortField="PaymentDate">
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-15p p-text-center" pSortableColumn="PaymentDate">
                    {{'Accounts.payment-date' | translate}}
                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                  </th>
                  <th class="width-15p p-text-center" pSortableColumn="PaymentType">
                    {{'Accounts.payment-type' | translate}}
                    <p-sortIcon field="PaymentType"></p-sortIcon>
                  </th>
                  <th class="width-15p p-text-center" pSortableColumn="CheckRef">
                    {{'Accounts.check-reference' | translate}}
                    <p-sortIcon field="CheckRef"></p-sortIcon>
                  </th>
                  <th class="width-15p p-text-right" pSortableColumn="PaymentAmount">
                    {{'Accounts.payment-amount' | translate}}
                    <p-sortIcon field="PaymentAmount"></p-sortIcon>
                  </th>
                  <th class="width-15p p-text-right">{{'PreviouslyAppliedAmount' | translate}}</th>
                  <th class="width-15p p-text-right">{{'AppliedAmount' | translate}}</th>
                  <th class="width-15p p-text-right">{{'balance' | translate}}</th>
                  <th class="width-15p p-text-center">{{'Accounts.apply' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="p-text-center">{{ rowData.PaymentDate | date: "MM/dd/yyyy" }}</td>
                  <td class="p-text-center">{{ rowData.PaymentType }}</td>
                  <td class="p-text-center">{{ rowData.CheckRef }}</td>
                  <td class="p-text-right">${{ rowData.PaymentAmount }}</td>
                  <td class="p-text-right">${{ rowData.AmountPreviouslyAppliedRetainer }}</td>
                  <td class="p-text-right">${{ rowData.AppliedAmount }}</td>
                  <td class="p-text-right">${{ rowData.PaymentUnapplied }}</td>
                  <td class="p-text-center ic-red">
                    <p-checkbox
                      binary="true"
                      [(ngModel)]="rowData['selected']"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="calculateLesspayments($event, rowData)"
                      >{{ rowData["selected"] }}
                    </p-checkbox>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="openRetainersDataSource.length > 1">
                  <td colspan="3" class="p-text-right p-text-bold ic-red">
                    {{'Totals' | translate}}
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="openRetainersDataSource.length > 0"
                      >${{ totalNumberPaymentamt}}</span
                    >
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ totalPreviouslyApplied | currency: "USD":true:"1.2-2" }}</span
                    >
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ totalAppliedAmount | currency: "USD":true:"1.2-2" }}</span
                    >
                  </td>
                  <td class="p-text-right p-text-bold ic-red">
                    <span *ngIf="timeExpenseRecords.length > 0"
                      >{{ totalNumberPaymentUnapplied | currency: "USD":true:"1.2-2" }}</span
                    >
                  </td>
                  <td class="p-text-right"></td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
      </div>

      <p-sidebar
        [(visible)]="isTopShow"
        position="bottom"
        [style]="{'min-height':'400px','background':'#8e8f92'}">
        <div class="card">
          <div class="control-label header-color text-nowrap p-col-12">
            <h4 class="label-text">{{'billing-invoicing.top-invoice-note' | translate}}</h4>
          </div>
          <div class="p-col-12 col-md-12">
            <ejs-richtexteditor
              id="topEditor"
              #topEditor
              formControlName="topInvoiceNote"
              [height]="285"
              style="resize: vertical"
              [findChoices]="mangoUtils.findChoices"
              (change)="replaceShortcutsNew($event, 'topInvoiceNote')"
              [getChoiceLabel]="mangoUtils.getChoiceLabel"
              [maxLength]="550"
              [showCharCount]="true">
            </ejs-richtexteditor>
          </div>
        </div>
      </p-sidebar>
      <p-sidebar
        [(visible)]="isBottomShow"
        position="bottom"
        [style]="{'min-height':'400px','background':'#8e8f92'}">
        <div class="card">
          <div class="control-label header-color text-nowrap p-col-12">
            <h4 class="label-text">{{'billing-invoicing.btm-invoice-note' | translate}}</h4>
          </div>
          <div class="p-col-12 col-md-12">
            <ejs-richtexteditor
              id="bottomEditor"
              #bottomEditor
              formControlName="bottomInvoiceNote"
              [height]="285"
              style="resize: vertical"
              [findChoices]="mangoUtils.findChoices"
              [getChoiceLabel]="mangoUtils.getChoiceLabel"
              (change)="replaceShortcutsNew($event, 'bottomInvoiceNote')"
              [maxLength]="550"
              [showCharCount]="true">
            </ejs-richtexteditor>
          </div>
        </div>
      </p-sidebar>
    </form>
  </div>
</div>
<br />
<br />
<p-sidebar
  [(visible)]="displaySideBar"
  position="right"
  (onHide)="onHideSideBar()"
  [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" style="min-height: calc(100vh - 35px)">
    <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2" *ngIf="selectedParent?.EngagementName">
      <div class="p-d-flex p-jc-between">
        <label class="label-text ic-size-18 p-mb-3"
          >{{ client }} - {{ selectedParent.EngagementName }}</label
        >
        <span
          *ngIf="selectedParent?.allSelectedTimeSlips?.length > 0 || selectedParent?.allSelectedExpenseSlips?.length > 0">
          <app-batch-options-time
            [selOpenTimeRecords]="selectedParent.allSelectedTimeSlips"
            [selOpenExpRecords]="selectedParent.allSelectedExpenseSlips"
            [defaultClient]="selClient"
            [deleteWuwdAndBilledAmt]="true"
            (onUpdate)="refreshTimeandExpenseRecords()"
            (onUpdateBilledAmt)="onUpdateBilledAmt($event)"
            [showUpdateBilledAmt]="true">
          </app-batch-options-time>
        </span>
      </div>
      <div class="p-col-12 p-pr-0 manual" *ngIf="selectedParent?.allTimeSlips.length > 0">
        <p-panel header="{{ 'Open-Time-Records' | translate }}" [toggleable]="true">
          <p-table
            [value]="selectedParent?.allTimeSlips"
            sortField="Ddate"
            [sortOrder]="-1"
            [rowHover]="true"
            dataKey="SlipMasterID"
            [(selection)]="selectedParent.allSelectedTimeSlips"
            [scrollable]="true"
            [scrollHeight]="selectedParent.allExpenseSlips.length > 0 ? 'calc(55vh - 200px)' : 'calc(85vh - 200px)'"
            (onHeaderCheckboxToggle)="checkHeaderCheckbox(selectedParent)"
            [responsive]="true"
            [resizableColumns]="true"
            metaKeySelection="true"
            (onFilter)="onFilterTime($event)"
            [globalFilterFields]="['StaffName', 'StaffNumber', 'scdescr', 'ServiceCode', 'Memo']"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            editMode="row"
            #dtchild
            [columns]="selectedColumns">
            <ng-template pTemplate="caption">
              <div class="table-header" style="margin-top: -10px">
                <div class="p-d-flex p-jc-between">
                  <div>
                    <span class="p-input-icon-left">
                      <i class="fal fa-search"></i>
                      <input
                        #searchValue
                        pInputText
                        type="text"
                        (input)="dtchild.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'Search-by' | translate }}" />
                    </span>
                    <span>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="dtchild.reset();clearSearchFilterTime()"
                        tooltipPosition="top"
                        pTooltip="{{'clear' | translate}}"></button>
                    </span>
                    <p-message
                      *ngIf="filteredTimeItemsSize > -1"
                      severity="success"
                      text="{{filteredTimeItemsSize}} {{ 'records_found' | translate }}."
                      styleClass="p-ml-2"></p-message>
                  </div>
                  <div class="p-d-flex p-jc-end">
                    <span class="p-mt-1 p-mr-4" *ngIf="mangoCompanyData.ActivateLaborRates">
                      <h5 class="ic-red">
                        Tax:
                        <span style="font-weight: 700"
                          >{{engagementLevelTimeTax | currency: 'USD':true:'1.2-2'}}</span
                        >
                      </h5>
                    </span>
                    <p-multiSelect
                      [options]="cols"
                      [(ngModel)]="selectedColumns"
                      optionLabel="header"
                      selectedItemsLabel="{0} columns selected"
                      [style]="{minWidth: '200px'}"
                      (onChange)="onChangeSelectedCols($event)"
                      placeholder="Choose Columns">
                    </p-multiSelect>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th class="width-4p p-text-center">
                  <p-tableHeaderCheckbox
                    [disabled]="searchValue && searchValue.nativeElement.value">
                  </p-tableHeaderCheckbox>
                </th>
                <th class="width-5p p-text-center">{{'actions' | translate}}</th>
                <th
                  class="width-3p p-text-center"
                  pTooltip="{{'Private-Memo' | translate}}"
                  tooltipPosition="top">
                  {{'PM' | translate}}
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <!-- <th class="width-7p p-text-center" pSortableColumn="Ddate">
                                    {{'date' | translate}}
                                    <p-sortIcon field="Ddate"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="StaffName">
                                    {{'user-title' | translate}}
                                    <p-sortIcon field="StaffName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="scdescr">
                                    {{'activity' | translate}}
                                    <p-sortIcon field="scdescr"></p-sortIcon>
                                </th>
                                <th class="width-15p p-text-left">
                                    {{'description' | translate}}
                                </th> -->
                <th
                  *ngFor="let col of columns"
                  class="{{col.rowClass}}"
                  [pSortableColumn]="col.field"
                  class="p-text-left">
                  <div [ngClass]="col?.canFilter ? 'p-d-flex p-jc-between' : ''">
                    <div class="p-mt-1 p-d-flex p-ai-center">
                      {{col.header}}
                      <i
                        *ngIf="col.field === 'ServiceCode'"
                        pTooltip="{{'activity-code' | translate}}"
                        tooltipPosition="top"
                        class="fa fa-question-circle ic-blue"></i>
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </div>
                    <p-columnFilter
                      *ngIf="col?.canFilter"
                      [type]="col.type || 'text'"
                      [field]="col.field"
                      display="menu"
                      (onFilter)="onFilterTime($event)"></p-columnFilter>
                  </div>
                </th>
                <th
                  class="width-5p p-text-right"
                  pTooltip="{{'Billing-Hours' | translate}}"
                  tooltipPosition="top">
                  {{'timer.hours' | translate}}
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th class="width-5p p-text-right">{{'billing-rate' | translate}}</th>
                <!-- <th class="width-8p p-text-right" pTooltip="{{'Non-Billable-Amount' | translate}}"
                                    tooltipPosition="top">
                                    {{'Non-Billable' | translate}}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th> -->
                <th
                  class="width-3p p-text-center"
                  pTooltip="{{'Non-Billable-tooltip' | translate}}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  NB
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th
                  class="width-8p p-text-right"
                  pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable-Amount' | translate}}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  {{'Standard_Amount' | translate}}
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th
                  class="width-5p p-text-right"
                  pTooltip="{{'writes-up-down' | translate}}"
                  tooltipPosition="top">
                  WUWD
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th
                  class="width-5p p-text-right"
                  pTooltip="{{'billed-amount' | translate}}"
                  tooltipPosition="top">
                  {{'billed' | translate}}
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th class="width-6p p-text-right"></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-childrowData
              let-columns="columns"
              let-editing="editing"
              let-ri="rowIndex">
              <tr class="cursor-hand" [pEditableRow]="childrowData">
                <td class="width-4p p-text-center">
                  <p-tableCheckbox
                    [value]="childrowData"
                    (click)="singleUncheck($event, childrowData, selectedParent)">
                  </p-tableCheckbox>
                </td>
                <td class="width-5p p-text-center">
                  <i
                    class="fal fa-pencil p-mr-3 ic-green ic-size-15 ic-green ic-size-15"
                    (click)="openTimeEntryDailog(childrowData,true,selectedParent)"
                    pTooltip="{{'Edit' | translate}}"
                    tooltipPosition="top"
                    showDelay="800"></i>
                  <i
                    class="fal fa-trash-alt ic-red p-mr-3 ic-size-15"
                    (click)="deleteClientAlert(childrowData,ri);"
                    tooltipPosition="top"
                    pTooltip="{{'mgmt-delete' | translate}}"></i>
                  <!-- <i class="fal fa-save ic-blue ic-size-15" *ngIf="childrowData['IsColumnChanges']"
                                        (click)="saveTimeEntry(childrowData)"  showDelay="800" pTooltip="{{'save_button' | translate}}"
                                        tooltipPosition="top"></i> -->
                </td>
                <td class="width-3p p-text-center">
                  <i
                    class="fa fa-sticky-note p-pr-2 cursor-hand"
                    [ngClass]="(childrowData.PrivateMemo === null || childrowData.PrivateMemo === '') ? 'ic-grey' : 'ic-folder-yellow'"
                    (click)="onShowScratchpad(childrowData)"
                    pTooltip="{{ 'private_memo' | translate }}"
                    tooltipPosition="top"
                    showDelay="800"></i>
                </td>
                <td
                  *ngFor="let col of columns"
                  class="{{col.rowClass}} p-text-truncate"
                  pTooltip="{{childrowData[col.field]}}"
                  showDelay="800"
                  tooltipStyleClass="tooltip-no-wrap"
                  tooltipPosition="top">
                  <span
                    *ngIf="col.field==='Ddate'"
                    (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                    {{ childrowData['displayDate'] | date:'MM/dd/yyyy' }}
                  </span>
                  <span
                    *ngIf="col.field==='StaffName'"
                    (click)="onRowEditInit(childrowData, $event)"
                    pInitEditableRow>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-autoComplete
                          placeholder="{{ childrowData.StaffName }}"
                          [(ngModel)]="childrowData['StaffName']"
                          [suggestions]="filteredStaffSingle"
                          [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                          (onSelect)="handleDropStaffSelectClick($event.value,childrowData, 'Staff')"
                          (completeMethod)="filterStaffItems($event,childrowData)"
                          field="label"
                          [size]="30"
                          [minLength]="1"
                          [dropdown]="true"
                          appendTo="body">
                        </p-autoComplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div>{{ childrowData.StaffName }}</div>
                      </ng-template>
                    </p-cellEditor>
                  </span>
                  <span
                    *ngIf="col.field==='StaffNumber'"
                    (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                    {{ childrowData.StaffNumber }}
                  </span>
                  <span
                    *ngIf="col.field==='scdescr'"
                    (click)="onRowEditInit(childrowData, $event)"
                    pInitEditableRow>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown
                          [options]="activityList"
                          placeholder="{{ childrowData.scdescr }}"
                          [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                          [(ngModel)]="childrowData.ServiceCodeID"
                          id="TimeDescription"
                          name="TimeDescription"
                          [filter]="true"
                          #TimeDescription="ngModel"
                          (onChange)="handleDropStaffSelectClick($event,childrowData,'Activity')"
                          appendTo="body"
                          class="p-text-left"
                          [style]="{'width':'100%'}">
                        </p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div>{{ childrowData['scdescr'] }}</div>
                      </ng-template>
                    </p-cellEditor>
                  </span>
                  <span
                    *ngIf="col.field==='ServiceCode'"
                    (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                    {{ childrowData.ServiceCode }}
                  </span>
                  <span
                    *ngIf="col.field==='Memo'"
                    (click)="onRowEditInit(childrowData, $event)"
                    pInitEditableRow
                    class="shortcut-desc">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span>
                          <app-mentions
                            [rows]="1"
                            [(inputText)]="childrowData['Memo']"
                            (inputTextChange)="replaceCaret($event, childrowData, 'Memo')"
                            class="p-text-left memo-data-{{ri}}"
                            [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                            id="desc_{{ childrowData['uuid'] }}"
                            style="width: 100% !important; resize: vertical"></app-mentions>
                        </span>
                      </ng-template>
                      <ng-template pTemplate="output"> {{ childrowData.Memo }} </ng-template>
                    </p-cellEditor>
                  </span>
                </td>
                <td
                  class="width-5p p-text-right"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, 'TotalTime', ri)"
                  showDelay="800"
                  pTooltip="{{ childrowData.TotalTime | number: '1.2-2' }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        pInputText
                        type="text"
                        pKeyFilter="num"
                        [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate']) || (!this.timeexp?.ActualTimeEdit)"
                        [(ngModel)]="childrowData.TotalTime"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="onTotalTimeChange($event, childrowData);childrowData['TotalTimeChanged'] = true;"
                        (focus)="preventTimeRecordUpdate(childrowData['ProjectMasterID'], childrowData, ri, $event);"
                        class="width-100p p-text-right"
                        (mouseup)="$event.preventDefault()" />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ childrowData.TotalTime | number: "1.2-2" }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  class="width-5p p-text-right"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, 'BillRate', ri)"
                  showDelay="800"
                  pTooltip="{{ childrowData.BillingRate | currency: 'USD':true:'1.2-2' }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-inputNumber
                        maxlength="20"
                        minFractionDigits="2"
                        inputStyleClass="width-100p"
                        (onFocus)="preventTimeRecordUpdate(childrowData['ProjectMasterID'], childrowData, ri, $event);"
                        [step]="0"
                        [value]="childrowData.BillingRate"
                        [ngModelOptions]="{standalone: true}"
                        styleClass="bill-rate-{{ri}}"
                        [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate']) || (!this.timeexp?.BillableTimeEdit)"
                        (onBlur)="onBillRateChange($event, childrowData);childrowData['BillRateChanged'] = true;"
                        [(ngModel)]="childrowData.BillingRate"
                        mode="decimal">
                      </p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ childrowData.BillingRate | currency: "USD":true:"1.2-2" }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  class="width-3p p-text-center"
                  id="border-right"
                  (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                  <i *ngIf="childrowData.nonbillableamount" class="pi pi-check ic-green"></i>
                </td>
                <td
                  class="width-8p p-text-right"
                  id="border-right"
                  (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                  {{ childrowData.billableamount || childrowData.nonbillableamount | currency:
                  "USD":true:"1.2-2" }}
                </td>
                <td
                  class="width-5p p-text-right"
                  id="border-right"
                  (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                  <span [style.color]="setWriteUpDownColor(childrowData.WriteUpDown)">
                    <span *ngIf="childrowData.WriteUpDown < 0">
                      ({{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }})
                    </span>
                    <span *ngIf="childrowData.WriteUpDown >= 0"
                      >{{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }}
                    </span>
                  </span>
                </td>
                <td
                  class="width-5p p-text-right"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, 'BilledAmount', ri)"
                  showDelay="800"
                  pTooltip="{{ childrowData.BilledAmount | currency: 'USD':true:'1.2-2' }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-inputNumber
                        maxlength="14"
                        minFractionDigits="2"
                        maxFractionDigits="2"
                        (onFocus)="preventTimeRecordUpdate(childrowData['ProjectMasterID'], childrowData, ri, $event);"
                        styleClass="billed-amount-{{ri}}"
                        placeholder="{{childrowData.BilledAmount}}"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                        [step]="0"
                        inputStyleClass="width-100p"
                        (onBlur)="onBilledAmountChange($event, childrowData);childrowData['BilledAmountChanged'] = true;childrowData['IsColumnChanges'] = true;"
                        [(ngModel)]="childrowData.BilledAmount"
                        mode="decimal">
                      </p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ childrowData.BilledAmount | currency: "USD":true:"1.2-2" }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="width-6p p-text-center">
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onRowEditSave($event, childrowData, 'TimeRecords');saveTimeEntry(childrowData);"
                    class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(childrowData, ri)"
                    class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
              <tr>
                <td class="width-4p"></td>
                <td class="width-5p"></td>
                <td class="width-3p"></td>
                <td *ngFor="let col of columns" class="{{col.rowClass}}"></td>
                <td class="width-5p p-text-right p-text-bold ic-red">Totals</td>
                <td class="width-5p p-text-right p-text-bold ic-red">
                  <span>{{ selectedParent.grandExpenseHrs | number: "1.2-2" }}</span>
                </td>
                <td class="width-3p p-text-right p-text-bold ic-red">&nbsp;</td>
                <td class="width-8p p-text-right p-text-bold ic-red">
                  <span
                    >{{ selectedParent.grandBillableamount + selectedParent.grandNonbillableamount |
                    currency: "USD":true:"1.2-2" }}</span
                  >
                </td>
                <td class="width-5p p-text-right p-text-bold ic-red">
                  <span [style.color]="setWriteUpDownColor(selectedParent.grandWUWDamount)">
                    <span *ngIf="selectedParent.grandWUWDamount < 0">
                      ({{ selectedParent.grandWUWDamount | currency: "USD":"symbol":"1.2-2" }})
                    </span>
                    <span *ngIf="selectedParent.grandWUWDamount >= 0"
                      >{{ selectedParent.grandWUWDamount | currency: "USD":"symbol":"1.2-2" }}</span
                    >
                  </span>
                </td>
                <td class="width-5p p-text-right p-text-bold ic-red">
                  <span>{{ selectedParent.grandBilledamount | currency: "USD":true:"1.2-2" }}</span>
                </td>
                <td class="width-6p p-text-right p-text-bold ic-red"></td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="10">
                  <p-message
                    severity="info"
                    text="{{'data_not_found' | translate}}."
                    styleClass="p-px-2">
                  </p-message>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </div>
      <div class="p-col-12 p-mt-3 p-pr-0 manual" *ngIf="selectedParent?.allExpenseSlips.length > 0">
        <p-panel header="{{ 'Expense-Records' | translate }}" [toggleable]="true">
          <p-table
            [value]="selectedParent?.allExpenseSlips"
            [rows]="5"
            [pageLinks]="3"
            sortField="Ddate"
            [sortOrder]="-1"
            [rowHover]="true"
            dataKey="SlipMasterID"
            [(selection)]="selectedParent.allSelectedExpenseSlips"
            [scrollable]="true"
            [scrollHeight]="'calc(55vh - 200px)'"
            [responsive]="true"
            [resizableColumns]="true"
            metaKeySelection="true"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            (onHeaderCheckboxToggle)="checkHeaderCheckbox(selectedParent)"
            editMode="row"
            #dtchildex>
            <ng-template pTemplate="caption">
              <div class="table-header" style="margin-top: -10px">
                <div class="p-d-flex p-jc-end">
                  <span class="p-mt-1 p-mr-4" *ngIf="mangoCompanyData.ActivateExpenseRates">
                    <h5 class="ic-red">
                      Tax:
                      <span style="font-weight: 700"
                        >{{engagementLevelExpTax | currency: 'USD':true:'1.2-2'}}</span
                      >
                    </h5>
                  </span>
                  <span style="width: 200px">&nbsp;</span>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th class="width-4p p-text-center">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th class="width-5p p-text-center">&nbsp;</th>
                <th class="width-9p p-text-center" pSortableColumn="Ddate">
                  {{'date' | translate}}
                  <p-sortIcon field="Ddate"></p-sortIcon>
                </th>
                <th class="width-12p p-text-left" pSortableColumn="StaffName">
                  {{'user-title' | translate}}
                  <p-sortIcon field="StaffName"></p-sortIcon>
                </th>
                <th class="width-9p p-text-center">{{'expense-code' | translate}}</th>
                <th class="width-15p p-text-left">{{'description' | translate}}</th>
                <th class="width-7p p-text-right">{{'Units' | translate}}</th>
                <th class="width-9p p-text-right">{{'Cost' | translate}}</th>
                <th
                  class="width-8p p-text-right"
                  pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable' | translate}}"
                  tooltipPosition="top">
                  {{'Standard_Amount' | translate}}
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th
                  class="width-8p p-text-right"
                  pTooltip="{{'writes-up-down' | translate}}"
                  tooltipPosition="top">
                  WUWD
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th
                  class="width-8p p-text-right"
                  pTooltip="{{'billed-amount' | translate}}"
                  tooltipPosition="top">
                  {{'billed' | translate}}
                  <i class="fa fa-question-circle ic-blue"></i>
                </th>
                <th class="width-7p"></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-childrowData
              let-columns="columns"
              let-editing="editing"
              let-ri="rowIndex">
              <tr class="cursor-hand" [pEditableRow]="childrowData">
                <td class="width-4p p-text-center">
                  <p-tableCheckbox
                    [value]="childrowData"
                    (click)="singleUncheck($event, childrowData, selectedParent)">
                  </p-tableCheckbox>
                </td>
                <td class="width-5p p-text-center">
                  <i
                    class="fal fa-pencil p-mr-3 ic-green ic-size-15"
                    (click)="openTimeEntryDailog(childrowData, false, selectedParent)"
                    showDelay="800"
                    pTooltip="{{'Edit' | translate}}"></i>
                  <i
                    class="fal fa-trash-alt ic-red p-mr-3 ic-size-15"
                    (click)="deleteClientAlert(childrowData,ri);"
                    tooltipPosition="top"
                    pTooltip="{{'mgmt-delete' | translate}}"></i>
                </td>
                <td
                  class="width-9p p-text-center"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, $event)"
                  showDelay="800"
                  pTooltip="{{childrowData['displayDate'] | date:'MM/dd/yyyy'}}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  <span>{{childrowData['displayDate'] | date:'MM/dd/yyyy'}}</span>
                </td>
                <td
                  class="width-12p p-text-left p-text-nowrap p-text-truncate"
                  showDelay="800"
                  pTooltip="{{ childrowData.StaffName }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, $event)">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-autoComplete
                        placeholder="{{ childrowData.StaffName }}"
                        [(ngModel)]="childrowData['StaffName']"
                        [suggestions]="filteredStaffSingle"
                        (onSelect)="handleDropStaffSelectClick($event.value,childrowData, 'Staff')"
                        (completeMethod)="filterStaffItems($event,childrowData)"
                        field="label"
                        [size]="30"
                        [minLength]="1"
                        [dropdown]="true"
                        appendTo="body">
                      </p-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div>{{ childrowData.StaffName }}</div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, $event)"
                  class="width-9p p-text-center p-text-nowrap p-text-truncate"
                  showDelay="800"
                  pTooltip="{{ childrowData.ExpenseCode }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        [options]="expList"
                        placeholder="{{ childrowData['ExpenseCode'] }}"
                        [(ngModel)]="childrowData.ExpenseCodeID"
                        id="ExpDescription"
                        name="ExpDescription"
                        [filter]="true"
                        #ExpDescription="ngModel"
                        (onChange)="handleDropStaffSelectClick($event,childrowData,'Expense')"
                        appendTo="body"
                        [style]="{'width':'200px !important'}"
                        class="p-text-left"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div>{{ childrowData['ExpenseCode'] }}</div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  class="width-15p p-text-left text-nowrap p-text-truncate shortcut-desc"
                  showDelay="800"
                  pTooltip="{{ childrowData.Memo }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                  style="overflow: visible"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, $event)">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <span>
                        <app-mentions
                          [rows]="1"
                          [(inputText)]="childrowData['Memo']"
                          (inputTextChange)="replaceCaret($event, rowData)"
                          id="desc_{{ childrowData['uuid'] }}"
                          style="width: 100% !important; resize: vertical"></app-mentions>
                      </span>
                    </ng-template>
                    <ng-template pTemplate="output"> {{ childrowData.Memo }} </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  class="width-7p p-text-right"
                  (click)="openTimeEntryDailog(childrowData,false,selectedParent)">
                  {{ childrowData.Units }}
                </td>
                <td
                  class="width-9p p-text-right"
                  (click)="openTimeEntryDailog(childrowData,false,selectedParent)">
                  {{ childrowData.Cost }}
                </td>
                <td
                  class="width-8p p-text-right"
                  (click)="openTimeEntryDailog(childrowData,false,selectedParent)">
                  {{childrowData.StandardAmount | currency: "USD":true:"1.2-2"}}
                </td>
                <td
                  class="width-8p p-text-right"
                  id="border-right"
                  (click)="openTimeEntryDailog(childrowData,true,selectedParent)">
                  <span [style.color]="setWriteUpDownColor(childrowData.WriteUpDown)">
                    <span *ngIf="childrowData.WriteUpDown < 0">
                      ({{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }})
                    </span>
                    <span *ngIf="childrowData.WriteUpDown >= 0"
                      >{{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }}
                    </span>
                  </span>
                </td>
                <td
                  class="width-8p p-text-right"
                  pInitEditableRow
                  (click)="onRowEditInit(childrowData, 'BilledAmount', ri)"
                  showDelay="800"
                  pTooltip="{{ childrowData.BilledAmount | currency: 'USD':true:'1.2-2' }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-inputNumber
                        maxlength="14"
                        minFractionDigits="2"
                        maxFractionDigits="2"
                        (onFocus)="preventTimeRecordUpdate(childrowData['ProjectMasterID'], childrowData, ri, $event);"
                        styleClass="billed-amount-{{ri}}"
                        placeholder="{{childrowData.BilledAmount}}"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                        [step]="0"
                        inputStyleClass="width-100p"
                        (onBlur)="onBilledAmountChange($event, childrowData, true);childrowData['BilledAmountChanged'] = true;childrowData['IsColumnChanges'] = true;"
                        [(ngModel)]="childrowData.BilledAmount"
                        mode="decimal">
                      </p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ childrowData.BilledAmount | currency: "USD":true:"1.2-2" }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="width-7p p-text-center">
                  <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fal fa-pencil"
                                        (click)="onRowEditInit(childrowData)" class="ic-green p-button-rounded p-button-text"></button> -->
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onRowEditSave($event, childrowData, 'TimeRecords', true);saveTimeExpense($event, childrowData);"
                    class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(childrowData, ri)"
                    class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td class="width-70p p-text-right p-text-bold ic-red">{{'Totals' | translate}}</td>
                <td class="width-8p p-text-right p-text-bold ic-red">
                  <span
                    >{{ selectedParent.grandExpenseTotalAmt | currency: "USD":true:"1.2-2" }}</span
                  >
                </td>
                <td class="width-8p p-text-right p-text-bold ic-red">
                  <span [style.color]="setWriteUpDownColor(selectedParent.grandExpenseWUWDamount)">
                    <span *ngIf="selectedParent.grandExpenseWUWDamount < 0">
                      ({{ selectedParent.grandExpenseWUWDamount | currency: "USD":"symbol":"1.2-2"
                      }})
                    </span>
                    <span *ngIf="selectedParent.grandExpenseWUWDamount >= 0"
                      >{{ selectedParent.grandExpenseWUWDamount | currency: "USD":"symbol":"1.2-2"
                      }}</span
                    >
                  </span>
                </td>
                <td class="width-8p p-text-right p-text-bold ic-red">
                  <span
                    >{{ selectedParent.grandExpenseBillableAmt | currency: "USD":true:"1.2-2"
                    }}</span
                  >
                </td>
                <td class="width-7p">&nbsp;</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="10">
                  <p-message
                    severity="info"
                    text="{{'data_not_found' | translate}}."
                    styleClass="p-px-2">
                  </p-message>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </div>
    </div>
  </div>
</p-sidebar>
<!-- ScratchPad sidebar -->
<p-sidebar
  *ngIf="editableData != null"
  [(visible)]="scratchPadEnabled"
  position="bottom"
  [style]="{'min-height':'522px','background':'#8e8f92'}"
  (onHide)="onScratchpadClose()">
  <div class="card">
    <div class="control-label header-color text-nowrap p-col-12">
      <h4 class="label-text">{{ 'Private-Memo' | translate }}</h4>
    </div>
    <div class="p-col-12 col-md-12">
      <ejs-richtexteditor
        id="clientEditor"
        #clientEditor
        [(ngModel)]="editableData.PrivateMemo"
        [toolbarSettings]="tools"
        [height]="400"
        (ngModelChange)="onScratchpadChange()">
      </ejs-richtexteditor>
    </div>
  </div>
</p-sidebar>
<p-dialog
  header="Invoice History"
  [(visible)]="historyDisplay"
  [style]="{width: '63vw'}"
  [modal]="true"
  [maximizable]="false"
  showEffect="fade"
  [draggable]="true"
  [resizable]="false"
  [contentStyle]="{minHeight: '350px'}">
  <div class="card p-mt-0 p-pt-0">
    <p-table
      scrollHeight="300px"
      [scrollable]="true"
      [resizableColumns]="true"
      [rows]="10"
      [responsive]="true"
      dataKey="InvoiceNumber"
      sortField="InvoiceDate"
      [sortOrder]="-1"
      [globalFilterFields]="['InvoiceDate','InvoiceNumber','DescriptionShort','InvoiceAmount']"
      styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
      [value]="historyDataSource"
      #historydt>
      <!-- Filter -->
      <ng-template pTemplate="caption">
        <div class="table-header">
          <span class="p-input-icon-left">
            <i class="fal fa-search"></i>
            <input
              #searchHistoryValue
              pInputText
              type="text"
              (input)="historydt.filterGlobal($event.target.value, 'contains')"
              placeholder="{{'globalsearch' | translate}}" />
          </span>
          <span>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              (click)="historydt.reset();clearSearchFilter()"
              tooltipPosition="top"
              pTooltip="{{'clear' | translate}}"></button>
          </span>
        </div>
      </ng-template>
      <!-- Table Header -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="p-text-left width-15p" pSortableColumn="InvoiceDate">
            {{'Accounts.invoice-date' | translate}}
            <p-sortIcon field="InvoiceDate"></p-sortIcon>
          </th>
          <th class="p-text-left width-15p" pSortableColumn="InvoiceNumber">
            {{'Invoice' | translate}} #
            <p-sortIcon field="InvoiceNumber"></p-sortIcon>
          </th>
          <th class="width-40p p-text-left">{{'Accounts.invoice-memo' | translate}}</th>
          <th class="p-text-right width-15p" pSortableColumn="InvoiceAmount">
            {{'Accounts.invoice-amount' | translate}}
            <p-sortIcon field="InvoiceAmount"></p-sortIcon>
          </th>
          <th class="p-text-right width-15p" pSortableColumn="InvoiceBalance">
            {{'Accounts.invoice-balance' | translate}}
            <p-sortIcon field="InvoiceBalance"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <!-- Rows -->
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="p-text-left width-15p">{{ rowData.displayDate | date: "MM/dd/yyyy" }}</td>
          <td class="p-text-left width-15p">{{ rowData.InvoiceNumber }}</td>
          <td class="p-text-left width-40p p-text-nowrap p-text-truncate">
            <span
              pTooltip="{{ rowData.DescriptionShort }}"
              tooltipStyleClass="tooltip-width-450"
              tooltipPosition="top"
              >{{ rowData.DescriptionShort }}</span
            >
          </td>

          <td class="p-text-right width-15p">
            {{ rowData.InvoiceAmount | currency:'USD':true:'1.2-2' }}
          </td>
          <td class="p-text-right width-15p">
            {{ rowData.InvoiceBalance | currency:'USD':true:'1.2-2' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<p-dialog
  header="Process Payment"
  [(visible)]="dialogDisplay"
  [modal]="true"
  [style]="{width: '40vw'}"
  [maximizable]="false"
  showEffect="fade"
  position="top"
  [baseZIndex]="1"
  [draggable]="true"
  [closable]="false"
  [resizable]="false">
  <div class="p-grid">
    <div class="card p-col-12">
      <form
        role="form"
        novalidate
        [formGroup]="myPaymentForm"
        (ngSubmit)="savePaymentData(myPaymentForm.value)"
        novalidate>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6">
            <label class="required-field label-text">{{'Accounts.payment-type' | translate}}</label>
            <p-dropdown
              placeholder="{{'Accounts.payment-type' | translate}}"
              appendTo="body"
              [options]="paymentTypes"
              formControlName="paymentType"
              (onChange)="showCardOptions(myPaymentForm.get('paymentType'))"></p-dropdown>
          </div>
          <div class="p-field p-col-6">
            <label for="activity" class="required-field label-text"
              >{{'Accounts.payment-date' | translate}}</label
            >
            <p-calendar
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="2000:2090"
              formControlName="paymentDate"
              disabled="true"
              appendTo="body"></p-calendar>
          </div>
          <div
            class="p-field p-col-6"
            *ngIf="(myPaymentForm.get('paymentType').value !== 'Credit Card' && myPaymentForm.get('paymentType').value !== 'ACH') || !mangoCompanyData.isSurchargeEnabled">
            <label for="activity" class="required-field label-text"
              >{{'Accounts.payment-amount' | translate}}</label
            >
            <input
              type="text"
              pInputText
              class="p-inputtext p-component"
              formControlName="paymentAmount"
              disabled="true"
              (blur)="changeUSMoney(myPaymentForm.get('paymentAmount'), null)"
              (focus)="$event.target.select()"
              maxlength="20" />
          </div>
          <div class="p-field p-col-6" *ngIf="isCheckRef">
            <label for="activity" class="required-field label-text"
              >{{'Accounts.check-reference' | translate}}</label
            >
            <input
              type="text"
              pInputText
              class="p-inputtext p-component"
              formControlName="checkReference"
              [required]="isCheckRef ? true : null"
              (focus)="$event.target.select()" />
          </div>
          <!--  -->
          <div
            class="p-field p-col-4"
            *ngIf="isSTaxEnabled && (myPaymentForm.get('paymentType').value === 'Credit Card' || myPaymentForm.get('paymentType').value === 'ACH') && mangoCompanyData.isSurchargeEnabled">
            <label for="activity" class="label-text"
              >{{'Accounts.payment-amount' | translate}}</label
            >
            <p-inputNumber
              formControlName="paymentAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              disabled="true"
              [maxFractionDigits]="2"
              [min]="0.01"
              inputStyleClass="width-120px"
              (onBlur)="changeUSMoney(myPaymentForm.get('paymentAmount'), null);calculateSurcharge()"
              (focus)="$event.target.select()">
            </p-inputNumber>
          </div>
          <div
            class="p-field p-col-7"
            *ngIf="isSTaxEnabled &&  mangoCompanyData.isSurchargeEnabled && paymentProfile && paymentProfile['StaxPaymentMethodID'] && myPaymentForm.get('SurChargeAmount').value == 0">
            <label for="activity" class="label-text">&nbsp;</label>
            <div class="surcharge-msg">
              <p-message
                severity="info"
                text="{{'surcharge-msg' |
                            translate}}"></p-message>
            </div>
          </div>
          <div
            class="p-field p-col-4"
            *ngIf="isSTaxEnabled && myPaymentForm.get('paymentType').value === 'Credit Card' && mangoCompanyData.isSurchargeEnabled && paymentProfile && paymentProfile['StaxPaymentMethodID'] && myPaymentForm.get('SurChargeAmount').value > 0">
            <label for="activity" class="label-text">{{'surcharge' | translate}}</label>
            <p-inputNumber
              disabled="true"
              inputStyleClass="width-120px"
              formControlName="SurChargeAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              [maxFractionDigits]="2">
            </p-inputNumber>
          </div>

          <div
            class="p-field p-col-4"
            *ngIf="isSTaxEnabled && myPaymentForm.get('paymentType').value === 'Credit Card' && mangoCompanyData.isSurchargeEnabled && paymentProfile && paymentProfile['StaxPaymentMethodID'] && myPaymentForm.get('SurChargeAmount').value > 0">
            <label for="activity" class="label-text">{{'total-amount' | translate}}</label>
            <p-inputNumber
              disabled="true"
              inputStyleClass="width-120px"
              formControlName="withSurcharge"
              mode="currency"
              currency="USD"
              locale="en-US"
              [maxFractionDigits]="2">
            </p-inputNumber>
          </div>

          <div class="p-field p-col-12">
            <label for="activity" class="label-text">{{'memo' | translate}}</label>
            <app-mentions
              [rows]="3"
              [(inputText)]="myPaymentForm.controls['paymentMemo'].value"
              (inputTextChange)="myPaymentForm.controls['paymentMemo'].setValue($event);"
              style="width: 100% !important; resize: vertical"></app-mentions>
          </div>
          <div class="p-field p-col-6">
            <p-checkbox
              [binary]="true"
              label="{{'Send-Thank-You-email' | translate}}"
              class="label-text"
              [(ngModel)]="mangoCompanyData['EmailPaymentMango']"
              (onChange)="updateMangoBillingFlag()"
              [ngModelOptions]="{ standalone: true }"></p-checkbox>
          </div>
        </div>

        <p-panel
          *ngIf="showCardoption && !isStaxVerify"
          [toggleable]="true"
          styleClass="manual-invoice-panel">
          <p-header>
            {{'billing-invoicing.credit-card-information' | translate}}
            <img class="img-responsive card-icons" src="assets/img/cards.png" />
          </p-header>

          <!-- Card Details -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="paymentProfile && paymentProfile['CustomerCardID'] && !flagAddNewCard">
            <div class="p-col-12">
              <h5>{{'billing.review-card-details' | translate}}</h5>
              <h5>{{'Card-Details' | translate}}</h5>

              <div class="p-grid p-field">
                <div class="p-col-4 label-text p-text-bold">
                  {{'billing-invoicing.name-on-card' | translate}}
                </div>
                <div class="p-col-4">: {{paymentProfile.NameOnCard}}</div>
              </div>
              <div class="p-grid p-field">
                <div class="p-col-4 label-text p-text-bold">
                  {{'last-4digits-crcard' | translate}}
                </div>
                <div class="p-col-4">: {{paymentProfile.CardNo}}</div>
              </div>
              <div class="p-grid p-field">
                <div class="p-col-4 label-text p-text-bold">{{'Expiry-date' | translate}}:</div>
                <div class="p-col-4 ic-size-16">{{paymentProfile.ExpiryDate}}</div>
              </div>
            </div>
          </div>

          <!-- Credit Card Form -->
          <div
            class="p-col-12"
            *ngIf="(paymentProfile == null || paymentProfile['CustomerCardID'] == null ||  flagAddNewCard)">
            <div class="p-fluid">
              <div class="p-formgroup-inline">
                <!-- Card type -->
                <div class="p-field p-col-6 p-pl-0">
                  <label for="cardTypes" class="required-field label-text"
                    >{{'Card Type' | translate}}</label
                  >
                  <p-dropdown
                    [options]="cardTypes"
                    class="width-100"
                    formControlName="cardType"
                    (onChange)="cardTypeChange()">
                  </p-dropdown>
                </div>
                <!-- Company Name -->
                <div class="p-field p-col-12 p-pr-0">
                  <label for="cupncode" class="label-text">{{'company' | translate}}</label>
                  <input type="text" pInputText formControlName="company" readonly="true" />
                </div>
              </div>

              <div class="p-formgroup-inline">
                <!-- Card Holder Name -->
                <div class="p-field p-col-6 p-pl-0">
                  <label for="cardNumber" class="required-field label-text"
                    >{{'billing-invoicing.name-on-card' | translate}}</label
                  >
                  <div>
                    <input type="text" pInputText formControlName="nameOnCard" />
                  </div>
                </div>

                <!-- Card Number -->
                <div class="p-field p-col-12 p-pl-0">
                  <label for="cardNumber" class="required-field label-text"
                    >{{'billing-invoicing.card-number' | translate}}</label
                  >
                  <p-inputMask
                    *ngIf="isVisaCard ==='true'"
                    mask="9999-9999-9999-9999"
                    formControlName="cardno"
                    autofocus></p-inputMask>
                  <p-inputMask
                    *ngIf="isVisaCard ==='false'"
                    mask="9999-999999-99999"
                    formControlName="cardno"
                    autofocus></p-inputMask>
                </div>
              </div>

              <!-- EXP Date CVV -->
              <div class="p-formgroup-inline">
                <div class="p-field p-col-6 p-pl-0">
                  <label for="cardExpiry" class="required-field label-text"
                    ><span>{{'billing-invoicing.expiration-date' | translate}}</span></label
                  >
                  <p-inputMask mask="99/9999" formControlName="expDate" autofocus> </p-inputMask>
                </div>
                <div class="p-field p-col-6 p-pr-0">
                  <label for="cardCVC" class="label-text">CV {{'Code' | translate}}</label>
                  <p-inputMask mask="999?9" formControlName="securityCode" autofocus></p-inputMask>
                </div>
              </div>
            </div>
          </div>
        </p-panel>

        <!-- ACH Information -->
        <p-panel
          header="ACH {{'Information' | translate}}"
          styleClass="manual-invoice-panel"
          *ngIf="showAchCardoption && !isStaxVerify"
          [toggleable]="true">
          <!-- form -->
          <div *ngIf="showAchNewCardoption">
            <!-- Card type -->
            <form
              role="form"
              class="p-fluid p-formgrid p-grid"
              novalidate
              [formGroup]="myAchReceiptsForm"
              novalidate>
              <div class="p-col-12">
                <div class="p-formgroup-inline">
                  <div class="p-field p-col-6 p-pl-0">
                    <label for="acdebit" class="required-field label-text"
                      >{{'billing.Account-to-Debit' | translate}}</label
                    >
                    <p-dropdown [options]="transCodeTypes" formControlName="TransCode">
                    </p-dropdown>
                  </div>
                </div>

                <div class="p-formgroup-inline">
                  <div class="p-field p-col-6 p-pl-0">
                    <label for="fname" class="required-field label-text"
                      >{{ 'fname' | translate }}</label
                    >
                    <input type="text" pInputText formControlName="FirstName" />
                  </div>
                  <div class="p-field p-col-6 p-pr-0">
                    <label for="lname" class="required-field label-text"
                      >{{ 'lname' | translate }}</label
                    >
                    <input type="text" pInputText formControlName="LastName" />
                  </div>
                </div>

                <div class="p-formgroup-inline">
                  <div class="p-field p-col-6 p-pl-0">
                    <label for="cardNumber" class="required-field label-text"
                      >{{'RoutingNumber' | translate}}</label
                    >
                    <input type="text" pInputText formControlName="RoutingNumber" />
                  </div>
                  <div class="p-field p-col-6 p-pr-0">
                    <label for="acNumber" class="required-field label-text"
                      >{{'Account-Number' | translate}}</label
                    >
                    <input type="text" pInputText formControlName="AccountNumber" />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Details -->
          <div class="p-fluid p-formgrid p-grid" *ngIf="!showAchNewCardoption && achProfile">
            <div class="p-col-12">
              <h5>{{'billing.Bank-Account-Details' | translate}}</h5>
              <div class="p-grid p-field">
                <div class="p-col-6 label-text p-text-bold">{{'user.Name' | translate}}</div>
                <div class="p-col-6">: {{achProfile.NameOnCard}}</div>
              </div>
              <div class="p-grid p-field">
                <div class="p-col-6 label-text p-text-bold">
                  {{'last-4digits-bank' | translate}}
                </div>
                <div class="p-col-6">: {{achProfile.BankAcctNo}}</div>
              </div>
            </div>
          </div>
        </p-panel>

        <!-- Stax flow -->

        <p-panel
          *ngIf="isSTaxEnabled"
          [toggleable]="true"
          styleClass="manual-invoice-panel"
          header="{{staxHeaderTaxt}}">
          <!-- Cr Card -->
          <div class="p-fluid p-formgrid p-grid p-m-1" *ngIf="isCCFlow">
            <div class="p-col-12" *ngIf="!isStaxNewCard && sTaxProfile">
              <h5>{{'billing.review-card-details' | translate}}</h5>
              <div class="p-grid p-field">
                <div class="p-col-4 label-text p-text-bold">
                  {{'billing-invoicing.name-on-card' | translate}}
                </div>
                <div class="p-col-4">{{sTaxProfile['NameOnCard']}}</div>
              </div>
              <div class="p-grid p-field">
                <div class="p-col-4 label-text p-text-bold p-pt-1">
                  <span *ngIf="!isDebitCard">
                    <label for="uname" class="label-text"
                      >{{ 'last-4digits-crcard' | translate }}</label
                    >
                  </span>
                  <span *ngIf="isDebitCard">
                    <label for="uname" class="label-text"
                      >{{ 'last-4digits-dbcard' | translate }}</label
                    >
                  </span>
                </div>
                <div class="p-col-4">
                  ****{{sTaxProfile['CardNo']}}
                  <span
                    *ngIf="sTaxProfile['CardType'] === 'visa'"
                    tooltipPosition="top"
                    pTooltip="Visa"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap"
                    class="crcardimg">
                    <img src="assets/mango/svg/visa.svg" />
                  </span>
                  <span
                    *ngIf="sTaxProfile['CardType'] === 'mastercard'"
                    tooltipPosition="top"
                    pTooltip="Mastercard"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap"
                    class="crcardimg">
                    <img src="assets/mango/svg/mastercard.svg" />
                  </span>
                  <span
                    *ngIf="sTaxProfile['CardType'] === 'discover'"
                    tooltipPosition="top"
                    pTooltip="Discover"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap"
                    class="crcardimg">
                    <img src="assets/mango/svg/discover.svg" />
                  </span>
                  <span
                    *ngIf="sTaxProfile['CardType'] === 'amex'"
                    tooltipPosition="top"
                    pTooltip="Amex"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap"
                    class="crcardimg">
                    <img src="assets/mango/svg/amex.svg" />
                  </span>
                </div>
              </div>
            </div>
            <div class="p-col-12" *ngIf="isStaxNewCard">
              <div class="p-fluid">
                <div class="p-formgroup-inline">
                  <!-- Card type -->
                  <!-- <div class="p-field p-col-6 p-pl-0">
                                        <label for="cardTypes" class="required-field label-text">{{'Card Type' |
                                            translate}}</label>
                                        <p-dropdown [options]="cardTypes" class="width-100" formControlName="cardType"
                                            (onChange)="cardTypeChange()">
                                        </p-dropdown>
                                    </div> -->
                  <!-- Company Name -->
                  <div class="p-field p-col-12 p-ml-0 p-pl-0 p-pr-0">
                    <label for="cupncode" class="label-text">{{'company' | translate}}</label>
                    <input type="text" pInputText formControlName="company" readonly="true" />
                  </div>
                </div>

                <div class="p-formgroup-inline">
                  <!-- Card Holder Name -->
                  <div class="p-field p-col-6 p-pl-0">
                    <label for="fname" class="required-field label-text"
                      >{{ 'fname' | translate }}</label
                    >
                    <input type="text" pInputText formControlName="fname" />
                  </div>
                  <div class="p-field p-col-6 p-pr-0">
                    <label for="lname" class="required-field label-text"
                      >{{ 'lname' | translate }}</label
                    >
                    <input type="text" pInputText formControlName="lname" />
                  </div>

                  <!--address -->
                  <div class="p-field">
                    <label for="cardNumber" class="required-field label-text"
                      >{{ 'user.user_address' | translate }}</label
                    >
                    <span class="p-text-italic p-ml-2 ic-red" style="font-size: 11px"
                      >(Address must match billing address on Credit Card)</span
                    >
                    <input type="text" pInputText formControlName="address" />
                  </div>
                  <div class="p-formgroup-inline">
                    <div class="p-field p-col-4 p-pl-0">
                      <label for="cardExpiry" class="required-field label-text"
                        ><span>{{ 'client.city' | translate }}</span></label
                      >
                      <input
                        type="text"
                        formControlName="city"
                        pInputText
                        autocomplete="nope"
                        maxlength="50" />
                    </div>
                    <div class="p-field p-col-4 p-pr-0">
                      <label for="cardExpiry" class="required-field label-text"
                        ><span>{{ 'client.state' | translate }}</span></label
                      >
                      <input
                        type="text"
                        formControlName="state"
                        pInputText
                        autocomplete="nope"
                        maxlength="50" />
                    </div>
                    <div class="p-field p-col-4 p-pr-0">
                      <label class="label-text required-field"
                        >{{ 'client.zip' | translate }}</label
                      >
                      <input
                        type="text"
                        pInputText
                        class="p-text-right"
                        formControlName="zip"
                        maxlength="10"
                        (blur)="focusCard(0,'card_number')"
                        autocomplete="nope" />
                    </div>
                  </div>

                  <!-- Card Number -->
                  <div class="p-field p-col-12 p-pl-0">
                    <label for="cardNumber" class="required-field label-text"
                      >{{'billing-invoicing.card-number' | translate}}</label
                    >

                    <div id="fattjs-number" style="height: 35px"></div>
                  </div>
                </div>

                <!-- EXP Date CVV -->
                <div class="p-formgroup-inline">
                  <div class="p-field p-col-6 p-pl-0">
                    <label for="cardExpiry" class="required-field label-text"
                      ><span>{{'billing-invoicing.expiration-date' | translate}}</span></label
                    >
                    <p-inputMask
                      mask="99/9999"
                      formControlName="expDate"
                      autofocus
                      (onBlur)="focusCard(1,'cvv')">
                    </p-inputMask>
                  </div>
                  <div class="p-field p-col-6 p-pr-0">
                    <label for="cardCVC" class="label-text">CV {{'Code' | translate}}</label>
                    <div id="fattjs-cvv" style="height: 35px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ACH Card -->
          <div class="p-fluid p-formgrid p-grid p-m-1" *ngIf="!isCCFlow">
            <div class="p-col-12" *ngIf="!isStaxNewCard && sTaxProfile">
              <h5>{{'billing.Bank-Account-Details' | translate}}</h5>
              <div class="p-grid p-field">
                <div class="p-col-5 label-text p-text-bold">{{'user.Name' | translate}}</div>
                <div class="p-col-6">{{sTaxProfile['NameOnCard']}}</div>
              </div>
              <div class="p-grid p-field">
                <div class="p-col-5 label-text p-text-bold">
                  {{'last-4digits-bank' | translate}}
                </div>
                <div class="p-col-6">
                  ****{{sTaxProfile['BankAcctNo']}}
                  <span *ngIf="sTaxProfile['CardType']">
                    <i
                      class="far fa-money-check ic-green p-ml-3 ic-size-16"
                      tooltipPosition="top"
                      pTooltip="{{sTaxProfile['CardType']}}"
                      showDelay="800"
                      tooltipStyleClass="tooltip-no-wrap"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="p-col-12" *ngIf="isStaxNewCard">
              <form
                role="form"
                class="p-fluid p-formgrid p-grid"
                novalidate
                [formGroup]="myAchReceiptsForm"
                novalidate>
                <div class="p-col-12">
                  <div class="p-formgroup-inline">
                    <div class="p-field p-col-12 p-pl-0">
                      <label for="acdebit" class="required-field label-text"
                        >{{'billing.Account-to-Debit' | translate}}</label
                      >
                      <p-dropdown [options]="transCodeTypes" formControlName="TransCode">
                      </p-dropdown>
                    </div>
                    <div class="p-field p-col-6 p-pl-0">
                      <label for="fname" class="required-field label-text"
                        >{{ 'fname' | translate }}</label
                      >
                      <input type="text" pInputText formControlName="fname" />
                    </div>
                    <div class="p-field p-col-6 p-pr-0">
                      <label for="lname" class="required-field label-text"
                        >{{ 'lname' | translate }}</label
                      >
                      <input type="text" pInputText formControlName="lname" />
                    </div>
                  </div>

                  <div class="p-formgroup-inline">
                    <div class="p-field p-col-6 p-pl-0">
                      <label for="cardNumber" class="required-field label-text"
                        >{{'RoutingNumber' | translate}}</label
                      >
                      <input type="text" pInputText formControlName="RoutingNumber" />
                    </div>
                    <div class="p-field p-col-6 p-pr-0">
                      <label for="acNumber" class="required-field label-text"
                        >{{'Account-Number' | translate}}</label
                      >
                      <input type="text" pInputText formControlName="AccountNumber" />
                    </div>
                  </div>
                  <div class="p-formgroup-inline">
                    <div class="p-field p-col-6 p-pl-0">
                      <p-radioButton
                        name="groupname"
                        value="false"
                        [(ngModel)]="isBusiness"
                        [ngModelOptions]="{standalone: true}"
                        label="Personal"
                        labelStyleClass="p-pr-2">
                      </p-radioButton>
                      <p-radioButton
                        name="groupname"
                        value="true"
                        [(ngModel)]="isBusiness"
                        [ngModelOptions]="{standalone: true}"
                        label="Business">
                      </p-radioButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </p-panel>
      </form>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <!-- Buttons -->
    <div class="p-grid" *ngIf="isSTaxEnabled">
      <div class="p-col-12">
        <button
          pButton
          pRipple
          (click)="processSTaxUsingToken();"
          label="{{ 'billing-invoicing.process-payment' | translate }}"
          class="p-button-sm p-pr-3 p-mr-2"
          *ngIf="!isStaxNewCard"
          [disabled]="processAmt <= 0"
          [disabled]="isProcessing"></button>
        <button
          pButton
          pRipple
          (click)="addNewCreditCard()"
          label="{{ 'Add-New-Card' | translate }}"
          class="p-button-sm p-pr-3 p-mr-2"
          *ngIf="!isStaxNewCard && isCCFlow"
          [disabled]="!isDialogFormValid && processAmt <= 0"></button>
        <button
          pButton
          pRipple
          (click)="addNewBankCard()"
          label="{{ 'Add New Bank' | translate }}"
          class="p-button-sm p-pr-3 p-mr-2"
          *ngIf="sTaxProfile && !isStaxNewCard && !isCCFlow"
          [disabled]="!isDialogFormValid && processAmt <= 0"></button>

        <button
          pButton
          pRipple
          (click)="authorizeSTaxNewCard()"
          label="{{ 'authorize-card' | translate }}"
          class="p-button-sm p-pr-3 p-mr-2"
          [disabled]="isProcessing"
          *ngIf="isStaxNewCard && isCCFlow"></button>

        <button
          pButton
          pRipple
          (click)="authorizeSTaxNewCard()"
          label="{{ 'authorize-bank' | translate }}"
          class="p-button-sm p-pr-3 p-mr-2"
          [disabled]="isProcessing"
          *ngIf="isStaxNewCard && !isCCFlow"></button>

        <!-- <button pButton pRipple (click)="addSTaxNewCard()" [disabled]="!isDialogFormValid"
                    label="{{ 'billing-invoicing.authorize-card' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="isStaxNewCard && isCCFlow && !mangoCompanyData.isSurchargeEnabled">
                </button>

                <button pButton pRipple (click)="addSTaxNewCard()" [disabled]="!isDialogFormValid"
                    label="{{ 'authorize-bank' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="isStaxNewCard && !isCCFlow && !mangoCompanyData.isSurchargeEnabled">
                </button> -->

        <button
          pButton
          pRipple
          (click)="showStaxoption=false;closePaymentForm(false)"
          class="p-button-sm p-pr-3 p-mr-2"
          label="{{ 'cancel_button' | translate }}"></button>
      </div>
    </div>
    <div class="p-grid" *ngIf="!isSTaxEnabled">
      <div class="p-col-12 pull-right">
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-check"
          label="{{'billing-invoicing.process-payment' | translate}}"
          (click)="savePaymentData('process')"
          *ngIf="(!showCardoption || paymentProfile) && !flagAddNewCard"
          [disabled]="!isDialogFormValid"></button>
        <button
          type="button"
          *ngIf="showCardoption && !!paymentProfile && !flagAddNewCard"
          pButton
          pRipple
          icon="fal fa-plus"
          label="{{'Add-New-Card' | translate}}"
          (click)="addNewCard()"></button>
        <button
          type="button"
          *ngIf="showAchCardoption && !!achProfile && !flagAddNewCard"
          pButton
          pRipple
          icon="fal fa-plus"
          label="{{'Add-New-Card' | translate}}"
          (click)="addAchNewCard()"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-check"
          *ngIf="showCardoption && (!paymentProfile || flagAddNewCard)"
          [disabled]="!isDialogFormValid"
          label="{{'billing-invoicing.authorize-card' | translate}}"
          (click)="savePaymentData('card')"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-check"
          *ngIf="showAchCardoption && (!achProfile || flagAddNewCard)"
          [disabled]="!isDialogFormValid"
          label="{{'billing-invoicing.authorize-card' | translate}}"
          (click)="savePaymentData('card')"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-check"
          label="{{'close' | translate}} "
          (click)="closePaymentForm(false)"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
