<div class="reports-page">
  <span class="pull-right p-mr-3 clientform-btn-top">
      <button pButton pRipple label="{{ 'reports.runreport' | translate }}" [disabled]="(!isRowclicked)"
          icon="fal fa-file-chart-pie" class="p-mr-1 p-button-sm" (click)="openReport()"></button>
      <button pButton pRipple label="{{'Reset-Filters' | translate}}" [disabled]="(!isRowclicked)"
          class="p-mr-1 p-button-sm" tooltipPosition="top" (click)="initializeForm(true)"
          pTooltip="{{'Reset-Filters' | translate}}"></button>
      <button pButton pRipple label="{{ 'emaillog' | translate }}" icon="fal fa-envelope" class="p-mr-1 p-button-sm"
          (click)="showSidePanel()"></button>
  </span>
  <div class="layout-content p-grid nested-grid common-dialog">
      <div class="p-col-8 p-mr-0 p-pl-4 p-pr-2">
          <div class="p-grid">
              <div class="p-col-12">
                  <div class="box p-pt-1 p-px-2 p-pb-0 reports-tabview">
                      <p-tabView (onChange)="onTabChange($event)">
                          <p-tabPanel header="{{ 'accounting' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="accountingDataSource" [rowHover]="true" [responsive]="true"
                                  dataKey="ReportID" [rows]="12" [[filterDelay]="0" selectionMode="single"
                                  [scrollable]="true" [virtualScroll]="true" scrollHeight="380px"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  [(selection)]="selectedItem" sortField="RptDescription"
                                  [globalFilterFields]="['RptDescription']" [sortOrder]="0" #accountdt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchValue pInputText type="text"
                                                  (input)="accountdt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="accountdt.reset();clearSearchFilter()"
                                                  tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'favorite' | translate }}
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'User_Reports' | translate }}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData);orientationType = ''">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isFavorite"
                                                  class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isFavorite" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addFavourite($event,rowData);"></i>
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isUser" class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isUser" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'invoicing' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="invoiceDataSource" [rowHover]="true" [responsive]="true"
                                  [rows]="defaultPageSize" dataKey="ReportID" selectionMode="single" [pageLinks]="3"
                                  [filterDelay]="0" [scrollable]="true" scrollHeight="flex"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  sortField="RptDescription" [globalFilterFields]="['RptDescription']" [sortOrder]="0"
                                  #invoicedt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchInvoiceValue pInputText type="text"
                                                  (input)="invoicedt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="invoicedt.reset();clearSearchFilter()"
                                                  tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'favorite' | translate }}
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'User_Reports' | translate }}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isFavorite"
                                                  class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isFavorite" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addFavourite($event,rowData);"></i>
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isUser" class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isUser" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'Time_&_Expense' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="timeAndExpensiveDataSource" [rowHover]="true" [responsive]="true"
                                  [rows]="defaultPageSize" dataKey="ReportID" selectionMode="single" [pageLinks]="3"
                                  [filterDelay]="0" [scrollable]="true" scrollHeight="flex"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  sortField="RptDescription" [globalFilterFields]="['RptDescription']" [sortOrder]="0"
                                  #timedt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchTimeValue pInputText type="text"
                                                  (input)="timedt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="timedt.reset();clearSearchFilter()" tooltipPosition="top"
                                                  pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'favorite' | translate }}
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'User_Reports' | translate }}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isFavorite"
                                                  class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isFavorite" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addFavourite($event,rowData);"></i>
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isUser" class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isUser" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'Performance' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="performanceDataSource" [rowHover]="true" [responsive]="true"
                                  [rows]="defaultPageSize" dataKey="ReportID" selectionMode="single" [pageLinks]="3"
                                  [filterDelay]="0" [scrollable]="true" scrollHeight="flex"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  sortField="RptDescription" [globalFilterFields]="['RptDescription']" [sortOrder]="0"
                                  #performancedt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchPerformanceValue pInputText type="text"
                                                  (input)="performancedt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="performancedt.reset();clearSearchFilter()"
                                                  tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'favorite' | translate }}
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'User_Reports' | translate }}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isFavorite"
                                                  class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isFavorite" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addFavourite($event,rowData);"></i>
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isUser" class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isUser" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'Payroll' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="payRollDataSource" [rowHover]="true" [responsive]="true"
                                  [rows]="defaultPageSize" dataKey="ReportID" selectionMode="single" [pageLinks]="3"
                                  [filterDelay]="0" [scrollable]="true" scrollHeight="flex"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  sortField="RptDescription" [globalFilterFields]="['RptDescription']" [sortOrder]="0"
                                  #payrolldt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchPayrollValue pInputText type="text"
                                                  (input)="payrolldt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="payrolldt.reset();clearSearchFilter()"
                                                  tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'favorite' | translate }}
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'User_Reports' | translate }}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isFavorite"
                                                  class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isFavorite" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addFavourite($event,rowData);"></i>
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isUser" class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isUser" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'Master_Lists' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="masterListDataSource" [rowHover]="true" [responsive]="true"
                                  [rows]="defaultPageSize" dataKey="ReportID" selectionMode="single" [pageLinks]="3"
                                  [filterDelay]="0" [scrollable]="true" scrollHeight="380px"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  sortField="RptDescription" [globalFilterFields]="['RptDescription']" [sortOrder]="0"
                                  #masterdt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchMasterValue pInputText type="text"
                                                  (input)="masterdt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="masterdt.reset();clearSearchFilter()" tooltipPosition="top"
                                                  pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'favorite' | translate }}
                                          </th>
                                          <th class="width-15p p-text-center">{{ 'User_Reports' | translate }}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isFavorite"
                                                  class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isFavorite" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addFavourite($event,rowData);"></i>
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i *ngIf="rowData.isUser" class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                              <i *ngIf="!rowData.isUser" class="fal fa-thumbs-up ic-size-18"
                                                  (click)="addUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'Favorites' | translate}}" *ngIf="isShowAllReports">
                              <p-table [value]="allFavouriteDataSource" [rowHover]="true" [responsive]="true"
                                  [rows]="defaultPageSize" dataKey="ReportID" selectionMode="single" [pageLinks]="3"
                                  [scrollable]="true" scrollHeight="flex" [filterDelay]="0"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                  sortField="RptDescription" [globalFilterFields]="['RptDescription']" [sortOrder]="0"
                                  #favdt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchFavValue pInputText type="text"
                                                  (input)="favdt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="favdt.reset();clearSearchFilter()" tooltipPosition="top"
                                                  pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-70p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th class="width-15p p-text-center">{{'Click_to_Remove' | translate}}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-70p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td class="width-15p p-text-center">
                                              <i class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="2">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                          <p-tabPanel header="{{'User_Reports' | translate}}">
                              <p-table [value]="allReportUsersDataSource" [rowHover]="true" [responsive]="true"
                                  dataKey="ReportID" [[filterDelay]="0" selectionMode="single" [scrollable]="true"
                                  [rows]="12" scrollHeight="380px"
                                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo p-datatable-scrollable-view"
                                  [(selection)]="selectedItem" sortField="RptDescription"
                                  [globalFilterFields]="['RptDescription']" [sortOrder]="0" #userdt>
                                  <ng-template pTemplate="caption">
                                      <div class="table-header">
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchUserValue pInputText type="text"
                                                  (input)="userdt.filterGlobal($event.target.value, 'contains')"
                                                  placeholder="{{ 'globalsearch' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times"
                                                  class="p-button-rounded p-button-danger p-button-text"
                                                  (click)="userdt.reset();clearSearchFilter()" tooltipPosition="top"
                                                  pTooltip="{{ 'clear' | translate }}"></button>
                                          </span>
                                      </div>
                                  </ng-template>
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th class="width-85p" pSortableColumn="RptDescription">
                                              {{ 'Report_Name' | translate }}
                                              <p-sortIcon field="RptDescription"></p-sortIcon>
                                          </th>
                                          <th *ngIf="auth.isAllowAccess(30)" class="width-15p p-text-center">
                                              {{'Click_to_Remove' | translate}}
                                          </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData>
                                      <tr class="cursor-hand" [pSelectableRow]="rowData">
                                          <td class="width-85p p-text-left" (click)="redirectToReport(rowData)">
                                              {{rowData.RptDescription}}
                                          </td>
                                          <td *ngIf="auth.isAllowAccess(30)" class="width-15p p-text-center">
                                              <i class="fa fa-thumbs-up ic-size-18 ic-orange"
                                                  (click)="removeUserFavourite($event,rowData);"></i>
                                          </td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td [colspan]="auth.isAllowAccess(30) ? 2 : 1">
                                              <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                  styleClass="p-px-2">
                                              </p-message>
                                          </td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                          </p-tabPanel>
                      </p-tabView>
                  </div>
              </div>
              <div class="p-col-12" *ngIf="isRowclicked">
                  <div class="box">
                      <div class="p-col-12 label-text ic-size-18">{{'Report_Description' | translate}}</div>
                      <div class="p-ml-2">{{rowDesc}}</div>
                  </div>
              </div>
          </div>
      </div>
      <div class="p-col-4 p-pl-p p-mr-0" *ngIf="isRowclicked">
          <div class="box box-stretched">
              <form [formGroup]="myReportsForm" novalidate>
                  <div class="p-fluid p-formgrid p-grid">
                      <div class="p-col-12 p-d-flex p-jc-lg-between">
                          <div class="label-text ic-size-18 p-pb-4">
                              {{'Report_Filters' | translate}}
                          </div>
                      </div>
                      <div class="p-field p-col" *ngIf="showYearFilter && (reportID !== 96) && !hideDateFilters">
                          <label class="required-field label-text">{{'All_Years' | translate}}</label>
                          <p-dropdown [options]="years" formControlName="year" scrollHeight="500px" 
                              placeholder="Select Year" required></p-dropdown>
                      </div>
                      <div class="p-field p-col" *ngIf="!isARReport && !showYearFilter && !hideDateFilters && reportID !== 143">
                          <label class="required-field label-text">{{'All_Dates' | translate}}</label>
                          <p-dropdown [options]="dateRangeList" formControlName="DateRange" 
                              (onChange)="changeDateTypes()" [disabled]="isDisbleAll || !p_dateFromTo"
                              scrollHeight="500px" placeholder="Select Date" required></p-dropdown>
                      </div>
                      <div class="p-field p-col" *ngIf="!isARReport && !showYearFilter && !hideDateFilters">
                          <label class="required-field label-text">{{'date-from' | translate}}</label>
                          <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" 
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="dateFromCheck" (onSelect)="changeDate($event, 'From')"
                              formControlName="DateFrom" required>
                          </p-calendar>
                      </div>
                      <div class="p-field p-col" *ngIf="!isARReport && !showYearFilter && !hideDateFilters">
                          <label class="label-text">{{'date-to' | translate}}</label>
                          <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"  
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="dateToCheck" (onSelect)="changeDate($event, 'To')"
                              formControlName="DateTo"></p-calendar>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="isARReport">
                      <div class="p-field p-col-4 p-pl-0 p-py-0 p-my-0" style="margin-right: 0.7rem !important;">
                          <label class="label-text">{{'date-as-of' | translate}}</label>
                      </div>
                      <div class="p-field p-py-0 p-mb-3">
                          <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"  
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="dateToCheck" (onSelect)="changeDate($event, 'To')"
                          formControlName="DateTo"></p-calendar>
                      </div>
                      
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_clients">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0 p-pb-0 p-mb-0"
                          style="margin-right: 0.7rem !important;">
                          <label for="Clients" class="label-text">{{'Clients' | translate}}</label>
                      </div>
                      <div class="p-field p-mb-1 p-pb-0">
                          <p-multiSelect [options]="clientsList" [style]="{'width': '18.4em'}"
                              formControlName="ClientID" defaultLabel="{{'client.tabs.all' | translate}}"
                              (onChange)="onMultiSelectClientsChange()">
                              <ng-template let-client pTemplate="item">
                                  {{client.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_clients">
                      <div class="p-field p-col-4 p-pl-0 p-py-0 p-my-0" style="margin-right: 0.7rem !important;">
                          <label for="inclibe" class="label-text">&nbsp;</label>
                      </div>
                      <div class="p-field p-my-0 p-py-0 p-my-3">
                          <p-checkbox [(ngModel)]="isIncludeActive" binary="true"
                              [ngModelOptions]="{standalone: true}" label="{{'Include_Inactive' | translate}}"
                              (onChange)="includeActive()" [disabled]="!p_clients"></p-checkbox>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_user">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;" *ngIf="reportID !== 54">
                          <label for="Staff" class="label-text">{{'staff-name' | translate}}</label>
                      </div>
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;" *ngIf="reportID === 54">
                          <label for="Staff" class="label-text">Manager<i pTooltip="Filters at the Client level"
                                  class="fa fa-question-circle ic-blue p-ml-2" tooltipPosition="top"></i></label>
                      </div>
                      <div class="p-field p-mt-2">
                          <span *ngIf="!isShowOtherStaffsToFilter"><strong>{{staffName}}</strong></span>
                          <p-multiSelect [options]="AllStaffsTypes" [style]="{'width': '18.4em'}"
                              formControlName="StaffID" defaultLabel="All Staff" *ngIf="isShowOtherStaffsToFilter">
                              <ng-template let-staff pTemplate="item">
                                  {{staff.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_partner">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0 p-pb-0 p-mb-0"
                          style="margin-right: 0.7rem !important;">
                          <label for="Originating Partner" class="label-text">{{'Originating_Partner' |
                              translate}}<i pTooltip="Filters at the Client level"
                              class="fa fa-question-circle ic-blue p-ml-2" tooltipPosition="top" *ngIf="reportID === 54"></i></label>
                      </div>
                      <div class="p-field">
                          <span *ngIf="!isShowOtherStaffsToFilter"><strong>{{staffName}}</strong></span>
                          <p-multiSelect [options]="AllOriginatingPartners" [style]="{'width': '18.4em'}"
                              formControlName="OriginatingPartnerID" [defaultLabel]="AllOriginatingPartners?.length > 0 ? 'All Partners' : 'None Assigned'"
                              *ngIf="isShowOtherStaffsToFilter">
                              <ng-template let-staff pTemplate="item">
                                  {{staff.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_manager">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Billing Partner" class="label-text">{{'Billing_Partner' | translate}}<i pTooltip="Filters at the Client level"
                              class="fa fa-question-circle ic-blue p-ml-2" tooltipPosition="top" *ngIf="reportID === 54"></i></label>
                      </div>
                      <div class="p-field">
                          <span *ngIf="!isShowOtherStaffsToFilter"><strong>{{staffName}}</strong></span>
                          <p-multiSelect [options]="AllBillingPartners" [style]="{'width': '18.4em'}"
                              formControlName="BillingPartnerID" [defaultLabel]="AllBillingPartners?.length > 0 ? 'All Partners' : 'None Assigned'"
                              *ngIf="isShowOtherStaffsToFilter">
                              <ng-template let-staff pTemplate="item">
                                  {{staff.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_StaffTime">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Staff" class="label-text">{{'staff-name' | translate}}<i pTooltip="Filters at the Time Record Level"
                              class="fa fa-question-circle ic-blue p-ml-2" tooltipPosition="top" *ngIf="reportID === 54"></i></label>
                      </div>
                      <div class="p-field p-mt-2">
                          <span *ngIf="!isShowOtherStaffsToFilter"><strong>{{staffName}}</strong></span>
                          <p-multiSelect [options]="AllStaffsTypes" [style]="{'width': '18.4em'}"
                              formControlName="TimeStaffID" defaultLabel="All Staff" *ngIf="isShowOtherStaffsToFilter">
                              <ng-template let-staff pTemplate="item">
                                  {{staff.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_clientType">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Clients Types" class="label-text">{{'Client_Types' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="clientsTypesList" [style]="{'width': '18.4em'}"
                              formControlName="CustomerTypeID" defaultLabel="{{'All_Client_Types' | translate}}">
                              <ng-template let-client pTemplate="item">
                                  {{client.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_engagement">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Engagements" class="label-text">{{'Engagements' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="engagementsTypes" [style]="{'width': '18.4em'}"
                              formControlName="EngagementTypeID" defaultLabel="{{'All_Engagements' | translate}}">
                              <ng-template let-engagement pTemplate="item">
                                  {{engagement.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_activity">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Activity" class="label-text">{{'activity' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="activityList" [style]="{'width': '18.4em'}"
                              formControlName="ServiceCodeID" defaultLabel="{{'All_Activities' | translate}}">
                              <ng-template let-activity pTemplate="item">
                                  {{activity.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_clientGroup">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Client Groups" class="label-text">{{'Client_Groups' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="allClientGroupsDataSource" [style]="{'width': '18.4em'}"
                              formControlName="CustomerGroupCategoryID"
                              defaultLabel="{{'All_Client_Groups' | translate}}">
                              <ng-template let-activity pTemplate="item">
                                  {{activity.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_billingGroup">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Client Groups" class="label-text">{{'billing-group' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="allClientGroupsDataSource" [style]="{'width': '18.4em'}"
                              formControlName="billingGroupID"
                              defaultLabel="{{'all' | translate}} {{'billing-group' | translate}}">
                              <ng-template let-activity pTemplate="item">
                                  {{activity.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_companyLocation">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Client Groups" class="label-text">{{'company_location' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="locationsDataSource" [style]="{'width': '18.4em'}"
                              formControlName="CompanyLocationID"
                              defaultLabel="{{'all' | translate}} {{'company_location' | translate}}">
                              <ng-template let-activity pTemplate="item">
                                  {{activity.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_department">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Client Groups" class="label-text">{{'settings.Staff-Department' |
                              translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-multiSelect [options]="allStaffDepartments" [style]="{'width': '18.4em'}"
                              formControlName="departmentID"
                              defaultLabel="{{'all' | translate}} {{'settings.Staff-Department' | translate}}">
                              <ng-template let-activity pTemplate="item">
                                  {{activity.label}}
                              </ng-template>
                          </p-multiSelect>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_isCSV && !p_isPortrait">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Report Format" class="label-text">{{'Report_Format' | translate}}</label>
                      </div>
                      <div class="p-field">
                          <p-dropdown [options]="reportsList" [(ngModel)]="reportType"
                              [ngModelOptions]="{standalone: true}"></p-dropdown>
                          <i pTooltip="{{'reports.other-option' | translate}}" class="fa fa-question-circle ic-blue p-ml-2" *ngIf="reportType === 'csv_'" (click)="onRedirectToExport()" tooltipPosition="right"></i>
                      </div>
                  </div>
                  <div class="p-formgroup-inline" *ngIf="p_isPortrait">
                      <div class="p-field p-pt-2 p-col-4 p-pl-0" style="margin-right: 0.7rem !important;">
                          <label for="Orientation Format" class="label-text">Orientation Format</label>
                      </div>
                      <div class="p-field">
                          <p-dropdown [options]="orientationList" [(ngModel)]="orientationType"
                              [ngModelOptions]="{standalone: true}"></p-dropdown>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      <!-- <div class="p-col-8 p-mr-0 p-pl-4 p-pr-2" *ngIf="isRowclicked">
          <div class="card">
              <div class="p-col-12 label-text ic-size-18">{{'Report_Description' | translate}}</div>
              <div class="p-ml-2">{{rowDesc}}</div>
          </div>
      </div> -->
  </div>
  <p-sidebar [(visible)]="sidePanelDisplay" position="right"
      [style]="{'min-width':'50%','min-height':'100%','background':'#8e8f92'}">
      <div class="card" style="min-height: calc(100vh - 34px)">
          <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 label-text ic-size-18 p-pb-4">{{'emaillog' | translate}}</div>
              <div class="p-field p-col">
                  <label class="label-text">{{'date-from' | translate}}</label>
                  <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                      [(ngModel)]="dateFrom" dateFormat="mm/dd/yy" [readonlyInput]="true" [maxDate]="maxDate"
                      (onSelect)="applyDueDate();"></p-calendar>
              </div>
              <div class="p-field p-col">
                  <label class="label-text">{{'date-to' | translate}}</label>
                  <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                      [(ngModel)]="dateTo" dateFormat="mm/dd/yy" [readonlyInput]="true" [maxDate]="maxDate"
                      (onSelect)="validateToDate();"></p-calendar>
              </div>
              <div class="p-field p-col">
                  <label class="label-text">&nbsp;</label>
                  <button (click)="loadMoreRecords()" [disabled]="(logsData.length < 100)" pButton pRipple
                      type="button" label="{{'Load_Next_100' | translate}}..."
                      class="p-button-raised p-button-label p-text-bold"></button>
              </div>
          </div>
          <div class="p-col-12">
              <p-table [value]="logsData" [rowHover]="true" [responsive]="true" dataKey="message-id"
                  selectionMode="single" [filterDelay]="0" [scrollHeight]="'calc(90vh - 200px)'"
                  styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                  [globalFilterFields]="['event','nameStr','email','date']" [scrollable]="true" #maildt>
                  <ng-template pTemplate="caption">
                      <div class="table-header">
                          <span class="p-input-icon-left">
                              <i class="fal fa-search"></i>
                              <input #searchMainValue pInputText type="text"
                                  (input)="maildt.filterGlobal($event.target.value, 'contains')"
                                  placeholder="{{ 'globalsearch' | translate }}" />
                          </span>
                          <span>
                              <button pButton pRipple type="button" icon="pi pi-times"
                                  class="p-button-rounded p-button-danger p-button-text"
                                  (click)="maildt.reset();clearMailSearchFilter()" tooltipPosition="top"
                                  pTooltip="{{ 'clear' | translate }}"></button>
                          </span>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="width-20p p-text-center">{{'status' | translate}}
                          </th>
                          <th class="width-40p p-text-left" pSortableColumn="date">
                              {{'Recipient' | translate}}/{{'date' | translate}}
                              <p-sortIcon field="date"></p-sortIcon>
                          </th>
                          <th class="width-25p p-text-left">{{'email' | translate}}/{{'Subject' | translate}}
                          </th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                      <tr class="cursor-hand">
                          <td class="p-text-center width-20p">
                              <strong class="sendinEmail-open-label"
                                  *ngIf="rowData.event === 'opened'">{{rowData.event}}</strong>
                              <strong class="sendinEmail-deli-label"
                                  *ngIf="rowData.event === 'delivered'">{{'Delivered' | translate}}</strong>
                              <strong class="sendinEmail-click-label" *ngIf="rowData.event === 'clicks'">{{'Clicked' |
                                  translate}}</strong>
                              <strong class="sendinEmail-hard-label"
                                  *ngIf="rowData.event === 'hardBounces'">{{'Hard_Bounce' | translate}}</strong>
                              <strong class="sendinEmail-soft-label"
                                  *ngIf="rowData.event === 'softBounces'">{{'Soft_Bounce' | translate}}</strong>
                              <strong class="sendinEmail-deferred-label"
                                  *ngIf="rowData.event === 'deferred'">{{'Deferred' | translate}}</strong>
                              <strong class="sendinEmail-blocked-label" *ngIf="rowData.event === 'blocked'">{{'Blocked'
                                  | translate}}</strong>
                          </td>
                          <td class="p-text-left width-40p">
                              <div><strong>{{rowData.nameStr}}</strong></div>
                              <div class="ic-size-11">{{rowData.date | date:'medium'}} </div>
                          </td>
                          <td class="p-text-left width-25p">
                              <div>{{rowData.email}}</div>
                              <div>{{rowData.userNameStr}}</div>
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="3">
                              <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                              </p-message>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
      </div>
  </p-sidebar>
</div>
