<div class="center-box">
    <div class="layout-content p-mx-6 p-my-0 p-col-5">
        <p-card>
            <ng-template pTemplate="title">
                Company Settings
            </ng-template>
            <ng-template pTemplate="subtitle">
                Please review and make changes that are required
            </ng-template>
            <ng-template pTemplate="content">
                <form #companySettingsForm="ngForm">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12">
                            <div class="p-formgroup-inline">
                                <div class="p-field p-mt-5 p-col-6">
                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}"  name="autoApproveSlips" id="autoApproveSlips" #autoApproveSlips="ngModel" [(ngModel)]="timeexp.AutoApproveSlips" label="Auto Approve Time Records"
                                        class="label-text">
                                    </p-checkbox><i pTooltip="Select if Approval is not required"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        class="fa fa-question-circle p-ml-1 ic-blue"></i>
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="label-text">Billing Time Increment</label><i
                                        pTooltip="Time Increment for Billable Time" tooltipPosition="top"
                                        tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle p-ml-1 ic-blue"></i>
                                    <p-dropdown [options]="templates" [autoWidth]=false
                                        [(ngModel)]="timeexp.BillingIncrements" name="billingIncrements"
                                        id="billingIncrements">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-mt-5 p-col-6">
                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}"  name="autoApproveExpenses" id="autoApproveExpenses" #autoApproveExpenses="ngModel" [(ngModel)]="timeexp.AutoApproveExpenses" label="Auto Approve Expenses"
                                        class="label-text">
                                    </p-checkbox><i pTooltip="Select if Approval is not required" tooltipPosition="top"
                                        tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle p-ml-1 ic-blue"></i>
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="label-text">Last Invoice Number</label>
                                    <input class="required-field" type="text" name="nextInvoiceNumber"
                                        id="nextInvoiceNumber" currencyMask pInputText
                                        [options]="{ thousands: '', precision: 0 }" maxlength="10" required
                                        class="p-text-right" [(ngModel)]="invoiceOpt.NextInvoiceNumber"
                                        #clientNameVl="ngModel" required number />
                                    <div *ngIf="clientNameVl.touched && clientNameVl.errors?.required"
                                        class="ic-orange">
                                        Please enter correct invoice number</div>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-mt-5 p-col-6">
                                    <p-checkbox [binary]="true"
                                        (click)="onStatusChange(9);companySettingsForm.control.markAsDirty();"
                                        label="Collect Sales Tax on Labor" class="label-text">
                                    </p-checkbox><i pTooltip="Does your Company collect sales tax on labor?"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        class="fa fa-question-circle p-ml-1 ic-blue"></i>
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="label-text required-field">Labor Tax Rate</label>
                                    <p-inputNumber [(ngModel)]="timeexp.LaborRate1" prefix="%" placeholder="0.00"
                                        required [disabled]="!timeexp.ActivateLaborRates" maxlength="5"
                                        inputStyleClass="p-text-right" (blur)="transformlaborrate1($event)"
                                        name="laborRate1" id="laborRate1" #expenseLaborRate1="ngModel">
                                    </p-inputNumber>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-mt-5 p-col-6">
                                    <p-checkbox [binary]="true"
                                        (click)="onStatusChange(10);companySettingsForm.control.markAsDirty();"
                                        label="Collect Sales Tax on Expense?" class="label-text">
                                    </p-checkbox><i pTooltip="Does your Company collect sales tax on Expense?"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        class="fa fa-question-circle p-ml-1 ic-blue"></i>
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="label-text required-field">Expense Tax Rate</label>
                                    <p-inputNumber [(ngModel)]="timeexp.ExpenseRate1" prefix="%" placeholder="0.00"
                                        required [disabled]="!timeexp.ActivateExpenseRates" maxlength="5"
                                        inputStyleClass="p-text-right" (blur)="transformexpenserate1($event)"
                                        name="expenseRate1" id="expenseRate1" #expenseRate1="ngModel">
                                    </p-inputNumber>
                                </div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-field p-col-6 p-mt-3">
                                    <label class="label-text">Industry</label>
                                    <p-dropdown [options]="industries" [autoWidth]=false [(ngModel)]="timeexp.Industry"
                                        name="industry" id="industry">
                                    </p-dropdown>
                                </div>
                            </div>
                        <!--
                            <div class="p-formgroup-inline" *ngIf="!iShareObj.isIShareTokenVerified">
                                <div class="p-field p-col-6 p-mt-3">
                                    <div class="p-field-radiobutton">
                                        <p-radioButton inputId="input_create" name="selectedIShareIntegrationOption" [(ngModel)]="iShareObj.selectedIShareIntegrationOption" value="create"></p-radioButton>
                                        <label for="input_filled">Create a new firm on MangoShare</label>
                                    </div>
                                    <div class="p-field-radiobutton">
                                        <p-radioButton inputId="input_existing" name="selectedIShareIntegrationOption" [(ngModel)]="iShareObj.selectedIShareIntegrationOption"  value="existing"></p-radioButton>
                                        <label for="input_outlined">I have existing firm on MangoShare</label>
                                    </div>
                                </div>
                            </div>
                        -->
                            <div class="p-formgroup-inline" *ngIf="iShareObj.selectedIShareIntegrationOption === 'existing' || iShareObj.isIShareTokenVerified">
                                <div class="p-field p-col-6 p-mt-3">
                                    <label class="label-text">MangoShare Firm ID<i
                                        pTooltip="Get the Firm ID From MangoShare" tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle ic-blue question-tooltip-icon p-ml-2"
                                        tooltipPosition="right"></i></label>
                                    <input type="text" [(ngModel)]="iShareObj.firmID"
                                        [ngModelOptions]="{standalone: true}" [disabled]="iShareObj.isIShareTokenVerified" pInputText />
                                </div>
                            </div>
                            <div class="p-formgroup-inline" *ngIf="iShareObj.selectedIShareIntegrationOption === 'existing' || iShareObj.isIShareTokenVerified">
                                <div class="p-field p-col-6 p-mt-3">
                                    <label class="label-text">MangoShare API Key<i
                                        pTooltip="Paste API Key From MangoShare Settings" tooltipStyleClass="tooltip-no-wrap"
                                        class="fa fa-question-circle ic-blue question-tooltip-icon p-ml-2"
                                        tooltipPosition="right"></i></label>
                                    <input type="text" [(ngModel)]="iShareObj.vendorAPIKeyIShare"
                                        [ngModelOptions]="{standalone: true}" [disabled]="iShareObj.isIShareTokenVerified" pInputText />
                                </div>
                            </div>
                            <div class="p-formgroup-inline" *ngIf="iShareObj.selectedIShareIntegrationOption === 'existing' || iShareObj.isIShareTokenVerified">
                                <div class="p-field p-ml-2 p-mt-3">
                                    <button pButton pRipple type="button" label="{{iShareObj.isIShareTokenVerified ? 'Connected to MangoShare' : 'Connect to MangoShare'}}" icon="fal fa-external-link"
                                        class="p-button-sm p-pr-2" [disabled]="iShareObj.isIShareTokenVerified"
                                        (click)="verifyISToken(iShareObj)"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid p-nogutter p-justify-end">
                        <p-button label="Previous" (onClick)="previousStep()" class="p-pr-2" icon="pi pi-angle-left"
                            iconPos="left">
                        </p-button>
                        <p-button label="Next" (onClick)="updateTimeAndExpenses()"
                            [disabled]="!companySettingsForm.valid || (iShareObj.selectedIShareIntegrationOption === 'existing' && !iShareObj.isIShareTokenVerified)"
                            icon="pi pi-angle-right" iconPos="right">
                        </p-button>
                    </div>
                </form>
            </ng-template>
        </p-card>
    </div>
</div>