<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

    <div class="login-content">
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo-height">
                    <img src="assets/img/mango-3.svg" alt="Welcome to Mango Practice Management" />
                </div>

                <!-- Login Form -->
                <div [hidden]="isForgotPassword">
                    <div>Log in to your Account</div>
                    <form #loginForm="ngForm" (ngSubmit)="login(loginForm.valid, loginForm)" autocomplete="off">
                        <div class="forms">
                            <div class="login-input-wrapper">
                                <label for="username">Email</label>
                                <i class="pi pi-user"></i>
                                <input id="username" pInputText placeholder="Type your username..." autocomplete="off"
                                    [(ngModel)]="user.email" #email="ngModel" [ngModelOptions]="{standalone: true}"
                                    name="email" />
                            </div>

                            <div class="login-input-wrapper">
                                <div>
                                    <label for="password">Password</label>
                                    <a class="hyperlink-text pull-right"
                                        (click)="isForgotPassword = !isForgotPassword">Forget Password?</a>
                                </div>
                                <i class="pi pi-lock"></i>
                                <input type="password" id="password" placeholder="Type your password" pPassword
                                    [(ngModel)]="user.password" #password="ngModel" class="p-p-2" name="password"
                                    autocomplete="off" [ngModelOptions]="{standalone: true}" [feedback]="false" />
                            </div>

                            <div class="p-col-12">
                                <p-checkbox [(ngModel)]="rememberMe" (onChange)="onChangeRememberMe()"
                                    [ngModelOptions]="{standalone: true}" label="Remember me" value="SaveInfo"
                                    binary="true"></p-checkbox>
                                <span class="pull-right">
                                    New User?
                                    <a routerLink="/register" class="hyperlink-text">
                                        Sign Up Here
                                    </a>
                                </span>
                            </div>

                            <div class="p-my-2">
                                <re-captcha id="recaptcha" 
                                    name="recaptcha" 
                                    #recaptcha="ngModel" 
                                    [(ngModel)]="token" 
                                    required 
                                    [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                            </div>

                            <div class="buttons">
                                <button pButton type="submit" pRipple label="Login"></button>
                            </div>

                            <hr class="p-my-4" />

                            <div
                              class="
                                p-text-center
                              "
                            >
                              <img
                                class="
                                  cursor-hand
                                  login-microsoft
                                "

                                src="assets/img/ms-symbollockup_signin_light.svg"

                                alt="Sign in with Microsoft Account"

                                (click)="
                                  onMicrosoftSignIn( );
                                "
                              />
                            </div>

                            <div class="p-text-center login-margin">
                                <img src="assets/img/Mango-payments.png" class="login-image" height="40px" width="270px"
                                    alt="Welcome to Mango Practice Management" />
                            </div>
                            <h6 style="font-size: 13px;margin-top: 5px;text-align: center;">Integrated Credit Card and ACH Payments. </h6>
                        </div>
                    </form>
                </div>

                <!-- Forgot Password Form -->
                <div [hidden]="!isForgotPassword">
                    <div>Forgot Password</div>
                    <form class="p-mt-5" #forgetForm="ngForm">

                        <div class="p-fluid">
                            <div class="p-field p-text-left">
                                <label for="username" class="label-text p-text-left">Email Address</label>
                                <input pInputText type="email" autocomplete="new-password" required name="emailNew"
                                    [(ngModel)]="emailNew" #emailForgPasswd="ngModel" required email autocomplete="off"
                                    placeholder="Enter log in email" />
                            </div>

                            <div class="p-my-2">
                                <re-captcha id="forgotrecaptcha" 
                                    name="forgotrecaptcha" 
                                    #forgotrecaptcha="ngModel" 
                                    [(ngModel)]="token" 
                                    required 
                                    [class.is-invalid]="forgotrecaptcha.invalid && (forgotrecaptcha.dirty || forgotrecaptcha.touched)"></re-captcha>
                            </div>

                            <div class="p-d-flex p-jc-center p-ai-center">
                                <p-button pRipple label="Send" class="p-button-help p-mr-2" title="Send"
                                    [disabled]="!forgetForm.valid" (click)="forgetPasswd(forgetForm);forgetForm.reset()">
                                </p-button>
                                <p-button pRipple label="Cancel" class="p-button-help p-ml-2" title="Cancel"
                                    (click)="isForgotPassword = !isForgotPassword"></p-button>
                            </div>
                            <div class="p-mt-3 p-text-center hyperlink-text"
                                (click)="isForgotPassword = !isForgotPassword">
                                Return to Login In
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            <div class="desert"></div>
        </div>
        <p-dialog [(visible)]="showOTP" [modal]="true" [closeOnEscape]="true" [style]="{width: '23vw'}"
            [maximizable]="false" showEffect="fade" [baseZIndex]="1" [showHeader]="false" [draggable]="true"
            [resizable]="false">
            <!-- <ng-template pTemplate="header">
                <div></div>
            </ng-template> -->
            <!-- <div class="p-px-2 p-pb-2">
                <div class="p-text-center">
                    <img src="assets/img/2fa.png" class="p-ml-1 ic-size-30" alt="Welcome to Mango Practice Management!" />
                </div>
                <div class="p-col-12 p-px-0 p-pb-0 p-mt-3">
                    <div class="p-text-center bg-dark-orange p-mb-0 p-pb-2" style="border-radius: 3px;">
                        <i class="fas fa-mobile-alt ic-white ic-size-25 p-mt-3 p-mb-0"></i>
                        <h2 class="p-mt-3 p-mb-2 ic-white">2-Step Verification</h2>
                    </div>
                </div>
                <div class="p-col-12 p-px-3" style="border-color: #ececec; border-style: solid; border-top: none; border-width: 1px;">
                    <div class="p-col-12 p-mb-3 p-pt-4 p-pb-0 p-formgroup-inline p-px-3">
                        <div class="p-fluid p-col">
                            <input type="text" pInputText [(ngModel)]="authcode" [ngModelOptions]="{standalone: true}" class="ic-size-35 p-mb-2 p-text-center" name="authcode" autocomplete="off"
                                autofocus placeholder="Enter Code" maxlength="6" />
                        </div>
                    </div>
                    <div class="p-col-12 p-pt-0 p-text-center">
                        <button type="button" pButton pRipple label="Submit" icon="fal fa-check"
                            class="p-button-sm bg-dark-orange ic-white" (click)="onSubmitOTP()"
                            [disabled]="authcode?.length != 6"></button>
                    </div>
                </div>
            </div> -->

            <div class="p-px-2 p-pt-4 p-pb-2" style="border-color: #ececec; border-style: solid; border-width: 1px;">
                <div class="p-text-center">
                    <img src="assets/img/2fa.png" class="p-ml-1 ic-size-30" style="height: 100px;"
                        alt="Welcome to Mango Practice Management!" />
                </div>
                <div class="p-col-12 p-px-0 p-pb-0 p-text-center">
                    <h4 class="p-mt-3 p-mb-4">Two-Step Verification</h4>
                </div>
                <div class="p-col-12 p-pb-0 p-text-center">
                    <h6 class="p-mb-1">Thanks for keeping your account secure.</h6>
                    <h6 class="p-mb-1 p-mt-3">Check your <strong>mobile device</strong> for 6-digit code.</h6>
                </div>
                <div class="p-col-12 p-px-2 p-text-left">
                    <div class="p-col-12 p-fluid p-mb-3 p-pt-4 p-pb-0 p-px-3">
                        <h5 class="p-mb-2" style="font-weight: 700;">Your Verification Code</h5>
                        <input type="text" pInputText [(ngModel)]="authcode" [ngModelOptions]="{standalone: true}"
                            class="ic-size-20 p-mb-2 p-text-center" name="authcode" autocomplete="off"
                            placeholder="6-digit code" maxlength="6" />
                    </div>
                    <div class="p-col-12 p-px-3 p-pt-0 p-mb-3 p-fluid p-text-center">
                        <button type="button" pButton pRipple label="Sign In Securely" icon="fas fa-lock"
                            class="p-button-sm p-mb-2 bg-dark-orange ic-white" (click)="onSubmitOTP()"
                            [disabled]="authcode?.length != 6"></button>
                        <button type="button" pButton pRipple label="Cancel" class="p-button-link ic-orange"
                            (click)="closeOTP()"></button>
                    </div>
                </div>
                <div class="p-col-12 p-text-center">
                    <img src="assets/mango/img/MangoLogo.png" class="p-ml-1 ic-size-15" alt="Welcome to Mango Practice Management!"
                        style="height: 25px;" />
                </div>
            </div>
        </p-dialog>
    </div>
</div>

<p-dialog [showHeader]="false" [(visible)]="showEnrollDialog"
    [style]="{width: '53vw','min-height':'98%','max-height': '99%'}" [maximizable]="false" showEffect="fade"
    position="top" [baseZIndex]="10" [draggable]="true" [resizable]="false" [closable]="false" [closeOnEscape]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card stax-msg p-pt-0">
                <div class="orange-box p-p-0 p-text-center p-mb-2">
                    <div class="p-p-3 ic-size-17">
                        <div class="p-text-bold ic-white"> MangoPayments</div>
                        <div class="p-text-bold ic-white"> We take electronic payments to a NEW level! </div>
                    </div>
                </div>
                <div class="p-text-center ic-size-17">
                    <p>Benefits</p>
                </div>
                <ul>
                    <li>Completely integrated
                        with Mango
                        Billing to automatically post payments and update invoices</li>

                    <li>Email Invoices and
                        Statements to
                        clients with a customized email and buttons to allow your clients to make payments conveniently
                        on
                        any device</li>
                    <li>Low Competitive Rates
                        with NO
                        monthly or annual fees for Credit Card and ACH processing</li>
                    <li>Automatic email
                        notifications to
                        your clients and internal staff when payments are made by your clients</li>
                    <li>
                        Schedule Electronic Payments to automatically process at a specified interval
                    </li>
                    <li>Our Recurring Invoicing feature has integrated Scheduled Electronic Payments to automatically
                        get PAID,
                        generate the Invoice and Payments. We even release open time based on a cutoff date for
                        engagements that are customizable by you! Invoicing that is completely automatic!</li>
                    <li>
                        Merchant Portal with customizable reporting at your fingertips
                    </li>
                    <li>
                        New Feature just added! Surcharing. Now you can pass on a convenience fee to your clients and
                        reduce credit card fees
                    </li>
                    <li>
                        Ability to View, Void and Refund electronic payments from within the Mango Practice Management application
                    </li>
                </ul>
                <div class="p-text-center ic-red p-pb-2 ic-size-17">
                    <p>Start getting paid faster with electronic payments!</p>
                </div>
                <div style="font-weight: 500;font-style: italic;">
                    <strong>Important Notice</strong> We will no longer support the integration for <strong>USIO and
                        CPAPAY.</strong>
                    Support for these products will be turned off on 5/1. It is Recommended to enroll in MangoPayments
                    immediately to start getting the benefits of this MUCH IMPROVED integration. Approval is 24-48
                    hours! Easily start the Enrollment Application inside of Mango Practice Management.
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <span style="font-weight: 500;font-style: italic;position: relative;top: -8px;" class="p-pr-4">
            <p-checkbox [(ngModel)]="isNotShowElecPayments" binary="true" inputId="binary"></p-checkbox>
            <label for="binary" class="p-ml-2 ic-red">Don't Show Again</label>
        </span>
        <button type="button" pButton pRipple (click)="redirectToStaxEnrollScreen()" label="Enroll Now"></button>
        <button type="button" pButton pRipple label="{{ 'cancel_button' | translate }}"
            (click)="cancelEnrollDialog()"></button>
    </ng-template>
</p-dialog>
