import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { SchedulingRoutingModule } from './scheduling-routing.module';

import { SchedulingComponent } from './scheduling.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MenuModule, ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { ScheduleAllModule, ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService } from '@syncfusion/ej2-angular-schedule';
import { AppTranslationModule } from '@app/shared/modules';

@NgModule({
  declarations: [SchedulingComponent],
  imports: [
    ScheduleAllModule,
    CommonModule,
    AppSharedModule,
    SchedulingRoutingModule,
    ScheduleModule, ContextMenuModule, RecurrenceEditorModule, GridModule,MenuModule,
    AppTranslationModule
  ],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SchedulingModule { }
