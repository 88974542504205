<div class="layout-content p-pt-0">
  <div class="p-grid p-mx-2">
    <div class="p-col-4">
      <div class="my-profile-mail-integration-card card p-mt-2">
        <h4>Nylas Mail Integration</h4>
        <div class="p-d-flex p-jc-between">
          <div>
            <h5>Authentican new email</h5>
            <div class="p-my-3">
              <input pInputText class="p-p-2" type="email" name="email" [(ngModel)]="email">
            </div>
            <div class="buttons">
              <button pButton label="Authenticate" (click)="redirectForAuth()"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-8">
    <p-table [value]="emailList" [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Email</th>
          <th>Enabled</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-email>
        <tr>
          <td>{{email.email}}</td>
          <td>
            <p-checkbox 
              [binary]="true" 
              [(ngModel)]="email.is_enabled" 
              (ngModelChange)="OnChangeEnableDisableToggle(email)"
            >
            </p-checkbox>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col-11 card p-mt-2 p-p-2" *ngIf="showContactsBox">
    <h4>Email Blacklist Management</h4>
    <p-pickList 
      filterBy="email"
      [responsive]="true"
      [source]="readContacts" 
      [target]="unreadContacts" 
      [showSourceControls]="false"
      [showTargetControls]="false" 
      targetHeader="Unwanted Emails"
      sourceHeader="Preferred Emails"
      (onMoveToTarget)="onMoveToTarget($event)"
      (onMoveToSource)="onMoveToSource($event)"
      (onMoveAllToTarget)="onMoveToTarget($event)"
      (onMoveAllToSource)="onMoveToSource($event)"
    >
      <ng-template let-contact pTemplate="item">
        <div class="p-d-flex p-ai-center">
          <div>{{contact.email}}</div>
        </div>
      </ng-template>
    </p-pickList>
  </div>
</div>