<div class="assignments">
    <div class="layout-content p-pt-0">

        <div class="p-grid">
            <div class="p-col-12 p-pb-0">
                <div class="card p-mx-3 p-mt-2">

                    <label class="label-text p-mr-4">{{'Utility' | translate}} {{'import-options' | translate}}</label>
                    <p-dropdown [options]="optionsList" [(ngModel)]="selectedOptions" [style]="{'width': '25em'}"
                        scrollHeight="500px" (onChange)="handleClick($event)"></p-dropdown>
                    <button *ngIf="selectedIndex==18" pButton pRipple label="{{(!createNewContact? 'mgmt-create-new' : 'Assign') | translate}}" icon="fas fa-check" class="p-ml-3 p-button-sm"
                        (click)="toggleCreateNewContact();"></button>


                        <span class="pull-right p-mr-3">
                            <button pButton pRipple label="{{'save_button' | translate}}"
                               *ngIf="selectedIndex < 7 || selectedIndex == 8 || selectedIndex == 9 || selectedIndex == 10|| selectedIndex == 11 || selectedIndex == 12 || selectedIndex == 16 || selectedIndex == 17 || selectedIndex == 18 "
                               icon="fas fa-check" class="p-button-sm p-pr-2 p-mr-2"
                               [disabled]="isValidTabs || (selectedIndex==12 && !makeClientInactiveActive) || (selectedIndex==9 && !isEnableStmtReminder) "
                               (click)="saveData()">
                           </button>
                           <button pButton pRipple label="{{'settings.Search-and-Replace' | translate}}" *ngIf="selectedIndex == 7"
                               icon="fal fa-search" class="p-button-sm p-pr-2 p-mr-2" [disabled]="isValidTabs"
                               (click)="saveData()"></button>
                           <button pButton pRipple label="{{'process' | translate}}" *ngIf="selectedIndex == 15" icon="fal fa-check"
                               class="p-button-sm p-pr-2 p-mr-2" [disabled]="isValidTabs" (click)="onRollOverBudgets()"></button>
                           
                           <button pButton pRipple label="{{'save_button' | translate}}" *ngIf="selectedIndex == 20" icon="fal fa-check"
                               class="p-button-sm p-pr-2 p-mr-2" [disabled]="(selectedProjects.length == 0) || (selectedEngagementItems.length == 0) " 
                               (click)="saveData()"
                           ></button>
                       </span>                        

                </div>
            </div>
            
            <div class="p-col-6 p-pt-0 p-mr-0 p-pr-0"
                *ngIf="selectedIndex < 7 || selectedIndex == 8 || selectedIndex == 9 || selectedIndex == 10 || selectedIndex == 11 || selectedIndex == 12 || selectedIndex == 13 || selectedIndex == 14 || selectedIndex == 15 || selectedIndex == 16 || selectedIndex == 17|| selectedIndex == 18 || selectedIndex == 20">
                <div class="card p-mt-1 p-ml-3 p-mr-0 p-pr-0">
                    <!--  -->
                    <div class="p-col-12" *ngIf="selectedIndex == 1 || selectedIndex == -1">
                        <h5 class="ic-red">{{'settings.Default-Values-for-Engagements' | translate}}</h5> 
                        <form [formGroup]="assignmentForm" novalidate>
                            <!-- <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-4">
                                    <label class="required-field label-text">Start Date</label>
                                    <p-calendar formControlName="startDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"></p-calendar>
                                </div>
                                <div class="p-field p-col-4">
                                    <label class="label-text">Manager Assigned</label>
                                    <p-dropdown [options]="staffList" formControlName="staffID"></p-dropdown>
                                </div>
                                <div class="p-field p-col-4">
                                    &nbsp;
                                </div>
                            </div> -->
                            <div class="p-col-12">
                                <p-table [value]="engagementsTypeList" [(selection)]="selectedItems"
                                    dataKey="EngagementTypeID" [rowHover]="false" [responsive]="true" [filterDelay]="0"
                                    styleClass="p-datatable-sm p-datatable-responsive-demo" sortField="Description"
                                    [sortOrder]="-1" [rows]="engagementsTypeList.length" [showCurrentPageReport]="true"
                                    [scrollable]="true" scrollHeight="350px" (onRowSelect)="onRowSelect()"
                                    [virtualScroll]="true" #engagementlist>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="width-6p p-text-center">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th pSortableColumn="Description" class="width-22p p-text-left">
                                                {{'engagement.type' | translate}} 
                                                <p-sortIcon field="Description"></p-sortIcon>
                                            </th>
                                            <!--
                                            <th class="width-19p p-text-left">{{'engagement.name' | translate}}</th>
                                            -->
                                            <th class="width-53p p-text-left p-text-nowrap p-text-truncate"
                                                tooltipPosition="top"
                                                pTooltip="{{'settings.Invoice-Description-Narrative-Invoice' | translate}}"
                                                tooltipStyleClass="tooltip-no-wrap">
                                                {{'settings.Invoice-Description-Narrative-Invoice' | translate}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                        <tr class="cursor-hand" (click)="onRowSelect()">
                                            <td class="width-6p p-text-center">
                                                <p-tableCheckbox [value]="rowData" #checkboxTableP [index]="rowIndex"
                                                    (click)="mangoUtils.checkboxTable(checkboxTableP, $event, engagementlist)">
                                                </p-tableCheckbox>
                                            </td>
                                            <td class="width-22p p-text-left" pTooltip="{{rowData.Description}}"
                                                showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                                tooltipPosition="top">{{ rowData.Description }}</td>
                                            <!--
                                            <td class="width-19p p-text-left" pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input"
                                                        pTooltip="{{rowData.userDescription}}" showDelay="800"
                                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                                        <input type="text" pInputText
                                                            class="p-text-left p-textarea-width"
                                                            [(ngModel)]="rowData['userDescription']"
                                                            (ngModelChange)="formChanged()"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            (focus)="$event.target.select()" />
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{ rowData.userDescription }}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            -->                                            
                                            <td class="width-53p p-text-left" pEditableColumn
                                                pTooltip="{{rowData.InvoiceDescription}}" showDelay="800"
                                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input type="text" pInputText
                                                            class="p-text-left p-textarea-width"
                                                            [(ngModel)]="rowData['InvoiceDescription']"
                                                            (ngModelChange)="formChanged()"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            (focus)="$event.target.select()" />
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{ rowData.InvoiceDescription }}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </form>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 2">
                        <h5 class="ic-red">{{'Client_Types' | translate}}</h5>
                        <p-table [value]="customerTypeList" [(selection)]="selectedClientTypesObj" #typelist
                            dataKey="CustomerTypeID" [rowHover]="false" [responsive]="true" [rows]="10"
                            [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="350px" [filterDelay]="0"
                            styleClass="p-datatable-sm p-datatable-responsive-demo" [virtualScroll]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-left"></th>
                                    <th class="p-text-left">{{'Client_Types' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr class="cursor-hand" (click)="onRowSelect()">
                                    <td class="width-10p p-text-center">
                                        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                                    </td>
                                    <td class="p-text-left">{{rowData.CustomerTypeDescription}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 17">
                        <h5 class="ic-red">{{'Billing Group' | translate}}</h5>
                        <p-table [value]="groupList" [(selection)]="selectedBillingGroup" #typelist
                            dataKey="CustomerGroupCategoryID" [rowHover]="false" [responsive]="true" [rows]="10"
                            [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="350px" [filterDelay]="0"
                            styleClass="p-datatable-sm p-datatable-responsive-demo" [virtualScroll]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-left"></th>
                                    <th class="p-text-left">{{'Billing Group' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr class="cursor-hand" (click)="onRowSelect()">
                                    <td class="width-10p p-text-center">
                                        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                                    </td>
                                    <td class="p-text-left">{{rowData.GroupDescription}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 18">
                        <div class="p-col-12 p-formgroup-inline">
                            <h5 class="ic-red">{{'assign_contacts_multiple_clients' | translate}}</h5>
                        </div>
                        <form *ngIf="createNewContact" #contactMainForm="ngForm" autocomplete="off">
                            <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
                                <div class="p-col-6">
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'companyname' | translate }}</label>
                                        <input type="text" pInputText autocomplete="off" [(ngModel)]="contactObj.Company"
                                            (ngModelChange)="onRowSelect()"
                                            name="company" id="company" #company="ngModel" maxlength="60" required>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="required-field label-text">{{ 'client.contact_name' | translate }}</label>
                                        <input type="text" pInputText autocomplete="off" [(ngModel)]="contactObj.ContactName"
                                            (ngModelChange)="onRowSelect()"
                                            name="contactName" id="contactName" #contactName="ngModel" maxlength="60" required>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'contact_type' | translate }}</label>
                                        <p-dropdown [options]="contactsType"
                                            placeholder="{{ 'select' | translate }} {{ 'contact_type' | translate }}"
                                            [(ngModel)]="contactObj.CustomerContactTypeID" name="selectedContactType"
                                            id="selectedContactType" #contactType="ngModel" appendTo="body"></p-dropdown>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'client.contact_description' | translate }}</label>
                                        <input type="text" pInputText autocomplete="off" [(ngModel)]="contactObj.ContactDescription"
                                            name="contactDescription" id="contactDescription" maxlength="30">
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="label-text required-field">{{ 'email' | translate }}</label>
                                        <!-- <i *ngIf="emailExists && contactObj.Email && contactMainForm.dirty" class="fas fa-exclamation-circle ic-red"
                                            pTooltip="{{'user.email-no-longer-available' | translate}}" tooltipPosition="top"
                                            tooltipStyleClass="tooltip-no-wrap"></i> -->
                                        <!-- <i tooltipPosition="top" pTooltip="{{'company-setup.Copy-URL-to-Clipboard' | translate}}"
                                            class="{{(isEmailValid && contactObj.Email) ? 'fal fa-copy pull-right ic-green cursor-hand p-pt-2 p-pr-3' : 'fal fa-copy pull-right ic-green cursor-hand p-pt-2 p-pr-2 notAllowed low-opacity'}}"
                                            (click)="copyClipBoard(email)">
                                        </i> -->
                                        <input type="text" #email pInputText autocomplete="nope" [(ngModel)]="contactObj.Email"
                                            (ngModelChange)="isValidEmail(contactObj.Email);onRowSelect();" name="email" id="email">
                                        <div *ngIf="!isEmailValid && contactObj.Email && contactMainForm.dirty" class="p-invalid">
                                            {{'invalid_email' | translate }}</div>
                                    </div>
                                </div>
                                <div class="p-col-6">
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'work' | translate }}</label>
                                        <p-inputMask mask="(999) 999-9999? x99999" autocomplete="off" [(ngModel)]="contactObj.Office"
                                            name="office" id="office"></p-inputMask>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'Fax-Number' | translate }}</label>
                                        <p-inputMask mask="(999) 999-9999?" autocomplete="off" [(ngModel)]="contactObj.Fax" name="fax"
                                            id="fax"></p-inputMask>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'mobile' | translate }}</label>
                                        <p-inputMask mask="(999) 999-9999?" autocomplete="nope" [(ngModel)]="contactObj.Mobile"
                                            name="mobile" id="mobile" #mobile="ngModel"></p-inputMask>
                                    </div>
                                    <div class="p-field p-col-12">
                                        <label class="label-text">{{ 'home' | translate }}</label>
                                        <p-inputMask mask="(999) 999-9999?" autocomplete="off" [(ngModel)]="contactObj.Home" name="home"
                                            id="home" #home="ngModel"></p-inputMask>
                                    </div>
                                    <div class="p-field p-col-12 p-formgrid">
                                        <div class="p-col-12">
                                            <p-checkbox [binary]="true" label="{{ 'email_statements' | translate }}" class="label-text"
                                            [(ngModel)]="contactObj.isEmailStatements"
                                            class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                                            name="isEmailStatements" id="isEmailStatements"></p-checkbox>
                                        </div>
                                        <div class="p-col-12 p-mt-3">
                                            <p-checkbox [binary]="true" label="{{ 'email_invoices' | translate }}"
                                                class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                                                [(ngModel)]="contactObj.isEmailInvoices" name="isEmailInvoices" id="isEmailInvoices">
                                            </p-checkbox>
                                        </div>
                                        <div class="p-col-12 p-mt-3">
                                            <p-checkbox [binary]="true" label="{{ 'access_client_portal' | translate }}"
                                            class="{{(isEmailValid && contactObj.Email) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                                            [(ngModel)]="contactObj.isClientPortalAccess" name="isClientPortalAccess"
                                            id="isClientPortalAccess"></p-checkbox>
                                        </div>
                                        <!-- <div class="p-col-12 p-mt-3">
                                            <p-checkbox [binary]="true" label="{{ 'default_contact' | translate }}"
                                            class="{{(isEmailValid && contactObj.Email && contactObj.ContactName) ? 'label-text' : 'label-text notAllowed low-opacity'}}"
                                            [(ngModel)]="contactObj.isDefaultContact" name="isDefaultContact"
                                            id="isDefaultContact"></p-checkbox>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="p-field p-col-12">
                                    <div class="p-col-12">
                                        <label class="label-text">{{ 'memo' | translate }}</label>
                                        <app-mentions [(inputText)]="contactObj.ScratchPad"
                                            style="width:100% !important; resize: vertical;"></app-mentions>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p-table *ngIf="!createNewContact" [value]="companyContacts" [(selection)]="selectedCompanyContacts"
                            dataKey="CustomerContactID" [rowHover]="false" [responsive]="true" [rows]="10"
                            [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="350px" [filterDelay]="0"
                            styleClass="p-datatable-sm p-datatable-responsive-demo" [virtualScroll]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-6p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-7p p-text-center"></th>
                                    <th class="width-20p p-text-left">{{'client.contact_name' | translate}}</th>
                                    <th class="width-20p p-text-left">{{'client.name' | translate}}</th>
                                    <th class="width-20p p-text-left">{{'email' | translate}}</th>
                                    <th class="width-20p p-text-left">{{'contact_type' | translate}}</th>
                                    <th class="width-20p p-text-left">{{'description' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <tr class="cursor-hand">
                                    <td class="width-6p p-text-center">
                                        <p-tableCheckbox (click)="onRowSelect();toggleSelectCompanyContact(rowData);" [value]="rowData" [index]="rowIndex"></p-tableCheckbox>
                                    </td>
                                    <td class="width-7p">
                                        <div class="p-text-center">
                                            <i class="fal fa-chevron-right p-mr-2 cursor-hand"
                                                (click)="editContact(rowData)" tooltipPosition="top" pTooltip="{{ 'View' | translate}}"></i>
                                        </div>
                                    </td>
                                    <td class="width-20p p-text-left" tooltipPosition="top" pTooltip="{{rowData.ContactName}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.ContactName}}</td>
                                    <td class="width-20p p-text-left" tooltipPosition="top" pTooltip="{{rowData.ClientName}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.ClientName}}</td>
                                    <td class="width-20p p-text-left" tooltipPosition="top" pTooltip="{{rowData.Email}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.Email}}</td>
                                    <td class="width-20p p-text-left" tooltipPosition="top" pTooltip="{{rowData.ContactType}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.ContactType}}</td>
                                    <td class="width-20p p-text-left" tooltipPosition="top" pTooltip="{{rowData.ContactDescription}}" tooltipStyleClass="tooltip-no-wrap">{{rowData.ContactDescription}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 12">
                        <h5 class="ic-red">{{'assign_inactive_active_clients' | translate}}</h5>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <p-checkbox binary="true" (ngModelChange)="showInactiveOrActiveToggle();onRowSelect();"
                                    [(ngModel)]="makeClientInactiveActive" [ngModelOptions]="{standalone: true}"
                                    label="{{ showInactiveClients? 'Make Active' : 'Make Inactive'}}"
                                    class="label-text p-as-end">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 11">
                        <p-dialog
                            header="{{ customTableCompanyTemplateObj['CustomTablesCompanyID']? 'Edit' : 'Add'}}{{ ' Custom Table Template' | translate }}"
                            [(visible)]="showAddTemplate" [modal]="true" [style]="{width: '40vw',display: 'grid'}"
                            [contentStyle]="{'overflow-y': 'scroll', 'max-height': '50vh'}" showEffect="fade"
                            (onHide)="onCloseAddTemplate();" [baseZIndex]="1" [draggable]="true" [resizable]="false">
                            <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
                                <div class="p-col-12 p-formgroup-inline">
                                    <div class="p-col-6 p-mt-2">
                                        <label class="label-text required-field">{{ 'dm.Template_Name' |
                                            translate}}</label>
                                        <input type="text" pInputText autocomplete="nope"
                                            [(ngModel)]="customTableCompanyTemplateObj['TemplateName']"
                                            (ngModelChange)="customTableCompanyTemplateObj['IsChanged']=true"
                                            (focus)="$event.target.select()" autocomplete="off" maxlength="20">
                                    </div>
                                    <div class="p-col-3 p-mt-5">
                                        <p-checkbox [(ngModel)]="customTableCompanyTemplateObj['Inactive']"
                                            [binary]="true"
                                            (ngModelChange)="customTableCompanyTemplateObj['IsChanged']=true"
                                            label="{{ 'Inactive' | translate }}" class="label-text">
                                        </p-checkbox>
                                    </div>
                                </div>
                                <div class="p-col-12">
                                    <div class="p-col-12 p-mt-3"
                                        *ngIf="!customTableCompanyTemplateObj['CustomTablesCompanyID']">
                                        <p-chips placeholder="{{ 'add_descriptions' | translate }}"
                                            (onAdd)="onChipsAdd()" max="5"
                                            [(ngModel)]="customTableCompanyTemplateObj['Description']" [addOnTab]="true"
                                            [addOnBlur]="true"
                                            (ngModelChange)="customTableCompanyTemplateObj['IsChanged']=true"></p-chips>
                                    </div>
                                    <span *ngIf="customTableCompanyTemplateObj['CustomTablesCompanyID']">
                                        <div class="p-field p-col-12 p-mt-5">
                                            <label class="label-text">{{ 'description' | translate }}</label>
                                        </div>
                                        <div class="p-field p-col-12"
                                            *ngFor="let item of customTableCompanyTemplateObj['Description']; let i = index; trackBy:trackByFn">
                                            <input pInputText type="text" class="width-80p"
                                                [ngModelOptions]="{ standalone: true }"
                                                [(ngModel)]="customTableCompanyTemplateObj['Description'][i]"
                                                (ngModelChange)="customTableCompanyTemplateObj['IsChanged'] = true"
                                                autocomplete="nope">
                                            <span>
                                                <i class="fal fa-trash-alt ic-red p-ml-3 ic-size-15 cursor-hand"
                                                    aria-hidden="true" (click)="deleteRowCustomTableCompany(i)"
                                                    pTooltip="Delete" tooltipPosition="top" showDelay="800"></i>
                                            </span>
                                            <span *ngIf="customTableCompanyTemplateObj['Description'].length - 1 == i">
                                                <i class="fal fa-plus ic-blue p-ml-3 ic-size-15 cursor-hand"
                                                    aria-hidden="true" (click)="addRowCustomTableCompany()"
                                                    pTooltip="Add New" tooltipPosition="top" showDelay="800"></i>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <ng-template pTemplate="footer">
                                <button pButton pRipple
                                    label="{{ (customTableCompanyTemplateObj['CustomTablesCompanyID']? 'update_button' : 'save_button') | translate}}"
                                    (click)="saveClientCustomTable()"
                                    [disabled]="!customTableCompanyTemplateObj['IsChanged'] || customTableCompanyTemplateObj['Description'].length == 0 || !customTableCompanyTemplateObj['TemplateName'] || customTableCompanyTemplateObj['TemplateName'] === ''"
                                    icon="fal fa-check" class="p-button-sm p-pr-2 pull-right"></button>
                                <button pButton pRipple (click)="onCloseAddTemplate()"
                                    class="p-button-sm p-pr-3 p-mr-2 pull-right"
                                    label="{{ 'cancel_button' | translate }}" icon="fal fa-times">
                                </button>
                            </ng-template>
                        </p-dialog>
                        <p-table [value]="customTableTemplate" #customTableTemplates sortField="CustomTablesCompanyID"
                            [(selection)]="selectedCustomTableTemplate" dataKey="CustomTablesCompanyID"
                            [rowHover]="false" [responsive]="true" [scrollable]="true" scrollHeight="350px"
                            [filterDelay]="0" styleClass="p-datatable-sm p-datatable-responsive-demo">
                            <ng-template pTemplate="caption">
                                <div class="table-header p-grid">
                                    <h5 class="ic-red p-col-6">{{'custom_table_company_templates' | translate}}</h5>
                                    <span class="p-col-6 p-text-right">
                                        <p-checkbox [(ngModel)]="showInactiveTemplates" binary="true"
                                            (ngModelChange)="toggleInactiveTemplates()"
                                            [ngModelOptions]="{standalone: true}"
                                            label="{{ 'show_inactive' | translate }}">
                                        </p-checkbox>
                                        <button pButton pRipple label="{{'dm.Add_Template' | translate}}"
                                            (click)="openAddTemplateDialog()" icon="fal fa-plus"
                                            class="p-button-sm p-pr-2 p-ml-4"></button>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr (click)="onRowSelect()">
                                    <th class="width-4p p-text-center"></th>
                                    <th class="p-text-center width-7p">{{'Actions' | translate}}</th>
                                    <th class="p-text-left width-10p">{{'Template Name' | translate}}</th>
                                    <th class="p-text-left width-20p">{{'Descriptions' | translate}}</th>
                                    <th class="p-text-center width-5p">{{'Inactive' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr (click)="onRowSelect()" class="cursor-hand">
                                    <td class="width-4p p-text-center">
                                        <p-tableRadioButton [disabled]="rowData['Inactive']" [value]="rowData">
                                        </p-tableRadioButton>
                                    </td>
                                    <td class="p-text-center width-7p">
                                        <span>
                                            <i class="fal fa-pencil ic-green p-mr-3 ic-size-15 p-ml-1"
                                                aria-hidden="true" tooltipPosition="top"
                                                (click)="openAddTemplateDialog(rowData)"
                                                tooltipStyleClass="tooltip-width-300" showDelay="800"
                                                pTooltip="{{'Edit' | translate}}"></i>
                                        </span>
                                        <span>
                                            <i class="fal fa-trash-alt ic-red p-mr-3 ic-size-15" aria-hidden="true"
                                                (click)="deleteCompanyTemplate(rowData)" pTooltip="Delete"
                                                tooltipPosition="top" showDelay="800"></i>
                                        </span>
                                    </td>
                                    <td class="p-text-left width-10p">{{rowData.TemplateName}}</td>
                                    <td class="p-text-left width-20p" pTooltip="{{rowData.DescriptionJoin}}"
                                        tooltipPosition="top">{{rowData.DescriptionJoin}}</td>
                                    <td class="p-text-center width-5p">
                                        <i *ngIf="rowData.Inactive" class="pi pi-check ic-green"></i>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 3">

                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <label for="Billing Partner" class="label-text ic-red">
                                    {{'Staff-Assignments' | translate}}
                                </label>
                            </div>
                            <div class="p-field p-pt-2 p-pl-0 p-col-9 ic-red p-text-right">
                                <label for="Billing Partner" class="label-text ic-red">
                                    Be sure to uncheck the checkbox if no update is required.
                                </label>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <label for="Billing Partner" class="label-text">{{'Billing_Partner' |
                                    translate}}</label>
                            </div>
                            <div class="p-field">
                                <p-dropdown [options]="staffList" [(ngModel)]="editableClient['BillingPartnerID']"
                                    name="billingPartnerID" id="billingPartnerID"
                                    (onChange)="onRowSelect();formChanged()" [style]="{'width': '14em'}"></p-dropdown>
                            </div>
                            <div class="p-field p-pt-2 p-pl-2">
                                <p-checkbox [(ngModel)]="isBillingPartnerIncluded" (onChange)="formChanged()"
                                    name="isBillingPartnerIncluded" binary="true" label="{{'Include' | translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <label for="Staff Assigned" class="label-text">{{'staff-assigned' | translate}}</label>
                            </div>
                            <div class="p-field">
                                <p-dropdown [options]="staffList" [(ngModel)]="editableClient['StaffAssignedID']"
                                    name="StaffAssignedID" id="StaffAssignedID" (onChange)="onRowSelect();formChanged()"
                                    [style]="{'width': '14em'}"></p-dropdown>
                            </div>
                            <div class="p-field p-pt-2 p-pl-2">
                                <p-checkbox [(ngModel)]="isStaffAssignedIncluded" (onChange)="formChanged()"
                                    name="isStaffAssignedIncluded" binary="true" label="{{'Include' | translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <label for="Originating Partner" class="label-text">{{'Originating_Partner' |
                                    translate}}</label>
                            </div>
                            <div class="p-field">
                                <p-dropdown [options]="staffList" [(ngModel)]="editableClient['OriginatingPartnerID']"
                                    name="OriginatingPartnerID" id="OriginatingPartnerID"
                                    (onChange)="onRowSelect();formChanged()" [style]="{'width': '14em'}"></p-dropdown>
                            </div>
                            <div class="p-field p-pt-2 p-pl-2">
                                <p-checkbox [(ngModel)]="isOriginatingPartnerIncluded" (onChange)="formChanged()"
                                    name="isOriginatingPartnerIncluded" binary="true" label="{{'Include' | translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 4">
                        <h5 class="ic-red">{{'Client_Groups' | translate}}</h5>
                        <p-table [value]="groupList" [(selection)]="selectedGpItems" dataKey="CustomerGroupCategoryID"
                            [rowHover]="false" [responsive]="true" [filterDelay]="0" [rows]="10"
                            [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="350px"
                            styleClass="p-datatable-sm p-datatable-responsive-demo" sortField="GroupDescription"
                            [sortOrder]="-1" [virtualScroll]="true" #customerGroups>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-6p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th pSortableColumn="GroupDescription" class="width-50p p-text-left">
                                        {{'Client_Groups' | translate}}
                                        <p-sortIcon field="GroupDescription"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <tr class="cursor-hand" (click)="onRowSelect()">
                                    <td class="width-6p p-text-center">
                                        <p-tableCheckbox [value]="rowData" #checkboxTableP [index]="rowIndex"
                                            (click)="mangoUtils.checkboxTable(checkboxTableP, $event, customerGroups)">
                                        </p-tableCheckbox>
                                    </td>
                                    <td class="width-50p p-text-left">{{ rowData.GroupDescription }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 5">
                        <h5 class="ic-red">{{'client.late_fees' | translate}}</h5>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <p-radioButton name="group1" value="true" label="{{'none' | translate}}"
                                    [(ngModel)]="invoiceOpt.NoLateFees" (onClick)="formChanged()"
                                    labelStyleClass="radio-chechbox-label" inputId="opt1">
                                </p-radioButton>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <p-radioButton name="group1" value="false"
                                    label="{{'settings.Annual-Interest-Rate' | translate}}"
                                    [(ngModel)]="invoiceOpt.NoLateFees" (onClick)="formChanged()" inputId="opt2"
                                    labelStyleClass="radio-chechbox-label"></p-radioButton>
                            </div>
                            <div class="p-field"
                                [ngStyle]="{visibility:invoiceOpt.NoLateFees === 'false' ? 'visible' : 'hidden'}">
                                <span class="p-inputgroup">
                                    <p-inputNumber (onInput)="formChanged()" maxlength="12"
                                        inputStyleClass="p-text-right" (focus)="$event.target.select()"
                                        autocomplete="off" name="InterestRate" id="InterestRate" maxlength="10"
                                        [(ngModel)]="invoiceOpt.InterestRate" #InterestRate="ngModel" inputId="percent"
                                        mode="decimal" locale="en-US" [minFractionDigits]="2"></p-inputNumber>
                                    <span class="p-inputgroup-addon"><i class="fal fa-percent"></i></span>
                                </span>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3"
                                [ngStyle]="{display:invoiceOpt.NoLateFees === 'false' ? 'block' : 'none'}">
                                <label for="Grace Days" class="label-text">{{'client.grace_days' | translate}}</label>
                            </div>
                            <div class="p-field"
                                [ngStyle]="{display:invoiceOpt.NoLateFees === 'false' ? 'block' : 'none'}">
                                <p-inputNumber maxlength="5" (focus)="$event.target.select()" autocomplete="off"
                                    name="gracedays" id="gracedays" (onInput)="formChanged()"
                                    inputStyleClass="p-text-right" [(ngModel)]="invoiceOpt.GraceDays"
                                    #gracedays="ngModel" mode="decimal"></p-inputNumber>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-3">
                                <label for="Customer Terms" class="label-text">{{'client.customer_terms' |
                                    translate}}</label>
                            </div>
                            <div class="p-field">
                                <p-dropdown [options]="termsList" (onChange)="formChanged($event)"
                                    [(ngModel)]="invoiceOpt.TermsID" name="TermsID" id="TermsID"
                                    [style]="{'width': '14em'}"></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 6">
                        <h5 class="ic-red">{{'invoices' | translate}}</h5>
                        <h6 class="ic-blue">{{'settings.select-at-least-one' | translate}}</h6>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-4">
                                <p-checkbox [(ngModel)]="isInvoiceTemplateSelected" (onChange)="formChanged()"
                                    name="isStaffAssignedIncluded" binary="true"
                                    label="{{'client.default_invoice_template' | translate}}"
                                    labelStyleClass="radio-chechbox-label"></p-checkbox>
                            </div>
                            <div class="p-field">
                                <p-dropdown [options]="invoiceTemplates" (onChange)="formChanged()"
                                    [(ngModel)]="selectedInvoiceTemplate" name="selectedInvoiceTemplate"
                                    id="selectedInvoiceTemplate" [style]="{'width': '14em'}"></p-dropdown>
                            </div>
                        </div>
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pt-2 p-col-4">
                                <p-checkbox [(ngModel)]="isFinalizeActionSelected" (onChange)="formChanged()"
                                    name="isFinalizeActionSelected" binary="true"
                                    label="{{'settings.Default-Finalize-Action' | translate}}"
                                    labelStyleClass="radio-chechbox-label"></p-checkbox>
                            </div>
                            <div class="p-field">
                                <p-dropdown [options]="finalizeAction" (onChange)="formChanged()"
                                    [(ngModel)]="selectedfinalizeAction" name="selectedfinalizeAction"
                                    id="selectedfinalizeAction" [style]="{'width': '14em'}"></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 8">
                        <h5 class="ic-red">{{'company_locations' | translate}}</h5>
                        <p-table [value]="companyLocations" [(selection)]="selectedCompanyLocationObj" #typelist
                            dataKey="CompanyMangoLocationID" [rowHover]="false" [responsive]="true" [rows]="10"
                            [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="350px" [filterDelay]="0"
                            styleClass="p-datatable-sm p-datatable-responsive-demo" [virtualScroll]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-10p p-text-left"></th>
                                    <th class="p-text-left">{{'company_location' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr class="cursor-hand" (click)="onRowSelect()">
                                    <td class="width-10p p-text-center">
                                        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                                    </td>
                                    <td class="p-text-left">{{rowData.CompanyLocation}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 9">
                        <div class="p-col-12">
                            <h5 class="ic-red" *ngIf="!invoiceOpt.InvoiceReminderActivate">Enable Statement Reminder at
                                <a class="ic-red text-underline" routerLink="/settings/invoices">Settings > Invoices</a>
                                first
                            </h5>
                            <h5 class="ic-red" *ngIf="invoiceOpt.InvoiceReminderActivate">Statement Reminders</h5>
                        </div>
                        <div class="p-col-12">
                            <p-checkbox [(ngModel)]="isEnableStmtReminder"
                                [disabled]="!invoiceOpt.InvoiceReminderActivate" (onChange)="formChanged()"
                                name="isEnableStmtReminder" binary="true"
                                label="{{(showStatementEnabled? 'disable-statement-reminder' : 'enable-statement-reminder') | translate}}"
                                labelStyleClass="radio-chechbox-label"></p-checkbox>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 10">
                        <div class="p-col-12">
                            <h5 class="ic-red">{{'assignments.update_last_late_fee' | translate}}</h5>
                        </div>
                        <div class="p-col-12 p-formgroup-inline">
                            <label class="label-text p-pr-3">{{'billing.Last-Late-Fee-Date' | translate}}</label>
                            <p-calendar [(ngModel)]="lastLateFeeDate" (onSelect)="onSelectLastLateFee()"
                                (onBlur)="onSelectLastLateFee()" (onClearClick)="onSelectLastLateFee()"
                                [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                showButtonBar="true"></p-calendar>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 13">
                        <div class="p-col-12">
                            <p-table #dtClientNoData [value]="ClientListNoData" [rowHover]="true" [responsive]="true"
                                [rows]="10" dataKey="ClientID" selectionMode="single" [filterDelay]="0"
                                [(selection)]="selectedClientForDeletion" (onFilter)="onFilter($event)"
                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [globalFilterFields]="['ClientName']" [resizableColumns]="true" sortField="ClientName"
                                [virtualScroll]="true" [virtualRowHeight]="34" [rows]="ClientListNoData.length"
                                [scrollable]="true" scrollHeight="350px">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-7">
                                            <div class="table-header">
                                                <span class="p-input-icon-left">
                                                    <i class="fal fa-search"></i>
                                                    <input #searchValue pInputText type="text"
                                                        (input)="dtClientNoData.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="{{'globalsearch' | translate}}" />
                                                </span>
                                                <span>
                                                    <button pButton pRipple type="button" icon="pi pi-times"
                                                        class="p-button-rounded p-button-danger p-button-text"
                                                        (click)="dtClientNoData.reset();clearSearchFilter()"
                                                        tooltipPosition="top"
                                                        pTooltip="{{'clear' | translate}}"></button>
                                                </span>
                                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                                    text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                                    styleClass="p-ml-2">
                                                </p-message>

                                            </div>
                                        </div>
                                        <div class="p-col-5 p-text-right">
                                            <span>
                                                <p-checkbox [(ngModel)]="showInactiveClients13" binary="true"
                                                    (ngModelChange)="showInactiveOrActiveToggle13()"
                                                    [ngModelOptions]="{standalone: true}" label="{{ 'Show Inactive' }}"
                                                    class="p-mt-2 label-text p-as-end p-mr-3">
                                                </p-checkbox>
                                            </span>
                                            <span>
                                                <button pButton pRipple label="{{'mgmt-delete' | translate}}"
                                                    [disabled]="selectedClientForDeletion.length == 0"
                                                    icon="fal fa-trash-alt" class="p-button-sm p-pr-2"
                                                    (click)="deleteClientRecords()">
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="width-8p p-text-left">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th pSortableColumn="ClientName" pResizableColumn class="width-50p p-text-left">
                                            {{'client.name' | translate}}
                                            <p-sortIcon field="ClientName"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="ClientID" pResizableColumn class="width-35p p-text-left">
                                            {{'clientid' | translate}}
                                            <p-sortIcon field="ClientID"></p-sortIcon>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                    <tr (click)="onRowSelect()">
                                        <td class="width-8p p-text-left">
                                            <p-tableCheckbox [value]="rowData" #checkboxTableP [index]="rowIndex"
                                                (click)="mangoUtils.checkboxTable(checkboxTableP, $event, dtClientNoData)">
                                            </p-tableCheckbox>
                                        </td>
                                        <td class="width-50p p-text-left">{{rowData.ClientName }}</td>
                                        <td class="width-35p p-text-left">{{rowData.ClientID}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="3">
                                            <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                styleClass="p-px-2"></p-message>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 14">
                        <div class="p-col-12">
                            <p-table #dtEngagementNoData [value]="engagementsNoData" [rowHover]="true"
                                [responsive]="true" [rows]="10" dataKey="ProjectMasterID" selectionMode="single"
                                [filterDelay]="0" [(selection)]="selectedEngagementForDeletion"
                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [globalFilterFields]="['ClientName', 'EngagementName', 'ProjectMasterID']"
                                [resizableColumns]="true" sortField="ClientName" (onFilter)="onFilter($event)"
                                [virtualScroll]="true" [virtualRowHeight]="34" [rows]="engagementsNoData.length"
                                [scrollable]="true" scrollHeight="350px">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-7">
                                            <div class="table-header">
                                                <span class="p-input-icon-left">
                                                    <i class="fal fa-search"></i>
                                                    <input #searchValue pInputText type="text"
                                                        (input)="dtEngagementNoData.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="{{'globalsearch' | translate}}" />
                                                </span>
                                                <span>
                                                    <button pButton pRipple type="button" icon="pi pi-times"
                                                        class="p-button-rounded p-button-danger p-button-text"
                                                        (click)="dtEngagementNoData.reset();clearSearchFilter()"
                                                        tooltipPosition="top"
                                                        pTooltip="{{'clear' | translate}}"></button>
                                                </span>
                                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                                    text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                                    styleClass="p-ml-2">
                                                </p-message>

                                            </div>
                                        </div>
                                        <div class="p-col-5 p-text-right">
                                            <span>
                                                <p-checkbox [(ngModel)]="showInactivveEngagements" binary="true"
                                                    (ngModelChange)="showInactiveOrActiveEngagements()"
                                                    [ngModelOptions]="{standalone: true}" label="{{ 'Show Inactive' }}"
                                                    class="p-mt-2 label-text p-as-end p-mr-3">
                                                </p-checkbox>
                                            </span>
                                            <span>
                                                <button pButton pRipple label="{{'mgmt-delete' | translate}}"
                                                    [disabled]="selectedEngagementForDeletion.length == 0"
                                                    icon="fal fa-trash-alt" class="p-button-sm p-pr-2"
                                                    (click)="deleteEngagementsWithNoData()">
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="width-8p p-text-left">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th pSortableColumn="EngagementName" pResizableColumn
                                            class="width-40p p-text-left">
                                            {{'client.engagement' | translate}}
                                            <p-sortIcon field="EngagementName"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="ClientName" pResizableColumn class="width-30p p-text-left">
                                            {{'client.name' | translate}}
                                            <p-sortIcon field="ClientName"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="ProjectMasterID" pResizableColumn
                                            class="width-20p p-text-left">
                                            {{'exports.Project_Master_ID' | translate}}
                                            <p-sortIcon field="ProjectMasterID"></p-sortIcon>
                                        </th>
                                        <!-- <th pSortableColumn="ClientID" pResizableColumn
                                            class="width-35p p-text-left">{{'clientid' | translate}}
                                            <p-sortIcon field="ClientID"></p-sortIcon> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                    <tr (click)="onRowSelect()">
                                        <td class="width-8p p-text-left">
                                            <p-tableCheckbox [value]="rowData" #checkboxTableP [index]="rowIndex"
                                                (click)="mangoUtils.checkboxTable(checkboxTableP, $event, dtEngagementNoData)">
                                            </p-tableCheckbox>
                                        </td>
                                        <td class="width-40p p-text-left">{{rowData.EngagementName }}</td>
                                        <td class="width-30p p-text-left">{{rowData.ClientName }}</td>
                                        <td class="width-20p p-text-left">{{rowData.ProjectMasterID }}</td>
                                        <!-- <td class="width-35p p-text-left">{{rowData.ClientID}}</td> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="3">
                                            <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                styleClass="p-px-2"></p-message>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 15">
                        <div class="p-col-12">
                            <h5 class="ic-red">
                                {{'Rollover_Budgets' | translate}}
                                <i pTooltip="{{ 'utility.rollover_budgets' | translate }}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    class="fa fa-question-circle ic-blue p-ml-1 ic-size-14"></i>
                            </h5>
                        </div>
                        <div class="p-col-12 p-formgroup-inline">
                            <label class="label-text p-mt-2 p-pr-3">Select Year to Rollover</label>
                            <p-dropdown [options]="yearsList" (onChange)="formChanged()" [(ngModel)]="selectedYear"
                                name="selectedYear" id="selectedYear" [style]="{'width': '14em'}"></p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="selectedIndex == 16">
                        <h5 class="ic-red">{{'settings.Update-Sales-Tax' | translate}}</h5>
                        <div class="p-field p-col-12">
                            <!-- <p-dropdown [options]="salesTaxCalcOptions" (onChange)="formChanged($event)"
                                [(ngModel)]="SalesTaxOption" styleClass="width-100p" name="SalesTaxOption" id="SalesTaxOption"
                                [style]="{'width': '14em'}"></p-dropdown> -->

                            <p-checkbox binary="true" [(ngModel)]="isTaxable" (onChange)="formChanged($event);"
                                [ngModelOptions]="{standalone: true}" label="{{ 'taxable' | translate }}"
                                class="label-text p-mr-4">
                            </p-checkbox>

                            <p-checkbox binary="true" *ngIf="isTaxable" [(ngModel)]="isCalcTaxNoTime"
                                (onChange)="formChanged($event);" [ngModelOptions]="{standalone: true}"
                                label="{{ 'engagement.Calculate-Sales-Tax-if-NO-Time-Records' | translate }}"
                                class="label-text">
                            </p-checkbox>
                        </div>
                    </div>
                    <!-- ENGAGEMENTS FOR PROJECTS LEFT-->
                    <div class="p-col-12" *ngIf="selectedIndex == 20 ">
                        <!--                        
                        <p>{{selectedEngagementItems | json}}</p>
                        -->
                        <h5 class="ic-red">{{'settings.Default-Values-for-Engagements' | translate}} </h5> 
                        <form [formGroup]="assignmentForm" novalidate>
                            <div class="p-col-12">
                                <p-table [value]="engagementsTypeList" [(selection)]="selectedEngagementItems"
                                    dataKey="EngagementTypeID" [rowHover]="false" [responsive]="true" [filterDelay]="0"
                                    styleClass="p-datatable-sm p-datatable-responsive-demo" sortField="Description"
                                    [sortOrder]="-1" [rows]="engagementsTypeList.length" [showCurrentPageReport]="true"
                                    [scrollable]="true" scrollHeight="350px" (onRowSelect)="onRowSelect()"
                                    [virtualScroll]="true" #engagementlist>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="width-6p p-text-center">
                                                <p></p>
                                            </th>
                                            <th pSortableColumn="Description" class="width-22p p-text-left">
                                                {{'engagement.type' | translate}} 
                                                <p-sortIcon field="Description"></p-sortIcon>
                                            </th>
                                            <!--
                                            <th class="width-19p p-text-left">{{'engagement.name' | translate}}</th>
                                            
                                            <th class="width-53p p-text-left p-text-nowrap p-text-truncate"
                                                tooltipPosition="top"
                                                pTooltip="{{'settings.Invoice-Description-Narrative-Invoice' | translate}}"
                                                tooltipStyleClass="tooltip-no-wrap">
                                                {{'settings.Invoice-Description-Narrative-Invoice' | translate}}</th>
                                            -->
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                        <tr class="cursor-hand" (click)="onRowSelect()">
                                            <td class="width-6p p-text-center">
                                                <p-tableRadioButton [value]="rowData" #checkboxTableP [index]="rowIndex"
                                                    (click)="mangoUtils.radioButtonTable(checkboxTableP, $event, engagementlist); refresh20($event)">
                                                </p-tableRadioButton>
                                            </td>
                                            <td class="width-22p p-text-left" pTooltip="{{rowData.Description}}"
                                                showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                                tooltipPosition="top">{{ rowData.Description }}
                                            </td>
                                            <!--
                                            <td class="width-19p p-text-left" pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input"
                                                        pTooltip="{{rowData.userDescription}}" showDelay="800"
                                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                                        <input type="text" pInputText
                                                            class="p-text-left p-textarea-width"
                                                            [(ngModel)]="rowData['userDescription']"
                                                            (ngModelChange)="formChanged()"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            (focus)="$event.target.select()" />
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{ rowData.userDescription }}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            
                                            <td class="width-53p p-text-left" pEditableColumn
                                                pTooltip="{{rowData.InvoiceDescription}}" showDelay="800"
                                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input type="text" pInputText
                                                            class="p-text-left p-textarea-width"
                                                            [(ngModel)]="rowData['InvoiceDescription']"
                                                            (ngModelChange)="formChanged()"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            (focus)="$event.target.select()" />
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{ rowData.InvoiceDescription }}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            -->
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </form>
                    </div>
                    <!-- END ENGAGEMENTS FOR PROJECTS LEFT-->
                </div>
            </div>

            
            <div class="p-col-6 p-pt-0 p-pl-0 p-ml-0"
                *ngIf="selectedIndex < 7 || selectedIndex == 8 || selectedIndex == 9 || selectedIndex == 11 || selectedIndex == 12 || selectedIndex == 17 || selectedIndex == 18 ">
                <div class="card p-mr-3 p-mt-1 p-ml-2">
                    <p-table #dt [value]="clientsList" [rowHover]="true" [(selection)]="selectedClientsItems" [responsive]="true"
                        [rows]="10" dataKey="ClientID" selectionMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" sortField="date"
                        [globalFilterFields]="globalFilterColumns" [resizableColumns]="true" sortField="ClientName"
                        (onFilter)="onFilter($event)" [columns]="selectedColumns" (onHeaderCheckboxToggle)="onRowSelect()"
                        [virtualScroll]="true" [virtualRowHeight]="34" [rows]="clientsList.length" [scrollable]="true"
                        scrollHeight="350px">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-7 p-py-0">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                        </p-message>
                                    </div>
                                </div>
                                <div class="p-col-5 p-text-right p-py-0">
                                    <!-- <span class="pull-right ic-red p-text-bold p-mb-2">{{'Clients' | translate}}</span> -->
                                    <p-checkbox [(ngModel)]="showClientsWithNoEngagements"
                                        *ngIf="selectedIndex == 1 || selectedIndex == -1" binary="true"
                                        (ngModelChange)="showClientsWithNoEngagementsToggle()" [ngModelOptions]="{standalone: true}"
                                        label="{{ 'settings.Show_clients_with_no_eng' | translate }}"
                                        class="p-mt-2 label-text p-as-end">
                                    </p-checkbox>
                                    <p-checkbox *ngIf="selectedIndex == 12" [(ngModel)]="showInactiveClients" binary="true"
                                        (ngModelChange)="showInactiveOrActiveToggle()" [ngModelOptions]="{standalone: true}"
                                        label="{{ 'Show Inactive' }}" class="p-mt-2 label-text p-as-end">
                                    </p-checkbox>
                                    <p-checkbox *ngIf="selectedIndex == 9" [(ngModel)]="showStatementEnabled" binary="true"
                                        (ngModelChange)="showStatementEnabledToggle()" [ngModelOptions]="{standalone: true}"
                                        label="{{ 'Show Enabled' }}" class="p-mt-2 label-text p-as-end">
                                    </p-checkbox>
                                    <!-- <p-checkbox [(ngModel)]="showClientsWithTemplates" *ngIf="selectedIndex == 11" binary="true" (ngModelChange)="clientListToggleClientsWithTemplates()"
                                                    [ngModelOptions]="{standalone: true}" label="{{ 'Show Clients with Templates' | translate }}"
                                                    class="label-text p-as-end">
                                                </p-checkbox> -->
                                    <p-dropdown [options]="optionClientTemplates" *ngIf="selectedIndex == 11"
                                        [(ngModel)]="showClientsWithTemplates" class="p-as-end"
                                        (onChange)="clientCustomTableChange($event)" appendTo="body" class="p-text-left"
                                        [style]="{'width':'70%'}">
                                    </p-dropdown>

                                    <!--
                                    <p-checkbox [(ngModel)]="showClientsWithNoEngagements"
                                        *ngIf="selectedIndex == 20" binary="true"
                                        (ngModelChange)="showClientsWithNoEngagementsToggle()" [ngModelOptions]="{standalone: true}"
                                        label="{{ 'settings.Show_projects_with_no_eng' | translate }}"
                                        class="p-mt-2 label-text p-as-end">
                                    </p-checkbox>
                                    -->

                                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                        selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                                        *ngIf="selectedIndex == 8" placeholder="Choose Columns" class="pull-right">
                                    </p-multiSelect>
                                </div>
                            </div>
            
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-8p p-text-left">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th pSortableColumn="ClientName" pResizableColumn class="width-50p p-text-left">
                                    {{'client.name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="CustomerTypeDescription" pResizableColumn class="width-35p p-text-left">
                                    {{'client.type' | translate}}
                                    <p-sortIcon field="CustomerTypeDescription"></p-sortIcon>
                                <th *ngIf="selectedIndex == 11" class="width-40p p-text-left">{{'Template' | translate}}
                                </th>
                                <th pSortableColumn="ClientNumber" *ngIf="selectedIndex == 4" class="width-40p p-text-left">
                                    {{'client.client_number' | translate}}
                                    <p-sortIcon field="ClientNumber"></p-sortIcon>
                                </th>
                                <th pSortableColumn="BillingGroup" class="width-40p p-text-left">{{'billing-group' |
                                    translate}}
                                    <p-sortIcon field="BillingGroup"></p-sortIcon>
                                </th>
                                <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                            <tr (click)="onRowSelect()">
                                <td class="width-8p p-text-left">
                                    <p-tableCheckbox [value]="rowData" #checkboxTableP [index]="rowIndex"
                                        (click)="mangoUtils.checkboxTable(checkboxTableP, $event, dt)">
                                    </p-tableCheckbox>
                                </td>
                                <td class="width-50p p-text-left">{{rowData.ClientName }}</td>
                                <td class="width-35p p-text-left">{{rowData.CustomerTypeDescription}}</td>
                                <td *ngIf="selectedIndex == 11" pTooltip="{{rowData.TemplateName}}" tooltipPosition="top"
                                    class="width-40p p-text-left">{{rowData.TemplateName}}</td>
                                <td *ngIf="selectedIndex == 4" pTooltip="{{rowData.ClientNumber}}" tooltipPosition="top"
                                    class="width-40p p-text-left">{{rowData.ClientNumber}}</td>
                                <td pTooltip="{{rowData.BillingGroup}}" tooltipPosition="top" class="width-40p p-text-left">
                                    {{rowData.BillingGroup}}</td>
                                <td *ngFor="let col of columns" class="{{col.rowClass}} p-text-truncate"
                                    pTooltip="{{rowData[col.field]}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                    tooltipPosition="top">
                                    {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="3">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            
            <div class="p-col-6 p-pt-0 p-pl-0 p-ml-0" *ngIf="selectedIndex == 16">
                <div class="card p-mr-3 p-mt-1 p-ml-2">
                    <p-table #dtEng [value]="engagementsDatasource" [rowHover]="true"
                        [(selection)]="selectedEngagements" [responsive]="true" [rows]="10" dataKey="ProjectMasterID"
                        selectionMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="ClientName" sortOrder="1"
                        [globalFilterFields]="['ClientName', 'EngagementName', 'Description']" [resizableColumns]="true"
                        (onFilter)="onFilter($event)" [columns]="selectedColumns"
                        (onHeaderCheckboxToggle)="onRowSelect()" [virtualScroll]="true" [virtualRowHeight]="34"
                        [scrollable]="true" scrollHeight="350px">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-7 p-py-0">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchEngValue pInputText type="text"
                                                (input)="dtEng.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dtEng.reset();clearEngSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <p-message *ngIf="filteredEngItemsSize > -1" severity="success"
                                            text="{{filteredEngItemsSize}} {{'records_found' | translate}}."
                                            styleClass="p-ml-2">
                                        </p-message>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-8p p-text-left">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th pSortableColumn="ClientName" pResizableColumn class="width-30p p-text-left">
                                    {{'client.name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="EngagementName" pResizableColumn class="width-30p p-text-left">
                                    {{'engagement.name' | translate}}
                                    <p-sortIcon field="EngagementName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Description" pResizableColumn class="width-30p p-text-left">
                                    {{'description' | translate}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns"
                            let-rowIndex="rowIndex">
                            <tr (click)="onRowSelect()">
                                <td class="width-8p p-text-left">
                                    <p-tableCheckbox [value]="rowData" #checkboxTableEng [index]="rowIndex"
                                        (click)="mangoUtils.checkboxTable(checkboxTableEng, $event, dtEng)">
                                    </p-tableCheckbox>
                                </td>
                                <td class="width-30p p-text-left">{{rowData.ClientName }}</td>
                                <td class="width-30p p-text-left">{{rowData.EngagementName }}</td>
                                <td class="width-30p p-text-left">{{rowData.Description }}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <div class="p-col-12 p-pt-0" *ngIf="selectedIndex == 7">
                <div class="card p-m-3">
                    <h5 class="ic-red">{{'settings.Update-Engagement-Default-Invoice-Description' | translate}}</h5>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-pt-2 p-col-2">
                            <label for="Engagement Name" class="label-text">{{'engagement.name' | translate}}</label>
                        </div>
                        <div class="p-field p-col-7">
                            <input type="text" pInputText autocomplete="off" class="p-text-left"
                                [(ngModel)]="engagementName" (ngModelChange)="validateEngagement();formChanged()"
                                name="engagementName" id="engagementName" [style]="{'width': '18em'}">
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-pt-2 p-col-2">
                            <label for="New Invoice Description" class="label-text">{{'settings.New-Invoice-Description'
                                | translate}}</label>
                        </div>
                        <div class="p-field p-col-9">
                            <app-mentions [(inputText)]="descInvoice" [rows]="3" (inputTextChange)="formChanged();validateEngagement();"
                                style="width:100% !important; resize: vertical;"></app-mentions>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-col-6 p-pt-0 p-pl-0 p-ml-0" *ngIf="selectedIndex == 20">
                <div class="card p-mr-3 p-mt-1 p-ml-2">
                    <p-table #dtEng [value]="projectsWithNoEngagement" [rowHover]="true"
                        [(selection)]="selectedProjects" [responsive]="true" [rows]="50" dataKey="ProjectHeaderID"
                        selectionMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="TemplateName" sortOrder="1"
                        [globalFilterFields]="['TemplateName', 'ProjectHeaderID']" [resizableColumns]="true"
                        (onFilter)="onFilter($event)" [columns]="selectedColumns"
                        (onHeaderCheckboxToggle)="onRowSelect()" [virtualScroll]="true" [virtualRowHeight]="34"
                        [scrollable]="true" scrollHeight="350px">
                        <ng-template pTemplate="caption">
                            <div class="p-grid">
                                <div class="p-col-7 p-py-0">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchEngValue pInputText type="text"
                                                (input)="dtEng.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dtEng.reset();clearEngSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <p-message *ngIf="filteredEngItemsSize > -1" severity="success"
                                            text="{{filteredEngItemsSize}} {{'records_found' | translate}}."
                                            styleClass="p-ml-2">
                                        </p-message>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-8p p-text-left">
                                    <!--
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    -->
                                    <p></p>
                                </th>
                                <th pSortableColumn="TemplateName" pResizableColumn class="width-30p p-text-left">
                                    {{'project-name' | translate}}
                                    <p-sortIcon field="TemplateName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ClientName" pResizableColumn class="width-30p p-text-left">
                                    {{'client-name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>


                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns"
                            let-rowIndex="rowIndex">
                            <tr (click)="onRowSelect()">
                                <td class="width-8p p-text-left">
                                    <p-tableCheckbox [value]="rowData" #checkboxTableEng [index]="rowIndex"
                                        (click)="mangoUtils.checkboxTable(checkboxTableEng, $event, dtEng)">
                                    </p-tableCheckbox>
                                </td>
                                <td class="width-30p p-text-left">{{rowData.TemplateName }}</td>
                                <td class="width-30p p-text-left">{{rowData.ClientName }}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>
</div>

