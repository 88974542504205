<app-times-entry *ngIf="_sharedDialogSvc.sharedCompVisibility.timesEntry" (closeTimeEntryModal)="closeTimeEntryModal()"></app-times-entry>
<!-- <ng-container #timesEntryContainer></ng-container> -->
<app-expense-entry *ngIf="_sharedDialogSvc.sharedCompVisibility.expenseEntry" (closeExpenseEntryModal)="closeExpenseEntryModal()"></app-expense-entry>
<app-engagement *ngIf="_sharedDialogSvc.sharedCompVisibility.engagement" (closeEngagementModal)="closeEngagementModal()"></app-engagement>
<app-notes *ngIf="_sharedDialogSvc.sharedCompVisibility.notes" (closeNotesModal)="closeNotesModal()"></app-notes>
<app-shortcuts *ngIf="_sharedDialogSvc.sharedCompVisibility.shortcuts" (closeShortcutsModal)="closeShortcutsModal()"></app-shortcuts>
<app-contact *ngIf="_sharedDialogSvc.sharedCompVisibility.contact" (closeContactModal)="closeContactModal()"></app-contact>
<app-task *ngIf="_sharedDialogSvc.sharedCompVisibility.task" (closeTaskModal)="closeTaskModal()"></app-task>
<app-invoice *ngIf="_sharedDialogSvc.sharedCompVisibility.invoice" (closeTaskModal)="closeInvoiceModal()"></app-invoice>
<app-activity *ngIf="_sharedDialogSvc.sharedCompVisibility.activity" (closeActivityModal)="closeActivityModal()"></app-activity>
<app-expenses *ngIf="_sharedDialogSvc.sharedCompVisibility.expenses" (closeExpensesModal)="closeExpensesModal()"></app-expenses>
<app-to-do *ngIf="_sharedDialogSvc.sharedCompVisibility.toDo"></app-to-do>
<app-timer *ngIf="_sharedDialogSvc.sharedCompVisibility.timer"></app-timer>
<app-language-keys *ngIf="_sharedDialogSvc.sharedCompVisibility.languageKeys"></app-language-keys>
<app-inactivity  *ngIf="_sharedDialogSvc.sharedCompVisibility.inactivity"></app-inactivity>
<app-upcoming-events  *ngIf="_sharedDialogSvc.sharedCompVisibility.upcomingEvents"></app-upcoming-events>
