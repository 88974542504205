<div class="update-history">
  <div class="layout-content p-pt-0">
    <span class="pull-right p-mr-3 clientform-btn-top">
      <button
        (click)="onAddHistoryDialog()"
        type="button"
        *ngIf="canUpdateHistory"
        pButton
        pRipple
        label="{{ 'Add_Update_History' | translate }}"
        icon="fal fa-plus"
        class="p-button-sm p-mr-2"></button>
    </span>
    <div class="p-mx-2">
      <div class="p-col-12 p-py-0">
        <div class="card p-py-3">
          <div class="p-formgroup-inline">
            <div class="p-field p-ml-3 p-mb-0 p-p-2">
              <label class="label-text p-mr-2">{{'All_Dates' | translate}}</label>
              <p-dropdown
                [options]="dateRangeList"
                [style]="{width: '140px'}"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="DateRange"
                (onChange)="changeDateTypes('history')"
                scrollHeight="500px"
                placeholder="Select Date"
                required></p-dropdown>
            </div>
            <div class="p-field p-mb-0 p-p-2">
              <label class="label-text p-mx-2">{{'date-from' | translate}}</label>
              <p-calendar
                [monthNavigator]="true"
                [ngModelOptions]="{standalone: true}"
                [yearNavigator]="true"
                yearRange="2000:2090"
                (onSelect)="getUpdateHistoryList()"
                (onBlur)="getUpdateHistoryList()"
                showButtonBar="true"
                [(ngModel)]="DateFrom"
                [style]="{width: '100px'}"
                [disabled]="DateRange !== 'custom'"
                required>
              </p-calendar>
            </div>
            <div class="p-field p-ml-3 p-mb-0 p-p-2">
              <label class="label-text p-mx-2">{{'date-to' | translate}}</label>
              <p-calendar
                [monthNavigator]="true"
                [ngModelOptions]="{standalone: true}"
                [yearNavigator]="true"
                yearRange="2000:2090"
                (onSelect)="getUpdateHistoryList()"
                (onBlur)="getUpdateHistoryList()"
                showButtonBar="true"
                [(ngModel)]="DateTo"
                [style]="{width: '100px'}"
                [disabled]="DateRange !== 'custom'"></p-calendar>
            </div>

            <div *ngIf="false" class="p-field p-ml-3 p-mb-0 p-p-2">
              <button
                pButton
                pRipple
                type="button"
                class="p-button-sm p-mr-2"
                icon="fal fa-plus"
                label="Feature Request / Report a Bug"
                (click)="onShowRequestSidebar()"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-pl-0 p-pr-0 p-mt-0 p-grid" style="padding-bottom: 100px">
        <div class="p-col-12 p-px-0 p-pt-0">
          <h4 class="p-ml-3 ic-red">New Features / Enhancements / Fixes</h4>
          <div
            class="p-card p-px-4 p-pb-3 p-pt-3 p-mb-2 p-ml-3"
            *ngIf="typeOneDatasource.length == 0">
            <div
              innerHTML="{{'data_not_found' | translate}}."
              style="font-weight: 400"
              class="p-py-4 html-content ic-size-16"></div>
          </div>
          <div
            class="card p-pl-0 p-pt-0"
            style="
              overflow-y: auto;
              min-height: 100%;
              max-height: calc(80vh - 50px);
              background-color: #eceff4;
            ">
            <p-timeline [value]="typeOneDatasource" align="right" styleClass="customized-timeline">
              <ng-template pTemplate="marker" let-update>
                <span
                  class="custom-marker ic-white p-shadow-2"
                  [style.backgroundColor]="'#ff9f1a'"
                  style="
                    border-radius: 30px;
                    height: 30px;
                    width: 30px;
                    box-shadow: 2px 2px 5px #7b7b7b;
                    text-align: center;
                    padding: 7.3px;
                  ">
                  <i class="fal fa-tools ic-size-15"></i>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-update>
                <p-card class="timeline-card">
                  <ng-template pTemplate="title">
                    <span class="ic-size-20 flag" [ngStyle]="{backgroundColor: '#ff9f1a' }">
                      {{ update.Ddate | date:'MM/dd/yyyy' }}
                    </span>
                    <span class="pull-right" *ngIf="canUpdateHistory">
                      <i
                        class="fal fa-pencil p-mr-2 cursor-hand ic-green ic-size-15"
                        (click)="editUpdateHistory(update)"
                        tooltipPosition="top"
                        pTooltip="{{ 'Edit' | translate }}"></i>
                      <i
                        (click)="deleteUpdateHistory(update)"
                        class="fal fa-trash-alt ic-red cursor-hand p-pl-2 ic-size-15"
                        tooltipPosition="top"
                        pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                    </span>
                  </ng-template>
                  <ng-template pTemplate="content">
                    <div
                      [innerHTML]="decodeHtml(update.Description)"
                      style="font-weight: 400"
                      class="p-text-left html-content ic-size-16"></div>
                  </ng-template>
                </p-card>
              </ng-template>
            </p-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-dialog
    [(visible)]="isShowHistoryDialog"
    *ngIf="isShowHistoryDialog"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [contentStyle]="{overflow: 'visible'}"
    [baseZIndex]="1"
    [maximizable]="false"
    showEffect="fade"
    [draggable]="true"
    [resizable]="false">
    <p-header>
      {{ ( isEdit ? "Edit_Update_History" : "Add_Update_History") | translate }}
    </p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-2 p-mt-4">
          <label class="label-text">{{ "date" | translate }}</label>
        </div>
        <div class="p-col-4 p-mt-4">
          <p-calendar
            [monthNavigator]="true"
            [ngModelOptions]="{standalone: true}"
            [yearNavigator]="true"
            yearRange="2000:2090"
            showButtonBar="true"
            [(ngModel)]="historyObj.Date"
            [style]="{width: '150px'}">
          </p-calendar>
        </div>
        <div class="p-col-1 p-mt-4">
          <label class="label-text">{{ "time.type" | translate }}</label>
        </div>
        <div class="p-col-5 p-mt-4">
          <p-dropdown
            [options]="typeList"
            [style]="{width: '100%'}"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="updateType"
            scrollHeight="500px"
            placeholder="Select Update Type"
            required></p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-2 p-mt-4">
          <label class="required-field label-text">{{ "description" | translate }}</label>
        </div>
        <div class="p-col-10 p-mt-4">
          <ejs-richtexteditor
            #notesEditor
            [enableHtmlEncode]="true"
            [enableResize]="false"
            [height]="'calc(40vh - 100px)'"
            [(value)]="historyObj.Description"
            [saveInterval]="200"
            [autoSaveOnIdle]="true">
          </ejs-richtexteditor>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-check"
          label="{{ 'Save' | translate }}"
          (click)="onSaveHistory()"
          [disabled]="!(historyObj.Description && historyObj.Date)"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-times"
          label="{{ 'cancel' | translate }}"
          (click)="onCloseHistoryDialog()"></button>
      </div>
    </ng-template>
  </p-dialog>

  <p-sidebar
    [(visible)]="isShowRequestSidebar"
    *ngIf="isShowRequestSidebar"
    position="right"
    [style]="{'min-width':'40%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
    <form [formGroup]="requestForm" autocomplete="nope">
      <div class="card p-pt-4" style="min-height: calc(40vh - 35px)">
        <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual p-fluid p-formgrid p-formgroup-inline">
          <div class="p-col-6 p-pl-0 ic-size-15 p-formgroup-inline">
            <label class="p-col-12 label-text p-mr-4 p-pr-0 p-pb-0">{{ 'date' | translate }}</label>
            <p-calendar
              class="p-col-12"
              [style]="{width: '100%'}"
              formControlName="Ddate"
              [disabled]="true"
              autocomplete="nope"></p-calendar>
          </div>
          <div class="p-col-6 p-pl-0 ic-size-15 p-pl-2 p-pr-3 p-formgroup-inline">
            <label class="p-col-12 label-text p-mr-5 p-pl-0 p-pr-1 p-pb-0"
              >{{ 'firm_name' | translate }}</label
            >
            <input
              type="text"
              class="p-col-12 p-mt-2"
              formControlName="FirmName"
              disabled
              pInputText
              autocomplete="nope" />
          </div>
          <div class="p-col-6 p-pl-0 ic-size-15 p-formgroup-inline">
            <label class="p-col-12 label-text p-mr-5 p-pr-1 p-pb-0"
              >{{ 'contact_number' | translate }}</label
            >
            <p-inputMask
              mask="(999) 999-9999? x99999"
              class="p-col-12"
              formControlName="ContactNumber"
              autocomplete="nope"></p-inputMask>
          </div>
          <div class="p-col-6 p-pl-0 ic-size-15 p-formgroup-inline">
            <label class="p-col-12 label-text p-mr-6 p-pr-0 p-pb-0"
              >{{ 'time.type' | translate }}</label
            >
            <p-dropdown
              [options]="requestList"
              class="p-col-12"
              [style]="{width: '100%'}"
              formControlName="Type"
              scrollHeight="500px"
              placeholder="Select Type"
              required></p-dropdown>
          </div>
          <div class="p-col-12 p-pl-2 p-pr-3 ic-size-15">
            <label class="label-text p-mr-6 p-pr-0">{{ 'description' | translate }}</label>
            <mwl-text-input-autocomplete-container>
              <textarea
                pInputTextarea
                class="p-mt-2 p-text-left"
                style="width: 100% !important; resize: vertical"
                formControlName="Description"
                id="desc"
                (focus)="$event.target.select()"
                [rows]="3"
                mwlTextInputAutocomplete
                appendTo="body"
                [findChoices]="mangoUtils.findChoices"
                [getChoiceLabel]="mangoUtils.getChoiceLabel"
                #textarea></textarea>
            </mwl-text-input-autocomplete-container>
          </div>
        </div>
        <div class="p-d-flex p-jc-end p-pr-3 p-mt-6 filter-btns">
          <button
            type="button"
            pButton
            pRipple
            icon="fal fa-check"
            class="p-mr-2"
            [disabled]="!isFormValid"
            (click)="onSubmitRequest()"
            label="{{ 'submit' | translate }}"></button>
          <button
            type="button"
            pButton
            pRipple
            icon="fal fa-times"
            label="{{ 'cancel_button' | translate }}"
            (click)="onCloseRequestSidebar()"></button>
        </div>
      </div>
    </form>
  </p-sidebar>

  <p-sidebar
    [(visible)]="isShowUserRequestsSidebar"
    position="right"
    [closeOnEscape]="true"
    (onHide)="onCloseUserRequestsSidebar()"
    [style]="{'min-width':'100%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
    <div
      *ngIf="isShowUserRequestsSidebar"
      class="card p-pt-4"
      style="min-height: calc(100vh - 35px)">
      <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
        <p-panel header="User Requests/Reports" [toggleable]="true">
          <p-table
            [value]="requestsDatasource"
            [responsive]="true"
            sortField="Ddate"
            (onFilter)="onFilter($event)"
            [(selection)]="selectedRequests"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            dataKey="FeatureRequestID"
            [sortOrder]="-1"
            sortMode="single"
            [globalFilterFields]="['SortableDdate', 'FirmName', 'ContactNumber', 'StaffName', 'SortableStatus', 'Priority', 'SortableType', 'Description']"
            [rowHover]="true"
            #dtDetails
            [scrollable]="true"
            [scrollHeight]="'calc(90vh - 350px)'">
            <ng-template pTemplate="caption">
              <div class="table-header">
                <div>
                  <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input
                      #searchValueDetails
                      pInputText
                      type="text"
                      (input)="dtDetails.filterGlobal($event.target.value, 'contains')"
                      placeholder="{{'globalsearch' | translate}}" />
                  </span>
                  <span>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-danger p-button-text"
                      (click)="dtDetails.reset();clearSearchFilter()"
                      tooltipPosition="top"
                      pTooltip="{{'clear' | translate}}"></button>
                  </span>
                  <p-message
                    *ngIf="filteredItemsSize > -1"
                    severity="success"
                    text="{{filteredItemsSize}} {{'records_found' | translate}}."
                    styleClass="p-ml-2">
                  </p-message>
                  <div class="pull-right p-d-flex p-jc-end">
                    <span *ngIf="selectedRequests.length > 0">
                      <label class="label-text p-mr-2 p-mb-2" style="display: inline-block"
                        >{{ 'pm.batch_options' | translate }}</label
                      >
                      <button
                        type="button"
                        class="p-mr-4"
                        pButton
                        pRipple
                        icon="pi pi-chevron-down"
                        (click)="menu.toggle($event)"></button>
                    </span>
                    <p-menu
                      appendTo="body"
                      #menu
                      [popup]="true"
                      styleClass="button-list"
                      [model]="buttonsDeleteList">
                    </p-menu>
                    <div>
                      <label class="label-text p-mr-2">{{'All_Dates' | translate}}</label>
                      <p-dropdown
                        [options]="dateRangeList"
                        [style]="{width: '140px'}"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="DateRangeReq"
                        (onChange)="changeDateTypes('request')"
                        scrollHeight="500px"
                        placeholder="Select Date"
                        required></p-dropdown>
                      <label class="label-text p-mx-2">{{'date-from' | translate}}</label>
                      <p-calendar
                        [monthNavigator]="true"
                        [ngModelOptions]="{standalone: true}"
                        [yearNavigator]="true"
                        yearRange="2000:2090"
                        (onSelect)="getUserRequestList()"
                        (onBlur)="getUserRequestList()"
                        showButtonBar="true"
                        [(ngModel)]="DateFromReq"
                        [style]="{width: '100px'}"
                        [disabled]="DateRangeReq !== 'custom'"
                        required>
                      </p-calendar>
                      <label class="label-text p-mx-2">{{'date-to' | translate}}</label>
                      <p-calendar
                        [monthNavigator]="true"
                        [ngModelOptions]="{standalone: true}"
                        [yearNavigator]="true"
                        yearRange="2000:2090"
                        (onSelect)="getUserRequestList()"
                        (onBlur)="getUserRequestList()"
                        showButtonBar="true"
                        [(ngModel)]="DateToReq"
                        [style]="{width: '100px'}"
                        [disabled]="DateRangeReq !== 'custom'"></p-calendar>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th class="width-5p p-text-center">
                  <p-tableHeaderCheckbox
                    [disabled]="searchValue && searchValue.nativeElement.value"></p-tableHeaderCheckbox>
                </th>
                <!-- <th class="width-5p p-text-center">
                                    {{'action' | translate}}
                                </th> -->
                <th class="width-7p p-text-left" pSortableColumn="SortableDdate">
                  {{'date' | translate}}
                  <p-sortIcon field="SortableDdate"></p-sortIcon>
                </th>
                <th class="width-10p p-text-left" pSortableColumn="FirmName">
                  {{'firm_name' | translate}}
                  <p-sortIcon field="FirmName"></p-sortIcon>
                </th>
                <th class="width-8p p-text-left" pSortableColumn="StaffName">
                  {{'Staff_Name' | translate}}
                  <p-sortIcon field="StaffName"></p-sortIcon>
                </th>
                <th class="width-8p p-text-left">{{'contact_number' | translate}}</th>
                <th class="width-8p p-text-left" pSortableColumn="SortableType">
                  {{'time.type' | translate}}
                  <p-sortIcon field="SortableType"></p-sortIcon>
                </th>
                <th class="width-7p p-text-left" pSortableColumn="Priority">
                  {{'Priority' | translate}}
                  <p-sortIcon field="Priority"></p-sortIcon>
                </th>
                <th class="width-7p p-text-left" pSortableColumn="SortableStatus">
                  {{'status' | translate}}
                  <p-sortIcon field="SortableStatus"></p-sortIcon>
                </th>
                <th class="width-40p p-text-left">{{'description' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr class="cursor-hand">
                <td class="width-5p p-text-center">
                  <p-tableCheckbox [value]="rowData"> </p-tableCheckbox>
                </td>
                <!-- <td class="width-5p p-text-center">
                                    <i class="fal fa-trash-alt ic-red ic-size-15" (click)="deleteRequest(rowData);"
                                        tooltipPosition="top" pTooltip="{{'mgmt-delete' | translate}}"></i>
                                </td> -->
                <td class="width-7p p-text-left">{{ rowData.Ddate | date:'MM/dd/yyyy'}}</td>
                <td
                  class="width-10p p-text-left"
                  showDelay="800"
                  pTooltip="{{ rowData.FirmName }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  {{rowData.FirmName}}
                </td>
                <td
                  class="width-8p p-text-left"
                  showDelay="800"
                  pTooltip="{{ rowData.StaffName }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap">
                  {{rowData.StaffName}}
                </td>
                <td class="width-8p p-text-left">{{rowData.ContactNumber}}</td>
                <td class="width-8p p-text-left">{{ rowData.SortableType }}</td>
                <td class="width-7p p-text-left" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        [options]="priorityOptions"
                        placeholder="Select Priority"
                        styleClass="width-100p"
                        [(ngModel)]="rowData['Priority']"
                        [ngModelOptions]="{standalone: true}"
                        appendTo="body"
                        (onChange)="onChangeDropdown($event, rowData, 'priority');">
                      </p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{rowData.Priority ? getPriority(rowData.Priority)?.label : ''}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="width-7p p-text-left" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        [options]="statusOptions"
                        placeholder="Select Status"
                        styleClass="width-100p"
                        [(ngModel)]="rowData['Status']"
                        [ngModelOptions]="{standalone: true}"
                        appendTo="body"
                        (onChange)="onChangeDropdown($event, rowData, 'status');">
                      </p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ rowData.Status ? getStatus(rowData.Status)?.label : '' }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  class="width-40p p-text-left"
                  showDelay="800"
                  pTooltip="{{ rowData.Description }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-width-800">
                  {{rowData.Description}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7">
                  <p-message
                    severity="info"
                    text="{{'data_not_found' | translate}}."
                    styleClass="p-px-2"></p-message>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </div>
    </div>
  </p-sidebar>

  <p-dialog
    [(visible)]="isShowAssignDialog"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [contentStyle]="{overflow: 'visible'}"
    [baseZIndex]="1"
    [maximizable]="false"
    showEffect="fade"
    [draggable]="true"
    [resizable]="false">
    <p-header>Assign Items - {{ selectedRequests?.length }} Items Selected</p-header>
    <div *ngIf="isShowAssignDialog" class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <div *ngIf="assignType == 'priority'" class="p-formgroup-inline p-col-12">
        <div class="p-col-4 p-mt-4">
          <label class="label-text">Priority</label>
        </div>
        <div class="p-col-8 p-mt-4">
          <p-dropdown
            [options]="priorityOptions"
            [style]="{width: '100%'}"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="assignObj.Priority"
            scrollHeight="500px"
            placeholder="Select Priority"
            required></p-dropdown>
        </div>
      </div>
      <div *ngIf="assignType == 'status'" class="p-formgroup-inline p-col-12">
        <div class="p-col-4 p-mt-4">
          <label class="label-text">Status</label>
        </div>
        <div class="p-col-8 p-mt-4">
          <p-dropdown
            [options]="statusOptions"
            [style]="{width: '100%'}"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="assignObj.Status"
            scrollHeight="500px"
            placeholder="Select Status"
            required></p-dropdown>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-check"
          label="{{ 'Save' | translate }}"
          (click)="onAssign()"
          [disabled]="shouldDisable()"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-times"
          label="{{ 'cancel' | translate }}"
          (click)="onCloseAssignDialog()"></button>
      </div>
    </ng-template>
  </p-dialog>
</div>
