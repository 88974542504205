import { EncrDecrService } from '@app/_services';
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { delay, map, retry, tap } from 'rxjs/operators';
import { environment } from "@environments/environment";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

export interface Message {
    source: string;
    content: string;
}

@Injectable()
export class WebsocketService {
    // private subject: AnonymousSubject<MessageEvent>;
    public messages: Subject<Message>;
    public isNewUpdatesAvailable: boolean = false;
    public subject: WebSocketSubject<any>;
    public isOpen = false
    constructor(
        private encrDecrSrvc: EncrDecrService
    ) {
        // if(this.encrDecrSrvc.getObject('token'))
        // this.connect() @TODO: Allow connection once a new server for websocket is available
    }

    connect() {
        console.log("Connecting...")
        this.subject = null
        this.subject = webSocket({url: environment.SOCKET_URL, openObserver: {
            next: (val: any) => {
              this.isOpen = true
            }
          }, protocol: ["access_token", this.encrDecrSrvc.getObject('token') ]});
        this.subject.subscribe({
            next: (msg) => {
                if (msg.source === "admin" && msg.content?.toLowerCase().includes("new deployment version")) {
                    this.isNewUpdatesAvailable = true;
                }
            }, // Called whenever there is a message from the server.
            error: () => {
                console.log("Server closed. Reconnecting after 10 seconds...")
                this.isOpen = false
                setTimeout(() => {
                    // Called if at any point WebSocket API signals some kind of error.
                    this.connect()
                }, 10000)
            }, 
            complete: () => {
                console.log("Server closed. Reconnecting after 10 seconds...")
                this.isOpen = false
                setTimeout(() => {
                    // Called when connection is closed (for whatever reason).
                    this.connect()
                }, 10000)
            }
        });
    }

    ngOnDestroy(): void {
        if(this.subject)
            this.subject.unsubscribe()
    }
}