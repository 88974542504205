import { IObjectIShare } from './../../document-management/dms-ui/interface';
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CurrencyPipe } from '@angular/common';
import { BreadcrumbService, MangoApiService, EncrDecrService } from "@app/_services";
import { SelectItem } from "primeng/api";
import { AppConstants } from "@app/_helpers/api-constants";
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from "@environments/environment";
export enum EnumIShareIntegrationOption {
  CREATE_FIRM = 'create',
  ADD_EXISTING = 'existing'
}
@Component({
  selector: 'app-company-settings',
  providers: [CurrencyPipe],
  templateUrl: './company-settings.component.html',
})



export class CompanySettingsComponent implements OnInit {
  formattedAmount: string = '';
  timeexp: any = {};
  invoiceOpt: any = {};
  templates: SelectItem[];
  industries: SelectItem[];
  private TimeandExpenseSub: Subscription = null;

  iShareObj: IObjectIShare = {
    isIntegrateIS: false,
    vendorAPIKeyIShare: '',
    isIShareTokenVerified: false,
    firmID: '',
    iShareClients: [],
    mangoClients: [],
    isCreateFirm: false,
    isAddExistingFirm: false,
    selectedIShareIntegrationOption: EnumIShareIntegrationOption.CREATE_FIRM
  };
  
  constructor(
    private breadcrumbService: BreadcrumbService,
    private mangoAPISrvc: MangoApiService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private encrDecSrvc: EncrDecrService
  ) { 
    this.breadcrumbService.setItems([
      { label: "Setup Wizard" },
      { label: "Company Settings", icon: "ic-red" },
    ]);

    this.templates = [
      { label: "Select Billing Increment", value: null },
      { label: "06 minutes", value: "06 minutes" },
      { label: "10 minutes", value: "10 minutes" },
      { label: "15 minutes", value: "15 minutes" },
      { label: "30 minutes", value: "30 minutes" },
      { label: "60 minutes", value: "60 minutes" }
    ];


    this.industries = [
      { label: "Select Industry", value: null },
      { label: "Accounting", value: "Accounting" },
      { label: "Legal", value: "Legal" },
      { label: "Consulting", value: "Consulting" },
      { label: "Other", value: "Other" },
    ];

    // this.timeexp.AutoApproveSlips = true;
    // this.timeexp.AutoApproveExpenses = true;
    this.invoiceOpt.NextInvoiceNumber = 1000;
    this.getTimeAndExpenses();
    this.getInvoiceOption();
  }

  ngOnInit(): void {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.loadIShareSettings().subscribe(
      (result: any) => {
        this.iShareObj.isIShareTokenVerified = !!result.data.VendorAPIKeyIShare;
        this.iShareObj.firmID = result.data.CompanyIDImagineShare;
        this.iShareObj.isIntegrateIS = result.data.isIntegrateIS;
        this.iShareObj.vendorAPIKeyIShare = result.data.VendorAPIKeyIShare;
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          "Error occurred while removing MangoShare token."
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getTimeAndExpenses() {
    const _this = this;
    const data = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    _this.timeexp = data
    _this.timeexp.AutoApproveExpenses = _this.timeexp.AutoApproveExpenses != null? _this.timeexp.AutoApproveExpenses : true;
    _this.timeexp.AutoApproveSlips = _this.timeexp.AutoApproveSlips != null? _this.timeexp.AutoApproveSlips : true;
    setTimeout(() => {
      _this.timeexp.BillingIncrements = _this.timeexp.BillingIncrements ? _this.timeexp.BillingIncrements : '15 minutes';
      _this.timeexp.Industry = _this.timeexp.Industry ? _this.timeexp.Industry : 'Accounting';

    }, 50);
    _this.timeexp.LaborRate1 = data.LaborRate1 ? data.LaborRate1.toString() : '';
    _this.timeexp.LaborRate2 = data.LaborRate2 ? data.LaborRate2.toString() : '';
    _this.timeexp.ExpenseRate1 = data.ExpenseRate1 ? data.ExpenseRate1.toString() : '';
    _this.timeexp.ExpenseRate2 = data.ExpenseRate2 ? data.ExpenseRate2.toString() : '';

  }

  transformlaborrate1(event) {
    this.formattedAmount = this.currencyPipe.transform(this.timeexp.LaborRate1, "USD").replace("USD", "");
    event.target.value = this.formattedAmount;
  }

  transformexpenserate1(event) {
    this.formattedAmount = this.currencyPipe.transform(this.timeexp.ExpenseRate1, "USD").replace("USD", "");
    event.target.value = this.formattedAmount;
  }

  getInvoiceOption() {
    const _this = this;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getInvoiceOption().subscribe((data) => {
      _this.invoiceOpt = data;
      if (!_this.invoiceOpt.NextInvoiceNumber)
        _this.invoiceOpt.NextInvoiceNumber = 1000;

      this.mangoAPISrvc.showLoader(false);
    }, (data) => { 
      this.mangoAPISrvc.showLoader(false);
    });
  }

  onStatusChange(index) {
    switch (index) {
      case 1:
        this.timeexp.AutoApproveSlips = !this.timeexp.AutoApproveSlips;
        break;
      case 6:
        this.timeexp.AutoApproveExpenses = !this.timeexp.AutoApproveExpenses
        break;
      case 9:
        this.timeexp.ActivateLaborRates = !this.timeexp.ActivateLaborRates;
        break;
      case 10:
        this.timeexp.ActivateExpenseRates = !this.timeexp.ActivateExpenseRates;
        break;
    }
  }

  updateTimeAndExpenses() {
    const _this = this;
    this.timeexp.LaborRate1 = this.timeexp.LaborRate1 ? this.timeexp.LaborRate1.toString() : "0";
    this.timeexp.LaborRate2 = this.timeexp.LaborRate2 ? this.timeexp.LaborRate2.toString() : "0";
    this.timeexp.ExpenseRate1 = this.timeexp.ExpenseRate1 ? this.timeexp.ExpenseRate1.toString() : "0";
    this.timeexp.ExpenseRate2 = this.timeexp.ExpenseRate2 ? this.timeexp.ExpenseRate2.toString() : "0";
    this.timeexp.IsHidePriorBalance = false;
    delete this.timeexp.CompanyIDImagineShare

    this.mangoAPISrvc.updateTimeAndExpenses(this.timeexp).subscribe((data) => {
      this.encrDecSrvc.addObject(AppConstants.timeAndExpenses,this.timeexp);
      _this.saveInvoice();
    }, (data) => {
      _this.mangoAPISrvc.notify('error', 'Error!', data);
    });
    this.mangoAPISrvc.activateOrDeactivateIntegration({
      isIntegrateIS: true
    }).subscribe((result) => {
    })
    if (
      this.iShareObj.selectedIShareIntegrationOption === EnumIShareIntegrationOption.CREATE_FIRM &&
      !this.iShareObj.isIShareTokenVerified
    ) {
      this.iShareObj.isCreateFirm = true;
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createIShareFirmAndAuthenticate({}).subscribe(
        (result: any) => {
          this.iShareObj.firmID = result.data?.firm?._id;
          this.iShareObj.vendorAPIKeyIShare = result?.data?.firm?.apiKey;
          this.iShareObj.isIShareTokenVerified = true;
          this.nextStep();
          this.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          console.error(error);
          this.mangoAPISrvc.showLoader(false);
        }
      );
    } else this.nextStep();
  }

  saveInvoice() {
    const _this = this;
    this.invoiceOpt.GlobalInvoiceTemplate = "0";
    this.mangoAPISrvc.saveInvoice(this.invoiceOpt).subscribe((data) => {
    }, (data) => {
      _this.mangoAPISrvc.notify('error', 'Error!', data.json().message);
    });
  }

  updateSetupWizardStatus() {
    const klassObj = this;
    const obj = { setupStep: 3 };
    klassObj.mangoAPISrvc.updateSetupStatus(obj).subscribe((data) => {
      klassObj.encrDecSrvc.addObject(AppConstants.setupStep, "3")
      klassObj.router.navigate(['/setup-wizard/user-settings']);
    }, (data) => {
    });
  }

  previousStep() {
    this.router.navigate(['/setup-wizard/company-info']);
  }

  nextStep() {
    this.updateSetupWizardStatus();
  }

  compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  onChangeIsIntegrateIS(event) {
    this.iShareObj.isCreateFirm = false
    this.iShareObj.isAddExistingFirm = false
    this.iShareObj.isIntegrateIS = event.checked;
    if(this.iShareObj.isIntegrateIS && !this.iShareObj.isIShareTokenVerified) {
      Swal.fire({
        title: "Confirmation",
        text: "Do you want to create a new firm on MangoShare or you have an existing firm on MangoShare already?",
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Create a new firm on MangoShare',
        cancelButtonText: 'I have existing firm on MangoShare'
      }).then((result) => {
        if (result.isConfirmed) {
          this.iShareObj.isCreateFirm = true
          this.mangoAPISrvc.showLoader(true)
          this.mangoAPISrvc.createIShareFirmAndAuthenticate({}).subscribe((result: any) => {
            this.iShareObj.firmID = result.data?.firm?._id
            this.iShareObj.vendorAPIKeyIShare = result?.data?.firm?.apiKey
            this.iShareObj.isIShareTokenVerified = true
            this.mangoAPISrvc.showLoader(false)
          }, error => {
            console.error(error)
            this.mangoAPISrvc.showLoader(false)
          })
          
        } else {
          this.iShareObj.isAddExistingFirm = true
        }
      })
    }
    this.mangoAPISrvc.activateOrDeactivateIntegration({
      isIntegrateIS: this.iShareObj.isIntegrateIS
    }).subscribe((result) => {
    })
  }

  verifyISToken(obj) {
    const body = {
      vendorAPIKey: obj.vendorAPIKeyIShare,
      firmID: obj.firmID
    }
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.verifyISToken(body, true).subscribe((result) => {
      this.mangoAPISrvc.showLoader(false)
      this.iShareObj.isIShareTokenVerified = true;
    }, err => {
      this.iShareObj.isIShareTokenVerified = false;
      this.mangoAPISrvc.notify("error", "Error!", "Error occurred while verifying MangoShare token.");
      this.mangoAPISrvc.showLoader(false)
    })
  }

  removeISToken() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.removeISToken().subscribe((result) => {
      this.mangoAPISrvc.showLoader(false)
      this.iShareObj.isIShareTokenVerified = false;
      this.iShareObj.firmID = ''
      this.iShareObj.vendorAPIKeyIShare= ''
    }, err => {
      this.mangoAPISrvc.notify("error", "Error!", "Error occurred while removing MangoShare token.");
      this.mangoAPISrvc.showLoader(false)
    })
  }
}
