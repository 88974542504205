<div class="exports">
  <div class="layout-content p-pt-0">
      <div class="p-grid  p-mx-2">
          <div class="p-col-12">
              <div class="card p-mb-1 p-pl-1">
                  <div class="p-grid p-formgroup-inline">
                      <div class="p-field p-mb-0 p-col-1 p-pl-5 p-pr-0 p-mt-2 p-pb-0">
                          <label class="label-text p-mr-0">{{'Export_Type' | translate}}</label>
                      </div>
                      <div class="p-field p-mb-0 p-col-2 p-fluid p-pb-0 p-pl-4 p-pr-4">
                          <p-dropdown placeholder="Select Export Type" [ngModelOptions]="{standalone: true}"
                              [options]="exportList" [(ngModel)]="selectedType" (onChange)="selectType($event)">
                          </p-dropdown>
                      </div>
                      <div class="p-col-9 p-p-0" *ngIf="selectedType === 'Invoices' || selectedType === 'OpenTime' || selectedType === 'Payments' || selectedType === 'Appointments' " >
                          <form [formGroup]="myReportsForm" novalidate>
                              <div class="p-formgroup-inline">
                                  <div class="p-field p-mb-0 p-col-1 p-pb-0 p-pr-0 p-pl-3 p-mt-2">
                                      <label class="label-text">{{'All_Dates' | translate}}</label>
                                  </div>
                                  <div class="p-field p-mb-0 p-col-2 p-pb-0 p-fluid p-pl-0">
                                      <p-dropdown [options]="dateRangeList" formControlName="DateRange"
                                          (onChange)="changeDateTypes()" scrollHeight="500px"
                                          placeholder="Select Date" required>
                                      </p-dropdown>
                                  </div>
                                  <div class="p-field p-mb-0 p-col-1 p-pb-0 p-pr-0 p-pl-2 p-mt-2">
                                      <label class="label-text">{{'date-from' | translate}}</label>
                                  </div>
                                  <div class="p-field p-mb-0 p-col-2 p-pb-0 p-fluid p-pl-0">
                                      <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                          formControlName="DateFrom" [disabled]="IsDateTypeReadOnly"
                                          required>
                                      </p-calendar>
                                  </div>
                                  <div class="p-field p-mb-0 p-col-1 p-pb-0 p-pr-0 p-pl-2 p-mt-2">
                                      <label class="label-text">{{'date-to' | translate}}</label>
                                  </div>
                                  <div class="p-field p-mb-0 p-col-2 p-pb-0 p-fluid p-pl-0">
                                      <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090"
                                          formControlName="DateTo" [disabled]="IsDateTypeReadOnly">
                                      </p-calendar>
                                  </div>
                                  <div class="p-field p-mb-0 p-col-1 p-pb-0">
                                      <button pButton pRipple name="syncIcon" pTooltip="{{'refresh' | translate}}" *ngIf="!IsDateTypeReadOnly"
                                          tooltipPosition="top" (click)="onRefresh()" type="button" icon="fal fa-sync"
                                          class="p-field p-col p-button-rounded p-mb-0"></button>
                                  </div>
                              </div>

                          </form>
                      </div>
                  </div>
              </div>
              <div class="card p-pt-0">
                  <div class="p-col-12 p-pt-0">
                      <p-table [columns]="selectedColumns" [value]="filteredDataSource" [responsive]="true" [sortField]="selectedColumns && selectedColumns[0]?.field"
                          (onFilter)="onFilter($event)" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                          [globalFilterFields]="globalFilterColumns" [rowHover]="true" [sortOrder]="1" (onSort)="onSort($event)"
                          [scrollHeight]="'calc(90vh - 350px)'" [virtualScroll]="true" [scrollable]="true"
                          #dt>
                          <ng-template pTemplate="colgroup" let-columns>
                              <colgroup>
                                  <col *ngFor="let col of columns">
                              </colgroup>
                          </ng-template>
                          <ng-template pTemplate="caption">
                              <div class="p-grid">
                                  <div class="p-col-6">
                                      <span class="p-input-icon-left">
                                          <i class="fal fa-search"></i>
                                          <input #searchValue pInputText type="text"
                                              (input)="dt.filterGlobal($event.target.value, 'contains')"
                                              placeholder="{{ 'globalsearch' | translate }}" />
                                      </span>
                                      <span>
                                          <button pButton pRipple type="button" icon="pi pi-times"
                                              class="p-button-rounded p-button-danger p-button-text"
                                              (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                              pTooltip="{{ 'clear' | translate }}"></button>
                                      </span>
                                      <p-message *ngIf="filteredExportItemsSize > -1" severity="success"
                                          text="{{filteredExportItemsSize}} {{'records_found' | translate}}."
                                          styleClass="p-ml-2">
                                      </p-message>
                                  </div>
                                  <div class="p-col-6 p-text-right">
                                      <p-multiSelect [options]="dataCols" *ngIf="showColumnSelector"
                                          [(ngModel)]="selectedColumns" (onChange)="onChangeSelectedColumns($event)"
                                          optionLabel="header"
                                          selectedItemsLabel="{0} {{'columns_selected' | translate}}"
                                          [style]="{minWidth: '200px'}"
                                          placeholder="{{'Choose_Columns' | translate}}"></p-multiSelect>
                                      <button type="button" pButton pRipple icon="pi pi-file-o"
                                          (click)="dt.exportCSV()" class="p-mx-2" pTooltip="CSV" tooltipPosition="top"
                                          *ngIf="filteredDataSource.length > 0"></button>
                                     <!--  <button type="button" pButton pRipple icon="pi pi-file-excel"
                                          (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                          tooltipPosition="top" *ngIf="filteredDataSource.length > 0"></button> -->
                                      <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                          (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                          tooltipPosition="top" *ngIf="filteredDataSource.length > 0"></button>
                                  </div>
                              </div>
                          </ng-template>
                          <ng-template pTemplate="header" let-columns>
                              <tr>
                                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="{{col.classes ? col.classes : ''}}">
                                      <div [ngClass]="col.canFilter ? 'p-d-flex p-jc-between' : ''">
                                          <div [ngClass]="col.header === 'Billable' ? 'p-mt-1' : 'p-mt-1 p-d-flex p-ai-center'">
                                              {{col.header}}
                                              <p-sortIcon *ngIf="col.canSort" [field]="col.field"></p-sortIcon>

                                          </div>
                                          <p-columnFilter *ngIf="col.canFilter" [type]="col.type || 'text'" [field]="col.field"
                                              display="menu" (onFilter)="onFilter($event)"></p-columnFilter>
                                      </div>
                                  </th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-expanded="expanded"
                              let-columns="columns">
                              <tr>
                                  <td *ngFor="let col of columns" class="{{col.classes ? col.classes : ''}} p-text-nowrap p-text-truncate"
                                      pTooltip="{{checkRowData(col.header, rowData[col.field])}}"
                                      tooltipPosition="top" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                      <ng-container *ngIf="col.field !== 'Billable'">
                                          {{ col?.isMoney ? (rowData[col.field] | currency:'USD':'symbol':'1.2-2') : checkRowData(col.field, rowData[col.field])}}
                                      </ng-container>
                                      <ng-container *ngIf="col.field === 'Billable'">
                                          <p-checkbox binary="true" [(ngModel)]="rowData['Billable']" disabled="true"
                                              [ngModelOptions]="{ standalone: true }">{{ rowData["Billable"] }}</p-checkbox>
                                      </ng-container>
                                  </td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="1">
                                      <p-message severity="info" text="{{'data_not_found' | translate}}."
                                          styleClass="p-px-2">
                                      </p-message>
                                  </td>
                              </tr>
                          </ng-template>
                      </p-table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
