<div>
  <div
    [style.right]="dynamicRigth + 'px'"
    [ngClass]="{ 'send-email-container': true, expanded: isExpanded, minimized: isMinimized }"
  >
    <div class="email-header">
      New message
      <div class="window-actions">
        <button (click)="minimize()" title="Minimizar">─</button>
        <button (click)="toggleEmailEditor()" title="Cerrar">×</button>
      </div>
    </div>
    <div class="email-content" [formGroup]="emailForm">
      <div>
        <input formControlName="to" placeholder="To" type="email" pInputText />
        <div
          *ngIf="emailForm.get('to').invalid && emailForm.get('to').touched"
          class="error-message"
        >
          To is required.
        </div>
      </div>
      <br />
      <div>
        <p-autoComplete
          formControlName="cc"
          [suggestions]="ccEmails"
          (completeMethod)="searchCC($event)"
          [multiple]="true"
          field="email"
          placeholder="CC"
        >
        </p-autoComplete>
      </div>
      <br />
      <div>
        <p-autoComplete
          formControlName="bcc"
          [suggestions]="bccEmails"
          (completeMethod)="searchBCC($event)"
          [multiple]="true"
          field="email"
          placeholder="BCC"
        >
        </p-autoComplete>
      </div>
      <br />
      <div>
        <input formControlName="subject" placeholder="Subject (optional)" type="text" pInputText />
      </div>
      <br />
      <div>
        <p-editor formControlName="body" [style]="{ height: '300px' }"></p-editor>
        <div
          *ngIf="emailForm.get('body').invalid && emailForm.get('body').touched"
          class="error-message"
        >
          Text is required.
        </div>
      </div>
      <br />
    </div>
    <div class="email-actions">
      <button (click)="send()" [disabled]="emailForm.invalid" pButton label="Send"></button>
    </div>
  </div>
</div>
<div
  (click)="restore()"
  [style.right]="dynamicRigthBoxButtom + 'px'"
  class="minimized-bar"
  *ngIf="isMinimized"
>
  Send new email
  <i class="fa fa-minus" (click)="restore()" title="Restaurar"></i>
  <i (click)="remove()" class="fa fa-window-close" title="Cerrar"></i>
</div>
