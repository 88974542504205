import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { SharedComponentsService } from "@app/shared/components";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import Swal from "sweetalert2";

@Component({
  selector: "app-client-budgets",
  templateUrl: "./client-budgets.component.html",
})
export class ClientBudgetsComponent implements OnInit {
  clientName;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public sharedSrvc: SharedComponentsService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  ngOnInit(): void {}

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('Budgets') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
  }

}
