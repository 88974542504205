<div class="assignments">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{ 'Scheduling.filter-view' | translate }}"
                icon="{{isRightPanelShow ? 'fal fa-eye-slash' : 'fal fa-eye'}}" class="pull-right p-mr-1 p-button-sm"
                (click)="isRightPanelShow = !isRightPanelShow;onClickFilterView()"></button>

            <button pButton pRipple label="{{ 'Scheduling.show-full-day' | translate }}" icon="fal fa-calendar-alt"
                class="pull-right p-mr-1 p-button-sm" (click)="showFullDay()"></button>
            <button pButton pRipple label="{{ 'print' | translate }}" icon="fal fa-print"
                class="pull-right p-mr-1 p-button-sm" (click)="onPrintClick()"></button>
            <button pButton pRipple label="{{ 'refresh' | translate }}" icon="fal fa-sync"
                class="pull-right p-mr-1 p-button-sm" (click)="loadAllDefaultData(true)"></button>
        </span>
        <div class="p-grid">
            <div [ngClass]="(isRightPanelShow)?'p-col-9':'p-col-12'">
                <div class="card" *ngIf="eventSettings && eventSettings.dataSource">
                    <ejs-schedule
                        [showHeaderBar]="showHeaderBar"
                        style="max-width: 100%;"
                        #scheduleObj
                        [cssClass]="isGroupByDate? 'schedule-cell-dimension':'schedule-resource'"
                        width='100%'
                        [height]='heightDynamic'
                        (dataBound)="onDataBound()"
                        [selectedDate]="selectedDate"
                        [eventSettings]="eventSettings"
                        (popupOpen)="onPopupOpen($event)"
                        (actionComplete)="onActionComplete($event)"
                        [workDays]="workDays"
                        [startHour]="startHour"
                        (popupClose)='onPopupClose($event)'
                        [endHour]="endHour"
                        [currentView]="currentView"
                        [firstDayOfWeek]='startWeek'
                        [timeScale]="timeScaleOptions"
                        (eventRendered)="eventRendered($event)"
                        (click)="clickCalendar($event)"
                    >
                        <e-views>
                            <e-view option="Day">
                                <ng-template #eventTemplate let-data>
                                    <div class='template-wrap' [style.width]="'100%'" [style.background]="data.Color">
                                        <div class="e-subject {{data.AllDay ? 'e-text-center' : ''}}" [style.background]="data.Color">{{!data.AllDay ? (data.StartTime | date:'shortTime') : ''}} {{ data.ClientName ? data.ClientName + ( data.Subject !== 'Add title' && data.Subject ? ' - ' : '')  : '' }}{{data.Subject !== 'Add title' ? textDecode(data.Subject) : ''}}</div>
                                        <div class="footer" [style.background]="data.Color"></div>
                                    </div>
                                </ng-template>
                            </e-view>
                            <e-view option="Week">
                                <ng-template #eventTemplate let-data>
                                    <div class='template-wrap' [style.width]="'100%'" [style.background]="data.Color">
                                        <div class="e-subject {{data.AllDay ? 'e-text-center' : ''}}" [style.background]="data.Color">{{!data.AllDay ? (data.StartTime | date:'shortTime') : ''}} {{ data.ClientName ? data.ClientName + ( data.Subject !== 'Add title' && data.Subject ? ' - ' : '') : '' }}{{data.Subject !== 'Add title' ? textDecode(data.Subject) : ''}}</div>
                                        <div class="footer" [style.background]="data.Color"></div>
                                    </div>
                                </ng-template>
                            </e-view>
                            <e-view option="WorkWeek">
                                <ng-template #eventTemplate let-data>
                                    <div class='template-wrap' [style.width]="'100%'" [style.background]="data.Color">
                                        <div class="e-subject {{data.AllDay ? 'e-text-center' : ''}}" [style.background]="data.Color">{{!data.AllDay ? (data.StartTime | date:'shortTime') : ''}} {{ data.ClientName ? data.ClientName + ( data.Subject !== 'Add title' && data.Subject ? ' - ' : '') : '' }}{{data.Subject !== 'Add title' ? textDecode(data.Subject) : ''}}</div>
                                        <div class="footer" [style.background]="data.Color"></div>
                                    </div>
                                </ng-template>
                            </e-view>
                            <e-view option="Month">
                                <ng-template #eventTemplate let-data>
                                    <div class='template-wrap' [style.width]="'100%'" [style.background]="data.Color">
                                        <div class="e-subject {{data.AllDay ? 'e-text-center' : ''}}" [style.background]="data.Color">{{!data.AllDay ? (data.StartTime | date:'shortTime') : ''}} {{ data.ClientName ? data.ClientName + ( data.Subject !== 'Add title' && data.Subject ? ' - ' : '') : '' }}{{data.Subject !== 'Add title' ? textDecode(data.Subject) : ''}}</div>
                                        <div class="footer" [style.background]="data.Color"></div>
                                    </div>
                                </ng-template>
                            </e-view>
                            <e-view option="Agenda">
                                <ng-template #eventTemplate let-data>
                                    <div class='template-wrap' [style.width]="'100%'" [style.background]="data.Color">
                                        <div class="e-subject {{data.AllDay ? 'e-text-center' : ''}}" [style.background]="data.Color">{{!data.AllDay ? (data.StartTime | date:'shortTime') : ''}} {{ data.ClientName ? data.ClientName + ( data.Subject !== 'Add title' && data.Subject ? ' - ' : '') : '' }}{{data.Subject !== 'Add title' ? textDecode(data.Subject) : ''}}</div>
                                        <div class="footer" [style.background]="data.Color"></div>
                                    </div>
                                </ng-template>
                            </e-view>
                        </e-views>
                        <e-resources>
                            <e-resource field='OwnerId' tooltipPosition="top" pTooltip='Owners'
                                [dataSource]='resourceDataSource' [allowMultiple]='allowMultiple' name='Owners'
                                textField='OwnerText' idField='OwnerId' colorField='Color'>
                            </e-resource>
                        </e-resources>
                    </ejs-schedule>

                    <ejs-contextmenu #menuObj cssClass='schedule-context-menu' target='.e-schedule' [items]='menuItems'
                        (beforeOpen)='onContextMenuBeforeOpen($event)' (select)='onMenuItemSelect($event)'>
                    </ejs-contextmenu>

                    <div #grid id="grid" style="display: none;">
                        <ejs-grid class="sortingenabled" [dataSource]='data' allowPaging='true' allowSorting='true'
                            [sortSettings]='initialSort' [pageSettings]='pageSettings'>
                            <e-columns>
                                <e-column field='Subject' headerText='Subject' width='150'></e-column>
                                <e-column field='Location' headerText='Location' width='170'></e-column>
                                <e-column field='StartTime' headerText='Start Time' format="M/d/y hh:mm a" width='155'>
                                </e-column>
                                <e-column field='EndTime' headerText='End Time' format="M/d/y hh:mm a" width='150'>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
            <div [ngClass]="(isRightPanelShow)?'p-col-3':'p-mb-3'">
                <div class="card">
                    <div class="p-col-12">
                        <h4 class="label-text">{{ 'Scheduling.search-event-fields' | translate }}</h4>
                    </div>
                    <div class="p-col-12">
                        <input type="text" class="e-input" id="events-search"
                            placeholder="{{ 'Scheduling.enter-search-text' | translate }}"
                            (keyup)="globalSearch($event)" />
                    </div>
                    <div *ngIf="appointmentsReSource?.length > 0" class="p-col-12">
                        <table id="checkboxes">
                            <tbody>
                                <tr class="p-my-2" *ngFor="let item of appointmentsReSource; let i = index">
                                    <td class="p-d-flex p-jc-start">
                                        <ejs-checkbox label="{{item.StaffName}}" id="checkbox-resource-{{i}}"
                                            value="{{item.ResourceID}}" [(checked)]="item['checked']"
                                            (change)="onChange();onChangeSelectedResource()">
                                        </ejs-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="p-col-12"></div>
                    <div class="p-col-12">
                        <h4 class="label-text">{{ 'group_by' | translate }}</h4>
                        <p-checkbox [(ngModel)]="isGroupByDate" (ngModelChange)="onGroupByDateChange()" binary="true" label="{{'scheduling.date_and_staff'| translate}}"></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
