<div class="center-box">
    <div class="layout-content p-mx-6 p-my-0 p-col-4">
        <p-card>
            <ng-template pTemplate="title">
                Company Information
            </ng-template>
            <ng-template pTemplate="subtitle">
                Please review Company Information and enter a required phone number.
            </ng-template>
            <ng-template pTemplate="content">
                <div class="p-fluid">
                    <div class="p-field">
                        <label class="label-text required-field">Company Name</label>
                        <input pInputText type="text" [(ngModel)]="company.CompanyName" name="companyName" id="companyName"
                            #companyNameVl="ngModel" required minlength="3" maxlength="70" readonly class="p-text-bold"/>
                        <div *ngIf="companyNameVl.touched && companyNameVl.errors?.required" class="ic-orange">
                            Company Name is required.</div>
                        <div *ngIf="companyNameVl.touched && companyNameVl.errors?.minlength" class="ic-orange">
                            Minimum of 3 characters</div>
                        <div *ngIf="companyNameVl.touched && companyNameVl.errors?.maxlength" class="ic-orange">
                            Maximum of 70 characters</div>
                    </div>
                    <div class="p-field">
                        <label class="label-text required-field">Contact Name</label>
                        <input pInputText type="text" [(ngModel)]="company.CompanyContact" name="contact" id="conatct"
                            #contact="ngModel" required minlength="3" maxlength="70" readonly class="p-text-bold" />
                        <div *ngIf="contact.touched && contact.errors?.required" class="ic-orange">
                            Contact name is required.</div>
                        <div *ngIf="contact.touched && contact.errors?.minlength" class="ic-orange">
                            Minimum of 3 characters</div>
                        <div *ngIf="contact.touched && contact.errors?.maxlength" class="ic-orange">
                            Maximum of 70 characters</div>
                    </div>
                    <div class="p-field">
                        <label class="label-text required-field">Phone Number</label>
                        <p-inputMask autocomplete="off" [(ngModel)]="company.Telephone" mask="(999) 999-9999" name="phone"
                            id="phone" #phone="ngModel" required (onBlur)="validPhoneNumber()" class="p-text-bold">
                        </p-inputMask>
                        <div *ngIf="phone.touched && phone.errors?.required" class="ic-orange">
                            Phone Number is required.</div>
                    </div>
                    <div class="p-field">
                        <label class="label-text required-field">Email</label>
                        <input pInputText type="text" autocomplete="off" [(ngModel)]="company.EmailCompany" name="email" id="email"
                            #companyEmail="ngModel" required email readonly class="p-text-bold"/>
                        <div *ngIf="companyEmail.touched && companyEmail.errors?.required" class="ic-orange">
                            Email is required</div>
                        <div *ngIf="companyEmail.touched && companyEmail.errors?.email" class="ic-orange">
                            Email is invalid</div>
                    </div>
                    <div class="p-field">
                        <label class="label-text required-field">Time Zone</label>
                        <p-dropdown styleClass="width-100p" [ngModelOptions]="{standalone: true}" [options]="timeZoneDataSet"
                            [autoWidth]=false [(ngModel)]="company.timezone" class="p-text-bold"></p-dropdown>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <div class="p-grid p-nogutter p-justify-end">
                    <p-button label="Next" (onClick)="saveCompanyInfo()" [disabled]="!isValidPhoneNumber"  icon="pi pi-angle-right" iconPos="right"></p-button>
                </div>
            </ng-template>
        </p-card>
    </div>
</div>