<!-- <p-dialog header="{{ 'Video Demonstration' | translate }}" [(visible)]="isShowVideo" [modal]="true" [style]="{width: '60vw'}" [maximizable]="false"
    [contentStyle]="{overflow: 'visible'}" showEffect="fade" (onHide)="isShowVideo = false"
    [draggable]="true" [resizable]="false">
    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
            <div class="wistia_embed wistia_async_{{videoCode}} videoFoam=true" style="height:100%;position:relative;width:100%">&nbsp;</div>
        </div>
    </div>
</p-dialog> -->
<div tooltipPosition="top" pTooltip="{{ 'Video Tutorial' | translate }}" class="wistia_embed wistia_async_{{videoCode}} popover=true popoverContent=html popoverPreventScroll=true" style="display:inline-block; white-space:nowrap;">
    <button type="button" pButton pRipple icon="fal fa-video" label="Tutorial" style="background-color: #de7a5a; border-color: #de7a5a" class="p-button-sm p-pr-3 p-mr-2"></button>   
</div>