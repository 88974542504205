import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mail-configuration',
  templateUrl: './mail-configuration.component.html'
})
export class MailConfigurationComponent implements OnInit {
  mailConfigurationsForm: UntypedFormGroup;
  public companyData: any = {};
  public companyId;
  public PortalUserAssignedID: any;
  public isPortalUserEnable: boolean = false;
  public MangoUserAssignedID: any;
  public isMangoUserEnable: boolean = false;
  public AllUsers: any = [];
  constructor(
    private fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.initializeForm();
  }

  ngOnInit(): void {
    this.getCompanyInfo();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('notifications'), icon: 'ic-red' }
    ]);
  }

  initializeForm() {
    this.mailConfigurationsForm = this.fb.group({
      EmailPaymentPortal: [false],
      EmailPaymentMango: [false],
      EmailCalendarEvent: [false],
      EmailFinishDDM: [false],
      InvoiceReminderActivate: [false],
      InvoiceReminderDaysPastDue: [0],
      InvoiceReminderInterval: [0],
      InvoiceReminderStartDate: [new Date()],
      PortalUserAssignedID: [],
      MangoUserAssignedID: [],
      RevenueBudgetUserAssignedID: [],
      TimeBudgetUserAssignedID: [],
      // isRevenueBudget: [false],
      // isTimeBudget: [false],
      portalFirm: [false],
      mangoFirm: [false],
      isToDoEmailNotify: [false]
    })

    this.mailConfigurationsForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.mailConfigurationsForm.pristine);
      this.isPortalUserEnable = !data.portalFirm;
      this.isMangoUserEnable = !data.mangoFirm
    });
  }

  getCompanyInfo() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getPMAllStaffList().subscribe(
      (allStaff: any) => {
        for (let i = 0; i < allStaff.length; i++) {
          if (allStaff[i].Inactive)
            continue;
          const obj = {};
          obj["label"] = allStaff[i]["StaffName"];
          obj["value"] = allStaff[i]["StaffID"];
          obj["StaffID"] = allStaff[i]["StaffID"];
          obj["StaffName"] = allStaff[i]["StaffName"];
          obj["StaffNumber"] = allStaff[i]["StaffNumber"];
          obj["Email"] = allStaff[i]["Email"];
          this.AllUsers.push(obj);
        }

        this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe(response => {
          this.mailConfigurationsForm.controls['EmailPaymentPortal'].setValue(response[0]['EmailPaymentPortal']);
          this.mailConfigurationsForm.controls['EmailPaymentMango'].setValue(response[0]['EmailPaymentMango']);
          this.mailConfigurationsForm.controls['EmailCalendarEvent'].setValue(response[0]['EmailCalendarEvent']);
          this.mailConfigurationsForm.controls['EmailFinishDDM'].setValue(response[0]['EmailFinishDDM']);

          this.mailConfigurationsForm.controls['InvoiceReminderActivate'].setValue(response[0]['InvoiceReminderActivate']);
          response[0]['InvoiceReminderDaysPastDue'] = response[0]['InvoiceReminderDaysPastDue'] ? response[0]['InvoiceReminderDaysPastDue'] : 0;
          this.mailConfigurationsForm.controls['InvoiceReminderDaysPastDue'].setValue(response[0]['InvoiceReminderDaysPastDue']);
          response[0]['InvoiceReminderInterval'] = response[0]['InvoiceReminderInterval'] ? response[0]['InvoiceReminderInterval'] : 0;
          this.mailConfigurationsForm.controls['InvoiceReminderInterval'].setValue(response[0]['InvoiceReminderInterval']);
          response[0]['InvoiceReminderStartDate'] = (response[0]['InvoiceReminderStartDate']) ? new Date(response[0]['InvoiceReminderStartDate']) : null;
          this.mailConfigurationsForm.controls['InvoiceReminderStartDate'].setValue(response[0]['InvoiceReminderStartDate']);
          this.mailConfigurationsForm.controls['isToDoEmailNotify'].setValue(response[0]['isToDoEmailNotify']);
          this.companyData = response[0];
          

          this.mangoAPISrvc.getCompanyDefaultNotifications(this.companyId).subscribe((resultNotif: any) => {
            this.mangoAPISrvc.showLoader(false);
            if(resultNotif?.RevenueEmailListBudget?.length > 0) {
              this.mailConfigurationsForm.controls['RevenueBudgetUserAssignedID'].setValue(resultNotif?.RevenueEmailListBudget?.map((item) => parseInt(item)));
            }

            if(resultNotif?.TimeEmailListBudget?.length > 0) {
              this.mailConfigurationsForm.controls['TimeBudgetUserAssignedID'].setValue(resultNotif?.TimeEmailListBudget?.map((item) => parseInt(item)));
            }
          }, err => {
            this.mangoAPISrvc.showLoader(false);
          })

          const selectedPortalUser = [];
          const selectedMangoUser = [];

          if(this.companyData.PortalPaymentNotifications && this.companyData.PortalPaymentNotifications.length > 0){
            this.mailConfigurationsForm.controls['portalFirm'].setValue(true);
            for (let i = 0; i < this.companyData.PortalPaymentNotifications.length; i++) {
              const item = this.AllUsers.filter(itm => itm.value === parseInt(this.companyData.PortalPaymentNotifications[i]));
              if (item.length > 0) selectedPortalUser.push(item[0].value);
            }
          }
          else{
            //check if company email is in staff emails
            const item = this.AllUsers.filter(itm => itm.Email === this.companyData.EmailCompany);
            if (item.length > 0) selectedPortalUser.push(item[0].value);
          }
       
          if(this.companyData.MangoPaymentNotification && this.companyData.MangoPaymentNotification.length > 0 ){
            this.mailConfigurationsForm.controls['mangoFirm'].setValue(true);
            for (let i = 0; i < this.companyData.MangoPaymentNotification.length; i++) {
              const item = this.AllUsers.filter(itm => itm.value === parseInt(this.companyData.MangoPaymentNotification[i]));
              if (item.length > 0) selectedMangoUser.push(item[0].value);
            }
          }
          else{
            const item = this.AllUsers.filter(itm => itm.Email === this.companyData.EmailCompany);
            if (item.length > 0) selectedMangoUser.push(item[0].value);
          }
          
          this.PortalUserAssignedID = selectedPortalUser;
          this.MangoUserAssignedID = selectedMangoUser;
        });
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
      });
  }

  saveMailOptions() {
    this.mangoAPISrvc.showLoader(true);
    const formData = this.mailConfigurationsForm.value;
    this.companyData['EmailPaymentPortal'] = formData['EmailPaymentPortal'];
    this.companyData['EmailPaymentMango'] = formData['EmailPaymentMango'];
    this.companyData['EmailCalendarEvent'] = formData['EmailCalendarEvent'];
    this.companyData['EmailFinishDDM'] = formData['EmailFinishDDM'];
    this.companyData['phone'] = this.companyData['Telephone'];
    this.companyData['email'] = this.companyData['EmailCompany'];
    this.companyData['state'] = this.companyData['State'];
    this.companyData['country'] = this.companyData['Country'];
    this.companyData['companyName'] = this.companyData['CompanyName'];

    this.companyData['InvoiceReminderActivate'] = formData['InvoiceReminderActivate'];
    if (this.companyData['InvoiceReminderActivate'] == true) {
      this.companyData['InvoiceReminderDaysPastDue'] = formData['InvoiceReminderDaysPastDue'];
      this.companyData['InvoiceReminderInterval'] = formData['InvoiceReminderInterval'];
      this.companyData['InvoiceReminderStartDate'] = formData['InvoiceReminderStartDate'];
    } else {
      this.companyData['InvoiceReminderDaysPastDue'] = 0;
      this.companyData['InvoiceReminderInterval'] = 0;
      this.companyData['InvoiceReminderStartDate'] = null;
    }
    this.companyData['isToDoEmailNotify'] = formData['isToDoEmailNotify'] ? formData['isToDoEmailNotify'] : false;
    this.companyData['PortalPaymentNotifications'] = formData['portalFirm'] && this.PortalUserAssignedID ? `{${this.PortalUserAssignedID.toString()}}` : null;
    this.companyData['MangoPaymentNotification'] = formData['mangoFirm'] && this.MangoUserAssignedID ? `{${this.MangoUserAssignedID.toString()}}` : null;

    this.mangoAPISrvc.updateCompanyNotificationSetting(this.companyData).subscribe(response => {
      this.mangoAPISrvc.updateCompanyDefaultNotification({
        RevenueEmailListBudget: formData.RevenueBudgetUserAssignedID ? `{${formData.RevenueBudgetUserAssignedID.toString()}}` : null,
        TimeEmailListBudget: formData.TimeBudgetUserAssignedID ? `{${formData.TimeBudgetUserAssignedID.toString()}}` : null
      }, this.companyId).subscribe((resultNotif: any) => {
        const data = {}
        data['Action'] = "Settings - Notifications";
        data['Description'] = "Changes to Settings";
        data['Table'] = "CompanyMango";
        const isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
        if (!isManaging) {
          this.mangoAPISrvc.addUserLogs(data).subscribe(res=>{}, err=>{});
        }
      
        this.mangoAPISrvc.showLoader(false);
      }, err => this.mangoAPISrvc.showLoader(false))
      
      this.mailConfigurationsForm.markAsPristine();
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    });
  }

}
