<div class="engagement-list">
    <div class="layout-content">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{ 'list' | translate }}" icon="fal fa-project-diagram"
                class="p-mr-1 p-button-sm" (click)="redirectListView()"></button>
        </span>
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="tempInvoice2" [rowHover]="true" [responsive]="true"
                        dataKey="BillingHeaderID" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                        [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="InvoiceDate" [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" 
                        [globalFilterFields]="['InvoiceDate','InvoiceNumber','DescriptionShort','InvoiceAmount','Discount','TotalPayments','LateFeeAmount','InvoiceBalance']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                <span class="pull-right">
                                    <p-dropdown [options]="invoiceOption" [(ngModel)]="nameCT" optionLabel="label"
                                        (onChange)="chageFilterAIA($event)"></p-dropdown>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-3p p-text-left"></th>
                                <th class="width-10p p-text-center">{{ 'actions' | translate }}</th>
                                <th pSortableColumn="InvoiceDate" pResizableColumn class="width-9p p-text-left">{{
                                    'Accounts.invoice-date' | translate }}
                                    <p-sortIcon field="InvoiceDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="InvoiceNumber" pResizableColumn class="width-9p p-text-right">{{
                                    'Accounts.invoice-number' | translate }}
                                    <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-17p p-text-left">{{
                                    'billing-invoicing.invoice-description' | translate }}</th>
                                <th pResizableColumn class="width-7p p-text-right">{{ 'Accounts.invoice-amount' |
                                    translate }}</th>
                                <th pResizableColumn class="width-5p p-text-right">{{ 'billing-invoicing.discount' |
                                    translate }}</th>
                                <th pResizableColumn class="width-7p p-text-right">{{ 'payments-applied' | translate }}
                                </th>
                                <th pResizableColumn class="width-5p p-text-right">{{ 'client.late_fee' | translate }}
                                </th>
                                <th pResizableColumn class="width-9p p-text-right">{{ 'Accounts.invoice-balance' |
                                    translate }}</th>
                                <th pResizableColumn class="width-4p p-text-center">{{ 'email' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-client let-i="rowIndex" let-expanded="expanded">
                            <tr class="cursor-hand">
                                <td class="width-3p p-text-center">
                                    <span [pRowToggler]="client" *ngIf="client.isExpandble">
                                        <i tooltipPosition="top" pTooltip="Show Payments" pRipple showDelay="900"
                                            [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                                        </i>
                                    </span>
                                </td>
                                <td class="width-10p">
                                    <div class="p-text-center">
                                        <i class="fal fa-print p-mr-3 ic-red" tooltipPosition="top"
                                            pTooltip="{{ 'preview' | translate }}"
                                            (click)="previewInvoice($event,client)"></i>
                                        <i class="lp-mr-3 ic-green" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}" (click)="editInvoice(client)"></i>
                                        <i class="fas fa-history  p-mr-3 ic-orange" tooltipPosition="top"
                                            pTooltip="Time Report" (click)="previewTimeHistoryReport(client)"></i>
                                        <i class="fal fa-undo ic-blue" tooltipPosition="top" pTooltip="Reverse"
                                            (click)="reverseInvoice(client,i)"></i>
                                    </div>
                                </td>
                                <td class="width-9p p-text-left"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{client.InvoiceDate | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-9p p-text-right"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{client.InvoiceNumber}}
                                </td>
                                <td class="width-17p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    pTooltip="{{client.DescriptionShort}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{client.DescriptionShort}}
                                </td>
                                <td class="width-7p p-text-right"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{(client.InvoiceAmount) | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-5p p-text-right"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{client.Discount | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-7p p-text-right"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{client.TotalPayments | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-5p p-text-right"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{client.LateFeeAmount | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-9p p-text-right"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-red':''">
                                    {{(client.InvoiceBalance) | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-4p p-text-center">
                                    <i class="far fa-envelope ic-red" tooltipPosition="top"
                                        pTooltip="Email Invoice"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-ri="rowIndex" let-client>
                            <tr>
                                <td [attr.colspan]="10">
                                    <div class="p-p-2 bg-blue">
                                        <p-table [value]="client.paymentDetails" [rowsPerPageOptions]="[5,10,20]"
                                            [rowHover]="true" sortField="InvoiceNumber" dataKey="BillingHeaderID"
                                            [paginator]="client.paymentDetails.length > 10" [sortOrder]="-1"
                                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                            #dtchild>
                                            <!-- Table Header -->
                                            <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th pSortableColumn="PaymentDate" pResizableColumn class="width-12p p-text-center">{{
                                    'Accounts.payment-date' | translate }}
                                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                                </th>
                                <th class="width-9p p-text-center">{{ 'Accounts.payment-type' | translate }}</th>
                                <th class="width-9p p-text-center">{{ 'Accounts.check-reference' | translate }}</th>
                                <th class="width-9p p-text-center">{{ 'Accounts.invoice-number' | translate }}</th>
                                <th class="width-9p p-text-right">{{ 'payment' | translate }}</th>
                                <th class="width-9p p-text-right">{{ 'Account.written_off' | translate }}</th>
                                <th class="width-40p p-text-left">{{ 'Account.payment_note' | translate }}</th>
                            </tr>
                        </ng-template>
                        <!-- Rows -->
                        <ng-template pTemplate="body" let-childrowData let-columns="columns">
                            <tr>
                                <td class="p-text-center">
                                    <span>{{childrowData.PaymentDate | date:'MM/dd/yyyy'}}</span>
                                </td>
                                <td class="p-text-center"> {{childrowData.PaymentType}}</td>
                                <td class="p-text-center">{{childrowData.CheckRef}}</td>
                                <td class="p-text-center">{{childrowData.InvoiceNumber}}</td>
                                <td class="p-text-right">
                                    {{ childrowData.AppliedAmount | currency:'USD':'symbol':'1.2-2' }}
                                </td>
                                <td class="p-text-right">
                                    {{childrowData.WriteOffAmount | currency:'USD':'symbol':'1.2-2'}}
                                </td>
                                <td class="p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    pTooltip="{{childrowData.PaymentNote}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                    {{childrowData.PaymentNote}}</td>
                            </tr>
                        </ng-template>
                        <!-- No data -->
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="7">
                                    {{ 'data_not_found' | translate }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                </td>
                </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td class="width-3p"></td>
                        <td class="width-10p"></td>
                        <td class="width-9p"></td>
                        <td class="width-9p"></td>
                        <td class="width-17p p-text-right p-text-bold ic-red">{{ 'Totals' | translate }}</td>
                        <td class="width-7p p-text-right p-text-bold ic-red">
                            <span *ngIf="tempInvoice2.length > 0">{{ totalInvoiceAmount |
                                currency:'USD':'symbol':'1.2-2' }}</span>
                        </td>
                        <td class="width-5p p-text-right p-text-bold ic-red">
                            <span *ngIf="tempInvoice2.length > 0">{{ totalDiscount |
                                currency:'USD':'symbol':'1.2-2'}}</span>
                        </td>
                        <td class="width-7p p-text-right p-text-bold ic-red">
                            <span *ngIf="tempInvoice2.length > 0">{{ totalPaymentsApplied |
                                currency:'USD':'symbol':'1.2-2'}}</span>
                        </td>
                        <td class="width-5p p-text-right p-text-bold ic-red">
                            <span *ngIf="tempInvoice2.length > 0">{{ totalLateFeeAmount |
                                currency:'USD':'symbol':'1.2-2'}}</span>
                        </td>
                        <td class="width-9p p-text-right p-text-bold ic-red">
                            <span *ngIf="tempInvoice2.length > 0">{{ totalInvoiceBalance |
                                currency:'USD':'symbol':'1.2-2'}}</span>
                        </td>
                        <td class="width-4p"></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="11">
                            <p-message severity="info" text="{{ 'data_not_found' | translate }}" styleClass="p-px-2">
                            </p-message>
                        </td>
                    </tr>
                </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
</div>