<div class="company-info">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple label="Next Step" icon="fal fa-arrow-right" iconPos="right" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="step2()"></p-button>
        </span>
        <div class="p-py-2 p-px-3">
            <div class="card p-col-12 p-px-3">
                <div class="p-col-12">
                    <h1>Welcome to Mango Practice Management</h1>
                </div>
                <div class="p-col-12">
                    <h5>Please take a moment to go through the next few screens to properly setup your Company information and customize your settings.</h5>
                </div>
            </div>
        </div>
    </div>
</div>