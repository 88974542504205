import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

export interface NewUpdatesReturn {
  isLogout: boolean
}

@Component({
  selector: "app-new-updates-available",
  templateUrl: "./new-updates-available.component.html",
})

export class NewUpdatesAvailableComponent implements OnInit {
  @Input() onSubmit: () => void;
  @Output() onSubmitNewUpdates: EventEmitter<NewUpdatesReturn> = new EventEmitter<NewUpdatesReturn>();
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    Swal.fire({
      html: this.translate.instant("new-updates.message"),
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Ok"
    }).then(() => {
      this.onSubmitNewUpdates.emit({ isLogout: false })
    })
  }
}
